import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Form from 'model/Forms/Form';

import RootState from 'model/State/RootState';
import { FormsState } from 'model/State/FormsState';
import { CustomFormClass } from 'model/Form';

const initialState: FormsState = {
  jobForms: {},
  isLoadingJobForms: {},
  isGeneratingPdf: false,
};

export const forms = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    fetchJobForms: (state: FormsState, action: PayloadAction<string>) => {
      state.isLoadingJobForms[action.payload] = true;
    },
    fetchJobFormsSuccess: (
      state: FormsState,
      action: PayloadAction<{ jobId: string; forms: Form[] }>,
    ) => {
      state.isLoadingJobForms[action.payload.jobId] = false;
      state.jobForms = {
        ...state.jobForms,
        [action.payload.jobId]: action.payload.forms,
      };
    },
    fetchJobFormsError: (state: FormsState, action: PayloadAction<string>) => {
      state.isLoadingJobForms[action.payload] = false;
    },
    getPdfExportId: (
      state: FormsState,
      action: PayloadAction<{
        formClass: CustomFormClass;
        formId: string;
        eventProperties: object;
      }>,
    ) => {
      state.isGeneratingPdf = true;
    },
    getPdfExportIdSuccess: (state: FormsState) => {
      state.isGeneratingPdf = false;
    },
    getPdfExportIdError: (state: FormsState) => {
      state.isGeneratingPdf = false;
    },
  },
});

export const {
  fetchJobForms,
  fetchJobFormsSuccess,
  fetchJobFormsError,
  getPdfExportId,
  getPdfExportIdSuccess,
  getPdfExportIdError,
} = forms.actions;

export const selectJobForms =
  (jobId?: string) =>
  (state: RootState): Form[] | null =>
    jobId ? state.forms.jobForms[jobId] : null;
export const selectJobFormsIsLoading =
  (jobId?: string) =>
  (state: RootState): boolean =>
    jobId ? state.forms.isLoadingJobForms[jobId] : false;
export const selectIsGeneratingFormPdf = (state: RootState): boolean =>
  state.forms.isGeneratingPdf;

export const formsReducer = forms.reducer;

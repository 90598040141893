import { useState, useEffect, useCallback } from 'react';
import theme from 'theme';

export default () => {
  const getWindowWidth = useCallback(() => window.innerWidth, []);

  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    const handleResize = () => setWindowWidth(getWindowWidth());

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [getWindowWidth]);

  return windowWidth < theme.breakpoints.sm;
};

import { put, select, takeLatest } from 'redux-saga/effects';
import { CommandCenter } from 'api';
import { Dates } from 'helpers';
import { setGlobalError } from 'store/slices/notifications';
import { setLastRefresh } from '../actions/session';
import { COMMAND_CENTER_LATEST_ACTIVITY_MAX_NUMBER_OF_ITEMS } from 'utils/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { CommandCenterFilterOptions } from 'model/CommandCenter/FilterOptions';

import {
  fetchStats,
  setStats,
  fetchActivities,
  setActivities,
  setActivitiesError,
} from 'store/slices/commandCenter';
import { AxiosError } from 'axios';

function* fetchCommandCenterStats(
  action: PayloadAction<CommandCenterFilterOptions>,
) {
  try {
    const { selectedWorkspace } = yield select((state) => state.session);
    const data = yield CommandCenter.getStats({
      ...action.payload,
      workspaceUuid: action.payload.workspaceUuid || selectedWorkspace?.uuid,
    });
    yield put(setStats(data));
    yield put(setLastRefresh(Dates.getLastRefresh()));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* fetchCommandCenterActivities(
  action: PayloadAction<{
    filterOptions: CommandCenterFilterOptions;
    options: { clearState: boolean };
  }>,
) {
  try {
    const { selectedWorkspace } = yield select((state) => state.session);

    const pageSize = 10;
    const maxPages =
      COMMAND_CENTER_LATEST_ACTIVITY_MAX_NUMBER_OF_ITEMS / pageSize;

    for (let page = 1; page < maxPages + 1; page++) {
      const data = yield CommandCenter.getActivities(
        {
          ...action.payload.filterOptions,
          workspaceUuid:
            action.payload.filterOptions.workspaceUuid ||
            selectedWorkspace?.uuid,
        },
        page,
      );

      const maxPagesForThisRequest = data?.count
        ? Math.ceil(data.count / pageSize)
        : 0;

      const isLastPage = page >= maxPagesForThisRequest || page >= maxPages;

      yield put(
        setActivities({
          activities: data.results,
          options: { isLastPage },
        }),
      );

      if (isLastPage) {
        break;
      }
    }
  } catch (err) {
    if ((err as AxiosError)?.response?.status === 404) {
      yield put(
        setActivities({
          activities: [],
          options: { isLastPage: true },
        }),
      );
    } else {
      yield put(setGlobalError(err));
      yield put(setActivitiesError());
    }
    console.error(err);
  }
}

export default function* commandCenterWatcher() {
  yield takeLatest(fetchStats.type, fetchCommandCenterStats);
  yield takeLatest(fetchActivities.type, fetchCommandCenterActivities);
}

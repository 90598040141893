import { createSelector } from 'reselect';
import RootState from 'model/State/RootState';
import Settings from 'model/Settings';
import { Languages } from 'utils/constants';

export const selectCurrentUserId = (state: RootState) =>
  state.session?.currentUser?.id;

export const selectIsStreamline = (state: RootState) =>
  state?.session?.selectedWorkspace?.feature_flags?.includes('streamline_v1');

export const selectUserPermissions = (state: RootState): string[] =>
  state?.session?.currentUser?.permissions ?? [];

const selectUserPermissionByFieldName = createSelector(
  selectUserPermissions,
  (_: RootState, fieldName: string) => fieldName,
  (permissions: string[], fieldName: string) =>
    !!permissions?.length && permissions?.includes(fieldName),
);

export const selectUserHasPermission =
  (fieldName: string) =>
  (state: RootState): boolean =>
    selectUserPermissionByFieldName(state, fieldName);

export const selectActiveWorkspaceUuid = (state: RootState) =>
  state?.session?.selectedWorkspace?.uuid;

export const selectWorkspaceSettings = (state: RootState) =>
  state?.session?.selectedWorkspace?.settings;

const selectWorkspaceSettingByFieldName = createSelector(
  selectWorkspaceSettings,
  (_: RootState, fieldName: string) => fieldName,
  (workspaceSettings: Settings, fieldName: string) =>
    workspaceSettings ? workspaceSettings[fieldName] === true : false,
);

export const selectWorkspaceHasSettingEnabled =
  (fieldName: string) => (state: RootState) =>
    selectWorkspaceSettingByFieldName(state, fieldName);

export const selectOrganizationId = (state: RootState) =>
  state?.session?.currentUser?.organisation?.uuid;

export const selectOrganizationSettings = (state: RootState) =>
  state.session.selectedWorkspace?.settings == null
    ? state.session?.currentUser?.organisation?.settings
    : state.session?.selectedWorkspace?.settings;

const selectOrganizationSettingByName = createSelector(
  selectOrganizationSettings,
  (_: RootState, fieldName: string) => fieldName,
  (organisationSettings: Settings | undefined, fieldName: string) =>
    organisationSettings ? organisationSettings[fieldName] === true : false,
);

export const selectHasOrganizationSettingEnabled =
  (fieldName: string) => (state: RootState) =>
    selectOrganizationSettingByName(state, fieldName);

export const selectGlobalLanguageSetting = (state: RootState) =>
  state.session.selectedLanguage || Languages.english.locale;

const isCurrentUser = createSelector(
  selectCurrentUserId,
  (_: RootState, id: number) => id,
  (currentUserId: number, id: number) => currentUserId === id,
);

export const selectIsProjectEngineerAssessment =
  (id: number) => (state: RootState) =>
    state.session.isProjectEngineer && isCurrentUser(state, id);

export const canSeeWrapUpSummary = (state: RootState) =>
  createSelector(
    selectWorkspaceHasSettingEnabled('wrap_up_summary_view'),
    selectUserHasPermission('wrap_up_summary_view'),
    (workspaceFlag, userPermission): boolean => workspaceFlag || userPermission,
  )(state);

import { Display } from 'components/ui';
import { CollapseContainer, SliderWrapper } from './styled';
import { useText } from 'hooks';
import { RatingValue, ratingValues } from 'model/Assessment/RAMS';

type Props = {
  title;
  rating: RatingValue;
  onSeverityChange: (value: string) => void;
  onLikelihoodChange: (value: string) => void;
  defaultActive?: boolean;
};

export const SliderRating = ({
  title,
  rating,
  onSeverityChange,
  onLikelihoodChange,
  defaultActive = false,
}: Props): JSX.Element => {
  const getText = useText();
  return (
    <CollapseContainer>
      <Display.Collapsible defaultActive={defaultActive} title={title}>
        <SliderWrapper>
          <Display.Slider
            steps={ratingValues}
            value={rating.severity}
            onChange={(value) => onSeverityChange(value)}
            title={`${getText('rams_rating_steps_severity')}: ${getText(
              ratingValues.find((value) => value.value === rating.severity)
                ?.title,
            )}`}
          />
          <Display.Slider
            steps={ratingValues}
            value={rating.likelihood}
            onChange={(value) => onLikelihoodChange(value)}
            title={`${getText('rams_rating_steps_likelihood')}: ${getText(
              ratingValues.find((value) => value.value === rating.likelihood)
                ?.title,
            )}`}
          />
        </SliderWrapper>
      </Display.Collapsible>
    </CollapseContainer>
  );
};

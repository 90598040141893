import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import RootState from 'model/State/RootState';
import { SiteBriefing } from 'model/State/SiteBriefing';
import SiteBriefingState from 'model/State/SiteBriefingState';

const initialState: SiteBriefingState = {
  siteBriefingsByJob: {},
  isFetchingSiteBriefing: false,
};

export const siteBriefing = createSlice({
  name: 'siteBriefing',
  initialState,
  reducers: {
    fetchSiteBriefing: (
      state: SiteBriefingState,
      action: PayloadAction<{ jobId: number; siteBriefingId: number }>,
    ) => {
      state.isFetchingSiteBriefing = true;
    },
    fetchSiteBriefingSuccess: (
      state: SiteBriefingState,
      action: PayloadAction<SiteBriefing>,
    ) => {
      state.isFetchingSiteBriefing = false;
      state.siteBriefingsByJob = {
        ...state.siteBriefingsByJob,
        [action.payload.jobId]: action.payload,
      };
    },
    fetchSiteBriefingError: (state: SiteBriefingState) => {
      state.isFetchingSiteBriefing = false;
    },
  },
});

export const {
  fetchSiteBriefing,
  fetchSiteBriefingSuccess,
  fetchSiteBriefingError,
} = siteBriefing.actions;

export const selectSiteBriefings = (
  state: RootState,
): { [key: number]: SiteBriefing } => state.siteBriefing.siteBriefingsByJob;
export const selectSiteBriefingsByJobId = createSelector(
  [selectSiteBriefings, (state: RootState, jobId: number) => jobId],
  (siteBriefings, jobId) => siteBriefings?.[jobId],
);
export const selectJobSiteBriefing = (state, jobId) =>
  selectSiteBriefingsByJobId(state, jobId);
export const selectIsFetchingSiteBriefing = (state: RootState): boolean =>
  state.siteBriefing.isFetchingSiteBriefing;

export const siteBriefingReducer = siteBriefing.reducer;

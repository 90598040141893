import { capitalize } from '@mui/material';
import {
  differenceInDays,
  differenceInHours,
  format,
  formatDistance,
  isBefore as isBeforeDate,
  isEqual,
  isSameDay as isDateFnsSameDay,
  secondsToHours,
  secondsToMinutes,
} from 'date-fns';
import * as Locales from 'date-fns/locale';
import { Languages } from 'utils/constants';

export const getLocale = () => {
  // e.g. en-GB or de-AT or sr-Latn or be-tarask
  const browserLocaleSplit = navigator.language.split('-');
  const rootLocale = browserLocaleSplit[0];
  const dateFnsLocale = browserLocaleSplit
    .map((fragment, index) => {
      if (index > 0) {
        return capitalize(fragment);
      }
      return fragment;
    })
    .join('');
  const appLanguage = window.localStorage.getItem('globalAppLanguage');

  return (
    Locales[dateFnsLocale] ??
    Locales[rootLocale] ??
    Locales[appLanguage] ??
    Locales.enGB
  );
};

const formatAppLocale = (appLocale) =>
  appLocale === Languages.english.locale ? Locales.enGB : Locales.es;

export const today = new Date();

export const todayString = today.toISOString();

export const yesterday = new Date(today - 86400000);

export const sevenDaysAgo = new Date(today - 7 * 24 * 60 * 60 * 1000);

export const sevenDaysAgoString = sevenDaysAgo.toISOString();

export const isSameDay = (date1, date2) =>
  date1 && date2 && isDateFnsSameDay(new Date(date1), new Date(date2));

export const isBefore = (date1, date2) =>
  date1 && date2 && isBeforeDate(new Date(date1), new Date(date2));

export const isSameOrBefore = (date1, date2) =>
  date1 &&
  date2 &&
  (isEqual(new Date(date1), new Date(date2)) ||
    isBefore(new Date(date1), new Date(date2)));

export const formatDateTime = (datetime, todayString, yesterdayString) => {
  if (!datetime) {
    return '';
  }
  const locale = getLocale();
  const separator = '•';
  const time = format(new Date(datetime).getTime(), 'p', {
    locale,
  });

  if (isSameDay(datetime, today) && todayString) {
    return `${time} ${separator} ${todayString}`;
  } else if (isSameDay(datetime, yesterday) && yesterdayString) {
    return `${time} ${separator} ${yesterdayString}`;
  } else {
    return format(new Date(datetime), `p ${separator} P`, {
      locale,
    });
  }
};

export const formatDateAndTime = (datetime) =>
  datetime
    ? format(new Date(datetime), 'Pp', {
        locale: getLocale(),
      })
    : '';

export const formatLongDateAndTime = (datetime) =>
  datetime
    ? format(new Date(datetime), 'PPP • p', {
        locale: getLocale(),
      })
    : '';

export const formatDate = (datetime) =>
  datetime
    ? format(new Date(datetime), 'P', {
        locale: getLocale(),
      })
    : '';

export const formatMonth = (date, appLocale = '') =>
  date
    ? format(new Date(date), 'MMMM', {
        locale: appLocale ? formatAppLocale(appLocale) : getLocale(),
      })
    : '';

export const formatDayAndMonth = (date) =>
  date
    ? new Date(date).toLocaleDateString(navigator.language, {
        month: 'numeric',
        day: 'numeric',
      })
    : '';

export const formatHavsDateRange = (dateRange) => {
  const startDate = dateRange.split(' - ')[0];
  const endDate = dateRange.split(' - ')[1];
  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

export const formatDateTimeWithTimezone = (datetime) =>
  datetime ? format(new Date(datetime), "yyyy-MM-dd'T'hh:mm:ssXXX") : '';

export const formatDateForAnalytics = (datetime) =>
  datetime ? format(new Date(datetime), 'yyyy-MM-dd') : '';

export const formatDateForDataExport = (datetime) =>
  datetime ? format(new Date(datetime), 'yyyy-MM-dd') : '';

export const formatDateForApi = (datetime) =>
  datetime ? format(new Date(datetime), 'yyyy-MM-dd') : '';

export const formatAutoWorkOrderId = () =>
  format(new Date(), 'hhmmssS-yy-MM-dd');

export const convertToHourAndMinute = (value) => {
  const hours = secondsToHours(value);
  const minutes = secondsToMinutes(value - hours * 60 * 60);
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${formattedMinutes}`;
};

export const getLastRefresh = () =>
  format(new Date(), 'Pp', {
    locale: getLocale(),
  });

export const getTimeSince = (datetime) => {
  const appLocale = JSON.parse(
    window.localStorage.getItem('globalAppLanguage'),
  );
  return datetime
    ? formatDistance(new Date(datetime), new Date(), {
        addSuffix: true,
        locale: formatAppLocale(appLocale),
      })
    : '';
};

export const getDaysBetween = (date1, date2) => {
  // if date2 is in the future, this returns a positive value
  if (!date1 || !date2) {
    return 0;
  }

  return differenceInDays(new Date(date2), new Date(date1));
};

export const getHoursBetween = (date1, date2) => {
  if (!date1 || !date2) {
    return 0;
  }

  return differenceInHours(new Date(date1), new Date(date2));
};

export const sortDates = (date1, date2) => {
  if ((date1 && date2 && date1 > date2) || (date1 && !date2)) {
    return 1;
  }
  if ((date1 && date2 && date1 < date2) || (!date1 && date2)) {
    return -1;
  }
  return 0;
};

import { Dates } from 'helpers';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import { useText } from 'hooks';
import { User } from 'model/User';
import React from 'react';
import { ExportButton } from 'components/views/Protected/Jobs/PdfExport/ExportButton';
import {
  CardContainer,
  CardDocumentContainer,
  CreationDate,
  CreationWrapper,
  Creator,
  DetailWrapper,
  DownloadIcon,
  EyeIcon,
  Icon,
  IconButton,
  IconWrapper,
  Subtitle,
  Title,
  TitleWrapper,
  TopSectionContainer,
} from './styled';
import {
  DownloadSource,
  DownloadType,
} from 'components/views/Protected/Jobs/PdfExport/constants';
import { CustomFormClass } from 'model/Form';

type Props = {
  title: string;
  subtitle: string;
  creator: User;
  date: string;
  to?: string;
  url?: string;
  IconComponent?: React.ElementType;
  jobId?: string | number;
  isLocked?: boolean;
  downloadItemId?: string;
  downloadType?: DownloadType;
  formClass?: CustomFormClass;
};

// Card.JobDetail
export const JobDetail = Analytics.withTrackEvent(
  ({
    title,
    subtitle,
    creator,
    date,
    to,
    url,
    IconComponent,
    jobId,
    isLocked = false,
    downloadItemId,
    downloadType,
    formClass,
    ...props
  }: Props): JSX.Element => {
    const getText = useText();

    const cardContent = (
      <>
        <IconWrapper $width={10}>
          {IconComponent && <Icon component={<IconComponent />} />}
        </IconWrapper>
        <DetailWrapper>
          <TopSectionContainer>
            <TitleWrapper>
              <Title>{title}</Title>
              <Subtitle>{subtitle}</Subtitle>
            </TitleWrapper>
            <IconWrapper $width={40}>
              {!isLocked && to && (
                <IconButton>
                  <EyeIcon />
                </IconButton>
              )}
              {!isLocked && url && (
                <IconButton>
                  <DownloadIcon />
                </IconButton>
              )}
              {!isLocked && jobId && downloadItemId && downloadType && (
                <ExportButton
                  type={downloadType}
                  itemId={downloadItemId}
                  jobId={jobId}
                  source={DownloadSource.JOB_OVERVIEW}
                  formClass={formClass}
                  backgroundColor={theme.colors.lightGrey}
                />
              )}
            </IconWrapper>
          </TopSectionContainer>
          <CreationWrapper>
            <Creator>{creator.fullName}</Creator>
            <CreationDate $marginLeft={10}>
              {Dates.formatDateTime(
                date,
                getText('date_today'),
                getText('date_yesterday'),
              )}
            </CreationDate>
          </CreationWrapper>
        </DetailWrapper>
      </>
    );
    if (url) {
      return (
        <CardDocumentContainer href={url} $startAligned {...props}>
          {cardContent}
        </CardDocumentContainer>
      );
    }
    return (
      <CardContainer to={to} startAligned {...props}>
        {cardContent}
      </CardContainer>
    );
  },
);

import { Button } from '@mui/material';
import {
  Arrow,
  Cross,
  Hazard,
  MarkerIcon,
  PlusIcon as Plus,
  Warning,
} from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const FlexContainer = styled.div`
  display: flex;
`;

export const ColumnContainer = styled.div`
  ${mixins.flexColumn};
`;

export const FlexEndContainer = styled.div`
  display: flex;
  align-items: end;
`;

export const SectionContentContainer = styled.div`
  height: 100%;
`;

export const Header = styled.div`
  ${mixins.flexBetween};
`;

export const HeaderWrapper = styled.div<{ $large: boolean }>`
  height: ${({ $large }) => ($large ? 15 : 10)}%;
`;

export const SectionContentWrapper = styled.div`
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  ${media.xl`
    padding: 15px;
  `}
`;

export const ModalContentWrapper = styled.div`
  ${mixins.flexColumn};
  height: 80vh;
  overflow: auto;
`;

export const ContentContainer = styled.div`
  display: flex;
  height: calc(80vh - 80px);
`;

export const FirstColumn = styled.div`
  flex-basis: 45%;
  min-width: 150px;
  transition: transform 0.2s ease-out;
  margin: 20px;
  ${media.xl`
    margin: 15px;
  `}
`;

export const SecondColumn = styled.div`
  flex-basis: 55%;
  min-width: 150px;
`;

export const VideoContainer = styled.div`
  height: 250px;
`;

export const LocationIcon = styled(MarkerIcon)`
  min-width: 15px;
  width: 15px;
  height: 15px;
  align-self: center;
  .fill-selector {
    fill: ${colors.darkGrey};
  }
`;

export const LocationContainer = styled.div`
  margin-top: 20px;
  ${mixins.flexCenter};
`;

export const LocationText = styled.span`
  font-family: ${fonts.light};
  color: ${colors.darkGrey};
  padding-left: 8px;
`;

export const IconContainer = styled(Button)`
  &.MuiButton-root {
    min-width: auto;
    color: ${colors.black};
    text-transform: none;
    font-size: ${fontSizes.default};
    border: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 15px;
    &:hover {
      box-shadow: none;
      border: 0;
      background-color: transparent;
    }
  }
`;

export const BackArrow = styled(Arrow)`
  height: 20px;
  width: 20px;
  transform: rotate(180deg);
  cursor: pointer;
  align-self: flex-start;
`;

export const Title = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxl};
  ${media.xxl`
    font-size: ${fontSizes.xl};
  `}
`;

export const Subtitle = styled.div`
  color: ${colors.darkGrey};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  padding-top: 5px;
  ${media.xxl`
    font-size: ${fontSizes.m};
  `}
`;

export const NotEditableTagWrapper = styled.div`
  height: 85%;
  align-content: end;
`;

export const MiddleContentContainer = styled.div<{ large: boolean }>`
  height: calc(${({ large }) => (large ? 80 : 72)}% - 24px);
  overflow-y: auto;
  padding: 5px 0;
  box-sizing: border-box;
`;

export const HazardStepContentContainer = styled.div`
  height: calc(75% - 24px);
  overflow-y: auto;
  padding: 5px 0;
  box-sizing: border-box;
`;

export const ActionButtonsWrapper = styled.div`
  height: 10%;
  margin-top: 24px;
`;

export const ActionButtons = styled.div`
  ${mixins.flexBetween};
  height: 100%;
  width: 100%;
`;

export const Divider = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  height: 5px;
  width: 100%;
  background-color: ${colors.lightGrey};
`;

export const HazardTagContainer = styled.div`
  background-color: ${colors.blue};
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 5px 15px;
  color: ${colors.white};
  font-size: ${fontSizes.m};
  border-radius: 5px;
`;

export const HazardIcon = styled(Hazard)`
  width: 40px;
  height: 40px;
  padding: 5px;
  border: 2px solid ${colors.black};
`;

export const ItemList = styled.div`
  flex: 1;
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding-right: 7px;
  ${media.sm`
    padding: 10px 15px 0 15px;
    margin-bottom: 0;
  `}
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.grey};
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const ItemListContainer = styled.div`
  margin-top: 5px;
`;

export const ListItemRow = styled(({ selected, ...rest }) => (
  <Button {...rest} />
))`
  &.MuiButton-root {
    width: 100%;
    min-height: 50px;
    background-color: ${({ selected }) =>
      selected ? colors.lightGrey : colors.black};
    padding: 0 15px;
    margin-bottom: 4px;
    border-radius: 3px;
    flex-shrink: 0;
    text-transform: none;
    font-family: ${fonts.bold};
    font-size: ${fontSizes.default};
    &:hover {
      background-color: ${({ selected }) =>
        selected ? colors.lightGrey : colors.orange};
    }
    ${mixins.flexBetween};
  }
`;

export const ItemTitle = styled.div<{ selected?: boolean }>`
  color: ${({ selected }) => (selected ? colors.black : colors.white)};
  font-family: ${fonts.bold};
  width: 85%;
  text-align: start;
`;

export const PlusIcon = styled(Plus)`
  height: 20px;
  width: 20px;
  margin-left: 15px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const CrossIcon = styled(Cross)`
  height: 20px;
  width: 20px;
  margin-left: 15px;
  .fill-selector {
    fill: ${colors.black};
  }
`;

export const SearchContainer = styled.div`
  min-height: 70px;
  margin: 3px;
`;

export const SearchSectionTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  margin-bottom: 10px;
  padding: 3px;
`;

export const NotEditableTagContainer = styled.div`
  ${mixins.flexBetween};
  height: 8%;
  padding: 3px 8px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
  color: ${colors.black};
  background-color: ${colors.orange};
  outline: 2px solid ${colors.orange};
  ${media.xl`
    font-size: ${fontSizes.s};
  `}
`;

export const WarningIcon = styled(Warning)`
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;
  padding-right: 8px;
  .fill-selector {
    fill: ${colors.black};
  }
  ${media.xl`
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
  `}
`;

export const AddToJobButton = styled(Button)`
  &.MuiButton-root {
    min-width: auto;
    color: ${colors.black};
    text-transform: none;
    font-size: ${fontSizes.default};
    text-decoration: underline ${colors.black};
    border: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 15px;
    &:hover {
      box-shadow: none;
      border: 0;
      > span {
        background-color: ${colors.yellow};
        text-decoration: underline ${colors.black};
      }
    }
  }
`;

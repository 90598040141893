import { Header, Icon, IconBox, Title } from '../styled';

type Props = {
  title: string;
  IconComponent: React.ElementType;
};

export const SectionHeader = ({ title, IconComponent }: Props): JSX.Element => (
  <Header>
    <Title>{title}</Title>
    <IconBox>
      <Icon component={<IconComponent />} />
    </IconBox>
  </Header>
);

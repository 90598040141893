import { useSafetyPrediction, useText } from 'hooks';
import styled from 'styled-components';
import theme from 'theme';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { Loader, Tabs, Map, Button } from 'components/ui';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import { useEffect, useMemo, useState } from 'react';
import { fetchActiveJob, resetActiveJob } from 'store/actions/jobs';
import { JobDetails } from './JobDetails';
import Coordinate from 'model/Coordinate';
import {
  Assessment,
  Hazard,
  Pencil,
  RequestDocuments,
  Evidence as EvidenceAndFormsIcon,
  Warning,
  WorkOrderItem,
  Note,
  Permit,
  Document as DocumentIcon,
  DeleteIcon,
  User,
} from 'assets/icons';
import {
  selectIsStreamline,
  selectJobsView,
  selectWorkspaceSettings,
} from 'store/selectors';
import { RequestFiles } from 'components/ui/Modal';
import { DeleteJob } from 'components/ui/Modal/DeleteJob';
import { setActiveJobTab } from 'store/actions/versions';
import DrawerJobHeader from './DrawerJobHeader';
import { PageSource } from 'components/ui/Map/constants';
import SkeletonMap from './Skeleton/SkeletonMap';
import { isEmptyView } from 'helpers/versions';
import { getMapMarkerIcon, getCoordinatesFromJob } from 'helpers/map';

const { colors, mixins } = theme;

const OverviewContainer = styled.div`
  background-color: ${colors.offWhite};
  border-left: 2px solid ${colors.black};
  ${mixins.flexColumn};
  height: 100%;
`;

const DetailsContent = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const DetailsContainer = styled.div`
  padding: 20px;
`;

const ListOverview = (): JSX.Element => {
  const navigate = useNavigate();
  const getText = useText();
  const location = useLocation();
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const { listJobs, mapJobs, activeJob, isFetchingActiveJob, isFetchingJobs } =
    useSelector((state: RootState) => state.jobs);
  const view = useSelector(selectJobsView);
  const isStreamline = useSelector(selectIsStreamline);
  const { permits_enabled } = useSelector(selectWorkspaceSettings);
  const { getRiskWarning, isFeedbackNeeded } = useSafetyPrediction();
  const [showRequestFiles, setShowRequestFiles] = useState<boolean>(false);
  const [showDeleteJob, setShowDeleteJob] = useState<boolean>(false);

  useEffect(() => {
    if (
      jobId &&
      (!activeJob || activeJob?.id?.toString() !== jobId) &&
      !isFetchingActiveJob
    ) {
      dispatch(fetchActiveJob(jobId, { updateAllJobs: false }));
    }
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isFetchingActiveJob &&
      activeJob &&
      isFeedbackNeeded(
        activeJob.user_review_psi_score_id,
        activeJob.safety_prediction_score,
      ) &&
      jobId &&
      (location?.pathname?.endsWith(jobId) ||
        location?.pathname?.endsWith(`${jobId}/timeline`))
    ) {
      navigate(`/jobs/${activeJob.id}/safety-review`);
    }
  }, [activeJob?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClose = () => {
    Analytics.trackEvent(constants.EVENT_JOB_DETAILS_CLOSE, {
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    });
    dispatch(resetActiveJob());
    navigate('/jobs');
    dispatch(setActiveJobTab(null));
  };

  const mapCoordinates: Coordinate[] | null = useMemo(() => {
    if (
      activeJob?.id?.toString() === jobId &&
      activeJob?.latitude &&
      activeJob?.longitude
    ) {
      return [getCoordinatesFromJob(activeJob, getRiskWarning)];
    }
    return null;
  }, [jobId, activeJob, getRiskWarning]);

  return (
    <OverviewContainer>
      <DrawerJobHeader title={activeJob?.job_number} onClose={onClose} />
      <Tabs.Desktop
        left={{
          text: getText('tabs_timeline'),
          to: `/jobs/${jobId}/timeline`,
          value: constants.TIMELINE,
          event: constants.EVENT_JOB_DETAILS_TIMELINE_TAB_CLICKED,
        }}
        right={{
          text: getText('tabs_job_overview'),
          to: `/jobs/${jobId}`,
          isActive: true,
          value: constants.JOB_OVERVIEW,
          event: constants.EVENT_JOB_DETAILS_OVERVIEW_TAB_CLICKED,
        }}
      />
      {isFetchingActiveJob ? (
        <Loader />
      ) : (
        activeJob &&
        activeJob?.id?.toString() === jobId && (
          <DetailsContent>
            {isEmptyView(view, listJobs, mapJobs) || isFetchingJobs ? (
              <SkeletonMap height='200px' />
            ) : (
              <Map
                id='job-list-overview-map'
                coordinates={mapCoordinates}
                getMapMarker={getMapMarkerIcon}
                height='200px'
                pageSource={PageSource.JOB_DETAILS_NEW}
              />
            )}
            <DetailsContainer>
              <JobDetails activeJob={activeJob} />
              <Button.JobActionButton
                IconComponent={Pencil}
                event={constants.EVENT_JOB_UPDATE_BUTTON}
                eventProperties={{
                  jobId,
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                text={getText('tabs_edit_job_details')}
                onClick={() => navigate(`/jobs/${jobId}/edit-details`)}
              />
              <Button.JobActionButton
                IconComponent={User}
                event={constants.EVENT_TEAM_MEMBERS_BUTTON}
                eventProperties={{
                  jobId,
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                text={getText('tabs_team_members')}
                onClick={() => navigate(`/jobs/${jobId}/team-members`)}
              />
              {!isStreamline && (
                <Button.JobActionButton
                  IconComponent={RequestDocuments}
                  event={constants.EVENT_JOB_REQUEST_FILES}
                  eventProperties={{
                    jobId,
                    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  }}
                  text={getText('tabs_request_files')}
                  onClick={() => setShowRequestFiles(true)}
                />
              )}
              <Button.JobActionButton
                IconComponent={Hazard}
                event={constants.EVENT_VIEWED_HAZARDS_CONTROLS_LIST}
                eventProperties={{
                  jobId,
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                text={getText('tabs_hazards_and_controls')}
                onClick={() => navigate(`/jobs/${jobId}/hazards-controls`)}
              />
              <Button.JobActionButton
                IconComponent={Assessment}
                event={constants.EVENT_VIEWED_RISK_ASSESSMENTS_LIST}
                eventProperties={{
                  jobId,
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                text={getText('tabs_risk_assessments')}
                onClick={() => navigate(`/jobs/${jobId}/assessments`)}
              />
              <Button.JobActionButton
                IconComponent={EvidenceAndFormsIcon}
                event={constants.EVENT_VIEWED_JOB_EVIDENCE_LIST}
                eventProperties={{
                  jobId,
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                text={getText('tabs_evidence')}
                onClick={() => navigate(`/jobs/${jobId}/evidence`)}
              />
              <Button.JobActionButton
                IconComponent={EvidenceAndFormsIcon}
                text={getText('tabs_forms')}
                onClick={() => navigate(`/jobs/${jobId}/job-forms`)}
              />
              <Button.JobActionButton
                IconComponent={Warning}
                event={constants.EVENT_VIEWED_JOB_BLOCKER_LIST}
                eventProperties={{
                  jobId,
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                text={getText('tabs_job_blockers')}
                onClick={() => navigate(`/jobs/${jobId}/blockers`)}
                disabled={isStreamline}
              />
              {!isStreamline && (
                <>
                  <Button.JobActionButton
                    IconComponent={WorkOrderItem}
                    event={constants.EVENT_VIEWED_WORK_COMPLETED_LIST}
                    eventProperties={{
                      jobId,
                      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                    }}
                    text={getText('tabs_work_completed')}
                    onClick={() => navigate(`/jobs/${jobId}/work-completed`)}
                  />

                  <Button.JobActionButton
                    IconComponent={Note}
                    event={constants.EVENT_VIEWED_LOG_LIST}
                    eventProperties={{
                      jobId,
                      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                    }}
                    text={getText('tabs_logs')}
                    onClick={() => navigate(`/jobs/${jobId}/logs`)}
                  />
                </>
              )}
              {permits_enabled && (
                <Button.JobActionButton
                  IconComponent={Permit}
                  event={constants.EVENT_VIEWED_PERMITS_LIST}
                  eventProperties={{
                    jobId,
                    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  }}
                  text={getText('tabs_permits')}
                  onClick={() => navigate(`/jobs/${jobId}/permits`)}
                />
              )}
              <Button.JobActionButton
                IconComponent={DocumentIcon}
                event={constants.EVENT_VIEWED_DOCUMENTS_LIST}
                eventProperties={{
                  jobId,
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                text={getText('tabs_documents')}
                onClick={() => navigate(`/jobs/${jobId}/documents`)}
                disabled={isStreamline}
              />
              <Button.JobActionButton
                IconComponent={DeleteIcon}
                event={constants.EVENT_DELETE_JOB_BUTTON}
                eventProperties={{
                  jobId,
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                text={getText('tabs_delete_job')}
                onClick={() => setShowDeleteJob(true)}
              />
            </DetailsContainer>
          </DetailsContent>
        )
      )}
      {showRequestFiles && (
        <RequestFiles
          showModal={showRequestFiles}
          setShowModal={setShowRequestFiles}
        />
      )}
      {showDeleteJob && (
        <DeleteJob showModal={showDeleteJob} setShowModal={setShowDeleteJob} />
      )}
    </OverviewContainer>
  );
};

export default ListOverview;

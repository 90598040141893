import { mapToUser } from 'api/User/helpers';
import { FormDetail } from './Forms/FormDetail';
import { LastApproval } from './Forms/LastApproval';
import { Photo } from './Media/Photo';
import { Video } from './Media/Video';
import { User } from './User';
import { mapToForm } from 'api/JobForms/helpers';
import { mapToPhoto, mapToVideo } from 'api/Media/helpers';

export type Form = {
  id: number;
  uuid: string | null;
  jobId: string;
  type: FormType;
  createdBy: User;
  createdAt: Date;
  sentAt: Date | null;
  updatedAt: Date | null;
  photos: Photo[];
  videos: Video[];
  form: FormDetail;
  lastApproval: LastApproval | null;
  thumbnail: string | undefined;
  comments: Comment[];
};

type FormType = {
  id: number;
  title: string;
};

export type Comment = {
  id: number | null;
  comment: string;
  userForm: number | null;
  createdBy: User;
  createdAt: Date | null;
  updatedAt: Date | null;
};

export enum CustomFormClass {
  JOB = 'job',
  USER = 'user',
  PERMIT = 'permit',
}

const mapToCustomForm = (backendForm: any): Form => ({
  id: backendForm.id,
  uuid: backendForm.uuid,
  jobId: backendForm.job_id,
  type: { id: backendForm.type.id, title: backendForm.type.title },
  createdBy: mapToUser(backendForm.created_by),
  createdAt: new Date(backendForm.created_at),
  sentAt: backendForm.sent_at ? new Date(backendForm.sent_at) : null,
  updatedAt: backendForm.updated_at ? new Date(backendForm.updated_at) : null,
  photos: backendForm.photos ? backendForm.photos.map(mapToPhoto) : [],
  videos: backendForm.videos ? backendForm.videos.map(mapToVideo) : [],
  form: mapToForm(backendForm.form),
  lastApproval: backendForm.last_approval
    ? mapToLastApproval(backendForm.last_approval)
    : null,
  thumbnail: backendForm.thumbnail ?? undefined,
  comments: backendForm?.comments ? backendForm.comments.map(mapToComment) : [],
});

const mapToLastApproval = (backendLastApproval: any): LastApproval => ({
  id: backendLastApproval.id,
  permitId: backendLastApproval.permit_id,
  isApproved: backendLastApproval.is_approved,
  rejectionNote: backendLastApproval.rejection_note ?? '',
  expiryDate: backendLastApproval.expiry_date
    ? new Date(backendLastApproval.expiry_date)
    : null,
  sentAt: backendLastApproval.sent_at
    ? new Date(backendLastApproval.sent_at)
    : null,
  createdAt: new Date(backendLastApproval.created_at),
  createdBy: mapToUser(backendLastApproval.created_by),
});

const mapToComment = (backendComment: any): Comment => ({
  id: backendComment?.id ?? null,
  comment: backendComment.comment,
  userForm: backendComment.user_form ?? null,
  createdBy: mapToUser(backendComment.created_by),
  createdAt: backendComment.created_at
    ? new Date(backendComment.created_at)
    : null,
  updatedAt: backendComment.updated_at
    ? new Date(backendComment.updated_at)
    : null,
});

export const Forms = { mapToCustomForm, mapToComment };

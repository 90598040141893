import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ChevronIcon,
  SubTitle,
} from './styled';

type Props = {
  title: string | JSX.Element;
  subTitle?: string;
  children: JSX.Element | null;
  defaultActive?: boolean;
  isLight?: boolean;
  isFyldVision?: boolean;
  extraStyles?: React.CSSProperties;
};

// Display.Collapsible
export const Collapsible = ({
  title,
  subTitle,
  children,
  defaultActive = false,
  isLight,
  isFyldVision,
  extraStyles,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(defaultActive);

  const onChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      style={extraStyles}
      disableGutters
    >
      <AccordionSummary
        isLight={isLight}
        isFyldVision={isFyldVision}
        expandIcon={<ChevronIcon size='20px' />}
      >
        <span>{title}</span>
        {subTitle ? <SubTitle>{subTitle}</SubTitle> : null}
      </AccordionSummary>
      <AccordionDetails isFyldVision={isFyldVision}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

import { ClockIcon } from 'assets/icons';
import { useText } from 'hooks';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Button as MaterialButton } from '@mui/material';

const { colors, fonts, fontSizes, media, mixins } = theme;

// Styled components
const StatContainer = styled(({ clickable, selected, ...rest }) => (
  <MaterialButton {...rest} />
))`
  &.MuiButtonBase-root {
    background-color: ${colors.white};
    outline: 2px solid ${colors.grey};
    border-bottom: 4px solid ${colors.grey};
    border-radius: 0;
    text-transform: none;
    width: 22%;
    height: fit-content;
    min-height: 100%;
    margin-right: 4%;
    &:last-of-type {
      margin: 0;
    }
    position: relative;
    padding: 10px 25px 6px 15px;
    ${mixins.flexColumn};
    justify-content: space-between;
    align-items: start;

    ${media.xl`
      width: 22.75%;
      margin-right: 3%;
      padding: 5px 10px;
    `}
    ${({ selected }) =>
      selected &&
      `
      outline: 2px solid ${colors.black};
      border-bottom: 4px solid ${colors.black};
      background-color: ${colors.black} !important;
      > div div, > div span {
        color: ${colors.white};
      }
    `}
    ${({ clickable }) =>
      clickable &&
      `
      cursor: pointer;
      &:hover {
        outline: 2px solid ${colors.black};
        border-bottom: 4px solid ${colors.black};
        background-color: ${colors.white};
      }
    `}
  }
`;

const ActionButtonContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  ${media.xl`
    top: 5px;
    right: 5px;
  `}
`;

const ActionButtonIcon = styled(({ selected, component, ...props }) =>
  React.cloneElement(component, props),
)`
  width: 30px;
  ${({ selected }) =>
    selected
      ? `.icon-inside {
        fill: ${colors.white};

      }
      .icon-inside-inverted {
        fill: ${colors.black}
      }
      `
      : `.icon-inside {
        fill: ${colors.black};
     }
     .icon-inside-inverted {
      fill: ${colors.white}
    }
  `}
  ${media.xl`
    top: 8px;
    right: 8px;
    width: 26px;
  `}
  ${media.md`
    top: 6px;
    right: 6px;
    width: 24px;
  `}
`;

const TextContainer = styled.div`
  ${mixins.flexColumn};
  justify-content: space-between;
  align-items: start;
  line-height: normal;
`;

const StatNumber = styled.div`
  color: ${colors.black};
  font-size: ${fontSizes.xxxl};
  line-height: ${fontSizes.xxxl};
  font-family: ${fonts.bold};
  ${media.xl`
    font-size: ${fontSizes.xxl};
    line-height: ${fontSizes.xxl};
  `}
  ${media.md`
    font-size: ${fontSizes.xl};
    line-height: ${fontSizes.xl};
  `}
`;

const StatText = styled.div`
  color: ${colors.black};
  font-size: ${fontSizes.default};
  font-family: ${fonts.bold};
  text-align: start;
  ${media.xl`
    font-size: ${fontSizes.default};
  `}
  ${media.md`
    font-size: ${fontSizes.s};
  `}
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0;
`;

const Clock = styled(ClockIcon)<{ selected?: boolean }>`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  .fill-selector {
    fill: ${({ selected }) => (selected ? colors.white : colors.darkGrey)};
  }
`;

const PastHoursText = styled.div`
  color: ${colors.darkGrey};
  font-family: ${fonts.default};
  font-size: ${fontSizes.s};
  line-height: ${fontSizes.s};
  ${media.md`
    font-size: ${fontSizes.xs};
    line-height: ${fontSizes.xs};
  `}
`;

type Props = {
  IconComponent: React.ElementType;
  number: number;
  text: string;
  selected?: boolean;
  onClick?: () => void;
  className?: string;
};

const StatusCard = ({
  IconComponent,
  number,
  text,
  selected = false,
  onClick,
  className,
}: Props): JSX.Element => {
  const getText = useText();
  return (
    <StatContainer
      clickable={!!onClick}
      selected={selected}
      onClick={onClick}
      className={className}
    >
      <ActionButtonContainer>
        <ActionButtonIcon selected={selected} component={<IconComponent />} />
      </ActionButtonContainer>
      <TextContainer>
        <StatNumber>{number}</StatNumber>
        <StatText>{text}</StatText>
      </TextContainer>
      <TimeContainer>
        <Clock selected={selected} />
        <PastHoursText>
          {getText('jobs_stats_past_hours', { hours: 24 })}
        </PastHoursText>
      </TimeContainer>
    </StatContainer>
  );
};

export default StatusCard;

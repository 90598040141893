import { Stack } from '@mui/material';
import { StyledPagination } from './styled';

type Props = {
  count: number;
  page: number;
  onChange: (page: number) => void;
  extraStyles?: React.CSSProperties;
};

// Display.Pagination
export const Pagination = ({ count, page, onChange, extraStyles }: Props) => (
  <Stack spacing={2}>
    <StyledPagination
      count={count}
      onChange={(_, page) => onChange(page)}
      page={page}
      variant='outlined'
      shape='rounded'
      style={extraStyles}
    />
  </Stack>
);

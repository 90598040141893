import { Navbar } from 'components/ui';
import { useText } from 'hooks';
import styled from 'styled-components';
import theme from 'theme';

const { fonts, fontSizes, media } = theme;

const H1 = styled.h1`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  margin-bottom: 8px !important;
`;

const H4 = styled.h4`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
`;

const Wrapper = styled.div`
  padding: 40px 0 0;
  height: 100%;
  position: relative;
  ${media.sm`
    padding: 30px;
 `}
`;

const FatigueManagerMobile = () => {
  const getText = useText();
  return (
    <>
      <Navbar home mobileOnly />
      <Wrapper>
        <H1>{getText('fatigue_manager')}</H1>
        <H4>{getText('fatigue_manager_only_desktop')}</H4>
      </Wrapper>
    </>
  );
};

export default FatigueManagerMobile;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTab, selectViewVersion } from 'store/selectors';
import {
  LIST_VIEW,
  LIST_VIEW_TAB,
  LEGACY_VIEW,
  MAP_VIEW_TAB,
  URL_TAB_QUERY_PARAM_FIELD,
} from 'utils/constants';
import useQueryParams from './useQueryParams';
import { setJobsTab } from 'store/actions/versions';

type QueryParamProps = {
  tab?: string;
};

export default () => {
  const { params }: { params: QueryParamProps } = useQueryParams();
  const tabFromURL = params?.[URL_TAB_QUERY_PARAM_FIELD];

  const [tabParam, setTabParam] = useState(tabFromURL ?? '');
  const dispatch = useDispatch();

  const viewVersion = useSelector(selectViewVersion);
  const tab = useSelector(selectTab);

  useEffect(() => {
    if (!tab && tabFromURL) {
      dispatch(setJobsTab(tabFromURL));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (viewVersion === LEGACY_VIEW) {
      if (tabParam) {
        setTabParam('');
      }
    } else if (viewVersion === LIST_VIEW) {
      if (tab && tabFromURL !== tab) {
        setTabParam(tab);
      } else if (
        tabFromURL &&
        !(tabFromURL === MAP_VIEW_TAB || tabFromURL === LIST_VIEW_TAB)
      ) {
        setTabParam('');
      }
    }
  }, [viewVersion, tab, tabFromURL]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    tab,
    tabParam,
  };
};

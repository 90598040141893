import { Dispatch, SetStateAction } from 'react';
import { useText } from 'hooks';
import { Button, Modal } from 'components/ui';
import { ButtonContainer, ModalContainer, Title } from './styled';

type Props = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
};

export const AssessmentWithoutHazardModal = ({
  isVisible,
  setIsVisible,
}: Props): JSX.Element | null => {
  const getText = useText();

  return (
    <Modal.Small
      isOpen={isVisible}
      onClose={() => setIsVisible(false)}
      topMargin={25}
    >
      <ModalContainer>
        <Title>
          {getText('workspace_settings_risk_assessment_no_hazard_info_title')}
        </Title>
        <div>
          {getText('workspace_settings_risk_assessment_no_hazard_info_message')}
        </div>
        <ButtonContainer>
          <Button.Confirm
            onClick={() => setIsVisible(false)}
            text={getText('continue_cta')}
            width='130px'
          />
        </ButtonContainer>
      </ModalContainer>
    </Modal.Small>
  );
};

import theme from 'theme';
import {
  DataGrid as MuiDataGrid,
  GridColDef,
  GridSortModel,
} from '@mui/x-data-grid';
import styled from 'styled-components';

import { memo } from 'react';

import { Pagination } from 'components/ui/Display';
import Sort from 'model/Sort';
import { getSortModel } from 'helpers/table';

import { Loader } from 'components/ui';
import { useText } from 'hooks';

const { colors, fonts, fontSizes, media, mixins } = theme;

const StyledDataGrid = styled(({ isSkeleton, columns, ...rest }) => (
  <MuiDataGrid columns={columns} {...rest} />
))`
  &.MuiDataGrid-root {
    border: 0;
    background-color: ${colors.white};
    .MuiDataGrid-columnHeaders {
      color: ${colors.black};
      border-bottom: 2px solid ${colors.black};
      font-family: ${fonts.bold};
      width: 100%;
      > div {
        width: 100%;
      }
      ${media.md`
        font-size: ${fontSizes.xs};
      `}
    }
    .MuiDataGrid-columnHeader {
      padding: 0 5px;
      background-color: ${colors.offWhite};
      &:focus,
      :focus-within {
        outline: 0;
      }
    }
    .MuiDataGrid-columnHeader--sortable .MuiDataGrid-sortIcon {
      opacity: 1 !important;
    }
    .MuiDataGrid-iconButtonContainer {
      visibility: visible;
      width: auto;
      .MuiIconButton-root {
        ${media.md`
        padding: 0;
      `}
      }
    }
    .MuiDataGrid-columnHeader--sorted,
    .MuiDataGrid-columnHeader--sorted .MuiIconButton-root {
      color: ${colors.blue};
    }
    .MuiDataGrid-columnHeader.open-button-cell
      .MuiDataGrid-columnHeaderTitleContainer {
      align-items: end;
      justify-content: end;
    }
    .MuiDataGrid-columnHeaderTitleContainer {
      white-space: unset;
    }
    .MuiDataGrid-columnHeaderTitle {
      white-space: unset;
      word-break: break-word;
      line-height: normal;
    }
    .MuiDataGrid-row {
      box-sizing: border-box;
      border-bottom: 1px solid ${colors.grey};
      width: 100%;
      min-height: 80px !important;
      padding: 5px 0;
      position: relative;
      &:hover {
        cursor: pointer;
        ${({ isSkeleton }) =>
          isSkeleton &&
          `
          cursor: default;
          background-color: ${colors.white};
        `}
      }
    }

    .MuiDataGrid-cell:focus,
    .MuiDataGrid-cell:focus-within,
    .MuiDataGrid-cell:focus-visible {
      outline: none;
    }

    .MuiDataGrid-cell {
      color: ${colors.darkGrey};
      font-size: ${fontSizes.default};
      font-family: ${fonts.default};
      border: 0;
      padding: 0 5px;
      ${mixins.flexCenter};

      &.fieldworker-cell {
        color: ${colors.black};
        font-family: ${fonts.bold};
        padding: 0;
      }
      ${media.xl`
        font-size: ${fontSizes.s};
      `}
      ${media.md`
        font-size: ${fontSizes.xs};
      `}

      &.is-pinned-cell {
        > svg {
          .fill-selector {
            stroke: ${colors.blue};
          }
        }
        > svg.filled {
          .fill-selector {
            fill: ${colors.blue};
            stroke: ${colors.blue};
          }
        }
      }
    }

    .MuiDataGrid-row.Mui-selected,
    .MuiDataGrid-row.Mui-selected:hover {
      background-color: ${colors.offWhite};
    }

    .is-pinned-cell {
      min-width: 7.5% !important;
      max-width: 7.5% !important;

      > svg {
        margin-left: 10px;
      }
    }
    .fieldworker-cell {
      min-width: 15% !important;
      max-width: 15% !important;
    }
    .action-cell {
      min-width: 20% !important;
      max-width: 20% !important;
      &.MuiDataGrid-columnHeader {
        min-width: 19.5% !important;
        max-width: 19.5% !important;
      }
    }
    .last-activity-cell {
      min-width: 11.5% !important;
      max-width: 11.5% !important;
    }
    .location-cell {
      min-width: 16% !important;
      max-width: 16% !important;
    }
    .work-order-number-cell {
      min-width: 11.5% !important;
      max-width: 11.5% !important;
    }
    .status-cell {
      min-width: 9.5% !important;
      max-width: 9.5% !important;

      &.MuiDataGrid-columnHeader {
        min-width: 5.5% !important;
        max-width: 5.5% !important;
      }
    }
    .open-button-cell {
      min-width: 9% !important;
      max-width: 9% !important;
      ${mixins.flexFullCenter};
      padding: 0;

      &.MuiDataGrid-columnHeader {
        min-width: 13.5% !important;
        max-width: 13.5% !important;
      }
    }
    .MuiDataGrid-withBorder {
      border: 0;
    }
    .MuiDataGrid-virtualScrollerRenderZone {
      width: 100%;
    }
    .MuiDataGrid-scrollbar {
      overflow-x: hidden;
      overflow-y: auto !important;
      background-color: ${colors.white};
      border-top: 1px solid ${colors.black};

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 12px;
      }
      ::-webkit-scrollbar-thumb {
        min-height: 80px;
        background-color: ${colors.grey};
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
    .MuiDataGrid-iconSeparator {
      display: none;
    }
  }
`;

const EmptyTableContainer = styled.div`
  padding: 25px;
  z-index: 9;
  position: relative;
`;

const Title = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 8px !important;
`;

const Subtitle = styled.p`
  font-size: ${fontSizes.m};
`;

const LoaderContainer = styled.div`
  background-color: ${colors.white};
  height: 100%;
  z-index: 100;
`;

const Container = styled.div`
  flex: 1 1 auto;
  height: 500px;
`;

const FooterWrapper = styled.div`
  background-color: ${colors.white};
  ${mixins.flexColumn};
  justify-content: end;
  align-items: end;
  padding: 5px 10px;
`;

const EndText = styled.div`
  color: ${colors.darkGrey};
  font-family: ${fonts.bold};
  padding-bottom: 5px;
`;

type Props = {
  isLoading: boolean;
  rows?: any[];
  columns: GridColDef<any, any, any>[];
  onPageChange?: (page: number) => void;
  sort: Sort | null;
  onSortChange?: (value: Sort) => void;
  total: number;
  pageSize: number;
  page: number;
  onRowClick?: ({ row }: { row: any }) => void;
  onCellClick?: (anything, event) => void;
  title: string;
  subtitle: string;
  isSkeleton?: boolean;
};

const DataGrid = ({
  isLoading,
  isSkeleton = false,
  rows,
  columns,
  onPageChange,
  sort,
  onSortChange,
  total,
  pageSize,
  page,
  onRowClick,
  onCellClick,
  title,
  subtitle,
}: Props): JSX.Element => {
  const getText = useText();
  const count = Math.ceil(total / pageSize);
  const handleSortChange = (newSort) => {
    if (
      sort &&
      onSortChange &&
      JSON.stringify(newSort) !== JSON.stringify(sort)
    ) {
      onSortChange(getSortModel(sort, newSort));
    }
  };
  const isEndTextVisible = page === count && count > 1;

  return (
    <Container>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        onRowClick={onRowClick}
        onCellClick={onCellClick}
        getRowHeight={() => 'auto'}
        hideFooterPagination
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableExtendRowFullWidth
        isSkeleton={isSkeleton}
        rowsPerPageOptions={[]}
        sortingOrder={['desc', 'asc']}
        sortingMode='server'
        sortModel={
          sort
            ? [
                {
                  field: sort?.field,
                  sort: sort?.order,
                },
              ]
            : []
        }
        onSortModelChange={(model: GridSortModel) => handleSortChange(model[0])}
        slots={{
          loadingOverlay: () => (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ),
          noRowsOverlay: () => (
            <EmptyTableContainer>
              <Title>{title}</Title>
              <Subtitle>{subtitle}</Subtitle>
            </EmptyTableContainer>
          ),
          footer: () => {
            return (
              <FooterWrapper>
                {!isLoading && (
                  <>
                    {isEndTextVisible && (
                      <EndText>{getText('jobs_search_adjust_filters')}</EndText>
                    )}
                    {count > 1 && onPageChange && (
                      <Pagination
                        count={count}
                        page={page}
                        onChange={onPageChange}
                        extraStyles={{ padding: 0 }}
                      />
                    )}
                  </>
                )}
              </FooterWrapper>
            );
          },
        }}
      />
    </Container>
  );
};

export default memo(DataGrid);

import {
  CLEAR_FORM_OPTIONS,
  FETCH_FORM_OPTIONS,
  SET_FORM_OPTIONS,
} from './actionTypes';

export const fetchFormOptions = () => ({
  type: FETCH_FORM_OPTIONS,
});

export const setFormOptions = (formOptions) => ({
  type: SET_FORM_OPTIONS,
  formOptions,
});

export const clearFormOptions = () => ({
  type: CLEAR_FORM_OPTIONS,
});

import styled from 'styled-components';
import { Button } from '@mui/material';
import { Arrow } from 'assets/icons';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins } = theme;

export const StyledLinkButton = styled(Button)`
  &.MuiButton-root {
    color: ${colors.black};
    text-transform: none;
    font-size: ${fontSizes.default};
    font-family: ${fonts.default};
    height: 55px;
    border: 0;
    border-radius: 0;
    padding: 0 15px;
    outline: 2px solid ${colors.black};
    ${mixins.flexBetween};
    &:hover {
      box-shadow: none;
      border: 0;
      outline: 3px solid ${colors.black};
      background-color: ${colors.white};
      transition: ease-out 0.2s;
    }
    &:focus-within {
      outline: 3px solid ${colors.orange};
    }
  }
`;

export const ArrowIcon = styled(Arrow)`
  height: 22px;
  width: 22px;
`;

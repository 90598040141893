import { WarningTag } from 'components/ui/Tags/WarningTag';
import WorkspaceAssessmentItem from 'model/State/WorkspaceAssessmentItem';
import { InfoText } from '../styled';
import { useText } from 'hooks';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ChevronIcon,
  Container,
  HazardListContainer,
  HazardTitleWrapper,
  ReviewTitle,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import {
  selectHazards,
  selectIsFetchingHazards,
  selectIsLoadingLinkingHazard,
} from 'store/selectors/settings/hazards';
import ItemTitle from '../../HazardSettings/components/ItemTitle';
import { Input, Loader } from 'components/ui';
import { useState } from 'react';
import { ControlRow } from './ControlRow';
import { changeHazardLinkedToAssessment } from 'store/actions/settings/hazards';
import * as Analytics from 'utils/analytics';
import {
  EVENT_ASSESSMENT_TYPE_EDIT_HAZARDS,
  HazardStatus,
} from '../../../constants';

type Props = {
  assessment: WorkspaceAssessmentItem;
};

export const HazardsReview = ({ assessment }: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const hazards = useSelector(selectHazards(selectedWorkspace?.uuid));
  const isFetchingHazards = useSelector(selectIsFetchingHazards);
  const isLoadingLinkingHazard = useSelector(selectIsLoadingLinkingHazard);
  const [openedList, setOpenedList] = useState<string[]>([]);

  const isExpanded = (rowKey: string) => openedList.includes(rowKey) ?? false;

  const isHazardAssigned = (hazardId) =>
    assessment?.hazards?.includes(hazardId);

  const handleExpandChange = (rowKey) => {
    if (isExpanded(rowKey)) {
      setOpenedList((prevList) => [
        ...prevList.filter((item) => item !== rowKey),
      ]);
    } else {
      setOpenedList((prevList) => [...prevList, rowKey]);
    }
  };

  const onHazardAssignmentChange = (hazard, isChecked) => {
    const modifiedHazard = { ...hazard };
    if (isChecked) {
      modifiedHazard.assessmentTypeList = [
        ...modifiedHazard.assessmentTypeList,
        assessment?.id,
      ];
    } else {
      modifiedHazard.assessmentTypeList =
        modifiedHazard.assessmentTypeList.filter(
          (assessmentType: number) => assessmentType !== assessment?.id,
        );
    }
    dispatch(
      changeHazardLinkedToAssessment(
        selectedWorkspace?.uuid,
        assessment?.id,
        modifiedHazard,
      ),
    );
    Analytics.trackEvent(EVENT_ASSESSMENT_TYPE_EDIT_HAZARDS, {
      assessmentTypeId: assessment?.id,
      assessmentType: assessment,
      hazardId: modifiedHazard?.id,
      hazardName: modifiedHazard?.title,
      status: isChecked ? HazardStatus.LINKED : HazardStatus.UNLINKED,
    });
  };

  return (
    <Container>
      {isFetchingHazards ? (
        <Loader />
      ) : (
        <>
          <ReviewTitle>
            {getText(
              'workspace_settings_risk_assessment_review_hazards_controls',
            )}
          </ReviewTitle>
          {assessment?.hazards?.length < 1 && (
            <WarningTag
              text={getText(
                'workspace_settings_risk_assessment_review_hazards_controls_warning',
              )}
              extraStyles={{ width: 'fit-content', marginBottom: '30px' }}
            />
          )}
          <InfoText $bold>
            {getText(
              'workspace_settings_risk_assessment_hazards_controls_assigned',
              {
                number: assessment?.hazards?.length,
              },
            )}
          </InfoText>
          <InfoText>
            {getText(
              'workspace_settings_risk_assessment_hazards_controls_assigned_info',
            )}
          </InfoText>
          <HazardListContainer>
            {hazards.map((hazard) => (
              <Accordion
                expanded={isExpanded(`hazard-${hazard.id}`)}
                key={`hazard-${hazard.id}`}
              >
                <AccordionSummary
                  expandIcon={
                    <ChevronIcon
                      onClick={() => handleExpandChange(`hazard-${hazard.id}`)}
                    />
                  }
                >
                  <HazardTitleWrapper>
                    <ItemTitle
                      item={hazard}
                      disabled={!isHazardAssigned(hazard.id)}
                    />
                    <Input.Checkbox
                      checked={isHazardAssigned(hazard.id)}
                      onChange={(e) =>
                        onHazardAssignmentChange(hazard, e.target.checked)
                      }
                      isSmall
                      disabled={isLoadingLinkingHazard?.[hazard?.id] ?? false}
                    />
                  </HazardTitleWrapper>
                </AccordionSummary>
                <AccordionDetails>
                  {isExpanded(`hazard-${hazard.id}`) && (
                    <ControlRow
                      hazardId={hazard.id}
                      disabled={!isHazardAssigned(hazard.id)}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </HazardListContainer>
        </>
      )}
    </Container>
  );
};

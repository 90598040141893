import { useDispatch, useSelector } from 'react-redux';
import ItemTitle from '../../../HazardSettings/components/ItemTitle';
import { useEffect } from 'react';
import RootState from 'model/State/RootState';
import { fetchWorkspaceControlsForHazard } from 'store/actions/settings/controls';
import {
  selectControlsForHazard,
  selectIsFetchingControlsForHazard,
} from 'store/selectors/settings/controls';
import { Loader } from 'components/ui';
import { Container } from './styled';

type Props = {
  hazardId: number;
  disabled: boolean;
};

export const ControlRow = ({ hazardId, disabled }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const controls = useSelector(selectControlsForHazard(hazardId));
  const isFetchingControlsForHazard = useSelector(
    selectIsFetchingControlsForHazard(hazardId),
  );

  useEffect(() => {
    if (selectedWorkspace?.uuid && hazardId) {
      dispatch(
        fetchWorkspaceControlsForHazard(selectedWorkspace?.uuid, hazardId),
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isFetchingControlsForHazard ? (
        <Loader />
      ) : (
        controls.map((control) => (
          <Container key={`control-${control.id}`}>
            <ItemTitle item={control} disabled={disabled} />
          </Container>
        ))
      )}
    </>
  );
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as constants from 'utils/constants';
import { useJobs, useTeams, useText } from 'hooks';
import { Button, Input, Loader } from 'components/ui';
import theme from 'theme';
import { ClearButton } from 'components/ui/Button';
import RootState from 'model/State/RootState';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackendUser } from 'model/BackendUser';
import { ModalType } from 'model/enum/ModalType';
import {
  selectActiveWorkspaceUuid,
  selectCurrentUserId,
  selectFormOptions,
  selectIsFetchingFormOptions,
  selectJobsView,
} from 'store/selectors';
import { TeamSelect } from 'components/ui/Filter/TeamSelect';
import { Team, Team as TeamIcon } from 'assets/icons';
import { formatMembersSelected } from 'helpers/names';
import { UserRoleBackend } from 'model/enum/UserRoleBackend';
import { getScreen } from 'helpers/utils';
import { formatDateForAnalytics } from 'helpers/dates';
import { fetchFormOptions } from 'store/actions/form';

const { colors, media, mixins, fontSizes } = theme;

// Styled Components
const FormContainer = styled.div`
  width: 100%;
  height: 80vh;
  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
const Form = styled.form`
  width: 425px;
  margin: 20px auto;
  ${mixins.flexColumn};
  ${media.sm`
    height: auto;
    flex: 1;
    width: auto;
    margin: 0;
  `}
`;

const InputsContainer = styled.div`
  flex: 1;
  ${media.sm`
    margin: 25px 15px;
  `}
  ${mixins.flexColumn};
  row-gap: 15px;
`;

const ManageTeamsContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const ErrorMessage = styled.div`
  color: ${colors.red};
  margin: 10px 0;
`;

type FilterPropTypes = {
  type: number | null;
  subtype: number | null;
  status: number | null;
  depot: number | null;
  assignees: BackendUser[] | null;
  assessmentType: number | null;
  hazard: number | null;
  daterange: [Date | null, Date | null] | null;
};

// SearchFilters
export const SearchFilters = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const getText = useText();
  const view = useSelector(selectJobsView);

  const currentUserId = useSelector(selectCurrentUserId);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);
  const { filters } = useSelector((state: RootState) => state.jobs);
  const formOptions = useSelector(selectFormOptions);
  const isFetchingFormOptions = useSelector(selectIsFetchingFormOptions);
  const { setFilters } = useJobs();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!formOptions && !isFetchingFormOptions) {
      dispatch(fetchFormOptions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOptions, isFetchingFormOptions]);

  // Use route state if any to keep state accross filters/members modals and refresh,
  // but default to context state
  const [selected, setSelected] = useState<FilterPropTypes>({
    type:
      location?.state?.type !== undefined ? location.state.type : filters.type,
    subtype:
      location?.state?.subtype !== undefined
        ? location.state.subtype
        : filters.subtype,
    status:
      location?.state?.status !== undefined
        ? location.state.status
        : filters.status,
    depot:
      location?.state?.depot !== undefined
        ? location.state.depot
        : filters.depot,
    assignees:
      location?.state?.assignees !== undefined
        ? location.state.assignees
        : filters.assignees,
    assessmentType:
      location?.state?.assessmentType !== undefined
        ? location.state.assessmentType
        : filters.assessmentType,
    hazard:
      location?.state?.hazard !== undefined
        ? location.state.hazard
        : filters.hazard,
    daterange:
      location?.state?.daterange !== undefined
        ? location.state.daterange
        : filters.daterange,
  });
  const { selectedTeam, openTeamsModal } = useTeams();
  const initialTeam = location.state?.team || selectedTeam;
  const [tempTeam, setTempTeam] = useState<Team | null>(initialTeam);
  const screen = getScreen(location?.pathname);

  const optionsWithAll = (options) => [
    { id: null, title: getText('jobs_filter_options_all') },
    ...options,
  ];

  const hasDateError = !!((startDate && !endDate) || (!startDate && endDate));

  const handleSelected = (filterName, options) => {
    const selection = selected[filterName];
    if (selection) {
      return options.find((item) => item.id === selection);
    }

    return { id: null, title: getText('jobs_filter_options_all') };
  };

  const handleChangeFilter = (filter, id) => {
    setSelected((prevSelected) => ({ ...prevSelected, [filter]: id }));
  };

  const handleTypeChange = (id) => {
    setSelected((prevSelected) => ({
      ...prevSelected,
      type: id,
      subtype: (
        optionsWithAll(formOptions.subtypes).find(
          (subtype) =>
            subtype.type_id === id ||
            subtype.title === getText('jobs_filter_options_all'),
        ) ?? {}
      )?.id,
    }));
  };

  const handleClearFilters = () => {
    setSelected({
      type: null,
      subtype: null,
      status: null,
      depot: null,
      assignees: [],
      daterange: null,
      assessmentType: null,
      hazard: null,
    });

    setStartDate(null);
    setEndDate(null);
    setTempTeam(null);
  };

  const handleSubmit = async () => {
    const isTheSameTeam = initialTeam?.id === tempTeam?.id;

    setFilters({
      ...filters,
      ...selected,
    });
    navigate(location.pathname, {
      state: {
        modifiedTeam: isTheSameTeam ? undefined : tempTeam,
        selectedWorkspaceUuid,
      },
    });
  };

  const onDateChange = () => {
    setSelected((prevSelected) => ({
      ...prevSelected,
      daterange: [startDate, endDate],
    }));
  };

  const isSubtypeDisabled = () => {
    const selectedType = formOptions.types.find(
      (type) => type.id === selected.type,
    );
    return (selectedType && selectedType.title === 'Other') || !selected.type;
  };

  const getMaxSelectionRange = () => {
    if (view === constants.LEGACY_VIEW) {
      return constants.MAP_VIEW_MAXIMUM_DATE_RANGE;
    } else {
      return constants.LIST_VIEW_MAXIMUM_DATE_RANGE;
    }
  };

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit}>
        {!isFetchingFormOptions && formOptions ? (
          <>
            <InputsContainer>
              <Input.Select
                id='type'
                label={getText('jobs_filter_job_type')}
                selected={handleSelected(
                  'type',
                  optionsWithAll(formOptions.types),
                )}
                options={optionsWithAll(formOptions.types)}
                onChange={(event) => handleTypeChange(event?.target?.value)}
                large
                fitContent
              />
              {formOptions.subtypes.length > 0 && (
                <Input.Select
                  id='subtype'
                  label={getText('jobs_filter_job_subtype')}
                  selected={handleSelected(
                    'subtype',
                    optionsWithAll(formOptions.subtypes),
                  )}
                  options={optionsWithAll(formOptions.subtypes).filter(
                    (subtype) =>
                      !subtype.id || subtype.type_id === selected.type,
                  )}
                  onChange={(event) =>
                    handleChangeFilter('subtype', event?.target?.value)
                  }
                  disabled={isSubtypeDisabled()}
                  grey={isSubtypeDisabled()}
                  large
                  fitContent
                />
              )}
              <Input.DateRangeSelector
                label={getText('jobs_filter_date')}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                selectedRange={selected.daterange}
                extraStyles={hasDateError ? { marginBottom: '-10px' } : {}}
                onChange={onDateChange}
                maxSelectionRange={getMaxSelectionRange()}
              />
              {hasDateError && (
                <ErrorMessage>
                  {getText('filter_data_date_required')}
                </ErrorMessage>
              )}
              <Input.Select
                id='status'
                label={getText('jobs_filter_status')}
                selected={handleSelected(
                  'status',
                  optionsWithAll(formOptions.statuses),
                )}
                options={optionsWithAll(formOptions.statuses)}
                onChange={(event) =>
                  handleChangeFilter('status', event?.target?.value)
                }
                large
                fitContent
              />
              <Input.Select
                id='assessmentType'
                label={getText('jobs_filter_assessment_type')}
                selected={handleSelected(
                  'assessmentType',
                  optionsWithAll(formOptions.assessmentTypes),
                )}
                options={optionsWithAll(formOptions.assessmentTypes)}
                onChange={(event) =>
                  handleChangeFilter('assessmentType', event?.target?.value)
                }
                large
                fitContent
              />
              <Input.Select
                id='hazard'
                label={getText('jobs_filter_hazard')}
                selected={handleSelected(
                  'hazard',
                  optionsWithAll(formOptions.hazards),
                )}
                options={optionsWithAll(formOptions.hazards)}
                onChange={(event) =>
                  handleChangeFilter('hazard', event?.target?.value)
                }
                large
                fitContent
              />
              <Input.Select
                id='depot'
                label={getText('jobs_filter_depot')}
                selected={handleSelected(
                  'depot',
                  optionsWithAll(formOptions.depots),
                )}
                options={optionsWithAll(formOptions.depots)}
                onChange={(event) =>
                  handleChangeFilter('depot', event?.target?.value)
                }
                large
                fitContent
              />
              {view === constants.LEGACY_VIEW && (
                <Input.Link
                  label={getText('jobs_filter_team_members')}
                  value={formatMembersSelected(
                    selected.assignees,
                    getText('jobs_filter_zero_selected_members'),
                    getText('jobs_filter_members_selected'),
                  )}
                  pathname={location.pathname}
                  search={`modal=${ModalType.SEARCH_MEMBERS}`}
                  state={selected}
                />
              )}
              {view !== constants.LEGACY_VIEW && (
                <>
                  <TeamSelect
                    selectedTeam={tempTeam}
                    handleTeamChange={(team) => setTempTeam(team)}
                  />
                  <ManageTeamsContainer>
                    <Button.Secondary
                      text={getText('manage_teams_modal_title')}
                      IconComponent={TeamIcon}
                      onClick={() =>
                        openTeamsModal(
                          location,
                          selected,
                          tempTeam,
                          ModalType.SEARCH_FILTERS,
                        )
                      }
                      width='180px'
                      extraStyles={{
                        height: 'auto',
                        minHeight: '30px',
                        fontSize: fontSizes.default,
                      }}
                      event={constants.FILTER_MANAGE_TEAMS_CLICKED}
                      eventProperties={{
                        userId: currentUserId,
                        area: screen,
                        [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                      }}
                    />
                  </ManageTeamsContainer>
                  <Input.Link
                    label={getText('jobs_filter_team_members')}
                    value={formatMembersSelected(
                      selected.assignees,
                      getText('jobs_filter_zero_selected_members'),
                      getText('jobs_filter_members_selected'),
                    )}
                    pathname={location.pathname}
                    search={`modal=${ModalType.SEARCH_MEMBERS}`}
                    state={{
                      ...location.state,
                      ...selected,
                      team: tempTeam,
                      assignees: selected.assignees ?? [],
                      userRoles: [
                        UserRoleBackend.FIELDWORKER,
                        UserRoleBackend.TEAMMATE,
                        UserRoleBackend.MANAGER,
                      ],
                    }}
                  />
                </>
              )}
              <ClearButton
                text={getText('jobs_filter_clear_all')}
                onClick={handleClearFilters}
                event={constants.EVENT_CLEAR_FILTERS}
                eventProperties={{
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                large
                width='145px'
                extraStyles={{ padding: '8px 12px', alignSelf: 'flex-start' }}
              />
            </InputsContainer>

            <Button.Primary
              text={getText('jobs_filter_apply_button')}
              onClick={handleSubmit}
              width='240px'
              disabled={hasDateError}
              event={constants.EVENT_JOB_FILTER_APPLY}
              eventProperties={{
                ...selected,
                ...(tempTeam && { team: tempTeam, teamId: tempTeam?.id }),
                ...(startDate && {
                  startDate: formatDateForAnalytics(startDate),
                }),
                ...(endDate && { endDate: formatDateForAnalytics(endDate) }),
                [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
              }}
            />
          </>
        ) : (
          <Loader />
        )}
      </Form>
    </FormContainer>
  );
};

import {
  combineReducers,
  configureStore as configureRTKStore,
} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware, routerReducer } from '../history';
import rootSaga from './sagas';

import websockets from './reducers/websockets';
import messages from './reducers/messages';
import session from './reducers/session';
import jobs from './reducers/jobs';
import legacyViewJobs from './reducers/legacyViewJobs';
import assessments from './reducers/assessments';
import { commandCenterReducer } from './slices/commandCenter';
import versions from './reducers/versions';
import user from './reducers/user';
import insights from './reducers/insights';
import workspace from './reducers/workspace';
import depots from './reducers/depots';
import jobTypes from './reducers/jobTypes';
import locations from './reducers/locations';
import form from './reducers/form';
import featuresGuide from './reducers/featuresGuide';
import workspaceJobSubtype from './reducers/settings/jobSubtype';
import workspaceHazards from './reducers/settings/hazards';
import workspaceControls from './reducers/settings/controls';
import workspaceAssessments from './reducers/settings/assessments';
import { havsSettingsReducer } from 'store/slices/havsSettings';
import { siteBriefingReducer } from 'store/slices/siteBriefing';
import { formsReducer } from 'store/slices/forms';
import { ramsAssessmentsReducer } from './slices/ramsAssessments';
import { notificationsReducer } from './slices/notifications';
import { fatigueReducer } from 'store/slices/fatigue';
import { evidencesReducer } from './slices/evidences';
import { workflowSettingsReducer } from './slices/workflows';

export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const reducer = {
    router: routerReducer,
    websockets,
    messages,
    session,
    job: combineReducers({
      evidences: evidencesReducer,
    }),
    jobs,
    legacyViewJobs,
    assessments,
    ramsAssessments: ramsAssessmentsReducer,
    commandCenter: commandCenterReducer,
    versions,
    user,
    insights,
    workspace,
    depots,
    jobTypes,
    locations,
    form,
    forms: formsReducer,
    fatigue: fatigueReducer,
    featuresGuide,
    workspaceJobSubtype,
    workspaceHazards,
    workspaceControls,
    workspaceAssessments,
    havsSettings: havsSettingsReducer,
    siteBriefing: siteBriefingReducer,
    notifications: notificationsReducer,
    workflowSettings: workflowSettingsReducer,
  };

  const store = configureRTKStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat([
        sagaMiddleware,
        routerMiddleware,
      ]),
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

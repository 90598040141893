import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAssessment,
  sendSuggestion,
  updateAssessment,
} from 'store/actions/assessments';
import { useIsMobile, useText } from 'hooks';
import styled from 'styled-components';
import * as constants from 'utils/constants';
import { Navbar } from 'components/ui';
import { PlusIcon, Cross } from 'assets/icons';
import { selectGlobalLanguageSetting, selectJobsView } from 'store/selectors';
import { Assessment } from 'model/Assessment';
import { Hazard, SuggestedHazardWithControl } from 'model/Assessment/Hazard';
import { useNavigate, useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import ModalCtaButton from './ModalCtaButton';
import { capitalizeFirstLetter } from 'helpers/names';

const Form = styled.form`
  height: calc(100% - 60px);
  width: 100%;
  ${({ theme }) => theme.mixins.flexColumn};
  ${({ theme }) => theme.media.sm`
  height: calc(100vh - 65px);
  `}
`;

const HazardWithControlContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding: 10px 18px 0 18px;
  margin-bottom: 15px;
  ${({ theme }) => theme.media.sm`
    padding: 0;
    margin-bottom: 0;
  `}
`;

const Section = styled.div`
  padding: 10px 15px 20px 15px;
  border-bottom: 15px solid ${({ theme }) => theme.colors.offWhite};
  &:last-of-type {
    border: none;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 20px 15px 40px 15px;
  }
`;

const HazardTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.xl};
`;

const AppliedControls = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: ${({ theme }) => theme.fontSizes.default};
  margin-bottom: 20px;
`;

const ItemRow = styled.div<{ width?: string }>`
  cursor: pointer;
  min-height: 65px;
  width: ${({ width }) => width};
  background-color: ${({ theme }) => theme.colors.black};
  padding: 5px 15px;
  margin-bottom: 4px;
  border-radius: 3px;
  flex-shrink: 0;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.l};
  ${({ theme }) => theme.mixins.flexBetween};
`;

const ItemRowSelected = styled.div<{ width?: string }>`
  cursor: pointer;
  min-height: 65px;
  width: ${({ width }) => width};
  background-color: ${({ theme }) => theme.colors.lightGrey};
  padding: 5px 15px;
  margin-bottom: 4px;
  border-radius: 3px;
  flex-shrink: 0;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.l};
  ${({ theme }) => theme.mixins.flexBetween};
`;

const P = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
`;

const PSelectedSuggested = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black};
`;

const PlusIconStyled = styled(PlusIcon)`
  min-width: 20px;
  max-width: 20px;
  margin-left: 15px;
  .fill-selector {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const CrossIconStyled = styled(Cross)`
  min-width: 20px;
  max-width: 20px;
  margin-left: 15px;
  .fill-selector {
    fill: ${({ theme }) => theme.colors.black};
  }
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-size: ${({ theme }) => theme.fontSizes.m};
  text-align: center;
  padding-bottom: 5px;
`;

type Props = {
  assessment?: Assessment | null;
  hazards: Hazard[];
  isAddition: boolean;
};

const SuggestControlForHazards = ({
  assessment = null,
  hazards,
  isAddition,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId, assessmentId } = useParams();
  const isMobile = useIsMobile();
  const getText = useText();
  const [sections, setSections] = useState<SuggestedHazardWithControl[]>(
    [] as SuggestedHazardWithControl[],
  );
  const [hasError, setError] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { isAssessmentUpdating: isLoading } = useSelector(
    (state: RootState) => state.assessments,
  );
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const view = useSelector(selectJobsView);

  useEffect(() => {
    if (hazards) {
      setSections(hazards.map((hazard) => ({ hazard, controls: [] })));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!assessment) {
      dispatch(fetchAssessment(assessmentId));
    }
  }, [assessmentId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setError(
      sections.filter((section) => section?.controls?.length === 0).length > 0,
    );
  }, [sections]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (submitted && !isLoading) {
      navigate(`/jobs/${jobId}/assessments/${assessmentId}`);
    }
  }, [submitted, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderItem = (hazardId, control) => {
    const isSuggested = sections?.find(
      (section) =>
        section.hazard.id === hazardId &&
        section.controls.find(
          (selectedControl) => selectedControl.id === control.id,
        ),
    );

    if (isSuggested) {
      return (
        <ItemRowSelected
          key={`${hazardId}-${control.id}`}
          onClick={() => handleSelectChange(hazardId, control)}
        >
          <PSelectedSuggested>
            {control[`title_${globalAppLanguage}`] || control.title}
          </PSelectedSuggested>
          <CrossIconStyled />
        </ItemRowSelected>
      );
    }

    return (
      <ItemRow
        key={`${hazardId}-${control.id}`}
        onClick={() => handleSelectChange(hazardId, control)}
      >
        <P>
          {capitalizeFirstLetter(
            control[`title_${globalAppLanguage}`] || control.title,
          )}
        </P>
        <PlusIconStyled />
      </ItemRow>
    );
  };

  const handleSelectChange = (hazardId, control) => {
    const selectedSection = sections.find(
      (section) => section.hazard.id === hazardId,
    );
    if (selectedSection) {
      if (
        selectedSection.controls.find(
          (selectedControl) => selectedControl.id === control.id,
        )
      ) {
        selectedSection.controls = [...selectedSection.controls].filter(
          (selectedControl) => selectedControl.id !== control.id,
        );
      } else {
        selectedSection.controls.push(control);
      }
    }
    setSections([...sections]);
  };

  const handleSubmit = async () => {
    if (isAddition && assessment) {
      assessment.suggestedHazardsWithControls.push(...sections);
      dispatch(updateAssessment(assessment.id, { assessment }));
    } else {
      dispatch(
        sendSuggestion(assessmentId, {
          suggestedHazards: sections.map((section) => ({
            hazard: section.hazard,
            controls: section.controls,
          })),
        }),
      );
    }

    setSubmitted(true);
  };

  const getAppliedControls = (hazardId) => {
    let appliedControls = 0;
    const selectedSection = sections.find(
      (section) => section.hazard.id === hazardId,
    );
    if (selectedSection?.controls) {
      appliedControls = selectedSection.controls.length;
    }
    return appliedControls;
  };

  return (
    <>
      <Navbar
        title={getText('assessment_details_suggest_hazard')}
        defaultBackPath={`/jobs/${jobId}/assessments/${assessmentId}`}
        mobileOnly
      />
      <Form onSubmit={handleSubmit}>
        <HazardWithControlContainer>
          {hazards?.map((hazard) => (
            <Section key={hazard.id}>
              <HazardTitle>
                {hazard[`title_${globalAppLanguage}`] || hazard.title}
              </HazardTitle>
              <AppliedControls>{`(${getText(
                'rams_controls_applied',
              )} ${getAppliedControls(hazard.id)})`}</AppliedControls>
              {hazard?.mappedControls?.map((control) =>
                renderItem(hazard.id, control),
              )}
            </Section>
          ))}
        </HazardWithControlContainer>

        {hasError && (
          <ErrorMessage>{getText('rams_select_min_one')}</ErrorMessage>
        )}

        <ModalCtaButton
          text={getText('job_details_send_suggestions')}
          onClick={handleSubmit}
          loading={isLoading}
          disabled={hasError}
          event={constants.EVENT_SUGGEST_HAZARDS_WITH_CONTROL}
          eventProperties={{
            jobId,
            assessmentId,
            suggestedHazardsWithControls: sections,
            [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
          }}
          extraStyles={!isMobile ? { margin: '0px', flex: '0 0 55px' } : {}}
        />
        {/* <Button.Primary
          disabled={hasError}
          event={constants.EVENT_SUGGEST_HAZARDS_WITH_CONTROL}
          eventProperties={{
            jobId,
            assessmentId,
            suggestedHazardsWithControls: sections,
          }}
          text={getText('job_details_send_suggestions')}
          onClick={handleSubmit}
          loading={isLoading}
          extraStyles={!isMobile ? { margin: '0px', flex: '0 0 55px' } : {}}
        /> */}
      </Form>
    </>
  );
};

export default SuggestControlForHazards;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useEvidence, useText, useJobs, useCommandCenter } from 'hooks';
import {
  FormattedDate,
  Loader,
  Media,
  Modal,
  Badge,
  Button,
} from 'components/ui';
import { Quote } from 'assets/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { canSeeWrapUpSummary, selectJobsView } from 'store/selectors';
import { WrapUpInsights } from './WrapUpInsights';
import theme from 'theme';
import { UnorderedList } from 'components/ui/Lists/UnorderedList';

const Container = styled.div`
  height: 80vh;
  display: flex;
`;

const Information = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Header = styled.div`
  height: 70px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.offWhite};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 0 15px;
  }
`;

const Section = styled.div`
  overflow-y: auto;
  height: calc(100% - 70px);
  box-sizing: border-box;
  padding: 20px 25px 40px 25px;
  border-bottom: 15px solid ${({ theme }) => theme.colors.offWhite};
  &:last-of-type {
    border: none;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 20px 15px 40px 15px;
  }
`;

export default () => {
  const { jobId, evidenceId } = useParams();
  const { evidence, comments, media, isFetching } = useEvidence(evidenceId);
  const getText = useText();
  const navigate = useNavigate();
  const { navigateToOverviewTabOrJob } = useJobs();
  const { isCommandCenter, getPathToTimeline } = useCommandCenter();
  const view = useSelector(selectJobsView);
  const hasWrapUpSummary = useSelector(canSeeWrapUpSummary);
  const [isEvidenceVideoPlaying, setIsEvidenceVideoPlaying] = useState(false);
  const [evidenceVideoPlayedDuration, setEvidenceVideoPlayedDuration] =
    useState<number>(0);

  const showInsights: boolean =
    hasWrapUpSummary &&
    !!evidence?.videos?.length &&
    !!evidence?.videos[0]?.summary;

  const trackImageDownload = () => {
    Analytics.trackEvent(constants.EVENT_EVIDENCE_DOWNLOAD_IMAGE, {
      evidenceId,
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    });
  };

  const handleClose = () => {
    if (isEvidenceVideoPlaying) {
      Analytics.trackEvent(`evidence-video-paused`, {
        url: media?.signedUrl ?? null,
        [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        duration_played: evidenceVideoPlayedDuration,
      });
    }
    if (isCommandCenter) {
      navigate('..');
    } else {
      navigateToOverviewTabOrJob(`/jobs/${jobId}/evidence`);
    }
  };

  useEffect(() => {
    if (evidenceId && jobId && evidence) {
      Analytics.trackPage(constants.EVENT_PAGE_VIEW_EVIDENCE, {
        jobId,
        evidenceId,
        mediaPath: [
          evidence?.photos?.map((x) => x.path),
          evidence?.videos?.map((x) => x.path),
        ],
        view,
      });
    }
  }, [evidenceId, jobId, evidence, view]);

  return (
    <Modal.Base
      isOpen
      title={getText('evidence')}
      onClose={handleClose}
      width='80vw'
    >
      <Container>
        {isFetching ? (
          <Loader />
        ) : (
          <>
            {media && (
              <Media
                kind='evidence'
                type={media.type}
                url={media.signedUrl}
                urls={media.signedUrls}
                width='55%'
                onDownload={trackImageDownload}
                onVideoPlayPause={(isPlaying: boolean) =>
                  setIsEvidenceVideoPlaying(isPlaying)
                }
                onVideoProgress={(durationSeconds: number) =>
                  setEvidenceVideoPlayedDuration(durationSeconds)
                }
                item={evidence?.videos[0]}
              />
            )}
            {evidence && (
              <Information>
                <Header>
                  <Badge.User user={evidence.createdBy} />
                  <FormattedDate
                    date={evidence?.createdAt?.toString() ?? ''}
                    extraStyles={{
                      color: theme.colors.grey,
                      textAlign: 'center',
                    }}
                  />
                </Header>

                <Section>
                  {isCommandCenter && (
                    <Button.SecondaryLink
                      event={constants.EVENT_VIEW_JOB_TIMELINE}
                      text={getText('assessment_details_open_timeline')}
                      extraStyles={{ marginTop: '15px' }}
                      to={getPathToTimeline(jobId)}
                      width='inherit'
                    />
                  )}
                  <UnorderedList
                    items={comments}
                    title={getText('evidence_comments')}
                    IconComponent={Quote}
                    extraItemStyle={{ marginLeft: '40px' }}
                  />
                  {showInsights && <WrapUpInsights evidence={evidence} />}
                </Section>
              </Information>
            )}
          </>
        )}
      </Container>
    </Modal.Base>
  );
};

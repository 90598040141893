import { Control } from 'model/Assessment/Control';
import { BulletPoint, BulletPointWrapper, ControlContainer } from './styled';
import { ItemRowSelected } from './ItemRowSelected';

type Props = {
  controls: Control[];
  keyPrefix: string;
};

export const SuggestedControls = ({
  controls,
  keyPrefix,
}: Props): JSX.Element => {
  return (
    <>
      {controls?.map((item: Control, index) => (
        <ControlContainer key={`${keyPrefix}-${index + 1}`}>
          <BulletPointWrapper>
            <BulletPoint />
          </BulletPointWrapper>
          <ItemRowSelected item={item} width={95} />
        </ControlContainer>
      ))}
    </>
  );
};

import axios from 'axios';
import { Log } from 'model/Log/Log';
import { mapToLog } from './helpers';

export const getAll = async (jobId: string): Promise<Log[]> => {
  const { data } = await axios.get(`/jobs/${jobId}/logs/`, {
    headers: {
      Accept: 'application/json; version=2;',
    },
  });
  return data.results.map(mapToLog);
};

export const get = async (id: number): Promise<Log> => {
  const { data } = await axios.get(`/logs/${id}/`);
  return mapToLog(data);
};

export const getAllJobBlockers = async (jobId: string): Promise<Log[]> => {
  const { data } = await axios.get(`/jobs/${jobId}/job-blockers/`);
  return data.results.map(mapToLog);
};

export const resolveJobBlocker = async (
  logId: number,
  note: string,
): Promise<Log> => {
  const { data } = await axios.post(`/logs/${logId}/resolve-blocker/`, {
    note,
  });
  return mapToLog(data);
};

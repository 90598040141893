import styled from 'styled-components';
import theme from 'theme';

const { colors, mixins, media } = theme;

export const Container = styled.div`
  height: 100%;
  padding: 40px 80px;
  ${mixins.flexColumn};
  ${media.sm`
    padding: 15px;
  `}
  background-color: ${colors.offWhite};
  box-sizing: border-box;
  overflow: auto;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

import {
  FETCH_JOB_TYPES,
  FETCH_JOB_TYPES_SUCCESS,
  FETCH_JOB_TYPES_ERROR,
  FETCH_JOB_SUBTYPES,
  FETCH_JOB_SUBTYPES_SUCCESS,
  FETCH_JOB_SUBTYPES_ERROR,
} from '../actions/actionTypes';

const defaultState = {
  jobTypes: [],
  isFetchingJobTypes: false,
  jobSubtypes: [],
  isFetchingJobSubtypes: false,
};

const jobTypes = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_JOB_TYPES:
      return {
        ...state,
        isFetchingJobTypes: true,
      };
    case FETCH_JOB_TYPES_SUCCESS:
      return {
        ...state,
        jobTypes: action.data,
        isFetchingJobTypes: false,
      };
    case FETCH_JOB_TYPES_ERROR:
      return {
        ...state,
        isFetchingJobTypes: false,
      };
    case FETCH_JOB_SUBTYPES:
      return {
        ...state,
        isFetchingJobSubtypes: true,
      };
    case FETCH_JOB_SUBTYPES_SUCCESS:
      return {
        ...state,
        jobSubtypes: action.data,
        isFetchingJobSubtypes: false,
      };
    case FETCH_JOB_SUBTYPES_ERROR:
      return {
        ...state,
        isFetchingJobSubtypes: false,
      };
    default:
      return state;
  }
};

export default jobTypes;

import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import theme from 'theme';

const { colors, fontSizes } = theme;

export const InputContainer = styled.div<{
  disabled: boolean;
  $error: string | boolean;
}>`
  border: none;
  border-radius: 0;
  outline: 2px solid
    ${({ disabled, $error }) => {
      if (disabled) {
        return colors.grey;
      }

      if ($error) {
        return colors.red;
      }
      return colors.black;
    }};
  transition: ease-out 0.2s;
  &:hover {
    box-shadow: none;
    outline: 3px solid
      ${({ disabled, $error }) => {
        if (disabled) {
          return colors.grey;
        }

        if ($error) {
          return colors.red;
        }

        return colors.black;
      }};
    transition: ease-out 0.2s;
  }
  &:focus-within {
    box-shadow: none;
    outline: 3px solid
      ${({ disabled, $error }) => {
        if (disabled) {
          return colors.grey;
        }

        if ($error) {
          return colors.red;
        }

        return colors.orange;
      }};
    transition: ease-out 0.2s;
  }
`;

export const StyledPhoneInput = styled(PhoneInput)`
  &.react-tel-input .form-control {
    width: 100%;
    height: 55px;
    border: none;
    border-radius: 0;
    font-size: ${fontSizes.default};
    color: ${colors.black};
    box-shadow: none;
    &:hover,
    &:focus {
      box-shadow: none;
      outline: 0;
      border: none;
    }
  }
  &.react-tel-input .selected-flag {
    background: ${colors.white};
    margin-right: 0;
  }
`;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useText } from 'hooks';
import { Button, Input } from 'components/ui';
import { HazardSummaryList } from './HazardSummaryList';
import RootState from 'model/State/RootState';
import * as constants from 'utils/constants';
import { selectJobsView } from 'store/selectors';
import {
  CheckboxContainer,
  Message,
  SummaryContentContainer,
  TagContainer,
  WarningIcon,
} from './styled';
import {
  EVENT_RAMS_SUMMARY_EDIT_BUTTON_CLICKED,
  EVENT_RAMS_SUMMARY_FINISH_BUTTON_CLICKED,
} from '../constants';
import { SectionHeader } from '../SectionHeader';
import {
  selectActiveRamsAssessment,
  selectIsEditTagDismissed,
  selectUserCanEditRams,
  sendRamsAssessmentRequest,
  setAssessmentStage,
} from 'store/slices/ramsAssessments';
import { Pencil } from 'assets/icons';
import {
  ActionButtons,
  ActionButtonsWrapper,
  SectionContentContainer,
} from '../styled';
import { NotEditableTag } from '../NotEditableTag';
import { RamsAssessments, RamsStages } from 'model/Assessment/RAMS';

export const RatingSummary = (): JSX.Element => {
  const { selectedHazards, ratings, currentStage, isSubmitting } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );
  const assessment = useSelector(selectActiveRamsAssessment);
  const canUserEdit = useSelector(selectUserCanEditRams);
  const isEditTagDismissed = useSelector(selectIsEditTagDismissed);
  const view = useSelector(selectJobsView);

  const [checked, setChecked] = useState<boolean>(false);
  const dispatch = useDispatch();
  const getText = useText();

  const isRatingLow = RamsAssessments.isPostControlRatingLow(ratings);

  const onEditClick = () => {
    dispatch(setAssessmentStage(RamsStages.hazardRatingReview));
    RamsAssessments.scrollToTop();
  };

  const onRatingFinishClick = () => {
    if (assessment) {
      dispatch(sendRamsAssessmentRequest(assessment?.id));
      dispatch(setAssessmentStage(RamsStages.methodStatement));
    }
  };

  return (
    <SectionContentContainer>
      <SectionHeader
        title={getText('rams_section_option_assessment')}
        subtitle={getText('rams_rating_summary_hazard')}
      />
      <SummaryContentContainer large={canUserEdit || isEditTagDismissed}>
        <TagContainer $isWarning={!isRatingLow}>
          {!isRatingLow && <WarningIcon />}
          <Message>
            {getText(
              isRatingLow
                ? 'rams_rating_summary_all_hazards_reduced'
                : 'rams_rating_summary_has_medium_risk_hazard',
            )}
          </Message>
        </TagContainer>

        <HazardSummaryList hazards={selectedHazards} />

        {!isRatingLow && currentStage !== RamsStages.hazardRatingSummary && (
          <CheckboxContainer>
            <Input.Checkbox
              defaultChecked={false}
              checked={checked}
              onChange={() => setChecked(!checked)}
              label={getText('rams_rating_summary_aware_of_medium_risk')}
              extraStyles={{ flexDirection: 'row', alignItems: 'center' }}
              isLight
            />
          </CheckboxContainer>
        )}
      </SummaryContentContainer>
      {!canUserEdit && <NotEditableTag />}
      <ActionButtonsWrapper>
        <ActionButtons>
          {currentStage === RamsStages.hazardRatingSummary && (
            <Button.Secondary
              text={getText('rams_rating_summary_edit')}
              onClick={onEditClick}
              disabled={!canUserEdit}
              IconComponent={Pencil}
              width='45%'
              event={EVENT_RAMS_SUMMARY_EDIT_BUTTON_CLICKED}
              eventProperties={{
                [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
              }}
              extraStyles={{
                margin: 0,
                alignSelf: 'flex-end',
                height: 'inherit',
                maxHeight: '55px',
              }}
            />
          )}
          {currentStage === RamsStages.hazardRatingReview &&
            ratings.length === selectedHazards.length && (
              <Button.Primary
                onClick={onRatingFinishClick}
                text={getText('rams_summary_finish_button')}
                disabled={(!checked && !isRatingLow) || !canUserEdit}
                width='100%'
                loading={isSubmitting}
                event={EVENT_RAMS_SUMMARY_FINISH_BUTTON_CLICKED}
                eventProperties={{
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                extraStyles={{
                  margin: 0,
                  alignSelf: 'flex-end',
                  height: 'inherit',
                  maxHeight: '55px',
                }}
              />
            )}
        </ActionButtons>
      </ActionButtonsWrapper>
    </SectionContentContainer>
  );
};

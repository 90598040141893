import { DecreaseArrow, IncreaseArrow } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes } = theme;

export const SummarySubTitle = styled.div<{ $isRed?: boolean }>`
  font-family: ${fonts.bold};
  display: flex;
  font-size: ${fontSizes.s};
  align-items: center;
  justify-content: center;
  color: ${({ $isRed }) => ($isRed ? colors.red : colors.black)};
`;

export const DecreaseArrowIcon = styled(DecreaseArrow)`
  margin-right: 8px;
  .fill-selector {
    fill: ${colors.red};
  }
`;

export const IncreaseArrowIcon = styled(IncreaseArrow)`
  margin-right: 8px;
`;

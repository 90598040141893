import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins } = theme;

export const Wrapper = styled.div`
  ${mixins.flexColumn};
`;

export const TitleContainer = styled.div<{ $swapOrder: boolean }>`
  ${mixins.flexColumn};
  ${({ $swapOrder }) => $swapOrder && `flex-direction: column-reverse`};
`;

export const Title = styled.div<{ disabled?: boolean }>`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
  padding: 10px 0;
  color: ${({ disabled }) => (disabled ? colors.grey : colors.black)};
`;

export const Info = styled.div`
  color: ${colors.grey};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
  padding: 5px 0;
`;

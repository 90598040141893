import { Filters } from 'assets/icons';
import { Tooltip } from 'components/ui/Display';
import { useText } from 'hooks';
import { ModalType } from 'model/enum/ModalType';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';

const { colors, media, fonts, mixins } = theme;

const FiltersButton = Analytics.withTrackEvent(styled(
  ({ width, hasLabel, isListView, disabled, to, ...rest }) => (
    <Link to={to} {...rest} />
  ),
)`
  outline: 2px solid ${colors.black};
  box-sizing: border-box;
  height: 55px;
  width: ${({ width }) => width || '55px'};
  padding: 8px 12px;
  color: ${colors.black};
  text-decoration: none;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  outline: ${({ disabled }) => {
    return disabled ? `2px solid ${colors.grey}` : `2px solid ${colors.black}`;
  }};
  background-color: ${({ disabled, isListView }) =>
    disabled ? colors.lightGrey : isListView && colors.yellow};
  ${({ isListView }) => !isListView && `margin-left: 20px;`}
  ${({ hasLabel }) => (hasLabel ? mixins.flexBetween : mixins.flexFullCenter)};
  &:visited {
    color: ${colors.black};
  }
  &:hover {
    outline: ${({ disabled }) => {
      return disabled
        ? `2px solid ${colors.grey}`
        : `3px solid ${colors.black}`;
    }};
  }
  ${media.xl`
      height: 45px;
      width: ${({ width }) => width || '45px'};
      margin-left: 8px;
    `}
  ${media.sm`
      outline: 2px solid ${colors.white};
      color: ${colors.white};
      margin-left: clamp(5px, 5px, 32px);
      &:visited {
        color: ${colors.white};
      }
    `}
  

    &:focus-within {
    outline: 3px solid ${colors.orange};
  }
`);

const FilterLabel = styled.span`
  font-family: ${fonts.bold};
  margin-right: 10px;
`;

const FiltersIcon = styled(Filters)`
  height: 20px;
  width: 20px;
  .fill-selector {
    fill: ${colors.black};
  }
  ${media.sm`
    .fill-selector {
      fill: ${colors.white};
    }
  `}
`;

type Props = {
  view: string;
  label?: string;
  width?: string;
  disabled?: boolean;
};

const JobFilterButton = ({
  view,
  label,
  width,
  disabled,
}: Props): JSX.Element => {
  const location = useLocation();

  const getText = useText();

  if (disabled) {
    return (
      <Tooltip
        title={getText('jobs_clear_search_tooltip')}
        triggerElement={
          <span>
            <FiltersButton
              event={constants.EVENT_FILTER_SEARCH}
              eventProperties={{
                [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
              }}
              to={`${location.pathname}?modal=${ModalType.SEARCH_FILTERS}`}
              width={width}
              hasLabel={!!label}
              isListView={view !== constants.LEGACY_VIEW}
              disabled
            >
              {label && <FilterLabel>{label}</FilterLabel>}
              <FiltersIcon />
            </FiltersButton>
          </span>
        }
        placement='bottom'
        height={40}
      />
    );
  }

  return (
    <FiltersButton
      event={constants.EVENT_FILTER_SEARCH}
      eventProperties={{
        [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      }}
      to={`${location.pathname}?modal=${ModalType.SEARCH_FILTERS}`}
      width={width}
      hasLabel={!!label}
      isListView={view !== constants.LEGACY_VIEW}
    >
      {label && <FilterLabel>{label}</FilterLabel>}
      <FiltersIcon />
    </FiltersButton>
  );
};

export default JobFilterButton;

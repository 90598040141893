import { useEffect } from 'react';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile, useLocalStorage } from 'hooks';
import { setWorkspace } from 'store/actions/session';
import { ErrorPage, Navbar } from 'components/ui';
import List from './List';
import * as JobsV2 from './JobsV2';
import Map from './Map';
import Modal from './Modal';
import Overview from './Overview';
import Timeline from './Timeline';
import { HazardsControlsComments } from './HazardsControlsComments';
import * as Assessment from './Assessment';
import * as Blocker from './Blocker';
import * as Note from './Note';
import * as Evidence from './Evidence';
import * as JobBlocker from './JobBlocker';
import * as Document from './Document';
import * as Logs from './Logs';
import * as AddContent from './AddContent';
import { RamsAssessmentModal } from './RamsAssessment';
import * as Permit from './Permits';
import * as JobForms from './JobForms';

import * as Edit from './Edit';
import Insights from './Insights';
import FatigueManager from './FatigueManager';
import { CommandCenter } from './CommandCenter';
import Settings from './Settings';
import NotificationSettings from './Settings/NotificationSettings';
import RootState from 'model/State/RootState';
import { TIMELINE } from 'utils/constants';
import Team from 'model/Team';
import { selectQueryParamValueByKey } from 'store/selectors/location';
import { SiteBriefingModal } from './SiteBriefing/Modal';
import { SiteBriefingShow } from './SiteBriefing/Show';
import { FeedbackShow } from './SafetyPrediction/Feedback/Show';
import { FeedbackModal } from './SafetyPrediction/Feedback/Modal';
import { Details } from './Edit/Details';
import { InsightsFormModal } from './InsightsForms';
import { WorkOrderItemList } from './WorkOrderItem/List';
import { WorkOrderItemShow } from './WorkOrderItem/Show';
import { WorkOrderItemModal } from './WorkOrderItem/Modal';

const MobileContainer = styled.div<{ $offsetHeight: number }>`
  height: ${({ $offsetHeight }) =>
    `calc(100vh - 120px - ${$offsetHeight || 0}px)`};
  width: 100vw;
`;

type Props = {
  permissions: string[];
  modal: string | null;
  offsetHeight?: number;
  selectedTeam: Team | null;
  setSelectedTeamForCurrentUser: (teamId: number | null) => void;
};

const MobileLayout = ({
  permissions,
  modal,
  offsetHeight = 0,
}: Props): JSX.Element => (
  <MobileContainer $offsetHeight={offsetHeight}>
    <Routes>
      <Route path='/*' element={<List />} />
      <Route path='/insights' element={<Insights />} />
      {permissions.includes('fatigue_dashboard_v1') && (
        <Route path='/fatigue-manager' element={<FatigueManager />} />
      )}
      {permissions.includes('command_center_v1') && (
        <Route path='/command-center' element={<CommandCenter />} />
      )}
      <Route path='/settings' element={<NotificationSettings />} />
      <Route path='/:jobId/*' element={<Overview />} />
      <Route
        path='/:jobId/hazards-controls'
        element={<HazardsControlsComments />}
      />
      <Route path='/:jobId/assessments' element={<Assessment.List />} />
      <Route path='/:jobId/evidence' element={<Evidence.List />} />
      <Route path='/:jobId/blockers' element={<JobBlocker.List />} />
      <Route path='/:jobId/documents' element={<Document.List />} />
      <Route path='/:jobId/work-completed' element={<WorkOrderItemList />} />
      <Route path='/:jobId/permits' element={<Permit.List />} />
      <Route path='/:jobId/logs' element={<Logs.List />} />
      <Route path='/:jobId/edit-details' element={<Details />} />
      <Route path='/:jobId/edit-team' element={<Edit.TeamEdit />} />
      <Route
        path='/:jobId/assessments/:assessmentId'
        element={<Assessment.AssessmentShow />}
      />
      <Route
        path='/:jobId/assessments/:assessmentId/hazards'
        element={<Assessment.SuggestHazard />}
      />
      <Route
        path={'/:jobId/assessments/:assessmentId/controls'}
        element={<Assessment.SuggestControl />}
      />
      <Route
        path={
          '/:jobId/assessments/:assessmentId/hazards-with-controls/:hazardId/controls'
        }
        element={<Assessment.SuggestControl />}
      />
      <Route
        path={'/:jobId/assessments/:assessmentId/signature/:userId'}
        element={<Assessment.DigitalSignature />}
      />
      <Route
        path='/rams-assessments/:assessmentId'
        element={<RamsAssessmentModal />}
      />
      <Route
        path='/:jobId/evidence/:evidenceId'
        element={<Evidence.EvidenceShow />}
      />
      <Route path='/:jobId/blockers/:blockerId' element={<Blocker.Show />} />
      <Route
        path='/:jobId/blockers/:blockerId/resolve-blocker'
        element={<Blocker.ResolveBlocker />}
      />
      <Route path='/:jobId/logs/:logId' element={<Note.Show />} />
      <Route
        path='/:jobId/work-completed/:workOrderItemId'
        element={<WorkOrderItemShow />}
      />
      <Route path='/:jobId/permits/:permitId' element={<Permit.Show />} />
      <Route
        path='/:jobId/permits/:permitId/rejection'
        element={<Permit.Rejection />}
      />
      <Route
        path='/:jobId/permits/:permitId/set-duration'
        element={<Permit.SetDuration />}
      />
      <Route
        path='/:jobId/permits/:permitId/instruction/:fieldId'
        element={<Permit.Instruction />}
      />
      <Route path='/:jobId/job-forms/:formId' element={<JobForms.Show />} />
      <Route
        path='/:jobId/job-forms/:formId/instruction/:fieldId'
        element={<JobForms.Instruction />}
      />
      <Route path='/:jobId/timeline' element={<Timeline />} />
      <Route path='/:jobId/add' element={<AddContent.Show />} />
      <Route path='/:jobId/add/document' element={<AddContent.AddDocument />} />
      <Route path='/:jobId/add/evidence' element={<AddContent.AddEvidence />} />
      <Route path='/:jobId/safety-review' element={<FeedbackShow />} />
      <Route
        path='/:jobId/site-briefing/:siteBriefingId'
        element={<SiteBriefingShow />}
      />
      <Route
        element={
          <ErrorPage
            titleText='404'
            subTitleText='Go back '
            linkText='Home'
            link='/jobs'
          />
        }
      />
    </Routes>
    {modal && <Modal />}
  </MobileContainer>
);

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const DesktopContainer = styled.div<{ $offsetHeight: number }>`
  height: ${({ $offsetHeight }) =>
    `calc(100vh - 65px - ${$offsetHeight || 0}px)`};
  display: flex;
`;

const DesktopLayout = ({
  permissions,
  modal,
  offsetHeight = 0,
  selectedTeam,
  setSelectedTeamForCurrentUser,
}: Props): JSX.Element => (
  <Container>
    <Navbar home />

    <DesktopContainer $offsetHeight={offsetHeight}>
      <Routes>
        <Route
          index={true}
          path=''
          element={
            <JobsV2.MapListViewWrapper
              mapView={
                <>
                  <List />
                  <Map />
                </>
              }
              listTab={
                <JobsV2.JobsBase
                  selectedTeam={selectedTeam}
                  setSelectedTeamForCurrentUser={setSelectedTeamForCurrentUser}
                >
                  <JobsV2.ListV2 selectedTeam={selectedTeam} />
                </JobsV2.JobsBase>
              }
              mapTab={
                <JobsV2.JobsBase
                  selectedTeam={selectedTeam}
                  setSelectedTeamForCurrentUser={setSelectedTeamForCurrentUser}
                >
                  <JobsV2.MapV2 />
                </JobsV2.JobsBase>
              }
            />
          }
        />
        <Route
          path={'/insights/*'}
          element={
            <>
              <Insights />
              <Routes>
                <Route
                  path='/forms/:formId/*'
                  element={<InsightsFormModal />}
                />
              </Routes>
            </>
          }
        />
        {permissions.includes('fatigue_dashboard_v1') && (
          <Route path='/fatigue-manager' element={<FatigueManager />} />
        )}
        {permissions.includes('command_center_v1') && (
          <Route
            path='/command-center/*'
            element={
              <>
                <CommandCenter />
                <Routes>
                  <Route
                    path='/:jobId/assessments/:assessmentId/*'
                    element={<Assessment.AssessmentModal />}
                  />
                  <Route
                    path='/:jobId/rams-assessments/:assessmentId/*'
                    element={<RamsAssessmentModal />}
                  />
                  <Route
                    path='/:jobId/evidence/:evidenceId'
                    element={<Evidence.EvidenceModal />}
                  />
                </Routes>
              </>
            }
          />
        )}
        <Route path='/settings' element={<Settings />} />
        <Route
          path={'/:jobId/*'}
          element={
            <>
              <JobsV2.MapListViewWrapper
                mapView={
                  <>
                    <List />
                    <Overview />
                    <Timeline />
                  </>
                }
                listTab={
                  <JobsV2.JobsBase
                    selectedTeam={selectedTeam}
                    setSelectedTeamForCurrentUser={
                      setSelectedTeamForCurrentUser
                    }
                  >
                    <JobsV2.ListV2 selectedTeam={selectedTeam} />
                  </JobsV2.JobsBase>
                }
                mapTab={
                  <JobsV2.JobsBase
                    selectedTeam={selectedTeam}
                    setSelectedTeamForCurrentUser={
                      setSelectedTeamForCurrentUser
                    }
                  >
                    <JobsV2.MapV2 />
                  </JobsV2.JobsBase>
                }
              />
              <Routes>
                <Route
                  path='/assessments/:assessmentId/*'
                  element={<Assessment.AssessmentModal />}
                />
                <Route
                  path='/rams-assessments/:assessmentId'
                  element={<RamsAssessmentModal />}
                />
                <Route
                  path='/evidence/:evidenceId'
                  element={<Evidence.EvidenceModal />}
                />
                <Route
                  path='/blockers/:blockerId'
                  element={<Blocker.Modal />}
                />
                <Route
                  path='/blockers/:blockerId/resolve-blocker'
                  element={<Blocker.ResolveModal />}
                />
                <Route path='/logs/:logId' element={<Note.Modal />} />
                <Route
                  path='/work-completed/:workOrderItemId'
                  element={<WorkOrderItemModal />}
                />
                <Route path='/permits/:permitId/*' element={<Permit.Modal />} />
                <Route path='/edit-details' element={<Edit.DetailsModal />} />
                <Route path='/edit-team' element={<Edit.TeamModal />} />
                <Route path='/add/*' element={<AddContent.Modal />} />
                <Route
                  path='/job-forms/:formId/*'
                  element={<JobForms.Modal />}
                />
                <Route path='/safety-review' element={<FeedbackModal />} />
                <Route
                  path='/site-briefing/:siteBriefingId'
                  element={<SiteBriefingModal />}
                />
              </Routes>
            </>
          }
        />
        <Route
          element={
            <ErrorPage
              titleText='404'
              subTitleText='Go back '
              linkText='Home'
              link='/jobs'
            />
          }
        />
      </Routes>
      {modal && <Modal />}
    </DesktopContainer>
  </Container>
);

export const DrawerLayout = ({
  activeTab,
}: {
  activeTab: string | null;
}): JSX.Element => {
  if (activeTab === TIMELINE) {
    return (
      <Routes>
        <Route path='/*' element={<JobsV2.TimelineV2 />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path='/*' element={<JobsV2.ListOverview />} />
      <Route path='/edit-details' element={<JobsV2.ListOverview />} />
      <Route path='/safety-review' element={<JobsV2.ListOverview />} />
      <Route path='/timeline' element={<JobsV2.TimelineV2 />} />
      <Route path='/team-members' element={<JobsV2.TeamMembers />} />
      <Route path='/edit-team' element={<JobsV2.TeamMembers />} />
      <Route path='/hazards-controls' element={<JobsV2.HazardsAndControls />} />
      <Route path='/assessments/*' element={<JobsV2.RiskAssessments />} />
      <Route path='/rams-assessments/*' element={<JobsV2.RiskAssessments />} />
      <Route path='/evidence/*' element={<JobsV2.Evidences />} />
      <Route path='/job-forms/*' element={<JobsV2.Forms />} />
      <Route path='/blockers/*' element={<JobsV2.JobBlockers />} />
      <Route path='/work-completed/*' element={<JobsV2.WorkOrderItems />} />
      <Route path='/logs/*' element={<JobsV2.Logs />} />
      <Route path='/permits/*' element={<JobsV2.Permits />} />
      <Route path='/documents' element={<JobsV2.Documents />} />
      <Route
        path='/site-briefing/:siteBriefingId'
        element={<SiteBriefingModal />}
      />
    </Routes>
  );
};

export default ({
  selectedTeam,
  setSelectedTeamForCurrentUser,
}: {
  selectedTeam: Team | null;
  setSelectedTeamForCurrentUser: (teamId: number | null) => void;
}) => {
  const modal = useSelector(selectQueryParamValueByKey('modal'));
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.session);
  const [activeWorkspaceUuid, setActiveWorkspaceUuid] = useLocalStorage<string>(
    'activeWorkspaceUuid',
  );
  const intercomBannerHeight = document.getElementsByName(
    'intercom-banner-frame',
  )[0]?.offsetHeight;

  useEffect(() => {
    let savedActiveWorkspace;

    if (activeWorkspaceUuid) {
      savedActiveWorkspace = currentUser?.workspaces?.find(
        (workspace) => workspace.uuid === activeWorkspaceUuid,
      );
    }

    if (savedActiveWorkspace) {
      dispatch(setWorkspace(savedActiveWorkspace));
    } else if (currentUser?.workspaces && currentUser?.workspaces?.length > 0) {
      setActiveWorkspaceUuid(currentUser?.workspaces[0]?.uuid);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isMobile ? (
        <MobileLayout
          permissions={currentUser?.permissions ?? []}
          modal={modal}
          offsetHeight={intercomBannerHeight}
          selectedTeam={selectedTeam}
          setSelectedTeamForCurrentUser={setSelectedTeamForCurrentUser}
        />
      ) : (
        <DesktopLayout
          permissions={currentUser?.permissions ?? []}
          modal={modal}
          offsetHeight={intercomBannerHeight}
          selectedTeam={selectedTeam}
          setSelectedTeamForCurrentUser={setSelectedTeamForCurrentUser}
        />
      )}
    </>
  );
};

import { useText } from 'hooks';
import {
  Container,
  DetailsContainer,
  FeedbackButton,
  FeedbackContainer,
  FeedbackTitle,
  SparkleIcon,
  ThumbsIcon,
  Title,
  TitleContainer,
} from './styled';
import { InsightsItem } from './components/InsightsItem';
import { ThumbsDown, ThumbsUp } from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsSendingFeedback,
  selectIsSendingFeedbackReason,
  sendWrapUpFeedback,
} from 'store/slices/evidences';
import { useEffect, useState } from 'react';
import { Evidence } from 'model/Evidence/Evidence';
import { FeedbackReason } from './components/FeedbackReason';
import { Loader } from 'components/ui';
import * as Analytics from 'utils/analytics';
import {
  EVENT_WRAP_UP_INSIGHTS_THUMBS_DOWN,
  EVENT_WRAP_UP_INSIGHTS_THUMBS_UP,
  Reason,
} from './components/constants';
import {
  selectActiveJob,
  selectCurrentUserId,
  selectIsCurrentUserAssignedToJob,
} from 'store/selectors';
import { SummaryItemBackendTitle, SummaryItemTitle } from './constants';
import { ThanksMessage } from './components/ThanksMessage';
import { fetchActiveJob } from 'store/actions/jobs';

type Props = {
  evidence: Evidence;
};

export const WrapUpInsights = ({ evidence }: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const isSubmittingFeedback = useSelector(selectIsSendingFeedback);
  const isSubmittingReason = useSelector(selectIsSendingFeedbackReason);
  const currentUserId = useSelector(selectCurrentUserId);
  const activeJob = useSelector(selectActiveJob);
  const isGivingFeedbackAvailable = useSelector(
    selectIsCurrentUserAssignedToJob,
  );
  const summary: { [key: string]: string } =
    evidence?.videos?.[0]?.summary ?? {};
  const [selectedFeedback, setSelectedFeedback] = useState<number | null>(
    evidence?.videos?.[0].videoFeedback,
  );
  const [selectedReasons, setSelectedReasons] = useState<Reason[]>([]);
  const [showFeedbackReason, setShowFeedbackReason] = useState<boolean>(false);
  const [showThanksMessage, setShowThanksMessage] = useState<boolean>(false);

  const isFeedbackAlreadySubmitted: boolean = selectedFeedback !== null;

  const summaryItems = [
    {
      title: SummaryItemTitle.WORK_COMPLETED,
      description: summary?.[SummaryItemBackendTitle.JOB_PERFORMED],
    },
    {
      title: SummaryItemTitle.WORK_PENDING,
      description: summary?.[SummaryItemBackendTitle.PENDING],
    },
    {
      title: SummaryItemTitle.KEY_INFORMATION,
      description: summary?.[SummaryItemBackendTitle.NOTEWORTHY],
    },
  ];

  useEffect(() => {
    if (!activeJob) {
      dispatch(fetchActiveJob(evidence.jobId));
    }
  }, [evidence.jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      (selectedFeedback && isSubmittingFeedback) ||
      (selectedFeedback === 0 &&
        !isSubmittingFeedback &&
        selectedReasons.length &&
        !isSubmittingReason)
    ) {
      setShowThanksMessage(true);
    }
  }, [selectedFeedback, isSubmittingFeedback, isSubmittingReason]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showThanksMessage) {
      const timer = setTimeout(() => {
        setShowThanksMessage(false);
        setSelectedReasons([]);
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [showThanksMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const sendFeedback = (isPositive: boolean = true) => {
    const feedbackValue = isPositive ? 1 : 0;
    setSelectedFeedback(feedbackValue);
    setShowFeedbackReason(!isPositive);
    dispatch(
      sendWrapUpFeedback({
        evidenceId: evidence.id.toString(),
        videoFeedback: feedbackValue,
      }),
    );
    Analytics.trackEvent(
      isPositive
        ? EVENT_WRAP_UP_INSIGHTS_THUMBS_UP
        : EVENT_WRAP_UP_INSIGHTS_THUMBS_DOWN,
      {
        evidence_id: evidence.id,
        evidence_video_id: evidence?.videos?.[0]?.fileId,
        user_id: currentUserId,
        timestamp: new Date(),
        job_id: evidence.jobId,
      },
    );
  };

  useEffect(() => {
    if (!isSubmittingReason) {
      setShowFeedbackReason(false);
    }
  }, [isSubmittingReason]);

  const scrollElementIntoView = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (showFeedbackReason) {
      scrollElementIntoView('feedback-reason');
    }
  }, [showFeedbackReason]);

  useEffect(() => {
    if (showThanksMessage) {
      scrollElementIntoView('thanks-message');
    }
  }, [showThanksMessage]);

  return (
    <>
      <Container>
        <TitleContainer>
          <SparkleIcon />
          <Title>{getText('wrap_up_summaries_title')}</Title>
        </TitleContainer>
        <DetailsContainer>
          {summaryItems.map(
            (item, index) =>
              item.description && (
                <InsightsItem
                  key={`insight-${index}`}
                  title={item.title}
                  description={item.description}
                />
              ),
          )}
        </DetailsContainer>
        {isGivingFeedbackAvailable && (
          <FeedbackContainer>
            <FeedbackTitle>
              {getText('wrap_up_summaries_feedback_question')}
            </FeedbackTitle>
            {isSubmittingFeedback ? (
              <div>
                <Loader size={20} />
              </div>
            ) : (
              <>
                <FeedbackButton
                  selected={selectedFeedback === 1}
                  disabled={isFeedbackAlreadySubmitted}
                  onClick={() => sendFeedback()}
                >
                  <ThumbsIcon component={<ThumbsUp />} />
                </FeedbackButton>
                <FeedbackButton
                  selected={selectedFeedback === 0}
                  disabled={isFeedbackAlreadySubmitted}
                  onClick={() => sendFeedback(false)}
                >
                  <ThumbsIcon component={<ThumbsDown />} />
                </FeedbackButton>
              </>
            )}
          </FeedbackContainer>
        )}
      </Container>
      {showFeedbackReason && (
        <div id='feedback-reason'>
          <FeedbackReason
            evidence={evidence}
            reasons={selectedReasons}
            setReasons={setSelectedReasons}
          />
        </div>
      )}
      {showThanksMessage && (
        <div id='thanks-message'>
          <ThanksMessage />
        </div>
      )}
    </>
  );
};

import { useText } from 'hooks';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { ModalType } from 'model/enum/ModalType';
import Filters from 'model/Filters';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchInput } from 'components/ui/Filter/SearchInput';
import { useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import { AppliedFilterBox } from './AppliedFilterBox';
import { FilterType } from 'model/enum/FilterType';
import {
  formatDate,
  formatDateForAnalytics,
  isSameDay,
  sevenDaysAgoString,
  todayString,
} from 'helpers/dates';
import { FyldScreen } from 'model/enum/FyldScreen';
import { selectInsightsTab, selectUserHasPermission } from 'store/selectors';
import Team from 'model/Team';
import { TeamFilter } from './TeamFilter';
import { Button } from '..';
import { selectFatigueTab } from 'store/slices/fatigue';
import { InsightsTabKey } from 'model/enum/InsightsTabKey';
import {
  FiltersContainer,
  FiltersIcon,
  ResponsiveContentWrapper,
  SimpleActiveFilterContainer,
} from './styled';

type Props = {
  screen: FyldScreen;
  filters: Filters;
  setFilters: (value: Filters) => void;
  hasSearchInput?: boolean;
  hasUserFilter?: boolean;
  hasDateRangeFilter?: boolean;
  hasWeekendFilter?: boolean;
  hasShiftDurationFilter?: boolean;
  selectedTeam: Team | null;
  setSelectedTeam: (value: number | null) => void;
};

export const FilterBase = ({
  screen,
  filters,
  hasSearchInput = false,
  hasUserFilter = false,
  hasDateRangeFilter = false,
  hasWeekendFilter = false,
  hasShiftDurationFilter = false,
  selectedTeam,
  setSelectedTeam,
  setFilters,
}: Props): JSX.Element => {
  const getText = useText();
  const navigate = useNavigate();
  const location = useLocation();
  const { depot: defaultUserDepot } = useSelector(
    (state: RootState) => state.session,
  );
  const canSwitchDepot = useSelector(
    selectUserHasPermission('switch_depot_v1'),
  );

  const activeInsightsTab = useSelector(selectInsightsTab);
  const activeTab = useSelector(
    screen === FyldScreen.INSIGHTS ? selectInsightsTab : selectFatigueTab,
  );

  useEffect(() => {
    if (location.state?.modifiedFilters) {
      setFilters(location.state?.modifiedFilters);
      const { pathname, ...rest } = location;
      navigate('', {
        ...rest,
        state: {
          ...rest.state,
          modifiedFilters: undefined,
        },
        replace: true,
      });
    }
  }, [location.state?.modifiedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const openFiltersModal = (focus?: string) => {
    Analytics.trackEvent(
      screen === FyldScreen.INSIGHTS
        ? constants.INSIGHTS_FILTER_CLICKED
        : constants.FATIGUE_FILTER_CLICKED,
      {
        ...filters,
        startDate: formatDateForAnalytics(filters.startDate),
        endDate: formatDateForAnalytics(filters.endDate),
      },
    );
    navigate(location.pathname + `?modal=${ModalType.FILTERS}`, {
      state: {
        filters,
        selectedTeam,
        hasUserFilter,
        hasDateRangeFilter,
        hasWeekendFilter,
        hasShiftDurationFilter,
        ...(focus && { focus }),
      },
    });
  };

  const removeFilter = (type: FilterType) => {
    switch (type) {
      case FilterType.DEPOT:
        setFilters({ ...filters, depot: defaultUserDepot ?? null });
        break;
      case FilterType.MEMBER:
        setFilters({ ...filters, members: [] });
        break;
      case FilterType.TEAM:
        setSelectedTeam(null);
        break;
      case FilterType.DATE_RANGE:
        setFilters({
          ...filters,
          startDate: null,
          endDate: null,
        });
        break;
      case FilterType.WEEKEND:
        setFilters({ ...filters, weekendIncluded: false });
        break;
      case FilterType.SHIFT_DURATION:
        setFilters({ ...filters, fraType: null });
        break;
      default:
    }
  };

  const startDate = hasDateRangeFilter
    ? (filters?.startDate ?? sevenDaysAgoString)
    : null;
  const endDate = hasDateRangeFilter ? (filters?.endDate ?? todayString) : null;

  const isInsights = location?.pathname.endsWith('insights');

  const allowsAllDepots =
    !isInsights ||
    !(
      activeInsightsTab === InsightsTabKey.JOBS_PER_DAY ||
      activeInsightsTab === InsightsTabKey.OUTPUT_PER_DAY
    );

  const depotSelectionForTabsWithMandatoryDepotIdFilter =
    canSwitchDepot && filters?.depot?.id === -1
      ? defaultUserDepot
      : filters?.depot;

  const depotSelection = allowsAllDepots
    ? filters?.depot
    : depotSelectionForTabsWithMandatoryDepotIdFilter;

  return (
    <ResponsiveContentWrapper>
      <FiltersContainer>
        {hasSearchInput && (
          <SearchInput
            value={filters?.search || ''}
            handleSearch={(value) => {
              if (value !== filters?.search) {
                setFilters({ ...filters, search: value });
              }
            }}
          />
        )}
        <Button.IconButton onClick={() => openFiltersModal()}>
          <FiltersIcon />
        </Button.IconButton>
        {hasUserFilter && (
          <TeamFilter
            screen={screen}
            filters={filters}
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            tab={activeTab}
          />
        )}
      </FiltersContainer>
      <SimpleActiveFilterContainer>
        {depotSelection && (
          <AppliedFilterBox
            filterType={FilterType.DEPOT}
            removeFilter={removeFilter}
            onClick={() => openFiltersModal('depot')}
            text={depotSelection?.title ?? ''}
            isDefault={filters.depot?.id === defaultUserDepot?.id}
          />
        )}
        {hasUserFilter && selectedTeam && (
          <AppliedFilterBox
            filterType={FilterType.TEAM}
            removeFilter={removeFilter}
            text={selectedTeam.name}
          />
        )}
        {hasUserFilter && filters?.members && filters.members?.length > 0 && (
          <AppliedFilterBox
            filterType={FilterType.MEMBER}
            removeFilter={removeFilter}
            text={`${filters.members?.length} ${getText(
              selectedTeam?.members
                ? 'insights_compliance_team_members_filter_additional_users_selected'
                : 'insights_compliance_team_members_filter_users_selected',
            )}`}
          />
        )}
        {startDate && endDate && (
          <AppliedFilterBox
            filterType={FilterType.DATE_RANGE}
            removeFilter={removeFilter}
            onClick={() => openFiltersModal('date')}
            text={`${formatDate(startDate)} to ${formatDate(endDate)}`}
            isDefault={
              isSameDay(startDate, sevenDaysAgoString) &&
              isSameDay(endDate, todayString)
            }
          />
        )}
        {hasWeekendFilter && filters?.weekendIncluded && (
          <AppliedFilterBox
            filterType={FilterType.WEEKEND}
            removeFilter={removeFilter}
            text={getText('filter_data_weekend_included')}
          />
        )}
        {hasShiftDurationFilter &&
          filters?.fraType &&
          filters.fraType?.value && (
            <AppliedFilterBox
              filterType={FilterType.SHIFT_DURATION}
              removeFilter={removeFilter}
              text={filters.fraType.title}
            />
          )}
      </SimpleActiveFilterContainer>
    </ResponsiveContentWrapper>
  );
};

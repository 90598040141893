import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile, useLocalStorage, useQueryParams } from 'hooks';
import { fetchJobs, setJobFilters } from 'store/actions/jobs';
import RootState from 'model/State/RootState';
import { ModalType } from 'model/enum/ModalType';
import { useNavigate, useParams } from 'react-router-dom';
import { selectActiveJobTab, selectJobsView } from 'store/selectors';
import {
  INITIAL_FILTERS,
  JOB_OVERVIEW,
  LEGACY_VIEW,
  TIMELINE,
} from 'utils/constants';
import { scrollToTop } from 'helpers/table';

type QueryParamProps = {
  modal?: string;
};

const useJobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { filters, sort } = useSelector((state: RootState) => state.jobs);
  const activeJobTab = useSelector(selectActiveJobTab);
  const [modal, setModal] = useState<ModalType | null>(null);

  const view = useSelector(selectJobsView);
  const isMobile = useIsMobile();

  const {
    params,
    getPathWithQueryParams,
  }: {
    params: QueryParamProps;
    getPathWithQueryParams: (pathname: string) => string;
  } = useQueryParams();
  const { jobId } = useParams();
  const [jobFiltersInStorage, setJobFiltersInStorage] = useLocalStorage(
    'jobFilters',
    {
      ...INITIAL_FILTERS,
    },
  );

  const setFilters = (newFilters) => {
    dispatch(setJobFilters(newFilters));
    setJobFiltersInStorage(newFilters);
  };

  const clearFiltersOnViewSwitch = (newView: string | undefined) => {
    dispatch(setJobFilters({ ...INITIAL_FILTERS }, newView));
    setJobFiltersInStorage({ ...INITIAL_FILTERS });
  };

  // Open/Close modal based on "modal" query param
  useEffect(() => {
    if (
      params.modal &&
      Object.values(ModalType).includes(params.modal as ModalType)
    ) {
      setModal(params.modal as ModalType);
    } else {
      setModal(null);
    }
  }, [params]);

  const refreshJobs = useCallback(
    (isSwitchingTabsOnly: boolean | undefined = false) => {
      if (filters && sort) {
        if (isMobile) {
          dispatch(fetchJobs(filters, LEGACY_VIEW, undefined));
        } else {
          dispatch(fetchJobs(filters, view, undefined, isSwitchingTabsOnly));
        }

        scrollToTop();
      }
    },
    [dispatch, filters, sort, view, isMobile],
  );

  const navigateToOverviewTabOrJob = (
    overviewTab?: string,
    options?: object,
  ) => {
    if (activeJobTab === TIMELINE) {
      navigate({ pathname: `/jobs/${jobId}/timeline`, ...options });
    } else if (overviewTab && activeJobTab === JOB_OVERVIEW) {
      navigate({ pathname: overviewTab, ...options });
    } else {
      navigate(getPathWithQueryParams(`/jobs/${jobId}`), {
        ...options,
      });
    }
  };

  return {
    jobFiltersInStorage,
    setFilters,
    clearFiltersOnViewSwitch,
    modal,
    refreshJobs,
    navigateToOverviewTabOrJob,
  };
};

export default useJobs;

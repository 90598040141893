import { Loader } from 'components/ui';
import { useLocalStorage, useText } from 'hooks';
import RootState from 'model/State/RootState';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeHazardMajorStatus,
  fetchWorkspaceHazards,
  removeExistingHazard,
  resetError,
} from 'store/actions/settings/hazards';
import {
  selectGlobalLanguageSetting,
  selectOrganizationId,
} from 'store/selectors';
import {
  selectIsFetchingHazards,
  selectIsLoadingHazardChange,
  selectSortedHazards,
} from 'store/selectors/settings/hazards';
import {
  DataContainer,
  EmptyListContainer,
  H2,
  ItemsContainer,
  Wrapper,
} from './styled';
import HazardTable from './components/HazardTable';
import { Hazard } from 'model/Assessment/Hazard';
import { getLocalizedValue } from 'helpers/locales';
import { TITLE_PROPERTY_NAME } from '../../constants';
import { AssessmentDeleteConfirmationModal } from '../AssessmentDeleteConfirmationModal';
import AppFunctionalityInfoModal from '../../../Modal/AppFunctionalityInfoModal';
import { MajorHazardDescription } from '../../InfoModals';
import { MajorHazardDemo } from 'assets/images';
import { WarningTag } from 'components/ui/Tags/WarningTag';
import * as Analytics from 'utils/analytics';
import {
  EVENT_HAZARD_DELETE_CLICKED,
  EVENT_HAZARD_DELETE_CONFIRMED,
  EVENT_HAZARD_EDIT_CLICKED,
  EVENT_MAJOR_HAZARD_INFO_CLICKED,
} from '../../constants';
import { AddEditHazardForm } from './components/AddEditHazardForm';

const HazardSettings = (): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const [isFirstChange, setIsFirstChange] = useLocalStorage<boolean>(
    'majorHazardFirstChange',
    true,
  );
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const organisationId = useSelector(selectOrganizationId);
  const hazards = useSelector(selectSortedHazards(selectedWorkspace?.uuid));
  const isFetchingHazards = useSelector(selectIsFetchingHazards);
  const isLoadingHazardChange = useSelector(selectIsLoadingHazardChange);
  const [hazardInEdit, setHazardInEdit] = useState<Hazard | null>(null);
  const [isMajorHazardInfoOpen, setMajorHazardInfoOpen] =
    useState<boolean>(false);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const [hazardToDelete, setHazardToDelete] = useState<Hazard | null>(null);

  const hasHazardsWithoutControl =
    hazards.filter((hazard: Hazard) => !hazard?.mappedControlIds?.length)
      ?.length > 0;

  useEffect(() => {
    dispatch(resetError());
    if (selectedWorkspace?.uuid) {
      dispatch(fetchWorkspaceHazards(selectedWorkspace?.uuid));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const editHazard = (hazard: Hazard) => {
    Analytics.trackEvent(EVENT_HAZARD_EDIT_CLICKED, {
      hazardId: hazard?.id,
      hazardName: hazard?.title,
      hazardName_es: hazard?.titleEs,
      isMajor: hazard?.isMajor,
      workspaceUuid: selectedWorkspace?.uuid,
      organisationId,
    });
    setHazardInEdit(hazard);
  };

  const removeHazard = (hazard: Hazard) => {
    dispatch(resetError());
    setDeleteConfirmationOpen(true);
    setHazardToDelete(hazard);
    Analytics.trackEvent(EVENT_HAZARD_DELETE_CLICKED, {
      hazardId: hazard?.id,
      hazardName: hazard?.title,
      hazardName_es: hazard?.titleEs,
      isMajor: hazard?.isMajor,
      workspaceUuid: selectedWorkspace?.uuid,
      organisationId,
    });
  };

  const confirmDeleteHazard = () => {
    dispatch(removeExistingHazard(selectedWorkspace?.uuid, hazardToDelete));
    setDeleteConfirmationOpen(false);
    Analytics.trackEvent(EVENT_HAZARD_DELETE_CONFIRMED, {
      hazardId: hazardToDelete?.id,
      hazardName: hazardToDelete?.title,
      hazardName_es: hazardToDelete?.titleEs,
      isMajor: hazardToDelete?.isMajor,
      workspaceUuid: selectedWorkspace?.uuid,
      organisationId,
    });
  };

  const onMajorToggleChange = (hazard: Hazard, isMajor: boolean) => {
    if (isFirstChange) {
      setMajorHazardInfoOpen(true);
      setIsFirstChange(false);
    }
    dispatch(
      changeHazardMajorStatus(selectedWorkspace?.uuid, {
        ...hazard,
        isMajor,
      }),
    );
  };

  const openMajorHazardInfo = () => {
    setMajorHazardInfoOpen(true);
    Analytics.trackEvent(EVENT_MAJOR_HAZARD_INFO_CLICKED);
  };

  return (
    <Wrapper>
      {!isFetchingHazards && (
        <>
          <DataContainer>
            <H2>{getText('workspace_settings_manage_hazards_and_controls')}</H2>
            {hasHazardsWithoutControl && (
              <WarningTag
                text={getText(
                  'workspace_settings_hazard_ensure_control_assigned',
                )}
                extraStyles={{ width: 'fit-content' }}
              />
            )}
            <ItemsContainer>
              {hazards?.length > 0 ? (
                <HazardTable
                  hazards={hazards}
                  onEditClick={(hazard) => editHazard(hazard)}
                  onRemoveClick={(hazard) => removeHazard(hazard)}
                  onMajorHazardChange={(hazard, checked) =>
                    onMajorToggleChange(hazard, checked)
                  }
                  openInfo={openMajorHazardInfo}
                  loading={isLoadingHazardChange}
                />
              ) : (
                <EmptyListContainer>
                  <div>{getText('workspace_settings_no_hazards')}</div>
                  <div>{getText('workspace_settings_no_hazards_add_help')}</div>
                </EmptyListContainer>
              )}
            </ItemsContainer>
          </DataContainer>
          <AddEditHazardForm
            initialHazard={hazardInEdit}
            resetInitialHazard={() => setHazardInEdit(null)}
          />
        </>
      )}
      {isFetchingHazards && <Loader />}
      {isDeleteConfirmationOpen && (
        <AssessmentDeleteConfirmationModal
          isVisible={isDeleteConfirmationOpen}
          setVisible={setDeleteConfirmationOpen}
          title={getText('workspace_settings_hazard_delete_confirmation', {
            type: hazardToDelete
              ? getLocalizedValue(
                  hazardToDelete,
                  TITLE_PROPERTY_NAME,
                  globalAppLanguage,
                )
              : '',
          })}
          warningMessages={[getText('workspace_setting_hazard_delete_warning')]}
          saveButtonText={getText('continue_cta')}
          onButtonClick={confirmDeleteHazard}
          hasConfirmToggle
        />
      )}
      <AppFunctionalityInfoModal
        isVisible={isMajorHazardInfoOpen}
        setIsVisible={setMajorHazardInfoOpen}
        description={<MajorHazardDescription />}
        image={MajorHazardDemo}
        hasCrossIcon
      />
    </Wrapper>
  );
};

export default HazardSettings;

import { useSelector } from 'react-redux';
import { HazardTagContainer } from './styled';
import RootState from 'model/State/RootState';
import { useText } from 'hooks';
import { getSingularOrPluralText } from 'helpers/names';

export const HazardsTag = ({ text }): JSX.Element => {
  const getText = useText();
  const { selectedHazards } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );

  return (
    <HazardTagContainer>{`${selectedHazards.length} ${getSingularOrPluralText(
      selectedHazards,
      getText('rams_summary_hazard'),
      getText('rams_summary_hazard_plural'),
    )} ${text}`}</HazardTagContainer>
  );
};

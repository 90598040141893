import { Permit as PermitIcon } from 'assets/icons';
import PermitStatus from 'components/views/Protected/Jobs/Permits/PermitStatus';
import { useText, useQueryParams } from 'hooks';
import { useSelector } from 'react-redux';
import * as Analytics from 'utils/analytics';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { Message } from 'model/Message';
import JobData from 'model/JobData';

import { MessageWithAction } from './MessageWithAction';
import { getLocalizedValue } from 'helpers/locales';
import { TITLE_PROPERTY_NAME } from 'components/views/Protected/Jobs/Settings/constants';

type Props = {
  message: Message;
  activeJob: JobData;
  onClick: () => void;
};

export const Permit = Analytics.withTrackEvent(
  ({ message, activeJob, onClick }: Props): JSX.Element => {
    const getText = useText();
    const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
    const { getPathWithQueryParams } = useQueryParams();

    const type = getText('job_details_timeline_action_permit');
    const subtype = getLocalizedValue(
      message.target,
      TITLE_PROPERTY_NAME,
      globalAppLanguage,
    );
    const to = getPathWithQueryParams(
      `/jobs/${activeJob.id}/permits/${message.target.id}`,
    );

    return (
      <MessageWithAction
        message={message}
        onClick={onClick}
        to={to}
        actionIcon={<PermitIcon />}
        type={type}
        subtype={subtype}
      >
        <PermitStatus lastApproval={message.target.lastApproval} />
      </MessageWithAction>
    );
  },
);

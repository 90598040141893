import { PayloadAction } from '@reduxjs/toolkit';
import { updateEvidence } from 'api/Evidence';
import { put, takeLatest } from 'redux-saga/effects';
import {
  sendWrapUpFeedback,
  sendWrapUpFeedbackError,
  sendWrapUpFeedbackReason,
  sendWrapUpFeedbackReasonError,
  sendWrapUpFeedbackReasonSuccess,
  sendWrapUpFeedbackSuccess,
} from 'store/slices/evidences';
import { setGlobalError } from 'store/slices/notifications';
import * as Logger from 'utils/logger';

function* updateEvidenceVideoFeedbackFn(
  action: PayloadAction<{
    evidenceId: string;
    videoFeedback: number | null;
  }>,
) {
  try {
    yield updateEvidence({
      evidenceId: action.payload.evidenceId,
      videoFeedback: action.payload.videoFeedback,
    });

    yield put(sendWrapUpFeedbackSuccess());
  } catch (err) {
    Logger.error(err);
    yield put(sendWrapUpFeedbackError());
    yield put(setGlobalError(err));
  }
}

function* updateEvidenceVideoFeedbackReasonFn(
  action: PayloadAction<{
    evidenceId: string;
    videoFeedbackReason: string;
  }>,
) {
  try {
    yield updateEvidence({
      evidenceId: action.payload.evidenceId,
      videoFeedbackReason: action.payload.videoFeedbackReason,
    });

    yield put(sendWrapUpFeedbackReasonSuccess());
  } catch (err) {
    Logger.error(err);
    yield put(sendWrapUpFeedbackReasonError());
    yield put(setGlobalError(err));
  }
}

export default function* evidencesWatcher() {
  yield takeLatest(sendWrapUpFeedback.type, updateEvidenceVideoFeedbackFn);
  yield takeLatest(
    sendWrapUpFeedbackReason.type,
    updateEvidenceVideoFeedbackReasonFn,
  );
}

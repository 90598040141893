import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from 'theme';

const { colors, mixins, media } = theme;

export const DesktopContainer = styled.div`
  position: sticky;
  min-height: 40px;
  display: flex;
  background-color: ${colors.black};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
`;

export const DesktopTabContainer = styled.div`
  width: 100%;
  ${mixins.flexFullCenter};
  z-index: 10;
`;

export const DesktopTab = styled(({ isActive, to, ...props }) => (
  <Link to={to} {...props} />
))`
  width: 50%;
  height: 100%;
  ${mixins.flexFullCenter};
  color: ${colors.white};
  text-align: center;
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? colors.yellow : colors.black)};
  text-decoration: none;
  box-sizing: border-box;
`;

export const MobileContainer = styled.div`
  display: none;
  ${media.sm`
    position: sticky;
    height: 40px;
    display: flex;
    background-color: ${colors.black};    
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
  `}
`;

export const MobileTab = styled(({ isActive, to, ...props }) => (
  <Link to={to} {...props} />
))`
  width: 50%;
  ${mixins.flexFullCenter};
  color: ${({ isActive }) => (isActive ? colors.white : colors.grey)};
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? colors.yellow : colors.darkGrey)};
  text-decoration: none;
`;

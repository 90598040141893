import { Cross } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { fontSizes, fonts, media } = theme;

export const SplitWrapper = styled.div<{ imageFirst?: boolean }>`
  display: flex;
  justify-content: flex-end;
  ${({ imageFirst }) => imageFirst && `flex-direction: row-reverse`};
`;

export const HalfSection = styled.div<{ $backgroundColor?: string }>`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ $backgroundColor }) =>
    $backgroundColor && `background-color: ${$backgroundColor}`};
`;

export const TextSection = styled.div`
  padding: 32px;
  padding-bottom: 0;
  ${media.xl`
    padding: 16px;
  `}
`;

export const Title = styled.div<{ variant?: string }>`
  ${({ variant }) =>
    variant === 'image'
      ? `font-size: ${fontSizes.xl}; font-family: ${fonts.bo}`
      : `font-size: ${fontSizes.default};`}
  margin: 20px 13px;
`;

export const CrossIcon = styled(Cross)<{ color: string }>`
  height: 22px;
  position: absolute;
  right: 20px;
  top: 15px;
  width: 22px;
  cursor: pointer;
  .fill-selector {
    fill: ${({ color }) => color};
  }
`;

export const JobNumber = styled.a`
  text-decoration: underline;
  padding-left: 5px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 300px;
`;

export const ListIndex = styled.span`
  flex-basis: 2.5%;
  text-align: end;
`;

export const JobItem = styled.div`
  display: flex;
`;

export const Description = styled.div<{ variant?: string }>`
  ${({ variant }) =>
    variant === 'image' ? 'padding: 20px 0;' : 'margin: 0px 0px 20px 13px;'}

  > div {
    margin-bottom: 20px;
  }
`;

export const DemoImage = styled.img`
  width: 100%;
  display: block;
  margin: auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 32px;
  padding-right: 32px;
`;

import { useText } from 'hooks';
import {
  MessageContainer,
  SmileyIcon,
  ThanksContainer,
  ThanksTitle,
} from './styled';

export const ThanksMessage = () => {
  const getText = useText();

  return (
    <ThanksContainer>
      <SmileyIcon />
      <MessageContainer>
        <ThanksTitle>
          {getText('wrap_up_summaries_feedback_thank_you_title')}
        </ThanksTitle>
        <div>{getText('wrap_up_summaries_feedback_thank_you_message')}</div>
      </MessageContainer>
    </ThanksContainer>
  );
};

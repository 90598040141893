import { Paper } from '@mui/material';
import { Job } from 'api';
import { InterventionRecorded, AlertRed, AlertOrange } from 'assets/icons';
import { Loader } from 'components/ui';
import { convertToHourAndMinute, getHoursBetween } from 'helpers/dates';
import { useIsMounted, useText } from 'hooks';
import { SortOrder } from 'model/enum/SortOrder';
import RootState from 'model/State/RootState';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import * as Analytics from 'utils/analytics';
import FatigueModal from '../FatigueModal';
import { CompletedFatigueRiskAssessmentModal } from '../CompletedFatigueRiskAssessmentModal';
import { convertFraTypeToNumber } from '../fra-type-to-number';
import { ConfirmModal } from 'components/ui/Modal';
import Filters from 'model/Filters';
import { getSelectedMemberIds } from 'helpers/filter';
import Team from 'model/Team';
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import Sort from 'model/Sort';
import { getSortModel } from 'helpers/table';
import { Pagination } from 'components/ui/Display';
import { RowWrapper, StyledDataGrid } from '../../styles';
import { SkeletonTable } from '../SkeletonTable';
import {
  EVENT_CONFIRM_SHIFT_END,
  EVENT_FATIGUE_LIVE_VIEW_SORT_BY,
  EVENT_FRA_SUBMITTED,
  EVENT_STOP_TIMER,
  EVENT_VIEW_FRA,
  FATIGUE_LIVE_PAGE_SIZE,
  fatigueLiveDataFields,
} from '../../constants';
import { CLICKED } from 'utils/constants';
import {
  fetchFatigueLive,
  selectIsLoadingLiveData,
  selectLiveData,
} from 'store/slices/fatigue';
import { FatigueLiveRowItem } from 'model/FatigueManager/FatigueLiveRowItem';
import { FatigueLiveViewItem } from 'model/FatigueManager/FatigueLiveViewItem';
import { FatigueRiskAssessment } from 'model/FatigueManager/FatigueRiskAssessment';
import {
  Colorable,
  ColumnButton,
  CurrentHourContainer,
  DataContainer,
  DataRow,
  EmptyTableText,
  FRAButtons,
  FraHour,
  FraIconContainer,
  FraWrapper,
  H2,
  StopTimerButton,
  StyledGreyTick,
  StyledRedCross,
  StyledTableContainer,
  TotalWorkedHours,
} from './styled';
import { NewFatigueRiskAssessmentModal } from '../NewFatigueRiskAssessmentModal';
import { Type } from './constants';
import FraForm from 'model/FatigueManager/FraForm';
import { getQuestionsForAnalytics } from 'model/FatigueManager/helpers';
import { stringifyUserIds } from 'api/Insights/helpers';

const Hour_12 = 12 * 60 * 60;
const Hour_48 = 48 * 60 * 60;
const Hour_55 = 55 * 60 * 60;
const Hour_60 = 60 * 60 * 60;

type Props = {
  depotId: number;
  filters: Filters;
  selectedTeam: Team | null;
};

type LongShiftModalContent = {
  name: string;
  hours: number;
  endTime: Date;
};

// FatigueManagerLiveData
const FatigueManagerLiveData = ({
  depotId,
  filters,
  selectedTeam,
}: Props): JSX.Element => {
  const { currentUser, selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const dispatch = useDispatch();
  const { results, count: total, page } = useSelector(selectLiveData) ?? {};
  const pageCount = total ? Math.ceil(total / FATIGUE_LIVE_PAGE_SIZE) : 0;
  const isLoading = useSelector(selectIsLoadingLiveData);
  const initialSort = {
    field: fatigueLiveDataFields.NAME,
    order: SortOrder.ASCENDING,
  };
  const [sort, setSort] = useState<Sort>(initialSort);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCompletedFraModal, setShowCompletedFraModal] =
    useState<boolean>(false);
  const [showNewFraModal, setShowNewFraModal] = useState<boolean>(false);
  const [showLongShiftWarning, setShowLongShiftWarning] =
    useState<boolean>(false);
  const [longShiftModalContent, setLongShiftModalContent] =
    useState<LongShiftModalContent | null>(null);
  const [shiftConfirmed, setShiftConfirmed] = useState<boolean>(false);
  const [selectedFatigueLiveRowItem, setSelectedFatigueLiveRowItem] =
    useState<FatigueLiveRowItem>();
  const [selectedFRAIndex, setSelectedFRAIndex] = useState<number | null>(null);
  const isMounted = useIsMounted();
  const getText = useText();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  const userFilters = getSelectedMemberIds(filters, selectedTeam);
  const userIds = stringifyUserIds(userFilters);

  const riskLevelToText = (level) => {
    switch (level) {
      case 2:
        return getText('fatigue_manager_live_data_risk_level_high');
      case 1:
        return getText('fatigue_manager_live_data_risk_level_medium');
      case 0:
      default:
        return '-';
    }
  };

  const renderIcon = (fra) => {
    if (fra.interventions.length > 0) {
      return <InterventionRecorded />;
    }
    if (fra.form !== null) {
      return <StyledGreyTick />;
    }
    return <StyledRedCross />;
  };

  const columns: GridColDef[] = [
    {
      field: fatigueLiveDataFields.NAME,
      headerName: getText('fatigue_manager_table_header_name'),
      renderCell: (props: GridRenderCellParams) => {
        const { row: rowData }: { row: FatigueLiveRowItem } = props;

        return (
          <RowWrapper>
            {rowData.user.fullName}
            {rowData.user.phoneNumber && <div>{rowData.user.phoneNumber}</div>}
          </RowWrapper>
        );
      },
    },
    {
      field: fatigueLiveDataFields.REGION,
      headerName: getText('fatigue_manager_table_header_region'),
      hidden: depotId !== -1,
    },
    {
      field: fatigueLiveDataFields.SHIFT_TIME_SINCE_LAST_BREAK,
      headerName: getText(
        'fatigue_manager_table_header_hours_worked_since_rest',
      ),
      renderCell: (props: GridRenderCellParams) => {
        const { row: rowData }: { row: FatigueLiveRowItem } = props;

        const isOver12Hours = rowData.shiftTimeSinceLastBreak > Hour_12;

        return (
          <CurrentHourContainer>
            {isOver12Hours && <AlertRed />}
            <Colorable
              variant={isOver12Hours ? Type.VERY_HIGH_RISK : undefined}
            >
              {rowData.shiftTimeSinceLastBreakLabel}
            </Colorable>
            {rowData.currentShiftTime > 0 && (
              <StopTimerButton onClick={() => onStopTimer(rowData)}>
                {getText('fatigue_manager_live_data_stop_timer')}
              </StopTimerButton>
            )}
          </CurrentHourContainer>
        );
      },
    },
    {
      headerName: getText(
        'fatigue_manager_table_header_total_hours_worked_7_days',
      ),
      field: fatigueLiveDataFields.TOTAL_SHIFT_TIME,
      renderCell: (props: GridRenderCellParams) => {
        const { row: rowData }: { row: FatigueLiveRowItem } = props;

        const type =
          rowData.totalShiftTime >= Hour_60
            ? Type.VERY_HIGH_RISK
            : rowData.totalShiftTime >= Hour_55
              ? Type.HIGH_RISK
              : rowData.totalShiftTime >= Hour_48
                ? Type.MEDIUM_RISK
                : Type.LOW_RISK;

        return (
          <TotalWorkedHours variant={type}>
            {type === Type.VERY_HIGH_RISK && <AlertRed />}
            {type === Type.HIGH_RISK && <AlertOrange />}
            {rowData.totalShiftTimeLabel}
          </TotalWorkedHours>
        );
      },
    },
    {
      field: fatigueLiveDataFields.FRA,
      headerName: getText(
        'fatigue_manager_table_header_fatigue_risk_assessment',
      ),
      hidden: !currentUser?.permissions?.includes('fatigue_risk_assessment_v1'),
      renderCell: (props: GridRenderCellParams) => {
        const { row: rowData }: { row: FatigueLiveRowItem } = props;

        if (rowData.fatigueRiskAssessments?.length > 0) {
          return (
            <FRAButtons>
              {rowData.fatigueRiskAssessments.map(
                (fra: FatigueRiskAssessment, index: number) => (
                  <FraWrapper key={`fra-${fra.id}`}>
                    <FraIconContainer>{renderIcon(fra)}</FraIconContainer>
                    <div>
                      <FraHour
                        isRed={
                          !fra.interventions?.length &&
                          (fra.fraType === 'fra-16' || fra.fraType === 'fra-14')
                        }
                      >
                        {convertFraTypeToNumber(fra.fraType)}{' '}
                        {getText('fatigue_manager_hours')}
                      </FraHour>
                      {index === 0 && fra.form === null ? (
                        <ColumnButton
                          onClick={() => onCompleteVRA(rowData, index)}
                        >
                          {getText('fatigue_manager_table_complete')}
                        </ColumnButton>
                      ) : (
                        fra.form !== null && (
                          <ColumnButton
                            onClick={() => onViewFRA(rowData, index)}
                          >
                            {getText('fatigue_manager_table_view')}
                          </ColumnButton>
                        )
                      )}
                    </div>
                  </FraWrapper>
                ),
              )}
            </FRAButtons>
          );
        }
        return <></>;
      },
    },
    {
      field: fatigueLiveDataFields.PERCEIVED_RISK,
      headerName: getText('fatigue_manager_table_header_perceived_risk'),
      renderCell: (props: GridRenderCellParams) => {
        const { row: rowData }: { row: FatigueLiveRowItem } = props;

        const text = riskLevelToText(rowData.perceivedRisk);

        return (
          <Colorable
            variant={
              rowData.perceivedRisk === 2 ? Type.VERY_HIGH_RISK : undefined
            }
          >
            {text}
          </Colorable>
        );
      },
    },
  ].filter((column) => !column.hidden);

  const onStopTimer = (record: FatigueLiveRowItem) => {
    Analytics.trackEvent(EVENT_STOP_TIMER, {
      shift_id: record.currentShiftId,
    });
    setShowModal(true);
    setSelectedFatigueLiveRowItem(record);
  };

  const onViewFRA = (record: FatigueLiveRowItem, index: number) => {
    setSelectedFatigueLiveRowItem(record);
    setSelectedFRAIndex(index);
    setShowCompletedFraModal(true);
    Analytics.trackEvent(EVENT_VIEW_FRA, {
      shift_id: record.currentShiftId,
      fra_type: record.fatigueRiskAssessmentTitles[index],
    });
  };

  const onCompleteVRA = (record: FatigueLiveRowItem, index: number) => {
    setSelectedFatigueLiveRowItem(record);
    setSelectedFRAIndex(index);
    setShowNewFraModal(true);
  };

  const endShift = async (endTime: Date) => {
    Analytics.trackEvent(EVENT_CONFIRM_SHIFT_END, {
      shift_id: selectedFatigueLiveRowItem?.currentShiftId,
    });
    await Job.endShiftForUser(
      selectedFatigueLiveRowItem?.currentShiftId,
      endTime?.setSeconds(0, 0),
    );
    requestPageData(page, filters.search);
  };

  const onStopTimerConfirm = (endTime: Date) => {
    const hoursSinceShiftStart = getHoursBetween(
      endTime,
      selectedFatigueLiveRowItem?.currentShiftStartedAt,
    );
    if (hoursSinceShiftStart >= 12) {
      setShowLongShiftWarning(true);
      setLongShiftModalContent({
        endTime: endTime,
        hours: hoursSinceShiftStart,
        name: selectedFatigueLiveRowItem?.user.fullName || '',
      });
    } else {
      endShift(endTime);
      setShowModal(false);
      setShiftConfirmed(true);
    }
  };

  const rows = results?.map(
    (result: FatigueLiveViewItem, index: number): FatigueLiveRowItem => {
      return {
        ...result,
        id: index,
        key: `data-${index}`,
        shiftTimeSinceLastBreakLabel: `${convertToHourAndMinute(
          result.shiftTimeSinceLastBreak,
        )} ${getText('fatigue_manager_hours')}`,
        totalShiftTimeLabel: `${convertToHourAndMinute(
          result.totalShiftTime,
        )} ${getText('fatigue_manager_hours')}`,
        fatigueRiskAssessmentTitles: result.fatigueRiskAssessments.map(
          (fra: FatigueRiskAssessment) =>
            getText('fatigue_manager_table_hour_fatigue_risk_assessment', {
              hour: convertFraTypeToNumber(fra.fraType),
            }),
        ),
      };
    },
  );

  const requestPageData = (page, search) => {
    dispatch(
      fetchFatigueLive({
        depotId,
        search,
        selectedWorkspaceId: selectedWorkspace?.uuid,
        userIds,
        sort,
        page,
        pageSize: FATIGUE_LIVE_PAGE_SIZE,
      }),
    );
  };

  useEffect(
    () => {
      requestPageData(1, filters.search);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      depotId,
      filters.search,
      shiftConfirmed,
      isMounted,
      globalAppLanguage,
      sort,
      userIds,
    ],
  );

  return (
    <>
      <DataContainer>
        <H2>{getText('fatigue_manager_live_view_title')}</H2>
        <DataRow>
          <StyledTableContainer component={Paper}>
            {!rows || isLoading ? (
              <SkeletonTable
                columns={columns}
                pageSize={FATIGUE_LIVE_PAGE_SIZE}
              />
            ) : (
              <StyledDataGrid
                rows={rows}
                columns={columns}
                isEmpty={!rows?.length}
                width={`${100 / columns?.length}%`}
                getRowHeight={() => 'auto'}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableExtendRowFullWidth
                disableRowSelectionOnClick
                disableColumnResize
                rowsPerPageOptions={[]}
                onColumnHeaderClick={(params: GridColumnHeaderParams) =>
                  Analytics.trackEvent(
                    `${EVENT_FATIGUE_LIVE_VIEW_SORT_BY}-${params.field}-${CLICKED}`,
                    {
                      depotId,
                      startDate: filters.startDate,
                      endDate: filters.endDate,
                      sort,
                      rowsOfData: total,
                    },
                  )
                }
                sortingOrder={['asc', 'desc']}
                sortingMode='server'
                sortModel={[{ field: sort.field, sort: sort.order }]}
                onSortModelChange={(model: GridSortModel) =>
                  setSort(getSortModel(sort, model[0]))
                }
                initialState={{
                  sorting: {
                    sortModel: [
                      {
                        field: initialSort.field,
                        sort: initialSort.order,
                      },
                    ],
                  },
                }}
                autoHeight
                slots={{
                  noRowsOverlay: () => (
                    <EmptyTableText>
                      {getText('fatigue_manager_no_data')}
                    </EmptyTableText>
                  ),
                  footer: () => {
                    return (
                      page &&
                      pageCount > 1 && (
                        <Pagination
                          count={pageCount}
                          page={page}
                          onChange={(page) =>
                            requestPageData(page, filters.search)
                          }
                        />
                      )
                    );
                  },
                  loadingOverlay: () => <Loader />,
                }}
              />
            )}
          </StyledTableContainer>
        </DataRow>
      </DataContainer>
      {showModal && (
        <FatigueModal
          selectedFatigueLiveRowItem={selectedFatigueLiveRowItem}
          isVisible={showModal}
          setVisible={setShowModal}
          onButtonClick={onStopTimerConfirm}
        />
      )}
      {showLongShiftWarning && longShiftModalContent && (
        <ConfirmModal
          warningMessage={getText(
            'fatigue_manager_live_data_stop_timer_long_shift_warning',
            longShiftModalContent,
          )}
          isVisible={showLongShiftWarning}
          closeModal={() => {
            setShowLongShiftWarning(false);
            setLongShiftModalContent(null);
          }}
          onButtonClick={() => {
            if (longShiftModalContent) {
              endShift(longShiftModalContent.endTime);
              setShowLongShiftWarning(false);
              setLongShiftModalContent(null);
              setShowModal(false);
              setShiftConfirmed(true);
            }
          }}
          buttonText={getText('confirm')}
        />
      )}
      {showCompletedFraModal &&
        selectedFRAIndex !== null &&
        selectedFatigueLiveRowItem && (
          <CompletedFatigueRiskAssessmentModal
            fatigueLiveRowItem={selectedFatigueLiveRowItem}
            index={selectedFRAIndex}
            setVisible={setShowCompletedFraModal}
            onSuccess={() => {
              setShowCompletedFraModal(false);
              requestPageData(1, filters.search);
            }}
          />
        )}
      {showNewFraModal &&
        selectedFRAIndex !== null &&
        selectedFatigueLiveRowItem && (
          <NewFatigueRiskAssessmentModal
            selectedFatigueLiveRowItem={selectedFatigueLiveRowItem}
            index={selectedFRAIndex}
            setVisible={setShowNewFraModal}
            onSuccess={(form: FraForm) => {
              setShowNewFraModal(false);
              Analytics.trackEvent(EVENT_FRA_SUBMITTED, {
                fraType:
                  selectedFatigueLiveRowItem.fatigueRiskAssessments[
                    selectedFRAIndex
                  ].fraType,
                ...getQuestionsForAnalytics(form.sections),
              });
              requestPageData(1, filters.search);
            }}
          />
        )}
    </>
  );
};

export default FatigueManagerLiveData;

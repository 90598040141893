import { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { InsightsUpsell as InsightsUpsellImage } from 'assets/images';
import { Job } from 'api';
import { Check } from 'assets/icons';
import { useText } from 'hooks';

const { colors, fonts, fontSizes } = theme;

// Styled Components
const Header = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 180px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: ${fontSizes.xxl};
  color: ${colors.darkGrey};
`;

const Subtitle = styled.div`
  font-size: ${fontSizes.xl};
  color: ${colors.darkGrey};
`;

const Button = styled.div`
  background-color: ${colors.blue};
  border-radius: 3px;
  padding: 6px 24px;
  margin-top: 30px;
  color: ${colors.white};
  font-family: ${fonts.bold};
  cursor: pointer;
`;

const Image = styled.img`
  width: 55%;
  height: 55%;
`;

const CheckIcon = styled(Check)`
  height: 20px;
  width: 20px;
  margin-left: 5px;
  padding-top: 5px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

const Note = styled.div`
  padding-top: 10px;
  font-size: ${fontSizes.s};
`;

const Italic = styled.i`
  padding-right: 3px;
`;

// InsightsUpsell
const InsightsUpsell = () => {
  const getText = useText();
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);

  const onButtonClick = async () => {
    setIsButtonClicked(true);
    await Job.postFeatureAccess('insights');
  };

  return (
    <Container>
      <Header>
        <Title>
          {getText('insights_upsell_discover')}{' '}
          <Italic>{getText('insights_upsell_fyld_insights')}</Italic>!
        </Title>
        <Subtitle>{getText('insights_upsell_upgrade_to_access')}</Subtitle>
        {isButtonClicked ? (
          <Button>
            {getText('insights_upsell_thank_you')} <CheckIcon />
          </Button>
        ) : (
          <Button onClick={() => onButtonClick()}>
            {getText('insights_upsell_upgrade')}
          </Button>
        )}
        {isButtonClicked && <Note>{getText('insights_upsell_in_touch')}</Note>}
      </Header>
      <Image src={InsightsUpsellImage} />
    </Container>
  );
};

export default InsightsUpsell;

import { Loader, Navbar } from 'components/ui';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Permit } from 'api';
import { getFieldById } from 'helpers/forms';
import { setGlobalError } from 'store/slices/notifications';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field } from 'model/Forms/Field';
import { InstructionContent, InstructionWrapper } from './styled';

export const Instruction = (): JSX.Element => {
  const { jobId, permitId, fieldId } = useParams();
  const dispatch = useDispatch();
  const [instructionData, setInstructionData] = useState<Field | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const data = await Permit.get(permitId ?? '');
        setInstructionData(getFieldById(data?.form, fieldId));
      } catch (err) {
        dispatch(setGlobalError(err));
      }
    })();
  }, [permitId, fieldId, dispatch]);

  return (
    <>
      <Navbar
        title={instructionData?.title}
        defaultBackPath={`/jobs/${jobId}/permits/${permitId}`}
        mobileOnly
      />
      {instructionData?.value ? (
        <InstructionWrapper>
          <InstructionContent>
            {parse(instructionData?.value)}
          </InstructionContent>
        </InstructionWrapper>
      ) : (
        <Loader />
      )}
    </>
  );
};

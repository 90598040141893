import styled from 'styled-components';
import theme from 'theme';
import { RadioGroup as MaterialRadioGroup } from '@mui/material';

const { colors, fonts, fontSizes, mixins } = theme;

export const Container = styled.div`
  ${mixins.flexColumn};
`;

export const Label = styled.p<{
  $marginBottom?: string;
  $marginRight?: string;
}>`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  color: ${({ color }) => color || colors.black};
  ${({ $marginBottom }) =>
    $marginBottom && `margin-bottom: ${$marginBottom} !important`}
  ${({ $marginRight }) =>
    $marginRight && `margin-bottom: ${$marginRight} !important`}
`;

export const StyledLabel = styled.label`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  color: ${({ color }) => color || colors.black};
  margin-bottom: 10px;
  margin-left: 0;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ErrorMessage = styled.span`
  color: ${colors.red};
  padding-top: 10px;
`;

export const StyledRadioGroup = styled(({ isMobile, ...rest }) => (
  <MaterialRadioGroup {...rest} />
))`
  &.MuiFormGroup-root {
    width: 100%;
  }
  .MuiFormControlLabel-root {
    display: inline-flex;
    justify-content: space-between;
    margin: 0 ${({ isMobile }) => (isMobile ? '15px' : '25px')};
    border-bottom: 1px solid ${colors.grey};
    &:last-of-type {
      border: none;
    }
    .MuiTypography-root {
      padding: 10px 0;
      font-family: ${fonts.bold};
      font-size: ${fontSizes.m};
    }
    .MuiRadio-root {
      color: ${colors.black};
    }
  }
`;

import { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { Dates } from 'helpers';
import { Evidence as EvidenceIcon, Camera, Video } from 'assets/icons';
import { Media } from 'components/ui';
import * as Analytics from 'utils/analytics';
import { useText } from 'hooks';
import { User } from 'model/User';
import {
  CreationDate,
  Creator,
  EvidenceCardContainer,
  EyeIcon,
  FlexBetweenContainer,
  FlexCenterContainer,
  Icon,
  InfoContainer,
  MiddleSection,
  Placeholder,
  Subtype,
  ThumbnailContainer,
  Title,
} from './styled';

type Props = {
  subtype: string;
  creator: User;
  date: string;
  to: string;
  thumbnail?: string;
  comments?: string;
};

// Card.Evidence
export const Evidence = Analytics.withTrackEvent(
  ({
    subtype,
    thumbnail,
    comments,
    creator,
    date,
    to,
    ...props
  }: Props): JSX.Element => {
    const [thumbnailURL, setThumbnailURL] = useState<string>();
    const getText = useText();

    useEffect(() => {
      (async () => {
        if (thumbnail) {
          const signedURL = await Storage.get(thumbnail);
          setThumbnailURL(signedURL);
        }
      })();
    }, [thumbnail]);

    const getIcon = () => {
      if (thumbnail?.includes('photos')) {
        return <Camera />;
      }
      if (thumbnail?.includes('videos')) {
        return <Video />;
      }
      return <EvidenceIcon />;
    };

    return (
      <EvidenceCardContainer to={to} {...props}>
        <ThumbnailContainer>
          {thumbnailURL ? (
            <Media kind='evidence' type='photo' url={thumbnailURL} photoCover />
          ) : (
            <Placeholder />
          )}
        </ThumbnailContainer>

        <InfoContainer>
          <FlexBetweenContainer>
            <FlexCenterContainer>
              <Icon component={getIcon()} />
              <Title $inline>{getText('evidence')}</Title>
            </FlexCenterContainer>

            <EyeIcon />
          </FlexBetweenContainer>

          <MiddleSection>
            <Subtype>{subtype}</Subtype>
            <Subtype>{comments?.substring(0, 60)}</Subtype>
          </MiddleSection>

          <FlexBetweenContainer>
            <Creator>{creator.fullName}</Creator>

            <CreationDate>
              {Dates.formatDateTime(
                date,
                getText('date_today'),
                getText('date_yesterday'),
              )}
            </CreationDate>
          </FlexBetweenContainer>
        </InfoContainer>
      </EvidenceCardContainer>
    );
  },
);

import {
  LIST_VIEW,
  LIST_VIEW_TAB,
  LEGACY_VIEW,
  MAP_VIEW_TAB,
  TIMELINE,
} from 'utils/constants';
import {
  SET_USER_VIEW_SELECTION,
  CLEAR_USER_VIEW_SELECTION,
  SET_ACTIVE_JOB_TAB,
  SET_TAB,
  SET_PREVIOUS_TIMELINE_PATH,
} from '../actions/actionTypes';
import VersionsState from 'model/State/Versions';

const versions = (
  state: VersionsState = {
    userViewSelection: null,
    previousUserViewSelection: null,
    activeJobTab: null,
    userTabSelection: null,
    previousUserTabSelection: null,
  },
  action,
): VersionsState => {
  switch (action.type) {
    case SET_USER_VIEW_SELECTION:
      return {
        ...state,
        userViewSelection: action.userViewSelection,
        previousUserViewSelection: state.userViewSelection,
      };
    case CLEAR_USER_VIEW_SELECTION:
      return {
        ...state,
        userViewSelection: null,
        previousUserViewSelection: null,
        userTabSelection: null,
        previousUserTabSelection: null,
      };
    case SET_ACTIVE_JOB_TAB:
      return { ...state, activeJobTab: action.tab };
    case SET_PREVIOUS_TIMELINE_PATH: {
      if (action.previousTimelinePath) {
        // the routing would render the job timeline component if we don't nullify activeJobTab
        return { ...state, activeJobTab: null };
      } else {
        // if we are nullifying the selection it means we want to return to the timeline
        return { ...state, activeJobTab: TIMELINE };
      }
    }

    case SET_TAB:
      return {
        ...state,
        userTabSelection: action.tab,
        previousUserTabSelection: state.userTabSelection,
        userViewSelection:
          action.tab === MAP_VIEW_TAB || action.tab === LIST_VIEW_TAB
            ? LIST_VIEW
            : LEGACY_VIEW,
        previousUserViewSelection: state.userViewSelection,
      };
    default:
      return state;
  }
};

export default versions;

import { useText } from 'hooks';
import { Assessments } from 'model/Assessment';
import { HazardWithControl } from 'model/Assessment/Hazard';
import { ComputerVisionContainer, SparkleIcon } from './styled';

type Props = { hazardsWithControls: HazardWithControl[] };

export const ComputerVisionTag = ({
  hazardsWithControls,
}: Props): JSX.Element | null => {
  const getText = useText();

  const fyldIntelligenceHazards =
    Assessments.getNumberOfFyldIntelligenceHazards(hazardsWithControls);

  if (hazardsWithControls?.length > 0 && fyldIntelligenceHazards > 0) {
    return (
      <ComputerVisionContainer>
        <SparkleIcon />
        {getText('assessment_details_hazard_identified_by_intelligence', {
          number: fyldIntelligenceHazards,
        })}
      </ComputerVisionContainer>
    );
  }

  return null;
};

import styled from 'styled-components';
import { useText } from 'hooks';
import { Modal } from 'components/ui';
import { Details } from './Details';
import { useNavigate, useParams } from 'react-router-dom';

// Styled Components
const Container = styled.div`
  height: calc(100% - 65px);
`;

// DetailsModal
const DetailsModal = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const getText = useText();

  const navigateToOverviewTabOrJob = () => {
    navigate(`/jobs/${jobId}`);
  };

  return (
    <Modal.Base
      isOpen
      title={getText('job_detail_overview_edit_job_button')}
      onClose={() => navigateToOverviewTabOrJob()}
      width='850px'
      useMaxHeight
    >
      <Container>
        <Details />
      </Container>
    </Modal.Base>
  );
};

export default DetailsModal;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useLogs, useText } from 'hooks';
import { Dates } from 'helpers';
import theme from 'theme';
import { Note } from 'assets/icons';
import { Loader, Navbar } from 'components/ui';
import { useParams } from 'react-router-dom';

const { colors, fonts, fontSizes, media, mixins } = theme;

// Styled Components
const Header = styled.div`
  height: 50px;
  ${mixins.flexBetween};
  background-color: ${colors.lightGrey};
  padding: 0 25px;
  ${media.sm`
    padding: 0 15px;  
  `}
`;

const Creator = styled.p`
  font-family: ${fonts.bold};
`;

const CreationDate = styled.p`
  color: ${colors.darkGrey};
`;

const Section = styled.div`
  padding: 25px;
  max-height: 60vh;
  overflow: auto;
  ${media.sm`
    padding: 25px 15px; 
    max-height: 100vh; 
  `}
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 15px;
`;

const SectionTitle = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
`;

const IconBox = styled.div`
  ${mixins.flexFullCenter};
  border: 3px solid ${colors.black};
  padding: 6px;
  margin-left: 20px;
`;

const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: 20px;
  width: 20px;
`;

// Note.Show
const Show = (): JSX.Element => {
  const { jobId, logId } = useParams();
  const { log: note } = useLogs(logId);
  const getText = useText();

  useEffect(() => {
    if (logId && jobId) {
      Analytics.trackPage(constants.EVENT_PAGE_VIEW_NOTE, {
        jobId,
        logId,
      });
    }
  }, [jobId, logId]);

  return (
    <>
      <Navbar
        title={getText('note_detail_title')}
        defaultBackPath={`/jobs/${jobId}/logs`}
        mobileOnly
      />

      {note ? (
        <>
          <Header>
            <Creator>{note.createdBy.fullName}</Creator>
            <CreationDate>
              {Dates.formatDateTime(
                note.createdAt,
                getText('date_today'),
                getText('date_yesterday'),
              )}
            </CreationDate>
          </Header>

          <Section>
            <SectionHeader>
              <SectionTitle>{note.title}</SectionTitle>

              <IconBox>
                <Icon component={<Note />} />
              </IconBox>
            </SectionHeader>

            <p>{note.note}</p>
          </Section>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Show;

import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryParams, useText } from 'hooks';
import {
  EVENT_PAGE_VIEW_RESET_PASSWORD,
  EVENT_FEEDBACK_MODAL_CLICKED,
} from 'utils/constants';
import * as Analytics from 'utils/analytics';

import { Button, Card, Input, Modal } from 'components/ui';
import {
  completeNewPassword,
  resetPassword,
  resetSessionErrors,
} from 'store/actions/session';
import { FormContainer } from '../styled';
import RootState from 'model/State/RootState';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorContainer, H1, LoginForm, PaddedWrapper } from '../Login/styled';
import theme from 'theme';
import { LogoSlogan } from 'components/ui/Logos';

const { colors } = theme;

// ResetPassword
export const ResetPassword = (): JSX.Element => {
  const dispatch = useDispatch();
  const getText = useText();
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [localError, setLocalError] = useState<string>('');
  const [dispatchSent, setDispatchSent] = useState(false);
  const { params }: { params: { resetToken?: string } } = useQueryParams();
  const resetToken = useMemo(() => params.resetToken, [params]);
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, passwordError } = useSelector(
    (state: RootState) => state.session,
  );
  // eslint-disable-next-line
  const passwordRegex =
    /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=.*[(!@#$%^&*()|{}[\]:";'<>?,~_.\/\\]).{8,})\S$/gm; // eslint-disable-line

  useEffect(() => {
    dispatch(resetSessionErrors());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    Analytics.trackPage(EVENT_PAGE_VIEW_RESET_PASSWORD);

    if (!resetToken && !location.state) {
      navigate('/login');
    }
  }, [resetToken, navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async () => {
    if (newPassword !== newPasswordConfirmation) {
      setLocalError(getText('reset_password_passwords_not_match'));
      return;
    }

    // Check if password meets criteria:
    if (!passwordRegex.exec(newPassword)) {
      // Something's not right with the password
      setLocalError(`${getText('reset_password_ensure_conditions')}
        ${getText('reset_password_conditions_first')}
        ${getText('reset_password_conditions_second')}
        ${getText('reset_password_conditions_third')}
        ${getText('reset_password_conditions_fourth')}
        ${getText('reset_password_conditions_fifth')}
        ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~
      `);
      return;
    }

    if (location.state) {
      dispatch(
        completeNewPassword(
          email,
          location.state.temporaryPassword,
          newPassword,
        ),
      );
      setDispatchSent(true);
    } else {
      dispatch(resetPassword(email, resetToken, newPassword));
      setDispatchSent(true);
    }
  };

  const isDisabled = !(email && newPasswordConfirmation);

  return (
    <>
      <LogoSlogan />
      <FormContainer onSubmit={handleSubmit}>
        <LoginForm>
          <PaddedWrapper>
            <H1>{getText('reset_password_title')}</H1>

            <Input.Text
              id='email-reset-password'
              label={getText('reset_password_email')}
              labelColor={colors.white}
              error={passwordError || localError}
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
            />

            <Input.Password
              id='password-reset-password'
              label={getText('reset_password_new_password')}
              labelColor={colors.white}
              error={passwordError || localError}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />

            <Input.Password
              id='password-confirmation-reset-password'
              label={getText('reset_password_repeat_password')}
              labelColor={colors.white}
              error={passwordError}
              value={newPasswordConfirmation}
              onChange={(e) => setNewPasswordConfirmation(e.target.value)}
              onEnter={!isDisabled ? handleSubmit : undefined}
            />
            {(localError || passwordError) && (
              <ErrorContainer>
                <Card.ErrorMessage message={passwordError || localError} />
              </ErrorContainer>
            )}
            <Button.Primary
              text={getText('reset_password_title')}
              disabled={isDisabled}
              loading={isLoading}
              onClick={handleSubmit}
              width='240px'
            />
          </PaddedWrapper>
        </LoginForm>
      </FormContainer>
      {!isLoading && !passwordError && !localError && dispatchSent && (
        <Modal.Feedback
          title={getText('reset_password_success_title')}
          description={getText('reset_password_success_description')}
          linkText={getText('login')}
          linkPath='/login'
          event={EVENT_FEEDBACK_MODAL_CLICKED}
          eventProperties={{ source: 'password-reset-success' }}
        />
      )}
    </>
  );
};

import { useSelector } from 'react-redux';
import { Badge } from 'components/ui';
import { BackendUser } from 'model/BackendUser';
import RootState from 'model/State/RootState';
import { BadgeContainer, NoSelectionText, Selection, Spacer } from './styled';

type Props = {
  selectedUsers: BackendUser[];
  emptyPlaceholder: string;
  onUserRemove?: (user: BackendUser) => void;
  disabled?: boolean;
};

export const TeamMemberSelection = ({
  selectedUsers,
  onUserRemove,
  emptyPlaceholder,
  disabled = false,
}: Props): JSX.Element => {
  const { currentUser } = useSelector((state: RootState) => state.session);

  if (selectedUsers?.length > 0) {
    return (
      <Selection>
        {selectedUsers?.map((selectedUser) => (
          <BadgeContainer key={`selectedUser-${selectedUser.id}`}>
            <Badge.UserBadge
              user={selectedUser}
              isCurrentUser={currentUser.id === selectedUser.id}
              onDelete={onUserRemove ? () => onUserRemove(selectedUser) : null}
              vertical
              large
              extraStyles={{ padding: '0 10px', width: '100%' }}
              extraTextStyles={{ alignItems: 'center', maxHeight: '60px' }}
              disabled={disabled}
            />
          </BadgeContainer>
        ))}
        <Spacer />
      </Selection>
    );
  } else {
    return <NoSelectionText>{emptyPlaceholder}</NoSelectionText>;
  }
};

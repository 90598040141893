import { Footer } from '../Footer';
import { Slogan } from '../Misc/Slogan';
import { BrandContainer, FyldLogo } from './styled';

export const LogoSlogan: React.FC = () => {
  return (
    <BrandContainer to='/'>
      <FyldLogo />
      <Slogan />
      <Footer />
    </BrandContainer>
  );
};

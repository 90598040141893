import React from 'react';
import { Job } from 'model/Job/Job';
import { CommandCenterFilter } from 'model/enum/CommandCenterFilter';
import {
  EyeIcon,
  SummaryIconWrapper,
  SummaryItem,
  SummaryNumber,
  SummaryNumberWrapper,
  SummaryTitle,
  SummaryTitleWrapper,
} from './styled';

type Props = {
  id: CommandCenterFilter;
  title: string;
  onClick: (id: CommandCenterFilter) => void;
  data?: Job[];
  textColor?: string;
  isActive?: boolean;
  extraStyles?: React.CSSProperties;
};

export const InteractiveInformationBox = ({
  id,
  title,
  data,
  textColor,
  onClick,
  isActive = false,
  extraStyles,
}: Props): JSX.Element => {
  const currentData = data?.length;

  return (
    <SummaryItem
      style={extraStyles}
      $isActive={isActive}
      onClick={() => onClick(id)}
    >
      <SummaryIconWrapper>{isActive && <EyeIcon />}</SummaryIconWrapper>
      <SummaryNumberWrapper>
        <SummaryNumber color={textColor}>{currentData ?? 0}</SummaryNumber>
      </SummaryNumberWrapper>
      <SummaryTitleWrapper>
        <SummaryTitle color={textColor}>{title}</SummaryTitle>
      </SummaryTitleWrapper>
    </SummaryItem>
  );
};

import { Warning } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes } = theme;

export const Title = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  padding-bottom: 15px;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: 20px auto;
  padding: 20px;
`;

export const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const WarningIcon = styled(Warning)`
  height: 45px;
  width: 45px;
  min-height: 45px;
  min-width: 45px;
  margin-right: 20px;
  .fill-selector {
    fill: ${colors.red};
  }
`;

export const ConfirmWrapper = styled.div`
  display: flex;
  align-items: start;
  padding: 15px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

import { Button } from 'components/ui';
import { updateArray } from 'helpers/utils';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPhotosForJob } from 'store/actions/jobs';
import { v4 as uuidv4 } from 'uuid';
import { DraggableSection } from './DraggableSection';
import { TemplateSectionModal } from './TemplateSectionModal';
import RootState from 'model/State/RootState';
import { useParams } from 'react-router-dom';
import { useText } from 'hooks';
import * as constants from 'utils/constants';
import { Photo } from 'model/Media/Photo';
import { selectJobsView } from 'store/selectors';
import {
  MethodStatementSection,
  MethodStatementSectionTemplate,
  RamsStages,
} from 'model/Assessment/RAMS';
import { ButtonWrapper, PlusIconStyled, TagContainer } from './styled';
import {
  EVENT_RAMS_BLANK_SECTION_BUTTON_CLICKED,
  EVENT_RAMS_SUMMARY_FINISH_BUTTON_CLICKED,
  EVENT_RAMS_TEMPLATE_SECTION_BUTTON_CLICKED,
} from '../constants';
import {
  selectActiveRamsAssessment,
  selectIsEditTagDismissed,
  selectUserCanEditRams,
  setAssessmentStage,
  setRamsSections,
} from 'store/slices/ramsAssessments';
import {
  ActionButtons,
  ActionButtonsWrapper,
  MiddleContentContainer,
} from '../styled';
import { NotEditableTag } from '../NotEditableTag';
import { SectionHeader } from '../SectionHeader';
import { InfoTag } from 'components/ui/Tags/InfoTag';

export const SectionList = memo(() => {
  const { sections: savedSections, currentStage } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );
  const assessment = useSelector(selectActiveRamsAssessment);
  const view = useSelector(selectJobsView);
  const canUserEdit = useSelector(selectUserCanEditRams);
  const isEditTagDismissed = useSelector(selectIsEditTagDismissed);

  const { jobId } = useParams();
  const dispatch = useDispatch();
  const getText = useText();
  const emptySection: MethodStatementSection = {
    uuid: uuidv4(),
    orderId: 0,
    title: '',
    content: '',
    photos: [] as Photo[],
  };
  const [sections, setSections] = useState<MethodStatementSection[]>(
    savedSections?.length ? [...savedSections] : [emptySection],
  );
  const [showTemplates, setShowTemplates] = useState<boolean>(false);
  const [highestId, setHighestId] = useState<number>(
    savedSections?.length
      ? Math.max(...savedSections.map((section) => section.orderId))
      : 0,
  );

  useEffect(() => {
    if (currentStage === RamsStages.methodStatement) {
      dispatch(fetchPhotosForJob(jobId));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isSectionFinishDisabled = (): boolean => {
    const isSectionReady = sections.every(
      (savedSection) =>
        savedSection.title.trim() &&
        savedSection.content.trim() &&
        savedSection.content !== '<p></p>',
    );
    return !canUserEdit || !sections.length || !isSectionReady;
  };

  const onBlankSectionAdd = () => {
    setSections([...sections, { ...emptySection, orderId: highestId + 1 }]);
    setHighestId(highestId + 1);
  };

  const onTemplateSectionAdd = (template: MethodStatementSectionTemplate) => {
    setSections([
      ...sections,
      {
        ...emptySection,
        title: template.title,
        content: template.content,
        orderId: highestId + 1,
      },
    ]);
    setHighestId(highestId + 1);
    setShowTemplates(false);
  };

  const onSectionDelete = (selectedSection: MethodStatementSection) => {
    if (sections.length > 1) {
      setSections([
        ...sections.filter(
          (existingSection: MethodStatementSection) =>
            existingSection.uuid !== selectedSection.uuid,
        ),
      ]);
    }
  };

  const handleSectionChange = (section: MethodStatementSection) => {
    const newSections = sections.map((sectionItem: MethodStatementSection) => {
      if (sectionItem.uuid === section.uuid) {
        return section;
      }
      return sectionItem;
    });
    setSections(newSections);
  };

  const findSection = useCallback(
    (id: number) => {
      const section = sections.find(
        (sectionItem: MethodStatementSection) => sectionItem.orderId === id,
      );
      return {
        section: section ?? null,
        index: section ? sections.indexOf(section) : null,
      };
    },
    [sections],
  );

  const moveSection = useCallback(
    (id: number, atIndex: number) => {
      const { section, index } = findSection(id);
      if (section && index !== null) {
        const newSections = updateArray(sections, atIndex, index, section);
        setSections(newSections);
      }
    },
    [findSection, setSections, sections],
  );

  const [, drop] = useDrop(() => ({ accept: 'section' }));

  const onSectionFinishClick = () => {
    const methodStatementSections = assessment?.methodStatement?.sections;
    const isEdited =
      !methodStatementSections ||
      JSON.stringify(sections) !== JSON.stringify(methodStatementSections);
    dispatch(setRamsSections({ sections, isEdited }));
    dispatch(setAssessmentStage(RamsStages.methodStatementSummary));
  };

  return (
    <>
      <SectionHeader title={getText('rams_section_option_method')} />
      <MiddleContentContainer
        ref={drop}
        large={canUserEdit || isEditTagDismissed}
      >
        {sections.map((section) => (
          <>
            <DraggableSection
              key={section.orderId}
              section={section}
              onSectionChange={handleSectionChange}
              onDeleteClick={onSectionDelete}
              moveSection={moveSection}
              findSection={findSection}
              deleteDisabled={sections.length === 1}
            />
            {!section.title.trim() && (
              <TagContainer>
                <InfoTag
                  text={getText('rams_section_method_summary_missing_title')}
                />
              </TagContainer>
            )}
          </>
        ))}
        <ButtonWrapper>
          <Button.Secondary
            text={getText('rams_section_method_add_blank_section')}
            IconComponent={PlusIconStyled}
            onClick={onBlankSectionAdd}
            width='47.5%'
            disabled={!canUserEdit}
            event={EVENT_RAMS_BLANK_SECTION_BUTTON_CLICKED}
            eventProperties={{
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
          />
          <Button.Secondary
            text={getText('rams_section_method_add_template_section')}
            IconComponent={PlusIconStyled}
            onClick={() => setShowTemplates(true)}
            width='47.5%'
            disabled={!canUserEdit}
            event={EVENT_RAMS_TEMPLATE_SECTION_BUTTON_CLICKED}
            eventProperties={{
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
          />
        </ButtonWrapper>

        {showTemplates && (
          <TemplateSectionModal
            showModal={showTemplates}
            setShowModal={setShowTemplates}
            onTemplateSectionAdd={onTemplateSectionAdd}
          />
        )}
      </MiddleContentContainer>
      {!canUserEdit && <NotEditableTag />}
      <ActionButtonsWrapper>
        <ActionButtons>
          <Button.Primary
            onClick={onSectionFinishClick}
            text={getText('rams_summary_finish_button')}
            disabled={isSectionFinishDisabled()}
            event={EVENT_RAMS_SUMMARY_FINISH_BUTTON_CLICKED}
            eventProperties={{
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            extraStyles={{
              margin: 0,
              alignSelf: 'flex-end',
              height: 'inherit',
              maxHeight: '55px',
            }}
          />
        </ActionButtons>
      </ActionButtonsWrapper>
    </>
  );
});

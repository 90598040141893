import React, { ChangeEvent, KeyboardEvent, KeyboardEventHandler } from 'react';
import { useText } from 'hooks';
import { InputAdornment } from '@mui/material';
import {
  Container,
  GuidanceWrapper,
  InputContainer,
  Label,
  SearchIcon,
} from './styled';
import { LabelWrapper } from '../styled';

type Props = {
  id: string;
  value: string | number;
  Input: React.ElementType;
  label?: string;
  labelColor?: string;
  guidance?: string;
  error?: string | boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  optional?: boolean;
  required?: boolean;
  extraStyles?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  placeholder?: string;
  maxLength?: number;
  isTextArea?: boolean;
  hasBlackBackground?: boolean;
  isSearch?: boolean;
  onEnter?: () => void;
  autoFocusOnRender?: boolean;
};

// Base
export const Base = ({
  id,
  label,
  guidance,
  labelColor,
  error,
  value,
  onChange,
  Input,
  disabled = false,
  optional,
  required,
  extraStyles,
  inputStyle,
  placeholder = '',
  maxLength,
  isTextArea = false,
  hasBlackBackground = false,
  isSearch = false,
  onEnter,
  autoFocusOnRender,
}: Props): JSX.Element => {
  const getText = useText();

  const handleKeyUp: KeyboardEventHandler = (event: KeyboardEvent<Element>) => {
    if (event.key === 'Enter' && onEnter) {
      onEnter();
    }
  };

  return (
    <Container style={extraStyles}>
      {label && (
        <LabelWrapper>
          <Label htmlFor={id} color={labelColor} disabled={disabled}>
            {label}
          </Label>
          {optional && (
            <Label $optional={optional} htmlFor={id}>
              {getText('optional')}
            </Label>
          )}
          {required && (
            <Label required={required} htmlFor={id}>
              {getText('required')}
            </Label>
          )}
        </LabelWrapper>
      )}
      {guidance && <GuidanceWrapper>{guidance}</GuidanceWrapper>}

      <InputContainer
        disabled={disabled}
        $hasError={!!error}
        $hasBlackBackground={hasBlackBackground}
      >
        {isTextArea ? (
          <Input
            style={{
              overflow: 'auto',
              resize: 'none',
              height: '120px',
              paddingTop: '6px',
              ...inputStyle,
            }}
            placeholder={getText('description_hint')}
            id={id}
            value={value}
            onChange={onChange}
            disabled={disabled}
            multiline
            onKeyUp={handleKeyUp}
            InputProps={maxLength ? { maxLength } : {}}
            autoFocus={autoFocusOnRender}
          />
        ) : (
          <Input
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            onKeyUp={handleKeyUp}
            InputProps={{
              ...(maxLength && { maxLength }),
              ...(isSearch && {
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }),
            }}
            autoFocus={autoFocusOnRender}
          />
        )}
      </InputContainer>
    </Container>
  );
};

import {
  FETCH_DEPOTS,
  FETCH_DEPOTS_ERROR,
  FETCH_DEPOTS_SUCCESS,
} from './actionTypes';

export const fetchDepots = (workspaceUuid) => ({
  type: FETCH_DEPOTS,
  workspaceUuid,
});

export const fetchDepotsSuccess = (data) => ({
  type: FETCH_DEPOTS_SUCCESS,
  data,
});

export const fetchDepotsError = () => ({
  type: FETCH_DEPOTS_ERROR,
});

import { createSelector } from 'reselect';
import RootState from 'model/State/RootState';
import { WorkspaceItemType } from 'model/AdminPanel/WorkspaceSettings/WorkspaceItemType';

export const selectWorkspaceJobSubtypes = (state: RootState) =>
  state?.workspaceJobSubtype?.jobSubtypes;
const selectJobSubtypesByUuid = createSelector(
  selectWorkspaceJobSubtypes,
  (_: RootState, uuid: string) => uuid,
  (jobSubtypes: { [key: string]: WorkspaceItemType[] }, uuid: string) =>
    jobSubtypes?.[uuid] || [],
);
export const selectJobSubtypes = (uuid: string) => (state: RootState) =>
  selectJobSubtypesByUuid(state, uuid);
export const selectIsFetchingJobSubtypes = (state: RootState) =>
  state.workspaceJobSubtype.isFetchingJobSubtypes;
export const selectIsLoadingJobSubtypeChanges = (state: RootState) =>
  state.workspaceJobSubtype.isLoadingJobSubtypeChanges;
export const selectJobSubtypeErrors = (state: RootState) =>
  state.workspaceJobSubtype.jobSubtypeErrors;

import { useState, useEffect } from 'react';
import { Hazard as HazardApi } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMounted } from './useIsMounted';
import { setGlobalError } from 'store/slices/notifications';
import { Hazard } from 'model/Assessment/Hazard';
import RootState from 'model/State/RootState';

// useHazards
const useHazards = () => {
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const [hazards, setHazards] = useState<Hazard[]>([]);
  const dispatch = useDispatch();
  const isMounted = useIsMounted();

  useEffect(() => {
    (async () => {
      try {
        const data = await HazardApi.getAll(selectedWorkspace?.uuid);

        if (isMounted.current) {
          setHazards(data);
        }
      } catch (err) {
        dispatch(setGlobalError(err));
        console.error(err);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return hazards;
};

export default useHazards;

import theme from 'theme';
import { GridColDef } from '@mui/x-data-grid';
import styled from 'styled-components';
import { useText } from 'hooks';
import {
  LIST_VIEW_TAB,
  LIST_VIEW_PAGE_SIZE,
  jobV2Fields,
} from 'utils/constants';
import { useMemo } from 'react';
import { Tabs } from 'components/ui';
import MemoizedDataGrid from '../MemoizedDataGrid';
import { FeaturesInfoButton } from '../FeaturesInfoButton';

const { colors } = theme;

const ButtonsContainer = styled.div`
  display: flex;
`;

const SkeletonCell = styled.div<{ width: number }>`
  background-color: ${colors.lightGrey};
  height: 20px;
  width: ${({ width }) => width}%;
`;

type TableData = {
  id: number;
  key: number;
  job_id: number;
  fieldworkers: string;
  location: string;
  work_order_number: string;
  date_created: string;
  status: string;
  unread_messages: number;
  pending_job_blocker_count: number;
  most_recent_job_blocker_created_at: Date;
  sign_off_pending_assessment_count: number;
  most_recent_risk_assessment_created_at: Date;
  editable: boolean;
  safetyWarning: SafetyWarning | null;
  has_major_hazard: boolean;
};

type SafetyWarning = {
  type: string;
  advice: string;
  score: string;
  scoreLabel: string;
  message: string;
};

const SkeletonJobsTable = ({
  sortingDisabled,
}: {
  sortingDisabled?: boolean;
}): JSX.Element => {
  const getText = useText();
  const NUMBER_OF_TEST_DATA = 8;

  const generateSkeletonData = () => {
    const skeletonList: TableData[] = [];
    for (let i = 0; i < NUMBER_OF_TEST_DATA; i += 1) {
      skeletonList.push({
        id: i + 1,
        key: i + 1,
        job_id: i,
        fieldworkers: '-',
        location: '',
        work_order_number: '',
        date_created: '',
        status: '',
        unread_messages: 0,
        pending_job_blocker_count: 0,
        most_recent_job_blocker_created_at: new Date(),
        sign_off_pending_assessment_count: 0,
        most_recent_risk_assessment_created_at: new Date(),
        editable: false,
        safetyWarning: null,
        has_major_hazard: false,
      });
    }
    return skeletonList;
  };

  const skeletonColumns: GridColDef[] = useMemo(
    () => [
      {
        headerName: getText('jobs_table_header_pin_job'),
        field: jobV2Fields.IS_PINNED,
        sortable: false,
        headerClassName: 'is-pinned-cell walkthrough-step-pin',
        cellClassName: 'is-pinned-cell',
        renderCell: () => <SkeletonCell width={50} />,
      },
      {
        headerName: getText('jobs_table_header_fieldworkers'),
        field: jobV2Fields.FIELDWORKERS,
        sortable: false,
        headerClassName: 'fieldworker-cell',
        cellClassName: 'fieldworker-cell',
        renderCell: () => <SkeletonCell width={100} />,
      },
      {
        headerName: getText('jobs_table_header_action_required'),
        field: jobV2Fields.ACTION_REQUIRED,
        sortable: !sortingDisabled,
        headerClassName: 'action-cell',
        cellClassName: 'action-cell',
        renderCell: () => <SkeletonCell width={100} />,
      },
      {
        headerName: getText('jobs_table_header_last_activity'),
        field: jobV2Fields.LAST_ACTIVITY,
        sortable: !sortingDisabled,
        headerClassName: 'last-activity-cell',
        cellClassName: 'last-activity-cell',
        renderCell: () => <SkeletonCell width={75} />,
      },
      {
        headerName: getText('jobs_table_header_location'),
        field: jobV2Fields.LOCATION,
        sortable: false,
        headerClassName: 'location-cell',
        cellClassName: 'location-cell',
        renderCell: () => <SkeletonCell width={75} />,
      },
      {
        headerName: getText('jobs_table_header_work_order_number'),
        field: jobV2Fields.WORK_ORDER_NUMBER,
        sortable: false,
        headerClassName: 'work-order-number-cell',
        cellClassName: 'work-order-number-cell',
        renderCell: () => <SkeletonCell width={75} />,
      },
      {
        headerName: getText('jobs_table_header_status'),
        field: jobV2Fields.STATUS,
        sortable: false,
        headerClassName: 'status-cell',
        cellClassName: 'status-cell',
        renderCell: () => <SkeletonCell width={75} />,
      },
      {
        headerName: '',
        field: jobV2Fields.OPEN_BUTTON,
        sortable: false,
        headerClassName: 'open-button-cell',
        cellClassName: 'open-button-cell',
        renderCell: () => <></>,
        renderHeader: () => (
          <ButtonsContainer>
            <FeaturesInfoButton />
            <Tabs.JobView activeTab={LIST_VIEW_TAB} />
          </ButtonsContainer>
        ),
      },
    ],
    [getText, sortingDisabled],
  );

  return (
    <MemoizedDataGrid
      rows={generateSkeletonData()}
      isLoading={false}
      columns={skeletonColumns}
      sort={null}
      total={NUMBER_OF_TEST_DATA}
      pageSize={LIST_VIEW_PAGE_SIZE}
      page={1}
      title={getText('jobs_search_no_jobs')}
      subtitle={getText('jobs_search_no_jobs_list_subtitle')}
      isSkeleton
    />
  );
};

export default SkeletonJobsTable;

import { UploadStatus } from 'model/enum/UploadStatus';
import { Video } from 'model/Media/Video';
import {
  BackendHazardWithControl,
  Hazard,
  Hazards,
  HazardSource,
  HazardWithControl,
  SuggestedHazardWithControl,
} from './Hazard';
import { Control, Controls } from './Control';
import { User } from 'model/User';
import {
  BackendRamsReview,
  MethodStatement,
  RamsAssessments,
  RamsRating,
  RamsReview,
} from './RAMS';
import { mapToVideo } from 'api/Media/helpers';
import { mapToUser } from 'api/User/helpers';

export type Assessment = {
  id: number;
  jobId: number;
  workspaceUuid: string;
  type: string;
  typeEs: string;
  typeId: number;
  typeIsRams: boolean;
  video: Video | null;
  videoStatus: UploadStatus;
  hazards: Hazard[];
  suggestedHazards: Hazard[];
  suggestedControls: Control[];
  additionalComments: string;
  createdBy: User;
  createdAt: Date | null;
  updatedAt: Date | null;
  isSignedOff: string;
  signoffRequired: boolean;
  signonRequired: boolean;
  isOfflineFlow: boolean;
  isEdgeAiFlow: boolean;
  isReviewed: boolean;
  isNewRequested: boolean;
  sentAt: Date | null;
  uuid: string;
  signedOnUsers: User[];
  editable: boolean;
  isUserSignedOn: boolean;
  ramsReviews: RamsReview[];
  methodStatement: MethodStatement | null;
  hazardsWithControls: HazardWithControl[];
  suggestedHazardsWithControls: SuggestedHazardWithControl[];
  controls: Control[];
  isMethodStatementDone: boolean;
  isRamsReviewed: boolean;
  hasMajorHazard: boolean;
  reviewedAt: Date | null;
  thumbnail: string;
  signedUrl?: string;
};

export enum AssessmentItemType {
  HAZARD = 'hazard',
  CONTROL = 'control',
}

export type AssessmentType = {
  id: number;
  title: string;
  titleEs: string;
  isRams: boolean;
};

export type Signature = {
  fileId: string;
  path: string;
  url: string;
  status: UploadStatus.NEW | UploadStatus.UPLOADED;
};

export type EditAssessmentAttributes = {
  ramsRatings?: RamsRating[];
  signedOff?: boolean;
  assessment?: Assessment;
  isNewRequested?: boolean;
};

export type EditAssessmentResponse = {
  id: number;
  isOfflineFlow: boolean;
  isEdgeAiFlow: boolean;
  additionalComments: string;
  hazards: number[];
  controls: number[];
  isNewRequested: boolean;
  isReviewed: boolean;
  isReviewedPoorly: boolean;
};

export type BackendAssessment = {
  hazards: number[] | undefined;
  controls: number[] | undefined;
  additional_comments: string | undefined;
  signed_off: boolean | undefined;
  rams_reviews_list: BackendRamsReview[] | undefined;
  hazards_with_controls: BackendHazardWithControl[] | undefined;
  is_new_requested: boolean | undefined;
};

const mapToAssessment = (input: any): Assessment => {
  const isTypeObject = typeof input?.type === 'object';

  return {
    id: input.id,
    jobId: input.job_id,
    workspaceUuid: input.workspace_uuid,
    type: isTypeObject ? input.type?.title : input.type,
    typeEs: isTypeObject ? input.type?.type_es : input.title_es,
    typeId: isTypeObject ? input.type?.type_id : input.type_id,
    typeIsRams: isTypeObject ? input.type?.is_rams : input.type_is_rams,
    video: input.video ? mapToVideo(input.video) : null,
    videoStatus: input.video_status,
    hazards: input?.hazards?.map(Hazards.mapToHazard) ?? [],
    suggestedHazards: input?.suggested_hazards?.map(Hazards.mapToHazard) ?? [],
    suggestedControls:
      input?.suggested_controls?.map(Controls.mapToControl) ?? [],
    additionalComments: input?.additional_comments,
    createdBy: mapToUser(input.created_by),
    createdAt: input?.created_at ? new Date(input.created_at) : null,
    updatedAt: input?.updated_at ? new Date(input.updated_at) : null,
    isSignedOff: input.is_signed_off,
    signoffRequired: input.signoff_required,
    signonRequired: input.signon_required,
    isOfflineFlow: input.is_offline_flow,
    isEdgeAiFlow: input.is_edge_ai_flow,
    isReviewed: input.is_reviewed,
    isNewRequested: input.is_new_requested,
    sentAt: input?.sent_at ? new Date(input.sent_at) : null,
    uuid: input.uuid,
    signedOnUsers: input?.signed_on_users?.map(mapToUser) ?? [],
    editable: input.editable,
    isUserSignedOn: input.is_user_signed_on,
    ramsReviews: input.rams_reviews?.map(RamsAssessments.mapToRamsReview) ?? [],
    methodStatement: input?.method_statement
      ? RamsAssessments.mapToMethodStatement(input.method_statement)
      : null,
    hazardsWithControls:
      input?.hazards_with_controls?.map(Hazards.mapToHazardsWithControls) ?? [],
    suggestedHazardsWithControls:
      input?.suggested_hazards_with_controls?.map(
        Hazards.mapToSuggestedHazardsWithControls,
      ) ?? [],
    controls: input?.controls?.map(Controls.mapToControl) ?? [],
    isMethodStatementDone: input?.is_method_statement_done ?? false,
    isRamsReviewed: input?.is_rams_reviewed ?? false,
    hasMajorHazard: input?.has_major_hazard ?? false,
    reviewedAt: input?.reviewed_at ? new Date(input.reviewed_at) : null,
    thumbnail: input.thumbnail ?? '',
  };
};

const mapToSignature = (backendSignature: any): Signature => ({
  fileId: backendSignature.file_id,
  path: backendSignature.path,
  url: backendSignature.url,
  status: backendSignature.status,
});

const mapToAssessmentType = (backendType: any) => ({
  id: backendType.id,
  title: backendType.title,
  titleEs: backendType.title_es ?? '',
  isRams: backendType.is_rams,
});

const mapToEditAssessmentResponse = (
  backendResponse: any,
): EditAssessmentResponse => ({
  id: backendResponse.id,
  isOfflineFlow: backendResponse.is_offline_flow ?? false,
  isEdgeAiFlow: backendResponse.is_edge_ai_flow ?? false,
  additionalComments: backendResponse.additional_comments ?? '',
  hazards: backendResponse.hazards,
  controls: backendResponse.controls,
  isNewRequested: backendResponse.is_new_requested ?? false,
  isReviewed: backendResponse.is_reviewed ?? false,
  isReviewedPoorly: backendResponse.reviewed_poorly ?? false,
});

const mapToBackendAssessment = (
  input: EditAssessmentAttributes,
): BackendAssessment => ({
  hazards: input?.assessment?.hazards
    ? input?.assessment?.hazards.map((hazard) => hazard.id)
    : undefined,
  controls: input?.assessment?.controls
    ? input?.assessment?.controls.map((control) => control.id)
    : undefined,
  additional_comments: input?.assessment?.additionalComments ?? undefined,
  signed_off: input?.signedOff,
  rams_reviews_list: input?.ramsRatings
    ? input.ramsRatings.map(RamsAssessments.mapToBackendRamsReview)
    : undefined,
  hazards_with_controls: input?.assessment?.hazardsWithControls
    ? input?.assessment?.hazardsWithControls.map(
        Hazards.mapToSuggestionRequestHazardsAndControls,
      )
    : undefined,
  is_new_requested: input.isNewRequested,
});

const getSuggestedItems = (
  assessment: Assessment,
  suggestedItems: Hazard[] | Control[],
  suggestionType: AssessmentItemType.HAZARD | AssessmentItemType.CONTROL,
  hazardId?: number,
) => {
  const selectedHazard: HazardWithControl | undefined =
    assessment?.hazardsWithControls?.find(
      (hazardWithControls) => hazardWithControls.hazard.id === hazardId,
    );
  let suggestedItemIds: number[] = [];

  if (suggestedItems?.length > 0) {
    suggestedItemIds = suggestedItems.map((item) => item.id);
  } else if (assessment?.hazardsWithControls?.length) {
    if (selectedHazard?.suggestedControls) {
      suggestedItemIds = selectedHazard?.suggestedControls.map(
        (control) => control.id,
      );
    } else {
      suggestedItemIds = assessment.suggestedHazardsWithControls.map(
        (hazardWithControls) => hazardWithControls.hazard.id,
      );
    }
  } else if (assessment) {
    const capitalizedType =
      suggestionType?.charAt(0).toUpperCase() + suggestionType?.slice(1);
    suggestedItemIds = assessment[`suggested${capitalizedType}s`].map(
      (item) => item.id,
    );
  }
  return suggestedItemIds;
};

const getNumberOfFyldIntelligenceHazards = (
  hazardsWithControls: HazardWithControl[],
): number =>
  hazardsWithControls?.filter(
    (hazard: HazardWithControl) =>
      hazard.isComputerVision ||
      hazard.sources.includes(HazardSource.WEATHER) ||
      hazard.sources.includes(HazardSource.VISION) ||
      hazard.sources.includes(HazardSource.LOCATION) ||
      hazard.sources.includes(HazardSource.TRAFFIC),
  )?.length ?? 0;

const containsAllHazards = (target: Hazard[], array: Hazard[]): boolean =>
  !!array &&
  !!target &&
  array?.length === target?.length &&
  target.every((item: Hazard) =>
    array.map((element: Hazard) => element?.id).includes(item?.id),
  );

const containsAllControls = (target: Control[], array: Control[]): boolean =>
  array?.length === target?.length &&
  target.every((item: Control) =>
    array.map((element: Control) => element?.id).includes(item?.id),
  );

export const Assessments = {
  mapToAssessment,
  mapToSignature,
  getSuggestedItems,
  mapToAssessmentType,
  mapToEditAssessmentResponse,
  mapToBackendAssessment,
  getNumberOfFyldIntelligenceHazards,
  containsAllHazards,
  containsAllControls,
};

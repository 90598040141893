import styled from 'styled-components';
import theme from 'theme';

const { colors, media } = theme;

export const FooterContainer = styled.div<{ $removeFromFlow: boolean }>`
  position: absolute;
  bottom: 40px;
  color: ${colors.white};
  ${media.sm`
    position: ${({ $removeFromFlow }) =>
      $removeFromFlow ? 'unset' : 'absolute'};
    bottom: 70px;
  `}
  @media only screen and (max-height: 560px) {
    display: none;
  }
`;

export const FooterLink = styled.a`
  color: ${colors.white};
  &:hover {
    color: ${colors.white};
  }
`;

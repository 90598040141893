import RootState from 'model/State/RootState';
import { WorkspaceKeyFunctionalitySettings } from 'model/AdminPanel/WorkspaceSettings/WorkspaceKeyFunctionalitySettings';
import { CreateJobSettings } from 'model/AdminPanel/WorkspaceSettings/CreateJobSettings';
import { createSelector } from 'reselect';
import { WorkspaceItemType } from 'model/AdminPanel/WorkspaceSettings/WorkspaceItemType';

export const selectWorkspacesSettings = (state: RootState) =>
  state.workspace?.workspaceSettings;
const selectWorkspaceSettingByUuid = createSelector(
  selectWorkspacesSettings,
  (_: RootState, uuid: string) => uuid,
  (
    workspaceSettings: { [key: string]: WorkspaceKeyFunctionalitySettings },
    uuid: string,
  ) => workspaceSettings?.[uuid] || {},
);
export const selectIsFetchingWorkspaceSettings = (state: RootState) =>
  state.workspace.isFetchingWorkspaceSettings;
export const selectInitialWorkspaceSettings =
  (uuid: string) => (state: RootState) =>
    selectWorkspaceSettingByUuid(state, uuid);
export const selectJobCreationSettings = (state: RootState) =>
  state.workspace?.jobCreationSettings;
const selectJobCreationSettingByUuid = createSelector(
  selectJobCreationSettings,
  (_: RootState, uuid: string) => uuid,
  (jobCreationSettings: { [key: string]: CreateJobSettings }, uuid: string) =>
    jobCreationSettings?.[uuid] || {},
);
export const selectInitialJobCreationSettings =
  (uuid: string) => (state: RootState) =>
    selectJobCreationSettingByUuid(state, uuid);
export const selectIsLoadingWorkspaceChanges = (state: RootState) =>
  state.workspace.isLoadingWorkspaceChanges;
export const selectIsLoadingJobCreationChanges = (state: RootState) =>
  state.workspace.isLoadingJobCreationChanges;
export const selectWorkspaceError = (state: RootState) =>
  state.workspace.workspaceError;
export const selectJobCreationError = (state: RootState) =>
  state.workspace.jobCreationError;
// EVIDENCE TYPES
export const selectWorkspaceEvidenceTypes = (state: RootState) =>
  state?.workspace?.evidenceTypes;
const selectEvidenceTypesByUuid = createSelector(
  selectWorkspaceEvidenceTypes,
  (_: RootState, uuid: string) => uuid,
  (evidenceTypes: { [key: string]: WorkspaceItemType[] }, uuid: string) =>
    evidenceTypes?.[uuid] || [],
);
export const selectIsFetchingEvidenceTypes = (state: RootState) =>
  state.workspace.isFetchingEvidenceTypes;
export const selectEvidenceTypes = (uuid: string) => (state: RootState) =>
  selectEvidenceTypesByUuid(state, uuid);
export const selectIsLoadingEvidenceChanges = (state: RootState) =>
  state.workspace.isLoadingEvidenceChanges;
export const selectEvidenceTypeErrors = (state: RootState) =>
  state.workspace.evidenceTypeErrors;
// JOB TYPES
export const selectWorkspaceJobTypes = (state: RootState) =>
  state?.workspace?.jobTypes;
const selectJobTypesByUuid = createSelector(
  selectWorkspaceJobTypes,
  (_: RootState, uuid: string) => uuid,
  (jobTypes: { [key: string]: WorkspaceItemType[] }, uuid: string) =>
    jobTypes?.[uuid] || [],
);
export const selectJobTypeList = (uuid: string) => (state: RootState) =>
  selectJobTypesByUuid(state, uuid);
export const selectIsFetchingJobTypes = (state: RootState) =>
  state.workspace.isFetchingJobTypes;
export const selectIsLoadingJobTypeChanges = (state: RootState) =>
  state.workspace.isLoadingJobTypeChanges;
export const selectJobTypeErrors = (state: RootState) =>
  state.workspace.jobTypeErrors;
// JOB BLOCKER TYPES
export const selectWorkspaceJobBlockerTypes = (state: RootState) =>
  state?.workspace?.jobBlockerTypes;
const selectJobBlockerTypesByUuid = createSelector(
  selectWorkspaceJobBlockerTypes,
  (_: RootState, uuid: string) => uuid,
  (jobBlockerTypes: { [key: string]: WorkspaceItemType[] }, uuid: string) =>
    jobBlockerTypes?.[uuid] || [],
);
export const selectIsFetchingJobBlockerTypes = (state: RootState) =>
  state.workspace.isFetchingJobBlockerTypes;
export const selectJobBlockerTypes = (uuid: string) => (state: RootState) =>
  selectJobBlockerTypesByUuid(state, uuid);
export const selectIsLoadingJobBlockerChanges = (state: RootState) =>
  state.workspace.isLoadingJobBlockerChanges;
export const selectJobBlockerTypeErrors = (state: RootState) =>
  state.workspace.jobBlockerTypeErrors;
// JOB BLOCKER SUBTYPES
export const selectWorkspaceJobBlockerSubtypes = (state: RootState) =>
  state?.workspace?.jobBlockerSubtypes;
const selectJobBlockerSubtypesByUuid = createSelector(
  selectWorkspaceJobBlockerSubtypes,
  (_: RootState, uuid: string) => uuid,
  (jobBlockerSubtypes: { [key: string]: WorkspaceItemType[] }, uuid: string) =>
    jobBlockerSubtypes?.[uuid] || [],
);
export const selectIsFetchingJobBlockerSubtypes = (state: RootState) =>
  state.workspace.isFetchingJobBlockerSubtypes;
export const selectJobBlockerSubtypes = (uuid: string) => (state: RootState) =>
  selectJobBlockerSubtypesByUuid(state, uuid);
export const selectIsLoadingJobBlockerSubtypeChanges = (state: RootState) =>
  state.workspace.isLoadingJobBlockerSubtypeChanges;
export const selectJobBlockerSubtypeErrors = (state: RootState) =>
  state.workspace.jobBlockerSubtypeErrors;

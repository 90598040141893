import { Button, Input, Modal } from 'components/ui';
import { useText } from 'hooks';
import { ChangeEvent, useCallback, useState } from 'react';
import { UserSearchDropdown } from './UserSearchDropdown';
import { UserRole } from 'model/enum/UserRole';
import {
  HavsLimit,
  HavsManager,
  HavsUser,
} from 'model/AdminPanel/HavsSettings/HavsUser';
import { UserFormWrapper } from '../styled';
import { inputAsNumber } from 'helpers/number';
import theme from 'theme';
import { addHavsLimit, editHavsLimit } from 'api/HavsSettings';
import * as Logger from 'utils/logger';
import { useDispatch } from 'react-redux';
import { setGlobalError } from 'store/slices/notifications';
import { UserBadge } from './UserBadge';

const { fontSizes } = theme;

type Props = {
  isVisible: boolean;
  onSuccess: (successfulPayload: {
    user: HavsUser;
    manager: HavsManager;
    havsLimit: number;
  }) => void;
  onCancel: () => void;
  initialLimit?: HavsLimit;
};

export const AddEditHavsLimitModal = ({
  isVisible,
  initialLimit,
  onSuccess,
  onCancel,
}: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const [user, setUser] = useState<HavsUser | null>(initialLimit?.user ?? null);
  const [manager, setManager] = useState<HavsManager | null>(
    initialLimit?.manager ?? null,
  );
  const [havsLimit, setHavsLimit] = useState<number | null>(
    initialLimit?.havsLimit ?? null,
  );

  const isDisabled = !user || !havsLimit || !manager;
  const isEditing = !!initialLimit;

  const updateHavsLimit = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      setHavsLimit(inputAsNumber(e.target.value)),
    [setHavsLimit],
  );

  const handleSubmit = async () => {
    if (user && manager && havsLimit) {
      try {
        if (isEditing) {
          await editHavsLimit({ user, manager, havsLimit } as HavsLimit);
        } else {
          await addHavsLimit({ user, manager, havsLimit } as HavsLimit);
        }
        onSuccess({ user, manager, havsLimit });
      } catch (error: any) {
        if (
          error?.response?.data?.user?.find(
            (errorMessage: string) =>
              errorMessage ===
              'user havs config with this user already exists.',
          )
        ) {
          dispatch(setGlobalError(getText('havs_user_add_new_error')));
        } else {
          Logger.error(error);
          dispatch(setGlobalError(error));
        }
      }
    }
  };

  return (
    <Modal.Base
      isOpen={isVisible}
      onClose={onCancel}
      width='650px'
      title={
        isEditing
          ? getText('havs_user_edit_modal_title')
          : getText('havs_user_add_new_modal_title')
      }
      isCentered
    >
      <UserFormWrapper>
        {isEditing && user ? (
          <UserBadge
            id={user.id}
            firstName={user.firstName || ''}
            lastName={user.lastName || ''}
            role={user.role}
            isCurrentUser={false}
          />
        ) : (
          <UserSearchDropdown
            label={getText('havs_user_add_new_user_dropdown_label')}
            id='havs-user-dropdown'
            selectedUser={user}
            setSelectedUser={setUser}
            userRoles={[UserRole.FIELDWORKER, UserRole.TEAMMATE]}
            placeholder={getText('havs_user_add_new_user_placeholder')}
            required
          />
        )}
        <Input.Text
          id='havs-limit'
          label={getText('havs_user_add_new_havs_input_label')}
          value={havsLimit ?? ''}
          onChange={updateHavsLimit}
          required
        />
        <UserSearchDropdown
          label={getText('havs_user_manager_dropdown_label')}
          id='havs-manager-dropdown'
          selectedUser={manager}
          setSelectedUser={setManager}
          userRoles={[UserRole.MANAGER]}
          placeholder={getText('havs_user_add_new_user_placeholder')}
          required
        />
      </UserFormWrapper>
      <Button.Primary
        text={
          isEditing
            ? getText('havs_user_edit_button_cta')
            : getText('havs_user_add_new_button_cta')
        }
        onClick={handleSubmit}
        disabled={isDisabled}
        extraStyles={{
          position: 'sticky',
          bottom: '0',
          marginTop: 'auto',
          flex: '0 0 55px',
          fontSize: fontSizes.l,
        }}
      />
    </Modal.Base>
  );
};

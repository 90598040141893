import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams, useText } from 'hooks';
import {
  Badge,
  Button,
  FormattedDate,
  Input,
  Loader,
  Media,
  Modal as StyledModal,
} from 'components/ui';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import {
  useLocation,
  useParams,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import { Storage } from 'aws-amplify';
import { getVideo, getVideoInfoPage } from 'helpers/forms';
import { setGlobalError } from 'store/slices/notifications';
import { JobForms, Permit, UserForms } from 'api';
import UserFormAnalyticsParameter from 'model/Forms/UserFormAnalyticsParameters';
import RootState from 'model/State/RootState';
import { Comment, CustomFormClass, Form, Forms } from 'model/Form';
import FormDetails from './components/FormDetails';
import Instruction from './components/Instruction';
import { Field } from 'model/Forms/Field';
import { ManagerComment } from '../Insights/ManagerComment';
import {
  fetchJobForm,
  fetchPermitForm,
  fetchUserForm,
} from 'store/actions/insights';
import {
  CommentContainer,
  Container,
  ContentWrapper,
  FormTitle,
  Header,
  RightContainer,
  Section,
  SectionHeader,
  SectionWrapper,
} from './styled';
import { ModalFooter } from '../PdfExport/ModalFooter';
import { DownloadType } from '../PdfExport/constants';
import { today } from 'helpers/dates';
import { mapToUser } from 'api/User/helpers';
import { mapToVideo } from 'api/Media/helpers';
import { selectWorkspaceSettings } from 'store/selectors';

type QueryParamProps = {
  form?: string;
};

export const InsightsFormModal = (): JSX.Element => {
  const { formId } = useParams();
  const navigate = useNavigate();
  const { params }: { params: QueryParamProps } = useQueryParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const getText = useText();
  const { currentUser } = useSelector((state: RootState) => state.session);
  const { havs_compliance_form_id } = useSelector(selectWorkspaceSettings);

  const [form, setForm] = useState<Form | null>(null);
  const [isFetching, setFetching] = useState<boolean>(true);
  const [videoUrl, setVideoUrl] = useState<string>();
  const [comment, setComment] = useState<string>('');
  const [formComments, setFormComments] = useState<Comment[]>([]);
  const [isCommentSending, setCommentSending] = useState<boolean>(false);
  const videoInfoPage: Field | null = getVideoInfoPage(form?.form);

  const handleModalClose = () =>
    navigate('/jobs/insights', { state: location.state });

  const handleGoBack = () => navigate(-1);

  const formType: CustomFormClass = useMemo(
    () => location.state?.formClass ?? params?.form,
    [location.state?.formClass, params?.form],
  );

  const isHavs: boolean = useMemo(
    () => !!form && form?.type?.id === havs_compliance_form_id,
    [form, havs_compliance_form_id],
  );

  useEffect(() => {
    (async () => {
      try {
        let data: Form | null = null;
        if (formType === CustomFormClass.PERMIT) {
          data = await Permit.get(formId ?? '');
        } else if (formType === CustomFormClass.USER) {
          const result = await UserForms.getUserForm(
            parseInt(formId ?? '', 10),
          );
          data = Forms.mapToCustomForm(result);
        } else {
          const result = await JobForms.get(parseInt(formId ?? '', 10));
          data = Forms.mapToCustomForm(result);
        }
        Analytics.trackEvent(constants.FORM_DASHBOARD_VIEWED, {
          form_name: data?.form?.title,
          form_type_id: data?.type?.id,
          form_id: data?.id,
        });

        setForm(data);
        if (data?.comments) {
          setFormComments(data?.comments);
        }
        setFetching(false);
      } catch (err) {
        dispatch(setGlobalError(err));
      }
    })();
  }, [formId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      if (form?.form && videoInfoPage) {
        const signedURL = await Storage.get(
          (videoInfoPage as Field)?.value?.path,
        );
        setVideoUrl(signedURL);
      }
    })();
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  const refetchFormDashboard = () => {
    if (location.state.depotId) {
      switch (formType) {
        case CustomFormClass.USER:
          dispatch(
            fetchUserForm(
              location.state.formTypeId,
              location.state.depotId,
              location.state.startDate,
              location.state.endDate,
              !location.state.isWeekendIncluded,
              location.state.memberIds,
              location.state.page,
              location.state.selectedWorkspaceUuid,
              location.state.search,
              isHavs,
            ),
          );
          break;
        case CustomFormClass.JOB:
          dispatch(
            fetchJobForm(
              location.state.formTypeId,
              location.state.depotId,
              location.state.startDate,
              location.state.endDate,
              !location.state.isWeekendIncluded,
              location.state.memberIds,
              location.state.page,
              location.state.selectedWorkspaceUuid,
              location.state.search,
            ),
          );
          break;
        case CustomFormClass.PERMIT:
          dispatch(
            fetchPermitForm(
              location.state.formTypeId,
              location.state.depotId,
              location.state.startDate,
              location.state.endDate,
              !location.state.isWeekendIncluded,
              location.state.memberIds,
              location.state.page,
              location.state.selectedWorkspaceUuid,
              location.state.search,
            ),
          );
          break;
      }
    }
  };

  const handleSubmit = async () => {
    setCommentSending(true);
    try {
      if (form) {
        const data = await UserForms.sendComment(form.id, comment);
        if (data) {
          const newComment: Comment = {
            id: null,
            comment: data,
            userForm: form?.id,
            createdBy: mapToUser(currentUser),
            createdAt: today,
            updatedAt: today,
          };
          setFormComments((prevState) => [...prevState, newComment]);
          refetchFormDashboard();
        }
      }
    } catch (err) {
      dispatch(setGlobalError(err));
    }
    setComment('');
    setCommentSending(false);
  };

  return (
    <StyledModal.Base
      isOpen
      title={form?.form?.title || ''}
      onClose={handleModalClose}
      onBack={
        (location.pathname.includes('instruction') && handleGoBack) || null
      }
      width={videoInfoPage ? '80vw' : '35vw'}
    >
      <Container>
        {isFetching ? (
          <Loader />
        ) : (
          <>
            {videoInfoPage && videoUrl && (
              <Media
                kind='form'
                type='video'
                url={videoUrl}
                width='55%'
                item={getVideo(form?.form, form?.videos?.map(mapToVideo) ?? [])}
                userFormParameters={
                  {
                    form_name: form?.form?.title,
                    form_type_id: form?.type?.id,
                    form_id: form?.id,
                    video_id: (videoInfoPage as Field)?.value?.file_id,
                  } as UserFormAnalyticsParameter
                }
              />
            )}
            <Routes>
              <Route
                path='/instruction/:fieldId'
                element={
                  <RightContainer width={videoInfoPage ? 45 : 100}>
                    <Instruction />
                  </RightContainer>
                }
              />
              <Route
                index
                element={
                  <RightContainer width={videoInfoPage ? 45 : 100}>
                    <ContentWrapper>
                      <SectionWrapper>
                        <Header>
                          {form?.createdBy && (
                            <Badge.User user={form.createdBy} />
                          )}
                          {form?.createdAt && (
                            <FormattedDate
                              date={form?.createdAt?.toString() ?? ''}
                              extraStyles={{
                                color: theme.colors.grey,
                                textAlign: 'center',
                              }}
                            />
                          )}
                        </Header>
                        {form && (
                          <Section>
                            {form?.form?.header && (
                              <SectionHeader>
                                <FormTitle>{form?.form?.header}</FormTitle>
                              </SectionHeader>
                            )}
                            <FormDetails
                              form={form?.form}
                              userFormParameters={{
                                form_name: form?.form?.title,
                                form_type_id: form?.type?.id,
                                form_id: form?.id,
                              }}
                              photos={form?.photos}
                              videos={form?.videos}
                            />
                          </Section>
                        )}
                        {isHavs && (
                          <Section>
                            <CommentContainer>
                              <ManagerComment comments={formComments} />
                              <Input.TextArea
                                id='manager-comment'
                                label={getText(
                                  'insights_forms_manager_comment',
                                )}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                              />
                            </CommentContainer>
                          </Section>
                        )}
                      </SectionWrapper>
                      {isHavs && (
                        <Button.Primary
                          text={getText('submit')}
                          extraStyles={{
                            position: 'sticky',
                            bottom: '0',
                            marginTop: 'auto',
                            flex: '0 0 55px',
                          }}
                          onClick={handleSubmit}
                          loading={isCommentSending}
                          disabled={!comment.trim()}
                          event={constants.EVENT_USER_FORM_COMMENT_SENT}
                          eventProperties={{
                            formId: form?.id,
                            userId: currentUser.id,
                          }}
                        />
                      )}
                      {formId && (
                        <ModalFooter
                          downloadType={
                            formType === CustomFormClass.PERMIT
                              ? DownloadType.PERMIT
                              : DownloadType.FORM
                          }
                          downloadItemId={formId}
                          formClass={formType}
                          jobId={form?.jobId}
                          onClick={() => handleModalClose()}
                        />
                      )}
                    </ContentWrapper>
                  </RightContainer>
                }
              />
            </Routes>
          </>
        )}
      </Container>
    </StyledModal.Base>
  );
};

import {
  LocationInsight,
  NearbyJob,
  SiteBriefing,
} from 'model/State/SiteBriefing';
import { BackendData, BackendLocationInsight, BackendNearbyJob } from './types';

export const mapToLocationInsight = ({
  distance_metres,
  label,
}: BackendLocationInsight): LocationInsight => {
  let metresNumber;
  try {
    metresNumber = Number(distance_metres);
    // for some inputs the Number constructor will throw a TypeError
  } catch (err) {
    metresNumber = NaN;
  }

  if (distance_metres === null || isNaN(metresNumber)) {
    return {
      label,
      roundedDistanceMetres: null,
    };
  }

  return {
    label,
    roundedDistanceMetres: (Math.ceil(metresNumber / 50) * 50).toFixed(),
  };
};

export const mapToSiteBriefing = (input: BackendData): SiteBriefing => {
  const trafficFlow = input.traffic?.traffic_flow?.summary ?? '';
  const trafficIncident = input.traffic?.traffic_incident?.summary ?? '';

  const trafficInsights = [trafficFlow, trafficIncident].filter(
    (trafficInfo) => !!trafficInfo,
  );

  return {
    id: input.id,
    jobId: input.job,
    address: input.address,
    createdAt: input.created_at,
    sentAt: input.sent_at,
    weather: input?.weather,
    trafficInsights: trafficInsights,
    locationInsights: input.location_insights?.insights
      ? input.location_insights?.insights.map(mapToLocationInsight)
      : [],
    cableStrikesLikely: input.location_insights?.cable_strikes_likely ?? false,
    nearbyJobs: input.nearby_jobs?.jobs?.map(mapToNearbyJob) ?? null,
  };
};

const mapToNearbyJob = (backendNearbyJob: BackendNearbyJob): NearbyJob => ({
  id: backendNearbyJob.id,
  distanceMiles: (backendNearbyJob.distance_metres / 1609.344).toFixed(1),
  url: backendNearbyJob.url,
  address: backendNearbyJob.address,
  workorderItemNumber: backendNearbyJob.workorder_item_number,
  jobType: backendNearbyJob.job_type,
  createdAtDate: backendNearbyJob.created_at_date,
  jobStatus: backendNearbyJob.job_status,
  latestAssessmentHasMajorHazard:
    !!backendNearbyJob.latest_assessment_has_major_hazard,
});

import * as constants from 'utils/constants';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsFetchingDocuments,
  selectJobDocuments,
  selectJobsView,
} from 'store/selectors';
import { InformationLabel } from 'components/ui/Shared/EmptyState';
import { Card, Loader } from 'components/ui';
import { useEffect } from 'react';
import { useText } from 'hooks';
import OverviewTabBase from './OverviewTabBase';
import { Document as DocumentIcon } from 'assets/icons';
import { fetchDocuments } from 'store/actions/jobs';
import { formatBytes } from 'helpers/utils';
import { Document } from 'model/Media/Document';

const Documents = (): JSX.Element => {
  const { jobId } = useParams();
  const documents = useSelector(selectJobDocuments(jobId));
  const isFetchingDocuments = useSelector(selectIsFetchingDocuments);
  const getText = useText();
  const dispatch = useDispatch();
  const view = useSelector(selectJobsView);

  useEffect(() => {
    dispatch(fetchDocuments(jobId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSubtitle = (extensionType, fileSize) =>
    `${extensionType} • ${formatBytes(fileSize)}`;

  return (
    <OverviewTabBase title={getText('tabs_documents')}>
      <>
        {isFetchingDocuments ? (
          <Loader />
        ) : (
          <>
            {!documents?.length ? (
              <InformationLabel>
                {getText('document_no_display')}
              </InformationLabel>
            ) : (
              documents?.map((document: Document) => (
                <Card.JobDetail
                  event={constants.EVENT_VIEW_DOCUMENT_ITEM}
                  eventProperties={{
                    jobId,
                    documentId: document.id,
                    source: 'job-overview',
                    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  }}
                  key={`document-${document.id}`}
                  title={document.originalFileName}
                  subtitle={getSubtitle(
                    document.extensionType.toUpperCase(),
                    document.fileSize,
                  )}
                  creator={document.createdBy}
                  date={document.createdAt}
                  IconComponent={DocumentIcon}
                  url={document.signedUrl}
                />
              ))
            )}
          </>
        )}
      </>
    </OverviewTabBase>
  );
};

export default Documents;

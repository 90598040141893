import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Notification, ToastType } from './constants';
import { ToastBase } from './ToastBase';
import { useDispatch, useSelector } from 'react-redux';
import { resetSuccess, selectSuccessList } from 'store/slices/notifications';
import { SuccessItem } from 'model/State/notifications/SuccessItem';
import { useText } from 'hooks';

export const SuccessToast = (): JSX.Element => {
  const successList = useSelector(selectSuccessList);
  const dispatch = useDispatch();
  const getText = useText();
  const CONTAINER_ID = 'success-container';
  const [notificationList, setNotificationList] = useState<Notification[]>([]);

  useEffect(() => {
    setNotificationList(
      successList.map((success: SuccessItem) => ({
        id: success.id,
        title: getText(success.message),
        canBeDismissed: false,
      })),
    );
  }, [successList]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClose = (id: number) => {
    dispatch(resetSuccess(id));
  };

  return (
    <ToastBase
      containerId={CONTAINER_ID}
      type={ToastType.SUCCESS}
      notifications={notificationList}
      onClose={(id) => onClose(id)}
      autoClose={5000}
    />
  );
};

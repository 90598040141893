import * as constants from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchActiveJob } from 'store/actions/jobs';
import TeamDisplay from '../../Overview/components/TeamDisplay';
import { Button } from 'components/ui';
import { useText } from 'hooks';
import OverviewTabBase from './OverviewTabBase';
import { selectJobsView } from 'store/selectors';

const TeamMembers = (): JSX.Element => {
  const getText = useText();
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const { activeJob, isFetchingActiveJob } = useSelector(
    (state: RootState) => state.jobs,
  );
  const { isManager, isBackOffice } = useSelector(
    (state: RootState) => state.session,
  );
  const view = useSelector(selectJobsView);

  useEffect(() => {
    if (
      jobId &&
      (!activeJob || activeJob?.id?.toString() !== jobId) &&
      !isFetchingActiveJob
    ) {
      dispatch(fetchActiveJob(jobId, { updateAllJobs: false }));
    }
  }, [jobId, activeJob]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OverviewTabBase>
      <>
        <TeamDisplay members={activeJob?.assignees ?? []} isColumn />
        {(activeJob?.is_assigned || isManager || isBackOffice) && (
          <Button.SecondaryLink
            event={constants.EVENT_EDIT_JOB_TEAM_MEMBERS_BUTTON}
            eventProperties={{
              jobId,
              members: activeJob?.assignees?.map((member) => member.id) ?? [],
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            text={getText('tabs_edit_team_members')}
            to={`/jobs/${jobId}/edit-team`}
          />
        )}
      </>
    </OverviewTabBase>
  );
};

export default TeamMembers;

import { DialogTitle as MuiDialogTitle } from '@mui/material';
import { Arrow, Cross } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

const DialogTitle = styled(MuiDialogTitle)`
  flex: 0 0 65px;
  height: 65px;
  background-color: ${colors.black};
  padding: 0 25px;
  box-sizing: border-box;
  ${mixins.flexBetween};
  ${media.sm`
      padding: 0 15px;
    `}
`;

const Left = styled.div`
  ${mixins.flexBetween};
`;

const BackIcon = styled(Arrow)`
  height: 25px;
  width: 25px;
  transform: rotate(180deg);
  margin-right: 15px;
  cursor: pointer;
  .fill-selector {
    fill: ${colors.yellow};
  }
`;

const Title = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  color: ${colors.yellow};
`;

const CrossIcon = styled(Cross)`
  height: 25px;
  width: 25px;
  cursor: pointer;
  .fill-selector {
    fill: ${colors.yellow};
  }
`;

type Props = {
  title?: string | JSX.Element;
  onBack?: (() => void) | null;
  onClose?: (() => void) | null;
  hasCancelButton?: boolean;
};

const ModalTitle = ({
  onClose,
  title,
  onBack,
  hasCancelButton = true,
}: Props) => (
  <DialogTitle>
    <Left>
      {onBack && <BackIcon onClick={onBack} />}
      {title && <Title>{title}</Title>}
    </Left>
    {hasCancelButton && <CrossIcon onClick={onClose} />}
  </DialogTitle>
);

export default ModalTitle;

import { Hazard } from 'model/Assessment/Hazard';
import { ItemRow, P } from './styled';
import { Control } from 'model/Assessment/Control';
import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { getLocalizedValue } from 'helpers/locales';
import { TITLE_PROPERTY_NAME } from '../constants';
import { capitalizeFirstLetter } from 'helpers/names';

type Props = {
  item: Hazard | Control;
  width?: number;
};

export const ItemRowSelected = ({ item, width }: Props): JSX.Element => {
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  return (
    <ItemRow key={item.id} width={width}>
      <P>
        {capitalizeFirstLetter(
          getLocalizedValue(item, TITLE_PROPERTY_NAME, globalAppLanguage),
        )}
      </P>
    </ItemRow>
  );
};

import { FyldAvatar } from 'assets/icons';
import { useText } from 'hooks';
import { Avatar, Container, Name } from './styled';

export const FyldBadge = (): JSX.Element => {
  const getText = useText();

  return (
    <Container>
      <Avatar>
        <FyldAvatar />
      </Avatar>
      <Name>{getText('fyld')}</Name>
    </Container>
  );
};

import styled from 'styled-components';
import theme from 'theme';

const { colors, fontSizes, mixins, media } = theme;

export const TokenLoginText = styled.div`
  color: ${colors.white};
  font-size: ${fontSizes.xl};
  padding: 20px 0;
  ${media.md`
    padding: 40px 0;
  `}
`;

export const LoadingWrapper = styled.div`
  ${mixins.flexCenter}
  flex-direction: column;
  width: 50%;
  ${media.md`
    width: 100%;
  `}
`;

export const ExpiredWrapper = styled.div`
  ${media.md`
    padding: 0 20px;
    width: 100%;
  `}
`;

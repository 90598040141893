import { FieldKind } from 'components/views/Protected/Jobs/FatigueManager/components/NewFatigueRiskAssessmentModal/types';
import { OTHER_LOCALISATION_STRING } from './constants';
import FraForm from './FraForm';
import FraFormSection from './FraFormSection';
import SectionField from './SectionField';
import JsonLogic from 'helpers/logic';

export const hasValue = (field: SectionField): boolean => {
  const value = field.value;

  if (!value) {
    return false;
  }

  if (typeof value === 'string') {
    return !!value.trim();
  }

  if (Array.isArray(value)) {
    if (
      value.find(
        (selectedOption) => selectedOption === OTHER_LOCALISATION_STRING,
      )
    ) {
      return !!field.customUserInputValue;
    }
    return !!value.length;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length !== 0;
  }

  return true;
};

export const isFormFieldVisible = (field: SectionField) => {
  if (field.required || field.requiredConditional) {
    return true;
  }

  return field.value?.length;
};

export const isFormSectionVisible = (
  showFormula,
  showSourceWidgetIds,
  allSections,
) => {
  let isVisible = true;

  if (showFormula && showSourceWidgetIds) {
    const formulaValues = showSourceWidgetIds.reduce(
      (valuesObject, sourceWidgetId) => {
        const allFields = allSections
          .map((formSection) => formSection.fields)
          .flat();

        const field = allFields.find((field) => field.id === sourceWidgetId);
        if (field) {
          return { ...valuesObject, [field.id]: field.value };
        } else {
          return valuesObject;
        }
      },
      {},
    );

    isVisible = JsonLogic.apply(showFormula, formulaValues);
  }

  return isVisible;
};

export const clearSection = (section: FraFormSection): FraFormSection => {
  return {
    ...section,
    fields: section.fields.map((field) => {
      if (field.kind !== FieldKind.INSTRUCTION) {
        field.value = undefined;
      }
      return field;
    }),
  };
};

export const isFraFormValid = (form: FraForm): boolean =>
  !form.sections.some((section) => !isSectionValid(section, form));

const isSectionValid = (section: FraFormSection, form: FraForm): boolean =>
  (section.config?.showFormula &&
    !isFormSectionVisible(
      section.config?.showFormula,
      section.config?.showSourceWidgetIds,
      form.sections,
    )) ||
  !section.fields.some((field) => !isFieldValid(field));

export const isFieldValid = (field: SectionField): boolean => {
  return (
    ((!field.required || field.isConditionallyShown) &&
      !field.requiredConditional) ||
    hasValue(field)
  );
};

export const updateFormAndToggleSectionVisibility = (
  currentForm: FraForm,
  updatedSection: FraFormSection,
  updatedSectionIndex: number,
) => {
  const sections = currentForm.sections.map(
    (previousSection, previousSectionIndex) => {
      return previousSectionIndex === updatedSectionIndex
        ? updatedSection
        : previousSection;
    },
  );

  const updatedForm = {
    ...currentForm,
    sections: sections.reduce<FraFormSection[]>((acc, section) => {
      const shouldShow = isFormSectionVisible(
        section.config?.showFormula,
        section.config?.showSourceWidgetIds,
        acc,
      );

      if (section.config?.isShowing === shouldShow) {
        return [...acc, section];
      } else if (shouldShow) {
        return [
          ...acc,
          {
            ...section,
            config: { ...section.config, isShowing: shouldShow },
          },
        ];
      } else {
        const clearedSection = clearSection(section);
        return [
          ...acc,
          {
            ...clearSection(section),
            config: { ...clearedSection.config, isShowing: shouldShow },
          },
        ];
      }
    }, []),
  };

  return updatedForm;
};

export const getQuestionsForAnalytics = (
  sections: FraFormSection[],
): { [key: string]: string } =>
  sections
    .flatMap((section: FraFormSection): null | [string, string][] => {
      if (
        !isFormSectionVisible(
          section.config?.showFormula,
          section.config?.showSourceWidgetIds,
          sections,
        )
      ) {
        return null;
      }
      return section.fields
        .filter((field) => isFormFieldVisible(field))
        .map((field): [string, string] => [
          field.title,
          Array.isArray(field.value)
            ? field.value.join(',')
            : (field.value ?? ''),
        ]);
    })
    .reduce((acc, pair, index) => {
      if (!pair) {
        return acc;
      }
      return {
        ...acc,
        [`question${index + 1}`]: pair[0],
        [`answer${index + 1}`]: pair[1],
      };
    }, {});

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryParams, useText } from 'hooks';
import { Card, Button, Input, Modal } from 'components/ui';
import { forgotPassword } from 'store/actions/session';
import { FormContainer } from '../styled';
import { EVENT_FEEDBACK_MODAL_CLICKED } from 'utils/constants';
import RootState from 'model/State/RootState';
import { LoginForm, PaddedWrapper } from '../Login/styled';
import { isBlank, isValidEmail } from 'helpers/utils';
import theme from 'theme';
import { ForgotPasswordFlowTriggerStatus } from 'model/Session';
import { LogoSlogan } from 'components/ui/Logos';
import { ErrorContainer, H1, P } from './styled';

const { colors } = theme;

// ForgottenPassword
export const ForgottenPassword = (): JSX.Element => {
  const dispatch = useDispatch();
  const getText = useText();
  const { params } = useQueryParams();

  const [email, setEmail] = useState(params.email ?? '');
  const [dispatchSent, setDispatchSent] = useState(false);
  const { isLoading, passwordError } = useSelector(
    (state: RootState) => state.session,
  );
  const handleSubmit = async () => {
    dispatch(forgotPassword(email));
    setDispatchSent(true);
  };

  useEffect(() => {
    if (passwordError) {
      setDispatchSent(false);
    }
  }, [passwordError]); // eslint-disable-line react-hooks/exhaustive-deps

  const isDisabled = isBlank(email) || !isValidEmail(email);

  const ssoText = [
    getText('forgot_password_error_sso_first_line'),
    getText('forgot_password_error_sso_second_line'),
  ];
  const genericText = getText('forgot_password_error_generic');

  const errorProps =
    passwordError === ForgotPasswordFlowTriggerStatus.ERR_SSO
      ? { messages: ssoText }
      : {
          message: genericText,
        };

  return (
    <>
      <LogoSlogan />
      <FormContainer onSubmit={handleSubmit}>
        <LoginForm>
          <PaddedWrapper>
            <H1>{getText('forgot_password_title')}</H1>
            <P>{getText('forgot_password_description')}</P>

            <Input.Text
              id='email-forgotten-password'
              label={getText('forgot_password_email_title')}
              labelColor={colors.white}
              error={passwordError}
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              onEnter={!isDisabled ? handleSubmit : undefined}
              hasBlackBackground
            />

            {passwordError && (
              <ErrorContainer>
                <Card.ErrorMessage {...errorProps} />
              </ErrorContainer>
            )}

            <Button.Primary
              text={getText('forgot_password_email_submit')}
              disabled={isDisabled}
              loading={isLoading}
              onClick={handleSubmit}
              width='240px'
              hasBlackBackground
            />
          </PaddedWrapper>
        </LoginForm>
      </FormContainer>
      {!isLoading && !passwordError && dispatchSent && (
        <Modal.Feedback
          title={getText('forgot_password_email_sent_title')}
          description={getText('forgot_password_email_sent_description')}
          linkText={getText('login')}
          linkPath='/login'
          event={EVENT_FEEDBACK_MODAL_CLICKED}
          eventProperties={{ source: 'password-reset-email-requested' }}
        />
      )}
    </>
  );
};

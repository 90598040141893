import {
  ColumnWrapper,
  Marker,
  MarkerIconContainer,
  NearbyJobsHeader,
  NearbyJobsTitle,
} from '../styled';
import { useText } from 'hooks';
import { NearbyJobCard } from './NearbyJobCard';
import { EmptyState } from './styled';
import { NearbyJob } from 'model/State/SiteBriefing';

type Props = {
  jobs: NearbyJob[] | null;
  eventProperties: object;
};

export const NearbyJobs = ({ jobs, eventProperties }: Props): JSX.Element => {
  const getText = useText();

  return (
    <>
      <ColumnWrapper>
        <NearbyJobsHeader>
          <NearbyJobsTitle>
            {getText('job_details_timeline_action_site_briefing_nearby_jobs')}
          </NearbyJobsTitle>
          <MarkerIconContainer>
            <Marker />
          </MarkerIconContainer>
        </NearbyJobsHeader>
        {jobs && jobs?.length > 0 ? (
          jobs.map((job) => (
            <NearbyJobCard
              key={`job-${job.id}`}
              job={job}
              eventProperties={{
                ...eventProperties,
                clickedJobId: job.id,
              }}
            />
          ))
        ) : (
          <EmptyState>
            {getText(
              'job_details_timeline_action_site_briefing_no_nearby_jobs',
            )}
          </EmptyState>
        )}
      </ColumnWrapper>
    </>
  );
};

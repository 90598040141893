import theme from 'theme';
import { DotsIcon, TagContainer } from './styled';

type Props = {
  text: string;
  extraStyles?: React.CSSProperties;
};

export const NotAvailableTag = ({ text, extraStyles }: Props): JSX.Element => (
  <TagContainer
    color={theme.colors.white}
    $backgroundColor={theme.colors.darkGrey}
    style={extraStyles}
  >
    <DotsIcon />
    {text}
  </TagContainer>
);

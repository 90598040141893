import { Cross } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins, media } = theme;

export const Container = styled.div<{ $vertical?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ $vertical }) => ($vertical ? 'column' : 'row')};
`;

export const Avatar = styled.div<{
  $backgroundColor?: string;
  $large?: boolean;
  $vertical?: boolean;
}>`
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  font-size: ${fontSizes.default};
  ${({ $large }) =>
    $large &&
    `
    height: 55px;
    width: 55px;
    min-height: 55px;
    min-width: 55px;
    font-size: ${fontSizes.l};
  `};
  border-radius: 50%;
  color: ${colors.white};
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ?? 'transparent'};
  margin-right: 15px;
  margin-bottom: 0;
  ${({ $vertical }) =>
    $vertical &&
    `
    margin-right: 0;
    margin-bottom: 8px;
  `};
  ${mixins.flexFullCenter};
  position: relative;

  ${media.xxl`
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    ${({ $large }) =>
      $large &&
      `
      height: 40px;
      width: 40px;
      min-height: 40px;
      min-width: 40px;
    `};
  `}
`;

export const DeleteIcon = styled.div<{ $large?: boolean; $disabled?: boolean }>`
  ${mixins.flexFullCenter}
  height: 22px;
  width: 22px;
  top: -6px;
  right: -6px;
  ${({ $large }) =>
    $large &&
    `
      height: 30px;
      width: 30px;
      top: -10px;
      right: -10px;
    `};
  border-radius: 50%;
  position: absolute;
  background-color: ${({ $disabled }) =>
    $disabled ? colors.grey : colors.black};
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};

  ${media.xxl`
    height: 18px;
    width: 18px;
    ${({ $large }) =>
      $large &&
      `
        height: 22px;
        width: 22px;
      `};
    `}
`;

export const CrossIcon = styled(({ $large, ...rest }) => <Cross {...rest} />)`
  height: ${({ $large }) => ($large ? '15px' : '10px')};
  margin-bottom: 2px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const FlexColumn = styled.div`
  ${mixins.flexColumn};
  max-height: 50px;
`;

export const Name = styled.p<{ $large?: boolean; $vertical?: boolean }>`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
  line-height: ${fontSizes.default};
  ${({ $large }) =>
    $large &&
    `
      font-size: ${fontSizes.m};
      line-height:${fontSizes.m};
    `};
  ${({ $vertical }) => $vertical && 'text-align: center;'}

  ${media.xxl`
  font-size: ${fontSizes.s};
    ${({ $large }) =>
      $large &&
      `
      font-size: ${fontSizes.default};
    `};
  `}
`;

export const Title = styled.p<{ $vertical?: boolean; $large?: boolean }>`
  color: ${colors.darkGrey};

  ${({ $vertical }) => $vertical && 'text-align: center;'}
  ${({ $vertical, $large }) =>
    $vertical &&
    `
      font-size: ${$large ? fontSizes.m : fontSizes.default};
      line-height: ${$large ? fontSizes.m : fontSizes.default};`}
`;

import { useDispatch, useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import { useJobs } from 'hooks';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { Modal } from 'components/ui';
import { FeedbackShow } from './Show';
import { setPsiDismissed } from 'store/actions/user';
import { selectJobsView } from 'store/selectors';

export const FeedbackModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const { navigateToOverviewTabOrJob } = useJobs();
  const { activeJob } = useSelector((state: RootState) => state.jobs);
  const view = useSelector(selectJobsView);

  const handleClose = () => {
    Analytics.trackEvent(constants.EVENT_PSI_FEEDBACK_MODAL_CLOSED, {
      jobId: activeJob?.id,
      psiScoreId: activeJob?.safety_prediction_score?.id,
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    });
    navigateToOverviewTabOrJob();
    dispatch(setPsiDismissed(true));
  };

  return (
    <Modal.Base isOpen={!!activeJob} onClose={handleClose} width='650px'>
      <FeedbackShow />
    </Modal.Base>
  );
};

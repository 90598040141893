export type AssessmentType = {
  id: number;
  hazards: number[];
  title: string;
  titleEs: string;
  active: boolean;
  isRams: boolean;
  organisation: number;
  workspace: number;
  forceHazards: number[];
};

export type BackendAssessmentType = {
  id: number;
  hazards: number[];
  title: string;
  title_es: string | null;
  active: boolean;
  is_rams: boolean;
  organisation: number;
  workspace: number;
  force_hazards: number[];
};

const mapToAssessmentType = (backendAssessmentType: any): AssessmentType => ({
  id: backendAssessmentType.id,
  hazards: backendAssessmentType.hazards ?? [],
  title: backendAssessmentType.title,
  titleEs: backendAssessmentType.title_es ?? '',
  active: backendAssessmentType.active ?? false,
  isRams: backendAssessmentType.is_rams ?? false,
  organisation: backendAssessmentType.organisation,
  workspace: backendAssessmentType.workspace,
  forceHazards: backendAssessmentType.force_hazards ?? [],
});

const mapToBackendAssessmentType = (
  input: AssessmentType,
): BackendAssessmentType => ({
  id: input.id,
  hazards: input.hazards,
  title: input.title,
  title_es: input.titleEs ? input.titleEs : null,
  active: input.active,
  is_rams: input.isRams,
  organisation: input.organisation,
  workspace: input.workspace,
  force_hazards: input.forceHazards,
});

export const AssessmentTypes = {
  mapToAssessmentType,
  mapToBackendAssessmentType,
};

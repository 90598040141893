import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import RootState from 'model/State/RootState';
import { EvidencesState } from 'model/State/jobOverview/EvidencesState';

const initialState: EvidencesState = {
  isSendingFeedback: false,
  isSendingFeedbackReason: false,
};

export const evidences = createSlice({
  name: 'evidences',
  initialState,
  reducers: {
    sendWrapUpFeedback: (
      state: EvidencesState,
      action: PayloadAction<{
        evidenceId: string;
        videoFeedback: number | null;
      }>,
    ) => {
      state.isSendingFeedback = true;
    },
    sendWrapUpFeedbackSuccess: (state: EvidencesState) => {
      state.isSendingFeedback = false;
    },
    sendWrapUpFeedbackError: (state: EvidencesState) => {
      state.isSendingFeedback = false;
    },
    sendWrapUpFeedbackReason: (
      state: EvidencesState,
      action: PayloadAction<{
        evidenceId: string;
        videoFeedbackReason: string;
      }>,
    ) => {
      state.isSendingFeedbackReason = true;
    },
    sendWrapUpFeedbackReasonSuccess: (state: EvidencesState) => {
      state.isSendingFeedbackReason = false;
    },
    sendWrapUpFeedbackReasonError: (state: EvidencesState) => {
      state.isSendingFeedbackReason = false;
    },
  },
});

export const {
  sendWrapUpFeedback,
  sendWrapUpFeedbackSuccess,
  sendWrapUpFeedbackError,
  sendWrapUpFeedbackReason,
  sendWrapUpFeedbackReasonSuccess,
  sendWrapUpFeedbackReasonError,
} = evidences.actions;

export const selectIsSendingFeedback = (state: RootState): boolean =>
  state.job.evidences.isSendingFeedback;
export const selectIsSendingFeedbackReason = (state: RootState): boolean =>
  state.job.evidences.isSendingFeedbackReason;

export const evidencesReducer = evidences.reducer;

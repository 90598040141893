import { Control } from 'model/Assessment/Control';
import RootState from 'model/State/RootState';
import { createSelector } from 'reselect';

export const selectControls = (state: RootState) =>
  state?.workspaceControls?.controls;
const selectControlsByHazardId = createSelector(
  selectControls,
  (_: RootState, id: number) => id,
  (controls: { [key: number]: Control[] }, id: number) => controls?.[id] || [],
);
export const selectControlsForHazard =
  (id: number) =>
  (state: RootState): Control[] =>
    selectControlsByHazardId(state, id);
export const selectIsFetchingControls = (state: RootState) =>
  state.workspaceControls.isFetchingControlsForHazard;
const selectIsFetchingByHazardId = createSelector(
  selectIsFetchingControls,
  (_: RootState, id: number) => id,
  (isFetchingControls: { [key: number]: boolean }, id: number) =>
    isFetchingControls?.[id] || false,
);
export const selectIsFetchingControlsForHazard =
  (id: number) => (state: RootState) =>
    selectIsFetchingByHazardId(state, id);
export const selectIsLoadingChanges = (state: RootState) =>
  state.workspaceControls.isLoadingControlsChangesByHazard;
const selectIsLoadingChangesByHazardId = createSelector(
  selectIsLoadingChanges,
  (_: RootState, id: number) => id,
  (controls: { [key: number]: boolean }, id: number) => controls?.[id] || false,
);
export const selectIsLoadingControlsChangesByHazard =
  (id: number) => (state: RootState) =>
    selectIsLoadingChangesByHazardId(state, id);
export const selectControlError = (state: RootState) =>
  state.workspaceControls.error;

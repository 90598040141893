import { User } from 'api';
import { LanguageSelector } from 'components/ui/Input/LanguageSelector';
import { useLocalStorage, useText } from 'hooks';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setGlobalLanguage } from 'store/actions/session';
import styled from 'styled-components';
import { Languages } from 'utils/constants';

const Container = styled.div``;

// ChangeLanguage
const ChangeLanguage = () => {
  const dispatch = useDispatch();
  const getText = useText();
  const navigate = useNavigate();
  const location = useLocation();
  const [appLanguage, setAppLanguage] =
    useLocalStorage<string>('globalAppLanguage');
  const languageOptions = Languages.all.map((language) => ({
    value: language.locale,
    title: getText(language.displayName.toLowerCase()),
  }));

  const onLanguageChange = async (value: string) => {
    await User.get(value);
    dispatch(setGlobalLanguage(value));
    setAppLanguage(value);
    navigate(location.pathname, {
      state: location.state,
    });
  };

  return (
    <Container>
      <LanguageSelector
        options={languageOptions}
        selected={appLanguage}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onLanguageChange(e?.target?.value)
        }
      />
    </Container>
  );
};

export default ChangeLanguage;

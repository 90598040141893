import { Button, Input, Loader } from 'components/ui';
import {
  ActionButtonContainer,
  BoldText,
  DataContainer,
  EmptyListContainer,
  ErrorMessage,
  FormContainer,
  H2,
  InputContainer,
  ItemsContainer,
  Wrapper,
} from './styled';
import { useLocalStorage, useLocales, useText } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { useCustomLocaleText } from 'hooks/useText';
import { PlusIcon } from 'assets/icons';
import {
  getLocalizedValue,
  hasItemPropertyChanged,
  isMissingTitles,
} from 'helpers/locales';
import { TITLE_PROPERTY_NAME } from '../../constants';
import { useEffect, useState } from 'react';
import { WarningTag } from 'components/ui/Tags/WarningTag';
import RootState from 'model/State/RootState';
import {
  addNewAssessment,
  editExistingAssessment,
  fetchWorkspaceAssessments,
  removeExistingAssessment,
  resetError,
} from 'store/actions/settings/assessments';
import {
  selectAssessmentError,
  selectAssessments,
  selectIsFetchingAssessments,
  selectIsLoadingAssessmentChange,
} from 'store/selectors/settings/assessments';
import { TypeItemRow } from '../../WorkspaceSettings/TypeItemRow';
import WorkspaceAssessmentItem from 'model/State/WorkspaceAssessmentItem';
import { AssessmentWithoutHazardModal } from '../../../Modal/AssessmentWithoutHazardModal';
import { AssessmentDeleteConfirmationModal } from '../AssessmentDeleteConfirmationModal';

export const AssessmentSettings = () => {
  const getText = useText();
  const getCustomText = useCustomLocaleText();
  const { availableLocales } = useLocales();
  const dispatch = useDispatch();
  const [isFirstAddition, setIsFirstAddition] = useLocalStorage<boolean>(
    'assessmentFirstAddition',
    true,
  );
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const assessments = useSelector(selectAssessments(selectedWorkspace?.uuid));
  const isFetchingAssessments = useSelector(selectIsFetchingAssessments);
  const isLoadingAssessmentChange = useSelector(
    selectIsLoadingAssessmentChange,
  );
  const error = useSelector(selectAssessmentError);
  const [titlesByLanguage, setTitlesByLanguage] = useState<{
    [key: string]: string;
  }>({});
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isHazardInfoOpen, setHazardInfoOpen] = useState<boolean>(false);
  const [editedAssessment, setEditedAssessment] =
    useState<WorkspaceAssessmentItem | null>(null);
  const [assessmentToDelete, setAssessmentToDelete] =
    useState<WorkspaceAssessmentItem | null>(null);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);

  const isUnchangedTitles =
    isUpdate &&
    editedAssessment &&
    !hasItemPropertyChanged(
      editedAssessment,
      titlesByLanguage,
      TITLE_PROPERTY_NAME,
      availableLocales,
    );

  const isAddEditDisabled =
    isLoadingAssessmentChange ||
    isMissingTitles(titlesByLanguage, availableLocales) ||
    isUnchangedTitles;

  const hasAssessmentsWithoutHazard =
    assessments.filter(
      (assessment: WorkspaceAssessmentItem) => !assessment?.hazards?.length,
    )?.length > 0;

  useEffect(() => {
    dispatch(resetError());
    if (selectedWorkspace?.uuid) {
      dispatch(fetchWorkspaceAssessments(selectedWorkspace?.uuid));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoadingAssessmentChange) {
      resetForm();
    }
  }, [isLoadingAssessmentChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const setValueByLocale = (locale, value) => {
    setTitlesByLanguage((prevState) => ({ ...prevState, [locale]: value }));
  };

  const resetForm = () => {
    setTitlesByLanguage({});
    setIsUpdate(false);
    setEditedAssessment(null);
  };

  const addOrEditAssessment = () => {
    dispatch(resetError());
    if (isUpdate) {
      dispatch(
        editExistingAssessment(selectedWorkspace?.uuid, {
          ...editedAssessment,
          ...titlesByLanguage,
        }),
      );
    } else {
      if (isFirstAddition) {
        setHazardInfoOpen(true);
        setIsFirstAddition(false);
      }
      dispatch(addNewAssessment(selectedWorkspace?.uuid, titlesByLanguage));
    }
  };

  const editAssessment = (assessment) => {
    setIsUpdate(true);
    setEditedAssessment(assessment);
    availableLocales.forEach((locale) => {
      setValueByLocale(
        locale,
        getLocalizedValue(assessment, TITLE_PROPERTY_NAME, locale) ?? '',
      );
    });
  };

  const removeAssessment = (assessment) => {
    dispatch(resetError());
    setDeleteConfirmationOpen(true);
    setAssessmentToDelete(assessment);
  };

  const confirmDeleteAssessment = () => {
    dispatch(
      removeExistingAssessment(selectedWorkspace?.uuid, assessmentToDelete),
    );
    setDeleteConfirmationOpen(false);
  };

  return (
    <Wrapper>
      {!isFetchingAssessments && (
        <>
          <DataContainer>
            <H2>{getText('workspace_settings_manage_risk_assessment')}</H2>
            {hasAssessmentsWithoutHazard && (
              <WarningTag
                text={getText(
                  'workspace_settings_risk_assessment_no_hazard_warning',
                )}
                extraStyles={{ width: 'fit-content' }}
              />
            )}
            <ItemsContainer>
              {assessments?.length > 0 ? (
                assessments.map((assessment) => (
                  <TypeItemRow
                    id={`assessment-${assessment.id}`}
                    key={`assessment-${assessment.id}`}
                    item={assessment}
                    onEditClick={() => editAssessment(assessment)}
                    onRemoveClick={() => removeAssessment(assessment)}
                    infoMessage={
                      assessment?.hazards?.length
                        ? ''
                        : getText(
                            'workspace_settings_risk_assessment_no_hazard_hint',
                          )
                    }
                  />
                ))
              ) : (
                <EmptyListContainer>
                  <BoldText>
                    {getText('workspace_settings_no_risk_assessment')}
                  </BoldText>
                  <div>
                    {getText('workspace_settings_no_risk_assessment_hint')}
                  </div>
                </EmptyListContainer>
              )}
            </ItemsContainer>
          </DataContainer>
          <FormContainer>
            <H2>{getText('workspace_settings_add_edit_risk_assessment')}</H2>
            <InputContainer
              $swapOrder={globalAppLanguage !== availableLocales[0]}
            >
              {availableLocales.map((locale) => (
                <Input.Text
                  key={locale}
                  id={`assessment-${locale}-input`}
                  value={titlesByLanguage[locale] ?? ''}
                  label={getCustomText(
                    'workspace_settings_risk_assessment_title',
                    locale,
                  )}
                  onChange={(e) => setValueByLocale(locale, e.target.value)}
                  disabled={isLoadingAssessmentChange}
                  maxLength={300}
                />
              ))}
            </InputContainer>
            <ActionButtonContainer>
              <Button.Primary
                text={getText(
                  isUpdate
                    ? 'workspace_settings_update'
                    : 'workspace_settings_add',
                )}
                IconComponent={isUpdate ? null : PlusIcon}
                onClick={addOrEditAssessment}
                disabled={isAddEditDisabled}
                loading={isLoadingAssessmentChange}
                width='200px'
                extraStyles={{ margin: 0 }}
              />
              {Object.values(titlesByLanguage).some((v) => v !== '') &&
                !isLoadingAssessmentChange && (
                  <Button.Confirm
                    onClick={resetForm}
                    text={getText('cancel')}
                    width='130px'
                    extraStyles={{ margin: '0 20px' }}
                  />
                )}
            </ActionButtonContainer>
            {error && (
              <ErrorMessage>
                {getText('workspace_settings_error_single', {
                  type: getLocalizedValue(
                    error,
                    TITLE_PROPERTY_NAME,
                    globalAppLanguage,
                  ),
                })}
              </ErrorMessage>
            )}
          </FormContainer>
        </>
      )}
      {isFetchingAssessments && <Loader />}

      <AssessmentWithoutHazardModal
        isVisible={isHazardInfoOpen}
        setIsVisible={setHazardInfoOpen}
      />
      {isDeleteConfirmationOpen && (
        <AssessmentDeleteConfirmationModal
          isVisible={isDeleteConfirmationOpen}
          setVisible={setDeleteConfirmationOpen}
          title={getText(
            'workspace_settings_risk_assessment_delete_confirmation',
          )}
          warningMessages={[
            getText('workspace_setting_risk_assessment_delete_warning_first'),
            getText('workspace_setting_risk_assessment_delete_warning_second'),
          ]}
          saveButtonText={getText('continue_cta')}
          onButtonClick={confirmDeleteAssessment}
          hasConfirmToggle
        />
      )}
    </Wrapper>
  );
};

import { SelectChangeEvent } from '@mui/material';
import { useJobs, useIsMobile, useText } from 'hooks';
import { useSelector } from 'react-redux';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import RootState from 'model/State/RootState';
import { JobSort } from 'model/enum/JobSort';
import { Chevron, SelectValue, StyledOptions, StyledSelect } from './styled';
import theme from 'theme';

const { colors } = theme;

// Form.Sort
export const Sort = () => {
  const { filters } = useSelector((state: RootState) => state.legacyViewJobs);
  const { setFilters } = useJobs();
  const isMobile = useIsMobile();
  const getText = useText();

  const isLiveJobs = filters.scope === JobSort.USER;

  const sortOptions = [
    {
      title: getText('job_search_sort_jobs_by_dialog'),
      value: 'sort_by',
      disabled: true,
    },
    { title: getText('job_search_sort_by_priority'), value: JobSort.PRIORITY },
    { title: getText('job_search_sort_by_created'), value: JobSort.CREATED },
  ];

  const onSortChange = (value) => {
    const currentSort = sortOptions.find((option) => option.value === value);
    const previousSort = sortOptions.find(
      (option) => option.value === filters?.sort,
    );
    Analytics.trackEvent(constants.EVENT_SORT_SUBMITTED, {
      currentSort,
      previousSort,
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: constants.LEGACY_VIEW,
    });
    setFilters({ ...filters, sort: value });
  };

  const onSortFocus = () => {
    const currentSort = sortOptions.find(
      (option) => option.value === filters.sort,
    );
    Analytics.trackEvent(constants.EVENT_SORT_CLICKED, {
      currentSort,
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: constants.LIST_VIEW_TAB,
    });
  };

  return (
    <StyledSelect
      defaultValue={JobSort.PRIORITY}
      value={filters.sort}
      renderValue={() => (
        <SelectValue>
          {sortOptions.find((option) => option.value === filters.sort)?.title}
        </SelectValue>
      )}
      disabled={!isLiveJobs}
      isMobile={isMobile}
      onChange={(e: SelectChangeEvent<unknown>) =>
        onSortChange(e?.target?.value)
      }
      onOpen={onSortFocus}
      IconComponent={() => (
        <Chevron disabled={!isLiveJobs} isMobile={isMobile} />
      )}
      MenuProps={{
        sx: {
          '& .MuiMenu-list': {
            padding: 0,
            border: `2px solid ${colors.black}`,
          },
          '& .MuiPopover-paper': {
            borderRadius: 0,
          },
        },
      }}
    >
      {sortOptions.map((option) => (
        <StyledOptions
          key={option.value}
          value={option.value}
          disabled={option.disabled}
          isMobile={isMobile}
        >
          {option.title}
        </StyledOptions>
      ))}
    </StyledSelect>
  );
};

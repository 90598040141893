import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useCommandCenter, useJobs, useText } from 'hooks';
import { Loader, Media, Modal } from 'components/ui';
import { fetchAssessment } from 'store/actions/assessments';
import { Storage } from 'aws-amplify';
import { fetchActiveJob, setJobPhotos } from 'store/actions/jobs';
import { RamsAssessment } from './RamsAssessment';
import { HazardSummary } from './Summary/HazardSummary';
import { useNavigate, useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import { AssessmentTimeline } from './Timeline';
import {
  ContentContainer,
  FirstColumn,
  LocationContainer,
  LocationIcon,
  LocationText,
  ModalContentWrapper,
  SecondColumn,
  SectionContentWrapper,
  VideoContainer,
} from './styled';
import { SuccessfulSave } from './MethodStatement/SuccessfulSave';
import { selectActiveJob } from 'store/selectors';
import { getJobFullAddress } from 'helpers/names';
import {
  clearRamsAssessment,
  selectActiveRamsAssessment,
  setAssessmentStage,
  setMethodStatement,
} from 'store/slices/ramsAssessments';
import { RamsStages } from 'model/Assessment/RAMS';

export const RamsAssessmentModal = () => {
  const { jobId, assessmentId } = useParams();
  const dispatch = useDispatch();
  const getText = useText();
  const navigate = useNavigate();
  const { navigateToOverviewTabOrJob } = useJobs();
  const { isCommandCenter } = useCommandCenter();

  const assessment = useSelector(selectActiveRamsAssessment);
  const { isActiveAssessmentLoaded: isLoaded } = useSelector(
    (state: RootState) => state.assessments,
  );
  const { currentStage, isSubmitting, methodStatementSubmitted } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );
  const activeJob = useSelector(selectActiveJob);

  const videoUrl = assessment?.signedUrl;
  const videoThumbnail = assessment?.video?.thumbnail;
  const [thumbnailURL, setThumbnailURL] = useState<string>();
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const isCurrentAssessmentLoaded =
    parseInt(assessmentId ?? '', 10) === assessment?.id;

  useEffect(() => {
    (async () => {
      if (videoThumbnail) {
        const signedURL = await Storage.get(videoThumbnail);
        setThumbnailURL(signedURL);
      }
    })();
  }, [videoThumbnail]);

  const redirectToJob = () => {
    if (isCommandCenter) {
      navigate('..');
    } else {
      navigateToOverviewTabOrJob(`/jobs/${jobId}/assessments`);
    }
    dispatch(clearRamsAssessment());
  };

  useEffect(() => {
    if (methodStatementSubmitted && !isSubmitting) {
      setShowSuccessMessage(true);
      dispatch(setJobPhotos([]));
    }
  }, [methodStatementSubmitted, isSubmitting]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect((): (() => void) => {
    if (!assessment || !isCurrentAssessmentLoaded) {
      dispatch(fetchAssessment(assessmentId));
    }
    return () => dispatch(clearRamsAssessment());
  }, [assessmentId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!activeJob) {
      dispatch(fetchActiveJob(jobId));
    }
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (assessmentId && jobId && videoUrl) {
      Analytics.trackPage(constants.EVENT_PAGE_VIEW_RISK_ASSESSMENT, {
        jobId,
        assessmentId,
        mediaPath: videoUrl,
      });
    }
  }, [assessmentId, jobId, videoUrl]);

  useEffect(() => {
    if (assessment) {
      if (assessment?.methodStatement) {
        dispatch(setMethodStatement(assessment.methodStatement));
      }

      dispatch(
        setAssessmentStage(
          assessment.ramsReviews?.length > 0 || assessment?.methodStatement
            ? RamsStages.ramsActionSelection
            : RamsStages.hazardIdentification,
        ),
      );
    }
  }, [assessment?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderModalContent = () => {
    if (
      (!isLoaded && (!assessment || !isCurrentAssessmentLoaded)) ||
      !currentStage
    ) {
      return <Loader />;
    }
    if (showSuccessMessage) {
      return (
        <SuccessfulSave hideContent={() => setShowSuccessMessage(false)} />
      );
    }
    return (
      <>
        {assessment && currentStage && (
          <AssessmentTimeline
            assessmentStage={currentStage}
            assessment={assessment}
          />
        )}
        <ContentContainer>
          <FirstColumn>
            <VideoContainer>
              <Media
                kind='assessment'
                type='video'
                url={videoUrl}
                width='100%'
                height='100%'
                thumbnail={thumbnailURL}
                isRams
                item={assessment?.video}
              />
            </VideoContainer>
            {activeJob && (
              <LocationContainer>
                <LocationIcon />
                <LocationText>
                  {getJobFullAddress(activeJob, false)}
                </LocationText>
              </LocationContainer>
            )}
            {currentStage === RamsStages.methodStatement && <HazardSummary />}
          </FirstColumn>
          <SecondColumn>
            <SectionContentWrapper className='rams-container'>
              <RamsAssessment />
            </SectionContentWrapper>
          </SecondColumn>
        </ContentContainer>
      </>
    );
  };

  return (
    <Modal.Base
      isOpen
      title={getText('rams_title')}
      onClose={redirectToJob}
      width='70%'
    >
      <ModalContentWrapper>{renderModalContent()}</ModalContentWrapper>
    </Modal.Base>
  );
};

import Coordinate from 'model/Coordinate';
import { CommandCenterFilter } from 'model/enum/CommandCenterFilter';
import { CommandCenterStats } from './Stats';
import { Job } from 'model/Job/Job';
import { CommandCenterJobType } from './JobTypes';

const getJobType = (
  job: Job,
  filterId: CommandCenterFilter,
  stats: CommandCenterStats,
) => {
  if (filterId === CommandCenterFilter.JOBS_WITH_UNRESOLVED_BLOCKERS) {
    return CommandCenterJobType.BLOCKED;
  }
  if (filterId === CommandCenterFilter.JOBS_COMPLETED) {
    return CommandCenterJobType.COMPLETED;
  }
  if (
    stats?.jobsWithMajorHazards.find(
      (jobWithMajor) => jobWithMajor.id === job.id,
    )
  ) {
    return CommandCenterJobType.MAJOR_HAZARD;
  }
  if (job.pendingJobBlockerCount > 0) {
    return CommandCenterJobType.BLOCKED;
  }
  return CommandCenterJobType.LIVE;
};

const areCoordinatesEqual = (
  prevCoords: Coordinate[],
  nextCoords: Coordinate[],
): boolean => {
  if (prevCoords.length !== nextCoords.length) {
    return false;
  }

  return prevCoords.every((prevCoord, index) => {
    const nextCoord = nextCoords[index];
    return (
      prevCoord.lat === nextCoord.lat &&
      prevCoord.lng === nextCoord.lng &&
      prevCoord.type === nextCoord.type &&
      prevCoord.jobId === nextCoord.jobId
    );
  });
};

export const CommandCenter = { getJobType, areCoordinatesEqual };

import { Depot } from 'model/Job/Depot';
import { User } from 'model/User';

export type CommandCenterFilterOptions = {
  workspaceUuid: string | null;
  depot: Depot | null;
  users: User[];
};

export const initialFilterOptions: CommandCenterFilterOptions = {
  workspaceUuid: null,
  depot: null,
  users: [],
};

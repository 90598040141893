import { Pencil } from 'assets/icons';
import { Button } from 'components/ui';
import { useText } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { ListWrapper, SignOnListContainer, Subtitle } from './styled';
import { getNotSignedOnUsers, getSignedOnUsers } from 'helpers/users';
import { Assessment } from 'model/Assessment';
import { SignOnUserRow } from './SignOnUserRow';
import { EVENT_ADD_TEAM_MEMBERS_CLICKED } from '../constants';
import {
  selectIsEditTagDismissed,
  selectUserCanEditRams,
  setAssessmentStage,
} from 'store/slices/ramsAssessments';
import { ActionButtons, ActionButtonsWrapper } from '../styled';
import { NotEditableTag } from '../NotEditableTag';
import { RamsStages } from 'model/Assessment/RAMS';

type Props = {
  assessment: Assessment;
};

export const SignOnList = ({ assessment }: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const signedOnUsers = getSignedOnUsers(assessment);
  const notSignedOnUsers = getNotSignedOnUsers(assessment);
  const canUserEdit = useSelector(selectUserCanEditRams);
  const isEditTagDismissed = useSelector(selectIsEditTagDismissed);

  return (
    <>
      <SignOnListContainer large={canUserEdit || isEditTagDismissed}>
        {notSignedOnUsers.length > 0 && (
          <ListWrapper>
            <Subtitle>{getText('rams_sign_on_awaiting')}</Subtitle>
            {notSignedOnUsers.map((user) => (
              <SignOnUserRow
                user={user}
                key={`not-signed-on-user-${user?.id}`}
              />
            ))}
          </ListWrapper>
        )}
        {signedOnUsers.length > 0 && (
          <ListWrapper>
            <Subtitle>{getText('rams_sign_on_signed_on')}</Subtitle>
            {signedOnUsers.map((user) => (
              <SignOnUserRow
                user={user}
                key={`not-signed-on-user-${user?.id}`}
              />
            ))}
          </ListWrapper>
        )}
      </SignOnListContainer>
      {!canUserEdit && <NotEditableTag />}
      <ActionButtonsWrapper>
        <ActionButtons>
          <Button.Secondary
            text={getText('rams_sign_on_add_remove_members')}
            onClick={() =>
              dispatch(setAssessmentStage(RamsStages.signOnEditMembers))
            }
            disabled={!canUserEdit}
            IconComponent={Pencil}
            width='50%'
            event={EVENT_ADD_TEAM_MEMBERS_CLICKED}
            eventProperties={{
              assessmentId: assessment?.id,
              assessmentType: assessment?.type,
            }}
            extraStyles={{
              margin: 0,
              alignSelf: 'flex-end',
              height: 'inherit',
              maxHeight: '55px',
            }}
          />
        </ActionButtons>
      </ActionButtonsWrapper>
    </>
  );
};

import { Input } from 'components/ui';
import { useText } from 'hooks';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  extraStyles?: React.CSSProperties;
  isFocused?: boolean;
};

export const DateRangeFilter = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  extraStyles = {},
  isFocused = false,
}: Props): JSX.Element => {
  const getText = useText();

  return (
    <Input.DateRangeSelector
      label={getText('filter_data_select_date_range')}
      startDate={startDate}
      endDate={endDate}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      extraStyles={extraStyles}
      isFocused={isFocused}
    />
  );
};

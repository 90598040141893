import { Button as MaterialButton } from '@mui/material';
import { Check } from 'assets/icons';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const { colors } = theme;

export const Button = styled(({ disabled, ...rest }) => (
  <MaterialButton disabled={disabled} {...rest} />
))`
  &.MuiButtonBase-root {
    height: 44px;
    width: 45px;
    min-width: auto;
    background-color: ${({ disabled }) =>
      disabled ? colors.lightGrey : colors.white} !important;
    box-sizing: border-box;
    border-radius: 0;
    outline: 2px solid ${colors.black};
    margin: 2px;
    &:hover {
      outline: 3px solid ${colors.black};
    }
    &.Mui-disabled {
      outline: 2px solid ${colors.grey};
      background-color: ${colors.lightGrey} !important;
      &:hover {
        outline: 2px solid ${colors.grey};
        background-color: ${colors.lightGrey} !important;
      }
    }
  }
`;

export const CheckIcon = styled(Check)`
  .fill-selector {
    fill: ${colors.darkGrey};
  }
`;

export const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: 25px;
  width: 25px;
`;

import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const LinkContainer = styled(RouterLink)<{
  width: string;
  disabled: boolean;
  $hasBlackBackground?: boolean;
}>`
  height: 55px;
  width: ${({ width }) => width};
  color: ${colors.black} !important;
  box-sizing: border-box;
  outline: ${({ disabled, $hasBlackBackground }) => {
    if ($hasBlackBackground) {
      return disabled
        ? `2px solid ${colors.black}`
        : `2px solid ${colors.grey}`;
    }

    return disabled ? `2px solid ${colors.grey}` : `2px solid ${colors.black}`;
  }};
  background-color: ${({ disabled }) =>
    disabled ? colors.lightGrey : colors.yellow};
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
  padding: 0 15px;
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  ${mixins.flexBetween};
  align-self: flex-end;
  margin: 2px;
  text-decoration: none;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  &:hover {
    outline: ${({ disabled, $hasBlackBackground }) => {
      if ($hasBlackBackground) {
        return disabled
          ? `2px solid ${colors.black}`
          : `3px solid ${colors.lightGrey}`;
      }

      return disabled
        ? `2px solid ${colors.grey}`
        : `3px solid ${colors.black}`;
    }};
  }
  ${media.xl`
    height: 45px;
    font-size: ${fontSizes.s};
    padding: 0 10px;
  `}
`;

export const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: 25px;
  width: 25px;
  margin-right: 15px;
  .fill-selector {
    fill: ${colors.black};
  }
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
  ${media.xl`
    height: 18px;
    width: 18px;
  `}
`;

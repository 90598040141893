import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
import { Modal } from 'components/ui';
import { ThumbsDown, ThumbsUp } from 'assets/icons';

const { mixins, fontSizes, fonts, colors } = theme;

const ModalContent = styled.div`
  padding: 35px 50px;
`;

const InfoTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
`;

const InfoSubTitle = styled.div`
  font-size: ${fontSizes.m};
  padding: 20px 0;
`;

const RiskTypeContainer = styled.ul`
  margin-left: 20px !important;
`;

const RiskType = styled.li`
  font-size: ${fontSizes.m};
  padding: 5px 0;
`;

const FeedbackContainer = styled.div`
  background-color: ${colors.orange};
  display: flex;
  font-size: ${fontSizes.m};
  padding: 10px;
`;

const IconsContainer = styled.div`
  ${mixins.flexColumn};
  justify-content: space-around;
  margin-right: 10px;
`;

const StyledThumbsUp = styled(ThumbsUp)`
  width: 25px;
  height: auto;
  .fill-selector {
    fill: ${colors.black};
  }
`;

const StyledThumbsDown = styled(ThumbsDown)`
  width: 25px;
  height: auto;
  .fill-selector {
    fill: ${colors.black};
  }
`;

const FeedbackTextContainer = styled.div`
  line-height: ${fontSizes.xl};
`;

const FeedbackTextBold = styled.span`
  font-family: ${fonts.bold};
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const InfoModal = ({ isOpen, onClose }: Props): JSX.Element => {
  const getText = useText();

  return (
    <Modal.Base isOpen={isOpen} onClose={onClose} width='650px'>
      <ModalContent>
        <InfoTitle>
          {getText('job_overview_safety_prediction_info_title')}
        </InfoTitle>
        <InfoSubTitle>
          {getText('job_overview_safety_prediction_info_subtitle')}
        </InfoSubTitle>
        <RiskTypeContainer>
          <RiskType>
            {getText('job_overview_safety_prediction_info_risk_type_injury')}
          </RiskType>
          <RiskType>
            {getText('job_overview_safety_prediction_info_risk_type_public')}
          </RiskType>
          <RiskType>
            {getText('job_overview_safety_prediction_info_risk_type_property')}
          </RiskType>
          <RiskType>
            {getText(
              'job_overview_safety_prediction_info_risk_type_environment',
            )}
          </RiskType>
          <RiskType>
            {getText('job_overview_safety_prediction_info_risk_type_supply')}
          </RiskType>
        </RiskTypeContainer>
        <InfoSubTitle>
          {getText('job_overview_safety_prediction_info_risk_score')}
        </InfoSubTitle>
        <FeedbackContainer>
          <IconsContainer>
            <StyledThumbsUp />
            <StyledThumbsDown />
          </IconsContainer>
          <FeedbackTextContainer>
            {getText('job_overview_safety_prediction_info_feedback')}
            <FeedbackTextBold>
              {getText(
                'job_overview_safety_prediction_info_feedback_agree_disagree',
              )}
            </FeedbackTextBold>
          </FeedbackTextContainer>
        </FeedbackContainer>
      </ModalContent>
    </Modal.Base>
  );
};

export default InfoModal;

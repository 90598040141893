import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useSafetyPrediction, useText } from 'hooks';
import theme from 'theme';
import { Card, EmptyState, Loader, Navbar } from 'components/ui';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import SearchHeader from './SearchHeader';
import { fetchMoreLegacyViewJobs } from 'store/actions/jobs';
import RootState from 'model/State/RootState';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { JobSort } from 'model/enum/JobSort';

const { colors, media, mixins, fonts } = theme;

// Styled Components
const Container = styled(Grid)`
  &&& {
    height: 100%;
    background-color: 'inherit';
    border-right: 2px solid ${colors.black};
    ${mixins.flexColumn};
    ${media.sm`
      border: none;
    `}
  }
`;

const JobsContainer = styled.div`
  flex: 1;
  padding: 15px;
  overflow: overlay;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 12px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    min-height: 80px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0;
  padding: 8px;
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${fonts.bold};
  cursor: pointer;
  color: ${colors.darkGrey};
  &:hover {
    color: ${colors.black};
  }
`;

// List
const List = () => {
  const dispatch = useDispatch();
  const { getRiskWarning } = useSafetyPrediction();
  const { jobs, nextLink, filters, isFetchingJobs, isFetchingMoreJobs } =
    useSelector((state: RootState) => state.legacyViewJobs);
  const { currentUser } = useSelector((state: RootState) => state.session);
  const navigate = useNavigate();
  const { jobId } = useParams();
  const getText = useText();

  const handleOnClick = async (job) => {
    navigate(`/jobs/${job.id}`);
    Analytics.trackEvent(constants.EVENT_VIEW_JOB, {
      source:
        filters.scope === JobSort.USER
          ? constants.JobsToggle.MY_JOBS
          : constants.JobsToggle.ALL_JOBS,
      jobId: job.id,
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: constants.LEGACY_VIEW,
      [constants.EVENT_PROPERTIES_SAFETY_RISK_FIELD]: !!getRiskWarning(
        job.safety_prediction_score,
      ),
      [constants.EVENT_PROPERTIES_HAS_UNREAD_MESSAGES_FIELD]:
        job.unread_messages > 0,
      [constants.EVENT_PROPERTIES_HAS_PENDING_JOB_BLOCKERS_FIELD]:
        job.pending_job_blocker_count > 0,
      [constants.EVENT_PROPERTIES_PENDING_ASSESSMENT_SIGN_OFF_FIELD]:
        job.sign_off_pending_assessment_count > 0,
      [constants.EVENT_PROPERTIES_USER_ID_FIELD]: currentUser.id,
      [constants.EVENT_PROPERTIES_HAS_MAJOR_HAZARD]: job.has_major_hazard,
      [constants.EVENT_PROPERTIES_HAS_RISK_ASSESSMENT_CREATED]:
        !!job.most_recent_risk_assessment_created_at,
    });
  };

  return (
    <>
      <Navbar home mobileOnly />
      <Container item xs={24} sm={8} md={6} lg={6}>
        <SearchHeader />
        {(isFetchingJobs || !jobs) && <Loader />}
        {!isFetchingJobs && jobs && jobs.length > 0 && (
          <JobsContainer>
            {jobs.map((job) => (
              <Card.Job
                key={job.id}
                job={job}
                isActive={parseInt(jobId ?? '', 10) === job.id}
                isImportant={false}
                isMyJobList={filters.scope === JobSort.USER}
                onClick={() => handleOnClick(job)}
              />
            ))}
            {nextLink && (
              <LoadMoreWrapper>
                {isFetchingMoreJobs ? (
                  <Loader />
                ) : (
                  <LoadMore
                    onClick={() => {
                      dispatch(fetchMoreLegacyViewJobs(nextLink));
                      Analytics.trackEvent(constants.EVENT_JOB_SEARCH_MORE, {
                        source:
                          filters.scope === JobSort.USER
                            ? constants.JobsToggle.MY_JOBS
                            : constants.JobsToggle.ALL_JOBS,
                        [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]:
                          constants.LEGACY_VIEW,
                      });
                    }}
                  >
                    {getText('load_more')}
                  </LoadMore>
                )}
              </LoadMoreWrapper>
            )}
          </JobsContainer>
        )}
        {!isFetchingJobs && jobs && jobs.length === 0 && (
          <EmptyState.JobsList />
        )}
      </Container>
    </>
  );
};

export default List;

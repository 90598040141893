import { useText } from 'hooks';
import { FooterContainer, FooterLink } from './styled';

const Privacy = () => {
  const getText = useText();
  return (
    <FooterLink
      href={`${process.env.PUBLIC_URL}/FYLD-Privacy-Policy.pdf`}
      target='_blank'
    >
      {getText('privacy_policy')}
    </FooterLink>
  );
};

const Terms = () => {
  const getText = useText();
  return (
    <FooterLink
      href={`${process.env.PUBLIC_URL}/FYLD-TCs-20200804.pdf`}
      target='_blank'
    >
      {getText('terms_and_conditions')}
    </FooterLink>
  );
};

export const RawFooter = ({ termsFirst = false }: { termsFirst: boolean }) => {
  const [first, second] = termsFirst
    ? [<Terms key='terms' />, <Privacy key='privacy' />]
    : [<Privacy key='privacy' />, <Terms key='terms' />];

  return (
    <>
      {' '}
      {first}
      {' | '}
      {second}{' '}
    </>
  );
};

// Footer
export const Footer = ({ isOktaLogin = false }: { isOktaLogin?: boolean }) => (
  <FooterContainer $removeFromFlow={isOktaLogin}>
    | <RawFooter termsFirst={false} /> |
  </FooterContainer>
);

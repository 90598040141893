import { useState } from 'react';
import { CSSProperties } from 'styled-components';
import { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useText } from 'hooks';
import { AWSRegion } from 'utils/constants';
import { InputContainer, StyledPhoneInput } from './styled';
import { Container, LabelWrapper, StyledLabel } from '../styled';

type Props = {
  id: string;
  value?: string;
  onChange?: (value: string, countryCode: string) => void;
  label?: string;
  labelColor?: string;
  error?: string | boolean;
  disabled?: boolean;
  extraStyles?: CSSProperties;
};

// Input.PhoneNumber
export const PhoneNumber = ({
  id,
  value,
  onChange = () => {},
  label,
  labelColor,
  error,
  disabled = false,
  extraStyles,
}: Props): JSX.Element => {
  const getText = useText();
  const [countryCode, setCountryCode] = useState<string>(
    process.env.REACT_APP_AWS_REGION === AWSRegion.US_EAST_1 ? 'us' : 'gb',
  );

  const onInputChange = (newValue: string, data: {} | CountryData) => {
    const phoneNumber = newValue ? `+${newValue}` : '';
    const dialCode = `+${(data as CountryData)?.dialCode ?? ''}`;
    onChange(phoneNumber, dialCode);
    setCountryCode((data as CountryData)?.countryCode ?? '');
  };

  return (
    <Container style={extraStyles}>
      {label && (
        <LabelWrapper>
          <StyledLabel htmlFor={id} color={labelColor}>
            {label}
          </StyledLabel>
        </LabelWrapper>
      )}

      <InputContainer disabled={disabled} $error={!!error}>
        <StyledPhoneInput
          countryCodeEditable={false}
          country={value ? undefined : countryCode}
          placeholder={getText('phone_number_input_text')}
          value={value}
          onChange={(newValue, data) => onInputChange(newValue, data)}
          disabled={disabled}
        />
      </InputContainer>
    </Container>
  );
};

import {
  ButtonWrapper,
  ErrorMessage,
  FormContainer,
  InputWrapper,
} from './styled';
import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';
import { Button, Input } from 'components/ui';
import useText, { useCustomLocaleText } from 'hooks/useText';
import { Languages } from 'utils/constants';
import { useSelector } from 'react-redux';
import { selectHasOrganizationSettingEnabled } from 'store/selectors';
import { isBlank } from 'helpers/utils';
import { WorkflowStep, WorkflowTask, WorkflowTaskType } from 'model/Workflows';
import {
  WorkflowStepFieldNames,
  WorkflowStepFieldTranslationKeys,
} from '../../constants';
import {
  fetchWorkflowTasks,
  fetchWorkflowTaskTypes,
  selectIsLoadingWorkflowTasks,
  selectIsLoadingWorkflowTaskTypes,
  selectWorkflowTasks,
  selectWorkflowTaskTypes,
} from 'store/slices/workflows';
import { useDispatch } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';
import { BookmarkIcon, Cross } from 'assets/icons';

type Props = {
  step: WorkflowStep | null;
  setStep: Dispatch<SetStateAction<WorkflowStep | null>>;
  onSave: () => void;
  onCancel: () => void;
  disabled: boolean;
};

export const AddEditWorkflowStep = ({
  step,
  setStep,
  onSave,
  onCancel,
  disabled,
}: Props) => {
  const getText = useText();
  const dispatch = useDispatch();
  const canToggleBetweenLanguages = useSelector(
    selectHasOrganizationSettingEnabled('enable_language_selection'),
  );
  const tasks = useSelector(selectWorkflowTasks);
  const isLoadingTasks = useSelector(selectIsLoadingWorkflowTasks);
  const selectedTask: WorkflowTask = step?.[WorkflowStepFieldNames.TASK];

  const taskTypes = useSelector(selectWorkflowTaskTypes(selectedTask?.id));
  const isLoadingTaskTypes = useSelector(selectIsLoadingWorkflowTaskTypes);
  const getEnglishText = useCustomLocaleText(Languages.english.locale);
  const getSpanishText = useCustomLocaleText(Languages.spanish.locale);

  useEffect(() => {
    if (tasks.length === 0) {
      dispatch(fetchWorkflowTasks());
    }
  }, [dispatch, tasks.length]);

  useEffect(() => {
    if (selectedTask && taskTypes.length === 0) {
      dispatch(fetchWorkflowTaskTypes(selectedTask.id));
    }
  }, [dispatch, selectedTask, taskTypes.length]);

  const updateStepField = (
    fieldName: string,
    value: string | WorkflowTask | WorkflowTaskType,
  ) =>
    setStep((prevStep) => {
      if (fieldName === WorkflowStepFieldNames.TASK) {
        return {
          ...prevStep,
          [fieldName]: value,
          [WorkflowStepFieldNames.TASK_TYPE]: null,
        } as WorkflowStep;
      } else {
        return { ...prevStep, [fieldName]: value } as WorkflowStep;
      }
    });

  const isRequiredBlankError = (fieldName: string) =>
    step && isBlank(step[fieldName])
      ? getText('workspace_settings_required_error_message')
      : undefined;

  if (!step) {
    return null;
  }

  const taskOptions: { id: number; title: string }[] = tasks
    .map((task, index) => ({
      id: index,
      title: task.title,
    }))
    .concat({ id: -1, title: 'No task' });

  const taskTypeOptions: { id: number; title: string }[] = taskTypes
    .map((taskType, index) => ({
      id: index,
      title: taskType.title,
    }))
    .concat({ id: -1, title: 'No task type' });

  return (
    <FormContainer>
      <InputWrapper>
        <Input.Text
          id={WorkflowStepFieldNames.TITLE}
          label={getEnglishText(
            WorkflowStepFieldTranslationKeys[WorkflowStepFieldNames.TITLE],
          )}
          guidance='Teams will see this on the task list '
          value={step[WorkflowStepFieldNames.TITLE]}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            updateStepField(WorkflowStepFieldNames.TITLE, event.target.value)
          }
          required
          error={isRequiredBlankError(WorkflowStepFieldNames.TITLE)}
        />
        {isRequiredBlankError(WorkflowStepFieldNames.TITLE) && (
          <ErrorMessage>
            {isRequiredBlankError(WorkflowStepFieldNames.TITLE)}
          </ErrorMessage>
        )}
      </InputWrapper>
      {canToggleBetweenLanguages && (
        <InputWrapper>
          <Input.Text
            id={WorkflowStepFieldNames.TITLE_ES}
            label={getSpanishText(
              WorkflowStepFieldTranslationKeys[WorkflowStepFieldNames.TITLE_ES],
            )}
            guidance='Los equipos verán esto en la lista de tareas'
            value={step[WorkflowStepFieldNames.TITLE_ES]}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateStepField(
                WorkflowStepFieldNames.TITLE_ES,
                event.target.value,
              )
            }
            required
            error={isRequiredBlankError(WorkflowStepFieldNames.TITLE_ES)}
          />
          {isRequiredBlankError(WorkflowStepFieldNames.TITLE_ES) && (
            <ErrorMessage>
              {isRequiredBlankError(WorkflowStepFieldNames.TITLE_ES)}
            </ErrorMessage>
          )}
        </InputWrapper>
      )}
      <InputWrapper>
        <Input.Select
          id={WorkflowStepFieldNames.TASK}
          label={getText(
            WorkflowStepFieldTranslationKeys[WorkflowStepFieldNames.TASK],
            { task: step[WorkflowStepFieldNames.TASK] },
          )}
          guidance='Teams will be asked to submit this'
          options={taskOptions}
          selected={step[WorkflowStepFieldNames.TASK]}
          onChange={(event: SelectChangeEvent<unknown>) => {
            const selectedOption =
              Number(event.target.value) > -1
                ? tasks[Number(event.target.value)]
                : null;

            if (selectedOption) {
              updateStepField(WorkflowStepFieldNames.TASK, selectedOption);
            }
          }}
          large
          isLoading={isLoadingTasks}
        />
      </InputWrapper>
      {step[WorkflowStepFieldNames.TASK] && (
        <InputWrapper>
          <Input.Select
            id={WorkflowStepFieldNames.TASK_TYPE}
            label={getText(
              WorkflowStepFieldTranslationKeys[
                WorkflowStepFieldNames.TASK_TYPE
              ],
              { task: step[WorkflowStepFieldNames.TASK]?.title },
            )}
            guidance='Select what you expect field teams to record on FYLD'
            options={taskTypeOptions}
            selected={step[WorkflowStepFieldNames.TASK_TYPE]}
            onChange={(event: SelectChangeEvent<unknown>) => {
              const selectedOption =
                Number(event.target.value) > -1
                  ? taskTypeOptions[Number(event.target.value)]
                  : null;

              if (selectedOption) {
                updateStepField(
                  WorkflowStepFieldNames.TASK_TYPE,
                  selectedOption,
                );
              }
            }}
            large
            isLoading={isLoadingTaskTypes}
          />
        </InputWrapper>
      )}
      <ButtonWrapper>
        <Button.Secondary
          text={getText('cancel')}
          onClick={onCancel}
          width='200px'
          extraStyles={{
            margin: 0,
          }}
          iconFront
          IconComponent={Cross}
        />
        <Button.Primary
          text={getText('save')}
          onClick={onSave}
          disabled={disabled}
          width='200px'
          iconFront
          IconComponent={BookmarkIcon}
        />
      </ButtonWrapper>
    </FormContainer>
  );
};

import { Job } from 'api';
import * as Logger from 'utils/logger';
import { put, takeLatest } from 'redux-saga/effects';
import {
  fetchJobSubtypesError,
  fetchJobSubtypesSuccess,
  fetchJobTypesError,
  fetchJobTypesSuccess,
} from 'store/actions/jobTypes';
import { setGlobalError } from 'store/slices/notifications';
import { FETCH_JOB_SUBTYPES, FETCH_JOB_TYPES } from 'store/actions/actionTypes';

function* fetchJobTypes({ workspaceUuid }) {
  try {
    const { data } = yield Job.getAllTypes(workspaceUuid);
    yield put(fetchJobTypesSuccess(data.results));
  } catch (err) {
    Logger.error(err);
    yield put(fetchJobTypesError());
    yield put(setGlobalError(err));
  }
}

function* fetchJobSubtypes({ workspaceUuid }) {
  try {
    const { data } = yield Job.getAllSubtypes(workspaceUuid);
    yield put(fetchJobSubtypesSuccess(data.results));
  } catch (err) {
    Logger.error(err);
    yield put(fetchJobSubtypesError());
    yield put(setGlobalError(err));
  }
}

export default function* jobTypesWatcher() {
  yield takeLatest(FETCH_JOB_TYPES, fetchJobTypes);
  yield takeLatest(FETCH_JOB_SUBTYPES, fetchJobSubtypes);
}

import styled from 'styled-components';
import theme from 'theme';
import { QuestionMark } from 'assets/icons';
import { Button as MuiButton } from '@mui/material';

const { colors, media } = theme;

export const Button = styled(MuiButton)`
  &.MuiButtonBase-root {
    border-radius: 50%;
    min-width: 23px;
    width: 23px;
    height: 23px;
    margin-left: 5px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-self: center;

    ${media.xl`
      min-width: 18px;
      width: 18px;
      height: 18px;
    `}
  }
`;

export const QuestionMarkIcon = styled(QuestionMark)`
  .fill-selector {
    fill: ${colors.blue};
  }
`;

import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import {
  selectGlobalLanguageSetting,
  selectIsSwitchingTabsOnly,
  selectJobsView,
} from 'store/selectors';
import { useJobs, useLocalStorage, useTeams } from 'hooks';
import Jobs from './Jobs';
import RootState from 'model/State/RootState';
import { useDebouncedCallback } from 'use-debounce';

import { isEmptyLegacyView, isEmptyView } from 'helpers/versions';
import { Notification } from 'components/ui/Notification';
import { downloadDocument } from 'store/actions/documents';
import { DownloadSource } from 'model/enum/DownloadSource';

const NavigateToJobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { objectId } = useParams();

  if (objectId) {
    dispatch(downloadDocument(objectId, DownloadSource.EMAIL));
  }
  useEffect(() => {
    navigate('/jobs', { replace: true });
  });

  return null;
};

// Protected
export default () => {
  const { filters, sort, listJobs, mapJobs, isFirstLoad } = useSelector(
    (state: RootState) => state.jobs,
  );
  const { jobs: legacyViewJobs, isFirstLoad: isLegacyViewFirstLoad } =
    useSelector((state: RootState) => state.legacyViewJobs);
  const view = useSelector(selectJobsView);
  const isSwitchingTabsOnly = useSelector(selectIsSwitchingTabsOnly);

  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const { refreshJobs } = useJobs();
  const { selectedTeam, setSelectedTeamForCurrentUser } = useTeams();
  const [, setAppLanguage] = useLocalStorage<string>('globalAppLanguage');
  const membersIds = selectedTeam
    ? selectedTeam?.members.map((member) => member.id)?.join(',')
    : '';

  const refreshJobsDebounced = useDebouncedCallback(() => {
    refreshJobs();
  }, 1000);

  useEffect(() => {
    setAppLanguage(globalAppLanguage);
  }, [globalAppLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  const firstViewRender = useRef(true);
  const firstFiltersRender = useRef(true);

  useEffect(() => {
    if (firstFiltersRender.current) {
      firstFiltersRender.current = false;
      return;
    }

    if (
      !isFirstLoad &&
      !isLegacyViewFirstLoad &&
      ((filters.scope &&
        selectedTeam !== undefined &&
        membersIds !== undefined) ||
        !filters.scope)
    ) {
      refreshJobsDebounced();
      return;
    }
  }, [filters, sort, membersIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      isEmptyView(view, listJobs, mapJobs) ||
      isEmptyLegacyView(view, legacyViewJobs)
    ) {
      refreshJobs(isSwitchingTabsOnly);
      return;
    }
    if (firstViewRender.current) {
      firstViewRender.current = false;
      return;
    }
    refreshJobs(isSwitchingTabsOnly);
  }, [view, isSwitchingTabsOnly]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Notification />
      <Routes>
        <Route
          path='/jobs/*'
          element={
            <Jobs
              selectedTeam={selectedTeam}
              setSelectedTeamForCurrentUser={setSelectedTeamForCurrentUser}
            />
          }
        />
        <Route path='/download/:objectId' element={<NavigateToJobs />} />
        <Route path='/*' element={<NavigateToJobs />} />
      </Routes>
    </>
  );
};

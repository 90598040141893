import React, { useState, useMemo, useRef } from 'react';
import { useText } from 'hooks';
import { Paperplane, PlusIcon } from 'assets/icons';
import { useNavigate, useParams } from 'react-router-dom';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { selectJobsView } from 'store/selectors';
import { useSelector } from 'react-redux';
import { Form, Icon, SubmitButton, Textarea } from './styled';

const maxTextAreaHeight = 300;

// Form.Chat
export const Chat = ({ onSubmit }: { onSubmit: (value: string) => void }) => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const getText = useText();
  const view = useSelector(selectJobsView);
  const [message, setMessage] = useState<string>('');
  const textareaRef =
    useRef() as React.MutableRefObject<HTMLTextAreaElement | null>;
  const IconComponent = useMemo(
    () => (message.trim()?.length > 0 ? <Paperplane /> : <PlusIcon />),
    [message],
  );

  const handleChange = (e) => {
    setMessage(e.target.value);
    if (
      textareaRef?.current &&
      textareaRef.current?.scrollHeight < maxTextAreaHeight
    ) {
      textareaRef.current.style.cssText = 'height:auto';
      textareaRef.current.style.cssText = `height:${textareaRef.current.scrollHeight}px`;
    }
  };

  const resizeTextarea = () => {
    textareaRef?.current?.style.removeProperty('height');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()?.length === 0) {
      Analytics.trackEvent(constants.TIMELINE_PLUS_CLICKED, {
        jobId,
        [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      });
      navigate(`/jobs/${jobId}/add`);
    } else {
      onSubmit(message);
      setMessage('');
      resizeTextarea();
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Textarea
        placeholder={getText('chat_write_message')}
        value={message}
        onChange={handleChange}
        ref={textareaRef}
      />

      <SubmitButton>
        <Icon component={IconComponent} />
      </SubmitButton>
    </Form>
  );
};

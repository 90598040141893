import { useSelector } from 'react-redux';
import { useText } from 'hooks';

import Protected from '../Protected';
import { Public } from '../Public';
import ErrorBoundary from './ErrorBoundary';
import { Grid } from '@mui/material';
import { SplashScreen } from '../Public/SplashScreen';

// App
const App = () => {
  const { isAuthenticating, currentUser, isAllowedAccess } = useSelector(
    (state) => state.session,
  );
  const getText = useText();
  return (
    <ErrorBoundary getText={getText}>
      <Grid container columns={24} direction='column' width='100vw'>
        {isAuthenticating ? (
          <SplashScreen />
        ) : currentUser && isAllowedAccess ? (
          <Protected />
        ) : (
          <Public />
        )}
      </Grid>
    </ErrorBoundary>
  );
};

export default App;

// import MessageTarget from './MessageTarget';
import { Form } from './Form';
import { Reaction } from './Reaction';

export enum MessageType {
  ASSESSMENT = 'assessment',
  EVIDENCE = 'evidence',
  EVENT = 'event',
  LOG = 'log',
  WORK_ORDER_NUMBER = 'work order number',
  PERMIT = 'permit',
  DOCUMENT = 'document',
  TEXT = 'text',
  JOB_FORM = 'job form',
}

export type Message = {
  id: number;
  type: MessageType | null;
  job_id: number;
  target: Form | any;
  created_at: Date;
  updated_at: Date;
  read: boolean;
  user_reaction: string;
  reactions: Reaction[];
};

import { Field } from 'model/Forms/Field';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes } = theme;

// Styled components
const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0;
`;

const SectionTitle = styled.div`
  font-family: ${fonts.bold};
  color: ${colors.black};
  font-size: ${fontSizes.xl};
  line-height: ${fontSizes.xl};
`;

const Header = ({ field }: { field: Field }): JSX.Element => (
  <SectionContainer>
    <SectionTitle>{field.title}</SectionTitle>
  </SectionContainer>
);

export default Header;

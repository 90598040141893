import { HavsTableFields } from 'model/AdminPanel/HavsSettings/HavsUser';

export const BackendFields = {
  [HavsTableFields.FIELDWORKER]: 'fieldworker',
  [HavsTableFields.HAVS_LIMIT]: 'havs_limit',
  [HavsTableFields.ROLE]: 'role',
  [HavsTableFields.DEPOT]: 'depot',
  [HavsTableFields.MANAGER]: 'manager',
  [HavsTableFields.LAST_UPDATED]: 'updated_at',
  [HavsTableFields.UPDATED_BY]: 'updated_by',
};

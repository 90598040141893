import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import theme from 'theme';

const { colors, mixins } = theme;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  ${mixins.flexFullCenter}
`;

export const Spin = styled(({ isWhite, ...rest }) => (
  <CircularProgress {...rest} />
))`
  &&& {
    color: ${({ isWhite }) => (isWhite ? colors.white : colors.black)};
  }
`;

import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
import { useSelector } from 'react-redux';
import {
  selectWorkspaceSettings,
  selectGlobalLanguageSetting,
} from 'store/selectors';
import JobData from 'model/JobData';
import { Grid } from '@mui/material';
import { getLabelByLocale } from 'helpers/utils';

const { fonts, fontSizes, media } = theme;

// Styled Components
const Title = styled.h3`
  display: none;
  ${media.sm`
    display: block;
    font-family: ${fonts.bold};
    font-size: ${fontSizes.xl};
    margin-bottom: 25px !important;
  `}
`;

const AttributesContainer = styled.div`
  display: flex;
  ${media.sm`
    flex-direction: column;
  `}
`;

const AttributesGroup = styled(Grid)`
  width: 50%;
  &:first-of-type {
    margin-bottom: 15px;
  }
  ${media.sm`
    width: 100%;
    &:first-of-type {
      margin-bottom: 0;
    }    
    &:last-of-type {
      margin-bottom: 15px;
    }
  `}
`;

const Attribute = styled.p`
  margin-bottom: 10px !important;
  line-height: ${fontSizes.xl};
`;

const AttributeLabel = styled.span`
  font-family: ${fonts.bold};
`;

// Details
const Details = ({ activeJob }: { activeJob: JobData }) => {
  const getText = useText();
  const {
    work_order_number_label,
    work_order_number_label_es,
    originating_number_label,
    originating_number_label_es,
    project_number_label,
    project_number_label_es,
    job_subtype_label,
    job_subtype_label_es,
    locations_enable,
    address_label,
    address_label_es,
    pin_location_enabled,
    postcode_label,
    postcode_label_es,
  } = useSelector(selectWorkspaceSettings);

  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  return (
    <>
      <Title>{getText('job_detail_overview_job_details')}</Title>

      <AttributesContainer>
        <AttributesGroup>
          {postcode_label && activeJob.postcode && (
            <Attribute>
              <AttributeLabel>
                {getLabelByLocale(globalAppLanguage, {
                  postcode_label,
                  postcode_label_es,
                })}
              </AttributeLabel>{' '}
              {activeJob.postcode}
            </Attribute>
          )}
          {address_label && (
            <Attribute>
              <AttributeLabel>
                {getLabelByLocale(globalAppLanguage, {
                  address_label,
                  address_label_es,
                })}
              </AttributeLabel>{' '}
              {activeJob.address}
            </Attribute>
          )}
          {work_order_number_label && (
            <Attribute>
              <AttributeLabel>
                {getLabelByLocale(globalAppLanguage, {
                  work_order_number_label,
                  work_order_number_label_es,
                })}
                :
              </AttributeLabel>{' '}
              {activeJob.job_number}
            </Attribute>
          )}
          {originating_number_label && (
            <Attribute>
              <AttributeLabel>
                {getLabelByLocale(globalAppLanguage, {
                  originating_number_label,
                  originating_number_label_es,
                })}
                :
              </AttributeLabel>{' '}
              {activeJob.originating_number}
            </Attribute>
          )}
          {project_number_label && (
            <Attribute>
              <AttributeLabel>
                {getLabelByLocale(globalAppLanguage, {
                  project_number_label,
                  project_number_label_es,
                })}
                :
              </AttributeLabel>{' '}
              {activeJob.project_number}
            </Attribute>
          )}
        </AttributesGroup>

        <AttributesGroup item xs={24} md={12}>
          <Attribute>
            <AttributeLabel>
              {getText('job_detail_overview_job_type')}
            </AttributeLabel>{' '}
            {activeJob?.type?.[`title_${globalAppLanguage}`] ||
              activeJob?.type?.title}
          </Attribute>
          {activeJob.type_other && (
            <Attribute>
              <>
                <AttributeLabel>
                  {getText('job_detail_overview_job_type_description')}
                </AttributeLabel>{' '}
                {activeJob.type_other}
              </>
            </Attribute>
          )}
          {activeJob?.subtype?.title && job_subtype_label && (
            <Attribute>
              <AttributeLabel>
                {getLabelByLocale(globalAppLanguage, {
                  job_subtype_label,
                  job_subtype_label_es,
                })}
                :
              </AttributeLabel>{' '}
              {activeJob.subtype[`title_${globalAppLanguage}`] ||
                activeJob.subtype.title}
            </Attribute>
          )}
          {activeJob.subtype_other && (
            <Attribute>
              <>
                <AttributeLabel>
                  {getText('job_detail_overview_job_subtype_description')}
                </AttributeLabel>{' '}
                {activeJob.subtype_other}
              </>
            </Attribute>
          )}
          <Attribute>
            <AttributeLabel>
              {getText('job_detail_overview_job_depot')}
            </AttributeLabel>{' '}
            {activeJob?.depot?.title}
          </Attribute>
          {locations_enable && activeJob.predefined_location && (
            <Attribute>
              <AttributeLabel>
                {getText('job_details_overview_location')}
              </AttributeLabel>{' '}
              {activeJob.predefined_location.name}
            </Attribute>
          )}
          {activeJob.latitude && activeJob.longitude && (
            <Attribute>
              <AttributeLabel>
                {getText('job_detail_overview_job_gps')}
              </AttributeLabel>{' '}
              {activeJob.latitude}, {activeJob.longitude}
            </Attribute>
          )}
          {pin_location_enabled && activeJob.location_description && (
            <Attribute>
              <AttributeLabel>
                {getText('job_details_overview_location_description')}
              </AttributeLabel>{' '}
              {activeJob.location_description}
            </Attribute>
          )}
        </AttributesGroup>
      </AttributesContainer>
    </>
  );
};

export default Details;

import styled from 'styled-components';
import theme from 'theme';
import { Button as MaterialButton } from '@mui/material';
import { Arrow } from 'assets/icons';
import React from 'react';

const { media, mixins, colors } = theme;

export const IconButton = styled(
  ({ disabled = false, size = '', margin = '', ...rest }) => (
    <MaterialButton disabled={disabled} {...rest} />
  ),
)`
  &.MuiButtonBase-root {
    ${mixins.flexFullCenter};
    background-color: ${colors.yellow};
    outline: 2px solid ${colors.black};
    cursor: pointer;
    height: ${({ size }) => size ?? '44px'};
    width: ${({ size }) => size ?? '44px'};
    min-height: ${({ size }) => size ?? '44px'};
    min-width: ${({ size }) => size ?? '44px'};
    margin-left: ${({ margin }) => margin ?? '10px'};
    border-radius: 0;
    border: none;
    &:hover {
      outline: 3px solid ${colors.black};
      background-color: ${colors.yellow};
    }

    &.Mui-disabled {
      outline: 2px solid ${colors.grey};
      background-color: ${colors.lightGrey} !important;
      &:hover {
        outline: 2px solid ${colors.grey};
        background-color: ${colors.lightGrey} !important;
      }
    }
    ${media.xl`
      height: 45px;
      width: 45px;
      min-height: 45px;
      min-width: 45px;
    `}
  }
`;

export const Description = styled.div<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ $disabled }) => ($disabled ? 0.65 : 1)};
`;

export const ArrowRight = styled(Arrow)<{ $hasMarginLeft: boolean }>`
  height: 20px;
  ${({ $hasMarginLeft }) => $hasMarginLeft && 'margin-left: 15px'};
  .fill-selector {
    fill: ${colors.black};
  }
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
`;

export const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
  margin-right: 15px;
  margin-left: 0;
`;

export const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: 25px;
  width: 25px;
  margin-right: 15px;
  .fill-selector {
    fill: ${colors.black};
  }
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
`;

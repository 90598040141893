import { Signature } from 'assets/icons';
import { formatDateTime } from 'helpers/dates';
import { useText } from 'hooks';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Storage } from 'aws-amplify';
import { Photo } from 'components/ui/Media/Photo';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import { Navbar } from 'components/ui';
import { selectJobsView } from 'store/selectors';
import { User } from 'model/User';

const { colors, fonts, fontSizes, mixins } = theme;

const ContentWrapper = styled.div`
  padding: 25px;
  align-self: flex-start;
`;

const TitleContainer = styled.div`
  ${mixins.flexBetween};
`;

const UserName = styled.div`
  font-family: ${fonts.bold};
  color: ${colors.black};
  font-size: ${fontSizes.xxl};
`;

const IconBox = styled.div`
  height: 36px;
  width: 36px;
  border: 4px solid ${colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSignatureIcon = styled(Signature)`
  height: 22px;
  width: 22px;
`;

const ConfirmText = styled.div`
  font-size: ${fontSizes.l};
  padding: 30px 0;
`;

const SignatureTitle = styled.div`
  font-family: ${fonts.bold};
  color: ${colors.black};
  font-size: ${fontSizes.xl};
  padding: 15px 0;
`;

const PhotoContainer = styled.div`
  height: 250px;
  width: 75%;
  border: 3px solid ${colors.black};
`;

const SignedAt = styled.div`
  padding: 10px 0;
`;

const DigitalSignature = (): JSX.Element => {
  const getText = useText();
  const location = useLocation();
  const { jobId, assessmentId, userId } = useParams();
  const { currentUser } = useSelector((state: RootState) => state.session);
  const assessment = useSelector(
    (state: RootState) => state.assessments.activeAssessment,
  );
  const view = useSelector(selectJobsView);
  const [signedOnUser, setSignedOnUser] = useState<User | undefined>(
    location.state?.user,
  );
  const [signatureUrl, setSignatureUrl] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (signedOnUser?.signature?.path) {
        const signedUrl = await Storage.get(signedOnUser?.signature?.path);
        setSignatureUrl(signedUrl);
      }
    })();
  }, [signedOnUser]);

  useEffect(() => {
    if (!location.state?.user && userId) {
      setSignedOnUser(
        assessment?.signedOnUsers.find(
          (user) => user.id === parseInt(userId, 10),
        ),
      );
    }
    Analytics.trackPage(constants.EVENT_PAGE_VIEW_DIGITAL_SIGNATURE, {
      jobId,
      assessmentId,
      userId: currentUser?.id,
      devicelessUserId: signedOnUser?.id,
      view,
      signaturePhotoId: signedOnUser?.signature?.fileId,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Navbar
        title={getText('job_details_assessment_title')}
        defaultBackPath={`/jobs/${jobId}/assessments`}
        mobileOnly
      />
      <ContentWrapper>
        <TitleContainer>
          {signedOnUser && <UserName>{`${signedOnUser?.fullName}`}</UserName>}
          <IconBox>
            <StyledSignatureIcon />
          </IconBox>
        </TitleContainer>
        <ConfirmText>
          {getText('assessment_details_signature_confirm')}
        </ConfirmText>
        <SignatureTitle>
          {getText('assessment_details_signature_title')}
        </SignatureTitle>
        {signatureUrl && (
          <PhotoContainer>
            <Photo url={signatureUrl} width='75%' forceImage />
          </PhotoContainer>
        )}
        <SignedAt>
          {formatDateTime(
            signedOnUser?.signedAt,
            getText('date_today'),
            getText('date_yesterday'),
          )}
        </SignedAt>
      </ContentWrapper>
    </>
  );
};

export default DigitalSignature;

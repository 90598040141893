import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HavsFilters, HavsLimit } from 'model/AdminPanel/HavsSettings/HavsUser';
import HavsSettingsState from 'model/State/HavsSettingsState';

import RootState from 'model/State/RootState';

const initialState: HavsSettingsState = {
  havsLimits: [],
  isLoading: false,
};

export const havsSettings = createSlice({
  name: 'havsSettings',
  initialState,
  reducers: {
    fetchHavsLimits: (
      state: HavsSettingsState,
      action: PayloadAction<HavsFilters>,
    ) => {
      state.isLoading = true;
    },
    fetchHavsLimitsSuccess: (
      state: HavsSettingsState,
      action: PayloadAction<HavsLimit[]>,
    ) => {
      state.isLoading = false;
      state.havsLimits = action.payload;
    },
    fetchHavsLimitsError: (state: HavsSettingsState) => {
      state.isLoading = false;
    },
  },
});

export const { fetchHavsLimits, fetchHavsLimitsSuccess, fetchHavsLimitsError } =
  havsSettings.actions;

export const selectHavsLimitData = (state: RootState): HavsLimit[] =>
  state.havsSettings.havsLimits;
export const selectHavsLimitsIsLoading = (state: RootState): boolean =>
  state.havsSettings.isLoading;

export const havsSettingsReducer = havsSettings.reducer;

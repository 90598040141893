import { Button } from '@mui/material';
import { Arrow, Check, Dots } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins, media } = theme;

export const SectionButtonsContainer = styled.div`
  height: calc(90% - 24px);
`;

export const BackButtonContainer = styled.div`
  height: 100%;
  width: 100%;
  ${mixins.flexCenter};
  justify-content: flex-end;
`;

export const MenuButtonContainer = styled.div`
  max-height: 30%;
  margin-bottom: 25px;
`;

export const MenuButton = styled(Button)`
  &.MuiButtonBase-root {
    width: 100%;
    padding: 20px 10px;
    box-sizing: border-box;
    border-radius: 0;
    outline: 2px solid
      ${({ disabled }) => (disabled ? colors.grey : colors.black)};
    font-size: ${fontSizes.l};
    color: ${colors.black};
    background-color: transparent;
    ${mixins.flexBetween};
    text-transform: none;
    letter-spacing: 0;
    text-align: left;
    &:first-of-type {
      margin-top: 0;
    }
    &:hover {
      outline: 3px solid ${colors.black};
      background-color: transparent;
    }
    &.Mui-disabled {
      outline: 2px solid ${colors.grey};
      background-color: ${colors.lightGrey} !important;
      color: ${colors.black};
      &:hover {
        outline: 2px solid ${colors.grey};
        background-color: ${colors.lightGrey} !important;
      }
    }

    ${media.xl`
      font-size: ${fontSizes.m};
      margin-top: 20px;
      padding: 15px 10px;
    `}
    ${media.sm`
      font-size: ${fontSizes.s};
      margin-top: 10px;
      padding: 10px 5px;
    `}
  }
`;

export const Title = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
`;

export const Subtitle = styled.div`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.default};
  font-family: ${fonts.default};
`;

export const ArrowRight = styled(Arrow)`
  max-width: 20%;
  height: 20px;
  .fill-selector {
    fill: ${colors.black};
  }
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
`;

export const TagContainer = styled.div<{
  $backgroundColor: string;
}>`
  ${mixins.flexCenter};
  padding: 3px 8px;
  margin-top: 4px;
  font-family: ${fonts.italic};
  font-size: ${fontSizes.s};
  color: ${colors.white};
  ${({ $backgroundColor }) => `
    background-color: ${$backgroundColor};
    outline: 2px solid ${$backgroundColor};
  `}
  ${media.xl`
    font-size: ${fontSizes.xs};
  `}
  &:first-of-type {
    margin-top: 0;
  }
`;

export const CheckIcon = styled(Check)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  .fill-selector {
    fill: ${colors.yellow};
  }
`;

export const DotsIcon = styled(Dots)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

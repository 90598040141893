import { formatDate } from 'helpers/dates';
import { useQueryParams, useText } from 'hooks';
import { NearbyJob } from 'model/State/SiteBriefing';
import {
  Address,
  ArrowRight,
  Distance,
  JobCardWrapper,
  JobDetailsContainer,
} from './styled';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Analytics from 'utils/analytics';
import { EVENT_SITE_BRIEFING_JOB_CLICKED } from '../constants';
import { useDispatch } from 'react-redux';
import { setActiveJobNearbyPath } from 'store/actions/jobs';
import { MajorHazardTag } from 'components/ui/Tags/MajorHazardTag';

type Props = {
  job: NearbyJob;
  eventProperties: any;
};

export const NearbyJobCard = ({ job, eventProperties }: Props): JSX.Element => {
  const getText = useText();
  const { getPathWithQueryParams } = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const onCardClick = () => {
    dispatch(setActiveJobNearbyPath(job.id, location.pathname));
    navigate(getPathWithQueryParams(`/jobs/${job.id}`), {
      state: { isNearbyJob: true, nearbyJobUrl: location.pathname },
    });

    Analytics.trackEvent(EVENT_SITE_BRIEFING_JOB_CLICKED, {
      ...eventProperties,
      clickedJobId: job.id,
    });
  };
  return (
    <>
      <Distance>
        {getText('job_details_timeline_action_site_briefing_miles_away', {
          number: job.distanceMiles,
        })}
      </Distance>
      <JobCardWrapper onClick={onCardClick}>
        <JobDetailsContainer>
          <Address>{job.address}</Address>
          <div>{`${getText(
            'job_details_timeline_action_site_briefing_work_order_number',
          )} ${job.workorderItemNumber}`}</div>
          <div>{`${getText(
            'job_details_timeline_action_site_briefing_job_type',
          )} ${job.jobType}`}</div>
          <div>{`${getText(
            'job_details_timeline_action_site_briefing_date_created',
          )} ${formatDate(job.createdAtDate)}`}</div>
          <div>{`${getText(
            'job_details_timeline_action_site_briefing_job_status',
          )} ${job.jobStatus}`}</div>
          {!!job.latestAssessmentHasMajorHazard && (
            <MajorHazardTag
              text={getText(
                'job_details_timeline_action_site_briefing_nearby_jobs_major_hazard',
              )}
              fitContent
              hasSmallIcon
            />
          )}
        </JobDetailsContainer>
        <ArrowRight />
      </JobCardWrapper>
    </>
  );
};

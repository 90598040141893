import { useState, useEffect } from 'react';
import { Control as ControlApi } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalError } from 'store/slices/notifications';
import RootState from 'model/State/RootState';
import { Control } from 'model/Assessment/Control';

// useControls
const useControls = () => {
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const [controls, setControls] = useState<Control[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const data = await ControlApi.getAll(selectedWorkspace?.uuid);

        setControls(data);
      } catch (err) {
        dispatch(setGlobalError(err));
        console.error(err);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return controls;
};

export default useControls;

import styled from 'styled-components';
import theme from 'theme';

const { mixins, colors } = theme;

export const FormContainer = styled.div`
  ${mixins.flexColumn}
  background-color: ${colors.white};
  padding: 30px;
  row-gap: 20px;
`;

export const InputWrapper = styled.div`
  ${mixins.flexColumn}
`;

export const ErrorMessage = styled.div`
  color: ${colors.darkRed};
  margin-top: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  column-gap: 20px;
`;

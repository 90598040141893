export const WorkflowStepFieldNames = {
  TITLE: 'title',
  TITLE_ES: 'titleEs',
  TASK: 'task',
  TASK_TYPE: 'taskType',
};

export const WorkflowStepFieldTranslationKeys = {
  [WorkflowStepFieldNames.TITLE]: 'workflow_settings_title_label',
  [WorkflowStepFieldNames.TITLE_ES]: 'workflow_settings_title_label',
  [WorkflowStepFieldNames.TASK]: 'workflow_settings_task_label',
  [WorkflowStepFieldNames.TASK_TYPE]: 'workflow_settings_task_type_label',
};

import {
  HavsFilters,
  HavsLimit,
  HavsUser,
} from 'model/AdminPanel/HavsSettings/HavsUser';
import axios, { AxiosResponse } from 'axios';
import {
  mapToHavsLimits,
  mapToHavsUser,
  mapToBackendHavsLimit,
  mapToBackendHavsFilters,
} from './helpers';
import { HavsSettingsResponse } from './types';
import { UserRole } from 'model/enum/UserRole';
import { UserRoleBackend } from 'model/enum/UserRoleBackend';

export const getHavsLimitSettings = async (
  filters: HavsFilters,
): Promise<HavsLimit[]> => {
  const response: AxiosResponse = await axios.get('/users/havs-config/', {
    params: { ...mapToBackendHavsFilters(filters), page: 1, page_size: 100 },
  });
  const { data }: { data: HavsSettingsResponse } = response;
  return data?.results.map(mapToHavsLimits);
};

export const mapToBackendUserRole = (
  userRole: UserRole,
): UserRoleBackend | null => {
  switch (userRole) {
    case UserRole.FIELDWORKER:
      return UserRoleBackend.FIELDWORKER;
    case UserRole.BACKOFFICE_ADMIN:
      return UserRoleBackend.BACKOFFICE;
    case UserRole.MANAGER:
      return UserRoleBackend.MANAGER;
    case UserRole.TEAMMATE:
      return UserRoleBackend.TEAMMATE;
    default:
      return null;
  }
};

export const getUsersForHavs = async (
  workspaceUuid: string,
  search: string,
  userRoles: UserRole[],
  page: number = 1,
  pageSize: number = 20,
): Promise<HavsUser[]> => {
  const response = await axios.get(`/users/`, {
    params: {
      search,
      page_size: pageSize,
      page,
      workspace_uuid: workspaceUuid,
      roles: userRoles
        .map(mapToBackendUserRole)
        .filter((role) => role !== null)
        .join(','),
    },
    headers: {
      Accept: `application/json; version=2;`,
    },
  });

  const { data } = response;
  return data?.results.map(mapToHavsUser);
};

export const addHavsLimit = async (limit: HavsLimit) => {
  const response = await axios.post(
    `/users/havs-config/`,
    mapToBackendHavsLimit(limit),
  );

  const { data } = response;
  return data;
};

export const editHavsLimit = async (limit: HavsLimit) => {
  const response = await axios.put(
    `/users/${limit.user.id}/havs-config/`,
    mapToBackendHavsLimit(limit),
  );

  const { data } = response;
  return data;
};

export const deleteHavsLimitForUserId = async (userId: number) => {
  const response = await axios.delete(`/users/${userId}/havs-config/`);

  const { data } = response;
  return data;
};

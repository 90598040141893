import { Loader, Navbar } from 'components/ui';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { JobForms } from 'api';
import { getFieldById } from 'helpers/forms';
import { setGlobalError } from 'store/slices/notifications';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field } from 'model/Forms/Field';
import styled from 'styled-components';

// Styled Components
const InstructionWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;
const InstructionContent = styled.div`
  margin-top: 20px;
  padding: 10px;
  p {
    padding-top: 15px;
  }
  ul {
    padding: revert;
  }
`;

const Instruction = (): JSX.Element => {
  const { jobId, formId, fieldId } = useParams();
  const dispatch = useDispatch();
  const [instructionData, setInstructionData] = useState<Field | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const data = await JobForms.get(parseInt(formId ?? '', 10));
        setInstructionData(getFieldById(data?.form, fieldId));
      } catch (err) {
        dispatch(setGlobalError(err));
      }
    })();
  }, [formId, fieldId, dispatch]);

  return (
    <>
      <Navbar
        title={instructionData?.title}
        defaultBackPath={`/jobs/${jobId}/job-forms/${formId}`}
        mobileOnly
      />
      {instructionData?.value ? (
        <InstructionWrapper>
          <InstructionContent>
            {parse(instructionData?.value)}
          </InstructionContent>
        </InstructionWrapper>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Instruction;

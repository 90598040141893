import { useState } from 'react';
import styled from 'styled-components';
import { Navbar, Upload } from 'components/ui';
import theme from 'theme';
import { Document as DocumentApi } from 'api';
import { useIsMobile, useText } from 'hooks';
import { Storage } from 'aws-amplify';
import * as constants from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { appendDocument } from 'store/actions/jobs';
import { useNavigate, useParams } from 'react-router-dom';
import { UploadStatus } from 'model/enum/UploadStatus';
import { selectJobsView } from 'store/selectors';
import { Document } from 'model/Media/Document';

const { media } = theme;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  overflow-y: auto;
  ${media.sm`
    padding: 10px;
   `}
`;

// AddContent.AddDocument
const AddDocument = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const isMobile = useIsMobile();
  const getText = useText();
  const dispatch = useDispatch();
  const view = useSelector(selectJobsView);

  const [fileList, setFileList] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const uploadFile = async (file) => {
    setIsLoading(true);
    const fileParts = file.name.split('.');
    try {
      // STEP1 - Call document API to create new document object
      const documentResponse: Document = await DocumentApi.create(
        fileParts[fileParts.length - 1],
        file.name,
        jobId,
      );
      // STEP2 - Upload file to bucket with path returned from backend
      await Storage.put(documentResponse.path, file);
      // STEP3 - Call backend to update status of document to 'uploaded'
      const finalDocument: Document = await DocumentApi.setDocumentStatus(
        documentResponse.id,
        UploadStatus.UPLOADED,
        file.size,
      );
      const signedUrl = await Storage.get(documentResponse.path);
      dispatch(appendDocument(jobId, { ...finalDocument, signedUrl }));
      if (isMobile || view !== constants.LEGACY_VIEW) {
        navigate(`/jobs/${jobId}/timeline`);
      } else {
        navigate(`/jobs/${jobId}`);
      }
    } catch (err) {
      setIsLoading(false);
      setError((err as Error)?.message);
    }
  };

  const onSubmit = () => {
    if (fileList.length > 0) {
      fileList.map(async (file) => uploadFile(file));
    } else {
      setError(getText('upload_error_no_file'));
    }
  };

  const removeFileItem = (file: File) => {
    setError(null);
    setFileList(fileList.filter((item) => item !== file));
  };

  const onFileListChange = (files: File[]) => {
    setError(null);
    const tooBigFile = files.some((file) => file.size > 10000000);
    if (tooBigFile) {
      setError(getText('upload_error_large_file', { size: 10 }));
      return;
    }
    const fileArray = [...fileList, ...files];
    setFileList(fileArray.slice(0, 6));
    if (fileArray.length > 6) {
      setError(getText('upload_error_max_number'));
    }
  };

  return (
    <>
      <Navbar
        title={getText('upload_add_documents')}
        defaultBackPath={`/jobs/${jobId}/add`}
        mobileOnly
      />
      <Wrapper>
        <Upload.UploadContent
          acceptType='image/*,video/*,text/plain,application/pdf,.doc,.docx,.xml,.ppt,.pptx,.xls,.xlsx,.eml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          jobId={jobId}
          onChange={onFileListChange}
          title={getText('upload_add_documents')}
          onSubmit={onSubmit}
          isLoading={isLoading}
          trackEvent={constants.EVENT_ADD_DOCUMENTS_UPLOAD}
          error={error}
          fileList={fileList}
          removeFileItem={removeFileItem}
        />
      </Wrapper>
    </>
  );
};

export default AddDocument;

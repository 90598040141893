import { Loader } from 'components/ui';
import { convertToHourAndMinute } from 'helpers/dates';
import { useIsMounted, useText } from 'hooks';
import { SortOrder } from 'model/enum/SortOrder';
import RootState from 'model/State/RootState';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import styled from 'styled-components';
import theme from 'theme';
import { convertFraTypeToNumber } from './fra-type-to-number';
import FraReviewModal from './FraReviewModal';
import Filters from 'model/Filters';
import { Pagination } from 'components/ui/Display';
import { getSelectedMemberIds } from 'helpers/filter';
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { getSortModel } from 'helpers/table';
import Sort from 'model/Sort';
import * as Analytics from 'utils/analytics';
import { Paper, TableContainer } from '@mui/material';
import Team from 'model/Team';
import { RowWrapper, StyledDataGrid } from '../styles';
import { SkeletonTable } from './SkeletonTable';
import {
  EVENT_FATIGUE_HISTORIC_VIEW_SORT_BY,
  FATIGUE_HISTORIC_PAGE_SIZE,
  fatigueHistoricDataFields,
} from '../constants';
import { CLICKED } from 'utils/constants';
import {
  fetchFatigueHistoric,
  selectHistoricData,
  selectIsLoadingHistoricData,
} from 'store/slices/fatigue';
import { FatigueRiskAssessment } from 'model/FatigueManager/FatigueRiskAssessment';
import { FatigueHistoricItem } from 'model/FatigueManager/FatigueHistoricDataItem';
import { FatigueHistoricRowItem } from 'model/FatigueManager/FatigueHistoricRowItem';
import { stringifyUserIds } from 'api/Insights/helpers';

const { colors, fonts, fontSizes, mixins } = theme;

const Wrapper = styled.div`
  padding: 40px 80px;
  background-color: ${colors.offWhite};
  min-height: 100%;
  box-sizing: border-box;
`;

const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxl};
  margin-bottom: 40px !important;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DataRow = styled.div`
  height: 50%;
  display: flex;
  background: ${colors.white};
  padding: 32px;
`;

const StyledTableContainer = styled(({ ...rest }) => (
  <TableContainer {...rest} />
))`
  &.MuiTableContainer-root {
    box-shadow: none;
    .MuiTableHead-root .MuiTableCell-root {
      border-bottom: 2px solid ${colors.darkGrey};
      font-family: ${fonts.bold};
      color: ${colors.darkGrey};
      .MuiTableSortLabel-root {
        .MuiTableSortLabel-icon {
          opacity: 1;
        }
        &.Mui-active,
        &.Mui-active .MuiTableSortLabel-icon {
          color: ${colors.blue};
        }
      }
    }
    .MuiTableBody-root .MuiTableCell-root {
      border-color: ${colors.darkGrey};
    }
  }
`;

const EmptyTableText = styled.div`
  background-color: ${colors.white};
  color: ${colors.grey};
  font-family: ${fonts.default};
  padding: 25px;
  ${mixins.flexFullCenter};
`;

const FraRow = styled.div`
  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const FraWrapper = styled.div`
  ${mixins.flexColumn};
`;

const sortFras = (a: FatigueRiskAssessment, b: FatigueRiskAssessment) => {
  if (convertFraTypeToNumber(a.fraType) < convertFraTypeToNumber(b.fraType)) {
    return -1;
  }
  if (convertFraTypeToNumber(a.fraType) > convertFraTypeToNumber(b.fraType)) {
    return 1;
  }
  return 0;
};

type Props = {
  depotId: number;
  filters: Filters;
  selectedTeam: Team | null;
};

// FatigueHistoricDataTab
const FatigueHistoricDataTab = ({
  depotId,
  filters,
  selectedTeam,
}: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const { results, count: total, page } = useSelector(selectHistoricData) ?? {};
  const isLoading = useSelector(selectIsLoadingHistoricData);
  const [displayFraModal, setDisplayFraModal] = useState<boolean>(false);
  const [selectedFra, setSelectedFra] = useState<FatigueRiskAssessment>();
  const count = total ? Math.ceil(total / FATIGUE_HISTORIC_PAGE_SIZE) : 0;
  const initialSort = {
    field: fatigueHistoricDataFields.STARTED_AT,
    order: SortOrder.DESCENDING,
  };
  const [sort, setSort] = useState<Sort>(initialSort);
  const isMounted = useIsMounted();
  const userFilters = getSelectedMemberIds(filters, selectedTeam);
  const userIds = stringifyUserIds(userFilters);

  const convertToHourAndMinuteOrEmpty = (value: number) =>
    value
      ? `${convertToHourAndMinute(value)} ${getText('fatigue_manager_hours')}`
      : '';

  const columns: GridColDef[] = [
    {
      field: fatigueHistoricDataFields.NAME,
      headerName: getText('fatigue_manager_table_header_name'),
      renderCell: (props: GridRenderCellParams) => {
        const { row }: { row: FatigueHistoricRowItem } = props;
        return (
          <RowWrapper>
            {row.user?.fullName}
            {row.user?.phoneNumber && <div>{row.user.phoneNumber}</div>}
          </RowWrapper>
        );
      },
    },
    {
      field: fatigueHistoricDataFields.REGION,
      headerName: getText('fatigue_manager_table_header_region'),
      hidden: depotId !== -1,
      sortable: false,
    },
    {
      field: fatigueHistoricDataFields.STARTED_AT,
      headerName: getText('fatigue_manager_table_header_start_date_time'),
    },
    {
      field: fatigueHistoricDataFields.FINISHED_AT,
      headerName: getText('fatigue_manager_table_header_end_date_time'),
    },
    {
      field: fatigueHistoricDataFields.CREATED_AT,
      headerName: getText('fatigue_manager_table_header_created_at'),
    },
    {
      field: fatigueHistoricDataFields.UPDATED_AT,
      headerName: getText('fatigue_manager_table_header_updated_at'),
    },
    {
      field: fatigueHistoricDataFields.DURATION,
      headerName: getText('fatigue_manager_table_header_hours_worked'),
    },
    {
      field: fatigueHistoricDataFields.FRA,
      headerName: getText(
        'fatigue_manager_table_header_fatigue_risk_assessment',
      ),
      sortable: false,
      renderCell: (props: GridRenderCellParams) => {
        const { row }: { row: FatigueHistoricRowItem } = props;

        return (
          <FraWrapper>
            {[...(row?.fra ?? [])]
              .sort(sortFras)
              .map((fra: FatigueRiskAssessment) =>
                fra.uuid ? (
                  <FraRow
                    key={fra.id}
                    onClick={() =>
                      openFraReviewModal({ ...fra, user: row.user })
                    }
                  >
                    <span>
                      {getText('fatigue_manager_table_view_hour', {
                        hour: convertFraTypeToNumber(fra.fraType),
                      })}
                    </span>
                  </FraRow>
                ) : (
                  <div key={fra.id}>
                    {getText('fatigue_manager_table_incomplete_hour', {
                      hour: convertFraTypeToNumber(fra.fraType),
                    })}
                  </div>
                ),
              )}
          </FraWrapper>
        );
      },
    },
  ].filter((column) => !column.hidden);

  const requestPageData = (page: number, search: string) => {
    const { startDate, endDate, fraType } = filters;
    dispatch(
      fetchFatigueHistoric({
        depotId,
        search,
        startDate,
        endDate,
        minimumDuration: fraType?.value ?? null,
        selectedWorkspaceId: selectedWorkspace?.uuid,
        userIds,
        sort,
        page,
        pageSize: FATIGUE_HISTORIC_PAGE_SIZE,
      }),
    );
  };

  useEffect(
    () => {
      requestPageData(1, filters.search);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filters.startDate,
      filters.endDate,
      filters.fraType,
      filters.search,
      depotId,
      isMounted,
      globalAppLanguage,
      sort,
      userIds,
    ],
  );

  const openFraReviewModal = (fraData) => {
    setDisplayFraModal(true);
    setSelectedFra(fraData);
  };

  const rows: FatigueHistoricRowItem[] | undefined = results?.map(
    (item: FatigueHistoricItem, index): FatigueHistoricRowItem => ({
      ...item,
      id: index,
      key: `data-${index}`,
      durationLabel: convertToHourAndMinuteOrEmpty(item.duration),
    }),
  );

  return (
    <Wrapper>
      <Header>
        <H2>{getText('fatigue_manager_historic_data_title')}</H2>
      </Header>
      <DataRow>
        <StyledTableContainer component={Paper}>
          {!rows || isLoading ? (
            <SkeletonTable
              columns={columns}
              pageSize={FATIGUE_HISTORIC_PAGE_SIZE}
            />
          ) : (
            <StyledDataGrid
              rows={rows}
              columns={columns}
              isEmpty={!rows?.length}
              width={`${100 / columns?.length}%`}
              getRowHeight={() => 'auto'}
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableExtendRowFullWidth
              disableRowSelectionOnClick
              disableColumnResize
              rowsPerPageOptions={[]}
              onColumnHeaderClick={(params: GridColumnHeaderParams) =>
                Analytics.trackEvent(
                  `${EVENT_FATIGUE_HISTORIC_VIEW_SORT_BY}-${params.field}-${CLICKED}`,
                  {
                    depotId,
                    startDate: filters.startDate,
                    endDate: filters.endDate,
                    sort,
                    rowsOfData: total,
                  },
                )
              }
              sortingOrder={['asc', 'desc']}
              sortingMode='server'
              sortModel={[{ field: sort.field, sort: sort.order }]}
              onSortModelChange={(model: GridSortModel) =>
                setSort(getSortModel(sort, model[0]))
              }
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: initialSort.field,
                      sort: initialSort.order,
                    },
                  ],
                },
              }}
              autoHeight
              slots={{
                noRowsOverlay: () => (
                  <EmptyTableText>
                    {getText('fatigue_manager_no_data')}
                  </EmptyTableText>
                ),
                footer: () => {
                  return (
                    page &&
                    count > 1 && (
                      <Pagination
                        count={count}
                        page={page}
                        onChange={(page) =>
                          requestPageData(page, filters.search)
                        }
                      />
                    )
                  );
                },
                loadingOverlay: () => <Loader />,
              }}
            />
          )}
        </StyledTableContainer>
      </DataRow>
      {selectedFra && (
        <FraReviewModal
          displayModal={displayFraModal}
          setDisplayModal={setDisplayFraModal}
          fraData={selectedFra}
        />
      )}
    </Wrapper>
  );
};

export default FatigueHistoricDataTab;

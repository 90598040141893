import styled from 'styled-components';
import theme from 'theme';
import { Button } from '@mui/material';
import { PlusIcon, ThreeDotsIcon, TickCircle } from 'assets/icons';

const { media, fonts, fontSizes, colors, mixins } = theme;

export const SuccessContainer = styled.div`
  ${mixins.flexColumn};
  height: 100%;
  padding: 25px 60px;
  background-color: ${colors.black};
  color: ${colors.white};
  ${media.sm`
    height: 100%;
    padding: 0;
  `}
`;

export const SuccessMessage = styled.div`
  ${mixins.flexColumn};
  justify-content: center;
  width: 65%;
  flex-grow: 1;
  ${media.xl`
    width: 85%;
  `}
  ${media.sm`
    margin-bottom: 60px;
    margin-top: auto;
    padding: 25px;
  `}
`;
export const TickContainer = styled.div`
  display: flex;
  ${media.sm`
    justify-content: center;
    margin-bottom: 90px;
  `}
`;

export const TickCircleIcon = styled(TickCircle)`
  width: 70px;
  height: 70px;
`;

export const Title = styled.div`
  font-size: ${fontSizes.xxxl};
  font-family: ${fonts.bold};
  padding: 20px 0;
  ${media.xxl`
    font-size: ${fontSizes.xxl};
  `}
  ${media.xl`
    font-size: ${fontSizes.xl};
  `}
`;

export const SubTitleContainer = styled.div`
  ${mixins.flexCenter};
`;

export const SubTitle = styled.div`
  font-size: ${fontSizes.xl};
  font-family: ${fonts.bold};
  ${media.xxl`
    font-size: ${fontSizes.m};
  `}
  ${media.xl`
    font-size: ${fontSizes.default};
  `}
`;

export const SignOnLinkButton = styled(Button)`
  &.MuiButton-root {
    font-family: ${fonts.default};
    font-size: ${fontSizes.xl};
    color: ${colors.yellow};
    text-decoration: underline ${colors.yellow};
    padding-left: 5px;
    text-transform: none;
    &:hover {
      cursor: pointer;
      background-color: ${colors.black};
      text-decoration: underline ${colors.yellow};
      text-decoration-thickness: 3px;
    }
    ${media.xxl`
      font-size: ${fontSizes.m};
    `}
    ${media.xl`
      font-size: ${fontSizes.default};
    `}
  }
`;

export const ListContainer = styled.div<{ large: boolean }>`
  height: calc(${({ large }) => (large ? 75 : 67)}% - 34px);
  overflow-y: auto;
  margin: 10px 0;
`;

export const SectionTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 5px;
`;

export const ImageContainer = styled.div`
  padding: 13px 0px;
`;

export const HtmlWrapper = styled.div`
  ul {
    padding: 0px 15px;
  }
  p:after {
    content: '';
    display: inline-block;
    width: 0px;
  }
`;

export const PlusIconStyled = styled(PlusIcon)`
  height: 25px;
  width: 25px;
  use.plus-symbol {
    fill: ${colors.black};
  }
`;

export const ButtonWrapper = styled.div`
  ${mixins.flexBetween};
  padding-top: 20px;
`;

export const TemplateSectionWrapper = styled.div`
  margin: 5% 15%;
  padding: 0 5px;
  height: 100%;
`;

export const TemplateContainer = styled.div`
  padding-right: 10px;
  height: 450px;
  max-height: 500px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.grey};
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const TemplateItem = styled.div<{ selected: boolean }>`
  cursor: pointer;
  color: ${colors.black};
  font-size: ${fontSizes.m};
  font-family: ${fonts.bold};
  padding: 20px;
  border-bottom: 2px solid ${colors.darkGrey};
  ${({ selected }) => selected && `background-color: ${colors.lightOrange};`}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const RamsSectionContainer = styled.div`
  margin-bottom: 16px;
`;

export const DotsIcon = styled(ThreeDotsIcon)`
  width: 35px;
  height: 35px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const HandlerContainer = styled.div`
  ${mixins.flexFullCenter};
  cursor: move;
`;

export const TagContainer = styled.div`
  margin-bottom: 20px;
`;

import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Dates } from 'helpers';
import { useText } from 'hooks';
import { LastApproval } from 'model/Forms/LastApproval';

const { colors, fonts, fontSizes } = theme;

// Styled components
const Wrapper = styled.div`
  padding-bottom: 10px;
`;
const RejectionCommentContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 7px 17px;
  background-color: ${colors.lightGrey};
  font-family: ${fonts.boldItalic};
  font-size: ${fontSizes.default};
`;

const RejectionCommentText = styled.span`
  color: ${colors.black};
  margin-left: 10px;
`;

const RejectionCommentAuthor = styled.div`
  color: ${colors.darkGrey};
  font-family: ${fonts.boldItalic};
  margin-top: 10px;
  font-size: ${fontSizes.default};
`;

const RejectionCommentDate = styled.div`
  color: ${colors.darkGrey};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
  margin-top: 5px;
`;

type Props = {
  lastApproval: LastApproval;
  extraStyles?: React.CSSProperties;
};

const RejectionComment = ({
  lastApproval,
  extraStyles,
}: Props): JSX.Element => {
  const getText = useText();

  return (
    <Wrapper style={extraStyles}>
      <RejectionCommentContainer>
        <RejectionCommentText>
          {lastApproval.rejectionNote}
        </RejectionCommentText>
      </RejectionCommentContainer>
      <RejectionCommentAuthor>
        {getText('job_permits_comment_author', {
          name: `${lastApproval?.createdBy?.fullName}`,
        })}
      </RejectionCommentAuthor>
      <RejectionCommentDate>
        {Dates.formatDateTime(
          lastApproval?.createdAt,
          getText('date_today'),
          getText('date_yesterday'),
        )}
      </RejectionCommentDate>
    </Wrapper>
  );
};

export default RejectionComment;

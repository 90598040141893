import { useText } from 'hooks';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';

import { ModalType } from 'model/enum/ModalType';
import Filters from 'model/Filters';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FyldScreen } from 'model/enum/FyldScreen';
import {
  selectActiveWorkspaceUuid,
  selectCurrentUserId,
} from 'store/selectors';
import Team from 'model/Team';
import JobFilters from 'model/JobFilters';
import { Tooltip } from '../Display';
import { useEffect } from 'react';
import { Button } from '..';
import { StyledTeamIcon } from './styled';

type Props = {
  screen: FyldScreen;
  filters: Filters | JobFilters;
  selectedTeam: Team | null;
  setSelectedTeam: (value: number | null) => void;
  view?: string;
  tab?: string;
  size?: string;
  className?: string;
  margin?: string;
  disabled?: boolean;
};

export const TeamFilter = ({
  screen,
  filters,
  selectedTeam,
  setSelectedTeam,
  view,
  tab,
  size,
  className,
  margin,
  disabled,
}: Props): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUserId = useSelector(selectCurrentUserId);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);
  const getText = useText();

  useEffect(() => {
    if (
      location.state?.modifiedTeam !== undefined &&
      location.state?.modifiedTeam?.id !== selectedTeam?.id &&
      location.state?.selectedWorkspaceUuid === selectedWorkspaceUuid
    ) {
      setSelectedTeam(location.state?.modifiedTeam?.id || null);
      const { pathname, ...rest } = location;
      // FIXME: should type what rest is going to be
      navigate('', {
        ...rest,
        state: {
          ...rest.state,
          modifiedTeam: undefined,
        },
        replace: true,
      });
    }
  }, [location.state?.modifiedTeam]); // eslint-disable-line react-hooks/exhaustive-deps

  const openTeamsModal = () => {
    Analytics.trackEvent(constants.MANAGE_TEAMS_CLICKED, {
      userId: currentUserId,
      area: screen,
      ...(tab && { tab }),
      ...(view &&
        screen === FyldScreen.JOBS && {
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        }),
    });
    navigate(location.pathname + `?modal=${ModalType.TEAMS}`, {
      state: {
        filters,
        selectedTeam,
        event: constants.MANAGE_TEAMS_CANCEL_CLICKED,
        eventProperties: {
          userId: currentUserId,
          area: screen,
          ...(screen === FyldScreen.JOBS && {
            [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
          }),
        },
      },
    });
  };

  if (disabled) {
    return (
      <Tooltip
        title={getText('jobs_clear_search_tooltip')}
        triggerElement={
          <span>
            <Button.IconButton
              onClick={openTeamsModal}
              size={size}
              className={className}
              margin={margin}
              disabled
            >
              <StyledTeamIcon />
            </Button.IconButton>
          </span>
        }
        placement='bottom'
        height={40}
      />
    );
  }
  return (
    <Button.IconButton
      onClick={openTeamsModal}
      size={size}
      className={className}
      margin={margin}
    >
      <StyledTeamIcon />
    </Button.IconButton>
  );
};

import { BulletPoint, BulletPointWrapper, Item } from './styled';

type Props = {
  title: string;
  extraStyles?: React.CSSProperties;
};

export const ListItem = ({ title, extraStyles }: Props): JSX.Element => (
  <Item style={extraStyles}>
    <BulletPointWrapper>
      <BulletPoint />
    </BulletPointWrapper>
    <p>{title}</p>
  </Item>
);

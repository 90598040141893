import { Button } from '@mui/material';
import {
  PlayIcon as Play,
  ThumbnailPlaceholder as Thumbnail,
  WhiteEye,
} from 'assets/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

const { fonts, fontSizes, colors, media, mixins } = theme;

export const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const MapContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const LegendContainer = styled.div`
  ${mixins.flexColumn}
  background: ${colors.white};
  padding: 12px 12px 0 12px;
  position: absolute;
  z-index: 1;
  margin: 25px;
`;

export const LegendRow = styled.div`
  ${mixins.flexCenter}
  margin-bottom: 12px;
`;

export const LegendTextWrapper = styled.div`
  ${mixins.flexColumn}
  margin-left: 12px;
`;

export const LegendTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  color: ${colors.darkGrey};
  ${media.hd`
    font-size: ${fontSizes.default};
  `};
  ${media.xl`
    font-size: ${fontSizes.s};
  `};
`;

export const SummaryItem = styled(Button)<{ $isActive: boolean }>`
  &.MuiButtonBase-root {
    width: 30%;
    height: 11vh;
    margin-left: 5%;
    padding: 0 10px;
    text-transform: none;
    ${mixins.flexFullCenter};
    flex-direction: column;
    position: relative;
    border-radius: 0;
    color: ${colors.white};
    outline: ${({ $isActive }) =>
      $isActive ? `3px solid ${colors.white}` : `2px solid ${colors.darkGrey}`};
    background-color: ${({ $isActive }) =>
      $isActive ? 'rgba(255, 255, 255, 0.2)' : null} !important;

    &:first-of-type {
      margin-left: 0;
    }
    &:hover {
      outline: 3px solid ${colors.white};
    }

    ${media.md`
      width: 31%;
      padding: 0 5px;
      margin-left: 2.5%;
    `};
  }
`;

export const SummaryTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SummaryTitle = styled.div<{ color?: string }>`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  line-height: ${fontSizes.xl};
  text-align: center;
  color: ${({ color }) => color || colors.white};
  ${media.hd`
    font-size: ${fontSizes.m};
  `};
  ${media.xl`
    font-size: ${fontSizes.s};
  `};
  ${media.md`
    font-size: ${fontSizes.xs};
  `};
`;

export const SummaryNumberWrapper = styled.div`
  display: flex;
`;

export const SummaryNumber = styled.div<{ color?: string }>`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.massive};
  line-height: ${fontSizes.massive};
  color: ${({ color }) => color || colors.white};
  margin-bottom: -0.2em;
  margin-top: -0.1em;
  ${media.hd`
    font-size: ${fontSizes.xxl};
  `};
  ${media.xl`
    font-size: ${fontSizes.xl};
  `};
  ${media.md`
    font-size: ${fontSizes.default};
  `};
`;

export const SummaryIconWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const EyeIcon = styled(WhiteEye)`
  position: absolute;
  top: 8px;
  right: 8px;
  height: 16px;
  width: 27px;
  ${media.md`
    height: 10px;
    width: 21px;
  `};
`;

export const ActivityWrapper = styled.div`
  width: 100%;
  min-height: 35vh;
  border: 2px solid ${colors.darkGrey};
  overflow: auto;
`;

export const ActivityRowWrapper = styled.div<{ $isLoading?: boolean }>`
  min-height: 29vh;
  margin: 3vh;
  display: flex;
  align-items: ${({ $isLoading }) => ($isLoading ? 'center' : 'normal')};
  justify-content: ${({ $isLoading }) =>
    $isLoading ? 'center' : 'space-between'};
  padding-bottom: 20px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.36);
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.grey};
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  ${media.xl`
    margin: 1.5vh;
 `}
  ${media.md`
    margin: 1vh;
 `}
`;

export const ActivityItem = styled(Link)`
  ${mixins.flexColumn}
  text-decoration: none;
  width: 15%;
  min-width: 15%;
  padding-right: 5%;
  ${media.lg`
    width: 20%;
    min-width: 20%;
    padding-right: 2.5%;
 `}
  &:last-of-type {
    padding: 0;
  }
`;

export const ActivityItemText = styled.p<{ $isBold?: boolean }>`
  font-size: ${fontSizes.l};
  font-family: ${({ $isBold }) => ($isBold ? fonts.bold : fonts.light)};
  color: ${({ color }) => color || colors.white};
  ${media.hd`
    font-size: ${fontSizes.default};
  `};
  ${media.xl`
    font-size: ${fontSizes.xl};
  `};
  ${media.md`
    font-size: ${fontSizes.md};
  `};
`;

export const ActivityItemImageWrapper = styled.div`
  width: 100%;
  height: 17vh;
  margin-bottom: 12px;
`;

export const EmptyStateWrapper = styled.div`
  width: 100%;
  color: ${colors.white};
  ${mixins.flexColumn}
  ${mixins.flexFullCenter}
`;

export const EmptyStateTitle = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  color: ${colors.white};
`;

export const ThumbnailContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid ${colors.darkGrey};
`;

export const ThumbnailImage = styled.img<{ url: string }>`
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ url }) => url});
  background-color: ${colors.black};
`;

export const ThumbnailPlaceholder = styled(Thumbnail)`
  height: 100%;
  width: 100%;
`;

export const PlayIcon = styled(Play)`
  position: absolute;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 7vh;
  text-align: center;
  height: 25px;
  .fill-selector {
    fill: ${colors.yellow};
  }
`;

export const ClockContainer = styled.div`
  font-size: ${fontSizes.ultimate};
  color: ${colors.white};
  ${media.xxl`
    font-size: ${fontSizes.xxl};
  `};
  ${media.xl`
    font-size: ${fontSizes.xl};
  `};
`;

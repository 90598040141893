import { partialOverridePermit } from 'api/Permit/helpers';
import {
  RECEIVE_MESSAGE,
  UPDATE_MESSAGES,
  CLEAR_MESSAGES,
  APPEND_MESSAGES,
  GET_MORE_MESSAGES,
  SEND_MESSAGE,
  GET_MESSAGES,
  UPDATE_PERMIT,
  SET_ASSESSMENT,
} from '../actions/actionTypes';
import { formatDateTimeWithTimezone } from 'helpers/dates';
import { MessageTypes } from 'components/ui/Message/constants';

const messages = (
  state = {
    messages: [],
    isLoadingNewMessage: false,
    nextPage: null,
    jobId: null,
    scrollToBottom: true,
  },
  action,
) => {
  switch (action.type) {
    case GET_MESSAGES:
      return { ...state, messages: [], jobId: action.jobId };
    case SEND_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            type: 'text',
            id: 0,
            job_id: action.jobId,
            created_at: formatDateTimeWithTimezone(new Date()),
            reactions: [],
            user_reaction: null,
            read: false,
            temp_message: true,
            target: {
              uuid: action.uuid,
              value: action.websocketMessage,
              sender: action.currentUser,
              created_at: formatDateTimeWithTimezone(new Date()),
            },
          },
        ],
      };
    case RECEIVE_MESSAGE: {
      const previousMessages = [...state.messages];
      const existingIndex = previousMessages.findIndex(
        (existingMsg) =>
          existingMsg.target.id === action.message.target.id &&
          existingMsg.type === action.message.type,
      );
      // If so, replace in place
      if (existingIndex > -1) {
        previousMessages[existingIndex] = action.message;
        return {
          ...state,
          messages: [...previousMessages],
          scrollToBottom: true,
        };
      }
      // Otherwise just append
      const filteredMessages = state.messages.filter(
        (existingMsg) =>
          existingMsg.temp_message &&
          existingMsg.target.uuid === action.message.target.uuid,
      );

      const newMessages = state.messages.filter(
        (message) => !filteredMessages.includes(message),
      );
      return {
        ...state,
        messages: [...newMessages, action.message],
        scrollToBottom: true,
      };
    }
    case UPDATE_MESSAGES:
      return {
        ...state,
        messages: [...action.messages],
        jobId: action.jobId,
        nextPage: action.nextPage,
        scrollToBottom: true,
      };
    case UPDATE_PERMIT: {
      const updatedMessages = state.messages.map((message) => {
        if (message?.target?.id === action?.permit?.id) {
          return {
            ...message,
            target: partialOverridePermit(
              message.target,
              action.permit.lastApproval,
            ),
          };
        }
        return message;
      });
      return {
        ...state,
        messages: updatedMessages,
      };
    }
    case APPEND_MESSAGES:
      return {
        ...state,
        messages: [...action.messages, ...state.messages],
        nextPage: action.nextPage,
        scrollToBottom: false,
        isLoadingNewMessage: false,
      };
    case GET_MORE_MESSAGES:
      return { ...state, isLoadingNewMessage: true };
    case CLEAR_MESSAGES:
      return { ...state, messages: [], nextPage: null, scrollToBottom: true };
    case SET_ASSESSMENT: {
      const previousMessages = [...state.messages];
      const existingIndex = previousMessages.findIndex(
        (existingMsg) =>
          existingMsg.target.id === action.assessment.id &&
          existingMsg.type === MessageTypes.ASSESSMENT,
      );
      // If so, replace in place
      if (existingIndex > -1) {
        previousMessages[existingIndex] = {
          ...previousMessages[existingIndex],
          target: {
            ...previousMessages[existingIndex].target,
            video: action.assessment.video,
          },
        };
        return {
          ...state,
          messages: [...previousMessages],
          scrollToBottom: true,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default messages;

import styled from 'styled-components';
import { Size } from 'model/enum/Size';
import theme from 'theme';

const { fontSizes, colors } = theme;

export const Span = styled.span<{
  size: Size.SMALL | Size.NORMAL;
  $grey: boolean;
}>`
  font-size: ${({ size }) => {
    if (size === Size.SMALL) {
      return fontSizes.s;
    }
    if (size === Size.NORMAL) {
      return fontSizes.default;
    }
  }};
  word-spacing: 5px;
  color: ${({ $grey }) => ($grey ? colors.grey : colors.black)};
`;

import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile, useSafetyPrediction, useText } from 'hooks';
import { Job } from 'api';
import theme from 'theme';
import { Button, Input, Map, Navbar } from 'components/ui';
import * as constants from 'utils/constants';
import { getMessages } from 'store/actions/messages';
import { Languages } from 'utils/constants';
import { fetchActiveJob } from 'store/actions/jobs';
import {
  selectWorkspaceSettings,
  selectGlobalLanguageSetting,
  selectJobs,
  selectActiveWorkspaceUuid,
  selectDepots,
  selectLocations,
  selectJobsView,
} from 'store/selectors';
import { useNavigate, useParams } from 'react-router-dom';
import Coordinate from 'model/Coordinate';
import { getLabelByLocale, isValidPostcode } from 'helpers/utils';
import { fetchDepots } from 'store/actions/depots';
import { fetchJobSubtypes, fetchJobTypes } from 'store/actions/jobTypes';
import { selectJobTypes } from 'store/selectors/jobTypes';
import { fetchLocations } from 'store/actions/locations';
import { PageSource } from 'components/ui/Map/constants';
import {
  getLegacyMapMarkerIcon,
  getMapMarkerIcon,
  getCoordinatesForNewJob,
  getCoordinatesFromJob,
  getLegacyCoordinatesFromJob,
} from 'helpers/map';
import { Container, ErrorMessage, Form, InputsContainer } from './styled';
import { ErrorState } from './constants';
import { PinLocationHint } from './PinLocationHint';

const { fontSizes } = theme;

type FormProps = {
  predefined_location: number | null;
  latitude: string;
  longitude: string;
  location_description: string;
  postcode: string;
  address: string;
  job_number: string;
  originating_number: string;
  project_number: string;
  type: number;
  subtype: number | null;
  depot: number;
  assignees: number[];
  type_other: string | null;
  subtype_other: string | null;
};

export const Details = () => {
  const noErrorsState = {
    postcode: false,
    invalidPostcode: false,
    subtype: false,
    depot: false,
    location: false,
    location_description: false,
    conflicting_job: null,
  };
  const dispatch = useDispatch();
  const getText = useText();
  const { activeJob, isFetchingActiveJob, mapCanLoad } =
    useSelector(selectJobs);
  const { locations, isFetchingLocations } = useSelector(selectLocations);
  const { depots, isFetchingDepots } = useSelector(selectDepots);
  const { jobTypes, isFetchingJobTypes, jobSubtypes, isFetchingJobSubtypes } =
    useSelector(selectJobTypes);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);
  const [form, setForm] = useState<FormProps>();
  const [selectedSubtype, setSelectedSubtype] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [errors, setErrors] = useState<ErrorState>(noErrorsState);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const view = useSelector(selectJobsView);
  const {
    work_order_number_label,
    work_order_number_label_es,
    originating_number_label,
    originating_number_label_es,
    project_number_label,
    project_number_label_es,
    job_subtype_label,
    job_subtype_label_es,
    address_label,
    address_label_es,
    locations_enable,
    pin_location_enabled,
    postcode_label,
    postcode_label_es,
    enable_auto_work_order_number,
  } = useSelector(selectWorkspaceSettings);
  const { getRiskWarning } = useSafetyPrediction();

  const navigate = useNavigate();
  const { jobId } = useParams();
  const isMobile = useIsMobile();

  const mapMeasurements = useMemo(
    () => ({
      height: isMobile ? '190px' : '100%',
      width: isMobile ? '100%' : '50%',
    }),
    [isMobile],
  );
  const pinLocationCoordinates: {
    lat: number;
    lng: number;
  } | null = useMemo(() => {
    if (activeJob?.latitude && activeJob?.longitude) {
      return getCoordinatesForNewJob(activeJob.latitude, activeJob.longitude);
    }
    return null;
  }, [activeJob?.latitude, activeJob?.longitude]);

  const mapCoordinates: Coordinate[] = useMemo(() => {
    if (activeJob?.latitude && activeJob?.longitude && activeJob?.id) {
      return [
        view === constants.LEGACY_VIEW
          ? getLegacyCoordinatesFromJob(activeJob, getRiskWarning)
          : getCoordinatesFromJob(activeJob, getRiskWarning),
      ];
    }
    return [];
  }, [activeJob, getRiskWarning, view]);

  const otherType = getText('other');
  const selectedTypeHasSubtype =
    !!form?.type &&
    !!jobSubtypes &&
    jobSubtypes.some((subType) => subType.type_id === form.type);

  const isFormValid = () => {
    const newErrors = {
      postcode: !!postcode_label && !!activeJob?.postcode && !form?.postcode,
      invalidPostcode:
        !!postcode_label &&
        !!form?.postcode &&
        !isValidPostcode(form.postcode.replace(/ /g, '')),
      subtype:
        jobSubtypes.length > 0 &&
        selectedType !== otherType &&
        selectedTypeHasSubtype &&
        !selectedSubtype,
      depot:
        !form?.depot &&
        selectedType !== otherType &&
        selectedSubtype !== otherType,
      location:
        pin_location_enabled &&
        !!form &&
        (form.latitude === null || form.longitude === null),
      location_description:
        pin_location_enabled && !!form && !form?.location_description,
      conflicting_job: null,
    };

    if (form && selectedSubtype !== otherType && selectedType !== otherType) {
      form.subtype_other = null;
    }
    if (form && selectedType !== otherType) {
      form.type_other = null;
    }
    if (form && selectedType === otherType) {
      form.subtype = null;
    }
    setErrors(newErrors);

    return Object.values(newErrors).every((error) => !error);
  };

  useEffect(() => {
    if (!activeJob && !isFetchingActiveJob) {
      dispatch(fetchActiveJob(jobId));
    } else if (activeJob) {
      setForm({
        predefined_location: activeJob.predefined_location?.id,
        latitude: activeJob.latitude,
        longitude: activeJob.longitude,
        location_description: activeJob.location_description,
        postcode: activeJob.postcode,
        address: activeJob.address,
        job_number: activeJob.job_number,
        originating_number: activeJob.originating_number,
        project_number: activeJob.project_number,
        type: activeJob?.type?.id,
        subtype: activeJob?.subtype?.id,
        depot: activeJob?.depot?.id,
        assignees: activeJob.assignees.map(({ id }) => id),
        type_other: activeJob.type_other,
        subtype_other: activeJob.subtype_other,
      });
    }
  }, [activeJob]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!form?.type) {
      setSelectedType(null);
    } else if (jobTypes.length >= 1 && form?.type) {
      setSelectedType(
        jobTypes.find((type) => type.id === form.type)?.title ?? null,
      );
    }
    if (!form?.subtype) {
      setSelectedSubtype(null);
    } else if (jobSubtypes.length >= 1 && form?.subtype) {
      setSelectedSubtype(
        jobSubtypes.find((subtype) => subtype.id === form.subtype)?.title ??
          null,
      );
    }
  }, [jobTypes, jobSubtypes, form]);

  useEffect(() => {
    if (locations_enable) {
      dispatch(fetchLocations());
    }
    dispatch(fetchJobTypes(selectedWorkspaceUuid));
    dispatch(fetchJobSubtypes(selectedWorkspaceUuid));
    dispatch(fetchDepots(selectedWorkspaceUuid));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isFormDisabled = () =>
    !form?.type ||
    (address_label && !form.address?.trim()) ||
    (postcode_label && activeJob?.postcode && !form.postcode?.trim()) ||
    (pin_location_enabled && !form.location_description?.trim()) ||
    ((work_order_number_label || work_order_number_label_es) &&
      !form.job_number?.trim());

  const handleChange = (e) => {
    setForm(
      (prevForm) =>
        ({ ...prevForm, [e.target.id]: e.target.value }) as FormProps,
    );
    if (e.target.id === 'postcode') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        postcode: false,
        invalidPostcode: false,
      }));
    }
  };

  const handlePlaceSelection = useCallback(
    (location) => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        location: false,
      }));
      setForm(
        (prevForm) =>
          ({
            ...prevForm,
            latitude: location.lat().toFixed(6),
            longitude: location.lng().toFixed(6),
          }) as FormProps,
      );
    },
    [setErrors, setForm],
  );

  const handleLocationDescriptionChange = useCallback(
    (description) => {
      setForm(
        (prevForm) =>
          ({
            ...prevForm,
            location_description: description,
          }) as FormProps,
      );
    },
    [setForm],
  );

  const handleTypeChange = (e) => {
    setForm(
      (prevForm) =>
        ({
          ...prevForm,
          type: e?.target?.value,
          subtype:
            (
              jobSubtypes?.find(
                (subtype) => subtype.type_id === e?.target?.value,
              ) || {}
            )?.id ?? null,
        }) as FormProps,
    );
  };

  const handleSubtypeChange = (e) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      subtype: false,
    }));
    setForm(
      (prevForm) => ({ ...prevForm, subtype: e?.target?.value }) as FormProps,
    );
  };

  const handleDepotChange = (e) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      depot: false,
    }));
    setForm(
      (prevForm) => ({ ...prevForm, depot: e?.target?.value }) as FormProps,
    );
  };

  const handleSubmit = async () => {
    if (isFormValid()) {
      try {
        await Job.updateDetails(activeJob?.id, {
          ...form,
          longitude: form?.longitude || undefined,
          latitude: form?.latitude || undefined,
        });
        dispatch(fetchActiveJob(activeJob?.id));
        dispatch(getMessages(jobId));
        navigate(`/jobs/${jobId}`);
      } catch (err: any) {
        console.error(err);
        if (err?.response?.data?.conflicting_job_id) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            conflicting_job: err?.response?.data?.conflicting_job_id,
          }));
        }
      }
    }
  };

  const getSelectedLocationOption = () => {
    const selected = locations.find(
      (location) => location.id === form?.predefined_location,
    );
    return selected ? { id: selected.id, title: selected.name } : null;
  };

  return (
    <>
      <Navbar
        title={getText('job_detail_overview_edit_job_button')}
        defaultBackPath={`/jobs/${jobId}`}
        mobileOnly
      />
      <Container>
        {activeJob && mapCanLoad && (
          <>
            <Map
              id='job-edit-details'
              height={
                pin_location_enabled && isMobile
                  ? '425px'
                  : mapMeasurements.height
              }
              width={mapMeasurements.width}
              coordinates={mapCoordinates}
              getMapMarker={
                view === constants.LEGACY_VIEW
                  ? getLegacyMapMarkerIcon
                  : getMapMarkerIcon
              }
              locationCoordinates={pinLocationCoordinates}
              isJobAddition={pin_location_enabled}
              isSearchable={pin_location_enabled}
              onPlaceSelection={handlePlaceSelection}
              onDescriptionChange={handleLocationDescriptionChange}
              pageSource={PageSource.EDIT_JOB}
            />
            <PinLocationHint isOnMap />
          </>
        )}
        {form && activeJob && (
          <Form onSubmit={handleSubmit}>
            <InputsContainer>
              {locations_enable &&
                !isFetchingLocations &&
                locations?.length > 0 &&
                form.predefined_location && (
                  <Input.Select
                    id='location'
                    label={getText('create_job_location_title')}
                    selected={getSelectedLocationOption()}
                    options={locations.map((location) => ({
                      id: location.id,
                      title: location.name,
                    }))}
                    extraStyles={{ marginBottom: '15px' }}
                    large
                    grey
                    disabled
                    isLocation
                  />
                )}
              {pin_location_enabled && form.latitude && form.longitude && (
                <Input.Text
                  id='pinned_location'
                  label={getText('create_job_location_title')}
                  value={`${form.latitude}, ${form.longitude}`}
                  extraStyles={{ marginBottom: '15px' }}
                  disabled
                />
              )}
              {pin_location_enabled && (
                <Input.Text
                  id='location_description'
                  label={getText('create_job_location_description_title')}
                  value={form.location_description}
                  extraStyles={{ marginBottom: '15px' }}
                  onChange={handleChange}
                  required
                />
              )}
              {errors.postcode && (
                <ErrorMessage>
                  {getText('create_job_required_field')}
                </ErrorMessage>
              )}
              {postcode_label && activeJob?.postcode && (
                <Input.Text
                  id='postcode'
                  label={getLabelByLocale(globalAppLanguage, {
                    postcode_label,
                    postcode_label_es,
                  })}
                  value={form.postcode}
                  onChange={handleChange}
                  extraStyles={{ marginBottom: '15px' }}
                  required
                />
              )}
              {errors.location_description && (
                <ErrorMessage>
                  {getText('create_job_required_field')}
                </ErrorMessage>
              )}
              {errors.invalidPostcode && (
                <ErrorMessage>
                  {getText('create_job_invalid_postcode')}
                </ErrorMessage>
              )}
              {address_label && (
                <Input.Text
                  id='address'
                  label={getLabelByLocale(globalAppLanguage, {
                    address_label,
                    address_label_es,
                  })}
                  value={form.address}
                  onChange={handleChange}
                  extraStyles={{ marginBottom: '15px' }}
                  required
                />
              )}
              {!enable_auto_work_order_number &&
                (work_order_number_label || work_order_number_label_es) && (
                  <Input.Text
                    id='job_number'
                    label={getLabelByLocale(globalAppLanguage, {
                      work_order_number_label,
                      work_order_number_label_es,
                    })}
                    value={form.job_number}
                    onChange={handleChange}
                    extraStyles={{ marginBottom: '15px' }}
                    required
                  />
                )}
              {originating_number_label && (
                <Input.Text
                  id='originating_number'
                  label={getLabelByLocale(globalAppLanguage, {
                    originating_number_label,
                    originating_number_label_es,
                  })}
                  value={form.originating_number}
                  onChange={handleChange}
                  extraStyles={{ marginBottom: '15px' }}
                />
              )}
              {project_number_label && (
                <Input.Text
                  id='project_number'
                  label={getLabelByLocale(globalAppLanguage, {
                    project_number_label,
                    project_number_label_es,
                  })}
                  value={form.project_number}
                  onChange={handleChange}
                  extraStyles={{ marginBottom: '15px' }}
                />
              )}
              {!isFetchingJobTypes && jobTypes.length > 0 && (
                <Input.Select
                  id='type'
                  label={getText('create_job_type_title')}
                  selected={jobTypes.find((type) => type.id === form.type)}
                  options={jobTypes}
                  onChange={handleTypeChange}
                  extraStyles={{ marginBottom: '15px' }}
                  large
                  required
                  fitContent
                />
              )}
              {form.type && selectedType === getText('other') && (
                <Input.Text
                  id='type_other'
                  label={getText('create_job_type_other_title')}
                  value={form.type_other ?? ''}
                  onChange={handleChange}
                  extraStyles={{ marginBottom: '15px' }}
                />
              )}
              {form.type &&
                !isFetchingJobSubtypes &&
                jobSubtypes.length > 0 &&
                selectedType !== getText('other') &&
                job_subtype_label &&
                selectedTypeHasSubtype && (
                  <Input.Select
                    id='subtype'
                    label={getLabelByLocale(globalAppLanguage, {
                      job_subtype_label,
                      job_subtype_label_es,
                    })}
                    selected={jobSubtypes.find(
                      (subtype) => subtype.id === form.subtype,
                    )}
                    options={jobSubtypes.filter(
                      (subtype) => subtype.type_id === form.type,
                    )}
                    onChange={handleSubtypeChange}
                    extraStyles={{ marginBottom: '15px' }}
                    large
                    required
                    fitContent
                  />
                )}
              {errors.subtype && (
                <ErrorMessage>
                  {getText('create_job_required_field')}
                </ErrorMessage>
              )}
              {form.subtype &&
                selectedSubtype === getText('other') &&
                selectedType !== getText('other') && (
                  <Input.Text
                    id='subtype_other'
                    label={getText('create_job_subtype_other_title')}
                    value={form.subtype_other ?? ''}
                    onChange={handleChange}
                    extraStyles={{ marginBottom: '15px' }}
                  />
                )}
              {!isFetchingDepots && depots.length > 0 && (
                <Input.Select
                  id='depot'
                  label={getText('create_job_depot_title')}
                  selected={depots.find((depot) => depot.id === form.depot)}
                  options={depots}
                  onChange={handleDepotChange}
                  extraStyles={{ marginBottom: '15px' }}
                  large
                  required={
                    !(
                      selectedType === getText('other') ||
                      selectedSubtype === getText('other')
                    )
                  }
                />
              )}
              {errors.depot && (
                <ErrorMessage>
                  {getText('create_job_required_field')}
                </ErrorMessage>
              )}
            </InputsContainer>

            {errors.location && (
              <ErrorMessage>
                {getText('create_job_location_required')}
              </ErrorMessage>
            )}

            {errors.conflicting_job && (
              <ErrorMessage>
                {getText('job_detail_overview_edit_job_conflicting_job_error', {
                  jobId: errors.conflicting_job,
                })}
              </ErrorMessage>
            )}

            <Button.Primary
              event={constants.EVENT_JOB_UPDATED}
              eventProperties={{
                jobId: activeJob?.id,
                [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
              }}
              text={getText('create_job_update_details_button')}
              onClick={handleSubmit}
              disabled={isFormDisabled()}
              extraStyles={
                globalAppLanguage === Languages.spanish.locale
                  ? { padding: '0 15px', fontSize: fontSizes.m }
                  : {}
              }
            />
          </Form>
        )}
      </Container>
    </>
  );
};

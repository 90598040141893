import styled from 'styled-components';
import theme from 'theme';

const { colors, fontSizes } = theme;

export const WeatherContainer = styled.div<{ $isMobile: boolean }>`
  background-color: ${colors.white};
  position: absolute;
  top: ${({ $isMobile }) => ($isMobile ? '170px' : '10px')};
  right: 10px;
  padding: 0px 4px;
  align-items: center;
  display: flex;
`;

export const Description = styled.span`
  text-transform: capitalize;
  font-size: ${fontSizes.s};
`;

export const Image = styled.img<{ $isMobile: boolean }>`
  height: ${({ $isMobile }) => ($isMobile ? '30px' : '40px')};
  width: ${({ $isMobile }) => ($isMobile ? '30px' : '40px')};
`;

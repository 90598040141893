import React from 'react';
import styled from 'styled-components';
import { Button as MaterialButton } from '@mui/material';
import theme from 'theme';

const { media, colors, fonts, fontSizes, mixins } = theme;

export const Button = styled(
  ({
    width,
    disabled,
    loading,
    hasBlackBackground,
    outlineOffset,
    marginTop,
    $iconFront,
    ...rest
  }) => <MaterialButton disabled={disabled} {...rest} />,
)`
  &.MuiButtonBase-root {
    height: 55px;
    width: ${({ width }) => width};
    ${mixins.flexBetween};
    ${({ $iconFront }) =>
      $iconFront && `justify-content: flex-start !important;`}
    box-sizing: border-box;
    border-radius: 0;
    outline: 2px solid
      ${({ hasBlackBackground }) =>
        hasBlackBackground ? colors.grey : colors.black};
    font-family: ${fonts.bold};
    font-size: ${fontSizes.l};
    text-transform: none;
    letter-spacing: 0;
    margin-top: ${({ marginTop }) => marginTop};
    padding: 0 20px;
    text-align: left;
    align-self: flex-end;
    background-color: ${({ style }) =>
      style?.backgroundColor
        ? style.backgroundColor
        : colors.yellow} !important;
    color: ${colors.black};
    &:hover {
      outline: 3px solid
        ${({ hasBlackBackground }) =>
          hasBlackBackground ? colors.lightGrey : colors.black};
      background-color: ${({ style }) =>
        style?.backgroundColor
          ? style.backgroundColor
          : colors.yellow} !important;
      ${({ outlineOffset }) =>
        outlineOffset && `outline-offset: ${outlineOffset};`}
    }
    &.Mui-disabled {
      outline: 2px solid ${colors.grey};
      background-color: ${colors.lightGrey} !important;
      color: ${colors.black};
      &:hover {
        outline: 2px solid ${colors.grey};
        background-color: ${colors.lightGrey} !important;
      }
    }
    ${({ loading }) =>
      loading &&
      `
      cursor: default;
      &:hover {
        outline: 2px solid ${colors.black};
      }
  `}
    ${media.sm`
      width: 100%;
      margin-top: auto;      
    `}

    ${media.xxl`
      font-size: ${fontSizes.m};
      padding: 0 10px;
    `}
    
    ${media.xl`
      height: 45px;
      font-size: ${fontSizes.s};
      padding: 0 10px;
    `}
  }
`;

export const DescriptionWrapper = styled.div<{ $iconFront?: boolean }>`
  ${mixins.flexBetween};
  flex-direction: row;

  order: ${({ $iconFront }) => ($iconFront ? 1 : 0)};
`;

export const Description = styled.div<{
  $disabled: boolean;
  $loading: boolean;
}>`
  opacity: ${({ $disabled }) => ($disabled ? 0.65 : 1)};
  margin-left: ${({ $loading }) => ($loading ? '5px' : 0)};
  text-align: start;
`;

export const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: 25px;
  width: 25px;
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
  order: ${({ $iconFront }) => ($iconFront ? 0 : 1)};
  ${({ $iconFront }) => $iconFront && `margin-right: 15px;`}

  ${media.xl`
    height: 20px;
    width: 20px;
  `}
`;

import { useText } from 'hooks';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { SearchIcon } from 'assets/icons';
import {
  Input,
  SearchContainer,
  SearchIconContainer,
  StyledSearchIcon,
} from './styled';

type Props = {
  value: string;
  handleSearch: (search: string) => void;
};

export const SearchInput = ({ value, handleSearch }: Props): JSX.Element => {
  const getText = useText();
  const [search, setSearch] = useState<string>(value);
  const [searchFilter] = useDebounce(search, 600);

  useEffect(() => {
    handleSearch(search);
  }, [searchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SearchContainer>
      <Input
        placeholder={getText('description_hint')}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <SearchIconContainer>
        <StyledSearchIcon src={SearchIcon} />
      </SearchIconContainer>
    </SearchContainer>
  );
};

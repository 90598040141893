import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

import {
  Warning,
  ThumbnailPlaceholder,
  ClockIcon,
  PlusIcon,
  VideoTimer,
  DownloadNoBackground,
} from 'assets/icons';

const { colors, fonts, fontSizes, media, mixins } = theme;

// Styled Components
export const Container = styled.div<{ $isCreatedByCurrentUser?: boolean }>`
  ${mixins.flexColumn};
  margin: 8px 0;
  ${({ $isCreatedByCurrentUser }) =>
    $isCreatedByCurrentUser ? 'padding-left: 56px;' : ''}
`;

export const ActionBox = styled(({ to, $isLocked, ...rest }) => (
  <Link to={to} {...rest} />
))`
  cursor: pointer;
  text-decoration: none;
  pointer-events: ${({ $isLocked }) => ($isLocked ? 'none' : 'auto')};
`;

export const ActionBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 3px solid ${colors.black};
  background-color: ${colors.white};
  padding: 17px;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: none;
  ${media.lg`
    padding: 17px 10px;
  `}
  ${media.sm`
    padding: 17px;
  `}
`;

export const Description = styled.div`
  ${mixins.flexColumn};
  font-size: ${fontSizes.s};
  ${media.lg`
  width: 70%;
`}
`;

export const Type = styled.h4<{
  $fontSize?: string;
  $whiteSpace?: string;
  $hasLineHeight: boolean;
}>`
  font-size: ${({ $fontSize }) => $fontSize ?? fontSizes.m};
  ${({ $whiteSpace }) => $whiteSpace && `white-space: ${$whiteSpace}`};
  ${({ $hasLineHeight }) => $hasLineHeight && `line-height: ${fontSizes.xxxl}`};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
`;

export const ActionIcon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: auto;
  width: 25px;
  min-width: 25px;
  margin-right: 20px;
  ${media.xl`
  margin-right: 10px;
`}
  ${media.lg`
  width: 15%;
  min-width: 15%;
`}
  ${media.sm`
  width: 25px;
  min-width: 25px;
  margin-right: 10px;
`}
`;

export const Subtype = styled.p<{ $fontSize?: string }>`
  color: ${colors.darkGrey};
  ${({ $fontSize }) => $fontSize && `font-size: ${$fontSize}`};
`;

export const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: auto;
  width: 25px;
  min-width: 25px;
  margin-left: auto;
  ${media.lg`
  width: 10%;
  min-width: 10%;
`}
  ${media.sm`
  width: 25px;
  min-width: 25px;
`}
`;

export const ThumbnailContainer = styled.div`
  height: 185px;
  background-color: ${colors.black};
  position: relative;
`;

export const ThumbnailOverLay = styled.div<{ $backgroundColor?: string }>`
  background: ${({ $backgroundColor }) => $backgroundColor || 'transparent'};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
`;

export const Placeholder = styled(ThumbnailPlaceholder)`
  height: 75%;
  width: 100%;
`;

export const ThumbnailText = styled.p`
  color: ${colors.white};
  font-family: ${fonts.bold};
  position: absolute;
  bottom: 0;
  margin: 10px 17px !important;
`;

export const CreationDateContainer = styled.p`
  font-size: ${fontSizes.s};
  color: ${colors.darkGrey};
  align-self: flex-end;
  margin-top: 10px !important;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 17px;
  font-family: ${fonts.bold};
`;

export const OrangeStatusContainer = styled(StatusContainer)`
  background-color: ${colors.orange};
`;

export const BlackStatusContainer = styled(StatusContainer)`
  background-color: ${colors.black};
`;

export const Status = styled.span<{ $textColor?: string }>`
  color: ${({ $textColor }) => $textColor || colors.white};
  margin-left: 10px;
`;

export const SmallWarningIcon = styled(Warning)`
  height: 20px;
  width: 20px;
`;

export const SmallClockIcon = styled(ClockIcon)`
  height: 40px;
  width: 40px;
`;

export const VideoTimerIcon = styled(VideoTimer)`
  height: 80px;
  width: 100px;
`;

export const ManualRAFlag = styled.span`
  position: absolute;
  background-color: ${colors.blue};
  color: ${colors.white};
  padding: 0px 6px;
  border-radius: 5px;
  top: 5px;
  right: 5px;
`;

export const LinearGradient = styled.div`
  height: 100%;
  width: 100%;
  background-image: linear-gradient(${colors.transparentGrey}, ${colors.black});
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
`;

export const TextBox = styled.div`
  border: 2px solid ${colors.grey};
  padding: 17px;
  margin: 10px 0;
  word-wrap: break-word;
  background-color: ${colors.white};
  white-space: pre-wrap;
`;

export const DateWrapper = styled.div`
  font-size: ${fontSizes.s};
  color: ${colors.darkGrey};
  margin-right: 8px;
`;

export const TextBoxFooter = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
`;

export const PlusIconStyled = styled(PlusIcon)`
  height: 12px;
  width: 12px;
  use.plus-symbol {
    fill: ${colors.grey};
  }
`;

export const AddEmojiButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: 1px solid ${colors.lightGrey};
  line-height: 16px;
  margin-right: 6px;
  cursor: pointer;
  background-color: ${colors.white};
`;

export const EmojiPopoverWrapper = styled.div`
  font-size: ${fontSizes.l};
  display: flex;
  width: 100%;
  height: 100%;
  ${mixins.flexFullCenter};
`;

export const EmojiPopoverItem = styled.div`
  padding: 0 6px;
  cursor: pointer;
`;

export const EmojiWrapper = styled.div`
  font-size: ${fontSizes.s};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
`;

export const EmojiItem = styled.div<{ $isUserReaction: boolean }>`
  color: ${({ $isUserReaction }) =>
    $isUserReaction ? colors.blue : colors.grey};
  padding: 6px;
  padding-bottom: 5px;
  border: 1px solid
    ${({ $isUserReaction }) =>
      $isUserReaction ? colors.blue : colors.lightGrey};
  line-height: 16px;
  cursor: ${({ $isUserReaction }) => ($isUserReaction ? 'pointer' : 'default')};
  display: flex;
`;

export const EmojiIcon = styled.span`
  font-size: 20px;
`;

export const EventContainer = styled.div`
  ${mixins.flexFullCenter};
  background-color: ${colors.lightGrey};
  padding: 7px 17px;
  margin: 15px 0;
`;

export const P = styled.p`
  font-family: ${fonts.bold};
`;

export const AnchorActionBox = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  border: 3px solid ${colors.black};
  padding: 17px 10px;
  margin-top: 10px;
  cursor: pointer;
`;

export const DownloadIcon = styled(DownloadNoBackground)`
  height: auto;
  width: 15px;
  min-width: 15px;
  margin-left: auto;
`;

export const CreationDate = styled.p`
  font-size: ${fontSizes.s};
  color: ${colors.darkGrey};
  align-self: flex-end;
  margin-top: 10px !important;
`;

import axios from 'axios';
import { formatDateForApi } from 'helpers/dates';
import { Comment, Forms } from 'model/Form';

export const getUserForm = async (id: number) => {
  try {
    const response = await axios.get(`/user-form/${id}/`);
    return response.data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err?.message);
    }
  }
};

export const sendComment = async (id: number, comment: string) => {
  try {
    const response = await axios.post(`/user-form/${id}/comment/`, {
      comment,
    });
    return response.data?.comment;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err?.message);
    }
  }
};

export const getHavsComplianceComments = async (
  id: number,
  startDate: Date,
  endDate: Date,
): Promise<Comment[]> => {
  const { data } = await axios.get(`/havs/compliance/comments/`, {
    params: {
      start_date: formatDateForApi(startDate),
      end_date: formatDateForApi(endDate),
      user_id: id,
    },
  });
  return data.results.map(Forms.mapToComment);
};

export const sendHavsComplianceComment = async (
  userId: number,
  createdById: number,
  comment: string,
  startDate: Date,
  endDate: Date,
): Promise<Comment> => {
  const { data } = await axios.post(`/havs/compliance/comments/`, {
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    comment,
    user: userId,
    created_by: createdById,
  });
  return Forms.mapToComment(data);
};

export const signOffHavsCompliance = async (
  startDate,
  endDate,
  userId,
  createdById,
) => {
  try {
    const response = await axios.post(`/havs/compliance/signoff/`, {
      start_date: formatDateForApi(startDate),
      end_date: formatDateForApi(endDate),
      user: userId,
      created_by: createdById,
    });
    return response.data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err?.message);
    }
  }
};

export const getPdfExportId = async (formId: string): Promise<string> => {
  const { data } = await axios.post(`/user-form/${formId}/pdf/`);
  return data.object_id;
};

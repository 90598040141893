import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useText } from 'hooks';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { MarkerBlack } from 'assets/icons';
import * as Logger from 'utils/logger';
import RootState from 'model/State/RootState';
import Coordinate from 'model/Coordinate';
import { checkIfGoogleMapsLoaded, getDefaultMapSettings } from 'helpers/map';
import { setGlobalError } from 'store/slices/notifications';
import { MapInteractionType } from './constants';
import { useNavigate } from 'react-router-dom';
import { MapJob } from 'model/MapJob';
import { selectJobsView } from 'store/selectors';
import {
  MapContainer,
  MapWrapper,
  SearchContainer,
  SearchIcon,
} from './styled';

const mapPadding = 20;

type Props = {
  id: string;
  height?: string;
  width?: string;
  coordinates?: Array<Coordinate | MapJob> | null;
  getMapMarker:
    | ((coordinate: MapJob) => string)
    | ((coordinate: Coordinate) => string);
  isJobAddition?: boolean;
  isSatellite?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  onPlaceSelection?: (value: google.maps.LatLng) => void;
  onDescriptionChange?: (value: string) => void;
  locationCoordinates?: Coordinate | null;
  pageSource?: string;
};

// Map
export const MapComponent = ({
  id,
  height = '100%',
  width = '100%',
  coordinates,
  getMapMarker,
  isJobAddition = false,
  isSatellite = false,
  isSearchable = false,
  isDisabled = false,
  onPlaceSelection = () => {},
  onDescriptionChange = () => {},
  locationCoordinates,
  pageSource = '',
}: Props): JSX.Element | null => {
  // This allows for multiple maps to display on the same page (e.g., when using modals)
  const maps = {};
  const navigate = useNavigate();
  const getText = useText();
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const { activeJob, mapCanLoad } = useSelector(
    (state: RootState) => state.jobs,
  );
  const view = useSelector(selectJobsView);
  maps[id] = { map: null, markers: [] };
  const [value, setValue] = useState<string>('');

  const googleMapsLoaded = checkIfGoogleMapsLoaded();
  const defaultMapSettings = getDefaultMapSettings();

  (window as any).gm_authFailure = () => {
    Logger.error(Error('Google Maps API error: Failed to authenticate '));
  };

  async function renderMap(options) {
    if (document?.getElementById(id)) {
      const { Map: GoogleMap, MapTypeId } = (await google.maps.importLibrary(
        'maps',
      )) as google.maps.MapsLibrary;
      const { ControlPosition } = (await google.maps.importLibrary(
        'core',
      )) as google.maps.CoreLibrary;
      const map = new GoogleMap(document.getElementById(id) as HTMLElement, {
        center: options?.center ?? defaultMapSettings?.defaultCentre,
        zoom: options?.zoom ?? defaultMapSettings?.defaultZoom,
        disableDefaultUI: true,
        zoomControl: true,
        streetViewControl: !isJobAddition,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: ControlPosition.BOTTOM_LEFT,
        },
        mapTypeId: isSatellite ? MapTypeId.SATELLITE : MapTypeId.ROADMAP,
      });

      map.addListener('zoom_changed', () => {
        Analytics.trackEvent(constants.EVENT_MAP_INTERACTION, {
          pageSource,
          type: MapInteractionType.ZOOM,
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        });
      });

      map.addListener('bounds_changed', () => {
        const mapElement = document?.querySelector('.gm-style');
        if (
          mapElement?.clientHeight === window.innerHeight &&
          mapElement?.clientWidth === window.innerWidth
        ) {
          Analytics.trackEvent(constants.EVENT_MAP_INTERACTION, {
            pageSource,
            type: MapInteractionType.FULL_SCREEN,
            [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
          });
        }
      });
      return map;
    }
  }

  function renderMapFromJobCoordinate(jobCoordinates) {
    return renderMap({
      center: {
        lat: jobCoordinates.lat,
        lng: jobCoordinates.lng,
      },
      zoom: 14,
    });
  }

  async function renderMapWithMarks(markedJobs) {
    const { LatLngBounds } = (await google.maps.importLibrary(
      'core',
    )) as google.maps.CoreLibrary;
    const bounds = new LatLngBounds();

    markedJobs.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));

    const map = await renderMap({
      center: bounds.getCenter(),
      zoom: isSatellite ? 14 : null,
    });
    if (!isJobAddition) {
      map?.fitBounds(bounds, mapPadding);
    }

    return map;
  }

  async function markJobs(jobCoordinates) {
    const { Marker } = (await google.maps.importLibrary(
      'marker',
    )) as google.maps.MarkerLibrary;
    for (const jobCoordinate of jobCoordinates) {
      const marker = new Marker({
        position: jobCoordinate,
        map: maps[id].map,
        icon: {
          url: getMapMarker(jobCoordinate),
        },
        zIndex: jobCoordinate.isActive ? 1 : 0,
      });

      marker.addListener('click', () => {
        if (jobCoordinate.jobId) {
          Analytics.trackEvent(constants.EVENT_VIEW_JOB, {
            source: 'map-click',
            jobId: jobCoordinate.jobId,
          });
          navigate(`/jobs/${jobCoordinate.jobId}`);
        }
      });
      maps[id].markers.push(marker);
    }
  }

  async function renderMapWithSearch(location) {
    const input = document.getElementById('search-input') as HTMLInputElement;

    const { SearchBox } = (await google.maps.importLibrary(
      'places',
    )) as google.maps.PlacesLibrary;
    const searchBox = new SearchBox(input);
    const { LatLngBounds } = (await google.maps.importLibrary(
      'core',
    )) as google.maps.CoreLibrary;
    const bounds = new LatLngBounds();

    if (location) {
      bounds.extend(location);
    }

    const map = await renderMap({
      center: location ? bounds.getCenter() : null,
      zoom: location ? 14 : null,
    });

    if (map) {
      map.addListener('bounds_changed', () => {
        searchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
      });

      map.addListener('click', (event) => {
        const currentZoom = map.getZoom();
        onPlaceSelection(event.latLng);
        bounds.extend(event.latLng);
        map.fitBounds(bounds);
        if (currentZoom) {
          map.setZoom(currentZoom);
        }
        setLocationMark(event.latLng);
      });
    }

    searchBox.addListener('places_changed', () => {
      const place = searchBox?.getPlaces()?.[0];
      if (place) {
        if (place?.geometry?.location) {
          onPlaceSelection(place?.geometry?.location);
          bounds.extend(place?.geometry?.location);
        }
        textChange(place?.formatted_address);
        map?.fitBounds(bounds);
        map?.setZoom(10);
        setLocationMark(place?.geometry?.location);
        Analytics.trackEvent(constants.EVENT_MAP_INTERACTION, {
          pageSource,
          type: MapInteractionType.PLACE_SEARCH,
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        });
      }
    });

    return map;
  }

  async function setLocationMark(location) {
    if (isDisabled) {
      return;
    }
    if (location) {
      if (maps[id]?.markers?.length) {
        maps[id].markers.forEach((marker) => {
          marker.setMap(null);
        });
        maps[id].markers = [];
      }

      const { Marker } = (await google.maps.importLibrary(
        'marker',
      )) as google.maps.MarkerLibrary;
      const marker = new Marker({
        position: location,
        map: maps[id].map,
        icon: {
          url: MarkerBlack,
        },
        zIndex: 1,
      });
      maps[id].markers.push(marker);
      maps[id].map.setCenter(location);
    }
  }

  function textChange(newValue) {
    if (isDisabled) {
      return;
    }
    setValue(newValue);
    onDescriptionChange(newValue);
  }

  useEffect(() => {
    (async () => {
      if (googleMapsLoaded) {
        if (coordinates && coordinates.length > 0 && !isSearchable) {
          let jobCoordinates: Coordinate | null = null;
          if (activeJob && mapCanLoad && !isSatellite) {
            jobCoordinates =
              coordinates.find((item) => item.jobId === activeJob.id) ?? null;
          }
          maps[id].map = jobCoordinates
            ? await renderMapFromJobCoordinate(jobCoordinates)
            : await renderMapWithMarks(coordinates);
          markJobs(coordinates);
        } else if (isSearchable) {
          maps[id].map = await renderMapWithSearch(locationCoordinates);
          setLocationMark(locationCoordinates);
        } else {
          maps[id].map = await renderMap({});
        }

        if (maps[id].map) {
          const panorama = maps[id].map.getStreetView();

          panorama.addListener('visible_changed', () => {
            Analytics.trackEvent(
              panorama.getVisible()
                ? constants.EVENT_MAP_ENTERING_STREET_VIEW
                : constants.EVENT_MAP_LEAVING_STREET_VIEW,
              { jobId: activeJob?.id },
            );
          });
        }
      } else {
        dispatch(setGlobalError(getText('command_centre_google_maps_error')));
        setIsError(true);
      }
    })();
  }, [JSON.stringify([id, coordinates])]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isError) {
    return null;
  }
  return (
    <MapWrapper height={height} width={width}>
      <MapContainer id={id} />
      {isSearchable && !isDisabled && googleMapsLoaded && (
        <SearchContainer id='search-wrapper' width={width}>
          <input
            id='search-input'
            type='text'
            placeholder=''
            value={value}
            onChange={(e) => textChange(e.target.value)}
          />
          <SearchIcon />
        </SearchContainer>
      )}
    </MapWrapper>
  );
};

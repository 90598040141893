import styled from 'styled-components';
import theme from 'theme';

const { colors } = theme;

export const Container = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 5px;
  padding-left: 35px;
  &:last-of-type {
    border-bottom: none;
  }
`;

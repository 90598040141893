import React from 'react';
import { Dates } from 'helpers';
import { useText } from 'hooks';
import { Size } from 'model/enum/Size';
import { Span } from './styled';

type Props = {
  date: string;
  size?: Size.SMALL | Size.NORMAL;
  grey?: boolean;
  extraStyles?: React.CSSProperties;
};

export const FormattedDate = ({
  date,
  size = Size.NORMAL,
  grey = false,
  extraStyles,
}: Props): JSX.Element => {
  const getText = useText();
  return (
    <Span $grey={grey} size={size} style={extraStyles}>
      {Dates.formatDateTime(
        date,
        getText('date_today'),
        getText('date_yesterday'),
      )}
    </Span>
  );
};

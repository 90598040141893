import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, media, mixins } = theme;

export const FeedContainer = styled.div`
  background-color: ${colors.offWhite};
  ${mixins.flexColumn};
  flex: 1;
  padding: 10px 15px 0 15px;
  overflow-y: auto;
  ${media.lg`
  padding: 10px 10px 0 10px;
`}
  ${media.sm`
  padding: 10px 15px 0 15px;
`}
`;

export const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 8px 0;
`;

export const LoadMore = styled.div`
  ${mixins.flexFullCenter};
  font-family: ${fonts.bold};
  cursor: pointer;
  color: ${colors.darkGrey};
  padding: 8px;
  &:hover {
    color: ${colors.black};
  }
`;

export const NewMessageWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 8px 0;
  animation-name: FadeAnimation;
  animation-duration: 1.75s;
  animation-delay: 4s;
  animation-fill-mode: forwards;
  @keyframes FadeAnimation {
    0% {
      opacity: 0.9;
      visibility: visible;
    }
    75% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const NewMessage = styled.div`
  padding: 8px;
  margin: 0 16px;
  font-family: ${fonts.bold};
  background-color: ${colors.orange};
  width: 50%;
  display: flex;
  justify-content: center;
  border-radius: 4px;
`;

export const NewMessageLine = styled.div`
  width: 25%;
  position: relative;
  top: 1em;
  border-top: 5px solid ${colors.orange};
`;

import { mapToEvidence } from 'api/Evidence/helpers';
import { mapToReaction } from 'api/Media/helpers';
import { mapToDepot, mapToJob } from 'api/Job/helpers';
import {
  ActivityType,
  CommandCenterActivity,
} from 'model/CommandCenter/Activity';
import { CommandCenterStats } from 'model/CommandCenter/Stats';
import { Assessments } from 'model/Assessment';

export const mapToCommandCenterStats = (
  backendStats: any,
): CommandCenterStats => ({
  jobsCompleted: backendStats?.jobs_completed?.map(mapToJob) ?? [],
  jobsLive: backendStats?.jobs_live?.map(mapToJob) ?? [],
  jobsNotStarted: backendStats?.jobs_not_started?.map(mapToJob) ?? [],
  jobsWithMajorHazards:
    backendStats?.jobs_with_major_hazards?.map(mapToJob) ?? [],
  jobsWithUnresolvedBlockers:
    backendStats?.jobs_with_unresolved_blockers?.map(mapToJob) ?? [],
  jobsWithoutVras: backendStats?.jobs_without_vras?.map(mapToJob) ?? [],
});

export const mapToCommandCenterActivity = (
  backendActivity: any,
): CommandCenterActivity => ({
  id: backendActivity.id,
  type: backendActivity.type,
  jobId: backendActivity.job_id,
  target:
    backendActivity.type === ActivityType.ASSESSMENT
      ? Assessments.mapToAssessment(backendActivity.target)
      : mapToEvidence(backendActivity.target),
  createdAt: new Date(backendActivity.created_at),
  updatedAt: backendActivity?.updated_at
    ? new Date(backendActivity.updated_at)
    : null,
  read: backendActivity.read,
  userReaction: backendActivity?.user_reaction
    ? mapToReaction(backendActivity.reactions)
    : null,
  reactions: backendActivity?.reactions?.map(mapToReaction) ?? [],
  depot: backendActivity.depot ? mapToDepot(backendActivity.depot) : undefined,
});

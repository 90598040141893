import { FormControlLabel, Checkbox as MaterialCheckbox } from '@mui/material';
import { Check } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, mixins } = theme;

export const Container = styled.div`
  ${mixins.flexColumn};
  align-items: center;
`;

export const StyledFormControlLabel = styled(
  ({
    isBold,
    labelColor,
    ...rest
  }: {
    control: JSX.Element;
    isBold: boolean;
    label: string;
    labelColor?: string;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  }) => <FormControlLabel {...rest} />,
)`
  &.MuiFormControlLabel-root {
    margin: 0;
    .MuiFormControlLabel-label {
      padding: 0 10px;
      font-family: ${({ isBold }) => (isBold ? fonts.bold : fonts.default)};
      ${({ labelColor }) => labelColor && `color: ${labelColor}`};
    }
  }
`;

export const StyledCheckBox = styled(
  ({ isLight, isSmall, checked, hasBlackBackground, ...rest }) => (
    <MaterialCheckbox checked={checked} {...rest} />
  ),
)`
  &.MuiCheckbox-root {
    height: 32px;
    width: 32px;
    min-width: 32px;
    ${({ isSmall }) =>
      isSmall &&
      `
        height: 20px;
        width: 20px;
        min-width: 20px;
      `};
    outline: 2px solid
      ${({ hasBlackBackground }) =>
        hasBlackBackground ? colors.white : colors.black};
    border-radius: 0;
    padding: 0;
    color: ${colors.black};
    background-color: ${({ isLight }) =>
      isLight ? colors.white : 'transparent'};

    &:hover {
      outline-color: ${colors.orange};
      background-color: ${({ hasBlackBackground }) =>
        hasBlackBackground ? colors.black : colors.white};
      transition: ease-out 0.2s;
    }

    &.Mui-checked {
      background-color: ${({ checked, isLight }) =>
        checked && !isLight ? colors.black : colors.white};
    }

    &.Mui-disabled {
      outline: 2px solid ${colors.grey};
      background-color: ${({ checked, isLight }) =>
        checked && !isLight ? colors.grey : colors.white};
    }
  }
  .MuiSvgIcon-root {
    font-size: 2.5rem;
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

export const CheckIcon = styled(({ isLight, disabled, ...rest }) => (
  <Check {...rest} />
))`
  .fill-selector {
    fill: ${({ isLight, disabled }) => {
      if (disabled && isLight) {
        return colors.grey;
      }
      return isLight ? colors.black : colors.white;
    }};
  }
`;

import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { Info, Title, TitleContainer, Wrapper } from './styled';
import { useLocales } from 'hooks';
import { Hazard } from 'model/Assessment/Hazard';
import { Control } from 'model/Assessment/Control';
import { TITLE_PROPERTY_NAME } from '../../../../constants';
import { getLocalizedCamelCaseValue } from 'helpers/locales';
import WorkspaceAssessmentItem from 'model/State/WorkspaceAssessmentItem';
import { WorkspaceItemType } from 'model/AdminPanel/WorkspaceSettings/WorkspaceItemType';

type Props = {
  item: Hazard | Control | WorkspaceAssessmentItem | WorkspaceItemType;
  infoMessage?: string;
  disabled?: boolean;
  extraStyles?: React.CSSProperties;
};

const ItemTitle = ({
  item,
  infoMessage,
  disabled,
  extraStyles,
}: Props): JSX.Element => {
  const { availableLocales } = useLocales();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  return (
    <Wrapper>
      <TitleContainer
        $swapOrder={globalAppLanguage !== availableLocales[0]}
        style={extraStyles}
      >
        {availableLocales.map((locale) => (
          <Title key={locale} disabled={disabled}>
            {getLocalizedCamelCaseValue(item, TITLE_PROPERTY_NAME, locale) ||
              ''}
          </Title>
        ))}
      </TitleContainer>
      {infoMessage && <Info>{infoMessage}</Info>}
    </Wrapper>
  );
};

export default ItemTitle;

import { Button } from '@mui/material';
import { Sparkle } from 'assets/icons';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins } = theme;

export const Container = styled.div`
  background-color: ${colors.lightBlue};
  padding: 16px;
  margin-top: 25px;
`;

export const TitleContainer = styled.div`
  ${mixins.flexCenter};
`;

export const Title = styled.p`
  color: ${colors.blue};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
`;

export const SparkleIcon = styled(Sparkle)`
  height: 30px;
  width: auto;
  padding-right: 10px;
`;

export const DetailsContainer = styled.div`
  ${mixins.flexColumn};
  padding: 16px 16px 0 16px;
`;

export const FeedbackContainer = styled.div`
  ${mixins.flexCenter};
  padding: 0 16px;
`;

export const FeedbackTitle = styled.div`
  font-family: ${fonts.bold};
  padding-right: 15px;
`;

export const FeedbackButton = styled(({ selected, disabled, ...rest }) => (
  <Button disabled={disabled} {...rest} />
))`
  &.MuiButtonBase-root {
    min-width: 0;
    width: 32px;
    height: 32px;
    background-color: ${colors.white};
    margin-right: 10px;
    border-radius: 4px;
    padding: 0;
    &:hover {
      background-color: ${colors.lightGrey};
    }
    ${({ selected }) =>
      selected &&
      `
        background-color: ${colors.blue};
        svg {
          .fill-selector {
            fill: ${colors.white};
          }
        }
        &:hover {
          background-color: ${colors.blue};
        }
    `};
    &:active {
      background-color: ${colors.blue};
      svg {
        .fill-selector {
          fill: ${colors.white};
        }
      }
    }
  }
`;

export const ThumbsIcon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  .fill-selector {
    fill: ${colors.blue};
  }
`;

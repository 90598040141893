import styled from 'styled-components';
import { useJobs, useText } from 'hooks';
import { Modal as StyledModal } from 'components/ui';
import Show from './Show';
import { useParams } from 'react-router-dom';

// Styled Components
const Container = styled.div`
  min-height: 210px;
`;

// Note.Modal
const Modal = () => {
  const { jobId } = useParams();
  const getText = useText();
  const { navigateToOverviewTabOrJob } = useJobs();

  return (
    <StyledModal.Base
      isOpen
      title={getText('note_detail_title')}
      onClose={() => navigateToOverviewTabOrJob(`/jobs/${jobId}/logs`)}
      width='425px'
    >
      <Container>
        <Show />
      </Container>
    </StyledModal.Base>
  );
};

export default Modal;

import axios from 'axios';
import { formatDateTimeWithTimezone } from 'helpers/dates';
import { Form, Forms } from 'model/Form';

export const getAll = async (jobId: string): Promise<Form[]> => {
  const response = await axios.get(`/jobs/${jobId}/permit/`, {
    headers: {
      Accept: 'application/json; version=2;',
    },
  });
  return response.data.results.map(Forms.mapToCustomForm);
};

export const get = async (id: string): Promise<Form> => {
  const { data } = await axios.get(`/permit/${id}/`);
  return Forms.mapToCustomForm(data);
};

export const approve = async (
  permitId: number,
  isApproved: boolean,
  rejectionNote: string | null = null,
  expiryDate: Date | null = null,
): Promise<Form> => {
  if (permitId) {
    const { data } = await axios.post(`/permit/${permitId}/approve/`, {
      is_approved: isApproved,
      sent_at: formatDateTimeWithTimezone(new Date()),
      ...(rejectionNote && { rejection_note: rejectionNote }),
      ...(expiryDate && { expiry_date: expiryDate }),
    });
    return Forms.mapToCustomForm(data);
  } else {
    throw new Error('Missing permit id');
  }
};

export const getPdfExportId = async (formId: string): Promise<string> => {
  const { data } = await axios.post(`/permit/${formId}/pdf/`);
  return data.object_id;
};

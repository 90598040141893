import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useEffect, useState } from 'react';
import { Loader } from 'components/ui';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalError } from 'store/slices/notifications';
import RootState from 'model/State/RootState';
import HavsSignOffData from 'model/Insight/HavsSignOffData';
import { formatDate } from 'helpers/dates';
import { signOffHavsCompliance } from 'store/actions/insights';
import {
  CheckIcon,
  LastSignedOffContainer,
  SignOffContainer,
  StyledCheckBox,
  StyledCheckboxLabel,
} from './styled';
import { HavsComplianceData } from 'model/Insight/HavsComplianceData';
import { useText } from 'hooks';

type Props = {
  rowData: HavsComplianceData;
  startDate: Date;
  endDate: Date;
};

// HavsSignOff
const HavsSignOff = ({ rowData, startDate, endDate }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const getText = useText();
  const { currentUser } = useSelector((state: RootState) => state.session);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [signedOffList, setSignedOffList] = useState<HavsSignOffData[]>(
    rowData.signed_off || [],
  );

  useEffect(() => {
    setSignedOffList(rowData.signed_off || []);
  }, [rowData]);

  const onCheckboxClick = async () => {
    if (rowData.requiring_sign_off) {
      setIsLoading(true);
      try {
        dispatch(
          signOffHavsCompliance(
            startDate,
            endDate,
            rowData.user_id,
            currentUser.id,
          ),
        );
        Analytics.trackEvent(constants.EVENT_HAVS_COMPLIANCE_SIGNED_OFF, {
          userId: rowData.user_id,
          start_date: startDate,
          end_date: endDate,
          managerId: currentUser?.id,
        });
      } catch (err) {
        dispatch(setGlobalError(err));
      }
      setIsLoading(false);
    }
  };

  const lastSignOff: HavsSignOffData | null = signedOffList?.[0] || null;

  return (
    <SignOffContainer>
      {isLoading ? (
        <Loader size={16} />
      ) : (
        <>
          <StyledCheckboxLabel
            control={
              <StyledCheckBox
                checked={!rowData.requiring_sign_off}
                disabled={!rowData.requiring_sign_off}
                onChange={onCheckboxClick}
                checkedIcon={<CheckIcon />}
              />
            }
            label={
              rowData.requiring_sign_off &&
              getText('insights_havs_sign_off_cta')
            }
          />
          {!rowData.requiring_sign_off && lastSignOff && (
            <LastSignedOffContainer>
              <div>{lastSignOff.full_name}</div>
              <div>{formatDate(lastSignOff.created_at)}</div>
            </LastSignedOffContainer>
          )}
        </>
      )}
    </SignOffContainer>
  );
};

export default HavsSignOff;

import styled from 'styled-components';
import {
  Drawer as MaterialDrawer,
  Select as MaterialSelect,
  MenuItem,
} from '@mui/material';
import { Arrow, Exit, Hamburger, ChevronDown } from 'assets/icons';
import { FyldLogo, FyldLogoFull } from 'assets/images';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const Container = styled.div`
  height: 65px;
  background-color: ${colors.black};
  padding: 0 40px;
  ${mixins.flexBetween};
  ${media.sm`
    padding: 0 15px;
    position: sticky;
  `}
`;

export const Title = styled.h1`
  font-size: ${fontSizes.l};
  color: ${colors.yellow};
`;

export const HamburgerWrapper = styled.div`
  height: 100%;
  border-left: 2px solid ${colors.white};
  padding-left: 20px;
  ${media.sm`
    padding-left: 0;
  `}
  ${mixins.flexCenter}
`;

export const HamburgerIcon = styled(Hamburger)`
  height: 20px;
  width: 20px;
  cursor: pointer;
  display: block;
  margin: auto;
  .fill-selector {
    fill: ${colors.yellow};
  }
`;

export const Drawer = styled(({ offsetHeight, ...props }) => (
  <MaterialDrawer {...props} />
))`
  .MuiDrawer-paper {
    background-color: ${colors.black};
    height: ${({ offsetHeight }) =>
      `calc(100vh - 65px - ${offsetHeight || 0}px)`} !important;
    width: 360px !important;
    bottom: 0;
    top: auto;
    ${media.sm`
      width: 100vw !important;
    `}
  }
`;

export const DrawerItem = styled.div`
  height: 65px;
  border-bottom: 2px solid ${colors.darkGrey};
  padding: 0 40px;
  cursor: pointer;
  ${mixins.flexBetween};
  ${media.sm`
    padding: 0 15px;
  `}
`;

export const FirstDrawerItem = styled.div`
  height: 65px;
  border-top: 2px solid ${colors.darkGrey};
  border-bottom: 2px solid ${colors.darkGrey};
  padding: 0 40px;
  cursor: pointer;
  ${mixins.flexBetween};
  ${media.sm`
  padding: 0 15px;
`}
`;
export const BottomDrawerItem = styled.div`
  height: 65px;
  border-top: 2px solid ${colors.darkGrey};
  margin-top: auto;
  padding: 0 40px;
  cursor: pointer;
  ${mixins.flexBetween};
  ${media.sm`
    padding: 0 15px;
  `}
`;

export const DrawerText = styled.p`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  color: ${colors.white};
`;

export const ExitIcon = styled(Exit)`
  height: 22px;
  width: 22px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const Header = styled.div`
  ${mixins.flexBetween};
`;

export const LogoContainer = styled.div`
  ${mixins.flexCenter};
  height: inherit;
`;

export const Logo = styled(({ mobile, ...props }) =>
  mobile ? <FyldLogo {...props} /> : <FyldLogoFull {...props} />,
)`
  height: 28px;
  cursor: pointer;
`;

export const ArrowIcon = styled(Arrow)`
  transform: rotate(180deg);
  height: 25px;
  width: 25px;
  cursor: pointer;
  .fill-selector {
    fill: ${colors.yellow};
  }
`;

export const IconWrapper = styled.div`
  z-index: 1;
  width: 35px;
  height: 25px;
  cursor: pointer;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const RightArrowIcon = styled(Arrow)`
  height: 22px;
  width: 22px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const LastRefreshWrapper = styled.div`
  display: flex;
  font-size: ${fontSizes.s};
  font-family: ${fonts.light};
  color: ${colors.white};
  margin-right: 20px;
  ${media.sm`
    flex-direction: column;
    text-align: right;
    margin-right: 10px;
  `}
`;

export const OrganisationLogoWrapper = styled.div`
  display: flex;
  margin-left: 24px;
  border-left: 1px solid ${colors.darkGrey};
  ${media.sm`
    flex-direction: column;
    margin-left: 8px;
  `}
`;

export const OrganisationLogo = styled.img`
  height: 40px;
  margin-left: 24px;
  ${media.sm`
    height: 32px;
    margin-left: 8px;
  `}
`;

export const LastUpdated = styled.div`
  font-family: ${fonts.bold};
  margin-right: 6px;
`;

export const StyledSelect = styled(({ open, ...rest }) => (
  <MaterialSelect {...rest} />
))`
  &.MuiInputBase-root {
    width: 250px;
    font-family: ${fonts.bold};
    color: ${({ open }) => (open ? colors.black : colors.white)};
    background-color: ${({ open }) => (open ? colors.orange : colors.black)};
    border: 1px solid ${colors.white};
    border-right: 0;
    border-width: 0 1px 0 1px;
    border-radius: 0;
    ${media.sm`
      border-width: 1px 0 1px 0;
      width: 100%;
    `}
    &:hover {
      box-shadow: none;
      background-color: ${colors.black};
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
`;

export const StyledOptions = styled(({ ...rest }) => <MenuItem {...rest} />)`
  &.MuiMenuItem-root {
    background-color: ${colors.black};
    color: ${colors.white};
    font-family: ${fonts.bold};
    white-space: break-spaces;
    height: 65px;
    padding: 0 15px;
    align-items: center;
    font-size: ${fontSizes.default};
    .arrow .fill-selector {
      fill: ${colors.white};
    }
    &:hover,
    &.Mui-selected {
      background-color: ${colors.orange} !important;
      color: ${colors.black};
      .arrow .fill-selector {
        fill: ${colors.black};
      }
    }
  }
`;

export const StyledArrow = styled(Arrow)`
  width: 20px;
  position: absolute;
  right: 15px;
  .fill-selector {
    fill: ${({ selected }) => (selected ? colors.black : colors.white)};
  }
`;

export const StyledChevron = styled(ChevronDown)`
  cursor: pointer;
  width: 15px;
  position: absolute;
  right: 10px;
  pointer-events: none;
  .fill-selector {
    fill: ${({ open }) => (open ? colors.black : colors.white)};
  }
`;

import { AllAppliedContainer, GreenCheckIcon } from './styled';

type Props = {
  text: string;
};

export const AllItemsAppliedTag = ({ text }: Props): JSX.Element => (
  <AllAppliedContainer>
    <GreenCheckIcon />
    {text}
  </AllAppliedContainer>
);

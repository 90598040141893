import { useEffect, useState } from 'react';
import { StyledSlider, Title } from './styled';

type Props = {
  disabled?: boolean;
  onChange: (newValue: string) => void;
  value: number;
  steps: Array<{
    id: number;
    value: number;
    color: string;
    title: string;
  }>;
  title?: string;
};

// Display.Slider
export const Slider = ({
  disabled = false,
  onChange,
  value = 0,
  steps,
  title,
}: Props) => {
  const getColor = (selectedValue: number) =>
    steps.find((step) => step.value === selectedValue)?.color || null;

  const [color, setColor] = useState<string | null>(getColor(value));

  useEffect(() => {
    setColor(getColor(value));
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    const newValue = event.target.value;
    onChange(newValue);
    setColor(getColor(newValue));
  };

  return (
    <>
      {title && <Title>{title}</Title>}
      <StyledSlider
        value={value}
        onChange={handleChange}
        step={1}
        min={0}
        max={5}
        color={color}
        disabled={disabled}
      />
    </>
  );
};

import { useSelector } from 'react-redux';
import { selectWorkspaceHasSettingEnabled } from 'store/selectors';
import {
  InfoIcon,
  LabelContainer,
  QualityInfoContainer,
  TickIcon,
  Title,
  WarningIcon,
} from './styled';
import { Tooltip } from '../Display';
import {
  QualityIndicatorType,
  SiteVisibilityValue,
  SoundCaptureValue,
  Video,
  VideoLengthValue,
} from 'model/Media/Video';
import { useText } from 'hooks';
import { QualityInfo } from './QualityInfo';
import { useMemo } from 'react';
import { QualityInfo as QualityInfoType } from './constants';

type Props = {
  video: Video;
  length: number;
};

export const QualityWarningLabel = ({
  video,
  length,
}: Props): JSX.Element | null => {
  const getText = useText();
  const isVideoQualityEnabled = useSelector(
    selectWorkspaceHasSettingEnabled('video_quality'),
  );

  const hasSiteVisibilityWarning =
    video?.qualityIndicator?.siteVisibility !== SiteVisibilityValue.OK;
  const hasSoundCaptureWarning =
    (video?.qualityIndicator?.soundCapture &&
      video.qualityIndicator.soundCapture !== SoundCaptureValue.OK) ??
    false;
  const hasVideoLengthWarning =
    (video?.qualityIndicator?.videoLength &&
      video.qualityIndicator.videoLength !== VideoLengthValue.OK) ??
    false;

  const hasWarning: boolean =
    hasSiteVisibilityWarning || hasSoundCaptureWarning || hasVideoLengthWarning;

  const okString = getText('ok').toUpperCase();

  const labelTitle = useMemo(() => {
    if (hasSiteVisibilityWarning) {
      return getText('video_quality_indicator_site_visibility');
    }
    if (hasSoundCaptureWarning) {
      return getText('video_quality_indicator_sound_capture');
    }
    if (hasVideoLengthWarning) {
      return getText('video_quality_indicator_video_length');
    }
    return getText('video_quality_indicator_ok');
  }, [
    getText,
    hasSiteVisibilityWarning,
    hasSoundCaptureWarning,
    hasVideoLengthWarning,
  ]);

  const qualityInfo: QualityInfoType[] | any[] = video?.qualityIndicator
    ? Object.entries(video?.qualityIndicator).map(([key, value]) => {
        if (value) {
          switch (key) {
            case QualityIndicatorType.SITE_VISIBILITY: {
              let valueString = okString;
              if (value === SiteVisibilityValue.DARK) {
                valueString = getText(
                  'video_quality_indicator_site_visibility_darkness',
                );
              } else if (value === SiteVisibilityValue.STILL) {
                valueString = getText(
                  'video_quality_indicator_site_visibility_coverage',
                );
              }
              return {
                title: getText('video_quality_indicator_site_visibility'),
                value: valueString,
                hasWarning: hasSiteVisibilityWarning,
              };
            }
            case QualityIndicatorType.SOUND_CAPTURE: {
              let valueString = okString;
              if (value === SoundCaptureValue.LIMITED_SPEECH) {
                valueString = getText(
                  'video_quality_indicator_sound_capture_limited_speech',
                );
              } else if (value === SoundCaptureValue.NO_SPEECH) {
                valueString = getText(
                  'video_quality_indicator_sound_capture_no_speech',
                );
              } else if (value === SoundCaptureValue.BACKGROUND_NOISE) {
                valueString = getText(
                  'video_quality_indicator_sound_capture_background_noise',
                );
              }
              return {
                title: getText('video_quality_indicator_sound_capture'),
                value: valueString,
                hasWarning: hasSoundCaptureWarning,
              };
            }
            case QualityIndicatorType.VIDEO_LENGTH:
              return {
                title: getText('video_quality_indicator_video_length'),
                value: getText('video_quality_indicator_video_length_seconds', {
                  number: Math.floor(length),
                }),
                hasWarning: hasVideoLengthWarning,
              };
            default:
              return [];
          }
        }
        return [];
      })
    : [];

  const tooltipTitle = (
    <QualityInfoContainer>
      {!!qualityInfo?.length &&
        qualityInfo.map((info, index) => {
          if (info.title) {
            return (
              <QualityInfo
                key={`${info}-${index}`}
                title={info.title}
                value={info.value}
                isWarning={info.hasWarning}
              />
            );
          }
          return null;
        })}
    </QualityInfoContainer>
  );

  const tootlipTriggerElement = (
    <LabelContainer $hasBlackBackground={hasWarning}>
      {hasWarning ? <WarningIcon /> : <TickIcon />}
      <Title>{labelTitle}</Title>
      <InfoIcon />
    </LabelContainer>
  );

  if (!isVideoQualityEnabled || !video?.qualityIndicator) {
    return null;
  }

  return (
    <Tooltip
      title={tooltipTitle}
      triggerElement={tootlipTriggerElement}
      placement='bottom'
      disableTouchListener
      isLight
    />
  );
};

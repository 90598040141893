import { SyntheticEvent } from 'react';
import Tab from 'model/Tab/Tab';
import TabPanel from 'model/Tab/TabPanel';
import { StyledTab, StyledTabPanel, StyledTabs, Wrapper } from './styled';

type Props = {
  value: string;
  onChange: (event: SyntheticEvent<Element, Event>, value: any) => void;
  tabs: Tab[];
  tabPanels: TabPanel[];
  scrollable?: boolean;
};

export const TabView = ({
  value,
  onChange,
  tabs,
  tabPanels,
  scrollable = true,
}: Props): JSX.Element => (
  <Wrapper>
    <StyledTabs
      value={value}
      onChange={onChange}
      variant={scrollable ? 'scrollable' : 'standard'}
      scrollButtons={scrollable}
    >
      {tabs
        .filter((tab) => tab.isShown)
        .map((tab) => (
          <StyledTab
            key={`tab-${tab.value}`}
            label={tab.label}
            value={tab.value}
          />
        ))}
    </StyledTabs>
    {tabPanels.map((tabPanel) => (
      <StyledTabPanel
        role='tabpanel'
        hidden={value !== tabPanel.value}
        key={`panel-${tabPanel.value}`}
      >
        {value === tabPanel.value && <>{tabPanel.children}</>}
      </StyledTabPanel>
    ))}
  </Wrapper>
);

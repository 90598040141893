import { mapToUser } from 'api/User/helpers';
import {
  InsightJobBlockerStat,
  LogDetail,
} from 'model/Insight/InsightJobBlockerStat';
import { JobBlocker } from 'model/Log/JobBlocker';
import { JobBlockerSubtype } from 'model/Log/JobBlockerSubtype';

export const stringifyUserIds = (userIds: number[]): string | null =>
  userIds?.length ? userIds.join(',') : null;

export const mapToJobBlocker = (backendJobBlocker: any): JobBlocker => ({
  id: backendJobBlocker.id,
  subtypes: backendJobBlocker.subtypes
    ? backendJobBlocker.subtypes.map(mapToJobBlockerSubtype)
    : [],
  title: backendJobBlocker.title,
  titleEs: backendJobBlocker.title_es ?? '',
});

export const mapToJobBlockerSubtype = (
  backendJobBlockerSubtype: any,
): JobBlockerSubtype => ({
  id: backendJobBlockerSubtype.id,
  title: backendJobBlockerSubtype.title,
  titleEs: backendJobBlockerSubtype.title_es ?? '',
});

export const mapToLogDetails = (backendStats: any): LogDetail => ({
  jobId: backendStats.job_id ?? backendStats.id,
  jobNumber: backendStats.job_number,
  location: backendStats.location ?? '',
  managers: backendStats.managers ? backendStats.managers.map(mapToUser) : [],
  fieldworkers: backendStats.fieldworkers
    ? backendStats.fieldworkers.map(mapToUser)
    : [],
  type: backendStats.type,
  typeEs: backendStats.type_es,
  timeBlocked: backendStats.time_blocked,
});

export const mapToJobBlockerStat = (
  backendStat: any,
): InsightJobBlockerStat => ({
  blocker: mapToJobBlocker(backendStat.blocker),
  blockerSubtype: backendStat.blocker_subtype
    ? mapToJobBlockerSubtype(backendStat.blocker_subtype)
    : null,
  combinedTitle: backendStat.combined_title ?? '',
  combinedTitleEs: backendStat.combined_title_es ?? '',
  timeLostPreviousPeriod: backendStat.time_lost_previous_period ?? 0,
  timeLostCurrentPeriod: backendStat.time_lost_current_period ?? 0,
  logDetails: backendStat.log_details
    ? backendStat.log_details
        .map(mapToLogDetails)
        .sort((item1: LogDetail, item2: LogDetail) => {
          if (item1?.timeBlocked && item2?.timeBlocked) {
            return item2?.timeBlocked - item1?.timeBlocked;
          }
          return 0;
        })
    : [],
});

import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
import { Field } from 'model/Forms/Field';
import { WidgetKind } from 'utils/constants';

const { fonts, fontSizes } = theme;

// Styled components
const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0;
`;

const DetailTitle = styled.span`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
  padding-right: 8px;
`;

const DetailText = styled.span`
  font-size: ${fontSizes.default};
`;

const UserSelector = ({ field }: { field: Field }): JSX.Element => {
  const getText = useText();
  const isMulti = field.kind === WidgetKind.MULTI_USER_SELECTOR;
  const value = isMulti
    ? field.value
        ?.map(
          (user) =>
            `${user.first_name || user.firstName} ${
              user.last_name || user.lastName
            }`,
        )
        .join(', ')
    : `${field.value?.first_name || field.value?.firstName} ${
        field.value?.last_name || field.value?.lastName
      }`;

  return (
    <DetailContainer>
      <DetailTitle>{field.title}</DetailTitle>
      <DetailText>
        {field.value ? value : getText('job_forms_not_reported')}
      </DetailText>
    </DetailContainer>
  );
};

export default UserSelector;

import styled from 'styled-components';
import theme from 'theme';
import { Button as MaterialButton } from '@mui/material';

const { fonts, colors, fontSizes, media } = theme;

export const TagsContainer = styled.div<{ $hasTooltip?: boolean }>`
  width: 100%;
  padding-right: 5%;
  ${media.md`
    padding-right: 2.5%;
  `}
  display: flex;

  flex-direction: column;
  ${({ $hasTooltip }) =>
    $hasTooltip &&
    `
      flex-direction: row;
      align-items: center;
    `};
`;

export const Tag = styled.div`
  min-width: 78%;
  max-width: 78%;
  margin-right: 2%;
  ${media.xl`
    min-width: 68%;
    max-width: 68%;
  `}
  ${media.lg`
    min-width: 58%;
    max-width: 58%;
  `}
`;

export const SeeMoreWrapper = styled.span`
  width: 20%;

  ${media.xl`
    min-width: 30%;
    max-width: 30%;
  `}
  ${media.lg`
    min-width: 40%;
    padding: 1px 3px;
  `}
`;
export const SeeMoreButton = styled((props) => <MaterialButton {...props} />)`
  &.MuiButtonBase-root {
    padding: 3px 5px;
    margin-top: 7px;
    font-family: ${fonts.bold};
    border-radius: 0px;
    border: 1px solid ${colors.darkGrey};
    color: ${colors.black};
    text-transform: none;
    font-size: ${fontSizes.xs};
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export const TagWrapper = styled.div`
  width: 100%;
  margin: 5px 0;
  > div {
    margin: 0 !important;
    width: auto;
    font-size: ${fontSizes.s};
    font-family: ${fonts.bold};
    flex-grow: 1;
  }
`;

import { useIsMobile, useText } from 'hooks';
import { Card, Button, Input } from 'components/ui';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectJobsView } from 'store/selectors';
import {
  Cta,
  DraggerContent,
  ErrorContainer,
  Hint,
  HintContainer,
  Info,
  SmallCross,
  StyledAttachment,
  StyledDraggable,
  UploaderWrapper,
  UploadList,
  UploadListItem,
} from './styled';

type Props = {
  acceptType: string;
  onChange: (value: File[]) => void;
  removeFileItem: (file: File) => void;
  trackEvent: string;
  eventProperties?: object;
  jobId?: string;
  title?: string;
  error?: string | null;
  fileList?: File[];
  comments?: string;
  setComments?: (value: string) => void;
  onSubmit?: () => void;
  selectedMediaType?: string | null;
  isLoading?: boolean;
  isSettings?: boolean;
  multiple?: boolean;
};

const UploadContent = ({
  acceptType,
  jobId,
  error = null,
  fileList,
  onChange,
  comments,
  setComments = () => {},
  removeFileItem,
  selectedMediaType = null,
  trackEvent,
  eventProperties,
  onSubmit = () => {},
  isLoading,
  title,
  isSettings,
  multiple = true,
}: Props): JSX.Element => {
  const isMobile = useIsMobile();
  const getText = useText();
  const [dragActive, setDragActive] = useState<boolean>(false);
  const isCsvUpload = selectedMediaType === 'csv';
  const view = useSelector(selectJobsView);

  const getHints = () => {
    if (selectedMediaType === 'photo') {
      return (
        <HintContainer>
          <Hint>{getText('photo_supported_file_types')}</Hint>
          <Hint>{getText('photo_size_limit')}</Hint>
          <Hint>{getText('photo_max_files')}</Hint>
        </HintContainer>
      );
    }
    if (selectedMediaType === 'video') {
      return (
        <HintContainer>
          <Hint>{getText('video_supported_file_types')}</Hint>
          <Hint>{getText('video_size_limit')}</Hint>
          <Hint>{getText('video_max_files')}</Hint>
        </HintContainer>
      );
    }
    if (selectedMediaType === 'csv') {
      return (
        <HintContainer>
          <Hint $isLarge>{getText('csv_supported_file_types')}</Hint>
          <Hint $isLarge>{getText('csv_size_limit')}</Hint>
          <Hint $isLarge>{getText('csv_max_files')}</Hint>
        </HintContainer>
      );
    }
    return (
      <HintContainer>
        <Hint>{getText('default_supported_file_types')}</Hint>
        <Hint>{getText('default_size_limit')}</Hint>
        <Hint>{getText('default_max_files')}</Hint>
      </HintContainer>
    );
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e?.dataTransfer?.files?.[0]) {
      onChange([...e.dataTransfer.files]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e?.target?.files?.[0]) {
      onChange([...e.target.files]);
    }
  };

  return (
    <UploaderWrapper>
      <>
        {error && (
          <ErrorContainer>
            <Card.ErrorMessage message={error} />
          </ErrorContainer>
        )}
        <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <DraggerContent component='label' disableRipple>
            <Info>
              <Cta $isCsvUpload={isCsvUpload}>{getText('upload_file')}</Cta>
              <StyledAttachment $isCsvUpload={isCsvUpload} />
            </Info>
            {getHints()}
            <input
              hidden
              accept={acceptType}
              multiple={multiple}
              type='file'
              onChange={handleChange}
              disabled={fileList?.length !== undefined && fileList.length >= 6}
            />
          </DraggerContent>
          {dragActive && (
            <StyledDraggable
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )}
        </form>
        {fileList && (
          <UploadList>
            {fileList.map((item) => (
              <UploadListItem key={`${item.name}-${item.lastModified}`}>
                {item.name}
                <SmallCross onClick={() => removeFileItem(item)} />
              </UploadListItem>
            ))}
          </UploadList>
        )}
        {selectedMediaType && !isSettings && (
          <Input.TextArea
            id='comments-add-evidence'
            label={getText('upload_comments')}
            optional
            extraStyles={
              isMobile
                ? { marginBottom: '64px', marginTop: '32px' }
                : { marginBottom: '16px', marginTop: '32px' }
            }
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        )}
        {!isSettings && (
          <Button.Primary
            onClick={() => {
              Analytics.trackEvent(
                trackEvent,
                eventProperties
                  ? { ...eventProperties, jobId, type: selectedMediaType }
                  : {
                      jobId,
                      type: selectedMediaType,
                    },
              );
              onSubmit();
            }}
            loading={isLoading}
            text={title}
            width='240px'
            extraStyles={
              isMobile
                ? { position: 'fixed', bottom: '0', left: '0' }
                : { marginLeft: 'auto' }
            }
            event={constants.FILE_UPLOAD_SUBMIT_CLICKED}
            eventProperties={{
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
          />
        )}
      </>
    </UploaderWrapper>
  );
};

export default UploadContent;

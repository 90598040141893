import { Sparkle } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { breakpoints, colors, fonts, mixins, media } = theme;

export const ContentWrapper = styled.div`
  flex: 1;
  ${mixins.flexColumn}
  height: calc(100% - 60px);
  max-width: 100%;
  max-height: calc(100% - 60px);
`;

export const Header = styled.div`
  height: 70px;
  background-color: ${colors.offWhite};
  border-bottom: 1px solid ${colors.lightGrey};
  ${mixins.flexBetween};
  padding: 10px 25px;
  ${media.sm`
    padding: 0 15px;
  `};
`;

export const Container = styled.div`
  background-color: ${colors.white};
  flex: 1;
  ${mixins.flexColumn}
  @media only screen and (min-width: ${breakpoints.sm}px) {
    overflow: auto;
  }
`;

export const Section = styled.div`
  padding: 20px 25px;
  border-bottom: 15px solid ${colors.offWhite};
  &:last-of-type {
    border: none;
  }
  ${media.sm`
    padding: 20px 15px;
  `};
`;

export const BoldText = styled.span`
  font-family: ${fonts.bold};
`;

export const ComputerVisionContainer = styled.div`
  background-color: ${colors.lightBlue};
  color: ${colors.blue};
  font-family: ${fonts.bold};
  padding: 8px;
  margin: 16px 0;
  ${mixins.flexCenter};
`;

export const SparkleIcon = styled(Sparkle)`
  width: 24px;
  height: auto;
  padding-right: 8px;
`;

export const ErrorContainer = styled.div`
  height: 160px;
  ${mixins.flexFullCenter};
`;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Job } from 'api';

import { JobStatusTitle } from 'model/enum/JobStatusTitle';
import JobStatus from 'model/JobStatus';
import RootState from 'model/State/RootState';

export const useStatusesAndDeletedStatus = (): {
  statuses: JobStatus[];
  deletedStatus: JobStatus | null;
} => {
  const [statuses, setStatuses] = useState<JobStatus[]>([]);
  const [deletedStatus, setDeletedStatus] = useState<JobStatus | null>(null);

  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );

  useEffect(() => {
    (async () => {
      const {
        data: { results },
      } = await Job.getAllStatuses(selectedWorkspace?.uuid);

      setStatuses(results);

      const deleted = results.find(
        (status: JobStatus) => status.title === JobStatusTitle.DELETED,
      );
      setDeletedStatus(deleted);
    })();
  }, [selectedWorkspace?.uuid]);

  return { statuses, deletedStatus };
};

import { useState } from 'react';
import { useOrganisations, useText } from 'hooks';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import Organisation from 'model/Organisation';
import { SSOLoginForm } from './SSOLoginForm';
import { OrganisationEmailForm } from './OrganisationEmailForm';
import { UsernamePasswordForm } from './UsernamePasswordForm';

// Login
export const Login = (): JSX.Element => {
  const showOKTALogin = process.env.REACT_APP_OKTA_LOGIN === 'true';
  const showSSOSetting = process.env.REACT_APP_SSO_LOGIN === 'true';
  const [showSSO, setShowSSO] = useState(showSSOSetting);
  const [email, setEmail] = useState('');
  const [organisation, setOrganisation] = useState<Organisation | null>(null);
  const organisations = useOrganisations();
  const getText = useText();

  const getWhitelistedOrganisation = (email: string) =>
    organisations.find((org: Organisation) =>
      org.email_domain_whitelist.some((domain: string) =>
        email.endsWith(domain),
      ),
    );

  const redirectToSSOIFWhitelisted = (email: string) => {
    const matchingOrganisation = getWhitelistedOrganisation(email);
    setOrganisation(matchingOrganisation || null);
    setShowSSO(!!matchingOrganisation);
    Analytics.trackEvent(constants.EVENT_CHECK_EMAIL_FOR_SSO_REDIRECT, {
      email,
      organisation: matchingOrganisation,
    });
  };

  const redirectToSSO = (email: string) => {
    const matchingOrganisation = getWhitelistedOrganisation(email);
    setOrganisation(matchingOrganisation || null);
    setShowSSO(true);
    Analytics.trackEvent(constants.EVENT_LOGIN_WITH_SSO_CLICKED, {
      email,
      organisation: matchingOrganisation,
    });
  };

  if (!organisations?.length || !showSSO) {
    return (
      <UsernamePasswordForm
        email={email}
        setEmail={setEmail}
        onReturnToSSOClick={redirectToSSO}
        showReturnToSSO={!!organisations?.length && showSSOSetting}
        returnToSSOButtonText={
          showOKTALogin
            ? getText('login_with_okta_button')
            : getText('login_with_sso_button')
        }
      />
    );
  }

  if (showOKTALogin) {
    return (
      <SSOLoginForm
        onLoginWithUserLinkClick={() => setShowSSO(false)}
        organisation={organisations[0]}
        buttonText={getText('login_with_okta_button')}
        loginSource={constants.LoginSource.OKTA}
      />
    );
  }

  if (organisation) {
    return (
      <SSOLoginForm
        onLoginWithUserLinkClick={() => setShowSSO(false)}
        organisation={organisation}
        buttonText={getText('login_with_sso_button')}
        loginSource={constants.LoginSource.SSO}
      />
    );
  }

  return (
    <OrganisationEmailForm
      email={email}
      setEmail={setEmail}
      handleEmailCheck={redirectToSSOIFWhitelisted}
    />
  );
};

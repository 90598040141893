export const EVENT_RAMS_SUMMARY_RATE_HAZARDS_BUTTON_CLICKED =
  'rams-summary-rate-hazards-button-clicked';
export const EVENT_RAMS_SUMMARY_NEXT_BUTTON_CLICKED =
  'rams-summary-next-button-clicked';
export const EVENT_RAMS_SUMMARY_DONE_BUTTON_CLICKED =
  'rams-summary-done-button-clicked';
export const EVENT_RAMS_SUMMARY_FINISH_BUTTON_CLICKED =
  'rams-summary-finish-button-clicked';
export const EVENT_RAMS_BLANK_SECTION_BUTTON_CLICKED =
  'rams-blank-section-button-clicked';
export const EVENT_RAMS_TEMPLATE_SECTION_BUTTON_CLICKED =
  'rams-template-section-button-clicked';
export const EVENT_RAMS_ADD_TEMPLATE_BUTTON_CLICKED =
  'rams-add-template-button-clicked';
export const EVENT_RAMS_SUMMARY_EDIT_BUTTON_CLICKED =
  'rams-summary-edit-button-clicked';
export const EVENT_RAMS_SUMMARY_MENU_BUTTON_CLICKED =
  'rams-summary-return-to-menu-button-clicked';
export const EVENT_RAMS_PHOTO_ADD_BUTTON_CLICKED =
  'rams-photo-add-button-clicked';
export const EVENT_ADD_TEAM_MEMBERS_CLICKED = 'add-team-members-rams';
export const EVENT_ADD_TEAM_MEMBERS_FROM_CONFIRMATION_CLICKED =
  'rams-add-team-members';
export const EVENT_CONFIRM_TEAM_MEMBERS_SAVED = 'confirm-team-members-saved';
export const EVENT_RAMS_REMINDER_SENT = 'rams-reminder-sent';
export const EVENT_DIGITAL_USER_SIGNATURE_REQUESTED =
  'digital-user-signature-requested';
export const EVENT_DIGITAL_SIGNATURE_CLICKED = 'digital-signature-clicked';
export const EVENT_EDIT_RAMS_CLICKED = 'edit-rams-clicked';
export const EVENT_RAMS_SUBMITTED = 'rams-submitted';

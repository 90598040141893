import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { useTeams, useText } from 'hooks';
import { User as UserApi } from 'api';
import { Badge, Button, Card, Input, Loader } from 'components/ui';
import theme from 'theme';
import { useDebounce } from 'use-debounce';
import RootState from 'model/State/RootState';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackendUser } from 'model/BackendUser';
import { ModalType } from 'model/enum/ModalType';
import * as Logger from 'utils/logger';
import { setGlobalError } from 'store/slices/notifications';
import { UserRoleBackend } from 'model/enum/UserRoleBackend';
import { getScreen, hasSameItems } from 'helpers/utils';
import { DeleteBin } from 'assets/icons';
import { ConfirmModal } from 'components/ui/Modal';
import { fetchTeams } from 'store/actions/user';
import { selectJobsView } from 'store/selectors';
import { FyldScreen } from 'model/enum/FyldScreen';

const { colors, fonts, fontSizes, media, mixins } = theme;

// Styled Components
const Form = styled.form`
  max-height: 80vh;
  min-height: 60vh;
  overflow-y: auto;
  ${mixins.flexColumn};
  ${media.sm`
    height: auto;
    flex: 1;
    width: auto;
    margin: 0;
    padding: 0;
  `}
`;

const InfoMessage = styled.div`
  padding: 15px 0;
`;

const SectionTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  padding: 25px 0 10px 0;
`;

const ContentContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

const ContentWrapper = styled.div`
  width: 75%;
  margin: 0 auto;
  padding: 10px 0;
  ${mixins.flexColumn};
`;

const DeleteButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin: 20px 0 10px 0;
`;

const DeleteIcon = styled(DeleteBin)`
  width: 18px;
  height: 18px;
`;

const Users = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const SelectedUsers = styled.div`
  display: inline-block;
  overflow-y: auto;
`;

const UserRow = styled.div`
  ${mixins.flexBetween};
  height: 75px;
  border-top: 2px solid ${colors.lightGrey};
  cursor: pointer;
  padding: 0 10px;
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  cursor: pointer;
  color: ${colors.darkGrey};
  padding: 8px;
  &:hover {
    color: ${colors.black};
  }
`;

const ErrorContainer = styled.div`
  ${mixins.flexCenter};
`;

const ErrorMessages = {
  NAME_ERROR: 'The fields name, manager must make a unique set.',
};

// ManageTeamDetailsModal
const ManageTeamDetailsModal = (): JSX.Element => {
  const { currentUser, selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const switchDepotEnabled =
    currentUser?.permissions?.includes('switch_depot_v1');
  const navigate = useNavigate();
  const location = useLocation();
  const getText = useText();
  const dispatch = useDispatch();
  const view = useSelector(selectJobsView);
  const [search, setSearch] = useState<string>('');
  const [searchFilter] = useDebounce(search, 600);
  const [teamName, setTeamName] = useState<string>(
    location.state?.originalTeam?.name || '',
  );
  const [users, setUsers] = useState<BackendUser[]>([]);
  const [nextLink, setNextLink] = useState<string | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<BackendUser[]>(
    location.state?.originalTeam?.members || [],
  );
  const [formError, setFormError] = useState<string>();
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const screen = getScreen(location?.pathname);
  const { selectedTeam, setSelectedTeamForCurrentUser } = useTeams();
  const isUpdatingExistingTeam = location.state?.originalTeam;

  const getUsers = async (nextLinkUrl?: string) => {
    let data;
    if (nextLinkUrl) {
      const result = await UserApi.getNext(nextLink);
      data = result.data;
      setUsers((currentFilteredUsers) => [
        ...currentFilteredUsers,
        ...data.results,
      ]);
    } else {
      const result = await UserApi.getAll(
        selectedWorkspace?.uuid,
        searchFilter,
        20,
        switchDepotEnabled ? null : currentUser.depot?.id,
        null,
        [
          UserRoleBackend.FIELDWORKER,
          UserRoleBackend.TEAMMATE,
          UserRoleBackend.MANAGER,
        ],
      );
      data = result.data;
      setUsers(data.results);
    }
    setNextLink(data.next);
  };

  useEffect(() => {
    (async () => {
      await getUsers();
    })();
  }, [searchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const userIdIsChecked = (id) =>
    selectedUsers?.map((user) => user.id).includes(id);

  const handleSearch = (newSearch) => {
    setSearch(newSearch);
  };

  const handleToggleSelect = (selectedUser) => {
    if (selectedUsers.map((user) => user.id).includes(selectedUser.id)) {
      setSelectedUsers(
        selectedUsers.filter((user) => user.id !== selectedUser.id),
      );
      Analytics.trackEvent(constants.MANAGE_TEAMS_USER_REMOVED, {
        userId: currentUser?.id,
        ...(isUpdatingExistingTeam && {
          teamId: location.state?.originalTeam?.id,
        }),
        area: screen,
        modifiedUserId: selectedUser?.id,
        actionType: isUpdatingExistingTeam ? 'edit' : 'create',
        ...(screen === FyldScreen.JOBS && {
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        }),
      });
    } else {
      setSelectedUsers((prevUsers) => [...prevUsers, selectedUser]);
      Analytics.trackEvent(constants.MANAGE_TEAMS_USER_ADDED, {
        userId: currentUser?.id,
        ...(isUpdatingExistingTeam && {
          teamId: location.state?.originalTeam?.id,
        }),
        area: screen,
        modifiedUserId: selectedUser?.id,
        actionType: isUpdatingExistingTeam ? 'edit' : 'create',
        ...(screen === FyldScreen.JOBS && {
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        }),
      });
    }
  };

  const handleSubmit = async () => {
    setFormError('');
    if (isUpdatingExistingTeam) {
      await UserApi.editTeam(
        location.state?.originalTeam?.id,
        currentUser?.id,
        selectedUsers.map((member) => member.id),
        teamName,
      )
        .then((result) => {
          setSelectedTeamForCurrentUser(result?.id);
          returnToPreviousModal(result);
        })
        .catch((err) => {
          handleError(err);
        });
    } else {
      await UserApi.saveTeam(
        currentUser?.id,
        selectedUsers.map((member) => member.id),
        teamName,
      )
        .then((result) => {
          returnToPreviousModal(result);
        })
        .catch((err) => {
          handleError(err);
        });
    }
    Analytics.trackEvent(
      isUpdatingExistingTeam
        ? constants.MANAGE_TEAMS_UPDATE_CLICKED
        : constants.MANAGE_TEAMS_CREATE_CLICKED,
      {
        userId: currentUser?.id,
        ...(isUpdatingExistingTeam && {
          teamId: location.state?.originalTeam?.id,
        }),
        area: screen,
        ...(screen === FyldScreen.JOBS && {
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        }),
      },
    );
  };

  const returnToPreviousModal = (savedTeam) => {
    dispatch(fetchTeams());
    navigate(location.pathname + `?modal=${ModalType.TEAMS}`, {
      state: { ...location.state, team: savedTeam },
    });
  };

  const handleError = (error) => {
    Logger.error(error);
    dispatch(setGlobalError(error));
    if (error?.response?.data?.members) {
      setFormError(error?.response?.data?.members);
    }
    if (
      error?.response?.data?.non_field_errors?.includes(
        ErrorMessages.NAME_ERROR,
      )
    ) {
      setFormError(
        getText('manage_teams_modal_unique_name_error', { teamName }),
      );
    }
  };

  const isSaveDisabled = () => {
    const updateConditions =
      location.state?.originalTeam?.name === teamName &&
      hasSameItems(location.state?.originalTeam?.members, selectedUsers);
    const baseCondition = !teamName.trim() || selectedUsers?.length < 1;
    return isUpdatingExistingTeam
      ? baseCondition || updateConditions
      : baseCondition;
  };

  const handleDeleteTeam = async () => {
    try {
      await UserApi.deleteTeam(location.state?.originalTeam?.id);
      if (selectedTeam?.id === location.state?.originalTeam?.id) {
        setSelectedTeamForCurrentUser(null);
      }
      dispatch(fetchTeams());
      navigate(location.pathname + `?modal=${ModalType.TEAMS}`, {
        state: {
          ...location.state,
          team: null,
        },
      });
    } catch (err) {
      Logger.error(err);
      dispatch(setGlobalError(err));
    }
    setDeleteConfirmationOpen(false);
    Analytics.trackEvent(constants.MANAGE_TEAMS_DELETE_CONFIRM_CLICKED, {
      userId: currentUser?.id,
      teamId: location.state?.originalTeam?.id,
      ...(screen === FyldScreen.JOBS && {
        [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      }),
    });
  };

  const filteredAllUsers = users?.filter(
    (user) =>
      !selectedUsers?.find((selectedUser) => selectedUser.id === user.id),
  );

  const filteredSelectedUsers = selectedUsers?.filter((user) =>
    `${user?.first_name} ${user?.last_name}`
      .toLowerCase()
      .includes(search.toLowerCase()),
  );

  return (
    <Form onSubmit={handleSubmit}>
      <ContentContainer>
        <ContentWrapper>
          <InfoMessage>{getText('filter_teams_modal_info')}</InfoMessage>
          <Input.Text
            id='team-name'
            label={getText('filter_teams_modal_team_name')}
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
          />
          {isUpdatingExistingTeam && (
            <DeleteButtonWrapper>
              <Button.Secondary
                text={getText('manage_teams_modal_delete')}
                IconComponent={DeleteIcon}
                onClick={() => setDeleteConfirmationOpen(true)}
                width='170px'
                extraStyles={{
                  height: '35px',
                  fontSize: fontSizes.default,
                  padding: '0 10px',
                }}
                event={constants.MANAGE_TEAMS_DELETE_CLICKED}
                eventProperties={{
                  userId: currentUser?.id,
                  teamId: location.state?.originalTeam?.id,
                  area: screen,
                  ...(screen === FyldScreen.JOBS && {
                    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  }),
                }}
              />
            </DeleteButtonWrapper>
          )}
          <Input.Text
            id='search'
            label={getText('search')}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            extraStyles={{ marginTop: '10px' }}
          />
          {users ? (
            <>
              <SectionTitle>
                {getText('filter_teams_modal_team_members')}
              </SectionTitle>
              <SelectedUsers>
                {filteredSelectedUsers?.map((user) => (
                  <UserRow
                    key={user.id}
                    onClick={() => handleToggleSelect(user)}
                  >
                    <Badge.UserBadge
                      user={user}
                      key={`selected-user-badge-${user.id}`}
                      isCurrentUser={currentUser.id === user.id}
                      large
                    />

                    <Input.Checkbox
                      key={`selected-user-checkbox-${user.id}`}
                      checked={userIdIsChecked(user.id)}
                    />
                  </UserRow>
                ))}
              </SelectedUsers>
              <SectionTitle>
                {getText('filter_teams_modal_all_members')}
              </SectionTitle>
              <Users>
                {filteredAllUsers?.map((user) => (
                  <UserRow
                    key={user.id}
                    onClick={() => handleToggleSelect(user)}
                  >
                    <Badge.UserBadge
                      user={user}
                      key={`badge-${user.id}`}
                      isCurrentUser={currentUser.id === user.id}
                      large
                    />

                    <Input.Checkbox
                      key={`checkbox-${user.id}`}
                      checked={userIdIsChecked(user.id)}
                    />
                  </UserRow>
                ))}
                {nextLink && (
                  <LoadMore onClick={() => getUsers(nextLink)}>
                    {getText('load_more')}
                  </LoadMore>
                )}
              </Users>
            </>
          ) : (
            <Loader />
          )}
        </ContentWrapper>
      </ContentContainer>
      {formError && (
        <ErrorContainer>
          <Card.ErrorMessage message={formError} />
        </ErrorContainer>
      )}
      <Button.Primary
        event={constants.TEAMS_SAVE_DEFAULT}
        eventProperties={{
          managerId: currentUser.id,
          teamMemberIds: selectedUsers.map((member) => member.id),
          ...(screen === FyldScreen.JOBS && {
            [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
          }),
        }}
        text={getText(
          isUpdatingExistingTeam
            ? 'filter_teams_modal_update'
            : 'manage_teams_modal_create',
        )}
        onClick={handleSubmit}
        disabled={isSaveDisabled()}
        extraStyles={{ marginTop: 0, minHeight: '55px' }}
      />
      {isDeleteConfirmationOpen && (
        <ConfirmModal
          warningMessage={getText('manage_teams_modal_delete_confirmation')}
          isVisible={isDeleteConfirmationOpen}
          closeModal={() => setDeleteConfirmationOpen(false)}
          onButtonClick={handleDeleteTeam}
          buttonText={getText('delete')}
          screenEvent={
            constants.EVENT_PAGE_VIEW_MANAGE_TEAMS_DELETE_CONFIRMATION
          }
          screenEventProperties={{
            userId: currentUser?.id,
            teamId: location.state?.originalTeam?.id,
          }}
          cancelEvent={constants.MANAGE_TEAMS_DELETE_CANCEL_CLICKED}
          cancelEventProperties={{
            userId: currentUser?.id,
            teamId: location.state?.originalTeam?.id,
            ...(screen === FyldScreen.JOBS && {
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }),
          }}
        />
      )}
    </Form>
  );
};

export default ManageTeamDetailsModal;

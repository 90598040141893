import { Column, Container, EditWrapper, StickyColumn } from './styled';
import { useEffect, useMemo, useState } from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';
import { DragNDropEditor } from 'components/ui/DragNDropEditor';
import { getLocalizedValue } from 'helpers/locales';
import { DragNDropItem } from 'components/ui/DragNDropEditor/types';
import { useSelector } from 'react-redux';
import {
  selectGlobalLanguageSetting,
  selectHasOrganizationSettingEnabled,
} from 'store/selectors';
import { LanguageSwitchToggle } from '../../../WorkspaceSettings/LanguageSwitchToggle';
import { WorkflowStep } from 'model/Workflows';
import {
  addWorkflowStep,
  deleteStep,
  fetchWorkflowSteps,
  selectActiveWorkflowId,
  selectIsLoadingWorkflowSteps,
  selectWorkflowSteps,
  updateWorkflowStepOrder,
} from 'store/slices/workflows';
import { useDispatch } from 'react-redux';
import { AddEditWorkflowStep } from '../AddEditStep';
import { Header } from './components/Header';
import { Button } from 'components/ui';
import { PlusIcon } from 'assets/icons';
import { v4 } from 'uuid';

export const WorkflowEditor = () => {
  const dispatch = useDispatch();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const canToggleBetweenLanguages = useSelector(
    selectHasOrganizationSettingEnabled('enable_language_selection'),
  );

  const [previewLanguage, setPreviewLanguage] = useState(globalAppLanguage);
  const workflowId = useSelector(selectActiveWorkflowId);
  const steps = useSelector(selectWorkflowSteps(workflowId));

  const isLoading = useSelector(selectIsLoadingWorkflowSteps(workflowId));
  const generateBlankStep = (insertAfterIndex: number = 0): WorkflowStep => {
    return {
      id: v4(),
      title: '',
      titleEs: '',
      insertAfterIndex,
    };
  };
  const [displayNotice, setDisplayNotice] = useState<boolean>(true);
  const [newStep, setNewStep] = useState<WorkflowStep | null>(null);

  useEffect(() => {
    if (workflowId) {
      dispatch(fetchWorkflowSteps(workflowId));
    }
  }, [dispatch, workflowId]);

  const updateStepOrder = (fromIndex: number, toIndex: number) => {
    discardStepChanges();
    if (workflowId) {
      dispatch(updateWorkflowStepOrder({ workflowId, fromIndex, toIndex }));
    }
  };

  const removeStep = (id: UniqueIdentifier) => {
    discardStepChanges();
    if (workflowId) {
      dispatch(deleteStep({ workflowId, stepId: id }));
    }
  };

  const dragNDropItems = useMemo(() => {
    let stepsWithNew = steps;
    if (newStep && newStep.insertAfterIndex !== undefined) {
      stepsWithNew = steps.toSpliced(newStep.insertAfterIndex, 0, newStep);
    }
    return stepsWithNew.map((step: WorkflowStep): DragNDropItem => {
      const stepToDisplay = step;

      return {
        id: stepToDisplay.id,
        title: getLocalizedValue(stepToDisplay, 'title', previewLanguage),
        subtitle:
          stepToDisplay.task && stepToDisplay.taskType
            ? [stepToDisplay.task.title, stepToDisplay.taskType.title]
            : (stepToDisplay.task?.title ?? ''),
      };
    });
  }, [steps, newStep, previewLanguage]);

  const hasUnsavedChanges =
    !!newStep &&
    Object.entries(newStep).filter((pair) => pair[0] !== 'id' && !!pair[1])
      .length > 0;

  const discardStepChanges = () => {
    if (hasUnsavedChanges) {
      alert('you have unsaved changes, are you sure you want to discard them?');
    }
    setNewStep(null);
  };

  const saveStepChanges = () => {
    if (newStep && workflowId) {
      dispatch(addWorkflowStep({ workflowId, step: newStep }));
    }
    setNewStep(null);
  };

  const addNewStep = () => {
    const blankStep = generateBlankStep();
    setNewStep(blankStep);
  };

  return (
    <Container>
      <Header
        displayNotice={displayNotice}
        setDisplayNotice={setDisplayNotice}
      />
      <EditWrapper>
        <StickyColumn>
          {dragNDropItems.length > 0 ? (
            <>
              {canToggleBetweenLanguages && (
                <LanguageSwitchToggle
                  activeLanguageCode={previewLanguage}
                  setActiveLanguageCode={setPreviewLanguage}
                />
              )}
              <DragNDropEditor
                items={dragNDropItems}
                updateItemOrder={updateStepOrder}
                removeItem={removeStep}
                disabled={isLoading}
              />
            </>
          ) : (
            <Button.Secondary
              text='Add new task'
              IconComponent={PlusIcon}
              onClick={addNewStep}
            />
          )}
        </StickyColumn>
        <Column>
          {newStep && (
            <AddEditWorkflowStep
              step={newStep}
              setStep={setNewStep}
              onSave={saveStepChanges}
              onCancel={discardStepChanges}
              disabled={!hasUnsavedChanges}
            />
          )}
        </Column>
      </EditWrapper>
    </Container>
  );
};

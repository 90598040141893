import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { Loader } from 'components/ui';
import theme from 'theme';
import { useText } from 'hooks';
import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import InsightGraphItemData from 'model/Insight/InsightGraphItemData';
import {
  CategoryScale,
  Chart,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import { formatDate, formatDayAndMonth, getDaysBetween } from 'helpers/dates';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
);

const { colors, fonts, fontSizes } = theme;

// Styled Components
const GraphWrapper = styled.div`
  width: 100%;
  border-top: 3px solid ${colors.black};
  background-color: ${colors.white};
`;

const GraphContainer = styled.div`
  padding: 16px;
  height: 475px;
`;

const NoData = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  display: flex;
  width: 100%;
  height: 5%;
  justify-content: center;
  margin-bottom: 8px;
`;

const StyledLine = styled(Line)`
  background-color: ${colors.white};
  height: 95% !important;
`;

type Props = {
  graphData: InsightGraphItemData[];
  depotName: string;
  regionName: string;
  startDate: Date;
  endDate: Date;
  averageValue: number;
  isOutputPerDay?: boolean;
};

// InsightLineGraph
const InsightLineGraph = ({
  graphData,
  depotName,
  regionName,
  startDate,
  endDate,
  averageValue,
  isOutputPerDay,
}: Props): JSX.Element => {
  const getText = useText();
  const [graphDataSet, setGraphDataSet] = useState<ChartData<'line'> | null>();
  const [isNoData, setIsNoData] = useState<boolean>(false);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  const generateGraphData = (results) => {
    const halfPoint = Math.ceil(results.length / 2);
    const lastPeriod = results.slice(0, halfPoint);
    const currentPeriod = results.slice(-halfPoint);
    const avarage = Array.from(
      { length: Math.round(getDaysBetween(startDate, endDate) / 2) },
      () => averageValue,
    );
    const data: ChartData<'line'> = {
      labels: [],
      datasets: [],
    };
    data.labels = currentPeriod.map((item) =>
      formatDayAndMonth(item.created_date),
    );
    data.datasets.push({
      label: getText('insights_graph_preceding_period'),
      data: lastPeriod.map((item) => item.sum_units),
      borderColor: 'rgb(184,184,184)',
      pointRadius: 0,
      fill: false,
      order: 2,
    });
    data.datasets.push({
      label: `${formatDate(startDate)} - ${formatDate(endDate)}`,
      data: currentPeriod.map((item) => item.sum_units),
      borderColor: 'rgb(255,168,0)',
      pointRadius: 0,
      fill: false,
      order: 3,
    });
    data.datasets.push({
      label: isOutputPerDay
        ? getText('insights_graph_average_meters_per_day_for_region', {
            regionName,
          })
        : getText('insights_graph_average_jobs_per_day_for_region', {
            regionName,
          }),
      data: avarage,
      borderColor: 'rgb(0,0,0)',
      borderWidth: 2,
      pointRadius: 0,
      fill: false,
      order: 1,
    });
    setGraphDataSet(data);
  };

  useEffect(() => {
    if (graphData) {
      setGraphDataSet(null);
      generateGraphData(graphData);
      setIsNoData(graphData.length === 0);
    }
  }, [graphData, globalAppLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  const options: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'end',
        labels: {
          font: {
            family: fonts.bold,
          },
          color: colors.darkGrey,
          padding: 16,
        },
      },
    },
    scales: {
      y: {
        stacked: false,
        title: {
          display: true,
          text: isOutputPerDay
            ? getText('insights_graph_meters_per_day_for_depot', { depotName })
            : getText('insights_graph_jobs_per_day_for_depot', { depotName }),
          font: {
            size: 14,
            family: fonts.default,
          },
          padding: 16,
        },
      },
      x: {
        offset: false,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <GraphWrapper>
      <GraphContainer>
        {graphDataSet && isNoData && (
          <NoData>{getText('insights_graph_no_job')}</NoData>
        )}
        {graphDataSet ? (
          <StyledLine data={graphDataSet} options={options} />
        ) : (
          <Loader />
        )}
      </GraphContainer>
    </GraphWrapper>
  );
};

export default InsightLineGraph;

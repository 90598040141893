import React from 'react';
import { CSSProperties } from 'styled-components';
import * as Analytics from 'utils/analytics';
import { Loader } from '../../Loader';
import { Button, Description, DescriptionWrapper, Icon } from './styled';
import { ArrowLeft, ArrowRight } from '../styled';

type Props = {
  text: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => {};
  width?: string;
  IconComponent?: React.ElementType;
  disabled?: boolean;
  loading?: boolean;
  href?: string;
  extraStyles?: CSSProperties;
  showArrowRight?: boolean;
  showArrowLeft?: boolean;
  hasBlackBackground?: boolean;
  outlineOffset?: string;
  marginTop?: string;
  iconFront?: boolean;
};

// Button.Primary
export const PrimaryButton = Analytics.withTrackEvent(
  ({
    text,
    onClick,
    width = '100%',
    IconComponent,
    disabled = false,
    loading = false,
    href,
    extraStyles,
    showArrowRight = true,
    showArrowLeft = false,
    hasBlackBackground = false,
    outlineOffset = '',
    marginTop = '25px',
    iconFront = false,
  }: Props): JSX.Element => (
    <Button
      width={width}
      disabled={disabled}
      loading={loading}
      disableRipple={loading}
      onClick={(e: React.MouseEvent<HTMLElement>) =>
        loading ? null : onClick(e)
      }
      href={href}
      style={extraStyles}
      hasBlackBackground={hasBlackBackground}
      outlineOffset={outlineOffset}
      marginTop={marginTop}
      $iconFront={iconFront}
    >
      <DescriptionWrapper $iconFront={iconFront}>
        {showArrowLeft && <ArrowLeft disabled={disabled} />}
        {loading && <Loader size={20} />}
        <Description $disabled={disabled} $loading={loading}>
          {text}
        </Description>
      </DescriptionWrapper>
      {IconComponent ? (
        <Icon
          disabled={disabled}
          component={<IconComponent />}
          $iconFront={iconFront}
        />
      ) : (
        showArrowRight && <ArrowRight disabled={disabled} $hasMarginLeft />
      )}
    </Button>
  ),
);

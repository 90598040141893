import styled from 'styled-components';
import { BrandPatternCircles } from 'assets/images';
import theme from 'theme';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const { media, colors, fonts, fontSizes } = theme;

export const LoginForm = styled.div`
  width: 415px;
  display: flex;
  flex-direction: column;
  ${media.sm`
    width: 100%;
    flex: 1 0;
    margin-top: 40px;
  `}
`;

export const PaddedWrapper = styled.div`
  margin-bottom: 32px;

  > div {
    &:not(:last-child) {
      margin-bottom: 20px;

      ${media.sm`
        margin-bottom: 10px;
      `}
    }
  }

  ${media.sm`
    padding: 20px;
  `}

  > button {
    margin-right: 0;
    margin-left: auto;
  }
`;

export const ErrorContainer = styled.div`
  height: 130px;
  max-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OktaForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${media.sm`
    width: 100%;
    flex: 1 0;
    margin-top: 40px;
    padding: 25px;
    align-items: flex-start;
  `}
`;

export const OktaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-image: url(${BrandPatternCircles});
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  ${media.sm`
    flex: 1 0;
    padding-top: 0px;
    align-items: flex-start;
    justify-content: flex-start;
    background-position-x: unset;
  `}
`;

export const AlternativeLoginContainer = styled.span`
  margin-bottom: 30px;
  color: ${colors.white};
  font-size: ${fontSizes.m};
  font-family: ${fonts.light};
`;

export const LoginWithUserLink = styled.a`
  color: ${colors.white};
  font-family: ${fonts.bold};
  border-bottom: 1px solid ${colors.white};
  margin-left: 6px;
  cursor: pointer;
  &:hover {
    color: ${colors.white};
    border-bottom: 1px solid ${colors.white};
  }
  ${media.sm`
    margin-left: 0;
  `}
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > {
    &:not(:last-child) {
      margin-bottom: 20px;

      ${media.sm`
        margin-bottom: 10px;
      `}
    }
  }
`;

export const H1 = styled.h1`
  color: ${colors.white};
  font-size: ${fontSizes.massive};
  margin-bottom: 40px !important;
  ${media.sm`
    font-size: ${fontSizes.xxxl};
    margin-bottom: 25px !important;
  `}
`;

export const SecondaryActionButton = styled(Button)`
  &.MuiButton-root {
    letter-spacing: normal;
    padding: 0;
    width: fit-content;
    font-size: ${fontSizes.m};
    font-family: ${fonts.default};
    color: ${colors.white};
    text-transform: none;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SecondaryActionLink = styled(Link)`
  font-size: ${fontSizes.m};
  color: ${colors.white};
  text-decoration: underline;
  &:hover {
    color: ${colors.white};
    text-decoration: underline;
  }
`;

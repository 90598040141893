import { HavsComplianceFields } from 'model/Insight/HavsComplianceData';

export const HavsComplianceColumnOrder = [
  HavsComplianceFields.DATE_RANGE,
  HavsComplianceFields.FULL_NAME,
  HavsComplianceFields.SUBMISSIONS,
  HavsComplianceFields.POINTS_BREACHES,
  HavsComplianceFields.ACTION,
  HavsComplianceFields.SIGNED_OFF,
];

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Loader } from 'components/ui';
import { useText } from 'hooks';
import { insightsComplianceFields } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import ComplianceDataTable from './ComplianceDataTable';
import { SortOrder } from 'model/enum/SortOrder';
import Sort from 'model/Sort';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { ComplianceType } from 'model/enum/ComplianceType';
import {
  selectActiveWorkspaceUuid,
  selectComplianceData,
  selectIsLoadingCompliance,
} from 'store/selectors';
import { fetchComplianceData } from 'store/actions/insights';
import { stringifyUserIds } from 'api/Insights/helpers';

const { colors, fonts, fontSizes } = theme;

// Styled Components
const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 40px !important;
`;

const DataContainer = styled.div`
  padding: 40px 80px;
  background-color: ${colors.offWhite};
  min-height: 100%;
`;

const DataRow = styled.div`
  height: 650px;
  display: flex;
  background: ${colors.white};
  padding: 32px;
`;

type Props = {
  depotId: number;
  search: string;
  startDate: Date;
  endDate: Date;
  isWeekendIncluded: boolean;
  userFilters: number[];
};

// InsightCompliance
const InsightCompliance = ({
  depotId,
  search,
  startDate,
  endDate,
  isWeekendIncluded,
  userFilters,
}: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();

  const initialSort = {
    field: insightsComplianceFields.VRA_AND_SIGN_ON_PERCENTAGE,
    order: SortOrder.DESCENDING,
  };
  const [sort, setSort] = useState<Sort>(initialSort);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const isLoading = useSelector(selectIsLoadingCompliance);
  const { results, count } = useSelector(selectComplianceData);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);

  const userIds = stringifyUserIds(userFilters);

  useEffect(
    () => {
      getComplianceData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      depotId,
      startDate,
      endDate,
      isWeekendIncluded,
      sort,
      userIds,
      search,
      selectedWorkspaceUuid,
    ],
  );

  const getComplianceData = (page: number = 1) => {
    if (depotId) {
      dispatch(
        fetchComplianceData(
          depotId,
          startDate,
          endDate,
          !isWeekendIncluded,
          sort,
          userIds,
          search,
          selectedWorkspaceUuid,
          page,
        ),
      );

      Analytics.trackEvent(
        `${ComplianceType.INSIGHTS}-${constants.EVENT_COMPLIANCE_LOADED}`,
        {
          depotId,
          startDate,
          endDate,
          isWeekendIncluded,
          sort,
          hasFilters: !!userIds,
          ...(!!userIds && { userIds }),
          search,
          page,
        },
      );
    }
    setCurrentPage(page);
  };

  return (
    <DataContainer>
      <H2>{getText('insights_compliance_title')}</H2>
      {results ? (
        <DataRow>
          <ComplianceDataTable
            data={results}
            total={count}
            currentPage={currentPage}
            onPageChange={(page) => getComplianceData(page)}
            sort={sort}
            setSort={setSort}
            initialSort={initialSort}
            analyticsData={{
              depotId,
              startDate,
              endDate,
              isWeekendIncluded,
              sort,
              rowsOfData: count,
            }}
            isLoading={isLoading}
          />
        </DataRow>
      ) : (
        <DataRow>
          <Loader />
        </DataRow>
      )}
    </DataContainer>
  );
};

export default InsightCompliance;

import axios from 'axios';
import {
  getUrlFragmentForTask,
  mapToWorkflowStep,
  mapToWorkflowTask,
  mapToWorkflowTaskType,
} from './helpers';
import { WorkflowStep, WorkflowTask, WorkflowTaskType } from 'model/Workflows';

export const getWorkflowSteps = async (
  workspaceId: string,
  workflowId: string,
): Promise<WorkflowStep[]> => {
  const { data } = await axios.get(
    `/workspace-settings/${workspaceId}/workflows/${workflowId}/task/`,
  );
  return data.map(mapToWorkflowStep);
};

export const getWorkflowTasks = async (
  workspaceId: string,
): Promise<WorkflowTask[]> => {
  const { data } = await axios.get(
    `/workspace-settings/${workspaceId}/workflows/task-types/`,
  );
  return data.map(mapToWorkflowTask);
};

export const getWorkflowTaskTypes = async (
  workspaceId: string,
  taskId: string,
): Promise<WorkflowTaskType[]> => {
  const urlTrail = getUrlFragmentForTask(taskId);
  const { data } = await axios.get(
    `/workspace-settings/${workspaceId}/workflows/task-types/${urlTrail}`,
  );
  return data.map(mapToWorkflowTaskType);
};

import { Component } from 'react';
import * as Logger from 'utils/logger';
import { ErrorPage } from 'components/ui';

type Props = {
  getText: (key: string) => string;
  children: JSX.Element;
};

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    Logger.init();
  }

  componentDidCatch(err, info) {
    Logger.error(err, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          titleText={this.props.getText('error_title')}
          subTitleText={this.props.getText('error_subtitle')}
          linkText='help@fyld.ai'
          link='mailto:help@fyld.ai'
        />
      );
    }

    return this.props.children;
  }
}

import styled from 'styled-components';
import theme from 'theme';
import { Arrow } from 'assets/icons';

const { colors, fonts, fontSizes, mixins, media } = theme;

export const Container = styled.div`
  ${mixins.flexColumn};
  position: relative;
`;

export const ArrowIcon = styled(Arrow)`
  height: 20px;
  width: 20px;
  .fill-selector {
    fill: ${colors.darkGrey};
  }
  ${media.sm`
    height: 15px;
    width: 15px;
  `}
`;

export const DateRangeWrapper = styled.div`
  .nice-dates-day:before {
    background-color: ${colors.orange};
  }
  .nice-dates-popover {
    max-width: 425px;
    border-radius: 0;
    border: 3px solid ${colors.black};
  }
  .nice-dates-day {
    font-family: ${fonts.bold};
    font-size: ${fontSizes.s};
  }
  .nice-dates-day_month {
    display: none;
  }
  ${media.sm`
    .nice-dates-popover.-open {
      position: sticky;
      left: 15px;
      width: 91vw;
    }
  `}
`;

export const DateRangeInputWrapper = styled.div`
  outline: 2px solid ${colors.black};
  height: 55px;
  display: flex;
  align-items: center;
  width: 100%;
  &:hover {
    outline: 3px solid ${colors.black};
  }
  &:focus-within {
    outline: 3px solid ${colors.orange};
  }
`;

export const DateInput = styled.input<{ $focused: boolean }>`
  color: ${colors.black};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
  border: 0;
  width: 110px;
  &::placeholder {
    color: ${colors.black};
    text-decoration: ${({ $focused }) => ($focused ? 'underline' : 'none')};
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }
  margin-left: 10px;
  outline: none;
`;

import {
  SET_CURRENT_INSIGHTS_TAB,
  FETCH_COMPLIANCE_DATA,
  FETCH_COMPLIANCE_DATA_ERROR,
  FETCH_COMPLIANCE_DATA_SUCCESS,
  FETCH_ENGAGEMENT_DATA,
  FETCH_ENGAGEMENT_DATA_ERROR,
  FETCH_ENGAGEMENT_DATA_SUCCESS,
  FETCH_JOBS_PER_DAY_GRAPHS,
  FETCH_JOBS_PER_DAY_GRAPHS_ERROR,
  FETCH_JOBS_PER_DAY_STATS,
  FETCH_JOBS_PER_DAY_STATS_ERROR,
  FETCH_JOBS_PER_DAY_STATS_SUCCESS,
  FETCH_JOBS_PER_DAY_GRAPHS_SUCCESS,
  FETCH_OUTPUT_PER_DAY_GRAPHS,
  FETCH_OUTPUT_PER_DAY_GRAPHS_ERROR,
  FETCH_OUTPUT_PER_DAY_STATS,
  FETCH_OUTPUT_PER_DAY_STATS_ERROR,
  FETCH_OUTPUT_PER_DAY_STATS_SUCCESS,
  FETCH_OUTPUT_PER_DAY_GRAPHS_SUCCESS,
  FETCH_INSIGHTS_JOB_BLOCKERS,
  FETCH_INSIGHTS_JOB_BLOCKERS_SUCCESS,
  FETCH_INSIGHTS_JOB_BLOCKERS_ERROR,
  FETCH_HAVS_COMPLIANCE,
  FETCH_HAVS_COMPLIANCE_SUCCESS,
  FETCH_HAVS_COMPLIANCE_ERROR,
  FETCH_VEHICLE_CHECK_COMPLIANCE,
  FETCH_VEHICLE_CHECK_COMPLIANCE_SUCCESS,
  FETCH_VEHICLE_CHECK_COMPLIANCE_ERROR,
  FETCH_VEHICLE_CHECK_USER_COMPLIANCE,
  FETCH_VEHICLE_CHECK_USER_COMPLIANCE_SUCCESS,
  FETCH_VEHICLE_CHECK_USER_COMPLIANCE_ERROR,
  SIGN_OFF_HAVS_COMPLIANCE,
  SIGN_OFF_HAVS_COMPLIANCE_SUCCESS,
  SIGN_OFF_HAVS_COMPLIANCE_ERROR,
  FETCH_USER_FORM,
  FETCH_USER_FORM_SUCCESS,
  FETCH_USER_FORM_ERROR,
  FETCH_JOB_FORM,
  FETCH_JOB_FORM_SUCCESS,
  FETCH_JOB_FORM_ERROR,
  FETCH_PERMIT_FORM,
  FETCH_PERMIT_FORM_SUCCESS,
  FETCH_PERMIT_FORM_ERROR,
} from './actionTypes';

export const setCurrentTab = (tab) => ({
  type: SET_CURRENT_INSIGHTS_TAB,
  tab,
});

export const fetchEngagementData = (
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  workspaceUuid,
) => ({
  type: FETCH_ENGAGEMENT_DATA,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  workspaceUuid,
});

export const fetchEngagementDataSuccess = (data) => ({
  type: FETCH_ENGAGEMENT_DATA_SUCCESS,
  data,
});

export const fetchEngagementDataError = () => ({
  type: FETCH_ENGAGEMENT_DATA_ERROR,
});

export const fetchComplianceData = (
  depotId,
  startDate,
  endDate,
  isWeekendIncluded,
  sort,
  userIds,
  search,
  workspaceUuid,
  currentPage,
) => ({
  type: FETCH_COMPLIANCE_DATA,
  depotId,
  startDate,
  endDate,
  isWeekendIncluded,
  sort,
  userIds,
  search,
  workspaceUuid,
  currentPage,
});

export const fetchComplianceDataSuccess = (data) => ({
  type: FETCH_COMPLIANCE_DATA_SUCCESS,
  data,
});

export const fetchComplianceDataError = () => ({
  type: FETCH_COMPLIANCE_DATA_ERROR,
});

export const fetchJobsPerDayGraphs = (
  depotId,
  typeId,
  startDate,
  endDate,
  excludeWeekends,
  selectedWorkspaceUuid,
) => ({
  type: FETCH_JOBS_PER_DAY_GRAPHS,
  depotId,
  typeId,
  startDate,
  endDate,
  excludeWeekends,
  selectedWorkspaceUuid,
});

export const fetchJobsPerDayGraphsSuccess = (data, typeId) => ({
  type: FETCH_JOBS_PER_DAY_GRAPHS_SUCCESS,
  data,
  typeId,
});

export const fetchJobsPerDayGraphsError = () => ({
  type: FETCH_JOBS_PER_DAY_GRAPHS_ERROR,
});

export const fetchJobsPerDayStats = (
  depotId,
  typeId,
  startDate,
  endDate,
  excludeWeekends,
  selectedWorkspaceUuid,
) => ({
  type: FETCH_JOBS_PER_DAY_STATS,
  depotId,
  typeId,
  startDate,
  endDate,
  excludeWeekends,
  selectedWorkspaceUuid,
});

export const fetchJobsPerDayStatsSuccess = (data, typeId) => ({
  type: FETCH_JOBS_PER_DAY_STATS_SUCCESS,
  data,
  typeId,
});

export const fetchJobsPerDayStatsError = () => ({
  type: FETCH_JOBS_PER_DAY_STATS_ERROR,
});

export const fetchOutputPerDayGraphs = (
  depotId,
  typeId,
  startDate,
  endDate,
  excludeWeekends,
  selectedWorkspaceUuid,
) => ({
  type: FETCH_OUTPUT_PER_DAY_GRAPHS,
  depotId,
  typeId,
  startDate,
  endDate,
  excludeWeekends,
  selectedWorkspaceUuid,
});

export const fetchOutputPerDayGraphsSuccess = (data) => ({
  type: FETCH_OUTPUT_PER_DAY_GRAPHS_SUCCESS,
  data,
});

export const fetchOutputPerDayGraphsError = () => ({
  type: FETCH_OUTPUT_PER_DAY_GRAPHS_ERROR,
});

export const fetchOutputPerDayStats = (
  depotId,
  typeId,
  startDate,
  endDate,
  excludeWeekends,
  selectedWorkspaceUuid,
) => ({
  type: FETCH_OUTPUT_PER_DAY_STATS,
  depotId,
  typeId,
  startDate,
  endDate,
  excludeWeekends,
  selectedWorkspaceUuid,
});

export const fetchOutputPerDayStatsSuccess = (data) => ({
  type: FETCH_OUTPUT_PER_DAY_STATS_SUCCESS,
  data,
});

export const fetchOutputPerDayStatsError = () => ({
  type: FETCH_OUTPUT_PER_DAY_STATS_ERROR,
});

export const fetchJobBlockers = (
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  workspaceUuid,
) => ({
  type: FETCH_INSIGHTS_JOB_BLOCKERS,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  workspaceUuid,
});

export const fetchJobBlockersSuccess = (data) => ({
  type: FETCH_INSIGHTS_JOB_BLOCKERS_SUCCESS,
  data,
});

export const fetchJobBlockersError = () => ({
  type: FETCH_INSIGHTS_JOB_BLOCKERS_ERROR,
});

export const fetchHavsCompliance = (
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  sort,
  userIds,
  search,
  workspaceUuid,
  currentPage,
) => ({
  type: FETCH_HAVS_COMPLIANCE,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  sort,
  userIds,
  search,
  workspaceUuid,
  currentPage,
});

export const fetchHavsComplianceSuccess = (data) => ({
  type: FETCH_HAVS_COMPLIANCE_SUCCESS,
  data,
});

export const fetchHavsComplianceError = () => ({
  type: FETCH_HAVS_COMPLIANCE_ERROR,
});

export const signOffHavsCompliance = (
  startDate,
  endDate,
  userId,
  currentUserId,
) => ({
  type: SIGN_OFF_HAVS_COMPLIANCE,
  startDate,
  endDate,
  userId,
  currentUserId,
});

export const signOffHavsComplianceSuccess = (userId, data) => ({
  type: SIGN_OFF_HAVS_COMPLIANCE_SUCCESS,
  userId,
  data,
});

export const signOffHavsComplianceError = () => ({
  type: SIGN_OFF_HAVS_COMPLIANCE_ERROR,
});

export const fetchVehicleCheckCompliance = (
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  sort,
  userIds,
  search,
  workspaceUuid,
  currentPage,
) => ({
  type: FETCH_VEHICLE_CHECK_COMPLIANCE,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  sort,
  userIds,
  search,
  workspaceUuid,
  currentPage,
});

export const fetchVehicleCheckComplianceSuccess = (data) => ({
  type: FETCH_VEHICLE_CHECK_COMPLIANCE_SUCCESS,
  data,
});

export const fetchVehicleCheckComplianceError = () => ({
  type: FETCH_VEHICLE_CHECK_COMPLIANCE_ERROR,
});

export const fetchVehicleCheckUserCompliance = (
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  sort,
  userIds,
  search,
  workspaceUuid,
  currentPage,
) => ({
  type: FETCH_VEHICLE_CHECK_USER_COMPLIANCE,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  sort,
  userIds,
  search,
  workspaceUuid,
  currentPage,
});

export const fetchVehicleCheckUserComplianceSuccess = (data) => ({
  type: FETCH_VEHICLE_CHECK_USER_COMPLIANCE_SUCCESS,
  data,
});

export const fetchVehicleCheckUserComplianceError = () => ({
  type: FETCH_VEHICLE_CHECK_USER_COMPLIANCE_ERROR,
});

export const fetchUserForm = (
  formId,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  memberIds,
  currentPage,
  workspaceUuid,
  search,
  isHavsForm = false,
) => ({
  type: FETCH_USER_FORM,
  formId,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  memberIds,
  currentPage,
  workspaceUuid,
  search,
  isHavsForm,
});

export const fetchUserFormSuccess = (data, formId) => ({
  type: FETCH_USER_FORM_SUCCESS,
  data,
  formId,
});

export const fetchUserFormError = () => ({ type: FETCH_USER_FORM_ERROR });

export const fetchJobForm = (
  formId,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  memberIds,
  currentPage,
  workspaceUuid,
  search,
) => ({
  type: FETCH_JOB_FORM,
  formId,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  memberIds,
  currentPage,
  workspaceUuid,
  search,
});

export const fetchJobFormSuccess = (data, formId) => ({
  type: FETCH_JOB_FORM_SUCCESS,
  data,
  formId,
});

export const fetchJobFormError = () => ({ type: FETCH_JOB_FORM_ERROR });

export const fetchPermitForm = (
  formId,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  memberIds,
  currentPage,
  workspaceUuid,
  search,
) => ({
  type: FETCH_PERMIT_FORM,
  formId,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  memberIds,
  currentPage,
  workspaceUuid,
  search,
});

export const fetchPermitFormSuccess = (data, formId) => ({
  type: FETCH_PERMIT_FORM_SUCCESS,
  data,
  formId,
});

export const fetchPermitFormError = () => ({ type: FETCH_PERMIT_FORM_ERROR });

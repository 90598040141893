import { mapToVideo } from 'api/Media/helpers';
import { partialMapToPermit } from 'api/Permit/helpers';
import { MessageType, Message } from 'model/Message';
import { Reaction } from 'model/Reaction';

export type BackendMessage = {
  id: number;
  type: MessageType | null;
  job_id: number;
  target: any;
  created_at: Date;
  updated_at: Date;
  read: boolean;
  user_reaction: string;
  reactions: Reaction[];
};

export const mapToMessage = (input: BackendMessage): Message => {
  let mappedTarget = input.target;

  switch (input.type) {
    case MessageType.ASSESSMENT:
      mappedTarget = {
        ...input.target,
        video: input.target?.video ? mapToVideo(input.target?.video) : null,
      };
      break;
    case MessageType.EVIDENCE:
    case MessageType.JOB_FORM:
      mappedTarget = {
        ...input.target,
        videos: input.target?.videos
          ? input.target?.videos.map(mapToVideo)
          : [],
      };
      break;
    case MessageType.PERMIT:
      mappedTarget = partialMapToPermit(input.target);
      break;
    default:
      break;
  }
  return {
    id: input.id,
    type: input.type,
    job_id: input.job_id,
    target: mappedTarget,
    created_at: input.created_at,
    updated_at: input.updated_at,
    read: input.read,
    user_reaction: input.user_reaction,
    reactions: input.reactions,
  };
};

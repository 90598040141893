import theme from 'theme';
import { Button as MaterialButton } from '@mui/material';
import styled from 'styled-components';

const { media, colors, fonts, fontSizes, mixins } = theme;

export const Button = styled(({ width, disabled, loading, large, ...rest }) => (
  <MaterialButton disabled={disabled} {...rest} />
))`
  &.MuiButtonBase-root {
    height: 55px;
    width: ${({ width }) => width};
    ${mixins.flexBetween};
    border-radius: 0;
    border: none;
    outline: 2px solid ${colors.grey};
    font-family: ${fonts.bold};
    font-size: ${({ large }) => (large ? fontSizes.m : fontSizes.default)};
    text-transform: none;
    letter-spacing: 0;
    margin-top: 25px;
    padding: 0 20px;
    text-align: left;
    align-self: flex-end;
    background-color: ${colors.white} !important;
    color: ${colors.black};
    &:hover {
      outline: 3px solid ${colors.grey};
      background-color: ${colors.white} !important;
    }
    &.Mui-disabled {
      outline: 2px solid ${colors.grey};
      background-color: ${colors.lightGrey} !important;
      color: ${colors.black};
      &:hover {
        outline: 2px solid ${colors.grey};
        background-color: ${colors.lightGrey} !important;
      }
    }
    ${({ loading }) =>
      loading &&
      `
      opacity: 0.65;
      cursor: default;
      &:hover {
        outline: 2px solid ${colors.black};
      }
    `}
    ${media.sm`
        width: 100%;
        margin-top: auto;      
      `}
  }
`;

export const DescriptionWrapper = styled.div<{ $loading: boolean }>`
  ${({ $loading }) => ($loading ? mixins.flexBetween : mixins.flexFullCenter)};
  flex-direction: row;
  width: 100%;
`;

export const Description = styled.div<{
  $disabled: boolean;
  $loading: boolean;
}>`
  opacity: ${({ $disabled }) => ($disabled ? 0.65 : 1)};
  margin-left: ${({ $loading }) => ($loading ? '5px' : 0)};
  text-align: ${({ $loading }) => ($loading ? 'start' : 'center')};
`;

import styled from 'styled-components';
import theme from 'theme';

const { fonts, fontSizes } = theme;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 33px;
  padding-bottom: 33px;
  border-bottom: 5px solid ${theme.colors.offWhite};
  &:last-of-type {
    border: none;
  }
`;

export const Label = styled.p`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
`;

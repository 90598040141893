import { RadioInput } from 'components/ui/Input/RadioInput';
import { FieldKind } from '../types';
import SectionField from 'model/FatigueManager/SectionField';
import { MultiSelectWithCustomTextInput } from 'components/ui/Input/MultiSelectWithCustomTextInput';
import { useText } from 'hooks';
import { memo, useCallback, useMemo } from 'react';
import { OTHER_LOCALISATION_STRING } from 'model/FatigueManager/constants';
import { isFieldValid } from 'model/FatigueManager/helpers';

type Props = {
  sectionIndex: number;
  field: SectionField;
  updateField: (
    sectionIndex: number,
    fieldId: string,
    newValue: string,
  ) => void;
  updateCustomUserInputForField: (
    sectionIndex: number,
    id: string,
    newValue: string,
  ) => void;
  formId: number;
  displayErrors: boolean;
};

export const FatigueRiskAssessmentField = memo(
  ({
    sectionIndex,
    field,
    updateField,
    updateCustomUserInputForField,
    displayErrors,
  }: Props) => {
    const getText = useText();

    const isValid = isFieldValid(field);

    const error =
      displayErrors && !isValid
        ? getText('fatigue_manager_fatigue_risk_assessment_field_required')
        : undefined;
    const optionsWithOther = useMemo(
      () => [...field.options, OTHER_LOCALISATION_STRING],
      [field.options],
    );
    const selectedOptions = useMemo(
      () => field.value as string[],
      [field.value],
    );
    const setSelectedMultipleChoiceOption = useCallback(
      (selectedOptions: any) =>
        updateField(sectionIndex, field.id, selectedOptions),
      [sectionIndex, field.id, updateField],
    );
    const setSelectedSingleChoiceOption = useCallback(
      (newValue: string) => updateField(sectionIndex, field.id, newValue),
      [sectionIndex, field.id, updateField],
    );
    const setCustomUserInputValue = useCallback(
      (newOtherValue) =>
        updateCustomUserInputForField(sectionIndex, field.id, newOtherValue),
      [sectionIndex, field.id, updateCustomUserInputForField],
    );

    switch (field.kind) {
      case FieldKind.MULTI_SELECT_CUSTOM_TEXT:
        return (
          <MultiSelectWithCustomTextInput
            options={optionsWithOther}
            selected={selectedOptions}
            setSelected={setSelectedMultipleChoiceOption}
            customUserInputValue={field.customUserInputValue}
            setCustomUserInputValue={setCustomUserInputValue}
            label={field.label}
            id={field.id}
            error={error}
            displayErrors={displayErrors}
          />
        );
      case FieldKind.BINARY_RADIO_BUTTON:
      case FieldKind.SINGLE_SELECT_INLINE:
      case FieldKind.SINGLE_SELECT:
        return (
          <RadioInput
            label={field.label}
            options={field.options}
            selected={
              Array.isArray(field.value) ? field.value.join(',') : field.value
            }
            setSelected={setSelectedSingleChoiceOption}
            error={error}
          />
        );
      default:
        return null;
    }
  },
);

import { Control } from 'model/Assessment/Control';
import {
  ADD_WORKSPACE_CONTROL_TO_HAZARD,
  ADD_WORKSPACE_CONTROL_TO_HAZARD_ERROR,
  ADD_WORKSPACE_CONTROL_TO_HAZARD_SUCCESS,
  EDIT_WORKSPACE_CONTROL,
  EDIT_WORKSPACE_CONTROL_ERROR,
  EDIT_WORKSPACE_CONTROL_SUCCESS,
  FETCH_WORKSPACE_CONTROLS_FOR_HAZARD,
  FETCH_WORKSPACE_CONTROLS_FOR_HAZARD_ERROR,
  FETCH_WORKSPACE_CONTROLS_FOR_HAZARD_SUCCESS,
  REMOVE_WORKSPACE_CONTROL,
  REMOVE_WORKSPACE_CONTROL_ERROR,
  REMOVE_WORKSPACE_CONTROL_SUCCESS,
  RESET_WORKSPACE_CONTROL_ERROR,
} from 'store/actions/actionTypes';

const defaultState = {
  controls: {},
  isFetchingControlsForHazard: {},
  isLoadingControlsChangesByHazard: {},
  error: null,
};

const workspaceControls = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_WORKSPACE_CONTROLS_FOR_HAZARD:
      return {
        ...state,
        isFetchingControlsForHazard: {
          ...state.isFetchingControlsForHazard,
          [action.hazardId]: true,
        },
      };
    case FETCH_WORKSPACE_CONTROLS_FOR_HAZARD_SUCCESS:
      return {
        ...state,
        controls: {
          ...state.controls,
          [action.hazardId]: [...action.data],
        },
        isFetchingControlsForHazard: {
          ...state.isFetchingControlsForHazard,
          [action.hazardId]: false,
        },
      };
    case FETCH_WORKSPACE_CONTROLS_FOR_HAZARD_ERROR:
      return {
        ...state,
        isFetchingControlsForHazard: {
          ...state.isFetchingControlsForHazard,
          [action.hazardId]: false,
        },
      };
    case ADD_WORKSPACE_CONTROL_TO_HAZARD:
    case EDIT_WORKSPACE_CONTROL:
    case REMOVE_WORKSPACE_CONTROL:
      return {
        ...state,
        isLoadingControlsChangesByHazard: {
          ...state.isLoadingControlsChangesByHazard,
          [action.hazardId]: true,
        },
      };
    case ADD_WORKSPACE_CONTROL_TO_HAZARD_SUCCESS: {
      const hazardControls = state.controls?.[action.hazardId] || [];
      return {
        ...state,
        controls: {
          ...state.controls,
          [action.hazardId]: [...hazardControls, action.data],
        },
        isLoadingControlsChangesByHazard: {
          ...state.isLoadingControlsChangesByHazard,
          [action.hazardId]: false,
        },
      };
    }
    case EDIT_WORKSPACE_CONTROL_SUCCESS: {
      const hazardControls = state.controls?.[action.hazardId] || [];
      const updatedControls = hazardControls.map((control) =>
        control?.id === action.data?.id ? action.data : control,
      );
      return {
        ...state,
        controls: {
          ...state.controls,
          [action.hazardId]: [...updatedControls],
        },
        isLoadingControlsChangesByHazard: {
          ...state.isLoadingControlsChangesByHazard,
          [action.hazardId]: false,
        },
      };
    }
    case REMOVE_WORKSPACE_CONTROL_SUCCESS: {
      const hazardControls: Control[] = state.controls?.[action.hazardId] || [];
      const updatedControls = hazardControls.filter(
        (control) => control?.id !== action?.id,
      );
      return {
        ...state,
        controls: {
          ...state.controls,
          [action.hazardId]: [...updatedControls],
        },
        isLoadingControlsChangesByHazard: {
          ...state.isLoadingControlsChangesByHazard,
          [action.hazardId]: false,
        },
      };
    }
    case ADD_WORKSPACE_CONTROL_TO_HAZARD_ERROR:
    case EDIT_WORKSPACE_CONTROL_ERROR:
    case REMOVE_WORKSPACE_CONTROL_ERROR:
      return {
        ...state,
        error: action.data,
        isLoadingControlsChangesByHazard: {
          ...state.isLoadingControlsChangesByHazard,
          [action.hazardId]: false,
        },
      };
    case RESET_WORKSPACE_CONTROL_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default workspaceControls;

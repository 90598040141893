import { WeatherIconTypes } from 'model/State/SiteBriefing';
import {
  WeatherClearNight,
  WeatherCloud,
  WeatherCloudNight,
  WeatherCold,
  WeatherFlood,
  WeatherFog,
  WeatherHail,
  WeatherHeat,
  WeatherRain,
  WeatherSnow,
  WeatherStorm,
  WeatherSun,
  WeatherWind,
} from 'assets/icons';

export const getWeatherIcon = (icon): React.ReactNode => {
  if (!icon) {
    return null;
  }
  switch (icon) {
    case WeatherIconTypes.CLEAR_NIGHT:
      return WeatherClearNight;
    case WeatherIconTypes.CLOUD:
      return WeatherCloud;
    case WeatherIconTypes.COLD:
      return WeatherCold;
    case WeatherIconTypes.FLOOD:
      return WeatherFlood;
    case WeatherIconTypes.FOG:
      return WeatherFog;
    case WeatherIconTypes.HAIL:
      return WeatherHail;
    case WeatherIconTypes.HEAT:
      return WeatherHeat;
    case WeatherIconTypes.CLOUD_NIGHT:
      return WeatherCloudNight;
    case WeatherIconTypes.RAIN:
      return WeatherRain;
    case WeatherIconTypes.SNOW:
      return WeatherSnow;
    case WeatherIconTypes.STORM:
      return WeatherStorm;
    case WeatherIconTypes.SUN:
      return WeatherSun;
    case WeatherIconTypes.WIND:
      return WeatherWind;
    default:
  }
};

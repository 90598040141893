import styled from 'styled-components';
import { Warning } from 'assets/icons';
import theme from 'theme';
const { colors, fonts, mixins } = theme;

export const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: ${colors.white};
`;

export const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  .gm-style-iw {
    padding: 0 !important;
    border-radius: 0;
    > div {
      overflow: auto !important;
    }
    button {
      display: none !important;
    }
    .gm-style-iw-chr {
      display: none;
    }
  }
  .gm-style-iw-tc::after {
    background-color: ${colors.black};
  }
  .gm-fullscreen-control {
    left: 0;
    top: unset !important;
    bottom: 10px !important;
  }
`;

export const TooltipWrapper = styled.div`
  background-color: ${colors.black};
  color: ${colors.white};
  padding: 10px;
  font-family: ${fonts.default};
`;

export const TooltipHeader = styled.div`
  font-family: ${fonts.bold};
  margin-bottom: 12px;
`;

export const DetailRow = styled.div`
  padding-bottom: 8px;
  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const DetailTitle = styled.span`
  font-family: ${fonts.bold};
  margin-right: 5px;
`;

export const WarningIcon = styled(Warning)`
  height: 20px;
  width: 20px;
  padding-right: 8px;
`;

export const WarningTag = styled.div`
  ${mixins.flexCenter};
  padding: 3px 8px;
  margin-top: 7px;
  background-color: ${colors.orange};
  color: ${colors.black};
  font-family: ${fonts.bold};
  width: fit-content;
`;

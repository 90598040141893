import { useEffect } from 'react';
import RootState from 'model/State/RootState';
import { Weather } from 'components/ui';
import { fetchWeatherForJob, setWeatherForJob } from 'store/actions/jobs';
import { useDispatch, useSelector } from 'react-redux';
import { getIsJobLive } from './helpers';
import { useIsMobile } from 'hooks';

export const LiveJobWeather = ({ job }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { weather } = useSelector((state: RootState) => state.jobs);

  const isJobLive = getIsJobLive(job);

  useEffect(() => {
    if (isJobLive) {
      if (job.latitude && job.longitude) {
        dispatch(fetchWeatherForJob(job.id));
      } else {
        dispatch(setWeatherForJob(null));
      }
    }
  }, [job, isJobLive, dispatch]);

  return (
    weather?.weather &&
    isJobLive && (
      <Weather
        isMobile={isMobile}
        degree={weather.temp}
        description={weather.weather.description}
        icon={weather.weather.icon}
      />
    )
  );
};

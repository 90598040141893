import { useDispatch, useSelector } from 'react-redux';
import { setWorkspace, reloadForWorkspace } from 'store/actions/session';
import { SelectChangeEvent } from '@mui/material';
import { useLocalStorage } from 'hooks';
import RootState from 'model/State/RootState';
import { useState } from 'react';
import {
  StyledArrow,
  StyledChevron,
  StyledOptions,
  StyledSelect,
} from './styled';

export const WorkspaceSelect = () => {
  const { workspaces, selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const dispatch = useDispatch();
  const [, setActiveWorkspaceUuid] = useLocalStorage<string>(
    'activeWorkspaceUuid',
  );
  const [open, setOpen] = useState<boolean>(false);

  const handleWorkSpaceChange = (e: SelectChangeEvent<unknown>) => {
    const selected = workspaces.find(
      (workspace) => workspace?.uuid === e?.target?.value,
    );
    if (selected) {
      dispatch(setWorkspace(selected));
      setActiveWorkspaceUuid(selected.uuid);
      dispatch(reloadForWorkspace());
    }
  };

  return (
    <StyledSelect
      value={selectedWorkspace?.uuid ?? ''}
      renderValue={() => selectedWorkspace?.name}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={handleWorkSpaceChange}
      IconComponent={() => <StyledChevron open={open} />}
      MenuProps={{
        sx: {
          '& .MuiMenu-list': {
            padding: 0,
          },
          '& .MuiPopover-paper': {
            borderRadius: 0,
            marginTop: '8px',
          },
        },
      }}
    >
      {workspaces.map((workspace) => (
        <StyledOptions key={workspace.uuid} value={workspace.uuid}>
          {workspace.name}
          <StyledArrow
            className='arrow'
            selected={
              selectedWorkspace && selectedWorkspace?.uuid === workspace.uuid
            }
          />
        </StyledOptions>
      ))}
    </StyledSelect>
  );
};

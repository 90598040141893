import styled from 'styled-components';
import theme from 'theme';

const { colors, mixins } = theme;

export const Container = styled.div`
  ${mixins.flexColumn}
  height: 100%;
  width: 100%;
  background-color: ${colors.offWhite};
  box-sizing: border-box;
`;

export const EditWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const Column = styled.div`
  ${mixins.flexColumn}
  width: 50%;
  padding: 20px;
`;

export const StickyColumn = styled(Column)`
  position: sticky;
  top: 0;
`;

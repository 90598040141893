import { useSelector } from 'react-redux';
import theme from 'theme';
import { useText } from 'hooks';
import { Media } from 'helpers';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { LatestActivityThumbnail } from './LatestActivityThumbnail';
import { Loader } from 'components/ui';
import { getTimeSince } from 'helpers/dates';
import {
  ActivityType,
  CommandCenterActivity,
} from 'model/CommandCenter/Activity';
import { Assessment } from 'model/Assessment';
import {
  selectActivities,
  selectIsFetchingActivities,
  selectNumberOfActivities,
} from 'store/slices/commandCenter';
import {
  ActivityItem,
  ActivityItemImageWrapper,
  ActivityItemText,
  ActivityRowWrapper,
  ActivityWrapper,
  EmptyStateTitle,
  EmptyStateWrapper,
} from './styled';

export const LatestActivity = (): JSX.Element => {
  const getText = useText();
  const activities = useSelector(selectActivities);
  const isFetchingActivities = useSelector(selectIsFetchingActivities);
  const numberOfActivities = useSelector(selectNumberOfActivities);

  const getType = (type: ActivityType) => {
    if (type === ActivityType.EVIDENCE) {
      return getText('command_centre_activity_evidence');
    }
    if (type === ActivityType.ASSESSMENT) {
      return getText('command_centre_activity_vra_completed');
    }
    return getText('command_centre_activity_na');
  };

  const getThumbnail = (activity: CommandCenterActivity) => {
    if (activity.type === ActivityType.EVIDENCE) {
      return Media.getThumbnail(activity.target);
    }
    if (activity.type === ActivityType.ASSESSMENT) {
      return Media.getAssessmentThumbnail(activity.target);
    }
  };

  const getPath = (activity: CommandCenterActivity): string =>
    `${activity.target.jobId}/${
      activity.type === ActivityType.ASSESSMENT
        ? (activity.target as Assessment)?.typeIsRams
          ? 'rams-assessments'
          : 'assessments'
        : 'evidence'
    }/${activity.target.id}`;

  const activitiesWithPlaceholders =
    activities?.length === numberOfActivities
      ? activities
      : [
          ...activities,
          ...Array(numberOfActivities).fill({
            target: {},
            depot: {},
          }),
        ].filter((_, index) => index < numberOfActivities);

  const hasActivities: boolean = activities.length > 0;
  const isLoading: boolean = isFetchingActivities && !activities.length;

  if (isLoading) {
    return (
      <ActivityWrapper>
        <ActivityRowWrapper $isLoading>
          <Loader isWhite />
        </ActivityRowWrapper>
      </ActivityWrapper>
    );
  }

  return (
    <ActivityWrapper>
      <ActivityRowWrapper>
        {hasActivities ? (
          activitiesWithPlaceholders.map((activity, index) => (
            <ActivityItem
              to={getPath(activity)}
              onClick={() =>
                Analytics.trackEvent(
                  constants.COMMAND_CENTER_ACTIVITY_CLICKED,
                  {
                    type: activity.type,
                    id: activity.target.id,
                    jobId: activity.target.jobId,
                  },
                )
              }
              key={activity.id || `placeholder-${index}`}
            >
              <ActivityItemImageWrapper>
                <LatestActivityThumbnail thumbnail={getThumbnail(activity)} />
              </ActivityItemImageWrapper>
              <ActivityItemText color={theme.colors.yellow} $isBold>
                {getType(activity.type)}
              </ActivityItemText>
              <ActivityItemText $isBold>
                {activity.target.createdBy?.firstName}{' '}
                {activity.target.createdBy?.lastName.charAt(0)}.
              </ActivityItemText>
              {activity.depot?.title && (
                <ActivityItemText>{activity.depot.title}</ActivityItemText>
              )}
              <ActivityItemText>
                ({getTimeSince(activity.createdAt)})
              </ActivityItemText>
            </ActivityItem>
          ))
        ) : (
          <EmptyStateWrapper>
            <EmptyStateTitle>
              {getText('command_centre_latest_activity_empty_state_title')}
            </EmptyStateTitle>
            <div>
              {getText('command_centre_latest_activity_empty_state_body')}
            </div>
          </EmptyStateWrapper>
        )}
      </ActivityRowWrapper>
    </ActivityWrapper>
  );
};

import { Display, Input } from 'components/ui';
import { useEffect, useState } from 'react';
import { PhotoList } from './Photos/PhotoList';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { MethodStatementSection } from 'model/Assessment/RAMS';
import { DotsIcon, HandlerContainer, RamsSectionContainer } from './styled';

type Props = {
  section: MethodStatementSection;
  onSectionChange: (value: MethodStatementSection) => void;
  onDeleteClick: (value: MethodStatementSection) => void;
  dragProps?: {
    drag: ConnectDragSource;
    preview: ConnectDragPreview;
    opacity: number;
  };
  deleteDisabled?: boolean;
};

// RamsSection
export const RamsSection = ({
  section,
  onSectionChange,
  onDeleteClick,
  dragProps,
  deleteDisabled,
}: Props): JSX.Element => {
  const [currentSection, setCurrentSection] =
    useState<MethodStatementSection>(section);
  const [showImages, setShowImages] = useState<boolean>(false);
  const { opacity, preview, drag } = dragProps || {};

  useEffect(() => {
    onSectionChange(currentSection);
    if (currentSection?.photos.length > 0) {
      setShowImages(true);
    }
  }, [currentSection]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHandler = () => (
    <HandlerContainer ref={drag}>
      <DotsIcon />
    </HandlerContainer>
  );

  return (
    <RamsSectionContainer ref={preview} style={{ opacity }}>
      <Display.EditableCollapsible
        dragHandler={renderHandler()}
        setTitle={(e) =>
          setCurrentSection({ ...currentSection, title: e.target.value })
        }
        title={currentSection?.title}
        onCrossClick={() => onDeleteClick(currentSection)}
        onImageClick={() => setShowImages(!showImages)}
        hidePhotoContainer={() => setShowImages(false)}
        deleteDisabled={deleteDisabled}
        defaultActive
      >
        <>
          <Input.RichText
            content={currentSection?.content}
            onChange={(content) => {
              setCurrentSection({ ...currentSection, content: content.trim() });
            }}
          />
          {showImages && (
            <PhotoList
              photos={currentSection?.photos}
              onChange={(photos) =>
                setCurrentSection({ ...currentSection, photos })
              }
            />
          )}
        </>
      </Display.EditableCollapsible>
    </RamsSectionContainer>
  );
};

import { mapToUser } from 'api/User/helpers';
import { WorkOrderItem, WorkOrderSubtype } from 'model/WorkOrderItem';

export const mapToWorkOrderItem = (
  backendWorkOrderItem: any,
): WorkOrderItem => ({
  id: backendWorkOrderItem.id,
  jobId: backendWorkOrderItem.job_id,
  unitTitle: backendWorkOrderItem.unit_title,
  unitTitleEs: backendWorkOrderItem.unit_title_es ?? '',
  units: backendWorkOrderItem.units,
  note: backendWorkOrderItem.note,
  createdAt: new Date(backendWorkOrderItem.created_at),
  createdBy: mapToUser(backendWorkOrderItem.created_by),
  updatedAt: backendWorkOrderItem.updated_at
    ? new Date(backendWorkOrderItem.updated_at)
    : undefined,
  sentAt: backendWorkOrderItem.sent_at
    ? new Date(backendWorkOrderItem.sent_at)
    : undefined,
  subtypeId: backendWorkOrderItem.subtype_id,
  subtype: backendWorkOrderItem.subtype
    ? mapToWorkOrderSubtype(backendWorkOrderItem.subtype)
    : null,
  uuid: backendWorkOrderItem.uuid,
});

const mapToWorkOrderSubtype = (
  backendWorkOrderSubtype: any,
): WorkOrderSubtype => ({
  id: backendWorkOrderSubtype.id,
  title: backendWorkOrderSubtype.title,
  titleEs: backendWorkOrderSubtype.title_es ?? '',
  typeId: backendWorkOrderSubtype.type_id,
  typeTitle: backendWorkOrderSubtype.type_title,
  unitTitle: backendWorkOrderSubtype.unit_title,
  unitTitleEs: backendWorkOrderSubtype.unit_title_es ?? '',
});

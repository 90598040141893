import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useIsMobile, useText } from 'hooks';

import { Card, Button, Input, Modal } from 'components/ui';
import { requestMagicLink } from 'store/actions/session';
import { FormContainer } from '../styled';
import {
  EVENT_FEEDBACK_MODAL_CLICKED,
  EVENT_PAGE_VIEW_LOGIN,
  LoginPages,
} from 'utils/constants';
import RootState from 'model/State/RootState';
import { isBlank, isValidEmail } from 'helpers/utils';
import { LoginForm, PaddedWrapper } from '../Login/styled';
import * as Analytics from 'utils/analytics';
import theme from 'theme';
import { LogoSlogan } from 'components/ui/Logos';
import { ErrorContainer, H1, P } from './styled';

const { colors } = theme;

// RequestMagicLink
export const RequestMagicLink = (): JSX.Element => {
  const dispatch = useDispatch();
  const getText = useText();
  const [email, setEmail] = useState('');
  const [dispatchSent, setDispatchSent] = useState(false);
  const isMobile = useIsMobile();

  const { isLoading, passwordError } = useSelector(
    (state: RootState) => state.session,
  );

  useEffect(() => {
    Analytics.trackPage(EVENT_PAGE_VIEW_LOGIN, { page: LoginPages.MAGIC_LINK });
  }, []);

  useEffect(() => {
    if (passwordError) {
      setDispatchSent(false);
    }
  }, [passwordError]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRequestToken = async () => {
    await dispatch(requestMagicLink(email));
    setDispatchSent(true);
  };

  const isDisabled = isBlank(email) || !isValidEmail(email);

  return (
    <>
      <LogoSlogan />
      <FormContainer onSubmit={handleRequestToken}>
        <LoginForm>
          <PaddedWrapper>
            <H1>{getText('magic_link_title')}</H1>
            <P>{getText('magic_link_body')}</P>

            <Input.Text
              id='email-request-magic-link'
              label={getText('magic_link_email_title')}
              labelColor={colors.white}
              error={passwordError}
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              onEnter={!isDisabled ? handleRequestToken : undefined}
              hasBlackBackground
            />

            {passwordError && (
              <ErrorContainer>
                <Card.ErrorMessage message={passwordError} />
              </ErrorContainer>
            )}

            <Button.Primary
              text={getText('magic_link_title')}
              disabled={isDisabled}
              loading={isLoading}
              onClick={handleRequestToken}
              hasBlackBackground
              extraStyles={
                isMobile
                  ? { width: '80%' }
                  : {
                      width: 'fit-content',
                    }
              }
            />
          </PaddedWrapper>
        </LoginForm>
      </FormContainer>
      {!isLoading && !passwordError && dispatchSent && (
        <Modal.Feedback
          title={getText('magic_link_prompt_title')}
          description={getText('magic_link_prompt_body')}
          linkText={getText('magic_link_ok')}
          linkPath='/'
          event={EVENT_FEEDBACK_MODAL_CLICKED}
          eventProperties={{ source: 'magic-link-requested' }}
        />
      )}
    </>
  );
};

import { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import {
  PlayIcon,
  ThumbnailContainer,
  ThumbnailImage,
  ThumbnailPlaceholder,
} from './styled';

type Props = {
  thumbnail: string;
};

export const LatestActivityThumbnail = ({ thumbnail }: Props): JSX.Element => {
  const [thumbnailURL, setThumbnailURL] = useState<string>();

  useEffect(() => {
    (async () => {
      if (thumbnail) {
        const signedURL = await Storage.get(thumbnail);
        setThumbnailURL(signedURL);
      }
    })();
  }, [thumbnail]);

  const getIcon = () => {
    if (thumbnail.includes('videos')) {
      return <PlayIcon />;
    }
  };

  return (
    <ThumbnailContainer>
      {thumbnailURL ? (
        <>
          <ThumbnailImage url={thumbnailURL} />
          {getIcon()}
        </>
      ) : (
        <ThumbnailPlaceholder />
      )}
    </ThumbnailContainer>
  );
};

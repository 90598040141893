import { LocationInsight } from 'model/State/SiteBriefing';
import {
  BulletPoint,
  BulletPointWrapper,
  ConditionInfoContainer,
  ConditionItem,
  ConditionsContainer,
  ConditionsIcon,
  ConditionsInfo,
  ConditionsTitle,
  DistanceText,
  InfoText,
  InfoTextWrapper,
} from './styled';
import { useText } from 'hooks';

type Props = {
  title: string;
  icon: React.ReactNode | null;
  infoText?: string;
  locationInsights?: LocationInsight[];
  trafficInsights?: string[];
};

export const Conditions = ({
  title,
  icon,
  infoText,
  locationInsights,
  trafficInsights,
}: Props): JSX.Element => {
  const getText = useText();

  return (
    <ConditionsContainer>
      <ConditionsTitle>{title}</ConditionsTitle>
      <ConditionsInfo>
        <>
          {icon && <ConditionsIcon component={icon} />}
          {infoText ? <InfoText>{infoText}</InfoText> : null}
          {locationInsights?.length && locationInsights?.length > 0 ? (
            <ConditionInfoContainer>
              {locationInsights?.map((locationInsight) => (
                <ConditionItem key={`condition-${locationInsight.label}`}>
                  <BulletPointWrapper>
                    <BulletPoint />
                  </BulletPointWrapper>
                  <InfoTextWrapper>
                    <InfoText>{locationInsight.label}</InfoText>
                    {locationInsight.roundedDistanceMetres === null ? null : (
                      <DistanceText>
                        (
                        {getText(
                          'job_details_timeline_action_site_briefing_metres_away',
                          {
                            number: locationInsight.roundedDistanceMetres,
                          },
                        )}
                        )
                      </DistanceText>
                    )}
                  </InfoTextWrapper>
                </ConditionItem>
              ))}
            </ConditionInfoContainer>
          ) : null}
          {trafficInsights?.length && trafficInsights?.length > 0 ? (
            <ConditionInfoContainer>
              {trafficInsights.map((trafficInsight) => (
                <ConditionItem>
                  <InfoText>{trafficInsight}</InfoText>
                </ConditionItem>
              ))}
            </ConditionInfoContainer>
          ) : null}
        </>
      </ConditionsInfo>
    </ConditionsContainer>
  );
};

import {
  SET_CURRENT_USER,
  SIGN_IN,
  SIGN_OUT,
  SESSION_ERROR,
  PASSWORD_ERROR,
  RESET_SESSION_ERRORS,
  SET_SESSION_TOKEN,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SENT,
  COMPLETE_NEW_PASSWORD,
  COMPLETE_NEW_PASSWORD_DONE,
  RESET_PASSWORD,
  RESET_PASSWORD_DONE,
  START_AUTH,
  END_AUTH,
  SET_LAST_REFRESH,
  REQUEST_MAGIC_LINK,
  MAGIC_LINK_REQUESTED,
  TOKEN_SIGN_IN,
  SET_WORKSPACE,
  SET_GLOBAL_LANGUAGE_SETTING,
  UPDATE_WORKSPACE_SETTINGS,
} from '../actions/actionTypes';

const defaultState = {
  currentUser: null,
  isAuthenticating: true,
  isLoading: false,
  error: null,
  passwordError: null,
  isManager: false,
  isBackOffice: false,
  isProjectEngineer: false,
  sessionToken: null,
  depot: null,
  workspaces: null,
  selectedWorkspace: null,
  selectedLanguage: null,
};

const session = (state = defaultState, action) => {
  switch (action.type) {
    case SIGN_IN:
      return { ...state, isLoading: true, error: null };
    case TOKEN_SIGN_IN:
      return { ...state, isLoading: true, error: null };
    case START_AUTH:
      return { ...state, isAuthenticating: true, isLoading: true };
    case END_AUTH:
      return { ...state, isAuthenticating: false, isLoading: false };
    case SET_SESSION_TOKEN:
      return {
        ...state,
        sessionToken: action.sessionToken,
      };
    case SET_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.user,
        isAuthenticating: false,
        isLoading: false,
        error: null,
        isBackOffice: action.user.permissions.includes('backoffice'),
        isManager: action.user.permissions.includes('manager'),
        isProjectEngineer: action.user.permissions.includes('project_engineer'),
        isAllowedAccess:
          action.user.permissions.includes('manager') ||
          action.user.permissions.includes('backoffice'),
        depot: action.user.depot,
        workspaces: action.user.workspaces,
        selectedWorkspace:
          action.user?.workspaces?.find(
            (workspace) =>
              workspace?.uuid === action.user?.selectedWorkspaceUuid,
          ) ?? action.user.workspaces[0],
      };
    }
    case SIGN_OUT: {
      const language = state.selectedLanguage;
      return {
        ...defaultState,
        selectedWorkspace: null,
        selectedLanguage: language,
      };
    }
    case SESSION_ERROR:
      return {
        ...defaultState,
        isAuthenticating: false,
        error: action.error,
      };
    case RESET_SESSION_ERRORS:
      return {
        ...state,
        isLoading: false,
        error: null,
        passwordError: null,
      };
    case PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        passwordError: action.error,
      };
    case RESET_PASSWORD:
    case FORGOT_PASSWORD:
    case COMPLETE_NEW_PASSWORD:
    case REQUEST_MAGIC_LINK:
      return {
        ...state,
        isLoading: true,
        passwordError: null,
      };
    case RESET_PASSWORD_DONE:
    case COMPLETE_NEW_PASSWORD_DONE:
    case FORGOT_PASSWORD_SENT:
    case MAGIC_LINK_REQUESTED:
      return {
        ...state,
        isLoading: false,
        passwordError: null,
      };
    case SET_LAST_REFRESH:
      return {
        ...state,
        lastRefresh: action.datetime,
      };
    case SET_WORKSPACE:
      return {
        ...state,
        selectedWorkspace: action.workspace,
      };
    case UPDATE_WORKSPACE_SETTINGS:
      return {
        ...state,
        selectedWorkspace: {
          ...state.selectedWorkspace,
          settings: { ...state.selectedWorkspace.settings, ...action.data },
        },
      };
    case SET_GLOBAL_LANGUAGE_SETTING: {
      return { ...state, selectedLanguage: action.selectedLanguage };
    }
    default:
      return state;
  }
};

export default session;

import styled from 'styled-components';
import theme from 'theme';

const { mixins, fontSizes, fonts, colors } = theme;

export const ModalContent = styled.div<{ $isMobile?: boolean }>`
  padding: ${({ $isMobile }) =>
    $isMobile ? '25px 15px 10px 15px' : '35px 50px 15px 50px'};
`;

export const TitleContainer = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  padding-bottom: 20px;
`;

export const RiskWarningContainer = styled.div`
  background-color: ${colors.orange};
  padding: 10px;
  font-size: ${fontSizes.m};
`;

export const RiskText = styled.span`
  font-family: ${fonts.bold};
`;

export const OptionsMessage = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  padding: 15px 0;
`;

export const Option = styled.div`
  ${mixins.flexCenter};
  margin-bottom: 10px;
`;

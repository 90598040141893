import { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { TeamMemberSearchEdit } from 'components/ui/TeamMembers/TeamMemberSearchEdit';
import { BackendUser } from 'model/BackendUser';

const { media, mixins } = theme;

// Styled Components
const TeamContainer = styled.div`
  height: 600px;
  ${mixins.flexColumn};
  ${media.sm`
    height: calc(100vh - 65px);
  `}
`;

type Props = {
  setAssignees: (value: BackendUser[]) => void;
};

// TeamJobFlow
const TeamJobFlow = ({ setAssignees }: Props): JSX.Element => {
  const [selectedUsers, setSelectedUsers] = useState<BackendUser[]>([]);

  const afterUserAdded = (selectedUser) => {
    const modifiedUsers = [...selectedUsers, selectedUser];

    setSelectedUsers(modifiedUsers);
    setAssignees(modifiedUsers);
  };

  const afterUserRemoved = (selectedUser) => {
    const modifiedUsers = selectedUsers.filter(
      (user) => user.id !== selectedUser.id,
    );

    setSelectedUsers(modifiedUsers);
    setAssignees(modifiedUsers);
  };

  return (
    selectedUsers && (
      <TeamContainer>
        <TeamMemberSearchEdit
          selectedUsers={selectedUsers}
          userResultCount={3}
          onUserRemove={afterUserRemoved}
          onUserAdd={afterUserAdded}
        />
      </TeamContainer>
    )
  );
};

export default TeamJobFlow;

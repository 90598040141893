import styled from 'styled-components';

export const SortableWrapper = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  justify-content: flex-start;
`;
export const SortableContainer = styled.div`
  padding: 20px;
`;

import { ConfirmModal } from 'components/ui/Modal';
import { useText } from 'hooks';
import { useDispatch } from 'react-redux';
import { setGlobalError } from 'store/slices/notifications';
import * as Logger from 'utils/logger';

type Props = {
  userId: number;
  isVisible: boolean;
  deleteHavsLimit: () => Promise<void>;
  closeModal: () => void;
};

export const DeleteHavsLimitModal = ({
  isVisible,
  deleteHavsLimit,
  closeModal,
}: Props) => {
  const getText = useText();
  const dispatch = useDispatch();

  const removeUser = async () => {
    try {
      await deleteHavsLimit();
      closeModal();
    } catch (error) {
      Logger.error(error);
      dispatch(setGlobalError(getText('havs_users_delete_user_error')));
    }
  };

  return (
    <ConfirmModal
      title={getText('havs_users_delete_user_title')}
      confirmMessage={getText('havs_users_delete_user_description')}
      isVisible={isVisible}
      closeModal={closeModal}
      onButtonClick={removeUser}
      buttonText={getText('havs_users_delete_user_title')}
    />
  );
};

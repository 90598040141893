import {
  INITIAL_FILTERS,
  LEGACY_VIEW,
  LIST_VIEW_TAB,
  MAP_VIEW_TAB,
} from 'utils/constants';
import {
  APPEND_DOCUMENT,
  APPEND_EVIDENCE,
  APPEND_LEGACY_VIEW_JOBS,
  CLEAR_JOBS,
  FETCH_ACTIVE_JOB,
  FETCH_DOCUMENTS,
  FETCH_EVIDENCES,
  FETCH_LEGACY_VIEW_JOBS,
  FETCH_MORE_LEGACY_VIEW_JOBS,
  FETCH_WEATHER_ERROR,
  FETCH_WEATHER_FOR_JOB,
  RESET_ACTIVE_JOB,
  SET_ACTIVE_JOB,
  SET_DOCUMENTS,
  SET_EVIDENCES,
  SET_LEGACY_VIEW_JOBS,
  SET_JOB_FILTERS,
  SET_JOB_PHOTOS,
  SET_WEATHER_FOR_JOB,
  UPDATE_USER_REVIEW_PSI_SCORE,
  FETCH_LOGS,
  SET_LOGS,
  FETCH_WORK_ORDER_ITEMS,
  SET_WORK_ORDER_ITEMS,
  FETCH_PERMITS,
  SET_PERMITS,
  UPDATE_PERMIT,
  SET_LEGACY_VIEW_JOB_STATS,
  FETCH_JOB_BLOCKERS,
  SET_JOB_BLOCKERS,
  SET_TAB,
} from '../actions/actionTypes';
import LegacyViewJobState from 'model/State/LegacyViewJobState';
import Job from 'model/JobData';

const defaultState: LegacyViewJobState = {
  activeJob: null,
  jobs: null,
  total: 0,
  page: 1,
  evidences: [],
  logsByJob: {},
  jobBlockersByJob: {},
  documentsByJob: {},
  workOrderItemsByJob: {},
  permitsByJob: {},
  nextLink: null,
  filters: INITIAL_FILTERS,
  isFetchingActiveJob: false,
  isFetchingJobs: false,
  isFetchingMoreJobs: false,
  jobStats: null,
  mapCanLoad: false,
  weather: null,
  isFetchingWeather: false,
  isEvidencesLoaded: false,
  isFetchingDocuments: false,
  isFetchingLogs: false,
  isFetchingJobBlockers: false,
  isFetchingWorkOrderItems: false,
  isFetchingPermits: false,
  isFirstLoad: false,
};

const jobs = (state = defaultState, action): LegacyViewJobState => {
  switch (action.type) {
    case SET_TAB: {
      if (action.tab === MAP_VIEW_TAB || action.tab === LIST_VIEW_TAB) {
        return {
          ...state,
          jobs: null,
        };
      } else {
        return state;
      }
    }
    case FETCH_LEGACY_VIEW_JOBS:
      return {
        ...state,
        isFetchingJobs: true,
        mapCanLoad: false,
        nextLink: null,
        isFirstLoad: false,
      };
    case CLEAR_JOBS:
      return { ...defaultState };
    case FETCH_MORE_LEGACY_VIEW_JOBS:
      return {
        ...state,
        isFetchingMoreJobs: true,
      };
    case FETCH_ACTIVE_JOB: {
      if (action.jobId === state?.activeJob?.id) {
        return {
          ...state,
          isFetchingActiveJob: true,
        };
      }

      return {
        ...state,
        isFetchingActiveJob: true,
        mapCanLoad: false,
        activeJob: null,
      };
    }
    case SET_LEGACY_VIEW_JOBS:
      return {
        ...state,
        jobs: [...action.jobs],
        total: action.count,
        page: action.page,
        isFetchingJobs: false,
        mapCanLoad: true,
        nextLink: action.nextLink,
      };
    case APPEND_LEGACY_VIEW_JOBS:
      return {
        ...state,
        jobs: [...(state.jobs || []), ...action.jobs],
        isFetchingMoreJobs: false,
        nextLink: action.nextLink,
      };
    case SET_ACTIVE_JOB: {
      if (action.updateAllJobs) {
        const previousJobs = state.jobs ? [...state.jobs] : [];
        const existingIndex = previousJobs.findIndex(
          (existingJob) => existingJob.id === action.activeJob.id,
        );
        // If so, replace in place
        if (existingIndex > -1) {
          previousJobs[existingIndex] = action.activeJob;
          return {
            ...state,
            jobs: [...previousJobs],
            activeJob: { ...state.activeJob, ...action.activeJob },
            isFetchingActiveJob: false,
            mapCanLoad: true,
          };
        }
      }
      // Otherwise just append
      return {
        ...state,
        activeJob: { ...state.activeJob, ...action.activeJob },
        isFetchingActiveJob: false,
        mapCanLoad: true,
      };
    }
    case RESET_ACTIVE_JOB: {
      return { ...state, activeJob: null };
    }
    case SET_JOB_FILTERS: {
      return {
        ...state,
        filters: action.filters,
        isFirstLoad: action.newView === LEGACY_VIEW,
      };
    }
    case SET_LEGACY_VIEW_JOB_STATS: {
      return { ...state, jobStats: action.jobStats };
    }
    case SET_WEATHER_FOR_JOB: {
      return { ...state, weather: action.weather, isFetchingWeather: false };
    }
    case FETCH_WEATHER_FOR_JOB: {
      if (action.jobId === state?.activeJob?.id) {
        return {
          ...state,
          isFetchingWeather: true,
        };
      }

      return { ...state, isFetchingWeather: true, weather: null };
    }
    case FETCH_WEATHER_ERROR: {
      return { ...state, weather: null, isFetchingWeather: false };
    }
    case FETCH_EVIDENCES: {
      return { ...state, isEvidencesLoaded: false };
    }
    case SET_EVIDENCES: {
      return { ...state, evidences: action.evidences, isEvidencesLoaded: true };
    }
    case APPEND_EVIDENCE: {
      return { ...state, evidences: [...state.evidences, action.evidence] };
    }
    case FETCH_DOCUMENTS: {
      return { ...state, isFetchingDocuments: true };
    }
    case SET_DOCUMENTS: {
      return {
        ...state,
        documentsByJob: {
          ...state.documentsByJob,
          [action.jobId]: [...action.documents],
        },
        isFetchingDocuments: false,
      };
    }
    case APPEND_DOCUMENT: {
      const jobDocuments = state.documentsByJob?.[action.jobId] || [];
      return {
        ...state,
        documentsByJob: {
          ...state.documentsByJob,
          [action.jobId]: [...jobDocuments, action.document],
        },
      };
    }
    case FETCH_LOGS: {
      return { ...state, isFetchingLogs: true };
    }
    case SET_LOGS: {
      return {
        ...state,
        logsByJob: {
          ...state.logsByJob,
          [action.jobId]: [...action.logs],
        },
        isFetchingLogs: false,
      };
    }
    case FETCH_JOB_BLOCKERS: {
      return { ...state, isFetchingJobBlockers: true };
    }
    case SET_JOB_BLOCKERS: {
      return {
        ...state,
        jobBlockersByJob: {
          ...state.jobBlockersByJob,
          [action.jobId]: [...action.blockers],
        },
        isFetchingJobBlockers: false,
      };
    }
    case FETCH_WORK_ORDER_ITEMS: {
      return { ...state, isFetchingWorkOrderItems: true };
    }
    case SET_WORK_ORDER_ITEMS: {
      return {
        ...state,
        workOrderItemsByJob: {
          ...state.workOrderItemsByJob,
          [action.jobId]: [...action.workOrderItems],
        },
        isFetchingWorkOrderItems: false,
      };
    }
    case FETCH_PERMITS: {
      return { ...state, isFetchingPermits: true };
    }
    case SET_PERMITS: {
      return {
        ...state,
        permitsByJob: {
          ...state.permitsByJob,
          [action.jobId]: [...action.permits],
        },
        isFetchingPermits: false,
      };
    }
    case UPDATE_PERMIT: {
      const jobPermits = state.permitsByJob?.[action.jobId] || [];
      const updatedPermits = jobPermits.map((permit) =>
        permit?.id === action.permit?.id ? action.permit : permit,
      );
      return {
        ...state,
        permitsByJob: {
          ...state.permitsByJob,
          [action.jobId]: [...updatedPermits],
        },
      };
    }
    case SET_JOB_PHOTOS:
      return {
        ...state,
        activeJob: { ...state.activeJob, photos: action.photos || [] } as Job,
      };
    case UPDATE_USER_REVIEW_PSI_SCORE:
      return {
        ...state,
        activeJob: {
          ...state.activeJob,
          user_review_psi_score_id: action.predictionId,
        } as Job,
      };
    default:
      return state;
  }
};

export default jobs;

import { AssessmentSettings } from 'api';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  ADD_WORKSPACE_CONTROL_TO_HAZARD,
  EDIT_WORKSPACE_CONTROL,
  FETCH_WORKSPACE_CONTROLS_FOR_HAZARD,
  REMOVE_WORKSPACE_CONTROL,
} from 'store/actions/actionTypes';
import { setGlobalError } from 'store/slices/notifications';
import {
  addNewControlToHazardError,
  addNewControlToHazardSuccess,
  editExistingControlError,
  editExistingControlSuccess,
  fetchWorkspaceControlsForHazardError,
  fetchWorkspaceControlsForHazardSuccess,
  removeExistingControlError,
  removeExistingControlSuccess,
} from 'store/actions/settings/controls';
import { updateHazardAssignedControlList } from 'store/actions/settings/hazards';
import { Languages } from 'utils/constants';
import * as Logger from 'utils/logger';
import * as Analytics from 'utils/analytics';
import {
  EVENT_CONTROL_ADDED,
  EVENT_CONTROL_REMOVED,
  EVENT_CONTROL_UPDATED,
} from 'components/views/Protected/Jobs/Settings/constants';
import { selectHazard } from 'store/selectors/settings/hazards';
import { selectOrganizationId } from 'store/selectors';

function* fetchControlsForHazard({ workspaceUuid, hazardId }) {
  try {
    const data = yield AssessmentSettings.getControlsForHazard(
      workspaceUuid,
      hazardId,
    );

    yield put(
      fetchWorkspaceControlsForHazardSuccess(workspaceUuid, hazardId, data),
    );
  } catch (err) {
    Logger.error(err);
    yield put(fetchWorkspaceControlsForHazardError());
    yield put(setGlobalError(err));
  }
}

function* addControlToHazard({ workspaceUuid, hazardId, controlTitles }) {
  const control = {
    title: controlTitles?.[Languages.english.locale],
    title_es: controlTitles?.[Languages.spanish.locale],
  };
  try {
    const hazard = yield select(selectHazard(workspaceUuid, hazardId));
    const organisationId = yield select(selectOrganizationId);
    const data = yield AssessmentSettings.addControlToHazard(
      workspaceUuid,
      hazardId,
      control,
    );

    yield put(addNewControlToHazardSuccess(workspaceUuid, hazardId, data));
    yield put(
      updateHazardAssignedControlList(workspaceUuid, hazardId, data?.id),
    );
    Analytics.trackEvent(EVENT_CONTROL_ADDED, {
      hazardId: hazard?.id,
      hazardName: hazard?.title,
      hazardName_es: hazard?.titleEs,
      isMajor: hazard?.is_major,
      controlId: data?.id,
      controlName: data?.title,
      controlName_es: data?.titleEs,
      workspaceUuid,
      organisationId,
    });
  } catch (err) {
    Logger.error(err);
    yield put(addNewControlToHazardError(control, hazardId));
    yield put(setGlobalError(err));
  }
}

function* editControl({ workspaceUuid, hazardId, controlTitles }) {
  const control = {
    ...controlTitles,
    title: controlTitles?.[Languages.english.locale],
    titleEs: controlTitles?.[Languages.spanish.locale],
  };
  try {
    const hazard = yield select(selectHazard(workspaceUuid, hazardId));
    const organisationId = yield select(selectOrganizationId);
    const data = yield AssessmentSettings.updateControl(
      workspaceUuid,
      hazardId,
      control,
    );

    yield put(editExistingControlSuccess(workspaceUuid, hazardId, data));
    Analytics.trackEvent(EVENT_CONTROL_UPDATED, {
      hazardId: hazard?.id,
      hazardName: hazard?.title,
      hazardName_es: hazard?.titleEs,
      isMajor: hazard?.is_major,
      controlId: data?.id,
      controlName: data?.title,
      controlName_es: data?.titleEs,
      workspaceUuid,
      organisationId,
    });
  } catch (err) {
    Logger.error(err);
    yield put(editExistingControlError(control, hazardId));
    yield put(setGlobalError(err));
  }
}

function* deleteControl({ workspaceUuid, hazardId, control }) {
  try {
    const hazard = yield select(selectHazard(workspaceUuid, hazardId));
    const organisationId = yield select(selectOrganizationId);
    yield AssessmentSettings.deleteControl(
      workspaceUuid,
      hazardId,
      control?.id,
    );

    yield put(
      removeExistingControlSuccess(workspaceUuid, hazardId, control?.id),
    );
    Analytics.trackEvent(EVENT_CONTROL_REMOVED, {
      hazardId: hazard?.id,
      hazardName: hazard?.title,
      hazardName_es: hazard?.titleEs,
      isMajor: hazard?.is_major,
      controlId: control?.id,
      controlName: control?.title,
      controlName_es: control?.titleEs,
      workspaceUuid,
      organisationId,
    });
  } catch (err) {
    Logger.error(err);
    yield put(removeExistingControlError(control));
    yield put(setGlobalError(err));
  }
}

export default function* workspaceControlsWatcher() {
  yield takeLatest(FETCH_WORKSPACE_CONTROLS_FOR_HAZARD, fetchControlsForHazard);
  yield takeLatest(ADD_WORKSPACE_CONTROL_TO_HAZARD, addControlToHazard);
  yield takeLatest(EDIT_WORKSPACE_CONTROL, editControl);
  yield takeLatest(REMOVE_WORKSPACE_CONTROL, deleteControl);
}

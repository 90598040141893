import styled from 'styled-components';
import theme from 'theme';
import { Dates } from 'helpers';
import { useText } from 'hooks';
import { BackendUser } from 'model/BackendUser';
const { fonts, fontSizes } = theme;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 17px solid ${theme.colors.lightGrey};
  padding: 16px 0px;
`;

const Section = styled.div`
  margin: 0px 100px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 5px solid ${theme.colors.offWhite};
  &:last-of-type {
    border: none;
  }
`;

const Label = styled.p`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
`;

const UserLabel = styled.p`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  padding-top: 24px;
`;

const Value = styled.p`
  font-family: ${fonts.lightItalic};
  font-size: ${fontSizes.m};
`;

type ItemPropTypes = {
  label: string;
  action: string;
  comments: string;
  created_by: BackendUser;
  created_at: string;
};

const InterventionList = ({ items }: { items: ItemPropTypes[] }) => {
  const getText = useText();

  const actions = {
    'stop-work': getText('fatigue_manager_risk_assessment_stop_work'),
    'relief-arranged': getText(
      'fatigue_manager_risk_assessment_relief_arranged',
    ),
    'no-action': getText('fatigue_manager_risk_assessment_no_action'),
  };

  return (
    <FlexColumn>
      {items?.map((item) => (
        <div key={item.created_at}>
          <Item>
            <Section>
              <Label>
                {getText(
                  'fatigue_manager_risk_assessment_action_or_intervention',
                )}
              </Label>
              <Value>{actions[item.action]}</Value>
            </Section>
            <Section key={item.label}>
              <Label>
                {getText(
                  'fatigue_manager_risk_assessment_intervention_comment',
                )}
              </Label>
              <Value>{item.comments}</Value>
              <UserLabel>
                {item.created_by.first_name} {item.created_by.last_name},{' '}
                {Dates.formatDateTime(
                  item.created_at,
                  getText('date_today'),
                  getText('date_yesterday'),
                )}
              </UserLabel>
            </Section>
          </Item>
        </div>
      ))}
    </FlexColumn>
  );
};

export default InterventionList;

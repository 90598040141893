import { Dispatch, SetStateAction } from 'react';
import {
  BlankButton,
  Container,
  Field,
  InfoBox,
  InfoIcon,
  CrossIcon,
  Notice,
  Value,
  Separator,
} from './styled';

type Props = {
  displayNotice: boolean;
  setDisplayNotice: Dispatch<SetStateAction<boolean>>;
};

export const Header = ({ displayNotice, setDisplayNotice }: Props) => {
  return (
    <>
      <Container>
        <InfoBox>
          <Field>Title: </Field>
          <Value>placeholder title</Value>
        </InfoBox>
        <Separator />
        <InfoBox>
          <Field>Job types: </Field>
          <Value>placeholder job types</Value>
        </InfoBox>
      </Container>
      {displayNotice && (
        <Notice>
          <InfoBox>
            <InfoIcon /> Use the icons to edit, remove or drag tasks.
          </InfoBox>
          <BlankButton>
            <CrossIcon onClick={() => setDisplayNotice(false)} />
          </BlankButton>
        </Notice>
      )}
    </>
  );
};

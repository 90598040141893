import JobType from 'model/JobType';
import RootState from 'model/State/RootState';
import { createSelector } from 'reselect';

export const selectJobTypes = (state: RootState) => state.jobTypes;
export const selectJobSubTypes = (state: RootState) =>
  state.jobTypes.jobSubtypes;
const selectJobSubtypesByTypeId = createSelector(
  selectJobSubTypes,
  (_: RootState, typeId: number | undefined) => typeId,
  (jobSubtypes: JobType[], typeId: number | undefined) =>
    typeId ? jobSubtypes?.filter((subtype) => subtype?.type_id === typeId) : [],
);
export const selectSubtypesByType =
  (typeId: number | undefined) => (state: RootState) =>
    selectJobSubtypesByTypeId(state, typeId);

export enum JobField {
  LOCATION = 'location',
  PINNED_LOCATION = 'pinned_location',
  LOCATION_DESCRIPTION = 'location_description',
  POSTCODE = 'postcode',
  ADDRESS = 'address',
  JOB_NUMBER = 'job_number',
  ORIGINATING_NUMBER = 'originating_number',
  PROJECT_NUMBER = 'project_number',
  TYPE = 'type',
  TYPE_OTHER = 'type_other',
  SUBTYPE = 'subtype',
  SUBTYPE_OTHER = 'subtype_other',
  DEPOT = 'depot',
}

import styled from 'styled-components';
import { useJobs, useText } from 'hooks';

import { Modal as StyledModal } from 'components/ui';
import ResolveBlocker from './ResolveBlocker';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Styled Components
const Container = styled.div`
  min-height: 210px;
`;

// Blocker.ResolveModal
const ResolveModal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { jobId, blockerId } = useParams();
  const getText = useText();
  const { navigateToOverviewTabOrJob } = useJobs();

  const handleGoBack = () => {
    navigate(`/jobs/${jobId}/blockers/${blockerId}`, {
      state: location.state,
    });
  };

  return (
    <StyledModal.Base
      isOpen
      title={getText('job_blocker_resolution_title')}
      onClose={() => navigateToOverviewTabOrJob(`/jobs/${jobId}/blockers`)}
      onBack={handleGoBack}
      width='850px'
    >
      <Container>
        <ResolveBlocker />
      </Container>
    </StyledModal.Base>
  );
};

export default ResolveModal;

export enum SummaryItemBackendTitle {
  JOB_PERFORMED = 'Job Performed',
  PENDING = 'Pending',
  NOTEWORTHY = 'Noteworthy',
}

export enum SummaryItemTitle {
  WORK_COMPLETED = 'Work completed',
  WORK_PENDING = 'Work pending',
  KEY_INFORMATION = 'Key information',
}

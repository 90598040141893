import { useState } from 'react';
import { Input } from 'components/ui';
import { useHazards, useText } from 'hooks';
import { useSelector } from 'react-redux';
import { HazardList } from './Identification/HazardList';
import { SearchContainer } from './styled';
import { selectActiveRamsAssessment } from 'store/slices/ramsAssessments';

// SearchHazard
export const SearchHazard = (): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  const hazards = useHazards();
  const getText = useText();
  const assessment = useSelector(selectActiveRamsAssessment);

  const handleFilterChange = (newSearch) => {
    setSearch(newSearch.toLowerCase());
  };

  const getHazards = () =>
    hazards?.filter(
      (hazard) =>
        hazard.title.toLowerCase().indexOf(search) > -1 &&
        !assessment?.hazards.some(
          (assessmentHazard) => assessmentHazard.id === hazard.id,
        ),
    );

  return (
    <SearchContainer>
      <Input.Text
        id='search'
        label=''
        placeholder={getText('description_hint')}
        value={search}
        onChange={(e) => handleFilterChange(e.target.value)}
      />
      <HazardList hazards={getHazards()} />
    </SearchContainer>
  );
};

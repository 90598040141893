import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useText } from 'hooks';
import { Notification, ToastType } from './constants';
import { ToastBase } from './ToastBase';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetFileDownloadSuccess,
  selectFileDownloadSuccessList,
} from 'store/slices/notifications';
import FileDownloadSuccessItem from 'model/State/notifications/FileDownloadSuccessItem';
import { FileNameListItem } from './styled';

export const FileDownloadSuccessToast = (): JSX.Element => {
  const successList = useSelector(selectFileDownloadSuccessList);
  const getText = useText();
  const dispatch = useDispatch();
  const CONTAINER_ID = 'file-download-success-container';
  const [notificationList, setNotificationList] = useState<Notification[]>([]);

  useEffect(() => {
    setNotificationList(
      successList.map((success: FileDownloadSuccessItem) => ({
        id: success.id,
        title: getText('pdf_export_downloading'),
        ...(success.fileName && {
          customComponent: (
            <FileNameListItem>{success.fileName}</FileNameListItem>
          ),
        }),
        canBeDismissed: false,
      })),
    );
  }, [successList]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClose = (id: number) => {
    dispatch(resetFileDownloadSuccess(id));
  };

  return (
    <ToastBase
      containerId={CONTAINER_ID}
      type={ToastType.SUCCESS}
      notifications={notificationList}
      onClose={(id) => onClose(id)}
      autoClose={5000}
    />
  );
};

import { capitalizeFullName } from 'helpers/names';
import { Assessments } from 'model/Assessment';
import { User } from 'model/User';

export const mapToUser = (backendUser: any): User => {
  return {
    id: backendUser.id,
    firstName: backendUser.first_name,
    lastName: backendUser.last_name,
    fullName: capitalizeFullName(backendUser.first_name, backendUser.last_name),
    email: backendUser.email ?? '',
    role: backendUser.role ?? '',
    isDeviceless: backendUser.is_deviceless ?? false,
    phoneNumber: backendUser.phone_number ?? null,
    signedAt: backendUser?.signed_at ? new Date(backendUser.signed_at) : null,
    notifiedAt: backendUser?.notified_at
      ? new Date(backendUser.notified_at)
      : null,
    signature: backendUser?.signature
      ? Assessments.mapToSignature(backendUser.signature)
      : null,
    externalId: backendUser?.external_id ?? null,
  };
};

import { Description, Image, WeatherContainer } from './styled';

type Props = {
  degree: number;
  description: string;
  icon: string;
  isMobile?: boolean;
};

// Weather
export const Weather = ({
  degree,
  description,
  icon,
  isMobile = false,
}: Props): JSX.Element => (
  <WeatherContainer $isMobile={isMobile}>
    <Description>
      {Math.round(degree)}°, {description}
    </Description>
    <Image
      $isMobile={isMobile}
      src={`http://openweathermap.org/img/w/${icon}.png`}
      alt={description}
    />
  </WeatherContainer>
);

import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
import { Button, Modal } from 'components/ui';

const { fontSizes, fonts } = theme;

// Styled Components
const Title = styled.div<{ variant?: string }>`
  ${({ variant }) =>
    variant === 'image'
      ? `font-size: ${fontSizes.xl}; font-family: ${fonts.bo}`
      : `font-size: ${fontSizes.default};`}

  margin: 0px 0px 20px 13px;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: 10px auto;
  padding: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
};

// DigitalSignatureInfoModal
const DigitalSignatureInfoModal = ({
  isVisible,
  setIsVisible,
}: Props): JSX.Element | null => {
  const getText = useText();

  if (!isVisible) {
    return null;
  }

  return (
    <Modal.Small
      isOpen={isVisible}
      onClose={() => setIsVisible(false)}
      topMargin={25}
    >
      <ModalContainer>
        <Title>
          {getText('workspace_settings_digital_signature_info_title')}
        </Title>
        <ButtonContainer>
          <Button.Confirm
            onClick={() => setIsVisible(false)}
            text={getText('ok').toLocaleUpperCase()}
            width='130px'
          />
        </ButtonContainer>
      </ModalContainer>
    </Modal.Small>
  );
};

export default DigitalSignatureInfoModal;

import { useState } from 'react';
import styled from 'styled-components';
import { useIsMobile, useJobs, useText } from 'hooks';
import { Button, Input, Navbar } from 'components/ui';
import theme from 'theme';
import { Permit } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalLanguageSetting, selectPermit } from 'store/selectors';
import { useParams } from 'react-router-dom';
import { updatePermit } from 'store/actions/jobs';

const { colors } = theme;

// Styled Components
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InputWrapper = styled.div`
  padding: 25px 30px;
`;

const Rejection = (): JSX.Element => {
  const [comment, setComment] = useState<string>('');
  const { navigateToOverviewTabOrJob } = useJobs();
  const { jobId, permitId } = useParams();
  const isMobile = useIsMobile();
  const getText = useText();
  const dispatch = useDispatch();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const permit = useSelector(selectPermit(jobId, permitId));

  const rejectPermit = async () => {
    const data = await Permit.approve(permit?.id, false, comment);
    if (data) {
      dispatch(updatePermit(jobId, data));
      navigateToOverviewTabOrJob(`/jobs/${jobId}/permits`);
    }
  };

  return (
    <>
      <Navbar
        title={
          permit &&
          (permit?.type[`title_${globalAppLanguage}`] || permit?.type?.title)
        }
        defaultBackPath={`/jobs/${jobId}/permits/${permitId}`}
        mobileOnly
      />
      <Container>
        <InputWrapper>
          <Input.TextArea
            id='comments-reject-permit'
            label={getText('job_permits_rejection_reason')}
            value={comment}
            inputStyle={{
              outlineColor: colors.grey,
              height: '200px',
            }}
            onChange={(e) => setComment(e.target.value)}
            maxLength={255}
          />
        </InputWrapper>
        <Button.Primary
          text={getText('job_permits_reject_button')}
          extraStyles={
            isMobile
              ? { position: 'absolute', bottom: '0' }
              : {
                  position: 'sticky',
                  bottom: '0',
                  marginTop: 'auto',
                  flex: '0 0 55px',
                }
          }
          onClick={rejectPermit}
          disabled={!comment.trim()}
        />
      </Container>
    </>
  );
};

export default Rejection;

import { MobileContainer, MobileTab } from './styled';

type TabPropTypes = {
  text: string;
  to: string;
  isActive?: boolean;
};

type Props = {
  left: TabPropTypes;
  right: TabPropTypes;
};

// Tabs.Mobile
export const Mobile = ({ left, right }: Props): JSX.Element => (
  <MobileContainer>
    <MobileTab to={left.to} isActive={left.isActive}>
      {left.text}
    </MobileTab>

    <MobileTab to={right.to} isActive={right.isActive}>
      {right.text}
    </MobileTab>
  </MobileContainer>
);

import {
  ADD_JOB_SUBTYPE_ERROR,
  ADD_JOB_SUBTYPE_SUCCESS,
  EDIT_JOB_SUBTYPE_ERROR,
  EDIT_JOB_SUBTYPE_SUCCESS,
  FETCH_WORKSPACE_JOB_SUBTYPES,
  FETCH_WORKSPACE_JOB_SUBTYPES_ERROR,
  FETCH_WORKSPACE_JOB_SUBTYPES_SUCCESS,
  REMOVE_JOB_SUBTYPE_ERROR,
  REMOVE_JOB_SUBTYPE_SUCCESS,
  RESET_JOB_SUBTYPE_ERRORS,
  SAVE_JOB_SUBTYPE_CHANGES,
  SAVE_JOB_SUBTYPE_CHANGES_ERROR,
  SAVE_JOB_SUBTYPE_CHANGES_SUCCESS,
} from '../actionTypes';

export const fetchWorkspaceJobSubtypes = (workspaceUuid, jobTypeId) => ({
  type: FETCH_WORKSPACE_JOB_SUBTYPES,
  workspaceUuid,
  jobTypeId,
});

export const fetchWorkspaceJobSubtypesSuccess = (workspaceUuid, data) => ({
  type: FETCH_WORKSPACE_JOB_SUBTYPES_SUCCESS,
  workspaceUuid,
  data,
});

export const fetchWorkspaceJobSubtypesError = () => ({
  type: FETCH_WORKSPACE_JOB_SUBTYPES_ERROR,
});

export const saveJobSubtypeChanges = (
  workspaceUuid,
  jobTypeId,
  jobSubtypes,
) => ({
  type: SAVE_JOB_SUBTYPE_CHANGES,
  workspaceUuid,
  jobTypeId,
  jobSubtypes,
});

export const saveJobSubtypeChangesSuccess = () => ({
  type: SAVE_JOB_SUBTYPE_CHANGES_SUCCESS,
});

export const saveJobSubtypeChangesError = () => ({
  type: SAVE_JOB_SUBTYPE_CHANGES_ERROR,
});

export const addNewJobSubtypeSuccess = (workspaceUuid, data) => ({
  type: ADD_JOB_SUBTYPE_SUCCESS,
  workspaceUuid,
  data,
});

export const addNewJobSubtypeError = (data) => ({
  type: ADD_JOB_SUBTYPE_ERROR,
  data,
});

export const editExistingJobSubtypeSuccess = (workspaceUuid, data) => ({
  type: EDIT_JOB_SUBTYPE_SUCCESS,
  workspaceUuid,
  data,
});

export const editExistingJobSubtypeError = (data) => ({
  type: EDIT_JOB_SUBTYPE_ERROR,
  data,
});

export const removeExistingJobSubtypeSuccess = (workspaceUuid, id) => ({
  type: REMOVE_JOB_SUBTYPE_SUCCESS,
  workspaceUuid,
  id,
});

export const removeExistingJobSubtypeError = (data) => ({
  type: REMOVE_JOB_SUBTYPE_ERROR,
  data,
});

export const resetJobSubtypeErrors = () => ({
  type: RESET_JOB_SUBTYPE_ERRORS,
});

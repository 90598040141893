import React, { MouseEvent } from 'react';
import { CSSProperties } from 'styled-components';
import * as Analytics from 'utils/analytics';
import { Button } from './styled';

type Props = {
  text: string;
  onClick: () => {};
  width?: string;
  minWidth?: string;
  IconComponent?: React.ElementType;
  disabled?: boolean;
  loading?: boolean;
  variant?: string;
  extraStyles?: CSSProperties;
};

// Button.Confirm
export const ConfirmButton = Analytics.withTrackEvent(
  ({
    text,
    onClick,
    IconComponent,
    width,
    minWidth,
    disabled = false,
    loading = false,
    variant,
    extraStyles,
  }: Props): JSX.Element => (
    <Button
      width={width}
      minWidth={minWidth}
      disabled={disabled}
      disableRipple={loading}
      onClick={
        loading
          ? () => null
          : (event: MouseEvent<HTMLElement>) => {
              event.preventDefault();
              event.stopPropagation();
              onClick();
            }
      }
      variant={variant}
      style={extraStyles}
    >
      {text}
      {IconComponent && <IconComponent height='25px' width='25px' />}
    </Button>
  ),
);

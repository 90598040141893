import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
import { useSelector } from 'react-redux';
import {
  selectWorkspaceSettings,
  selectGlobalLanguageSetting,
  selectFormOptions,
} from 'store/selectors';
import JobData from 'model/JobData';
import { getLabelByLocale } from 'helpers/utils';
import PsiFeedback from './PsiFeedback';
import { StatusSelect } from '../Overview/components/StatusSelect';

const { fonts, fontSizes, mixins } = theme;

// Styled Components
const Title = styled.span`
  display: block;
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 25px !important;
`;

const AttributesContainer = styled.div`
  ${mixins.flexColumn};
`;

const Attribute = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px !important;
  line-height: ${fontSizes.xl};
`;

const AttributeLabel = styled.div`
  font-family: ${fonts.bold};
  width: 50%;
`;
const AttributeValue = styled.div`
  width: 50%;
`;

// JobDetails
export const JobDetails = ({ activeJob }: { activeJob: JobData }) => {
  const getText = useText();
  const {
    work_order_number_label,
    work_order_number_label_es,
    originating_number_label,
    originating_number_label_es,
    project_number_label,
    project_number_label_es,
    job_subtype_label,
    job_subtype_label_es,
    locations_enable,
    address_label,
    address_label_es,
    pin_location_enabled,
    postcode_label,
    postcode_label_es,
  } = useSelector(selectWorkspaceSettings);
  const formOptions = useSelector(selectFormOptions);

  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  return (
    <>
      <Title>{getText('job_detail_overview_job_details')}</Title>

      <PsiFeedback />

      <AttributesContainer>
        {activeJob.status && formOptions?.statuses && (
          <Attribute>
            <AttributeLabel>
              {getText('job_detail_overview_status')}
            </AttributeLabel>
            <AttributeValue>
              <StatusSelect />
            </AttributeValue>
          </Attribute>
        )}

        {address_label && (
          <Attribute>
            <AttributeLabel>
              {getLabelByLocale(globalAppLanguage, {
                address_label,
                address_label_es,
              })}
              :
            </AttributeLabel>
            <AttributeValue>{activeJob.address}</AttributeValue>
          </Attribute>
        )}
        {postcode_label && activeJob.postcode && (
          <Attribute>
            <AttributeLabel>
              {getLabelByLocale(globalAppLanguage, {
                postcode_label,
                postcode_label_es,
              })}
              :
            </AttributeLabel>
            <AttributeValue>{activeJob.postcode}</AttributeValue>
          </Attribute>
        )}
        {work_order_number_label && (
          <Attribute>
            <AttributeLabel>
              {getLabelByLocale(globalAppLanguage, {
                work_order_number_label,
                work_order_number_label_es,
              })}
              :
            </AttributeLabel>
            <AttributeValue>{activeJob.job_number}</AttributeValue>
          </Attribute>
        )}
        {originating_number_label && (
          <Attribute>
            <AttributeLabel>
              {getLabelByLocale(globalAppLanguage, {
                originating_number_label,
                originating_number_label_es,
              })}
              :
            </AttributeLabel>
            <AttributeValue>{activeJob.originating_number}</AttributeValue>
          </Attribute>
        )}
        {project_number_label && (
          <Attribute>
            <AttributeLabel>
              {getLabelByLocale(globalAppLanguage, {
                project_number_label,
                project_number_label_es,
              })}
              :
            </AttributeLabel>
            <AttributeValue>{activeJob.project_number}</AttributeValue>
          </Attribute>
        )}
        <Attribute>
          <AttributeLabel>
            {getText('job_detail_overview_job_type')}
          </AttributeLabel>
          <AttributeValue>
            {activeJob?.type[`title_${globalAppLanguage}`] ||
              activeJob?.type.title}
          </AttributeValue>
        </Attribute>
        {activeJob.type_other && (
          <Attribute>
            <>
              <AttributeLabel>
                {getText('job_detail_overview_job_type_description')}
              </AttributeLabel>
              <AttributeValue>{activeJob.type_other}</AttributeValue>
            </>
          </Attribute>
        )}
        {activeJob?.subtype?.title && job_subtype_label && (
          <Attribute>
            <AttributeLabel>
              {getLabelByLocale(globalAppLanguage, {
                job_subtype_label,
                job_subtype_label_es,
              })}
              :
            </AttributeLabel>
            <AttributeValue>
              {activeJob.subtype[`title_${globalAppLanguage}`] ||
                activeJob.subtype.title}
            </AttributeValue>
          </Attribute>
        )}
        {activeJob.subtype_other && (
          <Attribute>
            <>
              <AttributeLabel>
                {getText('job_detail_overview_job_subtype_description')}
              </AttributeLabel>
              <AttributeValue>{activeJob.subtype_other}</AttributeValue>
            </>
          </Attribute>
        )}
        <Attribute>
          <AttributeLabel>
            {getText('job_detail_overview_job_depot')}
          </AttributeLabel>
          <AttributeValue>{activeJob?.depot?.title}</AttributeValue>
        </Attribute>
        {locations_enable && activeJob.predefined_location && (
          <Attribute>
            <AttributeLabel>
              {getText('job_details_overview_location')}
            </AttributeLabel>
            <AttributeValue>
              {activeJob.predefined_location.name}
            </AttributeValue>
          </Attribute>
        )}
        {activeJob.latitude && activeJob.longitude && (
          <Attribute>
            <AttributeLabel>
              {getText('job_detail_overview_job_gps')}
            </AttributeLabel>
            <AttributeValue>
              {activeJob.latitude}, {activeJob.longitude}
            </AttributeValue>
          </Attribute>
        )}
        {pin_location_enabled && activeJob.location_description && (
          <Attribute>
            <AttributeLabel>
              {getText('job_details_overview_location_description')}
            </AttributeLabel>
            <AttributeValue>{activeJob.location_description}</AttributeValue>
          </Attribute>
        )}
      </AttributesContainer>
    </>
  );
};

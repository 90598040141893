import HavsSignOffData from './HavsSignOffData';

export enum HavsComplianceFields {
  USER_ID = 'user_id',
  DATE_RANGE = 'date_range',
  FULL_NAME = 'full_name',
  SUBMISSIONS = 'submissions',
  ACTION = 'action',
  POINTS_BREACHES = 'points_breaches',
  SIGNED_OFF = 'signed_off',
  REQUIRING_SIGN_OFF = 'requiring_sign_off',
  COMMENTS = 'comments',
}

export type HavsComplianceData = {
  id: string;
  [HavsComplianceFields.USER_ID]: string;
  [HavsComplianceFields.FULL_NAME]: string;
  [HavsComplianceFields.DATE_RANGE]: string;
  [HavsComplianceFields.SUBMISSIONS]: number;
  [HavsComplianceFields.POINTS_BREACHES]: number;
  [HavsComplianceFields.SIGNED_OFF]: HavsSignOffData[];
  [HavsComplianceFields.REQUIRING_SIGN_OFF]: boolean;
  [HavsComplianceFields.COMMENTS]: boolean;
};

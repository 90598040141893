import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import { memo } from 'react';
import Sort from 'model/Sort';
import { getSortModel } from 'helpers/table';
import { Button, Loader } from 'components/ui';
import { SortOrder } from 'model/enum/SortOrder';
import {
  ButtonsWrapper,
  EmptyPlaceholderSubtitle,
  EmptyPlaceholderTitle,
  EmptyTableContainer,
  LoaderContainer,
  StyledDataGrid,
} from '../styled';
import { useText } from 'hooks';
import { Cross, Pencil } from 'assets/icons';
import { getTitleForRole } from '../helpers';
import Settings from 'model/Settings';
import { formatDateTime } from 'helpers/dates';
import {
  HavsLimit,
  HavsTableData,
  HavsTableFields,
} from 'model/AdminPanel/HavsSettings/HavsUser';
import { capitalizeFullName } from 'helpers/names';

type MemoizedTableProps = {
  isLoading: boolean;
  rows?: HavsTableData[];
  columns: GridColDef<any, any, any>[];
  sort: Sort | null;
  onSortChange: (value: Sort) => void;
  title: string;
  subtitle: string;
  isSkeleton?: boolean;
};

const MemoizedTable = memo(
  ({
    isLoading,
    isSkeleton = false,
    rows,
    columns,
    sort,
    onSortChange,
    title,
    subtitle,
  }: MemoizedTableProps): JSX.Element => {
    const handleSortChange = (newSort) => {
      if (
        sort &&
        onSortChange &&
        JSON.stringify(newSort) !== JSON.stringify(sort)
      ) {
        onSortChange(getSortModel(sort, newSort));
      }
    };

    return (
      <StyledDataGrid
        rows={rows}
        columns={columns}
        loading={isLoading}
        getRowHeight={() => 'auto'}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableExtendRowFullWidth
        disableRowSelectionOnClick
        hideFooterPagination
        isSkeleton={isSkeleton}
        rowsPerPageOptions={[]}
        sortingOrder={[SortOrder.ASCENDING, SortOrder.DESCENDING]}
        sortingMode='server'
        sortModel={
          sort
            ? [
                {
                  field: sort?.field,
                  sort: sort?.order,
                },
              ]
            : []
        }
        onSortModelChange={(model: GridSortModel) => handleSortChange(model[0])}
        slots={{
          loadingOverlay: () => (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ),
          noRowsOverlay: () => (
            <EmptyTableContainer>
              <EmptyPlaceholderTitle>{title}</EmptyPlaceholderTitle>
              <EmptyPlaceholderSubtitle>{subtitle}</EmptyPlaceholderSubtitle>
            </EmptyTableContainer>
          ),
        }}
      />
    );
  },
);

type Props = {
  isLoading: boolean;
  data: HavsLimit[];
  sort: Sort;
  changeSort: (sort: Sort) => void;
  language: string;
  workspaceSettings: Settings;
  openEditModal: (havsUserId: number) => void;
  openDeleteModal: (havsUserId: number) => void;
  hasActiveFilters: boolean;
};

export const HavsLimitsTable = ({
  isLoading,
  data,
  sort,
  changeSort,
  language,
  workspaceSettings,
  openEditModal,
  openDeleteModal,
  hasActiveFilters,
}: Props) => {
  const getText = useText();

  const columns: GridColDef[] = [
    {
      headerName: getText('havs_users_table_header_fieldworker'),
      field: HavsTableFields.FIELDWORKER,
      cellClassName: 'big-cell',
      headerClassName: 'big-cell',
    },
    {
      headerName: getText('havs_users_table_header_havs_limit'),
      field: HavsTableFields.HAVS_LIMIT,
      cellClassName: 'small-cell',
      headerClassName: 'small-cell',
    },
    {
      headerName: getText('havs_users_table_header_role'),
      field: HavsTableFields.ROLE,
      cellClassName: 'medium-cell',
      headerClassName: 'medium-cell',
    },
    {
      headerName: getText('havs_users_table_header_depot'),
      field: HavsTableFields.DEPOT,
      cellClassName: 'small-cell',
      headerClassName: 'small-cell',
    },
    {
      headerName: getText('havs_users_table_header_manager'),
      field: HavsTableFields.MANAGER,
      cellClassName: 'medium-cell',
      headerClassName: 'medium-cell',
    },
    {
      headerName: getText('havs_users_table_header_last_updated'),
      field: HavsTableFields.LAST_UPDATED,
      cellClassName: 'medium-cell',
      headerClassName: 'medium-cell',
    },
    {
      headerName: getText('havs_users_table_header_updated_by'),
      field: HavsTableFields.UPDATED_BY,
      cellClassName: 'small-cell',
      headerClassName: 'small-cell',
    },
    {
      headerName: getText('havs_users_table_header_action'),
      field: HavsTableFields.ACTION,
      cellClassName: 'small-cell',
      headerClassName: 'small-cell',
      sortable: false,
      renderCell: (props: GridRenderCellParams) => (
        <ButtonsWrapper>
          <Button.Confirm
            onClick={() => openEditModal(props.row.userId)}
            IconComponent={Pencil}
            width='50px'
          />
          <Button.Confirm
            onClick={() => openDeleteModal(props.row.userId)}
            IconComponent={Cross}
            width='50px'
          />
        </ButtonsWrapper>
      ),
    },
  ];

  const rows: HavsTableData[] = data?.map(
    (item: HavsLimit): HavsTableData => ({
      id: `havs-limit-${item.user.id}`,
      userId: item?.user?.id,
      fieldworker: capitalizeFullName(
        item?.user?.firstName,
        item?.user?.lastName,
      ),
      havsLimit: item?.havsLimit,
      role: getTitleForRole(item?.user?.role, language, workspaceSettings),
      depot: item?.user?.depot?.title,
      manager: capitalizeFullName(
        item?.manager?.firstName,
        item?.manager?.lastName,
      ),
      lastUpdated: formatDateTime(item?.updatedAt),
      updatedBy: capitalizeFullName(
        item?.updatedBy?.firstName,
        item?.updatedBy?.lastName,
      ),
    }),
  );

  return (
    <MemoizedTable
      isLoading={isLoading}
      columns={columns}
      rows={rows}
      sort={sort}
      onSortChange={changeSort}
      title={
        hasActiveFilters
          ? getText('havs_users_table_no_results_with_filters_title')
          : getText('havs_users_table_no_results_without_filters_title')
      }
      subtitle={
        hasActiveFilters
          ? getText('havs_users_table_no_results_with_filters_subtitle')
          : getText('havs_users_table_no_results_without_filters_subtitle')
      }
    />
  );
};

import styled from 'styled-components';
import theme from 'theme';
import { Separator } from 'components/ui/Misc';
import { WidgetKind } from 'utils/constants';
import { useParams } from 'react-router-dom';
import UserFormAnalyticsParameter from 'model/Forms/UserFormAnalyticsParameters';
import { Video } from 'model/Media/Video';
import { Photo } from 'model/Media/Photo';
import { FormDetail } from 'model/Forms/FormDetail';
import MultiSelect from './Widgets/MultiSelect';
import PhotoUpload from './Widgets/PhotoUpload';
import VideoUpload from './Widgets/VideoUpload';
import UnaryCheckbox from './Widgets/UnaryCheckbox';
import BinaryRadioButton from './Widgets/BinaryRadioButton';
import TextField from './Widgets/TextField';
import TextArea from './Widgets/TextArea';
import SingleSelect from './Widgets/SingleSelect';
import UserSelector from './Widgets/UserSelector';
import DateSelector from './Widgets/DateSelector';
import InstructionWidget from './Widgets/InstructionWidget';
import Header from './Widgets/Header';
import CalculatedWidget from './Widgets/CalculatedWidget';
import React from 'react';
import { isFormSectionVisible } from 'model/FatigueManager/helpers';
import { CustomFormClass } from 'model/Form';

const { breakpoints, colors, fonts, fontSizes } = theme;

// Styled components
const SectionTitle = styled.div`
  font-family: ${fonts.bold};
  color: ${colors.black};
  font-size: ${fontSizes.xxl};
  padding: 0 25px 40px 25px;
  @media only screen and (max-width: ${breakpoints.sm}px) {
    margin-bottom: 30px;
    padding: 0 15px 0 15px;
  }
`;

const FieldContainer = styled.div<{ $isSeparator?: boolean }>`
  padding: ${({ $isSeparator }) =>
    $isSeparator ? '0 0 40px 0' : '0 25px 40px 25px'};
  @media only screen and (max-width: ${breakpoints.sm}px) {
    padding: ${({ $isSeparator }) =>
      $isSeparator ? '0 0 30px 0' : '0 15px 30px 15px'};
  }
`;

type Props = {
  form: FormDetail;
  photos?: Photo[];
  videos?: Video[];
  userFormParameters?: UserFormAnalyticsParameter;
};

const FormDetails = ({
  form,
  photos = [],
  videos = [],
  userFormParameters,
}: Props): JSX.Element | null => {
  const params = useParams();

  const renderField = (field) => {
    switch (field.kind) {
      case WidgetKind.MULTI_SELECT_CUSTOM_TEXT:
      case WidgetKind.MULTI_SELECT:
        return <MultiSelect field={field} />;
      case WidgetKind.PHOTO_UPLOAD:
        return <PhotoUpload field={field} photos={photos} />;
      case WidgetKind.VIDEO_UPLOAD:
        return (
          <VideoUpload
            field={field}
            videos={videos}
            userFormParameters={userFormParameters}
          />
        );
      case WidgetKind.UNARY_CHECKBOX:
        return <UnaryCheckbox field={field} />;
      case WidgetKind.BINARY_RADIOBUTTON:
        return <BinaryRadioButton field={field} />;
      case WidgetKind.TEXT_FIELD:
        return <TextField field={field} />;
      case WidgetKind.TEXT_AREA:
        return <TextArea field={field} />;
      case WidgetKind.SINGLE_SELECT:
      case WidgetKind.SINGLE_SELECT_INLINE:
        return <SingleSelect field={field} />;
      case WidgetKind.USER_SELECTOR:
      case WidgetKind.MULTI_USER_SELECTOR:
        return <UserSelector field={field} />;
      case WidgetKind.DATE_SELECTOR:
        return <DateSelector field={field} />;
      case WidgetKind.HEADER_2:
        return <Header field={field} />;
      case WidgetKind.CALCULATED:
        return <CalculatedWidget field={field} />;
      case WidgetKind.INSTRUCTION:
        return (
          <InstructionWidget
            field={field}
            formClass={CustomFormClass.JOB}
            jobId={params?.jobId}
            formId={params?.formId ?? ''}
          />
        );
      case WidgetKind.SEPARATOR:
        return <Separator />;
      default:
    }
  };

  const visibleSections = form?.sections
    ?.map((section, sectionIndex) => {
      const { showFormula, showSourceWidgetIds } = section.config ?? {};

      const isVisible = isFormSectionVisible(
        showFormula,
        showSourceWidgetIds,
        form?.sections,
      );

      if (!isVisible) {
        return null;
      }

      return (
        <React.Fragment key={`section-${sectionIndex}`}>
          {section?.title && <SectionTitle>{section?.title}</SectionTitle>}
          {section?.fields?.length > 0 &&
            section?.fields.map(
              (field, index) =>
                field?.kind !== WidgetKind.VIDEO_UPLOAD_INFO_PAGE && (
                  <FieldContainer
                    key={`field-${field?.id}-${index}`}
                    $isSeparator={field?.kind === WidgetKind.SEPARATOR}
                  >
                    {renderField(field)}
                  </FieldContainer>
                ),
            )}
        </React.Fragment>
      );
    })
    .filter((section) => section);

  if (!visibleSections?.length) {
    return null;
  }

  return <div>{visibleSections}</div>;
};

export default FormDetails;

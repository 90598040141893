import { InfoFilled, Tick, Warning } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins } = theme;

export const IconContainer = styled.div<{ $isDark: boolean }>`
  ${mixins.flexFullCenter};
  background-color: ${({ $isDark }) => ($isDark ? colors.black : colors.white)};
  padding: 5px;
  border-radius: 2px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const WarningIcon = styled(({ color = '', ...rest }) => (
  <Warning {...rest} />
))`
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;

  .fill-selector {
    fill: ${({ color }) => color || colors.yellow};
  }
`;

export const TickIcon = styled(Tick)`
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  .fill-selector {
    fill: ${colors.green};
  }
`;

export const LabelContainer = styled.div<{ $hasBlackBackground?: boolean }>`
  ${mixins.flexCenter};
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 9;
  padding: 8px 16px;
  background-color: ${({ $hasBlackBackground }) =>
    $hasBlackBackground ? colors.black : colors.white};
  color: ${({ $hasBlackBackground }) =>
    $hasBlackBackground ? colors.white : colors.black};
`;

export const Title = styled.div`
  padding: 0 5px;
`;

export const InfoIcon = styled(InfoFilled)`
  .fill-selector {
    fill: ${colors.blue};
  }
`;

export const QualityInfoContainer = styled.div`
  ${mixins.flexColumn};
  background-color: ${colors.white};
  color: ${colors.black};
`;

export const InfoRow = styled.div`
  ${mixins.flexCenter};
  flex-direction: row;
  font-size: ${fontSizes.s};
  padding: 2px 0;
`;

export const BoldText = styled.span`
  font-family: ${fonts.bold};
  padding: 0 2px 0 5px;
`;

import { Input } from 'components/ui';
import { ChangeEvent, Fragment, memo } from 'react';
import { useText } from 'hooks';
import { OTHER_LOCALISATION_STRING } from 'model/FatigueManager/constants';
import { Container, ErrorMessage, Label } from '../styled';

type Props = {
  id: string;
  label: string;
  options: any[];
  selected: string[];
  setSelected: (newValue: any) => void;
  setCustomUserInputValue: (newOtherValue: any) => void;
  customUserInputValue?: string;
  error?: string;
  displayErrors: boolean;
};

export const MultiSelectWithCustomTextInput: React.FC<Props> = memo(
  ({
    id,
    label,
    options,
    selected,
    setSelected,
    customUserInputValue,
    setCustomUserInputValue,
    error,
    displayErrors,
  }) => {
    const getText = useText();
    const isSelected = (option: string) =>
      !!selected?.find((selectedOption) => selectedOption === option);

    const onChange = (option: string) => {
      const isAlreadySelected = isSelected(option);

      if (isAlreadySelected) {
        setSelected(
          selected.filter((selectedOption) => selectedOption !== option),
        );
      } else {
        setSelected([...(selected ?? []), option]);
      }
    };

    const isCustomUserInputEnabled = isSelected(OTHER_LOCALISATION_STRING);

    const customUserInputError =
      displayErrors && isCustomUserInputEnabled && !customUserInputValue
        ? getText('fatigue_manager_fatigue_risk_assessment_field_required')
        : undefined;

    const requiredFieldError = error || customUserInputError;

    return (
      <Container>
        <Label $marginRight='10px'>{label}</Label>
        {options.map((option, index) => {
          const checked = isSelected(option);
          if (index < options.length - 1) {
            return (
              <Input.Checkbox
                key={`${id}-option-${index}`}
                checked={checked}
                label={option}
                labelPlacement='end'
                onChange={() => onChange(option)}
                extraStyles={{
                  margin: '10px 25px',
                  alignItems: 'flex-start',
                }}
              />
            );
          }
          return (
            <Fragment key={`${id}-option-${index}`}>
              <Input.Checkbox
                checked={checked}
                label={getText('other')}
                labelPlacement='end'
                onChange={() => onChange(option)}
                extraStyles={{
                  margin: '10px 25px',
                  alignItems: 'flex-start',
                }}
              />
              {isCustomUserInputEnabled && (
                <Input.Text
                  id={`${id}-option-${index}-other`}
                  value={customUserInputValue ?? ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setCustomUserInputValue(e.target.value)
                  }
                  error={customUserInputError}
                  disabled={!isCustomUserInputEnabled}
                  placeholder={getText(
                    'fatigue_manager_fatigue_risk_assessment_field_placeholder',
                  )}
                  autoFocusOnRender
                />
              )}
            </Fragment>
          );
        })}
        {requiredFieldError && (
          <ErrorMessage>{requiredFieldError}</ErrorMessage>
        )}
      </Container>
    );
  },
);

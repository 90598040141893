import theme from 'theme';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import styled from 'styled-components';
import { useSafetyPrediction, useText } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import {
  EVENT_PROPERTIES_HAS_MAJOR_HAZARD,
  EVENT_PROPERTIES_HAS_PENDING_JOB_BLOCKERS_FIELD,
  EVENT_PROPERTIES_HAS_RISK_ASSESSMENT_CREATED,
  EVENT_PROPERTIES_HAS_UNREAD_MESSAGES_FIELD,
  EVENT_PROPERTIES_PAGE_SOURCE_FIELD,
  EVENT_PROPERTIES_PENDING_ASSESSMENT_SIGN_OFF_FIELD,
  EVENT_PROPERTIES_SAFETY_RISK_FIELD,
  EVENT_PROPERTIES_USER_ID_FIELD,
  EVENT_VIEW_JOB,
  LIST_VIEW_TAB,
  LIST_VIEW_PAGE_SIZE,
  jobV2Fields,
} from 'utils/constants';
import { Arrow, Pin as PinIcon } from 'assets/icons';
import { useCallback, useEffect, useMemo } from 'react';
import { selectGlobalLanguageSetting, selectJobsView } from 'store/selectors';
import { UserRole } from 'model/enum/UserRole';
import { formatDateTime } from 'helpers/dates';
import { getJobFullAddress } from 'helpers/names';
import Sort from 'model/Sort';
import MemoizedDataGrid from './MemoizedDataGrid';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { JobSort } from 'model/enum/JobSort';
import { updatePinStatus } from 'api/Job';
import { setActiveJobTab } from 'store/actions/versions';
import { Tabs } from 'components/ui';
import { JobStatusTitle } from 'model/enum/JobStatusTitle';
import { useNavigate } from 'react-router-dom';
import Team from 'model/Team';
import SkeletonJobsTable from './Skeleton/SkeletonJobsTable';
import { FeaturesInfoButton } from './FeaturesInfoButton';
import * as Logger from 'utils/logger';
import { setGlobalError } from 'store/slices/notifications';
import { ActionRequiredBadge } from './ActionRequiredBadge';

const { colors } = theme;

const ArrowRight = styled(Arrow)`
  min-height: 33px;
  overflow: visible;
  cursor: pointer;
  .fill-selector {
    fill: ${colors.black};
  }
`;

const FieldworkerName = styled.div`
  padding: 0 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

type Props = {
  onRequestPage: (page: number) => void;
  updatePinnedStatusInStore: (jobId: number, status: boolean) => void;
  sort: Sort | null;
  onSortChange: (value: Sort) => void;
  selectedTeam: Team | null;
  sortingDisabled?: boolean;
};

const JobsTable = ({
  onRequestPage,
  updatePinnedStatusInStore,
  sort,
  onSortChange,
  selectedTeam,
  sortingDisabled,
}: Props): JSX.Element => {
  const getText = useText();
  const navigate = useNavigate();
  const { getRiskWarning } = useSafetyPrediction();
  const dispatch = useDispatch();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const { currentUser } = useSelector((state: RootState) => state.session);
  const {
    listJobs: jobs,
    isFetchingJobs,
    filters,
    total,
    page,
    isLoadingJobsPage,
  } = useSelector((state: RootState) => state.jobs);
  const view = useSelector(selectJobsView);

  useEffect(() => {
    if (jobs?.length) {
      Analytics.trackEvent(constants.EVENT_PAGINATION_CLICKED, {
        jobs: jobs.map((job) => job.id),
        page,
        filters: {
          ...filters,
          ...(selectedTeam ? { team: selectedTeam?.id } : {}),
        },
        is_last_page: Math.ceil(total / LIST_VIEW_PAGE_SIZE) === page,
        [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: LIST_VIEW_TAB,
      });
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const jobData = jobs?.map((result, index) => {
    const fieldWorkers = result.assignees?.filter(
      (assignee) => assignee.role === UserRole.FIELDWORKER,
    );
    const additionalFieldWorkers =
      fieldWorkers?.length > 1 ? `(+${fieldWorkers.length - 1})` : '';
    const firstFieldworkerName = `${fieldWorkers[0]?.first_name} ${fieldWorkers[0]?.last_name}`;
    const safetyWarning = getRiskWarning(result.safety_prediction_score);
    return {
      id: index + 1,
      key: index + 1,
      job_id: result.id,
      is_pinned: result.is_pinned,
      fieldworkers:
        fieldWorkers?.length > 0
          ? `${firstFieldworkerName} ${additionalFieldWorkers}`
          : '-',
      location: getJobFullAddress(result),
      work_order_number: result.job_number,
      last_activity: formatDateTime(
        result.last_activity ?? result.updated_at,
        getText('date_today'),
        getText('date_yesterday'),
      ),
      status:
        result.status &&
        (result.status[`title_${globalAppLanguage}`] || result.status.title),
      unread_messages: result.unread_messages,
      pending_job_blocker_count: result.pending_job_blocker_count,
      most_recent_job_blocker_created_at:
        result.most_recent_job_blocker_created_at,
      sign_off_pending_assessment_count:
        result.sign_off_pending_assessment_count,
      most_recent_risk_assessment_created_at:
        result.most_recent_risk_assessment_created_at,
      editable: result.editable,
      safetyWarning: safetyWarning,
      has_major_hazard: result.has_major_hazard,

      is_low_quality_video: result.is_low_quality_video,
      is_limited_hazard: result.is_limited_hazard,
      is_permit_pending: result.is_permit_pending,
      is_poor_signal: result.is_poor_signal,
      is_major_hazards: result.is_major_hazards,
      is_new_message: result.is_new_message,
      is_job_blocked: result.is_job_blocked,
      is_ready_for_review: result.is_ready_for_review,
    };
  });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: getText('jobs_table_header_pin_job'),
        field: jobV2Fields.IS_PINNED,
        sortable: false,
        headerClassName: jobData?.length
          ? 'is-pinned-cell'
          : 'is-pinned-cell walkthrough-step-pin',
        cellClassName: 'is-pinned-cell',
        renderCell: ({ row }: GridRenderCellParams) => (
          <PinIcon
            className={
              row[jobV2Fields.IS_PINNED]
                ? 'filled walkthrough-step-pin'
                : row.id === 1
                  ? 'walkthrough-step-pin'
                  : ''
            }
          />
        ),
      },
      {
        headerName: getText('jobs_table_header_fieldworkers'),
        field: jobV2Fields.FIELDWORKERS,
        sortable: false,
        headerClassName: 'fieldworker-cell',
        cellClassName: 'fieldworker-cell',
        renderCell: (props: GridRenderCellParams) => (
          <FieldworkerName>{props.row.fieldworkers}</FieldworkerName>
        ),
      },
      {
        headerName: getText('jobs_table_header_action_required'),
        field: jobV2Fields.ACTION_REQUIRED,
        sortable: !sortingDisabled,
        headerClassName: 'action-cell',
        cellClassName: 'action-cell',
        renderCell: (props: GridRenderCellParams) => (
          <>
            {![
              JobStatusTitle.COMPLETED,
              JobStatusTitle.FOLLOW_UP,
              JobStatusTitle.DELETED,
            ].includes(props.row.status) && (
              <ActionRequiredBadge row={props.row} />
            )}
          </>
        ),
      },
      {
        headerName: getText('jobs_table_header_last_activity'),
        field: jobV2Fields.LAST_ACTIVITY,
        sortable: !sortingDisabled,
        headerClassName: 'last-activity-cell',
        cellClassName: 'last-activity-cell',
      },
      {
        headerName: getText('jobs_table_header_location'),
        field: jobV2Fields.LOCATION,
        sortable: false,
        headerClassName: 'location-cell',
        cellClassName: 'location-cell',
      },
      {
        headerName: getText('jobs_table_header_work_order_number'),
        field: jobV2Fields.WORK_ORDER_NUMBER,
        sortable: false,
        headerClassName: 'work-order-number-cell',
        cellClassName: 'work-order-number-cell',
      },
      {
        headerName: getText('jobs_table_header_status'),
        field: jobV2Fields.STATUS,
        sortable: false,
        headerClassName: 'status-cell',
        cellClassName: 'status-cell',
      },
      {
        headerName: '',
        field: jobV2Fields.OPEN_BUTTON,
        sortable: false,
        headerClassName: 'open-button-cell',
        cellClassName: 'open-button-cell',
        renderCell: () => <ArrowRight />,
        renderHeader: () => (
          <ButtonsContainer>
            <FeaturesInfoButton />
            <Tabs.JobView activeTab={LIST_VIEW_TAB} />
          </ButtonsContainer>
        ),
      },
    ],
    [jobData?.length, getText, sortingDisabled],
  );

  const source =
    filters.scope === JobSort.USER
      ? constants.JobsToggle.MY_JOBS
      : constants.JobsToggle.ALL_JOBS;
  const userId = currentUser.id;

  const showWorkspacePlaceholderMessage =
    currentUser?.workspaces &&
    currentUser?.workspaces?.length > 1 &&
    selectedTeam;

  const onRowClick = useCallback(
    ({ row }) => {
      if (jobs) {
        dispatch(setActiveJobTab(constants.TIMELINE));
        Analytics.trackEvent(EVENT_VIEW_JOB, {
          source,
          jobId: row.job_id,
          [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
          [EVENT_PROPERTIES_SAFETY_RISK_FIELD]: !!row.safetyWarning,
          [EVENT_PROPERTIES_HAS_UNREAD_MESSAGES_FIELD]: row.unread_messages > 0,
          [EVENT_PROPERTIES_HAS_PENDING_JOB_BLOCKERS_FIELD]:
            row.pending_job_blocker_count > 0,
          [EVENT_PROPERTIES_PENDING_ASSESSMENT_SIGN_OFF_FIELD]:
            row.sign_off_pending_assessment_count > 0,
          [EVENT_PROPERTIES_USER_ID_FIELD]: userId,
          [EVENT_PROPERTIES_HAS_MAJOR_HAZARD]: row.has_major_hazard,
          [EVENT_PROPERTIES_HAS_RISK_ASSESSMENT_CREATED]:
            !!row.most_recent_risk_assessment_created_at,
        });
        navigate(`/jobs/${row.job_id}/timeline`);
      }
    },
    [navigate, source, userId, dispatch, jobs, view],
  );

  const onCellClick = useCallback(
    async (cell, event) => {
      if (cell.field === 'is_pinned') {
        try {
          event.preventDefault();
          event.stopPropagation();
          const jobId = cell?.row?.job_id;
          const newStatus = !cell?.row?.[jobV2Fields.IS_PINNED];
          await updatePinStatus(jobId, newStatus);
          updatePinnedStatusInStore(jobId, newStatus);
          Analytics.trackEvent(constants.EVENT_JOB_PINNED, {
            jobId: cell?.row?.job_id,
            activePin: !cell?.row?.[jobV2Fields.IS_PINNED],
            [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: LIST_VIEW_TAB,
          });
        } catch (error) {
          Logger.error(error);
          dispatch(setGlobalError(error));
        }
      }
    },
    [dispatch, updatePinnedStatusInStore],
  );

  if (isFetchingJobs || isLoadingJobsPage || !jobData) {
    return <SkeletonJobsTable sortingDisabled={sortingDisabled} />;
  }

  return (
    <MemoizedDataGrid
      rows={jobData}
      isLoading={isFetchingJobs && !jobData?.length}
      columns={columns}
      onPageChange={onRequestPage}
      sort={sort}
      onSortChange={onSortChange}
      total={total}
      pageSize={LIST_VIEW_PAGE_SIZE}
      page={page}
      onRowClick={onRowClick}
      onCellClick={onCellClick}
      title={getText('jobs_search_no_jobs')}
      subtitle={getText(
        showWorkspacePlaceholderMessage
          ? 'jobs_search_no_jobs_list_subtitle_workspace_switch'
          : 'jobs_search_no_jobs_list_subtitle',
      )}
    />
  );
};

export default JobsTable;

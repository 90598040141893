import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';

import { Log } from 'api';
import { useLogs, useIsMobile, useText } from 'hooks';
import theme from 'theme';

import { TickCircle } from 'assets/icons';
import { Navbar, Button, Input, Card } from 'components/ui';
import { getMessages } from 'store/actions/messages';
import { fetchJobs } from 'store/actions/jobs';
import { useNavigate, useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import { selectJobsView } from 'store/selectors';

const { media, fonts, fontSizes, colors } = theme;

// Styled Components
const InputsContainer = styled.div`
  ${media.sm`
    padding: 15px;
  `}
`;

const Form = styled.form`
  flex: 1;
  padding: 25px 60px;
  min-height: 315px;
  ${media.sm`
    height: 100%
    overflow: scroll;
    padding: 0;
  `}
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuccessContainer = styled.div`
  height: 315px;
  padding: 25px 60px;
  background-color: ${colors.black};
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  ${media.sm`
    height: 100%;
    padding: 0;
  `}
`;

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fonts.boldItalic};
  font-size: ${fontSizes.l};
  ${media.sm`
    margin-bottom: 60px;
    margin-top: auto;
    padding: 25px;
  `}
`;

const TickContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  ${media.sm`
    justify-content: center;
    margin-bottom: 90px;
  `}
`;

const TickCircleIcon = styled(TickCircle)`
  width: 64px;
  height: 64px;
`;

// Blocker.ResolveBlocker
const ResolveBlocker = () => {
  const navigate = useNavigate();
  const { jobId, blockerId } = useParams();
  const isMobile = useIsMobile();
  const getText = useText();
  const { filters } = useSelector((state: RootState) => state.jobs);
  const { log: blocker } = useLogs(blockerId);
  const [note, setNote] = useState<string>();
  const [error, setError] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const dispatch = useDispatch();
  const view = useSelector(selectJobsView);

  useEffect(() => {
    if (jobId && blockerId && blocker) {
      Analytics.trackPage(constants.EVENT_PAGE_VIEW_JOB_BLOCKER, {
        jobId,
        blockerId,
        category: blocker?.jobBlocker?.title,
      });
    }
  }, [jobId, blockerId, blocker]);

  const handleSubmit = async () => {
    try {
      if (blockerId) {
        await Log.resolveJobBlocker(parseInt(blockerId), note ?? '');
        setError('');
        setShowSuccess(true);
        dispatch(getMessages(jobId));
        dispatch(fetchJobs(filters, view));
      }
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };

  const backToTimeLine = () => {
    navigate(`/jobs/${jobId}/timeline`);
  };

  return (
    <>
      <Navbar
        title={getText('job_blocker_detail_resolve_button')}
        defaultBackPath={`/jobs/${jobId}/logs`}
        mobileOnly
      />
      {!showSuccess ? (
        <Form onSubmit={handleSubmit}>
          <InputsContainer>
            <Input.TextArea
              id='note-resolve-blocker'
              label={getText('job_log_blocker_note')}
              optional
              extraStyles={{ marginBottom: '15px' }}
              error={error}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </InputsContainer>
          {error && (
            <ErrorContainer>
              <Card.ErrorMessage message={error} />
            </ErrorContainer>
          )}
          <Button.Primary
            text={getText('confirm')}
            onClick={handleSubmit}
            event={constants.EVENT_JOB_BLOCKER_RESOLVED}
            eventProperties={{
              blockerId,
              jobId,
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            extraStyles={
              isMobile
                ? { position: 'absolute', bottom: '0' }
                : { width: '328px', marginLeft: 'auto', marginTop: '50px' }
            }
          />
        </Form>
      ) : (
        <SuccessContainer>
          <SuccessMessage>
            <TickContainer>
              <TickCircleIcon />
            </TickContainer>
            <span>{getText('job_blocker_detail_resolution_well_done')}</span>
            <span>
              {getText('job_blocker_detail_resolution_no_longer_blocked')}
            </span>
          </SuccessMessage>
          <Button.Primary
            text={getText(
              'job_blocker_detail_resolution_go_to_timeline_button',
            )}
            onClick={backToTimeLine}
            event={constants.EVENT_BACK_TO_TIMELINE}
            eventProperties={{
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            extraStyles={
              isMobile
                ? { position: 'absolute', bottom: '0' }
                : { width: '328px', marginLeft: 'auto', marginTop: '50px' }
            }
          />
        </SuccessContainer>
      )}
    </>
  );
};

export default ResolveBlocker;

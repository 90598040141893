import { getLabelByLocale } from 'helpers/utils';
import Settings from 'model/Settings';
import { UserRole } from 'model/enum/UserRole';

export const getTitleForRole = (
  role: UserRole,
  language: string,
  workspaceSettings: Settings,
): string => {
  const {
    manager_label,
    manager_label_es,
    backoffice_label,
    backoffice_label_es,
    teammate_label,
    teammate_label_es,
    fieldworker_label,
    fieldworker_label_es,
  } = workspaceSettings;

  if (role === UserRole.MANAGER) {
    return getLabelByLocale(language, {
      manager_label,
      manager_label_es,
    });
  }
  if (role === UserRole.BACKOFFICE_ADMIN) {
    return getLabelByLocale(language, {
      backoffice_label,
      backoffice_label_es,
    });
  }
  if (role === UserRole.TEAMMATE) {
    return getLabelByLocale(language, {
      teammate_label,
      teammate_label_es,
    });
  }
  return getLabelByLocale(language, {
    fieldworker_label,
    fieldworker_label_es,
  });
};

export type ParameterUnionOf<T> = T[keyof T];

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryParams, useText } from 'hooks';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { Loader } from 'components/ui';
import { FormContainer } from '../styled';
import { resetSessionErrors, tokenSignIn } from 'store/actions/session';
import RootState from 'model/State/RootState';
import { SecondaryAction } from '../Login/SecondaryAction';
import { useNavigate } from 'react-router-dom';
import { LogoSlogan } from 'components/ui/Logos';
import { ExpiredWrapper, LoadingWrapper, TokenLoginText } from './styled';

type QueryParamProps = {
  host?: string;
  email?: string;
  challengeToken?: string;
};

// TokenLogin

export const TokenLogin = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const getText = useText();
  const navigate = useNavigate();
  const { params }: { params: QueryParamProps } = useQueryParams();
  const { error, isLoading, currentUser } = useSelector(
    (state: RootState) => state.session,
  );
  const [tryLogin, setTryLogin] = useState<boolean>(false);

  const isValidHost = (host) =>
    host.endsWith('.fyld.ai') || host.endsWith('.sitestream.app');

  const handleRequestMagicLinkClick = () => {
    dispatch(resetSessionErrors());
    Analytics.trackEvent(constants.EVENT_CLICK_REQUEST_MAGIC_LINK);
  };

  useEffect(() => {
    if (!error) {
      Analytics.trackPage(constants.EVENT_PAGE_VIEW_LOGIN, {
        page: constants.LoginPages.DECODE_MAGIC_LINK,
      });
      if (params.host && isValidHost(params.host)) {
        window.location.replace(
          `https://${params.host}/challenge-response?challengeToken=${
            params.challengeToken
          }&email=${encodeURIComponent(params.email ?? '')}`,
        );
      } else {
        dispatch(tokenSignIn(params.email, params.challengeToken));
        setTryLogin(true);
        Analytics.trackEvent(constants.EVENT_LOGIN_ATTEMPT, {
          loginSource: constants.LoginSource.MAGIC_LINK,
          email: params.email,
        });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      navigate('/');
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  if (process.env.REACT_APP_ENABLE_MAGIC_LINK !== 'true') {
    return null;
  }

  return (
    <>
      <LogoSlogan />
      <FormContainer>
        {isLoading && (
          <LoadingWrapper>
            <Loader isWhite />
            <TokenLoginText>{getText('login_ongoing')}</TokenLoginText>
          </LoadingWrapper>
        )}
        {tryLogin && !isLoading && !currentUser && (
          <ExpiredWrapper>
            <TokenLoginText>{getText('magic_link_expired')}</TokenLoginText>
            <SecondaryAction
              to='/request-magic-link'
              onClick={handleRequestMagicLinkClick}
            >
              {getText('login_request_link')}
            </SecondaryAction>
          </ExpiredWrapper>
        )}
      </FormContainer>
    </>
  );
};

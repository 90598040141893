import { ChangeEvent } from 'react';
import { CSSProperties } from 'styled-components';
import {
  CheckIcon,
  Container,
  StyledCheckBox,
  StyledFormControlLabel,
} from './styled';

type Props = {
  checked: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  defaultChecked?: boolean;
  disabled?: boolean;
  isLight?: boolean;
  isBold?: boolean;
  isSmall?: boolean;
  labelColor?: string;
  hasBlackBackground?: boolean;
  extraStyles?: CSSProperties;
};

export const Checkbox = ({
  checked,
  onChange,
  label = '',
  labelPlacement = 'start',
  defaultChecked,
  disabled = false,
  isLight = false,
  isBold = false,
  isSmall = false,
  labelColor,
  hasBlackBackground = false,
  extraStyles,
}: Props) => (
  <Container style={extraStyles}>
    <StyledFormControlLabel
      control={
        <StyledCheckBox
          checked={checked}
          defaultChecked={defaultChecked}
          onChange={onChange}
          checkedIcon={<CheckIcon isLight={isLight} disabled={disabled} />}
          disabled={disabled}
          isLight={isLight}
          isSmall={isSmall}
          hasBlackBackground={hasBlackBackground}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      labelColor={labelColor}
      isBold={isBold}
    />
  </Container>
);

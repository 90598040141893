import { useJobs, useText } from 'hooks';
import RootState from 'model/State/RootState';
import { useDispatch, useSelector } from 'react-redux';
import {
  EVENT_JOB_PAGE_BOX_FILTER_CLICKED,
  EVENT_PROPERTIES_PAGE_SOURCE_FIELD,
  JOB_OVERVIEW,
  TIMELINE,
} from 'utils/constants';
import { useEffect } from 'react';
import { fetchActiveJob, fetchJobs } from 'store/actions/jobs';
import StatusCard from './StatusCard';
import { setActiveJobTab } from 'store/actions/versions';
import { fetchFormOptions } from 'store/actions/form';
import {
  selectActiveJob,
  selectActiveJobTab,
  selectFormOptions,
  selectJobsView,
} from 'store/selectors';
import { JobFilterBase } from 'components/ui/Filter/JobFilterBase';
import { DrawerLayout } from '..';
import Team from 'model/Team';
import { scrollToTop } from 'helpers/table';
import { useParams } from 'react-router-dom';
import * as Analytics from 'utils/analytics';
import StatusCardSkeleton from './Skeleton/StatusCardSkeleton';
import { FeaturesGuide } from './FeaturesGuide';
import {
  ActionButtonBlockerIcon,
  ActionButtonJobsIcon,
  Container,
  Drawer,
  EyeWithoutTopPadding,
  SkeletonStatsWrapper,
  StatsWrapper,
  StatusCardsContainer,
} from './styled';

//JobsV2.JobsBase
const JobsBase = ({
  selectedTeam,
  setSelectedTeamForCurrentUser,
  children,
}: {
  selectedTeam: Team | null;
  setSelectedTeamForCurrentUser: (teamId: number | null) => void;
  children?: JSX.Element;
}) => {
  const { '*': path, jobId } = useParams();
  const dispatch = useDispatch();
  const getText = useText();
  const { jobFiltersInStorage, setFilters } = useJobs();
  const { jobStats, filters, isFetchingJobStats, isLoadingJobStats } =
    useSelector((state: RootState) => state.jobs);
  const activeJob = useSelector(selectActiveJob);
  const view = useSelector(selectJobsView);
  const formOptions = useSelector(selectFormOptions);
  const activeJobTab = useSelector(selectActiveJobTab);
  const isDrawerOpen = !!jobId;
  const intercomBannerHeight = document.getElementsByName(
    'intercom-banner-frame',
  )[0]?.offsetHeight;
  const showSkeleton = isLoadingJobStats || isFetchingJobStats;

  useEffect(() => {
    if (jobId && (!activeJob || activeJob.id !== parseInt(jobId ?? '', 10))) {
      dispatch(fetchActiveJob(jobId));
    }
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    scrollToTop();
  }, [selectedTeam]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      jobFiltersInStorage &&
      JSON.stringify(jobFiltersInStorage) !== JSON.stringify(filters)
    ) {
      setFilters(jobFiltersInStorage);
    }
    if (!formOptions) {
      dispatch(fetchFormOptions());
    }
    if (isDrawerOpen) {
      dispatch(setActiveJobTab(path === 'timeline' ? TIMELINE : JOB_OVERVIEW));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onTeamChange = (team: number | null) => {
    setSelectedTeamForCurrentUser(team);
    dispatch(fetchJobs(filters, view));
  };

  const sendStatsAnalytics = (filterBoxName, boxValue) => {
    Analytics.trackEvent(EVENT_JOB_PAGE_BOX_FILTER_CLICKED, {
      filterBoxName,
      boxValue,
      [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    });
  };

  const noSearchQuery = !filters?.q;

  const SkeletonStats = (
    <SkeletonStatsWrapper>
      <StatusCardsContainer>
        <StatusCardSkeleton className='walkthrough-step-stat-boxes' />
        <StatusCardSkeleton />
        <StatusCardSkeleton />
        <StatusCardSkeleton />
      </StatusCardsContainer>
    </SkeletonStatsWrapper>
  );

  const ActualStats = (
    <StatsWrapper>
      <StatusCardsContainer>
        {jobStats && (
          <>
            <StatusCard
              IconComponent={ActionButtonJobsIcon}
              number={jobStats?.is_active_count}
              text={getText('jobs_stats_active')}
              selected={filters?.is_active}
              onClick={() => {
                setFilters({
                  ...filters,
                  is_active: !filters?.is_active,
                  has_major_hazard: false,
                  has_vra_ready_for_sign_off: false,
                  has_no_vra: false,
                });
                sendStatsAnalytics('is_active', !filters?.is_active);
              }}
              className='walkthrough-step-stat-boxes'
            />
            <StatusCard
              IconComponent={ActionButtonBlockerIcon}
              number={jobStats.has_major_hazard_count}
              text={getText('jobs_stats_major_hazard')}
              selected={filters?.has_major_hazard}
              onClick={() => {
                setFilters({
                  ...filters,
                  is_active: false,
                  has_major_hazard: !filters?.has_major_hazard,
                  has_vra_ready_for_sign_off: false,
                  has_no_vra: false,
                });
                sendStatsAnalytics(
                  'has_major_hazard',
                  !filters?.has_major_hazard,
                );
              }}
            />
            <StatusCard
              IconComponent={EyeWithoutTopPadding}
              number={jobStats.has_vra_ready_for_sign_off_count}
              text={getText('jobs_stats_vra_ready')}
              selected={filters?.has_vra_ready_for_sign_off}
              onClick={() => {
                setFilters({
                  ...filters,
                  is_active: false,
                  has_major_hazard: false,
                  has_vra_ready_for_sign_off:
                    !filters?.has_vra_ready_for_sign_off,
                  has_no_vra: false,
                });
                sendStatsAnalytics(
                  'has_vra_ready_for_sign_off',
                  !filters?.has_vra_ready_for_sign_off,
                );
              }}
            />
            <StatusCard
              IconComponent={ActionButtonBlockerIcon}
              number={jobStats.has_no_vra_count}
              text={getText('jobs_stats_without_vra')}
              selected={filters?.has_no_vra}
              onClick={() => {
                setFilters({
                  ...filters,
                  is_active: false,
                  has_major_hazard: false,
                  has_vra_ready_for_sign_off: false,
                  has_no_vra: !filters?.has_no_vra,
                });
                sendStatsAnalytics('has_no_vra', !filters?.has_no_vra);
              }}
            />
          </>
        )}
      </StatusCardsContainer>
    </StatsWrapper>
  );

  return (
    <Container>
      <FeaturesGuide />
      <JobFilterBase
        view={view}
        filters={filters}
        setFilters={setFilters}
        selectedTeam={selectedTeam}
        setSelectedTeam={onTeamChange}
      />
      {noSearchQuery && !!filters.scope && (
        <>{showSkeleton ? SkeletonStats : ActualStats}</>
      )}
      {children}
      <Drawer
        anchor='right'
        open={isDrawerOpen}
        offsetHeight={intercomBannerHeight}
        variant='persistent'
      >
        <DrawerLayout activeTab={activeJobTab} />
      </Drawer>
    </Container>
  );
};

export default JobsBase;

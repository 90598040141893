import { forwardRef } from 'react';
import { StyledItemActionButton } from './styled';

type Props = {
  icon: any;
  onClick?: () => void;
  onPointerDown?: () => void;
  onKeyDown?: () => void;
  canGrab?: boolean;
  isGrabbing?: boolean;
  disabled?: boolean;
};

export const ItemAction = forwardRef<HTMLButtonElement, Props>(
  (
    {
      icon,
      canGrab,
      isGrabbing,
      disabled,
      onClick,
      onPointerDown,
      onKeyDown,
      ...props
    },
    ref,
  ) => {
    return (
      <StyledItemActionButton
        ref={ref}
        {...props}
        tabIndex={0}
        $canGrab={!!canGrab}
        $isGrabbing={!!isGrabbing}
        $disabled={!!disabled}
        onClick={!disabled ? onClick : undefined}
        onPointerDown={!disabled ? onPointerDown : undefined}
        onKeyDown={!disabled ? onKeyDown : undefined}
      >
        {icon}
      </StyledItemActionButton>
    );
  },
);

import { Input } from 'components/ui';
import { useText } from 'hooks';
import { ShiftDurationOption } from 'model/FatigueManager/ShiftDurationOption';

type Props = {
  selectedMinimumShiftDuration: ShiftDurationOption | null;
  handleMinimumShiftDurationChange: (
    minimumShiftDuration: ShiftDurationOption,
  ) => void;
};

export const ShiftDurationFilter = ({
  selectedMinimumShiftDuration,
  handleMinimumShiftDurationChange,
}: Props): JSX.Element => {
  const getText = useText();

  const shiftDurationOptions = [
    {
      id: 1,
      title: getText('fatigue_manager_risk_assessment_option_all'),
      value: null,
    },
    {
      id: 2,
      title: getText('fatigue_manager_risk_assessment_option_over_hour', {
        hour: 12,
      }),
      value: 12,
    },
    {
      id: 3,
      title: getText('fatigue_manager_risk_assessment_option_over_hour', {
        hour: 14,
      }),
      value: 14,
    },
    {
      id: 4,
      title: getText('fatigue_manager_risk_assessment_option_over_hour', {
        hour: 16,
      }),
      value: 16,
    },
  ];

  return (
    <Input.Select
      id='select-shift-duration'
      label={getText('fatigue_manager_historic_data_filter_shift_duration')}
      hint={getText('filter_data_select_hint')}
      selected={selectedMinimumShiftDuration ?? shiftDurationOptions[0]}
      options={shiftDurationOptions}
      extraStyles={{ marginBottom: '20px' }}
      onChange={(e) => {
        const minimumDuration = shiftDurationOptions.find(
          (type) => type.id === e?.target?.value,
        );
        if (minimumDuration) {
          handleMinimumShiftDurationChange(minimumDuration);
        }
      }}
      large
    />
  );
};

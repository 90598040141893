import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FyldLogoFull } from 'assets/images';
import theme from 'theme';

const { breakpoints, mixins } = theme;

export const BrandContainer = styled(Link)`
  padding: 150px 80px;
  flex: 50%;
  @media only screen and (max-width: ${breakpoints.sm}px) {
    padding: 25px 20px;
    flex: 0 1;
  }
  text-decoration: none;
  ${mixins.flexColumn}
  align-items: flex-start;
`;

export const FyldLogo = styled(FyldLogoFull)`
  height: 55px;
  margin-bottom: ${({ $bigMargin }) => {
    if ($bigMargin) {
      return '100px';
    }
    return 'none';
  }};
  @media only screen and (max-width: ${theme.breakpoints.sm}px) {
    height: 30px;
    margin-bottom: 0;
  }
`;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchHavsLimits,
  selectHavsLimitData,
  selectHavsLimitsIsLoading,
} from 'store/slices/havsSettings';
import { AddEditHavsLimitModal } from './components/AddEditHavsLimit';
import { FiltersModal } from './components/FiltersModal';
import { Button } from 'components/ui';
import {
  Container,
  FiltersIcon,
  FiltersWrapper,
  Header,
  HeaderContainer,
  StyledPlusIcon,
  TableContainer,
} from './styled';
import { useLocalStorage, useText } from 'hooks';
import { SearchBar } from './components/SearchBar';
import { HavsLimitsTable } from './components/HavsLimitsTable';
import {
  BLANK_FILTERS,
  EVENT_ADD_HAVS_USER,
  EVENT_DELETE_HAVS_USER,
  EVENT_EDIT_HAVS_USER,
  EVENT_UPDATE_FILTERS,
} from './constants';
import {
  selectGlobalLanguageSetting,
  selectWorkspaceSettings,
} from 'store/selectors';
import Sort from 'model/Sort';
import { DeleteHavsLimitModal } from './components/DeleteHavsLimitModal';
import {
  HavsFilterTypes,
  HavsFilters,
  HavsManager,
  HavsUser,
} from 'model/AdminPanel/HavsSettings/HavsUser';
import { deleteHavsLimitForUserId } from 'api/HavsSettings';
import { FilterLabels } from './components/FilterLabels';
import * as Analytics from 'utils/analytics';
import { ParameterUnionOf } from './helpers';

export const HavsSettings = (): JSX.Element => {
  const dispatch = useDispatch();
  const getText = useText();
  const workspaceSettings = useSelector(selectWorkspaceSettings);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const data = useSelector(selectHavsLimitData);
  const isLoading = useSelector(selectHavsLimitsIsLoading);

  const [filters, setFilters] = useLocalStorage(
    'havsSettingsFilters',
    BLANK_FILTERS,
  );

  const [isAddEditUserModalOpen, setIsAddEditUserModalOpen] =
    useState<boolean>(false);
  const [havsLimitInEditUserId, setHavsLimitInEditUserId] = useState<
    number | null
  >(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const [userIdToBeDeleted, setUserIdToBeDeleted] = useState<number | null>(
    null,
  );

  const refreshHavsLimits = useCallback(
    () => dispatch(fetchHavsLimits(filters)),
    [dispatch, filters],
  );

  useEffect(() => {
    refreshHavsLimits();
  }, [refreshHavsLimits, filters]);

  const openAddEditModal = () => setIsAddEditUserModalOpen(true);
  const openFiltersModal = () => setIsFiltersOpen(true);

  const updateFilters = (
    field: keyof HavsFilters,
    value: ParameterUnionOf<HavsFilters>,
  ) => {
    setFilters((prevState: HavsFilters) => {
      const updatedFilters = {
        ...prevState,
        [field]: value,
      };
      Analytics.trackEvent(EVENT_UPDATE_FILTERS, updatedFilters);
      return updatedFilters;
    });
  };

  const applyFilters = (newFilters: HavsFilters) => {
    Analytics.trackEvent(EVENT_UPDATE_FILTERS, newFilters);
    setFilters(newFilters);
    setIsFiltersOpen(false);
  };

  const havsLimitInEdit = data?.find(
    (item) => item.user.id === havsLimitInEditUserId,
  );

  const resetAddEditUserModal = () => {
    setIsAddEditUserModalOpen(false);
    setHavsLimitInEditUserId(null);
  };

  const onAddEditSuccess = (successfulPayload: {
    user: HavsUser;
    manager: HavsManager;
    havsLimit: number;
  }): void => {
    if (havsLimitInEdit) {
      Analytics.trackEvent(EVENT_EDIT_HAVS_USER, successfulPayload);
    } else {
      Analytics.trackEvent(EVENT_ADD_HAVS_USER, successfulPayload);
    }
    resetAddEditUserModal();
    refreshHavsLimits();
  };

  const deleteHavsLimit = async () => {
    if (userIdToBeDeleted) {
      await deleteHavsLimitForUserId(userIdToBeDeleted);
      Analytics.trackEvent(EVENT_DELETE_HAVS_USER, {
        userId: userIdToBeDeleted,
      });
      refreshHavsLimits();
    }
  };

  const hasActiveFilters = Object.entries(filters).some(
    (pair) => pair[0] !== HavsFilterTypes.SORT && pair[1] !== null,
  );

  return (
    <Container>
      <HeaderContainer>
        <Header>{getText('havs_user_settings_header')}</Header>
        <FiltersWrapper>
          <SearchBar
            placeholder={getText('havs_users_search_bar_placeholder')}
            onSubmit={(value) => updateFilters(HavsFilterTypes.SEARCH, value)}
            resetSearch={() => updateFilters(HavsFilterTypes.SEARCH, null)}
            initialValue={filters.search ?? ''}
          />
          <Button.IconButton onClick={openFiltersModal}>
            <FiltersIcon />
          </Button.IconButton>
          <Button.IconButton onClick={openAddEditModal}>
            <StyledPlusIcon />
          </Button.IconButton>
        </FiltersWrapper>
      </HeaderContainer>
      <FilterLabels
        filters={filters}
        removeFilter={(field: keyof HavsFilters) => updateFilters(field, null)}
      />
      <TableContainer>
        <HavsLimitsTable
          isLoading={isLoading}
          data={data}
          sort={filters.sort}
          changeSort={(newSort: Sort) => {
            updateFilters(HavsFilterTypes.SORT, newSort);
          }}
          language={globalAppLanguage}
          workspaceSettings={workspaceSettings}
          openEditModal={(id: number) => {
            setHavsLimitInEditUserId(id);
            setIsAddEditUserModalOpen(true);
          }}
          openDeleteModal={(id: number) => setUserIdToBeDeleted(id)}
          hasActiveFilters={hasActiveFilters}
        />
      </TableContainer>
      {isAddEditUserModalOpen && (
        <AddEditHavsLimitModal
          isVisible={isAddEditUserModalOpen}
          onSuccess={onAddEditSuccess}
          onCancel={resetAddEditUserModal}
          initialLimit={havsLimitInEdit}
        />
      )}
      {isFiltersOpen && (
        <FiltersModal
          isVisible={isFiltersOpen}
          setIsVisible={setIsFiltersOpen}
          initialFilters={filters}
          applyFilters={applyFilters}
        />
      )}
      {userIdToBeDeleted && (
        <DeleteHavsLimitModal
          isVisible={!!userIdToBeDeleted}
          closeModal={() => setUserIdToBeDeleted(null)}
          userId={userIdToBeDeleted}
          deleteHavsLimit={deleteHavsLimit}
        />
      )}
    </Container>
  );
};

import * as Analytics from 'utils/analytics';
import { useText } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Job } from 'api';
import ConfirmModal from './ConfirmModal';
import { fetchActiveJob, fetchJobs } from 'store/actions/jobs';
import {
  selectActiveJob,
  selectFilters,
  selectJobsView,
} from 'store/selectors';
import { useStatusesAndDeletedStatus } from 'components/views/Protected/Jobs/Overview/hooks';
import { EVENT_DELETE_JOB_CONFIRM } from './constants';

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DeleteJob = ({ showModal, setShowModal }: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const view = useSelector(selectJobsView);
  const activeJob = useSelector(selectActiveJob);
  const filters = useSelector(selectFilters);
  const { deletedStatus } = useStatusesAndDeletedStatus();

  const handleDeleteJob = async () => {
    try {
      setShowModal(false);
      if (!deletedStatus) {
        throw new Error(getText('jobs_update_status_no_deleted'));
      }

      await Job.updateDetails(activeJob?.id, { status: deletedStatus?.id });
      Analytics.trackEvent(EVENT_DELETE_JOB_CONFIRM, { jobId });
      dispatch(fetchActiveJob(activeJob?.id));
      dispatch(fetchJobs(filters, view));
      navigate('/jobs');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ConfirmModal
      warningMessage={getText('job_detail_overview_delete_job_alert_message')}
      isVisible={showModal}
      closeModal={() => setShowModal(false)}
      onButtonClick={handleDeleteJob}
      buttonText={getText('job_detail_overview_delete_job')}
    />
  );
};

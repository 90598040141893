import { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Loader } from 'components/ui';
import { useText } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { SortOrder } from 'model/enum/SortOrder';
import Sort from 'model/Sort';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { ComplianceType } from 'model/enum/ComplianceType';
import {
  selectActiveWorkspaceUuid,
  selectIsLoadingVehicleCheckCompliance,
  selectVehicleCheckComplianceData,
} from 'store/selectors';
import VehicleCheckComplianceDataTable from './VehicleCheckComplianceDataTable';
import { fetchVehicleCheckCompliance } from 'store/actions/insights';
import { stringifyUserIds } from 'api/Insights/helpers';

const { colors, fonts, fontSizes } = theme;

// Styled Components
const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 40px !important;
`;

const DataContainer = styled.div`
  padding: 40px 80px;
  background-color: ${colors.offWhite};
  min-height: 100%;
`;

const DataRow = styled.div`
  height: 650px;
  display: flex;
  background: ${colors.white};
  padding: 32px;
`;

type Props = {
  depotId: number;
  search: string;
  startDate: Date;
  endDate: Date;
  isWeekendIncluded: boolean;
  userFilters: number[];
};

// VehicleCheckCompliance
const VehicleCheckCompliance = ({
  depotId,
  search,
  startDate,
  endDate,
  isWeekendIncluded,
  userFilters,
}: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();

  const initialSort = {
    field: constants.vehicleCheckComplianceFields.REGISTRATION,
    order: SortOrder.DESCENDING,
  };
  const [sort, setSort] = useState<Sort>(initialSort);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const userIds = stringifyUserIds(userFilters);

  const isLoading = useSelector(selectIsLoadingVehicleCheckCompliance);
  const {
    count: total,
    columns: complianceColumns,
    results: complianceData,
  } = useSelector(selectVehicleCheckComplianceData);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);

  useEffect(() => {
    if (depotId) {
      dispatch(
        fetchVehicleCheckCompliance(
          depotId,
          startDate,
          endDate,
          !isWeekendIncluded,
          sort,
          userIds,
          search,
          selectedWorkspaceUuid,
          currentPage,
        ),
      );
    }

    Analytics.trackEvent(
      `${ComplianceType.VEHICLE_CHECK}-${constants.EVENT_COMPLIANCE_LOADED}`,
      {
        depotId,
        startDate,
        endDate,
        isWeekendIncluded,
        sort,
        hasFilters: !!userIds,
        ...(!!userIds && { userIds }),
        search,
        page: currentPage,
      },
    );
  }, [
    dispatch,
    depotId,
    startDate,
    endDate,
    isWeekendIncluded,
    sort,
    userIds,
    search,
    currentPage,
    selectedWorkspaceUuid,
  ]);

  return (
    <DataContainer>
      <H2>{getText('insights_vehicle_check_compliance_title')}</H2>
      {complianceData ? (
        <DataRow>
          <VehicleCheckComplianceDataTable
            data={complianceData}
            columns={complianceColumns}
            total={total}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
            sort={sort}
            setSort={setSort}
            initialSort={initialSort}
            analyticsData={{
              depotId,
              startDate,
              endDate,
              isWeekendIncluded,
              sort,
              rowsOfData: total,
            }}
            isLoading={isLoading}
          />
        </DataRow>
      ) : (
        <DataRow>
          <Loader />
        </DataRow>
      )}
    </DataContainer>
  );
};

export default VehicleCheckCompliance;

import { Button, Input, Loader } from 'components/ui';
import { useText } from 'hooks';
import styled from 'styled-components';
import theme from 'theme';
import { TypeItemRow } from '../TypeItemRow';
import { PlusIcon } from 'assets/icons';
import { useSelector } from 'react-redux';
import {
  selectGlobalLanguageSetting,
  selectHasOrganizationSettingEnabled,
} from 'store/selectors';
import { useEffect, useState } from 'react';
import { ConfirmModal } from 'components/ui/Modal';
import { WorkspaceItemStatus } from 'model/enum/WorkspaceItemStatus';
import { concatenateLocale } from 'helpers/names';
import { WorkspaceItemType } from 'model/AdminPanel/WorkspaceSettings/WorkspaceItemType';

const { colors, fonts, fontSizes, media, mixins } = theme;

const Container = styled.div<{ $hasSelectInput: boolean }>`
  background-color: ${colors.white};
  padding: 32px;
  min-height: ${({ $hasSelectInput }) => ($hasSelectInput ? 570 : 460)}px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ListContent = styled.div`
  width: 50%;
  padding-right: 125px;
  box-sizing: border-box;
  ${media.xxl`
    padding-right: 30px;
  `}
`;

const EditContent = styled.div`
  width: 50%;
  padding-left: 25px;
  box-sizing: border-box;
`;

const H2 = styled.h2<{ $loading?: boolean }>`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  ${({ $loading }) =>
    !$loading &&
    `padding-bottom: 50px;
  `};
`;

const ItemsContainer = styled.div`
  height: 300px;
  overflow-y: auto;
`;

const Description = styled.p`
  padding-bottom: 25px;
`;

const InputContainer = styled.div<{ $swapOrder: boolean }>`
  ${mixins.flexColumn};
  ${({ $swapOrder }) => $swapOrder && `flex-direction: column-reverse`};
  > div > div {
    width: 70%;
    margin-bottom: 15px;
    ${media.lg`
     width: 100%;
  `}
  }
`;

const ActionButtonContainer = styled.div`
  ${mixins.flexCenter};
  margin-top: 25px;
`;

const ButtonContainer = styled.div`
  width: 40%;
  ${media.xl`
     width: 60%;
  `}
  ${media.lg`
     width: 100%;
  `}
`;

const ErrorText = styled.div`
  color: ${colors.red};
  margin-top: 10px;
`;

type Props = {
  manageTitle: string;
  addEditTitle: string;
  noTypeText: string;
  types: WorkspaceItemType[];
  loading: boolean;
  swapOrder: boolean;
  label: string;
  labelEs?: string;
  extra_field_label?: string;
  extra_field_label_es?: string;
  hasSelectInput?: boolean;
  selectLabel?: string;
  descriptionText?: string;
  selectOptions?: WorkspaceItemType[];
  selected?: WorkspaceItemType;
  handleSelectChange?: (value: WorkspaceItemType) => void;
  onAddOrEditPress: (
    isUpdate: boolean,
    index: number,
    englishText: string,
    spanishText: string,
    extraEnglishText?: string,
    extraSpanishText?: string,
  ) => void;
  onSubmit: () => void;
  isSubmitting: boolean;
  hasChangedFields: boolean;
  onRemove: (index: number, id: number | null) => void;
  errors: WorkspaceItemType[];
};

const ManageTypesBase = ({
  manageTitle,
  addEditTitle,
  noTypeText,
  types,
  loading,
  swapOrder,
  label,
  labelEs,
  extra_field_label,
  extra_field_label_es,
  hasSelectInput = false,
  selectLabel,
  descriptionText,
  selectOptions,
  selected,
  handleSelectChange,
  onAddOrEditPress,
  onSubmit,
  isSubmitting,
  hasChangedFields,
  onRemove,
  errors,
}: Props): JSX.Element => {
  const getText = useText();
  const enableLanguageSelection = useSelector(
    selectHasOrganizationSettingEnabled('enable_language_selection'),
  );
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [editedIndex, setEditedIndex] = useState<number>(-1);
  const [editedEnglishText, setEditedEnglishText] = useState<string>('');
  const [editedSpanishText, setEditedSpanishText] = useState<string>('');
  const [editedExtraEnglishText, setEditedExtraEnglishText] =
    useState<string>('');
  const [editedExtraSpanishText, setEditedExtraSpanishText] =
    useState<string>('');
  const [error, setError] = useState<string>('');
  const [indexToDelete, setIndexToDelete] = useState<number>(-1);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);

  const hasItems = types?.some(
    (type) => type?.status !== WorkspaceItemStatus.DELETED,
  );

  const isEditDisabled = () => {
    const editedType = types[editedIndex];
    const isTypeTheSame =
      editedType?.title === editedEnglishText.trim() &&
      editedType?.titleEs === editedSpanishText.trim();
    if (extra_field_label) {
      return (
        isTypeTheSame &&
        editedType?.unitTitle === editedExtraEnglishText.trim() &&
        editedType?.unitTitleEs === editedExtraSpanishText.trim()
      );
    }
    return isTypeTheSame;
  };

  const isAddEditDisabled =
    isSubmitting ||
    !editedEnglishText.trim() ||
    (enableLanguageSelection && !editedSpanishText.trim()) ||
    (isUpdate && isEditDisabled());

  useEffect(() => {
    if (hasSelectInput && selected) {
      resetForm();
    }
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (types.some((type) => !type.id)) {
      const listElement = document.getElementById(`${manageTitle}-type-list`);
      const newElement = document.getElementById(`type-${types.length - 1}`);

      listElement?.scrollTo({
        top: newElement?.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [types?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const onEditRowClick = (type: WorkspaceItemType, index) => {
    setEditedIndex(index);
    setEditedEnglishText(type?.title || '');
    setEditedSpanishText(type?.titleEs ?? '');
    setEditedExtraEnglishText(type?.unitTitle ?? '');
    setEditedExtraSpanishText(type?.unitTitleEs ?? '');
    setIsUpdate(true);
  };

  const resetForm = () => {
    setEditedIndex(-1);
    setEditedEnglishText('');
    setEditedSpanishText('');
    setEditedExtraEnglishText('');
    setEditedExtraSpanishText('');
    setIsUpdate(false);
    setError('');
  };

  const onDeleteClick = (index) => {
    resetForm();
    setIndexToDelete(index);
    setDeleteConfirmationOpen(true);
  };

  const handleDelete = () => {
    setDeleteConfirmationOpen(false);
    if (indexToDelete > -1) {
      onRemove(indexToDelete, types[indexToDelete]?.id);
    }
    setIndexToDelete(-1);
  };

  const isFormValid = () => {
    setError('');
    const duplicateItems = types.filter(
      (type) =>
        type.title.toLowerCase().trim() ===
          editedEnglishText.toLowerCase().trim() ||
        (enableLanguageSelection &&
          type.titleEs?.toLowerCase().trim() ===
            editedSpanishText.toLowerCase().trim()),
    );
    if (
      duplicateItems?.length === 0 ||
      (isUpdate &&
        duplicateItems?.length === 1 &&
        duplicateItems[0]?.id === types?.[editedIndex]?.id)
    ) {
      return true;
    }
    setError(getText('workspace_settings_error_already_exist'));
    return false;
  };

  const onAddOrUpdate = () => {
    if (isFormValid()) {
      onAddOrEditPress(
        isUpdate,
        editedIndex,
        editedEnglishText,
        editedSpanishText,
        editedExtraEnglishText,
        editedExtraSpanishText,
      );
      resetForm();
    }
  };

  const formatErrorMessage = () => {
    if (errors?.length > 1) {
      const concatenated = concatenateLocale(
        globalAppLanguage,
        errors.map(
          (error) => error?.[`title_${globalAppLanguage}`] || error?.title,
        ),
      );
      return getText('workspace_settings_error_multiple', {
        types: concatenated,
      });
    }

    return getText('workspace_settings_error_single', {
      type: errors[0]?.[`title_${globalAppLanguage}`] || errors[0]?.title,
    });
  };

  return (
    <Container $hasSelectInput={hasSelectInput}>
      <ContentWrapper>
        <ListContent>
          <H2 $loading={loading}>{manageTitle}</H2>
          {!loading && (
            <>
              {hasSelectInput && (
                <Input.Select
                  id='category-select'
                  label={selectLabel}
                  options={selectOptions}
                  selected={selected}
                  onChange={(e) => {
                    const selectedItem = selectOptions?.find(
                      (item) => item.id === e?.target?.value,
                    );
                    if (selectedItem && handleSelectChange) {
                      handleSelectChange(selectedItem);
                    }
                  }}
                  extraStyles={{ marginBottom: '25px' }}
                  large
                  fitContent
                />
              )}
              <ItemsContainer id={`${manageTitle}-type-list`}>
                {hasItems
                  ? types.map((type, index) =>
                      type?.status === WorkspaceItemStatus.DELETED ? null : (
                        <TypeItemRow
                          id={`type-${index}`}
                          key={`type-${index}`}
                          item={type}
                          onEditClick={() => onEditRowClick(type, index)}
                          onRemoveClick={() => onDeleteClick(index)}
                        />
                      ),
                    )
                  : noTypeText}
              </ItemsContainer>
              <ButtonContainer>
                <Button.Primary
                  text={getText('workspace_settings_save_changes')}
                  onClick={onSubmit}
                  disabled={!hasChangedFields}
                  loading={isSubmitting}
                />
              </ButtonContainer>
              {!isSubmitting && errors?.length > 0 && (
                <ErrorText>{formatErrorMessage()}</ErrorText>
              )}
            </>
          )}
        </ListContent>
        {!loading && (
          <EditContent>
            <H2>{addEditTitle}</H2>
            {descriptionText && <Description>{descriptionText}</Description>}
            <InputContainer $swapOrder={swapOrder}>
              <div>
                <Input.Text
                  id='manage-english-type-input'
                  value={editedEnglishText}
                  label={label}
                  onChange={(e) => setEditedEnglishText(e.target.value)}
                  disabled={isSubmitting}
                  maxLength={300}
                />
                {extra_field_label && (
                  <Input.Text
                    id='manage-english-extra-info-input'
                    value={editedExtraEnglishText}
                    label={extra_field_label}
                    onChange={(e) => setEditedExtraEnglishText(e.target.value)}
                    disabled={isSubmitting}
                    maxLength={300}
                    extraStyles={
                      !swapOrder && enableLanguageSelection
                        ? { marginBottom: '40px' }
                        : {}
                    }
                  />
                )}
              </div>
              {enableLanguageSelection && (
                <div>
                  <Input.Text
                    id='manage-spanish-type-input'
                    value={editedSpanishText}
                    label={labelEs}
                    onChange={(e) => setEditedSpanishText(e.target.value)}
                    disabled={isSubmitting}
                    maxLength={300}
                  />
                  {extra_field_label_es && (
                    <Input.Text
                      id='manage-spanish-extra-info-input'
                      value={editedExtraSpanishText}
                      label={extra_field_label_es}
                      onChange={(e) =>
                        setEditedExtraSpanishText(e.target.value)
                      }
                      disabled={isSubmitting}
                      maxLength={300}
                      extraStyles={swapOrder ? { marginBottom: '40px' } : {}}
                    />
                  )}
                </div>
              )}
            </InputContainer>

            <ActionButtonContainer>
              <Button.Primary
                text={getText(
                  isUpdate
                    ? 'workspace_settings_update'
                    : 'workspace_settings_add',
                )}
                IconComponent={isUpdate ? null : PlusIcon}
                onClick={onAddOrUpdate}
                disabled={isAddEditDisabled}
                width='200px'
                extraStyles={{ margin: 0 }}
              />
              <Button.Confirm
                onClick={() => resetForm()}
                text={getText('cancel')}
                width='130px'
                extraStyles={{ margin: '0 20px' }}
              />
            </ActionButtonContainer>
            <ErrorText>{error}</ErrorText>
          </EditContent>
        )}
      </ContentWrapper>
      {loading && <Loader />}
      {isDeleteConfirmationOpen && (
        <ConfirmModal
          warningMessage={getText('workspace_settings_delete_confirmation', {
            type:
              types[indexToDelete]?.[`title_${globalAppLanguage}`] ||
              types[indexToDelete]?.title,
          })}
          isVisible={isDeleteConfirmationOpen}
          closeModal={() => setDeleteConfirmationOpen(false)}
          onButtonClick={handleDelete}
          buttonText={getText('delete')}
        />
      )}
    </Container>
  );
};

export default ManageTypesBase;

import { useEffect, useState } from 'react';
import { useText } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Display } from 'components/ui';
import { SearchControl } from '../SearchControl';
import { ControlList } from './ControlList';
import RootState from 'model/State/RootState';
import { Control } from 'model/Assessment/Control';
import { CollapseContainer } from './styled';
import { SliderRating } from './SliderRating';
import {
  clearSelectedControls,
  setSelectedControls,
} from 'store/slices/ramsAssessments';
import { RatingValue } from 'model/Assessment/RAMS';

type Props = {
  onRatingChange: (
    preRating: RatingValue,
    postRating: RatingValue,
    hazardId: number,
  ) => void;
  hazardId: number;
};

// RatingSteps
export const RatingSteps = ({
  onRatingChange,
  hazardId,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const getText = useText();

  const { selectedControls, selectedHazards, editedHazard, ratings } =
    useSelector((state: RootState) => state.ramsAssessments);
  const [preRating, setPreRating] = useState<RatingValue>({
    severity: 0,
    likelihood: 0,
  });
  const [postRating, setPostRating] = useState<RatingValue>({
    severity: 0,
    likelihood: 0,
  });

  useEffect(() => {
    if (!editedHazard) {
      dispatch(clearSelectedControls());
      setPostRating({
        severity: 0,
        likelihood: 0,
      });
      setPreRating({
        severity: 0,
        likelihood: 0,
      });
    } else {
      const rating = ratings.find(
        (savedRating) => savedRating.hazardId === editedHazard.id,
      );
      if (rating) {
        const { preControlRating, postControlRating } = rating;
        setPostRating({
          severity: postControlRating.severity,
          likelihood: postControlRating.likelihood,
        });
        setPreRating({
          severity: preControlRating.severity,
          likelihood: preControlRating.likelihood,
        });
        dispatch(setSelectedControls(rating?.selectedControls ?? []));
      }
    }
  }, [hazardId, editedHazard]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onRatingChange(preRating, postRating, hazardId);
  }, [preRating, postRating]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPreSeverityChange = (value) => {
    setPreRating({
      ...preRating,
      severity: value,
    });
  };

  const onPostSeverityChange = (value) => {
    setPostRating({
      ...postRating,
      severity: value,
    });
  };

  const onPreLikelihoodChange = (value) => {
    setPreRating({
      ...preRating,
      likelihood: value,
    });
  };

  const onPostLikelihoodChange = (value) => {
    setPostRating({
      ...postRating,
      likelihood: value,
    });
  };

  const getSelectedControls = () => {
    const rating = ratings.find(
      (savedRating) => savedRating.hazardId === hazardId,
    );
    let controls = [] as Control[];
    if (rating) {
      controls = selectedControls;
    } else {
      const currentHazard = selectedHazards.find(
        (hazard) => hazard.id === hazardId,
      );
      const mappedControls = currentHazard?.mappedControls || [];
      const filteredMappedControls = mappedControls.filter(
        (mappedControl) =>
          !selectedControls.some(
            (selectedControl) => selectedControl.id === mappedControl.id,
          ),
      );
      controls = [...selectedControls, ...filteredMappedControls];
    }
    return controls;
  };

  return (
    <>
      <SliderRating
        title={getText('rams_rating_steps_rate_pre_controls')}
        rating={preRating}
        onSeverityChange={onPreSeverityChange}
        onLikelihoodChange={onPreLikelihoodChange}
        defaultActive
      />
      <CollapseContainer>
        <Display.Collapsible
          defaultActive={!!editedHazard}
          title={getText('rams_rating_steps_add_controls')}
        >
          <>
            <ControlList controls={getSelectedControls()} />
            <SearchControl hazardId={hazardId} />
          </>
        </Display.Collapsible>
      </CollapseContainer>

      <SliderRating
        title={getText('rams_rating_steps_rate_post_controls')}
        rating={postRating}
        onSeverityChange={onPostSeverityChange}
        onLikelihoodChange={onPostLikelihoodChange}
        defaultActive={!!editedHazard}
      />
    </>
  );
};

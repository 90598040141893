export type Control = {
  id: number;
  title: string;
  titleEs: string | null;
  assessmentTypeList: number[];
};

export type BackendControl = {
  id: number;
  title: string;
  title_es: string | null;
  assessment_type_list: number[];
  assessment_type: number[];
};

const mapToControl = (backendControl: any): Control => ({
  id: backendControl.id,
  title: backendControl.title,
  titleEs: backendControl.title_es,
  assessmentTypeList: backendControl?.assessment_type_list ?? [],
});

const mapToBackendControl = (input: Control): BackendControl => ({
  id: input.id,
  title: input.title,
  title_es: input.titleEs ? input.titleEs : null,
  assessment_type_list: input.assessmentTypeList,
  assessment_type: input.assessmentTypeList,
});

export const Controls = { mapToControl, mapToBackendControl };

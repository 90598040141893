import styled from 'styled-components';
import { BrandPatternCircles } from 'assets/images';
import theme from 'theme';

const { breakpoints, fonts, fontSizes, colors, media } = theme;

export const DesktopLineBreak = styled.br`
  display: block;
  ${media.sm`
    display: none;
  `}
`;

export const Container = styled.div`
  flex-grow: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-image: url(${BrandPatternCircles});
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  padding-top: 150px;
  ${media.sm`
    padding: 47px 25px;
    align-items: flex-start;
    background-position-x: left;
  `}
`;

export const Instructions = styled.p`
  text-align: center;
  align-self: center;
  font-family: ${fonts.light};
  font-size: ${fontSizes.m};
  color: ${colors.white};
  padding: 50px 0px 35px;
  ${media.sm`
    text-align: left;
    align-self: flex-start;
    padding: 35px 0px 15px;
  `}
`;

export const Bold = styled.span`
  font-family: ${fonts.bold};
`;

export const BadgeContainer = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  ${media.sm`
    flex-direction: column;
  `}
`;

export const AppStoreLink = styled.img`
  @media only screen and (min-width: ${breakpoints.sm}px) {
    margin-top: 15px;
    height: 65px;
  }
  ${media.sm`
    width: 185px;
  `}
`;

export const GooglePlayLink = styled.img`
  @media only screen and (min-width: ${breakpoints.sm}px) {
    height: 95px;
  }
  ${media.sm`
    width: 212px;
    margin-left: -15px;
  `}
`;

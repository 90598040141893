import { MarkerIcon } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const Container = styled.div`
  height: 80vh;
  display: flex;
  box-sizing: border-box;
`;

export const DetailsContainer = styled.div`
  ${mixins.flexColumn}
  row-gap: 25px;
  ${media.xl`
    row-gap: 15px;
  `}
  overflow: auto;
  padding-top: 35px;
  padding-bottom: 35px;
  width: 100%;
  ${media.md`
    width: auto;
  `}
`;

export const Header = styled.div`
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 10px;
  ${media.xl`
    padding-bottom: 5px;
  `}
`;

export const Address = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.massive};
  ${media.xl`
    font-size: ${fontSizes.xxxl};
  `}
`;

export const DateTime = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  color: ${colors.darkGrey};
`;

export const ColumnWrapper = styled.div`
  ${mixins.flexColumn}
  padding-left: 35px;
  padding-right: 35px;
`;

export const NearbyJobsHeader = styled.div`
  ${mixins.flexBetween};
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const NearbyJobsTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
`;

export const MarkerIconContainer = styled.div`
  border: 4px solid ${colors.black};
  width: 40px;
  height: 40px;
  ${mixins.flexFullCenter};
`;

export const Marker = styled(MarkerIcon)`
  width: 20px;
  height: 30px;
`;

import axios, { AxiosResponse } from 'axios';
import { BackendData } from './types';
import { mapToSiteBriefing } from './helper';
import { SiteBriefing } from 'model/State/SiteBriefing';

export const getSiteBriefingData = async (
  jobId: number,
  siteBriefingId: number,
): Promise<SiteBriefing> => {
  const response: AxiosResponse = await axios.get(
    `/jobs/${jobId}/briefing/${siteBriefingId}/`,
    {
      headers: {
        Accept: 'application/json; version=2;',
      },
    },
  );
  const { data }: { data: BackendData } = response;
  return mapToSiteBriefing(data);
};

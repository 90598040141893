import { useSelector } from 'react-redux';
import { Names } from 'helpers';
import {
  selectWorkspaceSettings,
  selectGlobalLanguageSetting,
} from 'store/selectors';
import { UserRole } from 'model/enum/UserRole';
import { getLabelByLocale } from 'helpers/utils';
import {
  Avatar,
  BadgeContainer,
  BadgeInfo,
  BadgeName,
  BadgeText,
} from './styled';
import { DotSeparator } from 'components/ui/DotSeparator';
import { User } from 'model/User';

type Props = {
  user?: User;
};

export const FatigueUserBadge = ({ user }: Props): JSX.Element => {
  const {
    fieldworker_label,
    fieldworker_label_es,
    manager_label,
    manager_label_es,
    backoffice_label,
    backoffice_label_es,
  } = useSelector(selectWorkspaceSettings);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const getTitleForRole = () => {
    if (user?.role === UserRole.MANAGER) {
      return getLabelByLocale(globalAppLanguage, {
        manager_label,
        manager_label_es,
      });
    }
    if (user?.role === UserRole.BACKOFFICE_ADMIN) {
      return getLabelByLocale(globalAppLanguage, {
        backoffice_label,
        backoffice_label_es,
      });
    }
    return getLabelByLocale(globalAppLanguage, {
      fieldworker_label,
      fieldworker_label_es,
    });
  };

  return (
    <BadgeContainer>
      <Avatar>{Names.getInitials(user?.firstName, user?.lastName)}</Avatar>
      <BadgeText>
        <BadgeName>
          {Names.capitalizeFullName(user?.firstName, user?.lastName)}
        </BadgeName>
        <BadgeInfo>
          {getTitleForRole()}{' '}
          {user?.phoneNumber ? (
            <>
              <DotSeparator />
              {user.phoneNumber}
            </>
          ) : null}
        </BadgeInfo>
      </BadgeText>
    </BadgeContainer>
  );
};

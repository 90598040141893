import { Button } from '@mui/material';
import { Arrow } from 'assets/icons';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins } = theme;

export const ConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 35px;
  padding-right: 35px;
`;

export const ConditionsTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  padding-bottom: 10px;
`;

export const ConditionsIcon = styled(({ component, ...props }) =>
  React.createElement(component, props),
)`
  width: 40px;
  min-width: 40px;
  margin-right: 15px;
  align-self: start;
`;

export const ConditionsInfo = styled.div`
  ${mixins.flexCenter};
`;

export const InfoText = styled.div`
  font-size: ${fontSizes.default};
`;

export const DistanceText = styled.div`
  font-size: ${fontSizes.default};
  font-family: ${fonts.bold};
`;

export const ConditionInfoContainer = styled.div`
  ${mixins.flexColumn};
`;

export const ConditionItem = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
`;

export const InfoTextWrapper = styled.div`
  ${mixins.flexColumn}
`;

export const BulletPointWrapper = styled.div`
  width: 5%;
  min-width: 5%;
  margin-right: 15px;
`;

export const BulletPoint = styled.div`
  min-height: 10px;
  min-width: 10px;
  max-height: 10px;
  max-width: 10px;
  background-color: ${colors.black};
`;

export const Distance = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
`;

export const JobCardWrapper = styled(Button)`
  &.MuiButtonBase-root {
    width: 100%;
    border: none;
    outline: 2px solid ${colors.black};
    text-transform: none;
    letter-spacing: 0;
    border-radius: 0;
    color: ${colors.darkGrey};
    font-family: ${fonts.bold};
    padding: 20px;
    margin: 10px 0 20px 0;
    ${mixins.flexBetween};
    align-items: start;
    &:hover {
      outline: 3px solid ${colors.black};
      background-color: ${colors.white} !important;
    }
  }
`;

export const JobDetailsContainer = styled.div`
  text-align: start;
`;

export const Address = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  color: ${colors.black};
`;

export const ArrowRight = styled(Arrow)`
  width: 40px;
`;

export const EmptyState = styled.div`
  color: ${colors.darkGrey};
  font-family: ${fonts.italic};
`;

import { useMemo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useText } from 'hooks';
import { Notification, ToastType } from './constants';
import { ToastBase } from './ToastBase';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetPdfCreation,
  selectPdfCreationNotifications,
} from 'store/slices/notifications';
import { CreatePdfIcon } from './styled';
import PdfCreationItem from 'model/State/notifications/PdfCreationItem';

export const PdfCreationToast = (): JSX.Element => {
  const notifications = useSelector(selectPdfCreationNotifications);
  const getText = useText();
  const dispatch = useDispatch();
  const CONTAINER_ID = 'pdf-creation-container';
  const notificationList: Notification[] = useMemo(
    () =>
      notifications.map((item: PdfCreationItem) => ({
        id: item.id,
        title: getText('pdf_export_info_modal_title'),
        customIcon: CreatePdfIcon,
        canBeDismissed: false,
      })),
    [notifications, getText],
  );

  const onClose = (id: number) => {
    dispatch(resetPdfCreation(id));
  };

  return (
    <ToastBase
      containerId={CONTAINER_ID}
      type={ToastType.SECONDARY}
      notifications={notificationList}
      onClose={(id) => onClose(id)}
      autoClose={5000}
    />
  );
};

import { useDrag, useDrop } from 'react-dnd';
import { RamsSection } from './RamsSection';
import { MethodStatementSection } from 'model/Assessment/RAMS';

type Props = {
  section: MethodStatementSection;
  onSectionChange: (value: MethodStatementSection) => void;
  onDeleteClick: (value: MethodStatementSection) => void;
  findSection: (id: number) => {
    section: MethodStatementSection | null;
    index: number | null;
  };
  moveSection: (id: number, index: number) => void;
  deleteDisabled?: boolean;
};

// DraggableSection
export const DraggableSection = ({
  section,
  onSectionChange,
  onDeleteClick,
  findSection,
  moveSection,
  deleteDisabled,
}: Props): JSX.Element => {
  const { orderId: id } = section;
  const originalIndex = findSection(id).index;
  const [{ opacity }, drag, preview] = useDrag(
    () => ({
      type: 'section',
      item: { id, originalIndex },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex: droppedIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop && droppedIndex !== null) {
          moveSection(droppedId, droppedIndex);
        }
      },
    }),
    [id, originalIndex, moveSection],
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'section',
      canDrop: () => false,
      hover(item: MethodStatementSection) {
        if (item.id && item.id !== id) {
          const { index: overIndex } = findSection(id);
          if (overIndex !== null) {
            moveSection(item.id, overIndex);
          }
        }
      },
    }),
    [findSection, moveSection],
  );

  return (
    <div ref={(node) => drop(node)}>
      <RamsSection
        key={section.uuid}
        section={section}
        onSectionChange={onSectionChange}
        onDeleteClick={onDeleteClick}
        dragProps={{ opacity, drag, preview }}
        deleteDisabled={deleteDisabled}
      />
    </div>
  );
};

import { useText } from 'hooks';
import { HazardIcon, IconBox, Title, TitleContainer } from './styled';

export const HazardsAndControlsTitle = (): JSX.Element => {
  const getText = useText();

  return (
    <TitleContainer>
      <Title>{getText('hazards_and_controls')}</Title>
      <IconBox>
        <HazardIcon />
      </IconBox>
    </TitleContainer>
  );
};

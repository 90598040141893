import * as constants from 'utils/constants';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  canSeeWrapUpSummary,
  selectGlobalLanguageSetting,
  selectJobsView,
} from 'store/selectors';
import { InformationLabel } from 'components/ui/Shared/EmptyState';
import { Card, Loader } from 'components/ui';
import { useEffect } from 'react';
import { useText } from 'hooks';
import RootState from 'model/State/RootState';
import { fetchEvidences } from 'store/actions/jobs';
import OverviewTabBase from './OverviewTabBase';
import { Camera, Video, Evidence as EvidenceIcon } from 'assets/icons';
import { getLocalizedCamelCaseValue } from 'helpers/locales';
import { InsightsAvailableTag } from 'components/ui/Tags/InsightsAvailableTag';

export const Evidences = (): JSX.Element => {
  const { jobId } = useParams();
  const { evidences, isEvidencesLoaded } = useSelector(
    (state: RootState) => state.jobs,
  );
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const dispatch = useDispatch();
  const getText = useText();
  const view = useSelector(selectJobsView);
  const hasWrapUpSummary = useSelector(canSeeWrapUpSummary);

  useEffect(() => {
    dispatch(fetchEvidences(jobId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getIcon = (thumbnail) => {
    if (thumbnail?.includes('photos')) {
      return Camera;
    }
    if (thumbnail?.includes('videos')) {
      return Video;
    }
    return EvidenceIcon;
  };

  return (
    <OverviewTabBase title={getText('tabs_evidence')}>
      <>
        {!isEvidencesLoaded && <Loader />}
        {isEvidencesLoaded && !evidences.length && (
          <InformationLabel>{getText('evidence_no_display')}</InformationLabel>
        )}
        {isEvidencesLoaded &&
          evidences &&
          evidences.map((evidence) => (
            <>
              <Card.JobDetail
                event={constants.EVENT_VIEW_EVIDENCE_ITEM}
                eventProperties={{
                  jobId,
                  evidenceId: evidence.id,
                  evidenceCategory: evidence.type ?? getText('evidence'),
                  source: 'job-overview',
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  insights_available: evidence?.summaryAvailable,
                }}
                key={`evidence-${evidence.id}`}
                title={getText('evidence')}
                subtitle={
                  evidence.type
                    ? getLocalizedCamelCaseValue(
                        evidence,
                        'type',
                        globalAppLanguage,
                      )
                    : getText('evidence')
                }
                creator={evidence.createdBy}
                date={evidence.createdAt}
                to={`/jobs/${evidence.jobId}/evidence/${evidence.id}`}
                IconComponent={getIcon(evidence.thumbnail)}
              />
              {hasWrapUpSummary && evidence.summaryAvailable && (
                <InsightsAvailableTag />
              )}
            </>
          ))}
      </>
    </OverviewTabBase>
  );
};

import { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import type { ActiveElement, ChartData, ChartOptions, Plugin } from 'chart.js';
import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { Loader } from 'components/ui';
import {
  getTooltipData,
  tooltipHandler,
  getSelectedDetails,
} from './TooltipProvider';
import JobData from 'model/JobData';
import InsightGraphItemData from 'model/Insight/InsightGraphItemData';
import TableData from 'model/Insight/TableData';
import { formatDate, formatDayAndMonth, getDaysBetween } from 'helpers/dates';

const { colors, fonts, fontSizes } = theme;

// Styled Components
const GraphWrapper = styled.div`
  width: 100%;
  border-top: 3px solid ${colors.black};
  background-color: ${colors.white};
  margin-top: 30px;
`;

const GraphContainer = styled.div`
  padding: 16px;
  height: 475px;
  position: relative;
`;

const NoData = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  display: flex;
  width: 100%;
  height: 5%;
  justify-content: center;
  margin-bottom: 8px;
`;

const StyledBar = styled(Bar)`
  background-color: ${colors.white};
  height: 95% !important;
`;

type Props = {
  graphData: InsightGraphItemData[];
  depotName: string;
  startDate: Date;
  endDate: Date;
  averageValue: number;
  isOutputPerDay?: boolean;
  setTableData?: (value: TableData) => void;
  hoverTooltipId?: string;
};

type GraphDataset = {
  label: string;
  data: number[] | number;
  borderColor?: string;
  borderWidth?: number;
  backgroundColor?: string[];
  pointRadius?: number;
  pointHoverRadius?: number;
  borderDash?: [number, number];
  type?: string;
  fill?: boolean;
  barPercentage?: number;
  categoryPercentage?: number;
  jobDetails?: JobData[];
};

// InsightBarContainer
const InsightBarContainer = ({
  graphData,
  depotName,
  startDate,
  endDate,
  averageValue,
  isOutputPerDay,
  setTableData = () => {},
  hoverTooltipId,
}: Props): JSX.Element => {
  const getText = useText();
  const [graphDataSet, setGraphDataSet] = useState<{
    labels: string[];
    datasets: GraphDataset[];
  }>({
    labels: [],
    datasets: [],
  });
  const [selectedItem, setSelectedItem] = useState<ActiveElement | null>(null);
  const [isNoData, setIsNoData] = useState(false);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  const generateGraphData = (results) => {
    const halfPoint = Math.ceil(results.length / 2);
    const lastPeriod = results.slice(0, halfPoint);
    const currentPeriod = results.slice(-halfPoint);
    const average = Array.from(
      { length: Math.round(getDaysBetween(startDate, endDate) / 2) + 1 },
      () => averageValue,
    );
    const data: { labels: string[]; datasets: GraphDataset[] } = {
      labels: [],
      datasets: [],
    };
    data.labels = currentPeriod.map((item) =>
      formatDayAndMonth(item.created_date),
    );
    data.datasets.push({
      label: isOutputPerDay
        ? getText('insights_graph_average_meters_per_day_for_region', {
            regionName: depotName,
          })
        : getText('insights_graph_average_jobs_per_day_for_region', {
            regionName: depotName,
          }),
      data: average,
      borderColor: colors.black,
      borderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 0,
      borderDash: [4, 4],
      type: 'line',
      fill: false,
    });
    data.datasets.push({
      label: getText('insights_graph_preceding_period'),
      data: lastPeriod.map((item) => item.sum_units),
      backgroundColor: results.map(() => colors.grey),
      barPercentage: 0.6,
      categoryPercentage: 0.4,
      jobDetails: lastPeriod.map((item) => item.job_details),
    });
    data.datasets.push({
      label: `${formatDate(startDate)} - ${formatDate(endDate)}`,
      data: currentPeriod.map((item) => item.sum_units),
      backgroundColor: results.map(() => colors.orange),
      barPercentage: 0.6,
      categoryPercentage: 0.4,
      jobDetails: currentPeriod.map((item) => item.job_details),
    });
    setGraphDataSet(data);
  };

  useEffect(() => {
    if (graphData) {
      setGraphDataSet({
        labels: [],
        datasets: [],
      });
      generateGraphData(graphData);
      setIsNoData(graphData.length === 0);
    }
  }, [graphData, globalAppLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  const options: ChartOptions<'bar'> = {
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    maintainAspectRatio: false,
    onClick: (_, item, chart) => {
      if (
        item.length &&
        item[0] !== selectedItem &&
        (item[0].datasetIndex !== selectedItem?.datasetIndex ||
          item[0].index !== selectedItem?.index)
      ) {
        setTableData(getTooltipData(chart));
        const selectedDetails = getSelectedDetails(chart);
        const modifiedDatasets = graphDataSet?.datasets;

        if (
          selectedItem &&
          modifiedDatasets &&
          modifiedDatasets?.[selectedItem.datasetIndex]?.backgroundColor?.[
            selectedItem?.index
          ]
        ) {
          modifiedDatasets[selectedItem.datasetIndex].backgroundColor![
            selectedItem?.index
          ] = selectedItem?.datasetIndex === 1 ? colors.grey : colors.orange;
        }

        setSelectedItem(item[0]);
        if (
          modifiedDatasets?.[selectedDetails.datasetIndex]?.backgroundColor?.[
            selectedDetails.dataIndex
          ]
        )
          modifiedDatasets[selectedDetails.datasetIndex].backgroundColor![
            selectedDetails.dataIndex
          ] = colors.red;
        setGraphDataSet((prevState) => ({
          ...prevState,
          datasets: modifiedDatasets ?? [],
        }));
        chart.update();
        Analytics.trackEvent(constants.INSIGHTS_JOBS_PER_DAY_DRILLDOWN, {
          day: graphDataSet?.labels[selectedDetails.dataIndex],
          period: graphDataSet?.datasets[selectedDetails.datasetIndex].label,
        });
      }
    },
    plugins: {
      tooltip: isOutputPerDay
        ? {
            enabled: true,
          }
        : {
            enabled: false,
            position: 'nearest',
            mode: 'x',
            external: (context) => tooltipHandler(context, hoverTooltipId),
          },
      legend: {
        display: true,
        position: 'bottom',
        align: 'end',
        labels: {
          font: {
            family: fonts.bold,
          },
          color: colors.darkGrey,
          padding: 16,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: isOutputPerDay
            ? getText('insights_graph_meters_per_day_for_depot', { depotName })
            : getText('insights_graph_jobs_per_day_for_depot', { depotName }),
          font: {
            size: 14,
            family: fonts.default,
          },
          padding: 16,
        },
      },
    },
  };

  const plugin: Plugin<'bar', Object>[] = [
    {
      id: 'plugin',
      beforeDraw: (chart) => {
        const precedingLegend = chart?.legend?.legendItems?.[1];
        const lastXLegend = chart?.legend?.legendItems?.[2];
        if (precedingLegend) {
          precedingLegend.fillStyle = colors.grey;
        }
        if (lastXLegend) {
          lastXLegend.fillStyle = colors.orange;
        }
      },
    },
  ];

  return (
    <GraphWrapper>
      <GraphContainer>
        {graphDataSet && isNoData && (
          <NoData>{getText('insights_graph_no_job')}</NoData>
        )}
        {graphDataSet ? (
          <StyledBar
            data={graphDataSet as ChartData<'bar'>}
            options={options}
            plugins={plugin}
          />
        ) : (
          <Loader />
        )}
      </GraphContainer>
    </GraphWrapper>
  );
};

export default InsightBarContainer;

import styled from 'styled-components';
import { ChevronDown, Cross, Pencil } from 'assets/icons';

export const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const ChevronIcon = styled(ChevronDown)`
  width: 20px;
  height: 20px;
`;

export const PencilIcon = styled(Pencil)`
  width: 20px;
  height: 20px;
`;

export const CrossIcon = styled(Cross)`
  width: 20px;
  height: 20px;
`;

import { mapToUser } from 'api/User/helpers';
import { mapToPhoto, mapToVideo } from 'api/Media/helpers';
import { Evidence } from 'model/Evidence/Evidence';
import { EvidenceType } from 'model/Evidence/EvidenceType';
import { EvidenceUpdateResponse } from 'model/Evidence/EvidenceUpdateResponse';

export const mapToEvidence = (backendEvidence: any): Evidence => {
  const isTypeString = typeof backendEvidence.type === 'string';
  return {
    id: backendEvidence.id,
    jobId: backendEvidence.job_id,
    type: isTypeString ? backendEvidence.type : backendEvidence.type?.title,
    typeEs:
      (isTypeString
        ? backendEvidence.type_es
        : backendEvidence.type?.type_es) ?? '',
    typeId: isTypeString ? backendEvidence.type_id : backendEvidence.type?.id,
    videos: backendEvidence.videos
      ? backendEvidence.videos.map(mapToVideo)
      : [],
    photos: backendEvidence.photos
      ? backendEvidence.photos.map(mapToPhoto)
      : [],
    comments: backendEvidence.comments,
    thumbnail: backendEvidence.thumbnail ?? '',
    createdBy: mapToUser(backendEvidence.created_by),
    createdAt: new Date(backendEvidence.created_at),
    updatedAt: new Date(backendEvidence.updated_at),
    sentAt: backendEvidence.sent_at ? new Date(backendEvidence.sent_at) : null,
    uuid: backendEvidence.uuid,
    summaryAvailable: backendEvidence.summary_available ?? false,
  };
};

export const mapToEvidenceType = (backendEvidenceType: any): EvidenceType => ({
  id: backendEvidenceType.id,
  title: backendEvidenceType.title,
  titleEs: backendEvidenceType.title_es ?? null,
});

export const mapToEvidenceUpdateResponse = (
  backendEvidenceUpdateResponse: any,
): EvidenceUpdateResponse => ({
  id: backendEvidenceUpdateResponse.id,
  comments: backendEvidenceUpdateResponse.comments ?? null,
  videoFeedback: backendEvidenceUpdateResponse.video_feedback ?? null,
  videoFeedbackReason:
    backendEvidenceUpdateResponse.video_feedback_reason ?? null,
});

import styled from 'styled-components';
import theme from 'theme';

const { fontSizes, colors, fonts, media } = theme;

export const H1 = styled.h1`
  color: ${colors.white};
  font-size: ${fontSizes.massive};
  margin-bottom: 10px !important;
  ${media.md`
    font-size: ${fontSizes.xxl};
  `}
  ${media.sm`
    font-size: ${fontSizes.xl};
  `}
`;

export const P = styled.p`
  font-family: ${fonts.light};
  font-size: ${fontSizes.l};
  color: ${colors.white};
  margin-bottom: 25px !important;
  ${media.md`
    margin-bottom: 30px !important;
    font-size: ${fontSizes.m};
  `}
  ${media.sm`
    font-size: ${fontSizes.s};
  `}
`;

export const ErrorContainer = styled.div`
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

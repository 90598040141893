import { Photo } from 'model/Media/Photo';
import { Hazard, Hazards } from '../Hazard';
import { Control, Controls } from '../Control';
import { mapToPhoto } from 'api/Media/helpers';
import theme from 'theme';

const { colors } = theme;

export type MethodStatement = {
  assessmentId: string;
  sections: MethodStatementSection[];
  createdAt: Date | null;
};

export type MethodStatementSection = {
  uuid: string;
  orderId: number;
  title: string;
  content: string;
  photos: Photo[];
  id?: number;
};

export type MethodStatementSectionTemplate = {
  name: string;
  title: string;
  content: string;
};

export type RamsReview = {
  id: number;
  hazard: Hazard;
  preControlSeverity: number;
  preControlLikelihood: number;
  postControlSeverity: number;
  postControlLikelihood: number;
  controls: Control[];
};

export type RamsRating = {
  hazardId: number;
  preControlRating: RatingValue;
  postControlRating: RatingValue;
  selectedControls?: Control[];
};

export type RatingValue = {
  severity: number;
  likelihood: number;
};

export type BackendRamsReview = {
  hazard_id: number;
  pre_control_severity: number;
  pre_control_likelihood: number;
  post_control_severity: number;
  post_control_likelihood: number;
  controls: number[];
};

export type BackendSection = {
  title: string;
  content: string;
  photos: number[];
  order_id: number;
};

export const ratingValues = [
  { title: 'rams_rating_not_rated', color: colors.grey, id: 1, value: 0 },
  { title: 'rams_rating_very_low', color: colors.green, id: 2, value: 1 },
  { title: 'rams_rating_low', color: colors.green, id: 3, value: 2 },
  { title: 'rams_rating_medium', color: colors.lightOrange, id: 4, value: 3 },
  { title: 'rams_rating_high', color: colors.darkRed, id: 5, value: 4 },
  { title: 'rams_rating_very_high', color: colors.darkRed, id: 6, value: 5 },
];

export enum RamsStages {
  hazardIdentification = 'hazard-identification',
  hazardRating = 'hazard-rating',
  hazardRatingSummary = 'hazard-rating-summary',
  hazardRatingReview = 'hazard-rating-review',
  ramsActionSelection = 'rams-action-selection',
  methodStatement = 'method-statement',
  methodStatementSummary = 'method-statement-summary',
  methodStatementView = 'method-statement-view',
  signOn = 'sign-on',
  signOnEditMembers = 'sign-on-edit-members',
}

type Stage = {
  id: number;
  stages: RamsStages[];
};

export const StageOrder: Stage[] = [
  {
    id: 2,
    stages: [
      RamsStages.hazardIdentification,
      RamsStages.hazardRating,
      RamsStages.hazardRatingReview,
      RamsStages.hazardRatingSummary,
      RamsStages.methodStatement,
      RamsStages.methodStatementSummary,
      RamsStages.methodStatementView,
      RamsStages.signOn,
      RamsStages.signOnEditMembers,
    ],
  },
  {
    id: 3,
    stages: [
      RamsStages.hazardRating,
      RamsStages.hazardRatingReview,
      RamsStages.hazardRatingSummary,
      RamsStages.methodStatement,
      RamsStages.methodStatementSummary,
      RamsStages.methodStatementView,
      RamsStages.signOn,
      RamsStages.signOnEditMembers,
    ],
  },
  {
    id: 4,
    stages: [
      RamsStages.methodStatement,
      RamsStages.methodStatementSummary,
      RamsStages.methodStatementView,
    ],
  },
];

const mapToRamsReview = (backendRamsReview: any): RamsReview => ({
  id: backendRamsReview.id,
  hazard: Hazards.mapToHazard(backendRamsReview.hazard),
  preControlSeverity: backendRamsReview.pre_control_severity,
  preControlLikelihood: backendRamsReview.pre_control_likelihood,
  postControlSeverity: backendRamsReview.post_control_severity,
  postControlLikelihood: backendRamsReview.post_control_likelihood,
  controls: backendRamsReview?.controls?.map(Controls.mapToControl) ?? [],
});

const mapToMethodStatement = (
  backendMethodStatement: any,
): MethodStatement => ({
  assessmentId: backendMethodStatement.assessment_id,
  sections:
    backendMethodStatement?.sections?.map(mapToMethodStatementSection) ?? [],
  createdAt: backendMethodStatement?.created_at
    ? new Date(backendMethodStatement.created_at)
    : null,
});

const mapToMethodStatementSection = (backendSection: any) => ({
  uuid: backendSection.uuid,
  orderId: backendSection.order_id,
  title: backendSection.title,
  content: backendSection.content,
  photos: backendSection?.photos?.map(mapToPhoto) ?? [],
  id: backendSection.id ?? undefined,
});

const mapToMethodStatementSectionTemplate = (
  backendSectionTemplate: any,
): MethodStatementSectionTemplate => ({
  name: backendSectionTemplate.name,
  title: backendSectionTemplate.title,
  content: backendSectionTemplate.content,
});

const mapToBackendRamsReview = (input: RamsRating): BackendRamsReview => ({
  hazard_id: input.hazardId,
  controls: input.selectedControls?.map((control) => control.id) || [],
  pre_control_severity: input.preControlRating.severity,
  pre_control_likelihood: input.preControlRating.likelihood,
  post_control_severity: input.postControlRating.severity,
  post_control_likelihood: input.postControlRating.likelihood,
});

const mapToBackendSection = (
  input: MethodStatementSection,
  index: number,
): BackendSection => ({
  title: input.title,
  content: input.content,
  photos: input?.photos.map((photo) => photo.id) ?? [],
  order_id: index,
});

const scrollToTop = () => {
  document
    ?.querySelector('.rams-container')
    ?.scrollTo({ top: 0, behavior: 'smooth' });
};

const scrollToRef = (ref) => {
  ref.current.scroll({
    top: 0,
    behavior: 'smooth',
  });
};

const isRatingLow = (ratings: RamsRating[]) =>
  ratings?.every(
    (rating) =>
      rating.preControlRating.severity <= 2 &&
      rating.preControlRating.likelihood <= 2 &&
      rating.postControlRating.severity <= 2 &&
      rating.postControlRating.likelihood <= 2,
  );

const isPostControlRatingLow = (ratings: RamsRating[]) =>
  ratings?.every(
    (rating) =>
      rating.postControlRating.severity <= 2 &&
      rating.postControlRating.likelihood <= 2,
  );

export const RamsAssessments = {
  mapToRamsReview,
  mapToMethodStatement,
  mapToMethodStatementSection,
  mapToMethodStatementSectionTemplate,
  mapToBackendRamsReview,
  mapToBackendSection,
  scrollToTop,
  scrollToRef,
  isRatingLow,
  isPostControlRatingLow,
};

import * as constants from 'utils/constants';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGlobalLanguageSetting,
  selectIsFetchingLogs,
  selectJobLogs,
  selectJobsView,
} from 'store/selectors';
import { InformationLabel } from 'components/ui/Shared/EmptyState';
import { Card, Loader } from 'components/ui';
import { useEffect } from 'react';
import { useText } from 'hooks';
import OverviewTabBase from './OverviewTabBase';
import { Note } from 'assets/icons';
import { fetchLogs } from 'store/actions/jobs';
import { getLocalizedValue } from 'helpers/locales';

const Logs = (): JSX.Element => {
  const { jobId } = useParams();
  const logs = useSelector(selectJobLogs(jobId));
  const isFetchingLogs = useSelector(selectIsFetchingLogs);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const view = useSelector(selectJobsView);
  const getText = useText();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLogs(jobId));
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildProps = (log) => ({
    event: constants.EVENT_VIEW_NOTE_ITEM,
    eventProperties: {
      jobId,
      logId: log.id,
      logType: 'Note',
      source: 'job-overview',
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    },
    key: `log-${log.id}`,
    to: `/jobs/${jobId}/logs/${log.id}`,
  });

  return (
    <OverviewTabBase title={getText('tabs_logs')}>
      <>
        {isFetchingLogs ? (
          <Loader />
        ) : (
          <>
            {!logs?.length ? (
              <InformationLabel>
                {getText('job_log_no_display')}
              </InformationLabel>
            ) : (
              logs?.map((log) => (
                <Card.JobDetail
                  title={getText('job_log_note')}
                  subtitle={getLocalizedValue(log, 'title', globalAppLanguage)}
                  creator={log.createdBy}
                  date={log.createdAt}
                  IconComponent={Note}
                  {...buildProps(log)}
                />
              ))
            )}
          </>
        )}
      </>
    </OverviewTabBase>
  );
};

export default Logs;

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { isAfter } from 'date-fns';
import { getValidJobCoordinates } from 'helpers/utils';
import { CommandCenter } from 'model/CommandCenter';
import { CommandCenterActivity } from 'model/CommandCenter/Activity';
import { CommandCenterFilterOptions } from 'model/CommandCenter/FilterOptions';
import { CommandCenterStats } from 'model/CommandCenter/Stats';
import Coordinate from 'model/Coordinate';
import { CommandCenterFilter } from 'model/enum/CommandCenterFilter';
import { Job } from 'model/Job/Job';
import { CommandCenterState } from 'model/State/CommandCenter';
import RootState from 'model/State/RootState';
import { COMMAND_CENTER_LATEST_ACTIVITY_MAX_NUMBER_OF_ITEMS } from 'utils/constants';

const initialState: CommandCenterState = {
  stats: {
    jobsCompleted: [],
    jobsLive: [],
    jobsNotStarted: [],
    jobsWithMajorHazards: [],
    jobsWithUnresolvedBlockers: [],
    jobsWithoutVras: [],
  },
  isFetchingStats: false,
  activities: [],
  isFetchingActivities: false,
  numberOfActivities: COMMAND_CENTER_LATEST_ACTIVITY_MAX_NUMBER_OF_ITEMS,
};

export const commandCenter = createSlice({
  name: 'commandCenter',
  initialState,
  reducers: {
    fetchStats: (
      state: CommandCenterState,
      action: PayloadAction<CommandCenterFilterOptions>,
    ) => {
      state.isFetchingStats = true;
    },
    setStats: (
      state: CommandCenterState,
      action: PayloadAction<CommandCenterStats>,
    ) => {
      state.stats = action.payload;
      state.isFetchingStats = false;
    },
    fetchActivities: (
      state: CommandCenterState,
      action: PayloadAction<{
        filterOptions: CommandCenterFilterOptions;
        options: { clearState: boolean };
      }>,
    ) => {
      if (action.payload.options.clearState) {
        state.isFetchingActivities = true;
        state.activities = [];
        state.numberOfActivities =
          COMMAND_CENTER_LATEST_ACTIVITY_MAX_NUMBER_OF_ITEMS;
      } else {
        state.isFetchingActivities = true;
      }
    },
    setActivities: (
      state: CommandCenterState,
      action: PayloadAction<{
        activities: CommandCenterActivity[];
        options: { isLastPage: boolean };
      }>,
    ) => {
      const newItemsThatAreNotPresent = action.payload.activities.filter(
        (newActivity) =>
          !state.activities.find(
            (oldActivity) => oldActivity.id === newActivity.id,
          ),
      );
      let activities = [...state.activities, ...newItemsThatAreNotPresent];
      activities.sort((a, b) =>
        isAfter(new Date(a.createdAt), new Date(b.createdAt)) ? -1 : 1,
      );
      activities = activities.filter(
        (_, index) =>
          index < COMMAND_CENTER_LATEST_ACTIVITY_MAX_NUMBER_OF_ITEMS,
      );

      if (action.payload.options.isLastPage) {
        state.isFetchingActivities = false;
        state.activities = activities;
        state.numberOfActivities = activities.length;
      } else {
        state.activities = activities;
      }
    },
    setActivitiesError: (state: CommandCenterState) => {
      state.isFetchingActivities = false;
      state.activities = [];
    },
    resetFetching: (state: CommandCenterState) => {
      state.isFetchingActivities = false;
    },
  },
});

export const {
  fetchStats,
  setStats,
  fetchActivities,
  setActivities,
  setActivitiesError,
  resetFetching,
} = commandCenter.actions;

export const selectIsFetchingActivities = (state: RootState): boolean =>
  state.commandCenter.isFetchingActivities;
export const selectNumberOfActivities = (state: RootState): number =>
  state.commandCenter.numberOfActivities;
export const selectStats = (state: RootState): CommandCenterStats =>
  state.commandCenter.stats;
export const selectActivities = (state: RootState): CommandCenterActivity[] =>
  state.commandCenter.activities;

const selectCoordinatesByActiveFilter = createSelector(
  [selectStats, (_: RootState, filterId: CommandCenterFilter) => filterId],
  (stats: CommandCenterStats, filterId: CommandCenterFilter) => {
    const selectedJobs = stats[filterId];
    return getValidJobCoordinates(selectedJobs).map((job: Job) => ({
      lat: parseFloat(job.latitude),
      lng: parseFloat(job.longitude),
      type: CommandCenter.getJobType(job, filterId, stats),
      jobId: job.id,
    }));
  },
  {
    memoize: (func) => {
      let lastResult = null;
      return (...args) => {
        const result = func(...args);

        if (
          Array.isArray(result) &&
          Array.isArray(lastResult) &&
          CommandCenter.areCoordinatesEqual(lastResult, result)
        ) {
          return lastResult;
        }

        lastResult = result;

        return result;
      };
    },
  },
);

export const selectCoordinates =
  (filterId: CommandCenterFilter) =>
  (state: RootState): Coordinate[] =>
    selectCoordinatesByActiveFilter(state, filterId);

export const commandCenterReducer = commandCenter.reducer;

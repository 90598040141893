import styled from 'styled-components';
import theme from 'theme';

const { colors, media, mixins } = theme;

// Styled components
const StatContainer = styled.div`
  background-color: ${colors.white};
  width: 22%;
  height: 100%;
  margin-right: 4%;
  padding: 15px;
  box-sizing: border-box;
  &:last-of-type {
    margin: 0;
  }
  ${mixins.flexColumn};
  align-items: start;
  justify-content: space-evenly;
  ${media.xl`
      width: 22.75%;
      margin-right: 3%;
      padding: 10px;
  `}
`;

const StatNumber = styled.div`
  background-color: ${colors.lightGrey};
  height: 20%;
  width: 20%;
`;

const StatText = styled.div`
  background-color: ${colors.lightGrey};
  text-align: start;
  height: 20%;
  width: 60%;
`;

const StatTime = styled.div`
  background-color: ${colors.lightGrey};
  height: 20%;
  width: 35%;
`;

const StatusCardSkeleton = ({
  className,
}: {
  className?: string;
}): JSX.Element => (
  <StatContainer className={className}>
    <StatNumber />
    <StatText />
    <StatTime />
  </StatContainer>
);

export default StatusCardSkeleton;

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useText } from 'hooks';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { Card, Button, Input } from 'components/ui';
import { signIn, resetSessionErrors } from 'store/actions/session';
import {
  LoginForm,
  PaddedWrapper,
  ErrorContainer,
  LinkWrapper,
} from './styled';
import { FormContainer } from '../styled';
import RootState from 'model/State/RootState';
import { SecondaryAction } from './SecondaryAction';
import theme from 'theme';
import { LogoSlogan } from 'components/ui/Logos';

const { colors } = theme;

type Props = {
  onReturnToSSOClick: (email: string) => void;
  email: string;
  setEmail: (email: string) => void;
  showReturnToSSO: boolean;
  returnToSSOButtonText: string;
};

export const UsernamePasswordForm = ({
  onReturnToSSOClick,
  email,
  setEmail,
  showReturnToSSO,
  returnToSSOButtonText,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const getText = useText();
  const [password, setPassword] = useState('');
  const { isLoading, error } = useSelector((state: RootState) => state.session);
  const handleForgottenPasswordClick = () => {
    dispatch(resetSessionErrors());
    Analytics.trackEvent(constants.EVENT_PASSWORD_CLICK_FORGOTTEN_LINK);
  };
  const handleRequestMagicLinkClick = () => {
    dispatch(resetSessionErrors());
    Analytics.trackEvent(constants.EVENT_CLICK_REQUEST_MAGIC_LINK);
  };
  const handleLoginWithSSOLinkClick = () => {
    dispatch(resetSessionErrors());
    onReturnToSSOClick(email);
  };

  useEffect(() => {
    Analytics.trackPage(constants.EVENT_PAGE_VIEW_LOGIN, {
      page: constants.LoginPages.EMAIL_PASSWORD,
    });
  }, []);

  const handleSubmit = () => dispatch(signIn(email, password));

  const isDisabled = !(email && password) || isLoading;
  return (
    <>
      <LogoSlogan />
      <FormContainer>
        <LoginForm>
          <PaddedWrapper>
            <Input.Text
              id='email-login'
              label={getText('login_email')}
              labelColor={colors.white}
              error={error}
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              onEnter={!isDisabled ? handleSubmit : undefined}
              hasBlackBackground
            />
            <Input.Password
              id='password-login'
              label={getText('login_password')}
              labelColor={colors.white}
              error={error}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              hasBlackBackground
              onEnter={!isDisabled ? handleSubmit : undefined}
            />
            <LinkWrapper>
              <SecondaryAction
                to={`/forgotten-password?email=${email ?? ''}`}
                onClick={handleForgottenPasswordClick}
              >
                {getText('forgot_password_label')}
              </SecondaryAction>
            </LinkWrapper>
            {error && (
              <ErrorContainer>
                <Card.ErrorMessage message={error} />
              </ErrorContainer>
            )}
          </PaddedWrapper>
          <PaddedWrapper>
            <LinkWrapper>
              {showReturnToSSO && (
                <SecondaryAction onClick={handleLoginWithSSOLinkClick}>
                  {returnToSSOButtonText}
                </SecondaryAction>
              )}
              {process.env.REACT_APP_ENABLE_MAGIC_LINK === 'true' && (
                <SecondaryAction
                  to='/request-magic-link'
                  onClick={handleRequestMagicLinkClick}
                >
                  {getText('login_request_link')}
                </SecondaryAction>
              )}
            </LinkWrapper>
          </PaddedWrapper>
          <Button.Primary
            text={getText('login')}
            disabled={isDisabled}
            loading={isLoading}
            onClick={handleSubmit}
            width='240px'
            event={constants.EVENT_LOGIN_ATTEMPT}
            eventProperties={{
              loginSource: constants.LoginSource.EMAIL_PASSWORD,
              email,
            }}
            hasBlackBackground
          />
        </LoginForm>
      </FormContainer>
    </>
  );
};

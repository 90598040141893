import React from 'react';
import { useSelector } from 'react-redux';
import theme from 'theme';
import { Names } from 'helpers';
import {
  selectWorkspaceSettings,
  selectGlobalLanguageSetting,
} from 'store/selectors';
import { BackendUser } from 'model/BackendUser';
import { UserRole } from 'model/enum/UserRole';
import { getLabelByLocale } from 'helpers/utils';
import {
  Avatar,
  Container,
  CrossIcon,
  DeleteIcon,
  FlexColumn,
  Name,
  Title,
} from './styled';

const { colors } = theme;

type Props = {
  user: BackendUser;
  isCurrentUser: boolean;
  title?: string;
  vertical?: boolean;
  large?: boolean;
  showRole?: boolean;
  onDelete?: (() => void) | null;
  extraStyles?: React.CSSProperties;
  extraTextStyles?: React.CSSProperties;
  disabled?: boolean;
};

// Badge.UserBadge
export const UserBadge = ({
  user,
  title,
  isCurrentUser,
  vertical = false,
  large = false,
  showRole = true,
  onDelete,
  extraStyles,
  extraTextStyles,
  disabled = false,
}: Props): JSX.Element => {
  const {
    fieldworker_label,
    fieldworker_label_es,
    manager_label,
    manager_label_es,
    backoffice_label,
    backoffice_label_es,
  } = useSelector(selectWorkspaceSettings);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const getTitleForRole = () => {
    if (user?.role === UserRole.MANAGER) {
      return getLabelByLocale(globalAppLanguage, {
        manager_label,
        manager_label_es,
      });
    }
    if (user?.role === UserRole.BACKOFFICE_ADMIN) {
      return getLabelByLocale(globalAppLanguage, {
        backoffice_label,
        backoffice_label_es,
      });
    }
    return getLabelByLocale(globalAppLanguage, {
      fieldworker_label,
      fieldworker_label_es,
    });
  };

  const getAvatarBackgroundColor = () => {
    if (isCurrentUser) {
      return colors.darkGrey;
    }
    if (!isCurrentUser && user?.role === UserRole.MANAGER) {
      return colors.black;
    }
    if (!isCurrentUser && user?.role === UserRole.BACKOFFICE_ADMIN) {
      return colors.orange;
    }
    return colors.blue;
  };

  const handleClick = () => {
    onDelete && !disabled && onDelete();
  };

  return (
    <Container $vertical={vertical} style={extraStyles}>
      <Avatar
        $backgroundColor={getAvatarBackgroundColor()}
        $vertical={vertical}
        $large={large}
      >
        {Names.getInitials(user?.first_name, user?.last_name)}

        {onDelete && (
          <DeleteIcon $large={large} onClick={handleClick} $disabled={disabled}>
            <CrossIcon $large={large} />
          </DeleteIcon>
        )}
      </Avatar>
      <FlexColumn style={extraTextStyles}>
        <Name $large={large} $vertical={vertical}>
          {Names.capitalizeFullName(user?.first_name, user?.last_name)}
        </Name>
        {showRole && (
          <Title $vertical={vertical}>{title ?? getTitleForRole()}</Title>
        )}
      </FlexColumn>
    </Container>
  );
};

import { FormDetail } from 'model/Forms/FormDetail';
import { Video } from 'model/Media/Video';
import { WidgetKind } from 'utils/constants';

export const hasVideo = (form) =>
  form?.sections[0]?.fields[0]?.kind === WidgetKind.VIDEO_UPLOAD_INFO_PAGE ||
  form?.sections[0]?.fields[0]?.kind === WidgetKind.VIDEO_UPLOAD;

export const getVideoInfoPage = (form) =>
  getFieldByKind(form?.sections, WidgetKind.VIDEO_UPLOAD_INFO_PAGE);

export const getFieldByKind = (sections, widgetKind) => {
  let result = null;
  sections?.find((section) => {
    result = section?.fields?.find((field) => field?.kind === widgetKind);
    return result;
  });
  return result;
};

export const getFieldById = (form, id) => {
  let result = null;
  form?.sections?.find((section) => {
    result = section?.fields?.find((field) => field?.id === id);
    return result;
  });
  return result;
};

export const getVideo = (
  form: FormDetail | undefined,
  videos: Video[],
): Video | null => {
  if (form && hasVideo(form)) {
    return (
      videos.find(
        (video) => video.fileId === form?.sections[0]?.fields[0]?.value.file_id,
      ) ?? null
    );
  }
  return null;
};

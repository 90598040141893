import { Button, Input, Loader } from 'components/ui';
import { ClearButton } from 'components/ui/Button';
import { formatMembersSelected } from 'helpers/names';
import { useLocalStorage, useText } from 'hooks';
import { Depot } from 'model/Job/Depot';
import RootState from 'model/State/RootState';
import { BackendUser } from 'model/BackendUser';
import { ModalType } from 'model/enum/ModalType';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchFormOptions } from 'store/actions/form';
import {
  selectFormOptions,
  selectGlobalLanguageSetting,
} from 'store/selectors';
import styled from 'styled-components';
import theme from 'theme';
import * as constants from 'utils/constants';
import { initialFilterOptions } from 'model/CommandCenter/FilterOptions';
import { fetchActivities, fetchStats } from 'store/slices/commandCenter';
import { mapToUser } from 'api/User/helpers';

const { fontSizes, mixins } = theme;

// Styled components

const FiltersContainer = styled.div`
  ${mixins.flexFullCenter};
  flex-direction: column;
  padding: 30px 0;
  height: 350px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  padding: 10px 0;
`;

type NullableDepotType =
  | Depot
  | {
      id: null;
      title: string;
    };

const CommandCentreFilters = (): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const formOptions = useSelector(selectFormOptions);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const [currentFilters, setCurrentFilters] = useLocalStorage<{
    depot?: NullableDepotType;
    users?: BackendUser[];
  }>('command-centre-filters', {});
  const [selectedDepot, setSelectedDepot] = useState<NullableDepotType>(() => {
    if (location?.state?.depot) {
      return location.state.depot;
    }
    return (
      currentFilters?.depot ?? {
        id: null,
        title: getText('command_centre_filter_depot_all'),
      }
    );
  });
  const [selectedMembers] = useState<BackendUser[]>(() => {
    if (location?.state?.assignees) {
      return location.state.assignees;
    }
    return currentFilters?.users ?? [];
  });
  const { currentUser, selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );

  useEffect(() => {
    if (!formOptions) {
      dispatch(fetchFormOptions());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDepotOptions = () => {
    const depots = formOptions?.depots;

    return depots?.length
      ? [
          ...formOptions.depots,
          { id: null, title: getText('command_centre_filter_depot_all') },
        ]
      : [];
  };

  const handleClearFilters = () => {
    setCurrentFilters({});
    dispatch(fetchStats({ ...initialFilterOptions }));
    dispatch(
      fetchActivities({
        filterOptions: {
          ...initialFilterOptions,
        },
        options: { clearState: true },
      }),
    );
    navigate(location.pathname);
  };

  const handleSubmit = () => {
    setCurrentFilters({ depot: selectedDepot, users: selectedMembers });
    dispatch(
      fetchStats({
        ...initialFilterOptions,
        depot: selectedDepot.id ? selectedDepot : null,
        users: selectedMembers.map(mapToUser),
      }),
    );
    dispatch(
      fetchActivities({
        filterOptions: {
          ...initialFilterOptions,
          depot: selectedDepot.id ? selectedDepot : null,
          users: selectedMembers.map(mapToUser),
        },
        options: { clearState: true },
      }),
    );
    navigate(location.pathname);
  };

  return (
    <FiltersContainer>
      {formOptions ? (
        <>
          <Input.Select
            id='depot'
            label='Depot'
            selected={getDepotOptions().find(
              (item) => item.id === selectedDepot.id,
            )}
            options={getDepotOptions()}
            onChange={(e) => {
              const selectedDepot = getDepotOptions().find(
                (item) => item.id === (e?.target?.value as number),
              );
              if (selectedDepot) {
                setSelectedDepot(selectedDepot);
              }
            }}
            large
            extraStyles={{ width: '50%' }}
          />

          <Input.Link
            label={getText('jobs_filter_team_members')}
            value={formatMembersSelected(
              selectedMembers,
              getText('jobs_filter_zero_selected_members'),
              getText('jobs_filter_members_selected'),
            )}
            pathname={location.pathname}
            search={`modal=${ModalType.SEARCH_MEMBERS}`}
            state={{
              ...location.state,
              depot: selectedDepot,
              assignees: selectedMembers,
            }}
            extraStyles={{ width: '50%', padding: '10px 0' }}
          />
          <ButtonContainer>
            <ClearButton
              text={getText('jobs_filter_clear_all')}
              onClick={handleClearFilters}
              large={globalAppLanguage === constants.Languages.english.locale}
              event={constants.COMMAND_CENTER_FILTER_CLEARED}
              width='175px'
            />
            <Button.Primary
              event={constants.COMMAND_CENTER_FILTER_SUBMITTED}
              eventProperties={{
                orgId: selectedWorkspace?.uuid,
                userId: currentUser?.id,
              }}
              text={getText('jobs_filter_apply_button')}
              onClick={handleSubmit}
              width='215px'
              extraStyles={{
                margin: 0,
                fontSize:
                  globalAppLanguage === constants.Languages.english.locale
                    ? fontSizes.m
                    : fontSizes.default,
              }}
            />
          </ButtonContainer>
        </>
      ) : (
        <Loader />
      )}
    </FiltersContainer>
  );
};

export default CommandCentreFilters;

import styled from 'styled-components';
import theme from 'theme';
import { TableRow as MuiTableRow } from '@mui/material';

const { colors, mixins } = theme;

export const TableRow = styled(MuiTableRow)`
  border-bottom: 1px solid ${colors.lightGrey};
  &:last-of-type {
    border: none;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  ${mixins.flexBetween};
  flex-direction: row;
  align-items: end;
`;

export const InputContainer = styled.div<{ $swapOrder: boolean }>`
  ${mixins.flexColumn};
  ${({ $swapOrder }) => $swapOrder && `flex-direction: column-reverse`};
  width: 60%;
`;

export const ActionButtonsWrapper = styled.div`
  ${mixins.flexCenter};
  justify-content: start;
  width: 35%;
  margin-bottom: 10px;
`;

export const ErrorMessage = styled.div`
  color: ${colors.red};
  margin-top: 15px;
`;

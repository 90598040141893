import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserHasPermission,
  selectUserViewSelection,
  selectViewVersion,
  selectWorkspaceHasSettingEnabled,
} from 'store/selectors';
import {
  LIST_VIEW,
  LEGACY_VIEW,
  URL_VIEW_QUERY_PARAM_FIELD,
} from 'utils/constants';
import useQueryParams from './useQueryParams';
import { setUserViewSelection } from 'store/actions/versions';

export default () => {
  const dispatch = useDispatch();
  const { params } = useQueryParams();
  const viewFromURL = params?.[URL_VIEW_QUERY_PARAM_FIELD];

  const [viewVersionParam, setViewVersionParam] = useState(viewFromURL ?? '');

  const canUseListView = useSelector(
    selectWorkspaceHasSettingEnabled('enable_jobs_page_v2'),
  );
  const defaultToListView = useSelector(
    selectUserHasPermission('jobs_page_v2'),
  );
  const userViewSelection = useSelector(selectUserViewSelection);
  const viewVersion = useSelector(selectViewVersion);

  useEffect(() => {
    if (!userViewSelection && viewFromURL) {
      dispatch(setUserViewSelection(viewFromURL));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // update selected view based on store or URL param
  useEffect(() => {
    if (canUseListView) {
      if (userViewSelection) {
        if (viewFromURL !== userViewSelection) {
          setViewVersionParam(userViewSelection);
        }
      } else {
        const value = defaultToListView ? LIST_VIEW : LEGACY_VIEW;
        setViewVersionParam(value);
      }
    } else if (viewFromURL) {
      // clear the url if can't use it
      setViewVersionParam('');
    }
  }, [canUseListView, userViewSelection, viewFromURL]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    viewVersion,
    viewVersionParam,
  };
};

import React, { useState } from 'react';
import { Swiper } from 'swiper';
import { A11y, Navigation, Pagination, Zoom } from 'swiper/modules';

// Import Swiper React components
import { SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import { Photo } from './Photo';
import { downloadBlob } from 'helpers/files';
import {
  Controls,
  DownloadControls,
  DownloadIcon,
  PhotoAndControlsContainer,
  PhotoCarouselStyledSwiper,
  ZoomControls,
  ZoomIcon,
  ZoomOutIcon,
} from './styled';

const downloadFetch = (url: string) => {
  fetch(url, {
    cache: 'no-store',
    headers: {
      Accept: 'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*',
    },
  }).then((response) => {
    response.blob().then((blob) => {
      const filename = url?.split('/')?.pop()?.split('?')[0];
      downloadBlob(blob, filename ?? '');
    });
  });
};

type Props = {
  urls: string[];
  width: string;
  onDownload?: () => void;
  extraStyles?: React.CSSProperties;
};

// PhotoCarousel
export const PhotoCarousel = ({
  urls,
  width,
  onDownload,
  extraStyles,
}: Props): JSX.Element => {
  const [swiperInstance, setSwiperInstance] = useState<Swiper>();

  return (
    <PhotoAndControlsContainer width={width} style={extraStyles}>
      <PhotoCarouselStyledSwiper
        modules={[Navigation, Pagination, A11y, Zoom]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{
          dynamicBullets: true,
          dynamicMainBullets: 3,
        }}
        onSwiper={setSwiperInstance}
        onSlideChange={setSwiperInstance}
        noSwiping={urls.length < 2}
        zoom
      >
        {urls.map((photoUrl) => (
          <SwiperSlide zoom key={photoUrl}>
            <Photo forceImage url={photoUrl} />
          </SwiperSlide>
        ))}
      </PhotoCarouselStyledSwiper>
      <Controls>
        {onDownload && (
          <DownloadControls>
            <DownloadIcon
              onClick={() => {
                if (
                  swiperInstance?.activeIndex !== undefined &&
                  swiperInstance.activeIndex > -1
                ) {
                  downloadFetch(urls[swiperInstance.activeIndex]);
                  onDownload();
                }
              }}
            />
          </DownloadControls>
        )}

        {swiperInstance && (
          <ZoomControls>
            <ZoomIcon onClick={() => swiperInstance.zoom.in()} />
            <ZoomOutIcon onClick={() => swiperInstance.zoom.out()} />
          </ZoomControls>
        )}
      </Controls>
    </PhotoAndControlsContainer>
  );
};

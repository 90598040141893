import axios from 'axios';
import { CommandCenterStats } from 'model/CommandCenter/Stats';
import { mapToCommandCenterActivity, mapToCommandCenterStats } from './helpers';
import { PaginatedData } from 'model/PaginatedData';
import { CommandCenterActivity } from 'model/CommandCenter/Activity';
import { CommandCenterFilterOptions } from 'model/CommandCenter/FilterOptions';

export const getStats = async (
  filterOptions: CommandCenterFilterOptions,
): Promise<CommandCenterStats> => {
  const { workspaceUuid, depot, users } = filterOptions;
  const { data } = await axios.get(`/command-center/stats/`, {
    params: {
      ...(workspaceUuid && { workspace_uuid: workspaceUuid }),
      ...(depot && { depot_id: depot.id }),
      ...(users?.length && { user_ids: users?.map((user) => user.id).join() }),
    },
  });
  return mapToCommandCenterStats(data);
};

export const getActivities = async (
  filterOptions: CommandCenterFilterOptions,
  page: number = 1,
  pageSize: number = 10,
): Promise<PaginatedData<CommandCenterActivity>> => {
  const { workspaceUuid, depot, users } = filterOptions;
  const { data } = await axios.get(`/command-center/latest-activity/`, {
    params: {
      page,
      page_size: pageSize,
      ...(workspaceUuid && {
        workspace_uuid: workspaceUuid,
      }),
      ...(depot && { depot_id: depot.id }),
      ...(users?.length && { user_ids: users?.map((user) => user.id).join() }),
    },
  });
  return { ...data, results: data.results.map(mapToCommandCenterActivity) };
};

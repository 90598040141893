import * as Sentry from '@sentry/react';

export const init = () => {
  if (process.env.REACT_APP_ENV === 'development') {
    return;
  }
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV ?? process.env.REACT_APP_ENV,
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.5, //  Capture 50% of the transactions
  });
};

export const error = (err, info = {}) => {
  if (process.env.REACT_APP_ENV === 'development') {
    console.error(err, info);
  } else {
    // Sending extra information changed in Sentry SDK v5.x
    // https://github.com/getsentry/sentry-javascript/blob/master/MIGRATION.md#new-scope-functions
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      Sentry.captureException(err);
    });
  }
};

export const setContext = (context) => {
  Object.keys(context).forEach((property) => {
    if (property === 'user') {
      Sentry.setUser(context[property]);
    }
  });
};

export const info = (err, info = {}) => {
  if (process.env.REACT_APP_ENV === 'development') {
    console.error(err, info);
  } else {
    // Sending extra information changed in Sentry SDK v5.x
    // https://github.com/getsentry/sentry-javascript/blob/master/MIGRATION.md#new-scope-functions
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      scope.setLevel('info');
      Sentry.captureException(err);
    });
  }
};

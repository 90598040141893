import * as constants from 'utils/constants';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGlobalLanguageSetting,
  selectIsFetchingWorkOrderItems,
  selectJobWorkOrderItems,
  selectJobsView,
} from 'store/selectors';
import { InformationLabel } from 'components/ui/Shared/EmptyState';
import { Card, Loader } from 'components/ui';
import { useEffect } from 'react';
import { useText } from 'hooks';
import RootState from 'model/State/RootState';
import { fetchWorkOrderItems } from 'store/actions/jobs';
import OverviewTabBase from './OverviewTabBase';
import { WorkOrderItem } from 'assets/icons';
import { getWorkOrderSubtype } from 'helpers/apiHelpers';

const WorkOrderItems = (): JSX.Element => {
  const { jobId } = useParams();
  const workOrderItems = useSelector(selectJobWorkOrderItems(jobId));
  const isFetchingWorkOrderItems = useSelector(selectIsFetchingWorkOrderItems);
  const { activeJob } = useSelector((state: RootState) => state.jobs);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const dispatch = useDispatch();
  const getText = useText();
  const view = useSelector(selectJobsView);

  useEffect(() => {
    dispatch(fetchWorkOrderItems(jobId));
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OverviewTabBase title={getText('tabs_work_completed')}>
      <>
        {isFetchingWorkOrderItems ? (
          <Loader />
        ) : (
          <>
            {!workOrderItems?.length ? (
              <InformationLabel>
                {getText('job_details_work_completed_no_display')}
              </InformationLabel>
            ) : (
              workOrderItems?.map((workOrderItem) => (
                <Card.JobDetail
                  event={constants.EVENT_VIEW_WORK_COMPLETED_ITEM}
                  eventProperties={{
                    jobId,
                    logId: workOrderItem.id,
                    logType: 'work item',
                    source: 'job-overview',
                    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  }}
                  key={`log-${workOrderItem.id}`}
                  title={getText('job_details_work_completed_title')}
                  subtitle={getWorkOrderSubtype(
                    workOrderItem.subtype,
                    activeJob,
                    globalAppLanguage,
                  )}
                  creator={workOrderItem.createdBy}
                  date={workOrderItem.createdAt}
                  to={`/jobs/${workOrderItem.jobId}/work-completed/${workOrderItem.id}`}
                  IconComponent={WorkOrderItem}
                />
              ))
            )}
          </>
        )}
      </>
    </OverviewTabBase>
  );
};

export default WorkOrderItems;

import { Dates } from 'helpers';
import { WorkOrderItem as WorkOrderItemIcon } from 'assets/icons';
import * as Analytics from 'utils/analytics';
import { useText } from 'hooks';
import { User } from 'model/User';
import {
  CardContainer,
  CreationDate,
  Creator,
  EyeIcon,
  FlexCenterContainer,
  Icon,
  Subtype,
  Type,
} from './styled';

type Props = {
  subtype: string;
  creator: User;
  date: string;
  to: string;
};

// Card.WorkOrderItem
export const WorkOrderItem = Analytics.withTrackEvent(
  ({ subtype, creator, date, to, ...props }: Props): JSX.Element => {
    const getText = useText();
    return (
      <CardContainer to={to} {...props}>
        <FlexCenterContainer>
          <Icon component={<WorkOrderItemIcon />} $marginRight={20} />

          <div>
            <Type>{getText('job_overview_work_completed')}</Type>
            <Subtype>{subtype}</Subtype>
          </div>
        </FlexCenterContainer>

        <FlexCenterContainer>
          <Creator>{creator.fullName}</Creator>

          <CreationDate $marginLeft={50} $marginRight={25}>
            {Dates.formatDateTime(
              date,
              getText('date_today'),
              getText('date_yesterday'),
            )}
          </CreationDate>

          <EyeIcon />
        </FlexCenterContainer>
      </CardContainer>
    );
  },
);

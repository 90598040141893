import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes } = theme;

export const Wrapper = styled.div`
  background-color: ${colors.white};
  padding: 32px;
  min-height: 570px;
  box-sizing: border-box;
  margin-top: 15px;
`;

export const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  padding-bottom: 20px;
`;

export const InfoText = styled.div<{ $bold?: boolean }>`
  font-family: ${({ $bold }) => ($bold ? fonts.bold : fonts.default)};
  font-size: ${fontSizes.default};
  padding: 5px 0;
`;

import { useEffect, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Sort from 'model/Sort';
import { ComplianceType } from 'model/enum/ComplianceType';
import { vehicleCheckUserComplianceFields } from 'utils/constants';
import ComplianceDataTableBase from '../../ComplianceDataTableBase';
import { formatHavsDateRange } from 'helpers/dates';

type Props = {
  data: any[];
  columns: object;
  total: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  sort: Sort;
  setSort: (value: Sort) => void;
  initialSort: Sort;
  analyticsData: object;
  isLoading: boolean;
};

// VehicleCheckUserComplianceDataTable
const VehicleCheckUserComplianceDataTable = ({
  data,
  columns,
  total,
  currentPage,
  onPageChange,
  sort,
  setSort,
  initialSort,
  analyticsData,
  isLoading,
}: Props): JSX.Element => {
  const [tableColumns, setTableColumns] = useState<object[]>([]);
  const [tableData, setTableData] = useState<GridColDef[]>([]);
  const sortableColumns = [
    vehicleCheckUserComplianceFields.USER_NAME,
    vehicleCheckUserComplianceFields.SUBMISSIONS,
    vehicleCheckUserComplianceFields.DEFECTS,
    vehicleCheckUserComplianceFields.MILEAGE_CHECKS,
    vehicleCheckUserComplianceFields.TYRE_PRESSURE_CHECKS,
    vehicleCheckUserComplianceFields.TYRE_THREAD_DEPTH_CHECKS,
  ];

  useEffect(() => {
    generateTableData(data);
    generateColumns(columns);
  }, [data, columns]); // eslint-disable-line react-hooks/exhaustive-deps

  const generateColumns = (values: object) => {
    const data: object[] = Object.entries(values).map(([key, value]) => ({
      field: key,
      headerName: value,
      sortable: sortableColumns.includes(key),
      renderCell: (props: GridRenderCellParams) => (
        <span>{props.row?.[key]}</span>
      ),
      ...(key === vehicleCheckUserComplianceFields.DATE_RANGE && {
        renderCell: (props: GridRenderCellParams) => (
          <span>{formatHavsDateRange(props.row?.date_range)}</span>
        ),
      }),
    }));
    setTableColumns(data);
  };

  const generateTableData = (
    results: Record<string, string | number | Array<string>>[],
  ) => {
    const dataResult: GridColDef[] = results.map((result) => {
      const values = {} as GridColDef<any, any, any>;
      Object.entries(result).forEach(
        ([key, value]: [string, string | number | Array<string>]) => {
          values[key] = Array.isArray(value) ? value.join(', ') : value;
        },
      );
      return values;
    });
    setTableData(dataResult);
  };

  return (
    <ComplianceDataTableBase
      data={tableData}
      columns={tableColumns}
      total={total}
      currentPage={currentPage}
      onPageChange={onPageChange}
      sort={sort}
      setSort={setSort}
      initialSort={initialSort}
      analyticsData={analyticsData}
      complianceType={ComplianceType.VEHICLE_CHECK_USER}
      isLoading={isLoading}
    />
  );
};

export default VehicleCheckUserComplianceDataTable;

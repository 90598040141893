import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FatigueHistoricItem } from 'model/FatigueManager/FatigueHistoricDataItem';
import { FatigueLiveViewItem } from 'model/FatigueManager/FatigueLiveViewItem';
import { PaginatedData } from 'model/PaginatedData';
import {
  FatigueHistoricPayload,
  FatigueLivePayload,
  FatigueState,
} from 'model/State/Fatigue';
import RootState from 'model/State/RootState';
import { FatigueManagerTabKey } from 'model/enum/FatigueManagerTabKey';

const initialState: FatigueState = {
  currentTab: null,
  isLoadingLiveData: false,
  liveData: null,
  isLoadingHistoricData: false,
  historicData: null,
};

export const fatigue = createSlice({
  name: 'fatigue',
  initialState,
  reducers: {
    setFatigueTab: (
      state: FatigueState,
      action: PayloadAction<FatigueManagerTabKey>,
    ) => {
      state.currentTab = action.payload;
    },
    fetchFatigueLive: (
      state: FatigueState,
      action: PayloadAction<FatigueLivePayload>,
    ) => {
      state.isLoadingLiveData = true;
    },
    fetchFatigueLiveSuccess: (
      state: FatigueState,
      action: PayloadAction<PaginatedData<FatigueLiveViewItem>>,
    ) => {
      state.isLoadingLiveData = false;
      state.liveData = action.payload;
    },
    fetchFatigueLiveError: (state: FatigueState) => {
      state.isLoadingLiveData = false;
    },
    fetchFatigueHistoric: (
      state: FatigueState,
      action: PayloadAction<FatigueHistoricPayload>,
    ) => {
      state.isLoadingHistoricData = true;
    },
    fetchFatigueHistoricSuccess: (
      state: FatigueState,
      action: PayloadAction<PaginatedData<FatigueHistoricItem>>,
    ) => {
      state.isLoadingHistoricData = false;
      state.historicData = action.payload;
    },
    fetchFatigueHistoricError: (state: FatigueState) => {
      state.isLoadingHistoricData = false;
    },
  },
});

export const {
  setFatigueTab,
  fetchFatigueLive,
  fetchFatigueLiveError,
  fetchFatigueLiveSuccess,
  fetchFatigueHistoric,
  fetchFatigueHistoricError,
  fetchFatigueHistoricSuccess,
} = fatigue.actions;

export const selectLiveData = (
  state: RootState,
): PaginatedData<FatigueLiveViewItem> | null => state.fatigue.liveData;
export const selectIsLoadingLiveData = (state: RootState): boolean =>
  state.fatigue.isLoadingLiveData;
export const selectHistoricData = (
  state: RootState,
): PaginatedData<FatigueHistoricItem> | null => state.fatigue.historicData;
export const selectIsLoadingHistoricData = (state: RootState): boolean =>
  state.fatigue.isLoadingHistoricData;
export const selectFatigueTab = (state: RootState): FatigueManagerTabKey =>
  state.fatigue.currentTab || FatigueManagerTabKey.LIVE_VIEW;

export const fatigueReducer = fatigue.reducer;

import { Footer, IconWrapper } from './styled';
import { ExportButton } from './ExportButton';
import theme from 'theme';
import { DownloadSource, DownloadType } from './constants';
import { CustomFormClass } from 'model/Form';

type Props = {
  downloadType: DownloadType;
  downloadItemId: string;
  formClass?: CustomFormClass;
  jobId?: number | string;
  onClick?: () => void;
};

export const ModalFooter = ({
  downloadType,
  downloadItemId,
  formClass,
  jobId,
  onClick,
}: Props): JSX.Element => (
  <Footer>
    <IconWrapper>
      <ExportButton
        type={downloadType}
        itemId={downloadItemId}
        jobId={jobId}
        source={DownloadSource.MODAL}
        onClick={onClick}
        large
        backgroundColor={theme.colors.black}
        formClass={formClass}
        iconColor={theme.colors.yellow}
      />
    </IconWrapper>
  </Footer>
);

import styled from 'styled-components';
import theme from 'theme';
import { Tabs } from 'components/ui';
import RootState from 'model/State/RootState';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MAP_VIEW_TAB } from 'utils/constants';
import JobViewMap from './JobViewMap';
import { getValidJobCoordinates } from 'helpers/utils';
import SkeletonMap from './Skeleton/SkeletonMap';
import { FeaturesInfoButton } from './FeaturesInfoButton';
import { useSafetyPrediction } from 'hooks';
import { MapJob } from 'model/MapJob';
import { getCoordinatesFromJob } from 'helpers/map';

const { colors } = theme;

const TabContainer = styled.div`
  border-bottom: 2px solid ${colors.black};
  display: flex;
  justify-content: end;
  margin-top: 10px;
`;

const MapContainer = styled.div`
  background-color: ${colors.white};
  padding: 20px;
  height: 100%;
`;

const MapV2 = (): JSX.Element => {
  const { mapJobs: jobs } = useSelector((state: RootState) => state.jobs);

  const { getRiskWarning } = useSafetyPrediction();

  const mapJobs: MapJob[] = useMemo(
    () =>
      jobs
        ? getValidJobCoordinates(jobs).map((job) =>
            getCoordinatesFromJob(job, getRiskWarning),
          )
        : null,
    [jobs, getRiskWarning],
  );

  return (
    <>
      <TabContainer>
        <FeaturesInfoButton />
        <Tabs.JobView activeTab={MAP_VIEW_TAB} />
      </TabContainer>
      <MapContainer>
        {mapJobs ? <JobViewMap jobs={mapJobs} /> : <SkeletonMap />}
      </MapContainer>
    </>
  );
};

export default MapV2;

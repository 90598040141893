import { useState } from 'react';
import { ThumbnailPlaceholderImg as placeholder } from 'assets/icons';
import { PhotoContainer, PhotoImage } from './styled';
import { QualityWarningTag } from '../QualityWarning/QualityWarningTag';
import { QualityIndicator } from 'model/Media/Video';

type PhotoProps = {
  url: string;
  height?: string;
  width?: string;
  cover?: boolean;
  forceImage?: boolean;
  qualityIndicator?: QualityIndicator | null;
};

export const Photo = ({
  url,
  height,
  width,
  cover,
  forceImage = false,
  qualityIndicator = null,
}: PhotoProps): JSX.Element => {
  const [imgSrc, setImgSrc] = useState<string>(url);

  const onLoadError = () => {
    setImgSrc(placeholder);
  };

  return forceImage ? (
    <PhotoImage
      src={imgSrc}
      onError={onLoadError}
      width={imgSrc === placeholder ? width : '100%'}
    />
  ) : (
    <>
      <PhotoContainer url={url} height={height} width={width} $cover={cover} />
      <QualityWarningTag qualityIndicator={qualityIndicator} />
    </>
  );
};

import React from 'react';
import { CSSProperties } from 'styled-components';
import * as Analytics from 'utils/analytics';
import { Loader } from '../../Loader';
import { ArrowRight, Button, Description, Icon } from './styled';
import { ArrowLeft } from '../styled';

type Props = {
  text: string;
  onClick: () => {};
  IconComponent?: React.ElementType;
  width?: string;
  disabled?: boolean;
  extraStyles?: CSSProperties;
  iconFront?: boolean;
  isDark?: boolean;
  hasBlackBackground?: boolean;
  loading?: boolean;
  showArrowRight?: boolean;
  showArrowLeft?: boolean;
};

// Button.Secondary
export const SecondaryButton = Analytics.withTrackEvent(
  ({
    text,
    onClick,
    IconComponent,
    width = '100%',
    disabled = false,
    extraStyles,
    iconFront = false,
    isDark = false,
    hasBlackBackground = false,
    loading = false,
    showArrowRight = true,
    showArrowLeft = false,
    ...props
  }: Props): JSX.Element => (
    <Button
      width={width}
      disabled={disabled}
      {...props}
      style={extraStyles}
      $iconFront={iconFront}
      isDark={isDark}
      onClick={onClick}
      hasBlackBackground={hasBlackBackground}
    >
      {showArrowLeft && <ArrowLeft disabled={disabled} />}

      {loading && <Loader size={20} />}
      <Description disabled={disabled} $iconFront={iconFront}>
        {text}
      </Description>
      {IconComponent ? (
        <Icon
          disabled={disabled}
          $iconFront={iconFront}
          component={<IconComponent />}
        />
      ) : (
        showArrowRight && <ArrowRight disabled={disabled} />
      )}
    </Button>
  ),
);

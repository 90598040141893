import styled from 'styled-components';
import { Button, Modal } from 'components/ui';

const Container = styled.div`
  padding: 40px;
`;

const H1 = styled.h1`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.xxxl};
  margin-bottom: 10px !important;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSizes.xl};
  }
`;

const P = styled.p`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${({ theme }) => theme.fontSizes.m};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 25px !important;
`;

type Props = {
  title: string;
  description: string;
  linkText: string;
  linkPath: string;
  event: string;
  eventProperties: object;
};

// Feedback
const Feedback = ({
  title,
  description,
  linkText,
  linkPath,
  event,
  eventProperties,
}: Props) => (
  <Modal.Small alwaysOpen>
    <Container>
      <H1>{title}</H1>
      <P>{description}</P>
      <Button.PrimaryLink
        text={linkText}
        width='240px'
        to={linkPath}
        event={event}
        eventProperties={eventProperties}
      />
    </Container>
  </Modal.Small>
);

export default Feedback;

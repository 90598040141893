import { useText } from 'hooks';
import theme from 'theme';
import { MessageIcon, TagContainer } from './styled';

const { fontSizes } = theme;

type Props = {
  unreadMessages: number;
  extraStyles?: React.CSSProperties;
};

export const MessageTag = ({
  unreadMessages,
  extraStyles,
}: Props): JSX.Element => {
  const getText = useText();

  return (
    <TagContainer
      color={theme.colors.black}
      $backgroundColor={theme.colors.orange}
      style={{ ...extraStyles, fontSize: fontSizes.s }}
    >
      <MessageIcon />
      {`${unreadMessages} ${unreadMessages > 1 ? getText('new_messages') : getText('new_message')}`}
    </TagContainer>
  );
};

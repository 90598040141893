import styled from 'styled-components';
import theme from 'theme';
import { formatDateTime } from 'helpers/dates';
import { useText } from 'hooks';
import { Field } from 'model/Forms/Field';

const { colors, fonts, fontSizes } = theme;

// Styled components
const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`;

const DetailTitle = styled.span`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  line-height: ${fontSizes.l};
  padding-bottom: 16px;
`;

const DetailText = styled.span`
  font-size: ${fontSizes.m};
  color: ${colors.darkGrey};
  font-family: ${fonts.boldItalic};
`;

const DateSelector = ({ field }: { field: Field }): JSX.Element => {
  const getText = useText();

  return (
    <DetailContainer>
      <DetailTitle>{field.title}</DetailTitle>
      <DetailText>
        {field.value
          ? formatDateTime(
              field.value,
              getText('date_today'),
              getText('date_yesterday'),
            )
          : getText('job_forms_not_reported')}
      </DetailText>
    </DetailContainer>
  );
};

export default DateSelector;

import styled from 'styled-components';
import theme from 'theme';
import { Check, Info, ThumbsDown, ThumbsUp, Warning } from 'assets/icons';
import { useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSafetyPrediction, useText } from 'hooks';
import { useEffect, useState } from 'react';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import InfoModal from '../SafetyPrediction/InfoModal';

const { mixins, fontSizes, fonts, colors } = theme;

const InfoContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
`;

const HighRiskWarningIcon = styled(Warning)`
  height: 50px;
  width: 50px;
  padding-right: 10px;

  .fill-selector {
    fill: ${colors.red};
  }
`;

const ScoreContainer = styled.div`
  ${mixins.flexCenter};
  text-align: left;
`;

const TitleWrapper = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
`;

const InfoIcon = styled(Info)`
  cursor: pointer;
`;

const BoldWrapper = styled.span`
  font-family: ${fonts.bold};
  line-height: 25px;
`;

const Label = styled.span`
  font-size: ${fontSizes.m};

  line-height: 25px;
  padding-left: 5px;
`;

const MessageContainer = styled.div`
  ${mixins.flexColumn}
`;

const ThumbsContainer = styled.div`
  display: flex;
  padding-left: 10px;
`;

const ThumbsWrapper = styled.div`
  cursor: pointer;
  display: flex;
  padding: 3px;
  &:hover {
    background-color: ${colors.lightGrey};
    border-radius: 4px;
  }
`;

const ScoreFeedbackWrapper = styled.div`
  display: flex;
  align-items: unset;
  margin-top: 5px;
`;

const SuccessMessage = styled.div`
  width: fit-content;
  padding: 10px;
  background-color: ${colors.lightGreen};
  margin-bottom: 10px;
`;

type RiskWarning = {
  type: string;
  advice: string;
  score: string;
  scoreLabel: string;
  message: string;
};

const PsiFeedback = (): JSX.Element | null => {
  const getText = useText();
  const { activeJob, isFetchingActiveJob } = useSelector(
    (state: RootState) => state.jobs,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { getRiskWarning } = useSafetyPrediction();
  const [riskWarning, setRiskWarning] = useState<RiskWarning | null>(null);
  const [isInfoOpen, setInfoOpen] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  useEffect(() => {
    if (!isFetchingActiveJob && activeJob) {
      setRiskWarning(getRiskWarning(activeJob.safety_prediction_score));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location?.state?.successfulReview) {
      setShowSuccessMessage(location?.state?.successfulReview);
    }
  }, [location?.state]);

  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => setShowSuccessMessage(false), 3500);
    }
  }, [showSuccessMessage]);

  if (!riskWarning) {
    return null;
  }

  const openModal = (isPositive: boolean = false) => {
    Analytics.trackEvent(
      isPositive
        ? constants.EVENT_PSI_FEEDBACK_THUMBS_UP_CLICKED
        : constants.EVENT_PSI_FEEDBACK_THUMBS_DOWN_CLICKED,
      {
        jobId: activeJob?.id,
        psiScoreId: activeJob?.safety_prediction_score.id,
      },
    );
    navigate(`/jobs/${activeJob?.id}/safety-review`, {
      state: {
        riskTypes: riskWarning.type,
      },
    });
  };

  return (
    <>
      <InfoContainer>
        <IconWrapper>
          <HighRiskWarningIcon />
        </IconWrapper>
        <MessageContainer>
          <TitleWrapper>
            {riskWarning.message}{' '}
            {activeJob?.safety_prediction_score.id !==
              activeJob?.user_review_psi_score_id && (
              <InfoIcon onClick={() => setInfoOpen(true)} />
            )}
          </TitleWrapper>
          <p>
            <BoldWrapper>{riskWarning.type}:</BoldWrapper> {riskWarning.advice}
          </p>
          <ScoreFeedbackWrapper>
            <ScoreContainer>
              <TitleWrapper>{riskWarning.score}</TitleWrapper>
              <Label>{riskWarning.scoreLabel}</Label>
            </ScoreContainer>
            {activeJob?.safety_prediction_score.id !==
              activeJob?.user_review_psi_score_id && (
              <ThumbsContainer>
                <ThumbsWrapper>
                  <ThumbsUp onClick={() => openModal(true)} />
                </ThumbsWrapper>
                <ThumbsWrapper>
                  <ThumbsDown onClick={() => openModal(false)} />
                </ThumbsWrapper>
              </ThumbsContainer>
            )}
          </ScoreFeedbackWrapper>
        </MessageContainer>
      </InfoContainer>
      {showSuccessMessage && (
        <SuccessMessage>
          {getText('job_overview_safety_prediction_feedback_success')} <Check />
        </SuccessMessage>
      )}
      <InfoModal isOpen={isInfoOpen} onClose={() => setInfoOpen(false)} />
    </>
  );
};

export default PsiFeedback;

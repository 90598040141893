import { Cross, Pencil } from 'assets/icons';
import { FilterType } from 'model/enum/FilterType';
import * as Analytics from 'utils/analytics';
import { useLocation } from 'react-router-dom';
import {
  EVENT_JOB_FILTER_REMOVED,
  FATIGUE_FILTER_REMOVED,
  INSIGHTS_FILTER_REMOVED,
} from './constants';
import { selectJobsView } from 'store/selectors';
import { EVENT_PROPERTIES_PAGE_SOURCE_FIELD } from 'utils/constants';
import { useSelector } from 'react-redux';
import { FilterBox, FilterText, Icon } from './styled';

type Props = {
  filterType: FilterType;
  removeFilter: (filterType: FilterType) => void;
  onClick?: () => void;
  text: string;
  isDefault?: boolean;
};

export const AppliedFilterBox = ({
  filterType,
  removeFilter,
  onClick,
  text,
  isDefault = false,
}: Props): JSX.Element => {
  const location = useLocation();
  const view = useSelector(selectJobsView);

  const onFilterRemove = () => {
    removeFilter(filterType);
    let event = '';
    let eventProperties = {};
    if (location.pathname.endsWith('/insights')) {
      event = INSIGHTS_FILTER_REMOVED;
    } else if (location.pathname.endsWith('/fatigue-manager')) {
      event = FATIGUE_FILTER_REMOVED;
    } else if (location.pathname.endsWith('/jobs')) {
      event = EVENT_JOB_FILTER_REMOVED;
      eventProperties = {
        [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      };
    }
    Analytics.trackEvent(event, { ...eventProperties, filterType });
  };

  return (
    <FilterBox>
      <>
        {isDefault ? (
          <Icon component={<Pencil />} onClick={onClick} />
        ) : (
          <Icon component={<Cross />} onClick={onFilterRemove} />
        )}
      </>
      <FilterText>{text}</FilterText>
    </FilterBox>
  );
};

import { WorkflowStep, WorkflowTask, WorkflowTaskType } from 'model/Workflows';

export const mapToWorkflowStep = (input: any): WorkflowStep => {
  return {
    id: input.id,
    title: input.title,
    titleEs: input.title_es,
    task: input.task,
    taskType: input.task_type,
  };
};

export const mapToWorkflowTask = (input: any): WorkflowTask => {
  return {
    id: input.obj_identifier,
    title: input.title.replace(' Type', ''),
    types: [],
    isLoading: false,
  };
};

export const mapToWorkflowTaskType = (input: any): WorkflowTaskType => {
  return {
    id: input.id,
    title: input.title,
    titleEs: input.title_es,
  };
};

export const getUrlFragmentForTask = (taskId: string) => {
  switch (taskId) {
    case 'SiteVisit':
      return 'site_visit';
    case 'Note':
      return 'log';
    case 'EvidenceType':
      return 'evidence_types';
    case 'AssessmentType':
      return 'assessment_types';
    case 'WrapUp':
      return 'wrapup';
    case 'JobBlocker':
      return 'job_blockers';
    case 'JobStatus':
      return 'job_statuses';
    case 'UserFormType':
      return 'user_form_types';
    case 'JobFormType':
      return 'job_form_types';
    case 'PermitType':
      return 'permit_types';
    default:
      throw new Error('Not a valid task id');
  }
};

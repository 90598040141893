import { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { Log as LogAPI } from 'api';
import { useDispatch } from 'react-redux';
import { getSignedUrls } from 'helpers/media';
import { setGlobalError } from 'store/slices/notifications';
import Media from 'model/Media';
import { Log } from 'model/Log/Log';

const useLogs = (logId?: string) => {
  const [log, setLog] = useState<Log | null>(null);
  const [media, setMedia] = useState<Media | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (logId) {
      (async () => {
        try {
          const data = await LogAPI.get(parseInt(logId));
          const mediaData: Media = {
            type: '',
            signedUrl: '',
            signedUrls: [],
          };
          if (data.photos.length > 0) {
            mediaData.type = 'photo';
            mediaData.signedUrls = await getSignedUrls(data.photos);
          } else if (data.videos.length > 0) {
            mediaData.type = 'video';
            mediaData.signedUrl = await Storage.get(data.videos[0].path);
          }
          setLog(data);
          setMedia(mediaData);
        } catch (err) {
          dispatch(setGlobalError(err));
          console.error((err as Error)?.message);
        }
      })();
    }
  }, [logId]); // eslint-disable-line react-hooks/exhaustive-deps

  return { log, media };
};

export default useLogs;

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WorkflowsState } from 'model/State/settings/Workflows';
import { WorkflowTask, WorkflowStep, WorkflowTaskType } from 'model/Workflows';
import RootState from 'model/State/RootState';
import { arrayMove } from '@dnd-kit/sortable';
import { UniqueIdentifier } from '@dnd-kit/core';

const initialState: WorkflowsState = {
  stepsByWorkflowId: {},
  isLoadingByWorkflowId: {},
  activeWorkflowId: null,
  tasks: [],
  isLoadingTasks: false,
  isLoadingTaskTypes: false,
};

export const workflowSettings = createSlice({
  name: 'workflowSettings',
  initialState,
  reducers: {
    setActiveWorkflowId: (
      state: WorkflowsState,
      action: PayloadAction<string | null>,
    ) => {
      state.activeWorkflowId = action.payload;
    },
    fetchWorkflowSteps: (
      state: WorkflowsState,
      action: PayloadAction<string>,
    ) => {
      state.isLoadingByWorkflowId[action.payload] = true;
    },
    fetchWorkflowStepsSuccess: (
      state: WorkflowsState,
      action: PayloadAction<{ workflowId: string; steps: WorkflowStep[] }>,
    ) => {
      state.isLoadingByWorkflowId[action.payload.workflowId] = false;
      state.stepsByWorkflowId[action.payload.workflowId] = action.payload.steps;
    },
    fetchWorkflowStepsError: (
      state: WorkflowsState,
      action: PayloadAction<string>,
    ) => {
      state.isLoadingByWorkflowId[action.payload] = false;
    },
    fetchWorkflowTasks: (state: WorkflowsState) => {
      state.isLoadingTasks = true;
    },
    fetchWorkflowTasksSuccess: (
      state: WorkflowsState,
      action: PayloadAction<WorkflowTask[]>,
    ) => {
      state.isLoadingTasks = false;
      state.tasks = action.payload;
    },
    fetchWorkflowTasksError: (state: WorkflowsState) => {
      state.isLoadingTasks = false;
    },
    fetchWorkflowTaskTypes: (
      state: WorkflowsState,
      action: PayloadAction<string>,
    ) => {
      state.isLoadingTaskTypes = true;
    },
    fetchWorkflowTaskTypesSuccess: (
      state: WorkflowsState,
      action: PayloadAction<{ taskId: string; types: WorkflowTaskType[] }>,
    ) => {
      state.isLoadingTaskTypes = false;
      state.tasks = state.tasks.map((task) => {
        if (task.id === action.payload.taskId) {
          return { ...task, types: action.payload.types };
        }
        return task;
      });
    },
    fetchWorkflowTaskTypesError: (state: WorkflowsState) => {
      state.isLoadingTaskTypes = false;
    },
    updateWorkflowStep: (
      state: WorkflowsState,
      action: PayloadAction<{ workflowId: string; step: WorkflowStep }>,
    ) => {
      state.stepsByWorkflowId[action.payload.workflowId] =
        state.stepsByWorkflowId[action.payload.workflowId].map(
          (previousStep) => {
            if (previousStep.id === action.payload.step.id) {
              return action.payload.step;
            }
            return previousStep;
          },
        );
    },
    addWorkflowStep: (
      state: WorkflowsState,
      action: PayloadAction<{ workflowId: string; step: WorkflowStep }>,
    ) => {
      if (action.payload.step.insertAfterIndex !== undefined) {
        const { insertAfterIndex, ...newStep } = action.payload.step;

        const currentSteps = state.stepsByWorkflowId[action.payload.workflowId];

        state.stepsByWorkflowId[action.payload.workflowId] =
          currentSteps?.length > 0
            ? state.stepsByWorkflowId[action.payload.workflowId]?.toSpliced(
                insertAfterIndex,
                0,
                newStep,
              )
            : [newStep];
      }
    },
    updateWorkflowStepOrder: (
      state: WorkflowsState,
      action: PayloadAction<{
        workflowId: string;
        fromIndex: number;
        toIndex: number;
      }>,
    ) => {
      state.stepsByWorkflowId[action.payload.workflowId] = arrayMove(
        state.stepsByWorkflowId[action.payload.workflowId],
        action.payload.fromIndex,
        action.payload.toIndex,
      );
    },
    deleteStep: (
      state: WorkflowsState,
      action: PayloadAction<{ workflowId: string; stepId: UniqueIdentifier }>,
    ) => {
      state.stepsByWorkflowId[action.payload.workflowId] =
        state.stepsByWorkflowId[action.payload.workflowId].filter(
          (step) => step.id !== action.payload.stepId,
        );
    },
  },
});

export const {
  setActiveWorkflowId,
  fetchWorkflowSteps,
  fetchWorkflowStepsError,
  fetchWorkflowStepsSuccess,
  fetchWorkflowTasks,
  fetchWorkflowTasksError,
  fetchWorkflowTasksSuccess,
  fetchWorkflowTaskTypes,
  fetchWorkflowTaskTypesError,
  fetchWorkflowTaskTypesSuccess,
  addWorkflowStep,
  updateWorkflowStep,
  updateWorkflowStepOrder,
  deleteStep,
} = workflowSettings.actions;

export const selectActiveWorkflowId = (state: RootState): string | null =>
  state.workflowSettings.activeWorkflowId;

const selectWorkflowsById = (
  state: RootState,
): { [workflowId: string]: WorkflowStep[] } =>
  state.workflowSettings.stepsByWorkflowId;
const selectIsLoadingWorkflowStepsById = (
  state: RootState,
): { [workflowId: string]: boolean } =>
  state.workflowSettings.isLoadingByWorkflowId;

const selectWorkflowStepsById = createSelector(
  selectWorkflowsById,
  (_: RootState, workflowId: string | null) => workflowId,
  (
    workflowStepsById: { [workflowId: string]: WorkflowStep[] },
    workflowId: string | null,
  ) => (workflowId ? (workflowStepsById?.[workflowId] ?? []) : []),
);
export const selectWorkflowSteps =
  (workflowId: string | null) => (state: RootState) =>
    selectWorkflowStepsById(state, workflowId);

const selectIsWorkflowLoadingByWorkflowId = createSelector(
  selectIsLoadingWorkflowStepsById,
  (_: RootState, workflowId: string | null) => workflowId,
  (isLoading: { [workflowId: string]: boolean }, workflowId: string | null) =>
    workflowId ? isLoading?.[workflowId] || false : false,
);
export const selectIsLoadingWorkflowSteps =
  (workflowId: string | null) => (state: RootState) =>
    selectIsWorkflowLoadingByWorkflowId(state, workflowId);

export const selectWorkflowTasks = (state: RootState): WorkflowTask[] =>
  state.workflowSettings.tasks;

export const selectIsLoadingWorkflowTasks = (state: RootState): boolean =>
  state.workflowSettings.isLoadingTasks;

const selectWorkflowTaskTypesByTaskId = createSelector(
  selectWorkflowTasks,
  (_: RootState, taskId: string) => taskId,
  (workflowTasks: WorkflowTask[], taskId: string): WorkflowTaskType[] =>
    workflowTasks.find((task) => task.id === taskId)?.types ?? [],
);
export const selectWorkflowTaskTypes = (taskId: string) => (state: RootState) =>
  selectWorkflowTaskTypesByTaskId(state, taskId);

export const selectIsLoadingWorkflowTaskTypes = (state: RootState) =>
  state.workflowSettings.isLoadingTaskTypes;

export const workflowSettingsReducer = workflowSettings.reducer;

import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media } = theme;

export const TextContainer = styled.div<{
  $alignEnd: boolean;
  $alignCenter: boolean;
}>`
  position: absolute;
  top: 20px;
  ${({ $alignEnd }) => $alignEnd && `right: 0px`};
  ${({ $alignCenter }) => $alignCenter && `transform: translateX(-40%)`};
`;

export const StatusContainer = styled.div<{
  $alignEnd: boolean;
  $alignCenter: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  width: max-content;
  ${({ $alignCenter }) => $alignCenter && `text-align: center`};
  ${({ $alignEnd }) => $alignEnd && `text-align: end`};
`;

export const MobileStatus = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

export const StatusTitle = styled.span<{ $isAchieved: boolean }>`
  font-family: ${fonts.bold};
  color: ${({ $isAchieved }) => ($isAchieved ? colors.black : colors.darkGrey)};
`;

export const DateWrapper = styled.div`
  font-family: ${fonts.light};
  font-size: ${fontSizes.s};
  ${media.xl`
  width: 65px;
`}
`;

export const Rectangle = styled.div<{
  size: number;
  $isAchieved: boolean;
  $isMobile: boolean;
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ $isAchieved }) =>
    $isAchieved ? colors.blue : colors.darkGrey};
  display: block;
  position: relative;
  z-index: 2;
  ${({ $isMobile }) => $isMobile && `margin-top: 5px`};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`;

export const MobileContainer = styled.div`
  display: flex;
`;

export const Line = styled.div<{ $isActive: boolean }>`
  border-bottom: 2px
    ${({ $isActive }) =>
      $isActive ? `solid ${colors.blue}` : `dashed ${colors.grey}`};
  height: 2px;
  display: flex;
  flex-grow: 1;
`;

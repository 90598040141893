import { useState, useEffect } from 'react';
import { useIsMobile, useText } from 'hooks';
import { Navbar, Input, Card } from 'components/ui';
import { useDispatch, useSelector } from 'react-redux';
import { updateAssessment } from 'store/actions/assessments';
import { selectGlobalLanguageSetting, selectJobsView } from 'store/selectors';
import { Assessment, AssessmentItemType } from 'model/Assessment';
import RootState from 'model/State/RootState';
import { useNavigate, useParams } from 'react-router-dom';
import { Hazard } from 'model/Assessment/Hazard';
import { Control } from 'model/Assessment/Control';
import ModalCtaButton from './ModalCtaButton';
import { EVENT_PROPERTIES_PAGE_SOURCE_FIELD } from 'utils/constants';
import {
  Container,
  ContentWrapper,
  CrossIconStyled,
  ErrorContainer,
  ItemList,
  ItemRow,
  ItemRowSelected,
  P,
  PlusIconStyled,
  PSelected,
} from './styled';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

type ItemPropTypes = {
  id: number;
  title: string;
};

type Props = {
  items: ItemPropTypes[];
  addType: AssessmentItemType.HAZARD | AssessmentItemType.CONTROL;
  selectedId?: number;
  event?: string;
  assessment?: Assessment | null;
  onNext?: (value: Hazard[] | Control[]) => void;
  suggestedItems?: Hazard[] | Control[];
};

const AddBase = ({
  items,
  addType,
  event,
  assessment,
  selectedId,
  onNext = () => {},
  suggestedItems = [],
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const { jobId, assessmentId } = useParams();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const getText = useText();

  const getSelectedItems = () => {
    if (!assessment) {
      return [];
    }
    if (
      addType === AssessmentItemType.HAZARD &&
      (assessment?.hazards?.length || assessment?.suggestedHazards?.length)
    ) {
      return [...(assessment.hazards ?? [])].concat(
        assessment?.suggestedHazards,
      );
    }
    if (suggestedItems?.length) {
      return suggestedItems;
    }
    if (assessment?.hazardsWithControls?.length && selectedId) {
      const hazard = assessment.hazardsWithControls.find(
        (hazardWithControls) => hazardWithControls.hazard.id === selectedId,
      );
      return hazard?.controls ?? [];
    }
    return assessment[`${addType}s`];
  };

  const [search, setSearch] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [selected, setSelected] = useState<Hazard[] | Control[]>(() =>
    getSelectedItems(),
  );
  const { isAssessmentUpdating: isLoading, assessmentError: errorMessage } =
    useSelector((state: RootState) => state.assessments);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const view = useSelector(selectJobsView);

  useEffect(() => {
    if (submitted && !isLoading) {
      navigate(`/jobs/${jobId}/assessments/${assessmentId}`);
    }
  }, [submitted, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const isItemSelected = (item: Hazard | Control): boolean =>
    !!selected.find((selectedItem) => selectedItem.id === item.id);

  const handleFilterChange = (newSearch) => {
    setSearch(newSearch.toLowerCase());
  };

  const handleSelectChange = (item) => {
    if (isItemSelected(item)) {
      setSelected(
        [...selected].filter((selectedItem) => selectedItem.id !== item.id),
      );
    } else {
      setSelected((prevItems) => [...prevItems, item]);
    }
  };

  const handleSubmit = async () => {
    let updatableAssessment: Assessment | null = assessment
      ? { ...assessment }
      : null;

    if (
      assessment?.hazardsWithControls &&
      assessment?.hazardsWithControls?.length > 0
    ) {
      if (addType === AssessmentItemType.CONTROL) {
        const baseHazardList =
          suggestedItems?.length > 0
            ? [...assessment.suggestedHazardsWithControls]
            : [...assessment.hazardsWithControls];
        const modifiedHazard = baseHazardList.find(
          (hazardWithControls) => hazardWithControls.hazard.id === selectedId,
        );
        if (modifiedHazard && updatableAssessment) {
          const modifiedHazardsWithControls =
            assessment.hazardsWithControls.map((hazardWithControls) => {
              if (hazardWithControls.hazard.id === modifiedHazard.hazard.id) {
                return { ...hazardWithControls, controls: [...selected] };
              }
              return hazardWithControls;
            });
          updatableAssessment = {
            ...updatableAssessment,
            hazardsWithControls: [...modifiedHazardsWithControls],
          };
        }
      } else {
        updatableAssessment = null;
        const alreadyAddedHazardIds = assessment?.hazardsWithControls.map(
          (hazardWithControls) => hazardWithControls.hazard.id,
        );
        onNext(
          selected.filter((item) => !alreadyAddedHazardIds.includes(item.id)),
        );
      }
    } else if (updatableAssessment && addType === AssessmentItemType.HAZARD) {
      updatableAssessment = {
        ...updatableAssessment,
        hazards: selected as Hazard[],
      };
    } else if (updatableAssessment && addType === AssessmentItemType.CONTROL) {
      updatableAssessment = {
        ...updatableAssessment,
        controls: selected as Control[],
      };
    }

    if (updatableAssessment) {
      dispatch(
        updateAssessment(assessment?.id, { assessment: updatableAssessment }),
      );
    }

    setSubmitted(true);
  };

  const renderItem = (item) => {
    if (isItemSelected(item)) {
      return (
        <ItemRowSelected key={item.id} onClick={() => handleSelectChange(item)}>
          <PSelected>
            {getLocalizedCamelCaseValue(item, 'title', globalAppLanguage)}
          </PSelected>
          <CrossIconStyled />
        </ItemRowSelected>
      );
    }

    return (
      <ItemRow key={item.id} onClick={() => handleSelectChange(item)}>
        <P>{getLocalizedCamelCaseValue(item, 'title', globalAppLanguage)}</P>
        <PlusIconStyled />
      </ItemRow>
    );
  };

  return (
    <>
      <Navbar
        title={getText('assessment_add', { type: addType })}
        defaultBackPath={`/jobs/${jobId}/assessments/${assessmentId}/${addType}s`}
        mobileOnly
      />

      {items && (
        <ContentWrapper>
          {errorMessage && (
            <ErrorContainer>
              <Card.ErrorMessage message={errorMessage} />
            </ErrorContainer>
          )}
          <Container>
            <Input.Text
              id='search'
              label={getText('assessment_enter', { type: addType })}
              value={search}
              onChange={(e) => handleFilterChange(e.target.value)}
            />
          </Container>

          <ItemList>
            {items
              ?.filter(
                (item) =>
                  search === '' ||
                  getLocalizedCamelCaseValue(item, 'title', globalAppLanguage)
                    .toLowerCase()
                    .indexOf(search) > -1,
              )
              .map(renderItem)}
          </ItemList>
          <ModalCtaButton
            text={getText('assessment_approve_changes')}
            onClick={handleSubmit}
            loading={isLoading}
            event={event}
            eventProperties={{
              jobId,
              assessmentId,
              ids: selected,
              [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            extraStyles={!isMobile ? { margin: '0', flex: '0 0 55px' } : {}}
          />
        </ContentWrapper>
      )}
    </>
  );
};

export default AddBase;

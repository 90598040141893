import { Button } from 'components/ui';
import { useText } from 'hooks';
import UserDataRow from 'model/UserDataRow';
import { useState } from 'react';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import theme from 'theme';
import { useSelector } from 'react-redux';
import { selectCurrentUserId } from 'store/selectors';
import ActivateUserModal from '../../ActivateUserModal';
import DeactivateUserModal from '../../DeactivateUserModal';

const { fonts } = theme;

type Props = {
  user: UserDataRow;
  updateUser: ({ id, is_active }) => void;
};

const ActivateButtons = ({ user, updateUser }: Props): JSX.Element => {
  const getText = useText();
  const currentUserId = useSelector(selectCurrentUserId);
  const [showActivateModal, setShowActivateModal] = useState<boolean>(false);
  const [showDeactivateModal, setShowDeactivateModal] =
    useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<UserDataRow | null>(null);

  const onStatusChangeClick = () => {
    if (!user.is_active) {
      setShowActivateModal(true);
      Analytics.trackEvent(constants.EVENT_OPEN_ACTIVATE_MODAL, {
        modified_user_id: user?.id,
        user_id: currentUserId,
      });
    } else {
      setShowDeactivateModal(true);
      Analytics.trackEvent(constants.EVENT_OPEN_DEACTIVATE_MODAL, {
        modified_user_id: user?.id,
        user_id: currentUserId,
      });
    }
    setSelectedUser(user);
  };

  const onActivateButtonClick = () => {
    setShowActivateModal(false);
    if (selectedUser) {
      updateUser({ id: selectedUser.id, is_active: true });
      Analytics.trackEvent(constants.EVENT_ACTIVATE_USER, {
        modified_user_id: selectedUser?.id,
        user_id: currentUserId,
      });
    }
  };

  const onDeactivateButtonClick = () => {
    setShowDeactivateModal(false);
    if (selectedUser) {
      updateUser({ id: selectedUser.id, is_active: false });
      Analytics.trackEvent(constants.EVENT_DEACTIVATE_USER, {
        modified_user_id: selectedUser?.id,
        user_id: currentUserId,
      });
    }
  };

  return (
    <>
      {user.is_active ? (
        <Button.Confirm
          onClick={onStatusChangeClick}
          text={getText('users_deactivate_user_button')}
          width='80px'
          minWidth='80px'
          extraStyles={{ fontFamily: fonts.default }}
        />
      ) : (
        <Button.Confirm
          variant='blue'
          onClick={onStatusChangeClick}
          text={getText('users_activate_user_button')}
          width='80px'
          minWidth='80px'
        />
      )}
      {showActivateModal && selectedUser && (
        <ActivateUserModal
          isVisible={showActivateModal}
          setVisible={setShowActivateModal}
          selectedUser={selectedUser}
          onButtonClick={onActivateButtonClick}
        />
      )}
      {showDeactivateModal && selectedUser && (
        <DeactivateUserModal
          isVisible={showDeactivateModal}
          setVisible={setShowDeactivateModal}
          selectedUser={selectedUser}
          onButtonClick={onDeactivateButtonClick}
        />
      )}
    </>
  );
};

export default ActivateButtons;

import { CSSProperties } from 'styled-components';
import { StyledTextField } from './styled';
import { Container } from '../styled';

type Props = {
  id: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  extraStyles?: CSSProperties;
  placeholder?: string;
};

// Input.TransparentTextBox
export const TransparentTextBox = ({
  id,
  value,
  onChange,
  disabled,
  extraStyles,
  placeholder = '',
}: Props): JSX.Element => (
  <Container style={extraStyles}>
    <StyledTextField
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      autoComplete='off'
    />
  </Container>
);

import { CSSProperties } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ArrowIcon, StyledLinkButton } from './styled';
import { Container, Label } from '../styled';

type Props = {
  label: string;
  value: string;
  pathname: string;
  search?: string;
  state?: object;
  extraStyles?: CSSProperties;
};

// Input.Link
export const Link = ({
  label,
  value,
  pathname,
  search,
  state,
  extraStyles,
  ...props
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${pathname}?${search}`, { state, ...props });
  };

  return (
    <Container style={extraStyles}>
      <Label $marginBottom='10px'>{label}</Label>

      <StyledLinkButton
        variant='outlined'
        onClick={handleClick}
        endIcon={<ArrowIcon />}
      >
        {value}
      </StyledLinkButton>
    </Container>
  );
};

import { useText } from 'hooks';
import { useSelector } from 'react-redux';
import { RamsStages } from 'model/Assessment/RAMS';
import { HazardIdentification } from './Identification';
import { HazardRating } from './Rating';
import RootState from 'model/State/RootState';
import { NotEditableTagWrapper, SectionContentContainer } from './styled';
import { SectionHeader } from './SectionHeader';
import { selectUserCanEditRams } from 'store/slices/ramsAssessments';
import { NotEditableTag } from './NotEditableTag';

// RamsHazard
export const RamsHazard = () => {
  const getText = useText();
  const { currentStage } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );
  const canUserEdit = useSelector(selectUserCanEditRams);

  const getSubtitle = () => {
    switch (currentStage) {
      case RamsStages.hazardRating:
        return getText('rams_status_risk_assessment_subtitle_hazard_rating');
      case RamsStages.hazardIdentification:
      default:
        return getText(
          'rams_status_risk_assessment_subtitle_hazard_identification',
        );
    }
  };

  const getComponentForStage = () => {
    switch (currentStage) {
      case RamsStages.hazardRating:
        return <HazardRating />;
      case RamsStages.hazardIdentification:
        return <HazardIdentification />;
      default:
        return null;
    }
  };

  return (
    <SectionContentContainer>
      <SectionHeader
        title={getText('rams_section_option_assessment')}
        subtitle={getSubtitle()}
      />

      {canUserEdit ? (
        <>{getComponentForStage()}</>
      ) : (
        <NotEditableTagWrapper>
          <NotEditableTag />
        </NotEditableTagWrapper>
      )}
    </SectionContentContainer>
  );
};

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import TagManager from 'react-gtm-module';
import { createReduxHistory } from './history';

import { configureCognito, configureAxios } from 'utils/config';
import * as serviceWorker from 'utils/serviceWorker';
import theme from 'theme';

import { App } from 'components';
import { configureStore } from './store/store';
import './i18n/index';
import 'react-calendar/dist/Calendar.css';

// Configurations
configureCognito();
configureAxios({ baseURL: process.env.REACT_APP_API_URL });
const store = configureStore();
const history = createReduxHistory(store);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_TAG,
};
TagManager.initialize(tagManagerArgs);

// Global styles
export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: ${() => theme.colors.white} !important;    
    font-family: ${() => theme.fonts.default} !important;
    font-size: ${() => theme.fontSizes.default} !important;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0 !important;
    color: ${() => theme.colors.black};
  }

  input, textarea {
    font-family: ${() => theme.fonts.default} !important;
    color: ${() => theme.colors.black} !important;
  }

  ul {
    color: ${() => theme.colors.black};
    padding: 0;
    margin: 0 !important;
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #ffa800;
    color: black;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #ffa800;
    color: black;
  }
  #search-input {
    padding: 5px;
    margin-top: 10px;
    width: 90%;
    height: 50px;
    font-size: ${theme.fontSizes.m};
    border: 3px solid ${theme.colors.black};
    &:focus {
      border: 3px solid ${theme.colors.orange};
      outline: none;
    }
  }
  .pac-container {
    z-index: 2000 !important;
  }
`;

const container = document.getElementById('root');
const root = createRoot(container!);
// App
root.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </ThemeProvider>,
);

// Service worker
serviceWorker.unregister();

import { Button as MaterialButton } from '@mui/material';
import { InfoFilled } from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setFeaturesGuideStatus } from 'store/actions/featuresGuide';
import { selectJobsView } from 'store/selectors';
import styled from 'styled-components';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';

const { colors } = theme;

// Styled Components
const InfoButton = styled(MaterialButton)`
  &.MuiButtonBase-root {
    padding: 0;
    min-width: 30px;
    border-radius: 0;
    border: none;
    margin-right: 16px;
  }
`;

const InfoIcon = styled(InfoFilled)`
  .fill-selector {
    fill: ${colors.blue};
  }
`;

export const FeaturesInfoButton = () => {
  const dispatch = useDispatch();
  const view = useSelector(selectJobsView);

  const onInfoButtonClick = () => {
    dispatch(setFeaturesGuideStatus(true));
    Analytics.trackEvent(constants.EVENT_WALKTHROUGH_INFORMATION_ICON_CLICKED, {
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    });
  };

  return (
    <InfoButton onClick={onInfoButtonClick}>
      <InfoIcon className='walkthrough-step-info' />
    </InfoButton>
  );
};

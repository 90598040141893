import { useEffect } from 'react';
import styled from 'styled-components';
import { useIsMobile, useQueryParams, useText } from 'hooks';
import theme from 'theme';
import { Card, Loader, Message, Navbar } from 'components/ui';
import { SkeletonCardContainer } from 'components/ui/Shared/EmptyState';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGlobalLanguageSetting,
  selectIsFetchingWorkOrderItems,
  selectJobWorkOrderItems,
} from 'store/selectors';
import { getWorkOrderSubtype } from 'helpers/apiHelpers';
import { useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import { fetchWorkOrderItems } from 'store/actions/jobs';

const { media } = theme;

// Styled Components
const Container = styled.div`
  height: 100%;
  ${media.sm`
    padding: 25px 15px;
    overflow: scroll;
  `}
`;

export const WorkOrderItemList = (): JSX.Element => {
  const { jobId } = useParams();
  const isMobile = useIsMobile();
  const getText = useText();
  const dispatch = useDispatch();
  const { getPathWithQueryParams } = useQueryParams();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const workOrderItems = useSelector(selectJobWorkOrderItems(jobId));
  const isFetchingWorkOrderItems = useSelector(selectIsFetchingWorkOrderItems);
  const { activeJob } = useSelector((state: RootState) => state.jobs);

  useEffect(() => {
    dispatch(fetchWorkOrderItems(jobId));
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (workOrderItems && jobId) {
      Analytics.trackPage(constants.EVENT_PAGE_VIEW_WORK_COMPLETED, {
        jobId,
        workOrderItems,
      });
    }
  }, [workOrderItems, jobId]);

  return (
    <>
      <Navbar
        title={getText('job_details_work_completed_title')}
        defaultBackPath={`/jobs/${jobId}`}
        mobileOnly
      />

      <Container>
        {isFetchingWorkOrderItems ? (
          <Loader />
        ) : (
          <>
            {!workOrderItems?.length ? (
              <SkeletonCardContainer>
                {getText('job_details_work_completed_no_display')}
              </SkeletonCardContainer>
            ) : (
              workOrderItems?.map((workOrderItem) =>
                isMobile ? (
                  <Message.Action
                    key={`log-${workOrderItem.id}`}
                    event={constants.EVENT_VIEW_WORK_COMPLETED_ITEM}
                    eventProperties={{
                      jobId,
                      logId: workOrderItem.id,
                      logType: 'work item',
                      source: 'job-overview',
                    }}
                    type={getText('job_details_timeline_action_work_completed')}
                    subtype={getWorkOrderSubtype(
                      workOrderItem.subtype,
                      activeJob,
                      globalAppLanguage,
                    )}
                    sender={workOrderItem.createdBy}
                    date={workOrderItem.createdAt}
                    to={`/jobs/${workOrderItem.jobId}/work-completed/${workOrderItem.id}`}
                  />
                ) : (
                  <Card.WorkOrderItem
                    key={`log-${workOrderItem.id}`}
                    event={constants.EVENT_VIEW_WORK_COMPLETED_ITEM}
                    eventProperties={{
                      jobId,
                      logId: workOrderItem.id,
                      logType: 'work item',
                      source: 'job-overview',
                    }}
                    subtype={getWorkOrderSubtype(
                      workOrderItem.subtype,
                      activeJob,
                      globalAppLanguage,
                    )}
                    creator={workOrderItem.createdBy}
                    date={workOrderItem.createdAt}
                    to={getPathWithQueryParams(
                      `/jobs/${workOrderItem.jobId}/work-completed/${workOrderItem.id}`,
                    )}
                  />
                ),
              )
            )}
          </>
        )}
      </Container>
    </>
  );
};

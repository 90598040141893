import { mapToUser } from 'api/User/helpers';
import { Document } from 'model/Media/Document';

export const mapToDocument = (backendDocument: any): Document => ({
  id: backendDocument.id,
  jobId: backendDocument.job_id,
  fileId: backendDocument.file_id,
  extensionType: backendDocument.extension_type,
  originalFileName: backendDocument.original_file_name,
  fileSize: backendDocument.file_size,
  path: backendDocument.path,
  url: backendDocument.url,
  status: backendDocument.status,
  createdBy: mapToUser(backendDocument.created_by),
  createdAt: new Date(backendDocument.created_at),
  updatedAt: new Date(backendDocument.updated_at),
  sentAt: backendDocument?.sent_at
    ? new Date(backendDocument.created_at)
    : null,
  uuid: backendDocument.uuid,
  signedUrl: null,
});

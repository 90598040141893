import styled from 'styled-components';
import theme from 'theme';
import { Button as MaterialButton } from '@mui/material';

const { media, colors, fonts, fontSizes, mixins } = theme;

export const Button = styled(({ width, disabled, ...rest }) => (
  <MaterialButton disabled={disabled} {...rest} />
))`
  &.MuiButtonBase-root {
    height: 55px;
    width: 100%;
    background-color: ${colors.white} !important;
    box-sizing: border-box;
    border-radius: 0;
    outline: 2px solid ${colors.grey};
    padding: 0 16px;
    color: ${colors.black};
    font-family: ${fonts.bold};
    font-size: ${fontSizes.l};
    ${mixins.flexBetween};
    text-transform: none;
    letter-spacing: 0;
    margin: 2px;
    margin-bottom: 15px;
    &:hover {
      outline: 3px solid ${colors.black};
      background-color: ${colors.white} !important;
    }
    &.Mui-disabled {
      outline: 2px solid ${colors.grey};
      background-color: ${colors.lightGrey} !important;
      color: ${colors.black};
      &:hover {
        outline: 2px solid ${colors.grey};
        background-color: ${colors.lightGrey} !important;
      }
    }
    ${media.sm`
      margin: 10px 0;
    `}
  }
`;

export const Description = styled.div<{ $disabled: boolean }>`
  ${mixins.flexCenter};
  width: 80%;
  opacity: ${({ $disabled }) => ($disabled ? 0.65 : 1)};
`;

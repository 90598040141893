import { ConfirmModal } from 'components/ui/Modal';
import { useText } from 'hooks';
import UserDataRow from 'model/UserDataRow';

type Props = {
  selectedUser: UserDataRow;
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onButtonClick: () => void;
};

const ActivateUserModal = ({
  selectedUser,
  isVisible,
  setVisible,
  onButtonClick,
}: Props): JSX.Element => {
  const getText = useText();
  const confirmMessage = getText('activate_user_confirmation', {
    user: ` ${selectedUser?.name} (${selectedUser?.email})`,
  });

  return (
    <ConfirmModal
      title={getText('activate_user_title')}
      confirmMessage={confirmMessage}
      warningMessage={getText('activate_user_warning')}
      isVisible={isVisible}
      closeModal={() => setVisible(false)}
      onButtonClick={onButtonClick}
      buttonText={getText('users_activate_user_button')}
    />
  );
};

export default ActivateUserModal;

import {
  WEBSOCKET_INIT,
  WEBSOCKET_CLOSE,
  WEBSOCKET_OPENED,
  WEBSOCKET_CLOSED,
  WEBSOCKET_MESSAGE_RECEIVED,
  WEBSOCKET_ERROR_THROWN,
  WEBSOCKET_RESTART,
  WEBSOCKET_HANDLE_ERROR,
} from './actionTypes';

export const websocketInit = (token) => ({
  type: WEBSOCKET_INIT,
  token,
});

export const websocketClose = () => ({ type: WEBSOCKET_CLOSE });

export const websocketOpened = () => ({ type: WEBSOCKET_OPENED });

export const websocketClosed = () => ({ type: WEBSOCKET_CLOSED });

export const websocketMessageReceived = (data) => ({
  type: WEBSOCKET_MESSAGE_RECEIVED,
  data,
});

export const websocketErrorThrown = () => ({ type: WEBSOCKET_ERROR_THROWN });

export const websocketRestart = () => ({ type: WEBSOCKET_RESTART });

export const websocketHandleError = () => ({ type: WEBSOCKET_HANDLE_ERROR });

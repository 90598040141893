import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectViewVersion } from 'store/selectors';
import { LEGACY_VIEW } from 'utils/constants';

const useCommandCenter = () => {
  const location = useLocation();
  const viewVersion = useSelector(selectViewVersion);

  const isCommandCenter = location?.pathname?.includes('/command-center');

  const getPathToTimeline = (jobId) => {
    if (viewVersion === LEGACY_VIEW) {
      return `/jobs/${jobId}`;
    }
    return `/jobs/${jobId}/timeline`;
  };

  return { isCommandCenter, getPathToTimeline };
};

export default useCommandCenter;

import { put, takeLatest, select, fork } from 'redux-saga/effects';
import { Session, User } from 'api';
import { Auth } from 'aws-amplify';
import * as Logger from 'utils/logger';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { Dates } from 'helpers';
import { push } from 'redux-first-history';
import { INITIAL_FILTERS } from 'utils/constants';
import {
  fetchJobs,
  setJobFilters,
  clearJobs,
  setJobSort,
} from '../actions/jobs';
import {
  setCurrentUser,
  setSessionError,
  setPasswordError,
  setSessionToken,
  forgotPasswordSent,
  completeNewPasswordDone,
  resetPasswordDone,
  startAuth,
  endAuth,
  setLastRefresh,
  magicLinkRequested,
  setGlobalLanguage,
} from '../actions/session';
import { websocketClose, websocketInit } from '../actions/websockets';
import {
  FETCH_CURRENT_USER,
  SIGN_IN,
  COMPLETE_NEW_PASSWORD,
  SIGN_OUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  WEBSOCKET_RESTART,
  TOKEN_SIGN_IN,
  REQUEST_MAGIC_LINK,
  RELOAD_FOR_WORKSPACE,
  INIT_GLOBAL_LANGUAGE_SETTING,
} from '../actions/actionTypes';
import { clearTeams, setLatestPsiReview } from 'store/actions/user';
import { clearViewVersion } from 'store/actions/versions';
import { clearFormOptions, fetchFormOptions } from 'store/actions/form';
import { finishSignIn } from 'helpers/auth';
import { Pathname } from 'model/enum/Pathname';
import { resetFetching } from 'store/slices/commandCenter';
import { selectJobsView } from 'store/selectors';
import { downloadDocument } from 'store/actions/documents';
import { DownloadSource } from 'model/enum/DownloadSource';
import { ForgotPasswordFlowTriggerStatus } from 'model/Session';

function* setupSessionToken() {
  // We need to check session here as well as this is for websocketReload
  // And to setSessionToken. I'll think further how to clear this up.
  let session;
  try {
    session = yield Auth.currentSession();
  } catch (err) {
    if (err !== 'No current user') {
      Logger.error(Error(`Auth error: ${err.message}`, { cause: err }));
      // No logged-in user: can't set currentUser
    }
  }
  if (session?.accessToken?.jwtToken) {
    yield put(setSessionToken(session.accessToken.jwtToken));
    yield put(websocketInit(session.accessToken.jwtToken));
  }
}

function* fetchCurrentUser({ source }) {
  // Need to check if we are in reset-password
  const router = yield select((state) => state.router);
  const isResetPassword =
    router?.location?.pathname?.includes('reset-password');
  // We need to check if we have a cognito session already
  // This is needed for page reload.
  let session;
  try {
    session = yield Auth.currentSession();
  } catch (err) {
    if (err !== 'No current user') {
      if (err.code === 'NotAuthorizedException') {
        Logger.info(Error(err));
      } else {
        Logger.error(Error(`Auth error: ${err.message}`, { cause: err }));
      }
    }
    Analytics.trackEvent(constants.EVENT_LOGIN_COMPLETE, {
      loginSource: source,
      success: false,
      failure_reason: err,
    });
    yield put(endAuth());
    // No logged-in user: can't set currentUser
  }
  if (session && !isResetPassword) {
    try {
      yield put(startAuth());
      const { data } = yield User.get();
      localStorage.setItem('organisationName', data.organisation?.name);
      if (data?.language) {
        yield put(setGlobalLanguage(data.language));
      }
      if (data.permissions.includes('show_job_safety_prediction_v1')) {
        const { data: statData } = yield User.getStats();
        yield put(setLatestPsiReview(statData.latest_psi_review));
      }
      const jobFilters = yield JSON.parse(
        window.localStorage.getItem('jobFilters') || '{}',
      );
      yield put(setJobFilters(jobFilters || INITIAL_FILTERS));
      if (
        !data.permissions.includes('manager') &&
        !data.permissions.includes('backoffice')
      ) {
        const user = yield Auth.currentAuthenticatedUser();
        yield user.signOut();
        yield put(
          setSessionError('Only managers can access the web dashboard.'),
        );
      } else {
        yield setupSessionToken();
        yield put(
          setCurrentUser({
            ...data,
            selectedWorkspaceUuid: JSON.parse(
              localStorage.getItem('activeWorkspaceUuid') ?? '{}',
            ),
          }),
        );
        yield put(setLastRefresh(Dates.getLastRefresh()));
        Analytics.setUser(
          data.id,
          {
            workspace: {
              id: data.organisation?.uuid ?? '',
              name: data.organisation?.name ?? '',
            },
            organisation: {
              id: data.organisation_uuid ?? '',
              name: data.organisation_name ?? '',
            },
          },
          {
            email: data.email,
            role: data.role ?? '',
            depot: data.depot?.title ?? '',
            user_id: data.id,
            company: {
              id: data.organisation?.uuid ?? '',
              name: data.organisation?.name ?? '',
            },
          },
        );
        Logger.setContext({ user: { id: data.id } });
        finishSignIn(source);
      }
    } catch (err) {
      if (
        err === 'No userPool' ||
        err.message === 'Request failed with status code 500'
      ) {
        Logger.error(
          new Error(`Auth error: ${err.message}`, {
            cause: err,
          }),
        );
      }
      yield put(endAuth());
    }
  }
}

function* signOut() {
  try {
    yield Auth.signOut();
    yield put(websocketClose());
    yield put(endAuth());
    yield put(clearJobs());
    yield put(clearFormOptions());
    yield put(clearViewVersion());
    yield put(clearTeams());
    window.localStorage.removeItem('insightFilters');
    window.localStorage.removeItem('fatigueManagerFilters');
    Analytics.trackEvent(constants.EVENT_USER_LOGOUT);
  } catch (err) {
    Logger.error(err);
  }
}

function* requestMagicLink({ email }) {
  try {
    yield User.requestMagicLink(email);
    Analytics.trackEvent(constants.EVENT_REQUEST_MAGIC_LINK, {
      email,
      status: constants.SUCCESS,
    });
    yield put(magicLinkRequested());
  } catch (err) {
    Analytics.trackEvent(constants.EVENT_REQUEST_MAGIC_LINK, {
      email,
      status: constants.ERROR,
    });
  }
}

function* tokenSignIn({ email, challengeToken }) {
  try {
    Auth.configure({
      authenticationFlowType: 'CUSTOM_AUTH',
    });

    const response = yield Auth.signIn(email, '');

    if (response.challengeName === 'CUSTOM_CHALLENGE') {
      yield Auth.sendCustomChallengeAnswer(response, challengeToken);
    }

    yield fetchCurrentUser({ source: constants.LoginSource.MAGIC_LINK });
  } catch (err) {
    Logger.error(err);
    Analytics.trackEvent(constants.EVENT_LOGIN_COMPLETE, {
      loginSource: constants.LoginSource.MAGIC_LINK,
      success: false,
      failure_reason: err,
    });
    yield put(endAuth());
  }
}

function* signIn({ email, password }) {
  try {
    Auth.configure({
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    });

    const response = yield Auth.signIn(email, password);
    if (response.challengeName === constants.AUTH_STATUS_RESET_PASSWORD) {
      Analytics.trackEvent(constants.EVENT_USER_LOGIN_FIRST);
      yield put(
        push('/reset-password', {
          temporaryPassword: password,
        }),
      );
    }
    yield fetchCurrentUser({ source: constants.LoginSource.EMAIL_PASSWORD });

    const orgSettings = yield select((state) =>
      state.session.selectedWorkspace?.settings == null
        ? state.session?.currentUser?.organisation?.settings
        : state.session?.selectedWorkspace?.settings,
    );
    const enableLanguageSelection = orgSettings
      ? orgSettings.enable_language_selection
      : true;

    if (!enableLanguageSelection) {
      yield put(setGlobalLanguage(constants.Languages.english.locale));
    }

    const downloadFileObjectId = sessionStorage.getItem('downloadObjectId');
    if (downloadFileObjectId) {
      yield put(downloadDocument(downloadFileObjectId, DownloadSource.EMAIL));
      sessionStorage.removeItem('downloadObjectId');
    }
  } catch (err) {
    if (err.code === 'NotAuthorizedException') {
      Logger.info(err, { email });
    } else {
      Logger.error(err, { email });
    }
    yield put(
      setSessionError(
        "Those details don't match our records. Please check and try again.",
      ),
    );
    Analytics.trackEvent(constants.EVENT_LOGIN_COMPLETE, {
      loginSource: constants.LoginSource.EMAIL_PASSWORD,
      success: false,
      failure_reason: err,
    });
  }
}

function* completeNewPassword({ email, temporaryPassword, newPassword }) {
  try {
    const user = yield Auth.signIn(email, temporaryPassword);
    yield Auth.completeNewPassword(user, newPassword);
    Analytics.trackEvent(constants.EVENT_PASSWORD_RESET, {
      status: constants.SUCCESS,
    });
    yield put(completeNewPasswordDone());
  } catch (err) {
    Analytics.trackEvent(constants.EVENT_PASSWORD_RESET, {
      status: constants.ERROR,
    });
    if (err.code === 'CodeMismatchException') {
      Logger.info(err, { email });
    } else {
      Logger.error(err, { email });
    }
    if (err.code === 'ExpiredCodeException') {
      put(
        setPasswordError(
          'Password reset code expired. Please request a new one.',
        ),
      );
    } else {
      put(
        setPasswordError(
          'There was a problem resetting your password. Please try again.',
        ),
      );
    }
  }
}

function* forgotPassword({ email }) {
  try {
    const status = yield Session.triggerPasswordResetFlow(email);

    if (status === ForgotPasswordFlowTriggerStatus.SUCCESS) {
      Analytics.trackEvent(constants.EVENT_PASSWORD_REQUEST_NEW, {
        status: constants.SUCCESS,
        info: status,
      });
      yield put(forgotPasswordSent());
    } else {
      Analytics.trackEvent(constants.EVENT_PASSWORD_REQUEST_NEW, {
        status: constants.ERROR,
        info: status,
      });
      Logger.error(status, { email });
      yield put(setPasswordError(status));
    }
  } catch (err) {
    Analytics.trackEvent(constants.EVENT_PASSWORD_REQUEST_NEW, {
      status: constants.ERROR,
      info: err,
    });
    Logger.error(err, { email });
    yield put(setPasswordError(ForgotPasswordFlowTriggerStatus.ERR_OTHER));
  }
}

function* resetPassword({ email, code, password }) {
  try {
    yield Auth.forgotPasswordSubmit(email, code, password);
    Analytics.trackEvent(constants.EVENT_PASSWORD_RESET, {
      status: constants.SUCCESS,
    });
    yield put(resetPasswordDone());
  } catch (err) {
    console.error(err);
    Analytics.trackEvent(constants.EVENT_PASSWORD_RESET, {
      status: constants.ERROR,
    });
    if (
      err.code === 'CodeMismatchException' ||
      err.code === 'ExpiredCodeException'
    ) {
      Logger.info(err, { email });
    } else {
      Logger.error(err, { email });
    }
    if (err.code === 'ExpiredCodeException') {
      yield put(
        setPasswordError(
          'Password reset token expired. Please request a new one.',
        ),
      );
    } else {
      yield put(
        setPasswordError(
          'There was a problem resetting your password. Please try again.',
        ),
      );
    }
  }
}

// FIXME: This is setting an auth loading state just to show the splash screen
// while doing a browser reload, on the jobs page only
// in order to get the jobs list to refresh properly due to the legacy map view
function* reloadForWorkspace() {
  const { location } = yield select((state) => state.router);
  const view = yield select(selectJobsView);
  const isJobsPage = ![
    Pathname.INSIGHTS,
    Pathname.FATIGUE_MANAGER,
    Pathname.COMMAND_CENTER,
    Pathname.SETTINGS,
  ].some((pageName) => location.pathname.includes(pageName));
  const isCommandCenter = location.pathname.includes(Pathname.COMMAND_CENTER);

  if (isJobsPage) {
    yield put(startAuth());
  }
  yield put(setJobFilters(INITIAL_FILTERS));
  yield put(setJobSort(null));
  yield put(fetchJobs(INITIAL_FILTERS, view));
  yield put(fetchFormOptions());
  if (isCommandCenter) {
    yield put(resetFetching());
  }
  if (isJobsPage) {
    window.location.replace(`/jobs`);
  }
}

function* setupAppLanguage() {
  try {
    const deviceLanguage =
      window.navigator.userLanguage || window.navigator.language;
    let deviceLanguageSplit = deviceLanguage.includes('-')
      ? deviceLanguage.substr(0, deviceLanguage.lastIndexOf('-'))
      : deviceLanguage;
    if (deviceLanguageSplit !== 'es') {
      deviceLanguageSplit = constants.Languages.english.locale;
    }

    yield put(setGlobalLanguage(deviceLanguageSplit));
  } catch (err) {
    console.warn(err);
  }
}

function* fetchUserOnFirstLoad() {
  yield fetchCurrentUser({ source: constants.SessionInitialPageLoad });
}

export default function* sessionWatcher() {
  yield fork(fetchUserOnFirstLoad);
  yield takeLatest(FETCH_CURRENT_USER, fetchCurrentUser);
  yield takeLatest(SIGN_OUT, signOut);
  yield takeLatest(SIGN_IN, signIn);
  yield takeLatest(COMPLETE_NEW_PASSWORD, completeNewPassword);
  yield takeLatest(FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(RESET_PASSWORD, resetPassword);
  yield takeLatest(WEBSOCKET_RESTART, setupSessionToken);
  yield takeLatest(TOKEN_SIGN_IN, tokenSignIn);
  yield takeLatest(REQUEST_MAGIC_LINK, requestMagicLink);
  yield takeLatest(RELOAD_FOR_WORKSPACE, reloadForWorkspace);
  yield takeLatest(INIT_GLOBAL_LANGUAGE_SETTING, setupAppLanguage);
}

import { Container, Spin } from './styled';

// Loader
export const Loader = ({
  size = 32,
  isWhite,
}: {
  size?: number;
  isWhite?: boolean;
}) => {
  return (
    <Container>
      <Spin size={size} isWhite={isWhite} />
    </Container>
  );
};

import styled from 'styled-components';
import theme from 'theme';
import { ArrayValue, StringValue } from '../styles';
import SectionField from 'model/FatigueManager/SectionField';
import { hasValue } from 'model/FatigueManager/helpers';
const { fonts, fontSizes } = theme;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 33px;
  padding-bottom: 33px;
  border-bottom: 5px solid ${theme.colors.offWhite};
  &:last-of-type {
    border: none;
  }
`;

const Label = styled.p`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
`;

const FRAList = ({ items }: { items: SectionField[] }): JSX.Element => (
  <FlexColumn>
    {items
      ?.filter((formField) => hasValue(formField))
      .map((item, index) => (
        <Item key={`${item.label}-${index}`}>
          <Label>{item.label}</Label>
          {Array.isArray(item.value) ? (
            <ArrayValue value={item.value} />
          ) : (
            <StringValue value={item.value} />
          )}
        </Item>
      ))}
  </FlexColumn>
);

export default FRAList;

import { Container } from './styled';
import { Button } from 'components/ui';
import { useDispatch } from 'react-redux';
import { setActiveWorkflowId } from 'store/slices/workflows';

export const WorkflowSettings = () => {
  const dispatch = useDispatch();
  return (
    <Container>
      <Button.Secondary
        onClick={() => dispatch(setActiveWorkflowId('1'))}
        text='edit workflow'
        width='200px'
      />
    </Container>
  );
};

import { Job } from 'api';
import * as Logger from 'utils/logger';
import { put, takeLatest } from 'redux-saga/effects';
import {
  fetchLocationsError,
  fetchLocationsSuccess,
} from 'store/actions/locations';
import { setGlobalError } from 'store/slices/notifications';
import { FETCH_LOCATIONS } from 'store/actions/actionTypes';

function* fetchLocations() {
  try {
    const { data } = yield Job.getAllLocations();
    yield put(fetchLocationsSuccess(data.results));
  } catch (err) {
    Logger.error(err);
    yield put(fetchLocationsError());
    yield put(setGlobalError(err));
  }
}

export default function* locationsWatcher() {
  yield takeLatest(FETCH_LOCATIONS, fetchLocations);
}

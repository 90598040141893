import { MessageTag } from 'components/ui/Tags/MessageTag';
import { WarningTag } from 'components/ui/Tags/WarningTag';
import { SafetyRiskTag } from 'components/ui/Tags/SafetyRiskTag';
import { MajorHazardTag } from 'components/ui/Tags/MajorHazardTag';
import { BlackWarningTag } from 'components/ui/Tags/BlackWarningTag';
import { StyleVariant } from 'model/enum/StyleVariant';
import { useText } from 'hooks';
import { useSelector } from 'react-redux';
import {
  selectUserHasPermission,
  selectWorkspaceHasSettingEnabled,
} from 'store/selectors';
import { Tooltip } from 'components/ui/Display';
import {
  SeeMoreButton,
  SeeMoreWrapper,
  Tag,
  TagWrapper,
  TagsContainer,
} from './styled';
import theme from 'theme';

const { fontSizes } = theme;

export const ActionRequiredBadge = ({ row }: { row: any }): JSX.Element => {
  const getText = useText();
  const hasUserPsiPermission = useSelector(selectUserHasPermission('psi_v1'));
  const hasJobFlagsUserPermission = useSelector(
    selectUserHasPermission('job_flags_v1'),
  );
  const hasJobFlagsWorkspaceSetting = useSelector(
    selectWorkspaceHasSettingEnabled('job_flags_v1'),
  );
  const extraBadges = hasJobFlagsUserPermission || hasJobFlagsWorkspaceSetting;

  if (!extraBadges) {
    return (
      <TagsContainer>
        {row.safetyWarning && (
          <SafetyRiskTag
            message={getText('job_overview_safety_prediction_tag_risk_score')}
            score={row.safetyWarning.score}
            hasBigIcon
            variant={StyleVariant.TOP}
            extraStyles={{ fontSize: fontSizes.s, width: '100%' }}
          />
        )}
        {row.unread_messages > 0 && (
          <MessageTag
            unreadMessages={row.unread_messages}
            extraStyles={{ width: '100%' }}
          />
        )}
        {row.pending_job_blocker_count > 0 && (
          <WarningTag
            text={getText('job_overview_pending_blocker')}
            date={row.most_recent_job_blocker_created_at}
            extraStyles={{ width: '100%' }}
          />
        )}
      </TagsContainer>
    );
  } else {
    const tagArray = [
      (row.is_job_blocked || row.pending_job_blocker_count > 0) && (
        <TagWrapper key='job_overview_pending_blocker'>
          <WarningTag
            text={getText('job_overview_pending_blocker')}
            date={row.most_recent_job_blocker_created_at}
          />
        </TagWrapper>
      ),
      row.is_major_hazards && (
        <TagWrapper key='job_overview_major_hazard_identified'>
          <MajorHazardTag
            text={getText('job_overview_major_hazard_identified')}
          />
        </TagWrapper>
      ),
      row.sign_off_pending_assessment_count > 0 && (
        <TagWrapper key='job_overview_pending_sign_off_assessment'>
          <WarningTag
            text={getText('job_overview_pending_sign_off_assessment')}
            date={row.most_recent_risk_assessment_created_at}
          />
        </TagWrapper>
      ),
      row.is_permit_pending && (
        <TagWrapper key='job_overview_permit_pending'>
          <WarningTag text={getText('job_overview_permit_pending')} />
        </TagWrapper>
      ),
      row.unread_messages > 0 && (
        <TagWrapper key='unread_messages'>
          <MessageTag unreadMessages={row.unread_messages} />
        </TagWrapper>
      ),
      row.is_low_quality_video && (
        <TagWrapper key='low_quality_video'>
          <BlackWarningTag
            text={getText('assessment_details_low_quality_video_tag')}
          />
        </TagWrapper>
      ),
      row.is_limited_hazard && hasUserPsiPermission && (
        <TagWrapper key='job_overview_limited_hazard'>
          <MajorHazardTag text={getText('job_overview_limited_hazard')} />
        </TagWrapper>
      ),
      row.is_poor_signal && (
        <TagWrapper key='job_overview_poor_signal'>
          <MajorHazardTag text={getText('job_overview_poor_signal')} />
        </TagWrapper>
      ),
      row.safetyWarning && (
        <TagWrapper key='job_overview_safety_prediction_tag_risk_score'>
          <SafetyRiskTag
            message={getText('job_overview_safety_prediction_tag_risk_score')}
            score={row.safetyWarning.score}
            hasBigIcon
            variant={StyleVariant.TOP}
          />
        </TagWrapper>
      ),
    ].filter((t) => t);

    return (
      <TagsContainer $hasTooltip>
        <Tag>{tagArray[0]}</Tag>
        {tagArray.length > 1 && (
          <Tooltip
            placement='right'
            title={<>{tagArray.slice(1)}</>}
            triggerElement={
              <SeeMoreWrapper>
                <SeeMoreButton
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                  }}
                  disableRipple
                >
                  {`+${getText('job_overview_more', {
                    number: tagArray.length - 1,
                  })}`}
                </SeeMoreButton>
              </SeeMoreWrapper>
            }
            isLight
          />
        )}
      </TagsContainer>
    );
  }
};

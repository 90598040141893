import { useEffect, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { DateRangePicker } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import * as constants from 'utils/constants';
import { useText } from 'hooks';
import { add, sub } from 'date-fns';
import { getLocale } from 'helpers/dates';
import {
  ArrowIcon,
  Container,
  DateInput,
  DateRangeInputWrapper,
  DateRangeWrapper,
} from './styled';
import { Label } from '../styled';

type Props = {
  label: string;
  extraStyles: CSSProperties;
  onChange?: () => void;
  selectedRange?: [Date | null, Date | null] | null;
  startDate?: Date | null;
  endDate?: Date | null;
  setEndDate: (value: Date | null) => void;
  setStartDate: (value: Date | null) => void;
  maxSelectionRange?: number;
  isFocused?: boolean;
};

enum DateRanges {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

// Input.DateRangeSelector
export const DateRangeSelector = ({
  label,
  extraStyles,
  onChange,
  selectedRange,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  maxSelectionRange = constants.DEFAULT_MAXIMUM_DATE_RANGE,
  isFocused = false,
}: Props): JSX.Element => {
  const getText = useText();
  const [focusedCalendar, setFocusedCalendar] = useState<
    DateRanges.START_DATE | DateRanges.END_DATE | null
  >(isFocused ? DateRanges.START_DATE : null);

  useEffect(() => {
    if (selectedRange) {
      const [start, end] = selectedRange;
      setStartDate(start ? new Date(start) : null);
      setEndDate(end ? new Date(end) : null);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onChange && (startDate || endDate)) {
      onChange();
    }
  }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const calculateMaxDate = () => {
    const now = new Date();
    if (startDate && focusedCalendar !== DateRanges.START_DATE) {
      const maxDate = add(new Date(startDate), { days: maxSelectionRange });
      return maxDate > now ? now : maxDate;
    }
    return now;
  };

  return (
    <Container style={extraStyles}>
      <Label $marginBottom='10px'>{label}</Label>
      <DateRangeWrapper>
        <DateRangePicker
          startDate={startDate ?? undefined}
          endDate={endDate ?? undefined}
          onStartDateChange={(value) => {
            setStartDate(value ?? null);
          }}
          onEndDateChange={(value) => {
            setEndDate(value ?? null);
          }}
          minimumLength={0}
          maximumLength={maxSelectionRange}
          minimumDate={
            endDate
              ? sub(new Date(endDate), { days: maxSelectionRange })
              : undefined
          }
          maximumDate={calculateMaxDate()}
          locale={getLocale()}
        >
          {({ startDateInputProps, endDateInputProps, focus }) => (
            <DateRangeInputWrapper>
              <DateInput
                {...startDateInputProps}
                onFocus={() => {
                  startDateInputProps.onFocus();
                  setFocusedCalendar(DateRanges.START_DATE);
                }}
                onBlur={() => {
                  startDateInputProps.onBlur();
                  setFocusedCalendar(null);
                }}
                placeholder={getText('date_selector_start')}
                $focused={focus === DateRanges.START_DATE}
                autoFocus={isFocused}
              />
              <ArrowIcon />
              <DateInput
                {...endDateInputProps}
                onFocus={() => {
                  endDateInputProps.onFocus();
                  setFocusedCalendar(DateRanges.END_DATE);
                }}
                onBlur={() => {
                  endDateInputProps.onBlur();
                  setFocusedCalendar(null);
                }}
                placeholder={getText('date_selector_end')}
                $focused={focus === DateRanges.END_DATE}
              />
            </DateRangeInputWrapper>
          )}
        </DateRangePicker>
      </DateRangeWrapper>
    </Container>
  );
};

import styled from 'styled-components';
import theme from 'theme';
import { Grid, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

export const { colors, fonts, fontSizes, media, mixins } = theme;

// Styled Components
export const Container = styled(Grid)`
  &&& {
    background-color: 'inherit';
    height: 100%;
    overflow: scroll;
    position: relative;
  }
`;

export const Header = styled.div`
  padding: 25px 40px;
  ${media.lg`
  padding: 25px 10px;
 `}
  ${media.sm`
   padding: 25px 15px;
 `}
`;

export const HeaderTop = styled.div`
  display: flex;
  justify-content: space-between;
  ${mixins.flexBetween};
  ${media.sm`
   ${mixins.flexColumn};
   align-items: baseline;
 `}
`;

export const JobNumber = styled.h1`
  font-family: ${fonts.bold};
  font-size: 32px;
  ${media.sm`
   display: none;
 `}
`;

export const Address = styled.h2`
  font-size: ${fontSizes.default};
  ${media.sm`
   font-family: ${fonts.bold};
   font-size: ${fontSizes.xl};
 `}
`;

export const MobileHeaderLinksContainer = styled.div`
  a {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const StatusContainer = styled.div`
  width: 150px;
  ${media.sm`
   background-color: ${colors.black};
   width: 100%;
   margin: 0;
   padding: 15px;
   box-sizing: border-box;
 `}
`;

export const MobileSection = styled.div`
  ${media.sm`
   padding: 25px 15px;
 `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const JobTimelineContainer = styled.div<{ isMobile?: boolean }>`
  margin: ${({ isMobile }) =>
    isMobile ? `25px 0px 30px 0px` : `35px 0px 45px 0px`};
`;

export const DesktopTabs = styled(MuiTabs)`
  background-color: ${colors.whiteGrey};
`;

export const Tab = styled(MuiTab)`
  text-transform: none;
  border: none;

  color: ${colors.darkGrey};
  font-family: ${fonts.bold};
  margin: 0;
  padding: 8px 16px;

  &.Mui-selected {
    color: ${colors.black};
  }
`;

export const TabPanel = styled.div`
  width: inherit;
  padding: 25px 40px;
  ${media.lg`
      padding: 25px 10px;
    `}
  ${media.sm`
    padding: 25px 40px;
   `}
`;

import {
  WEBSOCKET_INIT,
  WEBSOCKET_OPENED,
  WEBSOCKET_CLOSED,
  WEBSOCKET_ERROR_THROWN,
} from '../actions/actionTypes';

const defaultState = {
  connected: false,
  connecting: false,
  disconnected: true,
};

const websockets = (state = defaultState, action) => {
  switch (action.type) {
    case WEBSOCKET_INIT:
      return { connected: false, connecting: true, disconnected: false };
    case WEBSOCKET_OPENED:
      return { connected: true, connecting: false, disconnected: false };
    case WEBSOCKET_CLOSED:
    case WEBSOCKET_ERROR_THROWN:
      return { connected: false, connecting: false, disconnected: true };
    default:
      return state;
  }
};

export default websockets;

import { Table, TableBody, TableRow } from '@mui/material';
import { TableCell } from '../styled';
import ItemTitle from '../../ItemTitle';
import { Hazard } from 'model/Assessment/Hazard';
import { useText } from 'hooks';
import {
  ActionButtonContainer,
  ChevronIcon,
  CrossIcon,
  PencilIcon,
} from './styled';
import { Button, Input } from 'components/ui';

type Props = {
  hazard: Hazard;
  onEditClick: (hazard: Hazard) => void;
  onRemoveClick: (hazard: Hazard) => void;
  onMajorHazardChange: (hazard: Hazard, checked: boolean) => void;
  handleExpandChange: (listName: string) => void;
  isExpanded: boolean;
  loading: boolean;
};

const HazardRow = ({
  hazard,
  onEditClick,
  onRemoveClick,
  onMajorHazardChange,
  handleExpandChange,
  isExpanded,
  loading,
}: Props): JSX.Element => {
  const getText = useText();

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell width={60}>
            <ItemTitle
              item={hazard}
              infoMessage={
                hazard?.mappedControlIds?.length
                  ? ''
                  : getText('workspace_settings_hazard_control_required')
              }
            />
          </TableCell>
          <TableCell width={15}>
            <Input.Checkbox
              checked={hazard?.isMajor}
              onChange={(e) => onMajorHazardChange(hazard, e.target.checked)}
              disabled={loading}
              isSmall
            />
          </TableCell>
          <TableCell width={15}>
            <ActionButtonContainer>
              <Button.Confirm
                onClick={() => onEditClick(hazard)}
                IconComponent={PencilIcon}
              />
              <Button.Confirm
                onClick={() => onRemoveClick(hazard)}
                IconComponent={CrossIcon}
              />
            </ActionButtonContainer>
          </TableCell>
          <TableCell width={10}>
            <Button.Confirm
              onClick={() => handleExpandChange(`hazard-${hazard.id}`)}
              IconComponent={ChevronIcon}
              extraStyles={isExpanded ? { transform: 'rotate(180deg)' } : {}}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default HazardRow;

import { useSelector } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { RatingSummary } from './Summary';
import { SectionList } from './MethodStatement/SectionList';
import { RamsHazard } from './RamsHazard';
import RootState from 'model/State/RootState';
import { RamsSignOn } from './SignOn';
import { EditMembers } from './SignOn/EditMembers';
import { SectionSummary } from './MethodStatement/SectionSummary';
import { ActionSelection } from './ActionSelection';
import { RamsStages } from 'model/Assessment/RAMS';

// RamsAssessment
export const RamsAssessment = (): JSX.Element => {
  const { selectedHazards, currentStage, ratings } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );

  if (
    ratings?.length === selectedHazards?.length &&
    (currentStage === RamsStages.hazardRatingReview ||
      currentStage === RamsStages.hazardRatingSummary)
  ) {
    return <RatingSummary />;
  }

  if (currentStage === RamsStages.ramsActionSelection) {
    return <ActionSelection />;
  }

  if (currentStage === RamsStages.methodStatement) {
    return (
      <DndProvider backend={HTML5Backend}>
        <SectionList />
      </DndProvider>
    );
  }

  if (
    currentStage === RamsStages.methodStatementSummary ||
    currentStage === RamsStages.methodStatementView
  ) {
    return <SectionSummary />;
  }
  if (currentStage === RamsStages.signOn) {
    return <RamsSignOn />;
  }
  if (currentStage === RamsStages.signOnEditMembers) {
    return <EditMembers />;
  }

  return <RamsHazard />;
};

import React from 'react';
import { useText } from 'hooks';
import { formatDate } from 'helpers/dates';
import { Assessment } from 'model/Assessment';
import theme from 'theme';
import {
  Container,
  Line,
  StatusContainer,
  StatusIndicator,
  StatusTitle,
  Subtitle,
  TextContainer,
  TickContainer,
  TickIcon,
  Timeline,
} from './styled';
import { Status } from './constants';
import { RamsStages, StageOrder } from 'model/Assessment/RAMS';

type Props = {
  assessmentStage: RamsStages;
  assessment?: Assessment;
};

// AssessmentTimeline
export const AssessmentTimeline = ({
  assessmentStage,
  assessment,
}: Props): JSX.Element => {
  const getText = useText();

  const hasRamsReviews =
    !!assessment?.ramsReviews.length && assessment.ramsReviews.length > 0;
  const hasMethodStatement = !!assessment?.methodStatement;

  const isStepAchieved = (id: number): boolean => {
    if (
      id === 1 ||
      hasMethodStatement ||
      (id === 2 && !!assessment?.hazards?.length) ||
      (id === 3 && hasRamsReviews)
    ) {
      return true;
    }
    const currentStage = StageOrder.find((stage) => stage.id === id);
    return currentStage?.stages.includes(assessmentStage) ?? false;
  };

  const statuses: Status[] = [
    {
      id: 1,
      title: getText('rams_status_site_video_title'),
      subtitle: assessment?.createdAt ? formatDate(assessment.createdAt) : '',
      isAchieved: isStepAchieved(1),
    },
    {
      id: 2,
      title: getText('rams_status_risk_assessment_title'),
      subtitle: getText(
        'rams_status_risk_assessment_subtitle_hazard_identification',
      ),
      isAchieved: isStepAchieved(2),
    },
    {
      id: 3,
      title: getText('rams_status_risk_assessment_title'),
      subtitle: getText('rams_status_risk_assessment_subtitle_hazard_rating'),
      isAchieved: isStepAchieved(3),
      isReady: hasRamsReviews,
    },
    {
      id: 4,
      title: getText('rams_status_method_statement'),
      subtitle: hasMethodStatement ? getText('submitted') : '',
      isAchieved: isStepAchieved(4),
      isReady: hasMethodStatement,
    },
  ];

  const nullStatusIndex = statuses.findIndex((status) => !status.isAchieved);

  const currentStatus =
    nullStatusIndex !== -1
      ? statuses[nullStatusIndex - 1]
      : statuses[statuses.length - 1];

  const isLineActive = (isAchieved: boolean, currentIndex: number): boolean => {
    const nextIndex = statuses[currentIndex + 1];
    return isAchieved && !!nextIndex && nextIndex.isAchieved;
  };

  return (
    <Timeline>
      <Container>
        {statuses.map(({ title, subtitle, id, isAchieved, isReady }, index) => {
          const isLast = index === statuses.length - 1;
          return (
            <React.Fragment key={id}>
              <StatusIndicator
                color={isAchieved ? theme.colors.blue : theme.colors.grey}
                $isCircle={isLast && !isReady}
              >
                {isAchieved && (currentStatus?.id !== id || isReady) && (
                  <TickContainer>
                    <TickIcon />
                  </TickContainer>
                )}
                <TextContainer $isLast={isLast}>
                  <StatusContainer $isLast={isLast}>
                    <StatusTitle>{title}</StatusTitle>
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                  </StatusContainer>
                </TextContainer>
              </StatusIndicator>
              {!isLast && <Line $isActive={isLineActive(isAchieved, index)} />}
            </React.Fragment>
          );
        })}
      </Container>
    </Timeline>
  );
};

import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
import { Button, Modal } from 'components/ui';

const { colors, fonts, fontSizes } = theme;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: 20px auto;
  padding: 20px;
`;

const Message = styled.div`
  font-size: ${fontSizes.m};
  color: ${colors.black};
  font-family: ${fonts.light};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

type Props = {
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  infoMessage?: string;
};

const InfoModal = ({ isVisible, setVisible, infoMessage }: Props) => {
  const getText = useText();

  return (
    <Modal.Small isOpen={isVisible} onClose={() => setVisible(false)}>
      <Container>
        {infoMessage && <Message>{infoMessage}</Message>}
        <ButtonContainer>
          <Button.Confirm
            onClick={() => setVisible(false)}
            text={getText('ok')}
            width='30px'
          />
        </ButtonContainer>
      </Container>
    </Modal.Small>
  );
};

export default InfoModal;

import { User } from 'model/User';
import { sortDates } from './dates';
import { Assessment } from 'model/Assessment';

export const getSignedOnUsers = (assessment: Assessment | null): User[] => {
  if (!assessment) {
    return [];
  }
  const signedOn: User[] =
    assessment?.signedOnUsers.filter((user: User) => user.signedAt) ?? [];
  return signedOn.sort((a, b) => sortDates(a?.signedAt, b?.signedAt));
};

export const getNotSignedOnUsers = (assessment: Assessment | null): User[] => {
  if (!assessment) {
    return [];
  }
  const notSignedOn: User[] =
    assessment?.signedOnUsers.filter((user: User) => !user.signedAt) ?? [];
  return notSignedOn.sort((a, b) => {
    const result = a.firstName.localeCompare(b.firstName);
    return result !== 0 ? result : a.lastName.localeCompare(b.lastName);
  });
};

export const arraysHaveSameUsers = (
  array1: number[],
  array2: number[],
): boolean => {
  return (
    !!array1 &&
    !!array2 &&
    array1?.length === array2?.length &&
    array1.every((item: number) => array2.includes(item))
  );
};

import axios from 'axios';
import { formatDateForApi, getDaysBetween } from 'helpers/dates';
import { CustomFormClass } from 'model/Form';
import { INSIGHTS_TABLE_PAGE_SIZE } from 'utils/constants';
import { APIHelpers } from 'helpers';
import { getSortString } from 'helpers/apiHelpers';
import Sort from 'model/Sort';
import { InsightJobBlockerStat } from 'model/Insight/InsightJobBlockerStat';
import { mapToJobBlockerStat } from './helpers';

export const getAll = async (
  formClass: CustomFormClass,
  formId: number,
  depotId: number,
  startDate: Date,
  endDate: Date,
  excludeWeekends: boolean,
  createdByIds: string,
  page: number,
  workspaceUuid: string,
  searchParam = '',
  pageSize: number = INSIGHTS_TABLE_PAGE_SIZE,
) => {
  try {
    const { data } = await axios.get(`/form/insights/`, {
      params: {
        form_class: formClass,
        form_type_id: formId,
        page,
        page_size: pageSize,
        depot_id: depotId,
        start_date: formatDateForApi(startDate),
        end_date: formatDateForApi(endDate),
        search: searchParam,
        exclude_weekends: excludeWeekends,
        ...(createdByIds ? { created_by_ids: createdByIds } : {}),
        ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
      },
    });
    const results = data?.results?.map((result, index) => {
      const tableData = { key: index + 1 };
      Object.entries(result).forEach(([key, value]) => {
        tableData[key] = Array.isArray(value) ? value.join(', ') : value;
      });
      return tableData;
    });
    return { ...data, results };
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err?.message);
    }
  }
};

export const getCustomHavsFormInsights = async (
  formClass: CustomFormClass,
  formId: number,
  depotId: number,
  startDate: Date,
  endDate: Date,
  excludeWeekends: boolean,
  createdByIds: string,
  page: number,
  workspaceUuid: string,
  searchParam = '',
  pageSize: number = INSIGHTS_TABLE_PAGE_SIZE,
) => {
  try {
    const { data } = await axios.get(`/form/insights/havs/`, {
      params: {
        form_class: formClass,
        form_type_id: formId,
        page,
        page_size: pageSize,
        depot_id: depotId,
        start_date: formatDateForApi(startDate),
        end_date: formatDateForApi(endDate),
        search: searchParam,
        exclude_weekends: excludeWeekends,
        ...(createdByIds ? { created_by_ids: createdByIds } : {}),
        ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
      },
    });
    const results = data?.results?.map((result, index) => {
      const tableData = { key: index + 1 };
      Object.entries(result).forEach(([key, value]) => {
        tableData[key] = Array.isArray(value) ? value.join(', ') : value;
      });
      return tableData;
    });
    return { ...data, results };
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err?.message);
    }
  }
};

export const getFormDataExport = async (
  formClass: CustomFormClass,
  formId: number,
  depotId: number,
  startDate: Date,
  endDate: Date,
  workspaceUuid: string,
  excludeWeekends: boolean,
  createdByIds: string,
  searchParam: string = '',
) => {
  const params = {
    form_class: formClass,
    form_type_id: formId,
    depot_id: depotId,
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    search: searchParam,
    exclude_weekends: excludeWeekends,
    ...(createdByIds ? { created_by_ids: createdByIds } : {}),
    ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
  };

  const result = await axios.get('/form/insights/export', {
    params,
    responseType: 'blob',
  });

  return result.data;
};

export const getFormDataExportForCustomHavsForm = async (
  formClass: CustomFormClass,
  formId: number,
  depotId: number,
  startDate: Date,
  endDate: Date,
  workspaceUuid: string,
  excludeWeekends: boolean,
  createdByIds: string,
  searchParam: string = '',
) => {
  const params = {
    form_class: formClass,
    form_type_id: formId,
    depot_id: depotId,
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    search: searchParam,
    exclude_weekends: excludeWeekends,
    ...(createdByIds ? { created_by_ids: createdByIds } : {}),
    ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
  };

  const result = await axios.get('/form/insights/havs/export/', {
    params,
    responseType: 'blob',
  });

  return result.data;
};

export const getForJobId = async (jobId: string) => {
  try {
    const response = await axios.get(`/jobs/${jobId}/form/`, {});
    return response.data.results;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err?.message);
    }
  }
};

export const getEngagement = async (
  depotId: number,
  startDate: Date,
  endDate: Date,
  excludeWeekends: boolean,
  workspaceUuid: string,
) => {
  const params = {
    depot_id: depotId,
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    exclude_weekends: excludeWeekends,
    ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
  };
  const { data } = await axios.get('/jobs/engagement/', { params });
  return data;
};

export const getJobsPerDay = async (
  depotId: number,
  typeId: number,
  startDate: Date,
  endDate: Date,
  excludeWeekends: boolean,
  selectedWorkspaceUuid: string,
) => {
  const params = {
    depot_id: depotId,
    type_id: typeId,
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    exclude_weekends: excludeWeekends,
    workspace_uuid: selectedWorkspaceUuid,
  };

  const {
    data: { results },
  } = await axios.get('/jobs/jobs-per-day/', { params });

  return APIHelpers.generateInsightsData(
    results,
    endDate,
    getDaysBetween(startDate, endDate),
    false,
    excludeWeekends,
  );
};

export const getJobsPerDayStats = async (
  depotId: number,
  typeId: number,
  startDate: Date,
  endDate: Date,
  excludeWeekends: boolean,
  selectedWorkspaceUuid: string,
) => {
  const params = {
    depot_id: depotId,
    type_id: typeId,
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    exclude_weekends: excludeWeekends,
    workspace_uuid: selectedWorkspaceUuid,
  };

  const { data } = await axios.get('/jobs/jobs-per-day-stats/', { params });
  return data;
};

export const getOutputPerDay = async (
  depotId: number,
  typeId: number,
  startDate: Date,
  endDate: Date,
  excludeWeekends: boolean,
  selectedWorkspaceUuid: string,
) => {
  const params = {
    depot_id: depotId,
    type_id: typeId,
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    exclude_weekends: excludeWeekends,
    workspace_uuid: selectedWorkspaceUuid,
  };

  const {
    data: { results },
  } = await axios.get('/jobs/output-per-day/', { params });

  return APIHelpers.generateInsightsData(
    results,
    endDate,
    getDaysBetween(startDate, endDate),
    true,
    excludeWeekends,
  );
};

export const getOutputPerDayStats = async (
  depotId: number,
  typeId: number,
  startDate: Date,
  endDate: Date,
  excludeWeekends: boolean,
  selectedWorkspaceUuid: string,
) => {
  const params = {
    depot_id: depotId,
    type_id: typeId,
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    exclude_weekends: excludeWeekends,
    workspace_uuid: selectedWorkspaceUuid,
  };

  const { data } = await axios.get('/jobs/output-per-day-stats/', { params });
  return data;
};

export const getComplianceData = async (
  depotId: number,
  startDate: Date,
  endDate: Date,
  isWeekendIncluded: boolean,
  sort: Sort,
  userIds: string | null,
  searchFilter: string,
  workspaceUuid: string,
  page: number,
) => {
  const { field: sortField, order: sortDirection } = sort;

  const excludeWeekends = !isWeekendIncluded;

  const params = {
    depot_id: depotId,
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    exclude_weekends: excludeWeekends,
    order_by: getSortString(sortField, sortDirection),
    user_ids: userIds,
    user_search: searchFilter || null,
    page,
    page_size: INSIGHTS_TABLE_PAGE_SIZE,
    ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
  };

  const { data } = await axios.get('/jobs/compliance/', { params });

  return APIHelpers.generateComplianceInsightData(data);
};

export const getHavsComplianceData = async (
  depotId: number,
  startDate: Date,
  endDate: Date,
  excludeWeekends: boolean,
  sort: Sort,
  userIds: string | null,
  searchFilter: string,
  workspaceUuid: string,
  page: number,
  pageSize = INSIGHTS_TABLE_PAGE_SIZE,
) => {
  const { field: sortField, order: sortDirection } = sort;

  const params = {
    page_size: pageSize,
    depot_id: depotId,
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    exclude_weekends: excludeWeekends,
    order_by: getSortString(sortField, sortDirection),
    user_ids: userIds,
    user_search: searchFilter || null,
    page,
    ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
  };

  const { data } = await axios.get('/havs/compliance/', { params });

  return APIHelpers.generateComplianceInsightData(data);
};

export const getHavsComplianceDataExport = async (
  depotId: number,
  startDate: Date,
  endDate: Date,
  workspaceUuid: string,
  excludeWeekends: boolean,
  userIds: string | null,
  sort: Sort,
  searchFilter: string,
) => {
  const { field: sortField, order: sortDirection } = sort;

  const params = {
    depot_id: depotId,
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    exclude_weekends: excludeWeekends,
    order_by: getSortString(sortField, sortDirection),
    user_ids: userIds,
    user_search: searchFilter || null,
    ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
  };

  const result = await axios.get('/havs/compliance/export', {
    params,
    responseType: 'blob',
  });

  return result.data;
};

export const getVehicleCheckComplianceData = async (
  depotId: number,
  startDate: Date,
  endDate: Date,
  excludeWeekends: boolean,
  sort: Sort,
  userIds: string | null,
  searchFilter: string,
  workspaceUuid: string,
  page: number,
  pageSize = INSIGHTS_TABLE_PAGE_SIZE,
) => {
  const { field: sortField, order: sortDirection } = sort;

  const params = {
    page_size: pageSize,
    depot_id: depotId,
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    exclude_weekends: excludeWeekends,
    order_by: getSortString(sortField, sortDirection),
    user_ids: userIds,
    user_search: searchFilter || null,
    page,
    ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
  };

  const { data } = await axios.get('/vehicle-checks/compliance-per-vehicle/', {
    params,
  });

  return APIHelpers.generateComplianceInsightData(data);
};

export const getVehicleCheckUserComplianceData = async (
  depotId: number,
  startDate: Date,
  endDate: Date,
  excludeWeekends: boolean,
  sort: Sort,
  userIds: string | null,
  searchFilter: string,
  workspaceUuid: string,
  page: number,
  pageSize = INSIGHTS_TABLE_PAGE_SIZE,
) => {
  const { field: sortField, order: sortDirection } = sort;

  const params = {
    page_size: pageSize,
    depot_id: depotId,
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    exclude_weekends: excludeWeekends,
    order_by: getSortString(sortField, sortDirection),
    user_ids: userIds,
    user_search: searchFilter || null,
    page,
    ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
  };

  const { data } = await axios.get('/vehicle-checks/compliance-per-user/', {
    params,
  });

  return APIHelpers.generateComplianceInsightData(data);
};

export const getJobBlockerTimeLost = async (
  depotId: number,
  startDate: Date,
  endDate: Date,
  excludeWeekends: boolean,
  workspaceUuid: string,
): Promise<InsightJobBlockerStat[]> => {
  const params = {
    depot_id: depotId,
    start_date: formatDateForApi(startDate),
    end_date: formatDateForApi(endDate),
    exclude_weekends: excludeWeekends,
    ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
  };

  const { data } = await axios.get('/jobs/job-blocker-time-lost/', { params });

  return data.results.map(mapToJobBlockerStat);
};

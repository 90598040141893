import styled from 'styled-components';
import { useText } from 'hooks';
import { Navbar, Button } from 'components/ui';
import theme from 'theme';
import * as constants from 'utils/constants';
import { useSelector } from 'react-redux';
import { selectIsStreamline, selectJobsView } from 'store/selectors';
import { useParams } from 'react-router-dom';

const { fonts, fontSizes, media } = theme;

const Title = styled.h1`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  margin-bottom: 16px !important;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  ${media.sm`
    padding: 10px;
   `}
`;

// AddContent.Show
export default () => {
  const { jobId } = useParams();
  const getText = useText();
  const isStreamline = useSelector(selectIsStreamline);
  const view = useSelector(selectJobsView);

  return (
    <>
      <Navbar title='' defaultBackPath={`/jobs/${jobId}/timeline`} mobileOnly />
      <Wrapper>
        <Title>{getText('upload_type_title')}</Title>
        <Button.SecondaryLink
          event={constants.EVENT_ADD_EVIDENCE_BUTTON}
          eventProperties={{
            jobId,
            [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
          }}
          text={getText('evidence')}
          to={`/jobs/${jobId}/add/evidence`}
          extraStyles={{ marginBottom: '8px' }}
        />
        <Button.SecondaryLink
          event={constants.EVENT_ADD_DOCUMENT_BUTTON}
          eventProperties={{
            jobId,
            [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
          }}
          text={getText('document')}
          to={`/jobs/${jobId}/add/document`}
          disabled={isStreamline}
        />
      </Wrapper>
    </>
  );
};

import { TableHead, TableRow } from '@mui/material';
import { HeaderTitle, HeaderTitleWrapper, TableHeaderCell } from './styled';
import { useText } from 'hooks';
import { InfoButton } from 'components/ui/Button';

type Props = {
  openInfo: () => void;
};

const TableHeader = ({ openInfo }: Props): JSX.Element => {
  const getText = useText();

  return (
    <TableHead>
      <TableRow>
        <TableHeaderCell width={60} />
        <TableHeaderCell width={15}>
          <HeaderTitleWrapper>
            <HeaderTitle>
              {getText('workspace_settings_hazard_header_major_hazard')}
            </HeaderTitle>
            <InfoButton onClick={openInfo} />
          </HeaderTitleWrapper>
        </TableHeaderCell>
        <TableHeaderCell width={25} />
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;

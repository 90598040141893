import { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Loader } from 'components/ui';
import { InsightSummary } from './InsightSummary';
import InsightLineGraph from './InsightLineGraph';
import InsightBarContainer from './InsightBarContainer';
import { InsightSummaryTable } from './InsightSummaryTable';
import TableData from 'model/Insight/TableData';
import { getDaysBetween } from 'helpers/dates';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchJobsPerDayGraphs,
  fetchJobsPerDayStats,
  fetchOutputPerDayGraphs,
  fetchOutputPerDayStats,
} from 'store/actions/insights';
import {
  selectActiveWorkspaceUuid,
  selectIsLoadingJobsPerDayData,
  selectIsLoadingOutputPerDayData,
  selectJobsPerDayGraphsById,
  selectJobsPerDayStatsById,
  selectOutputPerDayGraphs,
  selectOutputPerDayStats,
} from 'store/selectors';
import { useText } from 'hooks';
import { mapToLogDetails } from 'api/Insights/helpers';

const { fonts, fontSizes, colors, mixins } = theme;

// Styled Components
const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 42px !important;
`;

const DataContainer = styled.div`
  padding: 40px 80px;
  background-color: ${colors.offWhite};
  min-height: 100%;
`;

const DataRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const NoTResultContainer = styled.div`
  ${mixins.flexFullCenter};
  margin-top: 40px;
`;

type Props = {
  rowTitle: string;
  depotId: number;
  depotTitle: string;
  startDate: Date;
  endDate: Date;
  isWeekendIncluded: boolean;
  typeId: number;
  isOutputPerDay?: boolean;
};

// InsightDataRow
const InsightDataRow = ({
  rowTitle,
  depotId,
  depotTitle,
  startDate,
  endDate,
  isWeekendIncluded,
  typeId,
  isOutputPerDay,
}: Props): JSX.Element => {
  const [tableData, setTableData] = useState<TableData | null>(null);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);

  const dispatch = useDispatch();
  const getText = useText();

  const isLoading = useSelector(
    isOutputPerDay
      ? selectIsLoadingOutputPerDayData
      : selectIsLoadingJobsPerDayData,
  );

  const graphData = useSelector(
    isOutputPerDay
      ? selectOutputPerDayGraphs
      : selectJobsPerDayGraphsById(typeId),
  );

  const statsData = useSelector(
    isOutputPerDay
      ? selectOutputPerDayStats
      : selectJobsPerDayStatsById(typeId),
  );

  useEffect(() => {
    setTableData(null);
    if (isOutputPerDay) {
      dispatch(
        fetchOutputPerDayGraphs(
          depotId,
          typeId,
          startDate,
          endDate,
          !isWeekendIncluded,
          selectedWorkspaceUuid,
        ),
      );
      dispatch(
        fetchOutputPerDayStats(
          depotId,
          typeId,
          startDate,
          endDate,
          !isWeekendIncluded,
          selectedWorkspaceUuid,
        ),
      );
    } else {
      dispatch(
        fetchJobsPerDayStats(
          depotId,
          typeId,
          startDate,
          endDate,
          !isWeekendIncluded,
          selectedWorkspaceUuid,
        ),
      );
      dispatch(
        fetchJobsPerDayGraphs(
          depotId,
          typeId,
          startDate,
          endDate,
          !isWeekendIncluded,
          selectedWorkspaceUuid,
        ),
      );
    }
  }, [
    dispatch,
    depotId,
    startDate,
    endDate,
    isWeekendIncluded,
    typeId,
    selectedWorkspaceUuid,
    isOutputPerDay,
  ]);

  return (
    <DataContainer>
      <H2>{rowTitle}</H2>
      {isLoading ? (
        <DataRow>
          <Loader />
        </DataRow>
      ) : statsData && graphData ? (
        <DataRow>
          <InsightSummary
            statsData={statsData}
            isOutputPerDay={isOutputPerDay}
          />
          {getDaysBetween(startDate, endDate) < 56 ? (
            <>
              <InsightBarContainer
                graphData={graphData}
                depotName={depotTitle}
                startDate={startDate}
                endDate={endDate}
                averageValue={
                  isOutputPerDay
                    ? statsData?.current_period.average_meters_per_day_region
                    : statsData?.current_period.average_jobs_per_day_region
                }
                isOutputPerDay={isOutputPerDay}
                setTableData={setTableData}
                hoverTooltipId={`hover-tooltip-${rowTitle}`}
              />
              {tableData && (
                <InsightSummaryTable
                  data={tableData.data.map(mapToLogDetails)}
                />
              )}
            </>
          ) : (
            <InsightLineGraph
              graphData={graphData}
              depotName={depotTitle}
              regionName={statsData?.region_title}
              startDate={startDate}
              endDate={endDate}
              averageValue={
                isOutputPerDay
                  ? statsData?.current_period.average_meters_per_day_region
                  : statsData?.current_period.average_jobs_per_day_region
              }
              isOutputPerDay={isOutputPerDay}
            />
          )}
        </DataRow>
      ) : (
        <NoTResultContainer>
          {getText('filter_data_no_results')}
        </NoTResultContainer>
      )}
    </DataContainer>
  );
};

export default InsightDataRow;

import { useJobs, useText } from 'hooks';
import { Modal } from 'components/ui';
import { Container } from './styled';
import { SiteBriefingShow } from './Show';

export const SiteBriefingModal = () => {
  const getText = useText();
  const { navigateToOverviewTabOrJob } = useJobs();

  return (
    <Modal.Base
      isOpen
      title={getText('job_details_timeline_action_site_briefing')}
      onClose={() => navigateToOverviewTabOrJob()}
      width='32vw'
      largeScreenWidth='26vw'
    >
      <Container>
        <SiteBriefingShow />
      </Container>
    </Modal.Base>
  );
};

import {
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_ERROR,
  FETCH_LOCATIONS_SUCCESS,
} from 'store/actions/actionTypes';

const defaultState = {
  locations: [],
  isFetchingLocations: false,
};

const locations = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_LOCATIONS:
      return {
        ...state,
        isFetchingLocations: true,
      };
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.data,
        isFetchingLocations: false,
      };
    case FETCH_LOCATIONS_ERROR:
      return {
        ...state,
        isFetchingLocations: false,
      };
    default:
      return state;
  }
};

export default locations;

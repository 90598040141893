import { Depot } from 'model/Job/Depot';
import { Reaction } from 'model/Reaction';
import { Assessment } from 'model/Assessment';
import { Evidence } from 'model/Evidence/Evidence';

export type CommandCenterActivity = {
  id: string;
  type: ActivityType;
  jobId: number;
  target: Assessment | Evidence;
  createdAt: Date;
  updatedAt: Date | null;
  read: boolean;
  userReaction: Reaction | null;
  reactions: Reaction[];
  depot: Depot | undefined;
};

export enum ActivityType {
  ASSESSMENT = 'assessment',
  EVIDENCE = 'evidence',
}

import React from 'react';
import { useSelector } from 'react-redux';
import theme from 'theme';
import { Names } from 'helpers';
import {
  selectWorkspaceSettings,
  selectGlobalLanguageSetting,
  selectCurrentUserId,
} from 'store/selectors';
import { UserRole } from 'model/enum/UserRole';
import { getLabelByLocale } from 'helpers/utils';
import {
  Avatar,
  Container,
  CrossIcon,
  DeleteIcon,
  FlexColumn,
  Name,
  Title,
} from './styled';
import { User as UserModel } from 'model/User';

const { colors } = theme;

type Props = {
  user: UserModel;
  title?: string;
  vertical?: boolean;
  large?: boolean;
  showRole?: boolean;
  onDelete?: (() => void) | null;
  extraStyles?: React.CSSProperties;
  extraTextStyles?: React.CSSProperties;
};

// TODO: Refactor the user badges in separate PR!!

// Badge.User
export const User = ({
  user,
  title,
  vertical = false,
  large = false,
  showRole = true,
  onDelete,
  extraStyles,
  extraTextStyles,
}: Props): JSX.Element => {
  const {
    fieldworker_label,
    fieldworker_label_es,
    manager_label,
    manager_label_es,
    backoffice_label,
    backoffice_label_es,
  } = useSelector(selectWorkspaceSettings);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const currentUserId = useSelector(selectCurrentUserId);

  const getTitleForRole = () => {
    if (user.role === UserRole.MANAGER) {
      return getLabelByLocale(globalAppLanguage, {
        manager_label,
        manager_label_es,
      });
    }
    if (user.role === UserRole.BACKOFFICE_ADMIN) {
      return getLabelByLocale(globalAppLanguage, {
        backoffice_label,
        backoffice_label_es,
      });
    }
    return getLabelByLocale(globalAppLanguage, {
      fieldworker_label,
      fieldworker_label_es,
    });
  };

  const getAvatarBackgroundColor = () => {
    if (user.id === currentUserId) {
      return colors.darkGrey;
    }
    if (user.role === UserRole.MANAGER) {
      return colors.black;
    }
    if (user.role === UserRole.BACKOFFICE_ADMIN) {
      return colors.orange;
    }
    return colors.blue;
  };

  return (
    <Container $vertical={vertical} style={extraStyles}>
      <Avatar
        $backgroundColor={getAvatarBackgroundColor()}
        $vertical={vertical}
        $large={large}
      >
        {Names.getInitials(user.firstName, user.lastName)}

        {onDelete && (
          <DeleteIcon $large={large} onClick={onDelete}>
            <CrossIcon $large={large} />
          </DeleteIcon>
        )}
      </Avatar>
      <FlexColumn style={extraTextStyles}>
        <Name $large={large} $vertical={vertical}>
          {user.fullName}
        </Name>
        {showRole && (
          <Title $vertical={vertical}>{title ?? getTitleForRole()}</Title>
        )}
      </FlexColumn>
    </Container>
  );
};

import { useEffect } from 'react';
import styled from 'styled-components';
import {
  useLocation,
  useParams,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useCommandCenter, useJobs, useText } from 'hooks';
import { Loader, Media, Modal } from 'components/ui';
import { clearAssessment, fetchAssessment } from 'store/actions/assessments';
import { HazardsControlsComments } from './HazardsControlsComments';
import SuggestHazard from './SuggestHazard';
import SuggestControl from './SuggestControl';
import RootState from 'model/State/RootState';
import DigitalSignature from './DigitalSignature';
import { ModalTitle } from 'components/ui/Modal';
import { selectJobsView } from 'store/selectors';
import { UploadStatus } from 'model/enum/UploadStatus';
import { VideoNotUploaded } from './components/VideoNotUploaded';

const Container = styled.div`
  height: calc(80vh + 65px);
  display: flex;
`;

const RightContainer = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
`;

export default () => {
  const navigate = useNavigate();
  const { jobId, assessmentId } = useParams();
  const dispatch = useDispatch();
  const getText = useText();
  const { navigateToOverviewTabOrJob } = useJobs();
  const { isCommandCenter } = useCommandCenter();

  const assessment = useSelector(
    (state: RootState) => state.assessments.activeAssessment,
  );
  const { isActiveAssessmentLoaded: isLoaded } = useSelector(
    (state: RootState) => state.assessments,
  );
  const view = useSelector(selectJobsView);
  const videoUrl = assessment?.signedUrl;

  const { currentUser, isProjectEngineer } = useSelector(
    (state: RootState) => state.session,
  );

  const projectEngineerAssessment =
    (isProjectEngineer &&
      currentUser &&
      assessment &&
      assessment.createdBy.id === currentUser.id) ??
    false;

  const isNotUploaded = assessment?.video?.status === UploadStatus.NEW;

  const { pathname } = useLocation();

  const isSubScreen =
    pathname.includes('hazard') ||
    pathname.includes('control') ||
    pathname.includes('signature');

  const goBack = () => navigate('.');

  const close = () => {
    if (isCommandCenter) {
      navigate('..');
    } else {
      navigateToOverviewTabOrJob(`/jobs/${jobId}/assessments`);
    }
  };

  useEffect((): (() => void) => {
    if (!assessment) {
      dispatch(fetchAssessment(assessmentId));
    }
    return () => dispatch(clearAssessment());
  }, [assessmentId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (assessmentId && jobId && videoUrl) {
      Analytics.trackPage(constants.EVENT_PAGE_VIEW_RISK_ASSESSMENT, {
        jobId,
        assessmentId,
        mediaPath: videoUrl,
        isLowQuality: !!assessment?.video?.qualityWarning,
        view,
      });
    }
  }, [assessmentId, jobId, videoUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const getTitle = () => {
    let title = getText('job_details_assessment_title');
    if (pathname.includes('hazard')) {
      title = getText(
        projectEngineerAssessment
          ? 'assessment_details_edit_hazards'
          : 'assessment_details_suggest_hazard',
      );
    }
    if (pathname.includes('control')) {
      title = getText(
        projectEngineerAssessment
          ? 'assessment_details_edit_controls'
          : 'assessment_details_suggest_control',
      );
    }
    return title;
  };

  const modalTitle = (
    <ModalTitle
      title={getTitle()}
      onBack={(isSubScreen && goBack) || null}
      onClose={close}
    />
  );

  return (
    <Modal.Base isOpen onClose={close} width='80vw'>
      <Container>
        {!isLoaded && !assessment ? (
          <Loader />
        ) : (
          <>
            {isNotUploaded ? (
              <VideoNotUploaded width='55%' />
            ) : (
              <Media
                kind='assessment'
                type='video'
                url={videoUrl}
                width='55%'
                item={assessment?.video}
              />
            )}
            <Routes>
              <Route
                path='/hazards'
                element={
                  <RightContainer width='45%'>
                    {modalTitle}
                    <SuggestHazard
                      assessment={assessment}
                      projectEngineerAssessment={projectEngineerAssessment}
                    />
                  </RightContainer>
                }
              />
              <Route
                path='/controls'
                element={
                  <RightContainer width='45%'>
                    {modalTitle}
                    <SuggestControl
                      assessment={assessment}
                      projectEngineerAssessment={projectEngineerAssessment}
                    />
                  </RightContainer>
                }
              />
              <Route
                path='/hazards-with-controls/:hazardId/controls'
                element={
                  <RightContainer width='45%'>
                    {modalTitle}
                    <SuggestControl
                      assessment={assessment}
                      projectEngineerAssessment={projectEngineerAssessment}
                    />
                  </RightContainer>
                }
              />
              <Route
                path='/signature/:userId'
                element={
                  <RightContainer width='45%'>
                    {modalTitle}
                    <DigitalSignature />
                  </RightContainer>
                }
              />
              <Route
                index
                element={
                  <RightContainer width='45%'>
                    {assessment && (
                      <>
                        {modalTitle}
                        <HazardsControlsComments assessment={assessment} />
                      </>
                    )}
                  </RightContainer>
                }
              />
            </Routes>
          </>
        )}
      </Container>
    </Modal.Base>
  );
};

import { Dates } from 'helpers';
import { useText } from 'hooks';
import { Message } from 'model/Message';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { useSelector } from 'react-redux';
import * as Analytics from 'utils/analytics';

import { EventContainer, P } from './styled';

type Props = {
  message: Message;
};

const getMessageTargetText = (message: Message, globalAppLanguage: string) =>
  message.target[`text_value_${globalAppLanguage}`] ||
  message.target.text_value;

// Message.Event
export const Event = Analytics.withTrackEvent(
  ({ message }: Props): JSX.Element => {
    const getText = useText();
    const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

    return (
      <EventContainer>
        <P>{`${getMessageTargetText(
          message,
          globalAppLanguage,
        )} ${Dates.formatDateTime(
          message.target.created_at,
          getText('date_today'),
          getText('date_yesterday'),
        )}`}</P>
      </EventContainer>
    );
  },
);

import {
  SET_FEATURES_GUIDE_CURRENT_STEP,
  SET_FEATURES_GUIDE_OPEN_STATUS,
} from './actionTypes';

export const setFeaturesGuideStatus = (status) => ({
  type: SET_FEATURES_GUIDE_OPEN_STATUS,
  status,
});

export const setFeaturesGuideCurrentStep = (currentStep) => ({
  type: SET_FEATURES_GUIDE_CURRENT_STEP,
  currentStep,
});

import { Arrow } from 'assets/icons';
import { useText } from 'hooks';
import RouterProps from 'model/RouterProps';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import {
  EVENT_BACK_TO_TIMELINE,
  EVENT_JOB_DETAILS_GO_BACK_TO_OVERVIEW_CLICKED,
  EVENT_PROPERTIES_PAGE_SOURCE_FIELD,
} from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { useSelector } from 'react-redux';
import { selectJobsView, selectPreviousTimelinePath } from 'store/selectors';
import { useDispatch } from 'react-redux';
import { setPreviousTimelinePath } from 'store/actions/jobs';

const { colors, fonts, fontSizes } = theme;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 25px;
  align-items: center;
  background-color: ${colors.black};
  padding: 15px;
`;

const IconWrapper = styled.div`
  z-index: 1;
  width: 35px;
  height: 25px;
  cursor: pointer;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const BackArrow = styled(Arrow)`
  height: 30px;
  width: 30px;
  .fill-selector {
    fill: ${colors.yellow};
  }
  transform: rotate(180deg);
  cursor: pointer;
`;

const Title = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  color: ${colors.yellow};
`;

const DrawerBackNavigation = (): JSX.Element => {
  const getText = useText();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobId }: RouterProps = useParams();
  const view = useSelector(selectJobsView);
  const previousTimelinePath = useSelector(selectPreviousTimelinePath(jobId));

  const handleGoBack = () => {
    if (previousTimelinePath) {
      dispatch(setPreviousTimelinePath(jobId, null));
      Analytics.trackEvent(EVENT_BACK_TO_TIMELINE, {
        jobId,
        [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      });
      navigate(previousTimelinePath);
    } else {
      navigate(`/jobs/${jobId}`);
      Analytics.trackEvent(EVENT_JOB_DETAILS_GO_BACK_TO_OVERVIEW_CLICKED, {
        jobId,
        [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      });
    }
  };

  return (
    <Container>
      <IconWrapper onClick={handleGoBack}>
        <BackArrow />
      </IconWrapper>
      <Title>
        {previousTimelinePath
          ? getText('tabs_timeline')
          : getText('tabs_job_overview')}
      </Title>
    </Container>
  );
};

export default DrawerBackNavigation;

import styled from 'styled-components';
import theme from 'theme';

const { fonts, fontSizes, colors } = theme;

export const HazardTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

export const HazardCount = styled.div`
  font-family: ${fonts.bold};
  color: ${colors.darkGrey};
  font-size: ${fontSizes.l};
  padding-left: 10px;
  white-space: nowrap;
`;

export const CollapseContainer = styled.div`
  padding: 4px 0px;
`;

export const SliderWrapper = styled.div`
  padding: 0px 5px;
`;

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIsMobile, useQueryParams, useText } from 'hooks';
import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting, selectJobsView } from 'store/selectors';
import { Loader, Card, Message, Navbar } from 'components/ui';
import { SkeletonCardContainer } from 'components/ui/Shared/EmptyState';
import { Log as LogAPI } from 'api';
import theme from 'theme';
import * as constants from 'utils/constants';
import { Warning, YellowCheckMark } from 'assets/icons';
import { useParams } from 'react-router-dom';
import { Log } from 'model/Log/Log';

const { media, colors, fonts } = theme;

// Styled Components
const Container = styled.div`
  height: 100%;
  ${media.sm`
    padding: 25px 15px;
    overflow: scroll;
  `}
`;

const Wrapper = styled.div`
  margin-bottom: 8px;
`;

const StatusContainer = styled.div<{
  $signed: boolean;
  $notSignedColor: string;
}>`
  display: flex;
  align-items: center;
  background-color: ${({ $signed, $notSignedColor }) =>
    $signed ? colors.black : $notSignedColor};
  padding: 5px 17px;
  font-family: ${fonts.bold};
`;

const Status = styled.span<{ $textColor?: string }>`
  color: ${({ $textColor }) => $textColor || colors.white};
  margin-left: 10px;
`;

const SmallWarningIcon = styled(Warning)`
  height: 20px;
  width: 20px;
`;

// JobBlocker.List
const List = () => {
  const { jobId } = useParams();
  const isMobile = useIsMobile();
  const getText = useText();
  const { getPathWithQueryParams } = useQueryParams();
  const [jobBlockers, setJobBlockers] = useState<Log[]>([]);
  const [jobBlockersLoaded, setJobBlockersLoaded] = useState<boolean>(false);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const view = useSelector(selectJobsView);

  useEffect(() => {
    (async () => {
      try {
        if (jobId) {
          const data = await LogAPI.getAllJobBlockers(jobId);
          setJobBlockers(data);
          setJobBlockersLoaded(true);
        }
      } catch (err) {
        console.error((err as Error)?.message);
      }
    })();
  }, [jobId, globalAppLanguage]);

  const buildProps = (log) => ({
    event: constants.EVENT_VIEW_JOB_BLOCKER_ITEM,
    eventProperties: {
      jobId,
      logId: log.id,
      jobBlockerCategory: log.jobBlocker.title,
      logType: 'Job Blocker',
      source: 'job-overview',
      view,
    },
    key: `job-blocker-${log.id}`,
    to: getPathWithQueryParams(`/jobs/${jobId}/blockers/${log.id}`),
    resolutionRequired: log.resolutionRequired,
    jobBlockerResolved: !!log.jobBlockerResolution,
  });

  return (
    <>
      <Navbar
        title={getText('job_overview_job_blockers')}
        defaultBackPath={`/jobs/${jobId}`}
        mobileOnly
      />

      <Container>
        {!jobBlockersLoaded && <Loader />}
        {jobBlockersLoaded && !jobBlockers?.length && (
          <SkeletonCardContainer>
            {getText('job_overview_job_blockers_no_information')}
          </SkeletonCardContainer>
        )}
        {jobBlockers?.map((log) =>
          isMobile ? (
            <Message.Action
              type={getText('job_details_timeline_action_job_blocker')}
              subtype={log?.jobBlocker?.title}
              sender={log.createdBy}
              date={log.createdAt}
              {...buildProps(log)}
            />
          ) : (
            <Wrapper key={`job-blocker-${log.id}`}>
              <Card.Log
                type={log.type}
                subtype={log.jobBlocker?.title}
                creator={log.createdBy}
                date={log.createdAt}
                customMargin='10px 0 0'
                {...buildProps(log)}
              />
              {log.resolutionRequired && (
                <StatusContainer
                  $notSignedColor={colors.orange}
                  $signed={!!log.jobBlockerResolution}
                >
                  {log.jobBlockerResolution ? (
                    <>
                      <YellowCheckMark />
                      <Status>
                        {getText('job_overview_job_blocker_resolved')}
                      </Status>
                    </>
                  ) : (
                    <>
                      <SmallWarningIcon />
                      <Status $textColor={colors.black}>
                        {getText(
                          'job_overview_job_blocker_requires_resolution',
                        )}
                      </Status>
                    </>
                  )}
                </StatusContainer>
              )}
            </Wrapper>
          ),
        )}
      </Container>
    </>
  );
};

export default List;

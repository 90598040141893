import {
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_ERROR,
  FETCH_LOCATIONS_SUCCESS,
} from './actionTypes';

export const fetchLocations = () => ({
  type: FETCH_LOCATIONS,
});

export const fetchLocationsSuccess = (data) => ({
  type: FETCH_LOCATIONS_SUCCESS,
  data,
});

export const fetchLocationsError = () => ({
  type: FETCH_LOCATIONS_ERROR,
});

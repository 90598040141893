import { Misc } from 'components/ui';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const Container = styled.div`
  height: 100%;
  ${media.sm`
    padding: 24px 16px;
    overflow: scroll;
  `}
`;

export const P = styled.p`
  flex: 1;
`;

export const Section = styled.div`
  padding: 16px 0 32px 0;
  &:last-of-type {
    padding-bottom: 0;
  }
  ${media.sm`
    padding: 16px 0;
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
  ${media.sm`
    margin-bottom: 16px !important;
  `}
`;

export const Title = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  ${media.sm`
    font-size: ${fontSizes.xxl};
  `}
`;

export const IconBox = styled.div`
  display: none;
  ${media.sm`
    ${mixins.flexFullCenter};  
    border: 4px solid ${colors.black};
    padding: 6px;
  `}
`;

export const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: 25px;
  width: 25px;
`;

export const Item = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const BulletPoint = styled.div`
  height: 8px;
  width: 8px;
  margin-right: 15px;
  background-color: ${colors.black};
`;

export const Separator = styled(Misc.Separator)`
  display: none;
  ${media.sm`
    display: block;
    margin-bottom: 25px;
  `}
`;

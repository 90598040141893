import { Button } from '@mui/material';
import {
  Arrow,
  DownloadNoBackground,
  Eye,
  ThumbnailPlaceholder,
} from 'assets/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const FlexCenterContainer = styled.div`
  ${mixins.flexCenter};
`;

export const FlexBetweenContainer = styled.div`
  ${mixins.flexBetween};
`;

export const CardContainer = styled(
  ({ customMargin = '', isLocked = false, startAligned = false, ...rest }) => (
    <Link to={rest.to} {...rest} />
  ),
)`
  text-decoration: none;
  ${mixins.flexBetween};
  ${({ startAligned }) => startAligned && `align-items: start`};
  background-color: ${colors.white};
  border: 3px solid ${colors.grey};
  padding: 16px 20px;
  ${({ customMargin }) => customMargin && `margin: ${customMargin}`};
  margin-top: 24px;
  cursor: pointer;
  pointer-events: ${({ isLocked }) => (isLocked ? 'none' : 'auto')};
  &:hover {
    border: 3px solid ${colors.black};
  }
  &:active {
    border: 3px solid ${colors.orange};
  }
  ${media.lg`
    padding: 16px 10px;
  `}
  ${media.sm`
    padding: 16px 20px;
  `}
`;

export const CardDocumentContainer = styled.a<{
  $customMargin?: string;
  $startAligned?: boolean;
}>`
  text-decoration: none;
  ${mixins.flexBetween};
  ${({ $startAligned }) => $startAligned && `align-items: start`};
  background-color: ${colors.white};
  border: 3px solid ${colors.grey};
  padding: 16px 20px;
  margin: ${({ $customMargin }) => $customMargin || '10px 0'};
  cursor: pointer;
  &:hover {
    border: 3px solid ${colors.black};
  }
  &:active {
    border: 3px solid ${colors.orange};
  }
  ${media.lg`
    padding: 16px 10px;
  `}
  ${media.sm`
    padding: 16px 20px;
  `}
`;

export const EvidenceCardContainer = styled(Link)`
  text-decoration: none;
  display: flex;
  min-height: 150px;
  border: 3px solid ${colors.grey};
  margin: 10px 0;
  cursor: pointer;
  &:hover {
    border: 3px solid ${colors.black};
  }
  &:active {
    border: 3px solid ${colors.orange};
  }
`;

export const ThumbnailContainer = styled.div`
  width: 150px;
  background-color: ${colors.black};
`;

export const Placeholder = styled(ThumbnailPlaceholder)`
  height: 100%;
  width: 100%;
`;

export const InfoContainer = styled.div`
  flex: 1;
  padding: 16px 20px;
  ${mixins.flexColumn};
`;

export const MiddleSection = styled.div`
  flex: 1;
  padding: 10px 0;
`;

export const IconWrapper = styled.div<{ $width: number }>`
  width: ${({ $width }) => $width}%;
  display: flex;
  justify-content: end;
`;

export const Icon = styled(
  ({
    component,
    width = undefined,
    $marginRight = 0,
    ...props
  }: {
    component: JSX.Element;
    width?: number;
    $marginRight?: number;
  }) => React.cloneElement(component, props),
)`
  height: 30px;
  ${({ width }) => width && `width: ${width}px`};
  ${({ $marginRight }) => $marginRight && `margin-right: ${$marginRight}px`};
  ${media.lg`
    margin-right: 10px;
  `}
  ${media.sm`
    margin-right: 20px;
  `}
`;

export const DetailWrapper = styled.div`
  width: 90%;
  padding-left: 10px;
  box-sizing: border-box;
`;

export const TopSectionContainer = styled.div`
  ${mixins.flexBetween};
  align-items: start;
`;

export const TitleWrapper = styled.div`
  width: 60%;
`;

export const Title = styled.div<{ $inline?: boolean }>`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  color: ${colors.black};
  ${({ $inline }) => ($inline ? `margin-left: 10px` : `padding-bottom: 10px`)};
`;

export const Subtitle = styled.div`
  font-family: ${fonts.light};
  color: ${colors.darkGrey};
  padding-bottom: 10px;
`;

export const Type = styled.h4`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
`;

export const BoldSubtype = styled.h4`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  ${media.lg`
    font-size: ${fontSizes.default};
  `}
  ${media.sm`
    font-size: ${fontSizes.m};
  `}
`;

export const DocumentType = styled.h4`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
  ${media.xl`
  max-width: 130px;
`}
  ${media.sm`
  max-width: 280px;
`}
`;

export const Subtype = styled.p`
  font-size: ${fontSizes.s};
  color: ${colors.darkGrey};
`;

export const CreationWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Creator = styled.p`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
  ${media.lg`
    font-size: ${fontSizes.s};
  `}
  ${media.sm`
    font-size: ${fontSizes.default};
  `}
`;

export const CreationDate = styled.p<{
  $marginLeft?: number;
  $marginRight?: number;
}>`
  font-size: ${fontSizes.s};
  color: ${colors.darkGrey};
  ${({ $marginLeft }) =>
    $marginLeft && `margin-left: ${$marginLeft}px !important`};
  ${({ $marginRight }) =>
    $marginRight && `margin-right: ${$marginRight}px !important`};
  ${media.lg`
    margin: 0 !important;
  `}
  ${media.sm`
    margin-right: 25px !important;
    margin-left: 50px !important;
  `}
`;

export const IconButton = styled(Button)`
  &.MuiButtonBase-root {
    min-width: 0;
    width: 40px;
    height: 40px;
    border-radius: 0;
    &:hover {
      background-color: ${colors.lightGrey};
    }
  }
`;

export const EyeIcon = styled(Eye)`
  height: 16px;
  width: 27px;
`;

export const DownloadIcon = styled(DownloadNoBackground)`
  height: 24px;
  width: 24px;
`;

export const Card = styled.div`
  width: 100%;
  background-color: ${colors.red};
  margin-top: 16px;
`;

export const Message = styled.p`
  color: ${colors.white};
  white-space: pre-wrap;
  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MainInfo = styled.div`
  ${mixins.flexColumn};
  width: 100%;
`;

export const JobTitle = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
`;

export const JobSubTitle = styled.h3`
  font-family: ${fonts.light};
  font-size: ${fontSizes.l};
  padding-bottom: 12px;
  word-break: break-word;
`;

export const JobDataContainer = styled.div`
  display: flex;
  margin-top: 1px !important;
  padding: 2px 0;
`;

export const JobDataTitle = styled.h4<{ $width?: number }>`
  width: ${({ $width }) => $width};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.s};
  padding-right: 3px;
  float: left;
`;

export const TypeContainer = styled.div`
  width: 100%;
`;

export const JobDataContent = styled.h4`
  font-family: ${fonts.light};
  font-size: ${fontSizes.s};
`;

export const RedArrowRight = styled(Arrow)`
  height: 36px;
  width: 36px;
  margin-top: 4px;
  .fill-selector {
    fill: ${colors.red};
  }
`;

export const ArrowRight = styled(({ isActive, ...rest }) => (
  <Arrow {...rest} />
))`
  height: 36px;
  width: 36px;
  margin-top: 4px;
  .fill-selector {
    fill: ${({ isActive }) => isActive && colors.white};
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 10px;
`;

export const CardWithWarningContainer = styled.div<{
  $hasBorder: boolean;
  $hasRedBorder: boolean;
}>`
  margin: 8px 0;
  ${({ $hasBorder, $hasRedBorder }) =>
    $hasBorder &&
    `border: 2px solid ${$hasRedBorder ? colors.red : colors.black};`}
`;

export const WarningCardContainer = styled.div<{
  $isActive: boolean;
  $hasBorder: boolean;
  $hasWarningOnTop: boolean;
}>`
  background-color: ${({ $isActive }) =>
    $isActive ? colors.black : colors.white};
  padding: 16px;
  cursor: pointer;
  h3 {
    color: ${({ $isActive }) =>
      $isActive ? colors.white : colors.black} !important;
  }
  h4 {
    color: ${({ $isActive }) =>
      $isActive ? colors.white : colors.darkGrey} !important;
  }

  p {
    color: ${({ $isActive }) =>
      $isActive ? colors.grey : colors.darkGrey} !important;
  }
  ${({ $hasBorder }) => $hasBorder && `border: 2px solid ${colors.black};`}
  ${({ $hasWarningOnTop }) => $hasWarningOnTop && ' border-top: 0;'}
`;

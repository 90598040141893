import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Input, Map } from 'components/ui';
import { useIsMobile, useText } from 'hooks';
import { selectJobsView, selectWorkspaceSettings } from 'store/selectors';
import Location from 'model/Location';
import JobType from 'model/JobType';
import { Depot } from 'model/Job/Depot';
import Coordinate from 'model/Coordinate';
import { JobField } from 'model/enum/JobField';
import { SelectChangeEvent } from '@mui/material';
import { useCustomLocaleText } from 'hooks/useText';
import { PageSource } from 'components/ui/Map/constants';
import { getLegacyMapMarkerIcon, getMapMarkerIcon } from 'helpers/map';
import { LEGACY_VIEW } from 'utils/constants';
import { PinLocationHint } from '../Edit/PinLocationHint';
import { BlankForm, CustomFields, FormErrors, FormProps } from './constants';
import { ErrorMessage, InputsContainer } from './styled';

type ReadOnlyFormProps = {
  isReadOnly: true;
  form?: never;
  setForm?: never;
  errors?: never;
  setErrors?: never;
  locations?: never;
  types?: never;
  subtypes?: never;
  depots?: never;
  selectedTypeTitle?: never;
  setSelectedTypeTitle?: never;
  selectedSubtypeTitle?: never;
  setSelectedSubtypeTitle?: never;
  coordinates?: never;
  customFields: CustomFields;
  language: string;
};

type EditableFormProps = {
  isReadOnly?: never;
  form: FormProps;
  setForm: Dispatch<SetStateAction<FormProps>>;
  errors: FormErrors;
  setErrors: Dispatch<SetStateAction<FormErrors>>;
  locations: Location[];
  types: JobType[];
  subtypes: JobType[];
  depots: Depot[];
  selectedTypeTitle: string;
  setSelectedTypeTitle: Dispatch<SetStateAction<string>>;
  selectedSubtypeTitle: string;
  setSelectedSubtypeTitle: Dispatch<SetStateAction<string>>;
  coordinates: Coordinate | null;
  customFields: CustomFields;
  language?: never;
};

type Props = EditableFormProps | ReadOnlyFormProps;

// CreateJobFormFields
export const CreateJobFormFields = ({
  isReadOnly,
  form = BlankForm,
  setForm,
  errors,
  setErrors,
  locations,
  types,
  subtypes,
  depots,
  selectedTypeTitle,
  setSelectedTypeTitle,
  selectedSubtypeTitle,
  setSelectedSubtypeTitle,
  coordinates,
  customFields,
  language,
}: Props): JSX.Element => {
  const { locations_enable, enable_auto_work_order_number } = useSelector(
    selectWorkspaceSettings,
  );
  const view = useSelector(selectJobsView);

  const isMobile = useIsMobile();
  const getCustomLanguageText = useCustomLocaleText(language);
  const getGlobalLanguageText = useText();
  const getText = language ? getCustomLanguageText : getGlobalLanguageText;

  const otherType = getText('other');

  const handleTypeChange = isReadOnly
    ? () => {}
    : (e) => {
        setForm({
          ...form,
          type: e?.target?.value,
          subtype:
            (
              subtypes.find(
                (subtype) => subtype.type_id === e?.target?.value,
              ) ?? {}
            )?.id ?? null,
        });
        setSelectedTypeTitle(
          types?.find((type) => type.id === e?.target?.value)?.title ?? '',
        );
        setSelectedSubtypeTitle(
          subtypes?.find((subtype) => subtype.type_id === e?.target?.value)
            ?.title ?? '',
        );
        if (Object.prototype.hasOwnProperty.call(errors, 'type')) {
          setErrors((prevState) => ({ ...prevState, type: false }));
        }
      };

  const handleFieldChange = isReadOnly
    ? () => {}
    : (
        fieldName: string,
        userInput:
          | string
          | ChangeEvent<HTMLInputElement>
          | SelectChangeEvent<unknown>
          | google.maps.LatLng,
      ) => {
        let newValue;

        const isMapCoordinate = (obj: any): obj is google.maps.LatLng => {
          return obj.lat instanceof Function && obj.lng instanceof Function;
        };

        if (isMapCoordinate(userInput)) {
          newValue = {
            latitude: userInput.lat().toFixed(6),
            longitude: userInput.lng().toFixed(6),
          };
        } else {
          newValue =
            typeof userInput === 'string'
              ? userInput
              : userInput?.target?.value;
        }

        setForm((prevState) => ({ ...prevState, [fieldName]: newValue }));
        if (Object.prototype.hasOwnProperty.call(errors, fieldName)) {
          setErrors((prevState) => ({ ...prevState, [fieldName]: false }));
        }
      };

  const getSelectedLocation = () => {
    const selectedLocation = locations?.find(
      (location) => location.id === form.predefined_location,
    );
    return selectedLocation
      ? { id: selectedLocation.id, title: selectedLocation.name }
      : null;
  };

  const displaySubtypeSelect = isReadOnly
    ? customFields.jobSubtypeLabel
    : form.type &&
      subtypes &&
      subtypes?.filter((subtype) => subtype?.type_id === form.type)?.length >
        0 &&
      selectedTypeTitle !== otherType &&
      customFields.jobSubtypeLabel;

  return (
    <InputsContainer>
      {locations_enable && (
        <>
          <Map
            id='job-creation'
            coordinates={coordinates ? [coordinates] : []}
            getMapMarker={
              view === LEGACY_VIEW ? getLegacyMapMarkerIcon : getMapMarkerIcon
            }
            height='250px'
            isJobAddition
            isSatellite
            isDisabled={isReadOnly}
            pageSource={PageSource.CREATE_JOB}
          />

          <Input.Select
            id={JobField.LOCATION}
            label={getText('create_job_location_title')}
            selected={getSelectedLocation()}
            options={locations?.map((location) => ({
              id: location.id,
              title: location.name,
            }))}
            onChange={(e) => handleFieldChange('predefined_location', e)}
            extraStyles={{ margin: '15px 0' }}
            large
            required
            disabled={isReadOnly}
          />
          {errors?.location && (
            <ErrorMessage>{getText('create_job_required_field')}</ErrorMessage>
          )}
        </>
      )}
      {customFields.pinLocationEnabled && (
        <>
          <Map
            id='job-creation-location-search'
            locationCoordinates={coordinates}
            getMapMarker={
              view === LEGACY_VIEW ? getLegacyMapMarkerIcon : getMapMarkerIcon
            }
            height={isMobile ? '425px' : '350px'}
            isJobAddition
            isSearchable
            onPlaceSelection={(location) =>
              handleFieldChange('location', location)
            }
            onDescriptionChange={(description) =>
              handleFieldChange('location_description', description)
            }
            isDisabled={isReadOnly}
            pageSource={PageSource.CREATE_JOB}
          />
          <PinLocationHint />
          <Input.Text
            id={JobField.PINNED_LOCATION}
            label={getText('create_job_location_title')}
            value={
              form?.location?.latitude && form?.location?.longitude
                ? `${form.location.latitude}, ${form.location.longitude}`
                : ''
            }
            extraStyles={{ marginBottom: '15px', marginTop: '15px' }}
            disabled
            required
          />
          {errors?.location && (
            <ErrorMessage>{getText('create_job_required_field')}</ErrorMessage>
          )}
          <Input.Text
            id={JobField.LOCATION_DESCRIPTION}
            label={getText('create_job_location_description_title')}
            value={form.location_description}
            required
            extraStyles={{ marginBottom: '15px' }}
            onChange={(e) => handleFieldChange('location_description', e)}
            disabled={isReadOnly}
          />
          {errors?.location_description && (
            <ErrorMessage>{getText('create_job_required_field')}</ErrorMessage>
          )}
        </>
      )}
      {customFields.postcodeLabel && (
        <Input.Text
          id={JobField.POSTCODE}
          label={customFields.postcodeLabel}
          value={form.postcode ?? ''}
          extraStyles={{ marginBottom: '15px' }}
          onChange={(e) => handleFieldChange('postcode', e)}
          required
          disabled={isReadOnly}
        />
      )}
      {errors?.postcode && (
        <ErrorMessage>{getText('create_job_required_field')}</ErrorMessage>
      )}
      {errors?.invalidPostcode && (
        <ErrorMessage>{getText('create_job_invalid_postcode')}</ErrorMessage>
      )}
      {customFields.addressLabel && (
        <>
          <Input.Text
            id={JobField.ADDRESS}
            label={customFields.addressLabel}
            value={form.address}
            onChange={(e) => handleFieldChange('address', e)}
            required
            extraStyles={{ marginBottom: '15px' }}
            disabled={isReadOnly}
          />
          {errors?.address && (
            <ErrorMessage>{getText('create_job_required_field')}</ErrorMessage>
          )}
        </>
      )}
      {!enable_auto_work_order_number && customFields.workOrderNumberLabel && (
        <Input.Text
          id={JobField.JOB_NUMBER}
          label={customFields.workOrderNumberLabel}
          value={form.job_number ?? ''}
          extraStyles={{ marginBottom: '15px' }}
          onChange={(e) => handleFieldChange('job_number', e)}
          required
          disabled={isReadOnly}
        />
      )}
      {errors?.jobNumber && (
        <ErrorMessage>{getText('create_job_required_field')}</ErrorMessage>
      )}
      {customFields.originatingNumberLabel && (
        <Input.Text
          id={JobField.ORIGINATING_NUMBER}
          label={customFields.originatingNumberLabel}
          value={form.originating_number}
          onChange={(e) => handleFieldChange('originating_number', e)}
          extraStyles={{ marginBottom: '15px' }}
          disabled={isReadOnly}
        />
      )}
      {customFields.projectNumberLabel && (
        <Input.Text
          id={JobField.PROJECT_NUMBER}
          label={customFields.projectNumberLabel}
          value={form.project_number}
          onChange={(e) => handleFieldChange('project_number', e)}
          extraStyles={{ marginBottom: '15px' }}
          disabled={isReadOnly}
        />
      )}
      <Input.Select
        id={JobField.TYPE}
        label={getText('create_job_type_title')}
        selected={types?.find((type) => type.id === form.type)}
        options={types}
        onChange={handleTypeChange}
        extraStyles={{ marginBottom: '15px' }}
        large
        fitContent
        required
        disabled={isReadOnly}
      />
      {errors?.type && (
        <ErrorMessage>{getText('create_job_required_field')}</ErrorMessage>
      )}
      {form.type && selectedTypeTitle === otherType && (
        <Input.Text
          id={JobField.TYPE_OTHER}
          label={getText('create_job_type_other_title')}
          value={form.type_other ?? ''}
          onChange={(e) => handleFieldChange('type_other', e)}
          extraStyles={{ marginBottom: '15px' }}
          disabled={isReadOnly}
        />
      )}
      {displaySubtypeSelect && (
        <Input.Select
          id={JobField.SUBTYPE}
          label={customFields.jobSubtypeLabel}
          selected={subtypes?.find((subtype) => subtype.id === form.subtype)}
          options={subtypes?.filter((subtype) => subtype.type_id === form.type)}
          onChange={(e) => handleFieldChange('subtype', e)}
          extraStyles={{ marginBottom: '15px' }}
          large
          fitContent
          required
          disabled={isReadOnly}
        />
      )}
      {errors?.subtype && (
        <ErrorMessage>{getText('create_job_required_field')}</ErrorMessage>
      )}
      {form.subtype &&
        selectedSubtypeTitle === otherType &&
        selectedTypeTitle !== otherType && (
          <Input.Text
            id={JobField.SUBTYPE_OTHER}
            label={getText('create_job_subtype_other_title')}
            value={form.subtype_other ?? ''}
            onChange={(e) => handleFieldChange('subtype_other', e)}
            extraStyles={{ marginBottom: '15px' }}
            disabled={isReadOnly}
          />
        )}
      <Input.Select
        id={JobField.DEPOT}
        label={getText('create_job_depot_title')}
        selected={depots?.find((depot) => depot.id === form.depot)}
        options={depots}
        onChange={(e) => handleFieldChange('depot', e)}
        extraStyles={{ marginBottom: '15px' }}
        large
        required={
          !(
            selectedTypeTitle === otherType ||
            selectedSubtypeTitle === otherType
          )
        }
        disabled={isReadOnly}
      />
      {errors?.depot && (
        <ErrorMessage>{getText('create_job_required_field')}</ErrorMessage>
      )}
    </InputsContainer>
  );
};

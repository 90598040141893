import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { Button } from 'components/ui';
import { useText } from 'hooks';
import { RamsStages } from 'model/Assessment/RAMS';
import { PhotoGrid } from './Photos/PhotoGrid';
import RootState from 'model/State/RootState';
import { SectionHeader } from '../SectionHeader';
import {
  ActionButtons,
  ActionButtonsWrapper,
  SectionContentContainer,
} from '../styled';
import { Pencil } from 'assets/icons';
import {
  HtmlWrapper,
  ImageContainer,
  ListContainer,
  SectionTitle,
} from './styled';
import { SectionDivider } from '../SectionDivider';
import { EVENT_EDIT_RAMS_CLICKED, EVENT_RAMS_SUBMITTED } from '../constants';
import {
  selectActiveRamsAssessment,
  selectIsEditTagDismissed,
  selectUserCanEditRams,
  sendMethodStatementRequest,
  setAssessmentStage,
} from 'store/slices/ramsAssessments';
import { NotEditableTag } from '../NotEditableTag';

// SectionSummary
export const SectionSummary = (): JSX.Element => {
  const { sections, isSubmitting, isMethodStatementEdited } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );
  const assessment = useSelector(selectActiveRamsAssessment);
  const canUserEdit = useSelector(selectUserCanEditRams);
  const isEditTagDismissed = useSelector(selectIsEditTagDismissed);
  const dispatch = useDispatch();
  const getText = useText();

  const onMethodStatementSubmit = () => {
    if (assessment) {
      dispatch(sendMethodStatementRequest(assessment?.id));
    }
  };

  const renderSection = () =>
    sections.map(({ title, content, photos, orderId }, index) => (
      <div key={orderId}>
        <SectionTitle>{title}</SectionTitle>
        <HtmlWrapper>{parse(content)}</HtmlWrapper>
        {photos.length > 0 && (
          <ImageContainer>{<PhotoGrid photos={photos} />}</ImageContainer>
        )}

        {sections && index !== sections.length - 1 && <SectionDivider />}
      </div>
    ));

  return (
    <SectionContentContainer>
      <SectionHeader
        title={getText('rams_section_option_method')}
        subtitle={getText('rams_section_method_summary')}
      />
      <ListContainer large={canUserEdit || isEditTagDismissed}>
        {renderSection()}
      </ListContainer>
      {!canUserEdit && <NotEditableTag />}
      <ActionButtonsWrapper>
        <ActionButtons>
          <Button.Secondary
            text={getText('rams_section_method_summary_edit')}
            onClick={() =>
              dispatch(setAssessmentStage(RamsStages.methodStatement))
            }
            disabled={!canUserEdit}
            IconComponent={Pencil}
            width='45%'
            extraStyles={{
              margin: 0,
              alignSelf: 'flex-end',
              height: 'inherit',
              maxHeight: '55px',
            }}
            event={EVENT_EDIT_RAMS_CLICKED}
            eventProperties={{
              assessmentId: assessment?.id,
              assessmentType: assessment?.type,
            }}
          />
          <Button.Primary
            text={getText(isMethodStatementEdited ? 'submit' : 'submitted')}
            onClick={onMethodStatementSubmit}
            width='45%'
            loading={isSubmitting}
            disabled={!isMethodStatementEdited || !canUserEdit}
            extraStyles={{
              margin: 0,
              alignSelf: 'flex-end',
              height: 'inherit',
              maxHeight: '55px',
            }}
            event={EVENT_RAMS_SUBMITTED}
            eventProperties={{
              assessmentId: assessment?.id,
              assessmentType: assessment?.type,
            }}
          />
        </ActionButtons>
      </ActionButtonsWrapper>
    </SectionContentContainer>
  );
};

import { Button } from '@mui/material';
import { Check, ThumbsDown, ThumbsUp } from 'assets/icons';
import { Loader } from 'components/ui';
import { UnorderedList } from 'components/ui/Lists/UnorderedList';
import { useIsMobile, useText } from 'hooks';
import { Video } from 'model/Media/Video';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendAssessmentSummaryFeedback } from 'store/actions/assessments';
import { selectCurrentUserId } from 'store/selectors';
import {
  selectIsSendingSummaryFeedback,
  selectIsSummaryFeedbackSuccess,
} from 'store/selectors/assessments';
import styled from 'styled-components';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';

const { colors, fonts, fontSizes, mixins } = theme;

const Container = styled.div`
  background-color: ${colors.white};
  padding: 20px;
  border-bottom: 15px solid ${colors.offWhite};
`;

const Header = styled.div`
  ${mixins.flexBetween};
  align-items: start;
`;

const TitleWrapper = styled.div`
  ${mixins.flexColumn};
`;

const Title = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
`;

const SubTitle = styled.div`
  color: ${colors.grey};
  font-family: ${fonts.bold};
  padding: 10px 0;
`;

const ThumbsContainer = styled.div`
  ${mixins.flexBetween};
  margin-bottom: 10px;
`;

const ThumbsWrapper = styled(Button)<{
  $isPositiveFeedback?: boolean;
  $isNegativeFeedback?: boolean;
  $isMobile?: boolean;
}>`
  &.MuiButton-root {
    min-width: auto;
    color: ${colors.black};
    text-transform: none;
    font-size: ${fontSizes.default};
    border: 0;
    border-radius: 0;
    padding: 5px;
    margin: 0 5px;
    ${mixins.flexBetween};
    &:hover {
      box-shadow: none;
      border: 0;
      background-color: ${colors.lightGrey};
      border-radius: 4px;
      transition: ease-out 0.2s;
    }

    ${({ $isMobile }) =>
      $isMobile &&
      `
      width: 45%;
      outline: 2px solid ${colors.grey};
      padding: 10px;
      margin: 0;
      justify-content: center;
      &:hover {
        outline: 3px solid ${colors.grey};
        border-radius: 0;
      }
    `};

    ${({ $isPositiveFeedback, $isNegativeFeedback, $isMobile }) => {
      if ($isPositiveFeedback || $isNegativeFeedback) {
        return `
          border-radius: ${$isMobile ? 0 : '4px'};
          outline: none;
          background-color: ${$isPositiveFeedback ? colors.green : colors.grey};
          > svg .fill-selector {
            fill: ${colors.white};
          }
          &:hover {
            background-color: ${
              $isPositiveFeedback ? colors.green : colors.grey
            };
            outline-style: ${$isMobile ? 'solid' : 'none'};
            outline-color: ${$isPositiveFeedback ? colors.green : colors.grey};
          }`;
      }
    }}
  }
`;

const ContentWrapper = styled.div`
  ${mixins.flexColumn};
`;

const ThumbsUpIcon = styled(ThumbsUp)<{ $size?: number }>`
  width: ${({ $size }) => $size || 20}px;
  height: ${({ $size }) => $size || 20}px;
`;

const ThumbsDownIcon = styled(ThumbsDown)<{ $size?: number }>`
  width: ${({ $size }) => $size || 20}px;
  height: ${({ $size }) => $size || 20}px;
`;

const SuccessMessage = styled.div`
  padding: 10px;
  background-color: ${colors.lightGreen};
  margin: 10px 0;
`;

type Props = {
  assessmentId: number;
  jobId: number;
  assessmentVideo: Video;
};

const AssessmentSummary = ({
  assessmentId,
  jobId,
  assessmentVideo,
}: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const currentUserId = useSelector(selectCurrentUserId);
  const isSendingSummaryFeedback = useSelector(selectIsSendingSummaryFeedback);
  const isSummaryFeedbackSuccess = useSelector(selectIsSummaryFeedbackSuccess);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [isFeedbackSent, setIsFeedbackSent] = useState<boolean>(false);
  const [selectedFeedback, setSelectedFeedback] = useState<boolean | null>(
    null,
  );
  const hasReview = assessmentVideo?.positiveSummaryReview !== null;

  useEffect(() => {
    if (showSuccessMessage && hasReview) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
        setSelectedFeedback(null);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage, selectedFeedback, hasReview, dispatch]);

  useEffect(() => {
    if (
      !isSendingSummaryFeedback &&
      hasReview &&
      isFeedbackSent &&
      isSummaryFeedbackSuccess
    ) {
      setShowSuccessMessage(true);
    }
  }, [
    isSendingSummaryFeedback,
    hasReview,
    isFeedbackSent,
    isSummaryFeedbackSuccess,
  ]);

  const sendFeedback = (isPositive = false) => {
    setSelectedFeedback(isPositive);
    setIsFeedbackSent(true);
    dispatch(
      sendAssessmentSummaryFeedback(assessmentId, assessmentVideo?.fileId, {
        ...assessmentVideo,
        positiveSummaryReview: isPositive,
      }),
    );

    Analytics.trackEvent(
      isPositive
        ? constants.EVENT_RISK_ASSESSMENT_VRA_AI_SUMMARY_THUMBS_UP
        : constants.EVENT_RISK_ASSESSMENT_VRA_AI_SUMMARY_THUMBS_DOWN,
      {
        jobId,
        assessmentId,
        userId: currentUserId,
      },
    );
  };

  const getThumbs = (isMobile: boolean = false, iconSize?: number) => (
    <ThumbsContainer>
      <ThumbsWrapper
        $isPositiveFeedback={selectedFeedback === true}
        $isMobile={isMobile}
        disabled={selectedFeedback !== null}
        onClick={() => sendFeedback(true)}
      >
        <ThumbsUpIcon $size={iconSize} />
      </ThumbsWrapper>
      <ThumbsWrapper
        $isNegativeFeedback={selectedFeedback === false}
        $isMobile={isMobile}
        disabled={selectedFeedback !== null}
        onClick={() => sendFeedback()}
      >
        <ThumbsDownIcon $size={iconSize} />
      </ThumbsWrapper>
    </ThumbsContainer>
  );

  if (isMobile) {
    return (
      <Container>
        <Header>
          <TitleWrapper>
            <Title>{getText('assessment_details_summary_title')}</Title>
            <SubTitle>
              {getText('assessment_details_summary_generated_by')}
            </SubTitle>
          </TitleWrapper>
        </Header>
        <ContentWrapper>
          <UnorderedList
            items={[{ id: 1, title: assessmentVideo?.summaryText ?? '' }]}
            extraItemStyle={{ marginLeft: '40px' }}
          />
          {!hasReview && (
            <>
              <SubTitle>
                {getText('assessment_details_summary_feedback_question')}
              </SubTitle>
              {isSendingSummaryFeedback ? (
                <Loader size={20} />
              ) : (
                getThumbs(true, 25)
              )}
            </>
          )}
          {showSuccessMessage && (
            <SuccessMessage>
              {getText('assessment_details_summary_feedback_success')} <Check />
            </SuccessMessage>
          )}
        </ContentWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <TitleWrapper>
          <Title>{getText('assessment_details_summary_title')}</Title>
          <SubTitle>
            {getText('assessment_details_summary_generated_by')}
          </SubTitle>
        </TitleWrapper>
        {!hasReview && (
          <>
            {isSendingSummaryFeedback ? (
              <div>
                <Loader size={20} />
              </div>
            ) : (
              getThumbs()
            )}
          </>
        )}
      </Header>
      <ContentWrapper>
        <UnorderedList
          items={[{ id: 1, title: assessmentVideo?.summaryText ?? '' }]}
          extraItemStyle={{ marginLeft: '40px' }}
        />
        {showSuccessMessage && (
          <SuccessMessage>
            {getText('assessment_details_summary_feedback_success')} <Check />
          </SuccessMessage>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default AssessmentSummary;

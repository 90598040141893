import { useDispatch, useSelector } from 'react-redux';
import {
  DownloadSource,
  DownloadType,
  EVENT_DOWNLOAD_CLICKED,
} from './constants';
import { DownloadButton, DownloadIcon } from './styled';
import {
  getPdfExportId as getFormPdfExportId,
  selectIsGeneratingFormPdf,
} from 'store/slices/forms';
import { getPdfExportId as getAssessmentPdfExportId } from 'store/actions/assessments';
import * as Analytics from 'utils/analytics';
import { selectJobsView } from 'store/selectors';
import { EVENT_PROPERTIES_PAGE_SOURCE_FIELD } from 'utils/constants';
import { selectIsGeneratingAssessmentPdf } from 'store/selectors/assessments';
import { CustomFormClass } from 'model/Form';

type Props = {
  type: DownloadType;
  itemId: string;
  source: DownloadSource;
  backgroundColor: string;
  formClass?: CustomFormClass;
  jobId?: number | string;
  onClick?: () => void;
  iconColor?: string;
  large?: boolean;
};

export const ExportButton = ({
  type,
  itemId,
  jobId,
  source,
  backgroundColor,
  formClass,
  onClick = () => {},
  iconColor,
  large = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const view = useSelector(selectJobsView);
  const isGeneratingAssessmentPdf = useSelector(
    selectIsGeneratingAssessmentPdf,
  );
  const isGeneratingFormPdf = useSelector(selectIsGeneratingFormPdf);

  const disabled =
    type === DownloadType.ASSESSMENT
      ? isGeneratingAssessmentPdf
      : isGeneratingFormPdf;

  const startPdfCreation = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const eventProperties = {
      ...(jobId && { jobId }),
      [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      download_source: source,
      download_type: type,
      [type === DownloadType.ASSESSMENT ? 'assessmentId' : 'formId']: itemId,
    };
    if (type === DownloadType.ASSESSMENT) {
      dispatch(getAssessmentPdfExportId(itemId, eventProperties));
    } else if (formClass) {
      dispatch(
        getFormPdfExportId({ formClass, formId: itemId, eventProperties }),
      );
    }
    onClick();

    Analytics.trackEvent(EVENT_DOWNLOAD_CLICKED, {
      ...eventProperties,
    });
  };

  return (
    <DownloadButton
      disableRipple
      onClick={(event) =>
        disabled ? event.preventDefault() : startPdfCreation(event)
      }
      large={large}
      backgroundColor={backgroundColor}
      disabled={disabled}
    >
      <DownloadIcon color={iconColor} />
    </DownloadButton>
  );
};

import { Navbar } from 'components/ui';
import { Container, Section } from './styled';
import HazardSettings from './HazardSettings';
import { AssessmentSettings } from './AssessmentSettings';
import { AssessmentHazardsSettings } from './AssessmentHazardsSettings';

const RiskAssessmentSettings = (): JSX.Element => {
  return (
    <>
      <Navbar home mobileOnly />
      <Container>
        <Section>
          <HazardSettings />
        </Section>
        <Section>
          <AssessmentSettings />
        </Section>
        <Section>
          <AssessmentHazardsSettings />
        </Section>
      </Container>
    </>
  );
};

export default RiskAssessmentSettings;

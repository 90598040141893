import { Button, Form } from 'components/ui';
import RootState from 'model/State/RootState';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { addMeToJob, markJobAsRead } from 'store/actions/jobs';
import { getMessages, sendMessage } from 'store/actions/messages';
import Feed from './Feed';
import { useText } from 'hooks';
import { PlusIcon } from 'assets/icons';
import { selectIsCurrentUserAssignedToJob } from 'store/selectors';

// TimelineContent
const TimelineContent = ({ children }: { children?: JSX.Element }) => {
  const params = useParams();
  const getText = useText();
  const { activeJob } = useSelector((state: RootState) => state.jobs);
  const isCurrentUserAssignedToJob = useSelector(
    selectIsCurrentUserAssignedToJob,
  );
  const dispatch = useDispatch();
  const { messages } = useSelector((state: RootState) => state.messages);
  const [newMessageMarkerPosition, setNewMessageMarkerPosition] = useState<
    number | null
  >(null);
  const [isGetMoreMessages, setIsGetMoreMessages] = useState<boolean>(false);
  const [messagesCount, setMessagesCount] = useState<number>(0);
  const { currentUser, isBackOffice } = useSelector(
    (state: RootState) => state.session,
  );
  const [currentActionId, setCurrentActionId] = useState<number | null>(null);

  useEffect(() => {
    if (params.jobId) {
      dispatch(getMessages(params.jobId));
      setMessagesCount(0);
      setCurrentActionId(null);
    }
  }, [params.jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (messages.length > messagesCount && isGetMoreMessages) {
      setIsGetMoreMessages(false);
      setMessagesCount(messages.length);
    }
    if (
      messages.length > 0 &&
      activeJob &&
      messages.length > messagesCount &&
      !isGetMoreMessages
    ) {
      if (activeJob.editable) {
        setNewMessageMarkerPosition(
          getNewMessageMarkerPosition(activeJob.unread_messages),
        );
        if (
          activeJob?.assignees?.find(
            (assignee) => assignee.id === currentUser.id,
          )
        ) {
          dispatch(markJobAsRead(activeJob.id));
        }
      }
      setMessagesCount(messages.length);
    }
  }, [messages, activeJob]); // eslint-disable-line react-hooks/exhaustive-deps

  const getNewMessageMarkerPosition = (unreadMessages) => {
    let newMessagePosition: number | null = null;
    if (unreadMessages > 0) {
      newMessagePosition = messages.findIndex(
        (message) => !message.read && message.type === 'text',
      );
    }
    return newMessagePosition;
  };

  const onMessageSubmit = (value: string) => {
    const uuid = uuidv4();
    setCurrentActionId(null);
    dispatch(sendMessage(params.jobId, value, currentUser, uuid));
  };

  return (
    <>
      {children}
      <Feed
        currentActionId={currentActionId}
        newMessageMarkerPosition={newMessageMarkerPosition}
        setIsGetMoreMessages={setIsGetMoreMessages}
        setCurrentActionId={setCurrentActionId}
      />

      {activeJob &&
        (isCurrentUserAssignedToJob || isBackOffice ? (
          <Form.Chat onSubmit={onMessageSubmit} />
        ) : (
          <Button.Primary
            text={getText('add_me_to_job')}
            IconComponent={PlusIcon}
            onClick={() => dispatch(addMeToJob())}
            marginTop='0px'
          />
        ))}
    </>
  );
};

export default TimelineContent;

import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, mixins, fontSizes } = theme;

export const StyledDataGrid = styled(({ isEmpty, width, columns, ...rest }) => (
  <DataGrid columns={columns} {...rest} />
))`
  &.MuiDataGrid-root {
    border: 0;
    .MuiDataGrid-columnHeaders {
      border-bottom: 2px solid ${colors.darkGrey};
      color: ${colors.darkGrey};
      font-family: ${fonts.bold};
      width: 100%;
      > div {
        width: 100%;
      }
    }
    .MuiDataGrid-columnHeader {
      height: 100px !important;
      min-width: ${({ width }) => width} !important;
      max-width: ${({ width }) => width} !important;
      &:focus,
      :focus-within {
        outline: 0;
      }
      .MuiDataGrid-columnSeparator {
        visibility: visible;
      }
    }
    .MuiDataGrid-columnHeaderTitleContainerContent {
      overflow: auto;
      width: 90%;
    }
    .MuiDataGrid-columnHeader--sortable .MuiDataGrid-sortIcon {
      opacity: 1;
    }
    .MuiDataGrid-iconButtonContainer {
      visibility: visible;
    }
    .MuiDataGrid-columnHeader--sorted,
    .MuiDataGrid-columnHeader--sorted .MuiIconButton-root {
      color: ${colors.blue};
    }
    .MuiDataGrid-row {
      border-bottom: 1px solid ${colors.grey};
      width: 100%;
      min-height: 52px !important;
      padding: 5px 0;
      display: flex;
      align-items: center;
    }
    .MuiDataGrid-cell {
      min-width: ${({ width }) => width} !important;
      max-width: ${({ width }) => width} !important;
      color: ${colors.black};
      font-family: ${fonts.default};
      border: 0;
      white-space: nowrap;
      overflow: hidden;
      &:focus,
      &:focus-within,
      &:focus-visible {
        outline: 0;
      }
      > span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .MuiDataGrid-columnHeaderTitleContainer {
      white-space: unset;
    }
    .MuiDataGrid-columnHeaderTitle {
      white-space: unset;
      word-break: break-word;
      line-height: normal;
    }
    .MuiDataGrid-withBorder {
      border: 0;
    }
    .MuiDataGrid-footerContainer {
      display: ${({ isEmpty }) => (isEmpty ? 'none' : 'block')};
    }
    .MuiDataGrid-virtualScrollerRenderZone {
      width: 100%;
    }
    .MuiDataGrid-virtualScroller {
      overflow-x: hidden;
      overflow-y: overlay;
    }
    .last-column .MuiDataGrid-iconSeparator {
      display: none;
    }
    .MuiDataGrid-footerContainer {
      display: none;
    }
    .MuiDataGrid-filler {
      display: none;
    }
    .MuiDataGrid-iconButtonContainer {
      width: auto;
    }
  }
`;

export const RowWrapper = styled.div`
  ${mixins.flexColumn}
`;

const Value = styled.p`
  font-family: ${fonts.lightItalic};
  font-size: ${fontSizes.l};
`;

const ValueList = styled.ul`
  padding-left: 20px;
`;

export const StringValue: React.FC<{ value: string | undefined }> = ({
  value,
}) => {
  return <Value>{value || '-'}</Value>;
};

export const ArrayValue: React.FC<{ value: string[] }> = ({ value }) => {
  if (!value.filter((f) => f).length) {
    return <Value>-</Value>;
  }

  return (
    <ValueList>
      {value.map((s) => (
        <li key={s}>
          <Value>{s}</Value>
        </li>
      ))}
    </ValueList>
  );
};

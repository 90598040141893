import { PayloadAction } from '@reduxjs/toolkit';
import { HavsLimitSettings } from 'api';
import { HavsFilters, HavsLimit } from 'model/AdminPanel/HavsSettings/HavsUser';
import { put, takeLatest } from 'redux-saga/effects';
import { setGlobalError } from 'store/slices/notifications';
import {
  fetchHavsLimits,
  fetchHavsLimitsError,
  fetchHavsLimitsSuccess,
} from 'store/slices/havsSettings';
import * as Logger from 'utils/logger';

function* fetchHavsLimitDataFn(action: PayloadAction<HavsFilters>) {
  try {
    const data: HavsLimit[] = yield HavsLimitSettings.getHavsLimitSettings(
      action.payload,
    );
    yield put(fetchHavsLimitsSuccess(data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchHavsLimitsError());
    yield put(setGlobalError(err));
  }
}

export default function* havsLimitWatcher() {
  yield takeLatest(fetchHavsLimits.type, fetchHavsLimitDataFn);
}

import { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import {
  insightsComplianceFields,
  INSIGHTS_COMPLIANCE_JOBS_ASSIGNED_CLICKED,
  INSIGHTS_METRIC_INFORMATION_CLICKED,
} from 'utils/constants';
import { useText } from 'hooks';
import AppFunctionalityInfoModal from '../../../Modal/AppFunctionalityInfoModal';
import { Tooltip } from 'components/ui/Display';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Sort from 'model/Sort';
import ComplianceDataTableBase from '../ComplianceDataTableBase';
import { ComplianceType } from 'model/enum/ComplianceType';
import RootState from 'model/State/RootState';
import { useSelector } from 'react-redux';
import { selectInsightsTab } from 'store/selectors';
import JobData from 'model/Insight/JobData';
import { InfoButton } from 'components/ui/Button';

const { fontSizes, mixins, media } = theme;

// Styled Components

const NumberOfJobs = styled.span`
  cursor: pointer;
`;

const HeaderContainer = styled.div`
  ${mixins.flexCenter};
`;

const Title = styled.div`
  ${media.xl`
    font-size: ${fontSizes.xs};
  `}
`;

const SecondLineText = styled.p`
  padding-top: 20px;
`;

type Props = {
  data: any[];
  total: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  sort: Sort;
  setSort: (value: Sort) => void;
  initialSort: Sort;
  analyticsData: object;
  isLoading: boolean;
};

// ComplianceDataTable
const ComplianceDataTable = ({
  data,
  total,
  currentPage,
  onPageChange,
  sort,
  setSort,
  initialSort,
  analyticsData,
  isLoading,
}: Props): JSX.Element => {
  const getText = useText();
  const { currentUser } = useSelector((state: RootState) => state.session);
  const activeInsightsTab = useSelector(selectInsightsTab);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState<{
    title: string;
    jobList?: JobData[];
    description?: string | JSX.Element;
  } | null>(null);

  const onJobsAssignedClick = (count, jobs) => {
    if (count > 0) {
      setIsInfoModalOpen(true);
      setInfoModal({
        title: count
          ? `${count} ${getText('insights_summary_tooltip_job')}${
              count === 1 ? '' : 's'
            }`
          : '',
        jobList: jobs,
      });
      Analytics.trackEvent(INSIGHTS_COMPLIANCE_JOBS_ASSIGNED_CLICKED);
    }
  };

  const headerByField = {
    [insightsComplianceFields.JOBS_ASSIGNED_COUNT]: {
      title: getText('insights_compliance_table_header_jobs_assigned'),
      description: getText('insights_compliance_jobs_assigned_description'),
    },
    [insightsComplianceFields.VRA_AND_SIGN_ON_PERCENTAGE]: {
      title: getText(
        'insights_compliance_table_header_percentage_vra_created_signed_on',
      ),
      description: (
        <div>
          {getText(
            'insights_compliance_percentage_vra_created_signed_on_description_first_line',
          )}
          <SecondLineText>
            {getText(
              'insights_compliance_percentage_vra_created_signed_on_description_second_line',
            )}
          </SecondLineText>
        </div>
      ),
    },
    [insightsComplianceFields.WRAPUP_PERCENTAGE]: {
      title: getText(
        'insights_compliance_table_header_percentage_wrap_up_created',
      ),
      description: (
        <span>
          {getText(
            'insights_compliance_percentage_wrap_up_created_description',
          )}
        </span>
      ),
    },
    [insightsComplianceFields.JOB_BLOCKER_COUNT]: {
      title: getText('insights_compliance_table_header_job_blockers_raised'),
      description: (
        <span>
          {getText('insights_compliance_job_blockers_raised_description')}
        </span>
      ),
    },
  };

  const renderTableHeader = (field: string) => (
    <HeaderContainer>
      <Title>{headerByField[field].title}</Title>
      <InfoButton
        onClick={(event) => onInfoClick(event, field)}
        disabled={isLoading}
      />
    </HeaderContainer>
  );

  const onInfoClick = (event, field) => {
    event.stopPropagation();
    setInfoModal(headerByField[field]);
    setIsInfoModalOpen(true);
    Analytics.trackEvent(INSIGHTS_METRIC_INFORMATION_CLICKED, {
      userId: currentUser?.id,
      tab: activeInsightsTab,
      metric: field,
    });
  };

  const columns: GridColDef[] = [
    {
      headerName: getText('insights_compliance_table_header_name'),
      field: insightsComplianceFields.USER_NAME,
    },
    {
      field: insightsComplianceFields.JOBS_ASSIGNED_COUNT,
      renderHeader: () =>
        renderTableHeader(insightsComplianceFields.JOBS_ASSIGNED_COUNT),
      renderCell: (props: GridRenderCellParams) => {
        if (props.row?.jobs_assigned_count > 0) {
          return (
            <Tooltip
              title={getText('insights_compliance_table_jobs_assigned_tooltip')}
              triggerElement={
                <NumberOfJobs
                  onClick={() =>
                    onJobsAssignedClick(
                      props.row?.jobs_assigned_count,
                      props.row?.jobs_assigned,
                    )
                  }
                >
                  {props.row?.jobs_assigned_count}
                </NumberOfJobs>
              }
              opacity={isInfoModalOpen ? 0 : 1}
            />
          );
        }
        return <span>{props.row?.jobs_assigned_count}</span>;
      },
    },
    {
      field: insightsComplianceFields.VRA_AND_SIGN_ON_PERCENTAGE,
      renderHeader: () =>
        renderTableHeader(insightsComplianceFields.VRA_AND_SIGN_ON_PERCENTAGE),
    },
    {
      field: insightsComplianceFields.WRAPUP_PERCENTAGE,
      renderHeader: () =>
        renderTableHeader(insightsComplianceFields.WRAPUP_PERCENTAGE),
    },
    {
      field: insightsComplianceFields.JOB_BLOCKER_COUNT,
      renderHeader: () =>
        renderTableHeader(insightsComplianceFields.JOB_BLOCKER_COUNT),
    },
  ];

  return (
    <>
      <AppFunctionalityInfoModal
        isVisible={isInfoModalOpen}
        setIsVisible={setIsInfoModalOpen}
        {...infoModal}
      />
      <ComplianceDataTableBase
        data={data}
        columns={columns}
        total={total}
        currentPage={currentPage}
        onPageChange={onPageChange}
        sort={sort}
        setSort={setSort}
        initialSort={initialSort}
        analyticsData={analyticsData}
        complianceType={ComplianceType.INSIGHTS}
        isLoading={isLoading}
      />
    </>
  );
};

export default ComplianceDataTable;

import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from 'hooks';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { initGlobalLanguage } from 'store/actions/session';

import { Login } from './Login';
import { ForgottenPassword } from './ForgottenPassword';
import { ResetPassword } from './ResetPassword';
import { MobileInstall } from './MobileInstall';
import { RequestMagicLink } from './RequestMagicLink';
import { TokenLogin } from './TokenLogin';
import { SignUp } from './SignUp';
import { DownloadRequest } from './Download';
import { Public as Container } from './styled';

const StaticFile = () => {
  useEffect(() => window.location.reload());

  return null;
};

export const Public = () => {
  const dispatch = useDispatch();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const [activeWorkspaceUuid, setActiveWorkspaceUuid] = useLocalStorage<
    string | null
  >('activeWorkspaceUuid');
  const [, setAppLanguage] = useLocalStorage<string>('globalAppLanguage');

  useEffect(() => {
    if (activeWorkspaceUuid) {
      setActiveWorkspaceUuid(null);
    }
    dispatch(initGlobalLanguage());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAppLanguage(globalAppLanguage);
  }, [globalAppLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/forgotten-password' element={<ForgottenPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/mobile/install' element={<MobileInstall />} />
        <Route path='/request-magic-link' element={<RequestMagicLink />} />
        <Route path='/challenge-response' element={<TokenLogin />} />
        <Route path='/download/:objectId' element={<DownloadRequest />} />
        <Route path='/*' element={<Navigate to='/login' />} />
        {/* TODO: might not need a route to static file */}
        <Route
          path='/.well-known/apple-app-site-association'
          element={<StaticFile />}
        />
      </Routes>
    </Container>
  );
};

import { VideoTimer } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins, media } = theme;

export const Container = styled.div<{
  width?: string;
  height?: string;
}>`
  ${mixins.flexFullCenter};
  flex-direction: column;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background-color: ${colors.black};
  color: ${colors.white};
`;

export const VideoTimerIcon = styled(VideoTimer)`
  height: 80px;
  width: 100px;
`;

export const Title = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  padding: 20px 0;
  ${media.sm`
    font-size: ${fontSizes.l};
  `};
`;

export const SubTitle = styled.div`
  text-align: center;
  line-height: ${fontSizes.xl};
  padding: 0 100px;
  ${media.sm`
    padding: 0 25px;
  `};
`;

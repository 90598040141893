import { useText } from 'hooks';
import ManageTypesBase from './ManageTypesBase';
import {
  selectGlobalLanguageSetting,
  selectJobTypeList,
} from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import RootState from 'model/State/RootState';
import { fetchWorkspaceJobTypes } from 'store/actions/workspace';
import { useCustomLocaleText } from 'hooks/useText';
import { Languages } from 'utils/constants';
import { WorkspaceItemStatus } from 'model/enum/WorkspaceItemStatus';
import * as Analytics from 'utils/analytics';
import { addItem, editItem } from 'helpers/workspace';
import {
  selectIsFetchingJobSubtypes,
  selectIsLoadingJobSubtypeChanges,
  selectJobSubtypeErrors,
  selectJobSubtypes,
} from 'store/selectors/settings/jobSubtype';
import {
  fetchWorkspaceJobSubtypes,
  resetJobSubtypeErrors,
  saveJobSubtypeChanges,
} from 'store/actions/settings/jobSubtype';
import {
  EVENT_WORKSPACE_SETTINGS_JOB_SUBTYPES_SAVED,
  EVENT_WORKSPACE_SETTINGS_JOB_SUBTYPE_ADDED,
  EVENT_WORKSPACE_SETTINGS_JOB_SUBTYPE_DELETED,
  EVENT_WORKSPACE_SETTINGS_JOB_SUBTYPE_EDITED,
} from '../constants';
import { WorkspaceItemType } from 'model/AdminPanel/WorkspaceSettings/WorkspaceItemType';

const JobSubtypeSettings = (): JSX.Element => {
  const getText = useText();
  const getEnglishText = useCustomLocaleText(Languages.english.locale);
  const getSpanishText = useCustomLocaleText(Languages.spanish.locale);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const isLoadingJobSubtypeChanges = useSelector(
    selectIsLoadingJobSubtypeChanges,
  );
  const jobSubtypeErrors = useSelector(selectJobSubtypeErrors);
  const dispatch = useDispatch();
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const jobTypes = useSelector(selectJobTypeList(selectedWorkspace?.uuid));
  const initialJobSubtypes = useSelector(
    selectJobSubtypes(selectedWorkspace?.uuid),
  );
  const isFetchingJobSubtypes = useSelector(selectIsFetchingJobSubtypes);
  const [jobSubtypes, setJobSubtypes] =
    useState<WorkspaceItemType[]>(initialJobSubtypes);
  const [selectedJobType, setSelectedJobType] = useState<WorkspaceItemType>(
    jobTypes[0],
  );

  useEffect(() => {
    dispatch(resetJobSubtypeErrors());
    if (selectedWorkspace?.uuid) {
      dispatch(fetchWorkspaceJobTypes(selectedWorkspace?.uuid));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setJobSubtypes(initialJobSubtypes);
  }, [initialJobSubtypes]);

  useEffect(() => {
    setSelectedJobType(jobTypes[0]);
  }, [jobTypes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedJobType?.id) {
      dispatch(
        fetchWorkspaceJobSubtypes(selectedWorkspace?.uuid, selectedJobType?.id),
      );
    }
  }, [selectedJobType]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAddOrEdit = (
    isUpdate: boolean,
    index: number,
    englishText: string,
    spanishText: string,
    extraEnglishText?: string,
    extraSpanishText?: string,
  ) => {
    if (isUpdate && index > -1) {
      const modifiedList = editItem(
        initialJobSubtypes,
        jobSubtypes,
        index,
        englishText,
        spanishText,
        EVENT_WORKSPACE_SETTINGS_JOB_SUBTYPE_EDITED,
        extraEnglishText,
        extraSpanishText,
      );
      setJobSubtypes(modifiedList);
    } else {
      const modifiedList = addItem(
        jobSubtypes,
        englishText,
        spanishText,
        EVENT_WORKSPACE_SETTINGS_JOB_SUBTYPE_ADDED,
        extraEnglishText,
        extraSpanishText,
      );
      setJobSubtypes(modifiedList);
    }
  };

  const onRemove = (index: number, id: number | null) => {
    Analytics.trackEvent(EVENT_WORKSPACE_SETTINGS_JOB_SUBTYPE_DELETED, {
      jobSubtype: jobSubtypes[index],
    });
    if (id) {
      setJobSubtypes(
        jobSubtypes.map((type) =>
          type.id === id
            ? { ...type, status: WorkspaceItemStatus.DELETED }
            : type,
        ),
      );
    } else if (index > -1) {
      setJobSubtypes((prevState) => prevState.filter((_, i) => i !== index));
    }
  };

  const onSubmit = () => {
    const changedFields = jobSubtypes.filter((type) => type?.status);
    dispatch(
      saveJobSubtypeChanges(
        selectedWorkspace?.uuid,
        selectedJobType?.id,
        changedFields,
      ),
    );

    Analytics.trackEvent(EVENT_WORKSPACE_SETTINGS_JOB_SUBTYPES_SAVED, {
      changed_fields: changedFields,
    });
  };

  return (
    <ManageTypesBase
      manageTitle={getText('workspace_settings_manage_job_subtype')}
      addEditTitle={getText('workspace_settings_add_edit_job_subtype')}
      noTypeText={getText('workspace_settings_no_job_subtype')}
      hasSelectInput
      selectLabel={getText('workspace_settings_select_job_type')}
      selectOptions={jobTypes}
      selected={selectedJobType}
      handleSelectChange={(jobType) => setSelectedJobType(jobType)}
      label={getEnglishText('workspace_settings_job_subtype_label')}
      labelEs={getSpanishText('workspace_settings_job_subtype_label')}
      extra_field_label={getEnglishText('workspace_settings_unit_type_label')}
      extra_field_label_es={getSpanishText(
        'workspace_settings_unit_type_label',
      )}
      descriptionText={getText(
        'workspace_settings_sub_categories_of_job_types',
      )}
      types={jobSubtypes}
      loading={isFetchingJobSubtypes}
      swapOrder={globalAppLanguage !== Languages.english.locale}
      onAddOrEditPress={onAddOrEdit}
      onSubmit={onSubmit}
      isSubmitting={isLoadingJobSubtypeChanges}
      hasChangedFields={jobSubtypes.some((type) => type?.status)}
      onRemove={onRemove}
      errors={jobSubtypeErrors}
    />
  );
};

export default JobSubtypeSettings;

import { useState, useEffect } from 'react';
import { ClockContainer } from './styled';

export const LiveClock = (): JSX.Element => {
  const [dateState, setDateState] = useState<Date>(new Date());

  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  return (
    <ClockContainer>
      {dateState.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      })}
    </ClockContainer>
  );
};

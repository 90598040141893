import { Dates, Names, Utils } from 'helpers';
import { Document as DocumentIcon } from 'assets/icons';
import * as Analytics from 'utils/analytics';
import { useText } from 'hooks';
import {
  CardDocumentContainer,
  CreationDate,
  Creator,
  DocumentType,
  DownloadIcon,
  FlexCenterContainer,
  Icon,
  Subtype,
} from './styled';
import { Document } from 'model/Media/Document';

type Props = {
  document: Document;
};

// Card.Document
export const DocumentCard = Analytics.withTrackEvent(
  ({ document, ...props }: Props): JSX.Element => {
    const getText = useText();

    return (
      <CardDocumentContainer href={document.signedUrl ?? undefined} {...props}>
        <FlexCenterContainer>
          <Icon component={<DocumentIcon />} $marginRight={20} />

          <div>
            <DocumentType>{document.originalFileName}</DocumentType>
            <Subtype>
              {document.extensionType.toUpperCase()} &bull;
              {Utils.formatBytes(document.fileSize)}
            </Subtype>
          </div>
        </FlexCenterContainer>

        <FlexCenterContainer>
          <Creator>
            {Names.capitalizeFullName(
              document.createdBy.firstName,
              document.createdBy.lastName,
            )}
          </Creator>

          <CreationDate $marginLeft={50} $marginRight={25}>
            {Dates.formatDateTime(
              document.createdAt,
              getText('date_today'),
              getText('date_yesterday'),
            )}
          </CreationDate>

          <DownloadIcon />
        </FlexCenterContainer>
      </CardDocumentContainer>
    );
  },
);

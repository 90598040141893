import { useState, useEffect } from 'react';
import { Organisation as OrganisationApi } from 'api';
import { useDispatch } from 'react-redux';
import { setGlobalError } from 'store/slices/notifications';
import Organisation from 'model/Organisation';

// useOrganisations
const useOrganisations = () => {
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { results: organisationsResult },
        } = await OrganisationApi.getAll();

        setOrganisations(organisationsResult);
      } catch (err) {
        dispatch(setGlobalError(err));
        console.error(err);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return organisations;
};

export default useOrganisations;

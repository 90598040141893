import { PayloadAction } from '@reduxjs/toolkit';
import { SiteBriefing as SiteBriefingApi } from 'api';
import { SiteBriefing } from 'model/State/SiteBriefing';
import { put, takeLatest } from 'redux-saga/effects';
import { setGlobalError } from 'store/slices/notifications';
import {
  fetchSiteBriefing,
  fetchSiteBriefingError,
  fetchSiteBriefingSuccess,
} from 'store/slices/siteBriefing';
import * as Logger from 'utils/logger';

function* fetchSiteBriefingData(
  action: PayloadAction<{ jobId: number; siteBriefingId: number }>,
) {
  try {
    const data: SiteBriefing = yield SiteBriefingApi.getSiteBriefingData(
      action.payload.jobId,
      action.payload.siteBriefingId,
    );
    yield put(fetchSiteBriefingSuccess(data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchSiteBriefingError());
    yield put(setGlobalError(err));
  }
}

export default function* siteBriefingWatcher() {
  yield takeLatest(fetchSiteBriefing.type, fetchSiteBriefingData);
}

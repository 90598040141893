import { useEffect, useState } from 'react';
import * as constants from 'utils/constants';
import {
  Button,
  CrossIcon,
  CrossWrapper,
  Input,
  InputContainer,
  Label,
  SearchForm,
  SearchIcon,
} from './styled';

type Props = {
  view: string;
  onSubmit: (value: string) => void;
  resetSearch: () => void;
  initialValue?: string;
  placeholder?: string;
  label?: string;
  onSearch?: (value: string) => void;
  inputWidth?: string;
};

// Form.Search
export const Search = ({
  view,
  initialValue = '',
  placeholder = '',
  label,
  onSubmit,
  resetSearch,
  onSearch,
  inputWidth,
}: Props): JSX.Element => {
  const [search, setSearch] = useState<string>(initialValue);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (isSubmitted && onSearch) {
      onSearch(search);
    }
  }, [isSubmitted]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (newSearch) => {
    setSearch(newSearch);
    if (isSubmitted && onSearch) {
      onSearch(newSearch);
      setIsSubmitted(false);
    }
  };

  const handleSubmit = (e) => {
    setIsSubmitted(true);
    e.preventDefault();
    if (search.length !== 1) {
      onSubmit(search);
    }
  };

  return (
    <SearchForm onSubmit={handleSubmit}>
      {label && <Label>{label}</Label>}
      <InputContainer>
        <Input
          placeholder={placeholder}
          value={search}
          onChange={(e) => handleChange(e.target.value)}
          $width={inputWidth}
        />
        {search && (
          <CrossWrapper className='cross-wrapper'>
            <CrossIcon
              onClick={() => {
                setSearch('');
                resetSearch();
                setIsSubmitted(false);
              }}
            />
          </CrossWrapper>
        )}
        <Button
          type='submit'
          event={constants.EVENT_JOB_SEARCH}
          eventProperties={{
            search,
            [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
          }}
          onClick={handleSubmit}
        >
          <SearchIcon />
        </Button>
      </InputContainer>
    </SearchForm>
  );
};

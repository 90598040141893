import { getNotSignedOnUsers, getSignedOnUsers } from 'helpers/users';
import { Section } from '../../styled';
import { SignOnSection } from './SignOnSection';
import { useText } from 'hooks';
import { Assessment } from 'model/Assessment';

type Props = {
  assessment: Assessment;
};

export const SignOn = ({ assessment }: Props): JSX.Element | null => {
  const getText = useText();

  const signedOnUsers = getSignedOnUsers(assessment);
  const notSignedOnUsers = getNotSignedOnUsers(assessment);

  if (assessment.signonRequired && assessment?.signedOnUsers?.length > 0) {
    return (
      <Section>
        {notSignedOnUsers?.length > 0 && (
          <SignOnSection
            users={notSignedOnUsers}
            title={getText('assessment_details_awaiting_sign_on')}
            keyPrefix='not-signed-on-user'
          />
        )}
        {signedOnUsers?.length > 0 && (
          <SignOnSection
            users={signedOnUsers}
            title={getText('assessment_details_signed_on')}
            keyPrefix='signed-on-user'
            marginTop={notSignedOnUsers?.length > 0 ? 35 : 0}
          />
        )}
      </Section>
    );
  }

  return null;
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { Storage } from 'aws-amplify';
import { getVideo, getVideoInfoPage } from 'helpers/forms';
import { Badge, FormattedDate, Loader, Media, Navbar } from 'components/ui';
import { JobForms } from 'api';
import { setGlobalError } from 'store/slices/notifications';
import FormDetails from './components/FormDetails';
import { useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import Form from 'model/Forms/Form';
import { Field } from 'model/Forms/Field';
import { mapToPhoto, mapToVideo } from 'api/Media/helpers';
import { ModalFooter } from '../PdfExport/ModalFooter';
import { DownloadType } from '../PdfExport/constants';
import { CustomFormClass } from 'model/Form';

const { colors, fonts, fontSizes, media, mixins } = theme;

/* Styled Components */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 55px;
  height: 100%;
  ${media.sm`
    padding-bottom: 0;
  `}
`;

const Header = styled.div`
  height: 50px;
  ${mixins.flexBetween};
  background-color: ${colors.lightGrey};
  padding: 0 25px;
  ${media.sm`
    padding: 0 15px;
  `}
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 16px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 0 15px 30px 15px;
  font-size: ${fontSizes.xl};
`;

const SectionTitle = styled.h2`
  color: ${colors.black};
  font-family: ${fonts.bold};
`;

/* JobForm.Show */
const Show = (): JSX.Element => {
  const { jobId, formId } = useParams();
  const [jobForm, setJobForm] = useState<Form>();
  const [isFetching, setFetching] = useState<boolean>(true);
  const [videoUrl, setVideoUrl] = useState<string>();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.session);
  const videoInfoPage: Field | null = getVideoInfoPage(jobForm?.form);

  useEffect(() => {
    (async () => {
      try {
        const data = await JobForms.get(parseInt(formId ?? '', 10));
        setJobForm(data);
        setFetching(false);
      } catch (err) {
        dispatch(setGlobalError(err));
      }
    })();
  }, [formId, jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      if (jobForm?.form && videoInfoPage) {
        const signedURL = await Storage.get(
          (videoInfoPage as Field)?.value.path,
        );
        setVideoUrl(signedURL);
      }
    })();
  }, [jobForm]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Navbar
        title={jobForm?.form?.title}
        defaultBackPath={`/jobs/${jobId}/timeline`}
        mobileOnly
      />

      {isFetching ? (
        <Loader />
      ) : (
        <Container>
          {videoInfoPage && videoUrl && (
            <Media
              kind='job-form'
              type='video'
              url={videoUrl}
              height='425px'
              item={getVideo(
                jobForm?.form,
                jobForm?.videos?.map(mapToVideo) ?? [],
              )}
            />
          )}
          <Header>
            {jobForm?.created_by && (
              <Badge.UserBadge
                user={jobForm?.created_by}
                isCurrentUser={currentUser.id === jobForm?.created_by.id}
              />
            )}
            {jobForm?.created_at && (
              <FormattedDate
                date={jobForm?.created_at?.toString() ?? ''}
                extraStyles={{ color: theme.colors.grey }}
              />
            )}
          </Header>
          {jobForm && (
            <Section>
              {jobForm?.form?.header && (
                <SectionHeader>
                  <SectionTitle>{jobForm?.form?.header}</SectionTitle>
                </SectionHeader>
              )}
              <FormDetails
                form={jobForm?.form}
                photos={jobForm?.photos.map(mapToPhoto)}
                videos={jobForm?.videos.map(mapToVideo)}
              />
            </Section>
          )}
          {jobId && formId && (
            <ModalFooter
              downloadType={DownloadType.FORM}
              downloadItemId={formId}
              formClass={CustomFormClass.JOB}
              jobId={jobId}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default Show;

import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { Hazard } from 'model/Assessment/Hazard';
import { Control } from 'model/Assessment/Control';
import {
  CrossIcon,
  ItemList,
  ItemListContainer,
  ItemTitle,
  ListItemRow,
  PlusIcon,
} from './styled';
import { capitalizeFirstLetter } from 'helpers/names';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

type Props = {
  items: Hazard[] | Control[];
  selectedItems: Hazard[] | Control[];
  onSelectedChange: (value: Hazard | Control) => void;
};

// RamsSuggestBase
export const RamsSuggestBase = ({
  items,
  onSelectedChange,
  selectedItems,
}: Props): JSX.Element => {
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  return (
    <ItemListContainer>
      <ItemList>
        {items?.map((item: Hazard | Control) => {
          const isSelected = selectedItems.some(
            (selected: Hazard | Control) => selected.id === item.id,
          );
          return (
            <ListItemRow
              selected={isSelected}
              key={item.id}
              onClick={() => onSelectedChange(item)}
            >
              <ItemTitle selected={isSelected}>
                {capitalizeFirstLetter(
                  getLocalizedCamelCaseValue(item, 'title', globalAppLanguage),
                )}
              </ItemTitle>
              {isSelected ? <CrossIcon /> : <PlusIcon />}
            </ListItemRow>
          );
        })}
      </ItemList>
    </ItemListContainer>
  );
};

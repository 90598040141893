import {
  KeyboardEventHandler,
  MouseEvent,
  useState,
  KeyboardEvent,
} from 'react';
import { CSSProperties } from 'styled-components';
import { IconButton } from '@mui/material';
import { Eye, EyeOff } from 'assets/icons';
import { StyledTextField } from './styled';
import { Container, LabelWrapper, StyledLabel } from '../styled';

type Props = {
  id: string;
  value: string;
  label?: string;
  labelColor?: string;
  error?: string | boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  extraStyles?: CSSProperties;
  hasBlackBackground?: boolean;
  onEnter?: () => void;
};

// Input.Password
export const PasswordInput = ({
  id,
  label,
  labelColor,
  error,
  value,
  onChange,
  disabled = false,
  extraStyles,
  hasBlackBackground,
  onEnter,
}: Props): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleKeyUp: KeyboardEventHandler = (event: KeyboardEvent<Element>) => {
    if (event.key === 'Enter' && onEnter) {
      onEnter();
    }
  };

  return (
    <Container style={extraStyles}>
      {label && (
        <LabelWrapper>
          <StyledLabel htmlFor={id} color={labelColor}>
            {label}
          </StyledLabel>
        </LabelWrapper>
      )}
      <StyledTextField
        type={showPassword ? 'text' : 'password'}
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        $hasError={!!error}
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? <Eye /> : <EyeOff />}
            </IconButton>
          ),
        }}
        $hasBlackBackground={hasBlackBackground}
        onKeyUp={handleKeyUp}
      />
    </Container>
  );
};

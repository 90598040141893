import { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { Evidence as EvidenceApi } from 'api';
import { useDispatch } from 'react-redux';
import { getSignedUrls } from 'helpers/media';
import { setGlobalError } from 'store/slices/notifications';
import Media from 'model/Media';
import { useParams } from 'react-router-dom';
import { Evidence } from 'model/Evidence/Evidence';

type StateProps = {
  evidence: Evidence | null;
  comments: { id: number; title: string }[];
  media: Media | null;
  isFetching: boolean;
  error: { message: string } | null;
};

export default (id) => {
  const { jobId } = useParams();
  const [state, setState] = useState<StateProps>({
    evidence: null,
    comments: [],
    media: null,
    isFetching: true,
    error: null,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const evidence: Evidence = await EvidenceApi.get(id);
        const media: Media = { type: '', signedUrl: '', signedUrls: [] };
        if (evidence.photos.length > 0) {
          media.type = 'photo';
          media.signedUrls = await getSignedUrls(evidence.photos);
        } else if (evidence.videos.length > 0) {
          media.type = 'video';
          media.signedUrl = await Storage.get(evidence.videos[0].path);
        }
        setState((prevState) => ({
          ...prevState,
          evidence,
          comments: evidence.comments
            ? [{ id: 1, title: evidence.comments }]
            : [],
          media,
          isFetching: false,
        }));
      } catch (err) {
        dispatch(setGlobalError(err));
        setState((prevState) => ({
          ...prevState,
          error: { message: (err as Error)?.message },
          isFetching: false,
        }));
      }
    })();
  }, [id, jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  return state;
};

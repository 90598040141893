import { Hazard } from 'model/Assessment/Hazard';
import {
  ADD_WORKSPACE_HAZARD,
  ADD_WORKSPACE_HAZARD_ERROR,
  ADD_WORKSPACE_HAZARD_SUCCESS,
  CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT,
  CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT_ERROR,
  CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT_SUCCESS,
  CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS,
  CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS_ERROR,
  CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS_SUCCESS,
  EDIT_WORKSPACE_HAZARD,
  EDIT_WORKSPACE_HAZARD_ERROR,
  EDIT_WORKSPACE_HAZARD_SUCCESS,
  FETCH_WORKSPACE_HAZARDS,
  FETCH_WORKSPACE_HAZARDS_ERROR,
  FETCH_WORKSPACE_HAZARDS_SUCCESS,
  REMOVE_WORKSPACE_HAZARD,
  REMOVE_WORKSPACE_HAZARD_ERROR,
  REMOVE_WORKSPACE_HAZARD_SUCCESS,
  RESET_WORKSPACE_HAZARD_ERROR,
  UPDATE_WORKSPACE_HAZARD_ASSIGNED_CONTROL_LIST,
} from 'store/actions/actionTypes';

const defaultState = {
  hazards: [],
  modifiedHazardId: null,
  isFetchingHazards: false,
  isLoadingHazardChange: false,
  isLoadingLinkingHazard: {},
  error: null,
};

const workspaceHazards = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_WORKSPACE_HAZARDS:
      return {
        ...state,
        isFetchingHazards: true,
        modifiedHazardId: null,
      };
    case FETCH_WORKSPACE_HAZARDS_SUCCESS:
      return {
        ...state,
        hazards: {
          ...state.hazards,
          [action.workspaceUuid]: [...action.data],
        },
        isFetchingHazards: false,
      };
    case FETCH_WORKSPACE_HAZARDS_ERROR:
      return {
        ...state,
        isFetchingHazards: false,
      };
    case ADD_WORKSPACE_HAZARD:
    case EDIT_WORKSPACE_HAZARD:
    case REMOVE_WORKSPACE_HAZARD:
    case CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS:
      return {
        ...state,
        isLoadingHazardChange: true,
        modifiedHazardId: null,
      };
    case ADD_WORKSPACE_HAZARD_SUCCESS:
    case EDIT_WORKSPACE_HAZARD_SUCCESS: {
      return {
        ...state,
        hazards: {
          ...state.hazards,
          [action.workspaceUuid]: [...action.hazardList],
        },
        modifiedHazardId: action.data?.id,
        isLoadingHazardChange: false,
      };
    }
    case ADD_WORKSPACE_HAZARD_ERROR:
    case EDIT_WORKSPACE_HAZARD_ERROR:
    case REMOVE_WORKSPACE_HAZARD_ERROR:
    case CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS_ERROR:
      return {
        ...state,
        error: action.data,
        isLoadingHazardChange: false,
      };
    case REMOVE_WORKSPACE_HAZARD_SUCCESS: {
      const workspaceHazards: Hazard[] =
        state.hazards?.[action.workspaceUuid] || [];
      const updatedHazards = workspaceHazards.filter(
        (hazard) => hazard?.id !== action?.id,
      );
      return {
        ...state,
        hazards: {
          ...state.hazards,
          [action.workspaceUuid]: [...updatedHazards],
        },
        isLoadingHazardChange: false,
      };
    }
    case RESET_WORKSPACE_HAZARD_ERROR:
      return {
        ...state,
        error: null,
      };
    case CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS_SUCCESS: {
      const workspaceHazards: Hazard[] =
        state.hazards?.[action.workspaceUuid] || [];
      const updatedHazards = workspaceHazards.map((hazard) =>
        hazard?.id === action.data?.id ? action.data : hazard,
      );
      return {
        ...state,
        hazards: {
          ...state.hazards,
          [action.workspaceUuid]: [...updatedHazards],
        },
        isLoadingHazardChange: false,
      };
    }
    case UPDATE_WORKSPACE_HAZARD_ASSIGNED_CONTROL_LIST: {
      const workspaceHazards: Hazard[] =
        state.hazards?.[action.workspaceUuid] || [];
      const updatedHazards = workspaceHazards.map((hazard) => {
        if (hazard.id === action.hazardId) {
          return {
            ...hazard,
            mappedControlIds: [
              ...(hazard.mappedControlIds ?? []),
              action.controlId,
            ],
          };
        }
        return hazard;
      });
      return {
        ...state,
        hazards: {
          ...state.hazards,
          [action.workspaceUuid]: updatedHazards,
        },
      };
    }
    case CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT:
      return {
        ...state,
        isLoadingLinkingHazard: {
          ...state.isLoadingLinkingHazard,
          [action.hazard?.id]: true,
        },
      };
    case CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT_SUCCESS: {
      const workspaceHazards: Hazard[] =
        state.hazards?.[action.workspaceUuid] || [];
      const updatedHazards = workspaceHazards.map((hazard) =>
        hazard?.id === action.hazard?.id ? action.hazard : hazard,
      );
      return {
        ...state,
        hazards: {
          ...state.hazards,
          [action.workspaceUuid]: updatedHazards,
        },
        isLoadingLinkingHazard: {
          ...state.isLoadingLinkingHazard,
          [action.hazard?.id]: false,
        },
      };
    }

    case CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT_ERROR:
      return {
        ...state,
        isLoadingLinkingHazard: {
          ...state.isLoadingLinkingHazard,
          [action.hazardId]: false,
        },
      };
    default:
      return state;
  }
};

export default workspaceHazards;

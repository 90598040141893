import { StyleVariant } from 'model/enum/StyleVariant';
import { HighRiskWarningContainer, HighRiskWarningIcon, Score } from './styled';

type Props = {
  message: string;
  score: string;
  hasBorder?: boolean;
  hasBigIcon?: boolean;
  variant?: StyleVariant;
  extraStyles?: React.CSSProperties;
};

export const SafetyRiskTag = ({
  message,
  score,
  hasBorder = false,
  hasBigIcon = false,
  variant,
  extraStyles,
}: Props): JSX.Element => {
  return (
    <HighRiskWarningContainer
      $hasBorder={!!hasBorder}
      variant={variant}
      style={extraStyles}
    >
      <HighRiskWarningIcon isBig={hasBigIcon} />
      {message}
      <Score variant={variant}>{score}</Score>
    </HighRiskWarningContainer>
  );
};

import { useText } from 'hooks';
import {
  DecreaseArrowIcon,
  IncreaseArrowIcon,
  SummarySubTitle,
} from './styled';
import { roundPercentage } from 'helpers/number';

type Props = {
  previousData: number;
  percentageChange: number;
};

export const InsightSummaryStatChange = ({
  previousData,
  percentageChange,
}: Props) => {
  const getText = useText();

  if (previousData === 0) {
    return (
      <SummarySubTitle>
        {getText('insights_summary_subtitle_na')}
      </SummarySubTitle>
    );
  }
  if (percentageChange === 0) {
    return (
      <SummarySubTitle>
        {getText('insights_summary_subtitle_no_change')}
      </SummarySubTitle>
    );
  }
  if (percentageChange > 0) {
    return (
      <SummarySubTitle>
        <IncreaseArrowIcon />
        {getText('insights_summary_subtitle_up_percentage', {
          percentage: roundPercentage(100 * percentageChange),
        })}
      </SummarySubTitle>
    );
  }
  return (
    <SummarySubTitle $isRed>
      <DecreaseArrowIcon />
      {getText('insights_summary_subtitle_down_percentage', {
        percentage: roundPercentage(100 * percentageChange * -1),
      })}
    </SummarySubTitle>
  );
};

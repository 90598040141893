import { Check, Warning } from 'assets/icons';
import { startOfDay } from 'date-fns';
import { getDaysBetween, isBefore } from 'helpers/dates';
import { useText } from 'hooks';
import { LastApproval } from 'model/Forms/LastApproval';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts } = theme;

// Styled Components

const StatusContainer = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  align-items: center;
  padding: 7px 17px;
  font-family: ${fonts.bold};
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || colors.orange};
`;

const Status = styled.span<{ $textColor?: string }>`
  color: ${({ $textColor }) => $textColor || colors.white};
  margin-left: 10px;
`;

const Icon = styled(({ component, iconColor = '', ...props }) =>
  React.cloneElement(component, props),
)`
  height: 20px;
  width: 20px;
  .fill-selector {
    fill: ${({ iconColor }) => iconColor || colors.black};
  }
`;

type Props = {
  lastApproval: LastApproval | null;
  extraStyles?: React.CSSProperties;
};

const PermitStatus = ({ lastApproval, extraStyles }: Props): JSX.Element => {
  const getText = useText();

  if (!lastApproval) {
    return (
      <StatusContainer style={extraStyles}>
        <Icon component={<Warning />} />
        <Status $textColor={colors.black}>
          {getText('job_permits_awaiting_authorisation')}
        </Status>
      </StatusContainer>
    );
  }
  if (!lastApproval.isApproved) {
    return (
      <StatusContainer style={extraStyles}>
        <Icon component={<Warning />} />
        <Status $textColor={colors.black}>
          {getText('job_permits_rejected')}
        </Status>
      </StatusContainer>
    );
  }
  if (
    lastApproval.expiryDate &&
    isBefore(lastApproval.expiryDate, new Date())
  ) {
    return (
      <StatusContainer $backgroundColor={colors.darkGrey} style={extraStyles}>
        <Icon component={<Warning />} iconColor={colors.grey} />
        <Status>{getText('job_permits_expired')}</Status>
      </StatusContainer>
    );
  }

  if (
    lastApproval.expiryDate &&
    getDaysBetween(startOfDay(new Date()), lastApproval.expiryDate) < 2
  ) {
    return (
      <StatusContainer style={extraStyles}>
        <Icon component={<Warning />} />
        <Status $textColor={colors.black}>
          {getText('job_permits_issued', {
            days: getDaysBetween(
              startOfDay(new Date()),
              lastApproval.expiryDate,
            ),
            dayText: getText('job_permit_issued_day'),
          })}
        </Status>
      </StatusContainer>
    );
  }

  return (
    <StatusContainer style={extraStyles} $backgroundColor={colors.black}>
      <Icon component={<Check />} iconColor={colors.yellow} />
      <Status>
        {getText('job_permits_issued', {
          days: getDaysBetween(startOfDay(new Date()), lastApproval.expiryDate),
          dayText: getText('job_permit_issued_days'),
        })}
      </Status>
    </StatusContainer>
  );
};

export default PermitStatus;

import { BackendUser } from 'model/BackendUser';

type FormLocation = {
  latitude: string | null;
  longitude: string | null;
};

export type FormProps = {
  predefined_location: number | null;
  location: FormLocation;
  location_description: string;
  postcode: string | null;
  address: string;
  job_number: string | null;
  originating_number: string;
  project_number: string;
  type: number | null;
  subtype: number | null;
  depot: number | null;
  assignees: BackendUser[] | null;
  type_other: string | null;
  subtype_other: string | null;
};

export type FormErrors = {
  address: boolean;
  location: boolean;
  location_description: boolean;
  type: boolean;
  subtype: boolean;
  depot: boolean;
  postcode: boolean;
  invalidPostcode: boolean;
  jobNumber: boolean;
};

export class CustomFields {
  pinLocationEnabled: boolean;
  workOrderNumberLabel: string;
  originatingNumberLabel: string;
  projectNumberLabel: string;
  jobSubtypeLabel: string;
  addressLabel: string;
  postcodeLabel: string;

  constructor(
    pinLocationEnabled: boolean,
    workOrderNumberLabel: string,
    originatingNumberLabel: string,
    projectNumberLabel: string,
    jobSubtypeLabel: string,
    addressLabel: string,
    postcodeLabel: string,
  ) {
    this.pinLocationEnabled = pinLocationEnabled;
    this.workOrderNumberLabel = workOrderNumberLabel;
    this.originatingNumberLabel = originatingNumberLabel;
    this.projectNumberLabel = projectNumberLabel;
    this.jobSubtypeLabel = jobSubtypeLabel;
    this.addressLabel = addressLabel;
    this.postcodeLabel = postcodeLabel;
  }
}

const BlankLocation = { latitude: null, longitude: null };

export const BlankForm = {
  predefined_location: null,
  location: BlankLocation,
  location_description: '',
  postcode: null,
  address: '',
  job_number: null,
  originating_number: '',
  project_number: '',
  type: null,
  subtype: null,
  depot: null,
  assignees: null,
  type_other: null,
  subtype_other: null,
};

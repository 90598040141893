import { PlusIcon } from 'assets/icons';
import { Button } from 'components/ui';
import { TeamSelect } from 'components/ui/Filter/TeamSelect';
import { useTeams, useText } from 'hooks';
import Team from 'model/Team';
import { ModalType } from 'model/enum/ModalType';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as constants from 'utils/constants';
import RootState from 'model/State/RootState';
import { useSelector } from 'react-redux';
import { getScreen } from 'helpers/utils';
import { selectActiveWorkspaceUuid, selectJobsView } from 'store/selectors';
import { FyldScreen } from 'model/enum/FyldScreen';
import { ButtonWrapper, Container, TeamsWrapper } from './styled';

// ManageTeamsModal
export const ManageTeamsModal = (): JSX.Element => {
  const getText = useText();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useSelector((state: RootState) => state.session);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);
  const view = useSelector(selectJobsView);
  const screen = getScreen(location?.pathname);
  const { selectedTeam } = useTeams();
  const [selected, setSelected] = useState<Team | null>(
    location.state?.team || selectedTeam,
  );

  const onNewTeamClick = () => {
    navigate(location.pathname + `?modal=${ModalType.TEAM_DETAILS}`, {
      state: {
        ...location.state,
        originalTeam: null,
        modalTitle: getText('manage_teams_modal_create'),
        event: constants.MANAGE_TEAMS_CREATE_NEW_CANCEL_CLICKED,
        eventProperties: {
          userId: currentUser?.id,
          ...(screen === FyldScreen.JOBS && {
            [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
          }),
        },
      },
    });
  };

  const path = location.state?.parentModal
    ? location.pathname + `?modal=${location.state?.parentModal}`
    : location.pathname;

  const saveToFilter = () =>
    navigate(path, {
      state: {
        ...location.state,
        modifiedTeam: selected,
        team: undefined,
        selectedWorkspaceUuid,
      },
    });

  return (
    <Container>
      <TeamsWrapper>
        <TeamSelect
          selectedTeam={selected}
          handleTeamChange={(team) => setSelected(team)}
        />
        <ButtonWrapper>
          <Button.SecondaryLink
            text={getText('manage_teams_modal_edit_team')}
            disabled={!selected}
            extraStyles={{ width: '47.5%' }}
            to={`${location.pathname}?modal=${ModalType.TEAM_DETAILS}`}
            state={{
              ...location.state,
              originalTeam: selected,
              modalTitle: getText('manage_teams_modal_edit_team'),
              event: constants.MANAGE_TEAMS_EDIT_CANCEL_CLICKED,
              eventProperties: {
                userId: currentUser?.id,
                teamId: selected?.id,
                ...(screen === FyldScreen.JOBS && {
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }),
              },
            }}
            event={constants.MANAGE_TEAMS_EDIT_TEAM_CLICKED}
            eventProperties={{
              userId: currentUser?.id,
              teamId: selected?.id,
              area: screen,
              ...(screen === FyldScreen.JOBS && {
                [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
              }),
            }}
          />
          <Button.Secondary
            text={getText('manage_teams_modal_create')}
            IconComponent={PlusIcon}
            extraStyles={{ width: '47.5%' }}
            onClick={onNewTeamClick}
            event={constants.MANAGE_TEAMS_CREATE_TEAM_CLICKED}
            eventProperties={{
              userId: currentUser?.id,
              area: screen,
              ...(screen === FyldScreen.JOBS && {
                [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
              }),
            }}
          />
        </ButtonWrapper>
      </TeamsWrapper>
      <Button.Primary
        text={getText('manage_teams_modal_apply')}
        disabled={!selected}
        onClick={saveToFilter}
        marginTop='0'
        event={constants.MANAGE_TEAMS_APPLY_FILTER_CLICKED}
        eventProperties={{
          userId: currentUser?.id,
          teamId: selected?.id,
          area: screen,
          ...(screen === FyldScreen.JOBS && {
            [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
          }),
        }}
      />
    </Container>
  );
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useText } from 'hooks';
import theme from 'theme';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import RootState from 'model/State/RootState';
import { Button } from 'components/ui';
import { LiveClock } from './components/LiveClock';
import { InteractiveInformationBox } from './components/InteractiveInformationBox';
import { LatestActivity } from './components/LatestActivity';
import { ControlCenterMap } from './components/ControlCenterMap';
import { CommandCenterFilter } from 'model/enum/CommandCenterFilter';
import { ModalType } from 'model/enum/ModalType';
import { useLocation, useNavigate } from 'react-router-dom';
import { initialFilterOptions } from 'model/CommandCenter/FilterOptions';
import {
  fetchActivities,
  fetchStats,
  selectCoordinates,
  selectIsFetchingActivities,
  selectStats,
} from 'store/slices/commandCenter';
import {
  BoxContainer,
  BoxRowContainer,
  ContentWrapper,
  DesktopWrapper,
  FilterIcon,
  Header,
  HeaderTitle,
  LatestActivityContainer,
  MapContainer,
  StatsTitle,
  SubTitle,
  Title,
} from './styled';

export const CommandCenterDesktop = (): JSX.Element => {
  const dispatch = useDispatch();
  const getText = useText();
  const isFetchingActivities = useSelector(selectIsFetchingActivities);
  const stats = useSelector(selectStats);
  const [mapCenter, setMapCenter] = useState<google.maps.LatLng | null>(null);
  const [mapZoom, setMapZoom] = useState<number>(6);
  const [currentActiveFilterId, setCurrentActiveFilterId] =
    useState<CommandCenterFilter>(CommandCenterFilter.JOBS_LIVE);
  const refreshTime = 120000;
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const coordinates = useSelector(selectCoordinates(currentActiveFilterId));

  const fetchData = () => {
    // directly reading localStorage instead of using the useLocalStorage hook
    // because the value of that key is changed in the Command Centre Filters modal
    // and we can't persist state across different hook instances
    const currentFilters = JSON.parse(
      window.localStorage.getItem('command-centre-filters') ?? '{}',
    );
    const workspaceUuid = JSON.parse(
      window.localStorage.getItem('activeWorkspaceUuid') ?? '{}',
    );
    dispatch(
      fetchStats({ ...initialFilterOptions, ...currentFilters, workspaceUuid }),
    );
    if (!isFetchingActivities) {
      dispatch(
        fetchActivities({
          filterOptions: {
            ...initialFilterOptions,
            ...currentFilters,
            workspaceUuid,
          },
          options: { clearState: true },
        }),
      );
    }
    Analytics.trackEvent(constants.COMMAND_CENTER_LOADED, {
      ...currentFilters,
      workspaceUuid,
    });
  };

  useEffect(() => {
    fetchData();
  }, [selectedWorkspace]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const interval = setInterval(fetchData, refreshTime);
    return () => clearInterval(interval); // Clear interval on component unmount to avoid memory leak
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const changeMapJobs = (id: CommandCenterFilter) => {
    setMapCenter(null);
    setCurrentActiveFilterId(id);
    sendStatAnalytics(id);
  };

  const openFilterModal = () => {
    navigate(location.pathname + `?modal=${ModalType.COMMAND_CENTRE_FILTERS}`);
  };

  const sendStatAnalytics = (filterId: CommandCenterFilter) => {
    Analytics.trackEvent(constants.COMMAND_CENTER_STAT_BOX_CLICKED, {
      filterId,
      jobs: stats[filterId],
    });
  };

  return (
    <DesktopWrapper>
      <Header>
        <HeaderTitle>
          <Title>{getText('command_centre_title')}</Title>
          <SubTitle>{getText('command_centre_subtitle')}</SubTitle>
        </HeaderTitle>
        <LiveClock />
        <Button.Secondary
          event={constants.COMMAND_CENTER_FILTER_OPENED}
          eventProperties={{
            orgId: selectedWorkspace?.uuid,
            userId: currentUser?.id,
          }}
          text={getText('command_centre_filter')}
          width='160px'
          IconComponent={FilterIcon}
          onClick={openFilterModal}
          isDark
          hasBlackBackground
          extraStyles={{
            backgroundColor: theme.colors.black,
            color: theme.colors.white,
            padding: '10px',
            height: '40px',
            fontSize: theme.fontSizes.s,
          }}
        />
      </Header>
      <ContentWrapper>
        <BoxContainer>
          <BoxRowContainer $noMargin>
            <InteractiveInformationBox
              id={CommandCenterFilter.JOBS_NOT_STARTED}
              title={getText('command_centre_information_box_jobs_not_started')}
              data={stats?.jobsNotStarted}
              onClick={(id) => changeMapJobs(id)}
              isActive={
                currentActiveFilterId === CommandCenterFilter.JOBS_NOT_STARTED
              }
            />
            <InteractiveInformationBox
              id={CommandCenterFilter.JOBS_LIVE}
              title={getText('command_centre_information_box_live_jobs')}
              data={stats?.jobsLive}
              onClick={(id) => changeMapJobs(id)}
              isActive={currentActiveFilterId === CommandCenterFilter.JOBS_LIVE}
            />
            <InteractiveInformationBox
              id={CommandCenterFilter.JOBS_COMPLETED}
              title={getText('command_centre_information_box_completed_jobs')}
              data={stats?.jobsCompleted}
              onClick={(id) => changeMapJobs(id)}
              isActive={
                currentActiveFilterId === CommandCenterFilter.JOBS_COMPLETED
              }
            />
          </BoxRowContainer>
          <BoxRowContainer $noMargin>
            <InteractiveInformationBox
              id={CommandCenterFilter.JOBS_WITH_MAJOR_HAZARD}
              title={getText(
                'command_centre_information_box_jobs_with_major_hazards',
              )}
              data={stats?.jobsWithMajorHazards}
              textColor={
                stats?.jobsWithMajorHazards?.length > 0
                  ? theme.colors.red
                  : theme.colors.white
              }
              onClick={(id) => changeMapJobs(id)}
              isActive={
                currentActiveFilterId ===
                CommandCenterFilter.JOBS_WITH_MAJOR_HAZARD
              }
            />
            <InteractiveInformationBox
              id={CommandCenterFilter.JOBS_WITHOUT_VRAS}
              title={getText('command_centre_information_box_jobs_without_vra')}
              data={stats?.jobsWithoutVras}
              onClick={(id) => changeMapJobs(id)}
              isActive={
                currentActiveFilterId === CommandCenterFilter.JOBS_WITHOUT_VRAS
              }
            />
            <InteractiveInformationBox
              id={CommandCenterFilter.JOBS_WITH_UNRESOLVED_BLOCKERS}
              title={getText('command_centre_information_box_jobs_blocked')}
              data={stats?.jobsWithUnresolvedBlockers}
              textColor={
                stats?.jobsWithUnresolvedBlockers?.length > 0
                  ? theme.colors.orange
                  : theme.colors.white
              }
              onClick={(id) => changeMapJobs(id)}
              isActive={
                currentActiveFilterId ===
                CommandCenterFilter.JOBS_WITH_UNRESOLVED_BLOCKERS
              }
            />
          </BoxRowContainer>
          <LatestActivityContainer>
            <StatsTitle>{getText('command_centre_stats_latest')}</StatsTitle>
            <LatestActivity />
          </LatestActivityContainer>
        </BoxContainer>
        <MapContainer>
          <ControlCenterMap
            coordinates={coordinates}
            mapCenter={mapCenter}
            setMapCenter={setMapCenter}
            mapZoom={mapZoom}
            setMapZoom={setMapZoom}
          />
        </MapContainer>
      </ContentWrapper>
    </DesktopWrapper>
  );
};

import {
  SET_FEATURES_GUIDE_CURRENT_STEP,
  SET_FEATURES_GUIDE_OPEN_STATUS,
} from 'store/actions/actionTypes';

const defaultState = {
  isOpen: false,
  currentStep: 0,
};

const errors = (state = defaultState, action) => {
  switch (action.type) {
    case SET_FEATURES_GUIDE_CURRENT_STEP: {
      return { ...state, currentStep: action.currentStep };
    }
    case SET_FEATURES_GUIDE_OPEN_STATUS:
      return {
        ...state,
        isOpen: action.status,
      };
    default:
      return state;
  }
};

export default errors;

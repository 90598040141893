import { useState, useEffect } from 'react';
import { useIsMobile, useText, useQueryParams } from 'hooks';
import { Navbar, Input, Card } from 'components/ui';
import { useDispatch, useSelector } from 'react-redux';
import { sendSuggestion } from 'store/actions/assessments';
import { selectGlobalLanguageSetting, selectJobsView } from 'store/selectors';
import { Assessment, Assessments, AssessmentItemType } from 'model/Assessment';
import {
  Hazard,
  HazardWithControl,
  SuggestedHazardWithControl,
  SuggestionRequest,
} from 'model/Assessment/Hazard';
import { Control } from 'model/Assessment/Control';
import { useNavigate, useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import ModalCtaButton from './ModalCtaButton';
import { EVENT_PROPERTIES_PAGE_SOURCE_FIELD } from 'utils/constants';
import {
  Container,
  ContentWrapper,
  CrossIconStyled,
  ErrorContainer,
  ItemList,
  ItemRow,
  ItemRowSelected,
  P,
  PlusIconStyled,
  PSelectedSuggested,
} from './styled';
import { capitalizeFirstLetter } from 'helpers/names';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

type ItemPropTypes = {
  id: number;
  title: string;
};

type Props = {
  items: ItemPropTypes[];
  suggestionType: AssessmentItemType.HAZARD | AssessmentItemType.CONTROL;
  selectedId?: number;
  event?: string;
  assessment?: Assessment | null;
  onNext?: (value: Hazard[] | Control[]) => void;
  suggestedItems?: Hazard[] | Control[];
};

const SuggestBase = ({
  items,
  suggestionType,
  event,
  assessment,
  selectedId,
  onNext,
  suggestedItems = [],
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const { jobId, assessmentId } = useParams();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const getText = useText();
  const { getPathWithQueryParams } = useQueryParams();

  const [search, setSearch] = useState<string>('');
  const [selected, setSelected] = useState<Hazard[] | Control[]>([]);
  const [suggested, setSuggested] = useState<number[]>([]);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { isAssessmentUpdating: isLoading, assessmentError: errorMessage } =
    useSelector((state: RootState) => state.assessments);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const view = useSelector(selectJobsView);

  const selectedHazard: HazardWithControl | undefined =
    assessment?.hazardsWithControls?.find(
      (hazard_with_controls) => hazard_with_controls.hazard.id === selectedId,
    );

  const selectedSuggestedHazard: SuggestedHazardWithControl | undefined =
    assessment?.suggestedHazardsWithControls?.find(
      (hazard_with_controls) => hazard_with_controls.hazard.id === selectedId,
    );

  useEffect(() => {
    if (assessment && suggestionType) {
      setSuggested(
        Assessments.getSuggestedItems(
          assessment,
          suggestedItems,
          suggestionType,
          selectedId,
        ),
      );
    }
  }, [assessment, suggestionType]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterChange = (newSearch) => {
    setSearch(newSearch.toLowerCase());
  };

  const handleSelectChange = (item) => {
    if (selected.indexOf(item) > -1) {
      setSelected([...selected].filter((x) => x.id !== item.id));
    } else {
      setSelected([...selected, item]);
    }
  };

  useEffect(() => {
    if (submitted && !isLoading) {
      navigate(
        getPathWithQueryParams(`/jobs/${jobId}/assessments/${assessmentId}`),
      );
    }
  }, [submitted, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async () => {
    let suggestionRequest: SuggestionRequest | null = null;
    const hazard = selectedHazard?.hazard ?? selectedSuggestedHazard?.hazard;

    if (suggestedItems?.length > 0 && hazard) {
      suggestionRequest = {
        suggestedHazards: [
          {
            hazard,
            controls: (suggestedItems as Control[]).concat(
              selected as Control[],
            ),
          },
        ],
      };
    } else if (assessment?.hazardsWithControls?.length) {
      if (suggestionType === AssessmentItemType.CONTROL && hazard) {
        suggestionRequest = {
          suggestedControls: [
            {
              hazard,
              controls: selected as Control[],
            },
          ],
        };
      } else if (onNext) {
        onNext(selected);
      }
    } else {
      suggestionRequest = {
        [`${suggestionType}s`]: selected,
      };
    }

    if (suggestionRequest) {
      dispatch(sendSuggestion(assessmentId, { ...suggestionRequest }));
    }

    setSubmitted(true);
  };

  const renderItem = (item) => {
    const isSuggestedHazard =
      suggested?.find((hazardId) => hazardId === item.id) ||
      (assessment?.hazardsWithControls &&
      assessment?.hazardsWithControls?.length > 0
        ? assessment.hazardsWithControls?.find(
            (hazardsWithControls) => hazardsWithControls.hazard.id === item.id,
          )
        : assessment?.hazards.find((hazard) => hazard.id === item.id));

    const isSuggestedControl =
      suggested?.find((controlId) => controlId === item.id) ??
      (selectedHazard
        ? (selectedHazard.controls?.find((control) => control.id === item.id) ??
          selectedHazard?.suggestedControls?.find(
            (control) => control.id === item.id,
          ))
        : assessment?.controls.find((control) => control.id === item.id));

    if (selected.indexOf(item) !== -1) {
      return (
        <ItemRowSelected key={item.id} onClick={() => handleSelectChange(item)}>
          <PSelectedSuggested>
            {capitalizeFirstLetter(
              getLocalizedCamelCaseValue(item, 'title', globalAppLanguage),
            )}
          </PSelectedSuggested>
          <CrossIconStyled />
        </ItemRowSelected>
      );
    }
    if (
      (suggestionType === AssessmentItemType.HAZARD && isSuggestedHazard) ||
      (suggestionType === AssessmentItemType.CONTROL && isSuggestedControl)
    ) {
      return (
        <ItemRowSelected key={item.id}>
          <PSelectedSuggested>
            {getLocalizedCamelCaseValue(item, 'title', globalAppLanguage)}
          </PSelectedSuggested>
        </ItemRowSelected>
      );
    }
    return (
      <ItemRow key={item.id} onClick={() => handleSelectChange(item)}>
        <P> {getLocalizedCamelCaseValue(item, 'title', globalAppLanguage)}</P>
        <PlusIconStyled />
      </ItemRow>
    );
  };

  return (
    <>
      <Navbar
        title={getText('assessment_suggest', { type: suggestionType })}
        defaultBackPath={getPathWithQueryParams(
          `/jobs/${jobId}/assessments/${assessmentId}`,
        )}
        mobileOnly
      />

      {items && (
        <ContentWrapper>
          {errorMessage && (
            <ErrorContainer>
              <Card.ErrorMessage message={errorMessage} />
            </ErrorContainer>
          )}
          <Container>
            <Input.Text
              id='search'
              label={getText('assessment_enter', { type: suggestionType })}
              value={search}
              onChange={(e) => handleFilterChange(e.target.value)}
            />
          </Container>

          <ItemList>
            {items
              ?.filter(
                (item) =>
                  search === '' ||
                  getLocalizedCamelCaseValue(item, 'title', globalAppLanguage)
                    .toLowerCase()
                    .indexOf(search) > -1,
              )
              .map(renderItem)}
          </ItemList>

          <ModalCtaButton
            text={getText(
              assessment?.hazardsWithControls?.length && onNext
                ? 'assessment_edit_add_control'
                : 'job_details_send_suggestions',
            )}
            onClick={handleSubmit}
            loading={isLoading}
            disabled={selected.length < 1}
            event={event}
            eventProperties={{
              jobId,
              assessmentId,
              suggestedIds: suggested,
              [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            extraStyles={!isMobile ? { margin: '0px', flex: '0 0 55px' } : {}}
          />
        </ContentWrapper>
      )}
    </>
  );
};

export default SuggestBase;

import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'components/ui';
import FormInput from 'model/FormInput';
import { EVENT_PROPERTIES_PAGE_SOURCE_FIELD } from 'utils/constants';
import { useSelector } from 'react-redux';
import { selectJobsView } from 'store/selectors';
import { FormError, FormWrapper, InputWrapper, StyledLabel } from './styled';

type FormProps = {
  inputFields: FormInput[];
  onSubmit: SubmitHandler<{}>;
  submitButtonEvent?: string;
  submitButtonText?: string;
  isLoading?: boolean;
  formError?: string;
};

// Form.Form
export const Form = ({
  inputFields,
  submitButtonEvent,
  submitButtonText,
  onSubmit,
  isLoading = false,
  formError,
}: FormProps): JSX.Element => {
  const view = useSelector(selectJobsView);

  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: inputFields.reduce(
      (agg, item) => ({ ...agg, [item?.id]: item?.value }),
      {},
    ),
  });
  const [submitted, setSubmitted] = useState<boolean>(false);

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit, () => setSubmitted(true))}>
      {inputFields.map((inputField: FormInput) => (
        <div key={`${inputField?.id}-container`}>
          {inputField?.label && (
            <StyledLabel style={inputField.labelStyle}>
              {inputField.label}
            </StyledLabel>
          )}

          <Controller
            control={control}
            name={inputField.id as never}
            rules={inputField?.validations}
            render={({ field: { onChange, value } }) => (
              <InputWrapper style={inputField?.extraStyles}>
                {React.cloneElement(inputField?.input as React.ReactElement, {
                  key: inputField?.id,
                  value,
                  onChange,
                })}

                {((errors && errors[inputField?.id]) ||
                  inputField?.fieldError) && (
                  <FormError>
                    {errors[inputField?.id]?.message || inputField?.fieldError}
                  </FormError>
                )}
              </InputWrapper>
            )}
          />
        </div>
      ))}
      {formError && <FormError>{formError}</FormError>}
      <Button.Primary
        text={submitButtonText}
        disabled={(submitted && !isValid) || isLoading}
        loading={isLoading}
        onClick={handleSubmit(onSubmit, () => setSubmitted(true))}
        width='240px'
        event={submitButtonEvent}
        eventProperties={{ [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view }}
        extraStyles={{ display: 'flex', alignSelf: 'start' }}
      />
    </FormWrapper>
  );
};

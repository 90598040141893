import { put, takeLatest, select, throttle } from 'redux-saga/effects';
import {
  Job,
  Search,
  Evidence,
  Document,
  Log,
  Permit,
  WorkOrderItem,
} from 'api';
import { Dates } from 'helpers';
import * as Logger from 'utils/logger';
import {
  setListJobs,
  appendLegacyViewJobs,
  setActiveJob,
  updateJob as updateJobAction,
  setJobStats,
  setWeatherForJob,
  fetchWeatherError,
  setEvidences,
  setDocuments,
  setJobPhotos,
  setLogs,
  setWorkOrderItems,
  setPermits,
  setLegacyViewJobs,
  setLegacyViewJobStats,
  setJobBlockers,
  fetchDocumentsError,
  fetchLogsError,
  fetchJobBlockersError,
  fetchWorkOrderItemsError,
  fetchPermitsError,
  fetchEvidencesError,
  setMapJobs,
  addMeToJobSuccess,
  addMeToJobError,
  fetchJobStatsThrottled,
  fetchJobStats,
} from '../actions/jobs';
import { setLastRefresh } from '../actions/session';
import {
  FETCH_ACTIVE_JOB,
  FETCH_LIST_JOBS,
  FETCH_MORE_LEGACY_VIEW_JOBS,
  UPDATE_JOB,
  JOB_MARK_AS_READ,
  FETCH_WEATHER_FOR_JOB,
  FETCH_EVIDENCES,
  FETCH_DOCUMENTS,
  FETCH_JOB_PHOTOS,
  FETCH_LOGS,
  FETCH_WORK_ORDER_ITEMS,
  FETCH_PERMITS,
  FETCH_LEGACY_VIEW_JOBS,
  FETCH_JOB_BLOCKERS,
  FETCH_MAP_JOBS,
  ADD_ME_TO_JOB,
  FETCH_JOB_STATS_THROTTLED,
} from '../actions/actionTypes';
import { generateWeatherData, getSignedUrls } from '../../helpers/apiHelpers';
import { setSuccess, setGlobalError } from 'store/slices/notifications';
import { JobSort } from 'model/enum/JobSort';
import {
  selectActiveJob,
  selectActiveWorkspaceUuid,
  selectCurrentUserId,
} from 'store/selectors';
import { LIST_VIEW_PAGE_SIZE, MAP_VIEW_PAGE_SIZE } from 'utils/constants';
import { setPsiDismissed } from 'store/actions/user';
import { selectActiveRamsAssessment } from 'store/slices/ramsAssessments';
import { fetchAssessment } from 'store/actions/assessments';

function* fetchListJobs({ filters, page = 1, requestJobStats = true }) {
  try {
    const selectedWorkspaceUuid = yield select(selectActiveWorkspaceUuid);
    const { teams } = yield select((state) => state.user);
    const { sort } = yield select((state) => state.jobs);
    const currentUserId = yield select(selectCurrentUserId);
    const selectedTeamByUserAndWorkspace = yield JSON.parse(
      window.localStorage.getItem('selectedTeamByUserAndWorkspace') || '{}',
    );
    const selectedTeamIdsByUser =
      selectedTeamByUserAndWorkspace?.[currentUserId];
    const selectedTeamId = selectedTeamIdsByUser?.[selectedWorkspaceUuid];
    const selectedTeam = teams?.find((team) => team?.id === selectedTeamId);
    const isActiveJobsToggledOn = filters?.scope === JobSort.USER;

    const allUserFilters = filters?.assignees?.length
      ? filters.assignees.map((assignee) => assignee.id)
      : [];

    if (selectedTeam?.members && selectedTeam?.members?.length > 0) {
      selectedTeam.members.forEach((member) => {
        if (!allUserFilters.includes(member?.id)) {
          allUserFilters.push(member.id);
        }
      });
    }

    if (isActiveJobsToggledOn) {
      allUserFilters.push(currentUserId);
    }

    if (selectedTeam?.members && selectedTeam?.members?.length > 0) {
      selectedTeam.members.forEach((member) => {
        if (!allUserFilters.includes(member?.id)) {
          allUserFilters.push(member.id);
        }
      });
    }

    const userIds = allUserFilters?.length ? allUserFilters.join(',') : null;

    if (filters?.scope && !filters?.q && requestJobStats) {
      yield put(fetchJobStatsThrottled(selectedWorkspaceUuid, userIds));
    }

    let data;
    if (filters?.q) {
      data = yield Search.jobsV2({
        page,
        pageSize: LIST_VIEW_PAGE_SIZE,
        search: filters?.q,
        workspaceUuid: selectedWorkspaceUuid,
      });
    } else {
      data = yield Job.getAll({
        page,
        pageSize: LIST_VIEW_PAGE_SIZE,
        sortField: sort?.field,
        sortDirection: sort?.order,
        userIds,
        search: filters?.q,
        startDate: filters?.daterange?.length ? filters.daterange[0] : null,
        endDate: filters?.daterange?.length ? filters.daterange[1] : null,
        type: filters?.type,
        subtype: filters?.subtype,
        status: filters?.status,
        assessmentType: filters?.assessmentType,
        hazard: filters?.hazard,
        depot: filters?.depot,
        isActive: filters?.scope && filters?.is_active,
        hasMajorHazard: filters?.scope && filters?.has_major_hazard,
        hasVraReadyForSignOff:
          filters?.scope && filters?.has_vra_ready_for_sign_off,
        hasNoVra: filters?.scope && filters?.has_no_vra,
        workspaceUuid: selectedWorkspaceUuid,
      });
    }
    yield put(setListJobs(data.results, data.count, page));
    yield put(setLastRefresh(Dates.getLastRefresh()));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* fetchMapJobs({ filters, page = 1, requestJobStats = true }) {
  try {
    const selectedWorkspaceUuid = yield select(selectActiveWorkspaceUuid);
    const { teams } = yield select((state) => state.user);
    const currentUserId = yield select(selectCurrentUserId);
    const selectedTeamByUserAndWorkspace = yield JSON.parse(
      window.localStorage.getItem('selectedTeamByUserAndWorkspace') || '{}',
    );
    const selectedTeamIdsByUser =
      selectedTeamByUserAndWorkspace?.[currentUserId];
    const selectedTeamId = selectedTeamIdsByUser?.[selectedWorkspaceUuid];
    const selectedTeam = teams?.find((team) => team?.id === selectedTeamId);
    const isActiveJobsToggledOn = filters?.scope === JobSort.USER;

    const allUserFilters = filters?.assignees?.length
      ? filters.assignees.map((assignee) => assignee.id)
      : [];

    if (selectedTeam?.members && selectedTeam?.members?.length > 0) {
      selectedTeam.members.forEach((member) => {
        if (!allUserFilters.includes(member?.id)) {
          allUserFilters.push(member.id);
        }
      });
    }

    if (isActiveJobsToggledOn) {
      allUserFilters.push(currentUserId);
    }

    if (selectedTeam?.members && selectedTeam?.members?.length > 0) {
      selectedTeam.members.forEach((member) => {
        if (!allUserFilters.includes(member?.id)) {
          allUserFilters.push(member.id);
        }
      });
    }

    const userIds = allUserFilters?.length ? allUserFilters.join(',') : null;

    if (filters?.scope && !filters?.q && requestJobStats) {
      yield put(fetchJobStatsThrottled(selectedWorkspaceUuid, userIds));
    }

    let data;
    if (filters?.q) {
      data = yield Search.jobsV2({
        page,
        pageSize: LIST_VIEW_PAGE_SIZE,
        search: filters?.q,
        workspaceUuid: selectedWorkspaceUuid,
      });
    } else {
      data = yield Job.getAll({
        page,
        pageSize: MAP_VIEW_PAGE_SIZE,
        userIds,
        search: filters?.q,
        startDate: filters?.daterange?.length ? filters.daterange[0] : null,
        endDate: filters?.daterange?.length ? filters.daterange[1] : null,
        type: filters?.type,
        subtype: filters?.subtype,
        status: filters?.status,
        assessmentType: filters?.assessmentType,
        hazard: filters?.hazard,
        depot: filters?.depot,
        isActive: true,
        hasMajorHazard: filters?.scope && filters?.has_major_hazard,
        hasVraReadyForSignOff:
          filters?.scope && filters?.has_vra_ready_for_sign_off,
        hasNoVra: filters?.scope && filters?.has_no_vra,
        workspaceUuid: selectedWorkspaceUuid,
      });
    }
    yield put(setMapJobs(data.results));
    yield put(setLastRefresh(Dates.getLastRefresh()));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* fetchJobStatsFn({ workspaceId, userIds }) {
  try {
    yield put(fetchJobStats());
    const { data: statsData } = yield Job.getJobStats(workspaceId, userIds);
    yield put(setJobStats(statsData));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* fetchLegacyViewJobs({ filters, page = 1 }) {
  try {
    const { selectedWorkspace } = yield select((state) => state.session);
    const { data: searchData } = yield Search.jobs({
      page,
      page_size: 100,
      ...filters,
      ...(filters.assessmentType
        ? { assessmenttype: filters.assessmentType }
        : {}),
      ...(filters.assignees && {
        assignees: filters.assignees.map((assignee) => assignee.id),
      }),
      ...(filters.daterange && {
        daterange: [
          new Date(filters.daterange[0]).toISOString(),
          new Date(filters.daterange[1]).toISOString(),
        ],
      }),
      workspace_uuid: selectedWorkspace?.uuid,
    });
    yield put(
      setLegacyViewJobs(
        searchData.results,
        searchData.next,
        searchData.count,
        page,
      ),
    );
    if (filters?.scope) {
      const { data: statsData } = yield Job.getLegacyViewJobStats(
        selectedWorkspace?.uuid,
      );
      yield put(setLegacyViewJobStats(statsData));
    }
    yield put(setLastRefresh(Dates.getLastRefresh()));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* fetchMoreLegacyViewJobs({ url }) {
  try {
    const { data: searchData } = yield Search.jobsUrl(url);
    yield put(appendLegacyViewJobs(searchData.results, searchData.next));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* fetchActiveJob({ jobId, options }) {
  try {
    const { data } = yield Job.get(jobId);
    yield put(setActiveJob(data, options?.updateAllJobs));
    yield put(setLastRefresh(Dates.getLastRefresh()));
    yield put(setPsiDismissed(false));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* updateJob({ jobId }) {
  // check if we have the job in the store currently
  const currentJobs = yield select((state) => state.jobs.jobs);
  const { selectedWorkspace } = yield select((state) => state.session);
  const existingIndex =
    currentJobs?.findIndex((currentJob) => currentJob.id === jobId) ?? -1;
  if (existingIndex > -1) {
    try {
      const { data: statsData } = yield Job.getLegacyViewJobStats(
        selectedWorkspace?.uuid,
      );
      yield put(setLegacyViewJobStats(statsData));
      yield put(setLastRefresh(Dates.getLastRefresh()));
    } catch (err) {
      yield put(setGlobalError(err));
      console.error(err);
    }
  }
}

function* markJobAsRead({ jobId }) {
  try {
    yield Job.markJobAsRead(jobId);
    yield put(updateJobAction(jobId));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* fetchWeatherForJob({ jobId }) {
  try {
    const { data } = yield Job.getWeatherByJob(jobId);
    yield put(setWeatherForJob(generateWeatherData(data)));
  } catch (err) {
    yield put(fetchWeatherError());
    console.error(err);
  }
}

function* fetchEvidences({ jobId }) {
  try {
    const data = yield Evidence.getAll(jobId);
    yield put(setEvidences(data));
  } catch (err) {
    yield put(setGlobalError(err));
    yield put(fetchEvidencesError());
    console.error(err);
  }
}

function* fetchDocuments({ jobId }) {
  try {
    const data = yield Document.getAll(jobId);
    const signedUrlDocumentList = yield getSignedUrls(data);
    yield put(setDocuments(jobId, signedUrlDocumentList));
  } catch (err) {
    yield put(fetchDocumentsError());
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* fetchWorkOrderItems({ jobId }) {
  try {
    const data = yield WorkOrderItem.getAll(jobId);
    yield put(setWorkOrderItems(jobId, data));
  } catch (err) {
    yield put(fetchWorkOrderItemsError());
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* fetchPermits({ jobId }) {
  try {
    const data = yield Permit.getAll(jobId);
    yield put(setPermits(jobId, data));
  } catch (err) {
    yield put(fetchPermitsError());
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* fetchPhotosForJob({ jobId }) {
  try {
    const data = yield Evidence.getEvidencePhotos(jobId);
    const signedUrlPhotoList = yield getSignedUrls(data);
    yield put(setJobPhotos(signedUrlPhotoList));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* fetchLogs({ jobId }) {
  try {
    const data = yield Log.getAll(jobId);
    yield put(setLogs(jobId, data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchLogsError());
    yield put(setGlobalError(err));
  }
}

function* fetchJobBlockers({ jobId }) {
  try {
    const data = yield Log.getAllJobBlockers(jobId);
    yield put(setJobBlockers(jobId, data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchJobBlockersError());
    yield put(setGlobalError(err));
  }
}

function* addMeToActiveJob() {
  try {
    const currentUserId = yield select(selectCurrentUserId);
    const activeJob = yield select(selectActiveJob);
    const currentAssigneeIds =
      activeJob?.assignees.map((user) => user.id) ?? [];
    yield Job.updateDetails(activeJob?.id, {
      assignees: [...currentAssigneeIds, currentUserId],
    });
    yield put(addMeToJobSuccess(currentUserId));
    yield put(setSuccess('added_to_job'));

    const activeRamsAssessment = yield select(selectActiveRamsAssessment);
    if (activeRamsAssessment) {
      yield put(fetchAssessment(activeRamsAssessment.id));
    }
  } catch (err) {
    yield put(addMeToJobError());
    yield put(setGlobalError(err));
  }
}

export default function* jobsWatcher() {
  yield takeLatest(FETCH_LIST_JOBS, fetchListJobs);
  yield takeLatest(FETCH_MAP_JOBS, fetchMapJobs);
  yield throttle(30000, FETCH_JOB_STATS_THROTTLED, fetchJobStatsFn);
  yield takeLatest(FETCH_LEGACY_VIEW_JOBS, fetchLegacyViewJobs);
  yield takeLatest(FETCH_MORE_LEGACY_VIEW_JOBS, fetchMoreLegacyViewJobs);
  yield takeLatest(FETCH_ACTIVE_JOB, fetchActiveJob);
  yield takeLatest(UPDATE_JOB, updateJob);
  yield takeLatest(JOB_MARK_AS_READ, markJobAsRead);
  yield takeLatest(FETCH_WEATHER_FOR_JOB, fetchWeatherForJob);
  yield takeLatest(FETCH_EVIDENCES, fetchEvidences);
  yield takeLatest(FETCH_DOCUMENTS, fetchDocuments);
  yield takeLatest(FETCH_LOGS, fetchLogs);
  yield takeLatest(FETCH_JOB_BLOCKERS, fetchJobBlockers);
  yield takeLatest(FETCH_PERMITS, fetchPermits);
  yield takeLatest(FETCH_JOB_PHOTOS, fetchPhotosForJob);
  yield takeLatest(FETCH_WORK_ORDER_ITEMS, fetchWorkOrderItems);
  yield takeLatest(ADD_ME_TO_JOB, addMeToActiveJob);
}

import {
  FETCH_ASSESSMENTS,
  SET_ASSESSMENTS,
  UPDATE_ASSESSMENT,
  SET_ASSESSMENT,
  SET_UPDATED_ASSESSMENT,
  FETCH_ASSESSMENT,
  SEND_SUGGESTION,
  CLEAR_ASSESSMENT,
  SET_ASSESSMENT_ERROR,
  ASSESSMENT_SUMMARY_FEEDBACK_SUCCESS,
  REQUEST_NEW_VRA,
  REQUEST_NEW_VRA_SUCCESS,
  REQUEST_NEW_VRA_ERROR,
  GENERATE_ASSESSMENT_PDF,
  GENERATE_ASSESSMENT_PDF_SUCCESS,
  GENERATE_ASSESSMENT_PDF_ERROR,
  SEND_ASSESSMENT_SUMMARY_FEEDBACK,
  ASSESSMENT_SUMMARY_FEEDBACK_ERROR,
} from '../actions/actionTypes';

const defaultState = {
  assessments: [],
  isAssessmentsLoaded: false,
  activeAssessment: null,
  isActiveAssessmentLoaded: false,
  isAssessmentUpdating: false,
  assessmentError: '',
  isSendingSummaryFeedback: false,
  isSummaryFeedbackSuccess: false,
  isRequestingNewVra: false,
  isGeneratingPdf: false,
};

const assessments = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ASSESSMENTS: {
      return {
        ...state,
        isAssessmentsLoaded: false,
      };
    }
    case SET_ASSESSMENTS: {
      return {
        ...state,
        assessments: action.assessments || [],
        isAssessmentsLoaded: true,
      };
    }
    case UPDATE_ASSESSMENT:
    case SEND_SUGGESTION: {
      return {
        ...state,
        isAssessmentUpdating: true,
      };
    }
    case SET_UPDATED_ASSESSMENT: {
      return {
        ...state,
        isAssessmentUpdating: false,
        assessments: state.assessments.map((assessment) => {
          if (assessment.id === action.assessmentId) {
            return { ...assessment, ...action.newAssessment };
          }
          return assessment;
        }),
        ...(state.activeAssessment.id === action.assessmentId
          ? {
              activeAssessment: {
                ...state.activeAssessment,
                ...action.newAssessment,
              },
            }
          : {}),
      };
    }
    case FETCH_ASSESSMENT: {
      return {
        ...state,
        isActiveAssessmentLoaded: false,
        assessmentError: '',
      };
    }
    case SET_ASSESSMENT: {
      return {
        ...state,
        activeAssessment: action.assessment,
        isActiveAssessmentLoaded: true,
        isAssessmentUpdating: false,
      };
    }
    case CLEAR_ASSESSMENT: {
      return {
        ...state,
        activeAssessment: null,
        isActiveAssessmentLoaded: false,
      };
    }
    case SET_ASSESSMENT_ERROR: {
      return {
        ...state,
        assessmentError: action.error || '',
      };
    }
    case SEND_ASSESSMENT_SUMMARY_FEEDBACK: {
      return { ...state, isSendingSummaryFeedback: true };
    }
    case ASSESSMENT_SUMMARY_FEEDBACK_SUCCESS: {
      return {
        ...state,
        activeAssessment: {
          ...state.activeAssessment,
          ...(action.video && {
            video: { ...state.activeAssessment?.video, ...action.video },
          }),
        },
        isSendingSummaryFeedback: false,
        isSummaryFeedbackSuccess: true,
      };
    }
    case ASSESSMENT_SUMMARY_FEEDBACK_ERROR: {
      return {
        ...state,
        isSendingSummaryFeedback: false,
        isSummaryFeedbackSuccess: false,
      };
    }
    case REQUEST_NEW_VRA:
      return { ...state, isRequestingNewVra: true };
    case REQUEST_NEW_VRA_SUCCESS:
      return {
        ...state,
        activeAssessment: { ...state.activeAssessment, isNewRequested: true },
        isRequestingNewVra: false,
      };
    case REQUEST_NEW_VRA_ERROR:
      return { ...state, isRequestingNewVra: false };
    case GENERATE_ASSESSMENT_PDF:
      return { ...state, isGeneratingPdf: true };
    case GENERATE_ASSESSMENT_PDF_SUCCESS:
    case GENERATE_ASSESSMENT_PDF_ERROR:
      return { ...state, isGeneratingPdf: false };
    default:
      return state;
  }
};

export default assessments;

import { CSSProperties } from 'styled-components';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { useText } from 'hooks';
import { useSelector } from 'react-redux';
import { createFilterOptions, TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import {
  Label,
  RequiredLabel,
  StyledAutocomplete,
  StyledOptions,
} from './styled';
import { Container, LabelWrapper } from '../styled';

type OptionPropTypes = {
  id: number;
  title: string;
};

type OptionProps =
  | {
      options?: OptionPropTypes[];
      defaultSelected?: OptionPropTypes;
      selected?: OptionPropTypes;
    }
  | {
      options?: any[];
      renderOption: (props: any, option: any) => JSX.Element;
      defaultSelected?: any;
      selected?: any;
    };

type Props = OptionProps & {
  id: string;
  label?: string;
  labelColor?: string;

  onChange?: (event: ChangeEvent<HTMLInputElement>, option: any) => void;
  onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  fitContent?: boolean;
  large?: boolean;
  grey?: boolean;
  noBorder?: boolean;
  required?: boolean;
  isInsights?: boolean;
  isLocation?: boolean;
  isLoading?: boolean;
  noOptionsText?: string;
  renderOption?: (props: any, options: any) => JSX.Element;
  placeholder?: string;
  extraStyles?: CSSProperties;
};

// Input.SelectWithSearch
export const SelectWithSearch = ({
  id,
  label,
  labelColor,
  defaultSelected,
  selected,
  options,
  onChange = () => {},
  onInputChange = () => {},
  disabled = false,
  fitContent = false,
  required = false,
  large = false,
  isLoading,
  noOptionsText,
  renderOption,
  placeholder,
  extraStyles,
}: Props): JSX.Element => {
  const getText = useText();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  const getFilterOptionsFunction = () => {
    // this is to help the Mui Autocomplete component handle isLoading prop as expected
    // if we are using isLoading, I want to see the Loading indicator when isLoading is true
    // but it was returning the existing options instead
    // so I am forcing the options filter to return []
    if (isLoading === undefined) {
      return createFilterOptions({
        stringify: (option: { label: string; id: number }) => option?.label,
      });
    }
    return isLoading ? () => [] : (options) => options;
  };

  return (
    <Container style={extraStyles}>
      {label && (
        <LabelWrapper>
          <Label
            htmlFor={id}
            color={labelColor}
            $large={large}
            disabled={disabled}
          >
            {label}
          </Label>
          {required && (
            <RequiredLabel htmlFor={id}>{getText('required')}</RequiredLabel>
          )}
        </LabelWrapper>
      )}
      <StyledAutocomplete
        options={options?.map((option) => ({
          ...option,
          label: option[`title_${globalAppLanguage}`] || option.title,
          id: option.id,
        }))}
        defaultValue={
          (defaultSelected &&
            (defaultSelected[`title_${globalAppLanguage}`] ||
              defaultSelected.title)) ||
          ''
        }
        value={
          (selected?.[`title_${globalAppLanguage}`] || selected?.title) ?? ''
        }
        $large={large}
        disabled={disabled}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        renderOption={
          renderOption
            ? renderOption
            : (params, option) => {
                return (
                  <StyledOptions
                    fitContent={fitContent}
                    $large={large}
                    {...params}
                    key={option.id}
                    selected={selected?.id === option.id}
                    id={option.id}
                  >
                    {option.label}
                  </StyledOptions>
                );
              }
        }
        disableClearable
        onChange={onChange}
        onInputChange={onInputChange}
        filterOptions={getFilterOptionsFunction()}
        loading={isLoading ?? false}
        noOptionsText={noOptionsText}
      />
    </Container>
  );
};

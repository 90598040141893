import { TextField } from '@mui/material';
import { Base } from '../Base';

type Props = {
  id: string;
  value: string | number;
  label?: string;
  labelColor?: string;
  guidance?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  extraStyles?: React.CSSProperties;
  hasBlackBackground?: boolean;
  isSearch?: boolean;
  onEnter?: () => void;
  maxLength?: number;
  autoFocusOnRender?: boolean;
};

// Input.Text
export const Text = ({ value, ...props }: Props): JSX.Element => (
  <Base value={value ?? ''} {...props} Input={TextField} />
);

import styled from 'styled-components';
import { Search } from 'assets/icons';
import theme from 'theme';

const { colors } = theme;

export const MapWrapper = styled.div<{ height: string; width: string }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  position: relative;
`;

export const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.lightGrey};
  .gm-fullscreen-control {
    left: 0;
    top: unset !important;
    bottom: 10px !important;
  }
`;

export const SearchContainer = styled.div<{ width: string }>`
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
`;

export const SearchIcon = styled(Search)`
  height: 25px;
  width: 25px;
  position: absolute;
  top: 30px;
  right: 5%;
`;

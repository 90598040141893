import React from 'react';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { useSelector } from 'react-redux';
import { useText } from 'hooks';
import { capitalizeFirstLetter } from 'helpers/names';
import { FlexColumn, Header, Icon, IconBox, NoItemText, Title } from './styled';
import { ListItem } from './ListItem';
import { getLocalizedValue } from 'helpers/locales';

type ItemPropTypes = {
  id: number;
  title: string;
};

type Props = {
  items: ItemPropTypes[];
  title?: string;
  needEmptyIcon?: boolean;
  IconComponent?: React.ElementType;
  children?: JSX.Element;
  extraItemStyle?: React.CSSProperties;
  extraHeaderStyle?: React.CSSProperties;
};

export const UnorderedList = ({
  items,
  title,
  needEmptyIcon = true,
  IconComponent,
  children,
  extraItemStyle,
  extraHeaderStyle,
}: Props): JSX.Element => {
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const getText = useText();
  return (
    <FlexColumn>
      {title && (
        <Header style={extraHeaderStyle}>
          <Title>{title}</Title>
          {IconComponent && (
            <IconBox>
              <Icon component={<IconComponent />} />
            </IconBox>
          )}
        </Header>
      )}
      {children}

      <FlexColumn>
        {items?.map((item) => (
          <ListItem
            key={`item-${item.id}`}
            title={capitalizeFirstLetter(
              getLocalizedValue(item, 'title', globalAppLanguage),
            )}
            extraStyles={extraItemStyle}
          />
        ))}
        {needEmptyIcon && (!items || items.length === 0) && (
          <NoItemText>{getText('job_forms_not_reported')}</NoItemText>
        )}
      </FlexColumn>
    </FlexColumn>
  );
};

export type Reason = {
  id: number;
  value: string;
};

export const EVENT_WRAP_UP_INSIGHTS_THUMBS_UP = 'wrap_up_insights_thumbs_up';
export const EVENT_WRAP_UP_INSIGHTS_THUMBS_DOWN =
  'wrap_up_insights_thumbs_down';
export const EVENT_WRAP_UP_INSIGHTS_TELL_US_MORE =
  'wrap_up_insights_tell_us_more';

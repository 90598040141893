import { css } from 'styled-components';

const breakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
  xxxl: 1750,
  hd: 1920,
};

const keys = Object.keys(breakpoints) as Array<keyof typeof breakpoints>;

const mediaQueries = keys.reduce(
  (accumulator, label) => {
    const emSize = breakpoints[label] / 16;
    accumulator[label] = (...args: Parameters<typeof css>) => css`
      @media (max-width: ${emSize}em) {
        ${css(...args)};
      }
    `;
    return accumulator;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  },
  {} as Record<keyof typeof breakpoints, any>,
);

export default mediaQueries;

import { useLocation } from 'react-router-dom';

export default () => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  const params: { [key: string]: string } = {};

  urlParams.forEach((value, key) => {
    params[key] = value;
  });

  const getPathWithQueryParams = (pathname: string) => `${pathname}${search}`;

  return { params, getPathWithQueryParams };
};

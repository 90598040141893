import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: ${colors.white};
  padding: 32px;
  min-height: 570px;
  box-sizing: border-box;
  margin-top: 15px;
`;

export const DataContainer = styled.div`
  width: 55%;
  box-sizing: border-box;
`;

export const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  padding-bottom: 20px;
`;

export const ItemsContainer = styled.div`
  height: 500px;
  overflow-y: auto;
  margin-top: 15px;
`;

export const EmptyListContainer = styled.div`
  margin-top: 25px;
`;

export const FormContainer = styled.div`
  width: 40%;
`;

export const InputContainer = styled.div<{ $swapOrder: boolean }>`
  ${mixins.flexColumn};
  padding-top: 50px;
  ${({ $swapOrder }) => $swapOrder && `flex-direction: column-reverse`};
  > div {
    width: 70%;
    margin-bottom: 20px;
    ${media.lg`
     width: 100%;
  `}
  }
`;

export const ActionButtonContainer = styled.div`
  ${mixins.flexCenter};
  margin-top: 25px;
`;

export const ErrorMessage = styled.div`
  color: ${colors.red};
  margin-top: 50px;
`;

import React from 'react';
import styled from 'styled-components';
import { Arrow, Plus } from 'assets/icons';
import { Size } from 'model/enum/Size';
import theme from 'theme';

const { colors } = theme;

export const Box = styled.div<{ $boxed: boolean; size: Size }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ $boxed }) => ($boxed ? colors.black : 'transparent')};
  padding: ${({ size, $boxed }) => {
    if (!$boxed) {
      return '0';
    }

    if (size === Size.SMALL) {
      return '2px';
    }
    if (size === Size.NORMAL) {
      return '3px';
    }
    if (size === Size.LARGE) {
      return '5px';
    }
  }};
`;

export const Image = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: ${({ size }) => {
    if (size === Size.SMALL) {
      return '12px';
    }
    if (size === Size.NORMAL) {
      return '22px';
    }
    if (size === Size.LARGE) {
      return '32px';
    }
  }};
  width: ${({ size }) => {
    if (size === Size.SMALL) {
      return '12px';
    }
    if (size === Size.NORMAL) {
      return '22px';
    }
    if (size === Size.LARGE) {
      return '32px';
    }
  }};
`;

export const RightArrowIcon = styled(Arrow)`
  width: 48px;
  height: 43px;
  .fill-selector {
    fill: ${colors.black};
  }
`;

export const PlusWhiteIcon = styled(Plus)`
  width: 16px;
  height: 16px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

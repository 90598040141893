import * as constants from 'utils/constants';
import { Input } from 'components/ui';
import { RawFooter } from 'components/ui/Footer/';
import { useText } from 'hooks';
import { Form } from 'components/ui/Form';
import SignUpData from 'model/SignUpData';
import { SubmitHandler } from 'react-hook-form';
import { FormWrapper, InputHolder, WrapperFooter } from './styled';

export const RoleIds = {
  fieldworker: 1,
  manager: 2,
};

export const RoleMap = {
  [RoleIds.fieldworker]: 'fieldworker',
  [RoleIds.manager]: 'manager',
};

type Props = {
  formData: SignUpData;
  errors: SignUpData;
  onSubmit: SubmitHandler<{}>;
  loading: boolean;
};

export const SignUpForm = ({
  formData,
  errors,
  onSubmit,
  loading,
}: Props): JSX.Element => {
  const getText = useText();

  const roleOptions = [
    {
      id: RoleIds.fieldworker,
      title: getText('signup_self_description_fieldworker'),
    },
    { id: RoleIds.manager, title: getText('signup_self_description_manager') },
  ];

  const RequiredFieldMessage = getText('sign_up_validation_required');

  return (
    <FormWrapper>
      <InputHolder>
        <Form
          inputFields={[
            {
              input: <Input.ClearableInput />,
              id: 'email',
              label: getText('sign_up_confirm_email'),
              value: formData.email,
              validations: {
                required: RequiredFieldMessage,
                pattern: {
                  value: constants.Regex.email,
                  message: getText('sign_up_validation_invalid_email'),
                },
              },
              fieldError: errors.email,
            },
            {
              input: <Input.ClearableInput />,
              id: 'firstName',
              label: getText('add_user_first_name'),
              value: formData.firstName,
              validations: { required: RequiredFieldMessage },
              fieldError: errors.firstName,
            },
            {
              input: <Input.ClearableInput />,
              id: 'lastName',
              label: getText('add_user_last_name'),
              value: formData.lastName,
              validations: { required: RequiredFieldMessage },
              fieldError: errors.lastName,
            },
            {
              input: <Input.PhoneNumber id='phoneNumber' />,
              id: 'phoneNumber',
              label: getText('user_phone_number'),
              value: formData.phoneNumber,
              validations: {
                required: RequiredFieldMessage,
                validate: {
                  isPossiblePhoneNumber: (phoneNumber) =>
                    constants.Regex.phoneNumber.test(phoneNumber) ||
                    getText('sign_up_validation_invalid_phone_number'),
                },
              },
              fieldError: errors.phoneNumber,
            },
            {
              input: (
                <Input.Select
                  hint={getText('add_user_select_hint')}
                  options={roleOptions}
                  large
                  id='roleId'
                />
              ),
              id: 'roleId',
              label: getText('sign_up_role'),
              value: formData.roleId,
              validations: { required: RequiredFieldMessage },
              fieldError: errors.roleId ?? '',
            },
          ]}
          submitButtonEvent={constants.EVENT_CLICK_SIGN_UP}
          submitButtonText={getText('submit')}
          onSubmit={onSubmit}
          isLoading={loading}
        />
      </InputHolder>
      <WrapperFooter>
        <RawFooter termsFirst />
      </WrapperFooter>
    </FormWrapper>
  );
};

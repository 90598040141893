import styled from 'styled-components';

export const InstructionWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  word-break: break-word;
`;
export const InstructionContent = styled.div`
  margin-top: 20px;
  padding: 10px;
  p {
    padding-top: 15px;
  }
  ul {
    padding: revert;
  }
`;

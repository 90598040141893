import { useText } from 'hooks';
import ManageTypesBase from './ManageTypesBase';
import {
  selectEvidenceTypeErrors,
  selectGlobalLanguageSetting,
  selectEvidenceTypes,
  selectIsFetchingEvidenceTypes,
  selectIsLoadingEvidenceChanges,
} from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import RootState from 'model/State/RootState';
import {
  fetchEvidenceTypes,
  resetEvidenceTypeErrors,
  saveEvidenceTypeChanges,
} from 'store/actions/workspace';
import { useCustomLocaleText } from 'hooks/useText';
import { Languages } from 'utils/constants';
import { WorkspaceItemStatus } from 'model/enum/WorkspaceItemStatus';
import * as Analytics from 'utils/analytics';
import { addItem, editItem } from 'helpers/workspace';
import {
  EVENT_WORKSPACE_SETTINGS_EVIDENCE_TYPES_ADDED,
  EVENT_WORKSPACE_SETTINGS_EVIDENCE_TYPES_DELETED,
  EVENT_WORKSPACE_SETTINGS_EVIDENCE_TYPES_EDITED,
  EVENT_WORKSPACE_SETTINGS_EVIDENCE_TYPES_SAVED,
} from '../../constants';
import { WorkspaceItemType } from 'model/AdminPanel/WorkspaceSettings/WorkspaceItemType';

const EvidenceSettings = (): JSX.Element => {
  const getText = useText();
  const getEnglishText = useCustomLocaleText(Languages.english.locale);
  const getSpanishText = useCustomLocaleText(Languages.spanish.locale);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const isLoadingEvidenceChanges = useSelector(selectIsLoadingEvidenceChanges);
  const evidenceTypeErrors = useSelector(selectEvidenceTypeErrors);
  const dispatch = useDispatch();
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const initialEvidenceTypes = useSelector(
    selectEvidenceTypes(selectedWorkspace?.uuid),
  );
  const isFetchingEvidenceTypes = useSelector(selectIsFetchingEvidenceTypes);
  const [evidenceTypes, setEvidenceTypes] =
    useState<WorkspaceItemType[]>(initialEvidenceTypes);

  useEffect(() => {
    dispatch(resetEvidenceTypeErrors());
    if (selectedWorkspace?.uuid) {
      dispatch(fetchEvidenceTypes(selectedWorkspace?.uuid));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEvidenceTypes(initialEvidenceTypes);
  }, [initialEvidenceTypes]);

  const onAddOrEdit = (
    isUpdate: boolean,
    index: number,
    englishText: string,
    spanishText: string,
  ) => {
    if (isUpdate && index > -1) {
      const modifiedList = editItem(
        initialEvidenceTypes,
        evidenceTypes,
        index,
        englishText,
        spanishText,
        EVENT_WORKSPACE_SETTINGS_EVIDENCE_TYPES_EDITED,
      );
      setEvidenceTypes(modifiedList);
    } else {
      const modifiedList = addItem(
        evidenceTypes,
        englishText,
        spanishText,
        EVENT_WORKSPACE_SETTINGS_EVIDENCE_TYPES_ADDED,
      );
      setEvidenceTypes(modifiedList);
    }
  };

  const onRemove = (index: number, id: number | null) => {
    Analytics.trackEvent(EVENT_WORKSPACE_SETTINGS_EVIDENCE_TYPES_DELETED, {
      evidenceType: evidenceTypes[index],
    });
    if (id) {
      setEvidenceTypes(
        evidenceTypes.map((type) =>
          type.id === id
            ? { ...type, status: WorkspaceItemStatus.DELETED }
            : type,
        ),
      );
    } else if (index > -1) {
      setEvidenceTypes((prevState) => prevState.filter((_, i) => i !== index));
    }
  };

  const onSubmit = () => {
    const changedFields = evidenceTypes.filter((type) => type?.status);
    dispatch(saveEvidenceTypeChanges(selectedWorkspace?.uuid, changedFields));

    Analytics.trackEvent(EVENT_WORKSPACE_SETTINGS_EVIDENCE_TYPES_SAVED, {
      changed_fields: changedFields,
    });
  };

  return (
    <ManageTypesBase
      manageTitle={getText('workspace_settings_manage_evidence')}
      addEditTitle={getText('workspace_settings_add_edit_evidence')}
      noTypeText={getText('workspace_settings_no_evidence')}
      label={getEnglishText('workspace_settings_evidence_label')}
      labelEs={getSpanishText('workspace_settings_evidence_label')}
      types={evidenceTypes}
      loading={isFetchingEvidenceTypes}
      swapOrder={globalAppLanguage !== Languages.english.locale}
      onAddOrEditPress={onAddOrEdit}
      onSubmit={onSubmit}
      isSubmitting={isLoadingEvidenceChanges}
      hasChangedFields={evidenceTypes.some((type) => type?.status)}
      onRemove={onRemove}
      errors={evidenceTypeErrors}
    />
  );
};

export default EvidenceSettings;

import {
  BackArrow,
  ColumnContainer,
  FlexContainer,
  Header,
  HeaderWrapper,
  IconContainer,
  Subtitle,
  Title,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { RamsStages } from 'model/Assessment/RAMS';
import * as Analytics from 'utils/analytics';
import { EVENT_RAMS_SUMMARY_MENU_BUTTON_CLICKED } from './constants';
import { EVENT_PROPERTIES_PAGE_SOURCE_FIELD } from 'utils/constants';
import { selectJobsView } from 'store/selectors';
import { setAssessmentStage } from 'store/slices/ramsAssessments';

type Props = {
  title: string;
  subtitle?: string;
};

export const SectionHeader = ({ title, subtitle }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const view = useSelector(selectJobsView);

  const navigateBackToMenu = () => {
    Analytics.trackEvent(EVENT_RAMS_SUMMARY_MENU_BUTTON_CLICKED, {
      [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    });
    dispatch(setAssessmentStage(RamsStages.ramsActionSelection));
  };

  return (
    <HeaderWrapper $large={!!subtitle}>
      <Header>
        <FlexContainer>
          <IconContainer onClick={navigateBackToMenu} disableRipple>
            <BackArrow />
          </IconContainer>

          <ColumnContainer>
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </ColumnContainer>
        </FlexContainer>
      </Header>
    </HeaderWrapper>
  );
};

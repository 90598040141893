import styled from 'styled-components';
import theme from 'theme';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  TableCell as MuiTableCell,
} from '@mui/material';

const { colors } = theme;

export const AnchorElement = styled.span`
  position: absolute;
  top: -50px;
`;

export const AccordionWrapper = styled(MuiTableCell)`
  &.MuiTableCell-root {
    padding: 0;
    position: relative;
  }
`;

export const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    box-shadow: none;

    &.Mui-expanded {
      margin: 0;
    }
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0;
    &:hover {
      cursor: default !important;
    }
    &.MuiAccordionSummary-content {
      margin: 0;
    }
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  &.MuiAccordionDetails-root {
    border-top: 1px solid ${colors.lightGrey};
    padding-right: 0;
  }
`;

export const TableCell = styled(({ width = 100, ...rest }) => (
  <MuiTableCell {...rest} />
))`
  &.MuiTableCell-root {
    width: ${({ width }) => width}%;
    border: none;
    padding: 10px 5px;
  }
`;

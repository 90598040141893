import React from 'react';
import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';
import {
  StyledItemWrapper,
  StyledItem,
  StyledActions,
  ItemText,
} from './styled';
import { ItemAction } from './components/ItemAction';
import { Pencil } from 'assets/icons';

export interface Props {
  dragOverlay?: boolean;
  color?: string;
  disabled?: boolean;
  dragging?: boolean;
  handle?: boolean;
  handleProps?: any;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  title: string;
  subtitle: string | string[];
  onEditClick?: () => void;
  onRemoveClick?: () => void;
}

export const Item = React.memo(
  React.forwardRef<HTMLDivElement, Props>(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemoveClick,
        onEditClick,
        sorting,
        transition,
        transform,
        title,
        subtitle,
        wrapperStyle,
        ...props
      },
      ref,
    ) => {
      return (
        <StyledItemWrapper
          $fadeIn={fadeIn}
          $dragOverlay={dragOverlay}
          style={
            {
              transition,
              '--translate-x': transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              '--translate-y': transform
                ? `${Math.round(transform.y)}px`
                : undefined,
              '--scale-x': transform?.scaleX
                ? `${transform.scaleX}`
                : undefined,
              '--scale-y': transform?.scaleY
                ? `${transform.scaleY}`
                : undefined,
              '--index': index,
              '--color': color,
            } as React.CSSProperties
          }
          ref={ref}
        >
          <StyledItem
            $dragOverlay={dragOverlay}
            $dragging={dragging}
            $noHandle={!!handle}
            $disabled={disabled}
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            <ItemText>
              <h3>{title}</h3>
              {subtitle}
            </ItemText>
            <StyledActions>
              <ItemAction
                icon={<Pencil />}
                onClick={onEditClick}
                disabled={disabled}
              />

              {onRemoveClick ? (
                <ItemAction
                  onClick={onRemoveClick}
                  disabled={disabled}
                  icon={
                    <svg
                      width='8'
                      viewBox='0 0 22 22'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M2.99998 -0.000206962C2.7441 -0.000206962 2.48794 0.0972617 2.29294 0.292762L0.292945 2.29276C-0.0980552 2.68376 -0.0980552 3.31682 0.292945 3.70682L7.58591 10.9998L0.292945 18.2928C-0.0980552 18.6838 -0.0980552 19.3168 0.292945 19.7068L2.29294 21.7068C2.68394 22.0978 3.31701 22.0978 3.70701 21.7068L11 14.4139L18.2929 21.7068C18.6829 22.0978 19.317 22.0978 19.707 21.7068L21.707 19.7068C22.098 19.3158 22.098 18.6828 21.707 18.2928L14.414 10.9998L21.707 3.70682C22.098 3.31682 22.098 2.68276 21.707 2.29276L19.707 0.292762C19.316 -0.0982383 18.6829 -0.0982383 18.2929 0.292762L11 7.58573L3.70701 0.292762C3.51151 0.0972617 3.25585 -0.000206962 2.99998 -0.000206962Z' />
                    </svg>
                  }
                />
              ) : null}
              {handle ? (
                <ItemAction
                  {...handleProps}
                  {...listeners}
                  icon={
                    <svg viewBox='0 0 20 20' width='12'>
                      <path d='M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z'></path>
                    </svg>
                  }
                  canGrab
                  isGrabbing={dragOverlay}
                  disabled={disabled}
                />
              ) : null}
            </StyledActions>
          </StyledItem>
        </StyledItemWrapper>
      );
    },
  ),
);

import {
  FETCH_JOB_SUBTYPES,
  FETCH_JOB_SUBTYPES_ERROR,
  FETCH_JOB_SUBTYPES_SUCCESS,
  FETCH_JOB_TYPES,
  FETCH_JOB_TYPES_ERROR,
  FETCH_JOB_TYPES_SUCCESS,
} from './actionTypes';

export const fetchJobTypes = (workspaceUuid) => ({
  type: FETCH_JOB_TYPES,
  workspaceUuid,
});

export const fetchJobTypesSuccess = (data) => ({
  type: FETCH_JOB_TYPES_SUCCESS,
  data,
});

export const fetchJobTypesError = () => ({
  type: FETCH_JOB_TYPES_ERROR,
});

export const fetchJobSubtypes = (workspaceUuid) => ({
  type: FETCH_JOB_SUBTYPES,
  workspaceUuid,
});

export const fetchJobSubtypesSuccess = (data) => ({
  type: FETCH_JOB_SUBTYPES_SUCCESS,
  data,
});

export const fetchJobSubtypesError = () => ({
  type: FETCH_JOB_SUBTYPES_ERROR,
});

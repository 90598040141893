import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Loader } from 'components/ui';
import { useText } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { SortOrder } from 'model/enum/SortOrder';
import Sort from 'model/Sort';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import HavsComplianceDataTable from './HavsComplianceDataTable';
import { ComplianceType } from 'model/enum/ComplianceType';
import {
  selectActiveWorkspaceUuid,
  selectHavsComplianceData,
  selectIsLoadingHavsCompliance,
} from 'store/selectors';
import { fetchHavsCompliance } from 'store/actions/insights';
import { getHavsComplianceDataExport } from 'api/Insights';
import { downloadBlob } from 'helpers/files';
import * as Logger from 'utils/logger';
import { setGlobalError } from 'store/slices/notifications';
import { formatDateForDataExport } from 'helpers/dates';
import RootState from 'model/State/RootState';
import {
  HavsComplianceData,
  HavsComplianceFields,
} from 'model/Insight/HavsComplianceData';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalType } from 'model/enum/ModalType';
import { stringifyUserIds } from 'api/Insights/helpers';
import { ExportExcelButton } from '../../DataExport/ExportExcelButton';

const { colors, fonts, fontSizes, mixins, media } = theme;

// Styled Components
const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 40px !important;
`;

const DataContainer = styled.div`
  padding: 40px 80px;
  background-color: ${colors.offWhite};
  min-height: 100%;
`;

const DataRow = styled.div`
  height: 650px;
  display: flex;
  background: ${colors.white};
  padding: 32px;
  ${media.lg`
    padding: 16px;
  `};
`;

const HeaderContainer = styled.div`
  ${mixins.flexBetween}
  align-items: flex-start;
`;

type Props = {
  depotId: number;
  search: string;
  startDate: Date;
  endDate: Date;
  isWeekendIncluded: boolean;
  userFilters: number[];
};

// HavsCompliance
const HavsCompliance = ({
  depotId,
  search,
  startDate,
  endDate,
  isWeekendIncluded,
  userFilters,
}: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useSelector((state: RootState) => state.session);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);

  const initialSort = {
    field: HavsComplianceFields.SUBMISSIONS,
    order: SortOrder.DESCENDING,
  };
  const [sort, setSort] = useState<Sort>(initialSort);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isGeneratingExport, setIsGeneratingExport] = useState(false);
  const userIds = stringifyUserIds(userFilters);

  const isLoading = useSelector(selectIsLoadingHavsCompliance);
  const {
    count: total,
    columns: complianceColumns,
    results: complianceData,
  } = useSelector(selectHavsComplianceData);

  const getComplianceData = useCallback(
    (page: number = 1) => {
      if (depotId) {
        dispatch(
          fetchHavsCompliance(
            depotId,
            startDate,
            endDate,
            !isWeekendIncluded,
            sort,
            userIds,
            search,
            selectedWorkspaceUuid,
            page,
          ),
        );

        Analytics.trackEvent(
          `${ComplianceType.HAVS}-${constants.EVENT_COMPLIANCE_LOADED}`,
          {
            depotId,
            startDate,
            endDate,
            isWeekendIncluded,
            sort,
            hasFilters: !!userIds,
            ...(!!userIds && { userIds }),
            search,
            page,
          },
        );
      }
      setCurrentPage(page);
    },
    [
      dispatch,
      depotId,
      startDate,
      endDate,
      isWeekendIncluded,
      search,
      selectedWorkspaceUuid,
      sort,
      userIds,
    ],
  );

  useEffect(() => {
    getComplianceData();
  }, [getComplianceData]);

  const onDownloadDataClick = async () => {
    try {
      setIsGeneratingExport(true);
      const blob = await getHavsComplianceDataExport(
        depotId,
        startDate,
        endDate,
        selectedWorkspaceUuid,
        isWeekendIncluded,
        userIds,
        sort,
        search,
      );

      const filename = `HAVS compliance ${formatDateForDataExport(
        startDate,
      )} - ${formatDateForDataExport(endDate)}.xlsx`;
      downloadBlob(blob, filename);
    } catch (err) {
      Logger.error(err);
      dispatch(setGlobalError(err));
    }
    setIsGeneratingExport(false);
  };

  const navigateToCommentModal = (rowData: HavsComplianceData) => {
    navigate(
      location.pathname + `?modal=${ModalType.HAVS_COMPLIANCE_COMMENT}`,
      {
        state: {
          userId: rowData.user_id,
          startDate,
          endDate,
          depotId,
          page: currentPage,
          isWeekendIncluded,
          sort,
          userIds,
          search,
        },
      },
    );
  };

  return (
    <DataContainer>
      <HeaderContainer>
        <H2>{getText('insights_havs_compliance_title')}</H2>
        <ExportExcelButton
          onClick={onDownloadDataClick}
          disabled={isGeneratingExport || isLoading || total === 0}
          loading={isGeneratingExport}
          event={constants.EVENT_HAVS_COMPLIANCE_DATA_EXPORT}
          eventProperties={{
            userId: currentUser?.id,
            depotId,
            search,
            startDate,
            endDate,
            memberIds: userIds,
            isWeekendIncluded,
          }}
        />
      </HeaderContainer>
      {complianceData ? (
        <DataRow>
          <HavsComplianceDataTable
            data={complianceData}
            columns={complianceColumns}
            total={total}
            currentPage={currentPage}
            onPageChange={(page) => getComplianceData(page)}
            sort={sort}
            setSort={setSort}
            initialSort={initialSort}
            analyticsData={{
              depotId,
              startDate,
              endDate,
              isWeekendIncluded,
              sort,
              rowsOfData: total,
            }}
            isLoading={isLoading}
            startDate={startDate}
            endDate={endDate}
            onCommentClick={navigateToCommentModal}
          />
        </DataRow>
      ) : (
        <DataRow>
          <Loader />
        </DataRow>
      )}
    </DataContainer>
  );
};

export default HavsCompliance;

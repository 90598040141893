import { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import styled from 'styled-components';
import theme from 'theme';
import { Loader } from 'components/ui';
import { useText } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import AppFunctionalityInfoModal from '../Modal/AppFunctionalityInfoModal';
import { Tooltip } from 'components/ui/Display';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { SortOrder } from 'model/enum/SortOrder';
import InsightEngagementData from 'model/Insight/InsightEngagementData';
import {
  selectActiveWorkspaceUuid,
  selectEngagementData,
  selectIsLoadingEngagement,
} from 'store/selectors';
import { fetchEngagementData } from 'store/actions/insights';
import JobData from 'model/Insight/JobData';

const { colors, fonts, fontSizes } = theme;

// Styled Components
const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 40px !important;
`;

const DataContainer = styled.div`
  padding: 40px 80px;
  background-color: ${colors.offWhite};
`;

const DataRow = styled.div`
  height: 625px;
  display: flex;
  background: ${colors.white};
  padding: 32px;
`;

const StyledDataGrid = styled(({ width, columns, ...rest }) => (
  <DataGrid columns={columns} {...rest} />
))`
  &.MuiDataGrid-root {
    border: 0;
    .MuiDataGrid-columnHeaders {
      border-bottom: 2px solid ${colors.darkGrey};
      color: ${colors.darkGrey};
      font-family: ${fonts.bold};
      width: 100%;
      > div {
        width: 100%;
      }
      .MuiDataGrid-columnSeparator {
        visibility: visible;
      }
    }
    .MuiDataGrid-columnHeader {
      min-width: ${({ width }) => width} !important;
      max-width: ${({ width }) => width} !important;
      &:focus {
        outline: 0;
      }
    }
    .MuiDataGrid-row {
      width: 100%;
      :first-of-type {
        .MuiDataGrid-cell {
          border-top: none;
        }
      }
    }
    .MuiDataGrid-row--lastVisible {
      border-bottom: 1px solid ${colors.darkGrey};
    }
    .MuiDataGrid-cell {
      min-width: ${({ width }) => width} !important;
      max-width: ${({ width }) => width} !important;
      color: ${colors.black};
      border-color: ${colors.darkGrey};
      font-family: ${fonts.default};
      &:focus {
        outline: 0;
      }
    }
    .last-column .MuiDataGrid-iconSeparator {
      display: none;
    }
    .column-engagement {
      border-right: solid;
      border-right-color: ${colors.black};
      border-right-width: 1px;
      .MuiDataGrid-iconSeparator {
        display: none;
      }
    }
    .MuiDataGrid-virtualScrollerRenderZone {
      width: 100%;
    }
    .MuiDataGrid-scrollbar {
      overflow-x: hidden;
      overflow-y: overlay;
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 12px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        min-height: 80px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
    .MuiDataGrid-footerContainer {
      display: none;
    }
    .MuiDataGrid-filler {
      display: none;
    }
  }
`;

const StyledAverageTable = styled(Table)`
  &.MuiTable-root {
    background-color: ${colors.black};
    .MuiTableCell-root {
      text-align: center;
      color: ${colors.yellow};
      font-family: ${fonts.light};
      width: 210px;
      &:first-of-type {
        text-align: start;
        font-family: ${fonts.bold};
      }
    }
  }
`;

const NumberOfJobs = styled.span`
  cursor: pointer;
`;

type Props = {
  depotId: number;
  startDate: Date;
  endDate: Date;
  isWeekendIncluded: boolean;
};

// InsightEngagement
const InsightEngagement = ({
  depotId,
  startDate,
  endDate,
  isWeekendIncluded,
}: Props): JSX.Element => {
  const [tableData, setTableData] = useState<InsightEngagementData[]>([]);
  const roundPercentage = (value) => Math.round(value);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState<{
    title: string;
    jobList?: JobData[];
  } | null>(null);

  const getText = useText();
  const dispatch = useDispatch();

  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);
  const isLoading = useSelector(selectIsLoadingEngagement);
  const engagementData = useSelector(selectEngagementData);

  const onOpenClick = (record) => {
    if (record.job_details?.length > 0) {
      setIsInfoModalOpen(true);
      setInfoModal({
        jobList: record.job_details,
        title: record.jobs_on_fyld
          ? `${record.jobs_on_fyld} ${getText('insights_summary_tooltip_job')}
    ${record.jobs_on_fyld === 1 ? '' : 's'}`
          : '',
      });
      Analytics.trackEvent(constants.EVENT_ENGAGEMENT_JOB_NUMBER_CLICKED);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: getText('insights_engagement_table_header_team_leader'),
      sortable: false,
    },
    {
      field: 'jobs_on_fyld',
      headerName: getText('insights_engagement_table_header_jobs'),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (props: GridRenderCellParams) => {
        if (props.row.jobs_on_fyld > 0) {
          return (
            <Tooltip
              title={getText('insights_engagement_table_header_jobs_tooltip')}
              triggerElement={
                <NumberOfJobs onClick={() => onOpenClick(props.row)}>
                  {props.row.jobs_on_fyld}
                </NumberOfJobs>
              }
              opacity={isInfoModalOpen ? 0 : 1}
            />
          );
        }
        return <span>{props.row.jobs_on_fyld}</span>;
      },
    },
    {
      field: 'vra_on_arrival_label',
      headerName: getText('insights_engagement_table_header_vra_arrival'),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'wrap_up_label',
      headerName: getText('insights_engagement_table_header_wrap_up'),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'engagement_label',
      headerName: getText('insights_engagement_table_header_engagement'),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'column-engagement',
      headerClassName: 'column-engagement',
    },
    {
      field: 'blocker_on_job_label',
      headerName: getText('insights_engagement_table_header_blocker'),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'evidence_on_job_label',
      headerName: getText('insights_engagement_table_header_evidence'),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'message_on_job_label',
      headerName: getText('insights_engagement_table_header_message'),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'last-column',
    },
  ];

  const mapToTableData = (results) =>
    results.map((result, index) => ({
      id: index + 1,
      key: index + 1,
      name: `${result.user.first_name} ${result.user.last_name}`,
      jobs_on_fyld: result.jobs_count,
      vra_on_arrival: result.jobs_with_vra_pct,
      vra_on_arrival_label: `${roundPercentage(
        result.jobs_with_vra_pct * 100,
      )}% (${result.jobs_with_vra_count})`,
      evidence_on_job: result.jobs_with_evidence_pct,
      evidence_on_job_label: `${roundPercentage(
        result.jobs_with_evidence_pct * 100,
      )}% (${result.jobs_with_evidence_count})`,
      message_on_job: result.jobs_with_messaging_pct,
      message_on_job_label: `${roundPercentage(
        result.jobs_with_messaging_pct * 100,
      )}% (${result.jobs_with_messaging_count})`,
      blocker_on_job: result.jobs_with_blocker_pct,
      blocker_on_job_label: `${roundPercentage(
        result.jobs_with_blocker_pct * 100,
      )}% (${result.jobs_with_blocker_count})`,
      wrap_up: result.jobs_with_wrapup_pct,
      wrap_up_label: `${roundPercentage(result.jobs_with_wrapup_pct * 100)}% (${
        result.jobs_with_wrapup_count
      })`,
      engagement: result.engagement,
      engagement_label: `${roundPercentage(result.engagement * 100)}%`,
      job_details: result.job_details,
    }));

  const calculateAverage = (data, isPercentage = false) => {
    const result =
      data.reduce((partial_sum, a) => partial_sum + a, 0) / data.length;
    return isPercentage
      ? `${roundPercentage(result * 100)}%`
      : Math.round(result * 100) / 100;
  };

  useEffect(() => {
    if (depotId) {
      dispatch(
        fetchEngagementData(
          depotId,
          startDate,
          endDate,
          !isWeekendIncluded,
          selectedWorkspaceUuid,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depotId, startDate, endDate, isWeekendIncluded, selectedWorkspaceUuid]);

  useEffect(() => {
    setTableData(mapToTableData(engagementData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementData]);

  const generateTable = () => {
    if (tableData?.length) {
      return (
        <StyledAverageTable>
          <TableBody>
            <TableRow>
              <TableCell>
                {getText('insights_engagement_table_header_depot_average')}
              </TableCell>
              <TableCell>
                {calculateAverage(
                  tableData.map((data) => data.jobs_on_fyld),
                  false,
                )}
              </TableCell>
              <TableCell>
                {`${calculateAverage(
                  tableData.map((data) => data.vra_on_arrival),
                  true,
                )} (${calculateAverage(
                  tableData.map((data) => data.vra_on_arrival),
                )})`}
              </TableCell>
              <TableCell>
                {`${calculateAverage(
                  tableData.map((data) => data.wrap_up),
                  true,
                )} (${calculateAverage(
                  tableData.map((data) => data.wrap_up),
                )})`}
              </TableCell>
              <TableCell>
                {calculateAverage(
                  tableData.map((data) => data.engagement),
                  true,
                )}
              </TableCell>
              <TableCell>
                {`${calculateAverage(
                  tableData.map((data) => data.blocker_on_job),
                  true,
                )} (${calculateAverage(
                  tableData.map((data) => data.blocker_on_job),
                )})`}
              </TableCell>
              <TableCell>
                {`${calculateAverage(
                  tableData.map((data) => data.evidence_on_job),
                  true,
                )} (${calculateAverage(
                  tableData.map((data) => data.evidence_on_job),
                )})`}
              </TableCell>
              <TableCell>
                {`${calculateAverage(
                  tableData.map((data) => data.message_on_job),
                  true,
                )} (${calculateAverage(
                  tableData.map((data) => data.message_on_job),
                )})`}
              </TableCell>
            </TableRow>
          </TableBody>
        </StyledAverageTable>
      );
    }
  };

  return (
    <DataContainer>
      <H2>{getText('insights_engagement_subtitle')}</H2>
      <AppFunctionalityInfoModal
        isVisible={isInfoModalOpen}
        setIsVisible={setIsInfoModalOpen}
        {...infoModal}
      />
      {tableData ? (
        <DataRow>
          <StyledDataGrid
            rows={tableData}
            columns={columns}
            width={`${100 / columns?.length}%`}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableExtendRowFullWidth
            disableRowSelectionOnClick
            sortModel={[]}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: 'name',
                    sort: SortOrder.ASCENDING,
                  },
                ],
              },
            }}
            disableColumnResize
            loading={isLoading}
            slots={{
              toolbar: () => generateTable(),
              loadingOverlay: () => <Loader />,
            }}
          />
        </DataRow>
      ) : (
        <DataRow>
          <Loader />
        </DataRow>
      )}
    </DataContainer>
  );
};

export default InsightEngagement;

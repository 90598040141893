import { Depot } from 'model/Job/Depot';
import Sort from 'model/Sort';
import { UserRole } from 'model/enum/UserRole';

interface User {
  id: number;
  firstName: string;
  lastName: string;
}

export type HavsManager = User;

export type HavsUser = User & {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  depot: Depot;
};

export type HavsLimit = {
  user: HavsUser;
  havsLimit: number;
  manager: HavsManager;
  updatedAt: Date;
  updatedBy: HavsManager;
};

export type BackendHavsLimit = {
  user: number;
  manager: number;
  havs_limit: number;
};

export type BackendHavsFilters = {
  depot_id: number | null;
  manager_id: number | null;
  havs_limit: number | null;
  search: string | null;
  order_by: string | null;
  order_by_dir: string | null;
};

export type HavsFilters = {
  depot: Depot | null;
  havsLimit: number | null;
  manager: HavsManager | null;
  search: string | null;
  sort: Sort;
};

export enum HavsFilterTypes {
  DEPOT = 'depot',
  HAVS_LIMIT = 'havsLimit',
  MANAGER = 'manager',
  SEARCH = 'search',
  SORT = 'sort',
}

export enum HavsTableFields {
  FIELDWORKER = 'fieldworker',
  HAVS_LIMIT = 'havsLimit',
  ROLE = 'role',
  DEPOT = 'depot',
  MANAGER = 'manager',
  LAST_UPDATED = 'lastUpdated',
  UPDATED_BY = 'updatedBy',
  ACTION = 'action',
}

export type HavsTableData = {
  id: string;
  userId: number;
  [HavsTableFields.FIELDWORKER]: string;
  [HavsTableFields.HAVS_LIMIT]: number;
  [HavsTableFields.ROLE]: string;
  [HavsTableFields.DEPOT]: string;
  [HavsTableFields.MANAGER]: string;
  [HavsTableFields.LAST_UPDATED]: string;
  [HavsTableFields.UPDATED_BY]: string;
};

import {
  FETCH_WORKSPACE_SETTINGS,
  FETCH_WORKSPACE_SETTINGS_SUCCESS,
  FETCH_WORKSPACE_SETTINGS_ERROR,
  SET_WORKSPACE_SETTINGS,
  SET_WORKSPACE_SETTINGS_ERROR,
  SET_WORKSPACE_SETTINGS_SUCCESS,
  FETCH_EVIDENCE_TYPES,
  FETCH_EVIDENCE_TYPES_SUCCESS,
  FETCH_EVIDENCE_TYPES_ERROR,
  ADD_EVIDENCE_TYPE_SUCCESS,
  ADD_EVIDENCE_TYPE_ERROR,
  EDIT_EVIDENCE_TYPE_SUCCESS,
  EDIT_EVIDENCE_TYPE_ERROR,
  REMOVE_EVIDENCE_TYPE_SUCCESS,
  REMOVE_EVIDENCE_TYPE_ERROR,
  SAVE_EVIDENCE_TYPE_CHANGES,
  SAVE_EVIDENCE_TYPE_CHANGES_SUCCESS,
  SAVE_EVIDENCE_TYPE_CHANGES_ERROR,
  RESET_EVIDENCE_TYPE_ERRORS,
  FETCH_WORKSPACE_JOB_TYPES,
  FETCH_WORKSPACE_JOB_TYPES_SUCCESS,
  FETCH_WORKSPACE_JOB_TYPES_ERROR,
  FETCH_WORKSPACE_JOB_BLOCKER_TYPES,
  FETCH_WORKSPACE_JOB_BLOCKER_TYPES_SUCCESS,
  FETCH_WORKSPACE_JOB_BLOCKER_TYPES_ERROR,
  SAVE_JOB_BLOCKER_TYPE_CHANGES,
  SAVE_JOB_BLOCKER_TYPE_CHANGES_SUCCESS,
  SAVE_JOB_BLOCKER_TYPE_CHANGES_ERROR,
  ADD_JOB_BLOCKER_TYPE_SUCCESS,
  ADD_JOB_BLOCKER_TYPE_ERROR,
  EDIT_JOB_BLOCKER_TYPE_SUCCESS,
  EDIT_JOB_BLOCKER_TYPE_ERROR,
  REMOVE_JOB_BLOCKER_TYPE_SUCCESS,
  REMOVE_JOB_BLOCKER_TYPE_ERROR,
  RESET_JOB_BLOCKER_TYPE_ERRORS,
  FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES,
  FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES_SUCCESS,
  FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES_ERROR,
  SAVE_JOB_BLOCKER_SUBTYPE_CHANGES,
  SAVE_JOB_BLOCKER_SUBTYPE_CHANGES_SUCCESS,
  SAVE_JOB_BLOCKER_SUBTYPE_CHANGES_ERROR,
  ADD_JOB_BLOCKER_SUBTYPE_SUCCESS,
  ADD_JOB_BLOCKER_SUBTYPE_ERROR,
  EDIT_JOB_BLOCKER_SUBTYPE_SUCCESS,
  EDIT_JOB_BLOCKER_SUBTYPE_ERROR,
  REMOVE_JOB_BLOCKER_SUBTYPE_SUCCESS,
  REMOVE_JOB_BLOCKER_SUBTYPE_ERROR,
  RESET_JOB_BLOCKER_SUBTYPE_ERRORS,
  SET_JOB_CREATION_SETTINGS_SUCCESS,
  SET_JOB_CREATION_SETTINGS_ERROR,
  SET_JOB_CREATION_SETTINGS,
  SAVE_JOB_TYPE_CHANGES,
  SAVE_JOB_TYPE_CHANGES_SUCCESS,
  SAVE_JOB_TYPE_CHANGES_ERROR,
  ADD_JOB_TYPE_SUCCESS,
  ADD_JOB_TYPE_ERROR,
  EDIT_JOB_TYPE_SUCCESS,
  EDIT_JOB_TYPE_ERROR,
  REMOVE_JOB_TYPE_SUCCESS,
  REMOVE_JOB_TYPE_ERROR,
  RESET_JOB_TYPE_ERRORS,
} from './actionTypes';

export const fetchWorkspaceSettings = (workspaceUuid) => ({
  type: FETCH_WORKSPACE_SETTINGS,
  workspaceUuid,
});

export const fetchWorkspaceSettingsSuccess = (
  workspaceUuid,
  workspaceData,
  jobCreationData,
) => ({
  type: FETCH_WORKSPACE_SETTINGS_SUCCESS,
  workspaceUuid,
  workspaceData,
  jobCreationData,
});

export const fetchWorkspaceSettingsError = () => ({
  type: FETCH_WORKSPACE_SETTINGS_ERROR,
});

export const setNewWorkspaceSettings = (workspaceUuid, params) => ({
  type: SET_WORKSPACE_SETTINGS,
  workspaceUuid,
  params,
});

export const setWorkspaceSettingsSuccess = (workspaceUuid, data) => ({
  type: SET_WORKSPACE_SETTINGS_SUCCESS,
  workspaceUuid,
  data,
});

export const setWorkspaceSettingsError = (error) => ({
  type: SET_WORKSPACE_SETTINGS_ERROR,
  error,
});

export const setNewJobCreationSettings = (workspaceUuid, params) => ({
  type: SET_JOB_CREATION_SETTINGS,
  workspaceUuid,
  params,
});

export const setJobCreationSettingsSuccess = (workspaceUuid, data) => ({
  type: SET_JOB_CREATION_SETTINGS_SUCCESS,
  workspaceUuid,
  data,
});

export const setJobCreationSettingsError = (error) => ({
  type: SET_JOB_CREATION_SETTINGS_ERROR,
  error,
});

// EVIDENCE TYPES
export const fetchEvidenceTypes = (workspaceUuid) => ({
  type: FETCH_EVIDENCE_TYPES,
  workspaceUuid,
});

export const fetchEvidenceTypesSuccess = (workspaceUuid, data) => ({
  type: FETCH_EVIDENCE_TYPES_SUCCESS,
  workspaceUuid,
  data,
});

export const fetchEvidenceTypesError = () => ({
  type: FETCH_EVIDENCE_TYPES_ERROR,
});

export const saveEvidenceTypeChanges = (workspaceUuid, evidenceTypes) => ({
  type: SAVE_EVIDENCE_TYPE_CHANGES,
  workspaceUuid,
  evidenceTypes,
});

export const saveEvidenceTypeChangesSuccess = () => ({
  type: SAVE_EVIDENCE_TYPE_CHANGES_SUCCESS,
});

export const saveEvidenceTypeChangesError = () => ({
  type: SAVE_EVIDENCE_TYPE_CHANGES_ERROR,
});

export const addNewEvidenceTypeSuccess = (workspaceUuid, data) => ({
  type: ADD_EVIDENCE_TYPE_SUCCESS,
  workspaceUuid,
  data,
});

export const addNewEvidenceTypeError = (data) => ({
  type: ADD_EVIDENCE_TYPE_ERROR,
  data,
});

export const editExistingEvidenceTypeSuccess = (workspaceUuid, data) => ({
  type: EDIT_EVIDENCE_TYPE_SUCCESS,
  workspaceUuid,
  data,
});

export const editExistingEvidenceTypeError = (data) => ({
  type: EDIT_EVIDENCE_TYPE_ERROR,
  data,
});

export const removeExistingEvidenceTypeSuccess = (workspaceUuid, id) => ({
  type: REMOVE_EVIDENCE_TYPE_SUCCESS,
  workspaceUuid,
  id,
});

export const removeExistingEvidenceTypeError = (data) => ({
  type: REMOVE_EVIDENCE_TYPE_ERROR,
  data,
});

export const resetEvidenceTypeErrors = () => ({
  type: RESET_EVIDENCE_TYPE_ERRORS,
});

// JOB TYPES
export const fetchWorkspaceJobTypes = (workspaceUuid) => ({
  type: FETCH_WORKSPACE_JOB_TYPES,
  workspaceUuid,
});

export const fetchWorkspaceJobTypesSuccess = (workspaceUuid, data) => ({
  type: FETCH_WORKSPACE_JOB_TYPES_SUCCESS,
  workspaceUuid,
  data,
});

export const fetchWorkspaceJobTypesError = () => ({
  type: FETCH_WORKSPACE_JOB_TYPES_ERROR,
});

export const saveJobTypeChanges = (workspaceUuid, jobTypes) => ({
  type: SAVE_JOB_TYPE_CHANGES,
  workspaceUuid,
  jobTypes,
});

export const saveJobTypeChangesSuccess = () => ({
  type: SAVE_JOB_TYPE_CHANGES_SUCCESS,
});

export const saveJobTypeChangesError = () => ({
  type: SAVE_JOB_TYPE_CHANGES_ERROR,
});

export const addNewJobTypeSuccess = (workspaceUuid, data) => ({
  type: ADD_JOB_TYPE_SUCCESS,
  workspaceUuid,
  data,
});

export const addNewJobTypeError = (data) => ({
  type: ADD_JOB_TYPE_ERROR,
  data,
});

export const editExistingJobTypeSuccess = (workspaceUuid, data) => ({
  type: EDIT_JOB_TYPE_SUCCESS,
  workspaceUuid,
  data,
});

export const editExistingJobTypeError = (data) => ({
  type: EDIT_JOB_TYPE_ERROR,
  data,
});

export const removeExistingJobTypeSuccess = (workspaceUuid, id) => ({
  type: REMOVE_JOB_TYPE_SUCCESS,
  workspaceUuid,
  id,
});

export const removeExistingJobTypeError = (data) => ({
  type: REMOVE_JOB_TYPE_ERROR,
  data,
});

export const resetJobTypeErrors = () => ({
  type: RESET_JOB_TYPE_ERRORS,
});

// JOB BLOCKER TYPES
export const fetchWorkspaceJobBlockerTypes = (workspaceUuid) => ({
  type: FETCH_WORKSPACE_JOB_BLOCKER_TYPES,
  workspaceUuid,
});

export const fetchWorkspaceJobBlockerTypesSuccess = (workspaceUuid, data) => ({
  type: FETCH_WORKSPACE_JOB_BLOCKER_TYPES_SUCCESS,
  workspaceUuid,
  data,
});

export const fetchWorkspaceJobBlockerTypesError = () => ({
  type: FETCH_WORKSPACE_JOB_BLOCKER_TYPES_ERROR,
});

export const saveJobBlockerTypeChanges = (workspaceUuid, jobBlockerTypes) => ({
  type: SAVE_JOB_BLOCKER_TYPE_CHANGES,
  workspaceUuid,
  jobBlockerTypes,
});

export const saveJobBlockerTypeChangesSuccess = () => ({
  type: SAVE_JOB_BLOCKER_TYPE_CHANGES_SUCCESS,
});

export const saveJobBlockerTypeChangesError = () => ({
  type: SAVE_JOB_BLOCKER_TYPE_CHANGES_ERROR,
});

export const addNewJobBlockerTypeSuccess = (workspaceUuid, data) => ({
  type: ADD_JOB_BLOCKER_TYPE_SUCCESS,
  workspaceUuid,
  data,
});

export const addNewJobBlockerTypeError = (data) => ({
  type: ADD_JOB_BLOCKER_TYPE_ERROR,
  data,
});

export const editExistingJobBlockerTypeSuccess = (workspaceUuid, data) => ({
  type: EDIT_JOB_BLOCKER_TYPE_SUCCESS,
  workspaceUuid,
  data,
});

export const editExistingJobBlockerTypeError = (data) => ({
  type: EDIT_JOB_BLOCKER_TYPE_ERROR,
  data,
});

export const removeExistingJobBlockerTypeSuccess = (workspaceUuid, id) => ({
  type: REMOVE_JOB_BLOCKER_TYPE_SUCCESS,
  workspaceUuid,
  id,
});

export const removeExistingJobBlockerTypeError = (data) => ({
  type: REMOVE_JOB_BLOCKER_TYPE_ERROR,
  data,
});

export const resetJobBlockerTypeErrors = () => ({
  type: RESET_JOB_BLOCKER_TYPE_ERRORS,
});

// JOB BLOCKER SUBTYPES
export const fetchWorkspaceJobBlockerSubtypes = (workspaceUuid, blockerId) => ({
  type: FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES,
  workspaceUuid,
  blockerId,
});

export const fetchWorkspaceJobBlockerSubtypesSuccess = (
  workspaceUuid,
  data,
) => ({
  type: FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES_SUCCESS,
  workspaceUuid,
  data,
});

export const fetchWorkspaceJobBlockerSubTypesError = () => ({
  type: FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES_ERROR,
});

export const saveJobBlockerSubtypeChanges = (
  workspaceUuid,
  blockerId,
  jobBlockerTypes,
) => ({
  type: SAVE_JOB_BLOCKER_SUBTYPE_CHANGES,
  workspaceUuid,
  blockerId,
  jobBlockerTypes,
});

export const saveJobBlockerSubtypeChangesSuccess = () => ({
  type: SAVE_JOB_BLOCKER_SUBTYPE_CHANGES_SUCCESS,
});

export const saveJobBlockerSubtypeChangesError = () => ({
  type: SAVE_JOB_BLOCKER_SUBTYPE_CHANGES_ERROR,
});

export const addNewJobBlockerSubtypeSuccess = (workspaceUuid, data) => ({
  type: ADD_JOB_BLOCKER_SUBTYPE_SUCCESS,
  workspaceUuid,
  data,
});

export const addNewJobBlockerSubtypeError = (data) => ({
  type: ADD_JOB_BLOCKER_SUBTYPE_ERROR,
  data,
});

export const editExistingJobBlockerSubtypeSuccess = (workspaceUuid, data) => ({
  type: EDIT_JOB_BLOCKER_SUBTYPE_SUCCESS,
  workspaceUuid,
  data,
});

export const editExistingJobBlockerSubtypeError = (data) => ({
  type: EDIT_JOB_BLOCKER_SUBTYPE_ERROR,
  data,
});

export const removeExistingJobBlockerSubtypeSuccess = (workspaceUuid, id) => ({
  type: REMOVE_JOB_BLOCKER_SUBTYPE_SUCCESS,
  workspaceUuid,
  id,
});

export const removeExistingJobBlockerSubtypeError = (data) => ({
  type: REMOVE_JOB_BLOCKER_SUBTYPE_ERROR,
  data,
});

export const resetJobBlockerSubtypeErrors = () => ({
  type: RESET_JOB_BLOCKER_SUBTYPE_ERRORS,
});

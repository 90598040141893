export enum JobStatusTitle {
  CREATED = 'Created',
  FOLLOW_UP = 'Follow-up',
  HANDOVER = 'Handover',
  COMPLETED = 'Completed',
  DELETED = 'Deleted',
  IN_PROGRESS = 'In-progress',
  ABORTED = 'Aborted',
  ABANDONED = 'Abandoned',
  CLOSED = 'Closed',
}

import {
  FETCH_CURRENT_USER,
  SET_CURRENT_USER,
  SIGN_IN,
  SIGN_OUT,
  COMPLETE_NEW_PASSWORD,
  COMPLETE_NEW_PASSWORD_DONE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SENT,
  RESET_PASSWORD,
  RESET_PASSWORD_DONE,
  SESSION_ERROR,
  PASSWORD_ERROR,
  RESET_SESSION_ERRORS,
  SET_SESSION_TOKEN,
  START_AUTH,
  END_AUTH,
  SET_LAST_REFRESH,
  TOKEN_SIGN_IN,
  REQUEST_MAGIC_LINK,
  MAGIC_LINK_REQUESTED,
  SET_WORKSPACE,
  RELOAD_FOR_WORKSPACE,
  SET_GLOBAL_LANGUAGE_SETTING,
  INIT_GLOBAL_LANGUAGE_SETTING,
  UPDATE_WORKSPACE_SETTINGS,
} from './actionTypes';

export const startAuth = () => ({ type: START_AUTH });

export const endAuth = () => ({ type: END_AUTH });

export const fetchCurrentUser = ({ source }) => ({
  type: FETCH_CURRENT_USER,
  source,
});

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  user,
});

export const setSessionToken = (sessionToken) => ({
  type: SET_SESSION_TOKEN,
  sessionToken,
});

export const signIn = (email, password) => ({
  type: SIGN_IN,
  email,
  password,
});

export const tokenSignIn = (email, challengeToken) => ({
  type: TOKEN_SIGN_IN,
  email,
  challengeToken,
});

export const requestMagicLink = (email) => ({
  type: REQUEST_MAGIC_LINK,
  email,
});

export const magicLinkRequested = () => ({ type: MAGIC_LINK_REQUESTED });

export const signOut = () => ({ type: SIGN_OUT });

export const completeNewPassword = (email, temporaryPassword, newPassword) => ({
  type: COMPLETE_NEW_PASSWORD,
  email,
  temporaryPassword,
  newPassword,
});

export const completeNewPasswordDone = () => ({
  type: COMPLETE_NEW_PASSWORD_DONE,
});

export const forgotPassword = (email) => ({
  type: FORGOT_PASSWORD,
  email,
});

export const forgotPasswordSent = () => ({ type: FORGOT_PASSWORD_SENT });

export const resetPassword = (email, code, password) => ({
  type: RESET_PASSWORD,
  email,
  code,
  password,
});

export const resetPasswordDone = () => ({ type: RESET_PASSWORD_DONE });

export const setSessionError = (error) => ({
  type: SESSION_ERROR,
  error,
});

export const setPasswordError = (error) => ({
  type: PASSWORD_ERROR,
  error,
});

export const resetSessionErrors = () => ({ type: RESET_SESSION_ERRORS });

export const setLastRefresh = (datetime) => ({
  type: SET_LAST_REFRESH,
  datetime,
});

export const setWorkspace = (workspace) => ({
  type: SET_WORKSPACE,
  workspace,
});

export const updateWorkspaceSettings = (data) => ({
  type: UPDATE_WORKSPACE_SETTINGS,
  data,
});

export const reloadForWorkspace = () => ({
  type: RELOAD_FOR_WORKSPACE,
});

export const initGlobalLanguage = () => ({
  type: INIT_GLOBAL_LANGUAGE_SETTING,
});

export const setGlobalLanguage = (selectedLanguage) => ({
  type: SET_GLOBAL_LANGUAGE_SETTING,
  selectedLanguage,
});

export enum FilterType {
  DEPOT = 'depot',
  DATE_RANGE = 'date-range',
  TEAM = 'team',
  MEMBER = 'member',
  WEEKEND = 'weekend',
  SHIFT_DURATION = 'shift-duration',
  ACTIVE = 'active',
  MAJOR_HAZARDS = 'major-hazards',
  VRA_READY_FOR_SIGN_OFF = 'vra-ready-for-sign-off',
  NO_VRA = 'no-vra',
  JOB_TYPE = 'job-type',
  JOB_SUBTYPE = 'job-subtype',
  STATUS = 'status',
  ASSESSMENT_TYPE = 'assessment-type',
  HAZARD = 'hazard',
}

export const downloadBlob = async (blob: Blob, filename: string) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  document.body.removeChild(link);
};

export const getFileNameFromResponse = (
  responseUrl: string,
  fileId: string,
  extension: string,
): string => {
  const fileNameWithId =
    responseUrl?.match(`${fileId}/.*.${extension}`)?.[0] ?? '';
  const fileName = fileNameWithId.replace(`${fileId}/`, '');
  return decodeURIComponent(fileName);
};

export const setPdfExportDataToLocalStorage = (
  objectId: string,
  properties: object,
): void => {
  const pdfDownloads = JSON.parse(
    window.localStorage.getItem('pdfDownloads') || '{}',
  );
  window.localStorage.setItem(
    'pdfDownloads',
    JSON.stringify({ ...pdfDownloads, [objectId]: properties }),
  );
};

export const getPdfExportDataFromLocalStorage = (
  objectId: string,
): object | undefined => {
  const pdfDownloads = JSON.parse(
    window.localStorage.getItem('pdfDownloads') || '{}',
  );
  return pdfDownloads[objectId];
};

export const removePdfExportDataFromLocalStorage = (objectId: string): void => {
  const pdfDownloads = JSON.parse(
    window.localStorage.getItem('pdfDownloads') || '{}',
  );
  const { [objectId]: propToRemove, ...remainingProps } = pdfDownloads;
  window.localStorage.setItem('pdfDownloads', JSON.stringify(remainingProps));
};

import styled from 'styled-components';
import theme from 'theme';

const { fonts, fontSizes } = theme;

export const Container = styled.div`
  height: 80vh;
  display: flex;
`;

export const Information = styled.div`
  overflow: scroll;
  flex: 1;
  flex-direction: column;
  display: flex;
  background-color: ${theme.colors.white};
`;

export const Title = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
`;

export const TitleContainer = styled.div`
  display: block;
  padding: 50px 100px 0px;
`;

export const Section = styled.div`
  padding: 30px 100px;
  border-bottom: 10px solid ${theme.colors.lightGrey};
`;

export const InterventionContainer = styled.div`
  padding: 33px 100px;
  flex-direction: column;
  display: flex;
`;

export const Label = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
`;

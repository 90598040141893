import TwelveHourForm from 'frontend-shared/fatigue/fatigue-twelve-hour.json';
import TwelveHourFollowUpForm from 'frontend-shared/fatigue/fatigue-twelve-hour-follow-up.json';
import FourteenHourForm from 'frontend-shared/fatigue/fatigue-fourteen-hour.json';
import SixteenHourForm from 'frontend-shared/fatigue/fatigue-sixteen-hour.json';

export enum FraTypes {
  Sixteen = 'fra-16',
  Fourteen = 'fra-14',
  TwelveFollowUp = 'fra-12-follow-up',
  Twelve = 'fra-12',
}

export const FormByFraType = {
  [FraTypes.Sixteen]: SixteenHourForm,
  [FraTypes.Fourteen]: FourteenHourForm,
  [FraTypes.TwelveFollowUp]: TwelveHourFollowUpForm,
  [FraTypes.Twelve]: TwelveHourForm,
};

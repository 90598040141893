export const CREATED_AT = 'created_at';
export const POINTS_SUM = 'points_sum';
export const IS_BREACH = 'is_breach';
export const HAS_COMMENTS = 'has_comments';
export const VEHICLE_CHECK_REGISTRATION = 'vehicle-check-vehicle-registation';
export const FORM = 'form';
export const JOB_NUMBER = 'job_number';

export const FIRST_COLUMN_KEY = 'created_at';
export const SECOND_COLUMN_KEY = 'created_by__name';
export const THIRD_COLUMN_KEY = 'depot__title';
export const LAST_COLUMN_KEY = FORM;
export const LAST_COLUMN_VALUE = 'Form';
export const LAST_COLUMN_VALUE_FOR_HAVS = 'Action';

export const isLastColumn = (key: string) => key === FORM;

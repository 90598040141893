import { useText } from 'hooks';
import { Loader, Navbar } from 'components/ui';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SiteBriefing } from 'model/State/SiteBriefing';
import {
  Address,
  ColumnWrapper,
  DateTime,
  DetailsContainer,
  Header,
} from './styled';
import { Location, Traffic } from 'assets/icons';
import { Conditions } from './components/Conditions';
import { getWeatherIcon } from './helper';
import { useEffect } from 'react';
import {
  fetchSiteBriefing,
  selectIsFetchingSiteBriefing,
  selectJobSiteBriefing,
} from 'store/slices/siteBriefing';
import { selectActiveJobTab, selectUserHasPermission } from 'store/selectors';
import { JOB_OVERVIEW, TIMELINE } from 'utils/constants';
import { setActiveJobTab } from 'store/actions/versions';
import { formatLongDateAndTime } from 'helpers/dates';
import { BlackWarningTag } from 'components/ui/Tags/BlackWarningTag';
import { NearbyJobs } from './components/NearbyJobs';
import { Separator } from 'components/ui/Misc';

export const SiteBriefingShow = () => {
  const getText = useText();
  const dispatch = useDispatch();
  const { jobId, siteBriefingId } = useParams();
  const siteBriefing: SiteBriefing = useSelector((state) =>
    selectJobSiteBriefing(state, parseInt(jobId ?? '', 10)),
  );
  const isFetchingSiteBriefing = useSelector(selectIsFetchingSiteBriefing);
  const activeJobTab = useSelector(selectActiveJobTab);
  const hasUserPsiPermission = useSelector(selectUserHasPermission('psi_v1'));
  const showSeparator: boolean =
    !!siteBriefing?.nearbyJobs?.length &&
    (!!siteBriefing?.weather?.summary ||
      !!siteBriefing?.trafficInsights?.length ||
      !!siteBriefing?.locationInsights?.length);

  useEffect(() => {
    if (activeJobTab === JOB_OVERVIEW) {
      dispatch(setActiveJobTab(TIMELINE));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isFetchingSiteBriefing) {
      dispatch(
        fetchSiteBriefing({
          jobId: parseInt(jobId ?? '', 10),
          siteBriefingId: parseInt(siteBriefingId ?? '', 10),
        }),
      );
    }
  }, [dispatch, jobId, siteBriefingId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Navbar
        title={getText('job_details_timeline_action_site_briefing')}
        defaultBackPath={`/jobs/${jobId}/timeline`}
        mobileOnly
      />

      {isFetchingSiteBriefing ? (
        <Loader />
      ) : (
        siteBriefing && (
          <DetailsContainer>
            <Header>
              <Address>{siteBriefing?.address}</Address>
              {siteBriefing?.createdAt && (
                <DateTime>
                  {formatLongDateAndTime(siteBriefing.createdAt)}
                </DateTime>
              )}
            </Header>
            {siteBriefing?.weather?.summary && (
              <Conditions
                title={getText(
                  'job_details_timeline_action_site_briefing_weather_conditions',
                )}
                icon={getWeatherIcon(siteBriefing?.weather?.icon)}
                infoText={siteBriefing?.weather?.summary ?? ''}
              />
            )}
            {!!siteBriefing?.trafficInsights?.length && (
              <Conditions
                title={getText(
                  'job_details_timeline_action_site_briefing_traffic_conditions',
                )}
                icon={Traffic}
                trafficInsights={siteBriefing?.trafficInsights}
              />
            )}

            {!!siteBriefing?.locationInsights?.length && (
              <Conditions
                title={getText(
                  'job_details_timeline_action_site_briefing_location_inisghts',
                )}
                icon={Location}
                locationInsights={siteBriefing?.locationInsights}
              />
            )}

            {hasUserPsiPermission && siteBriefing?.cableStrikesLikely && (
              <ColumnWrapper>
                <BlackWarningTag
                  text={getText(
                    'job_details_timeline_action_site_briefing_cable_strikes',
                  )}
                />
              </ColumnWrapper>
            )}
            {showSeparator && <Separator />}
            <NearbyJobs
              jobs={siteBriefing?.nearbyJobs}
              eventProperties={{
                jobId: siteBriefing?.jobId,
                siteBriefingId: siteBriefing?.id,
              }}
            />
          </DetailsContainer>
        )
      )}
    </>
  );
};

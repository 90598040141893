import { useEffect } from 'react';
import styled from 'styled-components';
import { useIsMobile, useText } from 'hooks';
import theme from 'theme';
import { Loader, Card, Message, Navbar } from 'components/ui';
import { SkeletonCardContainer } from 'components/ui/Shared/EmptyState';
import { useDispatch, useSelector } from 'react-redux';
import * as constants from 'utils/constants';
import { fetchDocuments } from 'store/actions/jobs';
import { useParams } from 'react-router-dom';
import {
  selectIsFetchingDocuments,
  selectJobDocuments,
  selectJobsView,
  selectPreviousTimelinePath,
} from 'store/selectors';
import { Document } from 'model/Media/Document';

const { media } = theme;

// Styled Components
const Container = styled.div`
  height: 100%;
  ${media.sm`
    padding: 25px 15px;
    overflow: scroll;
  `}
`;

// List
export default () => {
  const { jobId } = useParams();
  const documents: Document[] = useSelector(selectJobDocuments(jobId));
  const isFetchingDocuments = useSelector(selectIsFetchingDocuments);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const getText = useText();
  const view = useSelector(selectJobsView);
  const previousTimelinePath = useSelector(selectPreviousTimelinePath(jobId));

  useEffect(() => {
    dispatch(fetchDocuments(jobId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Navbar
        title={getText('document')}
        defaultBackPath={previousTimelinePath ?? `/jobs/${jobId}`}
        mobileOnly
      />

      <Container>
        {isFetchingDocuments ? (
          <Loader />
        ) : (
          <>
            {!documents?.length ? (
              <SkeletonCardContainer>
                {getText('document_no_display')}
              </SkeletonCardContainer>
            ) : (
              documents?.map((document) =>
                isMobile ? (
                  <Message.DownloadDocument
                    key={`document-${document.id}`}
                    event={constants.EVENT_VIEW_DOCUMENT_ITEM}
                    eventProperties={{
                      jobId,
                      documentId: document.id,
                      source: 'job-overview',
                      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                    }}
                    document={document}
                  />
                ) : (
                  <Card.DocumentCard
                    key={`document-${document.id}`}
                    event={constants.EVENT_VIEW_DOCUMENT_ITEM}
                    eventProperties={{
                      jobId,
                      documentId: document.id,
                      source: 'job-overview',
                      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                    }}
                    document={document}
                  />
                ),
              )
            )}
          </>
        )}
      </Container>
    </>
  );
};

import { SecondaryActionButton, SecondaryActionLink } from './styled';

export const SecondaryAction = ({
  onClick,
  to = '',
  children,
}: {
  onClick: () => void;
  to?: string;
  children?: JSX.Element | string;
}) => {
  if (to) {
    return (
      <SecondaryActionLink to={to} onClick={onClick}>
        {children}
      </SecondaryActionLink>
    );
  }

  return (
    <SecondaryActionButton onClick={onClick}>{children}</SecondaryActionButton>
  );
};

import JobFilters from 'model/JobFilters';
import JobSearch from 'components/views/Protected/Jobs/List/JobSearch';
import AddJobButton from 'components/views/Protected/Jobs/List/AddJobButton';
import MyLiveJobsToggle from 'components/views/Protected/Jobs/List/MyLiveJobsToggle';
import { FyldScreen } from 'model/enum/FyldScreen';
import Team from 'model/Team';
import { TeamFilter } from './TeamFilter';
import JobFilterButton from 'components/views/Protected/Jobs/List/JobFilterButton';
import { ActiveFilterLabels } from './ActiveFilterLabels';
import { ContentWrapper, JobTableActionContainer } from './styled';

type Props = {
  view: string;
  filters: JobFilters;
  setFilters: (value: JobFilters) => void;
  selectedTeam: Team | null;
  setSelectedTeam: (value: number | null) => void;
};

export const JobFilterBase = ({
  view,
  filters,
  setFilters,
  selectedTeam,
  setSelectedTeam,
}: Props): JSX.Element => {
  const noSearchQuery = !filters?.q;

  return (
    <ContentWrapper>
      <JobTableActionContainer>
        <MyLiveJobsToggle view={view} />
        <div>
          <AddJobButton view={view} />
        </div>
        <JobSearch view={view} extraStyles={{ width: '40%' }} />
        <div>
          <JobFilterButton view={view} disabled={!noSearchQuery} />
        </div>
        <TeamFilter
          screen={FyldScreen.JOBS}
          filters={filters}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          view={view}
          size='55px'
          className='walkthrough-step-team'
          margin='15px'
          disabled={!noSearchQuery}
        />
      </JobTableActionContainer>
      {noSearchQuery && (
        <ActiveFilterLabels
          filters={filters}
          setFilters={setFilters}
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
        />
      )}
    </ContentWrapper>
  );
};

import { useText } from 'hooks';
import { useSelector } from 'react-redux';
import { EmptyState } from './EmptyState';
import { SignOnList } from './SignOnList';
import { SectionHeader } from '../SectionHeader';
import { SectionContentContainer } from '../styled';
import { selectActiveRamsAssessment } from 'store/slices/ramsAssessments';

export const RamsSignOn = (): JSX.Element => {
  const getText = useText();
  const assessment = useSelector(selectActiveRamsAssessment);

  return (
    <SectionContentContainer>
      <SectionHeader title={getText('rams_section_option_sign_on')} />
      {assessment && (
        <>
          {!assessment?.signedOnUsers?.length ||
          !assessment?.methodStatement ? (
            <EmptyState
              isMethodStatementSubmitted={!!assessment?.methodStatement}
            />
          ) : (
            <SignOnList assessment={assessment} />
          )}
        </>
      )}
    </SectionContentContainer>
  );
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { useText } from 'hooks';
import ErrorFields from 'model/State/notifications/ErrorFields';
import { Notification, ToastType } from './constants';
import { ToastBase } from './ToastBase';
import { resetGlobalError, selectErrors } from 'store/slices/notifications';

export const ErrorToast = (): JSX.Element => {
  const errors = useSelector(selectErrors);
  const dispatch = useDispatch();
  const getText = useText();
  const CONTAINER_ID = 'error-container';
  const [errorList, setErrorList] = useState<Notification[]>([]);

  const getErrorMessage = (error: string | ErrorFields): string => {
    if (typeof error === 'string') {
      return error;
    }

    if (error?.config?.url) {
      return `${getText('error_from')} ${error?.config?.url}`;
    }

    if (error?.response?.message) {
      return error?.response?.message;
    }

    return '';
  };

  useEffect(() => {
    const tempList: Notification[] = [];
    errors.forEach(({ error, title, id, statusCode }) => {
      const generalError = {
        title: statusCode
          ? `${getText('error_occurred_text_with_status_code')} ${statusCode}`
          : getText('error_occurred_text_without_status_code'),
        message: getErrorMessage(error),
      };
      tempList.push({
        id,
        ...(title ? { title } : { ...generalError }),
        canBeDismissed: true,
      });
    });
    setErrorList(tempList);
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClose = (id: number) => {
    dispatch(resetGlobalError(id));
  };

  return (
    <ToastBase
      containerId={CONTAINER_ID}
      type={ToastType.ERROR}
      notifications={errorList}
      onClose={(id) => onClose(id)}
      autoClose={5000}
      light
    />
  );
};

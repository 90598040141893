import { Loader, Navbar } from 'components/ui';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { JobForms, Permit, UserForms } from 'api';
import { getFieldById } from 'helpers/forms';
import { setGlobalError } from 'store/slices/notifications';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field } from 'model/Forms/Field';
import { useQueryParams } from 'hooks';
import { CustomFormClass, Form } from 'model/Form';
import { InstructionContent, InstructionWrapper } from '../styled';

type QueryParamProps = {
  form?: string;
};

const Instruction = (): JSX.Element => {
  const { formId, fieldId } = useParams();
  const dispatch = useDispatch();
  const [instructionData, setInstructionData] = useState<Field | null>(null);
  const { params }: { params: QueryParamProps } = useQueryParams();

  useEffect(() => {
    (async () => {
      try {
        let data: Form | null = null;
        if (params?.form === CustomFormClass.PERMIT) {
          data = await Permit.get(formId ?? '');
        } else if (params?.form === CustomFormClass.USER) {
          data = await UserForms.getUserForm(parseInt(formId ?? '', 10));
        } else {
          data = await JobForms.get(parseInt(formId ?? '', 10));
        }

        setInstructionData(getFieldById(data?.form, fieldId));
      } catch (err) {
        dispatch(setGlobalError(err));
      }
    })();
  }, [formId, fieldId, params?.form, dispatch]);

  return (
    <>
      <Navbar
        title={instructionData?.title}
        defaultBackPath={'/jobs/insights'}
        mobileOnly
      />
      {instructionData?.value ? (
        <InstructionWrapper>
          <InstructionContent>
            {parse(instructionData?.value)}
          </InstructionContent>
        </InstructionWrapper>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Instruction;

import styled from 'styled-components';
import { Error } from 'assets/icons';
import theme from 'theme';

const { colors, media, fonts } = theme;

export const ErrorContainer = styled.div`
  background-color: ${colors.black};
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ExplanationIcon = styled(Error)`
  width: 15%;

  ${media.lg`
    width: 30%;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
`;

export const MessageBannerContainer = styled.div`
  margin-top: 160px;
  display: flex;
  align-items: center;
  width: 60%;
`;

export const MessageTextContainer = styled.div`
  margin-top: 20px;
  width: 60%;
`;

export const MessageTitle = styled.h1`
  line-height: 64px;
  font-size: 56px;
  color: ${colors.white};
  font-family: ${fonts.negativeBold};
  width: 60%;
  padding-left: 30px;

  ${media.xxl`
    line-height: 54px;
    font-size: 46px;
  `}
  ${media.xl`
    line-height: 50px;
    font-size: 40px;
  `}
  ${media.lg`
    line-height: 44px;
    font-size: 36px;
  `}
`;

export const MessageText = styled.p`
  color: ${colors.white};
  grid-column: 1 / 3;
  font-size: 24px;
  font-family: ${fonts.negativeRegular};
`;

export const EmailLink = styled.a`
  color: ${colors.white};
  text-decoration: underline;
  margin-left: 6px;

  &:hover {
    color: ${colors.white};
  }
`;

export const BrandBackgroundImage = styled.img`
  align-self: end;
  width: calc(50% + 316px);
`;

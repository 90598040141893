import styled from 'styled-components';

export const NotificationContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 10px;
  z-index: 9999;

  .Toastify__toast-container {
    padding: 0;
    width: 100%;
    position: static;
    transform: none;
  }
`;

import { mapToForm } from 'api/JobForms/helpers';
import { mapToPhoto, mapToVideo } from 'api/Media/helpers';
import { mapToUser } from 'api/User/helpers';
import { LastApproval } from 'model/Forms/LastApproval';
import { Form, Forms } from 'model/Form';

export const partialMapToPermit = (backendPermit: any): Form => ({
  ...backendPermit,
  lastApproval: backendPermit.last_approval
    ? mapToLastApproval(backendPermit.last_approval)
    : null,
});

export const partialOverridePermit = (
  backendPermit: any,
  lastApproval: LastApproval,
): Form => ({
  ...backendPermit,
  lastApproval: lastApproval,
});

export const mapToPermit = (backendPermit: any): Form => ({
  id: backendPermit.id,
  uuid: backendPermit.uuid,
  jobId: backendPermit.job_id,
  type: { id: backendPermit.type.id, title: backendPermit.type.title },
  createdBy: mapToUser(backendPermit.created_by),
  createdAt: new Date(backendPermit.created_at),
  sentAt: backendPermit.sent_at ? new Date(backendPermit.sent_at) : null,
  updatedAt: backendPermit.updated_at
    ? new Date(backendPermit.updated_at)
    : null,
  photos: backendPermit.photos ? backendPermit.photos.map(mapToPhoto) : [],
  videos: backendPermit.videos ? backendPermit.videos.map(mapToVideo) : [],
  form: mapToForm(backendPermit.form),
  lastApproval: backendPermit.last_approval
    ? mapToLastApproval(backendPermit.last_approval)
    : null,
  thumbnail: backendPermit.thumbnail ?? undefined,
  comments: backendPermit?.comments
    ? backendPermit.comments.map(Forms.mapToComment)
    : [],
});

const mapToLastApproval = (backendLastApproval: any): LastApproval => ({
  id: backendLastApproval.id,
  permitId: backendLastApproval.permit_id,
  isApproved: backendLastApproval.is_approved,
  rejectionNote: backendLastApproval.rejection_note ?? '',
  expiryDate: backendLastApproval.expiry_date
    ? new Date(backendLastApproval.expiry_date)
    : null,
  sentAt: backendLastApproval.sent_at
    ? new Date(backendLastApproval.sent_at)
    : null,
  createdAt: new Date(backendLastApproval.created_at),
  createdBy: mapToUser(backendLastApproval.created_by),
});

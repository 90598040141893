import {
  ADD_WORKSPACE_ASSESSMENT,
  ADD_WORKSPACE_ASSESSMENT_ERROR,
  ADD_WORKSPACE_ASSESSMENT_SUCCESS,
  EDIT_WORKSPACE_ASSESSMENT,
  EDIT_WORKSPACE_ASSESSMENT_ERROR,
  EDIT_WORKSPACE_ASSESSMENT_SUCCESS,
  FETCH_WORKSPACE_ASSESSMENTS,
  FETCH_WORKSPACE_ASSESSMENTS_ERROR,
  FETCH_WORKSPACE_ASSESSMENTS_SUCCESS,
  REMOVE_WORKSPACE_ASSESSMENT,
  REMOVE_WORKSPACE_ASSESSMENT_ERROR,
  REMOVE_WORKSPACE_ASSESSMENT_SUCCESS,
  RESET_WORKSPACE_ASSESSMENT_ERROR,
  TOGGLE_HAZARD_ASSESSMENT_LINKING,
} from '../actionTypes';

export const fetchWorkspaceAssessments = (workspaceUuid) => ({
  type: FETCH_WORKSPACE_ASSESSMENTS,
  workspaceUuid,
});

export const fetchWorkspaceAssessmentsSuccess = (workspaceUuid, data) => ({
  type: FETCH_WORKSPACE_ASSESSMENTS_SUCCESS,
  workspaceUuid,
  data,
});

export const fetchWorkspaceAssessmentsError = () => ({
  type: FETCH_WORKSPACE_ASSESSMENTS_ERROR,
});

export const addNewAssessment = (workspaceUuid, assessmentTitles) => ({
  type: ADD_WORKSPACE_ASSESSMENT,
  workspaceUuid,
  assessmentTitles,
});

export const addNewAssessmentSuccess = (workspaceUuid, data) => ({
  type: ADD_WORKSPACE_ASSESSMENT_SUCCESS,
  workspaceUuid,
  data,
});

export const addNewAssessmentError = (data) => ({
  type: ADD_WORKSPACE_ASSESSMENT_ERROR,
  data,
});

export const editExistingAssessment = (workspaceUuid, assessmentTitles) => ({
  type: EDIT_WORKSPACE_ASSESSMENT,
  workspaceUuid,
  assessmentTitles,
});

export const editExistingAssessmentSuccess = (workspaceUuid, data) => ({
  type: EDIT_WORKSPACE_ASSESSMENT_SUCCESS,
  workspaceUuid,
  data,
});

export const editExistingAssessmentError = (data) => ({
  type: EDIT_WORKSPACE_ASSESSMENT_ERROR,
  data,
});

export const removeExistingAssessment = (workspaceUuid, assessment) => ({
  type: REMOVE_WORKSPACE_ASSESSMENT,
  workspaceUuid,
  assessment,
});

export const removeExistingAssessmentSuccess = (workspaceUuid, id) => ({
  type: REMOVE_WORKSPACE_ASSESSMENT_SUCCESS,
  workspaceUuid,
  id,
});

export const removeExistingAssessmentError = (data) => ({
  type: REMOVE_WORKSPACE_ASSESSMENT_ERROR,
  data,
});

export const toggleHazardAssessmentLinking = (
  workspaceUuid,
  assessmentId,
  hazardId,
) => ({
  type: TOGGLE_HAZARD_ASSESSMENT_LINKING,
  workspaceUuid,
  assessmentId,
  hazardId,
});

export const resetError = () => ({
  type: RESET_WORKSPACE_ASSESSMENT_ERROR,
});

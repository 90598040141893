import { AssessmentSettings } from 'api';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  ADD_WORKSPACE_HAZARD,
  CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS,
  EDIT_WORKSPACE_HAZARD,
  FETCH_WORKSPACE_HAZARDS,
  REMOVE_WORKSPACE_HAZARD,
} from 'store/actions/actionTypes';
import { setGlobalError } from 'store/slices/notifications';
import {
  addNewHazardError,
  addNewHazardSuccess,
  changeHazardMajorStatusError,
  changeHazardMajorStatusSuccess,
  editExistingHazardError,
  editExistingHazardSuccess,
  fetchWorkspaceHazardsError,
  fetchWorkspaceHazardsSuccess,
  removeExistingHazardError,
  removeExistingHazardSuccess,
} from 'store/actions/settings/hazards';
import { Languages } from 'utils/constants';
import * as Logger from 'utils/logger';
import * as Analytics from 'utils/analytics';
import {
  EVENT_HAZARD_ADDED,
  EVENT_HAZARD_MAJOR_HAZARD_TICKED,
  EVENT_HAZARD_REMOVED,
  EVENT_HAZARD_UPDATED,
} from 'components/views/Protected/Jobs/Settings/constants';
import { selectOrganizationId } from 'store/selectors';

function* fetchHazards({ workspaceUuid }) {
  try {
    const data = yield AssessmentSettings.getHazards(workspaceUuid);

    yield put(fetchWorkspaceHazardsSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchWorkspaceHazardsError());
    yield put(setGlobalError(err));
  }
}

function* addHazard({ workspaceUuid, hazardTitles }) {
  const hazard = {
    title: hazardTitles?.[Languages.english.locale],
    title_es: hazardTitles?.[Languages.spanish.locale],
  };
  try {
    const data = yield AssessmentSettings.addHazard(workspaceUuid, hazard);
    const hazardList = yield AssessmentSettings.getHazards(workspaceUuid);

    yield put(addNewHazardSuccess(workspaceUuid, hazardList, data));
    Analytics.trackEvent(EVENT_HAZARD_ADDED, {
      hazardId: data?.id,
      hazardName: data?.title,
      hazardName_es: data?.titleEs,
    });
  } catch (err) {
    Logger.error(err);
    yield put(addNewHazardError(hazard));
    yield put(setGlobalError(err));
  }
}

function* editHazard({ workspaceUuid, hazardTitles }) {
  const hazard = {
    ...hazardTitles,
    title: hazardTitles?.[Languages.english.locale],
    titleEs: hazardTitles?.[Languages.spanish.locale],
  };
  try {
    const data = yield AssessmentSettings.updateHazard(workspaceUuid, hazard);
    const hazardList = yield AssessmentSettings.getHazards(workspaceUuid);

    yield put(editExistingHazardSuccess(workspaceUuid, hazardList, data));
    Analytics.trackEvent(EVENT_HAZARD_UPDATED, {
      hazardId: data?.id,
      hazardName: data?.title,
      hazardName_es: data?.titleEs,
    });
  } catch (err) {
    Logger.error(err);
    yield put(editExistingHazardError(hazard));
    yield put(setGlobalError(err));
  }
}

function* deleteHazard({ workspaceUuid, hazard }) {
  try {
    yield AssessmentSettings.deleteHazard(workspaceUuid, hazard?.id);

    yield put(removeExistingHazardSuccess(workspaceUuid, hazard?.id));
    Analytics.trackEvent(EVENT_HAZARD_REMOVED, {
      hazardId: hazard?.id,
      hazardName: hazard?.title,
      hazardName_es: hazard?.titleEs,
    });
  } catch (err) {
    Logger.error(err);
    yield put(removeExistingHazardError(hazard));
    yield put(setGlobalError(err));
  }
}

function* changeHazardMajorStatus({ workspaceUuid, hazard }) {
  try {
    const organisationId = yield select(selectOrganizationId);
    const data = yield AssessmentSettings.updateHazard(workspaceUuid, hazard);

    yield put(changeHazardMajorStatusSuccess(workspaceUuid, data));
    Analytics.trackEvent(EVENT_HAZARD_MAJOR_HAZARD_TICKED, {
      hazardId: data?.id,
      hazardName: data?.title,
      hazardName_es: data?.titleEs,
      isMajor: data?.isMajor,
      workspaceUuid,
      organisationId,
    });
  } catch (err) {
    Logger.error(err);
    yield put(changeHazardMajorStatusError(hazard));
    yield put(setGlobalError(err));
  }
}

export default function* workspaceHazardsWatcher() {
  yield takeLatest(FETCH_WORKSPACE_HAZARDS, fetchHazards);
  yield takeLatest(ADD_WORKSPACE_HAZARD, addHazard);
  yield takeLatest(EDIT_WORKSPACE_HAZARD, editHazard);
  yield takeLatest(REMOVE_WORKSPACE_HAZARD, deleteHazard);
  yield takeLatest(
    CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS,
    changeHazardMajorStatus,
  );
}

// WEBSOCKET ACTIONS
export const WEBSOCKET_INIT = 'WEBSOCKET_INIT';
export const WEBSOCKET_CLOSE = 'WEBSOCKET_CLOSE';
export const WEBSOCKET_OPENED = 'WEBSOCKET_OPENED';
export const WEBSOCKET_CLOSED = 'WEBSOCKET_CLOSED';
export const WEBSOCKET_MESSAGE_RECEIVED = 'WEBSOCKET_MESSAGE_RECEIVED';
export const WEBSOCKET_ERROR_THROWN = 'WEBSOCKET_ERROR_THROWN';
export const WEBSOCKET_RESTART = 'WEBSOCKET_RESTART';
export const WEBSOCKET_HANDLE_ERROR = 'WEBSOCKET_HANDLE_ERROR';

// MESSAGE ACTIONS
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const GET_MESSAGES = 'GET_MESSAGES';
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const GET_MORE_MESSAGES = 'GET_MORE_MESSAGES';
export const APPEND_MESSAGES = 'APPEND_MESSAGES';
export const SET_REACTION = 'SET_REACTION';

// SESSION ACTIONS
export const START_AUTH = 'START_AUTH';
export const END_AUTH = 'END_AUTH';
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SIGN_IN = 'SIGN_IN';
export const TOKEN_SIGN_IN = 'TOKEN_SIGN_IN';
export const REQUEST_MAGIC_LINK = 'REQUEST_MAGIC_LINK';
export const MAGIC_LINK_REQUESTED = 'MAGIC_LINK_REQUESTED';
export const SIGN_OUT = 'SIGN_OUT';
export const COMPLETE_NEW_PASSWORD = 'COMPLETE_NEW_PASSWORD';
export const COMPLETE_NEW_PASSWORD_DONE = 'COMPLETE_NEW_PASSWORD_DONE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SENT = 'FORGOT_PASSWORD_SENT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_DONE = 'RESET_PASSWORD_DONE';
export const SESSION_ERROR = 'SESSION_ERROR';
export const PASSWORD_ERROR = 'PASSWORD_ERROR';
export const RESET_SESSION_ERRORS = 'RESET_SESSION_ERRORS';
export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN';
export const SET_LAST_REFRESH = 'SET_LAST_REFRESH';
export const SET_WORKSPACE = 'SET_WORKSPACE';
export const UPDATE_WORKSPACE_SETTINGS = 'UPDATE_WORKSPACE_SETTINGS';
export const RELOAD_FOR_WORKSPACE = 'RELOAD_FOR_WORKSPACE';
export const INIT_GLOBAL_LANGUAGE_SETTING = 'INIT_GLOBAL_LANGUAGE_SETTING';
export const SET_GLOBAL_LANGUAGE_SETTING = 'SET_GLOBAL_LANGUAGE_SETTING';

// USER ACTIONS
export const SET_LATEST_PSI_REVIEW = 'SET_LATEST_PSI_REVIEW';
export const SET_PSI_DISMISSED = 'SET_PSI_DISMISSED';
export const FETCH_TEAMS = 'FETCH_TEAMS';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_ERROR = 'FETCH_TEAMS_ERROR';
export const CLEAR_TEAMS = 'CLEAR_TEAMS';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_MORE_USERS = 'FETCH_MORE_USERS';
export const SET_USERS = 'SET_USERS';
export const APPEND_USERS = 'APPEND_USERS';

// JOB ACTIONS
export const FETCH_LIST_JOBS = 'FETCH_LIST_JOBS';
export const FETCH_MAP_JOBS = 'FETCH_MAP_JOBS';
export const FETCH_LEGACY_VIEW_JOBS = 'FETCH_LEGACY_VIEW_JOBS';
export const FETCH_JOB_STATS = 'FETCH_JOB_STATS';
export const FETCH_JOB_STATS_THROTTLED = 'FETCH_JOB_STATS_THROTTLED';
export const CLEAR_JOBS = 'CLEAR_JOBS';
export const FETCH_MORE_LEGACY_VIEW_JOBS = 'FETCH_MORE_LEGACY_VIEW_JOBS';
export const FETCH_ACTIVE_JOB = 'FETCH_ACTIVE_JOB';
export const SET_LIST_JOBS = 'SET_LIST_JOBS';
export const SET_MAP_JOBS = 'SET_MAP_JOBS';
export const SET_PINNED_JOB_STATUS = 'SET_PINNED_JOB_STATUS';
export const SET_LEGACY_VIEW_JOBS = 'SET_LEGACY_VIEW_JOBS';
export const APPEND_LEGACY_VIEW_JOBS = 'APPEND_LEGACY_VIEW_JOBS';
export const SET_ACTIVE_JOB = 'SET_ACTIVE_JOB';
export const RESET_ACTIVE_JOB = 'RESET_ACTIVE_JOB';
export const SET_ACTIVE_JOB_NEARBY_PATH = 'SET_ACTIVE_JOB_NEARBY_PATH';
export const SET_PREVIOUS_TIMELINE_PATH = 'SET_PREVIOUS_TIMELINE_PATH';
export const UPDATE_JOB = 'UPDATE_JOB';
export const SET_JOB_FILTERS = 'SET_JOB_FILTERS';
export const FETCH_FORM_OPTIONS = 'FETCH_FORM_OPTIONS';
export const SET_FORM_OPTIONS = 'SET_FORM_OPTIONS';
export const CLEAR_FORM_OPTIONS = 'CLEAR_FORM_OPTIONS';
export const SET_JOB_SORT = 'SET_JOB_SORT';
export const JOB_MARK_AS_READ = 'JOB_MARK_AS_READ';
export const SET_JOB_STATS = 'SET_JOB_STATS';
export const SET_LEGACY_VIEW_JOB_STATS = 'SET_LEGACY_VIEW_JOB_STATS';
export const FETCH_WEATHER_FOR_JOB = 'FETCH_WEATHER_FOR_JOB';
export const SET_WEATHER_FOR_JOB = 'SET_WEATHER_FOR_JOB';
export const FETCH_WEATHER_ERROR = 'FETCH_WEATHER_ERROR';
export const FETCH_EVIDENCES = 'FETCH_EVIDENCES';
export const FETCH_EVIDENCES_ERROR = 'FETCH_EVIDENCES_ERROR';
export const SET_EVIDENCES = 'SET_EVIDENCES';
export const APPEND_EVIDENCE = 'APPEND_EVIDENCE';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const FETCH_DOCUMENTS_ERROR = 'FETCH_DOCUMENTS_ERROR';
export const SET_LOGS = 'SET_LOGS';
export const FETCH_LOGS = 'FETCH_LOGS';
export const FETCH_LOGS_ERROR = 'FETCH_LOGS_ERROR';
export const SET_JOB_BLOCKERS = 'SET_JOB_BLOCKERS';
export const FETCH_JOB_BLOCKERS = 'FETCH_JOB_BLOCKERS';
export const FETCH_JOB_BLOCKERS_ERROR = 'FETCH_JOB_BLOCKERS_ERROR';
export const APPEND_DOCUMENT = 'APPEND_DOCUMENT';
export const FETCH_WORK_ORDER_ITEMS = 'FETCH_WORK_ORDER_ITEMS';
export const FETCH_WORK_ORDER_ITEMS_ERROR = 'FETCH_WORK_ORDER_ITEMS_ERROR';
export const SET_WORK_ORDER_ITEMS = 'SET_WORK_ORDER_ITEMS';
export const FETCH_PERMITS = 'FETCH_PERMITS';
export const FETCH_PERMITS_ERROR = 'FETCH_PERMITS_ERROR';
export const SET_PERMITS = 'SET_PERMITS';
export const UPDATE_PERMIT = 'UPDATE_PERMIT';
export const SET_JOB_PHOTOS = 'SET_JOB_PHOTOS';
export const FETCH_JOB_PHOTOS = 'FETCH_JOB_PHOTOS';
export const UPDATE_USER_REVIEW_PSI_SCORE = 'UPDATE_USER_REVIEW_PSI_SCORE';
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';
export const FETCH_JOB_TYPES = 'FETCH_JOB_TYPES';
export const FETCH_JOB_TYPES_SUCCESS = 'FETCH_JOB_TYPES_SUCCESS';
export const FETCH_JOB_TYPES_ERROR = 'FETCH_JOB_TYPES_ERROR';
export const FETCH_JOB_SUBTYPES = 'FETCH_JOB_SUBTYPES';
export const FETCH_JOB_SUBTYPES_SUCCESS = 'FETCH_JOB_SUBTYPES_SUCCESS';
export const FETCH_JOB_SUBTYPES_ERROR = 'FETCH_JOB_SUBTYPES_ERROR';
export const FETCH_DEPOTS = 'FETCH_DEPOTS';
export const FETCH_DEPOTS_SUCCESS = 'FETCH_DEPOTS_SUCCESS';
export const FETCH_DEPOTS_ERROR = 'FETCH_DEPOTS_ERROR';
export const ADD_ME_TO_JOB = 'ADD_ME_TO_JOB';
export const ADD_ME_TO_JOB_SUCCESS = 'ADD_ME_TO_JOB_SUCCESS';
export const ADD_ME_TO_JOB_ERROR = 'ADD_ME_TO_JOB_ERROR';
export const PROCESS_UNREAD_MESSAGE = 'PROCESS_UNREAD_MESSAGE';

// ASSESSMENT ACTIONS
export const FETCH_ASSESSMENTS = 'FETCH_ASSESSMENTS';
export const SET_ASSESSMENTS = 'SET_ASSESSMENTS';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';
export const SET_ASSESSMENT = 'SET_ASSESSMENT';
export const SET_UPDATED_ASSESSMENT = 'SET_UPDATED_ASSESSMENT';
export const FETCH_ASSESSMENT = 'FETCH_ASSESSMENT';
export const SEND_SUGGESTION = 'SEND_SUGGESTION';
export const CLEAR_ASSESSMENT = 'CLEAR_ASSESSMENT';
export const SET_ASSESSMENT_ERROR = 'SET_ASSESSMENT_ERROR';
export const SEND_ASSESSMENT_SUMMARY_FEEDBACK =
  'SEND_ASSESSMENT_SUMMARY_FEEDBACK';
export const ASSESSMENT_SUMMARY_FEEDBACK_SUCCESS =
  'ASSESSMENT_SUMMARY_FEEDBACK_SUCCESS';
export const ASSESSMENT_SUMMARY_FEEDBACK_ERROR =
  'ASSESSMENT_SUMMARY_FEEDBACK_ERROR';
export const REQUEST_NEW_VRA = 'REQUEST_NEW_VRA';
export const REQUEST_NEW_VRA_SUCCESS = 'REQUEST_NEW_VRA_SUCCESS';
export const REQUEST_NEW_VRA_ERROR = 'REQUEST_NEW_VRA_ERROR';
export const GENERATE_ASSESSMENT_PDF = 'GENERATE_ASSESSMENT_PDF';
export const GENERATE_ASSESSMENT_PDF_SUCCESS =
  'GENERATE_ASSESSMENT_PDF_SUCCESS';
export const GENERATE_ASSESSMENT_PDF_ERROR = 'GENERATE_ASSESSMENT_PDF_ERROR';

// VIEW VERSION ACTIONS
export const SET_USER_VIEW_SELECTION = 'SET_USER_VIEW_SELECTION';
export const CLEAR_USER_VIEW_SELECTION = 'CLEAR_USER_VIEW_SELECTION';
export const SET_ACTIVE_JOB_TAB = 'SET_ACTIVE_JOB_TAB';
export const SET_TAB = 'SET_TAB';

// INSIGHTS ACTIONS
export const SET_CURRENT_INSIGHTS_TAB = 'SET_CURRENT_INSIGHTS_TAB';
export const FETCH_ENGAGEMENT_DATA = 'FETCH_ENGAGEMENT_DATA';
export const FETCH_ENGAGEMENT_DATA_SUCCESS = 'FETCH_ENGAGEMENT_DATA_SUCCESS';
export const FETCH_ENGAGEMENT_DATA_ERROR = 'FETCH_ENGAGEMENT_DATA_ERROR';

export const FETCH_COMPLIANCE_DATA = 'FETCH_COMPLIANCE_DATA';
export const FETCH_COMPLIANCE_DATA_SUCCESS = 'FETCH_COMPLIANCE_DATA_SUCCESS';
export const FETCH_COMPLIANCE_DATA_ERROR = 'FETCH_COMPLIANCE_DATA_ERROR';

export const FETCH_JOBS_PER_DAY_GRAPHS = 'FETCH_JOBS_PER_DAY_GRAPHS';
export const FETCH_JOBS_PER_DAY_GRAPHS_SUCCESS =
  'FETCH_JOBS_PER_DAY_GRAPHS_SUCCESS';
export const FETCH_JOBS_PER_DAY_GRAPHS_ERROR =
  'FETCH_JOBS_PER_DAY_GRAPHS_ERROR';

export const FETCH_OUTPUT_PER_DAY_GRAPHS = 'FETCH_OUTPUT_PER_DAY_GRAPHS';
export const FETCH_OUTPUT_PER_DAY_GRAPHS_SUCCESS =
  'FETCH_OUTPUT_PER_DAY_GRAPHS_SUCCESS';
export const FETCH_OUTPUT_PER_DAY_GRAPHS_ERROR =
  'FETCH_OUTPUT_PER_DAY_GRAPHS_ERROR';

export const FETCH_JOBS_PER_DAY_STATS = 'FETCH_JOBS_PER_DAY_STATS';
export const FETCH_JOBS_PER_DAY_STATS_SUCCESS =
  'FETCH_JOBS_PER_DAY_STATS_SUCCESS';
export const FETCH_JOBS_PER_DAY_STATS_ERROR = 'FETCH_JOBS_PER_DAY_STATS_ERROR';

export const FETCH_OUTPUT_PER_DAY_STATS = 'FETCH_OUTPUT_PER_DAY_STATS';
export const FETCH_OUTPUT_PER_DAY_STATS_SUCCESS =
  'FETCH_OUTPUT_PER_DAY_STATS_SUCCESS';
export const FETCH_OUTPUT_PER_DAY_STATS_ERROR =
  'FETCH_OUTPUT_PER_DAY_STATS_ERROR';

export const FETCH_INSIGHTS_JOB_BLOCKERS = 'FETCH_INSIGHTS_JOB_BLOCKERS';
export const FETCH_INSIGHTS_JOB_BLOCKERS_SUCCESS =
  'FETCH_INSIGHTS_JOB_BLOCKERS_SUCCESS';
export const FETCH_INSIGHTS_JOB_BLOCKERS_ERROR =
  'FETCH_INSIGHTS_JOB_BLOCKERS_ERROR';

export const FETCH_HAVS_COMPLIANCE = 'FETCH_HAVS_COMPLIANCE';
export const FETCH_HAVS_COMPLIANCE_SUCCESS = 'FETCH_HAVS_COMPLIANCE_SUCCESS';
export const FETCH_HAVS_COMPLIANCE_ERROR = 'FETCH_HAVS_COMPLIANCE_ERROR';
export const SIGN_OFF_HAVS_COMPLIANCE = 'SIGN_OFF_HAVS_COMPLIANCE';
export const SIGN_OFF_HAVS_COMPLIANCE_SUCCESS =
  'SIGN_OFF_HAVS_COMPLIANCE_SUCCESS';
export const SIGN_OFF_HAVS_COMPLIANCE_ERROR = 'SIGN_OFF_HAVS_COMPLIANCE_ERROR';

export const FETCH_VEHICLE_CHECK_COMPLIANCE = 'FETCH_VEHICLE_CHECK_COMPLIANCE';
export const FETCH_VEHICLE_CHECK_COMPLIANCE_SUCCESS =
  'FETCH_VEHICLE_CHECK_COMPLIANCE_SUCCESS';
export const FETCH_VEHICLE_CHECK_COMPLIANCE_ERROR =
  'FETCH_VEHICLE_CHECK_COMPLIANCE_ERROR';

export const FETCH_VEHICLE_CHECK_USER_COMPLIANCE =
  'FETCH_VEHICLE_CHECK_USER_COMPLIANCE';
export const FETCH_VEHICLE_CHECK_USER_COMPLIANCE_SUCCESS =
  'FETCH_VEHICLE_CHECK_USER_COMPLIANCE_SUCCESS';
export const FETCH_VEHICLE_CHECK_USER_COMPLIANCE_ERROR =
  'FETCH_VEHICLE_CHECK_USER_COMPLIANCE_ERROR';

export const FETCH_USER_FORM = 'FETCH_USER_FORM';
export const FETCH_USER_FORM_SUCCESS = 'FETCH_USER_FORM_SUCCESS';
export const FETCH_USER_FORM_ERROR = 'FETCH_USER_FORM_ERROR';
export const FETCH_JOB_FORM = 'FETCH_JOB_FORM';
export const FETCH_JOB_FORM_SUCCESS = 'FETCH_JOB_FORM_SUCCESS';
export const FETCH_JOB_FORM_ERROR = 'FETCH_JOB_FORM_ERROR';
export const FETCH_PERMIT_FORM = 'FETCH_PERMIT_FORM';
export const FETCH_PERMIT_FORM_SUCCESS = 'FETCH_PERMIT_FORM_SUCCESS';
export const FETCH_PERMIT_FORM_ERROR = 'FETCH_PERMIT_FORM_ERROR';

// WORKSPACE ACTIONS
export const FETCH_WORKSPACE_SETTINGS = 'FETCH_WORKSPACE_SETTINGS';
export const FETCH_WORKSPACE_SETTINGS_SUCCESS =
  'FETCH_WORKSPACE_SETTINGS_SUCCESS';
export const FETCH_WORKSPACE_SETTINGS_ERROR = 'FETCH_WORKSPACE_SETTINGS_ERROR';
export const SET_WORKSPACE_SETTINGS = 'SET_WORKSPACE_SETTINGS';
export const SET_WORKSPACE_SETTINGS_SUCCESS = 'SET_WORKSPACE_SETTINGS_SUCCESS';
export const SET_WORKSPACE_SETTINGS_ERROR = 'SET_WORKSPACE_SETTINGS_ERROR';
export const SET_JOB_CREATION_SETTINGS = 'SET_JOB_CREATION_SETTINGS';
export const SET_JOB_CREATION_SETTINGS_SUCCESS =
  'SET_JOB_CREATION_SETTINGS_SUCCESS';
export const SET_JOB_CREATION_SETTINGS_ERROR =
  'SET_JOB_CREATION_SETTINGS_ERROR';
// EVIDENCE TYPES
export const FETCH_EVIDENCE_TYPES = 'FETCH_EVIDENCE_TYPES';
export const FETCH_EVIDENCE_TYPES_SUCCESS = 'FETCH_EVIDENCE_TYPES_SUCCESS';
export const FETCH_EVIDENCE_TYPES_ERROR = 'FETCH_EVIDENCE_TYPES_ERROR';
export const SAVE_EVIDENCE_TYPE_CHANGES = 'SAVE_EVIDENCE_TYPE_CHANGES';
export const SAVE_EVIDENCE_TYPE_CHANGES_SUCCESS =
  'SAVE_EVIDENCE_TYPE_CHANGES_SUCCESS';
export const SAVE_EVIDENCE_TYPE_CHANGES_ERROR =
  'SAVE_EVIDENCE_TYPE_CHANGES_ERROR';
export const ADD_EVIDENCE_TYPE_SUCCESS = 'ADD_EVIDENCE_TYPE_SUCCESS';
export const ADD_EVIDENCE_TYPE_ERROR = 'ADD_EVIDENCE_TYPE_ERROR';
export const EDIT_EVIDENCE_TYPE_SUCCESS = 'EDIT_EVIDENCE_TYPE_SUCCESS';
export const EDIT_EVIDENCE_TYPE_ERROR = 'EDIT_EVIDENCE_TYPE_ERROR';
export const REMOVE_EVIDENCE_TYPE_SUCCESS = 'REMOVE_EVIDENCE_TYPE_SUCCESS';
export const REMOVE_EVIDENCE_TYPE_ERROR = 'REMOVE_EVIDENCE_TYPE_ERROR';
export const RESET_EVIDENCE_TYPE_ERRORS = 'RESET_EVIDENCE_TYPE_ERRORS';
// JOB TYPES
export const FETCH_WORKSPACE_JOB_TYPES = 'FETCH_WORKSPACE_JOB_TYPES';
export const FETCH_WORKSPACE_JOB_TYPES_SUCCESS =
  'FETCH_WORKSPACE_JOB_TYPES_SUCCESS';
export const FETCH_WORKSPACE_JOB_TYPES_ERROR =
  'FETCH_WORKSPACE_JOB_TYPES_ERROR';
export const SAVE_JOB_TYPE_CHANGES = 'SAVE_JOB_TYPE_CHANGES';
export const SAVE_JOB_TYPE_CHANGES_SUCCESS = 'SAVE_JOB_TYPE_CHANGES_SUCCESS';
export const SAVE_JOB_TYPE_CHANGES_ERROR = 'SAVE_JOB_TYPE_CHANGES_ERROR';
export const ADD_JOB_TYPE_SUCCESS = 'ADD_JOB_TYPE_SUCCESS';
export const ADD_JOB_TYPE_ERROR = 'ADD_JOB_TYPE_ERROR';
export const EDIT_JOB_TYPE_SUCCESS = 'EDIT_JOB_TYPE_SUCCESS';
export const EDIT_JOB_TYPE_ERROR = 'EDIT_JOB_TYPE_ERROR';
export const REMOVE_JOB_TYPE_SUCCESS = 'REMOVE_JOB_TYPE_SUCCESS';
export const REMOVE_JOB_TYPE_ERROR = 'REMOVE_JOB_TYPE_ERROR';
export const RESET_JOB_TYPE_ERRORS = 'RESET_JOB_TYPE_ERRORS';
// JOB SUBTYPES
export const FETCH_WORKSPACE_JOB_SUBTYPES = 'FETCH_WORKSPACE_JOB_SUBTYPES';
export const FETCH_WORKSPACE_JOB_SUBTYPES_SUCCESS =
  'FETCH_WORKSPACE_JOB_SUBTYPES_SUCCESS';
export const FETCH_WORKSPACE_JOB_SUBTYPES_ERROR =
  'FETCH_WORKSPACE_JOB_SUBTYPES_ERROR';
export const SAVE_JOB_SUBTYPE_CHANGES = 'SAVE_JOB_SUBTYPE_CHANGES';
export const SAVE_JOB_SUBTYPE_CHANGES_SUCCESS =
  'SAVE_JOB_SUBTYPE_CHANGES_SUCCESS';
export const SAVE_JOB_SUBTYPE_CHANGES_ERROR = 'SAVE_JOB_SUBTYPE_CHANGES_ERROR';
export const ADD_JOB_SUBTYPE_SUCCESS = 'ADD_JOB_SUBTYPE_SUCCESS';
export const ADD_JOB_SUBTYPE_ERROR = 'ADD_JOB_SUBTYPE_ERROR';
export const EDIT_JOB_SUBTYPE_SUCCESS = 'EDIT_JOB_SUBTYPE_SUCCESS';
export const EDIT_JOB_SUBTYPE_ERROR = 'EDIT_JOB_SUBTYPE_ERROR';
export const REMOVE_JOB_SUBTYPE_SUCCESS = 'REMOVE_JOB_SUBTYPE_SUCCESS';
export const REMOVE_JOB_SUBTYPE_ERROR = 'REMOVE_JOB_SUBTYPE_ERROR';
export const RESET_JOB_SUBTYPE_ERRORS = 'RESET_JOB_SUBTYPE_ERRORS';
// JOB BLOCKER TYPES
export const FETCH_WORKSPACE_JOB_BLOCKER_TYPES =
  'FETCH_WORKSPACE_JOB_BLOCKER_TYPES';
export const FETCH_WORKSPACE_JOB_BLOCKER_TYPES_SUCCESS =
  'FETCH_WORKSPACE_JOB_BLOCKER_TYPES_SUCCESS';
export const FETCH_WORKSPACE_JOB_BLOCKER_TYPES_ERROR =
  'FETCH_WORKSPACE_JOB_BLOCKER_TYPES_ERROR';
export const SAVE_JOB_BLOCKER_TYPE_CHANGES = 'SAVE_JOB_BLOCKER_TYPE_CHANGES';
export const SAVE_JOB_BLOCKER_TYPE_CHANGES_SUCCESS =
  'SAVE_JOB_BLOCKER_TYPE_CHANGES_SUCCESS';
export const SAVE_JOB_BLOCKER_TYPE_CHANGES_ERROR =
  'SAVE_JOB_BLOCKER_TYPE_CHANGES_ERROR';
export const ADD_JOB_BLOCKER_TYPE_SUCCESS = 'ADD_JOB_BLOCKER_TYPE_SUCCESS';
export const ADD_JOB_BLOCKER_TYPE_ERROR = 'ADD_JOB_BLOCKER_TYPE_ERROR';
export const EDIT_JOB_BLOCKER_TYPE_SUCCESS = 'EDIT_JOB_BLOCKER_TYPE_SUCCESS';
export const EDIT_JOB_BLOCKER_TYPE_ERROR = 'EDIT_JOB_BLOCKER_TYPE_ERROR';
export const REMOVE_JOB_BLOCKER_TYPE_SUCCESS =
  'REMOVE_JOB_BLOCKER_TYPE_SUCCESS';
export const REMOVE_JOB_BLOCKER_TYPE_ERROR = 'REMOVE_JOB_BLOCKER_TYPE_ERROR';
export const RESET_JOB_BLOCKER_TYPE_ERRORS = 'RESET_JOB_BLOCKER_TYPE_ERRORS';
// JOB BLOCKER SUBTYPES
export const FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES =
  'FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES';
export const FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES_SUCCESS =
  'FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES_SUCCESS';
export const FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES_ERROR =
  'FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES_ERROR';
export const SAVE_JOB_BLOCKER_SUBTYPE_CHANGES =
  'SAVE_JOB_BLOCKER_SUBTYPE_CHANGES';
export const SAVE_JOB_BLOCKER_SUBTYPE_CHANGES_SUCCESS =
  'SAVE_JOB_BLOCKER_SUBTYPE_CHANGES_SUCCESS';
export const SAVE_JOB_BLOCKER_SUBTYPE_CHANGES_ERROR =
  'SAVE_JOB_BLOCKER_SUBTYPE_CHANGES_ERROR';
export const ADD_JOB_BLOCKER_SUBTYPE_SUCCESS =
  'ADD_JOB_BLOCKER_SUBTYPE_SUCCESS';
export const ADD_JOB_BLOCKER_SUBTYPE_ERROR = 'ADD_JOB_BLOCKER_SUBTYPE_ERROR';
export const EDIT_JOB_BLOCKER_SUBTYPE_SUCCESS =
  'EDIT_JOB_BLOCKER_SUBTYPE_SUCCESS';
export const EDIT_JOB_BLOCKER_SUBTYPE_ERROR = 'EDIT_JOB_BLOCKER_SUBTYPE_ERROR';
export const REMOVE_JOB_BLOCKER_SUBTYPE_SUCCESS =
  'REMOVE_JOB_BLOCKER_SUBTYPE_SUCCESS';
export const REMOVE_JOB_BLOCKER_SUBTYPE_ERROR =
  'REMOVE_JOB_BLOCKER_SUBTYPE_ERROR';
export const RESET_JOB_BLOCKER_SUBTYPE_ERRORS =
  'RESET_JOB_BLOCKER_SUBTYPE_ERRORS';
// HAZARDS
export const FETCH_WORKSPACE_HAZARDS = 'FETCH_WORKSPACE_HAZARDS';
export const FETCH_WORKSPACE_HAZARDS_SUCCESS =
  'FETCH_WORKSPACE_HAZARDS_SUCCESS';
export const FETCH_WORKSPACE_HAZARDS_ERROR = 'FETCH_WORKSPACE_HAZARDS_ERROR';
export const ADD_WORKSPACE_HAZARD = 'ADD_WORKSPACE_HAZARD';
export const ADD_WORKSPACE_HAZARD_SUCCESS = 'ADD_WORKSPACE_HAZARD_SUCCESS';
export const ADD_WORKSPACE_HAZARD_ERROR = 'ADD_WORKSPACE_HAZARD_ERROR';
export const EDIT_WORKSPACE_HAZARD = 'EDIT_WORKSPACE_HAZARD';
export const EDIT_WORKSPACE_HAZARD_SUCCESS = 'EDIT_WORKSPACE_HAZARD_SUCCESS';
export const EDIT_WORKSPACE_HAZARD_ERROR = 'EDIT_WORKSPACE_HAZARD_ERROR';
export const REMOVE_WORKSPACE_HAZARD = 'REMOVE_WORKSPACE_HAZARD';
export const REMOVE_WORKSPACE_HAZARD_SUCCESS =
  'REMOVE_WORKSPACE_HAZARD_SUCCESS';
export const REMOVE_WORKSPACE_HAZARD_ERROR = 'REMOVE_WORKSPACE_HAZARD_ERROR';
export const RESET_WORKSPACE_HAZARD_ERROR = 'RESET_WORKSPACE_HAZARD_ERROR';
export const CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS =
  'CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS';
export const CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS_SUCCESS =
  'CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS_SUCCESS';
export const CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS_ERROR =
  'CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS_ERROR';
export const UPDATE_WORKSPACE_HAZARD_ASSIGNED_CONTROL_LIST =
  'UPDATE_WORKSPACE_HAZARD_ASSIGNED_CONTROL_LIST';
// CONTROLS
export const FETCH_WORKSPACE_CONTROLS_FOR_HAZARD =
  'FETCH_WORKSPACE_CONTROLS_FOR_HAZARD';
export const FETCH_WORKSPACE_CONTROLS_FOR_HAZARD_SUCCESS =
  'FETCH_WORKSPACE_CONTROLS_FOR_HAZARD_SUCCESS';
export const FETCH_WORKSPACE_CONTROLS_FOR_HAZARD_ERROR =
  'FETCH_WORKSPACE_CONTROLS_FOR_HAZARD_ERROR';
export const ADD_WORKSPACE_CONTROL_TO_HAZARD =
  'ADD_WORKSPACE_CONTROL_TO_HAZARD';
export const ADD_WORKSPACE_CONTROL_TO_HAZARD_SUCCESS =
  'ADD_WORKSPACE_CONTROL_TO_HAZARD_SUCCESS';
export const ADD_WORKSPACE_CONTROL_TO_HAZARD_ERROR =
  'ADD_WORKSPACE_CONTROL_TO_HAZARD_ERROR';
export const RESET_WORKSPACE_CONTROL_ERROR = 'RESET_WORKSPACE_CONTROL_ERROR';
export const EDIT_WORKSPACE_CONTROL = 'EDIT_WORKSPACE_CONTROL';
export const EDIT_WORKSPACE_CONTROL_SUCCESS = 'EDIT_WORKSPACE_CONTROL_SUCCESS';
export const EDIT_WORKSPACE_CONTROL_ERROR = 'EDIT_WORKSPACE_CONTROL_ERROR';
export const REMOVE_WORKSPACE_CONTROL = 'REMOVE_WORKSPACE_CONTROL';
export const REMOVE_WORKSPACE_CONTROL_SUCCESS =
  'REMOVE_WORKSPACE_CONTROL_SUCCESS';
export const REMOVE_WORKSPACE_CONTROL_ERROR = 'REMOVE_WORKSPACE_CONTROL_ERROR';
// ASSESSMENTS
export const FETCH_WORKSPACE_ASSESSMENTS = 'FETCH_WORKSPACE_ASSESSMENTS';
export const FETCH_WORKSPACE_ASSESSMENTS_SUCCESS =
  'FETCH_WORKSPACE_ASSESSMENTS_SUCCESS';
export const FETCH_WORKSPACE_ASSESSMENTS_ERROR =
  'FETCH_WORKSPACE_ASSESSMENTS_ERROR';
export const ADD_WORKSPACE_ASSESSMENT = 'ADD_WORKSPACE_ASSESSMENT';
export const ADD_WORKSPACE_ASSESSMENT_SUCCESS =
  'ADD_WORKSPACE_ASSESSMENT_SUCCESS';
export const ADD_WORKSPACE_ASSESSMENT_ERROR = 'ADD_WORKSPACE_ASSESSMENT_ERROR';
export const REMOVE_WORKSPACE_ASSESSMENT = 'REMOVE_WORKSPACE_ASSESSMENT';
export const REMOVE_WORKSPACE_ASSESSMENT_SUCCESS =
  'REMOVE_WORKSPACE_ASSESSMENT_SUCCESS';
export const REMOVE_WORKSPACE_ASSESSMENT_ERROR =
  'REMOVE_WORKSPACE_ASSESSMENT_ERROR';
export const CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT =
  'CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT';
export const CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT_SUCCESS =
  'CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT_SUCCESS';
export const CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT_ERROR =
  'CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT_ERROR';
export const TOGGLE_HAZARD_ASSESSMENT_LINKING =
  'TOGGLE_HAZARD_ASSESSMENT_LINKING';
export const RESET_WORKSPACE_ASSESSMENT_ERROR =
  'RESET_WORKSPACE_ASSESSMENT_ERROR';
export const EDIT_WORKSPACE_ASSESSMENT = 'EDIT_WORKSPACE_ASSESSMENT';
export const EDIT_WORKSPACE_ASSESSMENT_SUCCESS =
  'EDIT_WORKSPACE_ASSESSMENT_SUCCESS';
export const EDIT_WORKSPACE_ASSESSMENT_ERROR =
  'EDIT_WORKSPACE_ASSESSMENT_ERROR';

// FEATURES GUIDE
export const SET_FEATURES_GUIDE_OPEN_STATUS = 'SET_FEATURES_GUIDE_OPEN_STATUS';
export const SET_FEATURES_GUIDE_CURRENT_STEP =
  'SET_FEATURES_GUIDE_CURRENT_STEP';

// DOCUMENTS
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';

import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins } = theme;

export const PhotoListContainer = styled.div`
  margin-bottom: 10px;
  height: auto;
  ${mixins.flexCenter};
  flex-wrap: wrap;
`;

export const PhotoContainer = styled.div`
  flex-basis: 33%;
`;

export const Image = styled.img`
  width: 120px;
`;

export const ImageContainer = styled.div`
  border: 1px solid ${colors.grey};
  background-color: ${colors.offWhite};
  min-height: 240px;
  padding: 10px 10px 5px 10px;
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 80%;
  align-self: center;
  margin-top: 5px;
  padding: 5px 7px;
`;

export const ImageTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.s};
  color: ${colors.darkGrey};
  margin-bottom: 5px;
`;

export const GridContainer = styled.div`
  padding: 5px;
  height: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EditText = styled.div`
  font-family: ${fonts.bold};
  text-decoration: underline;
  cursor: pointer;
`;

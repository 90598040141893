import Amplify from 'aws-amplify';
import axios from 'axios';

export default {
  google: {
    map: {
      PUBLIC_KEY: 'AIzaSyBT8WAeMcw5HZKtWPFA85uTaKL3Cu-6mYw',
    },
  },
};

export const configureCognito = () => {
  Amplify.configure({
    Auth: {
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
        responseType: 'code',
      },
    },
    Storage: {
      AWSS3: {
        bucket: process.env.REACT_APP_S3_DATA_BUCKET,
        region: process.env.REACT_APP_AWS_REGION,
        customPrefix: { public: '', protected: '', private: '' },
      },
    },
  });
};

export const configureAxios = (options = {}) => {
  Object.keys(options).forEach((key) => {
    if (key === 'baseURL') {
      axios.defaults.baseURL = options[key];
    }
  });

  const isAmazonUrl = (url) => url.indexOf('amazonaws') > -1;

  // Add latest auth access token to every http request
  axios.interceptors.request.use((config) =>
    Amplify.Auth.currentSession()
      .then((session) => {
        // User is logged in. Set auth header on all requests
        const newConfig = { ...config };
        if (!isAmazonUrl(config.url)) {
          newConfig.headers.Authorization = `Bearer ${session.idToken.jwtToken}`;
        }
        return Promise.resolve(newConfig);
      })
      .catch(() =>
        // No logged-in user: don't set auth header
        Promise.resolve(config),
      ),
  );
};

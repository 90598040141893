import { useSelector } from 'react-redux';
import { Names } from 'helpers';
import {
  selectWorkspaceSettings,
  selectGlobalLanguageSetting,
} from 'store/selectors';
import { UserRole } from 'model/enum/UserRole';
import {
  Info,
  UserAvatar,
  UserDetailsWrapper,
  UserDropdownItemWrapper,
  UserName,
  UserTextWrapper,
} from '../styled';
import { getTitleForRole } from '../helpers';

type Props = {
  id: number;
  firstName: string;
  lastName: string;
  role: UserRole;
  email?: string;
  isCurrentUser: boolean;
};

export const UserBadge = ({
  id,
  firstName,
  lastName,
  role,
  email,
  isCurrentUser,
  ...props
}: Props): JSX.Element => {
  const workspaceSettings = useSelector(selectWorkspaceSettings);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  return (
    <UserDropdownItemWrapper key={id} {...props}>
      <UserAvatar
        $isCurrentUser={isCurrentUser}
        $isManager={role === UserRole.MANAGER}
        $isBackOffice={role === UserRole.BACKOFFICE_ADMIN}
      >
        {Names.getInitials(firstName, lastName)}
      </UserAvatar>
      <UserTextWrapper>
        <UserName>{Names.capitalizeFullName(firstName, lastName)}</UserName>
        <UserDetailsWrapper>
          <Info>
            {getTitleForRole(role, globalAppLanguage, workspaceSettings)}
          </Info>
          {email && <Info>{email}</Info>}
        </UserDetailsWrapper>
      </UserTextWrapper>
    </UserDropdownItemWrapper>
  );
};

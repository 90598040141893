import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useText } from 'hooks';
import theme from 'theme';
import { Storage } from 'aws-amplify';
import { getVideo, hasVideo } from 'helpers/forms';
import { Button, Loader, Media, Navbar } from 'components/ui';
import PermitStatus from './PermitStatus';
import RejectionComment from './Modal/RejectionComment';
import PermitDetails from './Modal/PermitDetails';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFetchingPermits, selectPermit } from 'store/selectors';
import { fetchPermits } from 'store/actions/jobs';
import { ModalFooter } from '../PdfExport/ModalFooter';
import { DownloadType } from '../PdfExport/constants';
import { CustomFormClass } from 'model/Form';

const { media } = theme;

/* Styled Components */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 55px;
  ${media.sm`
    padding-bottom: 0;
  `}
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 16px;
`;

const StyledLinkButton = styled(Button.PrimaryLink)`
  ${media.sm`
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 2;
  `}
`;

const RejectionButtonContainer = styled.div`
  padding: 0 15px 30px 15px;
`;

/* Permit.Show */
const Show = (): JSX.Element => {
  const { jobId, permitId } = useParams();
  const permit = useSelector(selectPermit(jobId, permitId));
  const isFetchingPermits = useSelector(selectIsFetchingPermits);
  const getText = useText();
  const dispatch = useDispatch();
  const [videoUrl, setVideoUrl] = useState<string>();

  useEffect(() => {
    (async () => {
      if (permit?.form && hasVideo(permit?.form)) {
        const signedURL = await Storage.get(
          permit?.form?.sections[0]?.fields[0]?.value.path,
        );
        setVideoUrl(signedURL);
      }
    })();
  }, [permit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!permit) {
      dispatch(fetchPermits(jobId));
    }
  }, [permitId, jobId, permit]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Navbar
        title={permit?.type?.title}
        defaultBackPath={`/jobs/${jobId}/permits`}
        mobileOnly
      />

      <>
        {isFetchingPermits ? (
          <Loader />
        ) : (
          <>
            <Container>
              {hasVideo(permit?.form) && videoUrl && (
                <Media
                  kind='permit'
                  type='video'
                  url={videoUrl}
                  height='425px'
                  item={getVideo(permit?.form, permit?.videos)}
                />
              )}
              <Section>
                <PermitStatus
                  lastApproval={permit?.lastApproval}
                  extraStyles={{
                    borderRadius: '3px',
                    margin: '0 15px 30px 15px',
                  }}
                />
                {permit?.lastApproval && !permit.lastApproval.isApproved && (
                  <RejectionComment lastApproval={permit.lastApproval} />
                )}
                {permit && <PermitDetails permit={permit} />}
                {!permit?.lastApproval && (
                  <RejectionButtonContainer>
                    <Button.SecondaryLink
                      text={getText('job_permits_reject_with_comment')}
                      extraStyles={{ marginTop: '20px' }}
                      to={`/jobs/${jobId}/permits/${permitId}/rejection`}
                    />
                  </RejectionButtonContainer>
                )}
              </Section>
              <ModalFooter
                downloadType={DownloadType.PERMIT}
                downloadItemId={permit?.id.toString()}
                jobId={jobId}
                formClass={CustomFormClass.PERMIT}
              />
            </Container>
            <StyledLinkButton
              text={getText(
                permit?.lastApproval
                  ? 'job_permits_go_to_timeline_button'
                  : 'job_permits_set_duration',
              )}
              width='328px'
              to={
                permit?.lastApproval
                  ? `/jobs/${jobId}/timeline`
                  : `/jobs/${jobId}/permits/${permitId}/set-duration`
              }
            />
          </>
        )}
      </>
    </>
  );
};

export default Show;

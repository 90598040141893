import { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
import { Modal, Input } from 'components/ui';
import { Warning } from 'assets/icons';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { FatigueLiveViewItem } from 'model/FatigueManager/FatigueLiveViewItem';
import { isAfter } from 'date-fns';
import { isSameOrBefore } from 'helpers/dates';

const { fonts, fontSizes } = theme;

const Title = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  padding-bottom: 30px;
`;

const Container = styled.div`
  width: 80%;
  margin: 20px auto;
`;

const Confirm = styled.div`
  font-size: ${fontSizes.m};
  font-family: ${fonts.light};
  padding-bottom: 20px;
`;

const WarningContainer = styled.div`
  padding: 40px 0px;
  display: flex;
  align-items: center;
`;

const WarningMessage = styled.div`
  font-size: ${fontSizes.m};
  font-family: ${fonts.bold};
`;

const WarningIcon = styled(Warning)`
  height: 60px;
  width: 60px;
  margin-right: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.span<{ disabled?: boolean }>`
  text-decoration: underline;
  cursor: pointer;
  padding: 0px 20px;
  text-transform: capitalize;
  font-size: ${fontSizes.m};

  ${({ disabled }) =>
    disabled &&
    `
      cursor: default;
      opacity: 0.5;
    `};
`;

const UpdateTimerButton = styled.span<{ $isUnderlined: boolean }>`
  text-decoration: ${({ $isUnderlined }) =>
    $isUnderlined ? 'underline' : 'none'};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.s};
  cursor: pointer;
`;

type Props = {
  selectedFatigueLiveRowItem?: FatigueLiveViewItem;
  isVisible: boolean;
  setVisible: (value: boolean) => void;
  onButtonClick: (value: Date) => void;
};

const FatigueModal = ({
  selectedFatigueLiveRowItem,
  isVisible,
  setVisible,
  onButtonClick,
}: Props): JSX.Element => {
  const getText = useText();
  const [endTime, setEndTime] = useState<Date>(new Date());
  const [showTimer, setShowTimer] = useState<boolean>(false);
  const [isInvalidDate, setIsInvalidDate] = useState<boolean>(false);
  const confirmMessage = getText(
    'fatigue_manager_live_data_stop_timer_confirmation',
    {
      name: selectedFatigueLiveRowItem?.user.fullName,
    },
  );
  const warningMessage = getText(
    'fatigue_manager_live_data_stop_timer_warning',
    {
      name: selectedFatigueLiveRowItem?.user.fullName,
    },
  );

  useEffect(() => {
    setIsInvalidDate(
      !!endTime &&
        !!selectedFatigueLiveRowItem?.currentShiftStartedAt &&
        (isAfter(endTime, new Date()) ||
          isSameOrBefore(
            endTime,
            selectedFatigueLiveRowItem?.currentShiftStartedAt,
          )),
    );
  }, [endTime, selectedFatigueLiveRowItem]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal.Base
      isOpen={isVisible}
      onClose={() => setVisible(false)}
      width='650px'
    >
      <Container>
        <Title>
          {getText('fatigue_manager_live_data_confirm_shift_title')}
        </Title>
        <Confirm>{confirmMessage}</Confirm>
        <UpdateTimerButton
          $isUnderlined={!showTimer}
          onClick={() => {
            setShowTimer(true);
            Analytics.trackEvent(
              constants.EVENT_SHIFT_UPDATE_USER_FINISHING_TIME,
              {
                updatedShiftId: selectedFatigueLiveRowItem?.currentShiftId,
              },
            );
          }}
        >
          {getText('fatigue_manager_live_data_update_user_finishing_time')}
        </UpdateTimerButton>
        {showTimer && (
          <Input.DateTimeSelector
            isInvalid={isInvalidDate}
            extraStyles={{ margin: '15px 0' }}
            shiftStartedAt={selectedFatigueLiveRowItem?.currentShiftStartedAt.toString()}
            onChange={(e) => {
              if (e) {
                setEndTime(e);
              }
            }}
          />
        )}
        <WarningContainer>
          <WarningIcon />
          <WarningMessage>{warningMessage}</WarningMessage>
        </WarningContainer>
        <ButtonContainer>
          <Button onClick={() => setVisible(false)}>{getText('cancel')}</Button>
          <Button
            onClick={() => (isInvalidDate ? null : onButtonClick(endTime))}
            disabled={isInvalidDate}
          >
            {getText('confirm')}
          </Button>
        </ButtonContainer>
      </Container>
    </Modal.Base>
  );
};

export default FatigueModal;

import axios from 'axios';
import {
  AssessmentType,
  AssessmentTypes,
} from 'model/Assessment/AssessmentType';
import { Control, Controls } from 'model/Assessment/Control';
import { Hazard, Hazards } from 'model/Assessment/Hazard';
import { BackendTitleData } from './types';

export const getHazards = async (workspaceUuid: string): Promise<Hazard[]> => {
  const { data } = await axios.get(
    `/workspace-settings/${workspaceUuid}/hazards/`,
  );
  return data.map(Hazards.mapToHazard);
};

export const addHazard = async (
  workspaceUuid: string,
  hazard: BackendTitleData,
): Promise<Hazard> => {
  const { data } = await axios.post(
    `/workspace-settings/${workspaceUuid}/hazards/`,
    hazard,
  );
  return Hazards.mapToHazard(data);
};

export const updateHazard = async (
  workspaceUuid: string,
  hazard: Hazard,
): Promise<Hazard> => {
  const { data } = await axios.put(
    `/workspace-settings/${workspaceUuid}/hazards/${hazard?.id}/`,
    Hazards.mapToBackendHazard(hazard),
  );
  return Hazards.mapToHazard(data);
};

export const deleteHazard = async (
  workspaceUuid: string,
  hazardId: number,
): Promise<void> => {
  await axios.delete(
    `/workspace-settings/${workspaceUuid}/hazards/${hazardId}/`,
  );
};

export const getControlsForHazard = async (
  workspaceUuid: string,
  hazardId: number,
): Promise<Control[]> => {
  const { data } = await axios.get(
    `/workspace-settings/${workspaceUuid}/hazards/${hazardId}/controls/`,
  );
  return data.map(Controls.mapToControl);
};

export const addControlToHazard = async (
  workspaceUuid: string,
  hazardId: number,
  control: BackendTitleData,
): Promise<Control> => {
  const { data } = await axios.post(
    `/workspace-settings/${workspaceUuid}/hazards/${hazardId}/controls/`,
    control,
  );
  return Controls.mapToControl(data);
};

export const updateControl = async (
  workspaceUuid: string,
  hazardId: number,
  control: Control,
): Promise<Control> => {
  const { data } = await axios.put(
    `/workspace-settings/${workspaceUuid}/hazards/${hazardId}/controls/${control?.id}/`,
    Controls.mapToBackendControl(control),
  );
  return Controls.mapToControl(data);
};

export const deleteControl = async (
  workspaceUuid: string,
  hazardId: number,
  controlId: number,
): Promise<void> => {
  await axios.delete(
    `/workspace-settings/${workspaceUuid}/hazards/${hazardId}/controls/${controlId}/`,
  );
};

export const getAssessments = async (
  workspaceUuid: string,
): Promise<AssessmentType[]> => {
  const { data } = await axios.get(
    `/workspace-settings/${workspaceUuid}/assessment-types/`,
  );
  return data.map(AssessmentTypes.mapToAssessmentType);
};

export const addAssessment = async (
  workspaceUuid: string,
  assessment: BackendTitleData,
): Promise<AssessmentType> => {
  const { data } = await axios.post(
    `/workspace-settings/${workspaceUuid}/assessment-types/`,
    assessment,
  );
  return AssessmentTypes.mapToAssessmentType(data);
};

export const updateAssessment = async (
  workspaceUuid: string,
  assessment: AssessmentType,
): Promise<AssessmentType> => {
  const { data } = await axios.put(
    `/workspace-settings/${workspaceUuid}/assessment-types/${assessment?.id}/`,
    AssessmentTypes.mapToBackendAssessmentType(assessment),
  );
  return AssessmentTypes.mapToAssessmentType(data);
};

export const deleteAssessment = async (
  workspaceUuid: string,
  assessmentId: number,
): Promise<void> => {
  await axios.delete(
    `/workspace-settings/${workspaceUuid}/assessment-types/${assessmentId}/`,
  );
};

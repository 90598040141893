import { Card, Message } from './styled';

type SingleLineProps = {
  message: string;
  messages?: never;
};

type MultipleLinesProps = { message?: never; messages: string[] };

type Props = SingleLineProps | MultipleLinesProps;

// ErrorMessage
export const ErrorMessage = ({ message, messages }: Props): JSX.Element => {
  if (messages) {
    return (
      <Card>
        {messages.map((line) => (
          <Message>{line}</Message>
        ))}
      </Card>
    );
  }
  return (
    <Card>
      <Message>{message}</Message>
    </Card>
  );
};

import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes } = theme;

export const Wrapper = styled.div`
  border: 1px solid ${colors.grey};
  .rdw-editor-wrapper {
    min-height: 250px;
  }
  .rdw-editor-toolbar {
    border-radius: 0px;
    margin-bottom: 0px;
    border-bottom: 1px solid ${colors.grey};
    font-size: ${fontSizes.s};
    font-family: ${fonts.default};
    height: 40px;
    padding: 5px;
  }
  .rdw-option-wrapper,
  .rdw-dropdown-wrapper {
    border: 0px;
    min-width: 0px;
    margin: 0 1px;
  }
  .public-DraftEditor-content {
    padding: 15px;
    .normal-text {
      font-size: ${fontSizes.default};
    }
  }
  .richText-textAlignment-block > div {
    display: inline;
  }
  .richText-left-block {
    text-align: left;
  }
  .richText-center-block {
    text-align: center;
  }
  .richText-right-block {
    text-align: right;
  }
  .richText-justify-block {
    text-align: justify;
  }
  .public-DraftStyleDefault-block {
    margin: 0;
    caret-color: ${colors.orange};
  }
  .rdw-editor-main {
    height: 220px;
  }
  .rdw-block-dropdown {
    min-width: 70px;
  }
`;

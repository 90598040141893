import { useEffect, useRef } from 'react';

export const useIsMounted = () => {
  const isAlive = useRef(false);

  useEffect(() => {
    isAlive.current = true;

    return () => {
      isAlive.current = false;
    };
  }, [isAlive]);

  return isAlive;
};

export default useIsMounted;

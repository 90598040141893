import { useSafetyPrediction, useText } from 'hooks';
import styled from 'styled-components';
import theme from 'theme';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { Tabs } from 'components/ui';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import { useEffect } from 'react';
import { fetchActiveJob, resetActiveJob } from 'store/actions/jobs';
import TimelineContent from '../Timeline/TimelineContent';
import { setActiveJobTab } from 'store/actions/versions';
import DrawerJobHeader from './DrawerJobHeader';
import { selectJobsView } from 'store/selectors';

const { colors, mixins } = theme;

const TimelineContainer = styled.div`
  background-color: ${colors.offWhite};
  border-left: 2px solid ${colors.black};
  ${mixins.flexColumn};
  height: 100%;
`;

const TimelineV2 = (): JSX.Element => {
  const navigate = useNavigate();
  const getText = useText();
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const { isFeedbackNeeded } = useSafetyPrediction();
  const { activeJob, isFetchingActiveJob } = useSelector(
    (state: RootState) => state.jobs,
  );
  const view = useSelector(selectJobsView);

  useEffect(() => {
    if (
      jobId &&
      (!activeJob || activeJob?.id?.toString() !== jobId) &&
      !isFetchingActiveJob
    ) {
      dispatch(fetchActiveJob(jobId, { updateAllJobs: false }));
    }
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isFetchingActiveJob &&
      activeJob &&
      isFeedbackNeeded(
        activeJob.user_review_psi_score_id,
        activeJob.safety_prediction_score,
      )
    ) {
      navigate(`/jobs/${activeJob.id}/safety-review`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeJob?.id]);

  const onClose = () => {
    Analytics.trackEvent(constants.EVENT_JOB_DETAILS_CLOSE, {
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    });
    dispatch(resetActiveJob());
    navigate('/jobs');
    dispatch(setActiveJobTab(null));
  };

  return (
    <TimelineContainer>
      <DrawerJobHeader title={activeJob?.job_number} onClose={onClose} />
      <Tabs.Desktop
        left={{
          text: getText('tabs_timeline'),
          to: `/jobs/${jobId}/timeline`,
          isActive: true,
          value: constants.TIMELINE,
          event: constants.EVENT_JOB_DETAILS_TIMELINE_TAB_CLICKED,
        }}
        right={{
          text: getText('tabs_job_overview'),
          to: `/jobs/${jobId}`,
          value: constants.JOB_OVERVIEW,
          event: constants.EVENT_JOB_DETAILS_OVERVIEW_TAB_CLICKED,
        }}
      />
      <TimelineContent />
    </TimelineContainer>
  );
};

export default TimelineV2;

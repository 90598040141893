import axios, { AxiosResponse } from 'axios';
import { ForgotPasswordResponse } from 'model/Session';

export const triggerPasswordResetFlow = async (email: string) => {
  try {
    const { data }: AxiosResponse = await axios.post(`/reset-password/`, {
      email,
    });

    const { status } = data as ForgotPasswordResponse;
    return status;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err?.message);
    }
  }
};

import {
  ADD_JOB_SUBTYPE_ERROR,
  ADD_JOB_SUBTYPE_SUCCESS,
  EDIT_JOB_SUBTYPE_ERROR,
  EDIT_JOB_SUBTYPE_SUCCESS,
  FETCH_WORKSPACE_JOB_SUBTYPES,
  FETCH_WORKSPACE_JOB_SUBTYPES_ERROR,
  FETCH_WORKSPACE_JOB_SUBTYPES_SUCCESS,
  REMOVE_JOB_SUBTYPE_ERROR,
  REMOVE_JOB_SUBTYPE_SUCCESS,
  RESET_JOB_SUBTYPE_ERRORS,
  SAVE_JOB_SUBTYPE_CHANGES,
  SAVE_JOB_SUBTYPE_CHANGES_ERROR,
  SAVE_JOB_SUBTYPE_CHANGES_SUCCESS,
} from 'store/actions/actionTypes';

const defaultState = {
  jobSubtypes: {},
  isFetchingJobSubtypes: false,
  isLoadingJobSubtypeChanges: false,
  jobSubtypeErrors: [],
};

const workspaceJobSubtype = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_WORKSPACE_JOB_SUBTYPES:
      return {
        ...state,
        isFetchingJobSubtypes: true,
      };
    case FETCH_WORKSPACE_JOB_SUBTYPES_SUCCESS:
      return {
        ...state,
        jobSubtypes: {
          ...state.jobSubtypes,
          [action.workspaceUuid]: [...action.data],
        },
        isFetchingJobSubtypes: false,
      };
    case FETCH_WORKSPACE_JOB_SUBTYPES_ERROR:
      return {
        ...state,
        isFetchingJobSubtypes: false,
      };
    case SAVE_JOB_SUBTYPE_CHANGES:
      return {
        ...state,
        isLoadingJobSubtypeChanges: true,
      };
    case SAVE_JOB_SUBTYPE_CHANGES_SUCCESS:
    case SAVE_JOB_SUBTYPE_CHANGES_ERROR:
      return {
        ...state,
        isLoadingJobSubtypeChanges: false,
      };
    case ADD_JOB_SUBTYPE_SUCCESS: {
      const workspaceJobSubtypes =
        state.jobSubtypes?.[action.workspaceUuid] || [];
      return {
        ...state,
        jobSubtypes: {
          ...state.jobSubtypes,
          [action.workspaceUuid]: [...workspaceJobSubtypes, action.data],
        },
      };
    }
    case EDIT_JOB_SUBTYPE_SUCCESS: {
      const workspaceJobSubtypes =
        state.jobSubtypes?.[action.workspaceUuid] || [];
      const updatedJobSubtypes = workspaceJobSubtypes.map((type) =>
        type?.id === action.data?.id ? action.data : type,
      );
      return {
        ...state,
        jobSubtypes: {
          ...state.jobSubtypes,
          [action.workspaceUuid]: [...updatedJobSubtypes],
        },
      };
    }
    case REMOVE_JOB_SUBTYPE_SUCCESS: {
      const workspaceJobSubtypes =
        state.jobSubtypes?.[action.workspaceUuid] || [];
      const updatedJobSubtypes = workspaceJobSubtypes.filter(
        (type) => type?.id !== action?.id,
      );
      return {
        ...state,
        jobSubtypes: {
          ...state.jobSubtypes,
          [action.workspaceUuid]: [...updatedJobSubtypes],
        },
      };
    }
    case ADD_JOB_SUBTYPE_ERROR:
    case EDIT_JOB_SUBTYPE_ERROR:
    case REMOVE_JOB_SUBTYPE_ERROR:
      return {
        ...state,
        jobSubtypeErrors: [...state.jobSubtypeErrors, action.data],
      };
    case RESET_JOB_SUBTYPE_ERRORS:
      return {
        ...state,
        jobSubtypeErrors: [],
      };
    default:
      return state;
  }
};

export default workspaceJobSubtype;

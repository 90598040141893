import { SelectablePhotoImage } from './styled';

type Props = {
  url: string;
  id: number;
  onPhotoClick?: (id: number) => void;
  selected?: boolean;
};

export const SelectablePhoto = ({
  url,
  onPhotoClick = () => {},
  id,
  selected,
}: Props): JSX.Element => (
  <SelectablePhotoImage
    $selected={!!selected}
    src={url}
    onClick={() => onPhotoClick(id)}
  />
);

import { eventChannel, END } from 'redux-saga';
import {
  take,
  call,
  put,
  fork,
  race,
  takeLatest,
  select,
} from 'redux-saga/effects';
import ReconnectingWebSocket from 'reconnecting-websocket';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import {
  websocketOpened,
  websocketClosed,
  websocketMessageReceived,
  websocketErrorThrown,
  websocketRestart,
  websocketHandleError,
} from '../actions/websockets';
import {
  WEBSOCKET_CLOSE,
  WEBSOCKET_INIT,
  WEBSOCKET_ERROR_THROWN,
  WEBSOCKET_HANDLE_ERROR,
} from '../actions/actionTypes';
import { selectJobsView } from 'store/selectors';

function* websocketHandleErrorWatcher() {
  yield put(websocketHandleError());
}

const createWebsocketChannel = (websocket) =>
  eventChannel((emitter) => {
    websocket.addEventListener('message', (message) => {
      emitter(websocketMessageReceived(message.data));
    });

    websocket.addEventListener('open', () => {
      emitter(websocketOpened());
    });

    websocket.addEventListener('error', () => {
      emitter(websocketErrorThrown());
      emitter(END);
    });

    websocket.addEventListener('close', () => {
      emitter(websocketClosed());
    });

    return () => websocket.close();
  });

function* websocketMessageSender(websocket) {
  while (true) {
    const wsAction = yield take((a) => a.websocketMessage);
    const { jobId, websocketMessage, uuid } = wsAction;
    websocket.send(
      JSON.stringify({
        action: constants.CHAT_NEW_MESSAGE,
        payload: {
          job_id: jobId,
          message: { uuid, value: websocketMessage },
        },
      }),
    );
    const view = yield select(selectJobsView);
    Analytics.trackEvent(constants.EVENT_MESSAGE_SENT, {
      jobId,
      messageUuid: uuid,
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    });
  }
}

function* websocketManager({ token }) {
  if (navigator.onLine) {
    const websocket = new ReconnectingWebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}?access_token=${token}`,
    );
    websocket.maxReconnectAttempts = 1;
    const websocketChannel = yield call(createWebsocketChannel, websocket);
    yield fork(websocketMessageSender, websocket);
    while (true) {
      const { errorAction, closeAction, socketAction } = yield race({
        errorAction: take(WEBSOCKET_HANDLE_ERROR),
        closeAction: take(WEBSOCKET_CLOSE),
        socketAction: take(websocketChannel),
      });

      if (closeAction) {
        websocket.close();
      }
      if (errorAction) {
        yield put(websocketRestart());
      }
      if (socketAction) {
        yield put(socketAction);
      }
    }
  }
}

export default function* websocketWatcher() {
  yield takeLatest(WEBSOCKET_INIT, websocketManager);
  yield takeLatest(WEBSOCKET_ERROR_THROWN, websocketHandleErrorWatcher);
}

import { Modal } from 'components/ui';
import FRAList from 'components/views/Protected/Jobs/FatigueManager/components/FRAList';
import { Dates } from 'helpers';
import { useText } from 'hooks';
import styled from 'styled-components';
import theme from 'theme';
import { convertFraTypeToNumber } from './fra-type-to-number';
import InterventionList from './InterventionList';
import SectionField from 'model/FatigueManager/SectionField';
import FraFormSection from 'model/FatigueManager/FraFormSection';
import { FatigueRiskAssessment } from 'model/FatigueManager/FatigueRiskAssessment';
import { FRAListV2 } from './FraListV2';
import { parseForm } from 'api/Fatigue/helpers';
import { Label } from './FraListV2/styled';
import { FatigueUserBadge } from './NewFatigueRiskAssessmentModal/components/FatigueUserBadge';

const { fontSizes, fonts } = theme;

const Container = styled.div`
  height: 80vh;
  display: flex;
`;

const Information = styled.div`
  overflow: scroll;
  flex: 1;
  flex-direction: column;
  display: flex;
  background-color: ${theme.colors.white};
`;

const Title = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
`;

const TitleContainer = styled.div`
  display: block;
  padding: 50px 100px 0px;
`;

const Section = styled.div`
  padding: 38px 100px;
  border-bottom: 10px solid ${theme.colors.lightGrey};
`;

type Props = {
  displayModal: boolean;
  setDisplayModal: (value: boolean) => void;
  fraData?: FatigueRiskAssessment;
};

// FraReviewModal
const FraReviewModal = ({
  displayModal,
  setDisplayModal,
  fraData,
}: Props): JSX.Element => {
  const getText = useText();
  const onCancel = () => {
    setDisplayModal(false);
  };

  const fraFirstTitle = fraData?.fraType
    ? getText('fatigue_manager_table_hour_fatigue_risk_assessment', {
        hour: convertFraTypeToNumber(fraData.fraType),
      })
    : '';

  const { interventions, submittedBy, form, sentAt, user } = fraData || {};

  const isSubmittedByManager =
    !!submittedBy && !!user && submittedBy.id !== user.id;

  return (
    <Modal.Base
      isOpen={displayModal}
      title={getText('fatigue_manager_risk_assessment_review_title')}
      onClose={onCancel}
      width='60vw'
    >
      <Container>
        {fraData && (
          <Information>
            <TitleContainer>
              <Title>{fraFirstTitle}</Title>
              {Dates.formatDateTime(
                sentAt,
                getText('date_today'),
                getText('date_yesterday'),
              )}
            </TitleContainer>
            <Section>
              {isSubmittedByManager && (
                <Label>
                  {getText('fatigue_manager_risk_assessment_completed_for')}
                </Label>
              )}
              <FatigueUserBadge user={user} />
            </Section>
            {isSubmittedByManager && (
              <Section>
                <>
                  <Label>
                    {getText('fatigue_manager_risk_assessment_completed_by')}
                  </Label>
                  <FatigueUserBadge user={submittedBy} />
                </>
              </Section>
            )}
            {form && (
              <Section>
                {form.version === 1 ? (
                  <FRAList
                    items={form.sections.reduce(
                      (acc: SectionField[], val: FraFormSection) =>
                        acc.concat(val.fields),
                      [],
                    )}
                  />
                ) : (
                  <FRAListV2 form={parseForm(form)} />
                )}
              </Section>
            )}
            {interventions && <InterventionList items={interventions} />}
          </Information>
        )}
      </Container>
    </Modal.Base>
  );
};

export default FraReviewModal;

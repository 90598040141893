import { Dates } from 'helpers';
import { Assessment as AssessmentIcon } from 'assets/icons';
import * as Analytics from 'utils/analytics';
import { useText } from 'hooks';
import { User } from 'model/User';
import {
  CardContainer,
  CreationDate,
  Creator,
  EyeIcon,
  FlexCenterContainer,
  Icon,
  BoldSubtype,
} from './styled';

type Props = {
  subtype: string;
  creator: User;
  date: string;
  to: string;
  isLocked?: boolean;
};

// Card.Assessment
export const Assessment = Analytics.withTrackEvent(
  ({ subtype, creator, date, to, isLocked, ...props }: Props): JSX.Element => {
    const getText = useText();

    return (
      <CardContainer to={to} {...props}>
        <FlexCenterContainer>
          <Icon component={<AssessmentIcon />} $marginRight={20} />
          <BoldSubtype>{subtype}</BoldSubtype>
        </FlexCenterContainer>
        <FlexCenterContainer>
          <Creator>{creator.fullName}</Creator>

          <CreationDate $marginLeft={50} $marginRight={25}>
            {Dates.formatDateTime(
              date,
              getText('date_today'),
              getText('date_yesterday'),
            )}
          </CreationDate>

          {!isLocked && <EyeIcon />}
        </FlexCenterContainer>
      </CardContainer>
    );
  },
);

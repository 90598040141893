import { useText } from 'hooks';
import { Button } from 'components/ui';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectJobsView } from 'store/selectors';
import { LEGACY_VIEW } from 'utils/constants';
import { RamsStages } from 'model/Assessment/RAMS';
import {
  SignOnLinkButton,
  SubTitle,
  SubTitleContainer,
  SuccessContainer,
  SuccessMessage,
  TickCircleIcon,
  TickContainer,
  Title,
} from './styled';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import { EVENT_ADD_TEAM_MEMBERS_FROM_CONFIRMATION_CLICKED } from '../constants';
import {
  selectActiveRamsAssessment,
  setAssessmentStage,
} from 'store/slices/ramsAssessments';

type Props = {
  hideContent: () => void;
};

// SuccessfulSave
export const SuccessfulSave = ({ hideContent }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const getText = useText();
  const dispatch = useDispatch();
  const view = useSelector(selectJobsView);
  const assessment = useSelector(selectActiveRamsAssessment);

  const backToTimeLine = () => {
    navigate(`/jobs/${jobId}${view === LEGACY_VIEW ? '' : '/timeline'}`);
  };

  const clickSignOnLink = () => {
    dispatch(setAssessmentStage(RamsStages.signOn));
    hideContent();
    Analytics.trackEvent(EVENT_ADD_TEAM_MEMBERS_FROM_CONFIRMATION_CLICKED, {
      assessmentId: assessment?.id,
      assessmentType: assessment?.type,
    });
  };

  return (
    <>
      <SuccessContainer>
        <SuccessMessage>
          <TickContainer>
            <TickCircleIcon />
          </TickContainer>
          <Title>{getText('rams_section_method_summary_complete')}</Title>
          <SubTitleContainer>
            <SubTitle>
              {getText('rams_section_method_summary_success_subtitle')}
            </SubTitle>
            <SignOnLinkButton
              variant='text'
              disableRipple
              onClick={clickSignOnLink}
            >
              {getText('rams_section_method_summary_success_sign_on_link')}
            </SignOnLinkButton>
          </SubTitleContainer>
        </SuccessMessage>
      </SuccessContainer>
      <Button.Primary
        text={getText('rams_section_return_to_timeline')}
        onClick={backToTimeLine}
        extraStyles={{
          position: 'sticky',
          bottom: '0',
          marginTop: 'auto',
          flex: '0 0 55px',
          fontSize: theme.fontSizes.l,
        }}
      />
    </>
  );
};

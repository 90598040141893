import { Dialog as MuiDialog } from '@mui/material';
import styled from 'styled-components';
import ModalTitle from './ModalTitle';
import theme from 'theme';

const { breakpoints } = theme;

const Dialog = styled(
  ({ width, largeScreenWidth, useMaxHeight, isCentered, open, ...rest }) => (
    <MuiDialog open={open} {...rest} />
  ),
)`
  .MuiDialog-paper {
    border-radius: 0;
    margin-top: 8vh;
    align-self: flex-start;
    overflow: hidden;
    max-width: 95vw;
    ${({ useMaxHeight }) =>
      useMaxHeight &&
      `height: min(calc(540px + 65px), calc(100% - 65px));
      `}
    ${({ width }) =>
      width &&
      `width: ${width}; 
      `}
    ${({ isCentered }) =>
      isCentered &&
      `margin: auto;
      `}
    ${({ largeScreenWidth }) =>
      largeScreenWidth &&
      `    
        @media (min-width: ${breakpoints.xl}px) {
          width: ${largeScreenWidth}; 
        }
      `}
  }
`;

type CommonProps = {
  children: React.ReactNode;
  title?: string | JSX.Element;
  onBack?: (() => void) | null;
  width?: string;
  largeScreenWidth?: string;
  useMaxHeight?: boolean;
  hasCancelButton?: boolean;
  showTitleBar?: boolean;
  isCentered?: boolean;
};

type OpenAndCloseProps = {
  alwaysOpen?: never;
  isOpen: boolean;
  onClose: () => void;
};

type AlwaysOpenProps = { alwaysOpen: true; isOpen?: never; onClose?: never };

type ConditionalProps = OpenAndCloseProps | AlwaysOpenProps;

type Props = CommonProps & ConditionalProps;

const Base = ({
  alwaysOpen,
  isOpen,
  onClose,
  title,
  children,
  onBack,
  width = '850px',
  largeScreenWidth,
  useMaxHeight = false,
  hasCancelButton = true,
  showTitleBar = false,
  isCentered = false,
}: Props) => (
  <Dialog
    open={alwaysOpen || isOpen}
    onClose={!alwaysOpen ? onClose : null}
    width={width}
    largeScreenWidth={largeScreenWidth}
    isCentered={isCentered}
    useMaxHeight={useMaxHeight}
  >
    {(showTitleBar || title) && (
      <ModalTitle
        title={title}
        onBack={onBack}
        onClose={onClose}
        hasCancelButton={hasCancelButton}
      />
    )}
    {children}
  </Dialog>
);

export default Base;

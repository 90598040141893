import styled from 'styled-components';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useText } from 'hooks';
import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { User } from 'model/User';
import { LogDetail } from 'model/Insight/InsightJobBlockerStat';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

const { colors, fonts, fontSizes, mixins } = theme;

const DataRow = styled.div`
  height: 350px;
  display: flex;
  background: ${colors.white};
  padding: 35px 70px;
  margin-top: 30px;
`;

const StyledDataGrid = styled(({ width, columns, ...rest }) => (
  <DataGrid columns={columns} {...rest} />
))`
  &.MuiDataGrid-root {
    border: 0;
    .MuiDataGrid-columnHeaders {
      border-bottom: 2px solid ${colors.darkGrey};
      color: ${colors.darkGrey};
      font-family: ${fonts.bold};
      width: 100%;
      > div {
        width: 100%;
      }
    }
    .MuiDataGrid-columnHeader {
      min-width: ${({ width }) => width}% !important;
      max-width: ${({ width }) => width}% !important;
      &:focus {
        outline: 0;
      }
    }
    .MuiDataGrid-row {
      border-bottom: 1px solid ${colors.grey};
      width: 100%;
      > .MuiDataGrid-cell {
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .MuiDataGrid-cell {
      min-width: ${({ width }) => width}% !important;
      max-width: ${({ width }) => width}% !important;
      color: ${colors.black};
      border: 0;
      &:focus,
      &:focus-within,
      &:focus-visible {
        outline: 0;
      }
    }
    .MuiDataGrid-cellContent {
      white-space: unset;
      word-break: break-word;
      line-height: normal;
    }
    .MuiDataGrid-iconSeparator {
      display: none;
    }
    .MuiDataGrid-withBorder {
      border: 0;
    }
    .MuiDataGrid-footerContainer {
      display: none;
    }
    .MuiDataGrid-virtualScrollerRenderZone {
      width: 100%;
    }
    .MuiDataGrid-filler {
      display: none;
    }
  }
`;

const JobNumber = styled.a`
  text-decoration: underline;
`;

const EmptyTableText = styled.div`
  background-color: ${colors.white};
  color: ${colors.grey};
  font-size: ${fontSizes.m};
  font-family: ${fonts.default};
  padding: 25px;
  ${mixins.flexFullCenter};
`;

export const InsightJobBlockerSummaryTable = ({
  data,
}: {
  data?: LogDetail[] | null;
}): JSX.Element => {
  const getText = useText();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  const columns: GridColDef[] = [
    {
      field: 'jobNumber',
      headerName: getText(
        'insights_job_blocker_table_header_work_order_number',
      ),
      sortable: false,
      renderCell: (props: GridRenderCellParams) => (
        <JobNumber
          target='_blank'
          href={`/jobs/${props.row?.jobId}`}
          onClick={() =>
            Analytics.trackEvent(constants.INSIGHTS_JOB_BLOCKERS_JOB_CLICKED, {
              jobId: props.row?.jobId,
            })
          }
        >
          {props.row?.jobNumber}
        </JobNumber>
      ),
    },
    {
      field: 'timeBlocked',
      headerName: getText('insights_job_blocker_table_header_blocked_length'),
      sortable: false,
      renderCell: (props: GridRenderCellParams) =>
        convertMinutesToHours(props.row?.timeBlocked),
    },
    {
      field: 'location',
      headerName: getText('insights_job_blocker_table_header_location'),
      sortable: false,
    },
    {
      field: 'jobType',
      headerName: getText('insights_job_blocker_table_header_job_type'),
      sortable: false,
      renderCell: (props: GridRenderCellParams) => (
        <span>
          {getLocalizedCamelCaseValue(props.row, 'type', globalAppLanguage)}
        </span>
      ),
    },
    {
      field: 'manager',
      headerName: getText('insights_job_blocker_table_header_manager'),
      sortable: false,
      renderCell: (props: GridRenderCellParams) =>
        props.row?.managers?.map((manager: User, index: number) => (
          <span
            key={`manager-${manager?.id}`}
          >{`${manager.fullName}${index < props.row?.managers.length - 1 ? ', ' : ''}`}</span>
        )),
    },
  ];

  const convertMinutesToHours = (minutes) => {
    const min = minutes % 60;
    return `${Math.floor(minutes / 60)}:${min > 9 ? '' : '0'}${min} ${getText(
      'insights_hours',
    )}`;
  };

  return (
    <DataRow>
      <StyledDataGrid
        rows={data?.map((row) => ({
          ...row,
          id: row.jobId,
          key: row.jobId,
        }))}
        columns={columns}
        width={100 / columns?.length}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableExtendRowFullWidth
        disableRowSelectionOnClick
        rowsPerPageOptions={[]}
        sortModel={[]}
        slots={{
          noRowsOverlay: () => (
            <EmptyTableText>
              {getText('insights_job_blocker_table_empty')}
            </EmptyTableText>
          ),
        }}
      />
    </DataRow>
  );
};

import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  FETCH_COMPLIANCE_DATA,
  FETCH_ENGAGEMENT_DATA,
  FETCH_HAVS_COMPLIANCE,
  FETCH_JOBS_PER_DAY_GRAPHS,
  FETCH_JOBS_PER_DAY_STATS,
  FETCH_INSIGHTS_JOB_BLOCKERS,
  FETCH_OUTPUT_PER_DAY_GRAPHS,
  FETCH_OUTPUT_PER_DAY_STATS,
  FETCH_VEHICLE_CHECK_COMPLIANCE,
  FETCH_VEHICLE_CHECK_USER_COMPLIANCE,
  SIGN_OFF_HAVS_COMPLIANCE,
  FETCH_USER_FORM,
  FETCH_JOB_FORM,
  FETCH_PERMIT_FORM,
} from 'store/actions/actionTypes';
import {
  fetchComplianceDataError,
  fetchComplianceDataSuccess,
  fetchEngagementDataError,
  fetchEngagementDataSuccess,
  fetchHavsComplianceError,
  fetchHavsComplianceSuccess,
  fetchJobBlockersError,
  fetchJobBlockersSuccess,
  fetchJobFormError,
  fetchJobFormSuccess,
  fetchJobsPerDayGraphsError,
  fetchJobsPerDayGraphsSuccess,
  fetchJobsPerDayStatsError,
  fetchJobsPerDayStatsSuccess,
  fetchOutputPerDayGraphsError,
  fetchOutputPerDayGraphsSuccess,
  fetchOutputPerDayStatsError,
  fetchOutputPerDayStatsSuccess,
  fetchPermitFormError,
  fetchPermitFormSuccess,
  fetchUserFormError,
  fetchUserFormSuccess,
  fetchVehicleCheckComplianceError,
  fetchVehicleCheckComplianceSuccess,
  fetchVehicleCheckUserComplianceError,
  fetchVehicleCheckUserComplianceSuccess,
  signOffHavsComplianceError,
  signOffHavsComplianceSuccess,
} from 'store/actions/insights';
import { UserForms, Insights } from 'api';
import { setGlobalError } from 'store/slices/notifications';
import * as Logger from 'utils/logger';
import { capitalizeFullName } from 'helpers/names';
import { CustomFormClass } from 'model/Form';
import { selectUserHasPermission } from 'store/selectors';

function* fetchEngagementData({
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  workspaceUuid,
}) {
  try {
    const canSwitchDepot = yield select(
      selectUserHasPermission('switch_depot_v1'),
    );

    let depot = depotId;
    if (depotId === -1 && canSwitchDepot) {
      depot = null;
    }

    const data = yield Insights.getEngagement(
      depot,
      startDate,
      endDate,
      excludeWeekends,
      workspaceUuid,
    );

    yield put(fetchEngagementDataSuccess(data?.results));
  } catch (err) {
    Logger.error(err);
    yield put(fetchEngagementDataError());
    yield put(setGlobalError(err));
  }
}

function* fetchComplianceData({
  depotId,
  startDate,
  endDate,
  isWeekendIncluded,
  sort,
  userIds,
  search,
  workspaceUuid,
  currentPage,
}) {
  try {
    const canSwitchDepot = yield select(
      selectUserHasPermission('switch_depot_v1'),
    );

    let depot = depotId;
    if (depotId === -1 && canSwitchDepot) {
      depot = null;
    }

    const data = yield Insights.getComplianceData(
      depot,
      startDate,
      endDate,
      isWeekendIncluded,
      sort,
      userIds,
      search,
      workspaceUuid,
      currentPage,
    );

    yield put(fetchComplianceDataSuccess(data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchComplianceDataError());
    yield put(setGlobalError(err));
  }
}

function* fetchJobsPerDayGraphs({
  depotId,
  typeId,
  startDate,
  endDate,
  excludeWeekends,
  selectedWorkspaceUuid,
}) {
  try {
    const data = yield Insights.getJobsPerDay(
      depotId,
      typeId,
      startDate,
      endDate,
      excludeWeekends,
      selectedWorkspaceUuid,
    );

    yield put(fetchJobsPerDayGraphsSuccess(data, typeId));
  } catch (err) {
    Logger.error(err);
    yield put(fetchJobsPerDayGraphsError());
    yield put(setGlobalError(err));
  }
}

function* fetchJobsPerDayStats({
  depotId,
  typeId,
  startDate,
  endDate,
  excludeWeekends,
  selectedWorkspaceUuid,
}) {
  try {
    const data = yield Insights.getJobsPerDayStats(
      depotId,
      typeId,
      startDate,
      endDate,
      excludeWeekends,
      selectedWorkspaceUuid,
    );

    yield put(fetchJobsPerDayStatsSuccess(data, typeId));
  } catch (err) {
    Logger.error(err);
    yield put(fetchJobsPerDayStatsError());
    yield put(setGlobalError(err));
  }
}

function* fetchOutputPerDayGraphs({
  depotId,
  typeId,
  startDate,
  endDate,
  excludeWeekends,
  selectedWorkspaceUuid,
}) {
  try {
    const data = yield Insights.getOutputPerDay(
      depotId,
      typeId,
      startDate,
      endDate,
      excludeWeekends,
      selectedWorkspaceUuid,
    );

    yield put(fetchOutputPerDayGraphsSuccess(data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchOutputPerDayGraphsError());
    yield put(setGlobalError(err));
  }
}

function* fetchOutputPerDayStats({
  depotId,
  typeId,
  startDate,
  endDate,
  excludeWeekends,
  selectedWorkspaceUuid,
}) {
  try {
    const data = yield Insights.getOutputPerDayStats(
      depotId,
      typeId,
      startDate,
      endDate,
      excludeWeekends,
      selectedWorkspaceUuid,
    );

    yield put(fetchOutputPerDayStatsSuccess(data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchOutputPerDayStatsError());
    yield put(setGlobalError(err));
  }
}

function* fetchJobBlockers({
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  workspaceUuid,
}) {
  try {
    const canSwitchDepot = yield select(
      selectUserHasPermission('switch_depot_v1'),
    );

    let depot = depotId;
    if (depotId === -1 && canSwitchDepot) {
      depot = null;
    }

    const data = yield Insights.getJobBlockerTimeLost(
      depot,
      startDate,
      endDate,
      excludeWeekends,
      workspaceUuid,
    );

    yield put(fetchJobBlockersSuccess(data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchJobBlockersError());
    yield put(setGlobalError(err));
  }
}

function* fetchHavsCompliance({
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  sort,
  userIds,
  search,
  workspaceUuid,
  currentPage,
}) {
  try {
    const canSwitchDepot = yield select(
      selectUserHasPermission('switch_depot_v1'),
    );

    let depot = depotId;
    if (depotId === -1 && canSwitchDepot) {
      depot = null;
    }

    const data = yield Insights.getHavsComplianceData(
      depot,
      startDate,
      endDate,
      excludeWeekends,
      sort,
      userIds,
      search,
      workspaceUuid,
      currentPage,
    );

    yield put(fetchHavsComplianceSuccess(data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchHavsComplianceError());
    yield put(setGlobalError(err));
  }
}

function* signOffHavsCompliance({ startDate, endDate, userId, currentUserId }) {
  try {
    const data = yield UserForms.signOffHavsCompliance(
      startDate,
      endDate,
      userId,
      currentUserId,
    );

    const newData = {
      user_id: data?.user,
      created_by: data?.created_by,
      created_at: data?.created_at,
      full_name: capitalizeFullName(
        data?.created_by?.first_name,
        data?.created_by?.last_name,
      ),
      start_date: data?.start_date,
      end_date: data?.end_date,
    };
    yield put(signOffHavsComplianceSuccess(userId, newData));
  } catch (err) {
    Logger.error(err);
    yield put(signOffHavsComplianceError());
    yield put(setGlobalError(err));
  }
}

function* fetchVehicleCheckCompliance({
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  sort,
  userIds,
  search,
  workspaceUuid,
  currentPage,
}) {
  try {
    const canSwitchDepot = yield select(
      selectUserHasPermission('switch_depot_v1'),
    );

    let depot = depotId;
    if (depotId === -1 && canSwitchDepot) {
      depot = null;
    }

    const data = yield Insights.getVehicleCheckComplianceData(
      depot,
      startDate,
      endDate,
      excludeWeekends,
      sort,
      userIds,
      search,
      workspaceUuid,
      currentPage,
    );

    yield put(fetchVehicleCheckComplianceSuccess(data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchVehicleCheckComplianceError());
    yield put(setGlobalError(err));
  }
}

function* fetchVehicleCheckUserCompliance({
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  sort,
  userIds,
  search,
  workspaceUuid,
  currentPage,
}) {
  try {
    const canSwitchDepot = yield select(
      selectUserHasPermission('switch_depot_v1'),
    );

    let depot = depotId;
    if (depotId === -1 && canSwitchDepot) {
      depot = null;
    }

    const data = yield Insights.getVehicleCheckUserComplianceData(
      depot,
      startDate,
      endDate,
      excludeWeekends,
      sort,
      userIds,
      search,
      workspaceUuid,
      currentPage,
    );

    yield put(fetchVehicleCheckUserComplianceSuccess(data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchVehicleCheckUserComplianceError());
    yield put(setGlobalError(err));
  }
}

function* fetchUserForm({
  formId,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  memberIds,
  currentPage,
  workspaceUuid,
  search,
  isHavsForm,
}) {
  try {
    const canSwitchDepot = yield select(
      selectUserHasPermission('switch_depot_v1'),
    );

    let depot = depotId;
    if (depotId === -1 && canSwitchDepot) {
      depot = null;
    }

    const data = isHavsForm
      ? yield Insights.getCustomHavsFormInsights(
          CustomFormClass.USER,
          formId,
          depot,
          startDate,
          endDate,
          excludeWeekends,
          memberIds,
          currentPage,
          workspaceUuid,
          search,
        )
      : yield Insights.getAll(
          CustomFormClass.USER,
          formId,
          depot,
          startDate,
          endDate,
          excludeWeekends,
          memberIds,
          currentPage,
          workspaceUuid,
          search,
        );

    yield put(fetchUserFormSuccess(data, formId));
  } catch (err) {
    Logger.error(err);
    yield put(fetchUserFormError());
    yield put(setGlobalError(err));
  }
}

function* fetchJobForm({
  formId,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  memberIds,
  currentPage,
  workspaceUuid,
  search,
}) {
  try {
    const canSwitchDepot = yield select(
      selectUserHasPermission('switch_depot_v1'),
    );

    let depot = depotId;
    if (depotId === -1 && canSwitchDepot) {
      depot = null;
    }

    const data = yield Insights.getAll(
      CustomFormClass.JOB,
      formId,
      depot,
      startDate,
      endDate,
      excludeWeekends,
      memberIds,
      currentPage,
      workspaceUuid,
      search,
    );

    yield put(fetchJobFormSuccess(data, formId));
  } catch (err) {
    Logger.error(err);
    yield put(fetchJobFormError());
    yield put(setGlobalError(err));
  }
}

function* fetchPermitForm({
  formId,
  depotId,
  startDate,
  endDate,
  excludeWeekends,
  memberIds,
  currentPage,
  workspaceUuid,
  search,
}) {
  try {
    const canSwitchDepot = yield select(
      selectUserHasPermission('switch_depot_v1'),
    );

    let depot = depotId;
    if (depotId === -1 && canSwitchDepot) {
      depot = null;
    }

    const data = yield Insights.getAll(
      CustomFormClass.PERMIT,
      formId,
      depot,
      startDate,
      endDate,
      excludeWeekends,
      memberIds,
      currentPage,
      workspaceUuid,
      search,
    );

    yield put(fetchPermitFormSuccess(data, formId));
  } catch (err) {
    Logger.error(err);
    yield put(fetchPermitFormError());
    yield put(setGlobalError(err));
  }
}

export default function* insightsWatcher() {
  yield takeLatest(FETCH_ENGAGEMENT_DATA, fetchEngagementData);
  yield takeLatest(FETCH_COMPLIANCE_DATA, fetchComplianceData);
  yield takeEvery(FETCH_JOBS_PER_DAY_GRAPHS, fetchJobsPerDayGraphs);
  yield takeEvery(FETCH_JOBS_PER_DAY_STATS, fetchJobsPerDayStats);
  yield takeLatest(FETCH_OUTPUT_PER_DAY_GRAPHS, fetchOutputPerDayGraphs);
  yield takeLatest(FETCH_OUTPUT_PER_DAY_STATS, fetchOutputPerDayStats);
  yield takeLatest(FETCH_INSIGHTS_JOB_BLOCKERS, fetchJobBlockers);
  yield takeLatest(FETCH_HAVS_COMPLIANCE, fetchHavsCompliance);
  yield takeLatest(SIGN_OFF_HAVS_COMPLIANCE, signOffHavsCompliance);
  yield takeLatest(FETCH_VEHICLE_CHECK_COMPLIANCE, fetchVehicleCheckCompliance);
  yield takeLatest(
    FETCH_VEHICLE_CHECK_USER_COMPLIANCE,
    fetchVehicleCheckUserCompliance,
  );
  yield takeLatest(FETCH_USER_FORM, fetchUserForm);
  yield takeLatest(FETCH_JOB_FORM, fetchJobForm);
  yield takeLatest(FETCH_PERMIT_FORM, fetchPermitForm);
}

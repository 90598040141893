import { Brand, BrandWrapper, FyldLogo, Wrapper } from './styled';

export const SplashScreen = () => {
  return (
    <Wrapper>
      <BrandWrapper>
        <Brand to='/'>
          <FyldLogo $bigMargin />
        </Brand>
      </BrandWrapper>
    </Wrapper>
  );
};

import styled from 'styled-components';
import { useText } from 'hooks';
import theme from 'theme';
import { useSelector } from 'react-redux';
import { selectOrganizationId } from 'store/selectors';
import { ChangeEvent } from 'react';
import { Toggle } from 'components/ui/Input';
import {
  EVENT_WORKSPACE_SETTINGS_SIGN_ON_INFO_CLICK,
  EVENT_WORKSPACE_SETTINGS_TOGGLE_DEVICELESS_DIGITAL_SIGNATURE,
  EVENT_WORKSPACE_SETTINGS_TOGGLE_FIELDWORKER_SIGN_ON,
  EVENT_WORKSPACE_SETTINGS_TOGGLE_RISK_ASSESSMENT,
  EVENT_WORKSPACE_SETTINGS_VRA_INFO_CLICK,
  WorkspaceFieldNames,
} from '../constants';
import RootState from 'model/State/RootState';
import * as Analytics from 'utils/analytics';
import { VRASignOffDemo, UserSignOnDemo } from 'assets/images';
import {
  UsersSignOnDescription,
  ManagersSignOffDescription,
} from '../InfoModals';
import { InfoButton } from 'components/ui/Button';

const { colors } = theme;

// Styled Components
const ToggleContainer = styled.div<{ $offWhite?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 55px;
  padding: 0 20px;
  background-color: ${({ $offWhite }) =>
    $offWhite ? colors.$offWhite : colors.white};
`;

const ToggleLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const WorkspaceFields = ({
  workspaceSettings,
  setWorkspaceSettings,
  setIsInfoModalOpen,
  setInfoModal,
}): JSX.Element => {
  const organisationId = useSelector(selectOrganizationId);
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );

  const getText = useText();

  const handleToggleChange = (e?: ChangeEvent<HTMLInputElement>) => {
    const fieldName = e?.target?.id;

    if (fieldName) {
      setWorkspaceSettings((prevState) => ({
        ...prevState,
        [fieldName]: e.target.checked,
      }));

      let toggleEventName;

      switch (fieldName) {
        case WorkspaceFieldNames.VRA_SIGN_OFF:
          toggleEventName = EVENT_WORKSPACE_SETTINGS_TOGGLE_RISK_ASSESSMENT;
          break;
        case WorkspaceFieldNames.FIELDWORKER_SIGN_ON:
          toggleEventName = EVENT_WORKSPACE_SETTINGS_TOGGLE_FIELDWORKER_SIGN_ON;
          break;
        case WorkspaceFieldNames.DEVICELESS_DIGITAL_SIGNATURE:
          toggleEventName =
            EVENT_WORKSPACE_SETTINGS_TOGGLE_DEVICELESS_DIGITAL_SIGNATURE;
          break;
      }

      if (toggleEventName) {
        Analytics.trackEvent(toggleEventName, {
          workspace_id: selectedWorkspace?.uuid,
          organisation_id: organisationId,
          status: e?.target.checked,
        });
      }
    }
  };

  const onInfoClick = (event, field) => {
    event.preventDefault();

    let eventName;
    let content;
    switch (field) {
      case WorkspaceFieldNames.VRA_SIGN_OFF:
        eventName = EVENT_WORKSPACE_SETTINGS_VRA_INFO_CLICK;
        content = {
          field,
          title: getText('workspace_settings_managers_sign_off_info_title'),
          description: <ManagersSignOffDescription />,
          image: VRASignOffDemo,
        };
        break;
      case WorkspaceFieldNames.FIELDWORKER_SIGN_ON:
        eventName = EVENT_WORKSPACE_SETTINGS_SIGN_ON_INFO_CLICK;
        content = {
          field,
          title: getText('workspace_settings_fieldworkers_sign_on_info_title'),
          description: <UsersSignOnDescription />,
          image: UserSignOnDemo,
        };
        break;
      case WorkspaceFieldNames.DEVICELESS_DIGITAL_SIGNATURE:
        eventName = EVENT_WORKSPACE_SETTINGS_SIGN_ON_INFO_CLICK;
        content = {
          field,
        };
        break;
    }

    if (eventName) {
      setInfoModal(content);
      setIsInfoModalOpen(true);
      Analytics.trackEvent(eventName, {
        toggle: eventName,
        workspace_id: selectedWorkspace?.uuid,
        organisation_id: organisationId,
      });
    }
  };

  return (
    <>
      <ToggleContainer $offWhite>
        <Toggle
          id={WorkspaceFieldNames.VRA_SIGN_OFF}
          label={
            <ToggleLabel>
              <div>{getText('workspace_settings_managers_sign_off')} </div>
              <InfoButton
                onClick={(event) =>
                  onInfoClick(event, WorkspaceFieldNames.VRA_SIGN_OFF)
                }
              />
            </ToggleLabel>
          }
          checked={workspaceSettings[WorkspaceFieldNames.VRA_SIGN_OFF] ?? false}
          onChange={handleToggleChange}
        />
      </ToggleContainer>
      <ToggleContainer>
        <Toggle
          id={WorkspaceFieldNames.FIELDWORKER_SIGN_ON}
          label={
            <ToggleLabel>
              <div>{getText('workspace_settings_fieldworkers_sign_on')} </div>
              <InfoButton
                onClick={(event) =>
                  onInfoClick(event, WorkspaceFieldNames.FIELDWORKER_SIGN_ON)
                }
              />
            </ToggleLabel>
          }
          checked={
            workspaceSettings[WorkspaceFieldNames.FIELDWORKER_SIGN_ON] ?? false
          }
          onChange={handleToggleChange}
        />
      </ToggleContainer>
      {workspaceSettings[WorkspaceFieldNames.FIELDWORKER_SIGN_ON] && (
        <ToggleContainer $offWhite>
          <Toggle
            id={WorkspaceFieldNames.DEVICELESS_DIGITAL_SIGNATURE}
            label={
              <ToggleLabel>
                <div>{getText('workspace_settings_digital_signature')} </div>
                <InfoButton
                  onClick={(event) =>
                    onInfoClick(
                      event,
                      WorkspaceFieldNames.DEVICELESS_DIGITAL_SIGNATURE,
                    )
                  }
                />
              </ToggleLabel>
            }
            checked={
              workspaceSettings[
                WorkspaceFieldNames.DEVICELESS_DIGITAL_SIGNATURE
              ] ?? false
            }
            onChange={handleToggleChange}
          />
        </ToggleContainer>
      )}
    </>
  );
};

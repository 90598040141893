import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const Users = styled.div<{ $height?: string; $hasPadding?: boolean }>`
  flex: 1;
  overflow-y: scroll;
  margin-bottom: 15px;
  ${({ $hasPadding }) => $hasPadding && `padding: 0 100px`};
  ${({ $height }) => $height && `height: calc(${$height} - 24px)`};
  &::-webkit-scrollbar {
    display: none;
  }
  ${media.sm`
    padding: 10px 15px 0 15px;
    margin-bottom: 0;
  `}
  @media (max-height: 800px) {
    ${({ $height }) => $height && `margin-top: 20px`};
    ${({ $height }) => $height && `height: calc(${$height} - 44px)`};
  }
`;

export const Initial = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxl};
`;

export const UserRow = styled.div<{ $disabled?: boolean }>`
  ${mixins.flexBetween};
  height: 75px;
  border-top: 2px solid ${colors.lightGrey};
  cursor: ${({ $disabled }) => (!$disabled ? 'pointer' : 'auto')};
`;

export const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  cursor: pointer;
  color: ${colors.darkGrey};
  padding: 8px;
  &:hover {
    color: ${colors.black};
  }
`;

export const SearchContainer = styled.div`
  margin: 25px 100px 0 100px;
  ${media.sm`
    margin: 15px 15px 10px 15px;
  `}
`;

export const NoSelectionText = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 5px 100px;
  overflow-x: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  ${media.sm`
    margin: 0 0 0 10px;
  `}
  color: ${colors.red};
`;

export const Selection = styled.div`
  height: 150px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 5px 100px;
  overflow-x: auto;
  padding-top: 40px;
  ${media.sm`
    padding-top: 20px;
  `}
  ${media.sm`
    margin: 0 0 0 10px;
  `}
`;

export const BadgeContainer = styled.div`
  min-width: 20%;
  max-width: 20%;
  ${media.sm`
    margin: 0 0 0 20px;
  `}
`;

export const Spacer = styled.div`
  height: 100%;
  display: flex;
  flex: 1 0 0;
`;

import theme from 'theme';
import { CheckIcon, TagContainer } from './styled';
import { useText } from 'hooks';

export const CompleteTag = (): JSX.Element => {
  const getText = useText();

  return (
    <TagContainer $backgroundColor={theme.colors.black}>
      <CheckIcon />
      {getText('complete')}
    </TagContainer>
  );
};

import styled from 'styled-components';
import theme from 'theme';
import StyledComponentProps from 'model/StyledComponentProps';

const { colors, fonts, fontSizes, mixins, breakpoints } = theme;

export const Container = styled.div`
  height: 80vh;
  display: flex;
`;

export const RightContainer = styled.div<StyledComponentProps>`
  width: ${({ width }) => width}%;
  background-color: ${colors.white};
  ${mixins.flexFullCenter};
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div`
  height: 70px;
  background-color: ${colors.offWhite};
  border-bottom: 1px solid ${theme.colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
  @media only screen and (max-width: ${theme.breakpoints.sm}px) {
    padding: 0 15px;
  }
`;

export const SectionWrapper = styled.div`
  background-color: ${colors.white};
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Section = styled.div`
  padding-top: 16px;
  border-bottom: 15px solid ${colors.offWhite};
  &:last-of-type {
    border: none;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px 0 25px;
  margin-bottom: 40px;
`;

export const FormTitle = styled.h2`
  color: ${colors.black};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
`;

export const CommentContainer = styled.div`
  padding: 0 25px 20px 25px;
`;

export const InstructionWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  word-break: break-word;
`;

export const InstructionContent = styled.div`
  margin-top: 20px;
  padding: 10px;
  p {
    padding-top: 15px;
  }
  ul {
    padding: revert;
  }
`;

export const SectionTitle = styled.div`
  font-family: ${fonts.bold};
  color: ${colors.black};
  font-size: ${fontSizes.xxl};
  padding: 0 25px 40px 25px;
  @media only screen and (max-width: ${breakpoints.sm}px) {
    margin-bottom: 30px;
    padding: 0 15px 0 15px;
  }
`;

export const FieldContainer = styled.div<{ $isSeparator?: boolean }>`
  padding: ${({ $isSeparator }) =>
    $isSeparator ? '0 0 40px 0' : '0 25px 40px 25px'};
  @media only screen and (max-width: ${breakpoints.sm}px) {
    padding: ${({ $isSeparator }) =>
      $isSeparator ? '0 0 30px 0' : '0 15px 30px 15px'};
  }
`;

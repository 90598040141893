import { useText } from 'hooks';
import {
  AddToJobButton,
  CrossIcon,
  FlexEndContainer,
  IconContainer,
  NotEditableTagContainer,
  WarningIcon,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsEditTagDismissed,
  setEditTagDismissed,
} from 'store/slices/ramsAssessments';
import { addMeToJob } from 'store/actions/jobs';
import { selectIsAddingMeToActiveJob } from 'store/selectors';
import { Loader } from 'components/ui';

export const NotEditableTag = (): JSX.Element | null => {
  const getText = useText();
  const dispatch = useDispatch();
  const isDismissed = useSelector(selectIsEditTagDismissed);
  const isAddingMeToJob = useSelector(selectIsAddingMeToActiveJob);

  if (isDismissed) {
    return null;
  }

  return (
    <NotEditableTagContainer>
      <FlexEndContainer>
        <WarningIcon />
        {getText('rams_edit_only_managers')}
      </FlexEndContainer>

      <div>
        <AddToJobButton
          variant='text'
          disableRipple
          onClick={() => dispatch(addMeToJob())}
          disabled={isAddingMeToJob}
        >
          {isAddingMeToJob ? (
            <Loader size={20} />
          ) : (
            <span>{getText('add_me_to_job')}</span>
          )}
        </AddToJobButton>
        <IconContainer onClick={() => dispatch(setEditTagDismissed())}>
          <CrossIcon />
        </IconContainer>
      </div>
    </NotEditableTagContainer>
  );
};

import { put, select, takeLatest } from 'redux-saga/effects';
import { setGlobalError } from 'store/slices/notifications';
import * as Logger from 'utils/logger';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  fetchWorkflowSteps,
  fetchWorkflowStepsError,
  fetchWorkflowStepsSuccess,
  fetchWorkflowTasks,
  fetchWorkflowTasksError,
  fetchWorkflowTasksSuccess,
  fetchWorkflowTaskTypes,
  fetchWorkflowTaskTypesError,
  fetchWorkflowTaskTypesSuccess,
  selectActiveWorkflowId,
} from 'store/slices/workflows';
import {
  getWorkflowSteps,
  getWorkflowTasks,
  getWorkflowTaskTypes,
} from 'api/WorkflowSettings';
import { selectActiveWorkspaceUuid } from 'store/selectors';

function* getWorkflowStepsFn(action: PayloadAction<string>) {
  try {
    const workspaceId = yield select(selectActiveWorkspaceUuid);
    const steps = yield getWorkflowSteps(workspaceId, action.payload);
    const workflowId = yield select(selectActiveWorkflowId);
    yield put(fetchWorkflowStepsSuccess({ workflowId, steps }));
  } catch (err) {
    Logger.error(err);
    yield put(fetchWorkflowStepsError(action.payload));
    yield put(setGlobalError(err));
  }
}

function* getWorkflowTasksFn() {
  try {
    const workspaceId = yield select(selectActiveWorkspaceUuid);
    const tasks = yield getWorkflowTasks(workspaceId);
    yield put(fetchWorkflowTasksSuccess(tasks));
  } catch (err) {
    Logger.error(err);
    yield put(fetchWorkflowTasksError());
    yield put(setGlobalError(err));
  }
}

function* getWorkflowTaskTypesFn(action: PayloadAction<string>) {
  try {
    const workspaceId = yield select(selectActiveWorkspaceUuid);
    const taskTypes = yield getWorkflowTaskTypes(workspaceId, action.payload);
    yield put(
      fetchWorkflowTaskTypesSuccess({
        taskId: action.payload,
        types: taskTypes,
      }),
    );
  } catch (err) {
    Logger.error(err);
    yield put(fetchWorkflowTaskTypesError());
    yield put(setGlobalError(err));
  }
}

export default function* workflowSettingsWatcher() {
  yield takeLatest(fetchWorkflowSteps, getWorkflowStepsFn);
  yield takeLatest(fetchWorkflowTasks, getWorkflowTasksFn);
  yield takeLatest(fetchWorkflowTaskTypes, getWorkflowTaskTypesFn);
}

import { useText } from 'hooks';
import { TeamMemberSearchItem } from './TeamMemberSearchItem';
import { Initial, LoadMore, UserRow, Users } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsFetchingUsers,
  selectNextLink,
  selectUsers,
} from 'store/selectors/user';
import { fetchMoreUsers, fetchUsers } from 'store/actions/user';
import { BackendUser } from 'model/BackendUser';
import { selectCurrentUserId } from 'store/selectors';
import { useEffect, useMemo } from 'react';
import { UserRoleBackend } from 'model/enum/UserRoleBackend';
import { Loader } from 'components/ui';
import { USER_LIST_PAGE_SIZE, UserListByInitial } from './constants';

type Props = {
  search: string;
  handleToggleSelect: (user: BackendUser) => void;
  selectedUserIds: number[];
  userRoles: UserRoleBackend[];
  depotId?: number;
  showCurrentDepotOnly?: boolean;
  height?: string;
};

export const MemberList = ({
  search,
  handleToggleSelect,
  selectedUserIds,
  userRoles,
  depotId,
  height,
}: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const isFetchingUsers = useSelector(selectIsFetchingUsers);
  const nextLink = useSelector(selectNextLink);
  const currentUserId = useSelector(selectCurrentUserId);

  useEffect(() => {
    dispatch(fetchUsers(search, USER_LIST_PAGE_SIZE, depotId, userRoles));
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const userListByInitial = useMemo(
    () =>
      users?.reduce((result: UserListByInitial, item: BackendUser) => {
        const letter = item?.first_name[0]?.toUpperCase() ?? '';
        if (!result[letter]) {
          result[letter] = [];
        }

        result[letter].push(item);
        return result;
      }, {}) ?? {},
    [users],
  );

  const handleLoadMore = () => {
    dispatch(fetchMoreUsers(nextLink));
  };

  const userIdIsChecked = (id) => selectedUserIds.includes(id);

  return (
    <Users $height={height}>
      {isFetchingUsers && <Loader />}
      {!isFetchingUsers && users && users?.length < 1 ? (
        <>{getText('rams_sign_on_user_no_display')}</>
      ) : (
        <>
          {Object.entries(userListByInitial).map(
            ([key, value]: [string, Array<BackendUser>]) => (
              <div key={`initial-${key}`}>
                <Initial>{key}</Initial>
                {value.map((user) => (
                  <UserRow
                    key={`userRow-${user.id}`}
                    onClick={() => handleToggleSelect(user)}
                  >
                    <TeamMemberSearchItem
                      filteredUser={user}
                      currentUserId={currentUserId}
                      isChecked={userIdIsChecked(user.id)}
                      roleTitle={getText(
                        user?.is_deviceless
                          ? 'rams_sign_on_digital_signature'
                          : 'rams_sign_on_user_fieldworker',
                      )}
                    />
                  </UserRow>
                ))}
              </div>
            ),
          )}
          {nextLink && (
            <LoadMore onClick={handleLoadMore}>{getText('load_more')}</LoadMore>
          )}
        </>
      )}
    </Users>
  );
};

import {
  FETCH_ASSESSMENTS,
  SET_ASSESSMENTS,
  UPDATE_ASSESSMENT,
  SET_ASSESSMENT,
  FETCH_ASSESSMENT,
  SET_UPDATED_ASSESSMENT,
  SEND_SUGGESTION,
  CLEAR_ASSESSMENT,
  SET_ASSESSMENT_ERROR,
  SEND_ASSESSMENT_SUMMARY_FEEDBACK,
  ASSESSMENT_SUMMARY_FEEDBACK_SUCCESS,
  REQUEST_NEW_VRA,
  REQUEST_NEW_VRA_SUCCESS,
  REQUEST_NEW_VRA_ERROR,
  GENERATE_ASSESSMENT_PDF,
  GENERATE_ASSESSMENT_PDF_SUCCESS,
  GENERATE_ASSESSMENT_PDF_ERROR,
  ASSESSMENT_SUMMARY_FEEDBACK_ERROR,
} from './actionTypes';

export const fetchAssessments = (jobId) => ({
  type: FETCH_ASSESSMENTS,
  jobId,
});

export const setAssessments = (assessments) => ({
  type: SET_ASSESSMENTS,
  assessments,
});

export const updateAssessment = (assessmentId, newAssessment) => ({
  type: UPDATE_ASSESSMENT,
  assessmentId,
  newAssessment,
});

export const setUpdatedAssessment = (assessmentId, newAssessment) => ({
  type: SET_UPDATED_ASSESSMENT,
  assessmentId,
  newAssessment,
});

export const setAssessment = (assessment) => ({
  type: SET_ASSESSMENT,
  assessment,
});

export const fetchAssessment = (assessmentId) => ({
  type: FETCH_ASSESSMENT,
  assessmentId,
});

export const sendSuggestion = (assessmentId, suggestions) => ({
  type: SEND_SUGGESTION,
  assessmentId,
  suggestions,
});

export const clearAssessment = () => ({
  type: CLEAR_ASSESSMENT,
});

export const setAssessmentError = (error) => ({
  type: SET_ASSESSMENT_ERROR,
  error,
});

export const sendAssessmentSummaryFeedback = (
  assessmentId,
  fileId,
  assessmentVideo,
) => ({
  type: SEND_ASSESSMENT_SUMMARY_FEEDBACK,
  assessmentId,
  fileId,
  assessmentVideo,
});

export const setAssessmentSummarySuccess = (video) => ({
  type: ASSESSMENT_SUMMARY_FEEDBACK_SUCCESS,
  video,
});

export const setAssessmentSummaryError = () => ({
  type: ASSESSMENT_SUMMARY_FEEDBACK_ERROR,
});

export const requestNewVra = (assessmentId, newAssessment) => ({
  type: REQUEST_NEW_VRA,
  assessmentId,
  newAssessment,
});

export const requestNewVraSuccess = () => ({
  type: REQUEST_NEW_VRA_SUCCESS,
});

export const requestNewVraError = () => ({
  type: REQUEST_NEW_VRA_ERROR,
});

export const getPdfExportId = (assessmentId, eventProperties) => ({
  type: GENERATE_ASSESSMENT_PDF,
  assessmentId,
  eventProperties,
});

export const getPdfExportIdSuccess = () => ({
  type: GENERATE_ASSESSMENT_PDF_SUCCESS,
});

export const getPdfExportIdError = () => ({
  type: GENERATE_ASSESSMENT_PDF_ERROR,
});

import styled from 'styled-components';
import theme from 'theme';
import { Check, Info, ThumbsDown, ThumbsUp, Warning } from 'assets/icons';
import { useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import { useIsMobile, useSafetyPrediction, useText } from 'hooks';
import { useEffect, useState } from 'react';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import InfoModal from '../../SafetyPrediction/InfoModal';

const { mixins, fontSizes, fonts, colors } = theme;

const Container = styled.div<{ $isMobile: boolean }>`
  padding: 30px 10px 10px 10px;
  ${({ $isMobile }) => ($isMobile ? mixins.flexColumn : mixins.flexBetween)}
  align-items: flex-start;
`;

const InfoContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex: 1;

  ${({ $isMobile }) => !$isMobile && `max-width: 70%;`}
`;

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
`;

const HighRiskWarningIcon = styled(Warning)`
  height: 50px;
  width: 50px;
  padding-right: 10px;
  padding-top: 5px;

  .fill-selector {
    fill: ${colors.red};
  }
`;

const ScoreContainer = styled.div<{ $isMobile?: boolean }>`
  ${mixins.flexColumn};
  ${({ $isMobile }) =>
    $isMobile
      ? 'text-align: left;'
      : 'text-align: right;   padding-left: 10px;'};
`;

const TitleWrapper = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 5px;
`;

const InfoIcon = styled(Info)`
  cursor: pointer;
`;

const BoldWrapper = styled.span`
  font-family: ${fonts.bold};
  line-height: 25px;
`;

const LargeWrapper = styled.span`
  font-size: ${fontSizes.m};
  margin-top: -5px;
  line-height: 25px;
`;

const MessageContainer = styled.div`
  ${mixins.flexColumn}
`;

const ThumbsContainer = styled.div`
  ${mixins.flexColumn}
  padding-left: 10px;
  justify-content: space-around;
`;

const ThumbsWrapper = styled.div`
  cursor: pointer;
  display: flex;
  padding: 3px;
  &:hover {
    background-color: ${colors.lightGrey};
    border-radius: 4px;
  }
`;

const ScoreFeedbackWrapper = styled.div`
  ${mixins.flexBetween}
  align-items: unset;
`;

const SuccessMessage = styled.div`
  width: fit-content;
  padding: 10px;
  background-color: ${colors.lightGreen};
`;

type RiskWarning = {
  type: string;
  advice: string;
  score: string;
  scoreLabel: string;
  message: string;
};

const HighRiskWarning = (): JSX.Element | null => {
  const getText = useText();
  const { activeJob, isFetchingActiveJob } = useSelector(
    (state: RootState) => state.jobs,
  );
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const { getRiskWarning } = useSafetyPrediction();
  const [riskWarning, setRiskWarning] = useState<RiskWarning | null>(null);
  const [isInfoOpen, setInfoOpen] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  useEffect(() => {
    if (!isFetchingActiveJob && activeJob) {
      setRiskWarning(getRiskWarning(activeJob.safety_prediction_score));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location?.state?.successfulReview) {
      setShowSuccessMessage(location?.state?.successfulReview);
    }
  }, [location?.state]);

  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => setShowSuccessMessage(false), 3500);

      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);

  if (!riskWarning) {
    return null;
  }

  const openModal = (isPositive = false) => {
    Analytics.trackEvent(
      isPositive
        ? constants.EVENT_PSI_FEEDBACK_THUMBS_UP_CLICKED
        : constants.EVENT_PSI_FEEDBACK_THUMBS_DOWN_CLICKED,
      {
        jobId: activeJob?.id,
        psiScoreId: activeJob?.safety_prediction_score.id,
      },
    );
    navigate(`/jobs/${activeJob?.id}/safety-review`, {
      state: {
        riskTypes: riskWarning.type,
      },
    });
  };

  const getScoreAndFeedbackIcons = () => (
    <ScoreFeedbackWrapper>
      <ScoreContainer $isMobile={isMobile}>
        <TitleWrapper>{riskWarning.score}</TitleWrapper>
        <LargeWrapper>{riskWarning.scoreLabel}</LargeWrapper>
      </ScoreContainer>
      {activeJob?.safety_prediction_score?.id !==
        activeJob?.user_review_psi_score_id && (
        <ThumbsContainer>
          <ThumbsWrapper>
            <ThumbsUp onClick={() => openModal(true)} />
          </ThumbsWrapper>
          <ThumbsWrapper>
            <ThumbsDown onClick={openModal} />
          </ThumbsWrapper>
        </ThumbsContainer>
      )}
    </ScoreFeedbackWrapper>
  );

  return (
    <>
      <Container $isMobile={isMobile}>
        <InfoContainer $isMobile={isMobile}>
          <IconWrapper>
            <HighRiskWarningIcon />
          </IconWrapper>
          <MessageContainer>
            <TitleWrapper>
              {riskWarning.message}{' '}
              {activeJob?.safety_prediction_score?.id !==
                activeJob?.user_review_psi_score_id && (
                <InfoIcon onClick={() => setInfoOpen(true)} />
              )}
            </TitleWrapper>
            <p>
              <BoldWrapper>{riskWarning.type}:</BoldWrapper>{' '}
              {riskWarning.advice}
            </p>
            {isMobile && getScoreAndFeedbackIcons()}
          </MessageContainer>
        </InfoContainer>
        {!isMobile && getScoreAndFeedbackIcons()}
      </Container>
      {showSuccessMessage && (
        <SuccessMessage>
          {getText('job_overview_safety_prediction_feedback_success')} <Check />
        </SuccessMessage>
      )}
      <InfoModal isOpen={isInfoOpen} onClose={() => setInfoOpen(false)} />
    </>
  );
};

export default HighRiskWarning;

import { useState, useEffect } from 'react';
import { Job } from 'api';
import { useDispatch } from 'react-redux';
import { setGlobalError } from 'store/slices/notifications';
import { Hazard, HazardWithControlsAndComments } from 'model/Assessment/Hazard';
import { Control } from 'model/Assessment/Control';

type Props = {
  hazards: Hazard[];
  controls: Control[];
  comments: string[];
  hazardsWithControlsAndComments: HazardWithControlsAndComments | null;
};

// useHazardsControlsComments
const useHazardsControlsComments = (jobId?: string) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<Props>({
    hazards: [],
    controls: [],
    comments: [],
    hazardsWithControlsAndComments: null,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (jobId) {
      setIsLoading(true);
      (async () => {
        try {
          const {
            hazards,
            controls,
            comments,
            hazardsWithControlsAndComments,
          } = await Job.getHazardsControlsComments(jobId);
          setData({
            hazards,
            controls,
            comments,
            hazardsWithControlsAndComments,
          });
          setIsLoading(false);
        } catch (err) {
          dispatch(setGlobalError(err));
          console.error(err);
          setIsLoading(false);
        }
      })();
    }
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  return { ...data, isLoading };
};

export default useHazardsControlsComments;

import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useText } from 'hooks';
import { WorkOrderItem as WorkOrderItemApi } from 'api';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import * as Logger from 'utils/logger';
import { Button, Input, Loader, Navbar } from 'components/ui';
import { formatDateTime, formatDate } from 'helpers/dates';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGlobalLanguageSetting,
  selectJobs,
  selectSubtypesByType,
} from 'store/selectors';
import { getWorkOrderSubtype } from 'helpers/apiHelpers';
import { useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import { Arrow } from 'assets/icons';
import { setGlobalError } from 'store/slices/notifications';
import { WorkOrderItem } from 'model/WorkOrderItem';
import { fetchJobSubtypes } from 'store/actions/jobTypes';
import { getLocalizedCamelCaseValue } from 'helpers/locales';
import { fetchActiveJob } from 'store/actions/jobs';

const { colors, fonts, fontSizes, media, mixins } = theme;
const {
  EVENT_CLICK_EDIT_WORK_OUTPUT,
  EVENT_CANCEL_EDIT_WORK_OUTPUT,
  EVENT_SAVE_EDIT_WORK_OUTPUT,
} = constants;

const ArrowLeft = styled(Arrow)`
  height: 15px;
  .fill-selector {
    fill: ${colors.black};
  }
  transform: rotate(180deg);
  margin-left: -5px;
  margin-right: 5px;
`;

// Styled Components
const Header = styled.div`
  height: 50px;
  min-height: 50px;
  ${mixins.flexBetween};
  background-color: ${colors.lightGrey};
  padding: 0 25px;
  ${media.sm`
    padding: 0 15px;
  `}
`;

const Creator = styled.p`
  font-family: ${fonts.bold};
`;

const DateWrapper = styled.p`
  color: ${colors.darkGrey};
`;

const Wrapper = styled.div`
  padding: 10px 0;
  overflow: auto;
`;

const Section = styled.div`
  padding: 10px 20px;
  font-size: ${fontSizes.m};
`;

const SectionTitle = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
`;

const CancelButton = styled.button`
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;

  padding-right: 5px;
  cursor: pointer;
  font-size: ${fontSizes.m};
  border-bottom: 2px solid black;
`;

const ErrorMessage = styled.div`
  color: ${colors.red};
  padding-top: 10px;
`;

export const WorkOrderItemShow = (): JSX.Element => {
  const { jobId, workOrderItemId } = useParams();
  const getText = useText();
  const dispatch = useDispatch();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const [workOrderItem, setWorkOrderItem] = useState<WorkOrderItem | null>(
    null,
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedUnitValue, setEditedUnitValue] = useState<string>('');
  const [hasUnitValueError, setHasUnitValueError] = useState<boolean>(false);
  const [editedSubtypeId, setEditedSubtypeId] = useState<number>();
  const [editedTime, setEditedTime] = useState<Date | undefined>();
  const { activeJob } = useSelector(selectJobs);
  const jobSubtypes = useSelector(
    selectSubtypesByType(workOrderItem?.subtype?.typeId),
  );
  const { currentUser, selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );

  const selectedWorkspaceUuid = selectedWorkspace?.uuid;

  const canEdit = currentUser?.permissions?.includes('edit_work_output_v1');

  const workOrderUnits: string = workOrderItem?.units?.toString() ?? '';

  useEffect(() => {
    if (!activeJob) {
      dispatch(fetchActiveJob(jobId));
    }
  }, [jobId, activeJob, dispatch]);

  useEffect(() => {
    dispatch(fetchJobSubtypes(selectedWorkspaceUuid));
  }, [selectedWorkspaceUuid, dispatch]);

  useEffect(() => {
    (async () => {
      const data = await WorkOrderItemApi.get(
        parseInt(workOrderItemId ?? '', 10),
      );
      setWorkOrderItem(data);
      setEditedUnitValue(data?.units?.toString() ?? '');
      setEditedSubtypeId(data?.subtypeId);
      setEditedTime(data?.sentAt);
    })();
  }, [workOrderItemId]);

  const onSaveClick = async () => {
    const parsedUnitValue: number | null =
      editedUnitValue === ''
        ? null
        : parseFloat(editedUnitValue.replaceAll(',', '.'));
    if (
      workOrderItem?.unitTitle &&
      parsedUnitValue !== null &&
      isNaN(parsedUnitValue)
    ) {
      setHasUnitValueError(true);
      return;
    }
    try {
      if (workOrderItem && editedSubtypeId && editedTime) {
        const data = await WorkOrderItemApi.editOutput(
          workOrderItem?.id,
          parsedUnitValue,
          editedSubtypeId,
          editedTime,
        );
        setWorkOrderItem(data);
      }
      setIsEditing(false);
      setEditedUnitValue('');
    } catch (err) {
      dispatch(setGlobalError(err));
      Logger.error(err);
    }
  };

  const onCancelClick = () => {
    Analytics.trackEvent(EVENT_CANCEL_EDIT_WORK_OUTPUT, {
      jobId: activeJob?.id,
      userId: currentUser?.id,
      logId: workOrderItem?.id,
    });
    setEditedUnitValue(workOrderUnits);
    setEditedSubtypeId(workOrderItem?.subtypeId);
    setEditedTime(workOrderItem?.sentAt);
    setIsEditing(false);
  };

  if (!workOrderItem || !activeJob) {
    return (
      <>
        <Navbar
          title={getText('job_details_work_completed_title')}
          defaultBackPath={`/jobs/${jobId}/work-completed`}
          mobileOnly
        />
        <Loader />
      </>
    );
  }

  const editedSubtype = jobSubtypes?.find(
    (subtype) => subtype.id === editedSubtypeId,
  );

  const onEditClick = () => {
    setEditedUnitValue(workOrderUnits);
    setIsEditing(true);
  };

  return (
    <>
      <Navbar
        title={
          isEditing
            ? getText('job_details_work_completed_editing_title')
            : getText('job_details_work_completed_title')
        }
        defaultBackPath={`/jobs/${jobId}/work-completed`}
        mobileOnly
      />
      <Header>
        <Creator>{workOrderItem.createdBy.fullName}</Creator>
        <DateWrapper>
          {formatDateTime(
            workOrderItem.createdAt,
            getText('date_today'),
            getText('date_yesterday'),
          )}
        </DateWrapper>
      </Header>
      {isEditing ? (
        <>
          <Section>
            <CancelButton onClick={onCancelClick}>
              <ArrowLeft />
              <span>{getText('job_details_work_completed_cancel_edits')}</span>
            </CancelButton>
          </Section>
          <Section>
            <SectionTitle>
              {getText('job_details_work_completed_date_work_completed')}
            </SectionTitle>
            <Input.DateTimeSelector
              value={editedTime?.toISOString()}
              onChange={(newEditedTime) =>
                setEditedTime(newEditedTime ?? undefined)
              }
            />
          </Section>
          <Section>
            <SectionTitle>
              {getText('job_details_work_completed_subtype')}
            </SectionTitle>
            {editedSubtypeId && jobSubtypes.length > 0 && (
              <Input.SelectWithSearch
                id='subtype'
                selected={editedSubtype}
                options={jobSubtypes}
                onChange={(_, option) => setEditedSubtypeId(Number(option?.id))}
                large
                required
                fitContent
              />
            )}
          </Section>
          {workOrderItem.unitTitle && (
            <Section>
              <SectionTitle>
                {editedSubtype?.[`unit_title_${globalAppLanguage}`] ||
                  editedSubtype?.unit_title}
              </SectionTitle>
              <Input.Text
                id='units'
                value={editedUnitValue}
                disabled={false}
                onChange={(e) => {
                  setHasUnitValueError(false);
                  setEditedUnitValue(e.target.value);
                }}
              />
              {hasUnitValueError && (
                <ErrorMessage>
                  {getText('job_details_work_completed_unit_error')}
                </ErrorMessage>
              )}
            </Section>
          )}
          <Button.Primary
            event={EVENT_SAVE_EDIT_WORK_OUTPUT}
            eventProperties={{
              jobId: activeJob?.id,
              userId: currentUser?.id,
              logId: workOrderItem?.id,
            }}
            text={getText('confirm')}
            onClick={onSaveClick}
            extraStyles={{
              outline: 'none',
              borderTop: `2px solid
              ${colors.black}`,
            }}
          />
        </>
      ) : (
        <Wrapper>
          <Section>
            <SectionTitle>
              {getText('job_details_work_completed_date_work_completed')}
            </SectionTitle>
            {formatDate(workOrderItem.sentAt)}
          </Section>
          <Section>
            <SectionTitle>
              {getText('job_details_work_completed_subtype')}
            </SectionTitle>
            {getWorkOrderSubtype(
              workOrderItem.subtype,
              activeJob,
              globalAppLanguage,
            )}
          </Section>
          {workOrderItem.unitTitle && (
            <Section>
              <SectionTitle>
                {getLocalizedCamelCaseValue(
                  workOrderItem,
                  'unitTitle',
                  globalAppLanguage,
                )}
              </SectionTitle>
              <p>{workOrderItem.units ?? '-'}</p>
            </Section>
          )}
          <Section>
            <SectionTitle>
              {getText('job_details_work_completed_other_conditions')}
            </SectionTitle>
            <p>{workOrderItem.note}</p>
          </Section>
          {canEdit && (
            <Section>
              <Button.Secondary
                event={EVENT_CLICK_EDIT_WORK_OUTPUT}
                eventProperties={{
                  jobId: activeJob?.id,
                  userId: currentUser?.id,
                  logId: workOrderItem?.id,
                }}
                onClick={onEditClick}
                text={getText('edit')}
              />
            </Section>
          )}
        </Wrapper>
      )}
    </>
  );
};

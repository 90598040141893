import axios from 'axios';

export const get = async (id: number) => {
  try {
    const response = await axios.get(`/job-form/${id}/`);
    return response.data;
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const getForJobId = async (jobId: string) => {
  try {
    const response = await axios.get(`/jobs/${jobId}/form/`, {});
    return response.data.results;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err?.message);
    }
  }
};

export const getPdfExportId = async (formId: string): Promise<string> => {
  const { data } = await axios.post(`/job-form/${formId}/pdf/`);
  return data.object_id;
};

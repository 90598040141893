import {
  HavsFilterTypes,
  HavsFilters,
} from 'model/AdminPanel/HavsSettings/HavsUser';
import {
  ActiveFilterContainer,
  FilterBox,
  FilterCross,
  FilterText,
} from '../styled';
import { capitalizeFullName } from 'helpers/names';
import { useText } from 'hooks';

type Props = {
  filters: HavsFilters;
  removeFilter: (field: keyof HavsFilters) => void;
};

const AppliedFilterBox = ({
  onClick,
  text,
}: {
  onClick: (type: keyof HavsFilters) => void;
  text: string;
}): JSX.Element => (
  <FilterBox>
    <FilterCross onClick={onClick} />
    <FilterText>{text}</FilterText>
  </FilterBox>
);

export const FilterLabels = ({ filters, removeFilter }: Props): JSX.Element => {
  const getText = useText();

  return (
    <ActiveFilterContainer>
      {filters.depot && (
        <AppliedFilterBox
          onClick={() => removeFilter(HavsFilterTypes.DEPOT)}
          text={filters.depot.title}
        />
      )}
      {filters.manager && (
        <AppliedFilterBox
          onClick={() => removeFilter(HavsFilterTypes.MANAGER)}
          text={capitalizeFullName(
            filters.manager.firstName,
            filters.manager.lastName,
          )}
        />
      )}
      {filters.havsLimit && (
        <AppliedFilterBox
          onClick={() => removeFilter(HavsFilterTypes.HAVS_LIMIT)}
          text={`${getText('havs_users_table_header_havs_limit')}: ${
            filters.havsLimit
          }`}
        />
      )}
    </ActiveFilterContainer>
  );
};

import { useEffect, useState } from 'react';
import * as constants from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssessment } from 'store/actions/assessments';
import { useHazards, useIsMobile } from 'hooks';
import SuggestBase from './SuggestBase';
import AddBase from './AddBase';
import SuggestControlForHazards from './SuggestControlForHazards';
import { Assessment, AssessmentItemType } from 'model/Assessment';
import { useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import { Hazard } from 'model/Assessment/Hazard';

type Props = {
  assessment?: Assessment | null;
  projectEngineerAssessment?: boolean;
};

const SuggestHazard = ({
  assessment = null,
  projectEngineerAssessment = false,
}: Props): JSX.Element => {
  const hazards = useHazards();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { assessmentId } = useParams();
  const { currentUser, isProjectEngineer } = useSelector(
    (state: RootState) => state.session,
  );
  const mobileAssessment = useSelector(
    (state: RootState) => state.assessments.activeAssessment,
  );
  const [projectEngineerMobile, setProjectEngineerMobile] =
    useState<boolean>(false);
  const [isHazardsControlsOpened, setHazardsControlsOpened] =
    useState<boolean>(false);
  const [selectedHazards, setSelectedHazards] = useState<Hazard[]>([]);

  useEffect(() => {
    if (!assessment) {
      dispatch(fetchAssessment(assessmentId));
    }
  }, [assessmentId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMobile) {
      setProjectEngineerMobile(
        (isProjectEngineer &&
          currentUser &&
          mobileAssessment &&
          mobileAssessment.createdBy.id === currentUser.id) ??
          false,
      );
    }
  }, [mobileAssessment]); // eslint-disable-line react-hooks/exhaustive-deps

  const openControlsForHazards = (selected) => {
    setHazardsControlsOpened(true);
    setSelectedHazards(selected);
  };

  const renderBase = () => {
    if (projectEngineerAssessment || projectEngineerMobile) {
      return (
        <AddBase
          event={constants.EVENT_ADD_HAZARDS}
          items={hazards?.filter(
            (item) =>
              (assessment &&
                item?.assessmentTypeList?.includes(assessment.typeId)) ||
              (mobileAssessment &&
                item?.assessmentTypeList?.includes(mobileAssessment.typeId)),
          )}
          assessment={assessment ?? mobileAssessment}
          addType={AssessmentItemType.HAZARD}
          onNext={openControlsForHazards}
        />
      );
    }
    return (
      <SuggestBase
        event={constants.EVENT_SUGGEST_HAZARDS}
        items={hazards?.filter(
          (item) =>
            (assessment &&
              item?.assessmentTypeList?.includes(assessment.typeId)) ||
            (mobileAssessment &&
              item?.assessmentTypeList?.includes(mobileAssessment.typeId)),
        )}
        assessment={assessment ?? mobileAssessment}
        suggestionType={AssessmentItemType.HAZARD}
        onNext={openControlsForHazards}
      />
    );
  };

  return isHazardsControlsOpened ? (
    <SuggestControlForHazards
      hazards={selectedHazards}
      isAddition={projectEngineerAssessment || projectEngineerMobile}
    />
  ) : (
    renderBase()
  );
};

export default SuggestHazard;

import { Assessment, Hazard, Job, Misc } from 'api';
import * as Logger from 'utils/logger';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { setGlobalError } from 'store/slices/notifications';
import { setFormOptions } from 'store/actions/form';
import { FETCH_FORM_OPTIONS } from 'store/actions/actionTypes';

function* fetchFormOptions() {
  try {
    const { selectedWorkspace } = yield select((state) => state.session);
    const [types, subtypes, statuses, depots, assessmentTypes, hazards] =
      yield all([
        Job.getAllTypes(selectedWorkspace?.uuid),
        Job.getAllSubtypes(selectedWorkspace?.uuid),
        Job.getAllStatuses(selectedWorkspace?.uuid),
        Misc.getAllDepots(selectedWorkspace?.uuid),
        Assessment.getAllTypes(selectedWorkspace?.uuid),
        Hazard.getAll(selectedWorkspace?.uuid),
      ]);
    yield put(
      setFormOptions({
        types: types.data.results,
        subtypes: subtypes.data.results,
        statuses: statuses.data.results,
        depots: depots.data.results,
        assessmentTypes,
        hazards,
      }),
    );
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
    Logger.error(err);
  }
}

export default function* formWatcher() {
  yield takeLatest(FETCH_FORM_OPTIONS, fetchFormOptions);
}

import theme from 'theme';
import styled, { css } from 'styled-components';
import { Slogan } from 'components/ui/Misc/Slogan';
import { BrandPatternCircles } from 'assets/images';

const { breakpoints, colors, fontSizes, media, mixins, fonts } = theme;

export const Container = styled.div`
  width: 70%;
  margin: 20px auto;
`;

export const Title = styled.div`
  font-size: ${fontSizes.xxl};
  font-family: ${fonts.bold};
`;

export const TextContent = styled.div`
  margin: 20px 0;
`;

export const Text = styled.div`
  font-size: ${fontSizes.m};
  line-height: ${fontSizes.xxl};

  & a {
    text-decoration: underline;
  }
`;

export const ChatWrapper = styled.span`
  text-decoration: underline;

  :focus {
    outline: 2px solid ${colors.black};
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  background-image: url(${BrandPatternCircles});
  background-repeat: no-repeat;
  background-position-x: 80vw;
  background-position-y: bottom;
  padding: 15vh 7vw 0;

  ${media.md`
    flex: 1 0;
    padding: 10px 2vw 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-position-x: unset;
    background-image: none;
  `}
`;

export const LogoContainer = styled.div<{ variant?: string }>`
  display: flex;
  ${({ variant }) =>
    variant === 'success'
      ? `
  justify-content: space-around;
    margin-top: 16px;
  `
      : css`
          flex-direction: column;
          align-items: flex-start;
          max-width: 450px;

          ${media.md`
      padding: 0 5px;
      width: 100%;
    `}
        `}
`;

export const YellowText = styled.div<{
  fontSize: string;
  $hasMargin?: boolean;
}>`
  color: ${colors.yellow};
  font-size: ${({ fontSize }) => fontSize};
  ${({ $hasMargin }) => $hasMargin && `margin-top: 80px`};
  ${media.sm`
    font-size: ${fontSizes.xl};
    ${({ $hasMargin }) => $hasMargin && `margin-top: 20px`};
  `}
`;

export const WrapperSlogan = styled(Slogan)`
  padding-top: 0px;
  padding-bottom: 15px;
  font-size: ${fontSizes.xxl};
  ${media.sm`
    font-size: ${fontSizes.xl};
  `}
`;

export const Item = styled.div`
  display: flex;
  align-items: baseline;
  margin: 5px 0;
`;

export const BulletPointWrapper = styled.div`
  ${mixins.flexFullCenter};
`;

export const BulletPoint = styled.div`
  min-height: 7px;
  min-width: 7px;
  border-radius: 50%;
  margin-right: 30px;
  background-color: ${colors.white};
`;

export const P = styled.p`
  color: ${colors.white};
  font-size: ${fontSizes.l};
  ${media.sm`
    font-size: ${fontSizes.m};
  `}
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px;

  ${media.sm`
    width: 100%;
    flex: 1 0;
    margin-top: 40px;
    padding: 0 0px;
  `}
`;

export const InputHolder = styled.div`
  margin: 1em 0;
  width: 416px;

  ${media.sm`
     width: 100%;
  `};
`;

export const WrapperFooter = styled.div`
  margin-top: 20px;
  color: ${colors.white};

  a {
    text-decoration: underline;
  }
`;

export const Wrapper = styled.div`
  max-width: 465px;

  ${media.sm`
    margin-top: 30px;
  `}
`;

export const WhiteText = styled.div`
  color: ${colors.white};
  font-size: ${fontSizes.xl};

  & + & {
    margin-top: 1em;
  }

  ${media.sm`
    font-size: ${fontSizes.m};
  `}
`;

export const AppStoreLink = styled.img`
  @media only screen and (min-width: ${breakpoints.sm}px) {
    margin-top: 15px;
    height: 65px;
  }
  ${media.sm`
    width: 185px;
  `}
`;

export const GooglePlayLink = styled.img`
  @media only screen and (min-width: ${breakpoints.sm}px) {
    height: 95px;
  }
  ${media.sm`
    width: 212px;
    margin-left: -15px;
  `}
`;

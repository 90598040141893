import { Hazard } from 'model/Assessment/Hazard';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectModifiedHazardId } from 'store/selectors/settings/hazards';
import { Table, TableBody, TableRow } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionWrapper,
  AnchorElement,
} from './styled';
import TableHeader from './TableHeader';
import HazardRow from './HazardRow';
import ControlRow from './ControlRow';

type Props = {
  hazards: Hazard[];
  onEditClick: (hazard: Hazard) => void;
  onRemoveClick: (hazard: Hazard) => void;
  onMajorHazardChange: (hazard: Hazard, checked: boolean) => void;
  openInfo: () => void;
  loading: boolean;
};

const HazardTable = ({
  hazards,
  onEditClick,
  onRemoveClick,
  onMajorHazardChange,
  openInfo,
  loading,
}: Props): JSX.Element => {
  const modifiedHazardId = useSelector(selectModifiedHazardId);
  const [openedList, setOpenedList] = useState<string[]>([]);

  const isExpanded = (rowKey: string) => openedList.includes(rowKey) ?? false;

  useEffect(() => {
    if (modifiedHazardId) {
      const modifiedHazard = document.getElementById(
        `anchor-${modifiedHazardId?.toString()}`,
      );
      if (modifiedHazard) {
        modifiedHazard.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }
    }
  }, [modifiedHazardId]);

  const handleExpandChange = (rowKey) => {
    if (isExpanded(rowKey)) {
      setOpenedList((prevList) => [
        ...prevList.filter((item) => item !== rowKey),
      ]);
    } else {
      setOpenedList((prevList) => [...prevList, rowKey]);
    }
  };

  return (
    <Table stickyHeader>
      <TableHeader openInfo={openInfo} />
      <TableBody>
        {hazards?.map((hazard) => (
          <TableRow id={hazard?.id?.toString()} key={`hazard-${hazard.id}`}>
            <AccordionWrapper colSpan={3}>
              <AnchorElement id={`anchor-${hazard?.id?.toString()}`} />
              <Accordion expanded={isExpanded(`hazard-${hazard.id}`)}>
                <AccordionSummary>
                  <HazardRow
                    hazard={hazard}
                    onEditClick={onEditClick}
                    onRemoveClick={onRemoveClick}
                    onMajorHazardChange={onMajorHazardChange}
                    handleExpandChange={handleExpandChange}
                    isExpanded={isExpanded(`hazard-${hazard.id}`)}
                    loading={loading}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {isExpanded(`hazard-${hazard.id}`) && (
                    <ControlRow hazard={hazard} />
                  )}
                </AccordionDetails>
              </Accordion>
            </AccordionWrapper>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default memo(HazardTable);

import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Job } from 'api';
import theme from 'theme';
import { Button, Card } from 'components/ui';
import * as constants from 'utils/constants';
import { fetchJobs } from 'store/actions/jobs';
import { useText } from 'hooks';
import {
  selectWorkspaceSettings,
  selectGlobalLanguageSetting,
  selectJobs,
  selectActiveWorkspaceUuid,
  selectLocations,
  selectJobTypes,
  selectDepots,
  selectJobsView,
} from 'store/selectors';
import TeamCreate from '../Edit/TeamCreate';
import Location from 'model/Location';
import Coordinate from 'model/Coordinate';
import { BackendUser } from 'model/BackendUser';
import RootState from 'model/State/RootState';
import { CreateJobFormFields } from '../CreateJobFormFields';
import { getLabelByLocale, isValidPostcode } from 'helpers/utils';
import { fetchDepots } from 'store/actions/depots';
import { fetchJobSubtypes, fetchJobTypes } from 'store/actions/jobTypes';
import { fetchLocations } from 'store/actions/locations';
import { trackEvent } from 'utils/analytics';
import { useNavigate } from 'react-router-dom';
import { setGlobalError } from 'store/slices/notifications';
import * as Logger from 'utils/logger';
import { formatAutoWorkOrderId } from 'helpers/dates';
import { getCoordinatesForNewJob } from 'helpers/map';
import { BlankForm, CustomFields } from '../CreateJobFormFields/constants';

const { media, mixins, colors, fonts, fontSizes } = theme;

const getCreateJobEventPayload = (
  job,
  user,
  view,
  membersAdded,
  jobTypeTitle,
  jobSubtypeTitle,
) => {
  return {
    jobId: job.id,
    job_number: job.job_number,
    job_postcode: job.postcode,
    job_address: job.address,
    latitude: job.latitude,
    longitude: job.longitude,
    creator_user_id: user.id,
    members_added: membersAdded,
    job_type: jobTypeTitle ?? null,
    job_subtype: jobSubtypeTitle ?? null,
    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
  };
};

// Styled Components
const Container = styled.div`
  height: 100%;
  display: flex;
  background-color: ${colors.white};
  ${media.sm`
    height: auto;
    ${mixins.flexColumn};
  `};
`;

const SubTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  margin-bottom: 12px;
`;

const Form = styled.form`
  flex: 1;
  overflow: auto;
  height: 80vh;
`;

const TeamWrapper = styled.div`
  padding: 25px;
  ${media.sm`
    padding: 15px;
  `}
`;

const InputSeparator = styled.div`
  border-bottom: 6px solid ${colors.lightGrey};
`;

const ErrorContainer = styled.div`
  min-height: 130px;
  max-height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type FormLocation = {
  latitude: string | null;
  longitude: string | null;
};

type FormProps = {
  predefined_location: number | null;
  location: FormLocation;
  location_description: string;
  postcode: string | null;
  address: string;
  job_number: string | null;
  originating_number: string;
  project_number: string;
  type: number | null;
  subtype: number | null;
  depot: number | null;
  assignees: BackendUser[] | null;
  type_other: string | null;
  subtype_other: string | null;
};

type FormErrors = {
  address: boolean;
  location: boolean;
  location_description: boolean;
  type: boolean;
  subtype: boolean;
  depot: boolean;
  postcode: boolean;
  invalidPostcode: boolean;
  jobNumber: boolean;
};

const noErrorsState: FormErrors = {
  address: false,
  location: false,
  location_description: false,
  type: false,
  subtype: false,
  depot: false,
  postcode: false,
  invalidPostcode: false,
  jobNumber: false,
};

// Modal.CreateJob
export const CreateJob = (): JSX.Element => {
  const { currentUser } = useSelector((state: RootState) => state.session);
  const [form, setForm] = useState<FormProps>(BlankForm);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null,
  );
  const [selectedSubtypeTitle, setSelectedSubtypeTitle] = useState<string>('');
  const [selectedTypeTitle, setSelectedTypeTitle] = useState<string>('');
  const [errors, setErrors] = useState<FormErrors>(noErrorsState);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [coordinates, setCoordinates] = useState<Coordinate | null>(null);
  const {
    locations_enable,
    pin_location_enabled,
    work_order_number_label,
    work_order_number_label_es,
    originating_number_label,
    originating_number_label_es,
    project_number_label,
    project_number_label_es,
    job_subtype_label,
    job_subtype_label_es,
    address_label,
    address_label_es,
    postcode_label,
    postcode_label_es,
    enable_auto_work_order_number,
  } = useSelector(selectWorkspaceSettings);
  const { filters } = useSelector(selectJobs);
  const { locations, isFetchingLocations } = useSelector(selectLocations);
  const { jobTypes, jobSubtypes } = useSelector(selectJobTypes);
  const { depots } = useSelector(selectDepots);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);
  const view = useSelector(selectJobsView);

  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getText = useText();

  const otherType = getText('other');

  const customFields: CustomFields = {
    pinLocationEnabled: pin_location_enabled,
    workOrderNumberLabel: getLabelByLocale(globalAppLanguage, {
      work_order_number_label,
      work_order_number_label_es,
    }),
    originatingNumberLabel: getLabelByLocale(globalAppLanguage, {
      originating_number_label,
      originating_number_label_es,
    }),
    projectNumberLabel: getLabelByLocale(globalAppLanguage, {
      project_number_label,
      project_number_label_es,
    }),
    jobSubtypeLabel: getLabelByLocale(globalAppLanguage, {
      job_subtype_label,
      job_subtype_label_es,
    }),
    addressLabel: getLabelByLocale(globalAppLanguage, {
      address_label,
      address_label_es,
    }),
    postcodeLabel: getLabelByLocale(globalAppLanguage, {
      postcode_label,
      postcode_label_es,
    }),
  };

  const isFormValid = () => {
    const newErrors = {
      address: !!customFields.addressLabel && !form.address.trim(),
      location:
        (pin_location_enabled &&
          (!form.location?.latitude || !form.location?.longitude)) ||
        (locations_enable && !form.predefined_location),
      location_description:
        pin_location_enabled && !form.location_description.trim(),
      type: jobTypes?.length > 0 && !form.type,
      subtype:
        jobSubtypes.length > 0 &&
        (!selectedTypeTitle || selectedTypeTitle !== otherType) &&
        !!form.type &&
        jobSubtypes.filter((subtype) => subtype.type_id === form.type)?.length >
          0 &&
        !selectedSubtypeTitle,
      depot:
        selectedTypeTitle !== otherType &&
        selectedSubtypeTitle !== otherType &&
        !form.depot,
      postcode: !!customFields.postcodeLabel && !form.postcode,
      invalidPostcode:
        !!customFields.postcodeLabel &&
        !!form.postcode &&
        !isValidPostcode(form.postcode.replace(/ /g, '')),
      jobNumber: !enable_auto_work_order_number && !form.job_number?.trim(),
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => !error);
  };

  useEffect(() => {
    if (locations.length > 1 && form?.predefined_location) {
      setSelectedLocation(
        locations?.find(
          (location) => location.id === form.predefined_location,
        ) ?? null,
      );
    }
    if (jobTypes.length > 1 && form?.type) {
      setSelectedTypeTitle(
        jobTypes?.find((type) => type.id === form.type)?.title ?? '',
      );
    }
    if (jobSubtypes.length > 1 && form?.subtype) {
      setSelectedSubtypeTitle(
        jobSubtypes?.find((subtype) => subtype.id === form.subtype)?.title ??
          '',
      );
    }
  }, [locations, jobTypes, jobSubtypes, form]);

  useEffect(() => {
    if (locations_enable) {
      dispatch(fetchLocations());
    }
    dispatch(fetchJobTypes(selectedWorkspaceUuid));
    dispatch(fetchJobSubtypes(selectedWorkspaceUuid));
    dispatch(fetchDepots(selectedWorkspaceUuid));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isFetchingLocations && locations?.length) {
      const displayedLocation = selectedLocation ?? locations[0];
      if (locations_enable && displayedLocation) {
        setCoordinates(
          getCoordinatesForNewJob(
            displayedLocation.latitude,
            displayedLocation.longitude,
          ),
        );
      }
    } else if (form?.location?.latitude && form?.location?.longitude) {
      setCoordinates(
        getCoordinatesForNewJob(
          form.location.latitude,
          form.location.longitude,
        ),
      );
    }
  }, [locations, form]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAssigneeChange = (assignees) => {
    setForm({ ...form, assignees: assignees.map((assignee) => assignee.id) });
  };

  const handleSubmit = async () => {
    setFormErrors([]);

    if (isFormValid()) {
      await Job.createJob({
        ...form,
        job_number: enable_auto_work_order_number
          ? formatAutoWorkOrderId()
          : form.job_number,
        workspace_uuid: selectedWorkspaceUuid,
        ...form.location,
      })
        .then((result) => {
          if (result) {
            const { data } = result;
            trackEvent(
              constants.EVENT_CREATE_JOB,
              getCreateJobEventPayload(
                data,
                currentUser,
                view,
                form.assignees,
                selectedTypeTitle,
                selectedSubtypeTitle,
              ),
            );
            navigate(
              `/jobs/${data.id}${
                view !== constants.LEGACY_VIEW ? '/timeline' : ''
              }`,
            );
            dispatch(fetchJobs(filters, view));
          }
        })
        .catch((errorResult) => {
          if (errorResult?.response?.data?.non_field_errors?.length > 0) {
            setFormErrors(errorResult.response.data.non_field_errors);
          } else if (errorResult?.response?.data?.detail) {
            setFormErrors([errorResult?.response?.data?.detail]);
          } else if (errorResult?.response?.data?.message) {
            setFormErrors([errorResult.response.data.message]);
          } else {
            Logger.error(errorResult);
            dispatch(
              setGlobalError(getText('create_job_generic_error_message')),
            );
          }
        });
    }
  };

  return (
    <Container>
      {form && (
        <Form onSubmit={handleSubmit}>
          <CreateJobFormFields
            form={form}
            setForm={setForm}
            errors={errors}
            setErrors={setErrors}
            locations={locations}
            types={jobTypes}
            subtypes={jobSubtypes}
            depots={depots}
            coordinates={coordinates}
            selectedTypeTitle={selectedTypeTitle}
            setSelectedTypeTitle={setSelectedTypeTitle}
            selectedSubtypeTitle={selectedSubtypeTitle}
            setSelectedSubtypeTitle={setSelectedSubtypeTitle}
            customFields={customFields}
          />
          <InputSeparator />
          <TeamWrapper>
            <SubTitle>{getText('create_job_add_members')}</SubTitle>
            <TeamCreate setAssignees={handleAssigneeChange} />
          </TeamWrapper>
          {formErrors.length > 0 && (
            <ErrorContainer>
              <Card.ErrorMessage
                message={formErrors.reduce(
                  (previousErrors, currentError) =>
                    `${previousErrors} ${currentError}`,
                )}
              />
            </ErrorContainer>
          )}
          <Button.Primary
            text={getText('create_job_create_job_title')}
            onClick={handleSubmit}
            disabled={
              (!form.type && !form.postcode && !form.job_number) ||
              (!!customFields.addressLabel && !form.address.trim())
            }
          />
        </Form>
      )}
    </Container>
  );
};

import styled from 'styled-components';
import theme from 'theme';

const { mixins } = theme;

export const Container = styled.div`
  min-height: 25vh;
  overflow-y: auto;
  ${mixins.flexColumn};
`;

export const TeamsWrapper = styled.div`
  margin: 0 auto;
  padding: 40px 5px;
  width: 75%;
  ${mixins.flexColumn};
  row-gap: 15px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`;

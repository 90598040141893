import { RamsPhoto } from './RamsPhoto';
import { Photo } from 'model/Media/Photo';
import { PhotoContainer, PhotoListContainer } from './styled';

// PhotoGrid
export const PhotoGrid = ({ photos }: { photos: Photo[] }): JSX.Element => (
  <PhotoListContainer>
    {photos.map((photo) => (
      <PhotoContainer key={photo.id}>
        <RamsPhoto photo={photo} />
      </PhotoContainer>
    ))}
  </PhotoListContainer>
);

import { Cross, Pencil } from 'assets/icons';
import { Button } from 'components/ui';
import styled from 'styled-components';
import theme from 'theme';
import ItemTitle from '../RiskAssessmentSettings/HazardSettings/components/ItemTitle';
import { WorkspaceItemType } from 'model/AdminPanel/WorkspaceSettings/WorkspaceItemType';
import WorkspaceAssessmentItem from 'model/State/WorkspaceAssessmentItem';

const { colors, mixins } = theme;

const ItemWrapper = styled.div`
  border-top: 1px solid ${colors.grey};
  padding: 12px 0;
  &:last-of-type {
    border-bottom: 1px solid ${colors.grey};
  }
`;

const Container = styled.div`
  ${mixins.flexBetween};
  padding: 5px 0;
`;

const TitleContainer = styled.div`
  width: 75%;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 25%;
`;

const PencilIcon = styled(Pencil)`
  width: 20px;
  height: 20px;
`;
const CrossIcon = styled(Cross)`
  width: 20px;
  height: 20px;
`;

type Props = {
  id: string;
  item: WorkspaceItemType | WorkspaceAssessmentItem;
  onEditClick: () => void;
  onRemoveClick: () => void;
  infoMessage?: string;
};

export const TypeItemRow = ({
  id,
  item,
  onEditClick,
  onRemoveClick,
  infoMessage,
}: Props): JSX.Element => (
  <ItemWrapper id={id}>
    <Container>
      <TitleContainer>
        <ItemTitle item={item} infoMessage={infoMessage} />
      </TitleContainer>
      <ActionButtonContainer>
        <Button.Confirm onClick={onEditClick} IconComponent={PencilIcon} />
        <Button.Confirm onClick={onRemoveClick} IconComponent={CrossIcon} />
      </ActionButtonContainer>
    </Container>
  </ItemWrapper>
);

import { CSSProperties } from 'styled-components';
import { Button, QuestionMarkIcon } from './styled';

type Props = {
  onClick: (event) => void;
  disabled?: boolean;
  extraStyles?: CSSProperties;
};

export const InfoButton = ({
  onClick,
  disabled = false,
  extraStyles,
}: Props): JSX.Element => (
  <Button disabled={disabled} onClick={onClick} style={extraStyles}>
    <QuestionMarkIcon />
  </Button>
);

import { useSelector } from 'react-redux';
import {
  selectUserPermissions,
  selectWorkspaceSettings,
} from 'store/selectors';
import useText from './useText';
import { getDaysBetween } from 'helpers/dates';
import { concatenateLocale } from 'helpers/names';
import { useCallback } from 'react';
import { startOfDay } from 'date-fns';

const useSafetyPrediction = () => {
  const getText = useText();
  const {
    enable_job_safety_prediction: workspaceSettingsEnabled,
    job_safety_prediction_threshold: highRiskThreshold,
    psi_review_interval: reviewInterval,
  } = useSelector(selectWorkspaceSettings);
  const userPermissions = useSelector(selectUserPermissions);
  const { latestPsiReview, isPsiDismissed } = useSelector(
    (state) => state.user,
  );

  const hasUserPermission =
    userPermissions?.length &&
    userPermissions.includes('show_job_safety_prediction_v1');

  const canUseSafetyPrediction =
    workspaceSettingsEnabled && highRiskThreshold && hasUserPermission;

  const LIST_FORMAT_LOCALE = 'en-GB';

  function capitalise(string) {
    return string && string[0].toUpperCase() + string.slice(1);
  }

  function getFormattedScore(riskPredictionValue) {
    return `${parseFloat((riskPredictionValue * 100).toFixed(2))}/100`;
  }

  const getRiskWarning = useCallback(
    (riskPrediction = {}) => {
      if (!canUseSafetyPrediction || !riskPrediction) {
        return null;
      }

      const {
        risk_environment,
        risk_injury,
        risk_property,
        risk_public,
        risk_supply,
      } = riskPrediction;

      function getPredictionsSortedByRiskDescending(riskPredictions) {
        return Object.entries(riskPredictions)
          .filter((pair) => pair[1] >= highRiskThreshold)
          .sort((a, b) => b[1] - a[1])
          .map((pair) => ({ key: pair[0], value: pair[1] }));
      }

      const overThresholdDescending = getPredictionsSortedByRiskDescending({
        risk_environment,
        risk_injury,
        risk_property,
        risk_public,
        risk_supply,
      });

      if (!overThresholdDescending.length) {
        return null;
      }

      const riskiestPrediction = overThresholdDescending[0];

      const RISK_TYPE_TRANSLATION_BY_KEY = {
        risk_injury: 'job_overview_safety_prediction_risk_type_injury',
        risk_public: 'job_overview_safety_prediction_risk_type_public',
        risk_property: 'job_overview_safety_prediction_risk_type_property',
        risk_environment:
          'job_overview_safety_prediction_risk_type_environment',
        risk_supply: 'job_overview_safety_prediction_risk_type_supply',
      };

      function formatLocale(riskTypes) {
        const concatenated = concatenateLocale(LIST_FORMAT_LOCALE, riskTypes);

        return capitalise(concatenated);
      }

      return {
        type: formatLocale(
          overThresholdDescending.map((prediction) =>
            getText(RISK_TYPE_TRANSLATION_BY_KEY[prediction.key]),
          ),
        ),
        advice: getText('job_overview_safety_prediction_advice'),
        score: getFormattedScore(riskiestPrediction.value),
        scoreLabel: getText(
          'job_overview_safety_prediction_warning_risk_score',
        ),
        message: getText('job_overview_safety_prediction_warning_high_risk'),
      };
    },
    [canUseSafetyPrediction, highRiskThreshold, getText],
  );

  function isFeedbackNeeded(usersLastReviewedPsi, riskPrediction = {}) {
    const riskWarning = getRiskWarning(riskPrediction);
    if (
      !riskWarning ||
      riskPrediction.id === usersLastReviewedPsi ||
      isPsiDismissed
    ) {
      return false;
    }
    if (!latestPsiReview) {
      return true;
    }
    return (
      (latestPsiReview &&
        reviewInterval &&
        getDaysBetween(latestPsiReview, startOfDay(new Date())) >=
          reviewInterval) ||
      false
    );
  }

  function getHighPredictions(riskPrediction = {}) {
    if (!riskPrediction) {
      return {};
    }
    const {
      risk_environment,
      risk_injury,
      risk_property,
      risk_public,
      risk_supply,
    } = riskPrediction;

    return Object.entries({
      risk_environment,
      risk_injury,
      risk_property,
      risk_public,
      risk_supply,
    }).reduce(
      (o, risk) => ({ ...o, [risk[0]]: risk[1] >= highRiskThreshold }),
      {},
    );
  }

  return { getRiskWarning, isFeedbackNeeded, getHighPredictions };
};

export default useSafetyPrediction;

export const convertFraTypeToNumber = (type) => {
  if (type === 'fra-16') {
    return `16`;
  }
  if (type === 'fra-14') {
    return `14`;
  }
  if (type === 'fra-12-follow-up') {
    return `12`;
  }
  return `11`;
};

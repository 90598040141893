import { PlusIcon } from 'assets/icons';
import { useText } from 'hooks';
import RootState from 'model/State/RootState';
import { ModalType } from 'model/enum/ModalType';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { selectWorkspaceSettings } from 'store/selectors';
import styled from 'styled-components';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';

const { colors, media, fonts, mixins } = theme;

const AddButton = Analytics.withTrackEvent(styled(Link)`
  width: 55px;
  height: 55px;
  box-sizing: border-box;
  background-color: ${colors.yellow};
  cursor: pointer;
  display: block;
  ${mixins.flexFullCenter};
  outline: 2px solid ${colors.black};
  ${media.xl`
    width: 45px;
    height: 45px;
  `}
  ${media.sm`
    width: 100%;
    ${mixins.flexBetween};
    padding: 0 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
  `}

  &:hover {
    outline: 3px solid ${colors.black};
  }

  &:focus-within {
    outline: 3px solid ${colors.orange};
  }
`);

const JobButtonTitle = styled.div`
  display: none;
  font-family: ${fonts.bold};
  ${media.sm`
    display: block;
  `}
`;

const StyledPlusIcon = styled(PlusIcon)`
  height: 18px;
`;

type Props = {
  view: string;
};

const AddJobButton = ({ view }: Props): JSX.Element | null => {
  const getText = useText();
  const { currentUser } = useSelector((state: RootState) => state.session);
  const { add_job_web_enabled } = useSelector(selectWorkspaceSettings);
  const location = useLocation();

  if (currentUser && add_job_web_enabled) {
    return (
      <AddButton
        event={constants.EVENT_VIEW_CREATE_JOB}
        to={`${location.pathname}?modal=${ModalType.CREATE_JOB}`}
        eventProperties={{
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        }}
      >
        <JobButtonTitle>{getText('jobs_search_create_job')}</JobButtonTitle>
        <StyledPlusIcon />
      </AddButton>
    );
  }
  return null;
};

export default AddJobButton;

import { Evidence, Warning, Permit, Arrow } from 'assets/icons';
import { Storage } from 'aws-amplify';
import { Media as MediaIcon } from 'components/ui';
import { Media } from 'helpers';
import { useQueryParams } from 'hooks';
import { useEffect, useState, useMemo } from 'react';
import * as Analytics from 'utils/analytics';
import { Message } from 'model/Message';
import JobData from 'model/JobData';

import { MessageWithAction } from './MessageWithAction';

import { ThumbnailContainer, Placeholder } from './styled';

type Props = {
  message: Message;
  activeJob: JobData;
  onClick: () => void;
};

export const JobForm = Analytics.withTrackEvent(
  ({ message, activeJob, onClick }: Props): JSX.Element => {
    const thumbnail = useMemo(() => {
      return message.target?.videos ? Media.getThumbnail(message.target) : null;
    }, [message]);

    const [thumbnailURL, setThumbnailURL] = useState<string>('');
    const { getPathWithQueryParams } = useQueryParams();

    useEffect(() => {
      (async () => {
        if (thumbnail) {
          const signedURL = await Storage.get(thumbnail);
          setThumbnailURL(signedURL);
        }
      })();
    }, [thumbnail]);

    const type = message.target?.type?.title;
    const buttonLogo = message.target?.type?.button_logo;
    const to = getPathWithQueryParams(
      `/jobs/${activeJob.id}/job-forms/${message.target.id}`,
    );

    const getActionComponent = () => {
      switch (buttonLogo) {
        case 'stamp':
          return <Permit />;
        case 'warning':
          return <Warning />;
        default:
          return <Evidence />;
      }
    };

    return (
      <MessageWithAction
        message={message}
        onClick={onClick}
        to={to}
        type={type}
        actionIcon={getActionComponent()}
        viewIcon={<Arrow />}
      >
        {thumbnail && (
          <ThumbnailContainer>
            {thumbnailURL ? (
              <MediaIcon
                type='photo'
                kind='job-form'
                url={thumbnailURL}
                photoCover
                qualityIndicator={message?.target?.videos[0]?.qualityIndicator}
              />
            ) : (
              <Placeholder />
            )}
          </ThumbnailContainer>
        )}
      </MessageWithAction>
    );
  },
);

import { Cross, Search, ChevronDown } from 'assets/icons';
import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Select as MaterialSelect, MenuItem } from '@mui/material';
import * as Analytics from 'utils/analytics';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const Form = styled.form`
  min-height: 65px;
  display: flex;
  border-top: 2px solid ${colors.grey};
  background-color: ${colors.lightGrey};
`;

export const Textarea = styled.textarea`
  flex: 1;
  margin: 5px;
  padding: 5px 10px;
  border: none;
  resize: none;
  overflow: hidden;
  font-size: ${fontSizes.default};
  &:focus {
    outline: none;
  }
`;

export const SubmitButton = styled.button`
  width: 65px;
  background-color: ${colors.yellow};
  border: none;
  cursor: pointer;
  ${mixins.flexFullCenter};
`;

export const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: 23px;
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.div`
  color: ${colors.white};
  margin: 5px 0;
`;

export const InputWrapper = styled.div`
  margin-bottom: 20px;
`;

export const FormError = styled.div`
  color: ${colors.darkRed};
`;

export const SearchForm = styled.form`
  ${mixins.flexColumn};
  width: 100%;
`;

export const Label = styled.label`
  font-family: ${fonts.bold};
  margin-bottom: 8px;
  color: ${colors.black};
  ${media.sm`
    color: ${colors.white};
  `}
`;

export const InputContainer = styled.div`
  display: flex;
  height: 55px;
  width: 100%;
  outline: 2px solid ${colors.black};
  ${media.xl`
     height: 45px;
   `}
  &:hover {
    outline: 3px solid ${colors.black};
  }

  input {
    border: none;
  }

  button {
    border: none;
  }
`;

export const Input = styled.input<{ $width?: string }>`
  flex: 1;
  padding: 5px 5px 5px 10px;
  outline: none;
  font-size: ${fontSizes.m};
  width: ${({ $width }) => $width};
  ${media.xl`
    font-size: ${fontSizes.default};
  `}
  ${media.lg`
    font-size: ${fontSizes.s};
  `}
  ${media.sm`
    border: none;
  `}
`;

export const Button = Analytics.withTrackEvent(styled.button`
  height: 55px;
  width: 55px;
  background-color: ${({ disabled }) =>
    disabled ? colors.lightGrey : colors.yellow};

  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${mixins.flexFullCenter};
  ${media.xl`
      height: 45px;
      width: 45px;
    `}
  ${media.sm`
      border: none;
    `}
`);

export const SearchIcon = styled(Search)`
  height: 23px;
  width: 23px;
  .fill-selector {
    fill: ${({ disabled }) => (disabled ? colors.darkGrey : colors.black)};
  }
`;

export const CrossIcon = styled(Cross)`
  height: 20px;
  .fill-selector {
    fill: ${colors.darkGrey};
  }
`;

export const CrossWrapper = styled.div`
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  cursor: pointer;
  ${media.sm`
    border: none;
  `}
`;

export const Chevron = styled(({ isMobile, ...rest }) => (
  <ChevronDown {...rest} />
))`
  width: 12px;
  height: 14px;
  position: absolute;
  right: 0;
  pointer-events: none;
  .fill-selector {
    fill: ${({ disabled, isMobile }) => {
      if (!isMobile) {
        return disabled ? colors.grey : colors.black;
      }
      return disabled ? colors.grey : colors.white;
    }};
  }
`;

export const StyledSelect = styled(({ disabled, isMobile, ...rest }) => (
  <MaterialSelect disabled={disabled} {...rest} />
))`
  &.MuiInputBase-root {
    width: 100%;
    font-size: ${fontSizes.default};
    color: ${({ disabled, isMobile }) => {
      if (isMobile) {
        return disabled ? colors.darkGrey : colors.white;
      }
      return disabled ? colors.darkGrey : colors.black;
    }};
    font-family: ${fonts.bold};
    &:hover {
      box-shadow: none;
      border: 0;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
    .MuiOutlinedInput-input.Mui-disabled {
      cursor: not-allowed;
      -webkit-text-fill-color: ${colors.grey};
    }
    .MuiMenu-list {
      padding: 0;
    }
  }
`;

export const StyledOptions = styled(({ isMobile, ...rest }) => (
  <MenuItem {...rest} />
))`
  &.MuiMenuItem-root {
    height: 45px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    font-size: ${fontSizes.default};
    font-family: ${fonts.bold};
    color: ${({ isMobile }) => (isMobile ? colors.white : colors.black)};
    &.Mui-selected {
      background-color: ${colors.white} !important;
    }
    &.Mui-disabled {
      color: ${colors.grey};
      opacity: 1;
    }
    &:hover {
      background-color: ${colors.lightGrey} !important;
      color: ${colors.black};
    }
  }
`;

export const SelectValue = styled.div`
  font-family: ${fonts.default};
`;

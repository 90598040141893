import { useState, useEffect } from 'react';
import * as constants from 'utils/constants';
import { Assessment } from 'api';
import { Button, Modal } from 'components/ui';
import { useText } from 'hooks';
import { useSelector } from 'react-redux';
import { selectJobsView } from 'store/selectors';
import { EVENT_RAMS_ADD_TEMPLATE_BUTTON_CLICKED } from '../constants';
import {
  ButtonContainer,
  TemplateContainer,
  TemplateItem,
  TemplateSectionWrapper,
  Title,
} from './styled';
import { MethodStatementSectionTemplate } from 'model/Assessment/RAMS';

type Props = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  onTemplateSectionAdd: (value: MethodStatementSectionTemplate) => void;
};

// TemplateSectionModal
export const TemplateSectionModal = ({
  showModal,
  setShowModal,
  onTemplateSectionAdd,
}: Props): JSX.Element => {
  const view = useSelector(selectJobsView);
  const getText = useText();
  const [templates, setTemplates] = useState<MethodStatementSectionTemplate[]>(
    [],
  );
  const [selectedTemplate, setSelectedTemplate] =
    useState<MethodStatementSectionTemplate | null>(null);

  useEffect(() => {
    (async () => {
      const data = await Assessment.getTemplates();
      setTemplates(data);
    })();
  }, []);

  const onTemplateClick = (template: MethodStatementSectionTemplate) => {
    setSelectedTemplate(template === selectedTemplate ? null : template);
  };

  return (
    <Modal.Base
      isOpen={showModal}
      title={getText('rams_section_method_apply_template')}
      onClose={() => setShowModal(false)}
      width='50%'
    >
      <TemplateSectionWrapper>
        <Title>{getText('rams_section_method_choose_template')}</Title>
        <TemplateContainer>
          {templates?.map((template) => (
            <TemplateItem
              key={template?.name}
              selected={selectedTemplate === template}
              onClick={() => onTemplateClick(template)}
            >
              {template.name}
            </TemplateItem>
          ))}
        </TemplateContainer>
        <ButtonContainer>
          <Button.Primary
            onClick={() => {
              if (selectedTemplate) {
                onTemplateSectionAdd(selectedTemplate);
              }
            }}
            text={getText('rams_section_method_add_template')}
            width='240px'
            disabled={!selectedTemplate}
            event={EVENT_RAMS_ADD_TEMPLATE_BUTTON_CLICKED}
            eventProperties={{
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
          />
        </ButtonContainer>
      </TemplateSectionWrapper>
    </Modal.Base>
  );
};

import styled from 'styled-components';
import theme from 'theme';

const { fontSizes, fonts } = theme;

export const Title = styled.div<{ variant?: string }>`
  font-size: ${fontSizes.default};
  font-family: ${fonts.bold};
  margin: 20px 0;
`;

export const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  padding: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  mapToEvidence,
  mapToEvidenceType,
  mapToEvidenceUpdateResponse,
} from './helpers';
import { Evidence } from 'model/Evidence/Evidence';
import { Video } from 'model/Media/Video';
import { mapToPhoto, mapToVideo } from 'api/Media/helpers';
import { UploadStatus } from 'model/enum/UploadStatus';
import { Photo } from 'model/Media/Photo';
import { EvidenceType } from 'model/Evidence/EvidenceType';
import { EvidenceUpdateResponse } from 'model/Evidence/EvidenceUpdateResponse';

export const get = async (id: string): Promise<Evidence> => {
  const { data } = await axios.get(`/evidence/${id}/`);
  return mapToEvidence(data);
};

export const getAll = async (jobId: string): Promise<Evidence[]> => {
  const { data } = await axios.get(`/jobs/${jobId}/evidence/`, {
    headers: {
      Accept: 'application/json; version=2;',
    },
  });
  return data.results.map(mapToEvidence);
};

export const create = async (
  jobId: string,
  type: number,
  comments: string,
): Promise<Evidence> => {
  const { data } = await axios.post(
    `/jobs/${jobId}/evidence/`,
    {
      type_id: type,
      comments,
      uuid: uuidv4(),
    },
    {
      headers: {
        Accept: 'application/json; version=2',
      },
    },
  );
  return mapToEvidence(data);
};

export const updateEvidence = async ({
  evidenceId,
  comments,
  videoFeedback,
  videoFeedbackReason,
}: {
  evidenceId: string;
  comments?: string;
  videoFeedback?: number | null;
  videoFeedbackReason?: string;
}): Promise<EvidenceUpdateResponse> => {
  const { data } = await axios.put(`/evidence/${evidenceId}/`, {
    ...(comments && { comments }),
    ...(videoFeedback !== null && { video_feedback: videoFeedback }),
    ...(videoFeedbackReason && { video_feedback_reason: videoFeedbackReason }),
  });
  return mapToEvidenceUpdateResponse(data);
};

export const createVideo = async (evidenceId: number): Promise<Video> => {
  const { data } = await axios.post(`/evidence/${evidenceId}/videos/`);
  return mapToVideo(data);
};

export const createPhoto = async (
  evidenceId: number,
  photoCount: number,
): Promise<Photo[]> => {
  const { data } = await axios.post(
    `/evidence/${evidenceId}/photos/`,
    {
      photos_count: photoCount,
    },
    {
      headers: {
        Accept: 'application/json; version=2',
      },
    },
  );
  return data.photos.map(mapToPhoto);
};

export const updateVideo = async (
  evidenceId: number,
  fileId: string,
  status: UploadStatus,
): Promise<Video> => {
  const { data } = await axios.put(
    `/evidence/${evidenceId}/videos/${fileId}/`,
    {
      status,
    },
  );
  return mapToVideo(data);
};

export const updatePhoto = async (
  evidenceId: number,
  fileId: string,
  status: UploadStatus,
): Promise<Photo> => {
  const { data } = await axios.put(
    `/evidence/${evidenceId}/photos/${fileId}/`,
    {
      status,
    },
  );
  return mapToPhoto(data);
};

export const getEvidencePhotos = async (jobId: string): Promise<Photo[]> => {
  const { data } = await axios.get(`/jobs/${jobId}/evidence-photos/`);
  return data.results.map(mapToPhoto);
};

export const getEvidenceTypes = async (
  workspaceUuid: string,
): Promise<EvidenceType[]> => {
  const { data } = await axios.get(`/evidence-types/`, {
    params: { ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}) },
  });
  return data.results.map(mapToEvidenceType);
};

import { Dates } from 'helpers';
import { Permit as PermitIcon } from 'assets/icons';
import * as Analytics from 'utils/analytics';
import { useText } from 'hooks';
import { User } from 'model/User';
import {
  CardContainer,
  CreationDate,
  Creator,
  EyeIcon,
  FlexCenterContainer,
  Icon,
  Subtype,
  Type,
} from './styled';

type Props = {
  type: string;
  subtype: string;
  creator: User;
  date: string;
  to: string;
  customMargin?: string;
};

// Card.Permit
export const Permit = Analytics.withTrackEvent(
  ({
    type,
    subtype,
    creator,
    date,
    to,
    customMargin,
    ...props
  }: Props): JSX.Element => {
    const getText = useText();
    return (
      <CardContainer to={to} customMargin={customMargin} {...props}>
        <FlexCenterContainer>
          <Icon component={<PermitIcon />} $marginRight={20} />
          <div>
            <Type>{type}</Type>
            <Subtype>{subtype}</Subtype>
          </div>
        </FlexCenterContainer>

        <FlexCenterContainer>
          <Creator>{creator.fullName}</Creator>

          <CreationDate $marginLeft={50} $marginRight={25}>
            {Dates.formatDateTime(
              date,
              getText('date_today'),
              getText('date_yesterday'),
            )}
          </CreationDate>

          <EyeIcon />
        </FlexCenterContainer>
      </CardContainer>
    );
  },
);

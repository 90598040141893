import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, media, mixins } = theme;

export const DataRow = styled.div`
  height: 650px;
  display: flex;
  background: ${colors.white};
  padding: 32px;
  ${media.lg`
    padding: 16px;
  `};
`;

export const StyledDataGrid = styled(({ isEmpty, columns, ...rest }) => (
  <DataGrid columns={columns} {...rest} />
))`
  &.MuiDataGrid-root {
    border: 0;
    .MuiDataGrid-columnHeaders {
      border-bottom: 2px solid ${colors.black};
    }
    .MuiDataGrid-columnHeader--moving {
      background-color: ${colors.white};
    }
    .MuiDataGrid-columnHeaders {
      color: ${colors.black};
      font-family: ${fonts.bold};
      width: 100%;
      > div {
        width: 100%;
      }
    }
    .MuiDataGrid-columnHeader {
      padding: 0 5px;
      &:focus,
      :focus-within {
        outline: 0;
      }
    }
    .MuiDataGrid-columnHeader--sortable .MuiDataGrid-sortIcon {
      opacity: 1 !important;
    }
    .MuiDataGrid-iconButtonContainer {
      visibility: visible;
    }
    .MuiDataGrid-columnHeaderTitleContainer {
      white-space: unset;
      .MuiDataGrid-columnHeaderTitleContainerContent {
        width: 100%;
      }
    }
    .MuiDataGrid-columnHeaderTitle {
      white-space: unset;
      word-break: break-word;
      line-height: normal;
    }
    .MuiDataGrid-row {
      box-sizing: border-box;
      border-bottom: 1px solid ${colors.grey};
      width: 100%;
      min-height: 80px !important;
      padding: 5px 0;
      position: relative;
    }

    .MuiDataGrid-cell:focus,
    .MuiDataGrid-cell:focus-within,
    .MuiDataGrid-cell:focus-visible {
      outline: none;
    }

    .MuiDataGrid-cell {
      color: ${colors.darkGrey};
      font-family: ${fonts.default};
      border: 0;
      padding: 0 5px;
      ${mixins.flexCenter};
      &.name-cell {
        min-width: 17.5% !important;
        max-width: 17.5% !important;
      }
      &.role-cell {
        min-width: 15% !important;
        max-width: 15% !important;
      }
      &.depot-cell {
        min-width: 12.5% !important;
        max-width: 12.5% !important;
      }
      &.date-cell {
        min-width: 10% !important;
        max-width: 10% !important;
      }
      &.phone-cell {
        min-width: 12.5% !important;
        max-width: 12.5% !important;
      }
      &.active-cell {
        min-width: 10% !important;
        max-width: 10% !important;
      }
      &.actions-cell {
        min-width: 22.5% !important;
        max-width: 22.5% !important;
      }
    }

    .MuiDataGrid-row.Mui-selected,
    .MuiDataGrid-row.Mui-selected:hover {
      background-color: ${colors.offWhite};
    }
    .name-cell {
      min-width: 17.5% !important;
      max-width: 17.5% !important;
    }
    .role-cell {
      min-width: 15% !important;
      max-width: 15% !important;
    }
    .depot-cell {
      min-width: 12.5% !important;
      max-width: 12.5% !important;
    }
    .date-cell {
      min-width: 10% !important;
      max-width: 10% !important;
    }
    .phone-cell {
      min-width: 12.5% !important;
      max-width: 12.5% !important;
    }
    .active-cell {
      min-width: 10% !important;
      max-width: 10% !important;
    }
    .actions-cell {
      min-width: 22.5% !important;
      max-width: 22.5% !important;
    }
    .MuiDataGrid-withBorder {
      border: 0;
    }
    .MuiDataGrid-virtualScrollerRenderZone {
      width: 100%;
    }
    .MuiDataGrid-scrollbar {
      overflow-x: hidden;
      overflow-y: auto !important;
      background-color: ${colors.white};

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 12px;
      }
      ::-webkit-scrollbar-thumb {
        min-height: 80px;
        background-color: ${colors.grey};
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
    .MuiDataGrid-iconSeparator {
      display: none;
    }
  }
`;

export const StatusText = styled.span`
  padding-left: 5px;
`;

export const ActionButtonContainer = styled.div`
  width: 100%;
  ${mixins.flexCenter};
  ${media.xl`
    ${mixins.flexColumn};
  `}
`;

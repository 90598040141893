import styled from 'styled-components';
import { TextField } from '@mui/material';
import theme from 'theme';

const { colors } = theme;

export const StyledTextField = styled(TextField)<{
  disabled: boolean;
  $hasError: string | boolean;
  $hasBlackBackground?: boolean;
}>`
  &.MuiTextField-root {
    background-color: ${colors.white};
    width: 100%;
    outline: 3px solid
      ${({ disabled, $hasError }) => {
        if (disabled) {
          return colors.grey;
        }

        if ($hasError) {
          return colors.red;
        }
        return colors.black;
      }};
    transition: ease-out 0.2s;
    &:hover {
      box-shadow: none;
      outline: 3px solid
        ${({ $hasBlackBackground, disabled, $hasError }) => {
          if ($hasError) {
            return colors.red;
          }

          if ($hasBlackBackground) {
            if (disabled) {
              return colors.black;
            }

            return colors.grey;
          }

          if (disabled) {
            return colors.grey;
          }

          return colors.black;
        }};
      transition: ease-out 0.2s;
    }

    &:focus-within {
      box-shadow: none;
      outline: 3px solid
        ${({ disabled, $hasError }) => {
          if (disabled) {
            return colors.grey;
          }

          if ($hasError) {
            return colors.red;
          }

          return colors.orange;
        }};
      transition: ease-out 0.2s;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
    .MuiIconButton-root {
      width: 32px;
      height: 32px;
    }
  }
`;

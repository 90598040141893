import { ColumnContainer } from '../styled';
import {
  ArrowRight,
  MenuButton,
  MenuButtonContainer,
  Subtitle,
  Title,
} from './styled';

type Props = {
  title: string;
  onClick: () => void;
  subtitle?: string;
  disabled?: boolean;
  StatusTag?: JSX.Element | null;
};

export const SectionButton = ({
  title,
  onClick,
  subtitle,
  disabled = false,
  StatusTag,
}: Props): JSX.Element => {
  return (
    <MenuButtonContainer>
      <MenuButton disabled={disabled} onClick={onClick}>
        <ColumnContainer>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </ColumnContainer>
        <ArrowRight disabled={disabled} />
      </MenuButton>
      {StatusTag}
    </MenuButtonContainer>
  );
};

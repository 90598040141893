import { useJobs, useText } from 'hooks';
import RootState from 'model/State/RootState';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'components/ui';
import styled from 'styled-components';
import theme from 'theme';
import { LEGACY_VIEW } from 'utils/constants';

const { colors } = theme;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const WarningMessage = styled.div`
  z-index: 99;
  color: ${colors.red};
  padding-top: 5px;
  position: absolute;
  top: 100%;
`;

type Props = {
  view: string;
  extraStyles?: React.CSSProperties;
};

const JobSearch = ({ view, extraStyles }: Props): JSX.Element => {
  const getText = useText();
  const { filters } = useSelector((state: RootState) => state.jobs);
  const { setFilters } = useJobs();
  const [showError, setShowError] = useState<boolean>(false);

  const searchLabel = getText('jobs_search_search_jobs');

  const handleSearch = (q) => {
    if (view === LEGACY_VIEW) {
      setFilters({
        ...filters,
        scope: null,
        q,
        has_unresolved_vra: false,
        has_unresolved_blocker: false,
      });
    } else {
      setFilters({ ...filters, q });
    }
  };

  const resetSearch = () => {
    setFilters({ ...filters, q: '' });
    setShowError(false);
  };

  const onSearch = (search) => {
    if (search.length === 1) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  return (
    <SearchContainer style={extraStyles}>
      <Form.Search
        view={view}
        label={view === LEGACY_VIEW ? searchLabel : ''}
        placeholder={getText('jobs_search_hint_edit_text')}
        initialValue={filters.q}
        onSubmit={handleSearch}
        resetSearch={resetSearch}
        onSearch={onSearch}
        inputWidth={view === LEGACY_VIEW ? '80%' : '90%'}
      />

      {showError && (
        <WarningMessage>
          {getText('jobs_search_more_character_warning')}
        </WarningMessage>
      )}
    </SearchContainer>
  );
};

export default JobSearch;

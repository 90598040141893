import { WorkspaceSettings } from 'model/AdminPanel/WorkspaceSettings/WorkspaceSettings';
import { BackendWorkspaceItemType, BackendWorkspaceSettings } from './types';
import { WorkspaceItemType } from 'model/AdminPanel/WorkspaceSettings/WorkspaceItemType';

export const mapToWorkspaceSettings = (
  backendWorkspaceSettings: any,
): WorkspaceSettings => ({
  vraSignoffRequired: backendWorkspaceSettings.vra_signoff_required ?? false,
  userSignOnRequired: backendWorkspaceSettings.user_sign_on_required ?? false,
  magicLinkLoginEnabled:
    backendWorkspaceSettings.magic_link_login_enabled ?? false,
  devicelessDigitalSignature:
    backendWorkspaceSettings.deviceless_digital_signature ?? false,
  addressLabel: backendWorkspaceSettings.address_label ?? '',
  addressLabelEs: backendWorkspaceSettings.address_label_es ?? '',
  jobSubtypeLabel: backendWorkspaceSettings.job_subtype_label ?? '',
  jobSubtypeLabelEs: backendWorkspaceSettings.job_subtype_label_es ?? '',
  originatingNumberLabel:
    backendWorkspaceSettings.originating_number_label ?? '',
  originatingNumberLabelEs:
    backendWorkspaceSettings.originating_number_label_es ?? '',
  postcodeLabel: backendWorkspaceSettings.postcode_label ?? '',
  postcodeLabelEs: backendWorkspaceSettings.postcode_label_es ?? '',
  projectNumberLabel: backendWorkspaceSettings.project_number_label ?? '',
  projectNumberLabelEs: backendWorkspaceSettings.project_number_label_es ?? '',
  workOrderNumberLabel: backendWorkspaceSettings.work_order_number_label ?? '',
  workOrderNumberLabelEs:
    backendWorkspaceSettings.work_order_number_label_es ?? '',
});

export const mapToBackendWorkspaceSettings = (
  input: any,
): BackendWorkspaceSettings => ({
  vra_signoff_required: input?.vraSignoffRequired,
  user_sign_on_required: input?.userSignOnRequired,
  magic_link_login_enabled: input?.magicLinkLoginEnabled,
  deviceless_digital_signature: input?.devicelessDigitalSignature,
  address_label: mapToBackendField(input?.addressLabel),
  address_label_es: mapToBackendField(input?.addressLabelEs),
  job_subtype_label: mapToBackendField(input?.jobSubtypeLabel),
  job_subtype_label_es: mapToBackendField(input?.jobSubtypeLabelEs),
  originating_number_label: mapToBackendField(input?.originatingNumberLabel),
  originating_number_label_es: mapToBackendField(
    input?.originatingNumberLabelEs,
  ),
  postcode_label: mapToBackendField(input?.postcodeLabel),
  postcode_label_es: mapToBackendField(input?.postcodeLabelEs),
  project_number_label: mapToBackendField(input?.projectNumberLabel),
  project_number_label_es: mapToBackendField(input?.projectNumberLabelEs),
  work_order_number_label: mapToBackendField(input?.workOrderNumberLabel),
  work_order_number_label_es: mapToBackendField(input?.workOrderNumberLabelEs),
});

// We only want to send the changed fields,
// If field is not changed keep it undefined
// If field is cleared, instead of empty string send null
// If field is modified but not cleared, send the new string
const mapToBackendField = (input: string | undefined) =>
  input === undefined ? undefined : input || null;

export const mapToWorkspaceItemType = (
  backendItemType: any,
): WorkspaceItemType => ({
  id: backendItemType.id,
  title: backendItemType.title,
  titleEs: backendItemType.title_es ?? '',
  status: undefined,
  unitTitle: backendItemType.unit_title,
  unitTitleEs: backendItemType.unit_title_es,
  organisation: backendItemType.organisation,
  workspace: backendItemType.workspace ?? null,
  jobType: backendItemType.job_type,
  jobBlocker: backendItemType.job_blocker,
});

export const mapToBackendWorkspaceItemType = (
  workspaceItemType: WorkspaceItemType,
): BackendWorkspaceItemType => ({
  id: workspaceItemType.id,
  title: workspaceItemType.title,
  title_es: workspaceItemType.titleEs || undefined,
  unit_title: workspaceItemType.unitTitle || undefined,
  unit_title_es: workspaceItemType.unitTitleEs || undefined,
  organisation: workspaceItemType.organisation,
  workspace: workspaceItemType.workspace ?? null,
  job_type: workspaceItemType.jobType ?? undefined,
  job_blocker: workspaceItemType.jobBlocker ?? undefined,
});

import { User } from 'api';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  FETCH_MORE_USERS,
  FETCH_TEAMS,
  FETCH_USERS,
} from 'store/actions/actionTypes';
import { setGlobalError } from 'store/slices/notifications';
import {
  appendUsers,
  fetchTeamsError,
  fetchTeamsSuccess,
  setUsers,
} from 'store/actions/user';
import * as Logger from 'utils/logger';

function* fetchTeams() {
  try {
    const { selectedWorkspace } = yield select((state) => state.session);

    const data = yield User.getTeams(selectedWorkspace?.uuid);
    yield put(fetchTeamsSuccess(data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchTeamsError());
    yield put(setGlobalError(err));
  }
}

function* fetchUsers({ search, pageSize, depotId, roles }) {
  const { selectedWorkspace } = yield select((state) => state.session);
  try {
    const { data } = yield User.getAll(
      selectedWorkspace?.uuid,
      search,
      pageSize,
      depotId,
      null,
      roles,
    );
    yield put(setUsers(data.results, data.next));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

function* fetchMoreUsers({ url }) {
  try {
    const { data } = yield User.getNext(url);
    yield put(appendUsers(data.results, data.next));
  } catch (err) {
    yield put(setGlobalError(err));
    console.error(err);
  }
}

export default function* userWatcher() {
  yield takeLatest(FETCH_TEAMS, fetchTeams);
  yield takeLatest(FETCH_USERS, fetchUsers);
  yield takeLatest(FETCH_MORE_USERS, fetchMoreUsers);
}

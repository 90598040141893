import { FyldScreen } from 'model/enum/FyldScreen';
import { AWSRegion, Regex } from 'utils/constants';
import { capitalizeFirstLetter } from './names';

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const updateArray = (array, atIndex, currentIndex, object) => {
  const copiedArray = [...array];
  copiedArray.splice(currentIndex, 1);
  copiedArray.splice(atIndex, 0, object);

  return copiedArray;
};

export const mergeArraysWithoutDuplicate = (array1, array2, uniqueField) => {
  const filteredArray = array2.filter(
    (item2) =>
      !array1.some((item1) => item1?.[uniqueField] === item2?.[uniqueField]),
  );
  return [...array1, ...filteredArray];
};

export const hasSameItems = (array1, array2) => {
  return (
    array1?.length === array2?.length &&
    array1.every((item) => array2.find((element) => element.id === item.id))
  );
};

export const getLabelByLocale = (appLanguage, labelOptions) => {
  const defaultLabel = Object.values(labelOptions)[0];
  const label = Object.entries(labelOptions).find(([key]) =>
    key.endsWith(`${capitalizeFirstLetter(appLanguage)}`),
  )?.[1];
  return label || defaultLabel;
};

export const isValidEmail = (email) => Regex.email.test(email);
export const isValidPostcode = (postcode) => Regex.postcode.test(postcode);

export const isBlank = (text) => !text || /^\s*$/.test(text);

export const getScreen = (path) => {
  if (path.endsWith('/insights')) {
    return FyldScreen.INSIGHTS;
  } else if (path.endsWith('/fatigue-manager')) {
    return FyldScreen.FATIGUE_MANAGER;
  }
  return FyldScreen.JOBS;
};

function getServerBox() {
  switch (process.env.REACT_APP_AWS_REGION) {
    case AWSRegion.EU_WEST_1:
      return {
        longitude: { lower: -11, upper: 37 },
        latitude: { lower: 35, upper: 64 },
      };

    case AWSRegion.US_EAST_1:
      return {
        longitude: { lower: -126, upper: -33 },
        latitude: { lower: null, upper: null },
      };

    case AWSRegion.AP_SOUTHEAST_2:
      return {
        longitude: { lower: 109, upper: 180 },
        latitude: { lower: null, upper: null },
      };

    default:
      return {
        longitude: { lower: null, upper: null },
        latitude: { lower: null, upper: null },
      };
  }
}

function getStandardDeviationBounds(arr) {
  let lower = null;
  let upper = null;
  const values = arr
    .map((item) => parseFloat(item))
    .filter((item) => !Number.isNaN(item));

  if (!values.length) {
    return { lower, upper };
  }
  // Calculate the average (mean) of the array
  const average = values.reduce((sum, num) => sum + num, 0) / values.length;

  // Calculate the standard deviation
  const squaredDifferences = values.map((num) => Math.pow(num - average, 2));
  const variance =
    squaredDifferences.reduce((sum, squaredDiff) => sum + squaredDiff, 0) /
    values.length;
  const standardDeviation = Math.sqrt(variance);

  // Calculate the range
  const range = 3 * standardDeviation;

  // Calculate the lower and upper bounds
  lower = average - range;
  upper = average + range;

  return { lower, upper };
}

function isWithin(value, bound) {
  if (bound.lower === null || bound.upper === null) {
    return true;
  }
  return value >= bound.lower && value <= bound.upper;
}

function isWithinBounds(box, job) {
  const { latitude, longitude } = job;
  return isWithin(latitude, box.latitude) && isWithin(longitude, box.longitude);
}

export function getValidJobCoordinates(jobs) {
  if (!jobs?.length) {
    return [];
  }

  const jobsWithCoordinates = jobs.filter(
    (job) => job.latitude && job.longitude,
  );

  if (!jobsWithCoordinates?.length) {
    return [];
  }

  const africaBox = {
    latitude: { lower: -19, upper: 34 },
    longitude: { lower: -34, upper: 21 },
  };
  const serverBox = getServerBox();

  const correctJobsForCurrentServer = jobsWithCoordinates.filter(
    (job) => !isWithinBounds(africaBox, job) && isWithinBounds(serverBox, job),
  );

  const standardDeviationBox = {
    latitude: getStandardDeviationBounds(
      correctJobsForCurrentServer.map((job) => job.latitude),
    ),
    longitude: getStandardDeviationBounds(
      correctJobsForCurrentServer.map((job) => job.longitude),
    ),
  };

  return correctJobsForCurrentServer.filter((job) =>
    isWithinBounds(standardDeviationBox, job),
  );
}

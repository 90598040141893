import styled from 'styled-components';
import theme from 'theme';
import DrawerBackNavigation from '../DrawerBackNavigation';

const { colors, fonts, fontSizes, media } = theme;

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  border-left: 2px solid ${colors.black};
  background-color: ${colors.offWhite};
`;

const Content = styled.div`
  padding: 20px 15px;
`;

const Title = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 12px !important;
  ${media.sm`
    margin-bottom: 25px !important;
  `}
`;

const OverviewTabBase = ({
  children,
  title,
}: {
  children: JSX.Element;
  title?: string;
}): JSX.Element => (
  <>
    <DrawerBackNavigation />
    <Container>
      <Content>
        {title && <Title>{title}</Title>}
        {children}
      </Content>
    </Container>
  </>
);

export default OverviewTabBase;

import axios from 'axios';
import { WorkOrderItem } from 'model/WorkOrderItem';
import { mapToWorkOrderItem } from './helpers';

export const getAll = async (jobId: string): Promise<WorkOrderItem[]> => {
  const { data } = await axios.get(`/jobs/${jobId}/work-order-items/`);
  return data.results.map(mapToWorkOrderItem);
};

export const get = async (id: number): Promise<WorkOrderItem> => {
  const { data } = await axios.get(`/work-order-items/${id}/`);
  return mapToWorkOrderItem(data);
};

export const editOutput = async (
  id: number,
  units: number | null,
  subtypeId: number,
  sentAt: Date,
): Promise<WorkOrderItem> => {
  const { data } = await axios.put(`/work-order-items/${id}/`, {
    units,
    subtype_id: subtypeId,
    sent_at: sentAt,
  });
  return mapToWorkOrderItem(data);
};

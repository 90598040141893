import { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import * as constants from 'utils/constants';

import { Job } from 'api';
import { selectWorkspaceSettings } from 'store/selectors';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Upload } from 'components/ui';
import { DownloadNoBackground } from 'assets/icons';
import { EmptyStatePatternCircles } from 'assets/images';
import { setGlobalError } from 'store/slices/notifications';
import { useText } from 'hooks';
import RootState from 'model/State/RootState';
import { AxiosResponse } from 'axios';
import { downloadBlob } from 'helpers/files';

const { colors, fonts, fontSizes, mixins, media } = theme;

const Container = styled.div`
  min-height: 100%;
  padding: 40px 80px;
  ${mixins.flexColumn};
  ${media.sm`
    padding: 15px;
  `}
  background-color: ${colors.offWhite};
  box-sizing: border-box;
`;

const PageContent = styled.div`
  z-index: 4;
`;

const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  margin-bottom: 30px !important;
`;

const StepText = styled.p`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
  padding-bottom: 20px;
`;

const DownloadIcon = styled(DownloadNoBackground)`
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0;
  .fill-selector {
    fill: ${colors.black};
  }
  ${media.sm`
    .fill-selector {
      fill: ${colors.white};
    }
  `}
`;

const UploadWrapper = styled.div`
  width: 640px;
`;

const Message = styled.span<{ error: boolean }>`
  color: ${({ error }) => (error ? colors.darkRed : colors.black)};
  font-family: ${fonts.heavy};
  padding: 5px;
  font-size: ${fontSizes.l};
`;

const BrandBackgroundImage = styled.img`
  width: 22.5%;
  position: absolute;
  bottom: 0;
  right: 0;
`;

// BulkJobUploadSettings
const BulkJobUploadSettings = () => {
  const [fileList, setFileList] = useState<File[]>([]);
  const [hasError, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const { bulk_upload_jobs_template } = useSelector(selectWorkspaceSettings);
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const dispatch = useDispatch();
  const getText = useText();

  const template = bulk_upload_jobs_template ?? '';

  const downloadTemplate = () => {
    if (template) {
      fetch(template, {
        cache: 'no-store',
      })
        .then((response) => response.blob())
        .then((blob) => {
          const filename = template.split('/').pop()?.split('?')[0];
          if (filename) {
            downloadBlob(blob, filename);
          }
        });
    }
  };

  const onFileListChange = (files: File[]) => {
    setError(false);
    const firstFile = files[0];
    if (!firstFile) {
      setMessage(getText('bulk_job_upload_validation_no_file'));
      setError(true);
      return;
    }
    if (firstFile.size > 10000000) {
      setMessage(getText('bulk_job_upload_validation_large'));
      setError(true);
      return;
    }
    if (!['text/csv', 'application/vnd.ms-excel'].includes(firstFile?.type)) {
      setMessage(getText('bulk_job_upload_validation_not_supported_type'));
      setError(true);
      return;
    }
    setFileList([firstFile]);
    setMessage(getText('bulk_job_upload_validation_format_ok'));
  };

  const removeFileItem = (file: File) => {
    setFileList((prevState) => prevState.filter((item) => item !== file));
    setMessage(null);
    setError(false);
  };

  const onSubmit = async () => {
    if (fileList.length > 0) {
      setSaving(true);
      try {
        const data = await Job.bulkJobUpload(
          fileList[0],
          selectedWorkspace?.uuid,
        );
        setMessage(data?.message);
        setError(false);
        setFileList([]);
        setSaving(false);
      } catch (error) {
        if ((error as AxiosResponse)?.data?.status_code === 400) {
          setMessage((error as AxiosResponse)?.data?.message[0]);
          setError(true);
        } else {
          dispatch(setGlobalError(error));
        }
        setFileList([]);
        setSaving(false);
      }
    } else {
      setMessage(getText('bulk_job_upload_validation_no_selected'));
      setError(true);
    }
  };

  return (
    <Container>
      <PageContent>
        <H2>{getText('bulk_job_upload')}</H2>
        <StepText>{getText('bulk_job_upload_step_download')}</StepText>
        <Button.Primary
          text={getText('bulk_job_upload_download_button')}
          IconComponent={DownloadIcon}
          onClick={() => downloadTemplate()}
          width='350px'
          event={constants.EVENT_ADD_BULK_JOB_DOWNLOAD_TEMPLATE}
          extraStyles={{
            marginTop: 0,
            marginBottom: '50px',
            alignSelf: 'flex-start',
          }}
        />
        <StepText>{getText('bulk_job_upload_step_upload')}</StepText>

        <UploadWrapper>
          <Upload.UploadContent
            acceptType='text/csv,application/vnd.ms-excel'
            selectedMediaType='csv'
            fileList={fileList}
            onChange={onFileListChange}
            removeFileItem={removeFileItem}
            trackEvent={constants.EVENT_ADD_BULK_JOB_UPLOAD}
            isSettings
            multiple={false}
          />
          {message && <Message error={hasError}>{message}</Message>}
          <Button.Primary
            text={getText('bulk_job_upload_upload_button')}
            disabled={!fileList.length || hasError}
            onClick={() => onSubmit()}
            width='350px'
            event={constants.EVENT_ADD_BULK_JOB_SUBMIT_CLICKED}
            loading={saving}
          />
        </UploadWrapper>
      </PageContent>
      <BrandBackgroundImage src={EmptyStatePatternCircles} />
    </Container>
  );
};
export default BulkJobUploadSettings;

import React from 'react';
import { CSSProperties } from 'styled-components';
import * as Analytics from 'utils/analytics';
import { Button, CheckIcon, Icon } from './styled';

type Props = {
  onClick: () => void;
  IconComponent: React.ElementType;
  disabled?: boolean;
  extraStyles?: CSSProperties;
};

export const LoginLinkActionButton = Analytics.withTrackEvent(
  ({
    onClick,
    IconComponent,
    disabled = false,
    extraStyles,
    ...props
  }: Props) => (
    <Button
      disabled={disabled}
      {...props}
      style={extraStyles}
      onClick={onClick}
    >
      <Icon
        disabled={disabled}
        component={disabled ? <CheckIcon /> : <IconComponent />}
      />
    </Button>
  ),
);

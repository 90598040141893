import {
  ADD_WORKSPACE_CONTROL_TO_HAZARD,
  ADD_WORKSPACE_CONTROL_TO_HAZARD_ERROR,
  ADD_WORKSPACE_CONTROL_TO_HAZARD_SUCCESS,
  EDIT_WORKSPACE_CONTROL,
  EDIT_WORKSPACE_CONTROL_ERROR,
  EDIT_WORKSPACE_CONTROL_SUCCESS,
  FETCH_WORKSPACE_CONTROLS_FOR_HAZARD,
  FETCH_WORKSPACE_CONTROLS_FOR_HAZARD_ERROR,
  FETCH_WORKSPACE_CONTROLS_FOR_HAZARD_SUCCESS,
  REMOVE_WORKSPACE_CONTROL,
  REMOVE_WORKSPACE_CONTROL_ERROR,
  REMOVE_WORKSPACE_CONTROL_SUCCESS,
  RESET_WORKSPACE_CONTROL_ERROR,
} from '../actionTypes';

export const fetchWorkspaceControlsForHazard = (workspaceUuid, hazardId) => ({
  type: FETCH_WORKSPACE_CONTROLS_FOR_HAZARD,
  workspaceUuid,
  hazardId,
});

export const fetchWorkspaceControlsForHazardSuccess = (
  workspaceUuid,
  hazardId,
  data,
) => ({
  type: FETCH_WORKSPACE_CONTROLS_FOR_HAZARD_SUCCESS,
  workspaceUuid,
  hazardId,
  data,
});

export const fetchWorkspaceControlsForHazardError = () => ({
  type: FETCH_WORKSPACE_CONTROLS_FOR_HAZARD_ERROR,
});

export const addNewControlToHazard = (
  workspaceUuid,
  hazardId,
  controlTitles,
) => ({
  type: ADD_WORKSPACE_CONTROL_TO_HAZARD,
  workspaceUuid,
  hazardId,
  controlTitles,
});

export const addNewControlToHazardSuccess = (
  workspaceUuid,
  hazardId,
  data,
) => ({
  type: ADD_WORKSPACE_CONTROL_TO_HAZARD_SUCCESS,
  workspaceUuid,
  hazardId,
  data,
});

export const addNewControlToHazardError = (data, hazardId) => ({
  type: ADD_WORKSPACE_CONTROL_TO_HAZARD_ERROR,
  data,
  hazardId,
});

export const editExistingControl = (
  workspaceUuid,
  hazardId,
  controlTitles,
) => ({
  type: EDIT_WORKSPACE_CONTROL,
  workspaceUuid,
  hazardId,
  controlTitles,
});

export const editExistingControlSuccess = (workspaceUuid, hazardId, data) => ({
  type: EDIT_WORKSPACE_CONTROL_SUCCESS,
  workspaceUuid,
  hazardId,
  data,
});

export const editExistingControlError = (data, hazardId) => ({
  type: EDIT_WORKSPACE_CONTROL_ERROR,
  data,
  hazardId,
});

export const removeExistingControl = (workspaceUuid, hazardId, control) => ({
  type: REMOVE_WORKSPACE_CONTROL,
  workspaceUuid,
  hazardId,
  control,
});

export const removeExistingControlSuccess = (workspaceUuid, hazardId, id) => ({
  type: REMOVE_WORKSPACE_CONTROL_SUCCESS,
  workspaceUuid,
  hazardId,
  id,
});

export const removeExistingControlError = (data) => ({
  type: REMOVE_WORKSPACE_CONTROL_ERROR,
  data,
});

export const resetError = () => ({
  type: RESET_WORKSPACE_CONTROL_ERROR,
});

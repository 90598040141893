import { InfoContainer, InfoIcon } from './styled';

type Props = {
  text: string;
  extraStyles?: React.CSSProperties;
};

export const InfoTag = ({ text, extraStyles }: Props): JSX.Element => (
  <InfoContainer style={extraStyles}>
    <InfoIcon />
    {text}
  </InfoContainer>
);

import {
  Assessment as AssessmentIcon,
  Control,
  YellowCheckMark,
} from 'assets/icons';
import { NotUploadedTimelinePlaceholder } from 'assets/images';
import { Storage } from 'aws-amplify';
import { Media as MediaUi } from 'components/ui';
import { Media } from 'helpers';
import { useText, useQueryParams } from 'hooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { MajorHazardTag } from '../Tags/MajorHazardTag';

import { Message } from 'model/Message';
import JobData from 'model/JobData';
import { BackendHazard } from 'model/Assessment/Hazard';
import { UploadStatus } from 'model/enum/UploadStatus';

import { MessageWithAction } from './MessageWithAction';

import {
  ThumbnailContainer,
  ThumbnailOverLay,
  Placeholder,
  OrangeStatusContainer,
  BlackStatusContainer,
  Status,
  SmallWarningIcon,
  SmallClockIcon,
  ManualRAFlag,
  VideoTimerIcon,
} from './styled';
import { NotAvailableTag } from '../Tags/NotAvailableTag';

const { colors, fonts } = theme;

type Props = {
  message: Message;
  activeJob: JobData;
  onClick: () => void;
};

// Message.Assessment
export const Assessment = Analytics.withTrackEvent(
  ({ message, activeJob, onClick }: Props): JSX.Element => {
    const getText = useText();
    const [thumbnailURL, setThumbnailURL] = useState<string>('');
    const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
    const { getPathWithQueryParams } = useQueryParams();

    const to = getPathWithQueryParams(
      `/jobs/${activeJob.id}/${
        message.target.type_is_rams ? 'rams-assessments' : 'assessments'
      }/${message.target.id}`,
    );

    const isSignedOff = message.target.is_signed_off;
    const signoffRequired = message.target.signoff_required;
    const isOfflineFlow = message.target.is_offline_flow;
    const hasReviewWithNoVideoLabel =
      message.target.is_reviewed &&
      message.target.video?.status === UploadStatus.NEW;
    const isNotUploaded =
      message.target?.video?.status === UploadStatus.NEW &&
      !message.target.type_is_rams &&
      !message.target.is_reviewed;
    const isLocked =
      message.target.video &&
      [UploadStatus.UPLOADED, UploadStatus.PROCESSED].includes(
        message.target.video?.status,
      ) &&
      !message.target.type_is_rams;
    const thumbnail = Media.getAssessmentThumbnail(message.target);
    const isRams = message.target.type_is_rams;
    const isRamsReviewed = message.target.rams_reviews?.length > 0;
    const isMethodStatementReady = !!message.target.method_statement;
    const isMajor = message.target?.hazards?.some(
      (hazard: BackendHazard) => hazard.is_major,
    );

    const type = getText('job_details_timeline_action_risk_assessment');
    const subtype =
      message.target[`type_${globalAppLanguage}`] || message.target.type;

    useEffect(() => {
      (async () => {
        if (thumbnail) {
          const signedURL = await Storage.get(thumbnail);
          setThumbnailURL(signedURL);
        }
      })();
    }, [thumbnail]);

    const getActionComponent = () => {
      return isRams ? <Control /> : <AssessmentIcon />;
    };

    const renderCompleteContainer = (title: string) => (
      <BlackStatusContainer>
        <YellowCheckMark />
        <Status>{title}</Status>
      </BlackStatusContainer>
    );

    const renderNotCompleteContainer = (title: string) => (
      <OrangeStatusContainer>
        <SmallWarningIcon />
        <Status $textColor={colors.black}>{title}</Status>
      </OrangeStatusContainer>
    );

    const getAssessmentStatusTag = (
      isCompleted: boolean,
      completedTitle: string,
      notCompletedTitle: string,
    ) =>
      isCompleted
        ? renderCompleteContainer(completedTitle)
        : renderNotCompleteContainer(notCompletedTitle);

    const renderRamsTag = (
      isRamsReviewed: boolean,
      isMethodStatementReady: boolean,
    ) => {
      if (!isRamsReviewed && !isMethodStatementReady) {
        return renderNotCompleteContainer(getText('job_details_rams_pending'));
      }
      if (isRamsReviewed && !isMethodStatementReady) {
        return renderNotCompleteContainer(
          getText('job_details_rams_method_statement_pending'),
        );
      }
      return renderCompleteContainer(
        getText('job_details_rams_method_statement_complete'),
      );
    };

    return (
      <MessageWithAction
        message={message}
        onClick={onClick}
        to={to}
        isLocked={isLocked}
        type={type}
        subtype={subtype}
        actionIcon={getActionComponent()}
      >
        <ThumbnailContainer>
          {isLocked && (
            <ThumbnailOverLay $backgroundColor={theme.colors.darkGrey}>
              <SmallClockIcon />
            </ThumbnailOverLay>
          )}
          {(isNotUploaded || hasReviewWithNoVideoLabel) && (
            <ThumbnailOverLay>
              <VideoTimerIcon />
            </ThumbnailOverLay>
          )}
          {isOfflineFlow && (
            <ManualRAFlag>{getText('job_details_manual_ra_tag')}</ManualRAFlag>
          )}
          {thumbnailURL ? (
            <MediaUi
              type='photo'
              kind='assessment'
              url={thumbnailURL}
              photoCover
              qualityIndicator={message.target?.video?.qualityIndicator}
            />
          ) : isNotUploaded || hasReviewWithNoVideoLabel ? (
            <MediaUi
              type='photo'
              kind='assessment'
              url={NotUploadedTimelinePlaceholder}
              photoCover
              qualityIndicator={message.target?.video?.qualityIndicator}
            />
          ) : (
            <Placeholder />
          )}
        </ThumbnailContainer>
        {signoffRequired &&
          !isRams &&
          !isNotUploaded &&
          !isLocked &&
          getAssessmentStatusTag(
            isSignedOff,
            getText('job_details_sign_off_done'),
            getText('job_details_sign_off_required'),
          )}
        {isRams && renderRamsTag(isRamsReviewed, isMethodStatementReady)}
        {isLocked && (
          <OrangeStatusContainer>
            <SmallWarningIcon />
            <Status $textColor={colors.black}>
              {getText('job_details_review_pending')}
            </Status>
          </OrangeStatusContainer>
        )}
        {isMajor && (
          <MajorHazardTag
            text={getText('job_overview_major_hazard_identified')}
            hasSmallIcon
            extraStyles={{ marginTop: 0, padding: '7px 17px' }}
          />
        )}
        {(isNotUploaded || hasReviewWithNoVideoLabel) && (
          <NotAvailableTag
            text={getText('job_details_not_fully_uploaded')}
            extraStyles={{
              marginTop: 0,
              padding: '7px 17px',
              fontFamily: fonts.default,
            }}
          />
        )}
      </MessageWithAction>
    );
  },
);

import { ModalType } from 'model/enum/ModalType';

export const getParentModalToMemberSearch = (
  isCommandCentre,
  isInsights,
  isFatigueManager,
) => {
  let parentModal = ModalType.SEARCH_FILTERS;
  if (isCommandCentre) {
    parentModal = ModalType.COMMAND_CENTRE_FILTERS;
  } else if (isInsights || isFatigueManager) {
    parentModal = ModalType.FILTERS;
  }
  return `?modal=${parentModal}`;
};

import { UnorderedList } from 'components/ui/Lists/UnorderedList';
import { Quote } from 'assets/icons';
import { Section } from '../styled';

type Props = {
  comments: string;
  title: string;
};

export const Comments = ({ comments, title }: Props): JSX.Element | null => {
  if (comments) {
    return (
      <Section>
        <UnorderedList
          items={[{ id: 1, title: comments }]}
          title={title}
          IconComponent={Quote}
          extraItemStyle={{ marginLeft: '40px' }}
        />
      </Section>
    );
  }

  return null;
};

import { useLayoutEffect } from 'react';
import * as constants from 'utils/constants';
import { MessageTypes } from './constants';
import { useSelector } from 'react-redux';
import {
  selectGlobalLanguageSetting,
  selectJobsView,
  selectPreviousViewVersion,
} from 'store/selectors';
import JobData from 'model/JobData';
import { Message as MessageType } from 'model/Message';

import { Evidence } from './Evidence';
import { Event } from './Event';
import { Log } from './Log';
import { Text } from './Text';
import { Document } from './Document';
import { Assessment } from './Assessment';
import { WorkOrderItem } from './WorkOrderItem';
import { Permit } from './Permit';
import { JobForm } from './JobForm';
import { SiteBriefing } from './SiteBriefing';

type Props = {
  job: JobData;
  message: MessageType;
  onClick: () => void;
  scrollTo: boolean;
};

export const Message = ({
  job,
  message,
  onClick,
  scrollTo,
}: Props): JSX.Element => {
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const view = useSelector(selectJobsView);
  const previousView = useSelector(selectPreviousViewVersion);

  useLayoutEffect(() => {
    if (scrollTo) {
      const action = document.getElementById(message.id.toString());
      if (action) {
        action.scrollIntoView();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const eventProperties = {
    jobId: job.id,
    source: 'job-stream',
    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    [constants.EVENT_PROPERTIES_PAGE_SOURCE_PREVIOUS_FIELD]: previousView,
  };

  switch (message.type) {
    case MessageTypes.TEXT:
      return (
        <Text
          message={message}
          key={message.id}
          event={constants.EVENT_VIEW_TEXT_ITEM}
          eventProperties={{ ...eventProperties, messageId: message.id }}
        />
      );
    case MessageTypes.EVENT:
      return (
        <Event
          message={message}
          key={message.id}
          event={constants.EVENT_VIEW_EVENT_ITEM}
          eventProperties={{ ...eventProperties, eventId: message.id }}
        />
      );
    case MessageTypes.EVIDENCE:
      return (
        <Evidence
          message={message}
          activeJob={job}
          onClick={onClick}
          event={constants.EVENT_VIEW_EVIDENCE_ITEM}
          eventProperties={{
            ...eventProperties,
            evidenceType:
              message.target?.[`type_${globalAppLanguage}`] ||
              message.target.type,
            evidenceId: message.target.id,
            insights_available: !!message.target?.videos?.[0]?.summary,
            evidence_video_id: message.target?.videos?.[0]?.file_id,
          }}
        />
      );
    case MessageTypes.LOG:
      if (message.target.type === MessageTypes.JOB_BLOCKER) {
        return (
          <Log
            message={message}
            activeJob={job}
            onClick={onClick}
            event={constants.EVENT_VIEW_JOB_BLOCKER_ITEM}
            eventProperties={{
              ...eventProperties,
              logId: message.target.id,
              jobBlockerCategory:
                message.target.job_blocker[`title_${globalAppLanguage}`] ||
                message.target.job_blocker.title,
            }}
          />
        );
      } else {
        return (
          <Log
            message={message}
            activeJob={job}
            onClick={onClick}
            event={constants.EVENT_VIEW_NOTE_ITEM}
            eventProperties={{
              ...eventProperties,
              logType: 'Note',
              logId: message.target.id,
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
          />
        );
      }
    case MessageTypes.DOCUMENT:
      return (
        <Document
          message={message}
          activeJob={job}
          onClick={onClick}
          event={constants.EVENT_DOWNLOAD_DOCUMENT_ITEM}
          eventProperties={{
            ...eventProperties,
            documentId: message.target.id,
          }}
        />
      );
    case MessageTypes.ASSESSMENT:
      return (
        <Assessment
          message={message}
          activeJob={job}
          onClick={onClick}
          event={constants.EVENT_VIEW_RISK_ASSESSMENT_ITEM}
          eventProperties={{
            ...eventProperties,
            assessmentId: message.target.id,
            assessmentType:
              message.target[`type_${globalAppLanguage}`] ||
              message.target.type,
          }}
        />
      );
    case MessageTypes.WORK_ORDER_ITEM:
      return (
        <WorkOrderItem
          message={message}
          activeJob={job}
          onClick={onClick}
          event={constants.EVENT_VIEW_WORK_COMPLETED_ITEM}
          eventProperties={{
            ...eventProperties,
            logId: message.target.id,
          }}
        />
      );
    case MessageTypes.PERMIT:
      return (
        <Permit
          message={message}
          activeJob={job}
          onClick={onClick}
          event={constants.EVENT_VIEW_PERMIT_ITEM}
          eventProperties={{
            ...eventProperties,
            logId: message.target.id,
          }}
        />
      );
    case MessageTypes.JOB_FORM:
      return (
        <JobForm
          message={message}
          activeJob={job}
          onClick={onClick}
          event={constants.EVENT_VIEW_PERMIT_ITEM}
          eventProperties={{
            ...eventProperties,
            formId: message.target.id,
          }}
        />
      );
    case MessageTypes.JOB_BRIEFING:
      return (
        <SiteBriefing
          message={message}
          activeJob={job}
          onClick={onClick}
          event={constants.EVENT_VIEW_SITE_BRIEFING_ITEM}
          eventProperties={{
            ...eventProperties,
            siteBriefingId: message.target.id,
          }}
        />
      );

    default:
      return <></>;
  }
};

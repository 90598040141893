import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Loader } from 'components/ui';
import { useText } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import theme from 'theme';
import InsightJobBlockerGraph from './InsightJobBlockerGraph';
import { InsightJobBlockerSummary } from './InsightSummary';
import { InsightJobBlockerSummaryTable } from './InsightJobBlockerSummaryTable';
import TableData from 'model/Insight/TableData';
import { fetchJobBlockers } from 'store/actions/insights';
import {
  selectActiveWorkspaceUuid,
  selectIsLoadingJobBlockers,
  selectJobBlockersGraphs,
  selectJobBlockersStats,
} from 'store/selectors';

const { fonts, fontSizes, colors, mixins } = theme;
// Styled Components
const DataContainer = styled.div`
  padding: 40px 80px;
  background-color: ${colors.offWhite};
  min-height: 100%;
`;

const DataRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 42px !important;
`;

const NoTResultContainer = styled.div`
  ${mixins.flexFullCenter};
  margin-top: 40px;
`;

type Props = {
  depotId: number;
  startDate: Date;
  endDate: Date;
  isWeekendIncluded: boolean;
};

// InsightJobBlocker
const InsightJobBlocker = ({
  depotId,
  startDate,
  endDate,
  isWeekendIncluded,
}: Props): JSX.Element => {
  const getText = useText();
  const [tableData, setTableData] = useState<TableData | null>(null);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);
  const graphData = useSelector(selectJobBlockersGraphs);
  const statsData = useSelector(selectJobBlockersStats);
  const isLoading = useSelector(selectIsLoadingJobBlockers);

  const dispatch = useDispatch();

  useEffect(() => {
    setTableData(null);

    if (depotId) {
      dispatch(
        fetchJobBlockers(
          depotId,
          startDate,
          endDate,
          !isWeekendIncluded,
          selectedWorkspaceUuid,
        ),
      );
    }
  }, [
    dispatch,
    depotId,
    startDate,
    endDate,
    isWeekendIncluded,
    selectedWorkspaceUuid,
  ]);

  return (
    <DataContainer>
      {isLoading ? (
        <DataRow>
          <Loader />
        </DataRow>
      ) : statsData && graphData ? (
        <DataRow>
          <H2>{getText('insights_job_blockers_longest_delay_title')}</H2>
          <InsightJobBlockerSummary statsData={statsData} />
          <H2>
            {getText(
              'insights_job_blockers_longest_delay_by_blocker_type_title',
            )}
          </H2>
          <InsightJobBlockerGraph
            graphData={graphData}
            startDate={startDate}
            endDate={endDate}
            setTableData={setTableData}
          />
          <InsightJobBlockerSummaryTable data={tableData?.data ?? []} />
        </DataRow>
      ) : (
        <NoTResultContainer>
          {getText('filter_data_no_results')}
        </NoTResultContainer>
      )}
    </DataContainer>
  );
};

export default InsightJobBlocker;

import {
  APPEND_USERS,
  CLEAR_TEAMS,
  FETCH_MORE_USERS,
  FETCH_TEAMS,
  FETCH_TEAMS_ERROR,
  FETCH_TEAMS_SUCCESS,
  FETCH_USERS,
  SET_LATEST_PSI_REVIEW,
  SET_PSI_DISMISSED,
  SET_USERS,
} from './actionTypes';

export const setLatestPsiReview = (latestPsiReview) => ({
  type: SET_LATEST_PSI_REVIEW,
  latestPsiReview,
});

export const setPsiDismissed = (isPsiDismissed) => ({
  type: SET_PSI_DISMISSED,
  isPsiDismissed,
});

export const fetchTeams = () => ({
  type: FETCH_TEAMS,
});

export const fetchTeamsSuccess = (teams) => ({
  type: FETCH_TEAMS_SUCCESS,
  teams,
});

export const fetchTeamsError = () => ({
  type: FETCH_TEAMS_ERROR,
});

export const clearTeams = () => ({
  type: CLEAR_TEAMS,
});

export const fetchUsers = (search, pageSize, depotId, roles) => ({
  type: FETCH_USERS,
  search,
  pageSize,
  depotId,
  roles,
});

export const fetchMoreUsers = (url) => ({
  type: FETCH_MORE_USERS,
  url,
});

export const setUsers = (users, nextLink) => ({
  type: SET_USERS,
  users,
  nextLink,
});

export const appendUsers = (users, nextLink) => ({
  type: APPEND_USERS,
  users,
  nextLink,
});

import { useText } from 'hooks';
import theme from 'theme';
import { SparkleIcon, TagContainer } from './styled';

export const InsightsAvailableTag = (): JSX.Element => {
  const getText = useText();

  return (
    <TagContainer
      color={theme.colors.blue}
      $backgroundColor={theme.colors.lightBlue}
      style={{ marginTop: 0 }}
    >
      <SparkleIcon />
      {getText('wrap_up_summaries_insights_available')}
    </TagContainer>
  );
};

import styled from 'styled-components';
import theme from 'theme';
import { Search } from 'assets/icons';

const { colors, fonts, fontSizes, mixins } = theme;

export const Container = styled.div`
  ${mixins.flexColumn};
  > .MuiTextField-root {
    max-height: inherit;
  }
`;

export const Label = styled.label<{
  disabled?: boolean;
  color?: string;
  $optional?: boolean;
  required?: boolean;
}>`
  font-family: ${({ $optional }) => ($optional ? fonts.light : fonts.bold)};
  font-size: ${({ required, $optional }) =>
    required || $optional ? fontSizes.s : fontSizes.m};
  color: ${({ disabled, color, $optional, required }) => {
    if (disabled) {
      return colors.darkGrey;
    }
    if ($optional) {
      return colors.grey;
    }
    if (required) {
      return colors.darkGrey;
    }

    return color || colors.black;
  }};
  margin-bottom: 10px;
  margin-left: ${({ $optional, required }) =>
    $optional || required ? 'auto' : 0};
`;

export const GuidanceWrapper = styled.div`
  margin-bottom: 10px;
`;

export const SearchIcon = styled(Search)`
  height: 23px;
  width: 23px;
`;

export const InputContainer = styled.div<{
  disabled: boolean;
  $hasError: string | boolean;
  $hasBlackBackground?: boolean;
}>`
  .MuiTextField-root {
    background-color: ${colors.white};
    width: 100%;
    transition: ease-out 0.2s;

    outline: 2px solid
      ${({ disabled, $hasError }) => {
        if (disabled) {
          return colors.grey;
        }

        if ($hasError) {
          return colors.red;
        }
        return colors.black;
      }};

    &:hover {
      box-shadow: none;
      outline: ${({ $hasBlackBackground, disabled, $hasError }) => {
        if ($hasError) {
          return `3px solid ${colors.red}`;
        }

        if ($hasBlackBackground) {
          if (disabled) {
            return `2px solid ${colors.black}`;
          }

          return `3px solid ${colors.grey}`;
        }

        if (disabled) {
          return `2px solid ${colors.grey}`;
        }

        return `3px solid ${colors.black}`;
      }};
      transition: ease-out 0.2s;
    }

    &:focus-within {
      box-shadow: none;
      outline: ${({ disabled, $hasError }) => {
        if (disabled) {
          return `2px solid ${colors.grey}`;
        }

        if ($hasError) {
          return `3px solid ${colors.red}`;
        }

        return `3px solid ${colors.orange}`;
      }};
      transition: ease-out 0.2s;
    }

    input {
      height: 100%;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
`;

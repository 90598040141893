import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIsMobile, useText } from 'hooks';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { Card, Button, Footer } from 'components/ui';
import { resetSessionErrors } from 'store/actions/session';
import {
  ErrorContainer,
  OktaForm,
  AlternativeLoginContainer,
  LoginWithUserLink,
  OktaContainer,
} from './styled';
import { MobileLineBreak, FyldLogo } from '../styled';
import RootState from 'model/State/RootState';
import Organisation from 'model/Organisation';
import { startSSOSignIn } from 'helpers/auth';
import { Slogan } from 'components/ui/Misc/Slogan';

type Props = {
  onLoginWithUserLinkClick: () => void;
  organisation: Organisation;
  buttonText: string;
  loginSource: string;
};

export const SSOLoginForm = ({
  onLoginWithUserLinkClick,
  organisation,
  buttonText,
  loginSource,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const getText = useText();
  const { error } = useSelector((state: RootState) => state.session);

  const pageByLoginSource = {
    [constants.LoginSource.OKTA]: constants.LoginPages.OKTA,
    [constants.LoginSource.SSO]: constants.LoginPages.SSO,
  };

  const page = pageByLoginSource[loginSource];

  useEffect(() => {
    Analytics.trackPage(
      constants.EVENT_PAGE_VIEW_LOGIN,
      page ? { page } : undefined,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginWithUserLinkClick = () => {
    Analytics.trackEvent(constants.EVENT_LOGIN_WITH_EMAIL_PASSWORD_CLICKED, {
      organisation,
    });
    dispatch(resetSessionErrors());
    onLoginWithUserLinkClick();
  };

  return (
    <OktaContainer>
      <OktaForm>
        <FyldLogo />
        <Slogan />
        <Button.Primary
          text={buttonText}
          key={organisation.cognito_identity_provider}
          onClick={() =>
            startSSOSignIn(organisation.cognito_identity_provider, loginSource)
          }
          extraStyles={
            isMobile
              ? {
                  marginTop: '120px',
                  marginBottom: '30px',
                  width: '80%',
                  alignSelf: 'flex-start',
                }
              : { marginBottom: '30px' }
          }
          event={constants.EVENT_LOGIN_ATTEMPT}
          eventProperties={{ loginSource, organisation }}
          hasBlackBackground
        />
        {error && (
          <ErrorContainer>
            <Card.ErrorMessage message={error} />
          </ErrorContainer>
        )}
        <AlternativeLoginContainer>
          {getText('login_alternatively')}
          <MobileLineBreak />
          <LoginWithUserLink onClick={handleLoginWithUserLinkClick}>
            {getText('login_with_username_password')}
          </LoginWithUserLink>
        </AlternativeLoginContainer>
        <Footer isOktaLogin />
      </OktaForm>
    </OktaContainer>
  );
};

import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
import { Field } from 'model/Forms/Field';

const { fonts, fontSizes } = theme;

// Styled components
const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`;

const DetailTitle = styled.span`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  padding-right: 8px;
`;

const DetailText = styled.span`
  font-size: ${fontSizes.default};
  padding: 5px 0;
  word-break: break-all;
`;

const TextField = ({ field }: { field: Field }): JSX.Element => {
  const getText = useText();

  return (
    <DetailContainer>
      <DetailTitle>{field.title}</DetailTitle>
      <DetailText>
        {field.value || getText('job_forms_not_reported')}
      </DetailText>
    </DetailContainer>
  );
};

export default TextField;

import axios, { AxiosError } from 'axios';
import { Control, Controls } from 'model/Assessment/Control';
import {
  Hazard,
  Hazards,
  HazardWithControlsAndComments,
} from 'model/Assessment/Hazard';
import { formatDateForApi } from 'helpers/dates';
import { getSortString } from 'helpers/apiHelpers';
import { JOB_SUBTYPES_PAGE_SIZE } from 'utils/constants';

const getHazards = async (id: string): Promise<Hazard[]> => {
  const { data } = await axios.get(`/jobs/${id}/hazards/`);
  return data.results.map(Hazards.mapToHazard);
};

const getControls = async (id: string): Promise<Control[]> => {
  const { data } = await axios.get(`/jobs/${id}/controls/`);
  return data.results.map(Controls.mapToControl);
};

const getComments = async (id: string): Promise<string[]> => {
  const { data } = await axios.get(`/jobs/${id}/comments/`);
  return data.results ?? [];
};

const getHazardsWithControlsAndComments = async (
  id: string,
): Promise<HazardWithControlsAndComments> => {
  const { data } = await axios.get(`/jobs/${id}/hazards-with-controls/`);
  return Hazards.mapToHazardsWithControlsAndComments(data);
};

export const getHazardsControlsComments = async (id: string) => {
  const result: [Hazard[], Control[], string[], HazardWithControlsAndComments] =
    await Promise.all([
      getHazards(id),
      getControls(id),
      getComments(id),
      getHazardsWithControlsAndComments(id),
    ]);

  return {
    hazards: result[0],
    controls: result[1],
    comments: result[2],
    hazardsWithControlsAndComments: result[3],
  };
};

export const getAll = async ({
  page,
  pageSize,
  sortField,
  sortDirection,
  userIds,
  search,
  startDate,
  endDate,
  type,
  subtype,
  status,
  assessmentType,
  hazard,
  depot,
  isActive,
  hasMajorHazard,
  hasVraReadyForSignOff,
  hasNoVra,
  workspaceUuid,
}) => {
  // We want to always get the pinned jobs first
  const DEFAULT_SORT = '-is_pinned';

  const customSort = getSortString(sortField, sortDirection);

  const isStatBoxSelected =
    isActive || hasMajorHazard || hasVraReadyForSignOff || hasNoVra;

  const params = {
    page: page,
    page_size: pageSize,
    order_by: customSort ? `${DEFAULT_SORT},${customSort}` : DEFAULT_SORT,
    ...(userIds?.length ? { user_ids: userIds } : {}),
    ...(search ? { q: search } : {}),
    ...(startDate && {
      start_date: formatDateForApi(startDate),
    }),
    ...(endDate && {
      end_date: formatDateForApi(endDate),
    }),
    ...(depot ? { depot_id: depot } : {}),
    ...(type ? { job_type_id: type } : {}),
    ...(subtype ? { job_subtype_id: subtype } : {}),
    ...(status ? { job_status_id: status } : {}),
    ...(assessmentType ? { assessment_type_id: assessmentType } : {}),
    ...(hazard ? { hazard_id: hazard } : {}),
    ...(isStatBoxSelected ? { is_active: isStatBoxSelected } : {}),
    ...(hasMajorHazard ? { has_major_hazard: hasMajorHazard } : {}),
    ...(hasVraReadyForSignOff
      ? { has_vra_ready_for_sign_off: hasVraReadyForSignOff }
      : {}),
    ...(hasNoVra ? { has_no_vra: hasNoVra } : {}),
    ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
  };

  const headers = {
    Accept: 'application/json; version=3;',
  };

  const { data } = await axios.get(`/jobs/`, {
    headers,
    params,
  });
  return data;
};

export const get = (id) => axios.get(`/jobs/${id}/`);

export const getJobStats = (workspaceUuid, userIds) =>
  axios.get(`/jobs/stats/`, {
    params: {
      ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
      ...(userIds?.length ? { user_ids: userIds } : {}),
    },
    headers: {
      Accept: 'application/json; version=3;',
    },
  });

export const markJobAsRead = (id) => axios.post(`/jobs/${id}/mark-as-read/`);

export const updateDetails = async (id, attributes) =>
  axios.put(`/jobs/${id}/`, { ...attributes });

export const createJob = async (attributes) =>
  axios.post(`/jobs/`, { ...attributes });

export const getAllLocations = async () => axios.get('/locations/');

export const getAllTypes = async (workspaceUuid) =>
  axios.get('/job-types/', {
    params: { ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}) },
  });

export const getAllSubtypes = async (workspaceUuid) =>
  axios.get('/job-subtypes/', {
    params: {
      page_size: JOB_SUBTYPES_PAGE_SIZE,
      ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
    },
  });

export const getAllStatuses = async (workspaceUuid) =>
  axios.get('/job-statuses/', {
    params: { ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}) },
  });

export const getLegacyViewJobStats = async (workspaceUuid) =>
  axios.get(`/jobs/my-jobs-stats/`, {
    params: { ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}) },
  });

export const endShiftForUser = async (id, endTime) => {
  const finishedAt = endTime || new Date();
  await axios.patch(`/fatigue/shift/${id}/`, {
    finished_at: new Date(finishedAt).toISOString(),
  });
};

export const resolveFRA = async (id, action, comments) => {
  await axios.post(`fatigue/risk-assessment/${id}/intervention/`, {
    action,
    comments,
  });
};

export const getWeatherByJob = async (id) => axios.get(`/jobs/${id}/weather/`);

export const postFeatureAccess = async (feature) =>
  axios.post(`/request-access/`, { feature });

export const bulkJobUpload = async (file, workspaceUuid) => {
  try {
    const formData = new FormData();
    formData.append('jobs_csv', file);
    formData.append('workspace_uuid', workspaceUuid);
    const { data } = await axios.post(`/admin/bulk-job-upload/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    throw (error as AxiosError)?.response;
  }
};

export const submitSafetyRiskReview = async (
  jobId,
  safetyPredictionScore,
  form,
  isPositive,
) => {
  try {
    const { data } = await axios.post(
      `jobs/${jobId}/safety-prediction-score/${safetyPredictionScore.id}/review/`,
      {
        positive_review: isPositive,
        ...form,
      },
    );
    return data;
  } catch (error) {
    throw (error as AxiosError)?.response;
  }
};

export const updatePinStatus = async (jobId, pinStatus) => {
  try {
    const { data } = await axios.post(`jobs/${jobId}/pin/`, {
      pin_job: pinStatus,
    });
    return data;
  } catch (error) {
    throw (error as AxiosError)?.response;
  }
};

import { ChangeEvent } from 'react';
import {
  StyledFormControlLabel,
  StyledFormGroup,
  StyledSwitch,
} from './styled';

type Props = {
  id: string;
  checked: boolean;
  label?: string | JSX.Element;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  disabled?: boolean;
  onChange?: (event?: ChangeEvent<HTMLInputElement>) => void;
};

// Input.Toggle
export const Toggle = ({
  id,
  checked,
  label,
  labelPlacement = 'start',
  disabled,
  onChange,
}: Props): JSX.Element => {
  return (
    <StyledFormGroup>
      <StyledFormControlLabel
        control={
          <StyledSwitch
            id={id}
            label={label}
            disabled={disabled}
            checked={checked}
            onChange={onChange}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
    </StyledFormGroup>
  );
};

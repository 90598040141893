import { FatigueLiveViewItem } from 'model/FatigueManager/FatigueLiveViewItem';
import {
  BackendFatigueHistoricItem,
  BackendFatigueLiveItem,
  BackendFatigueRiskAssessment,
} from './types';
import { FatigueRiskAssessment } from 'model/FatigueManager/FatigueRiskAssessment';
import { FatigueHistoricItem } from 'model/FatigueManager/FatigueHistoricDataItem';
import { formatDateAndTime } from 'helpers/dates';
import { formatISO } from 'date-fns';
import FraForm from 'model/FatigueManager/FraForm';
import FraFormSection from 'model/FatigueManager/FraFormSection';
import SectionField from 'model/FatigueManager/SectionField';
import { FieldKind } from 'components/views/Protected/Jobs/FatigueManager/components/NewFatigueRiskAssessmentModal/types';
import { OTHER_LOCALISATION_STRING } from 'model/FatigueManager/constants';
import Sort from 'model/Sort';
import {
  fatigueHistoricDataFields,
  fatigueLiveDataFields,
} from 'components/views/Protected/Jobs/FatigueManager/constants';
import { mapToUser } from 'api/User/helpers';

export const mapToFatigueRiskAssessment = (
  backendFatigueRiskAssessment: BackendFatigueRiskAssessment,
): FatigueRiskAssessment => ({
  id: backendFatigueRiskAssessment.id,
  uuid: backendFatigueRiskAssessment.uuid,
  form: backendFatigueRiskAssessment.form,
  fraType: backendFatigueRiskAssessment.fra_type ?? '',
  interventions: backendFatigueRiskAssessment.interventions ?? [],
  sentAt: new Date(backendFatigueRiskAssessment.sent_at),
  user: !!backendFatigueRiskAssessment.started_by
    ? mapToUser(backendFatigueRiskAssessment.started_by)
    : undefined,
  submittedBy: !!backendFatigueRiskAssessment.submitted_by
    ? mapToUser(backendFatigueRiskAssessment.submitted_by)
    : undefined,
});

export const mapToFatigueLiveItem = (
  backendFatigueLiveItem: BackendFatigueLiveItem,
): FatigueLiveViewItem => {
  const fras = backendFatigueLiveItem.latest_fras.map(
    mapToFatigueRiskAssessment,
  );

  return {
    user: mapToUser({
      id: backendFatigueLiveItem.id,
      first_name: backendFatigueLiveItem.first_name,
      last_name: backendFatigueLiveItem.last_name,
      phone_number: backendFatigueLiveItem.phone_number,
    }),
    depot: backendFatigueLiveItem.depot?.title ?? '',
    currentShiftTime: backendFatigueLiveItem.current_shift_time,
    shiftTimeSinceLastBreak: backendFatigueLiveItem.shift_time_since_last_break,
    totalShiftTime: backendFatigueLiveItem.total_shift_time,
    currentShiftId: backendFatigueLiveItem.current_shift_id,
    currentShiftStartedAt: backendFatigueLiveItem.current_shift_started_at,
    fatigueRiskAssessments: fras,
    perceivedRisk: backendFatigueLiveItem.risk_level,
  };
};

export const mapToFatigueHistoricItem = (
  backendFatigueHistoricDataItem: BackendFatigueHistoricItem,
): FatigueHistoricItem => ({
  user: !!backendFatigueHistoricDataItem.started_by
    ? mapToUser(backendFatigueHistoricDataItem.started_by)
    : undefined,
  region: backendFatigueHistoricDataItem.depot?.title ?? '',
  startedAt: formatDateAndTime(backendFatigueHistoricDataItem.started_at),
  finishedAt: formatDateAndTime(backendFatigueHistoricDataItem.finished_at),
  createdAt: formatDateAndTime(backendFatigueHistoricDataItem.created_at),
  updatedAt: formatDateAndTime(backendFatigueHistoricDataItem.updated_at),
  duration: backendFatigueHistoricDataItem.duration,
  fra: backendFatigueHistoricDataItem.fras.map(mapToFatigueRiskAssessment),
});

type FraSubmissionPayload = {
  id: number;
  form: FraForm;
  sentAt: Date;
  uuid: string;
};

const mapToBackendField = (field: SectionField): SectionField => {
  const { customUserInputValue, error, ...backendField } = field;

  if (Array.isArray(backendField.value)) {
    if (
      backendField.kind === FieldKind.MULTI_SELECT_CUSTOM_TEXT &&
      backendField.value.find(
        (selectedOption) => selectedOption === OTHER_LOCALISATION_STRING,
      )
    ) {
      return {
        ...backendField,
        value: backendField.value.map((selectedOption) => {
          if (selectedOption === OTHER_LOCALISATION_STRING) {
            return customUserInputValue ?? '';
          }
          return selectedOption;
        }),
      };
    }
  }

  return backendField;
};

const mapToBackendFraSection = (section: FraFormSection): FraFormSection => {
  return { ...section, fields: section.fields.map(mapToBackendField) };
};

export const mapToBackendFatigueRiskAssessment = (
  fraSubmissionPayload: FraSubmissionPayload,
): BackendFatigueRiskAssessment => ({
  id: fraSubmissionPayload.id,
  uuid: fraSubmissionPayload.uuid,
  form: {
    ...fraSubmissionPayload.form,
    sections: fraSubmissionPayload.form.sections.map(mapToBackendFraSection),
  },
  sent_at: formatISO(fraSubmissionPayload.sentAt),
});

export const parseForm = (form: any): FraForm => {
  const { hasVideo: _, ...filteredForm } = form;
  return {
    ...filteredForm,
    sections: form.sections.map(parseSection),
  };
};

const parseSection = (section: any): FraFormSection => {
  const isConditionallyShown = !!section?.config?.showFormula;
  const fields = section.fields
    .filter((field) => field.kind !== FieldKind.SEPARATOR)
    .map((field: any) => parseField(field, isConditionallyShown));

  return {
    ...section,
    fields,
    config: { ...section.config, isShowing: !isConditionallyShown },
  };
};

const parseField = (
  field: any,
  isConditionallyShown: boolean,
): SectionField => {
  const { sectionOrder: _, hasMedia: __, ...filteredField } = field;

  switch (field.kind) {
    case FieldKind.SINGLE_SELECT:
    case FieldKind.BINARY_RADIO_BUTTON:
    case FieldKind.SINGLE_SELECT_INLINE:
      return {
        ...filteredField,
        value: field.value ? field.value : undefined,
        label: field.title,
        isConditionallyShown,
      };
    case FieldKind.MULTI_SELECT_CUSTOM_TEXT:
      return {
        ...filteredField,
        value: field.value ? field.value : [],
        label: field.title,
        customUserInputValue: '',
        isConditionallyShown,
      };
    default:
      return field;
  }
};

const sortableBackendFieldsByName = {
  [fatigueLiveDataFields.SHIFT_TIME_SINCE_LAST_BREAK]:
    'shift_time_since_last_break',
  [fatigueLiveDataFields.TOTAL_SHIFT_TIME]: 'total_shift_time',
  [fatigueHistoricDataFields.STARTED_AT]: 'started_at',
  [fatigueHistoricDataFields.FINISHED_AT]: 'finished_at',
  [fatigueHistoricDataFields.CREATED_AT]: 'created_at',
  [fatigueHistoricDataFields.UPDATED_AT]: 'updated_at',
  [fatigueHistoricDataFields.DURATION]: 'duration',
};

export const mapToBackendSort = (sort: Sort): Sort => {
  return {
    ...sort,
    field: sortableBackendFieldsByName[sort.field] ?? sort.field,
  };
};

import { TagContainer, WarningIcon } from './styled';
import theme from 'theme';

type Props = {
  fitContent?: boolean;
  extraStyles?: React.CSSProperties;
  text: string;
};

export const BlackWarningTag = ({
  fitContent,
  extraStyles,
  text,
}: Props): JSX.Element => {
  return (
    <TagContainer
      color={theme.colors.white}
      $backgroundColor={theme.colors.black}
      $fitContent={fitContent}
      style={extraStyles}
    >
      <WarningIcon />
      {text}
    </TagContainer>
  );
};

import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useLogs, useText } from 'hooks';
import { Dates } from 'helpers';
import theme from 'theme';
import { Warning } from 'assets/icons';
import { Loader, Navbar, Button, Media } from 'components/ui';
import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting, selectJobsView } from 'store/selectors';
import { useParams } from 'react-router-dom';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

const { colors, fonts, fontSizes, media: themeMedia, mixins } = theme;

// Styled Components
const Header = styled.div`
  height: 50px;
  ${mixins.flexBetween};
  background-color: ${colors.lightGrey};
  padding: 0 25px;
  ${themeMedia.sm`
    padding: 0 15px;
  `}
`;

const Creator = styled.p`
  font-family: ${fonts.bold};
`;

const CreationDate = styled.p`
  color: ${colors.darkGrey};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 55px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 25px;
  ${themeMedia.sm`
    padding: 25px 15px;
  `}
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 15px;
`;

const SectionTitle = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
`;

const SectionSubtitle = styled.p`
  padding-bottom: 24px;
`;

const IconBox = styled.div`
  ${mixins.flexFullCenter};
  border: 3px solid ${colors.black};
  padding: 6px;
  margin-left: 20px;
`;

const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: 20px;
  width: 20px;
`;

const StyledLinkButton = styled(Button.PrimaryLink)`
  ${themeMedia.sm`
    width: 100%;
    position: fixed;
    bottom: 0;
  `}
`;

const ResolutionWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`;

const ResolutionLabel = styled.div`
  font-family: ${fonts.bold};
  background-color: ${colors.lightGrey};
  color: ${colors.black};
  padding: 10px;
`;

const ResolutionNotes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  color: ${colors.black};
`;

const ResolutionNotesHeader = styled.div`
  font-family: ${fonts.bold};
  margin-right: 10px;
`;

// Blocker.Show
const Show = () => {
  const { jobId, blockerId } = useParams();
  const { log: blocker, media } = useLogs(blockerId);
  const getText = useText();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const view = useSelector(selectJobsView);

  useEffect(() => {
    if (jobId && blockerId && blocker) {
      Analytics.trackPage(constants.EVENT_PAGE_VIEW_JOB_BLOCKER, {
        jobId,
        blockerId,
        category: blocker?.jobBlocker?.title,
      });
    }
  }, [jobId, blockerId, blocker]);

  return (
    <>
      <Navbar
        title={getText('job_blocker_detail_title')}
        defaultBackPath={`/jobs/${jobId}/logs`}
        mobileOnly
      />
      {blocker ? (
        <>
          <Container>
            {media && (
              <Media
                kind='blocker'
                type={media.type}
                url={media.signedUrl}
                urls={media.signedUrls}
                height='425px'
              />
            )}
            <Header>
              <Creator>{blocker.createdBy.fullName}</Creator>
              <CreationDate>
                {Dates.formatDateTime(
                  blocker.createdAt,
                  getText('date_today'),
                  getText('date_yesterday'),
                )}
              </CreationDate>
            </Header>
            <Section>
              <SectionHeader>
                <SectionTitle>
                  {blocker?.jobBlocker &&
                    getLocalizedCamelCaseValue(
                      blocker.jobBlocker,
                      'title',
                      globalAppLanguage,
                    )}
                </SectionTitle>

                <IconBox>
                  <Icon component={<Warning />} />
                </IconBox>
              </SectionHeader>
              {blocker?.jobBlockerSubtype && (
                <SectionSubtitle>
                  {getLocalizedCamelCaseValue(
                    blocker.jobBlockerSubtype,
                    'title',
                    globalAppLanguage,
                  )}
                </SectionSubtitle>
              )}
              <p>{blocker.note}</p>
              {blocker.jobBlockerResolution && (
                <ResolutionWrapper>
                  <ResolutionLabel>
                    {getText('job_blocker_detail_resolution', {
                      name: `${blocker.jobBlockerResolution.createdBy.fullName}`,
                      time: Dates.formatDateTime(
                        blocker.jobBlockerResolution.createdAt,
                        getText('date_today'),
                        getText('date_yesterday'),
                      ),
                    })}
                  </ResolutionLabel>
                  {blocker.jobBlockerResolution.note && (
                    <ResolutionNotes>
                      <ResolutionNotesHeader>
                        {getText('job_blocker_detail_resolution_note')}
                      </ResolutionNotesHeader>
                      {blocker.jobBlockerResolution.note}
                    </ResolutionNotes>
                  )}
                </ResolutionWrapper>
              )}
            </Section>
          </Container>
          {blocker.resolutionRequired && !blocker.jobBlockerResolution && (
            <StyledLinkButton
              event={constants.EVENT_JOB_BLOCKER_RESOLVE_CLICKED}
              eventProperties={{
                logId: blockerId,
                jobId,
                [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
              }}
              text={getText('job_blocker_detail_resolve_button')}
              width='328px'
              to={`/jobs/${jobId}/blockers/${blockerId}/resolve-blocker`}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Show;

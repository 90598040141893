import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile, useText } from 'hooks';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import RootState from 'model/State/RootState';
import { selectHasOrganizationSettingEnabled } from 'store/selectors';
import { signOut } from 'store/actions/session';
import { resetActiveJob } from 'store/actions/jobs';
import { WorkspaceSelect } from './WorkspaceSelect';
import { ModalType } from 'model/enum/ModalType';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ActionWrapper,
  ArrowIcon,
  BottomDrawerItem,
  Container,
  Drawer,
  DrawerItem,
  DrawerText,
  ExitIcon,
  FirstDrawerItem,
  HamburgerIcon,
  HamburgerWrapper,
  Header,
  IconWrapper,
  LastRefreshWrapper,
  LastUpdated,
  Logo,
  LogoContainer,
  OrganisationLogo,
  OrganisationLogoWrapper,
  RightArrowIcon,
  Title,
} from './styled';

type Props = {
  title?: string | null;
  defaultBackPath?: string;
  onBack?: () => void;
  home?: boolean;
  mobileOnly?: boolean;
};

/* Navbar */
export const Navbar = ({
  title,
  defaultBackPath,
  onBack = () => {},
  home = false,
  mobileOnly = false,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const getText = useText();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { lastRefresh, selectedWorkspace, currentUser, workspaces } =
    useSelector((state: RootState) => state.session);
  const languageSelectionEnabled = useSelector(
    selectHasOrganizationSettingEnabled('enable_language_selection'),
  );
  const intercomBannerHeight = document.getElementsByName(
    'intercom-banner-frame',
  )[0]?.offsetHeight;

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleGoHome = () => {
    Analytics.trackEvent(constants.EVENT_CLICK_HOME);
    dispatch(resetActiveJob());
    navigate(`/jobs${location?.search}`);
    setIsDrawerOpen(false);
  };

  const handleGoBack = () => {
    // FIXME: Can't get history.length.
    // if (history.length > 2) {
    //   navigate(-1);
    // }

    // If 'history.length' is small than 2 this is the first page visited, so use 'defaultBackPath'
    if (defaultBackPath) {
      navigate(defaultBackPath);
      onBack();
    }
  };

  const handleLanguage = () => {
    navigate(location.pathname + `?modal=${ModalType.CHANGE_LANGUAGE}`);
    handleToggleDrawer();
  };

  const handleSettings = () => {
    Analytics.trackEvent(constants.EVENT_VIEW_SETTINGS);
    navigate(`/jobs/settings`);
    handleToggleDrawer();
  };

  const handleInsights = () => {
    Analytics.trackEvent(constants.EVENT_VIEW_INSIGHTS);
    navigate(`/jobs/insights`);
    handleToggleDrawer();
  };

  const handleFatigueManager = () => {
    Analytics.trackEvent(constants.EVENT_VIEW_FATIGUE_MANAGER);
    navigate(`/jobs/fatigue-manager`);
    handleToggleDrawer();
  };

  const handleCommandCenter = () => {
    Analytics.trackEvent(constants.EVENT_VIEW_COMMAND_CENTER);
    navigate(`/jobs/command-center`);
    handleToggleDrawer();
  };

  if (mobileOnly && !isMobile) {
    return null;
  }
  if (home) {
    return (
      <>
        <Container>
          <LogoContainer>
            <Logo mobile={isMobile} onClick={handleGoHome} />
            {selectedWorkspace?.logo && (
              <OrganisationLogoWrapper>
                <OrganisationLogo src={selectedWorkspace.logo} />
              </OrganisationLogoWrapper>
            )}
          </LogoContainer>
          <ActionWrapper>
            <LastRefreshWrapper>
              <LastUpdated>{getText('navbar_last_updated')}</LastUpdated>
              <span>{lastRefresh}</span>
            </LastRefreshWrapper>
            {!isMobile && workspaces.length > 1 && <WorkspaceSelect />}
            <HamburgerWrapper>
              <HamburgerIcon
                onClick={() => {
                  Analytics.trackEvent(constants.EVENT_CLICK_MENU);
                  handleToggleDrawer();
                }}
              />
            </HamburgerWrapper>

            <Drawer
              anchor='right'
              open={isDrawerOpen}
              onClose={handleToggleDrawer}
              offsetHeight={intercomBannerHeight}
            >
              <FirstDrawerItem onClick={handleGoHome}>
                <DrawerText>{getText('navbar_drawer_home')}</DrawerText>
                <RightArrowIcon />
              </FirstDrawerItem>
              {languageSelectionEnabled && (
                <DrawerItem onClick={handleLanguage}>
                  <DrawerText>{getText('navbar_drawer_language')}</DrawerText>
                  <RightArrowIcon />
                </DrawerItem>
              )}
              <DrawerItem onClick={handleInsights}>
                <DrawerText>{getText('navbar_drawer_insights')}</DrawerText>
                <RightArrowIcon />
              </DrawerItem>
              {currentUser?.permissions?.includes('fatigue_dashboard_v1') && (
                <DrawerItem onClick={handleFatigueManager}>
                  <DrawerText>
                    {getText('navbar_drawer_fatigue_manager')}
                  </DrawerText>
                  <RightArrowIcon />
                </DrawerItem>
              )}
              {currentUser?.permissions?.includes('command_center_v1') && (
                <DrawerItem onClick={handleCommandCenter}>
                  <DrawerText>
                    {getText('navbar_drawer_command_centre')}
                  </DrawerText>
                  <RightArrowIcon />
                </DrawerItem>
              )}
              <DrawerItem onClick={handleSettings}>
                <DrawerText>{getText('navbar_drawer_settings')}</DrawerText>
                <RightArrowIcon />
              </DrawerItem>
              <BottomDrawerItem onClick={() => dispatch(signOut())}>
                <DrawerText>{getText('navbar_drawer_logout')}</DrawerText>
                <ExitIcon />
              </BottomDrawerItem>
            </Drawer>
          </ActionWrapper>
        </Container>
        {isMobile && workspaces.length > 1 && <WorkspaceSelect />}
      </>
    );
  }

  return (
    <Container>
      <Header>
        <IconWrapper onClick={handleGoBack}>
          <ArrowIcon />
        </IconWrapper>
        {title && <Title>{title}</Title>}
      </Header>
    </Container>
  );
};

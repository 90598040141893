import { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useIsMobile, useQueryParams, useText } from 'hooks';
import * as constants from 'utils/constants';
import { Card, Loader, Message, Navbar } from 'components/ui';
import theme from 'theme';
import { SkeletonCardContainer } from 'components/ui/Shared/EmptyState';
import PermitStatus from './PermitStatus';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPermits } from 'store/actions/jobs';
import {
  selectIsFetchingPermits,
  selectJobPermits,
  selectJobsView,
} from 'store/selectors';

const { media } = theme;

/* Styled Components */
const Container = styled.div`
  height: 100%;
  ${media.sm`
    padding: 25px 15px;
    overflow: scroll;
  `}
`;

const Wrapper = styled.div`
  margin-bottom: 8px;
`;

/* Permits.List */
export const List = () => {
  const params = useParams();
  const isMobile = useIsMobile();
  const getText = useText();
  const dispatch = useDispatch();
  const { getPathWithQueryParams } = useQueryParams();
  const permits = useSelector(selectJobPermits(params?.jobId));
  const isFetchingPermits = useSelector(selectIsFetchingPermits);
  const view = useSelector(selectJobsView);

  useEffect(() => {
    dispatch(fetchPermits(params?.jobId));
  }, [params?.jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Navbar
        title={getText('job_permits')}
        defaultBackPath={`/jobs/${params?.jobId}`}
        mobileOnly
      />

      <Container>
        {isFetchingPermits ? (
          <Loader />
        ) : (
          <>
            {!permits?.length ? (
              <SkeletonCardContainer>
                {getText('permit_no_display')}
              </SkeletonCardContainer>
            ) : (
              permits?.map((permit) =>
                isMobile ? (
                  <Message.Action
                    key={`permit-${permit.id}`}
                    event={constants.EVENT_VIEW_PERMIT_ITEM}
                    eventProperties={{
                      jobId: params?.jobId,
                      permitId: permit.id,
                      permitType: permit.type ? permit.type.title : 'Permit',
                      source: 'job-overview',
                      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                    }}
                    type={getText('job_details_timeline_action_permit')}
                    subtype={permit?.type?.title}
                    thumbnail={permit?.thumbnail}
                    sender={permit.createdBy}
                    date={permit.createdAt}
                    to={`/jobs/${permit.jobId}/permits/${permit.id}`}
                    isLocked={false}
                    isPermit
                    lastApproval={permit.lastApproval}
                    qualityIndicator={permit?.videos?.[0]?.qualityIndicator}
                  />
                ) : (
                  <Wrapper key={`permit-${permit.id}`}>
                    <Card.Permit
                      event={constants.EVENT_VIEW_PERMIT_ITEM}
                      eventProperties={{
                        jobId: params.jobId,
                        permitId: permit.id,
                        permitType: permit.type ? permit.type.title : 'Permit',
                        source: 'job-overview',
                        [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                      }}
                      type={getText('job_details_timeline_action_permit')}
                      subtype={permit?.type?.title}
                      creator={permit.createdBy}
                      date={permit.createdAt}
                      to={getPathWithQueryParams(
                        `/jobs/${permit.jobId}/permits/${permit.id}`,
                      )}
                      customMargin='10px 0 0'
                    />
                    <PermitStatus
                      key={`permit-status-${permit.id}`}
                      lastApproval={permit.lastApproval}
                    />
                  </Wrapper>
                ),
              )
            )}
          </>
        )}
      </Container>
    </>
  );
};

import Job from 'model/JobData';
import {
  LEGACY_VIEW,
  LIST_VIEW,
  LIST_VIEW_TAB,
  MAP_VIEW_TAB,
} from 'utils/constants';

export const isEmptyLegacyView = (view: string, jobs: Job[] | null): boolean =>
  view === LEGACY_VIEW && !jobs;

export const isEmptyView = (
  view: string,
  listJobs: Job[] | null,
  mapJobs: Job[] | null,
): boolean =>
  ((view === LIST_VIEW_TAB || view === LIST_VIEW) && !listJobs) ||
  (view === MAP_VIEW_TAB && !mapJobs);

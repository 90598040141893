import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const DownloadRequest = () => {
  const navigate = useNavigate();
  const { objectId } = useParams();

  if (objectId) {
    sessionStorage.setItem('downloadObjectId', objectId);
  }

  useEffect(() => {
    navigate('/login', { replace: true });
  });

  return null;
};

import styled from 'styled-components';
import theme from 'theme';
import { Arrow, Cross } from 'assets/icons';
import { Button } from 'components/ui';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveJobNearbyPath } from 'store/actions/jobs';
import { selectNearbyJobPath } from 'store/selectors';
import { useText } from 'hooks';

const { colors, media, mixins, fontSizes } = theme;

const VerticalContainer = styled.div`
  ${mixins.flexColumn}
`;

const Wrapper = styled.div`
  background-color: ${colors.black};
  ${mixins.flexBetween}
  padding: 20px 32px;
  position: relative;
`;

const Title = styled.div`
  color: ${colors.yellow};
  font-size: ${fontSizes.l};
  height: ${fontSizes.l};
`;

const CrossIcon = styled(Cross)`
  height: 20px;
  width: 20px;
  cursor: pointer;
  position: absolute;
  right: 32px;
  top: 20px;
  .fill-selector {
    fill: ${colors.yellow};
  }
  ${media.xl`
    height: 15px;
    width: 15px;
  `};
`;

const ArrowLeft = styled(Arrow)`
  transform: rotate(180deg);
`;

const DrawerJobHeader = ({ title, onClose }): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getText = useText();
  const { jobId } = useParams();
  const nearbyJobPath = useSelector(selectNearbyJobPath(jobId));

  const returnToNearbyJobs = () => {
    if (nearbyJobPath) {
      dispatch(setActiveJobNearbyPath(jobId, null));
      navigate(nearbyJobPath);
    }
  };
  return (
    <VerticalContainer>
      {nearbyJobPath && (
        <Button.Secondary
          iconFront
          IconComponent={ArrowLeft}
          text={getText('job_details_return_to_site_briefing')}
          onClick={returnToNearbyJobs}
        />
      )}
      <Wrapper>
        <Title>{title}</Title>
        <CrossIcon onClick={onClose} />
      </Wrapper>
    </VerticalContainer>
  );
};

export default DrawerJobHeader;

import 'react-toastify/dist/ReactToastify.css';
import { useIsMobile } from 'hooks';
import { Toast } from './styled';
import { toast } from 'react-toastify';
import { Notification, ToastPosition, ToastType } from './constants';
import theme from 'theme';
import { useEffect } from 'react';
import { ToastContent } from './ToastContent';

const { colors } = theme;

type Props = {
  containerId: string;
  type: ToastType;
  notifications: Notification[];
  onClose: (id: number) => void;
  autoClose?: number | false;
  light?: boolean;
};

export const ToastBase = ({
  containerId,
  type,
  notifications,
  onClose,
  autoClose = false,
  light = false,
}: Props): JSX.Element => {
  const isMobile = useIsMobile();

  const getBackgroundColor = (): string => {
    switch (type) {
      case ToastType.INFO:
        return colors.blue;
      case ToastType.WARNING:
        return colors.orange;
      case ToastType.SUCCESS:
        return colors.green;
      case ToastType.ERROR:
        return colors.darkRed;
      case ToastType.SECONDARY:
      default:
        return colors.lightGrey;
    }
  };

  const dismissToast = (id: number) => {
    toast.dismiss(`${containerId}-${id}`);
    onClose(id);
    toast.clearWaitingQueue({ containerId });
  };

  useEffect(() => {
    notifications.forEach((notification) => {
      if (!toast.isActive(`${containerId}-${notification.id}`)) {
        toast(
          <ToastContent
            notification={notification}
            type={type}
            onClose={(id) => dismissToast(id)}
            light={light}
            children={notification.customComponent}
          />,
          {
            containerId,
            toastId: `${containerId}-${notification.id}`,
            onClose: () => dismissToast(notification.id),
          },
        );
      }
    });
  }, [notifications]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Toast
      isMobile={isMobile}
      pauseOnFocusLoss={false}
      limit={4}
      autoClose={autoClose}
      closeOnClick={false}
      closeButton={false}
      draggable={false}
      hideProgressBar
      containerId={containerId}
      backgroundColor={getBackgroundColor()}
      position={isMobile ? ToastPosition.TOP_CENTER : ToastPosition.BOTTOM_LEFT}
    />
  );
};

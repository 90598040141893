import theme from 'theme';
import { Button } from 'components/ui';
import { useText } from 'hooks';
import { DownloadIcon } from './styled';

const { colors, fontSizes } = theme;

type Props = {
  onClick: () => void;
  disabled: boolean;
  loading: boolean;
  event: string;
  eventProperties: object;
};

export const ExportExcelButton = ({
  onClick,
  disabled,
  loading,
  event,
  eventProperties,
}: Props) => {
  const getText = useText();

  return (
    <Button.Secondary
      text={getText('insights_havs_export_data_button')}
      width='200px'
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      IconComponent={DownloadIcon}
      extraStyles={{
        height: '44px',
        backgroundColor: `${colors.offWhite}`,
        fontSize: `${fontSizes.s}`,
        padding: '0 10px',
        paddingLeft: '10px',
        alignSelf: 'auto',
      }}
      event={event}
      eventProperties={eventProperties}
    />
  );
};

import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useText } from 'hooks';
import { Notification, ToastType } from './constants';
import { ToastBase } from './ToastBase';
import { useDispatch, useSelector } from 'react-redux';
import { resetInfo, selectInfoList } from 'store/slices/notifications';
import InfoItem from 'model/State/notifications/InfoItem';
import { DownloadButton } from './styled';
import { downloadDocument } from 'store/actions/documents';
import { DownloadSource } from 'model/enum/DownloadSource';
import * as Analytics from 'utils/analytics';
import { EVENT_DOWNLOAD_TOAST_POPUP_LINK_CLICKED } from 'components/views/Protected/Jobs/PdfExport/constants';
import {
  getPdfExportDataFromLocalStorage,
  removePdfExportDataFromLocalStorage,
} from 'helpers/files';
import { toast } from 'react-toastify';

export const InfoToast = (): JSX.Element => {
  const infos = useSelector(selectInfoList);
  const getText = useText();
  const dispatch = useDispatch();
  const CONTAINER_ID = 'info-container';
  const [notificationList, setNotificationList] = useState<Notification[]>([]);

  const getInfoDetails = (info: InfoItem): Notification => {
    return {
      id: info.id,
      title: info.isFileExport ? getText('pdf_export_ready_toast_title') : '',
      message: info.isFileExport
        ? getText('pdf_export_ready_toast_message')
        : '',
      ...(info?.objectId && {
        download: {
          objectId: info?.objectId,
          fileName: info?.fileName,
        },
        customComponent: (
          <DownloadButton
            variant='text'
            disableRipple
            onClick={(event) => onDownloadClick(event, info)}
          >
            <span>
              <mark>{info?.fileName}</mark>
            </span>
          </DownloadButton>
        ),
      }),
      canBeDismissed: true,
    };
  };

  useEffect(() => {
    setNotificationList(infos.map((info: InfoItem) => getInfoDetails(info)));
  }, [infos]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClose = (id: number) => {
    dispatch(resetInfo(id));

    const itemToRemove = infos.find((info) => info.id === id);
    if (itemToRemove?.objectId) {
      removePdfExportDataFromLocalStorage(itemToRemove.objectId);
    }
  };

  const onDownloadClick = (event, item: InfoItem) => {
    event.preventDefault();
    if (item?.objectId) {
      dispatch(
        downloadDocument(item.objectId, DownloadSource.TOAST, item?.fileName),
      );
      Analytics.trackEvent(EVENT_DOWNLOAD_TOAST_POPUP_LINK_CLICKED, {
        ...getPdfExportDataFromLocalStorage(item.objectId),
      });
    }
    onClose(item.id);
    toast.dismiss(`${CONTAINER_ID}-${item.id}`);
    toast.clearWaitingQueue({ containerId: CONTAINER_ID });
  };

  return (
    <ToastBase
      containerId={CONTAINER_ID}
      type={ToastType.INFO}
      notifications={notificationList}
      onClose={(id) => onClose(id)}
      autoClose={false}
      light
    />
  );
};

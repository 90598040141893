import { getTimeSince } from 'helpers/dates';
import theme from 'theme';
import { TagContainer, WarningIcon } from './styled';

type Props = {
  text: string;
  date?: Date;
  isSmall?: boolean;
  extraStyles?: React.CSSProperties;
};

export const WarningTag = ({
  text,
  date,
  isSmall,
  extraStyles,
}: Props): JSX.Element => (
  <TagContainer
    color={theme.colors.black}
    $backgroundColor={theme.colors.orange}
    style={{ ...extraStyles, fontSize: theme.fontSizes.s }}
  >
    <WarningIcon color={theme.colors.black} $isSmall={isSmall} />
    {text} {date && `(${getTimeSince(date)})`}
  </TagContainer>
);

import { formatDateForApi } from 'helpers/dates';
import { add, sub } from 'date-fns';
import axios from 'axios';
import { getSortString } from 'helpers/apiHelpers';
import Sort from 'model/Sort';
import { PaginatedData } from 'model/PaginatedData';
import { BackendFatigueHistoricItem, BackendFatigueLiveItem } from './types';
import {
  mapToBackendFatigueRiskAssessment,
  mapToBackendSort,
  mapToFatigueHistoricItem,
  mapToFatigueLiveItem,
} from './helpers';
import { FatigueLiveViewItem } from 'model/FatigueManager/FatigueLiveViewItem';
import { FatigueHistoricItem } from 'model/FatigueManager/FatigueHistoricDataItem';
import { v4 } from 'uuid';
import FraForm from 'model/FatigueManager/FraForm';

export const getLiveData = async (
  depotId: number,
  search: string,
  selectedWorkspaceId: string,
  userIds: string | null,
  sort: Sort,
  page: number,
  pageSize: number,
): Promise<PaginatedData<FatigueLiveViewItem>> => {
  const { field: sortField, order: sortDirection } = mapToBackendSort(sort);

  const params = {
    depot_id: depotId,
    start_date: formatDateForApi(sub(new Date(), { days: 7 })),
    end_date: formatDateForApi(add(new Date(), { days: 1 })),
    ...(search ? { search } : {}),
    ...(selectedWorkspaceId ? { workspace_uuid: selectedWorkspaceId } : {}),
    ...(userIds?.length ? { user_ids: userIds } : {}),
    order_by: sortField,
    order_by_dir: sortDirection,
    page_size: pageSize,
    page,
  };

  const { data }: { data: PaginatedData<BackendFatigueLiveItem> } =
    await axios.get('/fatigue/insights/', {
      params,
    });

  return { ...data, results: data.results.map(mapToFatigueLiveItem) };
};

export const getHistoricData = async (
  depotId: number,
  search: string,
  startDate: string | null,
  endDate: string | null,
  minimumDuration: number | null,
  selectedWorkspaceId: string,
  userIds: string | null,
  sort: Sort,
  page: number,
  pageSize: number,
): Promise<PaginatedData<FatigueHistoricItem>> => {
  const { field: sortField, order: sortDirection } = mapToBackendSort(sort);
  const params = {
    depot_id: depotId,
    start_date: formatDateForApi(startDate ?? sub(new Date(), { days: 7 })),
    end_date: formatDateForApi(endDate ?? add(new Date(), { days: 1 })),
    ...(selectedWorkspaceId ? { workspace_uuid: selectedWorkspaceId } : {}),
    ...(userIds?.length ? { user_ids: userIds } : {}),
    ...(search ? { search } : {}),
    ...(minimumDuration ? { minimum_duration: minimumDuration } : {}),
    order_by: getSortString(sortField, sortDirection),
    page_size: pageSize,
    page,
  };

  const { data }: { data: PaginatedData<BackendFatigueHistoricItem> } =
    await axios.get('/fatigue/historical-insights/', {
      params,
    });

  return { ...data, results: data.results.map(mapToFatigueHistoricItem) };
};

export const submitFatigueRiskAssessmentForm = async (
  fatigueRiskAssessmentId: number,
  form: FraForm,
  sentAt: Date,
) => {
  await axios.patch(
    `fatigue/risk-assessment/${fatigueRiskAssessmentId}/`,
    mapToBackendFatigueRiskAssessment({
      id: fatigueRiskAssessmentId,
      form,
      uuid: v4(),
      sentAt,
    }),
  );
};

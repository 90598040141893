import { WorkOrderItem as WorkOrderItemIcon } from 'assets/icons';
import { useText, useQueryParams } from 'hooks';
import { useSelector } from 'react-redux';
import * as Analytics from 'utils/analytics';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { Message } from 'model/Message';
import JobData from 'model/JobData';
import { getWorkOrderSubtype } from 'helpers/apiHelpers';

import { MessageWithAction } from './MessageWithAction';
import { mapToWorkOrderItem } from 'api/WorkOrderItem/helpers';

type Props = {
  message: Message;
  activeJob: JobData;
  onClick: () => void;
};

export const WorkOrderItem = Analytics.withTrackEvent(
  ({ message, activeJob, onClick }: Props): JSX.Element => {
    const getText = useText();
    const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
    const { getPathWithQueryParams } = useQueryParams();

    const workOrderItem = mapToWorkOrderItem(message.target);
    const type = getText('job_details_timeline_action_work_completed');
    const subtype = getWorkOrderSubtype(
      workOrderItem.subtype,
      activeJob,
      globalAppLanguage,
    );
    const to = getPathWithQueryParams(
      `/jobs/${activeJob.id}/work-completed/${workOrderItem.id}`,
    );

    return (
      <MessageWithAction
        message={message}
        onClick={onClick}
        to={to}
        type={type}
        subtype={subtype}
        actionIcon={<WorkOrderItemIcon />}
      />
    );
  },
);

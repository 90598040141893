import { useEffect, useMemo, useState } from 'react';
import theme from 'theme';
import styled from 'styled-components';
import {
  selectWorkspaceHasSettingEnabled,
  selectUserHasPermission,
  selectWorkspaceSettings,
} from 'store/selectors';
import { useText } from 'hooks';
import * as Analytics from 'utils/analytics';
import { useSelector } from 'react-redux';
import NotificationSettings from './NotificationSettings';
import { UserSettings } from './UserSettings';
import BulkJobUploadSettings from './BulkJobUploadSetting';
import { WorkspaceSettingsTab } from './WorkspaceSettings/WorkspaceSettingsTab';
import { Button, Display } from 'components/ui';
import Tab from 'model/Tab/Tab';
import TabPanel from 'model/Tab/TabPanel';
import RiskAssessmentSettings from './RiskAssessmentSettings';
import {
  EVENT_VIEW_BULK_JOB_UPLOAD,
  EVENT_VIEW_HAVS_SETTINGS,
  EVENT_VIEW_NOTIFICATIONS,
  EVENT_VIEW_SETTINGS_ASSESSMENT,
  EVENT_VIEW_SETTINGS_USERS,
  EVENT_VIEW_SETTINGS_WORKSPACE,
  EVENT_VIEW_WORKFLOW_SETTINGS,
  SettingsTabs,
  UserPermissions,
  WorkspaceSettings,
} from './constants';
import { HavsSettings } from './HavsSettings';
import { WorkflowSettings } from './WorkflowSettings';
import { WorkflowEditor } from './WorkflowSettings/components/WorkflowEditor';
import { FullScreenContainer } from './WorkflowSettings/styled';
import {
  selectActiveWorkflowId,
  setActiveWorkflowId,
} from 'store/slices/workflows';
import { useDispatch } from 'react-redux';

const { colors, fonts, fontSizes, mixins, media } = theme;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.white};
  ${mixins.flexColumn};
  ${media.sm`
    height: 100vh;
 `}
`;

const H1 = styled.h1`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  margin-bottom: 8px !important;
`;

const SettingsWrapper = styled.div`
  padding: 40px 0 0;
  height: 100%;
  ${media.sm`
    padding: 30px;
 `}
  overflow-y: auto;
  box-sizing: border-box;
`;

const Header = styled.div`
  height: 10%;
  display: flex;
  padding: 0 80px 10px 80px;
  box-sizing: border-box;
  ${media.lg`
    height: 18%;
  `}
`;

const Settings = () => {
  const getText = useText();
  const dispatch = useDispatch();
  const renderUserSettings = useSelector(
    selectUserHasPermission(UserPermissions.USERS_ADMIN),
  );
  const renderWorkspaceSettings = useSelector(
    selectUserHasPermission(UserPermissions.WORKSPACE_ADMIN),
  );
  const renderRiskAssessmentSettings = useSelector(
    selectWorkspaceHasSettingEnabled(WorkspaceSettings.RISK_ASSESSMENT),
  );
  const renderHAVSSettings = useSelector(
    selectUserHasPermission(UserPermissions.HAVS),
  );
  const renderWorkflowSettings = useSelector(
    selectUserHasPermission(UserPermissions.WORKFLOW_SETUP),
  );
  const activeWorkflowId = useSelector(selectActiveWorkflowId);

  const { bulk_upload_jobs_template } = useSelector(selectWorkspaceSettings);
  const renderBulkJobsUploadTemplate = !!bulk_upload_jobs_template;

  const [tabKey, setTabKey] = useState<string>(
    renderUserSettings ? SettingsTabs.USERS : SettingsTabs.NOTIFICATIONS,
  );

  const tabs: Tab[] = useMemo(
    () => [
      {
        label: getText('settings_tab_users'),
        value: SettingsTabs.USERS,
        isShown: renderUserSettings,
      },
      {
        label: getText('settings_tab_bulk_job_upload'),
        value: SettingsTabs.BULK_JOB_UPLOAD,
        isShown: renderBulkJobsUploadTemplate,
      },
      {
        label: getText('settings_tab_notifications'),
        value: SettingsTabs.NOTIFICATIONS,
        isShown: true,
      },
      {
        label: getText('settings_tab_workspace'),
        value: SettingsTabs.WORKSPACE,
        isShown: renderWorkspaceSettings,
      },
      {
        label: getText('settings_tab_assessments'),
        value: SettingsTabs.ASSESSMENTS,
        isShown: renderWorkspaceSettings && renderRiskAssessmentSettings,
      },
      {
        label: getText('settings_tab_havs'),
        value: SettingsTabs.HAVS,
        isShown: renderHAVSSettings,
      },
      {
        label: getText('settings_tab_workflows'),
        value: SettingsTabs.WORKFLOWS,
        isShown: renderWorkflowSettings,
      },
    ],
    [
      getText,
      renderUserSettings,
      renderBulkJobsUploadTemplate,
      renderRiskAssessmentSettings,
      renderWorkspaceSettings,
      renderHAVSSettings,
      renderWorkflowSettings,
    ],
  );

  const tabPanels: TabPanel[] = [
    { value: SettingsTabs.USERS, children: <UserSettings /> },
    {
      value: SettingsTabs.BULK_JOB_UPLOAD,
      children: <BulkJobUploadSettings />,
    },
    { value: SettingsTabs.NOTIFICATIONS, children: <NotificationSettings /> },
    { value: SettingsTabs.WORKSPACE, children: <WorkspaceSettingsTab /> },
    { value: SettingsTabs.ASSESSMENTS, children: <RiskAssessmentSettings /> },
    { value: SettingsTabs.HAVS, children: <HavsSettings /> },
    {
      value: SettingsTabs.WORKFLOWS,
      children: <WorkflowSettings />,
    },
  ];

  const eventByTab = {
    [SettingsTabs.USERS]: EVENT_VIEW_SETTINGS_USERS,
    [SettingsTabs.NOTIFICATIONS]: EVENT_VIEW_NOTIFICATIONS,
    [SettingsTabs.BULK_JOB_UPLOAD]: EVENT_VIEW_BULK_JOB_UPLOAD,
    [SettingsTabs.WORKSPACE]: EVENT_VIEW_SETTINGS_WORKSPACE,
    [SettingsTabs.ASSESSMENTS]: EVENT_VIEW_SETTINGS_ASSESSMENT,
    [SettingsTabs.HAVS]: EVENT_VIEW_HAVS_SETTINGS,
    [SettingsTabs.WORKFLOWS]: EVENT_VIEW_WORKFLOW_SETTINGS,
  };

  const onTabChange = (key: string) => {
    setTabKey(key);
  };

  useEffect(() => {
    Analytics.trackEvent(eventByTab[tabKey]);
  }, [tabKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getAvailableTabKey = (key: string): string => {
      const shouldDisplay = tabs.find((tab) => tab.value === key)?.isShown;

      if (shouldDisplay) {
        return key;
      }

      return tabs.find((tab) => tab.isShown)?.value ?? '';
    };

    setTabKey((prevState) => getAvailableTabKey(prevState));
  }, [
    renderUserSettings,
    renderBulkJobsUploadTemplate,
    renderWorkspaceSettings,
    renderHAVSSettings,
    tabs,
  ]);

  return (
    <Wrapper>
      {renderWorkflowSettings && activeWorkflowId ? (
        <SettingsWrapper>
          <Button.Secondary
            text='Manage workflows'
            onClick={() => dispatch(setActiveWorkflowId(null))}
            showArrowLeft
            showArrowRight={false}
            width='200'
            extraStyles={{ marginBottom: '40px' }}
          />
          <FullScreenContainer>
            <WorkflowEditor />
          </FullScreenContainer>
        </SettingsWrapper>
      ) : (
        <SettingsWrapper>
          <Header>
            <H1>{getText('settings')}</H1>
          </Header>
          <Display.TabView
            value={tabKey}
            onChange={(_, newValue) => onTabChange(newValue)}
            tabs={tabs}
            tabPanels={tabPanels}
          />
        </SettingsWrapper>
      )}
    </Wrapper>
  );
};

export default Settings;

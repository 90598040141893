import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useLogs, useText, useJobs } from 'hooks';
import { Dates } from 'helpers';
import theme from 'theme';
import { Warning } from 'assets/icons';
import {
  Loader,
  Navbar,
  Button,
  Media,
  Modal,
  Badge,
  FormattedDate,
} from 'components/ui';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalLanguageSetting, selectJobsView } from 'store/selectors';
import { useLocation, useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import { fetchActiveJob } from 'store/actions/jobs';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

const {
  colors,
  fonts,
  fontSizes,
  media: themeMedia,
  mixins,
  breakpoints,
} = theme;

// Styled Components
const Header = styled.div`
  height: 70px;
  background-color: ${colors.offWhite};
  border-bottom: 1px solid ${colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
  @media only screen and (max-width: ${breakpoints.sm}px) {
    padding: 0 15px;
  }
`;

const Container = styled.div`
  display: flex;
  height: 80vh;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${colors.white};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  flex-grow: 1;
  overflow: auto;
  ${themeMedia.sm`
    padding: 25px 15px;
  `}
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 15px;
`;

const SectionTitle = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
`;

const SectionSubtitle = styled.p`
  padding-bottom: 24px;
`;

const SectionBody = styled.p`
  padding-bottom: 24px;
  flex-grow: 1;
`;

const IconBox = styled.div`
  ${mixins.flexFullCenter};
  border: 3px solid ${colors.black};
  padding: 6px;
  margin-left: 20px;
`;

const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: 20px;
  width: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const StyledLinkButton = styled(Button.PrimaryLink)`
  ${themeMedia.sm`
    width: 100%;
    max-width: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  `}
`;

const ResolutionWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`;

const ResolutionLabel = styled.div`
  font-family: ${fonts.bold};
  background-color: ${colors.lightGrey};
  color: ${colors.black};
  padding: 10px;
`;

const ResolutionNotes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  color: ${colors.black};
`;

const ResolutionNotesHeader = styled.div`
  font-family: ${fonts.bold};
  margin-right: 10px;
`;

// Blocker.BlockerModal
const BlockerModal = (): JSX.Element => {
  const { jobId, blockerId } = useParams();
  const getText = useText();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { navigateToOverviewTabOrJob } = useJobs();
  const { log: blocker, media } = useLogs(blockerId);
  const { activeJob } = useSelector((state: RootState) => state.jobs);
  const { currentUser } = useSelector((state: RootState) => state.session);
  const view = useSelector(selectJobsView);

  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  useEffect(() => {
    if (!activeJob) {
      dispatch(fetchActiveJob(jobId));
    }
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (jobId && blockerId && blocker) {
      Analytics.trackPage(constants.EVENT_PAGE_VIEW_JOB_BLOCKER, {
        jobId,
        blockerId,
        category: blocker?.jobBlocker?.title,
      });
    }
  }, [jobId, blockerId, blocker]);

  return (
    <Modal.Base
      isOpen
      title={
        (blocker?.jobBlocker &&
          getLocalizedCamelCaseValue(
            blocker.jobBlocker,
            'title',
            globalAppLanguage,
          )) ??
        ''
      }
      onClose={() => navigateToOverviewTabOrJob(`/jobs/${jobId}/blockers`)}
      width='80vw'
    >
      <>
        <Navbar
          title={getText('job_blocker_detail_title')}
          defaultBackPath={`/jobs/${jobId}/logs`}
          mobileOnly
        />
        {blocker && currentUser ? (
          <Container>
            {media && (
              <Media
                kind='blocker'
                type={media.type}
                url={media.signedUrl}
                urls={media.signedUrls}
                width='55%'
              />
            )}

            <DetailsContainer>
              <Header>
                {blocker.createdBy && <Badge.User user={blocker.createdBy} />}
                <FormattedDate
                  date={blocker.createdAt.toString()}
                  extraStyles={{
                    color: theme.colors.darkGrey,
                  }}
                />
              </Header>

              <Section>
                <SectionHeader>
                  <SectionTitle>
                    {blocker.jobBlocker &&
                      getLocalizedCamelCaseValue(
                        blocker.jobBlocker,
                        'title',
                        globalAppLanguage,
                      )}
                  </SectionTitle>

                  <IconBox>
                    <Icon component={<Warning />} />
                  </IconBox>
                </SectionHeader>
                {blocker.jobBlockerSubtype && (
                  <SectionSubtitle>
                    {getLocalizedCamelCaseValue(
                      blocker.jobBlockerSubtype,
                      'title',
                      globalAppLanguage,
                    )}
                  </SectionSubtitle>
                )}
                <SectionBody>{blocker.note}</SectionBody>
                {blocker.jobBlockerResolution && (
                  <ResolutionWrapper>
                    <ResolutionLabel>
                      {getText('job_blocker_detail_resolution', {
                        name: `${blocker.jobBlockerResolution.createdBy.fullName}`,
                        time: Dates.formatDateTime(
                          blocker.jobBlockerResolution.createdAt,
                          getText('date_today'),
                          getText('date_yesterday'),
                        ),
                      })}
                    </ResolutionLabel>
                    {blocker.jobBlockerResolution.note && (
                      <ResolutionNotes>
                        <ResolutionNotesHeader>
                          {getText('job_blocker_detail_resolution_note')}
                        </ResolutionNotesHeader>
                        {blocker.jobBlockerResolution.note}
                      </ResolutionNotes>
                    )}
                  </ResolutionWrapper>
                )}

                {blocker.resolutionRequired &&
                  !blocker.jobBlockerResolution && (
                    <ButtonContainer>
                      <StyledLinkButton
                        event={constants.EVENT_JOB_BLOCKER_RESOLVE_CLICKED}
                        eventProperties={{
                          logId: blockerId,
                          jobId,
                          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                        }}
                        text={getText('job_blocker_detail_resolve_button')}
                        to={{
                          pathname: `/jobs/${jobId}/blockers/${blockerId}/resolve-blocker`,
                          search,
                        }}
                        disabled={!activeJob?.editable}
                        extraStyles={{
                          maxWidth: '328px',
                          height: '55px',
                        }}
                      />
                    </ButtonContainer>
                  )}
              </Section>
            </DetailsContainer>
          </Container>
        ) : (
          <Loader />
        )}
      </>
    </Modal.Base>
  );
};

export default BlockerModal;

import { Locales } from 'components/views/Protected/Jobs/Settings/RiskAssessmentSettings/HazardSettings/constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectGlobalLanguageSetting,
  selectHasOrganizationSettingEnabled,
} from 'store/selectors';

const useLocales = () => {
  const enableLanguageSelection = useSelector(
    selectHasOrganizationSettingEnabled('enable_language_selection'),
  );
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  const availableLocales = useMemo(
    () =>
      enableLanguageSelection
        ? Locales
        : Locales.filter((locale) => locale === globalAppLanguage),
    [enableLanguageSelection, globalAppLanguage],
  );

  return { availableLocales };
};

export default useLocales;

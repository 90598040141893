import { Button } from '@mui/material';
import { Smiley, Tick } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins } = theme;

export const Container = styled.div`
  ${mixins.flexColumn};
  padding: 8px 0;
`;

export const Title = styled.p`
  font-family: ${fonts.bold};
  color: ${colors.black};
`;

export const DescriptionContainer = styled.ul`
  ${mixins.flexCenter};
  padding: 12px 4px;
  list-style-type: none;
`;

export const Description = styled.li`
  position: relative;
  padding-left: 16px;
  line-height: ${fontSizes.default};
  ::before {
    position: absolute;
    left: 0;
    top: 2px;
    line-height: ${fontSizes.default};
    width: 8px;
    height: 8px;
    background-color: ${colors.black};
    margin-right: 8px;
    content: ' ';
  }
`;

export const ReasonTitle = styled.p`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  color: ${colors.black};
  padding: 10px 0;
`;

export const ReasonOption = styled(
  ({ selected, disabled = false, ...rest }) => (
    <Button disabled={disabled} {...rest} />
  ),
)`
  &.MuiButtonBase-root {
    height: 55px;
    border-radius: 0;
    border: none;
    outline: 2px solid ${colors.lightBlue};
    font-family: ${fonts.default};
    font-size: ${fontSizes.default};
    text-transform: none;
    letter-spacing: 0;
    margin-top: 20px;
    margin-right: 15px;
    padding: 8px 12px;
    text-align: left;
    background-color: ${colors.white};
    color: ${colors.black};
    &:hover {
      background-color: ${colors.lightBlue};
      color: ${colors.black};
      outline: 2px solid ${colors.lightBlue};
      svg {
        .fill-selector {
          fill: ${colors.black};
        }
      }
    }
    ${({ selected }) =>
      selected &&
      `
      outline: 2px solid ${colors.blue};
      background-color: ${colors.blue};
      color: ${colors.white};
    `};
  }
`;

export const TickIcon = styled(Tick)`
  width: 24px;
  height: auto;
  padding-right: 5px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const ThanksContainer = styled.div`
  display: flex;
  background-color: ${colors.blue};
  color: ${colors.white};
  padding: 12px;
  margin-top: 20px;
`;

export const MessageContainer = styled.div`
  ${mixins.flexColumn};
  padding-left: 15px;
`;

export const SmileyIcon = styled(Smiley)`
  width: 24px;
`;

export const ThanksTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  padding-bottom: 5px;
`;

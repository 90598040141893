import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { DragOverlay, DndContext, UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';

import { Item } from './components/Item';
import { SortableContainer, SortableWrapper } from './styled';
import { SortableItem } from './components/SortableItem';
import { DragNDropItem } from './types';

export interface Props {
  items: DragNDropItem[];
  updateItemOrder: (fromIndex: number, toIndex: number) => void;
  editItem?: (id: UniqueIdentifier) => void;
  removeItem: (id: UniqueIdentifier) => void;
  disabled: boolean;
}

export const DragNDropEditor: React.FC<Props> = ({
  items,
  updateItemOrder,
  editItem,
  removeItem,
  disabled,
}) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const isFirstAnnouncement = useRef(true);
  const getIndex = (id: UniqueIdentifier) =>
    items.findIndex((item) => item.id === id);
  const activeIndex = activeId ? getIndex(activeId) : -1;

  useEffect(() => {
    if (!activeId) {
      isFirstAnnouncement.current = true;
    }
  }, [activeId]);

  return (
    <DndContext
      onDragStart={({ active }) => {
        if (!active) {
          return;
        }

        setActiveId(active.id);
      }}
      onDragEnd={({ over }) => {
        setActiveId(null);

        if (over) {
          const overIndex = getIndex(over.id);
          if (activeIndex !== overIndex) {
            updateItemOrder(activeIndex, overIndex);
          }
        }
      }}
      onDragCancel={() => setActiveId(null)}
    >
      <SortableWrapper>
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <SortableContainer>
            {items.map((item, index) => (
              <SortableItem
                key={item.id}
                id={item.id}
                title={item.title}
                subtitle={item.subtitle}
                index={index}
                onEdit={editItem}
                onRemove={removeItem}
                useDragOverlay
                handle
                disabled={disabled}
              />
            ))}
          </SortableContainer>
        </SortableContext>
      </SortableWrapper>
      {createPortal(
        <DragOverlay>
          {activeId ? (
            <Item
              title={items[activeIndex].title}
              subtitle={items[activeIndex].subtitle}
              handle
              dragOverlay
            />
          ) : null}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  );
};

import { useText } from 'hooks';
import { Container, Subtitle, Title } from './styled';

// JobsList
export const JobsList = (): JSX.Element => {
  const getText = useText();
  return (
    <Container>
      <Title>{getText('jobs_search_no_jobs')}</Title>

      <Subtitle>{getText('jobs_search_no_jobs_subtitle')}</Subtitle>
    </Container>
  );
};

import * as constants from 'utils/constants';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsFetchingJobBlockers,
  selectJobBlockers,
  selectJobsView,
} from 'store/selectors';
import { InformationLabel } from 'components/ui/Shared/EmptyState';
import { Card, Loader } from 'components/ui';
import React, { useEffect } from 'react';
import { useText } from 'hooks';
import OverviewTabBase from './OverviewTabBase';
import { Warning, YellowCheckMark } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';
import { fetchJobBlockers } from 'store/actions/jobs';

const { colors, fonts } = theme;

const StatusContainer = styled.div<{
  $signed: boolean;
  $notSignedColor: string;
}>`
  display: flex;
  align-items: center;
  background-color: ${({ $signed, $notSignedColor }) =>
    $signed ? colors.black : $notSignedColor};
  padding: 5px 17px;
  font-family: ${fonts.bold};
`;

const Status = styled.span<{ $textColor?: string }>`
  color: ${({ $textColor }) => $textColor || colors.white};
  margin-left: 10px;
`;

const SmallWarningIcon = styled(Warning)`
  height: 20px;
  width: 20px;
`;

const JobBlockers = (): JSX.Element => {
  const { jobId } = useParams();
  const jobBlockers = useSelector(selectJobBlockers(jobId));
  const isFetchingJobBlockers = useSelector(selectIsFetchingJobBlockers);
  const getText = useText();
  const dispatch = useDispatch();
  const view = useSelector(selectJobsView);

  useEffect(() => {
    dispatch(fetchJobBlockers(jobId));
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OverviewTabBase title={getText('tabs_job_blockers')}>
      <>
        {isFetchingJobBlockers ? (
          <Loader />
        ) : (
          <>
            {!jobBlockers?.length ? (
              <InformationLabel>
                {getText('job_overview_job_blockers_no_information')}
              </InformationLabel>
            ) : (
              jobBlockers?.map((blocker) => (
                <React.Fragment key={`job-blocker-${blocker.id}`}>
                  <Card.JobDetail
                    event={constants.EVENT_VIEW_JOB_BLOCKER_ITEM}
                    eventProperties={{
                      jobId,
                      logId: blocker.id,
                      jobBlockerCategory: blocker.jobBlocker.title,
                      logType: 'Job Blocker',
                      source: 'job-overview',
                      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                    }}
                    title={getText('job_blocker_detail_title')}
                    subtitle={blocker.jobBlocker.title}
                    creator={blocker.createdBy}
                    date={blocker.createdAt}
                    to={`/jobs/${blocker.jobId}/blockers/${blocker.id}`}
                    IconComponent={Warning}
                  />
                  {blocker.resolution_required && (
                    <StatusContainer
                      $notSignedColor={colors.orange}
                      $signed={!!blocker.jobBlockerResolution}
                    >
                      {blocker.jobBlockerResolution ? (
                        <>
                          <YellowCheckMark />
                          <Status>
                            {getText('job_overview_job_blocker_resolved')}
                          </Status>
                        </>
                      ) : (
                        <>
                          <SmallWarningIcon />
                          <Status $textColor={colors.black}>
                            {getText(
                              'job_overview_job_blocker_requires_resolution',
                            )}
                          </Status>
                        </>
                      )}
                    </StatusContainer>
                  )}
                </React.Fragment>
              ))
            )}
          </>
        )}
      </>
    </OverviewTabBase>
  );
};

export default JobBlockers;

import { HazardsControlsComments } from '../../HazardsControlsComments';
import OverviewTabBase from './OverviewTabBase';

const HazardsAndControls = (): JSX.Element => (
  <OverviewTabBase>
    <HazardsControlsComments />
  </OverviewTabBase>
);

export default HazardsAndControls;

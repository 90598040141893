import { ClickAwayListener } from '@mui/material';
import { BlueTick, Tick } from 'assets/icons';
import { Badge } from 'components/ui';
import { Dates } from 'helpers';
import { useText } from 'hooks';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setReaction } from 'store/actions/messages';
import { Tooltip } from '../Display';
import RootState from 'model/State/RootState';
import { Message } from 'model/Message';
import { selectGlobalLanguageSetting } from 'store/selectors';
import * as Analytics from 'utils/analytics';

import {
  Container,
  TextBox,
  DateWrapper,
  TextBoxFooter,
  PlusIconStyled,
  AddEmojiButton,
  EmojiPopoverWrapper,
  EmojiPopoverItem,
  EmojiWrapper,
  EmojiItem,
  EmojiIcon,
} from './styled';

type Props = {
  message: Message;
};

const getMessageText = (message: Message, globalAppLanguage: string) =>
  message.target[`value_${globalAppLanguage}`] || message.target.value;

// Message.Text
export const Text = Analytics.withTrackEvent(
  ({ message }: Props): JSX.Element => {
    const { currentUser } = useSelector((state: RootState) => state.session);
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

    const dispatch = useDispatch();
    const getText = useText();

    const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
    const text = getMessageText(message, globalAppLanguage);
    const sender = message.target.sender;
    const isCreatedByCurrentUser = currentUser.id === message.target.sender.id;
    const { read, reactions, user_reaction: userReaction } = message;

    function handleEmojiClick(messageId: number, type: string) {
      dispatch(setReaction(messageId, type));
      setIsTooltipOpen(false);
    }

    function createMarkup(emojiType: string) {
      return { __html: emojiType };
    }

    const handleTooltipClose = () => setIsTooltipOpen(false);

    return (
      <Container $isCreatedByCurrentUser={isCreatedByCurrentUser}>
        <Badge.UserBadge user={sender} isCurrentUser={isCreatedByCurrentUser} />

        <TextBox>
          <p>{text}</p>
        </TextBox>
        <TextBoxFooter>
          <DateWrapper>
            {Dates.formatDateTime(
              message.target.created_at,
              getText('date_today'),
              getText('date_yesterday'),
            )}
          </DateWrapper>
          {reactions && (
            <EmojiWrapper>
              {reactions.map((reaction) => (
                <EmojiItem
                  key={`emoji-item-${reaction.value}`}
                  $isUserReaction={reaction.value === userReaction}
                  onClick={() =>
                    reaction.value === userReaction &&
                    dispatch(setReaction(message.id, null))
                  }
                >
                  <EmojiIcon
                    role='img'
                    aria-label={reaction.value}
                    dangerouslySetInnerHTML={createMarkup(reaction.value)} // eslint-disable-line react/no-danger
                  />
                  <span>{reaction.count}</span>
                </EmojiItem>
              ))}
            </EmojiWrapper>
          )}
          {!userReaction && (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  title={
                    <EmojiPopoverWrapper>
                      <EmojiPopoverItem
                        onClick={() =>
                          handleEmojiClick(message.id, '&#128578;')
                        }
                        role='button'
                        tabIndex={0}
                      >
                        <span role='img' aria-label='smiling-face'>
                          &#128578;
                        </span>
                      </EmojiPopoverItem>
                      <EmojiPopoverItem
                        onClick={() =>
                          handleEmojiClick(message.id, '&#128079;')
                        }
                        role='button'
                        tabIndex={0}
                      >
                        <span role='img' aria-label='clap'>
                          &#128079;
                        </span>
                      </EmojiPopoverItem>
                      <EmojiPopoverItem
                        onClick={() =>
                          handleEmojiClick(message.id, '&#128077;')
                        }
                        role='button'
                        tabIndex={0}
                      >
                        <span role='img' aria-label='thumbs-up'>
                          &#128077;
                        </span>
                      </EmojiPopoverItem>
                    </EmojiPopoverWrapper>
                  }
                  triggerElement={
                    <AddEmojiButton onClick={() => setIsTooltipOpen(true)}>
                      <PlusIconStyled />
                    </AddEmojiButton>
                  }
                  onClose={handleTooltipClose}
                  isOpen={isTooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                />
              </div>
            </ClickAwayListener>
          )}
          {read ? (
            <>
              <BlueTick />
              <BlueTick />
            </>
          ) : (
            <>
              <Tick />
              <Tick />
            </>
          )}
        </TextBoxFooter>
      </Container>
    );
  },
);

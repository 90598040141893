import styled from 'styled-components';
import { ChevronDown, Cross, ImageIcon } from 'assets/icons';
import theme from 'theme';
import {
  Pagination as MaterialPagination,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Slider as MuiSlider,
  Tab as MaterialTab,
  Tabs,
} from '@mui/material';

const { colors, fonts, fontSizes, mixins, media } = theme;

export const lightTooltipStyle = {
  backgroundColor: theme.colors.white,
  fontFamily: theme.fonts.default,
  borderRadius: 0,
  border: `1px solid ${theme.colors.darkGrey}`,
  boxShadow: '0px 4px 3.8px 0px #00000040',
  minWidth: '150px',
  '& .MuiTooltip-arrow': {
    '&:before': {
      border: `1px solid ${theme.colors.darkGrey}`,
    },
    color: theme.colors.white,
  },
};

export const darkTooltipStyle = (height: number, opacity: number) => ({
  backgroundColor: colors.black,
  fontSize: fontSizes.xs,
  width: '150px',
  height: `${height}px`,
  textAlign: 'center',
  borderRadius: 0,
  opacity: `${opacity} !important`,
  '& .MuiTooltip-arrow': {
    color: colors.black,
  },
});

export const ChevronIcon = styled(
  ({ isLight = false, active = false, size, marginRight = '0', ...props }) => (
    <ChevronDown {...props} />
  ),
)`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  cursor: pointer;
  margin-right: ${({ marginRight }) => marginRight};
  .fill-selector {
    fill: ${({ isLight }) => (isLight ? colors.black : colors.white)};
  }
  ${({ active }) => active && `transform: translateX(5%) rotate(180deg)`};
`;

export const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    box-shadow: none;

    &.Mui-expanded {
      margin: 0;
    }
  }
`;

export const AccordionSummary = styled(({ isLight, isFyldVision, ...rest }) => (
  <MuiAccordionSummary {...rest} />
))`
  &.MuiAccordionSummary-root {
    background-color: ${({ isLight, isFyldVision }) => {
      if (isLight) {
        return colors.lightGrey;
      }
      if (isFyldVision) {
        return colors.blue;
      }
      return colors.black;
    }};
    color: ${({ isLight }) => (isLight ? colors.black : colors.white)};
    font-family: ${({ isFyldVision }) =>
      isFyldVision ? fonts.light : fonts.bold};
    font-size: ${({ isLight, isFyldVision }) =>
      isLight || isFyldVision ? fontSizes.m : fontSizes.l};
  }
`;

export const AccordionDetails = styled(({ isFyldVision, ...rest }) => (
  <MuiAccordionDetails {...rest} />
))`
  &.MuiAccordionDetails-root {
    padding: ${({ isFyldVision }) => (isFyldVision ? '0' : '5')}px;
  }
`;

export const SubTitle = styled.span`
  font-family: ${fonts.light};
  margin-left: 10px;
`;

export const CrossIcon = styled(({ deleteDisabled, ...props }) => (
  <Cross {...props} />
))`
  width: 20px;
  height: 20px;
  margin-right: 25px;
  cursor: ${({ deleteDisabled }) =>
    deleteDisabled ? 'not-allowed' : 'pointer'};
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const StyledImageIcon = styled(ImageIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 25px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.black};
  padding: 7px 0px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentContainer = styled.div`
  transition: opacity 500ms ease-in;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  align-content: flex-end;
  padding-right: 5px;
`;

export const Container = styled.div`
  font-size: ${fontSizes.l};
  height: 100%;
  ${mixins.flexFullCenter};
  background-color: ${colors.offWhite};
  margin-top: 40px;
`;

export const StyledPagination = styled(MaterialPagination)`
  &.MuiPagination-root {
    align-self: end;
    padding-top: 20px;
    .MuiPaginationItem-root {
      background-color: ${colors.white};
      border-radius: 0;
      font-family: ${fonts.default};
      &.Mui-selected {
        border: 1px solid ${colors.black};
        font-family: ${fonts.bold};
      }
    }
  }
`;

export const StyledSlider = styled(({ color, ...rest }) => (
  <MuiSlider {...rest} />
))`
  &.MuiSlider-root {
    height: 6px;

    :not(.Mui-disabled) {
      &.MuiSlider-rail {
        color: ${colors.grey};
      }
      &.MuiSlider-thumb {
        color: ${({ color }) => color || colors.blue};
        &::after {
          width: auto;
        }
      }
      &.MuiSlider-track {
        color: ${({ color }) => color || colors.blue};
      }
    }
  }
`;

export const Title = styled.div`
  font-family: ${fonts.bold};
  padding: 5px 0px;
`;

export const Wrapper = styled.div`
  height: 90%;
  display: flex;
  flex-direction: column;
  ${media.lg`
    height: 82%;
  `}
`;

export const StyledTabs = styled(({ ...props }) => <Tabs {...props} />)`
  &.MuiTabs-root {
    margin: 0 80px;
    min-height: 40px;
    .MuiTabs-flexContainer {
      height: 100%;
    }
    .MuiTabs-scroller {
      height: fit-content;
    }
    .MuiTabs-indicator {
      display: none;
    }
    .MuiTabs-scrollButtons {
      background-color: ${colors.offWhite};
      &.Mui-disabled {
        display: none;
      }
      &:first-of-type {
        margin-right: 5px;
      }
      &:last-of-type {
        margin-left: 5px;
      }
    }
  }
`;

export const StyledTab = styled(MaterialTab)`
  &.MuiTab-root {
    width: 160px;
    height: auto;
    padding: 5px;
    font-family: ${fonts.bold};
    letter-spacing: 0.5px;
    border-bottom: 1px solid ${colors.lightGrey};
    background-color: ${colors.darkGrey};
    color: ${colors.white};
    text-transform: unset;
    word-break: break-word;
    margin-left: 16px;
    ${mixins.flexFullCenter};
    &:first-of-type {
      margin-left: 0 !important;
    }
    &.Mui-selected {
      background-color: ${colors.offWhite};
      border-bottom: 1px solid ${colors.offWhite};
      color: ${colors.black};
      font-family: ${fonts.bold};
    }
  }
`;

export const StyledTabPanel = styled.div`
  padding: 0;
  height: 100%;
  overflow-y: overlay;
`;

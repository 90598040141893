import { useEffect, useState } from 'react';
import { useHazardsControlsComments, useText } from 'hooks';
import { Loader, Navbar } from 'components/ui';
import { Comment, Control, Hazard } from 'assets/icons';
import { useSelector, useDispatch } from 'react-redux';
import { fetchActiveJob } from 'store/actions/jobs';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import { MajorHazardTag } from 'components/ui/Tags/MajorHazardTag';
import { InformationLabel } from 'components/ui/Shared/EmptyState';
import { getLocalizedValue } from 'helpers/locales';
import { capitalizeFirstLetter } from 'helpers/names';
import { WarningTag } from 'components/ui/Tags/WarningTag';
import { SectionHeader } from './components/SectionHeader';
import { Container, Section, Separator } from './styled';
import { ListItem } from '../../../../ui/Lists/ListItem';
import { UnorderedList } from 'components/ui/Lists/UnorderedList';

// HazardsControlsComments
export const HazardsControlsComments = () => {
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const getText = useText();

  const { activeJob, isFetchingActiveJob } = useSelector(
    (state: RootState) => state.jobs,
  );
  const {
    hazards,
    controls,
    comments,
    hazardsWithControlsAndComments,
    isLoading,
  } = useHazardsControlsComments(jobId);
  const [pendingVraCount, setPendingVraCount] = useState<number | null>(null);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  useEffect(() => {
    if (activeJob) {
      setPendingVraCount(activeJob?.vra_in_new_or_processed_count);
    } else {
      dispatch(fetchActiveJob(jobId));
    }
  }, [activeJob, jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSections = () => {
    if (hazardsWithControlsAndComments) {
      return (
        <>
          <Section>
            <SectionHeader
              title={getText('hazards_and_controls')}
              IconComponent={Hazard}
            />
            {hazardsWithControlsAndComments?.hazardWithControls?.length > 0 ? (
              <>
                {hazardsWithControlsAndComments.hazardWithControls.map(
                  (hazardWithControls) => (
                    <UnorderedList
                      key={hazardWithControls.hazard.id}
                      items={hazardWithControls.controls}
                      title={getLocalizedValue(
                        hazardWithControls.hazard,
                        'title',
                        globalAppLanguage,
                      )}
                      needEmptyIcon={false}
                    >
                      <>
                        {hazardWithControls.hazard.isMajor && (
                          <MajorHazardTag
                            text={getText('assessment_details_major_hazard')}
                            fitContent
                            extraStyles={{
                              marginBottom: '5px',
                            }}
                          />
                        )}
                        {hazardWithControls.controls.length < 1 && (
                          <InformationLabel>
                            {getText('controls_no_display')}
                          </InformationLabel>
                        )}
                      </>
                    </UnorderedList>
                  ),
                )}
              </>
            ) : (
              <InformationLabel>
                {getText('hazards_and_controls_no_display')}
              </InformationLabel>
            )}
          </Section>

          <Section>
            <SectionHeader
              title={getText('other_conditions')}
              IconComponent={Hazard}
            />
            {hazardsWithControlsAndComments.otherConditions.length ? (
              hazardsWithControlsAndComments.otherConditions.map(
                (comment, index) => (
                  <ListItem key={`other-condition-${index}`} title={comment} />
                ),
              )
            ) : (
              <InformationLabel>
                {getText('other_conditions_no_display')}
              </InformationLabel>
            )}
          </Section>
        </>
      );
    }
    return (
      <>
        <Section>
          <SectionHeader title={getText('hazards')} IconComponent={Hazard} />
          {hazards.length ? (
            hazards.map((hazard) => (
              <ListItem
                key={`hazard-${hazard.title}`}
                title={capitalizeFirstLetter(
                  getLocalizedValue(hazard, 'title', globalAppLanguage),
                )}
              />
            ))
          ) : (
            <InformationLabel>{getText('hazards_no_display')}</InformationLabel>
          )}
        </Section>

        <Separator />

        <Section>
          <SectionHeader title={getText('controls')} IconComponent={Control} />
          {controls.length ? (
            controls.map((control) => (
              <ListItem
                key={`control-${control.title}`}
                title={capitalizeFirstLetter(
                  getLocalizedValue(control, 'title', globalAppLanguage),
                )}
              />
            ))
          ) : (
            <InformationLabel>
              {getText('controls_no_display')}
            </InformationLabel>
          )}
        </Section>

        <Separator />

        <Section>
          <SectionHeader
            title={getText('other_conditions')}
            IconComponent={Comment}
          />
          {comments.length ? (
            comments.map((comment, index) => (
              <ListItem key={`comment-${index}`} title={comment} />
            ))
          ) : (
            <InformationLabel>
              {getText('other_conditions_no_display')}
            </InformationLabel>
          )}
        </Section>
      </>
    );
  };

  return (
    <>
      <Navbar
        title={getText('hazards_and_controls_title')}
        mobileOnly
        defaultBackPath={`/jobs/${jobId}`}
      />
      {isFetchingActiveJob || isLoading ? (
        <Loader />
      ) : (
        <Container>
          {!!pendingVraCount && pendingVraCount > 0 && (
            <WarningTag
              text={`${pendingVraCount} ${getText('vra')}${
                pendingVraCount > 1 ? 's' : ''
              } ${getText('vra_pending')}`}
            />
          )}
          {renderSections()}
        </Container>
      )}
    </>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mergeArraysWithoutDuplicate } from 'helpers/utils';
import { Assessment } from 'model/Assessment';
import { Control } from 'model/Assessment/Control';
import { Hazard } from 'model/Assessment/Hazard';
import {
  MethodStatement,
  MethodStatementSection,
  RamsRating,
  RamsReview,
  RamsStages,
} from 'model/Assessment/RAMS';
import RamsAssessmentState from 'model/State/RamsAssessmentState';
import RootState from 'model/State/RootState';
import {
  selectIsCurrentUserAssignedToJob,
  selectUserHasPermission,
} from 'store/selectors';

const initialState: RamsAssessmentState = {
  activeAssessment: null,
  selectedHazards: [],
  selectedControls: [],
  ratings: [],
  currentStage: null,
  isSubmitting: false,
  editedHazard: null,
  sections: [],
  isMethodStatementEdited: false,
  methodStatementSubmitted: false,
  isUpdatingSignOnList: false,
  isEditTagDismissed: false,
};

export const ramsAssessments = createSlice({
  name: 'ramsAssessments',
  initialState,
  reducers: {
    setSelectedHazards: (
      state: RamsAssessmentState,
      action: PayloadAction<Hazard>,
    ) => {
      state.selectedHazards = [action.payload, ...state.selectedHazards];
    },
    removeSelectedHazard: (
      state: RamsAssessmentState,
      action: PayloadAction<number>,
    ) => {
      const isRated = state.ratings.some(
        (rating) => rating.hazardId === action.payload,
      );
      if (isRated) {
        const newRatings = state.ratings.filter(
          (rating) => rating.hazardId !== action.payload,
        );
        state.ratings = newRatings;
      }
      state.selectedHazards = state.selectedHazards.filter(
        (hazard) => hazard.id !== action.payload,
      );
    },
    setAssessmentStage: (
      state: RamsAssessmentState,
      action: PayloadAction<RamsStages | null>,
    ) => {
      state.currentStage = action.payload;
    },
    setEditedHazard: (
      state: RamsAssessmentState,
      action: PayloadAction<Hazard>,
    ) => {
      state.editedHazard = action.payload;
    },
    setSelectedControls: (
      state: RamsAssessmentState,
      action: PayloadAction<Control[]>,
    ) => {
      state.selectedControls = action.payload;
    },
    addSelectedControl: (
      state: RamsAssessmentState,
      action: PayloadAction<Control>,
    ) => {
      state.selectedControls = [action.payload, ...state.selectedControls];
    },
    removeSelectedControl: (
      state: RamsAssessmentState,
      action: PayloadAction<number>,
    ) => {
      state.selectedControls = state.selectedControls.filter(
        (control) => control.id !== action.payload,
      );
    },
    setHazardRatings: (
      state: RamsAssessmentState,
      action: PayloadAction<RamsRating>,
    ) => {
      const isRatingPresent = state.ratings.some(
        (rating) => rating.hazardId === action.payload.hazardId,
      );
      let newRatings: RamsRating[] = [];
      if (isRatingPresent) {
        newRatings = state.ratings.map((rating) => {
          if (rating.hazardId === action.payload.hazardId) {
            return action.payload;
          }
          return rating;
        });
      } else {
        newRatings = [...state.ratings, action.payload];
      }
      state.ratings = newRatings;
      state.editedHazard = null;
    },
    clearSelectedControls: (state: RamsAssessmentState) => {
      state.selectedControls = [];
    },
    clearRamsAssessment: (state: RamsAssessmentState) => initialState,
    sendRamsAssessmentRequest: (
      state: RamsAssessmentState,
      action: PayloadAction<number>,
    ) => {
      state.isSubmitting = true;
    },
    sendRamsAssessmentSuccess: (state: RamsAssessmentState) => {
      state.isSubmitting = false;
    },
    setRamsAssessment: (
      state: RamsAssessmentState,
      action: PayloadAction<Assessment>,
    ) => {
      const selectedHazards = action.payload?.ramsReviews?.map(
        (review: RamsReview) => review.hazard,
      );
      const selectedControls = [];
      const ratings = action.payload?.ramsReviews?.map(
        (review: RamsReview) => ({
          hazardId: review.hazard.id,
          selectedControls: review.controls,
          preControlRating: {
            severity: review.preControlSeverity,
            likelihood: review.preControlLikelihood,
          },
          postControlRating: {
            severity: review.postControlSeverity,
            likelihood: review.postControlLikelihood,
          },
        }),
      );
      if (state.activeAssessment) {
        state.activeAssessment = {
          ...state.activeAssessment,
          ...action.payload,
        };
        state.ratings = mergeArraysWithoutDuplicate(
          state.ratings,
          ratings,
          'hazardId',
        );
        state.selectedControls = mergeArraysWithoutDuplicate(
          state.selectedControls,
          selectedControls,
          'id',
        );
        state.selectedHazards = mergeArraysWithoutDuplicate(
          state.selectedHazards,
          selectedHazards,
          'id',
        );
      } else {
        state.activeAssessment = action.payload;
        state.ratings = ratings;
        state.selectedControls = selectedControls;
        state.selectedHazards = selectedHazards;
      }
    },
    setRamsSections: (
      state: RamsAssessmentState,
      action: PayloadAction<{
        sections: MethodStatementSection[];
        isEdited: boolean;
      }>,
    ) => {
      state.isMethodStatementEdited = action.payload.isEdited;
      state.sections = action.payload.sections;
    },
    sendMethodStatementRequest: (
      state: RamsAssessmentState,
      action: PayloadAction<number>,
    ) => {
      state.isSubmitting = true;
      state.methodStatementSubmitted = false;
    },
    sendMethodStatementSuccess: (state: RamsAssessmentState) => {
      state.isSubmitting = false;
      state.methodStatementSubmitted = true;
    },
    setRamsAssessmentError: (state: RamsAssessmentState) => {
      state.isSubmitting = false;
    },
    setMethodStatement: (
      state: RamsAssessmentState,
      action: PayloadAction<MethodStatement>,
    ) => {
      const methodStatementSections = [...(action.payload?.sections ?? [])];
      state.sections = methodStatementSections.sort(
        (a, b) => a.orderId - b.orderId,
      );
    },
    updateRamsSignOnList: (
      state: RamsAssessmentState,
      action: PayloadAction<{ assessmentId: number; userIds: number[] }>,
    ) => {
      state.isUpdatingSignOnList = true;
    },
    updateRamsSignOnListSuccess: (state: RamsAssessmentState) => {
      state.isUpdatingSignOnList = false;
    },
    updateRamsSignOnListError: (state: RamsAssessmentState) => {
      state.isUpdatingSignOnList = false;
    },
    setEditTagDismissed: (state: RamsAssessmentState) => {
      state.isEditTagDismissed = true;
    },
  },
});

export const {
  setSelectedHazards,
  removeSelectedHazard,
  setAssessmentStage,
  setEditedHazard,
  setSelectedControls,
  addSelectedControl,
  removeSelectedControl,
  setHazardRatings,
  clearSelectedControls,
  clearRamsAssessment,
  sendRamsAssessmentRequest,
  sendRamsAssessmentSuccess,
  setRamsAssessment,
  setRamsSections,
  sendMethodStatementRequest,
  sendMethodStatementSuccess,
  setRamsAssessmentError,
  setMethodStatement,
  updateRamsSignOnList,
  updateRamsSignOnListSuccess,
  updateRamsSignOnListError,
  setEditTagDismissed,
} = ramsAssessments.actions;

export const selectActiveRamsAssessment = (
  state: RootState,
): Assessment | null => state.ramsAssessments.activeAssessment;
export const selectIsUpdatingSignOnList = (state: RootState): boolean =>
  state.ramsAssessments.isUpdatingSignOnList;
export const selectUserCanEditRams = (state: RootState): boolean =>
  !!state.ramsAssessments.activeAssessment &&
  state.ramsAssessments.activeAssessment.editable &&
  selectUserHasPermission('manager') &&
  selectIsCurrentUserAssignedToJob(state);
export const selectIsEditTagDismissed = (state: RootState): boolean =>
  state.ramsAssessments.isEditTagDismissed;

export const ramsAssessmentsReducer = ramsAssessments.reducer;

import { Display } from 'components/ui';
import { useSelector } from 'react-redux';
import { useText } from 'hooks';
import { selectGlobalLanguageSetting } from 'store/selectors';
import RootState from 'model/State/RootState';
import { HazardsTag } from '../HazardsTag';
import { ControlRow } from './ControlRow';
import { IdentifiedHazardsWrapper } from './styled';
import { RamsRating } from 'model/Assessment/RAMS';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

// HazardSummary
export const HazardSummary = (): JSX.Element => {
  const getText = useText();
  const { selectedHazards, ratings } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  const getRatingByHazardId = (id: number) =>
    ratings.find((rating: RamsRating) => rating.hazardId === id);

  return (
    <IdentifiedHazardsWrapper>
      <HazardsTag text={getText('rams_summary_hazard_identified')} />
      {selectedHazards.map((hazard) => {
        const rating = getRatingByHazardId(hazard.id);
        return (
          <Display.Collapsible
            key={hazard.id}
            title={getLocalizedCamelCaseValue(
              hazard,
              'title',
              globalAppLanguage,
            )}
            subTitle={`(${rating?.selectedControls?.length} ${getText(
              'rams_summary_controls',
            )})`}
            extraStyles={{ marginBottom: '10px' }}
          >
            {rating ? <ControlRow rating={rating} /> : null}
          </Display.Collapsible>
        );
      })}
    </IdentifiedHazardsWrapper>
  );
};

import styled from 'styled-components';
import { FyldLogoFull, BrandPatternCircles } from 'assets/images';
import { Link as RouterLink } from 'react-router-dom';
import theme from 'theme';

const { media, colors } = theme;

export const FormContainer = styled.div`
  flex: 50%;
  display: flex;
  flex-direction: column;
  background-image: url(${BrandPatternCircles});
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  padding-top: 150px;
  ${media.sm`
    flex: 1 0;
    background-image: none;
    padding-top: 0px;
  `}
`;

export const BrandContainer = styled(RouterLink)`
  padding: 150px 80px;
  flex: 50%;
  ${media.sm`
    padding: 25px 20px;
    flex: 0 1;
  `}
  text-decoration: none;
`;

export const FyldLogo = styled(FyldLogoFull)`
  height: 55px;
  margin-bottom: ${({ bigMargin }) => {
    if (bigMargin) {
      return '100px';
    }
    return 'none';
  }};
  ${media.sm`
    height: 30px;
    margin-bottom: 0;
  `}
`;

export const MobileLineBreak = styled.br`
  display: none;
  ${media.sm`
    display: block;
  `}
`;

export const Public = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  background-color: ${colors.black};
  ${media.sm`
    flex-direction: column;
    position: relative;
  `}
`;

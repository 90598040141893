import axios from 'axios';
import { getSortString } from 'helpers/apiHelpers';
import { SortOrder } from 'model/enum/SortOrder';
import { jobV2Fields } from 'utils/constants';

export const jobs = (params) => {
  let querystring = `?`;
  for (const key in params) {
    const value = params[key];
    if (value) {
      querystring += `${key}=${value}&`;
    }
  }
  querystring = querystring.slice(0, -1);

  return axios.get(`/search-jobs/${querystring}`);
};

export const jobsV2 = async ({ page, pageSize, search, workspaceUuid }) => {
  const headers = {
    Accept: 'application/json; version=2;',
  };

  const { data } = await axios.get(`/search-jobs/`, {
    headers,
    params: {
      q: search,
      page,
      page_size: pageSize,
      sort: getSortString(jobV2Fields.ACTION_REQUIRED, SortOrder.DESCENDING),
      ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
    },
  });

  return data;
};

export const jobsUrl = (url) => {
  return axios.get(url);
};

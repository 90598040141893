import { useDispatch, useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import { useIsMobile, useJobs, useSafetyPrediction, useText } from 'hooks';
import { useMemo, useState } from 'react';
import * as constants from 'utils/constants';
import { Button, Navbar } from 'components/ui';
import { Checkbox } from 'components/ui/Input';
import { Job } from 'api';
import * as Logger from 'utils/logger';
import { setGlobalError } from 'store/slices/notifications';
import { setLatestPsiReview, setPsiDismissed } from 'store/actions/user';
import { updateUserReviewPsiScore } from 'store/actions/jobs';
import { useParams } from 'react-router-dom';
import {
  ModalContent,
  Option,
  OptionsMessage,
  RiskText,
  RiskWarningContainer,
  TitleContainer,
} from './styled';
import { selectJobsView } from 'store/selectors';

type FormOptions = {
  risk_environment: boolean;
  risk_injury: boolean;
  risk_property: boolean;
  risk_public: boolean;
  risk_supply: boolean;
  user_comment: string | null;
};

export const FeedbackShow = (): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { navigateToOverviewTabOrJob } = useJobs();
  const { jobId } = useParams();
  const { activeJob } = useSelector((state: RootState) => state.jobs);
  const view = useSelector(selectJobsView);

  const { getHighPredictions, getRiskWarning } = useSafetyPrediction();
  const highPredictions = getHighPredictions(
    activeJob?.safety_prediction_score,
  );
  const originalForm = useMemo(() => {
    return {
      risk_environment: false,
      risk_injury: false,
      risk_property: false,
      risk_public: false,
      risk_supply: false,
      user_comment: null,
      ...highPredictions,
    };
  }, [highPredictions]);

  const [form, setForm] = useState<FormOptions>(originalForm);

  const riskTypes = useMemo(() => {
    const riskWarning = getRiskWarning(activeJob?.safety_prediction_score);
    return riskWarning?.type;
  }, [activeJob, getRiskWarning]);

  const onChange = (field: string, value: boolean) => {
    const newForm = { ...form, [field]: value };
    if (value && form.user_comment) {
      newForm.user_comment = null;
    }
    if (
      !newForm.risk_environment &&
      !newForm.risk_injury &&
      !newForm.risk_property &&
      !newForm.risk_public &&
      !newForm.risk_supply
    ) {
      newForm.user_comment = getText(
        'job_overview_safety_prediction_review_option_none',
      );
    }
    setForm({ ...newForm });
  };

  const onNoneSelect = () => {
    setForm({
      risk_environment: false,
      risk_injury: false,
      risk_property: false,
      risk_public: false,
      risk_supply: false,
      user_comment: getText(
        'job_overview_safety_prediction_review_option_none',
      ),
    });
  };

  const handleSubmit = async () => {
    try {
      const data = await Job.submitSafetyRiskReview(
        activeJob?.id,
        activeJob?.safety_prediction_score,
        form,
        originalForm &&
          Object.entries(form).toString() ===
            Object.entries(originalForm).toString(),
      );
      dispatch(setLatestPsiReview(data?.created_at));
      dispatch(updateUserReviewPsiScore(activeJob?.safety_prediction_score.id));
      navigateToOverviewTabOrJob(`/jobs/${activeJob?.id}`, {
        state: { successfulReview: true },
      });
    } catch (err) {
      Logger.error(err);
      dispatch(setGlobalError(err));
      navigateToOverviewTabOrJob();
    }
  };

  return (
    <>
      <Navbar
        title={getText('job_overview_safety_prediction_warning_high_risk')}
        defaultBackPath={`/jobs/${jobId}`}
        onBack={() => dispatch(setPsiDismissed(true))}
        mobileOnly
      />
      <ModalContent $isMobile={isMobile}>
        <TitleContainer>
          {getText('job_overview_safety_prediction_review_title')}
        </TitleContainer>
        {riskTypes && (
          <RiskWarningContainer>
            {getText('job_overview_safety_prediction_review_categorised_risk')}
            <RiskText>{riskTypes?.toLowerCase()}</RiskText>
          </RiskWarningContainer>
        )}
        <OptionsMessage>
          {getText('job_overview_safety_prediction_review_present_risks')}
        </OptionsMessage>
        <div>
          <Option>
            <Checkbox
              checked={form.risk_injury}
              onChange={(e) => onChange('risk_injury', e.target.checked)}
              label={getText(
                'job_overview_safety_prediction_review_option_injury',
              )}
              labelPlacement='end'
            />
          </Option>
          <Option>
            <Checkbox
              checked={form.risk_public}
              onChange={(e) => onChange('risk_public', e.target.checked)}
              label={getText(
                'job_overview_safety_prediction_review_option_public',
              )}
              labelPlacement='end'
            />
          </Option>
          <Option>
            <Checkbox
              checked={form.risk_property}
              onChange={(e) => onChange('risk_property', e.target.checked)}
              label={getText(
                'job_overview_safety_prediction_review_option_property',
              )}
              labelPlacement='end'
            />
          </Option>
          <Option>
            <Checkbox
              checked={form.risk_environment}
              onChange={(e) => onChange('risk_environment', e.target.checked)}
              label={getText(
                'job_overview_safety_prediction_review_option_environment',
              )}
              labelPlacement='end'
            />
          </Option>
          <Option>
            <Checkbox
              checked={form.risk_supply}
              onChange={(e) => onChange('risk_supply', e.target.checked)}
              label={getText(
                'job_overview_safety_prediction_review_option_supply',
              )}
              labelPlacement='end'
            />
          </Option>
          <Option>
            <Checkbox
              checked={!!form.user_comment}
              onChange={onNoneSelect}
              label={getText(
                'job_overview_safety_prediction_review_option_none',
              )}
              labelPlacement='end'
            />
          </Option>
        </div>
      </ModalContent>

      <Button.Primary
        text={getText('submit')}
        extraStyles={
          !isMobile
            ? {
                position: 'sticky',
                bottom: '0',
                marginTop: 'auto',
                flex: '0 0 55px',
              }
            : { position: 'absolute', bottom: 0 }
        }
        onClick={handleSubmit}
        event={constants.EVENT_PSI_FEEDBACK_SUBMITTED}
        eventProperties={{
          jobId: activeJob?.id,
          psiScoreId: activeJob?.safety_prediction_score?.id,
          submittedRisks: Object.keys(form)
            .filter((key) => form[key])
            .join(', '),
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        }}
      />
    </>
  );
};

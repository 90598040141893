import { useParams } from 'react-router-dom';
import { setActiveJobTab } from 'store/actions/versions';
import { useDispatch, useSelector } from 'react-redux';
import * as Analytics from 'utils/analytics';
import { selectJobsView } from 'store/selectors';
import { EVENT_PROPERTIES_PAGE_SOURCE_FIELD } from 'utils/constants';
import { DesktopContainer, DesktopTab, DesktopTabContainer } from './styled';

type TabPropTypes = {
  text: string;
  to: string;
  isActive?: boolean;
  value?: string;
  event?: string;
};

type Props = {
  left: TabPropTypes;
  right: TabPropTypes;
};

// Tabs.Desktop
export const Desktop = ({ left, right }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const view = useSelector(selectJobsView);

  const onTabClick = (value, event) => {
    if (value) {
      dispatch(setActiveJobTab(value));
      if (event) {
        Analytics.trackEvent(event, {
          jobId,
          [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        });
      }
    }
  };

  return (
    <DesktopContainer>
      <DesktopTabContainer>
        <DesktopTab
          to={left.to}
          isActive={left.isActive}
          onClick={() => onTabClick(left?.value, left?.event)}
        >
          {left.text}
        </DesktopTab>

        <DesktopTab
          to={right.to}
          isActive={right.isActive}
          onClick={() => onTabClick(right?.value, right?.event)}
        >
          {right.text}
        </DesktopTab>
      </DesktopTabContainer>
    </DesktopContainer>
  );
};

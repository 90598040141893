import {
  HavsFilters,
  HavsTableFields,
} from 'model/AdminPanel/HavsSettings/HavsUser';
import Sort from 'model/Sort';
import { SortOrder } from 'model/enum/SortOrder';

export const EVENT_ADD_HAVS_USER = 'havs-panel-add-user';
export const EVENT_EDIT_HAVS_USER = 'havs-panel-update-user';
export const EVENT_DELETE_HAVS_USER = 'havs-panel-remove-user';
export const EVENT_UPDATE_FILTERS = 'havs-filter-applied';

const DEFAULT_SORT: Sort = {
  field: HavsTableFields.LAST_UPDATED,
  order: SortOrder.DESCENDING,
};

export const BLANK_FILTERS: HavsFilters = {
  depot: null,
  havsLimit: null,
  manager: null,
  search: null,
  sort: DEFAULT_SORT,
};

import { Languages } from 'utils/constants';

export const isMissingTitles = (
  titlesByLanguage: { [key: string]: string },
  languages: string[],
) =>
  Object.keys(titlesByLanguage)?.length < languages?.length ||
  Object.values(titlesByLanguage).some((v) => !v.trim());

export const hasItemPropertyChanged = (
  item: object,
  values: { [key: string]: string },
  propertyName: string,
  availableLocales: string[],
): boolean => {
  let result = false;

  for (const locale of availableLocales) {
    const property =
      locale === Languages.english.locale
        ? propertyName
        : `${propertyName}_${locale}`;

    result = item?.[property] !== values[locale];

    if (result) {
      break;
    }
  }

  return result;
};

export const getLocalizedValue = (
  item: object,
  propertyName: string,
  locale: string,
) => {
  const capitalizedLocale =
    locale.charAt(0).toUpperCase() + locale.slice(1).toLowerCase();
  return (
    (item?.[`${propertyName}_${locale}`] ||
      item?.[`${propertyName}${capitalizedLocale}`]) ??
    item?.[propertyName] ??
    ''
  );
};

export const getLocalizedCamelCaseValue = (
  item: object,
  propertyName: string,
  locale: string,
) => {
  const capitalizedLocale =
    locale.charAt(0).toUpperCase() + locale.slice(1).toLowerCase();
  return (
    item?.[`${propertyName}${capitalizedLocale}`] ?? item?.[propertyName] ?? ''
  );
};

export const sortByProperty = (
  items: object[],
  propertyName: string,
  locale: string,
) =>
  [...items].sort((item1, item2) =>
    getLocalizedValue(item1, propertyName, locale).localeCompare(
      getLocalizedValue(item2, propertyName, locale),
    ),
  );

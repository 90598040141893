import { useText } from 'hooks';
import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import {
  checkIfGoogleMapsLoaded,
  getDefaultMapSettings,
  getMapMarkerIcon,
} from 'helpers/map';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalError } from 'store/slices/notifications';
import { setActiveJobTab } from 'store/actions/versions';
import { useNavigate } from 'react-router-dom';
import {
  EVENT_MAP_INTERACTION,
  EVENT_PROPERTIES_HAS_MAJOR_HAZARD,
  EVENT_PROPERTIES_HAS_PENDING_JOB_BLOCKERS_FIELD,
  EVENT_PROPERTIES_HAS_UNREAD_MESSAGES_FIELD,
  EVENT_PROPERTIES_PAGE_SOURCE_FIELD,
  EVENT_PROPERTIES_PENDING_ASSESSMENT_SIGN_OFF_FIELD,
  EVENT_PROPERTIES_SAFETY_RISK_FIELD,
  EVENT_VIEW_JOB,
  JobsToggle,
  MAP_VIEW_TAB,
  TIMELINE,
} from 'utils/constants';
import * as Analytics from 'utils/analytics';
import RootState from 'model/State/RootState';
import { JobSort } from 'model/enum/JobSort';
import { MapInteractionType } from 'components/ui/Map/constants';
import { MapContainer, MapWrapper } from './styled';
import { InfoWindowContent } from './components/InfoWindowContent';
import { MapJob } from 'model/MapJob';
import { selectJobsView } from 'store/selectors';

type Props = {
  jobs: MapJob[];
};

const JobViewMap = ({ jobs }: Props): JSX.Element | null => {
  const getText = useText();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { filters } = useSelector((state: RootState) => state.jobs);
  const view = useSelector(selectJobsView);
  const id = 'jobs-map-view';
  const [mapCenter, setMapCenter] = useState<google.maps.LatLng | null>(null);
  const [mapZoom, setMapZoom] = useState<number>(6);
  const [isError, setIsError] = useState(false);

  const defaultMapSettings = getDefaultMapSettings();

  const renderMap = async (centerArgs?, zoom?) => {
    const { Map: GoogleMap, MapTypeId } = (await google.maps.importLibrary(
      'maps',
    )) as google.maps.MapsLibrary;
    const { ControlPosition } = (await google.maps.importLibrary(
      'core',
    )) as google.maps.CoreLibrary;
    const map = new GoogleMap(document.getElementById(id) as HTMLElement, {
      center: centerArgs ?? defaultMapSettings?.defaultCentre,
      zoom: zoom ?? defaultMapSettings?.defaultZoom,
      disableDefaultUI: true,
      zoomControl: true,
      streetViewControl: true,
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: ControlPosition.BOTTOM_LEFT,
      },
      mapTypeId: MapTypeId.ROADMAP,
    });
    const mapCenter = map.getCenter();
    const mapZoom = map.getZoom();

    map.addListener('dragend', () => {
      if (mapCenter) {
        setMapCenter(mapCenter);
      }
    });
    map.addListener('zoom_changed', () => {
      if (mapCenter) {
        setMapCenter(mapCenter);
      }
      if (mapZoom) {
        setMapZoom(mapZoom);
      }
      Analytics.trackEvent(EVENT_MAP_INTERACTION, {
        pageSource: MAP_VIEW_TAB,
        type: MapInteractionType.ZOOM,
        [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      });
    });

    map.addListener('bounds_changed', () => {
      const mapElement = document?.querySelector('.gm-style');
      if (
        mapElement?.clientHeight === window.innerHeight &&
        mapElement?.clientWidth === window.innerWidth
      ) {
        Analytics.trackEvent(EVENT_MAP_INTERACTION, {
          pageSource: MAP_VIEW_TAB,
          type: MapInteractionType.FULL_SCREEN,
          [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        });
      }
    });

    return map;
  };

  const renderMapWithMarkers = async () => {
    const { LatLngBounds } = (await google.maps.importLibrary(
      'core',
    )) as google.maps.CoreLibrary;
    const bounds = new LatLngBounds();

    jobs?.forEach(({ lat, lng }) =>
      bounds.extend({
        lat,
        lng,
      }),
    );
    let map: google.maps.Map;
    if (!mapCenter) {
      const boundCenter = bounds.getCenter();
      map = await renderMap({ lat: boundCenter.lat(), lng: boundCenter.lng() });
    } else {
      map = await renderMap(
        { lat: mapCenter.lat(), lng: mapCenter.lng() },
        mapZoom,
      );
    }
    if (jobs && jobs?.length > 1) {
      map?.fitBounds(bounds);
    }

    createMarkers(map);
  };

  const createMarkers = async (map: google.maps.Map) => {
    if (jobs) {
      for (const job of jobs) {
        const { Marker } = (await google.maps.importLibrary(
          'marker',
        )) as google.maps.MarkerLibrary;
        const marker = new Marker({
          position: {
            lat: job.lat,
            lng: job.lng,
          },
          map,
          icon: {
            url: getMapMarkerIcon(job),
          },
        });

        marker.addListener('click', () => {
          if (job.jobId) {
            dispatch(setActiveJobTab(TIMELINE));
            Analytics.trackEvent(EVENT_VIEW_JOB, {
              source:
                filters.scope === JobSort.USER
                  ? JobsToggle.MY_JOBS
                  : JobsToggle.ALL_JOBS,
              jobId: job.jobId,
              [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: MAP_VIEW_TAB,
              [EVENT_PROPERTIES_SAFETY_RISK_FIELD]: job.isHighSafetyRisk,
              [EVENT_PROPERTIES_HAS_UNREAD_MESSAGES_FIELD]:
                job.hasUnreadMessages,
              [EVENT_PROPERTIES_HAS_PENDING_JOB_BLOCKERS_FIELD]: job.isBlocked,
              [EVENT_PROPERTIES_PENDING_ASSESSMENT_SIGN_OFF_FIELD]:
                job.hasPendingRiskAssessment,
              [EVENT_PROPERTIES_HAS_MAJOR_HAZARD]: job.hasMajorHazard,
            });
            navigate(`/jobs/${job.jobId}/timeline`);
          }
        });
        const { InfoWindow } = (await google.maps.importLibrary(
          'streetView',
        )) as google.maps.StreetViewLibrary;
        const infoWindow = new InfoWindow({
          content: '',
        });
        marker.addListener('mouseover', () => {
          const div = document.createElement('div');
          const root = createRoot(div);
          root.render(<InfoWindowContent {...job} getText={getText} />);
          infoWindow.setContent(div);
          infoWindow.open(map, marker);
        });
        marker.addListener('mouseout', () => infoWindow.close());
      }
    }
  };

  const googleMapsIsLoaded = checkIfGoogleMapsLoaded();

  useEffect(() => {
    if (googleMapsIsLoaded) {
      if (jobs && jobs.length > 0) {
        renderMapWithMarkers();
      } else if (jobs) {
        renderMap();
      }
    } else {
      dispatch(setGlobalError(getText('command_centre_google_maps_error')));
      setIsError(true);
    }
  }, [jobs, googleMapsIsLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isError) {
    return null;
  }
  return (
    <MapWrapper>
      <MapContainer id={id} />
    </MapWrapper>
  );
};

export default JobViewMap;

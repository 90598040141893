import { Tooltip } from 'components/ui/Display';
import { Toggle } from 'components/ui/Input';
import { useJobs, useText } from 'hooks';
import RootState from 'model/State/RootState';
import { JobSort } from 'model/enum/JobSort';
import { useDispatch, useSelector } from 'react-redux';
import { setJobFilters } from 'store/actions/jobs';
import styled from 'styled-components';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';

const { colors, fonts, fontSizes, media } = theme;

const Wrapper = styled.div`
  width: max-content;
  padding-right: 15px;
`;
const Label = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
  ${media.sm`
    color: ${colors.white};
  `}
  padding-right: 10px;
`;

type Props = {
  view: string;
  hasUnresolvedVra?: boolean;
  hasUnresolvedBlocker?: boolean;
};

const MyLiveJobsToggle = ({
  view,
  hasUnresolvedVra = false,
  hasUnresolvedBlocker = false,
}: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const { setFilters } = useJobs();
  const { filters } = useSelector((state: RootState) => state.jobs);

  const handleScopeToggle = () => {
    const scope = filters.scope ? null : JobSort.USER;
    Analytics.trackEvent(constants.EVENT_TOGGLE_LIST_VIEW, {
      'toggle-set':
        scope === JobSort.USER
          ? constants.JobsToggle.MY_JOBS
          : constants.JobsToggle.ALL_JOBS,
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    });
    if (view === constants.LEGACY_VIEW) {
      const sort = filters.scope ? JobSort.CREATED : JobSort.PRIORITY;
      dispatch(
        setJobFilters({
          ...filters,
          has_unresolved_vra: filters.scope ? false : hasUnresolvedVra,
          has_unresolved_blocker: filters.scope ? false : hasUnresolvedBlocker,
          scope,
          sort,
        }),
      );
    } else {
      setFilters({
        ...filters,
        scope,
      });
    }
  };

  if (view !== constants.LEGACY_VIEW && filters?.q) {
    return (
      <Tooltip
        title={getText('jobs_clear_search_tooltip')}
        triggerElement={
          <Wrapper>
            <Toggle
              id='my-live-jobs'
              disabled
              checked={false}
              label={<Label>{getText('jobs_search_filter_switch_text')}</Label>}
            />
          </Wrapper>
        }
        placement='bottom'
        height={40}
      />
    );
  }
  return (
    <Wrapper>
      <Toggle
        id='my-live-jobs'
        checked={!!filters?.scope}
        onChange={handleScopeToggle}
        label={<Label>{getText('jobs_search_filter_switch_text')}</Label>}
      />
    </Wrapper>
  );
};

export default MyLiveJobsToggle;

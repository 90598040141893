import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Loader } from 'components/ui';
import { useText } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { SortOrder } from 'model/enum/SortOrder';
import Sort from 'model/Sort';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { ComplianceType } from 'model/enum/ComplianceType';
import VehicleCheckUserComplianceDataTable from './VehicleCheckUserComplianceDataTable';
import { fetchVehicleCheckUserCompliance } from 'store/actions/insights';
import {
  selectActiveWorkspaceUuid,
  selectIsLoadingVehicleCheckUserCompliance,
  selectVehicleCheckUserComplianceData,
} from 'store/selectors';
import { stringifyUserIds } from 'api/Insights/helpers';

const { colors, fonts, fontSizes, media } = theme;

// Styled Components
const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 40px !important;
`;

const DataContainer = styled.div`
  padding: 40px 80px;
  background-color: ${colors.offWhite};
  min-height: 100%;
`;

const DataRow = styled.div`
  height: 650px;
  display: flex;
  background: ${colors.white};
  padding: 32px;
  ${media.lg`
    padding: 16px;
  `};
`;

type Props = {
  depotId: number;
  search: string;
  startDate: Date;
  endDate: Date;
  isWeekendIncluded: boolean;
  userFilters: number[];
};

// VehicleCheckUserCompliance
const VehicleCheckUserCompliance = ({
  depotId,
  search,
  startDate,
  endDate,
  isWeekendIncluded,
  userFilters,
}: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();

  const initialSort = {
    field: constants.vehicleCheckUserComplianceFields.SUBMISSIONS,
    order: SortOrder.DESCENDING,
  };
  const [sort, setSort] = useState<Sort>(initialSort);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const isLoading = useSelector(selectIsLoadingVehicleCheckUserCompliance);
  const {
    count: total,
    columns: complianceColumns,
    results: complianceData,
  } = useSelector(selectVehicleCheckUserComplianceData);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);

  const userIds = stringifyUserIds(userFilters);

  const getComplianceData = useCallback(
    (page: number = 1) => {
      if (depotId) {
        dispatch(
          fetchVehicleCheckUserCompliance(
            depotId,
            startDate,
            endDate,
            !isWeekendIncluded,
            sort,
            userIds,
            search,
            selectedWorkspaceUuid,
            page,
          ),
        );

        Analytics.trackEvent(
          `${ComplianceType.VEHICLE_CHECK_USER}-${constants.EVENT_COMPLIANCE_LOADED}`,
          {
            depotId,
            startDate,
            endDate,
            isWeekendIncluded,
            sort,
            hasFilters: !!userIds,
            ...(!!userIds && { userIds }),
            search,
            page,
          },
        );
      }
      setCurrentPage(page);
    },
    [
      dispatch,
      depotId,
      startDate,
      endDate,
      isWeekendIncluded,
      search,
      selectedWorkspaceUuid,
      sort,
      userIds,
    ],
  );

  useEffect(() => {
    getComplianceData();
  }, [getComplianceData]);

  return (
    <DataContainer>
      <H2>{getText('insights_vehicle_check_user_compliance_title')}</H2>
      {complianceData ? (
        <DataRow>
          <VehicleCheckUserComplianceDataTable
            data={complianceData}
            columns={complianceColumns}
            total={total}
            currentPage={currentPage}
            onPageChange={(page) => getComplianceData(page)}
            sort={sort}
            setSort={setSort}
            initialSort={initialSort}
            analyticsData={{
              depotId,
              startDate,
              endDate,
              isWeekendIncluded,
              sort,
              rowsOfData: total,
            }}
            isLoading={isLoading}
          />
        </DataRow>
      ) : (
        <DataRow>
          <Loader />
        </DataRow>
      )}
    </DataContainer>
  );
};

export default VehicleCheckUserCompliance;

import theme from 'theme';
import { BoldText, InfoRow, TickIcon, WarningIcon } from './styled';

type Props = {
  title: string;
  value: string;
  isWarning: boolean;
};

export const QualityInfo = ({
  title,
  value,
  isWarning,
}: Props): JSX.Element => (
  <InfoRow>
    {isWarning ? <WarningIcon color={theme.colors.black} /> : <TickIcon />}
    <BoldText>{title}: </BoldText>
    {value}
  </InfoRow>
);

import { useState } from 'react';
import { CSSProperties } from 'styled-components';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { renderTimeViewClock, TimeView } from '@mui/x-date-pickers';
import { useText } from 'hooks';
import { formatDateAndTime, getLocale, isSameDay } from 'helpers/dates';
import { endOfDay, startOfDay } from 'date-fns';
import { StyledDateTimePicker } from './styled';
import theme from 'theme';
import { Container, ErrorMessage, Label } from '../styled';

const { colors } = theme;

type Props = {
  onChange: (value: Date | null) => void;
  value?: string;
  label?: string;
  disabled?: boolean;
  extraStyles?: CSSProperties;
  shiftStartedAt?: string;
  isInvalid?: boolean;
};

// Input.DateTimeSelector
export const DateTimeSelector = ({
  value,
  label,
  disabled = false,
  extraStyles,
  shiftStartedAt,
  isInvalid = false,
  onChange,
}: Props): JSX.Element => {
  const getText = useText();
  const startedAt = shiftStartedAt ? new Date(shiftStartedAt) : new Date();
  const now = new Date();
  const [selectedTime, setSelectedTime] = useState<Date | null>(
    value ? new Date(value) : now,
  );
  const [open, setOpen] = useState<boolean>(false);

  const range = (start: number, end: number): number[] => {
    const result: number[] = [];
    for (let i = start; i < end; i += 1) {
      result.push(i);
    }
    return result;
  };

  const isEqualHours = (value, selectedTime): boolean => {
    const selectedHour = selectedTime
      ? new Date(selectedTime.toString()).getHours()
      : null;
    return !!selectedHour && selectedHour === value;
  };

  const shouldDisableDate = (current: Date): boolean => {
    const isAfterEndOfToday = endOfDay(new Date()) < current;
    const isBeforeShiftStart =
      !!shiftStartedAt && startOfDay(new Date(shiftStartedAt)) > current;
    return !!shiftStartedAt && (isAfterEndOfToday || isBeforeShiftStart);
  };

  const shouldDisableTime = (current: Date, clockType: TimeView) => {
    const isHours = clockType === 'hours';

    const isSelectedToday = isSameDay(selectedTime, now);
    const isSelectedOnStartDay = isSameDay(selectedTime, startedAt);
    const isSelectedThisHour = isEqualHours(now.getHours(), selectedTime);
    const isSelectedStartHour = isEqualHours(
      startedAt.getHours(),
      selectedTime,
    );

    if (shiftStartedAt) {
      if (isSelectedToday && isSelectedOnStartDay) {
        if (isSelectedThisHour && isSelectedStartHour) {
          const notAllowedHoursRange = range(0, startedAt.getHours()).concat(
            range(now.getHours() + 1, 24),
          );
          const notAllowedMinutesRange = range(now.getMinutes() + 1, 60).concat(
            range(now.getMinutes() + 1, 60),
          );
          return isHours
            ? notAllowedHoursRange.includes(current.getHours())
            : notAllowedMinutesRange.includes(current.getMinutes());
        }
        if (isSelectedThisHour) {
          const notAllowedHoursRange = range(0, startedAt.getHours()).concat(
            range(now.getHours() + 1, 24),
          );
          const notAllowedMinutesRange = range(now.getMinutes() + 1, 60);

          return isHours
            ? notAllowedHoursRange.includes(current.getHours())
            : notAllowedMinutesRange.includes(current.getMinutes());
        }
        if (isSelectedStartHour) {
          const notAllowedHoursRange = range(0, startedAt.getHours()).concat(
            range(now.getHours() + 1, 24),
          );
          const notAllowedMinutesRange = range(0, startedAt.getMinutes() + 1);
          return isHours
            ? notAllowedHoursRange.includes(current.getHours())
            : notAllowedMinutesRange.includes(current.getMinutes());
        }
        const notAllowedHoursRange = range(0, startedAt.getHours()).concat(
          range(now.getHours() + 1, 24),
        );
        return isHours
          ? notAllowedHoursRange.includes(current.getHours())
          : false;
      }
      if (isSelectedToday && !isSelectedOnStartDay) {
        if (isSelectedThisHour) {
          const notAllowedHoursRange = range(now.getHours() + 1, 24);
          const notAllowedMinutesRange = range(now.getMinutes() + 1, 60);

          return isHours
            ? notAllowedHoursRange.includes(current.getHours())
            : notAllowedMinutesRange.includes(current.getMinutes());
        }
        const notAllowedHoursRange = range(now.getHours() + 1, 24);
        return isHours
          ? notAllowedHoursRange.includes(current.getHours())
          : false;
      }
      if (isSelectedOnStartDay && !isSelectedToday) {
        if (isSelectedStartHour) {
          const notAllowedHoursRange = range(0, startedAt.getHours());
          const notAllowedMinutesRange = range(0, startedAt.getMinutes() + 1);
          return isHours
            ? notAllowedHoursRange.includes(current.getHours())
            : notAllowedMinutesRange.includes(current.getMinutes());
        }
        const notAllowedHoursRange = range(0, startedAt.getHours());
        return isHours
          ? notAllowedHoursRange.includes(current.getHours())
          : false;
      }
    }
    return false;
  };

  return (
    <Container style={extraStyles}>
      {label && <Label $marginBottom='10px'>{label}</Label>}
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={getLocale()}
      >
        <StyledDateTimePicker
          open={!disabled && open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          value={selectedTime}
          format={formatDateAndTime(selectedTime)}
          onChange={(newValue: any) => {
            const dateTimeWithoutSeconds = new Date(newValue);
            dateTimeWithoutSeconds.setSeconds(0, 0);
            setSelectedTime(dateTimeWithoutSeconds);
            onChange(dateTimeWithoutSeconds);
          }}
          shouldDisableDate={(date: Date) => shouldDisableDate(date)}
          shouldDisableTime={shouldDisableTime}
          disabled={disabled}
          ampm={false}
          slotProps={{
            textField: {
              onClick: () => setOpen(true),
              disabled: disabled,
            },
            actionBar: { actions: [] },
            layout: {
              sx: {
                '.MuiPickersDay-root.Mui-selected, .MuiClock-clock > div:not(:first-of-type), .MuiClock-clock > div:not(:first-of-type) > div':
                  {
                    backgroundColor: `${colors.orange} !important`,
                    borderColor: `${colors.orange} !important`,
                  },
              },
            },
          }}
        />
      </LocalizationProvider>
      {isInvalid && (
        <ErrorMessage>{getText('date_time_picker_invalid')}</ErrorMessage>
      )}
    </Container>
  );
};

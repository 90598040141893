import { useEffect } from 'react';
import { useText } from 'hooks';
import * as constants from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { Button, Input } from 'components/ui';
import { LoginForm, PaddedWrapper } from './styled';
import { FormContainer } from '../styled';
import { isBlank, isValidEmail } from 'helpers/utils';
import theme from 'theme';
import { LogoSlogan } from 'components/ui/Logos';

const { colors } = theme;

type Props = {
  handleEmailCheck: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
};

export const OrganisationEmailForm = ({
  handleEmailCheck,
  email,
  setEmail,
}: Props): JSX.Element => {
  const getText = useText();

  useEffect(() => {
    Analytics.trackPage(constants.EVENT_PAGE_VIEW_LOGIN, {
      page: constants.LoginPages.EMAIL_CHECK,
    });
  }, []);

  const onSubmitEmail = () => {
    handleEmailCheck(email);
  };

  const isDisabled = isBlank(email) || !isValidEmail(email);

  return (
    <>
      <LogoSlogan />
      <FormContainer>
        <LoginForm>
          <PaddedWrapper>
            <Input.Text
              id='email-login'
              label={getText('login_email')}
              labelColor={colors.white}
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              onEnter={!isDisabled ? onSubmitEmail : undefined}
              hasBlackBackground
            />
            <Button.Primary
              text={getText('continue_cta')}
              disabled={isDisabled}
              onClick={onSubmitEmail}
              width='240px'
              event={constants.EVENT_CLICK_LOGIN_CONTINUE}
              hasBlackBackground
            />
          </PaddedWrapper>
        </LoginForm>
      </FormContainer>
    </>
  );
};

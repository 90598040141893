import { useText } from 'hooks';
import { Hazards, HazardWithControl } from 'model/Assessment/Hazard';
import { SourceText } from './styled';

type Props = { hazardWithControls: HazardWithControl };

export const HazardSource = ({
  hazardWithControls,
}: Props): JSX.Element | null => {
  const getText = useText();
  const source = Hazards.getHazardSource(hazardWithControls.sources);

  return source ? (
    <SourceText>
      {`${getText(
        'assessment_details_hazard_source_identified_by',
      )} ${getText(`assessment_details_hazard_source_${source}`)}`}
    </SourceText>
  ) : null;
};

import {
  Assessment,
  Control,
  Document,
  Evidence,
  Eye,
  Note,
  Warning,
  WorkOrderItem,
  Permit,
  YellowCheckMark,
  Arrow,
} from 'assets/icons';
import { NotUploadedTimelinePlaceholder } from 'assets/images';
import { Storage } from 'aws-amplify';
import { Badge, Media } from 'components/ui';
import PermitStatus from 'components/views/Protected/Jobs/Permits/PermitStatus';
import { Dates, Utils } from 'helpers';
import { useText } from 'hooks';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import { selectWorkspaceSettings } from 'store/selectors';
import { User } from 'model/User';
import { LastApproval } from 'model/Forms/LastApproval';
import { MajorHazardTag } from '../Tags/MajorHazardTag';
import {
  ActionBox,
  ActionBoxWrapper,
  ActionIcon,
  BlackStatusContainer,
  Container,
  CreationDateContainer,
  Description,
  Icon,
  LinearGradient,
  ManualRAFlag,
  OrangeStatusContainer,
  Placeholder,
  SmallClockIcon,
  SmallWarningIcon,
  Status,
  Subtype,
  ThumbnailContainer,
  ThumbnailOverLay,
  ThumbnailText,
  Type,
  VideoTimerIcon,
} from './styled';
import { NotAvailableTag } from '../Tags/NotAvailableTag';
import { QualityIndicator } from 'model/Media/Video';

const { colors, fonts } = theme;

type Props = {
  type: string;
  sender: User;
  date: string;
  to: string;
  subtype?: string;
  thumbnail?: string;
  comments?: string;
  isSignedOff?: boolean;
  signoffRequired?: boolean;
  isLocked?: boolean;
  isNotUploaded: boolean;
  resolutionRequired?: boolean;
  jobBlockerResolved?: boolean;
  label?: string;
  fileSize?: number;
  extensionType?: string;
  isOfflineFlow?: boolean;
  hasReviewWithNoVideoLabel?: boolean;
  id?: number;
  scrollTo?: boolean;
  onClick?: (id?: number) => void;
  isRams?: boolean;
  isRamsReviewed?: boolean;
  isMethodStatementReady?: boolean;
  isPermit?: boolean;
  lastApproval?: LastApproval;
  button_logo?: string;
  hasArrow?: boolean;
  isForm?: boolean;
  isMajor?: boolean;
  qualityIndicator?: QualityIndicator | null;
};

// Message.Action
export const Action = Analytics.withTrackEvent(
  ({
    type,
    sender,
    date,
    to,
    subtype,
    id,
    thumbnail,
    comments,
    scrollTo,
    onClick,
    hasArrow = false,
    isLocked = false,
    qualityIndicator = null,
    ...props
  }: Props): JSX.Element => {
    const getText = useText();
    const [thumbnailURL, setThumbnailURL] = useState<string>('');
    const { view_unreviewed_vra } = useSelector(selectWorkspaceSettings);

    useEffect(() => {
      (async () => {
        if (thumbnail) {
          const signedURL = await Storage.get(thumbnail);
          setThumbnailURL(signedURL);
        }
      })();
    }, [thumbnail]);

    useLayoutEffect(() => {
      if (scrollTo) {
        const action = document.getElementById(id?.toString() ?? '');
        if (action) {
          action.scrollIntoView();
        }
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getActionComponent = () => {
      if (props?.button_logo) {
        // Custom forms are objects with a button_logo field
        switch (props.button_logo) {
          case 'stamp':
            return <Permit />;
          case 'warning':
            return <Warning />;
          default:
            return <Evidence />;
        }
      }

      switch (type) {
        case getText('job_details_timeline_action_risk_assessment'):
          return props.isRams ? <Control /> : <Assessment />;
        case getText('job_details_timeline_action_evidence'):
          return <Evidence />;
        case getText('job_details_timeline_action_job_blocker'):
          return <Warning />;
        case getText('job_details_timeline_action_note'):
          return <Note />;
        case getText('job_details_timeline_action_work_completed'):
          return <WorkOrderItem />;
        case getText('job_details_timeline_action_document'):
          return <Document />;
        case getText('job_details_timeline_action_permit'):
          return <Permit />;
        default:
          return <Evidence />;
      }
    };

    const onContainerClick = () => {
      if (onClick) {
        if (id) {
          onClick(id);
        } else {
          onClick();
        }
      }
    };

    const renderCompleteContainer = (title) => (
      <BlackStatusContainer>
        <YellowCheckMark />
        <Status>{title}</Status>
      </BlackStatusContainer>
    );

    const renderNotCompleteContainer = (title) => (
      <OrangeStatusContainer>
        <SmallWarningIcon />
        <Status $textColor={colors.black}>{title}</Status>
      </OrangeStatusContainer>
    );

    const getAssessmentStatusTag = (
      isCompleted,
      completedTitle,
      notCompletedTitle,
    ) =>
      isCompleted
        ? renderCompleteContainer(completedTitle)
        : renderNotCompleteContainer(notCompletedTitle);

    const renderRamsTag = (isRamsReviewed, isMethodStatementReady) => {
      if (!isRamsReviewed && !isMethodStatementReady) {
        return renderNotCompleteContainer(getText('job_details_rams_pending'));
      }
      if (isRamsReviewed && !isMethodStatementReady) {
        return renderNotCompleteContainer(
          getText('job_details_rams_method_statement_pending'),
        );
      }
      return renderCompleteContainer(
        getText('job_details_rams_method_statement_complete'),
      );
    };

    const renderStatus = () => {
      if (props.jobBlockerResolved) {
        return (
          <BlackStatusContainer>
            <YellowCheckMark />
            <Status>{getText('job_log_blocker_resolved')}</Status>
          </BlackStatusContainer>
        );
      }
      return (
        <OrangeStatusContainer>
          <SmallWarningIcon />
          <Status $textColor={colors.black}>
            {getText('job_log_blocker_requires_resolution')}
          </Status>
        </OrangeStatusContainer>
      );
    };

    return (
      <Container {...props} onClick={onContainerClick} id={id?.toString()}>
        <Badge.User user={sender} />
        <ActionBox to={to} $isLocked={!view_unreviewed_vra && isLocked}>
          <ActionBoxWrapper>
            <ActionIcon component={getActionComponent()} />
            {type === getText('job_details_timeline_action_document') ? (
              <Description>
                <Type $hasLineHeight>{props.label}</Type>
                <Subtype>
                  {props.extensionType} &bull;
                  {Utils.formatBytes(props.fileSize)}
                </Subtype>
              </Description>
            ) : (
              <Description>
                <Type $hasLineHeight>{type}</Type>
                <Subtype>{subtype}</Subtype>
              </Description>
            )}
            {(!isLocked || view_unreviewed_vra) && (
              <Icon component={hasArrow ? <Arrow /> : <Eye />} />
            )}
          </ActionBoxWrapper>

          {type === getText('job_details_timeline_action_evidence') && (
            <ThumbnailContainer>
              {thumbnailURL ? (
                <Media
                  type='photo'
                  kind='evidence'
                  url={thumbnailURL}
                  photoCover
                  qualityIndicator={qualityIndicator}
                />
              ) : (
                <Placeholder />
              )}
              {comments && <LinearGradient />}

              <ThumbnailText>{comments?.substring(0, 60)}</ThumbnailText>
            </ThumbnailContainer>
          )}
          {type === getText('job_details_timeline_action_risk_assessment') && (
            <ThumbnailContainer>
              {isLocked && (
                <ThumbnailOverLay>
                  <SmallClockIcon />
                </ThumbnailOverLay>
              )}
              {(props.isNotUploaded || props.hasReviewWithNoVideoLabel) && (
                <ThumbnailOverLay>
                  <VideoTimerIcon />
                </ThumbnailOverLay>
              )}
              {props.isOfflineFlow && (
                <ManualRAFlag>
                  {getText('job_details_manual_ra_tag')}
                </ManualRAFlag>
              )}
              {thumbnailURL ? (
                <Media
                  type='photo'
                  kind='assessment'
                  url={thumbnailURL}
                  photoCover
                  qualityIndicator={qualityIndicator}
                />
              ) : props.isNotUploaded || props.hasReviewWithNoVideoLabel ? (
                <Media
                  type='photo'
                  kind='assessment'
                  url={NotUploadedTimelinePlaceholder}
                  photoCover
                />
              ) : (
                <Placeholder />
              )}
            </ThumbnailContainer>
          )}
          {props.isForm && thumbnail && (
            <ThumbnailContainer>
              {thumbnailURL ? (
                <Media
                  type='photo'
                  kind='job-form'
                  url={thumbnailURL}
                  photoCover
                  qualityIndicator={qualityIndicator}
                />
              ) : (
                <Placeholder />
              )}
            </ThumbnailContainer>
          )}
          {props.signoffRequired &&
            !props.isRams &&
            !props.isNotUploaded &&
            !isLocked &&
            getAssessmentStatusTag(
              props.isSignedOff,
              getText('job_details_sign_off_done'),
              getText('job_details_sign_off_required'),
            )}
          {props.isRams &&
            renderRamsTag(props.isRamsReviewed, props.isMethodStatementReady)}
          {isLocked && (
            <OrangeStatusContainer>
              <SmallWarningIcon />
              <Status $textColor={colors.black}>
                {getText('job_details_review_pending')}
              </Status>
            </OrangeStatusContainer>
          )}
          {props.isMajor && (
            <MajorHazardTag
              text={getText('job_overview_major_hazard_identified')}
              hasSmallIcon
              extraStyles={{ marginTop: 0, padding: '7px 17px' }}
            />
          )}
          {(props.isNotUploaded || props.hasReviewWithNoVideoLabel) && (
            <NotAvailableTag
              text={getText('job_details_not_fully_uploaded')}
              extraStyles={{
                marginTop: 0,
                padding: '7px 17px',
                fontFamily: fonts.default,
              }}
            />
          )}
          {props.isPermit && (
            <PermitStatus lastApproval={props.lastApproval ?? null} />
          )}
          {type === getText('job_details_timeline_action_job_blocker') &&
            props.resolutionRequired &&
            renderStatus()}
        </ActionBox>
        <CreationDateContainer>
          {Dates.formatDateTime(
            date,
            getText('date_today'),
            getText('date_yesterday'),
          )}
        </CreationDateContainer>
      </Container>
    );
  },
);

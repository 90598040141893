import { Button } from 'components/ui';
import { useLocation } from 'react-router-dom';
import { Field } from 'model/Forms/Field';
import * as constants from 'utils/constants';
import { useSelector } from 'react-redux';
import { selectJobsView } from 'store/selectors';
import { CustomFormClass } from 'model/Form';

type Props = {
  field: Field;
  formClass: CustomFormClass;
  formId?: string;
  jobId?: string;
};

const InstructionWidget = ({
  field,
  formClass,
  formId,
  jobId,
}: Props): JSX.Element => {
  const { pathname } = useLocation();
  const view = useSelector(selectJobsView);

  const to = `/jobs/${jobId}/${formClass === CustomFormClass.JOB ? 'job-forms' : 'permits'}/${formId}/instruction/${field.id}`;

  return (
    <Button.SecondaryLink
      text={field.title}
      to={to}
      event={constants.INSTRUCTION_WIDGET_CLICKED}
      eventProperties={
        pathname.includes('insights')
          ? undefined
          : {
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }
      }
    />
  );
};

export default InstructionWidget;

import {
  BackendHavsFilters,
  BackendHavsLimit,
  HavsFilters,
  HavsLimit,
  HavsManager,
  HavsUser,
} from 'model/AdminPanel/HavsSettings/HavsUser';
import { BackendData, BackendHavsManager, BackendHavsUser } from './types';
import { BackendFields } from './constants';

export const mapToHavsUser = (backendUser: BackendHavsUser): HavsUser => ({
  id: backendUser.id,
  firstName: backendUser.first_name,
  lastName: backendUser.last_name,
  email: backendUser.email,
  role: backendUser.role,
  depot: {
    id: backendUser.depot?.id,
    title: backendUser.depot?.title,
  },
});

const mapToHavsManager = (backendUser: BackendHavsManager): HavsManager => ({
  id: backendUser.id,
  firstName: backendUser.first_name,
  lastName: backendUser.last_name,
});

export const mapToHavsLimits = (input: BackendData): HavsLimit => ({
  user: mapToHavsUser(input.user),
  havsLimit: input.havs_limit,
  manager: mapToHavsManager(input.manager),
  updatedAt: new Date(input.updated_at),
  updatedBy: mapToHavsManager(input.updated_by),
});

export const mapToBackendHavsLimit = (input: HavsLimit): BackendHavsLimit => ({
  user: input.user.id,
  manager: input.manager.id,
  havs_limit: input.havsLimit,
});

export const mapToBackendHavsFilters = (
  filters: HavsFilters,
): BackendHavsFilters => ({
  depot_id: filters.depot?.id ?? null,
  manager_id: filters.manager?.id ?? null,
  havs_limit: filters.havsLimit,
  search: filters.search,
  order_by: filters.sort?.field
    ? (BackendFields[filters.sort.field] ?? null)
    : null,
  order_by_dir: filters.sort?.order ?? null,
});

import { CSSProperties } from 'styled-components';
import { useDispatch } from 'react-redux';
import { setActiveJobTab, setJobsTab } from 'store/actions/versions';
import * as Analytics from 'utils/analytics';
import {
  EVENT_LIST_VIEW_CLICKED,
  EVENT_MAP_VIEW_CLICKED,
  EVENT_LEGACY_VIEW_CLICKED,
  LIST_VIEW_TAB,
  LEGACY_VIEW,
  MAP_VIEW_TAB,
  TIMELINE,
  EVENT_PROPERTIES_PAGE_SOURCE_FIELD,
} from 'utils/constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useJobs } from 'hooks';
import { LegacyMapView, ListView, MapView, Tab, TabContainer } from './styled';

type Props = {
  activeTab: string;
  extraStyles?: CSSProperties;
};

// Tabs.JobView
export const JobView = ({ activeTab, extraStyles }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { jobId } = useParams();
  const { clearFiltersOnViewSwitch } = useJobs();

  const onTabClick = (value: string, event: string) => {
    switch (value) {
      case LIST_VIEW_TAB:
      case MAP_VIEW_TAB:
        dispatch(setJobsTab(value));
        if (activeTab === LEGACY_VIEW) {
          clearFiltersOnViewSwitch(value);

          if (jobId) {
            navigate(`${location.pathname}/timeline`);
            dispatch(setActiveJobTab(TIMELINE));
          }
        }
        break;
      case LEGACY_VIEW:
        dispatch(setJobsTab(value));
        clearFiltersOnViewSwitch(value);
        break;
      default:
    }
    Analytics.trackEvent(event, {
      [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: activeTab,
    });
  };

  return (
    <TabContainer style={extraStyles} className='walkthrough-step-legacy'>
      <Tab
        isActive={activeTab === LIST_VIEW_TAB}
        onClick={() => onTabClick(LIST_VIEW_TAB, EVENT_LIST_VIEW_CLICKED)}
      >
        <ListView />
      </Tab>

      <Tab
        isActive={activeTab === MAP_VIEW_TAB}
        onClick={() => onTabClick(MAP_VIEW_TAB, EVENT_MAP_VIEW_CLICKED)}
        collapseBorders
      >
        <MapView />
      </Tab>

      <Tab
        isActive={activeTab === LEGACY_VIEW}
        onClick={() => onTabClick(LEGACY_VIEW, EVENT_LEGACY_VIEW_CLICKED)}
      >
        <LegacyMapView />
      </Tab>
    </TabContainer>
  );
};

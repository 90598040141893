import { Document as DocumentIcon } from 'assets/icons';
import { Utils } from 'helpers';
import { useIsMobile } from 'hooks';
import { useSelector } from 'react-redux';
import * as Analytics from 'utils/analytics';
import { selectGlobalLanguageSetting, selectJobsView } from 'store/selectors';
import { Message } from 'model/Message';
import JobData from 'model/JobData';
import * as constants from 'utils/constants';

import { MessageWithAction } from './MessageWithAction';
import { useDispatch } from 'react-redux';
import { setPreviousTimelinePath } from 'store/actions/jobs';
import { useLocation } from 'react-router-dom';

type Props = {
  message: Message;
  activeJob: JobData;
  onClick: () => void;
};

// Message.Document
export const Document = Analytics.withTrackEvent(
  ({ message, activeJob, onClick }: Props): JSX.Element => {
    const location = useLocation();
    const isMobile = useIsMobile();
    const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
    const view = useSelector(selectJobsView);
    const dispatch = useDispatch();

    const extensionType = message.target.extension_type.toUpperCase();
    const fileSize = message.target.file_size;

    const type: string =
      message.target[`original_file_name_${globalAppLanguage}`] ||
      message.target.original_file_name;

    const subtype = (
      <>
        {extensionType} &bull; {Utils.formatBytes(fileSize)}
      </>
    );

    const to =
      isMobile || view !== constants.LEGACY_VIEW
        ? `/jobs/${activeJob.id}/documents`
        : `/jobs/${activeJob.id}?overviewTab=documents`;

    const setPreviousTimelinePathAndClick = () => {
      // I could have written this as view === constants.LIST_VIEW but I did it this way to
      // make it easier to deprecate the legacy view
      if (isMobile || view !== constants.LEGACY_VIEW) {
        dispatch(setPreviousTimelinePath(activeJob.id, location.pathname));
      }
      onClick();
    };

    return (
      <MessageWithAction
        message={message}
        onClick={setPreviousTimelinePathAndClick}
        to={to}
        actionIcon={<DocumentIcon />}
        type={type}
        subtype={subtype}
      />
    );
  },
);

import { Dates } from 'helpers';
import { Note, Warning } from 'assets/icons';
import * as Analytics from 'utils/analytics';
import { useText } from 'hooks';
import { User } from 'model/User';
import {
  CardContainer,
  CreationDate,
  Creator,
  EyeIcon,
  FlexCenterContainer,
  Icon,
  Type,
  Subtype,
} from './styled';
import { MessageTypes } from '../Message/constants';

type Props = {
  type: string;
  subtype: string;
  creator: User;
  date: string;
  to: string;
  customMargin?: string;
};

// Card.Log
export const Log = Analytics.withTrackEvent(
  ({
    type,
    subtype,
    creator,
    date,
    to,
    customMargin,
    ...props
  }: Props): JSX.Element => {
    const getText = useText();
    const isNote = type === MessageTypes.NOTE;

    return (
      <CardContainer to={to} customMargin={customMargin} {...props}>
        <FlexCenterContainer>
          <Icon
            component={isNote ? <Note /> : <Warning />}
            width={30}
            $marginRight={20}
          />

          <div>
            <Type>
              {getText(
                isNote
                  ? 'job_details_timeline_action_note'
                  : 'job_details_timeline_action_job_blocker',
              )}
            </Type>
            <Subtype>{subtype}</Subtype>
          </div>
        </FlexCenterContainer>

        <FlexCenterContainer>
          <Creator>{creator.fullName}</Creator>

          <CreationDate $marginLeft={50} $marginRight={25}>
            {Dates.formatDateTime(
              date,
              getText('date_today'),
              getText('date_yesterday'),
            )}
          </CreationDate>

          <EyeIcon />
        </FlexCenterContainer>
      </CardContainer>
    );
  },
);

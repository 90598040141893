import { Drawer as MaterialDrawer } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';
import { ActionButtonBlocker, ActionButtonJobs, Eye } from 'assets/icons';

const { colors, mixins, media } = theme;

// Styled components
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.offWhite};
  width: 100%;
  padding: 15px 25px;
  box-sizing: border-box;
`;

export const StatsWrapper = styled.div`
  ${mixins.flexBetween};
  flex-direction: row;
  height: fit-content;
  padding-bottom: 10px;
  box-sizing: border-box;
`;

export const SkeletonStatsWrapper = styled.div`
  ${mixins.flexBetween};
  flex-direction: row;
  min-height: 95px;
  padding-bottom: 10px;
  box-sizing: border-box;
`;

export const StatusCardsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Drawer = styled(({ offsetHeight, ...props }) => (
  <MaterialDrawer {...props} />
))`
  .MuiDrawer-paper {
    height: ${({ offsetHeight }) =>
      `calc(100vh - 65px - ${offsetHeight || 0}px)`} !important;
    width: 25vw !important;
    bottom: 0;
    top: auto;
    ${media.xxl`
      width: 30vw !important;
    `}
    ${media.xl`
      width: 35vw !important;
    `}
    ${media.lg`
      width: 45vw !important;
    `}
  }

  .MuiBackdrop-root {
    background-color: transparent;
  }
`;

export const ActionButtonBlockerIcon = styled(ActionButtonBlocker)`
  .fill-selector {
    fill: ${colors.black};
  }
`;

export const ActionButtonJobsIcon = styled(ActionButtonJobs)`
  transform: scale(1.5);
  ${media.xl`
    transform: scale(1.25);
  `}
`;

export const EyeWithoutTopPadding = styled(Eye)`
  top: 0;
`;

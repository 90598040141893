import { formatMonth } from 'helpers/dates';
import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { CSSProperties } from 'styled-components';
import { StyledCalendar } from './styled';
import { Container } from '../styled';

type Props = {
  selectedDate: Date | null;
  minDate: Date;
  onDateChange: (value: Date | null) => void;
  extraStyles: CSSProperties;
};

export const Calendar = ({
  selectedDate,
  minDate,
  onDateChange,
  extraStyles,
}: Props): JSX.Element => {
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  return (
    <Container style={extraStyles}>
      <StyledCalendar
        value={selectedDate}
        onChange={(value) =>
          onDateChange(value ? new Date(value?.toString()) : null)
        }
        locale={globalAppLanguage}
        navigationLabel={({ date }) => formatMonth(date, globalAppLanguage)}
        next2Label={null}
        prev2Label={null}
        showFixedNumberOfWeeks
        minDate={minDate}
      />
    </Container>
  );
};

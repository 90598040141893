import { Modal, Button } from 'components/ui';
import { useText } from 'hooks';
import * as constants from 'utils/constants';
import { ChatWrapper, Container, Text, TextContent, Title } from './styled';

type Props = {
  errorMessage: string;
  visible: boolean;
  onClose: () => void;
};

const Chatlink = ({
  children,
}: {
  children: JSX.Element | string;
}): JSX.Element => {
  const openIntercomMessanger = () => {
    // TODO :: This should open intercom after it is added to the code base
    window.location.href = 'mailto:help@fyld.ai';
  };

  return (
    <ChatWrapper role='button' tabIndex={0} onClick={openIntercomMessanger}>
      {children}
    </ChatWrapper>
  );
};

export const ErrorModal = ({
  errorMessage,
  visible,
  onClose,
}: Props): JSX.Element => {
  const getText = useText();
  return (
    <Modal.Small isOpen={visible} onClose={onClose}>
      <Container>
        <Title>{errorMessage}</Title>
        <TextContent>
          <Text>{getText('error_ensure_email')}</Text>
          <Text>
            {getText('error_contact_us_first')}{' '}
            <Chatlink>{getText('error_contact_us_second')}</Chatlink>{' '}
            {getText('error_contact_us_third')}{' '}
            <a href='mailto:help@fyld.ai'>help@fyld.ai</a>
          </Text>
        </TextContent>
        <Button.Primary
          text={getText('retry')}
          onClick={onClose}
          extraStyles={{ maxWidth: '10em', height: '2.5em' }}
          event={constants.EVENT_CLICK_ERROR_MODAL_CLOSE}
        />
      </Container>
    </Modal.Small>
  );
};

import { PlusIcon } from 'assets/icons';
import { Button, Input } from 'components/ui';
import { useLocales, useText } from 'hooks';
import { useCustomLocaleText } from 'hooks/useText';
import RootState from 'model/State/RootState';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewHazard,
  editExistingHazard,
  resetError,
} from 'store/actions/settings/hazards';
import { selectGlobalLanguageSetting } from 'store/selectors';
import {
  selectHazardError,
  selectIsLoadingHazardChange,
} from 'store/selectors/settings/hazards';
import {
  ActionButtonContainer,
  ErrorMessage,
  FormContainer,
  H2,
  InputContainer,
} from '../styled';
import { Hazard } from 'model/Assessment/Hazard';
import {
  getLocalizedValue,
  hasItemPropertyChanged,
  isMissingTitles,
} from 'helpers/locales';
import { TITLE_PROPERTY_NAME } from '../../../constants';

type Props = {
  initialHazard: Hazard | null;
  resetInitialHazard: () => void;
};
export const AddEditHazardForm = ({
  initialHazard,
  resetInitialHazard,
}: Props): JSX.Element => {
  const { availableLocales } = useLocales();
  const getTitlesForHazard = useCallback(
    (hazard: Hazard | null) =>
      hazard
        ? availableLocales.reduce(
            (titles, locale) => ({
              ...titles,
              [locale]:
                getLocalizedValue(hazard, TITLE_PROPERTY_NAME, locale) ?? '',
            }),
            {},
          )
        : {},
    [availableLocales],
  );
  const initialTitlesByLanguage = getTitlesForHazard(initialHazard);
  const isUpdate = !!initialHazard;

  const getText = useText();
  const getCustomText = useCustomLocaleText();
  const dispatch = useDispatch();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const isLoadingHazardChange = useSelector(selectIsLoadingHazardChange);
  const error = useSelector(selectHazardError);
  const [editedHazard, setEditedHazard] = useState<Hazard | null>(
    initialHazard,
  );
  const [titlesByLanguage, setTitlesByLanguage] = useState<{
    [key: string]: string;
  }>(initialTitlesByLanguage);

  const isUnchangedTitles =
    isUpdate &&
    editedHazard &&
    !hasItemPropertyChanged(
      editedHazard,
      titlesByLanguage,
      TITLE_PROPERTY_NAME,
      availableLocales,
    );

  useEffect(() => {
    dispatch(resetError());
  }, [dispatch]);

  useEffect(() => {
    setEditedHazard(initialHazard);
    setTitlesByLanguage(getTitlesForHazard(initialHazard));
  }, [initialHazard, getTitlesForHazard]);

  useEffect(() => {
    if (!isLoadingHazardChange) {
      resetForm();
    }
  }, [isLoadingHazardChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const setValueByLocale = (locale: string, value: string) => {
    setTitlesByLanguage((prevState) => ({ ...prevState, [locale]: value }));
  };

  const resetForm = () => {
    setTitlesByLanguage({});
    setEditedHazard(null);
    if (isUpdate) {
      resetInitialHazard();
    }
  };

  const addOrEditHazard = () => {
    dispatch(resetError());
    if (isUpdate) {
      dispatch(
        editExistingHazard(selectedWorkspace?.uuid, {
          ...editedHazard,
          ...titlesByLanguage,
        }),
      );
    } else {
      dispatch(addNewHazard(selectedWorkspace?.uuid, titlesByLanguage));
    }
  };

  const isAddEditDisabled =
    isLoadingHazardChange ||
    isMissingTitles(titlesByLanguage, availableLocales) ||
    isUnchangedTitles;

  return (
    <FormContainer>
      <H2>{getText('workspace_settings_add_edit_hazard')}</H2>
      <InputContainer $swapOrder={globalAppLanguage !== availableLocales[0]}>
        {availableLocales.map((locale) => (
          <Input.Text
            key={locale}
            id={`assessment-${locale}-input`}
            value={titlesByLanguage[locale] ?? ''}
            label={getCustomText('workspace_settings_hazard_title', locale)}
            onChange={(e) => setValueByLocale(locale, e.target.value)}
            disabled={isLoadingHazardChange}
            maxLength={300}
          />
        ))}
      </InputContainer>
      <ActionButtonContainer>
        <Button.Primary
          text={getText(
            isUpdate ? 'workspace_settings_update' : 'workspace_settings_add',
          )}
          IconComponent={isUpdate ? null : PlusIcon}
          onClick={addOrEditHazard}
          disabled={isAddEditDisabled}
          loading={isLoadingHazardChange}
          width='200px'
          extraStyles={{ margin: 0 }}
        />
        {Object.values(titlesByLanguage).some((v) => v !== '') &&
          !isLoadingHazardChange && (
            <Button.Confirm
              onClick={resetForm}
              text={getText('cancel')}
              width='130px'
              extraStyles={{ margin: '0 20px' }}
            />
          )}
      </ActionButtonContainer>
      {error && (
        <ErrorMessage>
          {getText('workspace_settings_error_single', {
            type: getLocalizedValue(
              error,
              TITLE_PROPERTY_NAME,
              globalAppLanguage,
            ),
          })}
        </ErrorMessage>
      )}
    </FormContainer>
  );
};

import styled from 'styled-components';
import theme from 'theme';
import { Warning, DownloadNoBackground, Cross, Tick, Eye } from 'assets/icons';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';

const { colors, fonts, fontSizes, media, mixins } = theme;

// Styled Components
export const DataContainer = styled.div`
  padding: 40px 80px;
  background-color: ${colors.offWhite};
  min-height: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DataRow = styled.div`
  height: 650px;
  display: flex;
  background: ${colors.white};
  padding: 32px;
  ${media.lg`
    padding: 16px;
  `};
`;

export const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 40px !important;
`;

export const FormIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ViewButton = styled(({ isText, ...rest }) => <Button {...rest} />)`
  &.MuiButtonBase-root {
    display: flex;
    cursor: pointer;
    width: fit-content;
    text-decoration: underline;
    text-transform: none;
    border-radius: 0;
    min-width: 0;
    color: ${colors.black};
    font-family: ${fonts.default};
    ${({ isText }) => !isText && `width: 40px; height: 40px;`};
    &:hover {
      ${({ isText }) =>
        isText
          ? `background-color: transparent; text-decoration: underline;`
          : `background-color: ${colors.lightGrey}`};
    }
    ${media.xxl`
      max-width: 100px;
      text-wrap: wrap;
    `};
    ${media.xl`
      max-width: 55px;
    `};
  }
`;

export const EyeIcon = styled(Eye)`
  height: 20px;
  width: auto;
`;

export const StyledDataGrid = styled(({ isEmpty, width, columns, ...rest }) => (
  <DataGrid columns={columns} {...rest} />
))`
  &.MuiDataGrid-root {
    border: 0;
    .MuiDataGrid-columnHeaders {
      border-bottom: 2px solid ${colors.darkGrey};
      color: ${colors.darkGrey};
      font-family: ${fonts.bold};
      width: 100%;
      > div {
        width: 100%;
      }
    }
    .MuiDataGrid-columnHeader {
      min-width: ${({ width }) => width} !important;
      max-width: ${({ width }) => width} !important;
      .MuiDataGrid-columnSeparator {
        visibility: visible;
      }
      &:focus {
        outline: 0;
      }
    }
    .MuiDataGrid-columnHeaderTitleContainer {
      white-space: unset;
    }
    .MuiDataGrid-columnHeaderTitle {
      white-space: unset;
      word-break: break-word;
      line-height: normal;
    }
    .MuiDataGrid-row {
      border-bottom: 1px solid ${colors.grey};
      width: 100%;
      > .MuiDataGrid-cell {
        white-space: nowrap;
      }
    }
    .MuiDataGrid-cell {
      min-width: ${({ width }) => width} !important;
      max-width: ${({ width }) => width} !important;
      color: ${colors.black};
      font-family: ${fonts.default};
      border: 0;
      ${mixins.flexCenter};
      &:focus,
      &:focus-within,
      &:focus-visible {
        outline: 0;
      }
      > span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .MuiDataGrid-withBorder {
      border: 0;
    }
    .MuiDataGrid-footerContainer {
      display: ${({ isEmpty }) => (isEmpty ? 'none' : 'block')};
    }
    .MuiDataGrid-virtualScrollerRenderZone {
      width: 100%;
    }
    .MuiDataGrid-scrollbar {
      overflow-x: hidden;
      overflow-y: overlay;
      background-color: ${colors.white};

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 12px;
      }
      ::-webkit-scrollbar-thumb {
        min-height: 80px;
        background-color: ${colors.grey};
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
    .last-column .MuiDataGrid-iconSeparator {
      display: none;
    }
    .MuiDataGrid-footerContainer {
      display: none;
    }
    .MuiDataGrid-filler {
      display: none;
    }
  }
`;

export const WarningIcon = styled(({ ...props }) => <Warning {...props} />)`
  height: 20px;
  width: 20px;
  padding-right: 5px;
  .fill-selector {
    fill: ${colors.red};
  }
`;

export const PointsSum = styled.span<{ $isRed?: boolean }>`
  ${({ $isRed }) =>
    $isRed &&
    `
    color: ${colors.red};
    font-family: ${fonts.bold};
  `}
`;

export const DownloadIcon = styled(DownloadNoBackground)`
  height: 20px;
  width: 20px;
  .fill-selector {
    fill: ${colors.black};
  }
`;

export const CommentsCross = styled(Cross)`
  height: 20px;
  width: 20px;
  margin-left: 10px;
  .fill-selector {
    fill: ${colors.red};
  }
`;

export const CommentsTick = styled(Tick)`
  height: 20px;
  width: 20px;
  margin-left: 10px;
  .fill-selector {
    fill: ${colors.green};
  }
`;

import { TableContainer } from '@mui/material';
import { Tick, RedCross } from 'assets/icons';
import StyledComponentProps from 'model/StyledComponentProps';
import styled from 'styled-components';
import theme from 'theme';
import { Type } from './constants';

const { colors, fonts, fontSizes, mixins } = theme;

export const DataContainer = styled.div`
  padding: 40px 80px;
  background-color: ${colors.offWhite};
  min-height: 100%;
  box-sizing: border-box;
`;

export const DataRow = styled.div`
  height: 50%;
  display: flex;
  background: ${colors.white};
  padding: 32px;
`;

export const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxl};
  margin-bottom: 40px !important;
`;

export const StyledTableContainer = styled(({ ...rest }) => (
  <TableContainer {...rest} />
))`
  &.MuiTableContainer-root {
    box-shadow: none;
    .MuiTableHead-root .MuiTableCell-root {
      border-bottom: 2px solid ${colors.darkGrey};
      font-family: ${fonts.bold};
      color: ${colors.darkGrey};
      .MuiTableSortLabel-root {
        .MuiTableSortLabel-icon {
          opacity: 1;
        }
        &.Mui-active,
        &.Mui-active .MuiTableSortLabel-icon {
          color: ${colors.blue};
        }
      }
    }
    .MuiTableBody-root .MuiTableCell-root {
      border-color: ${colors.darkGrey};
    }
  }
`;

export const ColumnButton = styled.span<StyledComponentProps>`
  text-decoration: underline;
  color: ${({ isRed }) => (isRed ? colors.red : 'inherit')};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.s};
  padding-right: 35px;
  cursor: pointer;
  margin-left: 10px;
`;

export const StopTimerButton = styled.span`
  text-decoration: underline;
  color: ${colors.black};
  font-size: ${fontSizes.s};
  margin-left: 8px;
  cursor: pointer;
`;

export const CurrentHourContainer = styled.div`
  display: flex;
  svg {
    height: 18px;
  }
`;

export const FRAButtons = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
`;

export const FraIconContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 20px;
  margin-right: 10px;
`;

export const FraWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FraHour = styled.span<StyledComponentProps>`
  color: ${({ isRed }) => (isRed ? colors.red : 'inherit')};
`;

export const StyledGreyTick = styled(Tick)`
  width: 15px;
  height: 15px;
`;

export const StyledRedCross = styled(RedCross)`
  width: 12.5px;
  height: 12.5px;
`;

export const EmptyTableText = styled.div`
  background-color: ${colors.white};
  color: ${colors.grey};
  font-family: ${fonts.default};
  padding: 25px;
  ${mixins.flexFullCenter};
`;

const ColourByType = {
  [Type.VERY_HIGH_RISK]: 'red',
  [Type.HIGH_RISK]: 'lightOrange',
  [Type.MEDIUM_RISK]: 'yellow',
  [Type.LOW_RISK]: 'green',
};
export const Colorable = styled.div<{ variant: Type | undefined }>`
  ${({ variant }) => variant && `color: ${colors[ColourByType[variant]]}`};
`;

export const TotalWorkedHours = styled(Colorable)<StyledComponentProps>`
  height: 22px;
  svg {
    height: 18px;
    margin-right: 7px;
    margin-left: -5px;
    margin-bottom: -2px;
  }
`;

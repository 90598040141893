import axios from 'axios';
import { getFileNameFromResponse } from 'helpers/files';
import { downloadBlob } from 'helpers/files';
import { UploadStatus } from 'model/enum/UploadStatus';
import { mapToDocument } from './helpers';
import { Document } from 'model/Media/Document';

export const getAll = async (jobId: number): Promise<Document[]> => {
  const { data } = await axios.get(`/jobs/${jobId}/document/`);
  return data?.results.map(mapToDocument);
};

export const create = async (
  extensionType: string,
  fileName: string,
  jobId: string = '',
): Promise<Document> => {
  const { data } = await axios.post(`/jobs/${jobId}/document/`, {
    extension_type: extensionType,
    original_file_name: fileName,
  });
  return mapToDocument(data);
};

export const setDocumentStatus = async (
  documentId: number,
  status: UploadStatus,
  fileSize: number,
): Promise<Document> => {
  const { data } = await axios.put(`/documents/${documentId}/`, {
    status,
    file_size: fileSize,
  });
  return mapToDocument(data);
};

export const downloadDocument = async (
  fileId: string,
  fileName?: string,
): Promise<string> => {
  const response = await axios.get(`/download/${fileId}/`, {
    responseType: 'blob',
  });
  const downloadFileName =
    fileName ??
    getFileNameFromResponse(response.request.responseURL, fileId, 'pdf');
  downloadBlob(response.data, downloadFileName);
  return downloadFileName;
};

import { useJobs, useText } from 'hooks';
import { Modal as StyledModal } from 'components/ui';
import { SearchFilters } from './SearchFilters';
import SearchMembers from './SearchMembers';
import { CreateJob } from './CreateJob';
import ChangeLanguage from './ChangeLanguage';
import CommandCentreFilters from './CommandCentreFilters';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiltersModal } from '../../../../ui/Filter/FiltersModal';
import { ModalType } from 'model/enum/ModalType';
import { getParentModalToMemberSearch } from 'helpers/modal';
import ManageTeamDetailsModal from './ManageTeamDetailsModal';
import HavsCommentModal from '../Insights/Compliance/Havs/HavsCommentModal';
import { ManageTeamsModal } from './ManageTeamsModal';
import * as Analytics from 'utils/analytics';

// Modal
const Modal = () => {
  const { modal } = useJobs();
  const navigate = useNavigate();
  const location = useLocation();
  const getText = useText();

  const handleLeaveModal = () => {
    navigate(location.pathname);
    if (location.state?.event) {
      Analytics.trackEvent(location.state.event, {
        ...location.state?.eventProperties,
      });
    }
  };

  const formatContent = () => {
    switch (modal) {
      case ModalType.SEARCH_FILTERS:
        return {
          title: getText('modal_title_filter_jobs'),
          body: <SearchFilters />,
          backPath: null,
        };
      case ModalType.COMMAND_CENTRE_FILTERS:
        return {
          title: getText('command_centre_filter'),
          body: <CommandCentreFilters />,
          backPath: null,
        };
      case ModalType.FILTERS:
        return {
          title: getText('filter_data_title'),
          body: <FiltersModal />,
          backPath: null,
        };
      case ModalType.CREATE_JOB:
        return {
          title: getText('modal_title_create_job'),
          body: <CreateJob />,
          backPath: null,
        };
      case ModalType.SEARCH_MEMBERS:
        return {
          title: getText('modal_title_team_members'),
          body: <SearchMembers />,
          onBack: () =>
            navigate(
              location.pathname +
                getParentModalToMemberSearch(
                  location?.pathname.endsWith('command-center'),
                  location?.pathname.endsWith('insights'),
                  location?.pathname.endsWith('fatigue-manager'),
                ),
              {
                state: location.state,
              },
            ),
        };
      case ModalType.CHANGE_LANGUAGE:
        return {
          title: getText('modal_title_language'),
          body: <ChangeLanguage />,
          backPath: null,
        };
      case ModalType.TEAM_DETAILS:
        return {
          title: location.state?.modalTitle,
          body: <ManageTeamDetailsModal />,
          onBack: () => {
            if (location.state.event) {
              Analytics.trackEvent(
                location.state.event,
                location.state.eventProperties,
              );
            }
            navigate(location.pathname + `?modal=${ModalType.TEAMS}`, {
              state: location.state,
            });
          },
        };
      case ModalType.HAVS_COMPLIANCE_COMMENT:
        return {
          title: getText('insights_forms_havs_log'),
          body: <HavsCommentModal />,
          backPath: null,
        };
      case ModalType.TEAMS:
        return {
          title: getText('manage_teams_modal_title'),
          body: <ManageTeamsModal />,
          onBack: location.state?.parentModal
            ? () =>
                navigate(
                  location.pathname + `?modal=${location.state?.parentModal}`,
                  {
                    state: location.state,
                  },
                )
            : undefined,
        };
      default:
        return { title: getText('modal_title_error'), body: <></> };
    }
  };
  const { title, body, onBack } = formatContent();

  if (modal === ModalType.CHANGE_LANGUAGE) {
    return (
      <StyledModal.Small
        isOpen
        title={title}
        onBack={onBack}
        onClose={handleLeaveModal}
        hasCancelButton={false}
      >
        {body}
      </StyledModal.Small>
    );
  }
  return (
    <StyledModal.Base
      isOpen
      title={title}
      onBack={onBack}
      onClose={handleLeaveModal}
      width='850px'
      isCentered
    >
      {body}
    </StyledModal.Base>
  );
};

export default Modal;

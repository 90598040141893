import React from 'react';
import { Dates } from 'helpers';
import { useText } from 'hooks';
import { JobStatusTitle } from 'model/enum/JobStatusTitle';
import {
  Container,
  DateWrapper,
  Line,
  MobileContainer,
  MobileStatus,
  Rectangle,
  StatusContainer,
  StatusTitle,
  TextContainer,
} from './styled';

type Props = {
  createdAt: string | Date;
  jobStatusTitle: JobStatusTitle;
  isMobile?: boolean;
  startedAt?: string | Date;
  completedAt?: string | Date;
};

export const JobTimeline = ({
  createdAt,
  startedAt,
  completedAt,
  isMobile = false,
  jobStatusTitle,
}: Props): JSX.Element => {
  const getText = useText();

  const statuses = [
    {
      title: getText('jobs_update_status_created'),
      date: createdAt,
      acceptableStatuses: [JobStatusTitle.CREATED, JobStatusTitle.ABANDONED],
    },
    {
      title: getText('jobs_update_status_in_progress'),
      date: startedAt,
      acceptableStatuses: [
        JobStatusTitle.IN_PROGRESS,
        JobStatusTitle.HANDOVER,
        JobStatusTitle.FOLLOW_UP,
      ],
    },
    {
      title: getText('jobs_update_status_completed'),
      date: completedAt,
      acceptableStatuses: [JobStatusTitle.COMPLETED],
    },
  ];
  const acceptableStatusIndex = statuses.findIndex((status) =>
    status.acceptableStatuses.includes(jobStatusTitle),
  );
  const currentStatus =
    acceptableStatusIndex === -1
      ? statuses[statuses.length - 1]
      : statuses[acceptableStatusIndex];

  const isLineActive = (currentDate, currentIndex) => {
    const nextIndex = statuses[currentIndex + 1];
    return (
      !!currentDate &&
      !!nextIndex &&
      !!nextIndex.date &&
      currentIndex <= acceptableStatusIndex - 1
    );
  };

  const getRectangularSize = (isActive, isAchieved) => {
    if (isActive) {
      return 15;
    }
    if (isAchieved) {
      return 10;
    }
    return 12;
  };

  if (isMobile) {
    return (
      <MobileContainer>
        <Rectangle $isMobile $isAchieved size={15} />
        <MobileStatus>
          <StatusTitle $isAchieved>{currentStatus?.title}</StatusTitle>
          <DateWrapper>
            {Dates.formatDateTime(
              currentStatus?.date,
              getText('date_today'),
              getText('date_yesterday'),
            )}
          </DateWrapper>
        </MobileStatus>
      </MobileContainer>
    );
  }

  return (
    <Container>
      {statuses.map(({ title, date }, index) => (
        <React.Fragment key={title}>
          <Rectangle
            $isAchieved={!!date && index <= acceptableStatusIndex}
            size={getRectangularSize(currentStatus?.title === title, date)}
            $isMobile={false}
          >
            <TextContainer
              $alignEnd={title === getText('jobs_update_status_completed')}
              $alignCenter={title === getText('jobs_update_status_in_progress')}
            >
              <StatusContainer
                $alignCenter={
                  title === getText('jobs_update_status_in_progress')
                }
                $alignEnd={title === getText('jobs_update_status_completed')}
              >
                <StatusTitle
                  $isAchieved={!!date && index <= acceptableStatusIndex}
                >
                  {title}
                </StatusTitle>
                {date && index <= acceptableStatusIndex && (
                  <DateWrapper>
                    {Dates.formatDateTime(
                      date,
                      getText('date_today'),
                      getText('date_yesterday'),
                    )}
                  </DateWrapper>
                )}
              </StatusContainer>
            </TextContainer>
          </Rectangle>
          {index !== statuses.length - 1 && (
            <Line $isActive={isLineActive(date, index)} />
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

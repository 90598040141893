import { Storage } from 'aws-amplify';
import { insightsComplianceFields } from 'utils/constants';
import { SortOrder } from 'model/enum/SortOrder';
import { startOfDay, sub } from 'date-fns';
import { isSameDay } from './dates';
import { getLocalizedCamelCaseValue } from './locales';

export function generateInsightsData(
  results,
  startDate,
  dayRange,
  isOutputPerDay,
  excludeWeekends,
) {
  // Create structure for data.
  const holderResults = [];
  for (let i = 0; i < dayRange + 1; i += 1) {
    const date = sub(startOfDay(new Date(startDate)), { days: dayRange - i });
    if (!excludeWeekends || (excludeWeekends && date.getDay() % 6 !== 0)) {
      holderResults.push({
        created_date: date,
        sum_units: 0.0,
        job_details: [],
      });
    }
  }

  // Format results before merge
  const formattedResults = results.map((item) => {
    const result = {};
    result.created_date = new Date(item.created_date);
    result.sum_units = isOutputPerDay
      ? item.meters_completed
      : item.jobs_completed;
    result.job_details = item.job_details;

    return result;
  });

  // Merge formattedResults and holderResults
  return holderResults.map(
    (item) =>
      formattedResults.find((result) =>
        isSameDay(result.created_date, item.created_date),
      ) || item,
  );
}

export function generateComplianceInsightData(response) {
  if (!response?.count || !response?.results?.length) {
    return { count: 0, columns: response?.columns || {}, results: [] };
  }

  function getPercentageFromFloat(input) {
    const twoDecimal = Math.round(Number.parseFloat(input) * 100);

    return `${twoDecimal}%`;
  }

  return {
    ...response,
    results: response.results.map((result) => ({
      ...result,
      [insightsComplianceFields.VRA_AND_SIGN_ON_PERCENTAGE]:
        getPercentageFromFloat(
          result[insightsComplianceFields.VRA_AND_SIGN_ON_PERCENTAGE],
        ),
      [insightsComplianceFields.WRAPUP_PERCENTAGE]: getPercentageFromFloat(
        result[insightsComplianceFields.WRAPUP_PERCENTAGE],
      ),
    })),
  };
}

export function generateWeatherData(weather) {
  if (weather) {
    return {
      ...weather.main,
      weather: weather.weather.find((result) => result),
    };
  }
}

export async function getSignedUrls(documentList) {
  if (!documentList?.length) {
    return [];
  }
  const signedUrlDocumentList = await Promise.all(
    documentList.map(async (document) => ({
      ...document,
      signedUrl: await Storage.get(document.path),
    })),
  );
  return signedUrlDocumentList;
}

export function getWorkOrderSubtype(subtype, activeJob, globalAppLanguage) {
  if (subtype) {
    return getLocalizedCamelCaseValue(subtype, 'title', globalAppLanguage);
  }

  if (activeJob) {
    return activeJob.subtype
      ? activeJob.subtype?.[`title_${globalAppLanguage}`] ||
          activeJob.subtype?.title
      : activeJob.type?.[`title_${globalAppLanguage}`] || activeJob.type?.title;
  }

  return '';
}

export function getSortString(field, direction) {
  let orderBy = null;

  // to sort in ascending order the back-end expects 'order_by=field_name'
  // for descending, dash in front: 'order_by=-field_name'
  if (field) {
    orderBy =
      direction && direction === SortOrder.DESCENDING ? `-${field}` : field;
  }

  return orderBy;
}

export const handleError = (err, defaultErrorMessage) =>
  err?.response?.data?.detail || defaultErrorMessage;

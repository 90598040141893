import { Depot } from 'model/Job/Depot';
import { Job } from 'model/Job/Job';

export const mapToJob = (backendJob: any): Job => ({
  id: backendJob.id,
  jobNumber: backendJob.job_number,
  postcode: backendJob.postcode ?? '',
  address: backendJob.address,
  latitude: backendJob.latitude ?? '',
  longitude: backendJob.longitude ?? '',
  status: backendJob.status,
  hasMajorHazard: backendJob?.has_major_hazard ?? false,
  pendingJobBlockerCount: backendJob?.pending_job_blocker_count ?? 0,
  mostRecentJobBlockerCreatedAt: backendJob?.most_recent_job_blocker_created_at
    ? new Date(backendJob.most_recent_job_blocker_created_at)
    : null,
});

export const mapToDepot = (backendDepot: any): Depot => ({
  id: backendDepot.id,
  title: backendDepot.title ?? '',
});

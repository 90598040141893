import { useText } from 'hooks';
import { Container, ReasonTitle } from './styled';
import { FeedbackReasonItem } from './FeedbackReasonItem';
import { EVENT_WRAP_UP_INSIGHTS_TELL_US_MORE, Reason } from './constants';
import { Button } from 'components/ui';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsSendingFeedbackReason,
  sendWrapUpFeedbackReason,
} from 'store/slices/evidences';
import * as Analytics from 'utils/analytics';
import { Evidence } from 'model/Evidence/Evidence';
import { selectCurrentUserId } from 'store/selectors';

type Props = {
  evidence: Evidence;
  reasons: Reason[];
  setReasons: (reasons: Reason[]) => void;
};

export const FeedbackReason = ({
  evidence,
  reasons,
  setReasons,
}: Props): JSX.Element | null => {
  const getText = useText();
  const dispatch = useDispatch();
  const isSubmitting = useSelector(selectIsSendingFeedbackReason);
  const currentUserId = useSelector(selectCurrentUserId);

  const reasonOptions: Reason[] = [
    { id: 1, value: getText('wrap_up_summaries_feedback_reason_option_wrong') },
    {
      id: 2,
      value: getText('wrap_up_summaries_feedback_reason_option_add_value'),
    },
    {
      id: 3,
      value: getText('wrap_up_summaries_feedback_reason_option_key_info'),
    },
    {
      id: 4,
      value: getText('wrap_up_summaries_feedback_reason_option_missing'),
    },
  ];

  const isSelected = (id: number): boolean =>
    !!reasons.find((item) => item.id === id);

  const handleReasonChange = (reason: Reason) => {
    if (isSelected(reason.id)) {
      setReasons(reasons.filter((item) => item.id !== reason.id));
    } else {
      setReasons([...reasons, reason]);
    }
  };

  const submitReason = () => {
    const videoFeedbackReason = reasons.map((item) => item.value).join(', ');

    dispatch(
      sendWrapUpFeedbackReason({
        evidenceId: evidence.id.toString(),
        videoFeedbackReason,
      }),
    );

    Analytics.trackEvent(EVENT_WRAP_UP_INSIGHTS_TELL_US_MORE, {
      evidence_id: evidence.id,
      evidence_video_id: evidence?.videos?.[0]?.fileId,
      user_id: currentUserId,
      timestamp: new Date(),
      job_id: evidence.jobId,
      tell_us_more: videoFeedbackReason,
    });
  };

  return (
    <Container>
      <ReasonTitle>
        {getText('wrap_up_summaries_feedback_reason_title')}
      </ReasonTitle>
      <div>
        {reasonOptions.map((reason) => (
          <FeedbackReasonItem
            key={`reason-${reason.id}`}
            reason={reason}
            onClick={() => handleReasonChange(reason)}
            selected={isSelected(reason.id)}
          />
        ))}
      </div>
      <Button.Primary
        text={getText('submit')}
        onClick={submitReason}
        disabled={!reasons.length}
        loading={isSubmitting}
      />
    </Container>
  );
};

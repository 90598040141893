import { Tick, Warning } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins } = theme;

export const Container = styled.div`
  margin: 20px;
`;

export const SummaryContentContainer = styled.div<{ large: boolean }>`
  height: calc(${({ large }) => (large ? 75 : 67)}% - 24px);
  overflow-y: auto;
`;

export const TagContainer = styled.div<{ $isWarning: boolean }>`
  background-color: ${({ $isWarning }) =>
    $isWarning ? colors.darkRed : colors.green};
  margin: 10px 0px;
  padding: 5px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  ${mixins.flexCenter};
`;

export const Message = styled.span`
  font-size: ${fontSizes.default};
  color: ${colors.white};
  padding-left: 5px;
`;

export const WarningIcon = styled(({ isRed = false, ...props }) => (
  <Warning {...props} />
))`
  height: ${({ isRed }) => (isRed ? 18 : 30)}px;
  width: ${({ isRed }) => (isRed ? 18 : 30)}px;
  margin-right: 7px;
  .fill-selector {
    fill: ${({ isRed }) => (isRed ? colors.red : colors.white)};
  }
`;

export const CheckboxContainer = styled.div`
  margin-top: 15px;
  background-color: ${colors.grey};
  font-family: ${fonts.bold};
  padding: 13px 16px;
`;

export const ButtonWrapper = styled.div`
  ${mixins.flexBetween};
`;

export const HazardContainer = styled.div`
  padding-bottom: 25px;
`;

export const HazardTitle = styled.span`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  padding-left: 10px;
`;

export const TickIcon = styled(Tick)`
  height: 18px;
  width: 18px;
  margin-right: 7px;
  .fill-selector {
    fill: ${colors.green};
  }
`;

export const AppliedControls = styled.div`
  padding: 10px 0px 5px 0px;
  font-family: ${fonts.lightItalic};
`;

export const ControlName = styled.div<{ $isLight?: boolean }>`
  font-family: ${({ $isLight }) => $isLight && fonts.light};
  padding-left: 10px;
`;

export const BulletPoint = styled.div`
  width: 7px;
  height: 8px;
  background-color: ${colors.black};
  margin-right: 5px;
  min-width: 7px;
`;

export const RatingContainer = styled.div`
  padding: 15px 0px;
`;

export const LightText = styled.div`
  font-family: ${fonts.light};
`;

export const BoldText = styled.span`
  font-family: ${fonts.bold};
`;

export const HazardTitleContainer = styled.div`
  ${mixins.flexCenter};
`;

export const AppliedControlContainer = styled.div`
  padding: 0px 15px;
`;

export const CenteredContainer = styled.div`
  ${mixins.flexCenter};
  padding-bottom: 5px;
`;

export const IdentifiedHazardsWrapper = styled.div`
  height: calc(100% - 300px);
  overflow-y: auto;
`;

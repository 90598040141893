import { useState } from 'react';
import {
  CrossIcon,
  CrossWrapper,
  InputContainer,
  StyledTextField,
} from './styled';
type Props = {
  value?: string;
  onChange?: (value: string) => void;
};

export const ClearableInput = ({
  value,
  onChange = () => {},
}: Props): JSX.Element => {
  const [isFocused, setFocused] = useState<boolean>(false);

  return (
    <InputContainer
      onFocus={() => setFocused(true)}
      onBlur={() => setTimeout(() => setFocused(false), 200)}
    >
      <StyledTextField
        value={value}
        onChange={(e) => onChange(e.target.value)}
        InputProps={{
          endAdornment: isFocused && value !== '' && (
            <CrossWrapper onClick={() => onChange('')}>
              <CrossIcon />
            </CrossWrapper>
          ),
        }}
      />
    </InputContainer>
  );
};

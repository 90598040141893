import { Fatigue } from 'api';
import { put, takeLatest } from 'redux-saga/effects';
import {
  fetchFatigueHistoric,
  fetchFatigueHistoricError,
  fetchFatigueHistoricSuccess,
  fetchFatigueLive,
  fetchFatigueLiveError,
  fetchFatigueLiveSuccess,
} from 'store/slices/fatigue';
import { setGlobalError } from 'store/slices/notifications';
import * as Logger from 'utils/logger';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  FatigueHistoricPayload,
  FatigueLivePayload,
} from 'model/State/Fatigue';

function* fetchLiveData(action: PayloadAction<FatigueLivePayload>) {
  const {
    depotId,
    search,
    selectedWorkspaceId,
    userIds,
    sort,
    page,
    pageSize,
  } = action.payload;

  try {
    const data = yield Fatigue.getLiveData(
      depotId,
      search,
      selectedWorkspaceId,
      userIds,
      sort,
      page,
      pageSize,
    );

    yield put(fetchFatigueLiveSuccess({ ...data, page }));
  } catch (err) {
    Logger.error(err);
    yield put(fetchFatigueLiveError());
    yield put(setGlobalError(err));
  }
}

function* fetchHistoricData(action: PayloadAction<FatigueHistoricPayload>) {
  const {
    depotId,
    search,
    startDate,
    endDate,
    minimumDuration,
    selectedWorkspaceId,
    userIds,
    sort,
    page,
    pageSize,
  } = action.payload;

  try {
    const data = yield Fatigue.getHistoricData(
      depotId,
      search,
      startDate,
      endDate,
      minimumDuration,
      selectedWorkspaceId,
      userIds,
      sort,
      page,
      pageSize,
    );

    yield put(fetchFatigueHistoricSuccess({ ...data, page }));
  } catch (err) {
    Logger.error(err);
    yield put(fetchFatigueHistoricError());
    yield put(setGlobalError(err));
  }
}

export default function* fatigueWatcher() {
  yield takeLatest(fetchFatigueLive, fetchLiveData);
  yield takeLatest(fetchFatigueHistoric, fetchHistoricData);
}

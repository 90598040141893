import { KeyboardEvent, KeyboardEventHandler, useState } from 'react';
import {
  CrossIcon,
  CrossWrapper,
  InputContainer,
  SearchButton,
  SearchIcon,
  SearchInput,
} from '../styled';

type Props = {
  onSubmit: (value: string) => void;
  resetSearch: () => void;
  initialValue?: string;
  placeholder: string;
  inputWidth?: string;
};

export const SearchBar = ({
  initialValue = '',
  placeholder,
  onSubmit,
  resetSearch,
  inputWidth,
}: Props): JSX.Element => {
  const [search, setSearch] = useState<string>(initialValue);
  const [isSearchDisabled, setIsSearchDisabled] = useState<boolean>(
    initialValue.length === 1,
  );

  const handleChange = (newSearch) => {
    setSearch(newSearch);
    setIsSearchDisabled(newSearch.length === 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (search.length !== 1) {
      onSubmit(search);
    }
  };

  const handleKeyUp: KeyboardEventHandler = (event: KeyboardEvent<Element>) => {
    if (event.key === 'Enter' && handleSubmit) {
      handleSubmit(event);
    }
  };

  return (
    <InputContainer>
      <SearchInput
        placeholder={placeholder}
        value={search}
        onChange={(e) => handleChange(e.target.value)}
        width={inputWidth}
        onKeyUp={handleKeyUp}
      />
      <CrossWrapper className='cross-wrapper' $isVisible={!!search}>
        <CrossIcon
          onClick={() => {
            setSearch('');
            setIsSearchDisabled(false);
            resetSearch();
          }}
        />
      </CrossWrapper>
      <SearchButton
        type='submit'
        disabled={isSearchDisabled}
        onClick={handleSubmit}
      >
        <SearchIcon disabled={isSearchDisabled} />
      </SearchButton>
    </InputContainer>
  );
};

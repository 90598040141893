import { Display, Loader } from 'components/ui';
import { useLocalStorage, useTeams, useText } from 'hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import FatigueHistoricDataTab from './components/FatigueHistoricDataTab';
import FatigueManagerLiveData from './components/FatigueManagerLiveData';
import Tab from 'model/Tab/Tab';
import TabPanel from 'model/Tab/TabPanel';
import RootState from 'model/State/RootState';
import { FatigueManagerTabKey } from 'model/enum/FatigueManagerTabKey';
import { FilterBase } from 'components/ui/Filter/FilterBase';
import { fetchFormOptions } from 'store/actions/form';
import { selectFormOptions } from 'store/selectors';
import { FyldScreen } from 'model/enum/FyldScreen';
import { sevenDaysAgo, todayString } from 'helpers/dates';
import { selectFatigueTab, setFatigueTab } from 'store/slices/fatigue';
import Filters from 'model/Filters';

const { colors, fonts, fontSizes, mixins, media } = theme;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.white};
  ${mixins.flexColumn};
  ${media.sm`
    height: 100vh;
    padding: 30px;
 `}
  overflow-y: auto;
`;

const FatigueWrapper = styled.div`
  padding: 40px 0 0;
  height: 100%;
  ${media.sm`
    padding: 30px;
 `}
  overflow-y: auto;
  box-sizing: border-box;
`;

const Header = styled.div`
  height: 10%;
  display: flex;
  justify-content: space-between;
  padding: 0 80px 10px 80px;
  box-sizing: border-box;
  ${media.lg`
    height: 18%;
  `}
`;

const H1 = styled.h1`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  margin-bottom: 8px !important;
  width: 25%;
  ${media.lg`
    width: 40%;
  `}
  ${media.md`
    width: 20%;
    font-size: ${fontSizes.xxl};
  `}
`;

const FatigueManagerDesktop = () => {
  const dispatch = useDispatch();
  const getText = useText();
  const { depot } = useSelector((state: RootState) => state.session);
  const blankFilters = {
    depot,
    startDate: null,
    endDate: null,
    fraType: null,
    members: [],
    search: '',
    weekendIncluded: false,
  };
  const formOptions = useSelector(selectFormOptions);
  const [fatigueManagerFilters, setFatigueManagerFilters] =
    useLocalStorage<Filters>('fatigueManagerFilters', blankFilters);
  const activeFatigueTab = useSelector(selectFatigueTab);
  const { selectedTeam, setSelectedTeamForCurrentUser } = useTeams();

  const startDate = fatigueManagerFilters?.startDate || sevenDaysAgo;
  const endDate = fatigueManagerFilters?.endDate || todayString;

  const filters = { ...fatigueManagerFilters, startDate, endDate } as Filters;

  const tabs: Tab[] = [
    {
      label: getText('fatigue_manager_live_view_title'),
      value: FatigueManagerTabKey.LIVE_VIEW,
      isShown: true,
    },
    {
      label: getText('fatigue_manager_historic_data_title'),
      value: FatigueManagerTabKey.HISTORIC_VIEW,
      isShown: true,
    },
  ];

  const tabPanels: TabPanel[] = [
    {
      value: FatigueManagerTabKey.LIVE_VIEW,
      children: (
        <>
          {selectedTeam !== undefined && !!filters?.depot?.id ? (
            <FatigueManagerLiveData
              depotId={filters.depot.id}
              filters={filters}
              selectedTeam={selectedTeam}
            />
          ) : (
            <Loader />
          )}
        </>
      ),
    },
    {
      value: FatigueManagerTabKey.HISTORIC_VIEW,
      children: (
        <>
          {selectedTeam !== undefined && filters?.depot?.id ? (
            <FatigueHistoricDataTab
              depotId={filters.depot.id}
              filters={filters}
              selectedTeam={selectedTeam}
            />
          ) : (
            <Loader />
          )}
        </>
      ),
    },
  ];

  const onTabChange = (key) => {
    dispatch(setFatigueTab(key));
  };

  useEffect(() => {
    if (filters?.depot && filters?.depot?.id && selectedTeam !== undefined) {
      Analytics.trackEvent(
        activeFatigueTab === FatigueManagerTabKey.LIVE_VIEW
          ? constants.EVENT_FATIGUE_LIVE_VIEW_LOADED
          : constants.EVENT_FATIGUE_HISTORIC_VIEW_LOADED,
        {
          depotId: filters?.depot.id,
          ...(selectedTeam && {
            team: selectedTeam,
            teamId: selectedTeam?.id,
          }),
        },
      );
    }
  }, [filters?.depot, selectedTeam, activeFatigueTab]);

  useEffect(() => {
    if (!formOptions) {
      dispatch(fetchFormOptions());
    }
    dispatch(setFatigueTab(tabs[0]?.value as FatigueManagerTabKey));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <FatigueWrapper>
        <Header>
          <H1 id='title'>{getText('fatigue_manager')}</H1>
          <FilterBase
            screen={FyldScreen.FATIGUE_MANAGER}
            filters={filters}
            setFilters={setFatigueManagerFilters}
            hasSearchInput
            hasDateRangeFilter={
              activeFatigueTab === FatigueManagerTabKey.HISTORIC_VIEW
            }
            hasShiftDurationFilter={
              activeFatigueTab === FatigueManagerTabKey.HISTORIC_VIEW
            }
            hasUserFilter
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeamForCurrentUser}
          />
        </Header>
        {activeFatigueTab &&
          (filters?.depot ? (
            <Display.TabView
              value={activeFatigueTab}
              onChange={(e, newValue) => onTabChange(newValue)}
              tabs={tabs}
              tabPanels={tabPanels}
            />
          ) : (
            <Display.MissingDepot />
          ))}
      </FatigueWrapper>
    </Wrapper>
  );
};

export default FatigueManagerDesktop;

import WorkspaceAssessmentItem from 'model/State/WorkspaceAssessmentItem';
import {
  ADD_WORKSPACE_ASSESSMENT,
  ADD_WORKSPACE_ASSESSMENT_ERROR,
  ADD_WORKSPACE_ASSESSMENT_SUCCESS,
  EDIT_WORKSPACE_ASSESSMENT,
  EDIT_WORKSPACE_ASSESSMENT_ERROR,
  EDIT_WORKSPACE_ASSESSMENT_SUCCESS,
  FETCH_WORKSPACE_ASSESSMENTS,
  FETCH_WORKSPACE_ASSESSMENTS_ERROR,
  FETCH_WORKSPACE_ASSESSMENTS_SUCCESS,
  REMOVE_WORKSPACE_ASSESSMENT,
  REMOVE_WORKSPACE_ASSESSMENT_ERROR,
  REMOVE_WORKSPACE_ASSESSMENT_SUCCESS,
  RESET_WORKSPACE_ASSESSMENT_ERROR,
  TOGGLE_HAZARD_ASSESSMENT_LINKING,
} from 'store/actions/actionTypes';

const defaultState = {
  assessments: [],
  isFetchingAssessments: false,
  isLoadingAssessmentChange: false,
  error: null,
};

const workspaceAssessments = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_WORKSPACE_ASSESSMENTS:
      return {
        ...state,
        isFetchingAssessments: true,
      };
    case FETCH_WORKSPACE_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        assessments: {
          ...state.assessments,
          [action.workspaceUuid]: [...action.data],
        },
        isFetchingAssessments: false,
      };
    case FETCH_WORKSPACE_ASSESSMENTS_ERROR:
      return {
        ...state,
        isFetchingAssessments: false,
      };
    case ADD_WORKSPACE_ASSESSMENT:
    case EDIT_WORKSPACE_ASSESSMENT:
    case REMOVE_WORKSPACE_ASSESSMENT:
      return {
        ...state,
        isLoadingAssessmentChange: true,
      };
    case ADD_WORKSPACE_ASSESSMENT_SUCCESS: {
      const workspaceAssessments: WorkspaceAssessmentItem[] =
        state.assessments?.[action.workspaceUuid] || [];
      return {
        ...state,
        assessments: {
          ...state.assessments,
          [action.workspaceUuid]: [...workspaceAssessments, action.data],
        },
        isLoadingAssessmentChange: false,
      };
    }
    case EDIT_WORKSPACE_ASSESSMENT_SUCCESS: {
      const workspaceAssessments: WorkspaceAssessmentItem[] =
        state.assessments?.[action.workspaceUuid] || [];
      const updatedAssessments = workspaceAssessments.map((assessment) =>
        assessment?.id === action.data?.id ? action.data : assessment,
      );
      return {
        ...state,
        assessments: {
          ...state.assessments,
          [action.workspaceUuid]: updatedAssessments,
        },
        isLoadingAssessmentChange: false,
      };
    }
    case REMOVE_WORKSPACE_ASSESSMENT_SUCCESS: {
      const workspaceAssessments: WorkspaceAssessmentItem[] =
        state.assessments?.[action.workspaceUuid] || [];
      const updatedAssessments = workspaceAssessments.filter(
        (assessment) => assessment?.id !== action?.id,
      );
      return {
        ...state,
        assessments: {
          ...state.assessments,
          [action.workspaceUuid]: [...updatedAssessments],
        },
        isLoadingAssessmentChange: false,
      };
    }
    case ADD_WORKSPACE_ASSESSMENT_ERROR:
    case EDIT_WORKSPACE_ASSESSMENT_ERROR:
    case REMOVE_WORKSPACE_ASSESSMENT_ERROR:
      return {
        ...state,
        error: action.data,
        isLoadingAssessmentChange: false,
      };
    case RESET_WORKSPACE_ASSESSMENT_ERROR:
      return {
        ...state,
        error: null,
      };
    case TOGGLE_HAZARD_ASSESSMENT_LINKING: {
      const workspaceAssessments: WorkspaceAssessmentItem[] =
        state.assessments?.[action.workspaceUuid] || [];
      const updatedAssessments = workspaceAssessments.map((assessment) => {
        if (assessment?.id === action.assessmentId) {
          const isHazardIncluded = assessment.hazards.includes(action.hazardId);
          if (isHazardIncluded) {
            return {
              ...assessment,
              hazards: assessment.hazards.filter(
                (hazardId) => hazardId !== action.hazardId,
              ),
            };
          } else {
            return {
              ...assessment,
              hazards: [...assessment.hazards, action.hazardId],
            };
          }
        }
        return assessment;
      });
      return {
        ...state,
        assessments: {
          ...state.assessments,
          [action.workspaceUuid]: updatedAssessments,
        },
      };
    }
    default:
      return state;
  }
};

export default workspaceAssessments;

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryParams, useText } from 'hooks';
import { FyldLogo, MobileLineBreak } from '../styled';
import { tokenSignIn } from 'store/actions/session';
import RootState from 'model/State/RootState';
import {
  AppStoreLink,
  BadgeContainer,
  Bold,
  Container,
  DesktopLineBreak,
  GooglePlayLink,
  Instructions,
} from './styled';

type QueryParamProps = {
  email?: string;
  challengeToken?: string;
};

// MobileInstall
export const MobileInstall = () => {
  const dispatch = useDispatch();
  const getText = useText();
  const { error } = useSelector((state: RootState) => state.session);
  const { params }: { params: QueryParamProps } = useQueryParams();

  useEffect(() => {
    if (!error && params.email && params.challengeToken) {
      dispatch(tokenSignIn(params.email, params.challengeToken));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <FyldLogo />
      <Instructions>
        {getText('mobile_install_instruction_first')}{' '}
        <Bold>
          {getText('mobile_install_instruction_second')}
          <MobileLineBreak /> {getText('mobile_install_instruction_third')}
          <DesktopLineBreak /> {getText('mobile_install_instruction_fourth')}
        </Bold>{' '}
        {getText('mobile_install_instruction_fifth')}
        <MobileLineBreak /> {getText('mobile_install_instruction_sixth')}{' '}
        <DesktopLineBreak />
        {getText('mobile_install_instruction_seventh')}
      </Instructions>
      <BadgeContainer>
        <a href='https://play.google.com/store/apps/details?id=com.sitestream&hl=en_GB&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
          <GooglePlayLink
            alt={getText('mobile_install_google_play')}
            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
          />
        </a>
        <a href='https://apps.apple.com/gb/app/fyld/id1588103319?itsct=apps_box_badge&amp;itscg=30200'>
          <AppStoreLink
            src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1643846400&h=3faedb7c17387fd6e68c237b4c546d2b'
            alt={getText('mobile_install_app_store')}
          />
        </a>
      </BadgeContainer>
    </Container>
  );
};

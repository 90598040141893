import { Tooltip as MaterialTooltip, TooltipProps } from '@mui/material';
import { darkTooltipStyle, lightTooltipStyle } from './styled';

type Props = {
  title: string | JSX.Element;
  triggerElement: JSX.Element;
  onClose?: () => void;
  isOpen?: boolean;
  opacity?: number;
  disableFocusListener?: boolean;
  disableHoverListener?: boolean;
  disableTouchListener?: boolean;
  placement?: TooltipProps['placement'];
  height?: number;
  isLight?: boolean;
};

export const Tooltip = ({
  title,
  triggerElement,
  opacity = 1,
  onClose,
  isOpen,
  disableFocusListener = false,
  disableHoverListener = false,
  disableTouchListener = false,
  placement = 'top',
  height = 50,
  isLight = false,
}: Props): JSX.Element => (
  <MaterialTooltip
    arrow
    placement={placement}
    title={title}
    open={isOpen}
    onClose={onClose}
    disableFocusListener={disableFocusListener}
    disableHoverListener={disableHoverListener}
    disableTouchListener={disableTouchListener}
    componentsProps={{
      tooltip: {
        sx: isLight ? lightTooltipStyle : darkTooltipStyle(height, opacity),
      },
    }}
  >
    {triggerElement}
  </MaterialTooltip>
);

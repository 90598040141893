import { Button } from 'components/ui';
import { Section } from '../styled';
import { UnorderedList } from 'components/ui/Lists/UnorderedList';
import { Assessment } from 'model/Assessment';
import { Control } from 'model/Assessment/Control';
import { Hazard } from 'model/Assessment/Hazard';
import { useCommandCenter, useQueryParams, useText } from 'hooks';
import { ItemRowSelected } from './ItemRowSelected';
import { Control as ControlIcon, Hazard as HazardIcon } from 'assets/icons';
import { useSelector } from 'react-redux';
import {
  selectIsProjectEngineerAssessment,
  selectJobsView,
} from 'store/selectors';
import * as constants from 'utils/constants';
import { SignOn } from './SignOn';
import { Comments } from './Comments';
import { RequestNewVra } from './RequestNewVra';

type Props = {
  assessment: Assessment;
};

export const SeparatedHazardsAndControlsSection = ({
  assessment,
}: Props): JSX.Element => {
  const getText = useText();
  const { getPathWithQueryParams } = useQueryParams();
  const { isCommandCenter, getPathToTimeline } = useCommandCenter();
  const projectEngineerAssessment = useSelector(
    selectIsProjectEngineerAssessment(assessment?.createdBy?.id),
  );
  const view = useSelector(selectJobsView);

  return (
    <>
      <Section>
        {isCommandCenter && (
          <Button.SecondaryLink
            event={constants.EVENT_VIEW_JOB_TIMELINE}
            text={getText('assessment_details_open_timeline')}
            extraStyles={{ marginTop: '15px' }}
            to={getPathToTimeline(assessment.jobId)}
            width='inherit'
          />
        )}
        <UnorderedList
          items={assessment.hazards}
          title={getText('hazards')}
          needEmptyIcon={false}
          IconComponent={HazardIcon}
          extraItemStyle={{ marginLeft: '40px' }}
          extraHeaderStyle={{ marginTop: 0 }}
        />
        {assessment.suggestedHazards.map((item: Hazard) => (
          <ItemRowSelected item={item} />
        ))}
        {assessment.editable && !assessment.isSignedOff && (
          <Button.SecondaryLink
            event={
              projectEngineerAssessment
                ? constants.EVENT_VIEW_ADD_HAZARD
                : constants.EVENT_VIEW_SUGGEST_HAZARD
            }
            eventProperties={{
              assessmentId: assessment.id,
              jobId: assessment.jobId,
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            text={getText(
              projectEngineerAssessment
                ? 'assessment_details_edit_hazards'
                : 'assessment_details_suggest_hazard',
            )}
            extraStyles={{ marginTop: '15px' }}
            to={getPathWithQueryParams(`hazards`)}
            width='inherit'
          />
        )}
      </Section>

      <Section>
        <UnorderedList
          items={assessment.controls}
          title={getText('controls')}
          needEmptyIcon={false}
          IconComponent={ControlIcon}
          extraItemStyle={{ marginLeft: '40px' }}
          extraHeaderStyle={{ marginTop: 0 }}
        />
        {assessment.suggestedControls.map((item: Control) => (
          <ItemRowSelected item={item} />
        ))}
        {assessment.editable && !assessment.isSignedOff && (
          <Button.SecondaryLink
            event={
              projectEngineerAssessment
                ? constants.EVENT_VIEW_ADD_CONTROL
                : constants.EVENT_VIEW_SUGGEST_CONTROL
            }
            eventProperties={{
              assessmentId: assessment.id,
              jobId: assessment.jobId,
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            text={getText(
              projectEngineerAssessment
                ? 'assessment_details_edit_controls'
                : 'assessment_details_suggest_control',
            )}
            extraStyles={{ marginTop: '15px' }}
            to={getPathWithQueryParams(`controls`)}
            width='inherit'
          />
        )}
      </Section>
      <Section>
        <RequestNewVra assessment={assessment} />
      </Section>
      <Comments
        comments={assessment.additionalComments}
        title={getText('assessment_details_comments')}
      />

      <SignOn assessment={assessment} />
    </>
  );
};

import { Eye } from 'assets/icons';
import { useSelector } from 'react-redux';
import { selectWorkspaceSettings } from 'store/selectors';

import {
  ActionBox as ActionBoxLink,
  Description,
  Type,
  ActionIcon,
  Subtype,
  Icon,
  ActionBoxWrapper,
} from './styled';
import React from 'react';

type Props = {
  to: string;
  type: string | JSX.Element;
  subtype?: string | JSX.Element;
  actionIcon: JSX.Element;
  viewIcon?: JSX.Element;
  isLocked?: boolean;
  children?: React.ReactNode;
};

export const ActionBox = ({
  to,
  type,
  subtype,
  actionIcon,
  viewIcon,
  isLocked,
  children,
}: Props): JSX.Element => {
  const { view_unreviewed_vra: viewUnreviewedVra } = useSelector(
    selectWorkspaceSettings,
  );

  return (
    <ActionBoxLink to={to} $isLocked={!viewUnreviewedVra && isLocked}>
      <ActionBoxWrapper>
        <ActionIcon component={actionIcon} />
        <Description>
          <Type $hasLineHeight>{type}</Type>
          <Subtype>{subtype}</Subtype>
        </Description>
        {(!isLocked || viewUnreviewedVra) && (
          <Icon component={viewIcon ?? <Eye />} />
        )}
      </ActionBoxWrapper>
      {children}
    </ActionBoxLink>
  );
};

import { CSSProperties } from 'styled-components';
import * as Analytics from 'utils/analytics';
import { Loader } from '../../Loader';
import { Button, Description, DescriptionWrapper } from './styled';

type Props = {
  text: string;
  onClick: () => {};
  width?: string;
  disabled?: boolean;
  loading?: boolean;
  large?: boolean;
  extraStyles?: CSSProperties;
};

// Button.ClearButton
export const ClearButton = Analytics.withTrackEvent(
  ({
    text,
    onClick,
    width = '100%',
    disabled = false,
    loading = false,
    large = false,
    extraStyles,
  }: Props): JSX.Element => (
    <Button
      width={width}
      disabled={disabled}
      loading={loading}
      disableRipple={loading}
      onClick={(e) => (loading ? e.preventDefault() : onClick())}
      large={large}
      style={extraStyles}
    >
      <DescriptionWrapper $loading={loading}>
        {loading && <Loader size={20} />}
        <Description $disabled={disabled} $loading={loading}>
          {text}
        </Description>
      </DescriptionWrapper>
    </Button>
  ),
);

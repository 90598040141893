import styled from 'styled-components';
import * as constants from 'utils/constants';
import { useIsMobile, useText } from 'hooks';
import { Button, Modal } from 'components/ui';
import { RequestDocuments } from 'assets/icons';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectJobsView } from 'store/selectors';

// Styled Components
const ContentContainer = styled.div`
  padding: 40px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 20px;
  }
`;

const H1 = styled.h1`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: ${({ theme }) => theme.fontSizes.xxxl};
  margin-bottom: 10px !important;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: ${({ theme }) => theme.fontSizes.xl};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const SubHeader = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.default};
`;

const RequestDocumentsIcon = styled(RequestDocuments)`
  border: 2px solid black;
  padding: 3px;
  width: 42px;
  height: 42px;
`;

const SubText = styled.div`
  margin-top: 8px;
  font-size: ${({ theme }) => theme.fontSizes.s};
`;

type Props = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
};

// RequestFiles
const RequestFiles = ({ showModal, setShowModal }: Props): JSX.Element => {
  const { jobId } = useParams();
  const isMobile = useIsMobile();
  const getText = useText();
  const mailTo = `mailto:help@fyld.ai?subject=Job file request&body=Please send me files for job_id: ${jobId}. Thanks!`;
  const view = useSelector(selectJobsView);
  return (
    <Modal.Small
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      fullScreenOnSmall
      showTitleBar
    >
      <ContentContainer>
        <Header>
          <H1>{getText('job_details_request_job_file')}</H1>{' '}
          <RequestDocumentsIcon />
        </Header>
        <SubHeader>
          {getText('job_details_request_job_file_subheader')}
        </SubHeader>
        <SubText>
          {getText('job_details_request_job_file_subtext', { jobId })}
        </SubText>
        <Button.Primary
          onClick={() => {
            setShowModal(false);
          }}
          href={mailTo}
          text={getText('confirm')}
          width='240px'
          extraStyles={
            isMobile
              ? { position: 'fixed', bottom: '0', left: '0' }
              : { marginLeft: 'auto' }
          }
          event={constants.EVENT_JOB_REQUEST_FILES_CONFIRM_CLICKED}
          eventProperties={{
            [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
          }}
        />
      </ContentContainer>
    </Modal.Small>
  );
};

export default RequestFiles;

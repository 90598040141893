import {
  SET_USER_VIEW_SELECTION,
  CLEAR_USER_VIEW_SELECTION,
  SET_ACTIVE_JOB_TAB,
  SET_TAB,
} from './actionTypes';

export const setUserViewSelection = (userViewSelection) => ({
  type: SET_USER_VIEW_SELECTION,
  userViewSelection,
});

export const clearViewVersion = () => ({
  type: CLEAR_USER_VIEW_SELECTION,
});

export const setActiveJobTab = (tab) => ({
  type: SET_ACTIVE_JOB_TAB,
  tab,
});

export const setJobsTab = (tab) => ({
  type: SET_TAB,
  tab,
});

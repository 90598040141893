export const inputAsNumber = (stringValue: string): number | null => {
  if (stringValue === '') {
    return null;
  }
  const value = parseInt(stringValue);
  if (isNaN(value)) {
    return null;
  } else {
    return value;
  }
};

export const roundTo1Decimal = (value: number): number =>
  Math.round(value * 10) / 10;

export const roundPercentage = (value: number): number => Math.round(value);

import RootState from 'model/State/RootState';
import { createSelector } from 'reselect';

export const selectInsightsTab = (state: RootState) =>
  state.insights.currentTab;
export const selectIsLoadingEngagement = (state: RootState) =>
  state.insights.isLoadingEngagement;
export const selectEngagementData = (state: RootState) =>
  state.insights.engagementData;
export const selectIsLoadingCompliance = (state: RootState) =>
  state.insights.isLoadingCompliance;
export const selectComplianceData = (state: RootState) =>
  state.insights.complianceData;
export const selectIsLoadingJobsPerDayData = (state: RootState) =>
  state.insights.isLoadingJobsPerDayData;
export const selectJobsPerDayGraphs = (state: RootState) =>
  state.insights.jobsPerDayGraphs;
export const selectJobsPerDayStats = (state: RootState) =>
  state.insights.jobsPerDayStats;
export const selectIsLoadingOutputPerDayData = (state: RootState) =>
  state.insights.isLoadingOutputPerDayData;
export const selectOutputPerDayGraphs = (state: RootState) =>
  state.insights.outputPerDayGraphs;
export const selectOutputPerDayStats = (state: RootState) =>
  state.insights.outputPerDayStats;
export const selectIsLoadingJobBlockers = (state: RootState) =>
  state.insights.isLoadingJobBlockers;
export const selectJobBlockersGraphs = (state: RootState) =>
  state.insights.jobBlockersGraphs;
export const selectJobBlockersStats = (state: RootState) =>
  state.insights.jobBlockersStats;
export const selectIsLoadingHavsCompliance = (state: RootState) =>
  state.insights.isLoadingHavsCompliance;
export const selectHavsComplianceData = (state: RootState) =>
  state.insights.havsComplianceData;
export const selectIsLoadingVehicleCheckCompliance = (state: RootState) =>
  state.insights.isLoadingVehicleCheckCompliance;
export const selectVehicleCheckComplianceData = (state: RootState) =>
  state.insights.vehicleCheckComplianceData;
export const selectIsLoadingVehicleCheckUserCompliance = (state: RootState) =>
  state.insights.isLoadingVehicleCheckUserCompliance;
export const selectVehicleCheckUserComplianceData = (state: RootState) =>
  state.insights.vehicleCheckUserComplianceData;
export const selectUserFormsData = (state: RootState) =>
  state.insights.userFormsData;
const selectUserFormsDataById = createSelector(
  selectUserFormsData,
  (_: RootState, id: number) => id,
  (userFormsData, id: number) => userFormsData?.[id] || {},
);
export const selectUserFormData = (id: number) => (state: RootState) =>
  selectUserFormsDataById(state, id);
export const selectJobFormsData = (state: RootState) =>
  state.insights.jobFormsData;
const selectJobFormsDataById = createSelector(
  selectJobFormsData,
  (_: RootState, id: number) => id,
  (jobFormsData, id: number) => jobFormsData?.[id] || {},
);
export const selectJobFormData = (id: number) => (state: RootState) =>
  selectJobFormsDataById(state, id);
export const selectPermitFormsData = (state: RootState) =>
  state.insights.permitFormsData;
const selectPermitFormsDataById = createSelector(
  selectPermitFormsData,
  (_: RootState, id: number) => id,
  (permitFormsData, id: number) => permitFormsData?.[id] || {},
);
export const selectPermitFormData = (id: number) => (state: RootState) =>
  selectPermitFormsDataById(state, id);
export const selectIsLoadingUserForms = (state: RootState) =>
  state.insights.isLoadingUserForms;
const selectIsLoadingUserFormById = createSelector(
  selectIsLoadingUserForms,
  (_: RootState, id: number) => id,
  (isLoadingUserForms, id: number) => isLoadingUserForms?.[id] || false,
);
export const selectIsLoadingUserForm = (id: number) => (state: RootState) =>
  selectIsLoadingUserFormById(state, id);
export const selectIsLoadingJobForms = (state: RootState) =>
  state.insights.isLoadingJobForms;
const selectIsLoadingJobFormById = createSelector(
  selectIsLoadingJobForms,
  (_: RootState, id: number) => id,
  (isLoadingJobForms, id: number) => isLoadingJobForms?.[id] || false,
);
export const selectIsLoadingJobForm = (id: number) => (state: RootState) =>
  selectIsLoadingJobFormById(state, id);
export const selectIsLoadingPermitForms = (state: RootState) =>
  state.insights.isLoadingPermitForms;
const selectIsLoadingPermitFormById = createSelector(
  selectIsLoadingPermitForms,
  (_: RootState, id: number) => id,
  (isLoadingPermitForms, id: number) => isLoadingPermitForms?.[id] || false,
);
export const selectIsLoadingPermitForm = (id: number) => (state: RootState) =>
  selectIsLoadingPermitFormById(state, id);
export const selectJobsPerDayGraphsById = (id: number) => (state: RootState) =>
  state.insights.jobsPerDayGraphs[id];
export const selectJobsPerDayStatsById = (id: number) => (state: RootState) =>
  state.insights.jobsPerDayStats[id];

import { Dots, Info, Message, Sparkle, Warning } from 'assets/icons';
import { StyleVariant } from 'model/enum/StyleVariant';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const TagContainer = styled.div<{
  color: string;
  $backgroundColor: string;
  $fitContent?: boolean;
}>`
  ${mixins.flexCenter};
  padding: 3px 8px;
  margin-top: 7px;
  font-family: ${fonts.bold};
  color: ${({ color }) => color};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  ${({ $fitContent }) => $fitContent && `width: fit-content`};
  ${media.xl`
    font-size: ${fontSizes.xs};
  `}
  &:first-of-type {
    margin-top: 0;
  }
`;

export const HighRiskWarningContainer = styled.div<{
  $hasBorder: boolean;
  variant?: StyleVariant;
}>`
  ${mixins.flexCenter};
  background-color: ${colors.red};
  color: ${colors.white};
  padding: 5px;
  position: relative;
  ${({ $hasBorder }) =>
    $hasBorder &&
    `
      border: 2px solid ${colors.red};
      border-bottom: 0;
    `}
  ${({ variant }) =>
    variant === StyleVariant.TOP &&
    `
      width: 100%; 
      padding: 3px 8px; 
      justify-content: unset;
    `}
  ${media.md`
    font-size: ${fontSizes.xs}!important;
    padding: 2px;
  `}
`;

export const EditableTagContainer = styled.div<{ $editable: boolean }>`
  width: 100px;
  padding: 5px 8px;
  margin-top: 8px;
  background-color: ${colors.grey};
  color: ${colors.black};
  font-size: ${fontSizes.s};
  ${({ $editable }) =>
    $editable &&
    `
    background-color: ${colors.blue};
    color: ${colors.white};
  `};
  ${media.xl`
    font-size: ${fontSizes.xs};
  `}
  ${media.md`
    padding: 2px 3px;
  `}
`;

export const WarningIcon = styled(
  ({ $isSmall = false, color = '', ...rest }) => <Warning {...rest} />,
)`
  height: 27px;
  width: 27px;
  min-height: 27px;
  min-width: 27px;
  padding-right: 8px;
  ${({ $isSmall }) =>
    $isSmall &&
    `
      height: 20px;
      width: 20px;
      min-height: 20px;
      min-width: 20px;
  `};

  .fill-selector {
    fill: ${({ color }) => color || colors.yellow};
  }
  ${media.xl`
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
  `}
`;

export const MessageIcon = styled(Message)`
  height: 27px;
  width: 27px;
  min-height: 27px;
  min-width: 27px;
  padding-right: 8px;
  ${media.xl`
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
  `}
`;

export const HighRiskWarningIcon = styled(({ isBig, ...props }) => (
  <Warning {...props} />
))`
  height: ${({ isBig }) => (isBig ? 27 : 20)}px;
  width: ${({ isBig }) => (isBig ? 27 : 20)}px;
  padding-right: 8px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const Score = styled.div<{ variant?: StyleVariant }>`
  ${({ variant }) =>
    variant === StyleVariant.INLINE
      ? `position: absolute; right: 5px;`
      : `margin-left: 10px`}
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  margin-top: 7px;
  color: ${colors.white};
  background-color: ${colors.blue};
  width: 100%;
  box-sizing: border-box;
  ${media.xl`
    font-size: ${fontSizes.xs};
  `}
`;

export const InfoIcon = styled(Info)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const SparkleIcon = styled(Sparkle)`
  height: 27px;
  width: 27px;
  min-height: 27px;
  min-width: 27px;
  padding-right: 8px;
  ${media.xl`
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
  `}
`;

export const DotsIcon = styled(Dots)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

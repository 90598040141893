import RootState from 'model/State/RootState';

export const selectActiveAssessment = (state: RootState) =>
  state.assessments.activeAssessment;
export const selectIsRequestingNewVra = (state: RootState) =>
  state.assessments.isRequestingNewVra;
export const selectIsGeneratingAssessmentPdf = (state: RootState) =>
  state.assessments.isGeneratingPdf;
export const selectIsSendingSummaryFeedback = (state: RootState) =>
  state.assessments.isSendingSummaryFeedback;
export const selectIsSummaryFeedbackSuccess = (state: RootState) =>
  state.assessments.isSummaryFeedbackSuccess;

import styled from 'styled-components';

import theme from 'theme';

const { colors, mixins } = theme;

// Styled Components
export const SkeletonCardContainer = styled.div`
  ${mixins.flexBetween};
  border: 3px solid ${colors.grey};
  padding: 16px 20px;
  margin: 10px 0;
  color: ${colors.grey};
`;

export const InformationLabel = styled.h4`
  color: ${colors.black};
`;

export const MessageTypes = {
  ASSESSMENT: 'assessment',
  EVIDENCE: 'evidence',
  LOG: 'log',
  JOB_BLOCKER: 'job_blocker',
  NOTE: 'note',
  WORK_ORDER_ITEM: 'work order item',
  DOCUMENT: 'document',
  PERMIT: 'permit',
  JOB_FORM: 'job form',
  TEXT: 'text',
  EVENT: 'event',
  JOB_BRIEFING: 'job briefing',
};

import JobData from 'model/JobData';
import { MapJob } from 'model/MapJob';
import {
  MarkerBlack,
  MarkerOrange,
  MarkerRed,
  MarkerYellow,
} from 'assets/icons';
import Coordinate from 'model/Coordinate';
import { AWSRegion } from 'utils/constants';
import * as Logger from 'utils/logger';

export const checkIfGoogleMapsLoaded = () => {
  if (!window?.google?.maps) {
    Logger.error(Error('Google Maps API script not loaded'));
    return false;
  }
  return true;
};

export const getDefaultMapSettings = () => {
  switch (process.env.REACT_APP_AWS_REGION) {
    case AWSRegion.EU_WEST_1:
      return {
        defaultCentre: { lat: 54.509865, lng: -2.118092 },
        defaultZoom: 6,
      };

    case AWSRegion.US_EAST_1:
      return {
        defaultCentre: { lat: 38.657756, lng: -95.715267 },
        defaultZoom: 4,
      };

    case AWSRegion.AP_SOUTHEAST_2:
      return {
        defaultCentre: { lat: -25.8559799094, lng: 133.20666584 },
        defaultZoom: 4,
      };

    default:
      return {
        defaultCentre: { lat: 51.509865, lng: -0.118092 },
        defaultZoom: 6,
      };
  }
};

export const getLegacyMapMarkerIcon = ({
  isHighSafetyRisk,
  isActive,
}: Coordinate) => {
  if (isHighSafetyRisk) {
    return MarkerRed;
  }
  if (isActive) {
    return MarkerYellow;
  }
  return MarkerBlack;
};

export const getMapMarkerIcon = (job: MapJob) => {
  if (job.isBlocked) {
    return MarkerOrange;
  }
  if (job.hasMajorHazard) {
    return MarkerRed;
  }

  return MarkerYellow;
};

type RiskWarningGetter = (riskPrediction: object) => object | null;

export const getLegacyCoordinatesFromJob = (
  job: JobData,
  getRiskWarning?: RiskWarningGetter,
) => {
  return {
    lat: parseFloat(job.latitude),
    lng: parseFloat(job.longitude),
    isActive: true,
    isHighSafetyRisk: !!getRiskWarning?.(job.safety_prediction_score),
    jobId: job.id,
  };
};

export const getCoordinatesFromJob = (
  job: JobData,
  getRiskWarning: RiskWarningGetter,
): MapJob => ({
  jobId: job.id,
  lat: parseFloat(job.latitude),
  lng: parseFloat(job.longitude),
  isActive: true,
  assignees: job.assignees,
  address: job.address,
  postcode: job.postcode,
  jobNumber: job.job_number,
  lastActivity: job.last_activity,
  updatedAt: job.updated_at,
  hasMajorHazard: job.has_major_hazard,
  isHighSafetyRisk: !!getRiskWarning(job.safety_prediction_score),
  hasUnreadMessages: job.unread_messages > 0,
  hasPendingRiskAssessment: job.sign_off_pending_assessment_count > 0,
  isBlocked: job.pending_job_blocker_count > 0,
});

export const getCoordinatesForNewJob = (
  latitude: string,
  longitude: string,
): Coordinate | null => {
  const parsedLat = parseFloat(latitude);
  const parsedLong = parseFloat(longitude);
  return Number.isNaN(parsedLat) || isNaN(parsedLong)
    ? null
    : {
        lat: parsedLat,
        lng: parsedLong,
        isActive: false,
      };
};

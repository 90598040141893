import { useCommandCenter, useText } from 'hooks';
import { SectionButton } from './SectionButton';
import { useDispatch, useSelector } from 'react-redux';
import { RamsStages } from 'model/Assessment/RAMS';
import RootState from 'model/State/RootState';
import { Button } from 'components/ui';
import { useNavigate, useParams } from 'react-router-dom';
import { BackButtonContainer, SectionButtonsContainer } from './styled';
import { setActiveJobTab } from 'store/actions/versions';
import { TIMELINE } from 'utils/constants';
import { getSignedOnUsers } from 'helpers/users';
import { CompleteTag } from './CompleteTag';
import { WarningTag } from 'components/ui/Tags/WarningTag';
import { NotStartedTag } from './NotStartedTag';
import theme from 'theme';
import {
  selectActiveRamsAssessment,
  setAssessmentStage,
} from 'store/slices/ramsAssessments';
import { ActionButtonsWrapper } from '../styled';

export const ActionSelection = (): JSX.Element => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const getText = useText();
  const dispatch = useDispatch();
  const { isCommandCenter } = useCommandCenter();
  const { sections, selectedHazards } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );
  const assessment = useSelector(selectActiveRamsAssessment);
  const signedOnUsers = getSignedOnUsers(assessment);

  const setMethodStatementStage = () => {
    if (sections && sections.length > 0) {
      dispatch(setAssessmentStage(RamsStages.methodStatementView));
    } else {
      dispatch(setAssessmentStage(RamsStages.methodStatement));
    }
  };

  const returnToTimeline = () => {
    navigate(`/jobs/${jobId}/timeline`);
    dispatch(setActiveJobTab(TIMELINE));
  };

  const isRiskAssessmentReviewSubmitted: boolean =
    !!assessment?.ramsReviews?.length;

  const isAllCompleted: boolean =
    isRiskAssessmentReviewSubmitted && !!assessment?.methodStatement;

  const getRiskAssessmentStatus = () => {
    if (isAllCompleted) {
      return null;
    }
    if (isRiskAssessmentReviewSubmitted) {
      return <CompleteTag />;
    }
    return (
      <WarningTag
        text={getText('not_complete')}
        isSmall
        extraStyles={{
          marginTop: '4px',
          outline: `2px solid ${theme.colors.orange}`,
          fontFamily: theme.fonts.italic,
        }}
      />
    );
  };

  const getMethodStatementStatus = () => {
    if (isAllCompleted) {
      return null;
    }
    if (!isRiskAssessmentReviewSubmitted) {
      return <NotStartedTag />;
    }
    return (
      <WarningTag
        text={getText('not_complete')}
        isSmall
        extraStyles={{
          marginTop: '4px',
          outline: `2px solid ${theme.colors.orange}`,
          fontFamily: theme.fonts.italic,
        }}
      />
    );
  };

  return (
    <>
      <SectionButtonsContainer>
        <SectionButton
          title={getText('rams_section_option_assessment')}
          subtitle={getText('rams_section_subtitle_hazards', {
            number: selectedHazards?.length ?? 0,
          })}
          onClick={() =>
            dispatch(
              setAssessmentStage(
                isRiskAssessmentReviewSubmitted
                  ? RamsStages.hazardRatingSummary
                  : RamsStages.hazardIdentification,
              ),
            )
          }
          StatusTag={getRiskAssessmentStatus()}
        />
        <SectionButton
          title={getText('rams_section_option_method')}
          subtitle={getText('rams_section_subtitle_sections', {
            number: sections?.length ?? 0,
          })}
          onClick={setMethodStatementStage}
          StatusTag={getMethodStatementStatus()}
          disabled={!isRiskAssessmentReviewSubmitted}
        />
        <SectionButton
          title={getText('rams_section_option_sign_on')}
          subtitle={
            assessment?.signedOnUsers?.length
              ? getText('rams_sign_on_action_button_members_signed_on', {
                  signedOnNumber: signedOnUsers?.length,
                  total: assessment?.signedOnUsers.length,
                })
              : getText('rams_sign_on_action_button_zero_added')
          }
          onClick={() => dispatch(setAssessmentStage(RamsStages.signOn))}
          disabled={!isRiskAssessmentReviewSubmitted}
        />
      </SectionButtonsContainer>
      <ActionButtonsWrapper>
        {!isCommandCenter && (
          <BackButtonContainer>
            <Button.Primary
              text={getText('rams_section_return_to_timeline')}
              onClick={returnToTimeline}
              width='50%'
              extraStyles={{
                margin: 0,
                alignSelf: 'flex-end',
                height: 'inherit',
                maxHeight: '55px',
              }}
            />
          </BackButtonContainer>
        )}
      </ActionButtonsWrapper>
    </>
  );
};

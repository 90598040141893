import styled, { keyframes } from 'styled-components';
import { Navbar } from 'components/ui';
import { useNotifications, useText } from 'hooks';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import theme from 'theme';
import { Toggle } from 'components/ui/Input';
import { ExemptionKind } from 'model/enum/ExemptionKind';
import { ExemptionChannel } from 'model/enum/ExemptionChannel';
import { useSelector } from 'react-redux';
import { selectWorkspaceHasSettingEnabled } from 'store/selectors';
import { WorkspaceFieldNames } from './constants';

const { colors, fonts, fontSizes, mixins, media } = theme;

const Container = styled.div`
  height: 100%;
  padding: 40px 80px;
  ${mixins.flexColumn};
  ${media.sm`
    padding: 15px;
  `}
  background-color: ${colors.offWhite};
  box-sizing: border-box;
`;

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  min-height: 340px;
  ${media.sm`
    width: 100%;
  `}
  background: ${colors.white};
  padding: 32px;

  > div:nth-child(odd) {
    background-color: ${colors.offWhite};
  }
  > div:nth-child(even) {
    background-color: ${colors.white};
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding: 0 20px;
`;

const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 40px !important;
`;

const HeaderWrapper = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 30px;
`;

const Label = styled.label`
  font-family: ${fonts.bold};
  margin-bottom: 8px;
  color: ${colors.black};
`;

const SettingsContainer = styled.div`
  display: flex;
  ${media.sm`
    flex-direction: column;
  `}
`;

const PushSettingsOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmailSettingsOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  ${media.sm`
    margin: 20px 0;
  `}
`;

// NotificationSettings
const NotificationSettings = (): JSX.Element => {
  const getText = useText();
  const { notificationExemptions, saveChange, addExemption, removeExemption } =
    useNotifications();
  const showSignOnToggle = useSelector(
    selectWorkspaceHasSettingEnabled(WorkspaceFieldNames.FIELDWORKER_SIGN_ON),
  );

  const handleChange = (exemption) => {
    Analytics.trackEvent(constants.EVENT_NOTIFICATION_EXEMPTIONS_UPDATED, {
      kind: exemption.kind,
      channel: exemption.channel,
      isAdded: !exemption.isAdded,
    });
    if (!exemption.isAdded) {
      addExemption({ kind: exemption.kind, channel: exemption.channel });
    } else {
      removeExemption({ kind: exemption.kind, channel: exemption.channel });
    }
  };

  return (
    <>
      <Navbar home mobileOnly />
      <Container>
        <HeaderWrapper>
          <H2>{getText('notifications')}</H2>
          {saveChange && <Spinner />}
        </HeaderWrapper>
        <SettingsContainer>
          <PushSettingsOptionsWrapper>
            <HeaderWrapper>
              <Label>{getText('notifications_mobile_push')}</Label>
            </HeaderWrapper>
            <SettingsWrapper>
              <ToggleContainer>
                <Toggle
                  id='assessment-ready-mobile'
                  label={getText('notifications_video_sign_off_ready')}
                  checked={
                    !notificationExemptions.find(
                      (exemption) =>
                        exemption.kind === ExemptionKind.ASSESSMENT_REVIEWED &&
                        exemption.channel === ExemptionChannel.PUSH,
                    )
                  }
                  onChange={(e) =>
                    handleChange({
                      kind: ExemptionKind.ASSESSMENT_REVIEWED,
                      channel: ExemptionChannel.PUSH,
                      isAdded: e?.target.checked,
                    })
                  }
                />
              </ToggleContainer>
              {showSignOnToggle && (
                <ToggleContainer>
                  <Toggle
                    id='assessment-not-all-signed'
                    label={getText('notifications_video_not_signed_off')}
                    checked={
                      !notificationExemptions.find(
                        (exemption) =>
                          exemption.kind ===
                            ExemptionKind.ASSESSMENT_NOT_ALL_USERS_SIGNED_ON &&
                          exemption.channel === ExemptionChannel.PUSH,
                      )
                    }
                    onChange={(e) =>
                      handleChange({
                        kind: ExemptionKind.ASSESSMENT_NOT_ALL_USERS_SIGNED_ON,
                        channel: ExemptionChannel.PUSH,
                        isAdded: e?.target.checked,
                      })
                    }
                  />
                </ToggleContainer>
              )}
              <ToggleContainer>
                <Toggle
                  id='job-blocker-mobile'
                  checked
                  disabled
                  label={getText('notifications_job_blocker')}
                />
              </ToggleContainer>
              <ToggleContainer>
                <Toggle
                  id='new-message-mobile'
                  label={getText('notifications_new_message')}
                  checked={
                    !notificationExemptions.find(
                      (exemption) =>
                        exemption.kind === ExemptionKind.TEXT_RECIEVED &&
                        exemption.channel === ExemptionChannel.PUSH,
                    )
                  }
                  onChange={(e) =>
                    handleChange({
                      kind: ExemptionKind.TEXT_RECIEVED,
                      channel: ExemptionChannel.PUSH,
                      isAdded: e?.target.checked,
                    })
                  }
                />
              </ToggleContainer>
              <ToggleContainer>
                <Toggle
                  id='evidence-mobile'
                  label={getText('notifications_evidence')}
                  checked={
                    !notificationExemptions.find(
                      (exemption) =>
                        exemption.kind === ExemptionKind.EVIDENCE_RECIEVED &&
                        exemption.channel === ExemptionChannel.PUSH,
                    )
                  }
                  onChange={(e) =>
                    handleChange({
                      kind: ExemptionKind.EVIDENCE_RECIEVED,
                      channel: ExemptionChannel.PUSH,
                      isAdded: e?.target.checked,
                    })
                  }
                />
              </ToggleContainer>
              <ToggleContainer>
                <Toggle
                  id='permit-received-mobile'
                  label={getText('notifications_permit_received')}
                  checked={
                    !notificationExemptions.find(
                      (exemption) =>
                        exemption.kind === ExemptionKind.PERMIT_RECEIVED &&
                        exemption.channel === ExemptionChannel.PUSH,
                    )
                  }
                  onChange={(e) =>
                    handleChange({
                      kind: ExemptionKind.PERMIT_RECEIVED,
                      channel: ExemptionChannel.PUSH,
                      isAdded: e?.target.checked,
                    })
                  }
                />
              </ToggleContainer>
              <ToggleContainer>
                <Toggle
                  id='form-received-mobile'
                  label={getText('notifications_form_received')}
                  checked={
                    !notificationExemptions.find(
                      (exemption) =>
                        exemption.kind === ExemptionKind.FORM_RECEIVED &&
                        exemption.channel === ExemptionChannel.PUSH,
                    )
                  }
                  onChange={(e) =>
                    handleChange({
                      kind: ExemptionKind.FORM_RECEIVED,
                      channel: ExemptionChannel.PUSH,
                      isAdded: e?.target.checked,
                    })
                  }
                />
              </ToggleContainer>
            </SettingsWrapper>
          </PushSettingsOptionsWrapper>
          <EmailSettingsOptionsWrapper>
            <HeaderWrapper>
              <Label>
                {getText('notifications_email')}
                <Label></Label>
              </Label>
            </HeaderWrapper>
            <SettingsWrapper>
              <ToggleContainer>
                <Toggle
                  id='assessment-ready-email'
                  label={getText('notifications_video_sign_off_ready')}
                  checked={
                    !notificationExemptions.find(
                      (exemption) =>
                        exemption.kind === ExemptionKind.ASSESSMENT_REVIEWED &&
                        exemption.channel === ExemptionChannel.EMAIL,
                    )
                  }
                  onChange={(e) =>
                    handleChange({
                      kind: ExemptionKind.ASSESSMENT_REVIEWED,
                      channel: ExemptionChannel.EMAIL,
                      isAdded: e?.target.checked,
                    })
                  }
                />
              </ToggleContainer>
              <ToggleContainer>
                <Toggle
                  id='job-blocker-email'
                  label={getText('notifications_job_blocker')}
                  checked={
                    !notificationExemptions.find(
                      (exemption) =>
                        exemption.kind === ExemptionKind.JOB_BLOCKER_RECEIVED &&
                        exemption.channel === ExemptionChannel.EMAIL,
                    )
                  }
                  onChange={(e) =>
                    handleChange({
                      kind: ExemptionKind.JOB_BLOCKER_RECEIVED,
                      channel: ExemptionChannel.EMAIL,
                      isAdded: e?.target.checked,
                    })
                  }
                />
              </ToggleContainer>
              <ToggleContainer>
                <Toggle
                  id='new-message-email'
                  label={getText('notifications_new_message')}
                  checked={
                    !notificationExemptions.find(
                      (exemption) =>
                        exemption.kind === ExemptionKind.TEXT_RECIEVED &&
                        exemption.channel === ExemptionChannel.EMAIL,
                    )
                  }
                  onChange={(e) =>
                    handleChange({
                      kind: ExemptionKind.TEXT_RECIEVED,
                      channel: ExemptionChannel.EMAIL,
                      isAdded: e?.target.checked,
                    })
                  }
                />
              </ToggleContainer>
              <ToggleContainer>
                <Toggle
                  id='evidence-email'
                  label={getText('notifications_evidence')}
                  checked={
                    !notificationExemptions.find(
                      (exemption) =>
                        exemption.kind === ExemptionKind.EVIDENCE_RECIEVED &&
                        exemption.channel === ExemptionChannel.EMAIL,
                    )
                  }
                  onChange={(e) =>
                    handleChange({
                      kind: ExemptionKind.EVIDENCE_RECIEVED,
                      channel: ExemptionChannel.EMAIL,
                      isAdded: e?.target.checked,
                    })
                  }
                />
              </ToggleContainer>
              <ToggleContainer>
                <Toggle
                  id='permit-received-email'
                  label={getText('notifications_permit_received')}
                  checked={
                    !notificationExemptions.find(
                      (exemption) =>
                        exemption.kind === ExemptionKind.PERMIT_RECEIVED &&
                        exemption.channel === ExemptionChannel.EMAIL,
                    )
                  }
                  onChange={(e) =>
                    handleChange({
                      kind: ExemptionKind.PERMIT_RECEIVED,
                      channel: ExemptionChannel.EMAIL,
                      isAdded: e?.target.checked,
                    })
                  }
                />
              </ToggleContainer>
              <ToggleContainer>
                <Toggle
                  id='form-received-email'
                  label={getText('notifications_form_received')}
                  checked={
                    !notificationExemptions.find(
                      (exemption) =>
                        exemption.kind === ExemptionKind.FORM_RECEIVED &&
                        exemption.channel === ExemptionChannel.EMAIL,
                    )
                  }
                  onChange={(e) =>
                    handleChange({
                      kind: ExemptionKind.FORM_RECEIVED,
                      channel: ExemptionChannel.EMAIL,
                      isAdded: e?.target.checked,
                    })
                  }
                />
              </ToggleContainer>
            </SettingsWrapper>
          </EmailSettingsOptionsWrapper>
        </SettingsContainer>
      </Container>
    </>
  );
};
export default NotificationSettings;

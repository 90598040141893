import styled from 'styled-components';
import theme from 'theme';
import { TableCell as MuiTableCell } from '@mui/material';

const { fonts, fontSizes } = theme;

export const TableHeaderCell = styled(({ width = 100, ...rest }) => (
  <MuiTableCell {...rest} />
))`
  &.MuiTableCell-root {
    width: ${({ width }) => width}%;
    font-family: ${fonts.bold};
    text-align: center;
    padding: 10px 5px;
  }
`;

export const HeaderTitleWrapper = styled.div`
  display: flex;
`;

export const HeaderTitle = styled.div`
  font-size: ${fontSizes.s};
`;

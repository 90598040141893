import axios from 'axios';
import { BackendUser } from 'model/BackendUser';
import { UserRoleBackend } from 'model/enum/UserRoleBackend';
import * as Logger from 'utils/logger';

export const getNext = (nextLink) => axios.get(nextLink);

export const getAll = (
  workspaceUuid: string,
  searchParam: string = '',
  pageSize: number = 1000,
  depotId: number | null = null,
  role: UserRoleBackend | null = null,
  roles: UserRoleBackend[] | null = null,
) => {
  let roleParam = {};

  if (roles?.length) {
    roleParam = { roles: roles.join(',') };
  } else if (role) {
    roleParam = { role };
  }

  return axios.get(`/users/`, {
    params: {
      search: searchParam,
      page_size: pageSize,
      ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
      ...(depotId ? { depot_id: depotId } : {}),
      ...roleParam,
    },
  });
};

export const get = (selectedLanguage) =>
  axios.get(`/me/`, {
    headers: {
      Accept: 'application/json; version=2;',
      ...(selectedLanguage && { Language: selectedLanguage }),
    },
  });

export const getStats = () => axios.get(`/me/stats/`);

export const getNotificationSettings = () =>
  axios.get(`/me/notification-settings/`);

export const updateNotificationSettings = async (notificationExemptions) =>
  axios.put(`/me/notification-settings/`, {
    exemptions: notificationExemptions,
  });

export const getUsers = (
  workspaceUuid,
  searchParam = '',
  pageSize = 1500,
  page = 1,
) =>
  axios.get(`admin/users/`, {
    params: {
      search: searchParam,
      page_size: pageSize,
      page,
      ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
    },
  });

export const getUsersCSV = async (workspaceUuid) => {
  const result = await axios.get(`admin/users/`, {
    params: {
      ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
    },
    headers: {
      Accept: 'text/csv',
    },
  });

  return result.data;
};

export const updateUser = (user: BackendUser) =>
  axios.put(`admin/users/${user.id}/`, user);

export const editUser = ({
  id,
  email,
  firstName,
  lastName,
  roleId,
  phoneNumber,
  depotId,
  workspaceUuid,
  isDeviceless = false,
  isActive,
  externalId,
}) => {
  const data = {
    id,
    email,
    last_name: lastName,
    first_name: firstName,
    role: roleId,
    depot_id: depotId,
    ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
    phone_number: !!phoneNumber.length && !isDeviceless ? phoneNumber : '',
    is_deviceless: isDeviceless,
    is_active: isActive,
    external_id: externalId || null,
  };

  if (phoneNumber.length > 0) {
    data.phone_number = phoneNumber;
  }

  return updateUser(data);
};

export const requestMagicLink = (email) =>
  axios.post(`request-magic-link/`, { email });

export const signUp = ({ email, firstName, lastName, role, phoneNumber }) => {
  const data = {
    role,
    email,
    last_name: lastName,
    first_name: firstName,
    phone_number: null,
  };

  if (phoneNumber.length > 0) {
    data.phone_number = phoneNumber;
  }

  return axios.post('/sign-up/', data);
};

export const createUser = ({
  email,
  firstName,
  lastName,
  roleId,
  phoneNumber,
  depotId,
  workspaceUuid,
  isDeviceless = false,
  externalId,
}) => {
  const data = {
    ...(email && !isDeviceless ? { email } : {}),
    last_name: lastName,
    first_name: firstName,
    role: roleId === 2 ? UserRoleBackend.MANAGER : UserRoleBackend.FIELDWORKER,
    depot_id: depotId,
    ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}),
    ...(!!phoneNumber.length && !isDeviceless
      ? { phone_number: phoneNumber }
      : {}),
    is_deviceless: isDeviceless,
    ...(externalId && { external_id: externalId || null }),
  };

  if (phoneNumber.length > 0) {
    data.phone_number = phoneNumber;
  }

  return axios.post('/admin/users/', data);
};

export const getTeams = async (workspaceUuid) => {
  const { data } = await axios.get(`/teams/`, {
    params: { ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}) },
  });
  return data?.results;
};

export const saveTeam = async (
  manager: number,
  members: number[],
  name: string,
) => {
  try {
    const response = await axios.post(`/teams/`, {
      manager,
      members,
      name,
    });
    return response.data;
  } catch (err) {
    Logger.error(err);
    throw err;
  }
};

export const editTeam = async (
  teamId,
  manager: number,
  members: number[],
  name: string,
) => {
  try {
    const response = await axios.put(`/teams/${teamId}`, {
      manager,
      members,
      name,
    });
    return response.data;
  } catch (err) {
    Logger.error(err);
    throw err;
  }
};

export const deleteTeam = async (teamId) => {
  try {
    const response = await axios.delete(`/teams/${teamId}`);
    return response.data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
  }
};

import styled from 'styled-components';
import theme from 'theme';
import { Warning } from 'assets/icons';
import { useText } from 'hooks';
import { Button, Modal } from 'components/ui';
import * as Analytics from 'utils/analytics';
import { useEffect } from 'react';

const { fonts, fontSizes } = theme;

const Title = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  padding-bottom: 30px;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: 20px auto;
  padding: 20px;
`;

const Confirm = styled.div`
  font-size: ${fontSizes.m};
  font-family: ${fonts.light};
`;

const WarningContainer = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
`;

const WarningMessage = styled.div`
  font-size: ${fontSizes.m};
  font-family: ${fonts.bold};
`;

const WarningIcon = styled(Warning)`
  height: 60px;
  width: 60px;
  min-height: 60px;
  min-width: 60px;
  margin-right: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  align-self: flex-end;
`;

type Props = {
  isVisible: boolean;
  closeModal: () => void;
  onButtonClick: () => void;
  confirmMessage?: string;
  warningMessage?: string;
  buttonText: string;
  title?: string;
  screenEvent?: string;
  screenEventProperties?: object;
  cancelEvent?: string;
  cancelEventProperties?: object;
};

const ConfirmModal = ({
  isVisible,
  closeModal,
  onButtonClick,
  confirmMessage,
  warningMessage,
  buttonText,
  title,
  screenEvent,
  screenEventProperties,
  cancelEvent,
  cancelEventProperties,
}: Props) => {
  const getText = useText();

  useEffect(() => {
    if (screenEvent) {
      Analytics.trackPage(screenEvent, screenEventProperties);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal.Small isOpen={isVisible} onClose={closeModal}>
      <Container>
        {title && <Title>{title}</Title>}
        {confirmMessage && <Confirm>{confirmMessage}</Confirm>}
        {warningMessage && (
          <WarningContainer>
            <WarningIcon />
            <WarningMessage>{warningMessage}</WarningMessage>
          </WarningContainer>
        )}
        <ButtonContainer>
          <Button.Confirm
            onClick={closeModal}
            text={getText('cancel')}
            event={cancelEvent}
            eventProperties={cancelEventProperties}
            extraStyles={{ boxSizing: 'border-box', padding: '10px 20px' }}
          />
          <Button.Confirm
            onClick={onButtonClick}
            text={buttonText}
            extraStyles={{ boxSizing: 'border-box', padding: '10px 20px' }}
          />
        </ButtonContainer>
      </Container>
    </Modal.Small>
  );
};

export default ConfirmModal;

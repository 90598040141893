import RootState from 'model/State/RootState';

import {
  LIST_VIEW,
  LIST_VIEW_TAB,
  LEGACY_VIEW,
  MAP_VIEW_TAB,
} from 'utils/constants';
import {
  selectUserHasPermission,
  selectWorkspaceHasSettingEnabled,
} from './session';
import { createSelector } from 'reselect';

const getViewToShow = (
  canUseListView: boolean,
  defaultToListView: boolean,
  userViewSelection: string | null,
) => {
  if (!canUseListView) {
    return LEGACY_VIEW;
  }

  if (defaultToListView) {
    return userViewSelection === LEGACY_VIEW ? LEGACY_VIEW : LIST_VIEW;
  } else {
    return userViewSelection === LIST_VIEW ? LIST_VIEW : LEGACY_VIEW;
  }
};

const getTabToShow = (userTabSelection: string | null) => {
  return userTabSelection === MAP_VIEW_TAB || userTabSelection === LIST_VIEW_TAB
    ? userTabSelection
    : null;
};

export const selectUserViewSelection = (state: RootState) =>
  state.versions.userViewSelection;

export const selectPreviousViewVersion = (state: RootState): string =>
  getViewToShow(
    selectWorkspaceHasSettingEnabled('enable_jobs_page_v2')(state),
    selectUserHasPermission('jobs_page_v2')(state),
    state.versions.previousUserViewSelection,
  );
export const selectViewVersion = (state: RootState): string =>
  getViewToShow(
    selectWorkspaceHasSettingEnabled('enable_jobs_page_v2')(state),
    selectUserHasPermission('jobs_page_v2')(state),
    state.versions.userViewSelection,
  );
export const selectActiveJobTab = (state: RootState) =>
  state.versions.activeJobTab;
export const selectTab = (state: RootState): string | null =>
  getTabToShow(state.versions.userTabSelection);
export const selectPreviousTab = (state: RootState): string | null =>
  getTabToShow(state.versions.previousUserTabSelection);
const calculateJobsView = (viewVersion: string, tab: string | null) => {
  if (viewVersion === LIST_VIEW) {
    if (!!tab) {
      return tab;
    }
    return LIST_VIEW_TAB;
  }
  return viewVersion;
};
export const selectPreviousJobsView = (state: RootState) =>
  createSelector(
    selectPreviousViewVersion,
    selectPreviousTab,
    calculateJobsView,
  )(state);
export const selectJobsView = (state: RootState) =>
  createSelector(selectViewVersion, selectTab, calculateJobsView)(state);
export const selectIsSwitchingTabsOnly = (state: RootState) =>
  createSelector(
    selectPreviousJobsView,
    selectJobsView,
    (previousView: string, currentView: string): boolean => {
      if (
        currentView === MAP_VIEW_TAB &&
        (previousView === LIST_VIEW || previousView === LIST_VIEW_TAB)
      ) {
        return true;
      }
      if (currentView === LIST_VIEW_TAB && previousView === MAP_VIEW_TAB) {
        return true;
      }
      return false;
    },
  )(state);

import axios from 'axios';
import { mapToBackendVideo, mapToVideo } from 'api/Media/helpers';
import { Video } from 'model/Media/Video';
import {
  Assessment,
  Assessments,
  AssessmentType,
  EditAssessmentAttributes,
  EditAssessmentResponse,
} from 'model/Assessment';
import { Hazards, SuggestionRequest } from 'model/Assessment/Hazard';
import {
  MethodStatementSection,
  MethodStatementSectionTemplate,
  RamsAssessments,
} from 'model/Assessment/RAMS';

export const get = async (id: number): Promise<Assessment> => {
  const { data } = await axios.get(`/assessments/${id}/`);
  return Assessments.mapToAssessment(data);
};

export const getAll = async (jobId: string): Promise<Assessment[]> => {
  const { data } = await axios.get(`/jobs/${jobId}/assessments/`, {
    headers: {
      Accept: 'application/json; version=3;',
    },
  });
  return data.results.map(Assessments.mapToAssessment);
};

export const getAllTypes = async (
  workspaceUuid: string,
): Promise<AssessmentType[]> => {
  const { data } = await axios.get('/assessment-types/', {
    params: { ...(workspaceUuid ? { workspace_uuid: workspaceUuid } : {}) },
  });
  return data.results.map(Assessments.mapToAssessmentType);
};

export const editAssessment = async (
  id: number,
  attributes: EditAssessmentAttributes,
): Promise<EditAssessmentResponse> => {
  const { data } = await axios.put(`/assessments/${id}/`, {
    ...Assessments.mapToBackendAssessment(attributes),
  });
  return Assessments.mapToEditAssessmentResponse(data);
};

export const createSuggestion = async (
  id: number,
  { hazards, controls, suggestedHazards, suggestedControls }: SuggestionRequest,
): Promise<Assessment> => {
  const { data } = await axios.post(
    `/assessments/${id}/suggestions/`,
    {
      ...Hazards.mapToBackendSuggestionRequest({
        hazards,
        controls,
        suggestedHazards,
        suggestedControls,
      }),
    },
    {
      headers: {
        ...((suggestedHazards || suggestedControls) && {
          Accept: 'application/json; version=3',
        }),
      },
    },
  );
  return Assessments.mapToAssessment(data);
};

export const updateAssessmentVideo = async (
  id: number,
  fileId: string,
  attributes: Video,
): Promise<Video> => {
  const { data } = await axios.put(
    `/assessments/${id}/videos/${fileId}`,
    mapToBackendVideo(attributes),
  );
  return mapToVideo(data);
};

export const updateAssessmentSignOnList = async (
  id: number,
  userIds: number[],
): Promise<Assessment> => {
  const { data } = await axios.put(`/assessments/${id}/sign-on-list/`, {
    users: userIds,
  });

  return Assessments.mapToAssessment(data);
};

export const uploadMethodAssessment = async (
  id: number,
  methodStatementSections: MethodStatementSection[],
): Promise<MethodStatementSection> => {
  const { data } = await axios.post(`assessments/${id}/method-statement/`, {
    sections: methodStatementSections.map((section, index) =>
      RamsAssessments.mapToBackendSection(section, index),
    ),
  });
  return RamsAssessments.mapToMethodStatementSection(data);
};

export const getTemplates = async (): Promise<
  MethodStatementSectionTemplate[]
> => {
  const { data } = await axios.get('/method-statement-section-templates/');
  return data.results.map(RamsAssessments.mapToMethodStatementSectionTemplate);
};

export const getPdfExportId = async (assessmentId: number): Promise<string> => {
  const { data } = await axios.post(`/assessments/${assessmentId}/pdf/`);
  return data.object_id;
};

import { useText } from 'hooks';
import ManageTypesBase from './ManageTypesBase';
import {
  selectGlobalLanguageSetting,
  selectJobBlockerTypes,
  selectJobTypeList,
  selectIsFetchingJobBlockerTypes,
  selectIsLoadingJobBlockerChanges,
  selectJobBlockerTypeErrors,
} from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import RootState from 'model/State/RootState';
import {
  fetchWorkspaceJobBlockerTypes,
  fetchWorkspaceJobTypes,
  resetJobBlockerTypeErrors,
  saveJobBlockerTypeChanges,
} from 'store/actions/workspace';
import { useCustomLocaleText } from 'hooks/useText';
import { Languages } from 'utils/constants';
import { WorkspaceItemStatus } from 'model/enum/WorkspaceItemStatus';
import * as Analytics from 'utils/analytics';
import { addItem, editItem } from 'helpers/workspace';
import {
  EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_TYPES_SAVED,
  EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_TYPE_ADDED,
  EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_TYPE_DELETED,
  EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_TYPE_EDITED,
} from '../../constants';
import { WorkspaceItemType } from 'model/AdminPanel/WorkspaceSettings/WorkspaceItemType';

const JobBlockerSettings = (): JSX.Element => {
  const getText = useText();
  const getEnglishText = useCustomLocaleText(Languages.english.locale);
  const getSpanishText = useCustomLocaleText(Languages.spanish.locale);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const isLoadingJobBlockerChanges = useSelector(
    selectIsLoadingJobBlockerChanges,
  );
  const jobBlockerTypeErrors = useSelector(selectJobBlockerTypeErrors);
  const dispatch = useDispatch();
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const jobTypes = useSelector(selectJobTypeList(selectedWorkspace?.uuid));
  const initialJobBlockerTypes = useSelector(
    selectJobBlockerTypes(selectedWorkspace?.uuid),
  );
  const isFetchingJobBlockerTypes = useSelector(
    selectIsFetchingJobBlockerTypes,
  );
  const [jobBlockerTypes, setJobBlockerTypes] = useState<WorkspaceItemType[]>(
    initialJobBlockerTypes,
  );

  useEffect(() => {
    dispatch(resetJobBlockerTypeErrors());
    if (selectedWorkspace?.uuid) {
      dispatch(fetchWorkspaceJobTypes(selectedWorkspace?.uuid));
      dispatch(fetchWorkspaceJobBlockerTypes(selectedWorkspace?.uuid));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setJobBlockerTypes(initialJobBlockerTypes);
  }, [initialJobBlockerTypes]);

  const onAddOrEdit = (
    isUpdate: boolean,
    index: number,
    englishText: string,
    spanishText: string,
  ) => {
    if (isUpdate && index > -1) {
      const modifiedList = editItem(
        initialJobBlockerTypes,
        jobBlockerTypes,
        index,
        englishText,
        spanishText,
        EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_TYPE_EDITED,
      );
      setJobBlockerTypes(modifiedList);
    } else {
      const modifiedList = addItem(
        jobBlockerTypes,
        englishText,
        spanishText,
        EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_TYPE_ADDED,
        undefined,
        undefined,
        (newType) => {
          const types = jobTypes
            .filter((jobType) => jobType.id !== null)
            .map((jobType) => jobType.id) as number[];
          newType.jobType = types.length > 0 ? types : undefined;
        },
      );
      setJobBlockerTypes(modifiedList);
    }
  };

  const onRemove = (index: number, id: number | null) => {
    Analytics.trackEvent(EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_TYPE_DELETED, {
      jobBlockerType: jobBlockerTypes[index],
    });
    if (id) {
      setJobBlockerTypes(
        jobBlockerTypes.map((type) =>
          type.id === id
            ? { ...type, status: WorkspaceItemStatus.DELETED }
            : type,
        ),
      );
    } else if (index > -1) {
      setJobBlockerTypes((prevState) =>
        prevState.filter((_, i) => i !== index),
      );
    }
  };

  const onSubmit = () => {
    const changedFields = jobBlockerTypes.filter((type) => type?.status);
    dispatch(saveJobBlockerTypeChanges(selectedWorkspace?.uuid, changedFields));

    Analytics.trackEvent(EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_TYPES_SAVED, {
      changed_fields: changedFields,
    });
  };

  return (
    <ManageTypesBase
      manageTitle={getText('workspace_settings_manage_job_blocker')}
      addEditTitle={getText('workspace_settings_add_edit_job_blocker')}
      noTypeText={getText('workspace_settings_no_job_blocker')}
      label={getEnglishText('workspace_settings_job_blocker_label')}
      labelEs={getSpanishText('workspace_settings_job_blocker_label')}
      descriptionText={getText(
        'workspace_settings_primary_categories_for_jobs_delay',
      )}
      types={jobBlockerTypes}
      loading={isFetchingJobBlockerTypes}
      swapOrder={globalAppLanguage !== Languages.english.locale}
      onAddOrEditPress={onAddOrEdit}
      onSubmit={onSubmit}
      isSubmitting={isLoadingJobBlockerChanges}
      hasChangedFields={jobBlockerTypes.some((type) => type?.status)}
      onRemove={onRemove}
      errors={jobBlockerTypeErrors}
    />
  );
};

export default JobBlockerSettings;

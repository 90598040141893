import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { useJobs, useIsMobile, useText } from 'hooks';
import {
  ActionButtonBlocker,
  ActionButtonVra,
  ActionButtonJobs,
} from 'assets/icons';
import { Form } from 'components/ui';
import { selectIsStreamline, selectJobsView } from 'store/selectors';
import MyLiveJobsToggle from './MyLiveJobsToggle';
import AddJobButton from './AddJobButton';
import {
  EVENT_JOB_PAGE_BOX_FILTER_CLICKED,
  EVENT_PROPERTIES_PAGE_SOURCE_FIELD,
} from 'utils/constants';
import JobSearch from './JobSearch';
import RootState from 'model/State/RootState';
import JobFilterButton from './JobFilterButton';
import * as Analytics from 'utils/analytics';

const { colors, fonts, fontSizes, media, mixins, shadows } = theme;

// Styled Components
const Container = styled.div`
  padding: 15px;
  ${media.sm`
  background-color: ${colors.black};
  box-shadow: ${shadows.default};
`}
`;

const H1 = styled.h1`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  margin-bottom: 8px !important;
  ${media.sm`
    display: none;
  `}
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const Filters = styled.div<{ $isMobile: boolean }>`
  ${mixins.flexBetween};
  margin-top: 15px;
  ${media.xl`
    ${({ $isMobile }) =>
      !$isMobile &&
      `
      flex-direction: column;
      align-items: end;
      height: 70px;`}
`}
`;

const LiveActions = styled.div`
  ${mixins.flexBetween};
  padding: 15px;
`;

const ActionButton = styled(({ isActive, ...props }) => <div {...props}></div>)`
  cursor: pointer;
  ${mixins.flexBetween};
  font-family: ${fonts.bold};
  color: ${({ isActive }) => (isActive ? colors.blue : colors.black)};
  border: 2px solid ${colors.grey};
  background-color: ${({ isActive }) =>
    isActive ? colors.lightGrey : colors.white};
  padding: 10px 8px;
  font-size: ${fontSizes.s};
  line-height: ${fontSizes.l};
  margin-right: min(5px);
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    border: 2px solid ${colors.black};
  }
  ${media.md`
    padding: 10px 0;
  `}
  ${media.sm`
    padding: 5px 3px;
  `}
`;

export const ActionButtonIcon = styled(({ component, isActive, ...props }) =>
  React.cloneElement(component, props),
)`
  margin-right: 8px;
  ${media.xxxl`
  width: 20px;
  height: 20px;
  `}
  ${media.sm`
  width: 18px;
  height: 18px;
  margin-right: 5px;
  `}
  align-self: center;
  outline: 2px solid
    ${({ isActive }) => (isActive ? colors.blue : colors.black)};
  .icon-inside {
    fill: ${({ isActive }) => (isActive ? colors.blue : colors.black)};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

// SearchHeader
const SearchHeader = () => {
  const { filters, jobStats } = useSelector(
    (state: RootState) => state.legacyViewJobs,
  );
  const isStreamline = useSelector(selectIsStreamline);
  const view = useSelector(selectJobsView);
  const { setFilters } = useJobs();
  const [hasUnresolvedVra, setHasUnresolvedVra] = useState<boolean>(false);
  const [hasUnresolvedBlocker, setHasUnresolvedBlocker] =
    useState<boolean>(false);
  const isMobile = useIsMobile();
  const getText = useText();

  const sendStatsAnalytics = (filterBoxName, boxValue) => {
    Analytics.trackEvent(EVENT_JOB_PAGE_BOX_FILTER_CLICKED, {
      filterBoxName,
      boxValue,
      [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    });
  };

  return (
    <>
      <Container>
        <TitleContainer>
          <H1>{getText('jobs_search_label')}</H1>
          <AddJobButton view={view} />
        </TitleContainer>
        <FilterContainer>
          <JobSearch view={view} extraStyles={{ width: '85%' }} />
          <JobFilterButton view={view} />
        </FilterContainer>
        <Filters $isMobile={isMobile}>
          <MyLiveJobsToggle
            view={view}
            hasUnresolvedVra={hasUnresolvedVra}
            hasUnresolvedBlocker={hasUnresolvedBlocker}
          />
          <div>
            <Form.Sort />
          </div>
        </Filters>
      </Container>
      {jobStats && !!filters.scope && !isStreamline && (
        <LiveActions>
          <ActionButton
            isActive={!hasUnresolvedVra && !hasUnresolvedBlocker}
            onClick={() => {
              setFilters({
                ...filters,
                has_unresolved_vra: false,
                has_unresolved_blocker: false,
              });
              setHasUnresolvedVra(false);
              setHasUnresolvedBlocker(false);
              sendStatsAnalytics('jobs', true);
            }}
          >
            <ActionButtonIcon
              isActive={!hasUnresolvedVra && !hasUnresolvedBlocker}
              component={<ActionButtonJobs />}
            />
            {jobStats.number_of_jobs} {getText('jobs_search_jobs')}
          </ActionButton>
          <ActionButton
            isActive={hasUnresolvedVra}
            onClick={() => {
              setFilters({
                ...filters,
                has_unresolved_vra: !hasUnresolvedVra,
                has_unresolved_blocker: false,
              });
              setHasUnresolvedVra(!hasUnresolvedVra);
              setHasUnresolvedBlocker(false);
              sendStatsAnalytics('has_unresolved_vra', !hasUnresolvedVra);
            }}
          >
            <ActionButtonIcon
              isActive={hasUnresolvedVra}
              component={<ActionButtonVra />}
            />
            {jobStats.number_of_jobs_with_unresolved_vras}{' '}
            {getText('jobs_search_vras')}
          </ActionButton>
          <ActionButton
            isActive={hasUnresolvedBlocker}
            onClick={() => {
              setFilters({
                ...filters,
                has_unresolved_blocker: !hasUnresolvedBlocker,
                has_unresolved_vra: false,
              });
              setHasUnresolvedBlocker(!hasUnresolvedBlocker);
              setHasUnresolvedVra(false);
              sendStatsAnalytics(
                'has_unresolved_blocker',
                !hasUnresolvedBlocker,
              );
            }}
          >
            <ActionButtonIcon
              isActive={hasUnresolvedBlocker}
              component={<ActionButtonBlocker />}
            />
            {jobStats.number_of_jobs_with_unresolved_blockers}{' '}
            {getText('jobs_search_blocked')}
          </ActionButton>
        </LiveActions>
      )}
    </>
  );
};

export default SearchHeader;

import styled from 'styled-components';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import {
  COMPLIANCE_SORT_BY,
  CLICKED,
  INSIGHTS_TABLE_PAGE_SIZE,
} from 'utils/constants';
import { useText } from 'hooks';
import { Pagination } from 'components/ui/Display';
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridSortModel,
} from '@mui/x-data-grid';
import Sort from 'model/Sort';
import { getSortModel } from 'helpers/table';
import { ComplianceType } from 'model/enum/ComplianceType';
import { Loader } from 'components/ui';

const { colors, fonts, mixins } = theme;

// Styled Components

const TableWrapper = styled.div`
  ${mixins.flexColumn};
  width: 100%;
`;

const StyledDataGrid = styled(
  ({ isEmpty, width, $hasColumnDescription, columns, ...rest }) => (
    <DataGrid columns={columns} {...rest} />
  ),
)`
  &.MuiDataGrid-root {
    font-family: ${fonts.default} !important;
    border: 0;
    .MuiDataGrid-columnHeaders {
      border-bottom: 2px solid ${colors.darkGrey};
      color: ${colors.darkGrey};
      font-family: ${fonts.bold};
      width: 100%;
      > div {
        width: 100%;
      }
    }
    .MuiDataGrid-columnHeader {
      min-width: ${({ width }) => width} !important;
      max-width: ${({ width }) => width} !important;
      padding: 0 5px;
      &:focus,
      :focus-within {
        outline: 0;
      }
      .MuiDataGrid-columnSeparator {
        visibility: visible;
      }
    }
    ${({ $hasColumnDescription }) =>
      $hasColumnDescription
        ? `
      .MuiDataGrid-columnHeaderTitleContainerContent {
        overflow: auto;
        width: 90%;
      }
      `
        : `
      .MuiDataGrid-columnHeaderTitleContainer {
        white-space: unset;
      }
      .MuiDataGrid-columnHeaderTitle {
        white-space: unset;
        word-break: break-word;
        line-height: normal;
      }
    `}
    .MuiDataGrid-cellContent {
      white-space: unset;
      word-break: break-word;
      line-height: normal;
    }
    .MuiDataGrid-columnHeader--sortable .MuiDataGrid-sortIcon {
      opacity: 1;
    }
    .MuiDataGrid-iconButtonContainer {
      visibility: visible;
      width: auto;
    }
    .MuiDataGrid-columnHeader--sorted,
    .MuiDataGrid-columnHeader--sorted .MuiIconButton-root {
      color: ${colors.blue};
    }
    .MuiDataGrid-row {
      border-bottom: 1px solid ${colors.grey};
      width: 100%;
      > .MuiDataGrid-cell {
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .MuiDataGrid-cell {
      min-width: ${({ width }) => width} !important;
      max-width: ${({ width }) => width} !important;
      color: ${colors.black};
      border: 0;
      ${mixins.flexCenter};
      &:focus,
      &:focus-within,
      &:focus-visible {
        outline: 0;
      }
      > span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .MuiDataGrid-withBorder {
      border: 0;
    }
    .MuiDataGrid-footerContainer {
      display: ${({ isEmpty }) => (isEmpty ? 'none' : 'block')};
    }
    .MuiDataGrid-virtualScrollerRenderZone {
      width: 100%;
    }
    .MuiDataGrid-virtualScroller {
      overflow-x: hidden;
      overflow-y: overlay;
    }
    .last-column .MuiDataGrid-iconSeparator {
      display: none;
    }
    .MuiDataGrid-filler {
      display: none;
    }
    .MuiDataGrid-filler {
      display: none;
    }
  }
`;

const EmptyTableText = styled.div`
  background-color: ${colors.white};
  color: ${colors.grey};
  font-family: ${fonts.default};
  padding: 25px;
  ${mixins.flexFullCenter};
`;

type Props = {
  data: GridColDef[];
  columns: object[];
  total: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  sort: Sort;
  setSort: (value: Sort) => void;
  initialSort: Sort;
  analyticsData: object;
  complianceType: ComplianceType;
  isLoading: boolean;
};

// ComplianceDataTableBase
const ComplianceDataTableBase = ({
  data,
  columns,
  total,
  currentPage,
  onPageChange,
  sort,
  setSort,
  initialSort,
  analyticsData,
  complianceType,
  isLoading,
}: Props): JSX.Element => {
  const getText = useText();
  const pageSize = INSIGHTS_TABLE_PAGE_SIZE;
  const count = Math.ceil(total / pageSize);

  const getEventByField = (field: string) =>
    `${complianceType}-${COMPLIANCE_SORT_BY}-${field}-${CLICKED}`;

  return (
    <>
      <TableWrapper>
        <StyledDataGrid
          rows={data.map((row, index) => ({ ...row, id: index }))}
          columns={columns}
          isEmpty={!data?.length}
          width={`${100 / columns?.length}%`}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableExtendRowFullWidth
          disableRowSelectionOnClick
          rowsPerPageOptions={[]}
          onColumnHeaderClick={(params: GridColumnHeaderParams) =>
            Analytics.trackEvent(getEventByField(params.field), analyticsData)
          }
          sortingOrder={['asc', 'desc']}
          sortingMode='server'
          sortModel={[{ field: sort.field, sort: sort.order }]}
          onSortModelChange={(model: GridSortModel) =>
            setSort(getSortModel(sort, model[0]))
          }
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: initialSort.field,
                  sort: initialSort.order,
                },
              ],
            },
          }}
          disableColumnResize
          loading={isLoading}
          $hasColumnDescription={complianceType === ComplianceType.INSIGHTS}
          slots={{
            noRowsOverlay: () => (
              <EmptyTableText>
                {getText('insights_compliance_search_no_results')}
              </EmptyTableText>
            ),
            footer: () => {
              return (
                count > 1 && (
                  <Pagination
                    count={count}
                    page={currentPage}
                    onChange={onPageChange}
                    extraStyles={isLoading ? { visibility: 'hidden' } : {}}
                  />
                )
              );
            },
            loadingOverlay: () => <Loader />,
          }}
        />
      </TableWrapper>
    </>
  );
};

export default ComplianceDataTableBase;

import {
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle as MuiDialogTitle,
} from '@mui/material';
import { Arrow, Cross } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

const SmallDialog = styled(({ fullScreenOnSmall, margin, open, ...rest }) => (
  <MuiDialog open={open} {...rest} />
))`
  .MuiDialog-paper {
    border-radius: 0;
    margin: 0;
    align-self: flex-start;
    margin-top: ${({ margin }) => margin || 8}vh;
    width: 650px;

    ${media.sm`
    ${({ fullScreenOnSmall }) =>
      fullScreenOnSmall
        ? `
        height: 100%;
        max-height: 100%;
        margin-top: 0;
        `
        : `
        align-self: flex-start;
        margin-top: 15vh;
        max-width: 95vw;
        `}
    `}

    .MuiDialogContent-root {
      padding: 0;
    }
  }
`;

const DialogTitle = styled(MuiDialogTitle)`
  flex: 0 0 65px;
  background-color: ${colors.black};
  padding: 0 25px;
  ${mixins.flexBetween};
  ${media.sm`
    padding: 0 15px;
  `}
`;

const Left = styled.div`
  ${mixins.flexBetween};
`;

const BackIcon = styled(Arrow)`
  height: 25px;
  width: 25px;
  transform: rotate(180deg);
  margin-right: 15px;
  cursor: pointer;
  .fill-selector {
    fill: ${colors.yellow};
  }
`;

const Title = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  color: ${colors.yellow};
`;

const CrossIcon = styled(Cross)`
  height: 25px;
  width: 25px;
  cursor: pointer;
  .fill-selector {
    fill: ${colors.yellow};
  }
`;

type CommonProps = {
  children: JSX.Element;
  title?: string | JSX.Element;
  onBack?: () => void;
  topMargin?: number;
  hasCancelButton?: boolean;
  fullScreenOnSmall?: boolean;
  showTitleBar?: boolean;
};

type OpenAndCloseProps = {
  alwaysOpen?: never;
  isOpen: boolean;
  onClose: () => void;
};

type AlwaysOpenProps = { alwaysOpen: true; isOpen?: never; onClose?: never };

type ConditionalProps = OpenAndCloseProps | AlwaysOpenProps;

type Props = CommonProps & ConditionalProps;

//Modal.Small
const Small = ({
  alwaysOpen,
  isOpen,
  onClose,
  title,
  children,
  onBack,
  topMargin,
  hasCancelButton = true,
  fullScreenOnSmall = false,
  showTitleBar = false,
}: Props) => (
  <SmallDialog
    open={alwaysOpen || isOpen}
    onClose={!alwaysOpen ? onClose : null}
    fullScreenOnSmall={fullScreenOnSmall}
    margin={topMargin}
  >
    {(showTitleBar || title) && (
      <DialogTitle>
        <Left>
          {onBack && <BackIcon onClick={onBack} />}
          {title && <Title>{title}</Title>}
        </Left>
        {hasCancelButton && <CrossIcon onClick={onClose} />}
      </DialogTitle>
    )}
    <DialogContent>{children}</DialogContent>
  </SmallDialog>
);

export default Small;

import { useText } from 'hooks';
import ManageTypesBase from './ManageTypesBase';
import {
  selectGlobalLanguageSetting,
  selectJobTypeList,
  selectIsFetchingJobTypes,
  selectIsLoadingJobTypeChanges,
  selectJobTypeErrors,
} from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import RootState from 'model/State/RootState';
import {
  fetchWorkspaceJobTypes,
  resetJobTypeErrors,
  saveJobTypeChanges,
} from 'store/actions/workspace';
import { useCustomLocaleText } from 'hooks/useText';
import { Languages } from 'utils/constants';
import { WorkspaceItemStatus } from 'model/enum/WorkspaceItemStatus';
import * as Analytics from 'utils/analytics';
import { addItem, editItem } from 'helpers/workspace';
import {
  EVENT_WORKSPACE_SETTINGS_JOB_TYPES_SAVED,
  EVENT_WORKSPACE_SETTINGS_JOB_TYPE_ADDED,
  EVENT_WORKSPACE_SETTINGS_JOB_TYPE_DELETED,
  EVENT_WORKSPACE_SETTINGS_JOB_TYPE_EDITED,
} from '../../constants';
import { WorkspaceItemType } from 'model/AdminPanel/WorkspaceSettings/WorkspaceItemType';

const JobTypeSettings = (): JSX.Element => {
  const getText = useText();
  const getEnglishText = useCustomLocaleText(Languages.english.locale);
  const getSpanishText = useCustomLocaleText(Languages.spanish.locale);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const isLoadingJobTypeChanges = useSelector(selectIsLoadingJobTypeChanges);
  const jobTypeErrors = useSelector(selectJobTypeErrors);
  const dispatch = useDispatch();
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const initialJobTypes = useSelector(
    selectJobTypeList(selectedWorkspace?.uuid),
  );
  const isFetchingJobTypes = useSelector(selectIsFetchingJobTypes);
  const [jobTypes, setJobTypes] =
    useState<WorkspaceItemType[]>(initialJobTypes);

  useEffect(() => {
    dispatch(resetJobTypeErrors());
    if (selectedWorkspace?.uuid) {
      dispatch(fetchWorkspaceJobTypes(selectedWorkspace?.uuid));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setJobTypes(initialJobTypes);
  }, [initialJobTypes]);

  const onAddOrEdit = (
    isUpdate: boolean,
    index: number,
    englishText: string,
    spanishText: string,
  ) => {
    if (isUpdate && index > -1) {
      const modifiedList = editItem(
        initialJobTypes,
        jobTypes.map((workspaceJobType) => workspaceJobType),
        index,
        englishText,
        spanishText,
        EVENT_WORKSPACE_SETTINGS_JOB_TYPE_EDITED,
      );
      setJobTypes(modifiedList);
    } else {
      const modifiedList = addItem(
        jobTypes,
        englishText,
        spanishText,
        EVENT_WORKSPACE_SETTINGS_JOB_TYPE_ADDED,
      );
      setJobTypes(modifiedList);
    }
  };

  const onRemove = (index: number, id: number | null) => {
    Analytics.trackEvent(EVENT_WORKSPACE_SETTINGS_JOB_TYPE_DELETED, {
      jobType: jobTypes[index],
    });
    if (id) {
      setJobTypes(
        jobTypes.map((type) =>
          type.id === id
            ? { ...type, status: WorkspaceItemStatus.DELETED }
            : type,
        ),
      );
    } else if (index > -1) {
      setJobTypes((prevState) => prevState.filter((_, i) => i !== index));
    }
  };

  const onSubmit = () => {
    const changedFields = jobTypes.filter((type) => type?.status);
    dispatch(saveJobTypeChanges(selectedWorkspace?.uuid, changedFields));

    Analytics.trackEvent(EVENT_WORKSPACE_SETTINGS_JOB_TYPES_SAVED, {
      changed_fields: changedFields,
    });
  };

  return (
    <ManageTypesBase
      manageTitle={getText('workspace_settings_manage_job_type')}
      addEditTitle={getText('workspace_settings_add_edit_job_type')}
      noTypeText={getText('workspace_settings_no_job_type')}
      label={getEnglishText('workspace_settings_job_type_label')}
      labelEs={getSpanishText('workspace_settings_job_type_label')}
      descriptionText={getText('workspace_settings_primary_job_categories')}
      types={jobTypes}
      loading={isFetchingJobTypes}
      swapOrder={globalAppLanguage !== Languages.english.locale}
      onAddOrEditPress={onAddOrEdit}
      onSubmit={onSubmit}
      isSubmitting={isLoadingJobTypeChanges}
      hasChangedFields={jobTypes.some((type) => type?.status)}
      onRemove={onRemove}
      errors={jobTypeErrors}
    />
  );
};

export default JobTypeSettings;

import { useEffect, useState } from 'react';
import { useText } from 'hooks';
import { User as UserApi } from 'api';
import { Input } from 'components/ui';
import { useSelector } from 'react-redux';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useDebounce } from 'use-debounce';
import { TeamMemberSearchItem } from './TeamMemberSearchItem';
import { TeamMemberSelection } from './TeamMemberSelection';
import { BackendUser } from 'model/BackendUser';
import RootState from 'model/State/RootState';
import { UserRoleBackend } from 'model/enum/UserRoleBackend';
import { selectJobsView } from 'store/selectors';
import { LoadMore, SearchContainer, UserRow, Users } from './styled';

const TrackableUserRow = Analytics.withTrackEvent(UserRow);

type Props = {
  userResultCount: number;
  selectedUsers: BackendUser[];
  onUserAdd?: (user: BackendUser) => void;
  onUserRemove?: (user: BackendUser) => void;
  showCurrentDepotOnly?: boolean;
  userRole?: UserRoleBackend | null;
  isInsights?: boolean;
  disabled?: boolean;
};

export const TeamMemberSearchEdit = ({
  selectedUsers,
  userResultCount,
  onUserAdd = () => {},
  onUserRemove = () => {},
  showCurrentDepotOnly = false,
  userRole = null,
  isInsights = false,
  disabled = false,
}: Props): JSX.Element => {
  const { currentUser, selectedWorkspace, depot } = useSelector(
    (state: RootState) => state.session,
  );
  const view = useSelector(selectJobsView);
  const getText = useText();
  const [search, setSearch] = useState<string>('');
  const [searchFilter] = useDebounce(search, 600);
  const [filteredUsers, setFilteredUsers] = useState<BackendUser[]>([]);
  const [nextLink, setNextLink] = useState<string>();

  const getUsers = async (nextLinkUrl: string | null = null) => {
    let data;
    if (nextLinkUrl) {
      const result = await UserApi.getNext(nextLink);
      data = result.data;
      setFilteredUsers((currentFilteredUsers) => [
        ...currentFilteredUsers,
        ...data.results,
      ]);
    } else {
      const result = await UserApi.getAll(
        selectedWorkspace?.uuid,
        searchFilter,
        userResultCount,
        showCurrentDepotOnly ? depot?.id : null,
        userRole,
      );
      data = result.data;
      setFilteredUsers(data.results);
    }
    setNextLink(data.next);
  };

  useEffect(() => {
    (async () => {
      await getUsers();
    })();
  }, [searchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggleSelect = (selectedUser) => {
    if (selectedUsers?.map((user) => user.id).includes(selectedUser.id)) {
      onUserRemove(selectedUser);
    } else {
      onUserAdd(selectedUser);
    }
  };

  const userIdIsChecked = (id) =>
    selectedUsers?.map((user) => user.id).includes(id);

  const getRowClickEvent = (id) => {
    if (userIdIsChecked(id)) {
      return isInsights
        ? constants.EVENT_INSIGHTS_REMOVE_USER
        : constants.EVENT_JOB_REMOVE_USER;
    } else {
      return isInsights
        ? constants.EVENT_INSIGHTS_ADD_USER
        : constants.EVENT_JOB_ADD_USER;
    }
  };

  return (
    <>
      <SearchContainer>
        <Input.Text
          id='search'
          label={getText('search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          disabled={disabled}
        />
      </SearchContainer>
      <TeamMemberSelection
        selectedUsers={selectedUsers}
        onUserRemove={onUserRemove}
        emptyPlaceholder={getText('team_members_empty_selection_placeholder')}
        disabled={disabled}
      />
      <Users $hasPadding>
        {filteredUsers?.map((filteredUser) => (
          <TrackableUserRow
            key={`userRow-${filteredUser.id}`}
            event={getRowClickEvent(filteredUser.id)}
            eventProperties={{
              userId: filteredUser.id,
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            onClick={() =>
              disabled ? () => {} : handleToggleSelect(filteredUser)
            }
            $disabled={disabled}
          >
            <TeamMemberSearchItem
              filteredUser={filteredUser}
              currentUserId={currentUser.id}
              isChecked={userIdIsChecked(filteredUser.id)}
              disabled={disabled}
            />
          </TrackableUserRow>
        ))}
        {nextLink && (
          <LoadMore onClick={() => getUsers(nextLink)}>
            {getText('load_more')}
          </LoadMore>
        )}
      </Users>
    </>
  );
};

import { Hazard } from 'model/Assessment/Hazard';
import RootState from 'model/State/RootState';
import { createSelector } from 'reselect';
import { selectGlobalLanguageSetting } from '../session';
import { sortByProperty } from 'helpers/locales';
import { PropertyName } from 'utils/constants';

export const selectWorkspaceHazards = (state: RootState) =>
  state?.workspaceHazards?.hazards;
const selectHazardsByUuid = createSelector(
  selectWorkspaceHazards,
  (_: RootState, uuid: string) => uuid,
  (hazards: { [key: string]: Hazard[] }, uuid: string) => hazards?.[uuid] || [],
);
export const selectIsFetchingHazards = (state: RootState) =>
  state.workspaceHazards.isFetchingHazards;
export const selectHazards =
  (uuid: string) =>
  (state: RootState): Hazard[] =>
    selectHazardsByUuid(state, uuid);
export const selectSortedHazardsByUuid = createSelector(
  selectWorkspaceHazards,
  selectGlobalLanguageSetting,
  (_: RootState, uuid: string) => uuid,
  (
    hazards: { [key: string]: Hazard[] },
    globalAppLanguage: string,
    uuid: string,
  ) => {
    const workspaceHazards = hazards?.[uuid] || [];
    return sortByProperty(
      workspaceHazards,
      PropertyName.TITLE,
      globalAppLanguage,
    ) as Hazard[];
  },
);
export const selectSortedHazards = (uuid: string) => (state: RootState) =>
  selectSortedHazardsByUuid(state, uuid);
const selectHazardById = createSelector(
  selectWorkspaceHazards,
  (_: RootState, uuid: string) => uuid,
  (_: RootState, __: string, hazardId: number) => hazardId,
  (hazards: { [key: string]: Hazard[] }, uuid: string, hazardId: number) =>
    hazards?.[uuid]?.find((hazard: Hazard) => hazard?.id === hazardId),
);
export const selectHazard =
  (uuid: string, hazardId: number) => (state: RootState) =>
    selectHazardById(state, uuid, hazardId);
export const selectIsLoadingHazardChange = (state: RootState) =>
  state.workspaceHazards.isLoadingHazardChange;
export const selectHazardError = (state: RootState) =>
  state.workspaceHazards.error;
export const selectModifiedHazardId = (state: RootState) =>
  state.workspaceHazards.modifiedHazardId;
export const selectIsLoadingLinkingHazard = (state: RootState) =>
  state.workspaceHazards.isLoadingLinkingHazard;
const selectIsLoadingLinkingHazardByHazardId = createSelector(
  selectIsLoadingLinkingHazard,
  (_: RootState, id: number) => id,
  (isLoading: { [key: number]: boolean }, id: number) =>
    isLoading?.[id] || false,
);
export const selectIsLoadingLinkingHazardByHazard =
  (id: number) => (state: RootState) =>
    selectIsLoadingLinkingHazardByHazardId(state, id);

import { Input } from 'components/ui';
import { useText } from 'hooks';
import { useEffect } from 'react';
import Team from 'model/Team';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsFetchingTeams, selectTeams } from 'store/selectors/user';
import { fetchTeams } from 'store/actions/user';

type Props = {
  selectedTeam?: Team | null;
  handleTeamChange: (team: Team | null) => void;
};

export const TeamSelect = ({
  selectedTeam = null,
  handleTeamChange,
}: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const teams = useSelector(selectTeams);
  const isFetchingTeams = useSelector(selectIsFetchingTeams);

  useEffect(() => {
    if (!teams && !isFetchingTeams) {
      dispatch(fetchTeams());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getOptions = () => {
    const teamOptions =
      teams?.map((team) => ({
        id: team.id,
        title: team.name,
      })) ?? [];
    return [
      { id: -1, title: getText('manage_teams_modal_none_selected') },
      ...teamOptions,
    ];
  };

  return (
    <Input.Select
      id='team'
      label={getText('manage_teams_modal_select_team')}
      hint={getText('filter_data_select_hint')}
      selected={
        selectedTeam?.id
          ? { id: selectedTeam.id, title: selectedTeam.name }
          : getOptions()[0]
      }
      options={getOptions()}
      onChange={(e) => {
        const team = teams.find((item) => item.id === e?.target?.value);
        handleTeamChange(team || null);
      }}
      large
    />
  );
};

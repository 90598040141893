import styled from 'styled-components';
import theme from 'theme';
import { DownloadNoBackground } from 'assets/icons';

const { colors } = theme;

export const DownloadIcon = styled(DownloadNoBackground)`
  height: 20px;
  width: 20px;
  .fill-selector {
    fill: ${colors.black};
  }
`;

import { Radio } from '@mui/material';
import { useIsMobile } from 'hooks';
import { Option } from './styled';
import { ChangeEvent } from 'react';
import { Container, ErrorMessage, Label, StyledRadioGroup } from '../styled';

type Props = {
  options: string[];
  selected?: string;
  setSelected: (newSelected: string) => void;
  label?: string;
  error?: string;
};

export const RadioInput = ({
  options,
  selected,
  setSelected,
  label,
  error,
}: Props): JSX.Element => {
  const isMobile = useIsMobile();

  const onChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSelected(event.target.value);

  return (
    <Container>
      {label && <Label $marginRight='10px'>{label}</Label>}
      <StyledRadioGroup onChange={onChange} isMobile={isMobile}>
        {options.map((option) => (
          <Option
            key={option}
            value={option}
            control={<Radio />}
            label={option}
            labelPlacement='end'
            checked={option === selected}
          />
        ))}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </StyledRadioGroup>
    </Container>
  );
};

import { Container, Description, DescriptionContainer, Title } from './styled';

type Props = {
  title: string;
  description: string;
};

export const InsightsItem = ({ title, description }: Props): JSX.Element => {
  return (
    <Container>
      <Title>{title}</Title>
      {description && (
        <DescriptionContainer>
          <Description>{description}</Description>
        </DescriptionContainer>
      )}
    </Container>
  );
};

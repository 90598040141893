import styled from 'styled-components';
import { FyldLogoFull } from 'assets/images';
import { BrandContainer, FormContainer } from '../styled';
import theme from 'theme';

const { mixins, media, colors } = theme;

export const FyldLogo = styled(FyldLogoFull)`
  height: 55px;
  margin-bottom: ${({ bigMargin }) => {
    if (bigMargin) {
      return '100px';
    }
    return 'none';
  }};
  ${media.sm`
    height: 30px;
    margin-bottom: 0;
  `}
`;

export const Wrapper = styled.div`
  ${mixins.flexColumn}
  min-height: 100vh;
  width: 100vw;
  display: flex;
  background-color: ${colors.black};
  ${media.sm`
    flex-direction: column;
    position: relative;
  `}
`;

export const Brand = styled(BrandContainer)`
  ${mixins.flexFullCenter}
`;

export const BrandWrapper = styled(FormContainer)`
  padding: 0;
`;

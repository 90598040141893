import Filters from 'model/Filters';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeams } from 'store/actions/user';
import {
  selectActiveWorkspaceUuid,
  selectCurrentUserId,
} from 'store/selectors';
import { selectIsFetchingTeams, selectTeams } from 'store/selectors/user';
import useLocalStorage from './useLocalStorage';
import * as Analytics from 'utils/analytics';
import Team from 'model/Team';
import { MANAGE_TEAMS_CLICKED } from 'utils/constants';
import { ModalType } from 'model/enum/ModalType';
import { useNavigate } from 'react-router-dom';

const useTeams = () => {
  const currentUserId = useSelector(selectCurrentUserId);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);
  const navigate = useNavigate();
  const teams = useSelector(selectTeams);
  const isFetchingTeams = useSelector(selectIsFetchingTeams);
  const dispatch = useDispatch();
  // directly reading localStorage instead of using the useLocalStorage hook
  // because the value of that key is changed on Jobs/Insights/Fatigue
  // and we can't persist state across different hook instances
  const selectedTeamByUserAndWorkspace = JSON.parse(
    window.localStorage.getItem('selectedTeamByUserAndWorkspace') ?? '{}',
  );

  const [, setSelectedTeamByUserAndWorkspace] = useLocalStorage<{
    [userId: number]: {
      [workspaceId: string]: number | null;
    };
  }>('selectedTeamByUserAndWorkspace', {});

  const [selectedTeam, setSelectedTeam] = useState<Team | null>(() =>
    getSelectedTeam(),
  );

  useEffect(() => {
    if (!teams && !isFetchingTeams) {
      dispatch(fetchTeams());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedTeam(getSelectedTeam());
  }, [teams, ...Object.values(selectedTeamByUserAndWorkspace)]); // eslint-disable-line react-hooks/exhaustive-deps

  function getSelectedTeam() {
    if (teams && selectedTeamByUserAndWorkspace) {
      const selectedTeamsByUser =
        selectedTeamByUserAndWorkspace?.[currentUserId];
      const selectedTeamId = selectedTeamsByUser?.[selectedWorkspaceUuid];
      if (!selectedTeamId) {
        movePreviousVersionTeamToNew();
        return null;
      }
      return teams?.find((team: Team) => team.id === selectedTeamId) || null;
    }
    return null;
  }

  function setSelectedTeamForCurrentUser(teamId: number | null) {
    setSelectedTeamByUserAndWorkspace((prevTeam) => ({
      ...prevTeam,
      [currentUserId]: {
        ...prevTeam[currentUserId],
        [selectedWorkspaceUuid]: teamId,
      },
    }));
    setSelectedTeam(teams?.find((team: Team) => team.id === teamId) || null);
  }

  function movePreviousVersionTeamToNew() {
    const insightFilters: Filters | null = JSON.parse(
      window.localStorage.getItem('insightFilters') ?? '{}',
    );
    const fatigueManagerFilters: Filters | null = JSON.parse(
      window.localStorage.getItem('fatigueManagerFilters') ?? '{}',
    );
    if (insightFilters?.team?.id || fatigueManagerFilters?.team?.id) {
      setSelectedTeamForCurrentUser(
        insightFilters?.team?.id ?? fatigueManagerFilters?.team?.id ?? null,
      );
      delete insightFilters?.team;
      window.localStorage.setItem(
        'insightFilters',
        JSON.stringify(insightFilters),
      );
      delete fatigueManagerFilters?.team;
      window.localStorage.setItem(
        'fatigueManagerFilters',
        JSON.stringify(fatigueManagerFilters),
      );
    }
  }

  const openTeamsModal = (location, filters, team, parentModal) => {
    Analytics.trackEvent(MANAGE_TEAMS_CLICKED, {
      userId: currentUserId,
    });
    navigate(location.pathname + `?modal=${ModalType.TEAMS}`, {
      state: {
        ...location.state,
        ...(parentModal === ModalType.SEARCH_FILTERS
          ? { ...filters }
          : { filters: { ...filters } }),
        team,
        parentModal,
      },
    });
  };

  return { selectedTeam, setSelectedTeamForCurrentUser, openTeamsModal };
};

export default useTeams;

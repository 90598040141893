import { Input } from 'components/ui';
import { useText } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Depot } from 'model/Job/Depot';
import { useEffect } from 'react';
import { selectFormOptions, selectInsightsTab } from 'store/selectors';
import { fetchFormOptions } from 'store/actions/form';
import { useLocation } from 'react-router-dom';
import { InsightsTabKey } from 'model/enum/InsightsTabKey';

type Props = {
  selectedDepot: Depot | null;
  handleDepotChange: (depot: Depot) => void;
  isFocused?: boolean;
};

export const DepotFilter = ({
  selectedDepot,
  handleDepotChange,
  isFocused = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const getText = useText();
  const location = useLocation();

  const formOptions = useSelector(selectFormOptions);
  const activeInsightsTab = useSelector(selectInsightsTab);

  const isInsights = location?.pathname.endsWith('insights');

  const allowsAllDepots =
    !isInsights ||
    !(
      activeInsightsTab === InsightsTabKey.JOBS_PER_DAY ||
      activeInsightsTab === InsightsTabKey.OUTPUT_PER_DAY
    );

  useEffect(() => {
    if (!formOptions) {
      dispatch(fetchFormOptions());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDepotOptions = () => {
    const depots = formOptions?.depots;
    return depots?.length && allowsAllDepots
      ? [...depots, { id: -1, title: getText('fatigue_manager_all_depots') }]
      : (depots ?? []);
  };

  return (
    <Input.Select
      id='depot'
      label={getText('filter_data_depot')}
      hint={getText('filter_data_select_hint')}
      selected={selectedDepot}
      options={getDepotOptions()}
      onChange={(e) => {
        const depot = getDepotOptions().find(
          (item) => item.id === e?.target?.value,
        );
        if (depot) {
          handleDepotChange(depot);
        }
      }}
      large
      isFocused={isFocused}
    />
  );
};

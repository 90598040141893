import { Cross } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { fonts, fontSizes, media, mixins, colors } = theme;

export const Container = styled.div`
  height: 100%;
  display: flex;
  ${media.sm`
    height: auto;
    ${mixins.flexColumn};
  `}
`;

export const Form = styled.form`
  flex: 1;
  overflow: auto;
  padding: 25px;
  ${media.sm`
    padding: 0;
  `}
`;

export const InputsContainer = styled.div`
  ${media.sm`
    padding: 15px;
  `}
`;

export const ErrorMessage = styled.span`
  color: ${colors.darkRed};
  margin-bottom: 10px;
`;

export const MapPositioningContainer = styled.div<{
  $isMobile: boolean;
  $isOnMap: boolean;
}>`
  background-color: ${colors.orange};
  font-size: ${({ $isMobile }) => ($isMobile ? fontSizes.s : fontSizes.m)};
  margin: 10px 0;
  padding: 10px;
  ${({ $isOnMap }) =>
    $isOnMap &&
    `
      margin: 10px 6.25%;
      height: auto;
      width: 37.5%;
      position: absolute;
      bottom: 0;
    `}
`;

export const MapPositioningContainerTitle = styled.div`
  ${mixins.flexBetween};
  font-family: ${fonts.bold};
`;

export const CrossIcon = styled(({ $isMobile, ...props }) => (
  <Cross {...props} />
))`
  width: 20px;
  height: 20px;
  cursor: pointer;
  .fill-selector {
    fill: ${colors.black};
  }
  ${({ $isMobile }) =>
    $isMobile &&
    `
      width: 10px;
      height: 10px;
    `}
`;

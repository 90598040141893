import styled from 'styled-components';
import theme from 'theme';
import React from 'react';
import { Filters, Team as TeamIcon } from 'assets/icons';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  margin-bottom: 5px;
`;

export const ResponsiveContentWrapper = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  ${media.lg`
    width: 60%;
    justify-content: start;
  `}
  ${media.md`
    width: 80%;
  `}
`;

export const JobTableActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 50%;
  min-height: 60px;
  ${media.xxl`
    width: 60%;
  `}
  ${media.xl`
    width: 85%;
  `}
  ${media.md`
    width: 95%;
  `}

  > button,> div, > a {
    margin-left: 15px;
  }
  > :first-child {
    margin-left: 0;
  }
`;

export const ActiveFilterContainer = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 0;
  box-sizing: border-box;

  ${media.xxl`
    margin-bottom: 10px;
    padding: 8px 0;
  `}
`;

export const SimpleActiveFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
`;

export const FilterBox = styled.div`
  ${mixins.flexBetween}
  min-width: 65px;
  align-items: center;
  background-color: ${colors.white};
  outline: 2px solid ${colors.lightGrey};
  padding: 5px 15px;
  margin-left: 10px;
  margin-top: 5px;
  text-align: center;
  &:hover {
    outline-color: ${colors.black};
  }
  ${media.md`
    padding: 5px;
    margin-left: 5px;
  `}
`;

export const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: 15px;
  width: 15px;
  margin-right: 5px;
  cursor: pointer;
  .fill-selector {
    fill: ${colors.darkGrey} !important;
  }
  ${media.lg`
    height: 10px;
    width: 10px;
    min-height: 10px;
    min-width: 10px;
 `}
`;

export const FilterText = styled.span`
  color: ${colors.darkGrey};
  font-family: ${fonts.bold};
  ${media.xl`
    font-size: ${fontSizes.s};
 `}
`;

export const FiltersContainer = styled.div`
  ${mixins.flexCenter};
  justify-content: end;
`;

export const FiltersIcon = styled(Filters)`
  height: 20px;
  width: 20px;
  margin: 0;
  .fill-selector {
    fill: ${colors.black};
  }
`;

export const Form = styled.form`
  overflow-y: auto;
  min-height: 40vh;
  max-height: 80vh;
  padding: 25px 0;
  ${mixins.flexFullCenter};
`;

export const InputsContainer = styled.div`
  width: 55%;
  ${media.md`
    width: 70%;
  `}
  ${mixins.flexColumn};
  row-gap: 15px;
`;

export const ErrorMessage = styled.span`
  color: ${colors.darkRed};
`;

export const ManageTeamsContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const SearchContainer = styled.div`
  position: relative;
  margin-left: 8px;
  display: flex;
`;

export const Input = styled.input`
  line-height: 0;
  width: 200px;
  height: 44px;
  flex: 1;
  padding: 0 0 0 15px;
  outline: 2px solid ${colors.black};
  border: none;
  font-size: ${fontSizes.s};
  ${media.sm`
    border: none;
  `}
  &:hover,
  &:focus,
  &:focus-within {
    outline: 3px solid ${colors.black};
  }
`;

export const SearchIconContainer = styled.div`
  height: 44px;
  width: 44px;
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: ${colors.yellow};
  ${mixins.flexFullCenter};
`;

export const StyledSearchIcon = styled.img`
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const StyledTeamIcon = styled(TeamIcon)`
  height: 30px;
  width: 30px;
  margin: 0;
  .fill-selector {
    fill: ${colors.black};
  }
`;

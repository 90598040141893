import { UploadStatus } from 'model/enum/UploadStatus';

export type Video = {
  fileId: string;
  path: string;
  thumbnail: string;
  url: string;
  status: UploadStatus;
  transcription: string;
  summary: { [key: string]: string } | null;
  summaryText: string | null;
  videoFeedback: number | null;
  videoFeedbackReason: string;
  positiveSummaryReview: boolean | null;
  qualityWarning: string | null;
  qualityIndicator: QualityIndicator | null;
};

export type QualityIndicator = {
  siteVisibility: SiteVisibilityValue;
  soundCapture: SoundCaptureValue | null;
  videoLength: VideoLengthValue | null;
};

export enum QualityIndicatorType {
  SITE_VISIBILITY = 'siteVisibility',
  SOUND_CAPTURE = 'soundCapture',
  VIDEO_LENGTH = 'videoLength',
}

export const okValue = 'ok';

export enum SiteVisibilityValue {
  OK = 'ok',
  DARK = 'dark',
  STILL = 'still',
}

export enum SoundCaptureValue {
  OK = 'ok',
  LIMITED_SPEECH = 'limited_speech',
  NO_SPEECH = 'no_speech',
  BACKGROUND_NOISE = 'background_noise',
}

export enum VideoLengthValue {
  OK = 'ok',
  NOT_OK = 'not_ok',
}

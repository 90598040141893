import styled from 'styled-components';
import { useJobs, useText } from 'hooks';
import { Modal as StyledModal } from 'components/ui';
import { WorkOrderItemShow } from './Show';
import { useParams } from 'react-router-dom';

// Styled Components
const Container = styled.div`
  min-height: 210px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;

export const WorkOrderItemModal = () => {
  const { jobId } = useParams();
  const getText = useText();
  const { navigateToOverviewTabOrJob } = useJobs();

  return (
    <StyledModal.Base
      isOpen
      onClose={() =>
        navigateToOverviewTabOrJob(`/jobs/${jobId}/work-completed`)
      }
      title={getText('job_details_work_completed_title')}
      width='425px'
    >
      <Container>
        <WorkOrderItemShow />
      </Container>
    </StyledModal.Base>
  );
};

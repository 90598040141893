import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/ui';
import { useText } from 'hooks';
import { selectGlobalLanguageSetting, selectJobsView } from 'store/selectors';
import { Hazard } from 'model/Assessment/Hazard';
import RootState from 'model/State/RootState';
import * as constants from 'utils/constants';
import { SectionDivider } from '../SectionDivider';
import {
  AppliedControlContainer,
  AppliedControls,
  BoldText,
  BulletPoint,
  CenteredContainer,
  HazardContainer,
  HazardTitle,
  HazardTitleContainer,
  LightText,
  RatingContainer,
  TickIcon,
  WarningIcon,
} from './styled';
import { ControlRow } from './ControlRow';
import {
  setAssessmentStage,
  setEditedHazard,
} from 'store/slices/ramsAssessments';
import { FlexContainer } from '../styled';
import {
  RamsAssessments,
  RamsStages,
  ratingValues,
} from 'model/Assessment/RAMS';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

type Props = {
  hazards: Hazard[];
};

export const HazardSummaryList = ({ hazards = [] }: Props): JSX.Element => {
  const { ratings, currentStage } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const view = useSelector(selectJobsView);

  const dispatch = useDispatch();
  const getText = useText();

  const renderIcon = (postValue) =>
    postValue >= 3 ? <WarningIcon isRed /> : <TickIcon />;

  const getChangeDirection = (preValue, postValue) =>
    getText(
      postValue > preValue
        ? 'rams_summary_increased_from'
        : 'rams_summary_reduced_from',
    );

  const getRatingTitle = (value) =>
    getText(
      ratingValues.find((rating) => rating.value === value)?.title,
    ).toLocaleLowerCase();

  const getRatingByHazardId = (id) =>
    ratings.find((rating) => rating?.hazardId === id);

  const getRatingText = (preValue, postValue, name) => (
    <LightText>
      {`${name} ${getText('rams_summary_risk_rating')} `}
      {preValue !== postValue ? (
        <>
          {getChangeDirection(preValue, postValue)}
          <BoldText> {getRatingTitle(preValue)} </BoldText> {getText('to')}
          <BoldText> {getRatingTitle(postValue)}</BoldText>.
        </>
      ) : (
        <>
          <BoldText>{getRatingTitle(preValue)}</BoldText>.
        </>
      )}
    </LightText>
  );

  const onEditClick = (hazard) => {
    dispatch(setAssessmentStage(RamsStages.hazardRating));
    dispatch(setEditedHazard(hazard));

    RamsAssessments.scrollToTop();
  };

  const displayHazards = () =>
    hazards?.map((hazard, index) => {
      const rating = getRatingByHazardId(hazard.id);
      if (rating) {
        const {
          preControlRating: { severity: preSeverity, likelihood: preLikehood },
          postControlRating: {
            severity: postSeverity,
            likelihood: postLikehood,
          },
        } = rating;
        return (
          <HazardContainer key={hazard.id}>
            <HazardTitleContainer>
              <BulletPoint />
              <HazardTitle>
                {getLocalizedCamelCaseValue(hazard, 'title', globalAppLanguage)}
              </HazardTitle>
            </HazardTitleContainer>
            <AppliedControlContainer>
              <AppliedControls>
                {getText('rams_controls_applied')}
              </AppliedControls>
              <ControlRow rating={rating} isLight />
            </AppliedControlContainer>

            <RatingContainer>
              <CenteredContainer>
                {renderIcon(postSeverity)}
                {getRatingText(
                  preSeverity,
                  postSeverity,
                  getText('rams_rating_steps_severity'),
                )}
              </CenteredContainer>
              <CenteredContainer>
                {renderIcon(postLikehood)}
                {getRatingText(
                  preLikehood,
                  postLikehood,
                  getText('rams_rating_steps_likelihood'),
                )}
              </CenteredContainer>
            </RatingContainer>
            {currentStage !== RamsStages.hazardRatingSummary && (
              <FlexContainer>
                <Button.Secondary
                  onClick={() => onEditClick(hazard)}
                  text={getText('rams_summary_edit')}
                  event={
                    constants.EVENT_RAMS_SUMMARY_EDIT_HAZARD_CONTROL_BUTTON_CLICKED
                  }
                  eventProperties={{
                    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  }}
                />
              </FlexContainer>
            )}
            {hazards && index !== hazards.length - 1 && <SectionDivider />}
          </HazardContainer>
        );
      }
      return null;
    });

  return (
    <>
      {displayHazards()}
      {currentStage !== RamsStages.hazardRatingSummary && (
        <FlexContainer>
          <Button.Secondary
            text={getText('rams_summary_add_edit_hazards')}
            onClick={() =>
              dispatch(setAssessmentStage(RamsStages.hazardIdentification))
            }
            event={constants.EVENT_RAMS_SUMMARY_ADD_REMOVE_BUTTON_CLICKED}
            eventProperties={{
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
          />
        </FlexContainer>
      )}
    </>
  );
};

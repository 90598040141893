export const getTooltipData = (chart) => {
  const { tooltip } = chart;

  let selectedJobDetails = [];
  if (tooltip?.dataPoints?.length > 0) {
    const selectedIndex = tooltip.dataPoints[0]?.dataIndex;
    const dataset = tooltip.dataPoints[0]?.dataset;

    if (dataset?.jobDetails) {
      selectedJobDetails = dataset?.jobDetails[selectedIndex] ?? [];
    }
  }
  const titleLines = tooltip?.title || [];

  return {
    title: titleLines,
    data: selectedJobDetails,
  };
};

export const tooltipHandler = (context, hoverTooltipId) => {
  const { tooltip } = context;
  const tooltipEl = document.getElementById(hoverTooltipId);
  if (tooltipEl) {
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'all';

    if (tooltip.opacity === 0) {
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.opacity = 0;
      return;
    }
    tooltipEl.style.left = `${tooltip.caretX - 55}px`;
    tooltipEl.style.top = `${tooltip.caretY - 5}px`;
  }
};

export const getSelectedDetails = (chart) => {
  const { tooltip } = chart;
  let selectedDataIndex = null;
  let datasetIndex = null;
  let value = 0;
  let selectedJobDetails = [];

  if (tooltip?.dataPoints) {
    const dataset = tooltip.dataPoints[0]?.dataset;
    selectedDataIndex = tooltip.dataPoints[0]?.dataIndex;
    datasetIndex = tooltip.dataPoints[0]?.datasetIndex;
    value = dataset?.data[selectedDataIndex];
    selectedJobDetails = dataset?.jobDetails?.[selectedDataIndex] ?? [];
  }

  return {
    dataIndex: selectedDataIndex,
    datasetIndex,
    value,
    data: selectedJobDetails,
  };
};

export const getJobBlockerData = (chart) => {
  const { tooltip } = chart;

  let selectedJobDetails = [];
  if (tooltip?.dataPoints?.length > 0) {
    const selectedIndex = tooltip.dataPoints[0]?.dataIndex;

    if (tooltip.dataPoints[0]?.dataset?.jobDetails) {
      selectedJobDetails =
        tooltip.dataPoints[0]?.dataset?.jobDetails[selectedIndex] ?? [];
    }
  }
  const titleLines = tooltip?.title || [];

  return {
    title: titleLines,
    data: selectedJobDetails,
  };
};

import React from 'react';
import { useText } from 'hooks';
import UserFormAnalyticsParameter from 'model/Forms/UserFormAnalyticsParameters';
import { Photo } from './Photo';
import { PhotoCarousel } from './PhotoCarousel';
import { Video } from './Video';
import { NoMedia } from './styled';
import { QualityIndicator, Video as VideoModel } from 'model/Media/Video';
import { Photo as PhotoModel } from 'model/Media/Photo';

type Props = {
  type: string;
  kind: string;
  url?: string;
  urls?: string[];
  height?: string;
  width?: string;
  photoCover?: boolean;
  onDownload?: () => void;
  thumbnail?: string;
  isRams?: boolean;
  carouselStyle?: React.CSSProperties;
  userFormParameters?: UserFormAnalyticsParameter;
  onVideoPlayPause?: (isPlaying: boolean) => void;
  onVideoProgress?: (durationSeconds: number) => void;
  qualityIndicator?: QualityIndicator | null;
  item?: VideoModel | PhotoModel | null;
};

// Media
export const Media = ({
  type,
  url,
  urls,
  kind,
  height = '100%',
  width = '100%',
  photoCover,
  onDownload,
  thumbnail,
  isRams = false,
  carouselStyle,
  userFormParameters,
  onVideoPlayPause,
  onVideoProgress,
  qualityIndicator = null,
  item,
}: Props): JSX.Element => {
  const getText = useText();
  return (
    <>
      {!url && !urls && (
        <NoMedia height={height} width={width}>
          {getText('job_blocker_detail_no_media')}
        </NoMedia>
      )}

      {url &&
        (type === 'photo' ? (
          <Photo
            url={url}
            height={height}
            width={width}
            cover={photoCover}
            qualityIndicator={qualityIndicator}
          />
        ) : (
          <Video
            thumbnail={thumbnail}
            kind={kind}
            url={url}
            height={height}
            width={width}
            isRams={isRams}
            userFormParameters={userFormParameters}
            onVideoPlayPause={onVideoPlayPause}
            onVideoProgress={onVideoProgress}
            item={item ? (item as VideoModel) : undefined}
          />
        ))}
      {urls && type === 'photo' && (
        <PhotoCarousel
          urls={urls}
          width={width}
          onDownload={onDownload}
          extraStyles={carouselStyle}
        />
      )}
    </>
  );
};

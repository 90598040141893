import styled from 'styled-components';
import theme from 'theme';

const { media, colors } = theme;

export const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 40px 80px;
  ${media.sm`
    padding: 15px;
  `}
  background-color: ${colors.offWhite};
  box-sizing: border-box;
  overflow: auto;
`;

export const FullScreenContainer = styled.div`
  display: flex;
  height: 100%;
  ${media.sm`
    padding: 15px;
  `}
  background-color: ${colors.offWhite};
  box-sizing: border-box;
  overflow: auto;
  padding: 20px;
`;

export const LabelContainer = styled.div<{ $offWhite?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: ${({ $offWhite }) =>
    $offWhite ? colors.offWhite : colors.white};
  ${media.lg`
   padding: 16px;
`}
  row-gap: 32px;
`;

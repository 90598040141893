import { Misc } from 'api';
import { put, takeLatest } from 'redux-saga/effects';
import * as Logger from 'utils/logger';
import { FETCH_DEPOTS } from 'store/actions/actionTypes';
import { fetchDepotsError, fetchDepotsSuccess } from 'store/actions/depots';
import { setGlobalError } from 'store/slices/notifications';

function* fetchDepots({ workspaceUuid }) {
  try {
    const { data } = yield Misc.getAllDepots(workspaceUuid);
    yield put(fetchDepotsSuccess(data.results));
  } catch (err) {
    Logger.error(err);
    yield put(fetchDepotsError());
    yield put(setGlobalError(err));
  }
}

export default function* depotsWatcher() {
  yield takeLatest(FETCH_DEPOTS, fetchDepots);
}

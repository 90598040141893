import { Input } from 'components/ui';
import { getValidStatuses } from '../helpers';
import { useStatusesAndDeletedStatus } from '../hooks';
import { SelectChangeEvent } from '@mui/material';
import { Job } from 'api';
import { EVENT_JOB_STATUS_UPDATED } from '../constants';
import * as Analytics from 'utils/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActiveJob, fetchJobs } from 'store/actions/jobs';
import {
  selectActiveJob,
  selectFilters,
  selectJobsView,
} from 'store/selectors';
import { EVENT_PROPERTIES_PAGE_SOURCE_FIELD } from 'utils/constants';

type Props = {
  label?: string;
  labelColor?: string;
  large?: boolean;
  noBorder?: boolean;
};

export const StatusSelect = ({
  label,
  labelColor,
  large = false,
  noBorder = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { statuses, deletedStatus } = useStatusesAndDeletedStatus();
  const view = useSelector(selectJobsView);
  const activeJob = useSelector(selectActiveJob);
  const filters = useSelector(selectFilters);

  const handleChangeStatus = (event: SelectChangeEvent<unknown>) => {
    (async () => {
      try {
        await Job.updateDetails(activeJob?.id, {
          status: event?.target?.value,
        });
        Analytics.trackEvent(EVENT_JOB_STATUS_UPDATED, {
          jobId: activeJob?.id,
          status: event?.target?.value,
          [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        });
        dispatch(fetchActiveJob(activeJob?.id));
        if (event?.target?.value === deletedStatus?.id) {
          dispatch(fetchJobs(filters, view));
        }
      } catch (err) {
        console.error(err);
      }
    })();
  };

  return (
    <Input.Select
      id='status'
      label={label}
      labelColor={labelColor}
      selected={activeJob?.status}
      options={getValidStatuses(statuses, activeJob)}
      onChange={handleChangeStatus}
      disabled={!activeJob?.editable}
      grey
      large={large}
      noBorder={noBorder}
    />
  );
};

import { useState } from 'react';
import { Button, Input } from 'components/ui';
import * as constants from 'utils/constants';
import { useTeams, useText } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import Filters from 'model/Filters';
import { DateRangeFilter } from 'components/ui/Filter/DateRangeFilter';
import { DepotFilter } from 'components/ui/Filter/DepotFilter';
import RootState from 'model/State/RootState';
import { ShiftDurationFilter } from './ShiftDurationFilter';
import { TeamSelect } from './TeamSelect';
import { useSelector } from 'react-redux';
import Team from 'model/Team';
import { ModalType } from 'model/enum/ModalType';
import { formatMembersSelected } from 'helpers/names';
import { UserRoleBackend } from 'model/enum/UserRoleBackend';
import { Team as TeamIcon } from 'assets/icons';
import { FyldScreen } from 'model/enum/FyldScreen';
import {
  selectActiveWorkspaceUuid,
  selectInsightsTab,
  selectJobsView,
  selectUserHasPermission,
} from 'store/selectors';
import { formatDateForAnalytics } from 'helpers/dates';
import { getScreen } from 'helpers/utils';
import { selectFatigueTab } from 'store/slices/fatigue';
import { InsightsTabKey } from 'model/enum/InsightsTabKey';
import {
  ErrorMessage,
  Form,
  InputsContainer,
  ManageTeamsContainer,
} from './styled';
import theme from 'theme';

const { fontSizes } = theme;

export const FiltersModal = (): JSX.Element => {
  const getText = useText();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useSelector((state: RootState) => state.session);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);
  const view = useSelector(selectJobsView);
  const canSwitchDepot = useSelector(
    selectUserHasPermission('switch_depot_v1'),
  );
  const { depot: defaultUserDepot } = useSelector(
    (state: RootState) => state.session,
  );

  const [tempSelected, setTempSelected] = useState<Filters>({
    ...location.state?.filters,
    startDate: location.state?.filters?.startDate
      ? location.state.filters.startDate
      : null,
    endDate: location.state?.filters?.endDate
      ? location.state.filters.endDate
      : null,
    ...(location.state?.assignees && { members: location.state?.assignees }),
  } as Filters);

  const screen = getScreen(location?.pathname);
  const { selectedTeam, openTeamsModal } = useTeams();
  const initialTeam = location.state?.team ?? selectedTeam;
  const [tempTeam, setTempTeam] = useState<Team | null>(initialTeam);
  const activeTab = useSelector(
    screen === FyldScreen.INSIGHTS ? selectInsightsTab : selectFatigueTab,
  );
  const activeInsightsTab = useSelector(selectInsightsTab);

  const switchDepotEnabled =
    currentUser?.permissions?.includes('switch_depot_v1');

  const handleSubmit = async () => {
    const isTheSameTeam = initialTeam?.id === tempTeam?.id;
    navigate(location?.pathname, {
      state: {
        modifiedFilters: tempSelected,
        modifiedTeam: isTheSameTeam ? undefined : tempTeam,
        selectedWorkspaceUuid,
      },
    });
  };

  const handleChangeFilter = (filter, value) => {
    setTempSelected((prevSelected) => ({
      ...prevSelected,
      [filter]: value,
    }));
  };

  const handleDateChange = (filter, value) => {
    setTempSelected((prevSelected) => ({
      ...prevSelected,
      [filter]: value?.toString(),
    }));
  };

  const hasDateError =
    location?.state?.hasDateRangeFilter &&
    (!tempSelected.startDate || !tempSelected.endDate);

  const isInsights = location?.pathname.endsWith('insights');

  const allowsAllDepots =
    !isInsights ||
    !(
      activeInsightsTab === InsightsTabKey.JOBS_PER_DAY ||
      activeInsightsTab === InsightsTabKey.OUTPUT_PER_DAY
    );

  const depotSelectionForTabsWithMandatoryDepotIdFilter =
    canSwitchDepot && tempSelected?.depot?.id === -1
      ? defaultUserDepot
      : tempSelected?.depot;

  const depotSelection = allowsAllDepots
    ? tempSelected.depot
    : depotSelectionForTabsWithMandatoryDepotIdFilter;

  const focus = location?.state?.focus;

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <InputsContainer>
          {location?.state?.hasDateRangeFilter && (
            <>
              <DateRangeFilter
                startDate={
                  tempSelected.startDate
                    ? new Date(tempSelected.startDate)
                    : null
                }
                endDate={
                  tempSelected.endDate ? new Date(tempSelected.endDate) : null
                }
                setStartDate={(date) => handleDateChange('startDate', date)}
                setEndDate={(date) => handleDateChange('endDate', date)}
                extraStyles={hasDateError ? { marginBottom: '-10px' } : {}}
                isFocused={focus === 'date'}
              />
              {hasDateError && (
                <ErrorMessage>
                  {getText('filter_data_date_required')}
                </ErrorMessage>
              )}
            </>
          )}
          {location?.state?.hasWeekendFilter && (
            <Input.Checkbox
              checked={tempSelected.weekendIncluded}
              onChange={(e) =>
                handleChangeFilter('weekendIncluded', e.target.checked)
              }
              label={getText('filter_data_include_weekend')}
              labelPlacement='end'
              extraStyles={{ alignItems: 'start' }}
            />
          )}
          {switchDepotEnabled && (
            <DepotFilter
              selectedDepot={depotSelection}
              handleDepotChange={(depot) => handleChangeFilter('depot', depot)}
              isFocused={focus === 'depot'}
            />
          )}
          {location?.state?.hasUserFilter && (
            <>
              <TeamSelect
                selectedTeam={tempTeam}
                handleTeamChange={(team) => setTempTeam(team)}
              />
              <ManageTeamsContainer>
                <Button.Secondary
                  text={getText('manage_teams_modal_title')}
                  IconComponent={TeamIcon}
                  onClick={() =>
                    openTeamsModal(
                      location,
                      tempSelected,
                      tempTeam,
                      ModalType.FILTERS,
                    )
                  }
                  width='180px'
                  extraStyles={{
                    height: 'auto',
                    minHeight: '30px',
                    fontSize: fontSizes.default,
                  }}
                  event={constants.FILTER_MANAGE_TEAMS_CLICKED}
                  eventProperties={{
                    userId: currentUser?.id,
                    area: screen,
                    tab: activeTab,
                    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  }}
                />
              </ManageTeamsContainer>
              <Input.Link
                label={getText('jobs_filter_team_members')}
                value={formatMembersSelected(
                  tempSelected.members,
                  getText('jobs_filter_zero_selected_members'),
                  getText('jobs_filter_members_selected'),
                )}
                pathname={location.pathname}
                search={`modal=${ModalType.SEARCH_MEMBERS}`}
                state={{
                  ...location.state,
                  filters: tempSelected,
                  team: tempTeam,
                  assignees: tempSelected?.members ?? [],
                  userRoles: [
                    UserRoleBackend.FIELDWORKER,
                    UserRoleBackend.TEAMMATE,
                  ],
                }}
              />
            </>
          )}
          {location?.state?.hasShiftDurationFilter && (
            <ShiftDurationFilter
              selectedMinimumShiftDuration={tempSelected.fraType ?? null}
              handleMinimumShiftDurationChange={(minimumDuration) =>
                handleChangeFilter('fraType', minimumDuration)
              }
            />
          )}
        </InputsContainer>
      </Form>
      <Button.Primary
        text={getText('filter_data_apply_button')}
        onClick={handleSubmit}
        disabled={hasDateError}
        extraStyles={{
          position: 'sticky',
          bottom: '0',
          marginTop: 'auto',
          flex: '0 0 55px',
        }}
        event={
          screen === FyldScreen.INSIGHTS
            ? constants.INSIGHTS_FILTER_APPLY
            : constants.FATIGUE_FILTER_APPLY
        }
        eventProperties={{
          ...tempSelected,
          ...(tempTeam && { team: tempTeam, teamId: tempTeam?.id }),
          startDate: formatDateForAnalytics(tempSelected.startDate),
          endDate: formatDateForAnalytics(tempSelected.endDate),
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        }}
      />
    </>
  );
};

import styled from 'styled-components';
import theme from 'theme';
import { Select as MaterialSelect, MenuItem } from '@mui/material';
import { InfoFilled } from 'assets/icons';

const { colors, fonts, fontSizes } = theme;

export const Label = styled.label<{
  $large: boolean;
  disabled: boolean;
  $isLocation: boolean;
}>`
  font-family: ${fonts.bold};
  font-size: ${({ $large }) => ($large ? fontSizes.m : fontSizes.default)};
  color: ${({ color, disabled, $isLocation }) => {
    if (disabled && !$isLocation) {
      return colors.darkGrey;
    }
    return color || colors.black;
  }};
  margin-bottom: 10px;
  margin-left: 0;
`;

export const StyledInfoIcon = styled(InfoFilled)`
  margin-bottom: 10px;
  margin-left: 5px;
  cursor: pointer;
`;

export const RequiredLabel = styled.label`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.s};
  color: ${colors.darkGrey};
  margin-bottom: 10px;
  margin-left: auto;
`;

export const StyledSelect = styled(
  ({ disabled, $large, grey, noBorder, ...rest }) => (
    <MaterialSelect disabled={disabled} {...rest} />
  ),
)`
  &.MuiInputBase-root {
    color: ${({ disabled }) => (disabled ? colors.darkGrey : colors.black)};
    background-color: ${({ disabled }) =>
      disabled ? colors.offWhite : colors.white};
    height: ${({ $large }) => ($large ? '55px' : '40px')} !important;

    border-radius: 0;
    border: 0;
    outline: ${({ disabled, grey, noBorder }) => {
      if (disabled) {
        return `2px solid ${colors.grey}`;
      }
      if (noBorder) {
        return '0';
      }
      const color = grey ? colors.grey : colors.black;
      return `2px solid ${color} !important`;
    }};

    &:hover {
      box-shadow: none;
      border: 0;
      outline: ${({ disabled }) => {
        if (disabled) {
          return `2px solid ${colors.grey}`;
        }
        return `3px solid ${colors.black}`;
      }} !important;
      background-color: ${({ disabled }) =>
        disabled ? colors.offWhite : colors.white};
      transition: ease-out 0.2s;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
    .MuiOutlinedInput-input.Mui-disabled {
      cursor: not-allowed;
    }
    .MuiMenu-list {
      padding: 0;
    }
  }
  &.MuiInputBase-root.Mui-focused,
  &:focus-within {
    box-shadow: none;
    border: 0;
    outline: 3px solid
      ${({ disabled }) => {
        if (disabled) {
          return colors.grey;
        }
        return colors.orange;
      }} !important;
    background-color: ${({ disabled }) =>
      disabled ? colors.offWhite : colors.white};
    transition: ease-out 0.2s;
  }
`;

export const StyledOptions = styled(
  ({
    fitcontent,
    $large,
    ...rest
  }: {
    onClick?: () => void;
    value?: number;
    fitcontent?: boolean;
    $large?: boolean;
    children?: JSX.Element | string;
  }) => <MenuItem {...rest} />,
)`
  &.MuiMenuItem-root {
    white-space: break-spaces;
    height: ${({ fitcontent, $large }) => {
      if (fitcontent) {
        return '100%';
      }
      if ($large) {
        return '55px';
      }
      return '40px';
    }};
    min-height: ${({ $large }) => ($large ? '55px' : '40px')};
    padding: 0 15px;
    align-items: center;
    font-size: ${fontSizes.default};
    font-family: ${fonts.default};
    color: ${colors.black};
    &:hover,
    &.Mui-selected {
      background-color: ${colors.orange} !important;
      color: ${colors.white};
    }
  }
`;

export const SelectValue = styled.div`
  font-family: ${fonts.default};
`;

export const GuidanceWrapper = styled.div`
  margin-bottom: 10px;
`;

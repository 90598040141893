import { Dates, Utils } from 'helpers';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import { Document as DocumentIcon } from 'assets/icons';
import { Badge } from 'components/ui';
import { useText } from 'hooks';
import { Document } from 'model/Media/Document';
import {
  AnchorActionBox,
  Container,
  CreationDate,
  Description,
  DownloadIcon,
  Subtype,
  Type,
  ActionIcon,
} from './styled';

const { fontSizes } = theme;

type Props = {
  document: Document;
};

// Message.DownloadDocument
export const DownloadDocument = Analytics.withTrackEvent(
  ({ document }: Props): JSX.Element => {
    const getText = useText();

    return (
      <Container>
        <Badge.User user={document.createdBy} />

        <AnchorActionBox href={document.url}>
          <ActionIcon component={<DocumentIcon />} />
          <Description>
            <Type
              $fontSize={fontSizes.default}
              $whiteSpace='nowrap'
              $hasLineHeight={false}
            >
              {document.originalFileName}
            </Type>
            <Subtype $fontSize={fontSizes.s}>
              {document.extensionType.toUpperCase()} &bull;{' '}
              {Utils.formatBytes(document.fileSize)}
            </Subtype>
          </Description>
          <DownloadIcon />
        </AnchorActionBox>

        <CreationDate>
          {Dates.formatDateTime(
            document.createdAt,
            getText('date_today'),
            getText('date_yesterday'),
          )}
        </CreationDate>
      </Container>
    );
  },
);

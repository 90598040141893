import FraForm from 'model/FatigueManager/FraForm';
import FraFormSection from 'model/FatigueManager/FraFormSection';
import SectionField from 'model/FatigueManager/SectionField';
import { FlexColumn, Item, Label } from './styled';
import { ArrayValue, StringValue } from '../../styles';
import {
  isFormFieldVisible,
  isFormSectionVisible,
} from 'model/FatigueManager/helpers';

type Props = {
  form: FraForm;
};

export const FRAListV2: React.FC<Props> = ({ form }) => {
  return (
    <>
      {form.sections.map((section: FraFormSection, index: number) => {
        if (
          !isFormSectionVisible(
            section.config?.showFormula,
            section.config?.showSourceWidgetIds,
            form.sections,
          )
        ) {
          return null;
        }

        return (
          <FlexColumn key={index}>
            {section.fields.map((field: SectionField, index: number) => {
              if (!isFormFieldVisible(field)) {
                return null;
              }

              return (
                <Item key={index}>
                  <Label>{field.label}</Label>
                  {Array.isArray(field.value) ? (
                    <ArrayValue value={field.value} />
                  ) : (
                    <StringValue value={field.value} />
                  )}
                </Item>
              );
            })}
          </FlexColumn>
        );
      })}
    </>
  );
};

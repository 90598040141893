import { Photo } from 'model/Media/Photo';
import { QualityIndicator, Video } from 'model/Media/Video';
import { BackendVideo } from './types';
import { Reaction } from 'model/Reaction';

export const mapToPhoto = (backendPhoto: any): Photo => ({
  id: backendPhoto.id,
  fileId: backendPhoto.file_id,
  path: backendPhoto.path,
  thumbnail: backendPhoto.thumbnail,
  url: backendPhoto.url,
  status: backendPhoto.status,
});

export const mapToVideo = (backendVideo: any): Video => {
  const hasSummary = !!backendVideo?.summary;
  const isSummaryString = typeof backendVideo?.summary === 'string';
  return {
    fileId: backendVideo.file_id,
    path: backendVideo.path,
    thumbnail: backendVideo.thumbnail,
    url: backendVideo.url,
    status: backendVideo.status,
    transcription: backendVideo.transcription ?? '',
    summary: hasSummary
      ? isSummaryString
        ? null
        : backendVideo.summary
      : null,
    summaryText:
      backendVideo.summaryText ??
      (hasSummary && isSummaryString ? backendVideo.summary : null),
    videoFeedback: backendVideo.video_feedback ?? null,
    videoFeedbackReason: backendVideo.video_feedback_reason ?? '',
    positiveSummaryReview: backendVideo.positive_summary_review ?? null,
    qualityWarning: backendVideo.quality_warning ?? null,
    qualityIndicator: backendVideo?.quality_indicator
      ? mapToQualityIndicator(backendVideo.quality_indicator)
      : null,
  };
};

export const mapToQualityIndicator = (
  backendQualityIndicator: any,
): QualityIndicator => ({
  siteVisibility: backendQualityIndicator.site_visibility,
  soundCapture: backendQualityIndicator.sound_capture ?? null,
  videoLength: backendQualityIndicator.video_length ?? null,
});

export const mapToBackendVideo = (video: Video): BackendVideo => ({
  file_id: video.fileId,
  path: video.path,
  thumbnail: video.thumbnail,
  url: video.url,
  status: video.status,
  transcription: video.transcription,
  summary: video.summaryText ?? video.summary ?? undefined,
  video_feedback: video.videoFeedback ?? undefined,
  video_feedback_reason: video.videoFeedbackReason ?? undefined,
  positive_summary_review: video.positiveSummaryReview ?? undefined,
  quality_warning: video.qualityWarning ?? undefined,
});

export const mapToReaction = (backendReaction: any): Reaction => ({
  value: backendReaction.value ?? '',
  count: backendReaction.count ?? 0,
});

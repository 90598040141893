import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Tick, Pencil, RedCross } from 'assets/icons';
import { Button, Loader } from 'components/ui';
import { Pagination } from 'components/ui/Display';
import { useText } from 'hooks';
import UserDataRow from 'model/UserDataRow';
import {
  ActionButtonContainer,
  DataRow,
  StatusText,
  StyledDataGrid,
} from './styled';
import { PaginationConfig } from '../constants';
import LoginLinkButtons from '../LoginLinkButtons';
import ActivateButtons from '../ActivateButtons';
import { useSelector } from 'react-redux';
import { selectWorkspaceHasSettingEnabled } from 'store/selectors';

type Props = {
  data: UserDataRow[];
  isLoading: boolean;
  paginationConfig: PaginationConfig;
  updateUser: ({ id, is_active }) => void;
  editUser: (user: UserDataRow) => void;
};

const UsersDataGrid = ({
  data,
  isLoading,
  paginationConfig,
  updateUser,
  editUser,
}: Props): JSX.Element => {
  const getText = useText();
  const magicLinkLoginEnabled = useSelector(
    selectWorkspaceHasSettingEnabled('magic_link_login_enabled'),
  );

  const count = Math.ceil(paginationConfig.total / paginationConfig.pageSize);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: getText('users_table_name'),
      sortable: false,
      headerClassName: 'name-cell',
      cellClassName: 'name-cell',
    },
    {
      field: 'role',
      headerName: getText('users_table_permission_level'),
      sortable: false,
      headerClassName: 'role-cell',
      cellClassName: 'role-cell',
    },
    {
      field: 'depot_label',
      headerName: getText('users_table_depot'),
      sortable: false,
      headerClassName: 'depot-cell',
      cellClassName: 'depot-cell',
    },
    {
      field: 'date_joined',
      headerName: getText('users_table_date_added'),
      headerClassName: 'date-cell',
      cellClassName: 'date-cell',
      sortable: false,
    },
    {
      field: 'phone_number',
      headerName: getText('user_phone_number'),
      headerClassName: 'phone-cell',
      cellClassName: 'phone-cell',
      sortable: false,
    },
    {
      field: 'is_active',
      headerName: getText('users_table_status'),
      headerClassName: 'active-cell',
      cellClassName: 'active-cell',
      sortable: false,
      renderCell: (props: GridRenderCellParams) =>
        renderActiveMessage(props.row?.is_active),
    },
    {
      field: '',
      headerName: getText('users_table_actions'),
      sortable: false,
      headerClassName: 'actions-cell',
      cellClassName: 'actions-cell',
      renderCell: (props: GridRenderCellParams) =>
        renderActionButtons(props.row),
    },
  ];

  const renderActionButtons = (record) => (
    <ActionButtonContainer>
      <ActivateButtons user={record} updateUser={updateUser} />
      <Button.Confirm
        onClick={() => editUser(record)}
        IconComponent={Pencil}
        extraStyles={{ margin: '0 10px' }}
      />

      {magicLinkLoginEnabled && record?.is_active && !record?.is_deviceless && (
        <LoginLinkButtons user={record} />
      )}
    </ActionButtonContainer>
  );

  const renderActiveMessage = (is_active) =>
    is_active ? (
      <>
        <Tick />
        <StatusText>{getText('users_table_status_active')}</StatusText>
      </>
    ) : (
      <>
        <RedCross />{' '}
        <StatusText>{getText('users_table_status_inactive')}</StatusText>
      </>
    );

  if (!data) {
    return (
      <DataRow>
        <Loader />
      </DataRow>
    );
  }

  return (
    <DataRow>
      <StyledDataGrid
        rows={data}
        columns={columns}
        isEmpty={!data?.length}
        getRowHeight={() => 'auto'}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableExtendRowFullWidth
        disableRowSelectionOnClick
        rowsPerPageOptions={[]}
        loading={isLoading}
        slots={{
          loadingOverlay: () => <Loader />,
          footer: () => {
            return (
              count > 1 && (
                <Pagination
                  count={count}
                  page={paginationConfig.current}
                  onChange={paginationConfig.onChange}
                  extraStyles={isLoading ? { visibility: 'hidden' } : {}}
                />
              )
            );
          },
        }}
      />
    </DataRow>
  );
};

export default UsersDataGrid;

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import { Badge } from 'components/ui';
import { useText } from 'hooks';
import { BackendUser } from 'model/BackendUser';
import RootState from 'model/State/RootState';

const { fonts, fontSizes, media, mixins } = theme;

// Styled Components
const Container = styled.div`
  ${mixins.flexColumn};
`;

const Title = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 12px !important;
  ${media.sm`
    margin-bottom: 25px !important;
  `}
`;

const MembersContainer = styled.div<{ $isColumn: boolean }>`
  display: flex;
  flex-wrap: wrap;
  ${({ $isColumn }) =>
    $isColumn &&
    `
      flex-direction: column;
      > div { 
        width: 100%
      }
    `};
  ${media.sm`
    flex-direction: column;
  `}
`;

const Member = styled.div`
  margin-bottom: 15px;
  width: 50%;
  &:last-of-type {
    margin-bottom: 25px;
  }
  ${media.sm`
    width: 100%;
  `}
`;

// TeamDisplay
const TeamDisplay = ({
  members,
  isColumn = false,
}: {
  members: BackendUser[];
  isColumn?: boolean;
}): JSX.Element => {
  const getText = useText();
  const { currentUser } = useSelector((state: RootState) => state.session);
  return (
    <Container>
      <Title>{getText('modal_title_team_members')}</Title>

      <MembersContainer $isColumn={isColumn}>
        {members.map((member) => (
          <Member key={`member-${member.id}`}>
            <Badge.UserBadge
              key={`badge-${member.id}`}
              user={member}
              isCurrentUser={currentUser.id === member.id}
            />
          </Member>
        ))}
      </MembersContainer>
    </Container>
  );
};

export default TeamDisplay;

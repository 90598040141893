import styled from 'styled-components';
import theme from 'theme';
import { Cross, Info } from 'assets/icons';

const { colors, fonts } = theme;

export const Container = styled.div`
  padding: 20px;
  background-color: ${colors.darkGrey};
  display: flex;
`;

export const InfoBox = styled.div`
  display: flex;
  color: ${colors.white};
`;

export const Field = styled.div`
  font-family: ${fonts.bold};
  white-space: pre;
`;

export const Value = styled.div``;

export const Notice = styled.div`
  padding: 20px;
  color: ${colors.white};
  background-color: ${colors.blue};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InfoIcon = styled(Info)`
  width: 20px;
  height: 20px;
  background-color: ${colors.blue};
  .fill-selector {
    fill: ${colors.white};
  }
  margin-right: 10px;
`;

export const Separator = styled.div`
  width: 0px;
  height: 100%;
  border: 1px solid white;
  margin: 0 10px;
`;

export const CrossIcon = styled(Cross)`
  width: 20px;
  height: 20px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const BlankButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  cursor: pointer;
`;

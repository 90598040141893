import { AlertRed } from 'assets/icons';
import { formatDateTime } from 'helpers/dates';
import { useText } from 'hooks';
import { useLocation } from 'react-router-dom';
import { DotSeparator } from 'components/ui/DotSeparator';
import { User } from 'model/User';
import {
  AppSignOn,
  ContentWrapper,
  DigitalSignatureLink,
  IconContainer,
  NotSignedContainer,
  NotSignedText,
  SignDate,
  TickIcon,
  UserName,
} from './styled';

type Props = {
  user: User;
  notificationTime?: string;
};

export const SignedOnUser = ({
  user,
  notificationTime,
}: Props): JSX.Element => {
  const getText = useText();
  const { search } = useLocation();

  return (
    <ContentWrapper>
      <IconContainer>
        {user.signedAt ? <TickIcon /> : <AlertRed />}
      </IconContainer>
      <div>
        <UserName>{`${user.fullName}`}</UserName>
        <SignDate>
          {user.signedAt ? (
            <>
              {formatDateTime(
                user.signedAt,
                getText('date_today'),
                getText('date_yesterday'),
              )}
              <DotSeparator />
              {user?.signature ? (
                <DigitalSignatureLink
                  to={`signature/${user?.id}?${search}`}
                  state={{ user }}
                >
                  {getText('assessment_details_digital_signature')}
                </DigitalSignatureLink>
              ) : (
                <AppSignOn>
                  {getText('assessment_details_app_sign_on')}
                </AppSignOn>
              )}
            </>
          ) : (
            <NotSignedContainer>
              {user.isDeviceless && (
                <NotSignedText>{`(${getText(
                  'assessment_details_digital_signature_required',
                )})`}</NotSignedText>
              )}
              {!user.isDeviceless && notificationTime && (
                <NotSignedText>{`(${getText(
                  'assessment_details_signed_on_notified',
                )} ${notificationTime})`}</NotSignedText>
              )}
            </NotSignedContainer>
          )}
        </SignDate>
      </div>
    </ContentWrapper>
  );
};

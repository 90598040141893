import styled from 'styled-components';
import { Cross } from 'assets/icons';
import theme from 'theme';
import StyledComponentProps from 'model/StyledComponentProps';
import { TextField } from '@mui/material';

const { colors } = theme;

export const InputContainer = styled.div<StyledComponentProps>`
  display: flex;

  &:hover,
  &:focus-within {
    box-shadow: none;
    outline: 3px solid
      ${({ disabled, error }) => {
        if (disabled) {
          return colors.grey;
        }

        if (error) {
          return colors.red;
        }

        return colors.orange;
      }};
    transition: ease-out 0.2s;
  }
`;

export const StyledTextField = styled(TextField)`
  &.MuiTextField-root {
    background-color: ${colors.white};
    width: 100%;
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
`;

export const CrossIcon = styled(Cross)`
  cursor: pointer;
  height: 20px;
  .fill-selector {
    fill: ${colors.darkGrey};
  }
`;

export const CrossWrapper = styled.div`
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  border: none;

  &:focus-within {
    outline: 4px solid green;
  }
`;

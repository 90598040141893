import { Eye, SiteBriefing as SiteBriefingIcon } from 'assets/icons';
import { useQueryParams, useText } from 'hooks';
import * as Analytics from 'utils/analytics';
import { Message } from 'model/Message';
import { MessageWithAction } from './MessageWithAction';
import { formatDate } from 'helpers/dates';
import JobData from 'model/JobData';

type Props = {
  message: Message;
  activeJob: JobData;
  onClick: () => void;
};

export const SiteBriefing = Analytics.withTrackEvent(
  ({ message, activeJob, onClick }: Props): JSX.Element => {
    const getText = useText();
    const { getPathWithQueryParams } = useQueryParams();

    const to = getPathWithQueryParams(
      `/jobs/${activeJob.id}/site-briefing/${message.target.id}`,
    );

    return (
      <MessageWithAction
        message={message}
        onClick={onClick}
        to={to}
        actionIcon={<SiteBriefingIcon />}
        type={getText('job_details_timeline_action_site_briefing')}
        subtype={formatDate(message.target.created_at)}
        date={message.target.sent_at}
        viewIcon={<Eye />}
      />
    );
  },
);

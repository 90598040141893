import { BackendControl, Control, Controls } from '../Control';

export type BackendHazard = {
  id: number;
  title: string;
  title_es: string | null;
  is_major: boolean;
  mapped_controls: BackendControl[] | number[];
  assessment_type_list: number[];
  assessment_type: number[];
};

export type Hazard = {
  id: number;
  title: string;
  titleEs: string | null;
  isMajor: boolean;
  mappedControls: Control[] | null;
  mappedControlIds: number[] | null;
  assessmentTypeList: number[];
};

export type SuggestedHazardWithControl = {
  hazard: Hazard;
  controls: Control[];
};

export type HazardWithControl = SuggestedHazardWithControl & {
  suggestedControls: Control[];
  isComputerVision: boolean;
  sources: HazardSource[];
};

export type HazardWithControlsAndComments = {
  hazardWithControls: HazardWithControl[];
  otherConditions: string[];
};

export type SuggestionRequest = {
  hazards?: Hazard[];
  controls?: Control[];
  suggestedHazards?: SuggestedHazardWithControl[];
  suggestedControls?: SuggestedHazardWithControl[];
};

export type BackendHazardWithControl = {
  hazard_id: number;
  controls: number[];
};

export type BackendSuggestionRequest = {
  hazards: number[];
  controls: number[];
  suggested_hazards: BackendHazardWithControl[];
  suggested_controls: BackendHazardWithControl[];
};

export enum HazardSource {
  EDGE = 'edge',
  LOCATION = 'location',
  TRAFFIC = 'traffic',
  TRANSCRIPTION = 'transcription',
  USER = 'user',
  VISION = 'vision',
  WEATHER = 'weather',
}

const mapToHazard = (backendHazard: any): Hazard => {
  const areControlsObjects = backendHazard?.mapped_controls?.some(
    (control) => control?.id,
  );
  return {
    id: backendHazard.id,
    title: backendHazard.title,
    titleEs: backendHazard.title_es,
    isMajor: backendHazard.is_major,
    mappedControls:
      backendHazard?.mapped_controls && areControlsObjects
        ? backendHazard?.mapped_controls?.map(Controls.mapToControl)
        : null,
    mappedControlIds:
      !backendHazard?.mapped_controls || areControlsObjects
        ? null
        : backendHazard?.mapped_controls,
    assessmentTypeList:
      backendHazard?.assessment_type_list ??
      backendHazard?.assessment_type ??
      [],
  };
};

const mapToSuggestedHazardsWithControls = (
  backendSuggestedHazardsWithControls: any,
): SuggestedHazardWithControl => ({
  hazard: mapToHazard(backendSuggestedHazardsWithControls.hazard),
  controls:
    backendSuggestedHazardsWithControls?.controls?.map(Controls.mapToControl) ??
    [],
});

const mapToHazardsWithControls = (
  backendHazardsWithControls: any,
): HazardWithControl => ({
  hazard: mapToHazard(backendHazardsWithControls.hazard),
  controls:
    backendHazardsWithControls?.controls?.map(Controls.mapToControl) ?? [],
  suggestedControls:
    backendHazardsWithControls?.suggested_controls?.map(
      Controls.mapToControl,
    ) ?? [],
  isComputerVision: backendHazardsWithControls.is_computer_vision,
  sources: backendHazardsWithControls.sources,
});

const mapToHazardsWithControlsAndComments = (
  backendHazardsWithControlsAndComments: any,
): HazardWithControlsAndComments => ({
  hazardWithControls:
    backendHazardsWithControlsAndComments?.hazard_with_controls.map(
      mapToHazardsWithControls,
    ) ?? [],
  otherConditions:
    backendHazardsWithControlsAndComments?.other_conditions ?? [],
});

const mapToSuggestionRequestHazardsAndControls = (
  input: SuggestedHazardWithControl,
): BackendHazardWithControl => ({
  hazard_id: input.hazard.id,
  controls: input.controls.map((control) => control.id),
});

const mapToBackendSuggestionRequest = (
  request: SuggestionRequest,
): BackendSuggestionRequest => ({
  hazards: request?.hazards?.map((hazard) => hazard.id) ?? [],
  controls: request?.controls?.map((control) => control.id) ?? [],
  suggested_hazards: request?.suggestedHazards
    ? request.suggestedHazards.map(mapToSuggestionRequestHazardsAndControls)
    : [],
  suggested_controls: request?.suggestedControls
    ? request.suggestedControls.map(mapToSuggestionRequestHazardsAndControls)
    : [],
});

const mapToBackendHazard = (input: Hazard): BackendHazard => ({
  id: input.id,
  title: input.title,
  title_es: input.titleEs ? input.titleEs : null,
  is_major: input.isMajor,
  mapped_controls:
    (input.mappedControls
      ? input.mappedControls.map(Controls.mapToBackendControl)
      : input.mappedControlIds) ?? [],
  assessment_type_list: input.assessmentTypeList,
  assessment_type: input.assessmentTypeList,
});

const getHazardSource = (sources?: HazardSource[]): HazardSource | null => {
  if (!sources || (sources.length === 1 && sources[0] === HazardSource.USER)) {
    return null;
  }
  return sources.find((source) => source !== HazardSource.USER) ?? null;
};

export const Hazards = {
  mapToHazard,
  mapToSuggestedHazardsWithControls,
  mapToHazardsWithControls,
  mapToHazardsWithControlsAndComments,
  mapToSuggestionRequestHazardsAndControls,
  mapToBackendSuggestionRequest,
  mapToBackendHazard,
  getHazardSource,
};

import styled from 'styled-components';
import theme from 'theme';

const { mixins, fonts, fontSizes, media, colors } = theme;

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
`;

export const Avatar = styled.div`
  height: 55px;
  width: 55px;
  min-height: 55px;
  min-width: 55px;
  font-size: ${fontSizes.l};
  border-radius: 50%;
  color: ${colors.white};
  background-color: ${colors.blue};
  margin-right: 15px;
  margin-bottom: 0;
  ${mixins.flexFullCenter};
  position: relative;
  padding: 10px;

  ${media.xxl`
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    ${({ large }) =>
      large &&
      `
      height: 40px;
      width: 40px;
      min-height: 40px;
      min-width: 40px;
    `};
  `}
`;

export const BadgeText = styled.div`
  ${mixins.flexColumn};
  max-height: 50px;
  row-gap: 10px;
`;

export const BadgeName = styled.p`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  line-height: ${fontSizes.m};
`;

export const BadgeInfo = styled.p`
  color: ${colors.darkGrey};
  font-family: ${fonts.default};
  font-size: ${fontSizes.m};
  line-height: ${fontSizes.m};
`;

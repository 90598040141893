import { Evidence as EvidenceIcon } from 'assets/icons';
import { Storage } from 'aws-amplify';
import { Media as MediaUi } from 'components/ui';
import { Media } from 'helpers';
import { useQueryParams, useText } from 'hooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Analytics from 'utils/analytics';
import {
  canSeeWrapUpSummary,
  selectGlobalLanguageSetting,
} from 'store/selectors';
import { Message } from 'model/Message';
import JobData from 'model/JobData';
import { MessageWithAction } from './MessageWithAction';

import {
  ThumbnailContainer,
  Placeholder,
  ThumbnailText,
  LinearGradient,
} from './styled';
import { InsightsAvailableTag } from '../Tags/InsightsAvailableTag';

type Props = {
  message: Message;
  activeJob: JobData;
  onClick: () => void;
};

// Message.Evidence
export const Evidence = Analytics.withTrackEvent(
  ({ message, activeJob, onClick }: Props): JSX.Element => {
    const getText = useText();
    const [thumbnailURL, setThumbnailURL] = useState<string>('');
    const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
    const { getPathWithQueryParams } = useQueryParams();
    const hasWrapUpSummary = useSelector(canSeeWrapUpSummary);

    const showInsightsTag: boolean =
      hasWrapUpSummary &&
      !!message.target.videos?.length &&
      !!message.target.videos[0]?.summary;

    const thumbnail: string = Media.getThumbnail(message.target);

    useEffect(() => {
      (async () => {
        if (thumbnail) {
          const signedURL = await Storage.get(thumbnail);
          setThumbnailURL(signedURL);
        }
      })();
    }, [thumbnail]);

    const type = getText('job_details_timeline_action_evidence');
    const subtype: string =
      message.target?.[`type_${globalAppLanguage}`] || message.target.type;
    const comments = message.target.comments;
    const to = getPathWithQueryParams(
      `/jobs/${activeJob.id}/evidence/${message.target.id}`,
    );

    return (
      <MessageWithAction
        message={message}
        onClick={onClick}
        to={to}
        actionIcon={<EvidenceIcon />}
        type={type}
        subtype={subtype}
      >
        <ThumbnailContainer>
          {thumbnailURL ? (
            <MediaUi
              type='photo'
              kind='evidence'
              url={thumbnailURL}
              photoCover
              qualityIndicator={message.target?.videos[0]?.qualityIndicator}
            />
          ) : (
            <Placeholder />
          )}
          {comments && <LinearGradient />}

          <ThumbnailText>{comments && comments.substring(0, 60)}</ThumbnailText>
        </ThumbnailContainer>
        {showInsightsTag && <InsightsAvailableTag />}
      </MessageWithAction>
    );
  },
);

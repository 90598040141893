import { Cross } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { media, colors, fontSizes, mixins, fonts } = theme;

export const InputsContainer = styled.div`
  padding: 25px;
  ${media.sm`
    padding: 15px;
  `}
`;

export const ErrorMessage = styled.div`
  color: ${colors.darkRed};
  margin-bottom: 10px;
`;

export const MapPositioningContainer = styled.div<{ isMobile: boolean }>`
  background-color: ${colors.orange};
  font-size: ${(props) => (props.isMobile ? fontSizes.s : fontSizes.m)};
  margin: 10px 0;
  padding: 10px;
`;

export const MapPositioningContainerTitle = styled.div`
  ${mixins.flexBetween};
  font-family: ${fonts.bold};
`;

export const CrossIcon = styled(({ isMobile, ...props }) => (
  <Cross {...props} />
))`
  width: ${({ isMobile }) => (isMobile ? 10 : 20)}px;
  height: ${({ isMobile }) => (isMobile ? 10 : 20)}px;
  cursor: pointer;
  .fill-selector {
    fill: ${colors.black};
  }
`;

import { Field } from 'model/Forms/Field';
import { UnorderedList } from 'components/ui/Lists/UnorderedList';

const MultiSelectCustomText = ({ field }: { field: Field }): JSX.Element => (
  <UnorderedList
    title={field.title}
    items={field.value.map((value, index) => ({
      id: index,
      title: value,
    }))}
    extraHeaderStyle={{ margin: 0, paddingBottom: '11px' }}
  />
);

export default MultiSelectCustomText;

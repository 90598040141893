import { Button } from 'components/ui';
import { useLocation } from 'react-router-dom';
import { Field } from 'model/Forms/Field';
import * as constants from 'utils/constants';

type Props = {
  field: Field;
  formId?: string;
};

const InstructionWidget = ({ field, formId }: Props): JSX.Element => {
  const { search } = useLocation();

  return (
    <Button.SecondaryLink
      text={field.title}
      to={`/jobs/insights/forms/${formId}/instruction/${field.id}${search}`}
      event={constants.INSTRUCTION_WIDGET_CLICKED}
    />
  );
};

export default InstructionWidget;

import { useText } from 'hooks';
import { Button, Modal } from 'components/ui';
import {
  Container,
  FormWrapper,
  ModalTitle,
  NotLastSeparator,
  PaddedContainer,
  SectionsWrapper,
} from './styled';
import { FatigueLiveRowItem } from 'model/FatigueManager/FatigueLiveRowItem';
import { Fragment, useCallback, useState } from 'react';
import { Section } from './components/Section';
import { Separator } from 'components/ui/Misc';
import { FatigueUserBadge } from './components/FatigueUserBadge';
import FraForm from 'model/FatigueManager/FraForm';
import { submitFatigueRiskAssessmentForm } from 'api/Fatigue';
import {
  isFraFormValid,
  updateFormAndToggleSectionVisibility,
} from 'model/FatigueManager/helpers';
import { parseForm } from 'api/Fatigue/helpers';
import { FormByFraType } from './constants';

type Props = {
  selectedFatigueLiveRowItem: FatigueLiveRowItem;
  index: number;
  setVisible: (value: boolean) => void;
  onSuccess: (form: FraForm) => void;
};

export const NewFatigueRiskAssessmentModal = ({
  selectedFatigueLiveRowItem,
  index,
  setVisible,
  onSuccess,
}: Props): JSX.Element => {
  const getText = useText();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayErrors, setDisplayErrors] = useState<boolean>(false);

  const { id, fraType } =
    selectedFatigueLiveRowItem.fatigueRiskAssessments[index];

  const [form, setForm] = useState<FraForm>(() =>
    parseForm(FormByFraType[fraType]),
  );

  const onSubmit = async () => {
    setIsLoading(true);
    setDisplayErrors(false);
    if (isFraFormValid(form)) {
      await submitFatigueRiskAssessmentForm(id, form, new Date());
      onSuccess(form);
      setVisible(false);
    }
    setDisplayErrors(true);
    setIsLoading(false);
  };

  const updateFieldForSection = useCallback(
    (sectionIndex: number, fieldId: string, fieldnewFieldValue: string) =>
      setForm((prevForm: FraForm) => {
        const section = prevForm.sections[sectionIndex];
        return updateFormAndToggleSectionVisibility(
          prevForm,
          {
            ...section,
            fields: section.fields.map((field) => {
              if (field.id === fieldId) {
                return { ...field, value: fieldnewFieldValue };
              }
              return field;
            }),
          },
          sectionIndex,
        );
      }),
    [],
  );

  const updateCustomUserInputFieldForSection = useCallback(
    (sectionIndex: number, fieldId: string, fieldnewFieldValue: string) =>
      setForm((prevForm: FraForm) => {
        const section = prevForm.sections[sectionIndex];

        return updateFormAndToggleSectionVisibility(
          prevForm,
          {
            ...section,
            fields: section.fields.map((field) => {
              if (field.id === fieldId) {
                return { ...field, customUserInputValue: fieldnewFieldValue };
              }
              return field;
            }),
          },
          sectionIndex,
        );
      }),
    [],
  );

  return (
    <Modal.Base
      isOpen
      title={getText('fatigue_manager_fatigue_risk_assessment_modal_title')}
      onClose={() => {
        setVisible(false);
      }}
      width='60vw'
    >
      <Container>
        <FormWrapper>
          <PaddedContainer>
            <ModalTitle>
              {getText('fatigue_manager_fatigue_risk_assessment_modal_title')}
            </ModalTitle>
            {getText('fatigue_manager_fatigue_risk_assessment_modal_subtitle')}
            <Separator height={'10px'} />
            <FatigueUserBadge user={selectedFatigueLiveRowItem.user} />
            <Separator height={'10px'} />
            <SectionsWrapper>
              {form.sections.map((section, index) =>
                section.config.isShowing ? (
                  <Fragment key={`section-${section.order}`}>
                    <Section
                      section={section}
                      sectionIndex={index}
                      formId={id}
                      allFormSections={form.sections}
                      updateFieldForSection={updateFieldForSection}
                      updateCustomUserInputFieldForSection={
                        updateCustomUserInputFieldForSection
                      }
                      displayErrors={displayErrors}
                    />
                    <NotLastSeparator height={'10px'} />
                  </Fragment>
                ) : null,
              )}
            </SectionsWrapper>
          </PaddedContainer>
        </FormWrapper>
        <Button.Primary
          onClick={onSubmit}
          loading={isLoading}
          text={getText('fatigue_manager_fatigue_risk_assessment_modal_cta')}
          width='100%'
          extraStyles={{ marginTop: '0', flex: '0 0 55px' }}
        />
      </Container>
    </Modal.Base>
  );
};

import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';

import { Item } from '../Item';

type SortableItemProps = {
  id: UniqueIdentifier;
  title: string;
  subtitle: string | string[];
  index: number;
  onEdit?: (id: UniqueIdentifier) => void;
  onRemove?: (id: UniqueIdentifier) => void;
  handle: boolean;
  useDragOverlay?: boolean;
  disabled: boolean;
};

export function SortableItem({
  handle,
  id,
  title,
  subtitle,
  index,
  onEdit,
  onRemove,
  useDragOverlay,
  disabled,
}: SortableItemProps) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });

  return (
    <Item
      ref={setNodeRef}
      title={title}
      subtitle={subtitle}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handleProps={
        handle
          ? {
              ref: setActivatorNodeRef,
            }
          : undefined
      }
      index={index}
      onEditClick={onEdit ? () => onEdit(id) : undefined}
      onRemoveClick={onRemove ? () => onRemove(id) : undefined}
      transform={transform}
      transition={transition}
      listeners={listeners}
      data-index={index}
      data-id={id}
      dragOverlay={!useDragOverlay && isDragging}
      disabled={disabled}
      {...attributes}
    />
  );
}

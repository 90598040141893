import { Button as MaterialButton } from '@mui/material';
import { Cross } from 'assets/icons';
import {
  WalkthroughLegacy,
  WalkthroughList,
  WalkthroughPin,
  WalkthroughStatBoxes,
  WalkthroughTeams,
} from 'assets/images';
import { Button } from 'components/ui';
import { useJobs, useLocalStorage, useText } from 'hooks';
import RootState from 'model/State/RootState';
import { JobSort } from 'model/enum/JobSort';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TourBase from 'reactour';
import {
  setFeaturesGuideCurrentStep,
  setFeaturesGuideStatus,
} from 'store/actions/featuresGuide';
import { setJobsTab } from 'store/actions/versions';
import { selectJobsView, selectTab } from 'store/selectors';
import {
  selectFeaturesGuideCurrentStep,
  selectIsFeaturesGuideOpen,
} from 'store/selectors/featuresGuide';
import styled from 'styled-components';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';

const { media, colors, fonts, fontSizes, mixins } = theme;

// Styled Components
const Title = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  margin-bottom: 20px;
  color: inherit;
`;

const Tour = styled(TourBase)`
  div[data-tour-elem='controls'] {
    justify-content: end;
  }
`;
const PrevButton = <></>;

const NextButton = styled(MaterialButton)`
  &.MuiButtonBase-root {
    box-sizing: border-box;
    border-radius: 0;
    border: none;
    outline: 2px solid ${colors.black};
    font-family: ${fonts.bold};
    font-size: ${fontSizes.s};
    text-transform: none;
    letter-spacing: 0;
    align-self: flex-end;
    background-color: ${colors.yellow} !important;
    color: ${colors.black};
    &:focus {
      outline: 2px solid ${colors.black};
    }
    &:hover {
      outline: 3px solid ${colors.black};
    }
    &.Mui-disabled {
      outline: 2px solid ${colors.grey};
      background-color: ${colors.lightGrey} !important;
      color: ${colors.black};
      &:hover {
        outline: 2px solid ${colors.grey};
        background-color: ${colors.lightGrey} !important;
      }
    }

    ${media.sm`
      width: 100%;
      margin-top: auto;      
    `}

    ${media.xl`
    height: 45px;
    font-size: ${fontSizes.s};

  `}
  }
`;

const DescriptionWrapper = styled.div`
  ${mixins.flexColumn}
  align-items: flex-start;
  margin-bottom: 20px;

  > div {
    margin-bottom: 10px;
  }
`;

const BulletList = styled.div`
  ${mixins.flexColumn}
  padding-left: 25px;
  align-self: center !important;
`;

const BulletPointIcon = styled.img`
  margin-right: 20px;
`;

const BulletPoint = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  background-color: ${colors.black};
  color: ${colors.yellow};
  padding: 20px 20px 10px 20px;
  ${mixins.flexBetween}
`;

const CloseButton = styled(MaterialButton)`
  padding: 0 !important;
  min-width: 30px;
  border-radius: 0;
  border: none;
`;

const CloseIcon = styled(Cross)`
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
  .fill-selector {
    fill: ${colors.yellow};
  }
`;

const Content = styled.div`
  padding: 42px 32px;
  ${mixins.flexColumn}
  align-items: center;
`;

const Steps = {
  INTRO: 'intro',
  TEAM: 'team',
  STAT_BOXES: 'stat-boxes',
  PIN: 'pin',
  LEGACY: 'legacy',
  INFO: 'info',
};
export const FeaturesGuide = () => {
  const tab = useSelector(selectTab);
  const { filters } = useSelector((state: RootState) => state.jobs);
  const isOpen = useSelector(selectIsFeaturesGuideOpen);
  const currentStep = useSelector(selectFeaturesGuideCurrentStep);
  const [previouslySeen, setPreviouslySeen] = useLocalStorage<boolean>(
    'walkthrough-viewed',
    false,
  );
  const view = useSelector(selectJobsView);
  const eventProperties = {
    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
  };

  const { setFilters } = useJobs();

  let originalFilters = useRef(filters);
  let isTourEndConfirmed = useRef(false);

  const dispatch = useDispatch();
  const getText = useText();

  useEffect(() => {
    if (!previouslySeen) {
      dispatch(setFeaturesGuideStatus(true));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isOpen) {
      originalFilters.current = filters;
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const onNextClick = () => {
    const step = steps[currentStep];
    if (step?.event) {
      Analytics.trackEvent(step.event, eventProperties);
    }
  };

  const onLastNextClick = () => {
    isTourEndConfirmed.current = true;
    Analytics.trackEvent(
      constants.EVENT_WALKTHROUGH_TOUR_END_CONFIRM_CLICKED,
      eventProperties,
    );
  };

  const onRequestClose = () => {
    if (!isTourEndConfirmed?.current) {
      Analytics.trackEvent(constants.EVENT_WALKTHROUGH_POP_UP_CLICKED, {
        dismissed: true,
        [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      });
    }
    dispatch(setFeaturesGuideStatus(false));
    dispatch(setFeaturesGuideCurrentStep(0));
    setPreviouslySeen(true);
    isTourEndConfirmed.current = false;
  };

  const bulletPoints = [
    { icon: WalkthroughTeams, textKey: 'jobs_walkthrough_bullet_point_teams' },
    { icon: WalkthroughList, textKey: 'jobs_walkthrough_bullet_point_list' },
    { icon: WalkthroughPin, textKey: 'jobs_walkthrough_bullet_point_pin' },
    {
      icon: WalkthroughLegacy,
      textKey: 'jobs_walkthrough_bullet_point_legacy',
    },
    {
      icon: WalkthroughStatBoxes,
      textKey: 'jobs_walkthrough_bullet_point_stat_boxes',
    },
  ];

  const steps = [
    {
      id: Steps.INTRO,
      style: { padding: '0', maxWidth: '80%', minWidth: '50%' },
      content: ({ goTo }) => (
        <>
          <Header>
            <Title>{getText('jobs_walkthrough_title')}</Title>
            <CloseButton onClick={onRequestClose}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Content>
            <div>
              <DescriptionWrapper>
                <div>{getText('jobs_walkthrough_intro_1')}</div>
                <div>{getText('jobs_walkthrough_intro_2')}</div>
              </DescriptionWrapper>
              <BulletList>
                {bulletPoints.map((point, index) => (
                  <BulletPoint key={`bullet-point-${index + 1}`}>
                    <BulletPointIcon src={point.icon} alt='' />
                    {getText(point.textKey)}
                  </BulletPoint>
                ))}
              </BulletList>
            </div>
          </Content>
          <Button.Primary
            onClick={() => {
              Analytics.trackEvent(constants.EVENT_WALKTHROUGH_POP_UP_CLICKED, {
                dismissed: false,
                [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
              });
              goTo(1);
            }}
            text={getText('jobs_walkthrough_cta')}
            extraStyles={{ marginTop: 0 }}
          />
        </>
      ),
    },
    {
      id: Steps.TEAM,
      event: constants.EVENT_WALKTHROUGH_TEAM_FILTER_CONFIRM_CLICKED,
      style: { padding: '20px', width: '200px' },
      selector: '.walkthrough-step-team',
      content: getText('jobs_walkthrough_step_team'),
    },
    {
      id: Steps.STAT_BOXES,
      event: constants.EVENT_WALKTHROUGH_STAT_BOX_CONFIRM_CLICKED,
      style: { padding: '20px', width: '200px' },
      position: 'bottom',
      selector: '.walkthrough-step-stat-boxes',
      content: (
        <div>
          <div>{getText('jobs_walkthrough_step_stat_boxes_1')}</div>
          <div>{getText('jobs_walkthrough_step_stat_boxes_2')}</div>
          <div>{getText('jobs_walkthrough_step_stat_boxes_3')}</div>
        </div>
      ),
    },
    {
      id: Steps.PIN,
      event: constants.EVENT_WALKTHROUGH_PIN_JOBS_CONFIRM_CLICKED,
      style: { padding: '20px', width: '200px' },
      selector: '.walkthrough-step-pin',
      content: getText('jobs_walkthrough_step_pin'),
    },
    {
      id: Steps.LEGACY,
      event: constants.EVENT_WALKTHROUGH_SWITCH_VIEW_CONFIRM_CLICKED,
      style: { padding: '20px', width: '200px' },
      selector: '.walkthrough-step-legacy',
      content: (
        <div>
          <div>{getText('jobs_walkthrough_step_legacy_1')}</div>
          <div>{getText('jobs_walkthrough_step_legacy_2')}</div>
        </div>
      ),
    },
    {
      id: Steps.INFO,
      event: constants.EVENT_WALKTHROUGH_TOUR_END_CONFIRM_CLICKED,
      style: { padding: '20px', width: '300px' },
      selector: '.walkthrough-step-info',
      content: (
        <div>
          <Title>{getText('jobs_walkthrough_final_1')}</Title>
          <div>{getText('jobs_walkthrough_final_2')}</div>
        </div>
      ),
    },
  ];

  const onStepChange = (currentStep: number) => {
    if (
      currentStep === steps.findIndex((step) => step.id === Steps.STAT_BOXES)
    ) {
      if (filters.q) {
        originalFilters.current = filters;
        setFilters({ ...filters, q: '' });
      }
      if (!filters.scope) {
        originalFilters.current = filters;
        setFilters({ ...filters, scope: JobSort.USER });
      }
    }
    if (currentStep === steps.findIndex((step) => step.id === Steps.PIN)) {
      if (tab && tab !== constants.LIST_VIEW_TAB) {
        originalFilters.current = {
          ...originalFilters.current,
          tab: constants.MAP_VIEW_TAB,
        };
        dispatch(setJobsTab(constants.LIST_VIEW_TAB));
      }
    }
    if (currentStep === steps.findIndex((step) => step.id === Steps.LEGACY)) {
      if (originalFilters.current.tab && originalFilters.current.tab !== tab) {
        dispatch(setJobsTab(originalFilters.current.tab));
      }
    }

    dispatch(setFeaturesGuideCurrentStep(currentStep));
  };

  const revertToOriginalFilters = () => {
    if (
      originalFilters.current.scope !== filters.scope ||
      originalFilters.current.q !== filters.q
    ) {
      setFilters({
        ...filters,
        scope: originalFilters.current.scope,
        q: originalFilters.current.q,
      });
    }
  };

  const maskSpace =
    currentStep === steps.findIndex((step) => step.id === Steps.LEGACY) ||
    currentStep === steps.findIndex((step) => step.id === Steps.INFO)
      ? 5
      : 10;

  return (
    <Tour
      startAt={currentStep}
      steps={steps}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      prevButton={PrevButton}
      nextButton={
        <NextButton onClick={onNextClick}>
          {getText('jobs_walkthrough_step_cta')}
        </NextButton>
      }
      lastStepNextButton={
        <NextButton onClick={onLastNextClick}>
          {getText('jobs_walkthrough_step_cta')}
        </NextButton>
      }
      showNumber={false}
      showCloseButton={false}
      showNavigation={false}
      showButtons={currentStep !== 0}
      getCurrentStep={onStepChange}
      onBeforeClose={revertToOriginalFilters}
      maskSpace={maskSpace}
      disableInteraction
    />
  );
};

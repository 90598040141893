import styled from 'styled-components';
import { Loader } from 'components/ui';
import { useText } from 'hooks';
import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { getJobBlockerCombinedTitle } from 'helpers/names';
import InsightSummaryItem from './InsightSummaryItem';
import InsightStat from 'model/Insight/InsightStat';
import { InsightJobBlockerStat } from 'model/Insight/InsightJobBlockerStat';

// Styled Components
const Container = styled.div`
  padding: 16px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  justify-content: center;
  grid-column-gap: 42px;
  grid-row-gap: 42px;
  width: 100%;
`;

const JobBlockerWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

type Props = {
  statsData: InsightStat;
  isOutputPerDay?: boolean;
};

// InsightSummary
const InsightSummary = ({ statsData, isOutputPerDay }: Props): JSX.Element => {
  const getText = useText();

  return (
    <Container>
      {!statsData && <Loader />}
      {statsData && !isOutputPerDay && (
        <>
          <InsightSummaryItem
            title={getText('insights_graph_average_jobs_per_day_for_region', {
              regionName: statsData.depot_title,
            })}
            previousData={statsData.previous_period.average_jobs_per_day_depot}
            currentData={statsData.current_period.average_jobs_per_day_depot}
          />
          <InsightSummaryItem
            title={getText('insights_graph_average_jobs_per_day_for_region', {
              regionName: statsData.region_title,
            })}
            previousData={statsData.previous_period.average_jobs_per_day_region}
            currentData={statsData.current_period.average_jobs_per_day_region}
          />
          <InsightSummaryItem
            title={getText('insights_graph_jobs_total')}
            previousData={statsData.previous_period.total_number_of_jobs_depot}
            currentData={statsData.current_period.total_number_of_jobs_depot}
          />
          <InsightSummaryItem
            totalJobNumber={statsData.current_period.total_number_of_jobs_depot}
            title={getText('insights_graph_jobs_percent')}
            previousData={
              statsData.previous_period.total_number_of_jobs_with_output_depot
            }
            currentData={
              statsData.current_period.total_number_of_jobs_with_output_depot
            }
          />
        </>
      )}

      {statsData && isOutputPerDay && (
        <>
          <InsightSummaryItem
            title={getText('insights_graph_average_meters_per_day_for_region', {
              regionName: statsData.depot_title,
            })}
            previousData={
              statsData.previous_period.average_meters_per_day_depot
            }
            currentData={statsData.current_period.average_meters_per_day_depot}
          />
          <InsightSummaryItem
            title={getText('insights_graph_average_meters_per_day_for_region', {
              regionName: statsData.region_title,
            })}
            previousData={
              statsData.previous_period.average_meters_per_day_region
            }
            currentData={statsData.current_period.average_meters_per_day_region}
          />
          <InsightSummaryItem
            title={getText(
              'insights_graph_average_services_per_day_for_region',
              { regionName: statsData.depot_title },
            )}
            previousData={
              statsData.previous_period.average_services_per_day_depot
            }
            currentData={
              statsData.current_period.average_services_per_day_depot
            }
          />
          <InsightSummaryItem
            totalJobNumber={statsData.current_period.total_number_of_jobs_depot}
            title={getText('insights_graph_jobs_percent')}
            previousData={
              statsData.previous_period.total_number_of_jobs_with_output_depot
            }
            currentData={
              statsData.current_period.total_number_of_jobs_with_output_depot
            }
          />
        </>
      )}
    </Container>
  );
};

// InsightJobBlockerSummary
const InsightJobBlockerSummary = ({
  statsData,
}: {
  statsData: InsightJobBlockerStat[];
}): JSX.Element => {
  const getText = useText();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  return (
    <JobBlockerWrapper>
      <Container>
        {!statsData && <Loader />}
        {statsData?.map((stat, index) => (
          <InsightSummaryItem
            isJobBlocker
            key={`job-blocker-summary-item-${index}-${stat.blocker.id}`} // eslint-disable-line react/no-array-index-key
            title={`${index + 1}. ${getText('insights_graph_total_delay', {
              regionName: getJobBlockerCombinedTitle(
                stat,
                globalAppLanguage,
              ).toLowerCase(),
            })}`}
            currentData={stat.timeLostCurrentPeriod}
            previousData={stat.timeLostPreviousPeriod}
          />
        ))}
      </Container>
    </JobBlockerWrapper>
  );
};

export { InsightSummary, InsightJobBlockerSummary };

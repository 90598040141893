import styled from 'styled-components';
import { Button as MaterialButton } from '@mui/material';
import theme from 'theme';

const { colors, fonts } = theme;

export const Button = styled(
  ({ width, minWidth, disabled, style, ...rest }) => (
    <MaterialButton style={style} disabled={disabled} {...rest} />
  ),
)`
  &.MuiButtonBase-root {
    margin: ${({ style }) => style?.margin ?? ''};
    text-transform: none;
    font-family: ${({ style }) =>
      style?.fontFamily ? style.fontFamily : fonts.bold};
    width: ${({ width }) => width ?? 'fit-content'};
    min-width: ${({ minWidth }) => minWidth ?? 0};
    color: ${({ variant }) =>
      variant === 'blue' ? colors.white : colors.black};
    ${({ variant }) =>
      variant === 'blue'
        ? `background-color: ${colors.blue};`
        : `text-decoration: underline;`}

    &:hover {
      background-color: ${({ variant }) =>
        variant === 'blue' ? colors.darkBlue : colors.lightGrey};
      text-decoration: ${({ variant }) =>
        variant === 'blue' ? 'none' : 'underline'};
    }
  }
`;

import * as constants from 'utils/constants';

export const TabKeys = {
  JOB_DETAILS: 'job-details',
  HAZARDS_CONTROLS: 'hazards-controls',
  ASSESSMENTS: 'assessments',
  EVIDENCE: 'evidence',
  JOB_BLOCKERS: 'blockers',
  DOCUMENTS: 'documents',
  WORK_COMPLETED: 'work-completed',
  PERMITS: 'permits',
  LOGS: 'logs',
};

export const EventByTab = {
  [TabKeys.JOB_DETAILS]: constants.EVENT_VIEWED_JOB_DETAILS,
  [TabKeys.HAZARDS_CONTROLS]: constants.EVENT_VIEWED_HAZARDS_CONTROLS_LIST,
  [TabKeys.ASSESSMENTS]: constants.EVENT_VIEWED_RISK_ASSESSMENTS_LIST,
  [TabKeys.EVIDENCE]: constants.EVENT_VIEWED_JOB_EVIDENCE_LIST,
  [TabKeys.JOB_BLOCKERS]: constants.EVENT_VIEWED_JOB_BLOCKER_LIST,
  [TabKeys.DOCUMENTS]: constants.EVENT_VIEWED_DOCUMENTS_LIST,
  [TabKeys.WORK_COMPLETED]: constants.EVENT_VIEWED_WORK_COMPLETED_LIST,
  [TabKeys.PERMITS]: constants.EVENT_VIEWED_PERMITS_LIST,
  [TabKeys.LOGS]: constants.EVENT_VIEWED_LOG_LIST,
};

export const EVENT_JOB_STATUS_UPDATED = 'job-status-updated';

import {
  FormGroup,
  FormControlLabel,
  Switch as MaterialSwitch,
} from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, shadows } = theme;

export const StyledFormGroup = styled(FormGroup)`
  &.MuiFormGroup-root {
    width: 100%;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0;

    .MuiFormControlLabel-label {
      font-size: ${fontSizes.s};
      width: 100%;
    }

    .MuiTypography-root {
      font-family: ${fonts.default};
    }
  }
`;

export const StyledSwitch = styled(({ disabled, checked, ...rest }) => (
  <MaterialSwitch disabled={disabled} checked={checked} {...rest} />
))`
  &.MuiSwitch-root {
    width: 42px;
    height: 22px;
    padding: 0;
    .MuiSwitch-switchBase {
      padding: 2px;
      .Mui-checked {
        transform: 'translateX(12px)';
      }
      .Mui-disabled {
        cursor: not-allowed;
      }
      &::active {
        .Mui-checked {
          transform: 'translateX(9px)';
        }
      }
    }
    .MuiSwitch-thumb {
      color: ${colors.white};
      box-shadow: ${shadows.default};
      width: 18px;
      height: 18px;
      border-radius: 50%;
      &::active {
        width: 15px;
      }
    }
    .MuiSwitch-track {
      background-color: ${({ checked, disabled }) =>
        !checked || disabled ? colors.grey : colors.blue} !important;
      border-radius: 15px;
      opacity: 1 !important;
      box-sizing: border-box;
    }
    .MuiTypography-root {
      font-size: ${fontSizes.s};
    }
  }
`;

import { memo, useEffect, useState } from 'react';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import {
  MarkerYellow,
  MarkerOrange,
  MarkerRed,
  MarkerGrey,
  MarkerYellowIcon,
  MarkerOrangeIcon,
  MarkerRedIcon,
  MarkerGreyIcon,
} from 'assets/icons';
import { useText } from 'hooks';
import Coordinate from 'model/Coordinate';
import { checkIfGoogleMapsLoaded, getDefaultMapSettings } from 'helpers/map';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalError } from 'store/slices/notifications';
import { MapInteractionType, PageSource } from 'components/ui/Map/constants';
import { selectJobsView } from 'store/selectors';
import { CommandCenterJobType } from 'model/CommandCenter/JobTypes';
import {
  LegendContainer,
  LegendRow,
  LegendTextWrapper,
  LegendTitle,
  MapContainer,
  MapWrapper,
} from './styled';

const mapPadding = 20;

type Props = {
  coordinates: Coordinate[];
  mapCenter?: google.maps.LatLng | null;
  setMapCenter?: (value: google.maps.LatLng | null) => void;
  mapZoom?: number;
  setMapZoom?: (value: number) => void;
};

export const ControlCenterMap = memo(
  ({
    coordinates,
    mapCenter,
    setMapCenter = () => {},
    mapZoom,
    setMapZoom = () => {},
  }: Props): JSX.Element | null => {
    const getText = useText();
    const dispatch = useDispatch();
    const view = useSelector(selectJobsView);

    const [isError, setIsError] = useState<boolean>(false);

    const defaultMapSettings = getDefaultMapSettings();
    const googleMapsIsLoaded = checkIfGoogleMapsLoaded();

    const id = 'command-center';
    const icons = {
      [CommandCenterJobType.LIVE]: {
        name: getText('command_centre_map_pin_in_progress'),
        icon: MarkerYellow,
      },
      [CommandCenterJobType.BLOCKED]: {
        name: getText('command_centre_map_pin_blocked'),
        icon: MarkerOrange,
      },
      [CommandCenterJobType.MAJOR_HAZARD]: {
        name: `${getText('command_centre_map_pin_major_hazards')} ${getText(
          'command_centre_map_pin_identified',
        )}`,
        icon: MarkerRed,
      },
      [CommandCenterJobType.COMPLETED]: {
        name: getText('command_centre_map_pin_completed'),
        icon: MarkerGrey,
      },
    };

    const renderMap = async (centerArgs?, zoom?) => {
      const { Map: GoogleMap, MapTypeId } = (await google.maps.importLibrary(
        'maps',
      )) as google.maps.MapsLibrary;
      const map = new GoogleMap(document.getElementById(id) as HTMLElement, {
        center: centerArgs ?? defaultMapSettings?.defaultCentre,
        zoom: zoom ?? defaultMapSettings?.defaultZoom,
        disableDefaultUI: true,
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeId: MapTypeId.ROADMAP,
      });

      map.addListener('dragend', () => {
        const newCenter = map.getCenter();
        if (newCenter) {
          setMapCenter(newCenter);
        }
      });
      map.addListener('zoom_changed', () => {
        const newCenter = map.getCenter();
        const newZoom = map.getZoom();
        if (newCenter) {
          setMapCenter(newCenter);
        }
        if (newZoom) {
          setMapZoom(newZoom);
        }
        Analytics.trackEvent(constants.EVENT_MAP_INTERACTION, {
          pageSource: PageSource.COMMAND_CENTER,
          type: MapInteractionType.ZOOM,
          [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        });
      });

      return map;
    };

    const renderMapWithMarkers = async () => {
      const { LatLngBounds } = (await google.maps.importLibrary(
        'core',
      )) as google.maps.CoreLibrary;
      const bounds = new LatLngBounds();

      coordinates.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
      let map: google.maps.Map;
      if (!mapCenter) {
        const boundCenter = bounds.getCenter();
        map = await renderMap({
          lat: boundCenter.lat(),
          lng: boundCenter.lng(),
        });
      } else {
        map = await renderMap(
          { lat: mapCenter.lat(), lng: mapCenter.lng() },
          mapZoom,
        );
      }

      // This part is causing the zoom issue
      if (coordinates?.length > 1) {
        map?.fitBounds(bounds, mapPadding);
      }

      createMarkers(map);
    };

    const createMarkers = async (map: google.maps.Map) => {
      for (const coordinate of coordinates) {
        const { Marker } = (await google.maps.importLibrary(
          'marker',
        )) as google.maps.MarkerLibrary;
        const marker = new Marker({
          position: coordinate,
          map,
          icon: {
            url: coordinate?.type ? icons[coordinate.type].icon : null,
          },
        });

        marker.addListener('click', () => {
          if (coordinate.jobId) {
            const win = window.open(`/jobs/${coordinate.jobId}`, '_blank');
            if (win) {
              win.focus();
            }
            Analytics.trackEvent(constants.COMMAND_CENTER_VIEW_JOB, {
              source: 'command-center-map-click',
              jobId: coordinate.jobId,
            });
          }
        });
      }
    };

    useEffect(() => {
      if (googleMapsIsLoaded) {
        if (coordinates && coordinates.length > 0) {
          renderMapWithMarkers();
        } else if (coordinates) {
          mapCenter
            ? renderMap({ lat: mapCenter.lat(), lng: mapCenter.lng() }, mapZoom)
            : renderMap();
        }
      } else {
        dispatch(setGlobalError(getText('command_centre_google_maps_error')));
        setIsError(true);
      }
    }, [coordinates, googleMapsIsLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isError) {
      return null;
    }
    return (
      <MapWrapper>
        <LegendContainer>
          <LegendRow>
            <MarkerYellowIcon />
            <LegendTextWrapper>
              <LegendTitle>
                {getText('command_centre_map_pin_in_progress')}
              </LegendTitle>
            </LegendTextWrapper>
          </LegendRow>
          <LegendRow>
            <MarkerOrangeIcon />
            <LegendTextWrapper>
              <LegendTitle>
                {getText('command_centre_map_pin_blocked')}
              </LegendTitle>
            </LegendTextWrapper>
          </LegendRow>
          <LegendRow>
            <MarkerRedIcon />
            <LegendTextWrapper>
              <LegendTitle>
                {getText('command_centre_map_pin_major_hazards')}
                <br />
                {getText('command_centre_map_pin_identified')}
              </LegendTitle>
            </LegendTextWrapper>
          </LegendRow>
          <LegendRow>
            <MarkerGreyIcon />
            <LegendTextWrapper>
              <LegendTitle>
                {getText('command_centre_map_pin_completed')}
              </LegendTitle>
            </LegendTextWrapper>
          </LegendRow>
        </LegendContainer>
        <MapContainer id={id} />
      </MapWrapper>
    );
  },
);

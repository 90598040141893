import * as Logger from 'utils/logger';
import { Auth } from 'aws-amplify';
import * as Analytics from 'utils/analytics';
import {
  EVENT_LOGIN_COMPLETE,
  LoginSource,
  SessionInitialPageLoad,
} from 'utils/constants';

export function startSSOSignIn(identityProvider, loginSource) {
  try {
    localStorage.setItem('SSO-initiated-source', loginSource);
    Auth.federatedSignIn({
      customProvider: identityProvider,
    });
  } catch (err) {
    Logger.info(err);
    Analytics.trackEvent(EVENT_LOGIN_COMPLETE, {
      loginSource,
      success: false,
      failure_reason: err,
    });
  }
}

export function finishSignIn(loginSource) {
  switch (loginSource) {
    case LoginSource.MAGIC_LINK:
    case LoginSource.EMAIL_PASSWORD:
      Analytics.trackEvent(EVENT_LOGIN_COMPLETE, {
        loginSource,
        success: true,
      });
      break;
    case SessionInitialPageLoad: {
      const SSOInitiatedSource = localStorage.getItem('SSO-initiated-source');
      if (SSOInitiatedSource) {
        Analytics.trackEvent(EVENT_LOGIN_COMPLETE, {
          loginSource: SSOInitiatedSource,
          success: true,
        });
      }
      break;
    }
    default:
      break;
  }
  localStorage.removeItem('SSO-initiated-source');
}

import styled from 'styled-components';
import theme from 'theme';
import * as constants from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useText } from 'hooks';
import {
  selectGlobalLanguageSetting,
  selectJobsView,
  selectWorkspaceSettings,
} from 'store/selectors';
import { fetchAssessments } from 'store/actions/assessments';
import { Card, Loader } from 'components/ui';
import { UploadStatus } from 'model/enum/UploadStatus';
import { InformationLabel } from 'components/ui/Shared/EmptyState';
import {
  Warning,
  YellowCheckMark,
  Assessment as AssessmentIcon,
} from 'assets/icons';
import { MajorHazardTag } from 'components/ui/Tags/MajorHazardTag';
import OverviewTabBase from './OverviewTabBase';
import { DownloadType } from '../../PdfExport/constants';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

const { colors, fonts } = theme;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 17px;
  font-family: ${fonts.bold};
`;

const OrangeStatusContainer = styled(StatusContainer)`
  background-color: ${colors.orange};
`;

const BlackStatusContainer = styled(StatusContainer)`
  background-color: ${colors.black};
`;

const SmallWarningIcon = styled(Warning)`
  height: 20px;
  width: 20px;
`;

const Status = styled.span<{ $textColor?: string }>`
  color: ${({ $textColor }) => $textColor || colors.white};
  margin-left: 10px;
`;

const RiskAssessments = (): JSX.Element => {
  const { jobId } = useParams();
  const getText = useText();
  const { assessments, isAssessmentsLoaded } = useSelector(
    (state: RootState) => state.assessments,
  );
  const { view_unreviewed_vra } = useSelector(selectWorkspaceSettings);
  const dispatch = useDispatch();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const view = useSelector(selectJobsView);

  useEffect(() => {
    dispatch(fetchAssessments(jobId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderCompleteContainer = (title) => (
    <BlackStatusContainer>
      <YellowCheckMark />
      <Status>{title}</Status>
    </BlackStatusContainer>
  );

  const renderNotCompleteContainer = (title) => (
    <OrangeStatusContainer>
      <SmallWarningIcon />
      <Status $textColor={colors.black}>{title}</Status>
    </OrangeStatusContainer>
  );

  const getAssessmentStatusTag = (
    isCompleted,
    completedTitle,
    notCompletedTitle,
  ) =>
    isCompleted
      ? renderCompleteContainer(completedTitle)
      : renderNotCompleteContainer(notCompletedTitle);

  const renderRamsTag = (is_rams_reviewed, method_statement) => {
    if (!is_rams_reviewed && !method_statement) {
      return renderNotCompleteContainer(getText('job_details_rams_pending'));
    }
    if (is_rams_reviewed && !method_statement) {
      return renderNotCompleteContainer(
        getText('job_details_rams_method_statement_pending'),
      );
    }
    return renderCompleteContainer(
      getText('job_details_rams_method_statement_complete'),
    );
  };

  return (
    <OverviewTabBase title={getText('tabs_risk_assessments')}>
      <>
        {!isAssessmentsLoaded && <Loader />}
        {isAssessmentsLoaded && !assessments.length && (
          <InformationLabel>
            {getText('job_overview_no_risk_assessment')}
          </InformationLabel>
        )}
        {isAssessmentsLoaded &&
          assessments &&
          assessments.map((assessment) => {
            const isRams = assessment.typeIsRams ?? false;
            const isLocked =
              [UploadStatus.UPLOADED, UploadStatus.PROCESSED].includes(
                assessment?.videoStatus,
              ) && !isRams;

            const isNotUploaded =
              assessment?.videoStatus === UploadStatus.NEW && !isRams;

            return (
              <div key={`assessment-${assessment.id}`}>
                <Card.JobDetail
                  jobId={jobId}
                  downloadItemId={!isRams ? assessment.id : undefined}
                  downloadType={!isRams ? DownloadType.ASSESSMENT : undefined}
                  event={constants.EVENT_VIEW_RISK_ASSESSMENT_ITEM}
                  eventProperties={{
                    jobId,
                    assessmentId: assessment.id,
                    assessmentType: assessment.type
                      ? getLocalizedCamelCaseValue(
                          assessment,
                          'type',
                          globalAppLanguage,
                        )
                      : getText('job_details_assessment_title'),
                    source: 'job-overview',
                    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  }}
                  key={`assessment-${assessment.id}`}
                  title={getText('job_details_assessment_title')}
                  subtitle={
                    assessment.type
                      ? getLocalizedCamelCaseValue(
                          assessment,
                          'type',
                          globalAppLanguage,
                        )
                      : getText('job_details_assessment_title')
                  }
                  creator={assessment.createdBy}
                  date={assessment.createdAt}
                  to={`/jobs/${assessment.jobId}/${
                    isRams ? 'rams-assessments' : 'assessments'
                  }/${assessment.id}`}
                  IconComponent={AssessmentIcon}
                  isLocked={!view_unreviewed_vra && isLocked}
                />
                {assessment.signoffRequired &&
                  !isRams &&
                  !isLocked &&
                  !isNotUploaded &&
                  getAssessmentStatusTag(
                    assessment.isSignedOff,
                    getText('job_details_sign_off_done'),
                    getText('job_details_sign_off_required'),
                  )}
                {isRams &&
                  renderRamsTag(
                    assessment.isRamsReviewed,
                    assessment.isMethodStatementDone,
                  )}
                {assessment.hasMajorHazard && (
                  <MajorHazardTag
                    text={getText('job_overview_major_hazard_identified')}
                    hasSmallIcon
                    extraStyles={{ marginTop: 0, padding: '7px 17px' }}
                  />
                )}
                {isLocked && !isNotUploaded && (
                  <OrangeStatusContainer>
                    <SmallWarningIcon />
                    <Status $textColor={colors.black}>
                      {getText('job_details_review_pending')}
                    </Status>
                  </OrangeStatusContainer>
                )}
              </div>
            );
          })}
      </>
    </OverviewTabBase>
  );
};

export default RiskAssessments;

import { Button } from 'components/ui';
import { useText } from 'hooks';
import { Assessment } from 'model/Assessment';
import { useDispatch, useSelector } from 'react-redux';
import { requestNewVra } from 'store/actions/assessments';
import { EVENT_RISK_ASSESSMENT_REQUEST_NEW_VRA } from '../constants';
import { selectIsRequestingNewVra } from 'store/selectors/assessments';
import {
  selectIsCurrentUserAssignedToJob,
  selectJobsView,
} from 'store/selectors';
import { EVENT_PROPERTIES_PAGE_SOURCE_FIELD } from 'utils/constants';

type Props = {
  assessment: Assessment;
};

export const RequestNewVra = ({ assessment }: Props): JSX.Element | null => {
  const getText = useText();
  const dispatch = useDispatch();
  const isRequestingNewVra = useSelector(selectIsRequestingNewVra);
  const view = useSelector(selectJobsView);
  const isCurrentUserAssignedToJob = useSelector(
    selectIsCurrentUserAssignedToJob,
  );

  const sendNewVraRequest = () => {
    dispatch(requestNewVra(assessment.id, { isNewRequested: true }));
  };

  if (!isCurrentUserAssignedToJob) {
    return null;
  }

  return (
    <Button.Secondary
      text={getText(
        assessment.isNewRequested
          ? 'assessment_details_new_vra_requested'
          : 'assessment_details_request_new_vra',
      )}
      onClick={sendNewVraRequest}
      disabled={assessment.isNewRequested || isRequestingNewVra}
      loading={isRequestingNewVra}
      event={EVENT_RISK_ASSESSMENT_REQUEST_NEW_VRA}
      eventProperties={{
        jobId: assessment.jobId,
        assessmentId: assessment.id,
        assessment_type: assessment.type,
        [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      }}
    />
  );
};

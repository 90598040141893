import styled from 'styled-components';
import { Button, Loader, Navbar } from 'components/ui';
import { useText } from 'hooks';
import theme from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGlobalLanguageSetting,
  selectHasOrganizationSettingEnabled,
  selectIsFetchingWorkspaceSettings,
  selectIsLoadingWorkspaceChanges,
  selectInitialWorkspaceSettings,
  selectIsLoadingJobCreationChanges,
  selectInitialJobCreationSettings,
  selectJobCreationError,
  selectWorkspaceError,
  selectOrganizationId,
} from 'store/selectors';
import { getLabelByLocale, isBlank } from 'helpers/utils';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { CreateJobFormFields } from '../../CreateJobFormFields';
import { Languages } from 'utils/constants';
import { useCustomLocaleText } from 'hooks/useText';
import RootState from 'model/State/RootState';
import {
  fetchWorkspaceSettings,
  setNewJobCreationSettings,
  setNewWorkspaceSettings,
} from 'store/actions/workspace';
import { LanguageSwitchToggle } from './LanguageSwitchToggle';
import * as Analytics from 'utils/analytics';
import AppFunctionalityInfoModal from '../../Modal/AppFunctionalityInfoModal';
import { WorkspaceFields } from './WorkspaceFields';
import { CreateJobFields } from './CreateJobFields';
import EvidenceSettings from './TypeSettings/EvidenceSettings';
import JobBlockerSettings from './TypeSettings/JobBlockerSettings';
import JobBlockerSubtypeSettings from './TypeSettings/JobBlockerSubtypeSettings';
import { CreateJobSettings } from 'model/AdminPanel/WorkspaceSettings/CreateJobSettings';
import { AdminPanelSettingsSection } from 'model/enum/AdminPanelSettingsSection';
import DigitalSignatureInfoModal from '../../Modal/DigitalSignatureInfoModal';
import JobTypeSettings from './TypeSettings/JobTypeSettings';
import JobSubtypeSettings from './TypeSettings/JobSubtypeSettings';
import {
  ADMIN_CREATE_JOB_SETTINGS_SCREEN_VIEW,
  EVENT_WORKSPACE_SETTINGS_SAVE_CREATE_JOB_SETTINGS,
  EVENT_WORKSPACE_SETTINGS_SAVE_WORKSPACE_SETTINGS,
  WorkspaceFieldNames,
  WorkspaceFieldTranslationKeys,
} from '../constants';
import { CustomFields } from '../../CreateJobFormFields/constants';
import { WorkspaceKeyFunctionalitySettings } from 'model/AdminPanel/WorkspaceSettings/WorkspaceKeyFunctionalitySettings';

const { colors, mixins, media, fontSizes, fonts } = theme;

// Styled Components
const Container = styled.div`
  height: 100%;
  padding: 40px 80px;
  ${mixins.flexColumn};
  ${media.sm`
    padding: 15px;
  `}
  background-color: ${colors.offWhite};
  box-sizing: border-box;
  overflow: auto;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const H2 = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
`;

const Description = styled.div`
  margin-bottom: 40px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

const CreateJobSettingsSection = styled.div`
  display: flex;
`;

const InputsContainer = styled.div`
  background-color: ${colors.white};
  padding: 32px;
  box-sizing: border-box;
  width: 50%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  > .MuiButtonBase-root {
    align-self: start;
  }
`;

const CreateJobPreview = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: ${colors.darkGrey};
  padding: 32px;
  padding-top: 64px;
  box-sizing: border-box;
`;

const PreviewModal = styled.div`
  background-color: ${colors.white};
`;

const PreviewModalHeader = styled.h3`
  background-color: ${colors.black};
  color: ${colors.yellow};
  font-family: ${fonts.bold};
  padding: 20px;
`;

const ButtonContainer = styled.div`
  width: 40%;
  ${media.xl`
     width: 60%;
  `}
  ${media.lg`
     width: 100%;
  `}
`;

const ErrorMessage = styled.div`
  color: ${colors.darkRed};
  margin-top: 10px;
`;

export const WorkspaceSettingsTab = (): JSX.Element => {
  const dispatch = useDispatch();
  const canToggleBetweenLanguages = useSelector(
    selectHasOrganizationSettingEnabled('enable_language_selection'),
  );
  const hasPinLocationEnabled = useSelector(
    selectHasOrganizationSettingEnabled('pin_location_enabled'),
  );
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const { selectedWorkspace, currentUser } = useSelector(
    (state: RootState) => state.session,
  );
  const organisationId = useSelector(selectOrganizationId);
  const isFetchingWorkspaceSettings = useSelector(
    selectIsFetchingWorkspaceSettings,
  );
  const isLoadingWorkspaceChanges = useSelector(
    selectIsLoadingWorkspaceChanges,
  );
  const isLoadingJobCreationChanges = useSelector(
    selectIsLoadingJobCreationChanges,
  );
  const initialWorkspaceSettings = useSelector(
    selectInitialWorkspaceSettings(selectedWorkspace?.uuid),
  );
  const initialJobCreationSettings = useSelector(
    selectInitialJobCreationSettings(selectedWorkspace?.uuid),
  );
  const workspaceError = useSelector(selectWorkspaceError);
  const jobCreationError = useSelector(selectJobCreationError);
  const [workspaceSettings, setWorkspaceSettings] =
    useState<WorkspaceKeyFunctionalitySettings>(initialWorkspaceSettings);
  const [createJobSettings, setCreateJobSettings] = useState<CreateJobSettings>(
    initialJobCreationSettings,
  );
  const [missingRequiredFields, setMissingRequiredFields] = useState<string[]>(
    [],
  );
  const [previewLanguage, setPreviewLanguage] = useState(globalAppLanguage);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState<{
    field: string;
    title?: string;
    description?: JSX.Element;
    image?: any;
  } | null>(null);

  const getText = useText();
  const getDynamicText = useCustomLocaleText(previewLanguage);
  const getEnglishText = useCustomLocaleText(Languages.english.locale);
  const getSpanishText = useCustomLocaleText(Languages.spanish.locale);

  useEffect(() => {
    if (selectedWorkspace?.uuid) {
      dispatch(fetchWorkspaceSettings(selectedWorkspace?.uuid));
    }
    Analytics.trackPage(ADMIN_CREATE_JOB_SETTINGS_SCREEN_VIEW, {
      userId: currentUser?.id,
      organisationId: currentUser?.organisation?.uuid,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setWorkspaceSettings(initialWorkspaceSettings);
  }, [initialWorkspaceSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCreateJobSettings(initialJobCreationSettings);
  }, [initialJobCreationSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFieldValue = useCallback(
    (fieldId: string) =>
      getLabelByLocale(previewLanguage, {
        [fieldId]: createJobSettings?.[fieldId],
        [`${fieldId}Es`]: createJobSettings?.[`${fieldId}Es`],
      }),
    [previewLanguage, createJobSettings],
  );

  const getLabel = (fieldName: string) => {
    if (fieldName.endsWith('Es')) {
      return getSpanishText(WorkspaceFieldTranslationKeys[fieldName]);
    } else {
      return getEnglishText(WorkspaceFieldTranslationKeys[fieldName]);
    }
  };

  const createJobPreviewFields = useMemo(
    () =>
      new CustomFields(
        hasPinLocationEnabled,
        getFieldValue(WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL),
        getFieldValue(WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL),
        getFieldValue(WorkspaceFieldNames.PROJECT_NUMBER_LABEL),
        getFieldValue(WorkspaceFieldNames.JOB_SUBTYPE_LABEL),
        getFieldValue(WorkspaceFieldNames.ADDRESS_LABEL),
        getFieldValue(WorkspaceFieldNames.POSTCODE_LABEL),
      ),
    [getFieldValue, hasPinLocationEnabled],
  );

  const requiredFields = hasPinLocationEnabled
    ? [
        WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL,
        ...(canToggleBetweenLanguages
          ? [WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL_ES]
          : []),
      ]
    : [
        WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL,
        WorkspaceFieldNames.ADDRESS_LABEL,
        WorkspaceFieldNames.POSTCODE_LABEL,
        ...(canToggleBetweenLanguages
          ? [
              WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL_ES,
              WorkspaceFieldNames.ADDRESS_LABEL_ES,
              WorkspaceFieldNames.POSTCODE_LABEL_ES,
            ]
          : []),
      ];

  const hasChangedFields = (
    settingValues: WorkspaceKeyFunctionalitySettings | CreateJobSettings,
    initialValues: WorkspaceKeyFunctionalitySettings | CreateJobSettings,
  ) =>
    Object.entries(settingValues).filter(
      (pair) => initialValues[pair[0]] !== pair[1],
    ).length > 0;

  const getChangedFields = (
    settingValues: WorkspaceKeyFunctionalitySettings | CreateJobSettings,
    initialValues: WorkspaceKeyFunctionalitySettings | CreateJobSettings,
  ) =>
    Object.entries(settingValues)
      .filter((pair) => initialValues[pair[0]] !== pair[1])
      .reduce(
        (acc, cur) => ({
          ...acc,
          [cur[0]]:
            typeof cur[1] === 'string' && cur[1].trim() === '' ? '' : cur[1],
        }),
        {},
      );

  const onSubmit = (section) => {
    let eventName;
    let eventProperties = {};

    switch (section) {
      case AdminPanelSettingsSection.WORKSPACE:
        eventName = EVENT_WORKSPACE_SETTINGS_SAVE_WORKSPACE_SETTINGS;
        eventProperties = {
          DevicelessDigitalSignature:
            workspaceSettings.devicelessDigitalSignature,
          UserSignOnRequired: workspaceSettings.userSignOnRequired,
          VraSignoffRequired: workspaceSettings.vraSignoffRequired,
        };
        break;
      case AdminPanelSettingsSection.JOB_CREATION:
        eventName = EVENT_WORKSPACE_SETTINGS_SAVE_CREATE_JOB_SETTINGS;
        eventProperties = {
          organisationId,
          JobNumberLabel: createJobSettings.workOrderNumberLabel,
          JobNumberLabelEs: createJobSettings.workOrderNumberLabelEs,
          jobSubTypeLabel: createJobSettings.jobSubtypeLabel,
          jobSubTypeLabelEs: createJobSettings.jobSubtypeLabelEs,
          ProjectNumberLabel: createJobSettings.projectNumberLabel,
          ProjectNumberLabelEs: createJobSettings.projectNumberLabelEs,
          OriginatingNumberLabel: createJobSettings.originatingNumberLabel,
          OriginatingNumberLabelEs: createJobSettings.originatingNumberLabelEs,
          AddressLabel: createJobSettings.addressLabel,
          AddressLabelEs: createJobSettings.addressLabelEs,
          PostcodeLabel: createJobSettings.postcodeLabel,
          PostcodeLabelEs: createJobSettings.postcodeLabelEs,
        };
        break;
    }
    if (eventName) {
      Analytics.trackEvent(eventName, { ...eventProperties });
    }
    dispatch(
      section === AdminPanelSettingsSection.WORKSPACE
        ? setNewWorkspaceSettings(
            selectedWorkspace?.uuid,
            getChangedFields(workspaceSettings, initialWorkspaceSettings),
          )
        : setNewJobCreationSettings(
            selectedWorkspace?.uuid,
            getChangedFields(createJobSettings, initialJobCreationSettings),
          ),
    );
  };

  const validateAndSubmit = (section) => {
    let missing: string[] = [];
    if (section === AdminPanelSettingsSection.JOB_CREATION) {
      setMissingRequiredFields([]);
      missing = Object.keys(createJobSettings).filter((key) => {
        const isRequiredAndBlank =
          requiredFields.find((requiredField) => requiredField === key) &&
          isBlank(createJobSettings[key]);

        let isMissingOtherLanguage = false;
        if (canToggleBetweenLanguages) {
          if (key.endsWith('Label')) {
            // if we have the Spanish label but not the English
            isMissingOtherLanguage =
              isBlank(createJobSettings[key]) &&
              !isBlank(createJobSettings[`${key}Es`]);
          }
          if (key.endsWith('LabelEs')) {
            // if we have the English label but not the Spanish
            isMissingOtherLanguage =
              isBlank(createJobSettings[key]) &&
              !isBlank(createJobSettings[key.slice(0, -2)]);
          }
        }
        return isRequiredAndBlank || isMissingOtherLanguage;
      });
    }

    if (missing.length > 0) {
      setMissingRequiredFields(missing);
    } else {
      onSubmit(section);
    }
  };

  return (
    <>
      <Navbar home mobileOnly />
      <Container>
        {!isFetchingWorkspaceSettings ? (
          <>
            {workspaceSettings && (
              <Section>
                <HeaderWrapper>
                  <H2>{getText('workspace_settings_general_title')}</H2>
                </HeaderWrapper>
                <InputsContainer>
                  <Description>
                    {getText('workspace_settings_general_description')}
                  </Description>
                  <WorkspaceFields
                    workspaceSettings={workspaceSettings}
                    setWorkspaceSettings={setWorkspaceSettings}
                    setInfoModal={setInfoModal}
                    setIsInfoModalOpen={setIsInfoModalOpen}
                  />
                  <ButtonContainer>
                    <Button.Primary
                      text={getText('workspace_settings_save_changes')}
                      onClick={() =>
                        validateAndSubmit(AdminPanelSettingsSection.WORKSPACE)
                      }
                      disabled={
                        !hasChangedFields(
                          workspaceSettings,
                          initialWorkspaceSettings,
                        )
                      }
                      loading={isLoadingWorkspaceChanges}
                    />
                  </ButtonContainer>
                  {workspaceError && (
                    <ErrorMessage>
                      {workspaceError?.message ||
                        getText('workspace_settings_error')}
                    </ErrorMessage>
                  )}
                </InputsContainer>
              </Section>
            )}
            {createJobSettings && !!Object.keys(createJobSettings)?.length && (
              <Section>
                <HeaderWrapper>
                  <H2>{getText('workspace_settings_create_job_title')}</H2>{' '}
                </HeaderWrapper>
                <CreateJobSettingsSection>
                  <InputsContainer>
                    <Description>
                      {getText('workspace_settings_create_job_description')}
                    </Description>
                    <CreateJobFields
                      hasPinLocationEnabled={hasPinLocationEnabled}
                      canToggleBetweenLanguages={canToggleBetweenLanguages}
                      workspaceSettings={createJobSettings}
                      setWorkspaceSettings={setCreateJobSettings}
                      optionalFields={Object.keys(createJobSettings).filter(
                        (key) =>
                          !requiredFields.find(
                            (requiredField) => requiredField === key,
                          ),
                      )}
                      initialSettings={initialJobCreationSettings}
                    />
                    {missingRequiredFields?.length > 0 && (
                      <ErrorMessage>
                        {missingRequiredFields?.length < 2
                          ? `${getText('create_job_required_field')}: `
                          : `${getText('create_job_required_fields')}: `}
                        {missingRequiredFields
                          .map((fieldName) => getLabel(fieldName))
                          .join(', ')}
                      </ErrorMessage>
                    )}
                    <ButtonContainer>
                      <Button.Primary
                        text={getText('workspace_settings_save_changes')}
                        onClick={() =>
                          validateAndSubmit(
                            AdminPanelSettingsSection.JOB_CREATION,
                          )
                        }
                        disabled={
                          !hasChangedFields(
                            createJobSettings,
                            initialJobCreationSettings,
                          )
                        }
                        loading={isLoadingJobCreationChanges}
                      />
                    </ButtonContainer>
                    {jobCreationError && (
                      <ErrorMessage>
                        {jobCreationError?.message ||
                          getText('workspace_settings_error')}
                      </ErrorMessage>
                    )}
                  </InputsContainer>
                  <CreateJobPreview>
                    {canToggleBetweenLanguages && (
                      <LanguageSwitchToggle
                        activeLanguageCode={previewLanguage}
                        setActiveLanguageCode={setPreviewLanguage}
                      />
                    )}
                    <PreviewModal>
                      <PreviewModalHeader>
                        {getDynamicText('modal_title_create_job')}
                      </PreviewModalHeader>
                      <CreateJobFormFields
                        isReadOnly
                        customFields={createJobPreviewFields}
                        language={previewLanguage}
                      />
                    </PreviewModal>
                  </CreateJobPreview>
                </CreateJobSettingsSection>
              </Section>
            )}
            <Section>
              <JobTypeSettings />
            </Section>
            <Section>
              <JobSubtypeSettings />
            </Section>
            <Section>
              <JobBlockerSettings />
            </Section>
            <Section>
              <JobBlockerSubtypeSettings />
            </Section>
            <Section>
              <EvidenceSettings />
            </Section>
            {infoModal?.field ===
            WorkspaceFieldNames.DEVICELESS_DIGITAL_SIGNATURE ? (
              <DigitalSignatureInfoModal
                isVisible={isInfoModalOpen}
                setIsVisible={setIsInfoModalOpen}
              />
            ) : (
              <AppFunctionalityInfoModal
                isVisible={isInfoModalOpen}
                setIsVisible={setIsInfoModalOpen}
                {...infoModal}
                hasCrossIcon
              />
            )}
          </>
        ) : (
          <Loader />
        )}
      </Container>
    </>
  );
};

import { useDispatch, useSelector } from 'react-redux';
import { RamsSuggestBase } from '../RamsSuggestBase';
import { Hazard } from 'model/Assessment/Hazard';
import RootState from 'model/State/RootState';
import {
  removeSelectedHazard,
  setSelectedHazards,
} from 'store/slices/ramsAssessments';

export const HazardList = ({ hazards }: { hazards: Hazard[] }): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedHazards } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );

  const handleSelectChange = (item) => {
    if (selectedHazards.some((hazard) => hazard.id === item.id)) {
      dispatch(removeSelectedHazard(item.id));
    } else {
      dispatch(setSelectedHazards(item));
    }
  };

  return (
    <RamsSuggestBase
      items={hazards}
      onSelectedChange={handleSelectChange}
      selectedItems={selectedHazards}
    />
  );
};

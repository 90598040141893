import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useJobs, useText } from 'hooks';
import { Modal } from 'components/ui';
import {
  useParams,
  useLocation,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import Show from './Show';
import AddDocument from './AddDocument';
import AddEvidence from './AddEvidence';

const Container = styled.div`
  height: 540px;
  display: flex;
`;

// AddContent.Modal
export default () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const { pathname } = useLocation();
  const { navigateToOverviewTabOrJob } = useJobs();
  const [evidenceMediaSelect, setEvidenceMediaSelect] =
    useState<boolean>(false);
  const [evidenceTypeSelect, setEvidenceTypeSelect] = useState<boolean>(false);
  const getText = useText();

  useEffect(() => {
    if (evidenceMediaSelect) {
      setEvidenceTypeSelect(false);
    }
  }, [evidenceMediaSelect]);

  const handleGoBack = () => {
    if (evidenceMediaSelect) {
      setEvidenceTypeSelect(true);
      setEvidenceMediaSelect(false);
    } else {
      navigate(`/jobs/${jobId}/add`);
    }
  };

  const getTitle = () => {
    if (pathname.includes('document')) {
      return getText('upload_add_documents');
    }
    if (pathname.includes('evidence')) {
      return getText('upload_add_evidence');
    }
    return '';
  };

  return (
    <Modal.Base
      isOpen
      onBack={
        ((pathname.includes('document') || pathname.includes('evidence')) &&
          handleGoBack) ||
        null
      }
      title={getTitle()}
      onClose={() => navigateToOverviewTabOrJob()}
    >
      <Container>
        <Routes>
          <Route path='/document' element={<AddDocument />} />
          <Route
            path='/evidence'
            element={
              <AddEvidence
                setEvidenceMediaSelect={setEvidenceMediaSelect}
                evidenceTypeSelect={evidenceTypeSelect}
              />
            }
          />
          <Route path='/' element={<Show />} />
        </Routes>
      </Container>
    </Modal.Base>
  );
};

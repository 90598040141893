import axios from 'axios';
import { WorkspaceSettings } from 'model/AdminPanel/WorkspaceSettings/WorkspaceSettings';
import {
  mapToBackendWorkspaceItemType,
  mapToBackendWorkspaceSettings,
  mapToWorkspaceItemType,
  mapToWorkspaceSettings,
} from './helpers';
import { WorkspaceItemType } from 'model/AdminPanel/WorkspaceSettings/WorkspaceItemType';

export const getWorkspaceSettings = async (
  workspaceUuid: string,
): Promise<WorkspaceSettings> => {
  const { data } = await axios.get(`/workspace-settings/${workspaceUuid}`);
  return mapToWorkspaceSettings(data);
};

export const saveWorkspaceSettings = async (
  workspaceUuid: string,
  params: object = {},
): Promise<WorkspaceSettings> => {
  const { data } = await axios.patch(
    `/workspace-settings/${workspaceUuid}`,
    mapToBackendWorkspaceSettings(params),
  );
  return mapToWorkspaceSettings(data);
};

// EVIDENCE
export const getEvidenceTypes = async (
  workspaceUuid: string,
): Promise<WorkspaceItemType[]> => {
  const { data } = await axios.get(
    `/workspace-settings/${workspaceUuid}/evidence-types/`,
  );
  return data.map(mapToWorkspaceItemType);
};

export const addEvidenceType = async (
  workspaceUuid: string,
  evidenceType: WorkspaceItemType,
): Promise<WorkspaceItemType> => {
  const { data } = await axios.post(
    `/workspace-settings/${workspaceUuid}/evidence-types/`,
    mapToBackendWorkspaceItemType(evidenceType),
  );
  return mapToWorkspaceItemType(data);
};

export const updateEvidenceType = async (
  workspaceUuid: string,
  evidenceType: WorkspaceItemType,
): Promise<WorkspaceItemType> => {
  const { data } = await axios.put(
    `/workspace-settings/${workspaceUuid}/evidence-types/${evidenceType?.id}`,
    mapToBackendWorkspaceItemType(evidenceType),
  );
  return mapToWorkspaceItemType(data);
};

export const deleteEvidenceType = async (
  workspaceUuid: string,
  typeId: number,
): Promise<void> => {
  await axios.delete(
    `/workspace-settings/${workspaceUuid}/evidence-types/${typeId}`,
  );
};

// TYPE
export const getJobTypes = async (
  workspaceUuid: string,
): Promise<WorkspaceItemType[]> => {
  const { data } = await axios.get(
    `/workspace-settings/${workspaceUuid}/job-types/`,
  );
  return data.map(mapToWorkspaceItemType);
};

export const addJobType = async (
  workspaceUuid: string,
  jobType: WorkspaceItemType,
): Promise<WorkspaceItemType> => {
  const { data } = await axios.post(
    `/workspace-settings/${workspaceUuid}/job-types/`,
    mapToBackendWorkspaceItemType(jobType),
  );
  return mapToWorkspaceItemType(data);
};

export const updateJobType = async (
  workspaceUuid: string,
  jobType: WorkspaceItemType,
): Promise<WorkspaceItemType> => {
  const { data } = await axios.put(
    `/workspace-settings/${workspaceUuid}/job-types/${jobType?.id}`,
    mapToBackendWorkspaceItemType(jobType),
  );
  return mapToWorkspaceItemType(data);
};

export const deleteJobType = async (
  workspaceUuid: string,
  typeId: number,
): Promise<void> => {
  await axios.delete(
    `/workspace-settings/${workspaceUuid}/job-types/${typeId}`,
  );
};

export const getJobSubtypes = async (
  workspaceUuid: string,
  jobTypeId: number,
): Promise<WorkspaceItemType[]> => {
  const { data } = await axios.get(
    `/workspace-settings/${workspaceUuid}/job-types/${jobTypeId}/sub-type/`,
  );
  return data.map(mapToWorkspaceItemType);
};

export const addJobSubtype = async (
  workspaceUuid: string,
  jobTypeId: number,
  jobSubtype: WorkspaceItemType,
): Promise<WorkspaceItemType> => {
  const { data } = await axios.post(
    `/workspace-settings/${workspaceUuid}/job-types/${jobTypeId}/sub-type/`,
    mapToBackendWorkspaceItemType(jobSubtype),
  );
  return mapToWorkspaceItemType(data);
};

export const updateJobSubtype = async (
  workspaceUuid: string,
  jobTypeId: number,
  jobSubtype: WorkspaceItemType,
): Promise<WorkspaceItemType> => {
  const { data } = await axios.put(
    `/workspace-settings/${workspaceUuid}/job-types/${jobTypeId}/sub-type/${jobSubtype?.id}/`,
    mapToBackendWorkspaceItemType(jobSubtype),
  );
  return mapToWorkspaceItemType(data);
};

export const deleteJobSubtype = async (
  workspaceUuid: string,
  jobTypeId: number,
  subtypeId: number,
): Promise<void> => {
  await axios.delete(
    `/workspace-settings/${workspaceUuid}/job-types/${jobTypeId}/sub-type/${subtypeId}/`,
  );
};

// JOB BLOCKER
export const getJobBlockerTypes = async (
  workspaceUuid: string,
): Promise<WorkspaceItemType[]> => {
  const { data } = await axios.get(
    `/workspace-settings/${workspaceUuid}/job-blockers/`,
  );
  return data.map(mapToWorkspaceItemType);
};

export const addJobBlockerType = async (
  workspaceUuid: string,
  jobBlockerType: WorkspaceItemType,
): Promise<WorkspaceItemType> => {
  const { data } = await axios.post(
    `/workspace-settings/${workspaceUuid}/job-blockers/`,
    mapToBackendWorkspaceItemType(jobBlockerType),
  );
  return mapToWorkspaceItemType(data);
};

export const updateJobBlockerType = async (
  workspaceUuid: string,
  jobBlockerType: WorkspaceItemType,
): Promise<WorkspaceItemType> => {
  const { data } = await axios.put(
    `/workspace-settings/${workspaceUuid}/job-blockers/${jobBlockerType?.id}`,
    mapToBackendWorkspaceItemType(jobBlockerType),
  );
  return mapToWorkspaceItemType(data);
};

export const deleteJobBlockerType = async (
  workspaceUuid: string,
  typeId: number,
): Promise<void> => {
  await axios.delete(
    `/workspace-settings/${workspaceUuid}/job-blockers/${typeId}`,
  );
};

export const getJobBlockerSubtypes = async (
  workspaceUuid: string,
  blockerId: number,
): Promise<WorkspaceItemType[]> => {
  const { data } = await axios.get(
    `/workspace-settings/${workspaceUuid}/job-blockers/${blockerId}/sub-type/`,
  );
  return data.map(mapToWorkspaceItemType);
};

export const addJobBlockerSubtype = async (
  workspaceUuid: string,
  blockerId: number,
  jobBlockerType: WorkspaceItemType,
): Promise<WorkspaceItemType> => {
  const { data } = await axios.post(
    `/workspace-settings/${workspaceUuid}/job-blockers/${blockerId}/sub-type/`,
    mapToBackendWorkspaceItemType(jobBlockerType),
  );
  return mapToWorkspaceItemType(data);
};

export const updateJobBlockerSubtype = async (
  workspaceUuid: string,
  blockerId: number,
  jobBlockerType: WorkspaceItemType,
): Promise<WorkspaceItemType> => {
  const { data } = await axios.put(
    `/workspace-settings/${workspaceUuid}/job-blockers/${blockerId}/sub-type/${jobBlockerType?.id}`,
    mapToBackendWorkspaceItemType(jobBlockerType),
  );
  return mapToWorkspaceItemType(data);
};

export const deleteJobBlockerSubtype = async (
  workspaceUuid: string,
  blockerId: number,
  typeId: number,
): Promise<void> => {
  await axios.delete(
    `/workspace-settings/${workspaceUuid}/job-blockers/${blockerId}/sub-type/${typeId}`,
  );
};

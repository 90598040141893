import styled from 'styled-components';
import { useText } from 'hooks';
import theme from 'theme';
import { ChangeEvent, useState } from 'react';
import { Text, Toggle } from 'components/ui/Input';
import { Languages } from 'utils/constants';
import { useCustomLocaleText } from 'hooks/useText';
import { isBlank } from 'helpers/utils';
import {
  WorkspaceFieldNames,
  WorkspaceFieldTranslationKeys,
} from '../constants';

const { colors, media } = theme;

// Styled Components
const InputWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const LabelContainer = styled.div<{ $offWhite?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: ${({ $offWhite }) =>
    $offWhite ? colors.offWhite : colors.white};
  ${media.lg`
     padding: 16px;
  `}
`;

const Container = styled.div`
  background-color: ${colors.white};
  box-sizing: border-box;
  width: 100%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.div`
  color: ${colors.darkRed};
  margin-top: 10px;
`;

export const CreateJobFields = ({
  hasPinLocationEnabled,
  canToggleBetweenLanguages,
  workspaceSettings,
  setWorkspaceSettings,
  optionalFields,
  initialSettings,
}): JSX.Element => {
  const getText = useText();
  const getEnglishText = useCustomLocaleText(Languages.english.locale);
  const getSpanishText = useCustomLocaleText(Languages.spanish.locale);
  const [disabled, setDisabled] = useState(
    Object.keys(workspaceSettings).reduce(
      (acc, cur) => ({
        ...acc,
        [cur]:
          !!optionalFields.find((optionalField) => optionalField === cur) &&
          !workspaceSettings[cur],
      }),
      {},
    ),
  );

  const handleTextFieldChange = (e?: ChangeEvent<HTMLInputElement>) => {
    const fieldName = e?.target?.id;

    if (fieldName) {
      setWorkspaceSettings((prevState) => ({
        ...prevState,
        [fieldName]: e.target.value,
      }));
    }
  };

  const isRequiredBlankError = (fieldName: string) =>
    isBlank(workspaceSettings[fieldName])
      ? getText('workspace_settings_required_error_message')
      : undefined;

  const isOptionalBlankError = (fieldName: string, siblingFieldName: string) =>
    isBlank(workspaceSettings[fieldName]) && canToggleBetweenLanguages
      ? workspaceSettings[siblingFieldName] &&
        getText('workspace_settings_required_error_message')
      : undefined;

  const isToggleChecked = (englishField, spanishField) =>
    canToggleBetweenLanguages
      ? !(disabled[englishField] && disabled[spanishField])
      : !disabled[englishField];

  const isDisabled = (englishField, spanishField) =>
    canToggleBetweenLanguages
      ? disabled[englishField] && disabled[spanishField]
      : disabled[englishField];

  const onToggleChange = (event, englishField, spanishField) => {
    setWorkspaceSettings((prevState) => ({
      ...prevState,
      [englishField]: event?.target?.checked
        ? initialSettings[englishField]
        : '',
      [spanishField]: event?.target?.checked
        ? initialSettings[spanishField]
        : '',
    }));

    setDisabled((prevState) => ({
      ...prevState,
      [englishField]: !event?.target?.checked,
      [spanishField]: !event?.target?.checked,
    }));
  };

  return (
    <Container>
      <LabelContainer $offWhite>
        <InputWrapper>
          <Text
            id={WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL}
            label={getEnglishText(
              WorkspaceFieldTranslationKeys[
                WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL
              ],
            )}
            guidance={getEnglishText(
              'workspace_settings_create_job_work_order_number_guidance',
            )}
            value={
              workspaceSettings[WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL]
            }
            onChange={handleTextFieldChange}
            required
            extraStyles={{ width: '100%' }}
            error={isRequiredBlankError(
              WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL,
            )}
            maxLength={200}
          />
          {isRequiredBlankError(
            WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL,
          ) && (
            <ErrorMessage>
              {isRequiredBlankError(
                WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL,
              )}
            </ErrorMessage>
          )}
        </InputWrapper>
        {canToggleBetweenLanguages && (
          <InputWrapper>
            <Text
              id={WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL_ES}
              label={getSpanishText(
                WorkspaceFieldTranslationKeys[
                  WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL_ES
                ],
              )}
              guidance={getSpanishText(
                'workspace_settings_create_job_work_order_number_guidance',
              )}
              value={
                workspaceSettings[
                  WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL_ES
                ]
              }
              onChange={handleTextFieldChange}
              required
              extraStyles={{ width: '100%' }}
              error={isRequiredBlankError(
                WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL_ES,
              )}
              maxLength={200}
            />
            {isRequiredBlankError(
              WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL_ES,
            ) && (
              <ErrorMessage>
                {isRequiredBlankError(
                  WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL_ES,
                )}
              </ErrorMessage>
            )}
          </InputWrapper>
        )}
      </LabelContainer>
      <LabelContainer>
        <Toggle
          id={`${WorkspaceFieldNames.JOB_SUBTYPE_LABEL}-${WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES}-toggle`}
          checked={isToggleChecked(
            WorkspaceFieldNames.JOB_SUBTYPE_LABEL,
            WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES,
          )}
          onChange={(event) =>
            onToggleChange(
              event,
              [WorkspaceFieldNames.JOB_SUBTYPE_LABEL],
              [WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES],
            )
          }
        />
        <InputWrapper>
          <Text
            id={WorkspaceFieldNames.JOB_SUBTYPE_LABEL}
            label={getEnglishText(
              WorkspaceFieldTranslationKeys[
                WorkspaceFieldNames.JOB_SUBTYPE_LABEL
              ],
            )}
            value={workspaceSettings[WorkspaceFieldNames.JOB_SUBTYPE_LABEL]}
            onChange={handleTextFieldChange}
            extraStyles={{ width: '100%' }}
            disabled={isDisabled(
              WorkspaceFieldNames.JOB_SUBTYPE_LABEL,
              WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES,
            )}
            error={isOptionalBlankError(
              WorkspaceFieldNames.JOB_SUBTYPE_LABEL,
              WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES,
            )}
            maxLength={200}
          />
          {isOptionalBlankError(
            WorkspaceFieldNames.JOB_SUBTYPE_LABEL,
            WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES,
          ) && (
            <ErrorMessage>
              {isOptionalBlankError(
                WorkspaceFieldNames.JOB_SUBTYPE_LABEL,
                WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES,
              )}
            </ErrorMessage>
          )}
        </InputWrapper>
        {canToggleBetweenLanguages && (
          <InputWrapper>
            <Text
              id={WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES}
              label={getSpanishText(
                WorkspaceFieldTranslationKeys[
                  WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES
                ],
              )}
              value={
                workspaceSettings[WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES]
              }
              onChange={handleTextFieldChange}
              extraStyles={{ width: '100%' }}
              disabled={isDisabled(
                WorkspaceFieldNames.JOB_SUBTYPE_LABEL,
                WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES,
              )}
              error={isOptionalBlankError(
                WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES,
                WorkspaceFieldNames.JOB_SUBTYPE_LABEL,
              )}
              maxLength={200}
            />
            {isOptionalBlankError(
              WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES,
              WorkspaceFieldNames.JOB_SUBTYPE_LABEL,
            ) && (
              <ErrorMessage>
                {isOptionalBlankError(
                  WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES,
                  WorkspaceFieldNames.JOB_SUBTYPE_LABEL,
                )}
              </ErrorMessage>
            )}
          </InputWrapper>
        )}
      </LabelContainer>
      <LabelContainer $offWhite>
        <Toggle
          id={`${WorkspaceFieldNames.PROJECT_NUMBER_LABEL}-${WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES}-toggle`}
          checked={isToggleChecked(
            WorkspaceFieldNames.PROJECT_NUMBER_LABEL,
            WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES,
          )}
          onChange={(event) =>
            onToggleChange(
              event,
              [WorkspaceFieldNames.PROJECT_NUMBER_LABEL],
              [WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES],
            )
          }
        />
        <InputWrapper>
          <Text
            id={WorkspaceFieldNames.PROJECT_NUMBER_LABEL}
            label={getEnglishText(
              WorkspaceFieldTranslationKeys[
                WorkspaceFieldNames.PROJECT_NUMBER_LABEL
              ],
            )}
            value={workspaceSettings[WorkspaceFieldNames.PROJECT_NUMBER_LABEL]}
            onChange={handleTextFieldChange}
            extraStyles={{ width: '100%' }}
            disabled={isDisabled(
              WorkspaceFieldNames.PROJECT_NUMBER_LABEL,
              WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES,
            )}
            error={isOptionalBlankError(
              WorkspaceFieldNames.PROJECT_NUMBER_LABEL,
              WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES,
            )}
            maxLength={200}
          />
          {isOptionalBlankError(
            WorkspaceFieldNames.PROJECT_NUMBER_LABEL,
            WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES,
          ) && (
            <ErrorMessage>
              {isOptionalBlankError(
                WorkspaceFieldNames.PROJECT_NUMBER_LABEL,
                WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES,
              )}
            </ErrorMessage>
          )}
        </InputWrapper>
        {canToggleBetweenLanguages && (
          <InputWrapper>
            <Text
              id={WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES}
              label={getSpanishText(
                WorkspaceFieldTranslationKeys[
                  WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES
                ],
              )}
              value={
                workspaceSettings[WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES]
              }
              onChange={handleTextFieldChange}
              extraStyles={{ width: '100%' }}
              disabled={isDisabled(
                WorkspaceFieldNames.PROJECT_NUMBER_LABEL,
                WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES,
              )}
              error={isOptionalBlankError(
                WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES,
                WorkspaceFieldNames.PROJECT_NUMBER_LABEL,
              )}
              maxLength={200}
            />
            {isOptionalBlankError(
              WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES,
              WorkspaceFieldNames.PROJECT_NUMBER_LABEL,
            ) && (
              <ErrorMessage>
                {isOptionalBlankError(
                  WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES,
                  WorkspaceFieldNames.PROJECT_NUMBER_LABEL,
                )}
              </ErrorMessage>
            )}
          </InputWrapper>
        )}
      </LabelContainer>
      <LabelContainer>
        <Toggle
          id={`${WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL}-${WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES}-toggle`}
          checked={isToggleChecked(
            WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL,
            WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES,
          )}
          onChange={(event) =>
            onToggleChange(
              event,
              [WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL],
              [WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES],
            )
          }
        />
        <InputWrapper>
          <Text
            id={WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL}
            label={getEnglishText(
              WorkspaceFieldTranslationKeys[
                WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL
              ],
            )}
            value={
              workspaceSettings[WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL]
            }
            onChange={handleTextFieldChange}
            extraStyles={{ width: '100%' }}
            disabled={isDisabled(
              WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL,
              WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES,
            )}
            error={isOptionalBlankError(
              WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL,
              WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES,
            )}
            maxLength={200}
          />
          {isOptionalBlankError(
            WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL,
            WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES,
          ) && (
            <ErrorMessage>
              {isOptionalBlankError(
                WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL,
                WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES,
              )}
            </ErrorMessage>
          )}
        </InputWrapper>
        {canToggleBetweenLanguages && (
          <InputWrapper>
            <Text
              id={WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES}
              label={getSpanishText(
                WorkspaceFieldTranslationKeys[
                  WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES
                ],
              )}
              value={
                workspaceSettings[
                  WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES
                ]
              }
              onChange={handleTextFieldChange}
              extraStyles={{ width: '100%' }}
              disabled={isDisabled(
                WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL,
                WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES,
              )}
              error={isOptionalBlankError(
                WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES,
                WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL,
              )}
              maxLength={200}
            />
            {isOptionalBlankError(
              WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES,
              WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL,
            ) && (
              <ErrorMessage>
                {isOptionalBlankError(
                  WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES,
                  WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL,
                )}
              </ErrorMessage>
            )}
          </InputWrapper>
        )}
      </LabelContainer>
      {hasPinLocationEnabled ? null : (
        <>
          <LabelContainer $offWhite>
            <InputWrapper>
              <Text
                id={WorkspaceFieldNames.ADDRESS_LABEL}
                label={getEnglishText(
                  WorkspaceFieldTranslationKeys[
                    WorkspaceFieldNames.ADDRESS_LABEL
                  ],
                )}
                value={workspaceSettings[WorkspaceFieldNames.ADDRESS_LABEL]}
                onChange={handleTextFieldChange}
                extraStyles={{ width: '100%' }}
                required
                error={isRequiredBlankError(WorkspaceFieldNames.ADDRESS_LABEL)}
                maxLength={200}
              />
              {isRequiredBlankError(WorkspaceFieldNames.ADDRESS_LABEL) && (
                <ErrorMessage>
                  {isRequiredBlankError(WorkspaceFieldNames.ADDRESS_LABEL)}
                </ErrorMessage>
              )}
            </InputWrapper>
            {canToggleBetweenLanguages && (
              <InputWrapper>
                <Text
                  id={WorkspaceFieldNames.ADDRESS_LABEL_ES}
                  label={getSpanishText(
                    WorkspaceFieldTranslationKeys[
                      WorkspaceFieldNames.ADDRESS_LABEL_ES
                    ],
                  )}
                  value={
                    workspaceSettings[WorkspaceFieldNames.ADDRESS_LABEL_ES]
                  }
                  onChange={handleTextFieldChange}
                  extraStyles={{ width: '100%' }}
                  required
                  error={isRequiredBlankError(
                    WorkspaceFieldNames.ADDRESS_LABEL_ES,
                  )}
                  maxLength={200}
                />
                {isRequiredBlankError(WorkspaceFieldNames.ADDRESS_LABEL_ES) && (
                  <ErrorMessage>
                    {isRequiredBlankError(WorkspaceFieldNames.ADDRESS_LABEL_ES)}
                  </ErrorMessage>
                )}
              </InputWrapper>
            )}
          </LabelContainer>
          <LabelContainer>
            <InputWrapper>
              <Text
                id={WorkspaceFieldNames.POSTCODE_LABEL}
                label={getEnglishText(
                  WorkspaceFieldTranslationKeys[
                    WorkspaceFieldNames.POSTCODE_LABEL
                  ],
                )}
                value={workspaceSettings[WorkspaceFieldNames.POSTCODE_LABEL]}
                onChange={handleTextFieldChange}
                extraStyles={{ width: '100%' }}
                required
                error={isRequiredBlankError(WorkspaceFieldNames.POSTCODE_LABEL)}
                maxLength={200}
              />
              {isRequiredBlankError(WorkspaceFieldNames.POSTCODE_LABEL) && (
                <ErrorMessage>
                  {isRequiredBlankError(WorkspaceFieldNames.POSTCODE_LABEL)}
                </ErrorMessage>
              )}
            </InputWrapper>
            {canToggleBetweenLanguages && (
              <InputWrapper>
                <Text
                  id={WorkspaceFieldNames.POSTCODE_LABEL_ES}
                  label={getSpanishText(
                    WorkspaceFieldTranslationKeys[
                      WorkspaceFieldNames.POSTCODE_LABEL_ES
                    ],
                  )}
                  value={
                    workspaceSettings[WorkspaceFieldNames.POSTCODE_LABEL_ES]
                  }
                  onChange={handleTextFieldChange}
                  extraStyles={{ width: '100%' }}
                  required
                  error={isRequiredBlankError(
                    WorkspaceFieldNames.POSTCODE_LABEL_ES,
                  )}
                  maxLength={200}
                />
                {isRequiredBlankError(
                  WorkspaceFieldNames.POSTCODE_LABEL_ES,
                ) && (
                  <ErrorMessage>
                    {isRequiredBlankError(
                      WorkspaceFieldNames.POSTCODE_LABEL_ES,
                    )}
                  </ErrorMessage>
                )}
              </InputWrapper>
            )}
          </LabelContainer>
        </>
      )}
    </Container>
  );
};

import { Button } from 'components/ui';
import { SimpleCarousel } from 'components/ui/Media/SimpleCarousel';
import { useText } from 'hooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as constants from 'utils/constants';
import { PhotoGrid } from './PhotoGrid';
import { Photo } from 'model/Media/Photo';
import RootState from 'model/State/RootState';
import { selectJobsView } from 'store/selectors';
import { EVENT_RAMS_PHOTO_ADD_BUTTON_CLICKED } from '../../constants';
import {
  ButtonContainer,
  EditText,
  GridContainer,
  ImageContainer,
  ImageTitle,
  TitleContainer,
} from './styled';

type Props = {
  onChange: (items: Photo[]) => void;
  photos: Photo[];
};

// PhotoList
export const PhotoList = ({ onChange, photos }: Props): JSX.Element => {
  const getText = useText();
  const { activeJob } = useSelector((state: RootState) => state.jobs);
  const view = useSelector(selectJobsView);

  const [selectedPhotos, setSelectedPhotos] = useState<Photo[]>(photos || []);
  const [photosAdded, setPhotosAdded] = useState<boolean>(false);

  useEffect(() => {
    if (photos.length > 0) {
      setPhotosAdded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onPhotoClick = (id) => {
    const isPhotoPresent = selectedPhotos.some(
      (existingPhoto) => existingPhoto.id === id,
    );
    let newPhotoList = [...selectedPhotos];
    if (isPhotoPresent) {
      newPhotoList = [
        ...selectedPhotos.filter((existingPhoto) => existingPhoto.id !== id),
      ];
    } else {
      const newPhoto = activeJob?.photos.find((jobPhoto) => jobPhoto.id === id);
      if (newPhoto) {
        newPhotoList.push(newPhoto);
      }
    }
    setSelectedPhotos(newPhotoList);
    onChange(newPhotoList);
  };

  const onAddClick = () => {
    onChange(selectedPhotos);
    setPhotosAdded(true);
  };

  return (
    <ImageContainer>
      {photosAdded ? (
        <GridContainer>
          <TitleContainer>
            <ImageTitle>{getText('rams_photo_list_title')}</ImageTitle>
            <EditText onClick={() => setPhotosAdded(false)}>
              {getText('rams_photo_list_edit')}
            </EditText>
          </TitleContainer>

          <PhotoGrid photos={selectedPhotos} />
        </GridContainer>
      ) : (
        <>
          <SimpleCarousel
            photos={activeJob?.photos ?? []}
            onPhotoClick={onPhotoClick}
            selectedPhotos={selectedPhotos}
            placeholder={getText('rams_photo_list_no_photo')}
          />
          <ButtonContainer>
            <Button.Primary
              text={getText('rams_photo_list_add_button')}
              extraStyles={{
                height: 45,
                width: 140,
                marginTop: 0,
                minWidth: 110,
              }}
              disabled={selectedPhotos.length === 0}
              onClick={onAddClick}
              event={EVENT_RAMS_PHOTO_ADD_BUTTON_CLICKED}
              eventProperties={{
                [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
              }}
            />
          </ButtonContainer>
        </>
      )}
    </ImageContainer>
  );
};

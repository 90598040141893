import { Cross } from 'assets/icons';
import { RamsStages } from 'model/Assessment/RAMS';
import { useText } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  BadgeContainer,
  ClearWrapper,
  SearchContainer,
  SearchSection,
  SelectedContainer,
  Title,
  TopSectionWrapper,
  VerticalSeparator,
} from './styled';
import { Badge, Button, Input } from 'components/ui';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { MemberList } from 'components/ui/TeamMembers/MemberList';
import { UserRoleBackend } from 'model/enum/UserRoleBackend';
import { selectActiveJob } from 'store/selectors';
import { UserRole } from 'model/enum/UserRole';
import { arraysHaveSameUsers } from 'helpers/users';
import { User } from 'model/User';
import {
  ActionButtons,
  ActionButtonsWrapper,
  SectionContentContainer,
} from '../styled';
import { EVENT_CONFIRM_TEAM_MEMBERS_SAVED } from '../constants';
import {
  selectActiveRamsAssessment,
  selectIsUpdatingSignOnList,
  setAssessmentStage,
  updateRamsSignOnList,
} from 'store/slices/ramsAssessments';
import { mapToUser } from 'api/User/helpers';

export const EditMembers = (): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const activeJob = useSelector(selectActiveJob);
  const assessment = useSelector(selectActiveRamsAssessment);
  const isUpdatingSignOnList = useSelector(selectIsUpdatingSignOnList);
  const [search, setSearch] = useState<string>('');
  const [searchFilter] = useDebounce(search, 600);
  const [selectedUsers, setSelectedUsers] = useState<User[]>(() => {
    if (assessment?.signedOnUsers && assessment.signedOnUsers?.length) {
      return assessment.signedOnUsers ?? [];
    }
    if (activeJob?.assignees && activeJob.assignees?.length) {
      return (
        activeJob.assignees
          ?.filter((assignee) => assignee.role === UserRole.FIELDWORKER)
          .map(mapToUser) ?? []
      );
    }
    return [];
  });

  const selectedUserIds = selectedUsers?.map((user: User) => user.id) ?? [];

  const signedOnUserIds =
    assessment?.signedOnUsers.map((user: User) => user.id) ?? [];

  const handleToggleSelect = (selectedUser) => {
    if (selectedUserIds.includes(selectedUser.id)) {
      clearUser(selectedUser);
    } else {
      setSelectedUsers((prevUsers) => [...prevUsers, mapToUser(selectedUser)]);
    }
  };

  const clearSelectedUsers = () => {
    setSelectedUsers([]);
  };

  const clearUser = (selectedUser) => {
    const modifiedUsers = selectedUsers.filter(
      (user) => user.id !== selectedUser.id,
    );

    setSelectedUsers(modifiedUsers);
  };

  const onSave = () => {
    if (assessment?.id) {
      dispatch(
        updateRamsSignOnList({
          assessmentId: assessment.id,
          userIds: selectedUserIds,
        }),
      );
    }
  };

  return (
    <SectionContentContainer>
      <TopSectionWrapper height={selectedUsers?.length ? '45%' : '25%'}>
        <Title>{getText('rams_sign_on_edit_members_title')}</Title>
        <SearchSection>
          <SearchContainer width={selectedUsers?.length > 0 ? 80 : 100}>
            <Input.Text
              id='search'
              placeholder={getText('search')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              isSearch
              extraStyles={{ maxHeight: '50px' }}
            />
          </SearchContainer>
          {selectedUsers?.length > 0 && (
            <ClearWrapper width={20}>
              <VerticalSeparator />
              <Button.Confirm
                onClick={clearSelectedUsers}
                text={getText('rams_sign_on_clear_all')}
                width='100px'
              />
            </ClearWrapper>
          )}
        </SearchSection>
        <SelectedContainer>
          {selectedUsers?.map((selectedUser) => (
            <BadgeContainer key={`selectedUser-${selectedUser.id}`}>
              <Badge.User
                user={selectedUser}
                onDelete={() => clearUser(selectedUser)}
                large
                vertical
                showRole={false}
                extraStyles={{ padding: '0 10px', width: '100%' }}
                extraTextStyles={{ alignItems: 'center', maxHeight: '60px' }}
              />
            </BadgeContainer>
          ))}
        </SelectedContainer>
      </TopSectionWrapper>
      <MemberList
        search={searchFilter}
        handleToggleSelect={(user) => handleToggleSelect(user)}
        selectedUserIds={selectedUserIds}
        userRoles={[UserRoleBackend.FIELDWORKER]}
        depotId={activeJob?.depot.id}
        showCurrentDepotOnly
        height={selectedUsers?.length ? '45%' : '65%'}
      />
      <ActionButtonsWrapper>
        <ActionButtons>
          <Button.Secondary
            text={getText('cancel')}
            onClick={() => dispatch(setAssessmentStage(RamsStages.signOn))}
            IconComponent={Cross}
            width='45%'
            disabled={isUpdatingSignOnList}
            extraStyles={{
              margin: 0,
              alignSelf: 'flex-end',
              height: 'inherit',
              maxHeight: '55px',
            }}
          />
          <Button.Primary
            text={getText('save')}
            onClick={onSave}
            width='45%'
            loading={isUpdatingSignOnList}
            disabled={
              isUpdatingSignOnList ||
              arraysHaveSameUsers(selectedUserIds, signedOnUserIds)
            }
            extraStyles={{
              margin: 0,
              alignSelf: 'flex-end',
              height: 'inherit',
              maxHeight: '55px',
            }}
            event={EVENT_CONFIRM_TEAM_MEMBERS_SAVED}
            eventProperties={{
              assessmentId: assessment?.id,
              assessmentType: assessment?.type,
              teamMembers: selectedUserIds,
            }}
          />
        </ActionButtons>
      </ActionButtonsWrapper>
    </SectionContentContainer>
  );
};

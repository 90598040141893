import styled from 'styled-components';
import theme from 'theme';
import { Autocomplete, Box } from '@mui/material';

const { colors, fonts, fontSizes } = theme;

export const Label = styled.label<{
  $large: boolean;
  disabled: boolean;
}>`
  font-family: ${fonts.bold};
  font-size: ${({ $large }) => ($large ? fontSizes.m : fontSizes.default)};
  color: ${({ color, disabled }) => {
    if (disabled) {
      return colors.darkGrey;
    }
    return color || colors.black;
  }};
  margin-bottom: 10px;
  margin-left: 0;
`;

export const RequiredLabel = styled.label`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.s};
  color: ${colors.darkGrey};
  margin-bottom: 10px;
  margin-left: auto;
`;

export const StyledAutocomplete = styled(
  ({ disabled, $large, renderInput, options, ...rest }) => (
    <Autocomplete
      disabled={disabled}
      renderInput={renderInput}
      options={options}
      {...rest}
    />
  ),
)`
  &.MuiAutocomplete-root {
    > ul {
      padding: 0;
    }
    color: ${({ disabled }) => (disabled ? colors.darkGrey : colors.black)};
    background-color: ${({ disabled }) =>
      disabled ? colors.offWhite : colors.white};
    border-radius: 0;
    border: 0;
    outline: ${({ disabled, noBorder }) => {
      if (noBorder) {
        return '0';
      }
      const color = disabled ? colors.grey : colors.black;
      return `2px solid ${color} !important`;
    }};
    &:hover {
      box-shadow: none;
      border: 0;
      outline: 3px solid
        ${({ disabled }) => {
          if (disabled) {
            return colors.grey;
          }
          return colors.black;
        }} !important;
      background-color: ${({ disabled }) =>
        disabled ? colors.offWhite : colors.white};
      transition: ease-out 0.2s;
    }
    &:focus-within {
      outline: 3px solid
        ${({ disabled }) => {
          if (disabled) {
            return colors.grey;
          }
          return colors.orange;
        }} !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
    .MuiOutlinedInput-root.Mui-disabled,
    .MuiOutlinedInput-input.Mui-disabled {
      cursor: not-allowed;
    }
  }
`;

export const StyledOptions = styled(
  ({ fitContent, $large, selected, ...rest }) => <Box {...rest} />,
)`
  &.MuiAutocomplete-option {
    white-space: break-spaces;

    height: ${({ fitContent, $large }) => {
      if (fitContent) {
        return '100%';
      }
      if ($large) {
        return '55px';
      }
      return '40px';
    }} !important;
    padding: 0 15px !important;
    align-items: center;
    font-size: ${fontSizes.default} !important;
    color: ${({ selected }) => (selected ? colors.white : colors.black)};
    background-color: ${({ selected }) =>
      selected ? colors.orange : colors.white};
  }
  &.MuiAutocomplete-option.Mui-focused {
    background-color: ${colors.orange} !important;
    color: ${colors.white};
  }
`;

import { PayloadAction } from '@reduxjs/toolkit';
import { Assessment } from 'api';
import { RamsStages } from 'model/Assessment/RAMS';
import { put, takeLatest, select } from 'redux-saga/effects';
import { setGlobalError } from 'store/slices/notifications';
import {
  sendMethodStatementRequest,
  sendMethodStatementSuccess,
  sendRamsAssessmentRequest,
  sendRamsAssessmentSuccess,
  setAssessmentStage,
  setRamsAssessment,
  setRamsAssessmentError,
  updateRamsSignOnList,
  updateRamsSignOnListError,
  updateRamsSignOnListSuccess,
} from 'store/slices/ramsAssessments';

function* sendRamsAssessment(action: PayloadAction<number>) {
  try {
    const { ratings } = yield select((state) => state.ramsAssessments);
    yield Assessment.editAssessment(action.payload, {
      ramsRatings: ratings,
    });
    yield put(sendRamsAssessmentSuccess());
  } catch (err) {
    yield put(setGlobalError(err));
    yield put(setRamsAssessmentError());
    console.warn(err);
  }
}

function* sendMethodStatement(action: PayloadAction<number>) {
  try {
    const { sections } = yield select((state) => state.ramsAssessments);
    yield Assessment.uploadMethodAssessment(action.payload, sections);
    yield put(sendMethodStatementSuccess());
  } catch (err) {
    yield put(setGlobalError(err));
    yield put(setRamsAssessmentError());
    console.warn(err);
  }
}

function* saveSignOnList(
  action: PayloadAction<{ assessmentId: number; userIds: number[] }>,
) {
  try {
    const response = yield Assessment.updateAssessmentSignOnList(
      action.payload.assessmentId,
      action.payload.userIds,
    );
    yield put(updateRamsSignOnListSuccess());
    yield put(setRamsAssessment(response));
    yield put(setAssessmentStage(RamsStages.signOn));
  } catch (err) {
    yield put(setGlobalError(err));
    yield put(updateRamsSignOnListError());
    console.warn(err);
  }
}

export default function* ramsAssessmentsWatcher() {
  yield takeLatest(sendRamsAssessmentRequest.type, sendRamsAssessment);
  yield takeLatest(sendMethodStatementRequest.type, sendMethodStatement);
  yield takeLatest(updateRamsSignOnList.type, saveSignOnList);
}

import { Paperplane } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { media } = theme;

export const LoginLinkContainer = styled.div`
  display: flex;
  justify-content: start;
  width: 100px;
  ${media.xl`
    margin: 0;
  `}
`;

export const PaperplaneIcon = styled(Paperplane)`
  height: 15px;
`;

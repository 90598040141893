import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Sort from 'model/Sort';
import ComplianceDataTableBase from '../ComplianceDataTableBase';
import { ComplianceType } from 'model/enum/ComplianceType';
import { useText } from 'hooks';
import HavsSignOff from './HavsSignOff';
import { formatHavsDateRange } from 'helpers/dates';
import { CommentsButton, PointsSum, WarningIcon } from './styled';
import { HavsComplianceColumnOrder } from './constants';
import {
  HavsComplianceData,
  HavsComplianceFields,
} from 'model/Insight/HavsComplianceData';

type Props = {
  data: any[];
  columns: object;
  total: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  sort: Sort;
  setSort: (value: Sort) => void;
  initialSort: Sort;
  analyticsData: object;
  isLoading: boolean;
  startDate: Date;
  endDate: Date;
  onCommentClick: (rowData: HavsComplianceData) => void;
};

// HavsComplianceDataTable
const HavsComplianceDataTable = ({
  data,
  columns,
  total,
  currentPage,
  onPageChange,
  sort,
  setSort,
  initialSort,
  analyticsData,
  isLoading,
  startDate,
  endDate,
  onCommentClick,
}: Props): JSX.Element => {
  const sortableColumns = [
    HavsComplianceFields.FULL_NAME,
    HavsComplianceFields.SUBMISSIONS,
    HavsComplianceFields.POINTS_BREACHES,
  ];
  const getText = useText();

  const tableColumns = HavsComplianceColumnOrder.map((key) => {
    const value = columns[key];

    return {
      field: key,
      headerName: value,
      sortable: sortableColumns.includes(key),
      renderCell: (props: GridRenderCellParams) => (
        <span>{props.row?.[key]}</span>
      ),
      ...(key === HavsComplianceFields.DATE_RANGE && {
        renderCell: (
          props: GridRenderCellParams<any, HavsComplianceData, any>,
        ) => <span>{formatHavsDateRange(props.row?.date_range)}</span>,
      }),
      ...(key === 'points_breaches' && {
        renderCell: (
          props: GridRenderCellParams<any, HavsComplianceData, any>,
        ) => {
          const isBreach = props.row?.points_breaches > 0;

          return (
            <>
              {isBreach && <WarningIcon />}
              <PointsSum $isRed={isBreach}>
                {props.row?.points_breaches}
              </PointsSum>
            </>
          );
        },
      }),
      ...(key === 'action' && {
        renderCell: (
          props: GridRenderCellParams<any, HavsComplianceData, any>,
        ) => (
          <CommentsButton onClick={() => onCommentClick(props.row)}>
            {getText('insights_forms_comments')}
          </CommentsButton>
        ),
      }),
      ...(key === 'signed_off' && {
        renderCell: (
          props: GridRenderCellParams<any, HavsComplianceData, any>,
        ) => (
          <HavsSignOff
            rowData={props.row}
            startDate={startDate}
            endDate={endDate}
          />
        ),
      }),
    };
  });
  return (
    <ComplianceDataTableBase
      data={data.map((item) => item as GridColDef)}
      columns={tableColumns}
      total={total}
      currentPage={currentPage}
      onPageChange={onPageChange}
      sort={sort}
      setSort={setSort}
      initialSort={initialSort}
      analyticsData={analyticsData}
      complianceType={ComplianceType.HAVS}
      isLoading={isLoading}
    />
  );
};

export default HavsComplianceDataTable;

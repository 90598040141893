import { PlusIcon, Cross } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const ContentWrapper = styled.div`
  height: calc(100% - 60px);
  width: 100%;
  ${mixins.flexColumn};
  ${media.sm`
    height: calc(100vh - 65px);
  `}
`;

export const Container = styled.div`
  margin: 25px 18px 10px 18px;
  ${media.sm`
    margin: 15px 15px 10px 15px;
  `}
`;

export const ItemList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px 18px 0 18px;
  margin-bottom: 15px;
  ${media.sm`
    padding: 10px 15px 0 15px;
    margin-bottom: 0;
  `}
`;

export const ItemRow = styled.div<{ width?: string }>`
  cursor: pointer;
  min-height: 65px;
  width: ${({ width }) => width};
  background-color: ${colors.black};
  padding: 0 15px;
  margin-bottom: 4px;
  border-radius: 3px;
  flex-shrink: 0;
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  ${mixins.flexBetween};
`;

export const ItemRowSelected = styled.div<{ width?: string }>`
  cursor: pointer;
  min-height: 65px;
  width: ${({ width }) => width};
  background-color: ${colors.lightGrey};
  padding: 0 15px;
  margin-bottom: 4px;
  border-radius: 3px;
  flex-shrink: 0;
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  ${mixins.flexBetween};
`;

export const P = styled.p`
  font-size: 16px;
  color: ${colors.white};
`;

export const PSelected = styled.p`
  font-size: 16px;
  color: ${colors.black};
`;

export const PlusIconStyled = styled(PlusIcon)`
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
  margin-left: 15px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const PSelectedSuggested = styled.p`
  font-size: 16px;
  color: ${colors.black};
`;

export const CrossIconStyled = styled(Cross)`
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
  margin-left: 15px;
  .fill-selector {
    fill: ${colors.black};
  }
`;

export const ErrorContainer = styled.div`
  min-height: 130px;
  max-height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { Tick } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media } = theme;

export const TextContainer = styled.div<{ $isLast?: boolean }>`
  position: absolute;
  top: 20px;
  ${({ $isLast }) => $isLast && `transform: translateX(-90%)`};
`;

export const StatusContainer = styled.div<{ $isLast?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  width: max-content;
  ${({ $isLast }) => $isLast && `text-align: right`};
`;

export const StatusTitle = styled.span`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.s};
  color: ${colors.black};
  ${media.xl`
    width: 100px;
    font-size: ${fontSizes.xs};
  `}
`;

export const Subtitle = styled.span`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.s};
  ${media.xl`
    width: 110px;
    font-size: ${fontSizes.xs};
  `}
`;

export const StatusIndicator = styled.div<{
  color: string;
  $isCircle?: boolean;
}>`
  width: 15px;
  height: 15px;
  background-color: ${({ color }) => color};
  display: block;
  position: relative;
  z-index: 2;
  ${({ $isCircle }) => $isCircle && `border-radius: 50%`};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Timeline = styled.div`
  padding: 15px 25px;
  background-color: rgba(231, 231, 231, 0.5);
  height: 80px;
  box-sizing: border-box;
`;

export const Line = styled.div<{ $isActive: boolean }>`
  border-style: solid;
  border-width: 2px 0;
  border-color: ${({ $isActive }) => ($isActive ? colors.blue : colors.grey)};
  height: 0;
  display: flex;
  flex-grow: 1;
`;

export const TickIcon = styled(Tick)`
  height: 9px;
  width: 9px;
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const TickContainer = styled.div`
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

import { all } from 'redux-saga/effects';
import websocketWatcher from './websockets';
import messagesWatcher from './messages';
import sessionWatcher from './session';
import jobsWatcher from './jobs';
import assessmentsWatcher from './assessments';
import commandCenterWatcher from './commandCenter';
import insightsWatcher from './insights';
import workspaceWatcher from './workspace';
import depotsWatcher from './depots';
import jobTypesWatcher from './jobTypes';
import locationsWatcher from './locations';
import formWatcher from './form';
import userWatcher from './user';
import fatigueWatcher from './fatigue';
import workspaceJobSubtypeWatcher from './settings/jobSubtype';
import workspaceHazardsWatcher from './settings/hazards';
import workspaceControlsWatcher from './settings/controls';
import workspaceAssessmentsWatcher from './settings/assessments';
import havsLimitWatcher from './settings/havsSettings';
import siteBriefingWatcher from './jobs/siteBriefing';
import formsWatcher from './forms';
import ramsAssessmentsWatcher from './jobs/ramsAssessments';
import documentsWatcher from './documents';
import evidencesWatcher from './job/evidences';
import workflowSettingsWatcher from './workflows';

export default function* rootSaga() {
  yield all([
    websocketWatcher(),
    messagesWatcher(),
    sessionWatcher(),
    jobsWatcher(),
    assessmentsWatcher(),
    ramsAssessmentsWatcher(),
    commandCenterWatcher(),
    insightsWatcher(),
    workspaceWatcher(),
    depotsWatcher(),
    jobTypesWatcher(),
    locationsWatcher(),
    formWatcher(),
    userWatcher(),
    fatigueWatcher(),
    workspaceJobSubtypeWatcher(),
    workspaceHazardsWatcher(),
    workspaceControlsWatcher(),
    workspaceAssessmentsWatcher(),
    havsLimitWatcher(),
    siteBriefingWatcher(),
    formsWatcher(),
    documentsWatcher(),
    evidencesWatcher(),
    workflowSettingsWatcher(),
  ]);
}

import FraFormSection from 'model/FatigueManager/FraFormSection';
import { Title, FormSections, NotLastSeparator } from '../styled';
import { FatigueRiskAssessmentField } from './FatigueRiskAssessmentField';
import { Fragment, memo, useCallback } from 'react';

type Props = {
  section: FraFormSection;
  sectionIndex: number;
  updateFieldForSection: (
    sectionIndex: number,
    fieldId: string,
    fieldnewFieldValue: string,
  ) => void;
  updateCustomUserInputFieldForSection: (
    sectionIndex: number,
    fieldId: string,
    fieldnewFieldValue: string,
  ) => void;
  formId: number;
  allFormSections: FraFormSection[];
  displayErrors: boolean;
};

export const Section: React.FC<Props> = memo(
  ({
    section,
    sectionIndex,
    updateFieldForSection,
    updateCustomUserInputFieldForSection,
    formId,
    displayErrors,
  }) => {
    const updateField = useCallback(
      (sectionIndex: number, fieldId: string, newValue: string) =>
        updateFieldForSection(sectionIndex, fieldId, newValue),
      [updateFieldForSection],
    );

    const updateCustomUserInputForField = useCallback(
      (sectionIndex: number, fieldId: string, newValue: string) =>
        updateCustomUserInputFieldForSection(sectionIndex, fieldId, newValue),
      [updateCustomUserInputFieldForSection],
    );

    return (
      <FormSections>
        {section.title?.trim() ? <Title>{section.title.trim()}</Title> : null}
        {section.fields.map((field) => (
          <Fragment key={`field-${field.id}`}>
            <FatigueRiskAssessmentField
              sectionIndex={sectionIndex}
              field={field}
              updateField={updateField}
              updateCustomUserInputForField={updateCustomUserInputForField}
              formId={formId}
              displayErrors={displayErrors}
            />
            <NotLastSeparator height={'10px'} />
          </Fragment>
        ))}
      </FormSections>
    );
  },
);

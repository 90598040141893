import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Navbar, Loader, Display } from 'components/ui';
import { useIsMobile, useLocalStorage, useTeams, useText } from 'hooks';
import { Job } from 'api';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import RootState from 'model/State/RootState';
import JobType from 'model/JobType';
import CustomForm from 'model/Forms/CustomForm';
import InsightDataRow from './InsightDataRow';
import InsightEngagement from './InsightEngagement';
import InsightJobBlocker from './InsightJobBlocker';
import InsightsUpsell from './InsightsUpsell';
import InsightCompliance from './Compliance/Insights';
import Tab from 'model/Tab/Tab';
import TabPanel from 'model/Tab/TabPanel';
import { InsightsTabKey } from 'model/enum/InsightsTabKey';
import { FilterBase } from 'components/ui/Filter/FilterBase';
import { sevenDaysAgoString, todayString } from 'helpers/dates';
import HavsCompliance from './Compliance/Havs';
import VehicleCheckCompliance from './Compliance/VehicleCheck';
import VehicleCheckUserCompliance from './Compliance/VehicleCheck/User';
import { setCurrentTab } from 'store/actions/insights';
import {
  selectActiveWorkspaceUuid,
  selectInsightsTab,
  selectUserHasPermission,
  selectWorkspaceSettings,
} from 'store/selectors';
import { FyldScreen } from 'model/enum/FyldScreen';
import { getSelectedMemberIds } from 'helpers/filter';
import InsightCustomForm from './InsightCustomForm';
import { CustomFormClass } from 'model/Form';
import { PdfExportInfoModal } from '../PdfExport/PdfExportInfoModal';
import Filters from 'model/Filters';

const { colors, fonts, fontSizes, mixins, media } = theme;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${colors.white};
  ${mixins.flexColumn};
  ${media.sm`
    height: 100vh;
 `}
`;

const H1 = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  margin-bottom: 8px !important;
  width: 25%;
  ${media.lg`
    width: 40%;
  `}
  ${media.md`
    width: 20%;
    font-size: ${fontSizes.xxl};
  `}
`;

const H4 = styled.h4`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
`;

const InsightsWrapper = styled.div`
  padding: 40px 0 0;
  height: 100%;
  ${media.sm`
    padding: 30px;
 `}
  overflow-y: auto;
  box-sizing: border-box;
`;

const Header = styled.div`
  height: 10%;
  display: flex;
  justify-content: space-between;
  padding: 0 80px 10px 80px;
  box-sizing: border-box;
  ${media.lg`
    height: 18%;
  `}
`;

const InsightDataRowSeperator = styled.div`
  border-bottom: 6px solid ${colors.lightGrey};
`;

const NoTabContainer = styled.div`
  font-size: ${fontSizes.l};
  height: 100%;
  ${mixins.flexFullCenter};
  background-color: ${colors.offWhite};
  margin-top: 40px;
`;

// Insights
const Insights = (): JSX.Element => {
  const isMobile = useIsMobile();
  const getText = useText();
  const dispatch = useDispatch();
  const selectedWorkspaceId = useSelector(selectActiveWorkspaceUuid);
  const { currentUser, depot } = useSelector(
    (state: RootState) => state.session,
  );
  const { havs_compliance_form_id } = useSelector(selectWorkspaceSettings);
  const activeWorkspaceId = useSelector(selectActiveWorkspaceUuid);
  const [repairTypeId, setRepairTypeId] = useState<number | undefined>();
  const [connectionsTypeId, setConnectionsTypeId] = useState<
    number | undefined
  >();
  const [replacementTypeId, setReplacementTypeId] = useState<
    number | undefined
  >();
  const activeInsightsTab = useSelector(selectInsightsTab);
  const [insightFilters, setInsightFilters] = useLocalStorage<Filters>(
    'insightFilters',
    {
      depot,
      startDate: sevenDaysAgoString,
      endDate: todayString,
      weekendIncluded: true,
      search: '',
      members: [],
    },
  );
  const { selectedTeam, setSelectedTeamForCurrentUser } = useTeams();

  const userForms =
    currentUser?.user_form_types?.filter(
      (form) =>
        form?.show_in_dashboard && form.workspace_uuid === activeWorkspaceId,
    ) ?? [];

  const jobForms =
    currentUser?.job_form_types?.filter(
      (form) =>
        form?.show_in_dashboard && form.workspace_uuid === activeWorkspaceId,
    ) ?? [];

  const permitForms =
    currentUser?.permit_form_types?.filter(
      (form) =>
        form?.show_in_dashboard && form.workspace_uuid === activeWorkspaceId,
    ) ?? [];

  const startDate = new Date(insightFilters?.startDate || sevenDaysAgoString);
  const endDate = new Date(insightFilters?.endDate || todayString);

  const eventByTab = {
    [InsightsTabKey.JOBS_PER_DAY]: constants.EVENT_JPD_LOADED,
    [InsightsTabKey.OUTPUT_PER_DAY]: constants.EVENT_OPD_LOADED,
    [InsightsTabKey.ENGAGEMENT]: constants.EVENT_ENGAGEMENT_LOADED,
    [InsightsTabKey.JOB_BLOCKERS]: constants.EVENT_JOB_BLOCKERS_LOADED,
  };

  useEffect(() => {
    (async () => {
      const {
        data: { results: types },
      } = await Job.getAllTypes(selectedWorkspaceId);

      setRepairTypeId(
        types.find((type: JobType) => type.title === 'Repair')?.id,
      );
      setConnectionsTypeId(
        types.find((type) => type.title === 'Connections')?.id,
      );
      setReplacementTypeId(
        types.find((type) => type.title === 'Replacement')?.id,
      );
    })();
  }, [selectedWorkspaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(setCurrentTab(getInitialTab()));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (insightFilters?.depot && insightFilters?.depot?.id) {
      const event = eventByTab?.[activeInsightsTab];
      if (event) {
        Analytics.trackEvent(event, {
          depotId: insightFilters?.depot?.id,
          startDate: startDate,
          endDate: endDate,
          isWeekendIncluded: insightFilters?.weekendIncluded,
        });
      }
    }
  }, [insightFilters, activeInsightsTab, startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabs: Tab[] = [
    {
      label: getText('insights_jobs_per_day_title'),
      value: InsightsTabKey.JOBS_PER_DAY,
      isShown: !!currentUser?.permissions?.includes('jpd_dashboard_v1'),
    },
    {
      label: getText('insights_output_per_day_title'),
      value: InsightsTabKey.OUTPUT_PER_DAY,
      isShown: !!currentUser?.permissions?.includes('jpd_output_dashboard_v1'),
    },
    {
      label: getText('insights_engagement_title'),
      value: InsightsTabKey.ENGAGEMENT,
      isShown: !!currentUser?.permissions?.includes('engagement_dashboard_v1'),
    },
    {
      label: getText('insights_compliance_title'),
      value: InsightsTabKey.COMPLIANCE,
      isShown: !!currentUser?.permissions?.includes('compliance_dashboard_v2'),
    },
    {
      label: getText('insights_job_blockers_title'),
      value: InsightsTabKey.JOB_BLOCKERS,
      isShown: !!currentUser?.permissions?.includes('job_blocker_dashboard_v1'),
    },
    {
      label: getText('insights_vehicle_check_compliance_title'),
      value: InsightsTabKey.VEHICLE_CHECK_COMPLIANCE,
      isShown: !!currentUser?.permissions?.includes('vehicle_check_dashboard'),
    },
    {
      label: getText('insights_vehicle_check_user_compliance_title'),
      value: InsightsTabKey.VEHICLE_CHECK_USER_COMPLIANCE,
      isShown: !!currentUser?.permissions?.includes('vehicle_check_dashboard'),
    },
    {
      label: getText('insights_havs_compliance_title'),
      value: InsightsTabKey.HAVS_COMPLIANCE,
      isShown: !!currentUser?.permissions?.includes(
        'havs_compliance_dashboard',
      ),
    },
    ...(userForms?.length
      ? userForms.map((form: CustomForm) => ({
          label: form.title,
          value: `${InsightsTabKey.USER_FORMS}-${form.id}`,
          isShown: true,
        }))
      : []),
    ...(jobForms?.length
      ? jobForms.map((form: CustomForm) => ({
          label: form.title,
          value: `${InsightsTabKey.JOB_FORMS}-${form.id}`,
          isShown: true,
        }))
      : []),
    ...(permitForms?.length
      ? permitForms.map((form: CustomForm) => ({
          label: form.title,
          value: `${InsightsTabKey.PERMIT_FORMS}-${form.id}`,
          isShown: true,
        }))
      : []),
  ];

  const canSwitchDepot = useSelector(
    selectUserHasPermission('switch_depot_v1'),
  );
  const { depot: defaultUserDepot } = useSelector(
    (state: RootState) => state.session,
  );

  const depotSelectionForTabsWithMandatoryDepotIdFilter =
    canSwitchDepot && insightFilters?.depot?.id === -1
      ? defaultUserDepot
      : insightFilters?.depot;

  const canRenderDataRow =
    insightFilters?.depot &&
    startDate &&
    endDate &&
    depotSelectionForTabsWithMandatoryDepotIdFilter;

  const tabPanels: TabPanel[] = [
    {
      value: InsightsTabKey.JOBS_PER_DAY,
      children: (
        <>
          {repairTypeId && canRenderDataRow ? (
            <InsightDataRow
              rowTitle={getText('insights_jobs_per_day_repair_title')}
              depotId={depotSelectionForTabsWithMandatoryDepotIdFilter.id}
              depotTitle={depotSelectionForTabsWithMandatoryDepotIdFilter.title}
              startDate={startDate}
              endDate={endDate}
              isWeekendIncluded={insightFilters?.weekendIncluded}
              typeId={repairTypeId}
            />
          ) : (
            <Loader />
          )}
          <InsightDataRowSeperator />
          {connectionsTypeId && canRenderDataRow ? (
            <InsightDataRow
              rowTitle={getText('insights_jobs_per_day_connections_title')}
              depotId={depotSelectionForTabsWithMandatoryDepotIdFilter?.id}
              depotTitle={
                depotSelectionForTabsWithMandatoryDepotIdFilter?.title
              }
              startDate={startDate}
              endDate={endDate}
              isWeekendIncluded={insightFilters?.weekendIncluded}
              typeId={connectionsTypeId}
            />
          ) : (
            <Loader />
          )}
        </>
      ),
    },
    {
      value: InsightsTabKey.OUTPUT_PER_DAY,
      children: (
        <>
          {replacementTypeId && canRenderDataRow ? (
            <InsightDataRow
              rowTitle={getText('insights_output_per_day_replacement_title')}
              depotId={depotSelectionForTabsWithMandatoryDepotIdFilter?.id}
              depotTitle={
                depotSelectionForTabsWithMandatoryDepotIdFilter?.title
              }
              startDate={startDate}
              endDate={endDate}
              isWeekendIncluded={insightFilters?.weekendIncluded}
              typeId={replacementTypeId}
              isOutputPerDay
            />
          ) : (
            <Loader />
          )}
        </>
      ),
    },
    {
      value: InsightsTabKey.ENGAGEMENT,
      children: (
        <>
          {insightFilters?.depot && startDate && endDate ? (
            <InsightEngagement
              depotId={insightFilters?.depot.id}
              startDate={startDate}
              endDate={endDate}
              isWeekendIncluded={insightFilters?.weekendIncluded}
            />
          ) : (
            <Loader />
          )}
        </>
      ),
    },
    {
      value: InsightsTabKey.COMPLIANCE,
      children: (
        <>
          {insightFilters?.depot && startDate && endDate ? (
            <InsightCompliance
              depotId={insightFilters?.depot.id}
              search={insightFilters?.search}
              startDate={startDate}
              endDate={endDate}
              isWeekendIncluded={insightFilters?.weekendIncluded}
              userFilters={getSelectedMemberIds(insightFilters, selectedTeam)}
            />
          ) : (
            <Loader />
          )}
        </>
      ),
    },
    {
      value: InsightsTabKey.JOB_BLOCKERS,
      children: (
        <>
          {insightFilters?.depot && startDate && endDate ? (
            <InsightJobBlocker
              depotId={insightFilters?.depot.id}
              startDate={startDate}
              endDate={endDate}
              isWeekendIncluded={insightFilters?.weekendIncluded}
            />
          ) : (
            <Loader />
          )}
        </>
      ),
    },
    {
      value: InsightsTabKey.VEHICLE_CHECK_COMPLIANCE,
      children: (
        <>
          {insightFilters?.depot && startDate && endDate ? (
            <VehicleCheckCompliance
              depotId={insightFilters?.depot.id}
              search={insightFilters?.search}
              startDate={startDate}
              endDate={endDate}
              isWeekendIncluded={insightFilters?.weekendIncluded}
              userFilters={getSelectedMemberIds(insightFilters, selectedTeam)}
            />
          ) : (
            <Loader />
          )}
        </>
      ),
    },
    {
      value: InsightsTabKey.VEHICLE_CHECK_USER_COMPLIANCE,
      children: (
        <>
          {insightFilters?.depot && startDate && endDate ? (
            <VehicleCheckUserCompliance
              depotId={insightFilters?.depot.id}
              search={insightFilters?.search}
              startDate={startDate}
              endDate={endDate}
              isWeekendIncluded={insightFilters?.weekendIncluded}
              userFilters={getSelectedMemberIds(insightFilters, selectedTeam)}
            />
          ) : (
            <Loader />
          )}
        </>
      ),
    },
    {
      value: InsightsTabKey.HAVS_COMPLIANCE,
      children: (
        <>
          {insightFilters?.depot && startDate && endDate ? (
            <HavsCompliance
              depotId={insightFilters?.depot.id}
              search={insightFilters?.search}
              startDate={startDate}
              endDate={endDate}
              isWeekendIncluded={insightFilters?.weekendIncluded}
              userFilters={getSelectedMemberIds(insightFilters, selectedTeam)}
            />
          ) : (
            <Loader />
          )}
        </>
      ),
    },
    ...(userForms?.length
      ? userForms.map((form: CustomForm) => ({
          value: `${InsightsTabKey.USER_FORMS}-${form.id}`,
          children: (
            <>
              {insightFilters?.depot && startDate && endDate && (
                <InsightCustomForm
                  form={form}
                  formClass={CustomFormClass.USER}
                  depotId={insightFilters?.depot?.id}
                  search={insightFilters?.search}
                  startDate={startDate}
                  endDate={endDate}
                  selectedMemberIds={getSelectedMemberIds(
                    insightFilters,
                    selectedTeam,
                  )}
                  isWeekendIncluded={insightFilters?.weekendIncluded}
                  isHavsForm={form.id === havs_compliance_form_id}
                />
              )}
            </>
          ),
        }))
      : []),
    ...(jobForms?.length
      ? jobForms.map((form: CustomForm) => ({
          value: `${InsightsTabKey.JOB_FORMS}-${form.id}`,
          children: (
            <>
              {insightFilters?.depot && startDate && endDate && (
                <InsightCustomForm
                  form={form}
                  formClass={CustomFormClass.JOB}
                  depotId={insightFilters?.depot?.id}
                  search={insightFilters?.search}
                  startDate={startDate}
                  endDate={endDate}
                  selectedMemberIds={getSelectedMemberIds(
                    insightFilters,
                    selectedTeam,
                  )}
                  isWeekendIncluded={insightFilters?.weekendIncluded}
                  isHavsForm={false}
                />
              )}
            </>
          ),
        }))
      : []),
    ...(permitForms?.length
      ? permitForms.map((form: CustomForm) => ({
          value: `${InsightsTabKey.PERMIT_FORMS}-${form.id}`,
          children: (
            <>
              {insightFilters?.depot && startDate && endDate && (
                <InsightCustomForm
                  form={form}
                  formClass={CustomFormClass.PERMIT}
                  depotId={insightFilters?.depot?.id}
                  search={insightFilters?.search}
                  startDate={startDate}
                  endDate={endDate}
                  selectedMemberIds={getSelectedMemberIds(
                    insightFilters,
                    selectedTeam,
                  )}
                  isWeekendIncluded={insightFilters?.weekendIncluded}
                  isHavsForm={false}
                />
              )}
            </>
          ),
        }))
      : []),
  ];

  const getInitialTab = (): string | null => {
    if (currentUser?.permissions?.includes('jpd_dashboard_v1')) {
      return InsightsTabKey.JOBS_PER_DAY;
    } else if (currentUser?.permissions?.includes('jpd_output_dashboard_v1')) {
      return InsightsTabKey.OUTPUT_PER_DAY;
    } else if (currentUser?.permissions?.includes('engagement_dashboard_v1')) {
      return InsightsTabKey.ENGAGEMENT;
    } else if (currentUser?.permissions?.includes('compliance_dashboard_v2')) {
      return InsightsTabKey.COMPLIANCE;
    } else if (currentUser?.permissions?.includes('job_blocker_dashboard_v1')) {
      return InsightsTabKey.JOB_BLOCKERS;
    } else if (userForms?.length) {
      return `${InsightsTabKey.USER_FORMS}-${userForms[0]?.id}`;
    } else if (jobForms?.length) {
      return `${InsightsTabKey.JOB_FORMS}-${jobForms[0]?.id}`;
    }
    return null;
  };

  const onTabChange = (key) => {
    dispatch(setCurrentTab(key));
  };

  const hasInsightsPermission = [
    'compliance_dashboard_v2',
    'jpd_dashboard_v1',
    'jpd_output_dashboard_v1',
    'engagement_dashboard_v1',
    'job_blocker_dashboard_v1',
  ].some((validPermission) =>
    currentUser?.permissions?.includes(validPermission),
  );

  const allowsAllDepots = !(
    activeInsightsTab === InsightsTabKey.JOBS_PER_DAY ||
    activeInsightsTab === InsightsTabKey.OUTPUT_PER_DAY
  );

  return (
    <Wrapper>
      <Navbar home mobileOnly />
      {isMobile ? (
        <InsightsWrapper>
          <H1>{getText('insights')}</H1>
          <H4>{getText('insights_only_desktop')}</H4>
        </InsightsWrapper>
      ) : (
        <InsightsWrapper>
          {hasInsightsPermission ? (
            <>
              <Header>
                <H1 id='depot-name'>
                  {allowsAllDepots
                    ? insightFilters?.depot?.title
                    : depotSelectionForTabsWithMandatoryDepotIdFilter?.title}
                </H1>
                <FilterBase
                  screen={FyldScreen.INSIGHTS}
                  filters={{
                    ...insightFilters,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                  }}
                  setFilters={setInsightFilters}
                  hasSearchInput={
                    !!activeInsightsTab &&
                    ((
                      [
                        InsightsTabKey.USER_FORMS,
                        InsightsTabKey.JOB_FORMS,
                        InsightsTabKey.COMPLIANCE,
                        InsightsTabKey.HAVS_COMPLIANCE,
                        InsightsTabKey.VEHICLE_CHECK_USER_COMPLIANCE,
                      ] as string[]
                    ).includes(activeInsightsTab) ||
                      activeInsightsTab.startsWith(InsightsTabKey.USER_FORMS) ||
                      activeInsightsTab.startsWith(InsightsTabKey.JOB_FORMS))
                  }
                  hasUserFilter={
                    !!activeInsightsTab &&
                    ((
                      [
                        InsightsTabKey.USER_FORMS,
                        InsightsTabKey.JOB_FORMS,
                        InsightsTabKey.COMPLIANCE,
                        InsightsTabKey.HAVS_COMPLIANCE,
                        InsightsTabKey.VEHICLE_CHECK_USER_COMPLIANCE,
                      ] as string[]
                    ).includes(activeInsightsTab) ||
                      activeInsightsTab.startsWith(InsightsTabKey.USER_FORMS) ||
                      activeInsightsTab.startsWith(InsightsTabKey.JOB_FORMS))
                  }
                  hasDateRangeFilter
                  hasWeekendFilter
                  selectedTeam={selectedTeam}
                  setSelectedTeam={setSelectedTeamForCurrentUser}
                />
              </Header>
              {insightFilters?.depot ? (
                activeInsightsTab ? (
                  <Display.TabView
                    value={activeInsightsTab}
                    onChange={(_, newValue) => onTabChange(newValue)}
                    tabs={tabs}
                    tabPanels={tabPanels}
                  />
                ) : (
                  <NoTabContainer>
                    {getText('insights_no_permission')}
                  </NoTabContainer>
                )
              ) : (
                <Display.MissingDepot />
              )}
            </>
          ) : (
            <InsightsUpsell />
          )}
        </InsightsWrapper>
      )}
      <PdfExportInfoModal />
    </Wrapper>
  );
};
export default Insights;

import { Button, Input, Modal } from 'components/ui';
import { useText } from 'hooks';
import { useState } from 'react';
import {
  ButtonContainer,
  ConfirmWrapper,
  Container,
  Title,
  WarningContainer,
  WarningIcon,
} from './styled';

type Props = {
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  warningMessages: string[];
  saveButtonText: string;
  onButtonClick: () => void;
  hasConfirmToggle?: boolean;
};

export const AssessmentDeleteConfirmationModal = ({
  isVisible,
  setVisible,
  title,
  warningMessages,
  saveButtonText,
  onButtonClick,
  hasConfirmToggle = false,
}: Props): JSX.Element => {
  const getText = useText();
  const [isConfirmChecked, setConfirmChecked] = useState<boolean>(false);

  return (
    <Modal.Small isOpen={isVisible} onClose={() => setVisible(false)}>
      <Container>
        <Title>{title}</Title>
        {warningMessages.map((warningMessage, index) => (
          <WarningContainer key={`warning-${index}`}>
            <WarningIcon />
            <div>{warningMessage}</div>
          </WarningContainer>
        ))}
        {hasConfirmToggle && (
          <ConfirmWrapper>
            <Input.Checkbox
              checked={isConfirmChecked}
              onChange={() => setConfirmChecked(!isConfirmChecked)}
              label={getText('workspace_settings_delete_understood')}
              labelPlacement='end'
              isSmall
            />
          </ConfirmWrapper>
        )}
        <ButtonContainer>
          <Button.Confirm
            onClick={() => setVisible(false)}
            text={getText('cancel')}
            width='130px'
          />
          <Button.Confirm
            onClick={onButtonClick}
            text={saveButtonText}
            width='130px'
            disabled={hasConfirmToggle && !isConfirmChecked}
          />
        </ButtonContainer>
      </Container>
    </Modal.Small>
  );
};

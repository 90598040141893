import { useText } from 'hooks';
import { H2, InfoText, Wrapper } from './styled';
import { useEffect, useState } from 'react';
import WorkspaceAssessmentItem from 'model/State/WorkspaceAssessmentItem';
import { Input, Loader } from 'components/ui';
import { getLocalizedValue } from 'helpers/locales';
import { TITLE_PROPERTY_NAME } from '../../constants';
import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import {
  selectAssessments,
  selectIsFetchingAssessments,
} from 'store/selectors/settings/assessments';
import RootState from 'model/State/RootState';
import { HazardsReview } from './HazardsReview';

export const AssessmentHazardsSettings = (): JSX.Element => {
  const getText = useText();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const assessments = useSelector(selectAssessments(selectedWorkspace?.uuid));
  const isFetchingAssessments = useSelector(selectIsFetchingAssessments);
  const [selectedAssessment, setSelectedAssessment] =
    useState<WorkspaceAssessmentItem | null>(null);

  const getOptions = () =>
    assessments?.map((assessment) => ({
      id: assessment.id,
      title: getLocalizedValue(
        assessment,
        TITLE_PROPERTY_NAME,
        globalAppLanguage,
      ),
    })) ?? [];

  useEffect(() => {
    if (selectedAssessment?.id) {
      setSelectedAssessment(
        assessments.find(
          (assessment) => assessment.id === selectedAssessment.id,
        ) ?? null,
      );
    }
  }, [assessments]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      {isFetchingAssessments ? (
        <Loader />
      ) : (
        <>
          <H2>{getText('workspace_settings_risk_assessment_edit')}</H2>
          <InfoText $bold>
            {getText('workspace_settings_risk_assessment_edit_section_info')}
          </InfoText>

          <Input.Select
            id='assessment-select'
            label={getText('workspace_settings_risk_assessment_name')}
            hint={getText('workspace_settings_risk_assessment_select')}
            selected={
              selectedAssessment
                ? {
                    id: selectedAssessment.id,
                    title: getLocalizedValue(
                      selectedAssessment,
                      TITLE_PROPERTY_NAME,
                      globalAppLanguage,
                    ),
                  }
                : null
            }
            options={getOptions()}
            onChange={(e) => {
              const assessment = assessments.find(
                (item) => item.id === e?.target?.value,
              );
              setSelectedAssessment(assessment ?? null);
            }}
            extraStyles={{ width: '25%', marginTop: '30px' }}
            large
            fitContent
          />
          {selectedAssessment && (
            <HazardsReview assessment={selectedAssessment} />
          )}
        </>
      )}
    </Wrapper>
  );
};

import {
  ADD_WORKSPACE_HAZARD,
  ADD_WORKSPACE_HAZARD_ERROR,
  ADD_WORKSPACE_HAZARD_SUCCESS,
  CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT,
  CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT_ERROR,
  CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT_SUCCESS,
  CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS,
  CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS_ERROR,
  CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS_SUCCESS,
  EDIT_WORKSPACE_HAZARD,
  EDIT_WORKSPACE_HAZARD_ERROR,
  EDIT_WORKSPACE_HAZARD_SUCCESS,
  FETCH_WORKSPACE_HAZARDS,
  FETCH_WORKSPACE_HAZARDS_ERROR,
  FETCH_WORKSPACE_HAZARDS_SUCCESS,
  REMOVE_WORKSPACE_HAZARD,
  REMOVE_WORKSPACE_HAZARD_ERROR,
  REMOVE_WORKSPACE_HAZARD_SUCCESS,
  RESET_WORKSPACE_HAZARD_ERROR,
  UPDATE_WORKSPACE_HAZARD_ASSIGNED_CONTROL_LIST,
} from '../actionTypes';

export const fetchWorkspaceHazards = (workspaceUuid) => ({
  type: FETCH_WORKSPACE_HAZARDS,
  workspaceUuid,
});

export const fetchWorkspaceHazardsSuccess = (workspaceUuid, data) => ({
  type: FETCH_WORKSPACE_HAZARDS_SUCCESS,
  workspaceUuid,
  data,
});

export const fetchWorkspaceHazardsError = () => ({
  type: FETCH_WORKSPACE_HAZARDS_ERROR,
});

export const addNewHazard = (workspaceUuid, hazardTitles) => ({
  type: ADD_WORKSPACE_HAZARD,
  workspaceUuid,
  hazardTitles,
});

export const addNewHazardSuccess = (workspaceUuid, hazardList, data) => ({
  type: ADD_WORKSPACE_HAZARD_SUCCESS,
  workspaceUuid,
  hazardList,
  data,
});

export const addNewHazardError = (data) => ({
  type: ADD_WORKSPACE_HAZARD_ERROR,
  data,
});

export const editExistingHazard = (workspaceUuid, hazardTitles) => ({
  type: EDIT_WORKSPACE_HAZARD,
  workspaceUuid,
  hazardTitles,
});

export const editExistingHazardSuccess = (workspaceUuid, hazardList, data) => ({
  type: EDIT_WORKSPACE_HAZARD_SUCCESS,
  workspaceUuid,
  hazardList,
  data,
});

export const editExistingHazardError = (data) => ({
  type: EDIT_WORKSPACE_HAZARD_ERROR,
  data,
});

export const removeExistingHazard = (workspaceUuid, hazard) => ({
  type: REMOVE_WORKSPACE_HAZARD,
  workspaceUuid,
  hazard,
});

export const removeExistingHazardSuccess = (workspaceUuid, id) => ({
  type: REMOVE_WORKSPACE_HAZARD_SUCCESS,
  workspaceUuid,
  id,
});

export const removeExistingHazardError = (data) => ({
  type: REMOVE_WORKSPACE_HAZARD_ERROR,
  data,
});

export const resetError = () => ({
  type: RESET_WORKSPACE_HAZARD_ERROR,
});

export const changeHazardMajorStatus = (workspaceUuid, hazard) => ({
  type: CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS,
  workspaceUuid,
  hazard,
});

export const changeHazardMajorStatusSuccess = (workspaceUuid, data) => ({
  type: CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS_SUCCESS,
  workspaceUuid,
  data,
});

export const changeHazardMajorStatusError = (data) => ({
  type: CHANGE_WORKSPACE_HAZARD_MAJOR_STATUS_ERROR,
  data,
});

export const updateHazardAssignedControlList = (
  workspaceUuid,
  hazardId,
  controlId,
) => ({
  type: UPDATE_WORKSPACE_HAZARD_ASSIGNED_CONTROL_LIST,
  workspaceUuid,
  hazardId,
  controlId,
});

export const changeHazardLinkedToAssessment = (
  workspaceUuid,
  assessmentId,
  hazard,
) => ({
  type: CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT,
  workspaceUuid,
  assessmentId,
  hazard,
});

export const changeHazardLinkedToAssessmentSuccess = (
  workspaceUuid,
  assessmentId,
  hazard,
) => ({
  type: CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT_SUCCESS,
  workspaceUuid,
  assessmentId,
  hazard,
});

export const changeHazardLinkedToAssessmentError = (
  workspaceUuid,
  assessmentId,
  hazardId,
) => ({
  type: CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT_ERROR,
  workspaceUuid,
  assessmentId,
  hazardId,
});

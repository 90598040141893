import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Arrow } from 'assets/icons';
import { Button as MaterialButton } from '@mui/material';

const { media, colors, fonts, fontSizes, mixins } = theme;

export const Button = styled(
  ({ width, disabled, isDark, hasBlackBackground, $iconFront, ...rest }) => (
    <MaterialButton disabled={disabled} {...rest} />
  ),
)`
  &.MuiButtonBase-root {
    height: 55px;
    width: ${({ width }) => width};
    background-color: ${({ isDark }) =>
      isDark ? colors.black : colors.white} !important;
    box-sizing: border-box;
    border-radius: 0;
    ${({ $iconFront }) =>
      $iconFront && `justify-content: flex-start !important;`}
    outline: ${({ disabled, hasBlackBackground }) => {
      if (hasBlackBackground) {
        return disabled
          ? `2px solid ${colors.black}`
          : `2px solid ${colors.grey}`;
      }

      return disabled
        ? `2px solid ${colors.grey}`
        : `2px solid ${colors.black}`;
    }};
    padding: 0 20px;
    color: ${({ isDark }) => (isDark ? colors.white : colors.black)};
    font-family: ${fonts.bold};
    font-size: ${fontSizes.l};
    ${mixins.flexBetween};
    text-transform: none;
    letter-spacing: 0;
    margin: 2px;
    &:hover {
      outline: 3px solid
        ${({ isDark, hasBlackBackground }) => {
          if (hasBlackBackground) {
            return colors.lightGrey;
          }

          return isDark ? colors.white : colors.black;
        }};
      background-color: ${({ isDark }) =>
        isDark ? colors.black : colors.white} !important;
    }
    &.Mui-disabled {
      outline: 2px solid ${colors.grey};
      background-color: ${colors.lightGrey} !important;
      color: ${colors.black};
      &:hover {
        outline: 2px solid ${colors.grey};
        background-color: ${colors.lightGrey} !important;
      }
    }
    ${media.sm`
      margin: 10px 0;
    `}
    ${media.xxl`
      font-size: ${fontSizes.m};
      padding: 0 10px;
    `}
    ${media.xl`
      height: 45px;
      font-size: ${fontSizes.s};
      padding: 0 10px;
    `}
  }
`;

export const Description = styled.div<{
  disabled: boolean;
  $iconFront: boolean;
}>`
  display: flex;
  width: 80%;
  order: ${({ $iconFront }) => ($iconFront ? 1 : 0)};
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
`;

export const Icon = styled(({ component, $iconFront, ...props }) =>
  React.cloneElement(component, props),
)`
  max-width: 20%;
  height: 25px;
  width: 25px;
  order: ${({ $iconFront }) => ($iconFront ? 0 : 1)};
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
  ${({ $iconFront }) => $iconFront && `margin-right: 15px;`}

  ${media.xl`
    height: 20px;
    width: 20px;
  `}
`;

export const ArrowRight = styled(Arrow)`
  max-width: 20%;
  height: 20px;
  .fill-selector {
    fill: ${colors.black};
  }
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
`;

import { Tick } from 'assets/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins } = theme;

export const Container = styled.div<{ $marginTop: number }>`
  margin-top: ${({ $marginTop }) => $marginTop}px;
`;

export const SectionTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 24px;
`;

export const ContentWrapper = styled.div`
  ${mixins.flexCenter};
  flex-direction: row;
  padding-bottom: 16px;
  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const IconContainer = styled.div`
  margin-right: 10px;
`;

export const TickIcon = styled(Tick)`
  min-width: 25px;
  min-height: 25px;
  .fill-selector {
    fill: ${colors.green};
  }
`;

export const UserName = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
`;

export const SignDate = styled.div`
  color: ${colors.darkGrey};
  font-family: ${fonts.light};
`;

export const DigitalSignatureLink = styled(Link)`
  color: ${colors.black};
  font-family: ${fonts.bold};
  :hover {
    cursor: pointer;
  }
`;

export const AppSignOn = styled.span`
  color: ${colors.darkGrey};
`;

export const NotSignedContainer = styled.div`
  ${mixins.flexCenter};
`;

export const NotSignedText = styled.span`
  margin-left: 5px;
  font-family: ${fonts.lightItalic};
`;

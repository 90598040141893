import { WorkspaceItemType } from 'model/AdminPanel/WorkspaceSettings/WorkspaceItemType';
import { WorkspaceItemStatus } from 'model/enum/WorkspaceItemStatus';
import * as Analytics from 'utils/analytics';

export function getStatusForUpdate(
  id: number | null,
  originalType: WorkspaceItemType | undefined,
  englishText: string,
  spanishText: string,
  unitEnglishText?: string,
  unitSpanishText?: string,
): WorkspaceItemStatus | undefined {
  if (!id) {
    return WorkspaceItemStatus.ADDED;
  } else if (
    originalType?.title !== englishText ||
    originalType?.titleEs !== spanishText ||
    (unitEnglishText && originalType?.unitTitle !== unitEnglishText) ||
    (unitSpanishText && originalType?.unitTitleEs !== unitSpanishText)
  ) {
    return WorkspaceItemStatus.EDITED;
  }
  return undefined;
}

export function addItem(
  typeList: WorkspaceItemType[],
  englishText: string,
  spanishText: string,
  event: string,
  unitEnglishText?: string,
  unitSpanishText?: string,
  addProperty?: (item: WorkspaceItemType) => void,
): WorkspaceItemType[] {
  const newType = {
    id: null,
    title: englishText,
    titleEs: spanishText,
    status: WorkspaceItemStatus.ADDED,
    unitTitle: unitEnglishText ?? '',
    unitTitleEs: unitSpanishText ?? '',
  } as WorkspaceItemType;

  if (addProperty) {
    addProperty(newType);
  }

  Analytics.trackEvent(event, {
    newType,
  });
  return [...typeList, newType];
}

export function editItem(
  initialTypeList: WorkspaceItemType[],
  typeList: WorkspaceItemType[],
  index: number,
  englishText: string,
  spanishText: string,
  event: string,
  unitEnglishText?: string,
  unitSpanishText?: string,
  addProperty?: (item: WorkspaceItemType) => void,
): WorkspaceItemType[] {
  const original: WorkspaceItemType | undefined = initialTypeList.find(
    (type) => type.id === typeList[index]?.id,
  );
  const editedType = {
    ...typeList[index],
    title: englishText,
    titleEs: spanishText,
    status: getStatusForUpdate(
      typeList[index]?.id,
      original,
      englishText,
      spanishText,
      unitEnglishText,
      unitSpanishText,
    ),
    unitTitle: unitEnglishText,
    unitTitleEs: unitSpanishText,
  };

  if (addProperty) {
    addProperty(editedType);
  }

  Analytics.trackEvent(event, {
    editedType,
  });
  return typeList.map((type, i) => (index === i ? editedType : type));
}

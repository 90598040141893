import theme from 'theme';
import { TagContainer, WarningIcon } from './styled';

type Props = {
  text: string;
  fitContent?: boolean;
  hasSmallIcon?: boolean;
  iconColor?: string;
  extraStyles?: React.CSSProperties;
};

export const MajorHazardTag = ({
  text,
  fitContent,
  hasSmallIcon,
  iconColor,
  extraStyles,
}: Props): JSX.Element => (
  <TagContainer
    color={theme.colors.white}
    $backgroundColor={theme.colors.black}
    $fitContent={fitContent}
    style={extraStyles}
  >
    <WarningIcon $isSmall={hasSmallIcon} color={iconColor} />
    {text}
  </TagContainer>
);

import { AssessmentSettings } from 'api';
import {
  EVENT_ASSESSMENT_TYPE_ADDED,
  EVENT_ASSESSMENT_TYPE_DELETED,
  EVENT_ASSESSMENT_TYPE_SAVE_CHANGES,
} from 'components/views/Protected/Jobs/Settings/constants';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  ADD_WORKSPACE_ASSESSMENT,
  CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT,
  EDIT_WORKSPACE_ASSESSMENT,
  FETCH_WORKSPACE_ASSESSMENTS,
  REMOVE_WORKSPACE_ASSESSMENT,
} from 'store/actions/actionTypes';
import { setGlobalError } from 'store/slices/notifications';
import {
  addNewAssessmentError,
  addNewAssessmentSuccess,
  editExistingAssessmentError,
  editExistingAssessmentSuccess,
  fetchWorkspaceAssessmentsError,
  fetchWorkspaceAssessmentsSuccess,
  removeExistingAssessmentError,
  removeExistingAssessmentSuccess,
  toggleHazardAssessmentLinking,
} from 'store/actions/settings/assessments';
import {
  changeHazardLinkedToAssessmentError,
  changeHazardLinkedToAssessmentSuccess,
} from 'store/actions/settings/hazards';
import { Languages } from 'utils/constants';
import * as Logger from 'utils/logger';
import * as Analytics from 'utils/analytics';
import { selectOrganizationId } from 'store/selectors';

function* fetchAssessments({ workspaceUuid }) {
  try {
    const data = yield AssessmentSettings.getAssessments(workspaceUuid);

    yield put(fetchWorkspaceAssessmentsSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchWorkspaceAssessmentsError());
    yield put(setGlobalError(err));
  }
}

function* addAssessment({ workspaceUuid, assessmentTitles }) {
  const assessment = {
    title: assessmentTitles?.[Languages.english.locale],
    title_es: assessmentTitles?.[Languages.spanish.locale],
  };
  try {
    const organisationId = yield select(selectOrganizationId);
    const data = yield AssessmentSettings.addAssessment(
      workspaceUuid,
      assessment,
    );

    yield put(addNewAssessmentSuccess(workspaceUuid, data));
    Analytics.trackEvent(EVENT_ASSESSMENT_TYPE_ADDED, {
      title: assessment.title,
      titleEs: assessment.titleEs,
      workspaceUuid,
      organisationId,
    });
  } catch (err) {
    Logger.error(err);
    yield put(addNewAssessmentError(assessment));
    yield put(setGlobalError(err));
  }
}

function* deleteAssessment({ workspaceUuid, assessment }) {
  try {
    const organisationId = yield select(selectOrganizationId);
    yield AssessmentSettings.deleteAssessment(workspaceUuid, assessment?.id);

    yield put(removeExistingAssessmentSuccess(workspaceUuid, assessment?.id));
    Analytics.trackEvent(EVENT_ASSESSMENT_TYPE_DELETED, {
      title: assessment.title,
      titleEs: assessment.titleEs,
      workspaceUuid,
      organisationId,
    });
  } catch (err) {
    Logger.error(err);
    yield put(removeExistingAssessmentError(assessment));
    yield put(setGlobalError(err));
  }
}

function* editAssessment({ workspaceUuid, assessmentTitles }) {
  const assessment = {
    ...assessmentTitles,
    title: assessmentTitles?.[Languages.english.locale],
    titleEs: assessmentTitles?.[Languages.spanish.locale],
  };
  try {
    const organisationId = yield select(selectOrganizationId);
    const data = yield AssessmentSettings.updateAssessment(
      workspaceUuid,
      assessment,
    );

    yield put(editExistingAssessmentSuccess(workspaceUuid, data));
    Analytics.trackEvent(EVENT_ASSESSMENT_TYPE_SAVE_CHANGES, {
      title: data.title,
      titleEs: data.titleEs,
      workspaceUuid,
      organisationId,
    });
  } catch (err) {
    Logger.error(err);
    yield put(editExistingAssessmentError(assessment));
    yield put(setGlobalError(err));
  }
}

function* changeHazardLinkedToAssessment({
  workspaceUuid,
  assessmentId,
  hazard,
}) {
  try {
    const data = yield AssessmentSettings.updateHazard(workspaceUuid, hazard);

    yield put(
      changeHazardLinkedToAssessmentSuccess(workspaceUuid, assessmentId, data),
    );
    yield put(
      toggleHazardAssessmentLinking(workspaceUuid, assessmentId, data?.id),
    );
  } catch (err) {
    Logger.error(err);
    yield put(
      changeHazardLinkedToAssessmentError(
        workspaceUuid,
        assessmentId,
        hazard?.id,
      ),
    );
    yield put(setGlobalError(err));
  }
}

export default function* workspaceAssessmentsWatcher() {
  yield takeLatest(FETCH_WORKSPACE_ASSESSMENTS, fetchAssessments);
  yield takeLatest(ADD_WORKSPACE_ASSESSMENT, addAssessment);
  yield takeLatest(EDIT_WORKSPACE_ASSESSMENT, editAssessment);
  yield takeLatest(REMOVE_WORKSPACE_ASSESSMENT, deleteAssessment);
  yield takeLatest(
    CHANGE_WORKSPACE_HAZARD_LINKED_TO_ASSESSMENT,
    changeHazardLinkedToAssessment,
  );
}

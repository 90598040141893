import styled from 'styled-components';
import { Button as MaterialButton } from '@mui/material';
import theme from 'theme';
import { Cross, Attachment } from 'assets/icons';

const { fonts, fontSizes, media, colors, mixins } = theme;

export const UploaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DraggerContent = styled(({ component, ...rest }) => (
  <MaterialButton component={component} {...rest} />
))`
  &.MuiButtonBase-root {
    ${mixins.flexColumn};
    text-align: left;
    padding: 12px 24px;
    margin-bottom: 16px;
    background: ${colors.offWhite};
    border: 3px solid ${colors.black};
    border-radius: 0;
    height: 150px;
    text-transform: none;
    letter-spacing: 0;
    &:hover {
      border: 3px solid ${colors.orange};
    }
    ${media.sm`
    height: 165px;
  `}
  }
`;

export const StyledDraggable = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
`;

export const Hint = styled.p<{ $isLarge?: boolean }>`
  color: ${colors.darkGrey};
  font-size: ${({ $isLarge }) => ($isLarge ? fontSizes.default : fontSizes.xs)};
  font-family: ${fonts.light};
`;

export const HintContainer = styled.div`
  width: 100%;
`;

export const UploadList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Cta = styled.p<{ $isCsvUpload: boolean }>`
  padding-bottom: 8px;
  font-size: ${({ $isCsvUpload }) =>
    $isCsvUpload ? fontSizes.l : fontSizes.m};
  font-family: ${fonts.bold};
`;

export const UploadListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: ${fontSizes.l};
`;

export const SmallCross = styled(Cross)`
  height: 16px;
  width: 16px;
  cursor: pointer;
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledAttachment = styled(Attachment)`
  height: auto;
  width: ${({ $isCsvUpload }) => ($isCsvUpload ? 35 : 25)}px;
`;

import { WarningSectionContainer, WarningIcon, WarningWrapper } from './styled';

type Props = {
  text: string;
  hasIcon?: boolean;
};

export const WarningSection = ({
  text,
  hasIcon = false,
}: Props): JSX.Element => {
  return (
    <WarningSectionContainer>
      <WarningWrapper>
        {hasIcon && <WarningIcon />}
        {text}
      </WarningWrapper>
    </WarningSectionContainer>
  );
};

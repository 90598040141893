import { ChangeEvent, useState } from 'react';
import { useText } from 'hooks';
import * as constants from 'utils/constants';
import { Job } from 'api';
import { Modal, Input, Button } from 'components/ui';
import { Dates } from 'helpers';
import FRAList from '../FRAList';
import InterventionList from '../InterventionList';
import FraFormSection from 'model/FatigueManager/FraFormSection';
import { FatigueLiveRowItem } from 'model/FatigueManager/FatigueLiveRowItem';
import {
  Container,
  Information,
  InterventionContainer,
  Label,
  Section,
  Title,
  TitleContainer,
} from './styled';
import { FRAListV2 } from '../FraListV2';
import SectionField from 'model/FatigueManager/SectionField';
import { parseForm } from 'api/Fatigue/helpers';
import { FatigueUserBadge } from '../NewFatigueRiskAssessmentModal/components/FatigueUserBadge';

type Props = {
  fatigueLiveRowItem: FatigueLiveRowItem;
  index: number;
  setVisible: (value: boolean) => void;
  onSuccess: () => void;
};

export const CompletedFatigueRiskAssessmentModal = ({
  fatigueLiveRowItem,
  index,
  setVisible,
  onSuccess,
}: Props): JSX.Element => {
  const {
    id: fraId,
    sentAt,
    fraType,
    form,
    interventions,
    submittedBy,
  } = fatigueLiveRowItem.fatigueRiskAssessments[index];

  const { user } = fatigueLiveRowItem;
  const [comments, setComments] = useState<string>('');
  const [action, setAction] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getText = useText();

  const actionOptions = [
    {
      id: 0,
      value: 'stop-work',
      title: getText('fatigue_manager_risk_assessment_stop_work'),
    },
    {
      id: 1,
      value: 'relief-arranged',
      title: getText('fatigue_manager_risk_assessment_relief_arranged'),
    },
    {
      id: 2,
      value: 'no-action',
      title: getText('fatigue_manager_risk_assessment_no_action'),
    },
  ];

  const onSubmit = async () => {
    setIsLoading(true);
    await Job.resolveFRA(fraId, action, comments);
    setIsLoading(false);
    onSuccess();
  };

  const isSubmittedByManager =
    !!submittedBy && !!user && submittedBy.id !== user.id;

  return (
    <Modal.Base
      isOpen
      title={getText('fatigue_manager_table_header_fatigue_risk_assessment')}
      onClose={() => {
        setVisible(false);
      }}
      width='60vw'
    >
      <Container>
        <Information>
          <TitleContainer>
            <Title>
              {fatigueLiveRowItem.fatigueRiskAssessmentTitles[index]}
            </Title>
            {Dates.formatDateTime(
              sentAt,
              getText('date_today'),
              getText('date_yesterday'),
            )}
          </TitleContainer>
          <Section>
            {isSubmittedByManager && (
              <Label>
                {getText('fatigue_manager_risk_assessment_completed_for')}
              </Label>
            )}
            <FatigueUserBadge user={user} />
          </Section>
          {isSubmittedByManager && (
            <Section>
              <>
                <Label>
                  {getText('fatigue_manager_risk_assessment_completed_by')}
                </Label>
                <FatigueUserBadge user={submittedBy} />
              </>
            </Section>
          )}
          <Section>
            {form.version === 1 ? (
              <FRAList
                items={form.sections.reduce(
                  (acc: SectionField[], val: FraFormSection) =>
                    acc.concat(val.fields),
                  [],
                )}
              />
            ) : (
              <FRAListV2 form={parseForm(form)} />
            )}
          </Section>

          <InterventionList items={interventions} />

          <InterventionContainer>
            <Input.Select
              id='fra-action'
              label={`${getText(
                'fatigue_manager_risk_assessment_action_or_intervention',
              )}:`}
              hint={getText(
                'fatigue_manager_risk_assessment_action_or_intervention_hint',
              )}
              options={actionOptions}
              disabled={isLoading}
              onChange={(e) => {
                setAction(
                  actionOptions?.find(
                    (option) => option.id === e?.target?.value,
                  )?.value,
                );
              }}
              large
              grey
            />
            <Input.TextArea
              id='fra-comments'
              label={getText(
                'fatigue_manager_risk_assessment_reviewer_comment',
              )}
              optional
              extraStyles={{ marginBottom: '16px', marginTop: '32px' }}
              value={comments}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setComments(e.target.value)
              }
              disabled={isLoading}
            />
            <Button.Primary
              onClick={onSubmit}
              loading={isLoading}
              text={getText('submit')}
              width='240px'
              disabled={!action}
              event={constants.EVENT_FRA_SUBMIT_INTERVENTION}
              eventProperties={{
                fra_id: fraId,
                fra_type: fraType,
                action,
              }}
            />
          </InterventionContainer>
        </Information>
      </Container>
    </Modal.Base>
  );
};

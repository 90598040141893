import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { SelectablePhoto } from './SelectablePhoto';
import { Photo } from 'model/Media/Photo';
import { A11y, Navigation } from 'swiper/modules';
import {
  PlaceHolder,
  SimpleCarouselStyledSwiper,
  SimplePhotoAndControlsContainer,
} from './styled';

type Props = {
  photos: Photo[];
  onPhotoClick?: (id: number) => void;
  selectedPhotos?: Photo[];
  placeholder?: string;
};

// SimpleCarousel
export const SimpleCarousel = ({
  photos,
  onPhotoClick,
  selectedPhotos,
  placeholder,
}: Props): JSX.Element => {
  const urls = photos?.map((photo) => photo.signedUrl) || [];
  return (
    <SimplePhotoAndControlsContainer>
      <SimpleCarouselStyledSwiper
        modules={[Navigation, A11y]}
        spaceBetween={10}
        slidesPerView={3}
        navigation
        noSwiping={urls?.length < 2}
      >
        {photos?.map(({ signedUrl, id }) => (
          <SwiperSlide key={signedUrl}>
            {signedUrl && (
              <SelectablePhoto
                onPhotoClick={onPhotoClick}
                url={signedUrl}
                id={id}
                selected={selectedPhotos?.some(
                  (selectedPhoto) => selectedPhoto.id === id,
                )}
              />
            )}
          </SwiperSlide>
        ))}
        {photos.length === 0 && <PlaceHolder>{placeholder}</PlaceHolder>}
      </SimpleCarouselStyledSwiper>
    </SimplePhotoAndControlsContainer>
  );
};

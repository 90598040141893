import { BrandPattern } from 'assets/images';
import {
  BrandBackgroundImage,
  ContentContainer,
  EmailLink,
  ErrorContainer,
  ExplanationIcon,
  MessageBannerContainer,
  MessageText,
  MessageTextContainer,
  MessageTitle,
} from './styled';

type Props = {
  titleText: string;
  subTitleText: string;
  linkText: string;
  link: string;
};

export const ErrorPage = ({
  titleText,
  subTitleText,
  linkText,
  link,
}: Props): JSX.Element => (
  <ErrorContainer>
    <ContentContainer>
      <MessageBannerContainer>
        <ExplanationIcon />
        <MessageTitle>{titleText}</MessageTitle>
      </MessageBannerContainer>
      <MessageTextContainer>
        <MessageText>
          {subTitleText}
          <EmailLink href={link}>{linkText}</EmailLink>
        </MessageText>
      </MessageTextContainer>
    </ContentContainer>
    <BrandBackgroundImage src={BrandPattern} />
  </ErrorContainer>
);

import { useDispatch, useSelector } from 'react-redux';
import { RamsSuggestBase } from '../RamsSuggestBase';
import RootState from 'model/State/RootState';
import { Control } from 'model/Assessment/Control';
import {
  addSelectedControl,
  removeSelectedControl,
} from 'store/slices/ramsAssessments';

export const ControlList = ({
  controls = [],
}: {
  controls?: Control[];
}): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedControls } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );

  const handleSelectChange = (item: Control) => {
    if (selectedControls.some((control) => control.id === item.id)) {
      dispatch(removeSelectedControl(item.id));
    } else {
      dispatch(addSelectedControl(item));
    }
  };

  return (
    <RamsSuggestBase
      items={controls}
      onSelectedChange={handleSelectChange}
      selectedItems={selectedControls}
    />
  );
};

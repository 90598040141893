export enum InsightsTabKey {
  JOBS_PER_DAY = 'jobs-per-day',
  COMPLIANCE = 'compliance',
  OUTPUT_PER_DAY = 'output-per-day',
  ENGAGEMENT = 'engagement',
  JOB_BLOCKERS = 'job-blockers',
  USER_FORMS = 'user-forms',
  JOB_FORMS = 'job-forms',
  PERMIT_FORMS = 'permit-forms',
  HAVS_COMPLIANCE = 'havs-compliance',
  VEHICLE_CHECK_COMPLIANCE = 'vehicle-check-compliance',
  VEHICLE_CHECK_USER_COMPLIANCE = 'vehicle-check-user-compliance',
}

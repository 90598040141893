import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useJobs, useQueryParams } from 'hooks';
import {
  Badge,
  FormattedDate,
  Loader,
  Media,
  Modal as StyledModal,
} from 'components/ui';
import theme from 'theme';
import {
  useLocation,
  useParams,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import { Storage } from 'aws-amplify';
import { getVideo, getVideoInfoPage } from 'helpers/forms';
import { setGlobalError } from 'store/slices/notifications';
import { JobForms, UserForms } from 'api';
import UserFormAnalyticsParameter from 'model/Forms/UserFormAnalyticsParameters';
import RootState from 'model/State/RootState';
import Form from 'model/Forms/Form';
import FormDetails from './components/FormDetails';
import Instruction from './components/Instruction';
import { Field } from 'model/Forms/Field';
import { CustomFormClass } from 'model/Form';
import {
  Container,
  ContentWrapper,
  Header,
  RightContainer,
  Section,
  SectionHeader,
  SectionTitle,
  SectionWrapper,
} from './styled';
import { ModalFooter } from '../PdfExport/ModalFooter';
import { DownloadType } from '../PdfExport/constants';
import { mapToPhoto, mapToVideo } from 'api/Media/helpers';

type QueryParamProps = {
  form?: CustomFormClass;
  view?: string;
};

const Modal = (): JSX.Element => {
  const { jobId, formId } = useParams();
  const { params }: { params: QueryParamProps } = useQueryParams();
  const navigate = useNavigate();
  const { navigateToOverviewTabOrJob } = useJobs();
  const [jobForm, setJobForm] = useState<Form>();
  const [isFetching, setFetching] = useState<boolean>(true);
  const [videoUrl, setVideoUrl] = useState<string>();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.session);
  const location = useLocation();
  const videoInfoPage: Field | null = getVideoInfoPage(jobForm?.form);

  const handleModalClose = () =>
    navigateToOverviewTabOrJob(`/jobs/${jobId}/job-forms`);

  const handleGoBack = () => navigate(`/jobs/${jobId}/job-forms/${formId}`);

  useEffect(() => {
    (async () => {
      try {
        const data =
          params?.form === CustomFormClass.USER
            ? await UserForms.getUserForm(parseInt(formId ?? '', 10))
            : await JobForms.get(parseInt(formId ?? '', 10));
        setJobForm(data);
        setFetching(false);
      } catch (err) {
        dispatch(setGlobalError(err));
      }
    })();
  }, [formId, jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      if (jobForm?.form && videoInfoPage) {
        const signedURL = await Storage.get(
          (videoInfoPage as Field)?.value?.path,
        );
        setVideoUrl(signedURL);
      }
    })();
  }, [jobForm]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledModal.Base
      isOpen
      title={jobForm?.form?.title || ''}
      onClose={handleModalClose}
      onBack={
        (location.pathname.includes('instruction') && handleGoBack) || null
      }
      width={videoInfoPage ? '80vw' : '35vw'}
    >
      <Container>
        {isFetching ? (
          <Loader />
        ) : (
          <>
            {videoInfoPage && videoUrl && (
              <Media
                kind='job-form'
                type='video'
                url={videoUrl}
                width='55%'
                item={getVideo(
                  jobForm?.form,
                  jobForm?.videos?.map(mapToVideo) ?? [],
                )}
                userFormParameters={
                  {
                    form_name: jobForm?.form?.title,
                    form_type_id: jobForm?.type?.id,
                    form_id: jobForm?.id,
                    video_id: (videoInfoPage as Field)?.value?.file_id,
                  } as UserFormAnalyticsParameter
                }
              />
            )}
            <Routes>
              <Route
                path='/instruction/:fieldId'
                element={
                  <RightContainer width={videoInfoPage ? 45 : 100}>
                    <Instruction />
                  </RightContainer>
                }
              />
              <Route
                index
                element={
                  <RightContainer width={videoInfoPage ? 45 : 100}>
                    <ContentWrapper>
                      <SectionWrapper>
                        <Header>
                          {jobForm?.created_by && (
                            <Badge.UserBadge
                              user={jobForm?.created_by}
                              isCurrentUser={
                                currentUser.id === jobForm?.created_by.id
                              }
                            />
                          )}
                          {jobForm?.created_at && (
                            <FormattedDate
                              date={jobForm?.created_at?.toString() ?? ''}
                              extraStyles={{
                                color: theme.colors.grey,
                                textAlign: 'center',
                              }}
                            />
                          )}
                        </Header>
                        {jobForm && (
                          <Section>
                            {jobForm?.form?.header && (
                              <SectionHeader>
                                <SectionTitle>
                                  {jobForm?.form?.header}
                                </SectionTitle>
                              </SectionHeader>
                            )}
                            <FormDetails
                              form={jobForm?.form}
                              userFormParameters={{
                                form_name: jobForm?.form?.title,
                                form_type_id: jobForm?.type?.id,
                                form_id: jobForm?.id,
                              }}
                              photos={jobForm?.photos.map(mapToPhoto)}
                              videos={jobForm?.videos.map(mapToVideo)}
                            />
                          </Section>
                        )}
                      </SectionWrapper>
                      {jobId && formId && (
                        <ModalFooter
                          downloadType={DownloadType.FORM}
                          downloadItemId={formId}
                          formClass={CustomFormClass.JOB}
                          jobId={jobId}
                          onClick={() => handleModalClose()}
                        />
                      )}
                    </ContentWrapper>
                  </RightContainer>
                }
              />
            </Routes>
          </>
        )}
      </Container>
    </StyledModal.Base>
  );
};

export default Modal;

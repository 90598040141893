import { useText } from 'hooks';
import styled from 'styled-components';
import theme from 'theme';

const { fonts, fontSizes, media } = theme;

const Subtitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
`;

const Container = styled.div`
  padding: 0 20px;
`;

const Section = styled.div`
  margin: 40px 0;
  ${media.xl`
    margin: 30px 0;
  `}
`;

export const ManagersSignOffDescription = () => {
  const getText = useText();

  return (
    <>
      <div>{getText('workspace_settings_managers_sign_off_info_para_1')}</div>
      <div>{getText('workspace_settings_managers_sign_off_info_para_2')}</div>
    </>
  );
};

export const UsersSignOnDescription = () => {
  const getText = useText();

  return (
    <>
      <div>
        {getText('workspace_settings_fieldworkers_sign_on_info_para_1')}
      </div>
      <Subtitle>
        {getText('workspace_settings_fieldworkers_sign_on_info_list_title')}
      </Subtitle>
      <div>
        {getText('workspace_settings_fieldworkers_sign_on_info_list_item_1')}
      </div>
      <div>
        {getText('workspace_settings_fieldworkers_sign_on_info_list_item_2')}
      </div>
    </>
  );
};

export const MajorHazardDescription = () => {
  const getText = useText();

  return (
    <Container>
      <Section>
        {getText('workspace_settings_hazard_major_hazard_info_para_1')}
      </Section>
      <Section>
        {getText('workspace_settings_hazard_major_hazard_info_para_2')}
      </Section>
    </Container>
  );
};

import React from 'react';
import { CSSProperties } from 'styled-components';
import * as Analytics from 'utils/analytics';
import { Button, Description } from './styled';
import { ArrowRight, Icon } from '../styled';

type Props = {
  text: string;
  onClick: () => {};
  IconComponent?: React.ElementType;
  width?: string;
  disabled?: boolean;
  extraStyles?: CSSProperties;
  iconFront?: boolean;
  isDark?: boolean;
  hasBlackBackground?: boolean;
};

// Button.JobActionButton
export const JobActionButton = Analytics.withTrackEvent(
  ({
    text,
    onClick,
    IconComponent,
    width = '100%',
    disabled = false,
    extraStyles,
    ...props
  }: Props): JSX.Element => (
    <Button
      disabled={disabled}
      width={width}
      {...props}
      style={extraStyles}
      onClick={onClick}
    >
      <Description $disabled={disabled}>
        {IconComponent && (
          <Icon disabled={disabled} component={<IconComponent />} />
        )}
        {text}
      </Description>
      <ArrowRight disabled={disabled} />
    </Button>
  ),
);

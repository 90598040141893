import { mapToJobBlocker, mapToJobBlockerSubtype } from 'api/Insights/helpers';
import { mapToPhoto, mapToVideo } from 'api/Media/helpers';
import { mapToUser } from 'api/User/helpers';
import { JobBlockerResolution } from 'model/Log/JobBlockerResolution';
import { Log } from 'model/Log/Log';

export const mapToLog = (backendLog: any): Log => ({
  id: backendLog.id,
  jobId: backendLog.job_id,
  type: backendLog.type,
  siteVisitedAt: backendLog.site_visited_at
    ? new Date(backendLog.site_visited_at)
    : null,
  jobBlocker: backendLog.job_blocker
    ? mapToJobBlocker(backendLog.job_blocker)
    : null,
  jobBlockerSubtype: backendLog.job_blocker_subtype
    ? mapToJobBlockerSubtype(backendLog.job_blocker_subtype)
    : null,
  jobBlockerResolution: backendLog.job_blocker_resolution
    ? mapToJobBlockerResolution(backendLog.job_blocker_resolution)
    : null,
  jobBlockerIsDelay: backendLog.job_blocker_is_delay ?? false,
  resolutionRequired: backendLog.resolution_required ?? false,
  title: backendLog.title,
  note: backendLog.note,
  attachment: backendLog.attachment,
  allDay: backendLog.all_day ?? false,
  startsAt: backendLog.starts_at ? new Date(backendLog.starts_at) : null,
  endsAt: backendLog.ends_at ? new Date(backendLog.ends_at) : null,
  alertNumber: backendLog.alert_number,
  alertPeriod: backendLog.alert_period,
  priority: backendLog.priority,
  videos: backendLog.videos ? backendLog.videos.map(mapToVideo) : [],
  photos: backendLog.photos ? backendLog.photos.map(mapToPhoto) : [],
  assignees: backendLog.assignees ? backendLog.assignees.map(mapToUser) : [],
  createdBy: mapToUser(backendLog.created_by),
  createdAt: new Date(backendLog.created_at),
  updatedAt: new Date(backendLog.updated_at),
  sentAt: backendLog.sent_at ? new Date(backendLog.sent_at) : null,
  uuid: backendLog.uuid ?? '',
});

export const mapToJobBlockerResolution = (
  backendJobBlockerResolution: any,
): JobBlockerResolution => ({
  createdBy: mapToUser(backendJobBlockerResolution.created_by),
  createdAt: new Date(backendJobBlockerResolution.created_at),
  note: backendJobBlockerResolution.note ?? '',
  delayDuration: backendJobBlockerResolution.delay_duration,
});

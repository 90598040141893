import { useText } from 'hooks';
import { Container, SubTitle, Title, VideoTimerIcon } from './styled';

type Props = {
  width?: string;
  height?: string;
};

export const VideoNotUploaded = ({
  width = '100%',
  height = '100%',
}: Props): JSX.Element => {
  const getText = useText();

  return (
    <Container width={width} height={height}>
      <VideoTimerIcon />
      <Title>{getText('job_details_not_fully_uploaded')}</Title>
      <SubTitle>{getText('job_details_not_fully_uploaded_reason')}</SubTitle>
    </Container>
  );
};

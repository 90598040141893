import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Map as MapComponent, Loader, Tabs } from 'components/ui';
import { useSafetyPrediction } from 'hooks';
import { Grid } from '@mui/material';
import { LEGACY_VIEW } from 'utils/constants';
import RootState from 'model/State/RootState';
import Coordinate from 'model/Coordinate';
import styled from 'styled-components';
import { getValidJobCoordinates } from 'helpers/utils';
import { selectWorkspaceHasSettingEnabled } from 'store/selectors';
import {
  getLegacyMapMarkerIcon,
  getLegacyCoordinatesFromJob,
} from 'helpers/map';

const StyledGrid = styled(Grid)`
  position: relative;
`;

// Map
const MapWithCoordinates = (): JSX.Element => {
  const { jobs, mapCanLoad } = useSelector(
    (state: RootState) => state.legacyViewJobs,
  );
  const { getRiskWarning } = useSafetyPrediction();
  const canUseListView = useSelector(
    selectWorkspaceHasSettingEnabled('enable_jobs_page_v2'),
  );
  const [coordinates, setCoordinates] = useState<Coordinate[] | null>(null);

  useEffect(() => {
    if (!mapCanLoad) {
      setCoordinates(null);
    }
  }, [mapCanLoad]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mapCanLoad && jobs) {
      setCoordinates(
        getValidJobCoordinates(jobs).map((job) =>
          getLegacyCoordinatesFromJob(job, getRiskWarning),
        ),
      );
    }
    if (mapCanLoad && !jobs) {
      setCoordinates([]);
    }
  }, [jobs]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledGrid item xs={0} sm={16} md={18} lg={18}>
      {mapCanLoad && coordinates ? (
        <>
          {canUseListView && (
            <Tabs.JobView
              activeTab={LEGACY_VIEW}
              extraStyles={{
                position: 'absolute',
                width: 'fit-content',
                right: '30px',
                top: '20px',
                zIndex: '10',
              }}
            />
          )}
          <MapComponent
            id='jobs'
            coordinates={coordinates}
            getMapMarker={getLegacyMapMarkerIcon}
            pageSource={LEGACY_VIEW}
          />
        </>
      ) : (
        <Loader />
      )}
    </StyledGrid>
  );
};

export default MapWithCoordinates;

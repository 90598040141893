import RootState from 'model/State/RootState';
import WorkspaceAssessmentItem from 'model/State/WorkspaceAssessmentItem';
import { createSelector } from 'reselect';

export const selectWorkspaceAssessments = (state: RootState) =>
  state?.workspaceAssessments?.assessments;
const selectAssessmentsByUuid = createSelector(
  selectWorkspaceAssessments,
  (_: RootState, uuid: string) => uuid,
  (assessments: { [key: string]: WorkspaceAssessmentItem[] }, uuid: string) =>
    assessments?.[uuid] || [],
);
export const selectIsFetchingAssessments = (state: RootState) =>
  state.workspaceAssessments.isFetchingAssessments;
export const selectAssessments = (uuid: string) => (state: RootState) =>
  selectAssessmentsByUuid(state, uuid);
export const selectIsLoadingAssessmentChange = (state: RootState) =>
  state.workspaceAssessments.isLoadingAssessmentChange;
export const selectAssessmentError = (state: RootState) =>
  state.workspaceAssessments.error;

import UserDataRow from 'model/UserDataRow';
import { useEffect, useState } from 'react';
import { LOGIN_LINK_COOLDOWN_MS } from './constants';
import { Button } from 'components/ui';
import { Email, Sms } from 'assets/icons';
import { useText } from 'hooks';
import {
  EVENT_SEND_LOGIN_LINK_BUTTON_CLICKED,
  EVENT_SEND_LOGIN_LINK_EMAIL_BUTTON_CLICKED,
  EVENT_SEND_LOGIN_LINK_SMS_BUTTON_CLICKED,
} from '../../constants';
import { LoginLinkContainer, PaperplaneIcon } from './styled';
import theme from 'theme';
import { User } from 'api';
import { useDispatch } from 'react-redux';
import { setGlobalError } from 'store/slices/notifications';
import * as Logger from 'utils/logger';

const { fontSizes } = theme;

type Props = {
  user: UserDataRow;
};

const LoginLinkButtons = ({ user }: Props): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const [isMainPressed, setIsMainPressed] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [isSmsSent, setIsSmsSent] = useState<boolean>(false);
  const [mainTimeout, setMainTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isMainPressed) {
      const timeout = setTimeout(() => {
        setIsMainPressed(false);
      }, LOGIN_LINK_COOLDOWN_MS);
      setMainTimeout(timeout);

      return () => clearTimeout(timeout);
    }
  }, [isMainPressed, setIsMainPressed]);

  useEffect(() => {
    if (isEmailSent) {
      if (mainTimeout) {
        clearTimeout(mainTimeout);
      }

      const timeout = setTimeout(() => {
        setIsEmailSent(false);
        setIsMainPressed(false);
      }, LOGIN_LINK_COOLDOWN_MS);

      return () => clearTimeout(timeout);
    }
  }, [isEmailSent, setIsEmailSent, setIsMainPressed, mainTimeout]);

  useEffect(() => {
    if (isSmsSent) {
      if (mainTimeout) {
        clearTimeout(mainTimeout);
      }
      const timeout = setTimeout(() => {
        setIsSmsSent(false);
        setIsMainPressed(false);
      }, LOGIN_LINK_COOLDOWN_MS);

      return () => clearTimeout(timeout);
    }
  }, [isSmsSent, setIsSmsSent, setIsMainPressed, mainTimeout]);

  const onLoginLinkClick = () => {
    setIsMainPressed(true);
  };

  const sendEmail = () => {
    requestLink(user?.email);
    setIsEmailSent(true);
  };

  const sendSms = () => {
    requestLink(user?.phone_number);
    setIsSmsSent(true);
  };

  const requestLink = async (emailOrPhone) => {
    try {
      await User.requestMagicLink(emailOrPhone);
    } catch (err) {
      Logger.error(err);
      dispatch(setGlobalError(err));
    }
  };

  return isMainPressed ? (
    <LoginLinkContainer>
      <Button.LoginLinkActionButton
        IconComponent={Email}
        onClick={sendEmail}
        disabled={isEmailSent}
        extraStyles={{ marginRight: '10px' }}
        event={EVENT_SEND_LOGIN_LINK_EMAIL_BUTTON_CLICKED}
        eventProperties={{ settingUserId: user?.id }}
      />
      {user?.phone_number !== '-' && (
        <Button.LoginLinkActionButton
          IconComponent={Sms}
          onClick={sendSms}
          disabled={isSmsSent}
          event={EVENT_SEND_LOGIN_LINK_SMS_BUTTON_CLICKED}
          eventProperties={{ settingUserId: user?.id }}
        />
      )}
    </LoginLinkContainer>
  ) : (
    <Button.Secondary
      text={getText('users_login_link')}
      width='100px'
      onClick={onLoginLinkClick}
      IconComponent={PaperplaneIcon}
      loading={false}
      event={EVENT_SEND_LOGIN_LINK_BUTTON_CLICKED}
      eventProperties={{ settingUserId: user?.id }}
      extraStyles={{
        height: '40px',
        fontSize: `${fontSizes.xs}`,
        padding: '0 8px',
      }}
    />
  );
};

export default LoginLinkButtons;

import { useEffect } from 'react';
import styled from 'styled-components';
import { useIsMobile, useQueryParams, useText } from 'hooks';
import { selectGlobalLanguageSetting, selectJobsView } from 'store/selectors';
import theme from 'theme';
import { Loader, Card, Message, Navbar } from 'components/ui';
import { SkeletonCardContainer } from 'components/ui/Shared/EmptyState';
import * as constants from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvidences } from 'store/actions/jobs';
import { useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

const { media } = theme;

// Styled Components
const Container = styled.div`
  height: 100%;
  ${media.sm`
    padding: 25px 15px;
    overflow: scroll;
  `}
`;

// List
export default (): JSX.Element => {
  const { jobId } = useParams();
  const { evidences, isEvidencesLoaded } = useSelector(
    (state: RootState) => state.jobs,
  );
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const getText = useText();
  const { getPathWithQueryParams } = useQueryParams();
  const view = useSelector(selectJobsView);

  useEffect(() => {
    dispatch(fetchEvidences(jobId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Navbar
        title={getText('evidence')}
        defaultBackPath={`/jobs/${jobId}`}
        mobileOnly
      />

      <Container>
        {!isEvidencesLoaded && <Loader />}
        {isEvidencesLoaded && !evidences.length && (
          <SkeletonCardContainer>
            {getText('evidence_no_display')}
          </SkeletonCardContainer>
        )}
        {isEvidencesLoaded &&
          evidences &&
          evidences.map((evidence) =>
            isMobile ? (
              <Message.Action
                key={`evidence-${evidence.id}`}
                event={constants.EVENT_VIEW_EVIDENCE_ITEM}
                eventProperties={{
                  jobId,
                  evidenceId: evidence.id,
                  evidenceCategory: evidence?.type,
                  source: 'job-overview',
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  insights_available: evidence?.summaryAvailable,
                }}
                type={getText('job_details_timeline_action_evidence')}
                subtype={evidence?.type}
                thumbnail={evidence.thumbnail}
                comments={evidence.comments}
                sender={evidence.createdBy}
                date={evidence.createdAt}
                to={`/jobs/${evidence.jobId}/evidence/${evidence.id}`}
                qualityIndicator={evidence?.videos?.[0]?.qualityIndicator}
              />
            ) : (
              <Card.Evidence
                key={`evidence-${evidence.id}`}
                event={constants.EVENT_VIEW_EVIDENCE_ITEM}
                eventProperties={{
                  jobId,
                  evidenceId: evidence.id,
                  evidenceCategory: evidence.type ?? getText('evidence'),
                  source: 'job-overview',
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  insights_available: evidence?.summaryAvailable,
                }}
                subtype={
                  evidence.type
                    ? getLocalizedCamelCaseValue(
                        evidence,
                        'type',
                        globalAppLanguage,
                      )
                    : getText('evidence')
                }
                thumbnail={evidence.thumbnail}
                comments={evidence.comments}
                creator={evidence.createdBy}
                date={evidence.createdAt}
                to={getPathWithQueryParams(
                  `/jobs/${evidence.jobId}/evidence/${evidence.id}`,
                )}
              />
            ),
          )}
      </Container>
    </>
  );
};

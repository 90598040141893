import styled from 'styled-components';
import theme from 'theme';

const { colors } = theme;

const MapSkeleton = styled.div<{ height: string }>`
  background-color: ${colors.lightGrey};
  height: ${({ height }) => height};
  width: 100%;
  position: relative;
`;

const FullScreenButtonSkeleton = styled.div`
  background-color: ${colors.white};
  width: 40px;
  height: 40px;
  position: absolute;
  left: 10px;
  bottom: 10px;
`;

const ControlsSkeleton = styled.div`
  background-color: ${colors.white};
  width: 40px;
  height: 80px;
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const StreetViewButtonSkeleton = styled.div`
  background-color: ${colors.white};
  width: 40px;
  height: 40px;
  position: absolute;
  right: 10px;
  bottom: 120px;
`;

type Props = {
  height?: string;
};

const SkeletonMap = ({ height = '100%' }: Props): JSX.Element => (
  <MapSkeleton height={height}>
    <FullScreenButtonSkeleton />
    <ControlsSkeleton />
    <StreetViewButtonSkeleton />
  </MapSkeleton>
);

export default SkeletonMap;

import styled from 'styled-components';
import theme from 'theme';

const { fonts, fontSizes } = theme;

export const SectionTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 8px;
`;

import styled from 'styled-components';

export const StyledItemActionButton = styled.button<{
  $canGrab?: boolean;
  $isGrabbing?: boolean;
  $disabled: boolean;
}>`
  padding: 10px;
  width: 50px;
  height: 50px;
  cursor: ${({ $disabled, $canGrab, $isGrabbing }) =>
    $disabled
      ? 'not-allowed'
      : $canGrab
        ? $isGrabbing
          ? 'grabbing'
          : 'grab'
        : 'pointer'};
`;

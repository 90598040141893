import { Dates } from 'helpers';

import { CreationDateContainer } from './styled';
import { useText } from 'hooks';

export const CreationDate = ({ date }) => {
  const getText = useText();

  return (
    <CreationDateContainer>
      {Dates.formatDateTime(
        date,
        getText('date_today'),
        getText('date_yesterday'),
      )}
    </CreationDateContainer>
  );
};

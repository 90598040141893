export const enum FieldKind {
  TEXT_FIELD = 'textfield',
  TEXT_AREA = 'textarea',
  PHOTO_UPLOAD = 'photo-upload',
  VIDEO_UPLOAD = 'video-upload',
  VIDEO_UPLOAD_With_INFO_PAGE = 'video-upload-with-info-page',
  MULTI_SELECT_CUSTOM_TEXT = 'multi-select-with-custom-text',
  MULTI_SELECT = 'multi-select',
  SEPARATOR = 'separator',
  UNARY_CHECKBOX = 'unary-checkbox',
  USER_SELECTOR = 'user-selector',
  MULTI_USER_SELECTOR = 'multi-user-selector',
  INSTRUCTION = 'instruction',
  BINARY_RADIO_BUTTON = 'binary-radiobutton',
  SINGLE_SELECT_WITH_CUSTOM_TEXT = 'single-select-with-custom-text',
  SINGLE_SELECT = 'single-select',
  SINGLE_SELECT_INLINE = 'single-select-inline',
  DATE_SELECTOR = 'date-selector',
  HEADER_2 = 'header-2',
  CALCULATED_WIDGET = 'calculated-widget',
  TEXTFIELD_COMPARISON_CONFIRM = 'textfield-comparison-confirm',
}

import { ColumnContainer } from '../styled';
import { Control } from 'model/Assessment/Control';
import { BulletPoint, CenteredContainer, ControlName } from './styled';
import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { capitalizeFirstLetter } from 'helpers/names';
import { RamsRating } from 'model/Assessment/RAMS';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

type Props = {
  rating: RamsRating;
  isLight?: boolean;
};

export const ControlRow = ({ rating, isLight }: Props): JSX.Element => {
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  return (
    <ColumnContainer>
      {rating?.selectedControls?.map((control: Control) => (
        <CenteredContainer key={control.id}>
          <BulletPoint />
          <ControlName $isLight={isLight}>
            {capitalizeFirstLetter(
              getLocalizedCamelCaseValue(control, 'title', globalAppLanguage),
            )}
          </ControlName>
        </CenteredContainer>
      ))}
    </ColumnContainer>
  );
};

import { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { Loader, Media } from 'components/ui';
import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
import { Field } from 'model/Forms/Field';
import { Photo } from 'model/Media/Photo';

const { colors, fonts, fontSizes } = theme;

// Styled components
const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0;
`;

const DetailTitle = styled.span`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  padding-right: 8px;
  padding-bottom: 16px;
`;

const DetailText = styled.span`
  font-size: ${fontSizes.default};
  color: ${colors.darkGrey};
  font-family: ${fonts.boldItalic};
  padding: 5px 0;
`;

type Props = {
  field: Field;
  photos: Photo[];
};

type PhotoResult = {
  file_id: string;
  url: string;
};

const PhotoUpload = ({ field, photos }: Props): JSX.Element => {
  const [photosWithUrl, setPhotosWithUrl] = useState<PhotoResult[]>([]);
  const [isFetchingPhotos, setFetchingPhotos] = useState<boolean>(true);
  const getText = useText();

  useEffect(() => {
    (async () => {
      if (photos?.length > 0) {
        const photoResults: PhotoResult[] = await Promise.all(
          photos.map(async (photo) => ({
            file_id: photo.fileId,
            url: await Storage.get(photo.path),
          })),
        );
        setPhotosWithUrl([...photoResults]);
        setFetchingPhotos(false);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUrlsFromList = (fieldValue, list) =>
    fieldValue.map((value) => {
      const foundMedia = list.find(
        (mediaWithUrl) => mediaWithUrl?.file_id === value.file_id,
      );
      return foundMedia?.url;
    });

  return (
    <DetailContainer style={{ flexDirection: 'column' }}>
      <DetailTitle>{field.title}</DetailTitle>
      {field.value &&
        field.value?.length > 0 &&
        (isFetchingPhotos ? (
          <Loader />
        ) : (
          getUrlsFromList(field.value, photosWithUrl)?.length > 0 && (
            <Media
              type='photo'
              urls={getUrlsFromList(field.value, photosWithUrl)}
              kind='job-form'
              carouselStyle={{ display: 'grid' }}
            />
          )
        ))}
      {(!field.value || !field.value?.length) && (
        <DetailText>{getText('job_forms_not_reported')}</DetailText>
      )}
    </DetailContainer>
  );
};

export default PhotoUpload;

import styled from 'styled-components';
import theme from 'theme';
import {
  Minus,
  Plus,
  Download,
  ChevronLeft,
  ChevronRight,
  PlayIcon,
} from 'assets/icons';
import { ScrollLeftArrow, ScrollRightArrow } from 'assets/images';
import { Swiper as SwiperComponent } from 'swiper/react';

const { colors, mixins } = theme;

export const NoMedia = styled.div<{ height: string; width: string }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background: ${colors.black};
  color: ${colors.white};
  ${mixins.flexFullCenter};
`;

export const PhotoContainer = styled.div<{
  height?: string;
  width?: string;
  url: string;
  $cover?: boolean;
}>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background-image: url(${({ url }) => url});
  background-position: center;
  background-size: ${({ $cover }) => ($cover ? 'cover' : 'contain')};
  background-repeat: no-repeat;
  background-color: ${colors.black};
`;

export const PhotoImage = styled.img`
  width: ${({ width }) => width};
  height: 100%;
  background-color: ${colors.black};
`;

export const SelectablePhotoImage = styled.img<{ $selected: boolean }>`
  width: 90%;
  height: 90%;
  cursor: pointer;
  ${({ $selected }) => $selected && `border: 4px solid ${colors.lightOrange};`}
`;

export const PhotoAndControlsContainer = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  height: 100%;
  background-color: ${colors.black};
`;

export const SimplePhotoAndControlsContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  position: relative;
  min-height: 130px;

  .swiper-container {
    width: 80%;
    height: 88.5%;
    position: initial;
  }
  .swiper-wrapper {
    align-items: center;
  }
`;

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ZoomControls = styled.div`
  padding: 10px 15px 10px;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
`;

export const DownloadControls = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-start;
`;

export const ZoomIcon = styled(Plus)`
  height: 40px;
  width: 40px;
  margin: 0 16px;
  cursor: pointer;
`;

export const ZoomOutIcon = styled(Minus)`
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

export const DownloadIcon = styled(Download)`
  height: 40px;
  width: 40px;
  cursor: pointer;
`;

export const PhotoCarouselStyledSwiper = styled(SwiperComponent)`
  &.swiper {
    width: 100%;
    height: 88.5%;
  }
  .swiper-pagination-bullet {
    background-color: ${colors.white} !important;
    opacity: 0.7;
  }
  .swiper-pagination-bullet-active {
    background-color: ${colors.yellow} !important;
    opacity: 1;
  }

  .swiper-button-next {
    height: 40px;
    width: 40px;
    margin-right: 16px;
    opacity: 1 !important;
    color: transparent;
    background-image: url(${ScrollRightArrow});
  }
  .swiper-button-prev {
    height: 40px;
    width: 40px;
    margin-left: 16px;
    opacity: 1 !important;
    color: transparent;
    background-image: url(${ScrollLeftArrow});
  }
  .swiper-button-disabled {
    display: none;
  }
  .swiper-zoom-container {
    height: 100%;
  }
`;

export const SimpleCarouselStyledSwiper = styled(SwiperComponent)`
  .swiper-button-next {
    width: 20px;
    height: 25px;
    margin-right: 10px;
    opacity: 1 !important;
    color: transparent;
    background-image: url(${ChevronRight});
    right: -10px;
  }
  .swiper-button-prev {
    width: 20px;
    height: 25px;
    margin-left: 10px;
    opacity: 1 !important;
    color: transparent;
    background-image: url(${ChevronLeft});
    left: -10px;
  }
  .swiper-button-disabled {
    display: none;
  }
`;

export const PlaceHolder = styled.div`
  text-align: center;
`;

export const VideoContainer = styled.div<{ height: string; width: string }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background-color: ${colors.black};
  position: relative;
  ${mixins.flexFullCenter};
`;

export const StyledPlayIcon = styled(PlayIcon)`
  .fill-selector {
    fill: ${colors.yellow};
  }
`;

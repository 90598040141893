import { useEffect } from 'react';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { Loader, Media, Navbar } from 'components/ui';
import { useDispatch, useSelector } from 'react-redux';
import { clearAssessment, fetchAssessment } from 'store/actions/assessments';
import { HazardsControlsComments } from './HazardsControlsComments';
import { useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import { selectJobsView } from 'store/selectors';
import { UploadStatus } from 'model/enum/UploadStatus';
import { VideoNotUploaded } from './components/VideoNotUploaded';

export default (): JSX.Element => {
  const { jobId, assessmentId } = useParams();
  const dispatch = useDispatch();

  const assessment = useSelector(
    (state: RootState) => state.assessments.activeAssessment,
  );
  const { isActiveAssessmentLoaded: isLoaded } = useSelector(
    (state: RootState) => state.assessments,
  );
  const view = useSelector(selectJobsView);
  const videoUrl = assessment?.signedUrl;

  const isNotUploaded = assessment?.video?.status === UploadStatus.NEW;

  const formatTitle = (title) => {
    if (title.length > 30) {
      return `${title.substring(0, 30)}...`;
    }

    return title;
  };

  useEffect((): (() => void) => {
    dispatch(fetchAssessment(assessmentId));
    return () => dispatch(clearAssessment());
  }, [assessmentId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (assessmentId && jobId && videoUrl) {
      Analytics.trackPage(constants.EVENT_PAGE_VIEW_RISK_ASSESSMENT, {
        jobId,
        assessmentId,
        mediaPath: videoUrl,
        view,
      });
    }
  }, [assessmentId, jobId, videoUrl, view]);

  return (
    <>
      <Navbar
        title={assessment && formatTitle(assessment.type)}
        defaultBackPath={`/jobs/${jobId}/assessments`}
        mobileOnly
      />

      {!isLoaded && !assessment ? (
        <Loader />
      ) : (
        <>
          {isNotUploaded ? (
            <VideoNotUploaded height='425px' />
          ) : (
            <Media
              kind='assessment'
              type='video'
              url={videoUrl}
              height='425px'
              item={assessment?.video}
            />
          )}

          {assessment && <HazardsControlsComments assessment={assessment} />}
        </>
      )}
    </>
  );
};

import { Table, TableBody } from '@mui/material';
import { TableCell } from '../styled';
import { Button, Input, Loader } from 'components/ui';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectControlError,
  selectControlsForHazard,
  selectIsFetchingControlsForHazard,
  selectIsLoadingControlsChangesByHazard,
} from 'store/selectors/settings/controls';
import RootState from 'model/State/RootState';
import { useEffect, useState } from 'react';
import {
  addNewControlToHazard,
  editExistingControl,
  fetchWorkspaceControlsForHazard,
  removeExistingControl,
  resetError,
} from 'store/actions/settings/controls';
import ItemTitle from '../../ItemTitle';
import {
  CrossIcon,
  PencilIcon,
  ActionButtonContainer,
} from '../HazardRow/styled';
import {
  getLocalizedValue,
  hasItemPropertyChanged,
  isMissingTitles,
} from 'helpers/locales';
import { useLocales, useText } from 'hooks';
import { PlusIcon } from 'assets/icons';
import { useCustomLocaleText } from 'hooks/useText';
import {
  selectGlobalLanguageSetting,
  selectOrganizationId,
} from 'store/selectors';
import {
  ActionButtonsWrapper,
  ErrorMessage,
  InputContainer,
  InputWrapper,
  TableRow,
} from './styled';
import { TITLE_PROPERTY_NAME } from '../../../../../constants';
import { Control } from 'model/Assessment/Control';
import { Hazard } from 'model/Assessment/Hazard';
import { AssessmentDeleteConfirmationModal } from '../../../../AssessmentDeleteConfirmationModal';
import { InfoModal } from 'components/ui/Modal';
import * as Analytics from 'utils/analytics';
import {
  EVENT_CONTROL_DELETE_CLICKED,
  EVENT_CONTROL_DELETE_CONFIRMED,
  EVENT_CONTROL_EDIT_CLICKED,
} from '../../../../../constants';

type Props = {
  hazard: Hazard;
};

const ControlRow = ({ hazard }: Props) => {
  const getText = useText();
  const getCustomText = useCustomLocaleText();
  const dispatch = useDispatch();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const { availableLocales } = useLocales();
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const organisationId = useSelector(selectOrganizationId);
  const isFetchingControlsForHazard = useSelector(
    selectIsFetchingControlsForHazard(hazard?.id),
  );
  const isLoadingControlsChange = useSelector(
    selectIsLoadingControlsChangesByHazard(hazard?.id),
  );
  const controls = useSelector(selectControlsForHazard(hazard?.id));
  const error = useSelector(selectControlError);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [titlesByLanguage, setTitlesByLanguage] = useState<{
    [key: string]: string;
  }>({});
  const [editedControl, setEditedControl] = useState<Control | null>(null);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const [showDeleteInfo, setShowDeleteInfo] = useState<boolean>(false);
  const [controlToDelete, setControlToDelete] = useState<Control | null>(null);

  const isUnchangedTitles =
    isUpdate &&
    editedControl &&
    !hasItemPropertyChanged(
      editedControl,
      titlesByLanguage,
      TITLE_PROPERTY_NAME,
      availableLocales,
    );

  const isAddEditDisabled =
    isLoadingControlsChange ||
    isMissingTitles(titlesByLanguage, availableLocales) ||
    isUnchangedTitles;

  useEffect(() => {
    dispatch(resetError());
    if (selectedWorkspace?.uuid && hazard?.id) {
      dispatch(
        fetchWorkspaceControlsForHazard(selectedWorkspace?.uuid, hazard?.id),
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoadingControlsChange) {
      resetForm();
    }
  }, [isLoadingControlsChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const setValueByLocale = (locale, value) => {
    setTitlesByLanguage((prevState) => ({ ...prevState, [locale]: value }));
  };

  const resetForm = () => {
    setTitlesByLanguage({});
    setIsUpdate(false);
  };

  const addOrEditControl = () => {
    dispatch(resetError());
    if (isUpdate) {
      dispatch(
        editExistingControl(selectedWorkspace?.uuid, hazard?.id, {
          ...editedControl,
          ...titlesByLanguage,
        }),
      );
    } else {
      dispatch(
        addNewControlToHazard(
          selectedWorkspace?.uuid,
          hazard?.id,
          titlesByLanguage,
        ),
      );
    }
  };

  const editControl = (control) => {
    setIsUpdate(true);
    setEditedControl(control);
    availableLocales.forEach((locale) => {
      setValueByLocale(
        locale,
        getLocalizedValue(control, TITLE_PROPERTY_NAME, locale) ?? '',
      );
    });
    Analytics.trackEvent(EVENT_CONTROL_EDIT_CLICKED, {
      hazardId: hazard?.id,
      hazardName: hazard?.title,
      hazardName_es: hazard?.titleEs,
      isMajor: hazard?.isMajor,
      controlName: control?.title,
      controlName_es: control?.titleEs,
      workspaceUuid: selectedWorkspace?.uuid,
      organisationId,
    });
  };

  const deleteControl = (control) => {
    dispatch(resetError());
    if (controls?.length === 1) {
      setShowDeleteInfo(true);
    } else {
      setDeleteConfirmationOpen(true);
      setControlToDelete(control);
    }
    Analytics.trackEvent(EVENT_CONTROL_DELETE_CLICKED, {
      hazardId: hazard?.id,
      hazardName: hazard?.title,
      hazardName_es: hazard?.titleEs,
      isMajor: hazard?.isMajor,
      controlName: control?.title,
      controlName_es: control?.title_es,
      workspaceUuid: selectedWorkspace?.uuid,
      organisationId,
    });
  };

  const confirmDeleteControl = () => {
    dispatch(
      removeExistingControl(
        selectedWorkspace?.uuid,
        hazard?.id,
        controlToDelete,
      ),
    );
    setDeleteConfirmationOpen(false);
    Analytics.trackEvent(EVENT_CONTROL_DELETE_CONFIRMED, {
      hazardId: hazard?.id,
      hazardName: hazard?.title,
      hazardName_es: hazard?.titleEs,
      isMajor: hazard?.isMajor,
      controlName: controlToDelete?.title,
      controlName_es: controlToDelete?.titleEs,
      workspaceUuid: selectedWorkspace?.uuid,
      organisationId,
    });
  };

  return (
    <Table>
      <TableBody>
        {isFetchingControlsForHazard && (
          <TableRow>
            <TableCell colSpan={5}>
              <Loader />
            </TableCell>
          </TableRow>
        )}
        {!isFetchingControlsForHazard &&
          controls?.length > 0 &&
          controls?.map((control) => (
            <TableRow key={`control-${control.id}`}>
              <TableCell width={75} colSpan={2}>
                <ItemTitle item={control} extraStyles={{ padding: '0 8px' }} />
              </TableCell>
              <TableCell width={15}>
                <ActionButtonContainer>
                  <Button.Confirm
                    onClick={() => editControl(control)}
                    IconComponent={PencilIcon}
                  />
                  <Button.Confirm
                    onClick={() => deleteControl(control)}
                    IconComponent={CrossIcon}
                  />
                </ActionButtonContainer>
              </TableCell>
              <TableCell width={10} />
            </TableRow>
          ))}
        <TableRow>
          <TableCell width={100} colSpan={5}>
            <InputWrapper>
              <InputContainer
                $swapOrder={globalAppLanguage !== availableLocales[0]}
              >
                {availableLocales.map((locale) => (
                  <Input.Text
                    key={locale}
                    id={`assessment-${locale}-input`}
                    value={titlesByLanguage[locale] ?? ''}
                    placeholder={getCustomText(
                      'workspace_settings_control_label',
                      locale,
                    )}
                    onChange={(e) => setValueByLocale(locale, e.target.value)}
                    disabled={isLoadingControlsChange}
                    maxLength={300}
                    extraStyles={{ margin: '10px 0' }}
                  />
                ))}
              </InputContainer>
              <ActionButtonsWrapper>
                <Button.Primary
                  text={getText(
                    isUpdate
                      ? 'workspace_settings_update'
                      : 'workspace_settings_add',
                  )}
                  IconComponent={isUpdate ? null : PlusIcon}
                  onClick={addOrEditControl}
                  disabled={isAddEditDisabled}
                  width='150px'
                  extraStyles={{ margin: 0 }}
                />
                {Object.values(titlesByLanguage).some((v) => v !== '') &&
                  !isLoadingControlsChange && (
                    <Button.Confirm
                      onClick={resetForm}
                      text={getText('cancel')}
                      width='100px'
                      extraStyles={{ margin: '0 0 0 20px' }}
                    />
                  )}
              </ActionButtonsWrapper>
            </InputWrapper>
            {error && (
              <ErrorMessage>
                {getText('workspace_settings_error_single', {
                  type: getLocalizedValue(
                    error,
                    TITLE_PROPERTY_NAME,
                    globalAppLanguage,
                  ),
                })}
              </ErrorMessage>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
      {showDeleteInfo && (
        <InfoModal
          isVisible={showDeleteInfo}
          setVisible={setShowDeleteInfo}
          infoMessage={getText(
            'workspace_settings_control_delete_warning_only_one',
          )}
        />
      )}
      {isDeleteConfirmationOpen && controlToDelete && (
        <AssessmentDeleteConfirmationModal
          isVisible={isDeleteConfirmationOpen}
          setVisible={setDeleteConfirmationOpen}
          title={getText('workspace_settings_control_delete_confirmation', {
            control: getLocalizedValue(
              controlToDelete,
              'title',
              globalAppLanguage,
            ),
          })}
          warningMessages={[
            getText('workspace_settings_control_delete_warning'),
          ]}
          saveButtonText={getText('continue_cta')}
          onButtonClick={confirmDeleteControl}
        />
      )}
    </Table>
  );
};

export default ControlRow;

import { useState } from 'react';
import ReactPlayer from 'react-player';
import { ThumbnailVideoPlaceholder as placeholder } from 'assets/images';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import UserFormAnalyticsParameter from 'model/Forms/UserFormAnalyticsParameters';
import { Photo } from './Photo';
import { useSelector } from 'react-redux';
import { selectJobsView } from 'store/selectors';
import { StyledPlayIcon, VideoContainer } from './styled';
import { QualityWarningLabel } from '../QualityWarning/QualityWaningLabel';
import { Video as VideoModel } from 'model/Media/Video';

type Props = {
  url: string;
  height: string;
  width: string;
  kind: string;
  thumbnail?: string;
  isRams?: boolean;
  userFormParameters?: UserFormAnalyticsParameter;
  onVideoPlayPause?: (isPlaying: boolean) => void;
  onVideoProgress?: (durationSeconds: number) => void;
  item?: VideoModel;
};

export const Video = ({
  url,
  kind,
  height,
  width,
  thumbnail,
  isRams,
  userFormParameters,
  onVideoPlayPause,
  onVideoProgress,
  item,
}: Props): JSX.Element => {
  const [videoError, setVideoError] = useState<boolean>(false);
  const [playedDuration, setPlayedDuration] = useState<number>(0);
  const [showVideoQualityLabel, setShowVideoQualityLabel] =
    useState<boolean>(true);
  const view = useSelector(selectJobsView);
  const [videoLength, setVideoLength] = useState<number>(0);

  const onPlay = () => {
    if (userFormParameters) {
      Analytics.trackEvent(constants.FORM_DASHBOARD_VIDEO_PLAYED, {
        ...userFormParameters,
        duration_played: playedDuration,
      });
    } else {
      Analytics.trackEvent(`${kind}-video-played`, {
        url,
        [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        duration_played: playedDuration,
      });
    }
    setShowVideoQualityLabel(false);
    if (onVideoPlayPause) {
      onVideoPlayPause(true);
    }
  };

  const onPause = () => {
    if (userFormParameters) {
      Analytics.trackEvent(
        constants.FORM_DASHBOARD_VIDEO_PAUSED,
        userFormParameters,
      );
    } else {
      Analytics.trackEvent(`${kind}-video-paused`, {
        url,
        [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
        duration_played: playedDuration,
      });
    }
    setShowVideoQualityLabel(true);
    if (onVideoPlayPause) {
      onVideoPlayPause(false);
    }
  };

  const onLoadError = () => {
    setVideoError(true);
  };

  return !videoError ? (
    <VideoContainer height={height} width={width}>
      {item && showVideoQualityLabel && (
        <QualityWarningLabel video={item} length={videoLength} />
      )}
      <ReactPlayer
        url={url}
        height='100%'
        width='100%'
        controls
        onPlay={onPlay}
        onPause={onPause}
        onProgress={(progress) => {
          setPlayedDuration(progress.playedSeconds);
          if (onVideoProgress) {
            onVideoProgress(progress.playedSeconds);
          }
        }}
        onError={onLoadError}
        onDuration={(duration) => setVideoLength(duration)}
        playIcon={
          <div>
            <StyledPlayIcon />
          </div>
        }
        light={isRams && thumbnail}
      />
    </VideoContainer>
  ) : (
    <VideoContainer height={height} width={width}>
      <Photo url={placeholder} height={height} width={width} forceImage />
    </VideoContainer>
  );
};

import { Button } from 'components/ui';
import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  padding: 2px;
  position: sticky;
  bottom: 0;
  margin-top: auto;
`;

type Props = {
  text: string;
  onClick: () => void;
  loading: boolean;
  disabled?: boolean;
  event?: string;
  eventProperties?: object;
  extraStyles?: React.CSSProperties;
};

const ModalCtaButton = ({
  text,
  onClick,
  loading = false,
  disabled = false,
  event,
  eventProperties,
  extraStyles,
}: Props): JSX.Element => {
  return (
    <ButtonContainer>
      <Button.Primary
        event={event}
        eventProperties={eventProperties}
        text={text}
        onClick={onClick}
        extraStyles={extraStyles}
        disabled={disabled}
        loading={loading}
        outlineOffset='-1px'
      />
    </ButtonContainer>
  );
};

export default ModalCtaButton;

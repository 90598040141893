import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Evidence } from 'api';
import { Storage } from 'aws-amplify';
import { useIsMobile, useText } from 'hooks';
import { Button, Navbar, Loader, Upload } from 'components/ui';
import theme from 'theme';
import * as constants from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalLanguageSetting, selectJobsView } from 'store/selectors';
import { appendEvidence } from 'store/actions/jobs';
import RootState from 'model/State/RootState';
import { useNavigate, useParams } from 'react-router-dom';
import { EvidenceType } from 'model/Evidence/EvidenceType';
import { UploadStatus } from 'model/enum/UploadStatus';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

const { fonts, fontSizes, media } = theme;

const Title = styled.h1`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  margin-bottom: 16px !important;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  overflow-y: auto;
  ${media.sm`
    padding: 10px;
   `}
`;

type Props = {
  setEvidenceMediaSelect?: (value: boolean) => void;
  evidenceTypeSelect?: boolean;
};

// AddContent.AddEvidence
const AddEvidence = ({
  setEvidenceMediaSelect = () => {},
  evidenceTypeSelect,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const isMobile = useIsMobile();
  const getText = useText();
  const dispatch = useDispatch();
  const view = useSelector(selectJobsView);

  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const [evidenceTypes, setEvidenceTypes] = useState<EvidenceType[]>([]);
  const [selectedEvidenceType, setSelectedEvidenceType] = useState<
    number | null
  >(null);
  const [selectedMediaType, setSelectedMediaType] = useState<string | null>(
    null,
  );
  const [showUploadEvidence, setShowUploadEvidence] = useState<boolean>(false);
  const [comments, setComments] = useState<string>('');
  const [fileList, setFileList] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const types = await Evidence.getEvidenceTypes(selectedWorkspace?.uuid);
      setEvidenceTypes(types);
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (evidenceTypeSelect) {
      setSelectedEvidenceType(null);
      setSelectedMediaType(null);
      setShowUploadEvidence(false);
    }
  }, [evidenceTypeSelect]);

  const renderTypeSelect = () => {
    if (selectedEvidenceType) {
      return (
        <>
          <Title>{getText('evidence_creation_media_title')}</Title>
          <Button.Secondary
            text={getText('evidence_creation_media_option_image')}
            onClick={() => {
              setSelectedMediaType('photo');
              setShowUploadEvidence(true);
            }}
            extraStyles={{ margin: 0, marginBottom: '10px' }}
            event={constants.EVIDENCE_MEDIA_OPTION_IMAGE_CLICKED}
            eventProperties={{
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
          />
          <Button.Secondary
            text={getText('evidence_creation_media_option_video')}
            onClick={() => {
              setSelectedMediaType('video');
              setShowUploadEvidence(true);
            }}
            extraStyles={{ margin: 0, marginBottom: '10px' }}
            event={constants.EVIDENCE_MEDIA_OPTION_VIDEO_CLICKED}
            eventProperties={{
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
          />
        </>
      );
    }
    return (
      <>
        <Title>{getText('evidence_creation_types_title')}</Title>
        {evidenceTypes
          .filter((type) => type.title !== 'Wrap up')
          .map((type) => (
            <Button.Secondary
              text={getLocalizedCamelCaseValue(
                type,
                'title',
                globalAppLanguage,
              )}
              key={type.id}
              onClick={() => {
                setSelectedEvidenceType(type.id);
                if (!isMobile) {
                  setEvidenceMediaSelect(true);
                }
              }}
              extraStyles={{ margin: 0, marginBottom: '10px' }}
              event={constants.EVIDENCE_MEDIA_OPTION_TYPE_SELECTED}
              eventProperties={{
                [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
              }}
            />
          ))}
      </>
    );
  };

  const pushToTimeline = () => {
    if (isMobile || view !== constants.LEGACY_VIEW) {
      navigate(`/jobs/${jobId}/timeline`);
    } else {
      navigate(`/jobs/${jobId}`);
    }
  };

  const uploadFilePhoto = async (files) => {
    if (!jobId || !selectedEvidenceType) {
      return;
    }
    setIsLoading(true);
    try {
      // STEP1 - Call evidence API to create new evidence object
      const evidence = await Evidence.create(
        jobId,
        selectedEvidenceType,
        comments,
      );
      dispatch(appendEvidence(evidence));
      // STEP2 - Create photos object for evidence
      const evidencePhotos = await Evidence.createPhoto(
        evidence.id,
        files.length,
      );
      // STEP3 - Upload files to bucket with paths returned from backend
      evidencePhotos.map(async (evidencePhoto, index) => {
        await Storage.put(evidencePhoto.path, files[index]);
        await Evidence.updatePhoto(
          evidence.id,
          evidencePhoto.fileId,
          UploadStatus.UPLOADED,
        );
      });
      pushToTimeline();
    } catch (err) {
      setIsLoading(false);
      setError((err as Error)?.message);
    }
  };

  const uploadVideoFile = async (file) => {
    if (!jobId || !selectedEvidenceType) {
      return;
    }
    setIsLoading(true);
    try {
      // STEP1 - Call evidence API to create new evidence object
      const evidence = await Evidence.create(
        jobId,
        selectedEvidenceType,
        comments,
      );
      dispatch(appendEvidence(evidence));
      // STEP2 - Create video object for evidence
      const evidenceVideo = await Evidence.createVideo(evidence.id);
      // STEP3 - Upload file to bucket with path returned from backend
      await Storage.put(evidenceVideo.path, file);
      await Evidence.updateVideo(
        evidence.id,
        evidenceVideo.fileId,
        UploadStatus.UPLOADED,
      );
      pushToTimeline();
    } catch (err) {
      setIsLoading(false);
      setError((err as Error)?.message);
    }
  };

  const onSubmit = () => {
    if (fileList.length > 0) {
      if (selectedMediaType === 'photo') {
        uploadFilePhoto(fileList);
      }
      if (selectedMediaType === 'video') {
        fileList.map(async (file) => uploadVideoFile(file));
      }
    } else {
      setError(getText('upload_error_no_file'));
    }
  };

  const removeFileItem = (file: File) => {
    setError(null);
    setFileList(fileList.filter((item) => item !== file));
  };

  const onFileListChangePhoto = (files: File[]) => {
    setError(null);
    if (files.some((file) => file.size > 15000000)) {
      setError(getText('upload_error_large_file', { size: 15 }));
      return;
    }
    const fileArray = [...fileList, ...files];
    setFileList(fileArray.slice(0, 6));
    if (fileArray.length > 6) {
      setError(getText('upload_error_max_number'));
    }
  };

  const onFileListChangeVideo = (files: File[]) => {
    setError(null);
    const firstFile = files[0];
    if (!firstFile) {
      setError(getText('upload_error_none_added'));
      return;
    }
    if (firstFile.size > 100000000) {
      setError(getText('upload_error_large_file', { size: 100 }));
    } else {
      setFileList([firstFile]);
    }
  };

  return (
    <>
      <Navbar
        title={getText('upload_add_evidence')}
        defaultBackPath={
          selectedEvidenceType
            ? `/jobs/${jobId}/add/evidence`
            : `/jobs/${jobId}/add`
        }
        mobileOnly
      />
      <Wrapper>
        {!evidenceTypes && <Loader />}
        {evidenceTypes && !showUploadEvidence && renderTypeSelect()}
        {showUploadEvidence && (
          <Upload.UploadContent
            acceptType={
              selectedMediaType === 'photo'
                ? '.gif,.jpg,.jpeg,.png'
                : '.mpg,.mpeg,.mp4'
            }
            jobId={jobId}
            onChange={
              selectedMediaType === 'photo'
                ? onFileListChangePhoto
                : onFileListChangeVideo
            }
            setComments={setComments}
            title={getText('upload_add_evidence')}
            onSubmit={onSubmit}
            isLoading={isLoading}
            trackEvent={constants.EVENT_ADD_EVIDENCE_UPLOAD}
            eventProperties={{
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            selectedMediaType={selectedMediaType}
            error={error}
            fileList={fileList}
            comments={comments}
            removeFileItem={removeFileItem}
          />
        )}
      </Wrapper>
    </>
  );
};

export default AddEvidence;

import { useEffect, useState } from 'react';
import { useText } from 'hooks';
import theme from 'theme';
import { Button } from 'components/ui';
import { User as UserApi } from 'api';
import { useDebounce } from 'use-debounce';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import AddEditUserModal from '../AddEditUserModal';
import RootState from 'model/State/RootState';
import UserDataRow from 'model/UserDataRow';
import { BackendUser } from 'model/BackendUser';
import { fetchFormOptions } from 'store/actions/form';
import { selectFormOptions } from 'store/selectors';
import { downloadBlob } from 'helpers/files';
import * as Logger from 'utils/logger';
import { setGlobalError } from 'store/slices/notifications';
import { formatDate } from 'helpers/dates';
import {
  ButtonsWrapper,
  Count,
  DataContainer,
  DownloadIcon,
  Header,
  SearchContainer,
  SearchHeader,
  SearchIcon,
  SearchInput,
  StyledPlusIcon,
  TotalContainer,
  TotalHeader,
  TotalTitle,
  UserButtonContainer,
} from './styled';
import UsersDataGrid from './UsersDataGrid';
import { PaginationConfig } from './constants';

const { colors, fontSizes } = theme;

export const UserSettings = (): JSX.Element => {
  const dispatch = useDispatch();
  const getText = useText();
  const { currentUser, selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const formOptions = useSelector(selectFormOptions);
  const [userData, setUserData] = useState<UserDataRow[]>([]);
  const [isFetchingUsers, setFetchingUsers] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [searchFilter] = useDebounce(search, 600);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<UserDataRow | null>(null);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [paginationInfo, setPaginationInfo] = useState<{
    userCount: number;
    pageCount: number;
    activeUserCount: number;
  }>({
    userCount: 0,
    pageCount: 0,
    activeUserCount: 0,
  });
  const [isExportingUsers, setIsExportingUsers] = useState(false);

  const onPaginationChange = (page) => {
    getUsers(page);
    setCurrentPage(page);
  };

  const paginationConfig: PaginationConfig = {
    pageSize: 20,
    total: paginationInfo.pageCount,
    onChange: onPaginationChange,
    current: currentPage,
    showSizeChanger: false,
  };

  useEffect(() => {
    if (!formOptions) {
      dispatch(fetchFormOptions());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getUsers();
  }, [searchFilter, selectedWorkspace]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUsers = async (page?: number) => {
    setUserData([]);
    setFetchingUsers(true);
    const { data } = await UserApi.getUsers(
      selectedWorkspace?.uuid,
      searchFilter,
      20,
      page,
    );
    setPaginationInfo({
      userCount: data?.users_count,
      pageCount: data?.count,
      activeUserCount: data?.active_users_count,
    });
    const rows = data?.results.map((result: BackendUser) =>
      mapUserToDataRow(result),
    );
    setUserData(rows);
    setFetchingUsers(false);
  };

  const mapUserToDataRow = (user: BackendUser): UserDataRow => ({
    key: `table-row ${user.id}`,
    date_joined: formatDate(user.date_joined),
    depot_label: user.depot?.title || '-',
    depot_id: user.depot?.id,
    email: user.email,
    phone_number: user.phone_number || '-',
    role: user.is_deviceless
      ? getText('add_user_role_fieldworker_digital_signature')
      : user.role,
    first_name: user.first_name,
    last_name: user.last_name,
    name: `${user.first_name} ${user.last_name}`,
    is_active: !!user.is_active,
    is_deviceless: !!user.is_deviceless,
    id: user.id,
    externalId: user.external_id || '',
  });

  const updateUser = async (user) => {
    const { data } = await UserApi.updateUser(user);
    setUserData(
      userData.map((value) => {
        if (value.id === data.id) {
          return {
            ...value,
            is_active: data.is_active,
          };
        }
        return value;
      }),
    );
  };

  const onAddUserClick = () => {
    setShowAddUserModal(true);
  };

  const onAddUserSuccess = (createdUser) => {
    Analytics.trackEvent(constants.EVENT_ADD_NEW_USER, {
      new_user_id: createdUser.id,
      current_user_id: currentUser.id,
    });
    const data = mapUserToDataRow(createdUser);
    setUserData([data, ...userData]);
  };

  const editUser = (user) => {
    setShowEditModal(true);
    setSelectedUser(user);
  };

  const onEditUserSuccess = (editedUser) => {
    Analytics.trackEvent(constants.EVENT_EDIT_USER_SUCCESS, {
      edited_user_id: editedUser.id,
      current_user_id: currentUser.id,
    });
    const data = mapUserToDataRow(editedUser);
    setUserData((prevState) =>
      prevState.map((user) => {
        if (user.id === editedUser.id) {
          return data;
        } else {
          return user;
        }
      }),
    );
  };

  const getCSVData = async () => {
    try {
      setIsExportingUsers(true);
      const csv = await UserApi.getUsersCSV(selectedWorkspace?.uuid);
      const filename = `FYLD_Users.csv`;
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      downloadBlob(blob, filename);
    } catch (err) {
      dispatch(
        setGlobalError(getText('settings_tab_users_export_error_message')),
      );
      Logger.error(err);
    }
    setIsExportingUsers(false);
  };

  const getTotalContainer = (text, count) => (
    <TotalContainer>
      <TotalTitle>{text}</TotalTitle>
      <Count>{count}</Count>
    </TotalContainer>
  );

  return (
    <>
      <DataContainer>
        <Header>
          <TotalHeader>
            {getTotalContainer(
              getText('users_total_users'),
              paginationInfo.userCount,
            )}
            {getTotalContainer(
              getText('users_total_active_users'),
              paginationInfo.activeUserCount,
            )}
          </TotalHeader>
          <SearchHeader>
            <ButtonsWrapper>
              <SearchContainer>
                <SearchInput
                  placeholder={getText('users_search')}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setCurrentPage(1);
                  }}
                />
                <SearchIcon />
              </SearchContainer>
              <UserButtonContainer>
                {formOptions && (
                  <Button.Primary
                    text={getText('add_user_new')}
                    width='50%'
                    onClick={onAddUserClick}
                    IconComponent={StyledPlusIcon}
                    event={constants.EVENT_ADD_NEW_USER_MODAL_OPEN}
                    extraStyles={{
                      height: '44px',
                      fontSize: `${fontSizes.s}`,
                      margin: '0 10px',
                      alignSelf: 'auto',
                    }}
                  />
                )}
                <Button.Secondary
                  text={getText('users_export_list')}
                  width='50%'
                  onClick={getCSVData}
                  IconComponent={DownloadIcon}
                  loading={isExportingUsers}
                  event={constants.EVENT_EXPORT_USER_LIST}
                  extraStyles={{
                    height: '44px',
                    backgroundColor: `${colors.offWhite}`,
                    fontSize: `${fontSizes.s}`,
                    padding: '0 10px',
                    paddingLeft: '10px',
                    alignSelf: 'auto',
                  }}
                />
              </UserButtonContainer>
            </ButtonsWrapper>
          </SearchHeader>
        </Header>
        <UsersDataGrid
          data={userData}
          isLoading={isFetchingUsers}
          paginationConfig={paginationConfig}
          updateUser={updateUser}
          editUser={editUser}
        />
      </DataContainer>
      {showAddUserModal && (
        <AddEditUserModal
          isVisible={showAddUserModal}
          setVisible={setShowAddUserModal}
          onSuccess={onAddUserSuccess}
        />
      )}
      {showEditModal && selectedUser && (
        <AddEditUserModal
          isVisible={showEditModal}
          setVisible={setShowEditModal}
          initialUser={selectedUser}
          onSuccess={onEditUserSuccess}
        />
      )}
    </>
  );
};

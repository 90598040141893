import Filters from 'model/Filters';
import Team from 'model/Team';
import { BackendUser } from 'model/BackendUser';

export const getSelectedMemberIds = (
  filters: Filters,
  team: Team | null,
): number[] => {
  const userArray: BackendUser[] = [];
  if (filters?.members) {
    userArray.push(...filters.members);
  }
  if (team?.members) {
    userArray.push(...team.members);
  }
  return Array.from(new Set(userArray.map((user) => user.id)));
};

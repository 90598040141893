import { Dispatch, SetStateAction } from 'react';
import { useText } from 'hooks';
import JobData from 'model/Insight/JobData';
import { Button, Modal } from 'components/ui';
import {
  ButtonWrapper,
  CrossIcon,
  DemoImage,
  Description,
  HalfSection,
  JobItem,
  JobNumber,
  ListContainer,
  ListIndex,
  SplitWrapper,
  TextSection,
  Title,
} from './styled';
import theme from 'theme';

type Props = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  title?: string;
  description?: string | JSX.Element;
  jobList?: JobData[];
  image?: any;
  hasCrossIcon?: boolean;
};

// AppFunctionalityInfoModal
const AppFunctionalityInfoModal = ({
  isVisible,
  setIsVisible,
  title,
  description,
  jobList,
  image,
  hasCrossIcon = false,
}: Props): JSX.Element | null => {
  const getText = useText();
  const renderJob = (index: number, job) => (
    <JobItem key={job?.id}>
      <ListIndex>{index + 1}.</ListIndex>
      <JobNumber target='_blank' href={`/jobs/${job?.id}`}>
        <b>{getText('insights_summary_tooltip_work_order_number')}</b>{' '}
        {job?.job_number}
      </JobNumber>
    </JobItem>
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Modal.Base
      isOpen={isVisible}
      onClose={() => setIsVisible(false)}
      width='50vw'
      isCentered
    >
      {image ? (
        <SplitWrapper>
          <HalfSection>
            {hasCrossIcon && (
              <CrossIcon
                onClick={() => setIsVisible(false)}
                color={theme.colors.yellow}
              />
            )}
            <TextSection>
              {title && <Title variant='image'>{title}</Title>}
              {description && (
                <Description variant='image'>{description}</Description>
              )}
            </TextSection>
            <ButtonWrapper>
              <Button.Confirm
                onClick={() => setIsVisible(false)}
                text={getText('ok').toLocaleUpperCase()}
                width='130px'
              />
            </ButtonWrapper>
          </HalfSection>
          <HalfSection $backgroundColor={theme.colors.black}>
            <DemoImage src={image} alt='' />
          </HalfSection>
        </SplitWrapper>
      ) : (
        <>
          <CrossIcon
            onClick={() => setIsVisible(false)}
            color={theme.colors.black}
          />
          {title && <Title>{title}</Title>}
          {jobList && jobList?.length > 0 ? (
            <ListContainer>
              {jobList.map((job, index) => renderJob(index, job))}
            </ListContainer>
          ) : null}
          {description && <Description>{description}</Description>}
        </>
      )}
    </Modal.Base>
  );
};

export default AppFunctionalityInfoModal;

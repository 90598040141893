import React from 'react';
import { Loader, Message } from 'components/ui';
import { useDispatch, useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import { useEffect, useRef } from 'react';
import { useText } from 'hooks';
import { getMoreMessages } from 'store/actions/messages';
import { useParams } from 'react-router-dom';

import {
  FeedContainer,
  LoadMoreWrapper,
  LoadMore,
  NewMessageWrapper,
  NewMessage,
  NewMessageLine,
} from './styled';
import { Message as MessageType } from 'model/Message';

type Props = {
  currentActionId: number | null;
  newMessageMarkerPosition: number | null;
  setIsGetMoreMessages: (value: boolean) => void;
  setCurrentActionId: (value: number) => void;
};

const Feed = ({
  currentActionId,
  newMessageMarkerPosition,
  setIsGetMoreMessages,
  setCurrentActionId,
}: Props): JSX.Element => {
  const params = useParams();
  const { jobId } = params;
  const { activeJob } = useSelector((state: RootState) => state.jobs);
  const { messages, scrollToBottom, nextPage, isLoadingNewMessage } =
    useSelector((state: RootState) => state.messages);
  const getText = useText();
  const dispatch = useDispatch();
  const feedBottom = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      activeJob &&
      messages.length > 0 &&
      scrollToBottom &&
      !currentActionId
    ) {
      feedBottom?.current?.scrollIntoView();
    }
  }, [messages, activeJob, scrollToBottom]); // eslint-disable-line react-hooks/exhaustive-deps

  const displayMessages = () =>
    messages.map((message, index) => {
      if (index === 0 && nextPage) {
        return (
          <React.Fragment key={`more_messages_${message.id}`}>
            <LoadMoreWrapper>
              {isLoadingNewMessage ? (
                <Loader />
              ) : (
                <LoadMore
                  onClick={() => {
                    setIsGetMoreMessages(true);
                    dispatch(getMoreMessages(nextPage));
                  }}
                >
                  {getText('load_more')}
                </LoadMore>
              )}
            </LoadMoreWrapper>
            {displayMessage(message)}
          </React.Fragment>
        );
      }

      if (newMessageMarkerPosition && index === newMessageMarkerPosition) {
        return (
          <React.Fragment key={`new_message_${message.id}`}>
            <NewMessageWrapper>
              <NewMessageLine />
              <NewMessage>{getText('new_messages')}</NewMessage>
              <NewMessageLine />
            </NewMessageWrapper>
            {displayMessage(message)}
          </React.Fragment>
        );
      }

      return displayMessage(message);
    });

  const displayMessage = (message: MessageType) => {
    if (!activeJob) {
      return;
    }

    return (
      <Message.Message
        job={activeJob}
        message={message}
        onClick={() => setCurrentActionId(message.id)}
        scrollTo={message.id === currentActionId}
        key={message.id}
      />
    );
  };

  const canDisplayMessages =
    activeJob?.id && activeJob.id.toString() === jobId && messages.length > 0;

  return (
    <FeedContainer>
      {canDisplayMessages ? displayMessages() : <Loader />}
      <div ref={feedBottom} />
    </FeedContainer>
  );
};

export default Feed;

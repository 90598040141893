export const fatigueLiveDataFields = {
  NAME: 'name',
  REGION: 'depot',
  SHIFT_TIME_SINCE_LAST_BREAK: 'shiftTimeSinceLastBreakLabel',
  TOTAL_SHIFT_TIME: 'totalShiftTimeLabel',
  FRA: 'fra',
  PERCEIVED_RISK: 'perceivedRisk',
};

export const fatigueHistoricDataFields = {
  NAME: 'name',
  REGION: 'region',
  STARTED_AT: 'startedAt',
  FINISHED_AT: 'finishedAt',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  DURATION: 'durationLabel',
  FRA: 'fra',
};

export const FATIGUE_LIVE_PAGE_SIZE = 100;
export const FATIGUE_HISTORIC_PAGE_SIZE = 25;

export const EVENT_STOP_TIMER = 'insights-fatigue-stop-timer-clicked';
export const EVENT_VIEW_FRA = 'insights-fatigue-view-fra-clicked';
export const EVENT_CONFIRM_SHIFT_END = 'insights-fatigue-stop-timer-confirmed';
export const EVENT_FATIGUE_LIVE_VIEW_SORT_BY = 'fatigue-live-view-sort-by';
export const EVENT_FATIGUE_HISTORIC_VIEW_SORT_BY =
  'fatigue-historic-view-sort-by';
export const EVENT_FRA_SUBMITTED = 'admin-fra-risk-assessment-submitted';

import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
import { ManagerComment } from '../../ManagerComment';
import { Button, Input } from 'components/ui';
import { useEffect, useState } from 'react';
import { Separator } from 'components/ui/Misc';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalError } from 'store/slices/notifications';
import { UserForms } from 'api';
import { useLocation } from 'react-router-dom';
import RootState from 'model/State/RootState';
import { EVENT_HAVS_COMPLIANCE_COMMENT_SENT } from 'utils/constants';
import { formatDate, todayString } from 'helpers/dates';
import { fetchHavsCompliance } from 'store/actions/insights';
import { selectActiveWorkspaceUuid } from 'store/selectors';
import { Comment } from 'model/Form';

const { colors, fonts } = theme;

const InputContainer = styled.div`
  padding: 25px;
`;

const CommentContainer = styled.div`
  padding: 25px;
  overflow: auto;
`;

const DateRangeContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DateRangeText = styled.div`
  color: ${colors.darkGrey};
  font-family: ${fonts.bold};
  padding-right: 10px;
`;

// HavsCommentModal
const HavsCommentModal = (): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const location = useLocation();
  const { currentUser } = useSelector((state: RootState) => state.session);
  const selectedWorkspaceUuid = useSelector(selectActiveWorkspaceUuid);
  const [comment, setComment] = useState<string>('');
  const [commentList, setCommentList] = useState<Comment[]>([]);
  const [isCommentSending, setIsCommentSending] = useState<boolean>(false);

  const {
    userId,
    startDate,
    endDate,
    depotId,
    isWeekendIncluded,
    sort,
    userIds,
    search,
    page,
  } = location.state;

  useEffect(() => {
    (async () => {
      try {
        const data = await UserForms.getHavsComplianceComments(
          userId,
          startDate,
          endDate,
        );
        if (data) {
          setCommentList(data);
        }
      } catch (err) {
        dispatch(setGlobalError(err));
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async () => {
    setIsCommentSending(true);
    try {
      if (userId) {
        const data = await UserForms.sendHavsComplianceComment(
          userId,
          currentUser?.id,
          comment,
          startDate,
          endDate,
        );
        if (data) {
          setCommentList((prevComments) => [data, ...prevComments]);

          if (depotId) {
            dispatch(
              fetchHavsCompliance(
                depotId,
                startDate,
                endDate,
                !isWeekendIncluded,
                sort,
                userIds,
                search,
                selectedWorkspaceUuid,
                page,
              ),
            );
          }
        }
      }
    } catch (err) {
      dispatch(setGlobalError(err));
    }
    setComment('');
    setIsCommentSending(false);
  };

  return (
    <>
      <InputContainer>
        <Input.TextArea
          id='manager-comment'
          label={getText('insights_forms_manager_comment')}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </InputContainer>
      {commentList?.length > 0 && (
        <>
          <Separator />
          <CommentContainer>
            <ManagerComment
              comments={commentList}
              subTitle={
                <DateRangeContainer>
                  <DateRangeText>
                    {getText('insights_forms_date_range')}
                  </DateRangeText>
                  {`${formatDate(startDate)} ${getText(
                    'insights_forms_date_range_to',
                  )} ${formatDate(endDate)}`}
                </DateRangeContainer>
              }
            />
          </CommentContainer>
        </>
      )}
      <Button.Primary
        text={getText('submit')}
        onClick={handleSubmit}
        loading={isCommentSending}
        disabled={isCommentSending || !comment.trim()}
        event={EVENT_HAVS_COMPLIANCE_COMMENT_SENT}
        eventProperties={{
          createdBy: currentUser,
          createdAt: todayString,
          comment,
          userId: userId,
          startDate: startDate,
          endDate: endDate,
        }}
        extraStyles={{
          position: 'sticky',
          bottom: '0',
          marginTop: 'auto',
          flex: '0 0 55px',
        }}
      />
    </>
  );
};

export default HavsCommentModal;

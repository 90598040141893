import styled from 'styled-components';
import theme from 'theme';
import { Separator } from 'components/ui/Misc';
import { hasVideo } from 'helpers/forms';
import { WidgetKind } from 'utils/constants';
import MultiSelect from '../../JobForms/components/Widgets/MultiSelect';
import PhotoUpload from '../../JobForms/components/Widgets/PhotoUpload';
import VideoUpload from '../../JobForms/components/Widgets/VideoUpload';
import UnaryCheckbox from '../../JobForms/components/Widgets/UnaryCheckbox';
import BinaryRadioButton from '../../JobForms/components/Widgets/BinaryRadioButton';
import TextField from '../../JobForms/components/Widgets/TextField';
import TextArea from '../../JobForms/components/Widgets/TextArea';
import SingleSelect from '../../JobForms/components/Widgets/SingleSelect';
import UserSelector from '../../JobForms/components/Widgets/UserSelector';
import DateSelector from '../../JobForms/components/Widgets/DateSelector';
import InstructionWidget from '../../JobForms/components/Widgets/InstructionWidget';
import Header from '../../JobForms/components/Widgets/Header';
import { CustomFormClass, Form } from 'model/Form';
import { useParams } from 'react-router-dom';
import React from 'react';

const { breakpoints, colors, fonts, fontSizes } = theme;

// Styled components
const SectionTitle = styled.div`
  font-family: ${fonts.bold};
  color: ${colors.black};
  font-size: ${fontSizes.xxxl};
  margin-bottom: 40px;
  padding: 0 25px 0 25px;
  @media only screen and (max-width: ${breakpoints.sm}px) {
    margin-bottom: 30px;
    padding: 0 15px 0 15px;
  }
`;

const FieldContainer = styled.div<{ $isSeparator: boolean }>`
  padding: ${({ $isSeparator }) =>
    $isSeparator ? '0 0 40px 0' : '0 25px 40px 25px'};
  @media only screen and (max-width: ${breakpoints.sm}px) {
    padding: ${({ $isSeparator }) =>
      $isSeparator ? '0 0 30px 0' : '0 15px 30px 15px'};
  }
`;

const PermitDetails = ({ permit }: { permit: Form }) => {
  const { jobId, permitId } = useParams();

  const getSections = () =>
    hasVideo(permit?.form)
      ? permit.form.sections.slice(1, permit.form.sections.length)
      : permit.form.sections;

  const renderField = (field) => {
    switch (field.kind) {
      case WidgetKind.MULTI_SELECT_CUSTOM_TEXT:
      case WidgetKind.MULTI_SELECT:
        return <MultiSelect field={field} />;
      case WidgetKind.PHOTO_UPLOAD:
        return <PhotoUpload field={field} photos={permit?.photos} />;
      case WidgetKind.VIDEO_UPLOAD:
        return <VideoUpload field={field} videos={permit?.videos} />;
      case WidgetKind.UNARY_CHECKBOX:
        return <UnaryCheckbox field={field} />;
      case WidgetKind.BINARY_RADIOBUTTON:
        return <BinaryRadioButton field={field} />;
      case WidgetKind.TEXT_FIELD:
        return <TextField field={field} />;
      case WidgetKind.TEXT_AREA:
        return <TextArea field={field} />;
      case WidgetKind.SINGLE_SELECT:
      case WidgetKind.SINGLE_SELECT_INLINE:
        return <SingleSelect field={field} />;
      case WidgetKind.USER_SELECTOR:
        return <UserSelector field={field} />;
      case WidgetKind.DATE_SELECTOR:
        return <DateSelector field={field} />;
      case WidgetKind.HEADER_2:
        return <Header field={field} />;
      case WidgetKind.INSTRUCTION:
        return (
          <InstructionWidget
            field={field}
            formClass={CustomFormClass.PERMIT}
            jobId={jobId}
            formId={permitId}
          />
        );
      case WidgetKind.SEPARATOR:
        return <Separator />;
      default:
    }
  };

  return (
    <div>
      {permit?.form?.sections?.length > 0 &&
        getSections().map((section, sectionIndex) => (
          <React.Fragment key={`section-${sectionIndex}`}>
            {section?.title && <SectionTitle>{section?.title}</SectionTitle>}
            {section?.fields?.length > 0 &&
              section?.fields.map(
                (field, index) =>
                  field?.kind !== WidgetKind.VIDEO_UPLOAD_INFO_PAGE && (
                    <FieldContainer
                      key={`field-${field?.id}-${index}`}
                      $isSeparator={field?.kind === WidgetKind.SEPARATOR}
                    >
                      {renderField(field)}
                    </FieldContainer>
                  ),
              )}
          </React.Fragment>
        ))}
    </div>
  );
};

export default PermitDetails;

import { createSelector } from 'reselect';
import RootState from 'model/State/RootState';

const selectQueryParamValue = createSelector(
  (state: RootState): string => state.router.location.search,
  (_: RootState, fieldName: string): string => fieldName,
  (queryString: string, fieldName: string): string | null => {
    const queryParams = new URLSearchParams(queryString);
    return queryParams.get(fieldName);
  },
);

export const selectQueryParamValueByKey =
  (fieldName: string) =>
  (state: RootState): string | null =>
    selectQueryParamValue(state, fieldName);

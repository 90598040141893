import {
  FETCH_DEPOTS,
  FETCH_DEPOTS_SUCCESS,
  FETCH_DEPOTS_ERROR,
} from '../actions/actionTypes';

const defaultState = {
  depots: [],
  isFetchingDepots: false,
};

const depots = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_DEPOTS:
      return {
        ...state,
        isFetchingDepots: true,
      };
    case FETCH_DEPOTS_SUCCESS:
      return {
        ...state,
        depots: action.data,
        isFetchingDepots: false,
      };
    case FETCH_DEPOTS_ERROR:
      return {
        ...state,
        isFetchingDepots: false,
      };
    default:
      return state;
  }
};

export default depots;

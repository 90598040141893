import {
  APPEND_USERS,
  CLEAR_TEAMS,
  FETCH_TEAMS,
  FETCH_TEAMS_ERROR,
  FETCH_TEAMS_SUCCESS,
  FETCH_USERS,
  SET_LATEST_PSI_REVIEW,
  SET_PSI_DISMISSED,
  SET_USERS,
} from '../actions/actionTypes';

const defaultState = {
  latestPsiReview: null,
  isPsiDismissed: false,
  teams: null,
  isFetchingTeams: false,
  users: null,
  isFetchingUsers: false,
  nextLink: null,
};

const user = (state = defaultState, action) => {
  switch (action.type) {
    case SET_LATEST_PSI_REVIEW: {
      return { ...state, latestPsiReview: action.latestPsiReview };
    }
    case SET_PSI_DISMISSED: {
      return { ...state, isPsiDismissed: action.isPsiDismissed };
    }
    case FETCH_TEAMS: {
      return {
        ...state,
        isFetchingTeams: true,
      };
    }
    case FETCH_TEAMS_SUCCESS: {
      return { ...state, teams: action.teams, isFetchingTeams: false };
    }
    case FETCH_TEAMS_ERROR: {
      return { ...state, isFetchingTeams: false };
    }
    case CLEAR_TEAMS: {
      return { ...defaultState };
    }
    case FETCH_USERS: {
      return { ...state, isFetchingUsers: true };
    }
    case SET_USERS: {
      return {
        ...state,
        users: [...action.users],
        nextLink: action.nextLink,
        isFetchingUsers: false,
      };
    }
    case APPEND_USERS: {
      return {
        ...state,
        users: [...(state.users || []), ...action.users],
        nextLink: action.nextLink,
        isFetchingUsers: false,
      };
    }
    default:
      return state;
  }
};

export default user;

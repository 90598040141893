import styled from 'styled-components';
import theme from 'theme';
import { Warning, Check } from 'assets/icons';
import { Checkbox, FormControlLabel } from '@mui/material';

const { colors, fonts, mixins, fontSizes } = theme;

export const WarningIcon = styled(({ ...props }) => <Warning {...props} />)`
  height: 20px;
  width: 20px;
  padding-right: 5px;
  .fill-selector {
    fill: ${colors.red};
  }
`;

export const PointsSum = styled.span<{ $isRed?: boolean }>`
  ${({ $isRed }) =>
    $isRed &&
    `
    color: ${colors.red};
    font-family: ${fonts.bold};
  `}
`;

export const CommentsButton = styled.div`
  color: ${colors.darkGrey};
  font-family: ${fonts.default} !important;
  text-decoration: underline;
  cursor: pointer;
  white-space: wrap;
  line-height: normal;
`;

export const SignOffContainer = styled.div`
  ${mixins.flexCenter};
  flex-direction: row;
  white-space: wrap;
  line-height: normal;
`;

export const StyledCheckboxLabel = styled(FormControlLabel)`
  margin-left: 0 !important;
  margin-right: 0 !important;
  > span {
    margin-left: 0;
    margin-right: 0;
    font-family: ${fonts.default} !important;
    font-size: ${fontSizes.s} !important;
    color: ${colors.darkGrey};
  }
`;

export const StyledCheckBox = styled(({ checked, ...rest }) => (
  <Checkbox checked={checked} {...rest} />
))`
  &.MuiCheckbox-root {
    height: 16px;
    width: 16px;
    min-width: 16px;
    border: 1px solid ${colors.black};
    border-radius: 0;
    padding: 0;
    margin-right: 10px;
    color: ${colors.black};
    background-color: transparent;

    &:hover {
      border-color: ${colors.orange};
      background-color: ${colors.white};
      transition: ease-out 0.2s;
    }

    &.Mui-checked {
      background-color: ${colors.white};

      &:hover {
        border-color: ${colors.black};
      }
    }
  }
  .MuiSvgIcon-root {
    font-size: 1.5rem;
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

export const CheckIcon = styled(Check)`
  .fill-selector {
    fill: ${colors.darkGrey};
  }
`;

export const LastSignedOffContainer = styled.div`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.s};
  font-family: ${fonts.default} !important;
`;

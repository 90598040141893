import styled from 'styled-components';
import theme from 'theme';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import { ChevronDown } from 'assets/icons';

const { colors, fonts, fontSizes, mixins } = theme;

export const Container = styled.div`
  margin-top: 50px;
`;

export const ReviewTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  padding-bottom: 10px;
`;

export const HazardListContainer = styled.div`
  width: 55%;
  height: 500px;
  overflow-y: auto;
  margin-top: 20px;
`;

export const ChevronIcon = styled(ChevronDown)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: 0 20px;
`;

export const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    box-shadow: none;

    &.Mui-expanded {
      margin: 0;
    }
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0;
    border-top: 1px solid ${colors.lightGrey};
    &:hover {
      cursor: default !important;
    }
    &.MuiAccordionSummary-content {
      margin: 0;
    }
  }
`;

export const HazardTitleWrapper = styled.div`
  ${mixins.flexBetween};
  width: 90%;
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0;
    border-top: 1px solid ${colors.lightGrey};
  }
`;

import { useText } from 'hooks';
import { AppliedFilterBox } from './AppliedFilterBox';
import { FilterType } from 'model/enum/FilterType';
import JobFilters from 'model/JobFilters';
import Team from 'model/Team';
import { useSelector } from 'react-redux';
import {
  selectFormOptions,
  selectGlobalLanguageSetting,
} from 'store/selectors';
import { formatDate } from 'helpers/dates';
import { ActiveFilterContainer } from './styled';

type Props = {
  filters: JobFilters;
  setFilters: (value: JobFilters) => void;
  selectedTeam: Team | null;
  setSelectedTeam: (value: number | null) => void;
};

export const ActiveFilterLabels = ({
  filters,
  setFilters,
  selectedTeam,
  setSelectedTeam,
}: Props): JSX.Element => {
  const getText = useText();
  const formOptions = useSelector(selectFormOptions);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  const selectedType = formOptions?.types?.find(
    (type) => type.id === filters?.type,
  );
  const selectedSubType = formOptions?.subtypes?.find(
    (type) => type.id === filters?.subtype,
  );
  const selectedStatus = formOptions?.statuses?.find(
    (status) => status.id === filters?.status,
  );
  const selectedAssessmentType = formOptions?.assessmentTypes?.find(
    (assessmentType) => assessmentType.id === filters?.assessmentType,
  );
  const selectedHazard = formOptions?.hazards?.find(
    (hazard) => hazard.id === filters?.hazard,
  );
  const selectedDepot = formOptions?.depots?.find(
    (depot) => depot.id === filters?.depot,
  );

  const getFilterTitle = (selectedItem) =>
    selectedItem?.[`title_${globalAppLanguage}`] || selectedItem?.title || '';

  const removeFilter = (type: FilterType) => {
    switch (type) {
      case FilterType.ACTIVE:
        setFilters({ ...filters, is_active: false });
        break;
      case FilterType.MAJOR_HAZARDS:
        setFilters({ ...filters, has_major_hazard: false });
        break;
      case FilterType.VRA_READY_FOR_SIGN_OFF:
        setFilters({ ...filters, has_vra_ready_for_sign_off: false });
        break;
      case FilterType.NO_VRA:
        setFilters({ ...filters, has_no_vra: false });
        break;
      case FilterType.TEAM:
        setSelectedTeam(null);
        break;
      case FilterType.MEMBER:
        setFilters({ ...filters, assignees: null });
        break;
      case FilterType.JOB_TYPE:
        setFilters({ ...filters, type: null, subtype: null });
        break;
      case FilterType.JOB_SUBTYPE:
        setFilters({ ...filters, subtype: null });
        break;
      case FilterType.DATE_RANGE:
        setFilters({ ...filters, daterange: null });
        break;
      case FilterType.STATUS:
        setFilters({ ...filters, status: null });
        break;
      case FilterType.ASSESSMENT_TYPE:
        setFilters({ ...filters, assessmentType: null });
        break;
      case FilterType.HAZARD:
        setFilters({ ...filters, hazard: null });
        break;
      case FilterType.DEPOT:
        setFilters({ ...filters, depot: null });
        break;
      default:
    }
  };

  return (
    <ActiveFilterContainer>
      {filters.scope && (
        <>
          {filters.is_active && (
            <AppliedFilterBox
              filterType={FilterType.ACTIVE}
              removeFilter={removeFilter}
              text={getText('jobs_stats_active')}
            />
          )}
          {filters.has_major_hazard && (
            <AppliedFilterBox
              filterType={FilterType.MAJOR_HAZARDS}
              removeFilter={removeFilter}
              text={getText('jobs_stats_major_hazard')}
            />
          )}
          {filters.has_vra_ready_for_sign_off && (
            <AppliedFilterBox
              filterType={FilterType.VRA_READY_FOR_SIGN_OFF}
              removeFilter={removeFilter}
              text={getText('jobs_stats_vra_ready')}
            />
          )}
          {filters.has_no_vra && (
            <AppliedFilterBox
              filterType={FilterType.NO_VRA}
              removeFilter={removeFilter}
              text={getText('jobs_stats_without_vra')}
            />
          )}
        </>
      )}
      {selectedTeam && (
        <AppliedFilterBox
          filterType={FilterType.TEAM}
          removeFilter={removeFilter}
          text={selectedTeam.name}
        />
      )}
      {filters?.assignees && filters.assignees?.length > 0 && (
        <AppliedFilterBox
          filterType={FilterType.MEMBER}
          removeFilter={removeFilter}
          text={`${filters.assignees?.length} ${getText(
            selectedTeam?.members
              ? 'jobs_filter_additional_users_selected'
              : 'jobs_filter_users_selected',
          )}`}
        />
      )}
      {filters?.type && selectedType && (
        <AppliedFilterBox
          filterType={FilterType.JOB_TYPE}
          removeFilter={removeFilter}
          text={getFilterTitle(selectedType)}
        />
      )}
      {filters?.subtype && selectedSubType && (
        <AppliedFilterBox
          filterType={FilterType.JOB_SUBTYPE}
          removeFilter={removeFilter}
          text={getFilterTitle(selectedSubType)}
        />
      )}
      {filters?.daterange && (
        <AppliedFilterBox
          filterType={FilterType.DATE_RANGE}
          removeFilter={removeFilter}
          text={`${formatDate(filters.daterange[0])} to ${formatDate(
            filters.daterange[1],
          )}`}
        />
      )}
      {filters?.status && selectedStatus && (
        <AppliedFilterBox
          filterType={FilterType.STATUS}
          removeFilter={removeFilter}
          text={getFilterTitle(selectedStatus)}
        />
      )}
      {filters?.assessmentType && selectedAssessmentType && (
        <AppliedFilterBox
          filterType={FilterType.ASSESSMENT_TYPE}
          removeFilter={removeFilter}
          text={getFilterTitle(selectedAssessmentType)}
        />
      )}
      {filters?.hazard && selectedHazard && (
        <AppliedFilterBox
          filterType={FilterType.HAZARD}
          removeFilter={removeFilter}
          text={getFilterTitle(selectedHazard)}
        />
      )}
      {filters?.depot && selectedDepot && (
        <AppliedFilterBox
          filterType={FilterType.DEPOT}
          removeFilter={removeFilter}
          text={getFilterTitle(selectedDepot)}
        />
      )}
    </ActiveFilterContainer>
  );
};

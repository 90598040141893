import { Storage } from 'aws-amplify';

export const get = (object) => {
  if (object.videos.length > 0) {
    return object.videos[0];
  }

  return object.photos[0];
};

export const getThumbnail = (object) => {
  const media = get(object);

  return media?.thumbnail;
};

export const getAssessmentThumbnail = (object) => object?.video?.thumbnail;

export const getSignedUrls = (photosList) =>
  Promise.all(photosList.map(async (photo) => Storage.get(photo.path)));

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useIsMobile, useText, useJobs } from 'hooks';
import { Job } from 'api';
import theme from 'theme';
import * as constants from 'utils/constants';
import { Button, Navbar, Card } from 'components/ui';
import { fetchActiveJob } from 'store/actions/jobs';
import { TeamMemberSearchEdit } from 'components/ui/TeamMembers/TeamMemberSearchEdit';
import { useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import { BackendUser } from 'model/BackendUser';
import { selectJobsView } from 'store/selectors';

const { media, mixins } = theme;

// Styled Components
const TeamContainer = styled.div`
  height: 100%;
  ${mixins.flexColumn};
  ${media.sm`
    height: calc(100vh - 65px);
  `}
`;

const ErrorContainer = styled.div`
  margin: 25px 100px 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// TeamEdit
const TeamEdit = () => {
  const dispatch = useDispatch();
  const { navigateToOverviewTabOrJob } = useJobs();
  const [selectedUsers, setSelectedUsers] = useState<BackendUser[]>([]);
  const [apiError, setApiError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { activeJob, isFetchingActiveJob } = useSelector(
    (state: RootState) => state.jobs,
  );
  const { jobId } = useParams();
  const isMobile = useIsMobile();
  const getText = useText();
  const view = useSelector(selectJobsView);

  useEffect(() => {
    if (!isFetchingActiveJob && !activeJob) {
      dispatch(fetchActiveJob(jobId));
    }
    if (activeJob) {
      setSelectedUsers(activeJob.assignees);
    }
  }, [activeJob, jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  const afterUserAdded = (selectedUser) => {
    const modifiedUsers = [...selectedUsers, selectedUser];

    setSelectedUsers(modifiedUsers);
  };

  const afterUserRemoved = (selectedUser) => {
    const modifiedUsers = selectedUsers.filter(
      (user) => user.id !== selectedUser.id,
    );

    setSelectedUsers(modifiedUsers);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await Job.updateDetails(activeJob?.id, {
        type: activeJob?.type ? activeJob?.type.id : null,
        subtype: activeJob?.subtype ? activeJob?.subtype.id : null,
        depot: activeJob?.depot ? activeJob?.depot.id : null,
        assignees: selectedUsers.map((user) => user.id),
      });
      dispatch(fetchActiveJob(jobId, { updateAllJobs: false }));
      navigateToOverviewTabOrJob(`/jobs/${jobId}/team-members`);
    } catch (err) {
      handlerError(err);
    }
  };

  const handlerError = (err) => {
    if (err?.response?.data?.detail) {
      setApiError(JSON.stringify(err.response.data.detail));
    } else {
      const keys = Object.keys(err.response.data);
      if (keys[0] === 'type') {
        setApiError(getText('job_detail_overview_job_type_missing'));
      } else if (keys[0] === 'sub_type') {
        setApiError(getText('job_detail_overview_job_subtype_missing'));
      } else if (keys[0] === 'depot') {
        setApiError(getText('job_detail_overview_job_depot_missing'));
      } else {
        setApiError(
          `${getText('job_detail_overview_request_error')} ${JSON.stringify(
            err.response.data,
          )}`,
        );
      }
    }
  };

  return (
    <>
      <Navbar
        title={getText('job_detail_overview_edit_team_members')}
        defaultBackPath={`/jobs/${jobId}`}
        mobileOnly
      />
      {selectedUsers && activeJob && (
        <TeamContainer>
          {apiError && (
            <ErrorContainer>
              <Card.ErrorMessage message={apiError} />
            </ErrorContainer>
          )}

          <TeamMemberSearchEdit
            selectedUsers={selectedUsers}
            userResultCount={20}
            onUserRemove={afterUserRemoved}
            onUserAdd={afterUserAdded}
            disabled={isLoading}
          />

          <Button.Primary
            event={constants.EVENT_JOB_TEAM_MEMBERS_UPDATED}
            eventProperties={{
              jobId: activeJob.id,
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            text={getText('confirm')}
            onClick={handleSubmit}
            loading={isLoading}
            extraStyles={
              !isMobile
                ? {
                    margin: '0 100px 15px 100px',
                    width: 300,
                    alignSelf: 'flex-end',
                  }
                : {}
            }
          />
        </TeamContainer>
      )}
    </>
  );
};

export default TeamEdit;

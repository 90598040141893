import { WorkspaceSettings } from 'api';
import { WorkspaceItemStatus } from 'model/enum/WorkspaceItemStatus';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_EVIDENCE_TYPES,
  FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES,
  FETCH_WORKSPACE_JOB_BLOCKER_TYPES,
  FETCH_WORKSPACE_JOB_TYPES,
  FETCH_WORKSPACE_SETTINGS,
  SAVE_EVIDENCE_TYPE_CHANGES,
  SAVE_JOB_BLOCKER_SUBTYPE_CHANGES,
  SAVE_JOB_BLOCKER_TYPE_CHANGES,
  SAVE_JOB_TYPE_CHANGES,
  SET_JOB_CREATION_SETTINGS,
  SET_WORKSPACE_SETTINGS,
} from 'store/actions/actionTypes';
import { setGlobalError } from 'store/slices/notifications';
import { updateWorkspaceSettings } from 'store/actions/session';
import {
  fetchWorkspaceSettingsSuccess,
  fetchWorkspaceSettingsError,
  setWorkspaceSettingsError,
  setWorkspaceSettingsSuccess,
  fetchEvidenceTypesSuccess,
  fetchEvidenceTypesError,
  addNewEvidenceTypeSuccess,
  addNewEvidenceTypeError,
  saveEvidenceTypeChangesSuccess,
  saveEvidenceTypeChangesError,
  editExistingEvidenceTypeSuccess,
  editExistingEvidenceTypeError,
  removeExistingEvidenceTypeSuccess,
  removeExistingEvidenceTypeError,
  resetEvidenceTypeErrors,
  fetchWorkspaceJobTypesSuccess,
  fetchWorkspaceJobTypesError,
  saveJobBlockerTypeChangesSuccess,
  saveJobBlockerTypeChangesError,
  resetJobBlockerTypeErrors,
  addNewJobBlockerTypeSuccess,
  addNewJobBlockerTypeError,
  editExistingJobBlockerTypeSuccess,
  editExistingJobBlockerTypeError,
  removeExistingJobBlockerTypeSuccess,
  removeExistingJobBlockerTypeError,
  fetchWorkspaceJobBlockerTypesSuccess,
  fetchWorkspaceJobBlockerTypesError,
  fetchWorkspaceJobBlockerSubtypesSuccess,
  fetchWorkspaceJobBlockerSubTypesError,
  resetJobBlockerSubtypeErrors,
  addNewJobBlockerSubtypeSuccess,
  addNewJobBlockerSubtypeError,
  editExistingJobBlockerSubtypeSuccess,
  editExistingJobBlockerSubtypeError,
  removeExistingJobBlockerSubtypeSuccess,
  removeExistingJobBlockerSubtypeError,
  saveJobBlockerSubtypeChangesSuccess,
  saveJobBlockerSubtypeChangesError,
  setJobCreationSettingsSuccess,
  setJobCreationSettingsError,
  resetJobTypeErrors,
  saveJobTypeChangesSuccess,
  saveJobTypeChangesError,
  addNewJobTypeSuccess,
  addNewJobTypeError,
  editExistingJobTypeSuccess,
  editExistingJobTypeError,
  removeExistingJobTypeSuccess,
  removeExistingJobTypeError,
} from 'store/actions/workspace';
import * as Logger from 'utils/logger';

function* fetchWorkspaceSettings({ workspaceUuid }) {
  try {
    const data = yield WorkspaceSettings.getWorkspaceSettings(workspaceUuid);
    const {
      vraSignoffRequired,
      userSignOnRequired,
      devicelessDigitalSignature,
      magicLinkLoginEnabled,
      ...jobCreationData
    } = data;
    const workspaceData = {
      vraSignoffRequired,
      userSignOnRequired,
      devicelessDigitalSignature,
      magicLinkLoginEnabled,
    };

    yield put(
      fetchWorkspaceSettingsSuccess(
        workspaceUuid,
        workspaceData,
        jobCreationData,
      ),
    );
  } catch (err) {
    Logger.error(err);
    yield put(fetchWorkspaceSettingsError());
    yield put(setGlobalError(err));
  }
}

function* setNewWorkspaceSettings({ workspaceUuid, params }) {
  try {
    const data = yield WorkspaceSettings.saveWorkspaceSettings(
      workspaceUuid,
      params,
    );
    const {
      vraSignoffRequired,
      userSignOnRequired,
      devicelessDigitalSignature,
      magicLinkLoginEnabled,
    } = data;
    const workspaceData = {
      vraSignoffRequired,
      userSignOnRequired,
      devicelessDigitalSignature,
      magicLinkLoginEnabled,
    };
    yield put(setWorkspaceSettingsSuccess(workspaceUuid, workspaceData));
    yield put(updateWorkspaceSettings(workspaceData));
  } catch (err) {
    Logger.error(err);
    yield put(setWorkspaceSettingsError(err));
    yield put(setGlobalError(err));
  }
}

function* setNewJobCreationSettings({ workspaceUuid, params }) {
  try {
    const data = yield WorkspaceSettings.saveWorkspaceSettings(
      workspaceUuid,
      params,
    );
    const {
      vraSignoffRequired,
      userSignOnRequired,
      devicelessDigitalSignature,
      magicLinkLoginEnabled,
      ...jobCreationData
    } = data;
    yield put(setJobCreationSettingsSuccess(workspaceUuid, jobCreationData));
    yield put(updateWorkspaceSettings(jobCreationData));
  } catch (err) {
    Logger.error(err);
    yield put(setJobCreationSettingsError(err));
    yield put(setGlobalError(err));
  }
}

// EVIDENCE TYPES
function* fetchEvidenceTypes({ workspaceUuid }) {
  try {
    const data = yield WorkspaceSettings.getEvidenceTypes(workspaceUuid);

    yield put(fetchEvidenceTypesSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchEvidenceTypesError());
    yield put(setGlobalError(err));
  }
}

function* saveEvidenceTypeChanges({ workspaceUuid, evidenceTypes }) {
  try {
    yield put(resetEvidenceTypeErrors());
    yield all(
      evidenceTypes.map((evidenceType) => {
        switch (evidenceType.status) {
          case WorkspaceItemStatus.ADDED:
            return call(addEvidenceType, { workspaceUuid, evidenceType });
          case WorkspaceItemStatus.EDITED:
            return call(editEvidenceType, {
              workspaceUuid,
              evidenceType,
            });
          case WorkspaceItemStatus.DELETED:
            return call(removeEvidenceType, {
              workspaceUuid,
              evidenceType,
            });
          default:
            return; // eslint-disable-line array-callback-return
        }
      }),
    );
    yield put(saveEvidenceTypeChangesSuccess());
  } catch (err) {
    Logger.error(err);
    yield put(saveEvidenceTypeChangesError());
    yield put(setGlobalError(err));
  }
}

function* addEvidenceType({ workspaceUuid, evidenceType }) {
  try {
    const data = yield WorkspaceSettings.addEvidenceType(workspaceUuid, {
      ...evidenceType,
    });

    yield put(addNewEvidenceTypeSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(addNewEvidenceTypeError(evidenceType));
    yield put(setGlobalError(err));
  }
}

function* editEvidenceType({ workspaceUuid, evidenceType }) {
  try {
    const data = yield WorkspaceSettings.updateEvidenceType(workspaceUuid, {
      ...evidenceType,
    });

    yield put(editExistingEvidenceTypeSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(editExistingEvidenceTypeError(evidenceType));
    yield put(setGlobalError(err));
  }
}

function* removeEvidenceType({ workspaceUuid, evidenceType }) {
  try {
    yield WorkspaceSettings.deleteEvidenceType(workspaceUuid, evidenceType?.id);

    yield put(
      removeExistingEvidenceTypeSuccess(workspaceUuid, evidenceType?.id),
    );
  } catch (err) {
    Logger.error(err);
    yield put(removeExistingEvidenceTypeError(evidenceType));
    yield put(setGlobalError(err));
  }
}

// JOB TYPES
function* fetchJobTypes({ workspaceUuid }) {
  try {
    const data = yield WorkspaceSettings.getJobTypes(workspaceUuid);

    yield put(fetchWorkspaceJobTypesSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchWorkspaceJobTypesError());
    yield put(setGlobalError(err));
  }
}

function* saveJobTypeChanges({ workspaceUuid, jobTypes }) {
  try {
    yield put(resetJobTypeErrors());
    yield all(
      jobTypes.map((jobType) => {
        switch (jobType.status) {
          case WorkspaceItemStatus.ADDED:
            return call(addJobType, { workspaceUuid, jobType });
          case WorkspaceItemStatus.EDITED:
            return call(editJobType, {
              workspaceUuid,
              jobType,
            });
          case WorkspaceItemStatus.DELETED:
            return call(removeJobType, {
              workspaceUuid,
              jobType,
            });
          default:
            return; // eslint-disable-line array-callback-return
        }
      }),
    );
    yield put(saveJobTypeChangesSuccess());
  } catch (err) {
    Logger.error(err);
    yield put(saveJobTypeChangesError());
    yield put(setGlobalError(err));
  }
}

function* addJobType({ workspaceUuid, jobType }) {
  try {
    const data = yield WorkspaceSettings.addJobType(workspaceUuid, {
      ...jobType,
    });

    yield put(addNewJobTypeSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(addNewJobTypeError(jobType));
    yield put(setGlobalError(err));
  }
}

function* editJobType({ workspaceUuid, jobType }) {
  try {
    const data = yield WorkspaceSettings.updateJobType(workspaceUuid, {
      ...jobType,
    });

    yield put(editExistingJobTypeSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(editExistingJobTypeError(jobType));
    yield put(setGlobalError(err));
  }
}

function* removeJobType({ workspaceUuid, jobType }) {
  try {
    yield WorkspaceSettings.deleteJobType(workspaceUuid, jobType?.id);

    yield put(removeExistingJobTypeSuccess(workspaceUuid, jobType?.id));
  } catch (err) {
    Logger.error(err);
    yield put(removeExistingJobTypeError(jobType));
    yield put(setGlobalError(err));
  }
}

// JOB BLOCKER TYPES
function* fetchJobBlockerTypes({ workspaceUuid }) {
  try {
    const data = yield WorkspaceSettings.getJobBlockerTypes(workspaceUuid);

    yield put(fetchWorkspaceJobBlockerTypesSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchWorkspaceJobBlockerTypesError());
    yield put(setGlobalError(err));
  }
}

function* saveJobBlockerTypeChanges({ workspaceUuid, jobBlockerTypes }) {
  try {
    yield put(resetJobBlockerTypeErrors());
    yield all(
      jobBlockerTypes.map((jobBlockerType) => {
        switch (jobBlockerType.status) {
          case WorkspaceItemStatus.ADDED:
            return call(addJobBlockerType, { workspaceUuid, jobBlockerType });
          case WorkspaceItemStatus.EDITED:
            return call(editJobBlockerType, {
              workspaceUuid,
              jobBlockerType,
            });
          case WorkspaceItemStatus.DELETED:
            return call(removeJobBlockerType, {
              workspaceUuid,
              jobBlockerType,
            });
          default:
            return; // eslint-disable-line array-callback-return
        }
      }),
    );
    yield put(saveJobBlockerTypeChangesSuccess());
  } catch (err) {
    Logger.error(err);
    yield put(saveJobBlockerTypeChangesError());
    yield put(setGlobalError(err));
  }
}

function* addJobBlockerType({ workspaceUuid, jobBlockerType }) {
  try {
    const data = yield WorkspaceSettings.addJobBlockerType(workspaceUuid, {
      ...jobBlockerType,
    });

    yield put(addNewJobBlockerTypeSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(addNewJobBlockerTypeError(jobBlockerType));
    yield put(setGlobalError(err));
  }
}

function* editJobBlockerType({ workspaceUuid, jobBlockerType }) {
  try {
    const data = yield WorkspaceSettings.updateJobBlockerType(workspaceUuid, {
      ...jobBlockerType,
    });

    yield put(editExistingJobBlockerTypeSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(editExistingJobBlockerTypeError(jobBlockerType));
    yield put(setGlobalError(err));
  }
}

function* removeJobBlockerType({ workspaceUuid, jobBlockerType }) {
  try {
    yield WorkspaceSettings.deleteJobBlockerType(
      workspaceUuid,
      jobBlockerType?.id,
    );

    yield put(
      removeExistingJobBlockerTypeSuccess(workspaceUuid, jobBlockerType?.id),
    );
  } catch (err) {
    Logger.error(err);
    yield put(removeExistingJobBlockerTypeError(jobBlockerType));
    yield put(setGlobalError(err));
  }
}

// JOB BLOCKER SUBTYPES
function* fetchJobBlockerSubtypes({ workspaceUuid, blockerId }) {
  try {
    const data = yield WorkspaceSettings.getJobBlockerSubtypes(
      workspaceUuid,
      blockerId,
    );

    yield put(fetchWorkspaceJobBlockerSubtypesSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchWorkspaceJobBlockerSubTypesError());
    yield put(setGlobalError(err));
  }
}

function* saveJobBlockerSubtypeChanges({
  workspaceUuid,
  blockerId,
  jobBlockerTypes,
}) {
  try {
    yield put(resetJobBlockerSubtypeErrors());
    yield all(
      jobBlockerTypes.map((jobBlockerType) => {
        switch (jobBlockerType.status) {
          case WorkspaceItemStatus.ADDED:
            return call(addJobBlockerSubtype, {
              workspaceUuid,
              blockerId,
              jobBlockerType,
            });
          case WorkspaceItemStatus.EDITED:
            return call(editJobBlockerSubtype, {
              workspaceUuid,
              blockerId,
              jobBlockerType,
            });
          case WorkspaceItemStatus.DELETED:
            return call(removeJobBlockerSubtype, {
              workspaceUuid,
              blockerId,
              jobBlockerType,
            });
          default:
            return; // eslint-disable-line array-callback-return
        }
      }),
    );
    yield put(saveJobBlockerSubtypeChangesSuccess());
  } catch (err) {
    Logger.error(err);
    yield put(saveJobBlockerSubtypeChangesError());
    yield put(setGlobalError(err));
  }
}

function* addJobBlockerSubtype({ workspaceUuid, blockerId, jobBlockerType }) {
  try {
    const data = yield WorkspaceSettings.addJobBlockerSubtype(
      workspaceUuid,
      blockerId,
      {
        ...jobBlockerType,
      },
    );

    yield put(addNewJobBlockerSubtypeSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(addNewJobBlockerSubtypeError(jobBlockerType));
    yield put(setGlobalError(err));
  }
}

function* editJobBlockerSubtype({ workspaceUuid, blockerId, jobBlockerType }) {
  try {
    const data = yield WorkspaceSettings.updateJobBlockerSubtype(
      workspaceUuid,
      blockerId,
      {
        ...jobBlockerType,
      },
    );

    yield put(editExistingJobBlockerSubtypeSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(editExistingJobBlockerSubtypeError(jobBlockerType));
    yield put(setGlobalError(err));
  }
}

function* removeJobBlockerSubtype({
  workspaceUuid,
  blockerId,
  jobBlockerType,
}) {
  try {
    yield WorkspaceSettings.deleteJobBlockerSubtype(
      workspaceUuid,
      blockerId,
      jobBlockerType?.id,
    );

    yield put(
      removeExistingJobBlockerSubtypeSuccess(workspaceUuid, jobBlockerType?.id),
    );
  } catch (err) {
    Logger.error(err);
    yield put(removeExistingJobBlockerSubtypeError(jobBlockerType));
    yield put(setGlobalError(err));
  }
}

export default function* workspaceWatcher() {
  yield takeLatest(FETCH_WORKSPACE_SETTINGS, fetchWorkspaceSettings);
  yield takeLatest(SET_WORKSPACE_SETTINGS, setNewWorkspaceSettings);
  yield takeLatest(SET_JOB_CREATION_SETTINGS, setNewJobCreationSettings);
  yield takeLatest(FETCH_EVIDENCE_TYPES, fetchEvidenceTypes);
  yield takeLatest(SAVE_EVIDENCE_TYPE_CHANGES, saveEvidenceTypeChanges);
  yield takeLatest(FETCH_WORKSPACE_JOB_TYPES, fetchJobTypes);
  yield takeLatest(SAVE_JOB_TYPE_CHANGES, saveJobTypeChanges);
  yield takeLatest(FETCH_WORKSPACE_JOB_BLOCKER_TYPES, fetchJobBlockerTypes);
  yield takeLatest(SAVE_JOB_BLOCKER_TYPE_CHANGES, saveJobBlockerTypeChanges);
  yield takeLatest(
    FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES,
    fetchJobBlockerSubtypes,
  );
  yield takeLatest(
    SAVE_JOB_BLOCKER_SUBTYPE_CHANGES,
    saveJobBlockerSubtypeChanges,
  );
}

import { Badge, Input } from 'components/ui';
import { BackendUser } from 'model/BackendUser';

type Props = {
  currentUserId: number;
  filteredUser: BackendUser;
  isChecked?: boolean;
  roleTitle?: string;
  disabled?: boolean;
};

export const TeamMemberSearchItem = ({
  filteredUser,
  currentUserId,
  isChecked,
  roleTitle,
  disabled = false,
}: Props): JSX.Element => (
  <>
    <Badge.UserBadge
      key={`badge-${filteredUser.id}`}
      user={filteredUser}
      isCurrentUser={currentUserId === filteredUser.id}
      large
      title={roleTitle}
    />
    <Input.Checkbox
      key={`checkbox-${filteredUser.id}`}
      checked={!!isChecked}
      extraStyles={{ marginRight: '10px' }}
      disabled={disabled}
    />
  </>
);

import { Radio, FormControlLabel } from '@mui/material';
import { useIsMobile } from 'hooks';
import { CSSProperties } from 'styled-components';
import { Container } from './styled';
import { Label, StyledRadioGroup } from '../styled';

type OptionPropTypes = {
  value: string;
  title: string;
};

type Props = {
  options: OptionPropTypes[];
  selected: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {};
  label?: string;
  extraStyles?: CSSProperties;
};

export const LanguageSelector = ({
  options,
  selected,
  onChange,
  label,
  extraStyles,
}: Props): JSX.Element => {
  const isMobile = useIsMobile();

  return (
    <Container style={extraStyles}>
      {label && <Label $marginRight='10px'>{label}</Label>}
      <StyledRadioGroup
        defaultValue={selected}
        onChange={onChange}
        isMobile={isMobile}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.title}
            labelPlacement='start'
          />
        ))}
      </StyledRadioGroup>
    </Container>
  );
};

import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, convertToRaw, EditorState, RichUtils } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Wrapper } from './styled';

type Props = {
  onChange: (value: string) => void;
  content: string;
};

// Input.RichText
export const RichTextEditor = ({ onChange, content }: Props): JSX.Element => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (content) {
      const blocksFromHTML = htmlToDraft(content);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      const newEditorState = EditorState.createWithContent(state);
      setEditorState(newEditorState);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editorState) {
      const htmlContent = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      );
      onChange(htmlContent);
    }
  }, [editorState]); // eslint-disable-line react-hooks/exhaustive-deps

  const textBlockStyling = (contentBlock) => {
    let addedClass = '';
    const blockAlignment =
      contentBlock.getData() && contentBlock.getData().get('text-align');
    if (blockAlignment) {
      addedClass = `richText-block richText-${blockAlignment}-block richText-textAlignment-block`;
    }

    if (contentBlock.getType() === 'unstyled') {
      addedClass = `${addedClass} normal-text`;
    }
    return addedClass;
  };

  return (
    <Wrapper>
      <Editor
        onEditorStateChange={setEditorState}
        editorState={editorState}
        handlePastedText={() => false}
        handleReturn={() =>
          setEditorState(RichUtils.insertSoftNewline(editorState))
        }
        blockStyleFn={textBlockStyling}
        toolbar={{
          options: ['blockType', 'inline', 'list', 'textAlign', 'link'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
          },
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2'],
          },
          list: {
            inDropdown: false,
            options: ['unordered', 'ordered'],
          },
          textAlign: { inDropdown: false },
          link: { inDropdown: false },
        }}
      />
    </Wrapper>
  );
};

import axios from 'axios';
import { BackendMessage, mapToMessage } from './helpers';
import { PaginatedData } from 'model/PaginatedData';
import { Message } from 'model/Message';

export const getMessages = async (
  id: number,
): Promise<PaginatedData<Message[]>> => {
  const { data } = await axios.get(`/jobs/${id}/messages/?page_size=20`, {
    headers: {
      Accept: 'application/json; version=3;',
    },
  });

  return {
    ...data,
    results: data.results.map((result: BackendMessage) => mapToMessage(result)),
  };
};

export const getMoreMessages = async (
  nextPage: string,
): Promise<PaginatedData<Message[]>> => {
  const { data } = await axios.get(nextPage, {
    headers: {
      Accept: 'application/json; version=3;',
    },
  });

  return {
    ...data,
    results: data.results.map((result: BackendMessage) => mapToMessage(result)),
  };
};

export const setReaction = async (messageId, reactionType) => {
  try {
    await axios.post(`/message/${messageId}/reactions/`, {
      value: reactionType,
    });
  } catch (err: any) {
    if (err.response.status === 400) {
      return Promise.reject(err.response.data);
    }
    throw new Error(err.message);
  }
};

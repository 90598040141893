import { Button } from '@mui/material';
import { AlertRed, Cross, Members, Tick } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins, media } = theme;

export const TopSectionWrapper = styled.div<{ height: string }>`
  height: ${({ height }) => height};
`;

export const Title = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxl};
  ${media.xxl`
    font-size: ${fontSizes.xl};
  `}
`;

export const Subtitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  padding: 10px 0 20px 0;
`;

export const NoMembersWrapper = styled.div<{ large: boolean }>`
  ${mixins.flexFullCenter};
  flex-direction: column;
  height: ${({ large }) => (large ? 90 : 82)}%;
`;

export const MembersIcon = styled(Members)`
  height: auto;
  width: 80px;
`;

export const NoMembersTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  margin: 30px 0 40px 0;
`;

export const AddMemberButtonWrapper = styled.div`
  width: 45%;
  ${media.xxl`
    width: 50%;
  `}
  ${media.xl`
    width: 75%;
  `}
`;

export const TagContainer = styled.div`
  height: 8%;
  padding: 3px 8px;
  box-sizing: border-box;
`;

export const SearchSection = styled.div`
  ${mixins.flexBetween};
  padding: 15px 0;
  ${media.xxl`
    padding: 10px 0;
  `}
`;

export const SearchContainer = styled.div<{ width?: number }>`
  width: ${({ width }) => width}%;
`;

export const SelectedContainer = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
`;

export const ClearWrapper = styled.div<{ width: number }>`
  display: flex;
  justify-content: end;
  width: ${({ width }) => width}%;
  height: 50px;
`;

export const VerticalSeparator = styled.div`
  border-left: 2px solid ${colors.lightGrey};
  margin-left: 10px;
`;

export const BadgeContainer = styled.div`
  min-width: 20%;
  max-width: 20%;
  ${media.sm`
    margin: 0 0 0 20px;
  `}
`;

export const SignOnListContainer = styled.div<{ large: boolean }>`
  height: calc(${({ large }) => (large ? 80 : 72)}% - 24px);
  overflow-y: auto;
`;

export const ListWrapper = styled.div`
  padding-left: 20px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid ${colors.lightGrey};
`;

export const IconWrapper = styled.div`
  padding-right: 10px;
`;

export const StyledTick = styled(Tick)`
  width: 21px;
  height: 21px;
  .fill-selector {
    fill: ${colors.green};
  }
`;
export const StyledAlert = styled(AlertRed)`
  width: 21px;
  height: 21px;
`;

export const BoldText = styled.div`
  padding: 10px 0;
  font-family: ${fonts.bold};
`;

export const UserInfo = styled.div`
  color: ${colors.darkGrey};
  ${media.xl`
    font-size: ${fontSizes.s};
  `}
`;

export const InfoContainer = styled.div`
  ${mixins.flexCenter};
  flex-direction: row;
`;

export const SignatureButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    color: ${colors.black};
    height: ${fontSizes.default};
    font-size: ${fontSizes.default};
    font-family: ${fonts.bold};
    text-decoration: underline;
    text-underline-offset: 3px;
    padding: 0;
    margin-left: 10px;
    &:hover {
      background-color: transparent;
      text-decoration: underline;
      text-decoration-thickness: 2px;
    }
    ${media.xl`
      font-size: ${fontSizes.s};
    `};
  }
`;

export const SignatureWrapper = styled.div`
  border: 1px solid ${colors.grey};
  margin: 0 30px 15px 30px;
`;

export const SignatureHeader = styled.div`
  padding: 12px 16px;
  background-color: ${colors.offWhite};
  ${mixins.flexBetween};
  flex-direction: row;
  border-bottom: 1px solid ${colors.grey};
`;

export const SignatureTitle = styled.span`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  padding-left: 10px;
  align-self: center;
  ${media.xl`
    font-size: ${fontSizes.default};
  `};
`;

export const CrossIcon = styled(Cross)`
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

export const PhotoContainer = styled.div`
  width: 40%;
  border: 2px solid ${colors.black};
  ${media.xl`
    width: 50%;
  `};
`;

export const SignatureContent = styled.div`
  padding: 20px 15px;
`;

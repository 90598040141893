import { PayloadAction } from '@reduxjs/toolkit';
import { JobForms, Permit, UserForms } from 'api';
import Form from 'model/Forms/Form';
import { put, select, takeLatest, throttle } from 'redux-saga/effects';
import {
  setGlobalError,
  setPdfCreationToast,
  showPdfInfo,
} from 'store/slices/notifications';
import {
  getPdfExportId,
  fetchJobForms,
  fetchJobFormsError,
  fetchJobFormsSuccess,
  getPdfExportIdSuccess,
  getPdfExportIdError,
} from 'store/slices/forms';
import * as Logger from 'utils/logger';
import { selectCurrentUserId } from 'store/selectors';
import { setPdfExportDataToLocalStorage } from 'helpers/files';
import { CustomFormClass } from 'model/Form';

function* fetchJobFormsFn(action: PayloadAction<string>) {
  const jobId = action.payload;
  try {
    const data: Form[] = yield JobForms.getForJobId(jobId);
    yield put(fetchJobFormsSuccess({ jobId, forms: data }));
  } catch (err) {
    Logger.error(err);
    yield put(fetchJobFormsError(jobId));
    yield put(setGlobalError(err));
  }
}

function* generateFormPdfFn(
  action: PayloadAction<{
    formClass: CustomFormClass;
    formId: string;
    eventProperties: object;
  }>,
) {
  const formId = action.payload.formId;
  const currentUserId = yield select(selectCurrentUserId);
  const isPdfModalDismissedByUsers = yield JSON.parse(
    window.localStorage.getItem('pdfInfoModalDismissed') || '{}',
  );
  const isDismissed = isPdfModalDismissedByUsers?.[currentUserId] ?? false;
  try {
    let objectId = '';
    switch (action.payload.formClass) {
      case CustomFormClass.JOB:
        objectId = yield JobForms.getPdfExportId(formId);
        break;
      case CustomFormClass.USER:
        objectId = yield UserForms.getPdfExportId(formId);
        break;
      case CustomFormClass.PERMIT:
        objectId = yield Permit.getPdfExportId(formId);
        break;
    }

    if (isDismissed) {
      yield put(setPdfCreationToast());
    } else {
      yield put(showPdfInfo());
    }

    setPdfExportDataToLocalStorage(objectId, action.payload.eventProperties);
    yield put(getPdfExportIdSuccess());
  } catch (err) {
    Logger.error(err);
    yield put(setGlobalError(err));
    yield put(getPdfExportIdError());
  }
}

export default function* formsWatcher() {
  yield takeLatest(fetchJobForms.type, fetchJobFormsFn);
  yield throttle(2000, getPdfExportId.type, generateFormPdfFn);
}

import { Navbar, Tabs } from 'components/ui';
import { useIsMobile, useText } from 'hooks';
import RootState from 'model/State/RootState';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { fetchActiveJob } from 'store/actions/jobs';
import { Grid } from '@mui/material';
import TimelineContent from './TimelineContent';

const { colors, media, mixins } = theme;

// Styled Components
const TimelineContainer = styled(Grid)`
  &&& {
    ${mixins.flexColumn};
    height: 100%;
    background-color: ${colors.whiteGrey};
    border-left: 2px solid ${colors.grey};
    ${media.sm`
    background-color: ${colors.white};
    border: none;
    ${media.sm`
      height: calc(100vh - 65px);
    `}
  `}
  }
`;

// Timeline
const Timeline = () => {
  const params = useParams();
  const { activeJob, isFetchingActiveJob } = useSelector(
    (state: RootState) => state.jobs,
  );
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const getText = useText();

  // When refreshing the timeline page on mobile you loose the activeJob in the context.
  useEffect(() => {
    if (!isFetchingActiveJob && !activeJob && isMobile) {
      dispatch(fetchActiveJob(params.jobId));
    }
  }, [activeJob, isMobile, params.jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Navbar
        title={activeJob?.job_number}
        defaultBackPath={`/jobs/${params.jobId}`}
        mobileOnly
      />
      <TimelineContainer item xs={24} sm={6} md={6} lg={6}>
        <Tabs.Mobile
          left={{
            text: getText('tabs_timeline'),
            to: `/jobs/${params.jobId}/timeline`,
            isActive: true,
          }}
          right={{
            text: getText('tabs_overview'),
            to: `/jobs/${params.jobId}`,
          }}
        />
        <TimelineContent />
      </TimelineContainer>
    </>
  );
};

export default Timeline;

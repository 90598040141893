import { capitalizeFullName } from 'helpers/names';
import {
  DetailRow,
  DetailTitle,
  TooltipWrapper,
  TooltipHeader,
  WarningIcon,
  WarningTag,
} from '../styled';
import { formatDateTime } from 'helpers/dates';
import { MajorHazardTag } from 'components/ui/Tags/MajorHazardTag';
import theme from 'theme';
import { MapJob } from 'model/MapJob';

const { colors } = theme;

// Warning: this is rendered from a different React context and does not have access to hooks or redux state
export const InfoWindowContent = ({
  assignees,
  address,
  postcode,
  jobNumber,
  lastActivity,
  updatedAt,
  hasMajorHazard,
  isBlocked,
  getText,
}: MapJob & { getText: (t: string) => string }) => {
  const hasAssignees = assignees?.length > 0;

  return (
    <TooltipWrapper>
      <TooltipHeader>
        {hasAssignees && (
          <>
            {capitalizeFullName(
              assignees[0].first_name,
              assignees[0].last_name,
            )}
            {assignees?.length > 1 && `(+${assignees?.length - 1})`}
          </>
        )}
      </TooltipHeader>
      {(address || postcode) && (
        <TooltipHeader>
          {address}
          {address && postcode && ', '}
          {postcode}
        </TooltipHeader>
      )}
      <DetailRow>
        <DetailTitle>
          {getText('jobs_map_tooltip_work_order_number')}
        </DetailTitle>
        {jobNumber}
      </DetailRow>
      <DetailRow>
        <DetailTitle>{getText('jobs_map_tooltip_last_activity')}</DetailTitle>
        {formatDateTime(
          lastActivity ?? updatedAt,
          getText('date_today'),
          getText('date_yesterday'),
        )}
      </DetailRow>
      {hasMajorHazard && (
        <MajorHazardTag
          text={getText('jobs_map_label_major_hazard')}
          fitContent
          hasSmallIcon
          iconColor={colors.white}
          extraStyles={{ backgroundColor: colors.red }}
        />
      )}
      {isBlocked && (
        <WarningTag>
          <WarningIcon />
          <span>{getText('jobs_map_label_blocked')}</span>
        </WarningTag>
      )}
    </TooltipWrapper>
  );
};

import {
  SignUpForm,
  RoleMap,
  RoleIds,
} from 'components/views/Public/SignUp/SignUpForm';
import {
  HappyFieldworkerFlow,
  HappyManagerWorkerFlow,
} from 'components/views/Public/SignUp/SuccessForms';
import { useState } from 'react';
import { useText } from 'hooks';
import { User } from 'api';
import { FyldLogo } from '../styled';
import { ErrorModal } from './ErrorModal';
import { AxiosError } from 'axios';
import {
  BulletPoint,
  BulletPointWrapper,
  Item,
  LogoContainer,
  MainContainer,
  P,
  WrapperSlogan,
  YellowText,
} from './styled';
import theme from 'theme';

const { fontSizes } = theme;

const States = {
  FORM: 'FORM',
  SUCCESS_WORKER: 'SUCCESS_WORKER',
  SUCCESS_MANAGER: 'SUCCESS_MANAGER',
  FORM_ERROR: 'FORM_ERROR',
};

const initialFormData = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  roleId: null,
};

const getInitialErrors = (): FieldProps => {
  const errors = {} as FieldProps;

  Object.keys(initialFormData).forEach((property) => {
    errors[property] = '';
  });

  return errors;
};

const getFieldErrors = (errorData): FieldProps => {
  const fieldErrors = {
    email: '',
    lastName: '',
    firstName: '',
    phoneNumber: '',
    roleId: '',
  };

  if (errorData.role !== undefined) {
    fieldErrors.roleId = errorData.role;
  }
  if (errorData.email !== undefined) {
    fieldErrors.email = errorData.email;
  }
  if (errorData.last_name !== undefined) {
    fieldErrors.lastName = errorData.last_name;
  }
  if (errorData.first_name !== undefined) {
    fieldErrors.firstName = errorData.first_name;
  }
  if (errorData.phone_number !== undefined) {
    fieldErrors.phoneNumber = errorData.phone_number;
  }

  return fieldErrors;
};

type FieldProps = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  roleId: string;
};

// SignUp
export const SignUp = (): JSX.Element => {
  const getText = useText();
  const [formErrors, setFormErrors] = useState<FieldProps>(getInitialErrors());
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState(States.FORM);

  const handleSubmit = async (formValues) => {
    const data = {
      ...formValues,
      role: RoleMap[formValues.roleId],
    };
    setIsLoading(true);

    try {
      await User.signUp(data);

      const nextState =
        formValues.roleId === RoleIds.fieldworker
          ? States.SUCCESS_WORKER
          : States.SUCCESS_MANAGER;

      setState(nextState);
      setIsLoading(false);
    } catch (e) {
      const fieldErrors = getFieldErrors((e as AxiosError)?.response?.data);

      if (Object.keys(fieldErrors).length > 0) {
        setFormErrors(fieldErrors);
      } else {
        setErrorMessage(e?.toString() ?? '');
      }
      setIsLoading(false);
    }
  };

  const renderState = () => {
    if (state === States.FORM) {
      return (
        <SignUpForm
          formData={initialFormData}
          onSubmit={handleSubmit}
          errors={formErrors}
          loading={isLoading}
        />
      );
    }
    if (state === States.SUCCESS_MANAGER) {
      return <HappyManagerWorkerFlow />;
    }
    if (state === States.SUCCESS_WORKER) {
      return <HappyFieldworkerFlow />;
    }
  };

  const onErrorModalClose = () => setErrorMessage('');
  return (
    <MainContainer>
      <LogoContainer>
        <FyldLogo />
        <YellowText fontSize={fontSizes.xxl} $hasMargin>
          {getText('sign_up')}
        </YellowText>
        <WrapperSlogan>{getText('sign_up_slogan')}</WrapperSlogan>
        {[
          getText('sign_up_slogan_list_quality'),
          getText('sign_up_slogan_list_paperwork'),
          getText('sign_up_slogan_list_collaboration'),
        ].map((text) => (
          <Item key={text}>
            <BulletPointWrapper>
              <BulletPoint />
            </BulletPointWrapper>
            <P>{text}</P>
          </Item>
        ))}
      </LogoContainer>
      {renderState()}
      <ErrorModal
        errorMessage={errorMessage}
        visible={!!errorMessage}
        onClose={onErrorModalClose}
      />
    </MainContainer>
  );
};

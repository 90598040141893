import { Input } from 'components/ui';
import { useEffect, useState } from 'react';
import { HavsLimitSettings as HavsLimitSettingsApi } from 'api';
import { useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import { useDebounce } from 'use-debounce';
import { UserRole } from 'model/enum/UserRole';
import { useText } from 'hooks';
import { UserBadge } from './UserBadge';
import { HavsManager, HavsUser } from 'model/AdminPanel/HavsSettings/HavsUser';
import { capitalizeFullName } from 'helpers/names';

type UserType = {
  selectedUser: HavsUser | null;
  setSelectedUser: (newValue: HavsUser | null) => void;
};

type ManagerType = {
  selectedUser: HavsManager | null;
  setSelectedUser: (newValue: HavsManager | null) => void;
};

type GenericProps = {
  id: string;
  label: string;
  userRoles: UserRole[];
  placeholder: string;
  required?: boolean;
};

type Props = (GenericProps & UserType) | (GenericProps & ManagerType);

export const UserSearchDropdown = ({
  id,
  label,
  selectedUser,
  setSelectedUser,
  userRoles,
  required = false,
  placeholder,
}: Props): JSX.Element => {
  const getText = useText();
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const [users, setUsers] = useState<HavsUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userSearch, setUserSearch] = useState<string>('');
  const [userSearchFilter] = useDebounce(userSearch, 600);

  useEffect(() => {
    async function getUsers() {
      setIsLoading(true);
      const result = await HavsLimitSettingsApi.getUsersForHavs(
        selectedWorkspace?.uuid,
        userSearchFilter,
        userRoles,
      );
      setUsers(result);
      setIsLoading(false);
    }

    getUsers();
  }, [userRoles, userSearchFilter, selectedWorkspace?.uuid]);

  const mapToOption = (user: HavsManager | HavsUser | null) => {
    if (!user) {
      return null;
    }
    return {
      ...user,
      title:
        user?.firstName || user?.lastName
          ? capitalizeFullName(user?.firstName, user?.lastName)
          : 'N/A',
    };
  };
  const selected = mapToOption(selectedUser);
  const options = users?.map(mapToOption);
  const renderOption = (props, option) => (
    <UserBadge {...props} {...option} key={`${id}-${option.id}`} />
  );
  const selectUser = (
    e: React.ChangeEvent<HTMLInputElement>,
    option: HavsUser,
  ) => setSelectedUser(option);
  const changeSearchTerm = (e: React.ChangeEvent<HTMLInputElement> | null) => {
    if (e?.target?.value !== undefined) {
      if (e?.type === 'blur') {
        setUserSearch('');
      } else {
        setUserSearch(e.target.value);
      }
    }
  };

  return (
    <Input.SelectWithSearch
      label={label}
      id={id}
      selected={selected}
      options={options}
      renderOption={renderOption}
      onChange={selectUser}
      onInputChange={changeSearchTerm}
      large
      required={required}
      fitContent
      isLoading={isLoading}
      noOptionsText={getText('havs_user_add_new_dropdown_no_results')}
      placeholder={placeholder}
    />
  );
};

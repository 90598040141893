import { BackendUser } from 'model/BackendUser';
import JobData from 'model/JobData';
import { getLocalizedCamelCaseValue } from './locales';
import { InsightJobBlockerStat } from 'model/Insight/InsightJobBlockerStat';

export const getInitials = (
  firstName: string = '',
  lastName: string = '',
): string => {
  let initials = '';
  if (firstName && lastName) {
    initials =
      firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  } else if (firstName) {
    initials = firstName.charAt(0).toUpperCase();
  } else {
    initials = 'N/A';
  }
  return initials;
};

export const capitalizeFullName = (
  firstName?: string,
  lastName?: string,
): string => {
  if (!firstName) {
    return 'N/A';
  }

  let capitalizedFullName =
    firstName.charAt(0).toUpperCase() + firstName.slice(1);

  if (lastName) {
    capitalizedFullName += ` ${lastName
      .charAt(0)
      .toUpperCase()}${lastName.slice(1)}`;
  }

  return capitalizedFullName;
};

export const getJobBlockerCombinedTitle = (
  item: InsightJobBlockerStat,
  globalAppLanguage: string,
): string =>
  item?.blockerSubtype
    ? `${getLocalizedCamelCaseValue(
        item.blockerSubtype,
        'title',
        globalAppLanguage,
      )} (${getLocalizedCamelCaseValue(
        item.blocker,
        'title',
        globalAppLanguage,
      )}`
    : getLocalizedCamelCaseValue(item.blocker, 'title', globalAppLanguage);

export const getJobFullAddress = (
  job: JobData,
  checkLocationDescription: boolean = true,
): string => {
  if (checkLocationDescription && job.location_description) {
    return job.location_description;
  }
  if (job.postcode && job.address) {
    return `${job.address}, ${job.postcode}`;
  }
  if (!job.address && job.postcode) {
    return job.postcode;
  }
  if (!job.postcode && job.address) {
    return job.address;
  }
  return '';
};

export const formatMembersSelected = (
  selectedMembers: BackendUser[] | null,
  zeroSelected: string,
  membersSelected: string,
): string => {
  return selectedMembers?.length
    ? `${selectedMembers?.length} ${membersSelected}`
    : zeroSelected;
};

export const concatenateLocale = (
  locale: string,
  strings: string[],
): string => {
  const formatter = new Intl.ListFormat(locale, {
    style: 'long',
    type: 'conjunction',
  });

  return formatter.format(strings);
};

export const formatLicensePlate = (plateNumber: string): string => {
  let formatted = plateNumber?.toUpperCase();
  if (plateNumber?.length > 4) {
    const firstSection = formatted?.slice(0, 4);
    const secondSection = formatted?.slice(4, formatted.length);
    formatted = `${firstSection} ${secondSection}`;
  }
  return formatted;
};

export const getSingularOrPluralText = (
  itemList: any[],
  singularText: string,
  pluralText: string,
): string => (itemList?.length > 1 ? pluralText : singularText);

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

import { useState } from 'react';
import { useText } from 'hooks';
import { TransparentTextBox } from '../Input/TransparentTextBox';
import {
  ContentContainer,
  CrossIcon,
  HeaderContainer,
  IconContainer,
  StyledImageIcon,
  TitleContainer,
  ChevronIcon,
} from './styled';

type Props = {
  title: string;
  children: JSX.Element;
  dragHandler: JSX.Element;
  onCrossClick: () => void;
  onImageClick: () => void;
  setTitle?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultActive?: boolean;
  hidePhotoContainer?: () => void;
  deleteDisabled?: boolean;
};

// Display.EditableCollapsible
export const EditableCollapsible = ({
  title,
  setTitle = () => {},
  children,
  defaultActive = false,
  onCrossClick,
  onImageClick,
  dragHandler,
  hidePhotoContainer = () => {},
  deleteDisabled,
}: Props): JSX.Element => {
  const [active, setActive] = useState<boolean>(defaultActive);
  const getText = useText();

  const handleImageClick = () => {
    setActive(true);
    onImageClick();
  };

  const onChevronClick = () => {
    if (!active) {
      hidePhotoContainer();
    }
    setActive(!active);
  };

  return (
    <div>
      <HeaderContainer>
        <TitleContainer>
          {dragHandler}
          <TransparentTextBox
            id='section-title'
            placeholder={getText('collapsible_enter_section_title')}
            value={title}
            onChange={setTitle}
          />
        </TitleContainer>
        <IconContainer>
          <StyledImageIcon onClick={handleImageClick} />
          <CrossIcon deleteDisabled={deleteDisabled} onClick={onCrossClick} />
          <ChevronIcon
            active={active}
            onClick={onChevronClick}
            size='25px'
            marginRight='15px'
          />
        </IconContainer>
      </HeaderContainer>
      <ContentContainer>{active && <div>{children}</div>}</ContentContainer>
    </div>
  );
};

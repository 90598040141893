import {
  SEND_MESSAGE,
  RECEIVE_MESSAGE,
  UPDATE_MESSAGES,
  GET_MESSAGES,
  CLEAR_MESSAGES,
  GET_MORE_MESSAGES,
  APPEND_MESSAGES,
  SET_REACTION,
} from './actionTypes';

export const sendMessage = (jobId, websocketMessage, currentUser, uuid) => ({
  type: SEND_MESSAGE,
  jobId,
  websocketMessage,
  currentUser,
  uuid,
});

export const receiveMessage = (message) => ({
  type: RECEIVE_MESSAGE,
  message,
});

export const getMessages = (jobId) => ({
  type: GET_MESSAGES,
  jobId,
});

export const updateMessages = (messages, nextPage, jobId) => ({
  type: UPDATE_MESSAGES,
  messages,
  nextPage,
  jobId,
});

export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
});

export const getMoreMessages = (nextPage) => ({
  type: GET_MORE_MESSAGES,
  nextPage,
});

export const appendMessages = (messages, nextPage) => ({
  type: APPEND_MESSAGES,
  messages,
  nextPage,
});

export const setReaction = (messageId, reactionType) => ({
  type: SET_REACTION,
  messageId,
  reactionType,
});

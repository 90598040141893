import { useText } from 'hooks';
import { EditableTagContainer } from './styled';

export const EditableTag = ({
  editable,
}: {
  editable: boolean;
}): JSX.Element => {
  const getText = useText();

  return (
    <EditableTagContainer $editable={editable}>
      {getText(
        editable ? 'jobs_search_my_job_title' : 'jobs_search_read_only_title',
      )}
    </EditableTagContainer>
  );
};

import { Check, Hazard, Warning } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins, media } = theme;

export const WarningSectionContainer = styled.div`
  padding: 20px 25px 0px 25px;
  ${media.sm`
    padding: 20px 15px 0px 15px;
  `};
`;

export const WarningWrapper = styled.div<{ width?: number }>`
  width: ${({ width }) => width}%;
  background-color: ${colors.orange};
  color: ${colors.black};
  padding: 15px 15px 9px 15px;
  margin-bottom: 4px;
  border-radius: 3px;
  flex-shrink: 0;
  ${mixins.flexCenter};
`;

export const WarningIcon = styled(Warning)`
  height: 27px;
  width: 27px;
  padding-right: 8px;
`;

export const TitleContainer = styled.div`
  ${mixins.flexBetween};
`;

export const Title = styled.span`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxl};
`;

export const IconBox = styled.div`
  height: 36px;
  width: 36px;
  border: 4px solid ${colors.black};
  ${mixins.flexFullCenter};
`;

export const HazardIcon = styled(Hazard)`
  height: 22px;
  width: 22px;
`;

export const ControlContainer = styled.div`
  ${mixins.flexCenter};
  height: 20%;
`;

export const BulletPointWrapper = styled.div`
  width: 5%;
  padding-right: 5px;
  ${mixins.flexFullCenter};
`;

export const BulletPoint = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${colors.black};
`;

export const ItemRow = styled.div<{ width?: number }>`
  min-height: 65px;
  width: ${({ width }) => width}%;
  background-color: ${colors.lightGrey};
  padding: 0 15px;
  margin-bottom: 4px;
  border-radius: 3px;
  flex-shrink: 0;
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  ${mixins.flexBetween};
  box-sizing: border-box;
`;

export const P = styled.p`
  font-size: 16px;
  color: ${colors.black};
`;

export const AllAppliedContainer = styled.div`
  ${mixins.flexCenter};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  margin-top: 20px;
`;

export const GreenCheckIcon = styled(Check)`
  height: 22px;
  width: 22px;
  margin-right: 10px;
  .fill-selector {
    fill: ${colors.green};
  }
`;

export const SuggestedHazardContainer = styled.div`
  margin-top: 20px;
`;

export const SourceText = styled.span`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.m};
  margin-bottom: 10px;
`;

export const ActionButtonsWrapper = styled.div`
  width: 100%;
  padding-top: 24px;
  ${mixins.flexBetween};
  gap: 16px;
  > div {
    min-width: 50%;
    ${media.xxl`
      width: 100%;
    `}
  }
  ${media.xxl`
    flex-direction: column;
  `};
`;

export enum ToastType {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
  SECONDARY = 'secondary',
}

export type Notification = {
  id: number;
  title: string;
  message?: string;
  download?: {
    objectId: string;
    fileName?: string;
  };
  canBeDismissed?: boolean;
  customComponent?: JSX.Element;
  customIcon?: React.ElementType;
};

export enum ToastPosition {
  TOP_RIGHT = 'top-right',
  TOP_CENTER = 'top-center',
  TOP_LEFT = 'top-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_LEFT = 'bottom-left',
}

import {
  ContentWrapper,
  IconWrapper,
  InfoContainer,
  UserInfo,
  StyledAlert,
  StyledTick,
  BoldText,
  SignatureButton,
} from './styled';
import { useText } from 'hooks';
import { formatDateTime } from 'helpers/dates';
import { SignatureBox } from './SignatureBox';
import { useState } from 'react';
import { DotSeparator } from 'components/ui/DotSeparator';
import { ColumnContainer } from '../styled';
import * as Analytics from 'utils/analytics';
import { EVENT_DIGITAL_SIGNATURE_CLICKED } from '../constants';
import { useSelector } from 'react-redux';
import { selectActiveRamsAssessment } from 'store/slices/ramsAssessments';
import { User } from 'model/User';

type Props = {
  user: User;
};

export const SignOnUserRow = ({ user }: Props): JSX.Element => {
  const getText = useText();
  const assessment = useSelector(selectActiveRamsAssessment);
  const [isSignatureOpen, setSignatureOpen] = useState<boolean>(false);

  const onSignatureButtonClick = () => {
    setSignatureOpen(true);
    Analytics.trackEvent(EVENT_DIGITAL_SIGNATURE_CLICKED, {
      assessmentId: assessment?.id,
      assessmentType: assessment?.type,
      digitalSignatureUserid: user?.id,
    });
  };

  return (
    <>
      <ContentWrapper>
        <IconWrapper>
          {user.signedAt ? <StyledTick /> : <StyledAlert />}
        </IconWrapper>
        <ColumnContainer>
          <BoldText>{`${user.firstName} ${user.lastName}`}</BoldText>
          <InfoContainer>
            <UserInfo>
              {getText(
                user?.isDeviceless
                  ? 'rams_sign_on_digital_signature'
                  : 'rams_sign_on_user_fieldworker',
              )}
            </UserInfo>
            {user?.signedAt && (
              <>
                <DotSeparator />
                <UserInfo>
                  {formatDateTime(
                    user.signedAt,
                    getText('date_today'),
                    getText('date_yesterday'),
                  )}
                </UserInfo>
                {user?.isDeviceless && user?.signature && (
                  <SignatureButton
                    variant='text'
                    onClick={onSignatureButtonClick}
                  >
                    {getText('rams_sign_on_digital_signature')}
                  </SignatureButton>
                )}
              </>
            )}
          </InfoContainer>
        </ColumnContainer>
      </ContentWrapper>
      {user?.signature && isSignatureOpen && (
        <SignatureBox
          signature={user.signature}
          close={() => setSignatureOpen(false)}
        />
      )}
    </>
  );
};

import { useState } from 'react';
import styled from 'styled-components';
import { useIsMobile, useJobs, useText } from 'hooks';
import { Button, Input, Navbar } from 'components/ui';
import theme from 'theme';
import { Permit } from 'api';
import { isBefore } from 'helpers/dates';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalLanguageSetting, selectPermit } from 'store/selectors';
import { useParams } from 'react-router-dom';
import { updatePermit } from 'store/actions/jobs';
import { endOfToday } from 'date-fns';

const { colors, fonts, fontSizes, media } = theme;

// Styled Components
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InputWrapper = styled.div`
  padding: 25px 35px;
  ${media.sm`
    padding: 15px 20px;
  `}
`;

const Title = styled.div`
  color: ${colors.black};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 30px;
`;

const ErrorMessage = styled.span`
  color: ${colors.darkRed};
  position: absolute;
  bottom: 55px;
  padding: 15px;
`;

const SetDuration = (): JSX.Element => {
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const dispatch = useDispatch();
  const { jobId, permitId } = useParams();
  const isMobile = useIsMobile();
  const { navigateToOverviewTabOrJob } = useJobs();
  const getText = useText();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const permit = useSelector(selectPermit(jobId, permitId));

  const issuePermit = async () => {
    const data = await Permit.approve(permit?.id, true, null, expiryDate);
    setExpiryDate(null);
    if (data) {
      dispatch(updatePermit(jobId, data));
      navigateToOverviewTabOrJob(`/jobs/${jobId}/permits`);
    }
  };

  return (
    <>
      <Navbar
        title={
          permit &&
          (permit?.type[`title_${globalAppLanguage}`] || permit?.type?.title)
        }
        defaultBackPath={`/jobs/${jobId}/permits/${permitId}`}
        mobileOnly
      />
      <Container>
        <InputWrapper>
          <Title>{getText('job_permits_set_duration_title')}</Title>
          <Input.Calendar
            selectedDate={expiryDate}
            onDateChange={(date) => setExpiryDate(date)}
            minDate={endOfToday()}
            extraStyles={{ padding: isMobile ? 0 : '10px' }}
          />
        </InputWrapper>
        {expiryDate && isBefore(expiryDate, new Date()) && (
          <ErrorMessage>
            {getText('job_permits_expiry_date_error')}
          </ErrorMessage>
        )}
        <Button.Primary
          text={getText('job_permits_issue_permit')}
          extraStyles={
            isMobile
              ? { position: 'absolute', bottom: '0' }
              : {
                  position: 'sticky',
                  bottom: '0',
                  marginTop: 'auto',
                  flex: '0 0 55px',
                }
          }
          onClick={issuePermit}
          disabled={!expiryDate || isBefore(expiryDate, new Date())}
        />
      </Container>
    </>
  );
};

export default SetDuration;

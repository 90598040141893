import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Languages } from 'utils/constants';
import enGBTranslation from '../translations/en-gb.json';
import esMXTranslation from '../translations/es-mx.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enGBTranslation },
    es: { translation: esMXTranslation },
  },
  fallbackLng: Languages.english.locale,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    useSuspense: false,
  },
  returnDetails: true,
  returnObjects: true,
});

i18n.loadLanguages([Languages.english.locale, Languages.spanish.locale]);

export default i18n;

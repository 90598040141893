export const EVENT_VIEW_SETTINGS_USERS = 'view-settings-users';
export const EVENT_VIEW_NOTIFICATIONS = 'view-settings-notifications';
export const EVENT_VIEW_BULK_JOB_UPLOAD = 'view-settings-bulk-job-upload';
export const EVENT_VIEW_SETTINGS_WORKSPACE = 'admin-panel-workspace-settings';
export const EVENT_VIEW_SETTINGS_ASSESSMENT = 'risk-assessment-tab-clicked';
export const EVENT_VIEW_HAVS_SETTINGS = 'havs-settings-tab';
export const EVENT_VIEW_WORKFLOW_SETTINGS = 'view-settings-workflow';
export const ADMIN_CREATE_JOB_SETTINGS_SCREEN_VIEW =
  'admin-create-job-settings-screen-view';
export const EVENT_WORKSPACE_SETTINGS_SAVE_CREATE_JOB_SETTINGS =
  'admin-create-job-saved';
export const EVENT_WORKSPACE_SETTINGS_SAVE_WORKSPACE_SETTINGS =
  'admin-workspace-settings-saved';
export const EVENT_WORKSPACE_SETTINGS_SIGN_ON_INFO_CLICK =
  'user-sign-on-info-button';
export const EVENT_WORKSPACE_SETTINGS_TOGGLE_DEVICELESS_DIGITAL_SIGNATURE =
  'toggle-digital-digital-signature-to-fieldworkers';
export const EVENT_WORKSPACE_SETTINGS_TOGGLE_FIELDWORKER_SIGN_ON =
  'toggle-fieldworker-join-risk-assessment';
export const EVENT_WORKSPACE_SETTINGS_TOGGLE_RISK_ASSESSMENT =
  'toggle-manager-approve-risk-assessment';
export const EVENT_WORKSPACE_SETTINGS_VRA_INFO_CLICK =
  'vra-sign-off-info-button';
export const EVENT_WORKSPACE_SETTINGS_EVIDENCE_TYPES_SAVED =
  'settings-workspace-manage-evidence-types-save-changes';
export const EVENT_WORKSPACE_SETTINGS_EVIDENCE_TYPES_ADDED =
  'admin-job-evidence-type-added';
export const EVENT_WORKSPACE_SETTINGS_EVIDENCE_TYPES_EDITED =
  'admin-job-evidence-type-edited';
export const EVENT_WORKSPACE_SETTINGS_EVIDENCE_TYPES_DELETED =
  'admin-job-evidence-type-deleted';
export const EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_TYPES_SAVED =
  'settings-workspace-manage-job-blocker-save-changes';
export const EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_TYPE_ADDED =
  'admin-job-blocker-type-added';
export const EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_TYPE_EDITED =
  'admin-job-blocker-type-edited';
export const EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_TYPE_DELETED =
  'admin-job-blocker-type-deleted';
export const EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_SUBTYPES_SAVED =
  'settings-workspace-manage-job-blocker-subtype-save-changes';
export const EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_SUBTYPE_ADDED =
  'admin-job-blocker-subtype-added';
export const EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_SUBTYPE_EDITED =
  'admin-job-blocker-subtype-edited';
export const EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_SUBTYPE_DELETED =
  'admin-job-blocker-subtype-deleted';
export const EVENT_WORKSPACE_SETTINGS_JOB_TYPES_SAVED =
  'workspace-settings-manage-job-type-save-changes';
export const EVENT_WORKSPACE_SETTINGS_JOB_TYPE_ADDED = 'admin-job-type-added';
export const EVENT_WORKSPACE_SETTINGS_JOB_TYPE_EDITED = 'admin-job-type-edited';
export const EVENT_WORKSPACE_SETTINGS_JOB_TYPE_DELETED =
  'admin-job-type-deleted';
export const EVENT_WORKSPACE_SETTINGS_JOB_SUBTYPES_SAVED =
  ' settings-workspace-manage-job-subtype-save-changes';
export const EVENT_WORKSPACE_SETTINGS_JOB_SUBTYPE_ADDED =
  'admin-job-subtype-added';
export const EVENT_WORKSPACE_SETTINGS_JOB_SUBTYPE_EDITED =
  'admin-job-subtype-edited';
export const EVENT_WORKSPACE_SETTINGS_JOB_SUBTYPE_DELETED =
  'admin-job-subtype-deleted';
export const EVENT_SEND_LOGIN_LINK_BUTTON_CLICKED =
  'settings-users-tab-send-login-link-button';
export const EVENT_SEND_LOGIN_LINK_EMAIL_BUTTON_CLICKED =
  'settings-users-tab-send-login-link-email-button';
export const EVENT_SEND_LOGIN_LINK_SMS_BUTTON_CLICKED =
  'settings-users-tab-send-login-link-sms-button';
export const EVENT_ASSESSMENT_TYPE_ADDED =
  'workspace-settings-assessment-type-added';
export const EVENT_ASSESSMENT_TYPE_SAVE_CHANGES =
  'workspace-settings-manage-assessment-types-save-changes';
export const EVENT_ASSESSMENT_TYPE_DELETED =
  'workspace-settings-assessment-type-deleted';
export const EVENT_ASSESSMENT_TYPE_EDIT_HAZARDS =
  'workspace-settings-assessment-edit-hazards';
export const EVENT_HAZARD_ADDED = 'risk-assessment-tab-hazard-added';
export const EVENT_HAZARD_EDIT_CLICKED =
  'risk-assessment-tab-edit-hazard-clicked';
export const EVENT_HAZARD_UPDATED = 'risk-assessment-tab-update-hazard-clicked';
export const EVENT_HAZARD_DELETE_CLICKED =
  'risk-assessment-tab-delete-hazard-clicked';
export const EVENT_HAZARD_DELETE_CONFIRMED =
  'risk-assessment-tab-delete-hazard-confirm';
export const EVENT_HAZARD_REMOVED = 'risk-assessment-tab-hazard-removed';
export const EVENT_HAZARD_MAJOR_HAZARD_TICKED =
  'risk-assessment-tab-hazard-major-hazard-ticked';
export const EVENT_CONTROL_ADDED = 'risk-assessment-tab-control-added';
export const EVENT_CONTROL_EDIT_CLICKED =
  'risk-assessment-tab-edit-control-clicked';
export const EVENT_CONTROL_UPDATED =
  'risk-assessment-tab-update-control-clicked';
export const EVENT_CONTROL_DELETE_CLICKED =
  'risk-assessment-tab-delete-control-clicked';
export const EVENT_CONTROL_DELETE_CONFIRMED =
  'risk-assessment-tab-delete-control-confirm';
export const EVENT_CONTROL_REMOVED = 'risk-assessment-tab-control-removed';
export const EVENT_MAJOR_HAZARD_INFO_CLICKED =
  'risk-assessment-tab-major-hazard-info-clicked';

export const WorkspaceFieldNames = {
  VRA_SIGN_OFF: 'vraSignoffRequired',
  FIELDWORKER_SIGN_ON: 'userSignOnRequired',
  DEVICELESS_DIGITAL_SIGNATURE: 'devicelessDigitalSignature',
  MAGIC_LINK_ENABLED: 'magicLinkLoginEnabled',
  WORK_ORDER_NUMBER_LABEL: 'workOrderNumberLabel',
  WORK_ORDER_NUMBER_LABEL_ES: 'workOrderNumberLabelEs',
  JOB_SUBTYPE_LABEL: 'jobSubtypeLabel',
  JOB_SUBTYPE_LABEL_ES: 'jobSubtypeLabelEs',
  PROJECT_NUMBER_LABEL: 'projectNumberLabel',
  PROJECT_NUMBER_LABEL_ES: 'projectNumberLabelEs',
  ORIGINATING_NUMBER_LABEL: 'originatingNumberLabel',
  ORIGINATING_NUMBER_LABEL_ES: 'originatingNumberLabelEs',
  ADDRESS_LABEL: 'addressLabel',
  ADDRESS_LABEL_ES: 'addressLabelEs',
  POSTCODE_LABEL: 'postcodeLabel',
  POSTCODE_LABEL_ES: 'postcodeLabelEs',
};

export const WorkspaceFieldTranslationKeys = {
  [WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL]:
    'workspace_settings_create_job_work_order_number_label',
  [WorkspaceFieldNames.WORK_ORDER_NUMBER_LABEL_ES]:
    'workspace_settings_create_job_work_order_number_label',
  [WorkspaceFieldNames.JOB_SUBTYPE_LABEL]:
    'workspace_settings_create_job_job_subtype_label',
  [WorkspaceFieldNames.JOB_SUBTYPE_LABEL_ES]:
    'workspace_settings_create_job_job_subtype_label',
  [WorkspaceFieldNames.PROJECT_NUMBER_LABEL]:
    'workspace_settings_create_job_project_number_label',
  [WorkspaceFieldNames.PROJECT_NUMBER_LABEL_ES]:
    'workspace_settings_create_job_project_number_label',
  [WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL]:
    'workspace_settings_create_job_originating_number_label',
  [WorkspaceFieldNames.ORIGINATING_NUMBER_LABEL_ES]:
    'workspace_settings_create_job_originating_number_label',
  [WorkspaceFieldNames.ADDRESS_LABEL]:
    'workspace_settings_create_job_address_label',
  [WorkspaceFieldNames.ADDRESS_LABEL_ES]:
    'workspace_settings_create_job_address_label',
  [WorkspaceFieldNames.POSTCODE_LABEL]:
    'workspace_settings_create_job_postcode_label',
  [WorkspaceFieldNames.POSTCODE_LABEL_ES]:
    'workspace_settings_create_job_postcode_label',
};

export const UserPermissions = {
  USERS_ADMIN: 'users_admin_v1',
  WORKSPACE_ADMIN: 'workspace_admin_v1',
  HAVS: 'havs_restrictions_management',
  WORKFLOW_SETUP: 'workflow_setup',
};

export const WorkspaceSettings = {
  RISK_ASSESSMENT: 'enable_risk_assessment_settings',
  WORKFLOW_SETUP: 'workflow_setup',
};

export const SettingsTabs = {
  USERS: 'users',
  BULK_JOB_UPLOAD: 'bulkJobUpload',
  NOTIFICATIONS: 'notification',
  WORKSPACE: 'workspace',
  ASSESSMENTS: 'assessments',
  HAVS: 'HAVS',
  WORKFLOWS: 'WORKFLOWS',
};

export const TITLE_PROPERTY_NAME = 'title';

export enum HazardStatus {
  LINKED = 'linked',
  UNLINKED = 'unlinked',
}

import { ReactNode } from 'react';
import { Notification, ToastType } from './constants';
import { InfoCircle, Trash, WarningCircle } from 'assets/icons';
import {
  CloseButton,
  ContentWrapper,
  CrossIcon,
  FlexContainer,
  IconContainer,
  Message,
  TextContainer,
  TickIcon,
  Title,
  WarningIcon,
} from './styled';

type Props = {
  notification: Notification;
  type: ToastType;
  onClose: (id: number) => void;
  light?: boolean;
  children?: JSX.Element;
};

export const ToastContent = ({
  notification,
  type,
  onClose,
  light = false,
  children,
}: Props): JSX.Element => {
  const hasLightText = type === ToastType.INFO || type === ToastType.ERROR;

  const getIcon = (): ReactNode => {
    if (!!notification.customIcon) {
      return <notification.customIcon />;
    }
    switch (type) {
      case ToastType.INFO:
        return <InfoCircle />;
      case ToastType.WARNING:
        return <WarningCircle />;
      case ToastType.SUCCESS:
        return <TickIcon />;
      case ToastType.ERROR:
        return <WarningIcon />;
      case ToastType.SECONDARY:
        return <Trash />;
      default:
    }
  };

  return (
    <ContentWrapper>
      <FlexContainer>
        <IconContainer>{getIcon()}</IconContainer>
        <TextContainer $light={hasLightText}>
          <Title>{notification.title}</Title>
          {notification.message && <Message>{notification.message}</Message>}
          {children}
        </TextContainer>
      </FlexContainer>
      {notification.canBeDismissed && (
        <CloseButton disableRipple onClick={() => onClose(notification.id)}>
          <CrossIcon $light={light} />
        </CloseButton>
      )}
    </ContentWrapper>
  );
};

import styled from 'styled-components';
import { TextField } from '@mui/material';
import theme from 'theme';

const { colors, fontSizes, fonts } = theme;

export const StyledTextField = styled(TextField)`
  .MuiTextField-root {
    &.Mui-focused {
      border: 0;
      outline: 0;
    }
    width: 100%;
    &:hover,
    &:focus {
      background-color: ${colors.black};
      box-shadow: none;
      border: none;
      transition: ease-out 0.2s;
    }
  }
  .MuiOutlinedInput-input {
    height: 45px;
    border: none;
    background-color: ${colors.black};
    border-radius: 0;
    width: 260px;
    padding: 0 5px;
    font-size: ${fontSizes.xl};
    color: ${colors.white} !important;
    box-shadow: none;
    outline: 3px solid ${colors.black};
    font-family: ${fonts.bold} !important;
    caret-color: ${colors.orange};
    &::placeholder {
      opacity: 1;
    }
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
`;

import { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { Loader, Media } from 'components/ui';
import styled from 'styled-components';
import theme from 'theme';
import { Field } from 'model/Forms/Field';
import { Video } from 'model/Media/Video';
import UserFormAnalyticsParameter from 'model/Forms/UserFormAnalyticsParameters';
import { useText } from 'hooks';

const { colors, fonts, fontSizes } = theme;

// Styled components
const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0;
`;

const DetailTitle = styled.span`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  padding-right: 8px;
  padding-bottom: 16px;
`;

const DetailText = styled.span`
  font-size: ${fontSizes.default};
  color: ${colors.darkGrey};
  font-family: ${fonts.boldItalic};
  padding: 5px 0;
`;

type Props = {
  field: Field;
  videos: Video[];
  userFormParameters?: UserFormAnalyticsParameter;
};

type VideoResult = {
  file_id: string;
  url: string;
};

const VideoUpload = ({
  field,
  videos,
  userFormParameters,
}: Props): JSX.Element => {
  const [videosWithUrl, setVideosWithUrl] = useState<VideoResult[]>([]);
  const [isFetchingVideos, setFetchingVideos] = useState<boolean>(true);
  const getText = useText();

  useEffect(() => {
    (async () => {
      if (videos?.length > 0) {
        const videoResult: VideoResult[] = await Promise.all(
          videos.map(async (video) => ({
            file_id: video.fileId,
            url: await Storage.get(video.path),
          })),
        );
        setVideosWithUrl([...videoResult]);
        setFetchingVideos(false);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DetailContainer style={{ flexDirection: 'column' }}>
      <DetailTitle>{field.title}</DetailTitle>
      {field.value &&
        (isFetchingVideos ? (
          <Loader />
        ) : (
          videosWithUrl.find(
            (videoWithUrl) => videoWithUrl?.file_id === field.value?.file_id,
          )?.url && (
            <Media
              type='video'
              url={
                videosWithUrl.find(
                  (videoWithUrl) =>
                    videoWithUrl?.file_id === field.value?.file_id,
                )?.url
              }
              kind='job-form'
              carouselStyle={{ display: 'grid' }}
              userFormParameters={
                {
                  ...userFormParameters,
                  video_id: field?.value?.file_id,
                } as UserFormAnalyticsParameter
              }
            />
          )
        ))}
      {!field.value && (
        <DetailText>{getText('job_forms_not_reported')}</DetailText>
      )}
    </DetailContainer>
  );
};

export default VideoUpload;

import styled from 'styled-components';
import { EmptyStatePatternCircles } from 'assets/images';
import theme from 'theme';

const { fonts, fontSizes } = theme;

export const Container = styled.div`
  flex: 1;
  padding: 15px;
  background-image: url(${EmptyStatePatternCircles});
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
`;

export const Title = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 8px !important;
`;

export const Subtitle = styled.p`
  font-size: ${fontSizes.m};
`;

import { Field } from 'model/Forms/Field';
import { FormDetail } from 'model/Forms/FormDetail';
import { Section } from 'model/Forms/Section';

export const mapToForm = (backendForm: any): FormDetail => ({
  title: backendForm.title,
  version: backendForm.version,
  sections: backendForm.sections.map(mapToSection),
  header: backendForm.header,
  submitButtonLabel: backendForm.submitButtonLabel,
});

const mapToSection = (backendSection: any): Section => ({
  order: backendSection.order,
  title: backendSection.title,
  fields: backendSection.fields.map(mapToField),
  config: backendSection.config,
});

const mapToField = (backendField: any): Field => ({
  id: backendField.id,
  title: backendField.title,
  kind: backendField.kind,
  value: backendField.value,
});

export const stringifyUserIds = (userIds: number[]): string | null =>
  userIds?.length ? userIds.join(',') : null;

import React from 'react';
import { CSSProperties } from 'styled-components';
import * as Analytics from 'utils/analytics';
import { LinkContainer } from './styled';
import { ArrowRight, Description, Icon } from '../styled';

type Props = {
  text: string;
  to?: string;
  IconComponent?: React.ElementType;
  width?: string;
  extraStyles?: CSSProperties;
  disabled?: boolean;
  state?: object;
  hasBlackBackground?: boolean;
};

// Button.SecondaryLink
export const SecondaryLinkButton = Analytics.withTrackEvent(
  ({
    text,
    to = '',
    IconComponent,
    width = '100%',
    extraStyles,
    disabled = false,
    state,
    hasBlackBackground = false,
    ...props
  }: Props): JSX.Element => (
    <LinkContainer
      to={{ pathname: to.split('?')[0], search: to.split('?')[1] }}
      state={state}
      width={width}
      disabled={disabled}
      {...props}
      style={extraStyles}
      $hasBlackBackground={hasBlackBackground}
    >
      <Description $disabled={disabled}>
        {IconComponent && (
          <Icon disabled={disabled} component={<IconComponent />} />
        )}
        {text}
      </Description>
      <ArrowRight disabled={disabled} />
    </LinkContainer>
  ),
);

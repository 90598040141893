import { Calendar as ReactCalendar } from 'react-calendar';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media } = theme;

export const StyledCalendar = styled(ReactCalendar)`
  &.react-calendar {
    border: none;
    width: 100%;
    .react-calendar__navigation__label {
      color: ${colors.darkGrey};
      font-family: ${fonts.bold};
      font-size: ${fontSizes.m};
      background-color: transparent;
    }
    .react-calendar__navigation button:enabled:hover {
      background-color: transparent;
    }
    .react-calendar__navigation__arrow {
      font-size: ${fontSizes.massive};
      color: ${colors.grey};
    }
    .react-calendar__month-view__weekdays {
      text-transform: unset;
      border-bottom: 1px solid ${colors.grey};
    }
    .react-calendar__month-view__weekdays__weekday abbr {
      text-decoration: none;
      color: ${colors.grey};
      font-size: ${fontSizes.s};
      font-family: ${fonts.light};
    }
    .react-calendar__tile {
      padding: 25px 0;
      font-size: ${fontSizes.default};
      font-family: ${fonts.bold};
      color: ${colors.black};
      width: 100%;
      height: 4.25vw;
      ${media.xxl`
      padding: 15px 0;
    `}
      ${media.lg`
      padding: 10px 0;
    `}
      ${media.sm`
      padding: 25px 0;
    `}
      &.react-calendar__tile--now {
        background-color: transparent;
      }
      &.react-calendar__tile--active {
        background-color: ${colors.orange};
        color: ${colors.white};
        border-radius: 50%;
      }
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: ${colors.grey};
    }
  }
`;

import {
  ADD_EVIDENCE_TYPE_ERROR,
  ADD_EVIDENCE_TYPE_SUCCESS,
  ADD_JOB_BLOCKER_SUBTYPE_ERROR,
  ADD_JOB_BLOCKER_SUBTYPE_SUCCESS,
  ADD_JOB_BLOCKER_TYPE_ERROR,
  ADD_JOB_BLOCKER_TYPE_SUCCESS,
  ADD_JOB_TYPE_ERROR,
  ADD_JOB_TYPE_SUCCESS,
  EDIT_EVIDENCE_TYPE_ERROR,
  EDIT_EVIDENCE_TYPE_SUCCESS,
  EDIT_JOB_BLOCKER_SUBTYPE_ERROR,
  EDIT_JOB_BLOCKER_SUBTYPE_SUCCESS,
  EDIT_JOB_BLOCKER_TYPE_ERROR,
  EDIT_JOB_BLOCKER_TYPE_SUCCESS,
  EDIT_JOB_TYPE_ERROR,
  EDIT_JOB_TYPE_SUCCESS,
  FETCH_EVIDENCE_TYPES,
  FETCH_EVIDENCE_TYPES_ERROR,
  FETCH_EVIDENCE_TYPES_SUCCESS,
  FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES,
  FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES_ERROR,
  FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES_SUCCESS,
  FETCH_WORKSPACE_JOB_BLOCKER_TYPES,
  FETCH_WORKSPACE_JOB_BLOCKER_TYPES_ERROR,
  FETCH_WORKSPACE_JOB_BLOCKER_TYPES_SUCCESS,
  FETCH_WORKSPACE_JOB_TYPES,
  FETCH_WORKSPACE_JOB_TYPES_ERROR,
  FETCH_WORKSPACE_JOB_TYPES_SUCCESS,
  FETCH_WORKSPACE_SETTINGS,
  FETCH_WORKSPACE_SETTINGS_ERROR,
  FETCH_WORKSPACE_SETTINGS_SUCCESS,
  REMOVE_EVIDENCE_TYPE_ERROR,
  REMOVE_EVIDENCE_TYPE_SUCCESS,
  REMOVE_JOB_BLOCKER_SUBTYPE_ERROR,
  REMOVE_JOB_BLOCKER_SUBTYPE_SUCCESS,
  REMOVE_JOB_BLOCKER_TYPE_ERROR,
  REMOVE_JOB_BLOCKER_TYPE_SUCCESS,
  REMOVE_JOB_TYPE_ERROR,
  REMOVE_JOB_TYPE_SUCCESS,
  RESET_EVIDENCE_TYPE_ERRORS,
  RESET_JOB_BLOCKER_SUBTYPE_ERRORS,
  RESET_JOB_BLOCKER_TYPE_ERRORS,
  RESET_JOB_TYPE_ERRORS,
  SAVE_EVIDENCE_TYPE_CHANGES,
  SAVE_EVIDENCE_TYPE_CHANGES_ERROR,
  SAVE_EVIDENCE_TYPE_CHANGES_SUCCESS,
  SAVE_JOB_BLOCKER_SUBTYPE_CHANGES,
  SAVE_JOB_BLOCKER_SUBTYPE_CHANGES_ERROR,
  SAVE_JOB_BLOCKER_SUBTYPE_CHANGES_SUCCESS,
  SAVE_JOB_BLOCKER_TYPE_CHANGES,
  SAVE_JOB_BLOCKER_TYPE_CHANGES_ERROR,
  SAVE_JOB_BLOCKER_TYPE_CHANGES_SUCCESS,
  SAVE_JOB_TYPE_CHANGES,
  SAVE_JOB_TYPE_CHANGES_ERROR,
  SAVE_JOB_TYPE_CHANGES_SUCCESS,
  SET_JOB_CREATION_SETTINGS,
  SET_JOB_CREATION_SETTINGS_ERROR,
  SET_JOB_CREATION_SETTINGS_SUCCESS,
  SET_WORKSPACE_SETTINGS,
  SET_WORKSPACE_SETTINGS_ERROR,
  SET_WORKSPACE_SETTINGS_SUCCESS,
} from 'store/actions/actionTypes';

const defaultState = {
  isFetchingWorkspaceSettings: false,
  isLoadingWorkspaceChanges: false,
  workspaceError: null,
  isLoadingJobCreationChanges: false,
  jobCreationError: null,
  workspaceSettings: {},
  jobCreationSettings: {},
  evidenceTypes: {},
  isFetchingEvidenceTypes: false,
  isLoadingEvidenceChanges: false,
  evidenceTypeErrors: [],
  jobTypes: {},
  isFetchingJobTypes: false,
  isLoadingJobTypeChanges: false,
  jobTypeErrors: [],
  jobBlockerTypes: {},
  isFetchingJobBlockerTypes: false,
  isLoadingJobBlockerChanges: false,
  jobBlockerTypeErrors: [],
  jobBlockerSubtypes: {},
  isFetchingJobBlockerSubtypes: false,
  isLoadingJobBlockerSubtypeChanges: false,
  jobBlockerSubtypeErrors: [],
};

const workspace = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_WORKSPACE_SETTINGS:
      return {
        ...state,
        isFetchingWorkspaceSettings: true,
      };
    case FETCH_WORKSPACE_SETTINGS_SUCCESS:
      return {
        ...state,
        workspaceSettings: {
          ...state.workspaceSettings,
          [action.workspaceUuid]: { ...action.workspaceData },
        },
        jobCreationSettings: {
          ...state.jobCreationSettings,
          [action.workspaceUuid]: { ...action.jobCreationData },
        },
        isFetchingWorkspaceSettings: false,
      };
    case FETCH_WORKSPACE_SETTINGS_ERROR:
      return {
        ...state,
        isFetchingWorkspaceSettings: false,
      };
    case SET_WORKSPACE_SETTINGS:
      return {
        ...state,
        isLoadingWorkspaceChanges: true,
        workspaceError: null,
      };
    case SET_WORKSPACE_SETTINGS_SUCCESS:
      return {
        ...state,
        workspaceSettings: {
          ...state.workspaceSettings,
          [action.workspaceUuid]: { ...action.data },
        },
        isLoadingWorkspaceChanges: false,
      };
    case SET_WORKSPACE_SETTINGS_ERROR:
      return {
        ...state,
        isLoadingWorkspaceChanges: false,
        workspaceError: action.error,
      };
    case SET_JOB_CREATION_SETTINGS:
      return {
        ...state,
        isLoadingJobCreationChanges: true,
        jobCreationError: null,
      };
    case SET_JOB_CREATION_SETTINGS_SUCCESS:
      return {
        ...state,
        jobCreationSettings: {
          ...state.jobCreationSettings,
          [action.workspaceUuid]: { ...action.data },
        },
        isLoadingJobCreationChanges: false,
      };
    case SET_JOB_CREATION_SETTINGS_ERROR:
      return {
        ...state,
        isLoadingJobCreationChanges: false,
        jobCreationError: action.error,
      };
    // EVIDENCE TYPES
    case FETCH_EVIDENCE_TYPES:
      return {
        ...state,
        isFetchingEvidenceTypes: true,
      };
    case FETCH_EVIDENCE_TYPES_SUCCESS:
      return {
        ...state,
        evidenceTypes: {
          ...state.evidenceTypes,
          [action.workspaceUuid]: [...action.data],
        },
        isFetchingEvidenceTypes: false,
      };
    case FETCH_EVIDENCE_TYPES_ERROR:
      return {
        ...state,
        isFetchingEvidenceTypes: false,
      };
    case SAVE_EVIDENCE_TYPE_CHANGES:
      return {
        ...state,
        isLoadingEvidenceChanges: true,
      };
    case SAVE_EVIDENCE_TYPE_CHANGES_SUCCESS:
    case SAVE_EVIDENCE_TYPE_CHANGES_ERROR:
      return {
        ...state,
        isLoadingEvidenceChanges: false,
      };
    case ADD_EVIDENCE_TYPE_SUCCESS: {
      const workspaceEvidenceTypes =
        state.evidenceTypes?.[action.workspaceUuid] || [];
      return {
        ...state,
        evidenceTypes: {
          ...state.evidenceTypes,
          [action.workspaceUuid]: [...workspaceEvidenceTypes, action.data],
        },
      };
    }
    case EDIT_EVIDENCE_TYPE_SUCCESS: {
      const workspaceEvidenceTypes =
        state.evidenceTypes?.[action.workspaceUuid] || [];
      const updatedEvidenceTypes = workspaceEvidenceTypes.map((type) =>
        type?.id === action.data?.id ? action.data : type,
      );
      return {
        ...state,
        evidenceTypes: {
          ...state.evidenceTypes,
          [action.workspaceUuid]: [...updatedEvidenceTypes],
        },
      };
    }
    case REMOVE_EVIDENCE_TYPE_SUCCESS: {
      const workspaceEvidenceTypes =
        state.evidenceTypes?.[action.workspaceUuid] || [];
      const updatedEvidenceTypes = workspaceEvidenceTypes.filter(
        (type) => type?.id !== action?.id,
      );
      return {
        ...state,
        evidenceTypes: {
          ...state.evidenceTypes,
          [action.workspaceUuid]: [...updatedEvidenceTypes],
        },
      };
    }
    case ADD_EVIDENCE_TYPE_ERROR:
    case EDIT_EVIDENCE_TYPE_ERROR:
    case REMOVE_EVIDENCE_TYPE_ERROR:
      return {
        ...state,
        evidenceTypeErrors: [...state.evidenceTypeErrors, action.data],
      };
    case RESET_EVIDENCE_TYPE_ERRORS:
      return {
        ...state,
        evidenceTypeErrors: [],
      };
    // JOB TYPES
    case FETCH_WORKSPACE_JOB_TYPES:
      return {
        ...state,
        isFetchingJobTypes: true,
      };
    case FETCH_WORKSPACE_JOB_TYPES_SUCCESS:
      return {
        ...state,
        jobTypes: {
          ...state.jobTypes,
          [action.workspaceUuid]: [...action.data],
        },
        isFetchingJobTypes: false,
      };
    case FETCH_WORKSPACE_JOB_TYPES_ERROR:
      return {
        ...state,
        isFetchingJobTypes: false,
      };
    case SAVE_JOB_TYPE_CHANGES:
      return {
        ...state,
        isLoadingJobTypeChanges: true,
      };
    case SAVE_JOB_TYPE_CHANGES_SUCCESS:
    case SAVE_JOB_TYPE_CHANGES_ERROR:
      return {
        ...state,
        isLoadingJobTypeChanges: false,
      };
    case ADD_JOB_TYPE_SUCCESS: {
      const workspaceJobTypes = state.jobTypes?.[action.workspaceUuid] || [];
      return {
        ...state,
        jobTypes: {
          ...state.jobTypes,
          [action.workspaceUuid]: [...workspaceJobTypes, action.data],
        },
      };
    }
    case EDIT_JOB_TYPE_SUCCESS: {
      const workspaceJobTypes = state.jobTypes?.[action.workspaceUuid] || [];
      const updatedJobTypes = workspaceJobTypes.map((type) =>
        type?.id === action.data?.id ? action.data : type,
      );
      return {
        ...state,
        jobTypes: {
          ...state.jobTypes,
          [action.workspaceUuid]: [...updatedJobTypes],
        },
      };
    }
    case REMOVE_JOB_TYPE_SUCCESS: {
      const workspaceJobTypes = state.jobTypes?.[action.workspaceUuid] || [];
      const updatedJobTypes = workspaceJobTypes.filter(
        (type) => type?.id !== action?.id,
      );
      return {
        ...state,
        jobTypes: {
          ...state.jobTypes,
          [action.workspaceUuid]: [...updatedJobTypes],
        },
      };
    }
    case ADD_JOB_TYPE_ERROR:
    case EDIT_JOB_TYPE_ERROR:
    case REMOVE_JOB_TYPE_ERROR:
      return {
        ...state,
        jobTypeErrors: [...state.jobTypeErrors, action.data],
      };
    case RESET_JOB_TYPE_ERRORS:
      return {
        ...state,
        jobTypeErrors: [],
      };
    // JOB BLOCKER TYPES
    case FETCH_WORKSPACE_JOB_BLOCKER_TYPES:
      return {
        ...state,
        isFetchingJobBlockerTypes: true,
      };
    case FETCH_WORKSPACE_JOB_BLOCKER_TYPES_SUCCESS:
      return {
        ...state,
        jobBlockerTypes: {
          ...state.jobBlockerTypes,
          [action.workspaceUuid]: [...action.data],
        },
        isFetchingJobBlockerTypes: false,
      };
    case FETCH_WORKSPACE_JOB_BLOCKER_TYPES_ERROR:
      return {
        ...state,
        isFetchingJobBlockerTypes: false,
      };
    case SAVE_JOB_BLOCKER_TYPE_CHANGES:
      return {
        ...state,
        isLoadingJobBlockerChanges: true,
      };
    case SAVE_JOB_BLOCKER_TYPE_CHANGES_SUCCESS:
    case SAVE_JOB_BLOCKER_TYPE_CHANGES_ERROR:
      return {
        ...state,
        isLoadingJobBlockerChanges: false,
      };
    case ADD_JOB_BLOCKER_TYPE_SUCCESS: {
      const workspaceJobBlockerTypes =
        state.jobBlockerTypes?.[action.workspaceUuid] || [];
      return {
        ...state,
        jobBlockerTypes: {
          ...state.jobBlockerTypes,
          [action.workspaceUuid]: [...workspaceJobBlockerTypes, action.data],
        },
      };
    }
    case EDIT_JOB_BLOCKER_TYPE_SUCCESS: {
      const workspaceJobBlockerTypes =
        state.jobBlockerTypes?.[action.workspaceUuid] || [];
      const updatedJobBlockerTypes = workspaceJobBlockerTypes.map((type) =>
        type?.id === action.data?.id ? action.data : type,
      );
      return {
        ...state,
        jobBlockerTypes: {
          ...state.jobBlockerTypes,
          [action.workspaceUuid]: [...updatedJobBlockerTypes],
        },
      };
    }
    case REMOVE_JOB_BLOCKER_TYPE_SUCCESS: {
      const workspaceJobBlockerTypes =
        state.jobBlockerTypes?.[action.workspaceUuid] || [];
      const updatedJobBlockerTypes = workspaceJobBlockerTypes.filter(
        (type) => type?.id !== action?.id,
      );
      return {
        ...state,
        jobBlockerTypes: {
          ...state.jobBlockerTypes,
          [action.workspaceUuid]: [...updatedJobBlockerTypes],
        },
      };
    }
    case ADD_JOB_BLOCKER_TYPE_ERROR:
    case EDIT_JOB_BLOCKER_TYPE_ERROR:
    case REMOVE_JOB_BLOCKER_TYPE_ERROR:
      return {
        ...state,
        jobBlockerTypeErrors: [...state.jobBlockerTypeErrors, action.data],
      };
    case RESET_JOB_BLOCKER_TYPE_ERRORS:
      return {
        ...state,
        jobBlockerTypeErrors: [],
      };
    // JOB BLOCKER SUBTYPES
    case FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES:
      return {
        ...state,
        isFetchingJobBlockerSubtypes: true,
      };
    case FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES_SUCCESS:
      return {
        ...state,
        jobBlockerSubtypes: {
          ...state.jobBlockerSubtypes,
          [action.workspaceUuid]: [...action.data],
        },
        isFetchingJobBlockerSubtypes: false,
      };
    case FETCH_WORKSPACE_JOB_BLOCKER_SUBTYPES_ERROR:
      return {
        ...state,
        isFetchingJobBlockerSubtypes: false,
      };
    case SAVE_JOB_BLOCKER_SUBTYPE_CHANGES:
      return {
        ...state,
        isLoadingJobBlockerSubtypeChanges: true,
      };
    case SAVE_JOB_BLOCKER_SUBTYPE_CHANGES_SUCCESS:
    case SAVE_JOB_BLOCKER_SUBTYPE_CHANGES_ERROR:
      return {
        ...state,
        isLoadingJobBlockerSubtypeChanges: false,
      };
    case ADD_JOB_BLOCKER_SUBTYPE_SUCCESS: {
      const workspaceJobBlockerSubtypes =
        state.jobBlockerSubtypes?.[action.workspaceUuid] || [];
      return {
        ...state,
        jobBlockerSubtypes: {
          ...state.jobBlockerSubtypes,
          [action.workspaceUuid]: [...workspaceJobBlockerSubtypes, action.data],
        },
      };
    }
    case EDIT_JOB_BLOCKER_SUBTYPE_SUCCESS: {
      const workspaceJobBlockerSubtypes =
        state.jobBlockerSubtypes?.[action.workspaceUuid] || [];
      const updatedJobBlockerSubtypes = workspaceJobBlockerSubtypes.map(
        (type) => (type?.id === action.data?.id ? action.data : type),
      );
      return {
        ...state,
        jobBlockerSubtypes: {
          ...state.jobBlockerSubtypes,
          [action.workspaceUuid]: [...updatedJobBlockerSubtypes],
        },
      };
    }
    case REMOVE_JOB_BLOCKER_SUBTYPE_SUCCESS: {
      const workspaceJobBlockerSubtypes =
        state.jobBlockerSubtypes?.[action.workspaceUuid] || [];
      const updatedJobBlockerSubtypes = workspaceJobBlockerSubtypes.filter(
        (type) => type?.id !== action?.id,
      );
      return {
        ...state,
        jobBlockerSubtypes: {
          ...state.jobBlockerSubtypes,
          [action.workspaceUuid]: [...updatedJobBlockerSubtypes],
        },
      };
    }
    case ADD_JOB_BLOCKER_SUBTYPE_ERROR:
    case EDIT_JOB_BLOCKER_SUBTYPE_ERROR:
    case REMOVE_JOB_BLOCKER_SUBTYPE_ERROR:
      return {
        ...state,
        jobBlockerSubtypeErrors: [
          ...state.jobBlockerSubtypeErrors,
          action.data,
        ],
      };
    case RESET_JOB_BLOCKER_SUBTYPE_ERRORS:
      return {
        ...state,
        jobBlockerSubtypeErrors: [],
      };
    default:
      return state;
  }
};

export default workspace;

import { useEffect } from 'react';
import styled from 'styled-components';
import { useIsMobile, useQueryParams, useText } from 'hooks';
import { Loader, Card, Message, Navbar } from 'components/ui';
import { SkeletonCardContainer } from 'components/ui/Shared/EmptyState';
import theme from 'theme';
import { YellowCheckMark, Warning } from 'assets/icons';
import * as constants from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssessments } from 'store/actions/assessments';
import {
  selectWorkspaceSettings,
  selectGlobalLanguageSetting,
  selectJobsView,
} from 'store/selectors';
import { useParams } from 'react-router-dom';
import RootState from 'model/State/RootState';
import { UploadStatus } from 'model/enum/UploadStatus';
import { MajorHazardTag } from 'components/ui/Tags/MajorHazardTag';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

const { media, colors, fonts } = theme;

// Styled Components
const Container = styled.div`
  height: 100%;
  ${media.sm`
    padding: 25px 15px;
    overflow: scroll;
  `}
`;

const Wrapper = styled.div`
  margin-bottom: 8px;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 17px;
  font-family: ${fonts.bold};
`;

const OrangeStatusContainer = styled(StatusContainer)`
  background-color: ${colors.orange};
`;

const BlackStatusContainer = styled(StatusContainer)`
  background-color: ${colors.black};
`;

const SmallWarningIcon = styled(Warning)`
  height: 20px;
  width: 20px;
`;

const Status = styled.span<{ $textColor?: string }>`
  color: ${({ $textColor }) => $textColor || colors.white};
  margin-left: 10px;
`;

// Assessment.List
export default (): JSX.Element => {
  const { jobId } = useParams();
  const getText = useText();
  const { getPathWithQueryParams } = useQueryParams();
  const { assessments, isAssessmentsLoaded } = useSelector(
    (state: RootState) => state.assessments,
  );
  const { view_unreviewed_vra } = useSelector(selectWorkspaceSettings);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const view = useSelector(selectJobsView);

  useEffect(() => {
    dispatch(fetchAssessments(jobId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderCompleteContainer = (title) => (
    <BlackStatusContainer>
      <YellowCheckMark />
      <Status>{title}</Status>
    </BlackStatusContainer>
  );

  const renderNotCompleteContainer = (title) => (
    <OrangeStatusContainer>
      <SmallWarningIcon />
      <Status $textColor={colors.black}>{title}</Status>
    </OrangeStatusContainer>
  );

  const getAssessmentStatusTag = (
    isCompleted,
    completedTitle,
    notCompletedTitle,
  ) =>
    isCompleted
      ? renderCompleteContainer(completedTitle)
      : renderNotCompleteContainer(notCompletedTitle);

  const renderRamsTag = (is_rams_reviewed, method_statement) => {
    if (!is_rams_reviewed && !method_statement) {
      return renderNotCompleteContainer(getText('job_details_rams_pending'));
    }
    if (is_rams_reviewed && !method_statement) {
      return renderNotCompleteContainer(
        getText('job_details_rams_method_statement_pending'),
      );
    }
    return renderCompleteContainer(
      getText('job_details_rams_method_statement_complete'),
    );
  };

  return (
    <>
      <Navbar
        title={getText('job_overview_risk_assessments')}
        defaultBackPath={`/jobs/${jobId}`}
        mobileOnly
      />

      <Container>
        {!isAssessmentsLoaded && <Loader />}
        {isAssessmentsLoaded && !assessments.length && (
          <SkeletonCardContainer>
            {getText('job_overview_no_risk_assessment')}
          </SkeletonCardContainer>
        )}
        {isAssessmentsLoaded &&
          assessments &&
          assessments.map((assessment) => {
            const isLocked =
              [UploadStatus.UPLOADED, UploadStatus.PROCESSED].includes(
                assessment?.videoStatus,
              ) && !assessment.typeIsRams;

            const isNotUploaded =
              assessment.videoStatus === UploadStatus.NEW &&
              !assessment.typeIsRams;

            return isMobile ? (
              <Message.Action
                key={`assessment-${assessment.id}`}
                event={constants.EVENT_VIEW_RISK_ASSESSMENT_ITEM}
                eventProperties={{
                  jobId,
                  assessmentId: assessment.id,
                  assessmentType: assessment.type
                    ? getLocalizedCamelCaseValue(
                        assessment,
                        'type',
                        globalAppLanguage,
                      )
                    : getText('job_details_assessment_title'),
                  source: 'job-overview',
                  [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
                type={getText('job_details_assessment_title')}
                subtype={getLocalizedCamelCaseValue(
                  assessment,
                  'type',
                  globalAppLanguage,
                )}
                sender={assessment.createdBy}
                date={assessment.createdAt}
                to={`/jobs/${assessment.jobId}/assessments/${assessment.id}`}
                signoffRequired={assessment.signoffRequired}
                isSignedOff={assessment.isSignedOff}
                isLocked={isLocked}
                isNotUploaded={isNotUploaded}
                qualityIndicator={assessment?.video?.qualityIndicator}
              />
            ) : (
              <Wrapper key={`assessment-${assessment.id}`}>
                <Card.Assessment
                  event={constants.EVENT_VIEW_RISK_ASSESSMENT_ITEM}
                  eventProperties={{
                    jobId,
                    assessmentId: assessment.id,
                    assessmentType: assessment.type
                      ? getLocalizedCamelCaseValue(
                          assessment,
                          'type',
                          globalAppLanguage,
                        )
                      : getText('job_details_assessment_title'),
                    source: 'job-overview',
                    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  }}
                  key={`assessment-${assessment.id}`}
                  subtype={
                    assessment.type
                      ? getLocalizedCamelCaseValue(
                          assessment,
                          'type',
                          globalAppLanguage,
                        )
                      : getText('job_details_assessment_title')
                  }
                  creator={assessment.createdBy}
                  date={assessment.createdAt}
                  to={getPathWithQueryParams(
                    `/jobs/${assessment.jobId}/${
                      assessment.typeIsRams ? 'rams-assessments' : 'assessments'
                    }/${assessment.id}`,
                  )}
                  isLocked={!view_unreviewed_vra && isLocked}
                />
                {assessment.signoffRequired &&
                  !assessment.typeIsRams &&
                  !isLocked &&
                  !isNotUploaded &&
                  getAssessmentStatusTag(
                    assessment.isSignedOff,
                    getText('job_details_sign_off_done'),
                    getText('job_details_sign_off_required'),
                  )}
                {assessment.typeIsRams &&
                  renderRamsTag(
                    assessment.isRamsReviewed,
                    assessment.isMethodStatementDone,
                  )}
                {assessment.hasMajorHazard && (
                  <MajorHazardTag
                    text={getText('job_overview_major_hazard_identified')}
                    hasSmallIcon
                    extraStyles={{ marginTop: 0, padding: '7px 17px' }}
                  />
                )}
                {isLocked && !isNotUploaded && (
                  <OrangeStatusContainer>
                    <SmallWarningIcon />
                    <Status $textColor={colors.black}>
                      {getText('job_details_review_pending')}
                    </Status>
                  </OrangeStatusContainer>
                )}
              </Wrapper>
            );
          })}
      </Container>
    </>
  );
};

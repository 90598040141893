import { useText } from 'hooks';
import ManageTypesBase from './ManageTypesBase';
import {
  selectGlobalLanguageSetting,
  selectJobBlockerSubtypes,
  selectJobBlockerTypes,
  selectIsFetchingJobBlockerSubtypes,
  selectIsLoadingJobBlockerSubtypeChanges,
  selectJobBlockerSubtypeErrors,
} from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import RootState from 'model/State/RootState';
import {
  fetchWorkspaceJobBlockerSubtypes,
  fetchWorkspaceJobBlockerTypes,
  resetJobBlockerSubtypeErrors,
  saveJobBlockerSubtypeChanges,
} from 'store/actions/workspace';
import { useCustomLocaleText } from 'hooks/useText';
import { Languages } from 'utils/constants';
import { WorkspaceItemStatus } from 'model/enum/WorkspaceItemStatus';
import * as Analytics from 'utils/analytics';
import { addItem, editItem } from 'helpers/workspace';
import {
  EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_SUBTYPES_SAVED,
  EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_SUBTYPE_ADDED,
  EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_SUBTYPE_DELETED,
  EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_SUBTYPE_EDITED,
} from '../../constants';
import { WorkspaceItemType } from 'model/AdminPanel/WorkspaceSettings/WorkspaceItemType';

const JobBlockerSubtypeSettings = (): JSX.Element => {
  const getText = useText();
  const getEnglishText = useCustomLocaleText(Languages.english.locale);
  const getSpanishText = useCustomLocaleText(Languages.spanish.locale);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const isLoadingJobBlockerSubtypeChanges = useSelector(
    selectIsLoadingJobBlockerSubtypeChanges,
  );
  const jobBlockerSubtypeErrors = useSelector(selectJobBlockerSubtypeErrors);
  const dispatch = useDispatch();
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const jobBlockerTypes = useSelector(
    selectJobBlockerTypes(selectedWorkspace?.uuid),
  );
  const [selectedJobBlockerType, setSelectedJobBlockerType] =
    useState<WorkspaceItemType>(jobBlockerTypes[0]);
  const isFetchingJobBlockerSubtypes = useSelector(
    selectIsFetchingJobBlockerSubtypes,
  );
  const initialJobBlockerSubtypes = useSelector(
    selectJobBlockerSubtypes(selectedWorkspace?.uuid),
  );
  const [jobBlockerSubtypes, setJobBlockerSubtypes] = useState<
    WorkspaceItemType[]
  >(initialJobBlockerSubtypes);

  useEffect(() => {
    dispatch(resetJobBlockerSubtypeErrors());
    if (selectedWorkspace?.uuid) {
      dispatch(fetchWorkspaceJobBlockerTypes(selectedWorkspace?.uuid));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setJobBlockerSubtypes(initialJobBlockerSubtypes);
  }, [initialJobBlockerSubtypes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedJobBlockerType(jobBlockerTypes[0]);
  }, [jobBlockerTypes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedJobBlockerType?.id) {
      dispatch(
        fetchWorkspaceJobBlockerSubtypes(
          selectedWorkspace?.uuid,
          selectedJobBlockerType?.id,
        ),
      );
    }
  }, [selectedJobBlockerType]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAddOrEdit = (
    isUpdate: boolean,
    index: number,
    englishText: string,
    spanishText: string,
  ) => {
    if (isUpdate && index > -1) {
      const modifiedList = editItem(
        initialJobBlockerSubtypes,
        jobBlockerSubtypes,
        index,
        englishText,
        spanishText,
        EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_SUBTYPE_EDITED,
      );
      setJobBlockerSubtypes(modifiedList);
    } else {
      const modifiedList = addItem(
        jobBlockerSubtypes,
        englishText,
        spanishText,
        EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_SUBTYPE_ADDED,
        undefined,
        undefined,
        (newType) => {
          if (selectedJobBlockerType?.id) {
            newType.jobBlocker = selectedJobBlockerType?.id;
          }
        },
      );
      setJobBlockerSubtypes(modifiedList);
    }
  };

  const onRemove = (index: number, id: number | null) => {
    Analytics.trackEvent(EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_SUBTYPE_DELETED, {
      jobBlockerType: jobBlockerSubtypes[index],
    });
    if (id) {
      setJobBlockerSubtypes(
        jobBlockerSubtypes.map((type) =>
          type.id === id
            ? { ...type, status: WorkspaceItemStatus.DELETED }
            : type,
        ),
      );
    } else if (index > -1) {
      setJobBlockerSubtypes((prevState) =>
        prevState.filter((_, i) => i !== index),
      );
    }
  };

  const onSubmit = () => {
    const changedFields = jobBlockerSubtypes.filter((type) => type?.status);
    dispatch(
      saveJobBlockerSubtypeChanges(
        selectedWorkspace?.uuid,
        selectedJobBlockerType?.id,
        changedFields,
      ),
    );

    Analytics.trackEvent(EVENT_WORKSPACE_SETTINGS_JOB_BLOCKER_SUBTYPES_SAVED, {
      changed_fields: changedFields,
    });
  };

  return (
    <ManageTypesBase
      manageTitle={getText('workspace_settings_manage_job_blocker_subtype')}
      addEditTitle={getText('workspace_settings_add_edit_job_blocker_subtype')}
      noTypeText={getText('workspace_settings_no_job_blocker_subtype')}
      label={getEnglishText('workspace_settings_job_blocker_subtype_label')}
      labelEs={getSpanishText('workspace_settings_job_blocker_subtype_label')}
      descriptionText={getText(
        'workspace_settings_sub_categories_for_jobs_delay',
      )}
      hasSelectInput
      selectLabel={getText('workspace_settings_select_blocker')}
      selectOptions={jobBlockerTypes}
      selected={selectedJobBlockerType}
      handleSelectChange={(jobBlocker) => setSelectedJobBlockerType(jobBlocker)}
      types={jobBlockerSubtypes}
      loading={isFetchingJobBlockerSubtypes}
      swapOrder={globalAppLanguage !== Languages.english.locale}
      onAddOrEditPress={onAddOrEdit}
      onSubmit={onSubmit}
      isSubmitting={isLoadingJobBlockerSubtypeChanges}
      hasChangedFields={jobBlockerSubtypes.some((type) => type?.status)}
      onRemove={onRemove}
      errors={jobBlockerSubtypeErrors}
    />
  );
};

export default JobBlockerSubtypeSettings;

import { Signature as SignatureIcon } from 'assets/icons';
import { useText } from 'hooks';
import {
  BoldText,
  CrossIcon,
  PhotoContainer,
  SignatureContent,
  SignatureHeader,
  SignatureTitle,
  SignatureWrapper,
} from './styled';
import { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { Photo } from 'components/ui/Media/Photo';
import { Signature } from 'model/Assessment';
import { Header, IconContainer } from '../styled';

type Props = {
  signature: Signature;
  close: () => void;
};

export const SignatureBox = ({ signature, close }: Props): JSX.Element => {
  const getText = useText();
  const [signatureUrl, setSignatureUrl] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (signature?.path) {
        const signedURL = await Storage.get(signature.path);
        setSignatureUrl(signedURL);
      }
    })();
  }, [signature]);

  return (
    <SignatureWrapper>
      <SignatureHeader>
        <Header>
          <SignatureIcon />
          <SignatureTitle>
            {getText('rams_sign_on_digital_signature')}
          </SignatureTitle>
        </Header>
        <IconContainer onClick={close}>
          <CrossIcon />
        </IconContainer>
      </SignatureHeader>
      <SignatureContent>
        <div>{getText('rams_sign_on_digital_signature_confirmation')}</div>
        <BoldText>{getText('rams_sign_on_signature')}</BoldText>
        {signatureUrl && (
          <PhotoContainer>
            <Photo url={signatureUrl} width='75%' forceImage />
          </PhotoContainer>
        )}
      </SignatureContent>
    </SignatureWrapper>
  );
};

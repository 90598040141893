import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Languages } from 'utils/constants';
import i18n from '../i18n/index';
import {
  selectGlobalLanguageSetting,
  selectHasOrganizationSettingEnabled,
} from 'store/selectors';
import { useCallback } from 'react';

export const useText = () => {
  const enableLanguageSelection = selectHasOrganizationSettingEnabled(
    'enable_language_selection',
  );
  const localeSetting = useSelector(selectGlobalLanguageSetting);

  const locale =
    (enableLanguageSelection && localeSetting) || Languages.english.locale;
  const { t } = useTranslation();
  if (i18n.language !== locale) {
    i18n.changeLanguage(locale);
  }
  return useCallback(
    (text, values) => {
      const result = t(text, values ? { ...values } : {});
      return result?.res ?? '';
    },
    [t],
  );
};

export const useCustomLocaleText = (customLocale = '') => {
  const enableLanguageSelection = selectHasOrganizationSettingEnabled(
    'enable_language_selection',
  );
  const locale =
    (enableLanguageSelection && customLocale) || Languages.english.locale;

  return useCallback(
    (text, localeProp = '') => {
      const result = i18n.t(text, { lng: localeProp || locale });
      return result?.res ?? '';
    },
    [locale],
  );
};

export default useText;

import { DownloadNoBackground, PlusIcon, Search } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const DataContainer = styled.div`
  padding: 40px 80px;
  background-color: ${colors.offWhite};
  min-height: 100%;
  box-sizing: border-box;
  overflow: auto;
  ${media.sm`
    padding: 15px;
  `}
`;

export const TotalTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  margin-bottom: 16px !important;
  ${media.lg`
    font-size: ${fontSizes.default};
  `}
  ${media.md`
    font-size: ${fontSizes.s};
  `}
`;

export const ColumnTitle = styled.div`
  border-right: 1px solid ${colors.lightGrey};
`;

export const TotalHeader = styled.div`
  display: flex;
  width: 30%;
  justify-content: space-between;
  margin-right: 5px;
`;

export const TotalContainer = styled.div`
  display: flex;
  padding: 5px;
  align-items: baseline;
`;

export const Count = styled.div`
  color: ${colors.darkGrey};
  margin-left: 15px;
  background-color: ${colors.whiteGrey};
  padding: 2px 6px;
  border-radius: 8px;
  ${media.lg`
    margin-left: 5px;
    font-size: ${fontSizes.xs};
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SearchHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 70%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

export const SearchInput = styled.input`
  width: 200px;
  flex: 1;
  padding: 15px;
  outline: 2px solid ${colors.grey};
  border: none;
  font-size: ${fontSizes.s};
  ${media.md`
    width: 100px;
  `}
  ${media.sm`
    border: none;
  `}
`;

export const UserButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
  ${media.xxl`
    width: 45%;
  `}
  ${media.xl`
    width: 55%;
  `}
  ${media.md`
    width: 60%;
  `}
`;

export const DownloadIcon = styled(DownloadNoBackground)`
  height: 20px;
  width: 20px;
  .fill-selector {
    fill: ${colors.black};
  }
`;

export const StyledPlusIcon = styled(PlusIcon)`
  height: 20px;
  width: 20px;
  .fill-selector {
    fill: ${colors.black};
  }
`;

export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  height: 44px;
  ${media.xl`
    width: 45%;
  `}
  ${media.md`
    width: 40%;
  `}
`;

export const SearchIcon = styled(Search)`
  height: 18px;
  width: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  ${media.md`
    ${mixins.flexColumn};
  `}
`;

import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

const { breakpoints, colors, fonts, media, mixins } = theme;

export const FlexColumn = styled.div`
  ${mixins.flexColumn};
`;

export const Header = styled.div`
  ${mixins.flexBetween};
  margin-bottom: 10px;
  @media only screen and (min-width: ${breakpoints.sm}px) {
    margin-top: 30px;
  }
`;

export const Title = styled.h2`
  font-family: ${fonts.bold};
`;

export const IconBox = styled.div`
  height: 36px;
  width: 36px;
  border: 4px solid ${colors.black};
  ${mixins.flexFullCenter};
`;

export const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props),
)`
  height: 22px;
  width: 22px;
`;

export const Item = styled.div`
  display: flex;
  align-items: baseline;
  margin: 4px 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
  ${media.sm`
    margin: 5px 0px 5px 10px;
    margin-left: 20px;
  `}
`;

export const BulletPointWrapper = styled.div`
  width: 5%;
  min-width: 5%;
  padding-right: 5px;
`;

export const BulletPoint = styled.div`
  min-height: 8px;
  min-width: 8px;
  max-height: 8px;
  max-width: 8px;
  background-color: ${colors.black};
`;

export const NoItemText = styled.div`
  padding-left: 15px;
`;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as constants from 'utils/constants';
import { useText } from 'hooks';
import { User as UserApi } from 'api';
import { PlusIcon } from 'assets/icons';
import { Badge, Button, Input, Loader } from 'components/ui';
import theme from 'theme';
import { useDebounce } from 'use-debounce';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { Languages } from 'utils/constants';
import { ClearButton } from 'components/ui/Button';
import RootState from 'model/State/RootState';
import { useLocation, useNavigate } from 'react-router-dom';
import { BackendUser } from 'model/BackendUser';
import { ModalType } from 'model/enum/ModalType';
import { getParentModalToMemberSearch } from 'helpers/modal';

const { colors, fonts, fontSizes, media, mixins } = theme;

// Styled Components
const Form = styled.form`
  height: 650px;
  width: 650px;
  margin: 0 auto;
  padding: 25px 5px;
  overflow-y: auto;
  ${mixins.flexColumn};
  ${media.sm`
    height: auto;
    flex: 1;
    width: auto;
    margin: 0;
    padding: 0;
  `}
`;

const Selection = styled.div<{ large?: boolean }>`
  height: ${({ large }) => (large ? '175' : '150')}px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  margin: 5px 0;
`;

const BadgeContainer = styled.div`
  min-width: 20%;
  max-width: 20%;
`;

const InputsContainer = styled.div`
  flex: 1;
  overflow: hidden;
  padding: 0 5px;
  ${mixins.flexColumn};
  ${media.sm`
    margin: 25px 15px 0 15px;
  `}
`;

const Users = styled.div`
  flex: 1;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const UserRow = styled.div`
  ${mixins.flexBetween};
  height: 75px;
  border-top: 2px solid ${colors.lightGrey};
  cursor: pointer;
  padding: 0 10px;
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  cursor: pointer;
  color: ${colors.darkGrey};
  padding: 8px;
  &:hover {
    color: ${colors.black};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  ${media.sm`
    padding: 0;
  `}
`;

// SearchMembers
const SearchMembers = (): JSX.Element => {
  const { currentUser, selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const switchDepotEnabled =
    currentUser?.permissions?.includes('switch_depot_v1');
  const navigate = useNavigate();
  const location = useLocation();
  const getText = useText();
  const { filters } = useSelector((state: RootState) => state.jobs);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const [search, setSearch] = useState<string>('');
  const [searchFilter] = useDebounce(search, 600);
  const [users, setUsers] = useState<BackendUser[]>([]);
  const [nextLink, setNextLink] = useState<string | null>(null);
  const isCommandCentre = location?.pathname.endsWith('command-center');
  const isInsights = location?.pathname.endsWith('insights');
  const isFatigueManager = location?.pathname.endsWith('fatigue-manager');
  const [selectedUsers, setSelectedUsers] = useState(() => {
    if (location?.state?.assignees) {
      return location.state.assignees;
    }
    if (!isInsights && filters.assignees) {
      return filters.assignees;
    }

    return [];
  });

  const getUsers = async (nextLinkUrl?: string) => {
    let data;
    if (nextLinkUrl) {
      const result = await UserApi.getNext(nextLink);
      data = result.data;
      setUsers((currentFilteredUsers) => [
        ...currentFilteredUsers,
        ...data.results,
      ]);
    } else {
      const result = await UserApi.getAll(
        selectedWorkspace?.uuid,
        searchFilter,
        20,
        switchDepotEnabled ? null : currentUser.depot?.id,
        null,
        location.state.userRoles ?? null,
      );
      data = result.data;
      setUsers(data.results);
    }
    setNextLink(data.next);
  };

  useEffect(() => {
    (async () => {
      await getUsers();
    })();
  }, [searchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  const userIdIsChecked = (id) =>
    selectedUsers?.map((user) => user.id).includes(id);

  const handleSearch = (newSearch) => {
    setSearch(newSearch);
  };

  const handleToggleSelect = (selectedUser) => {
    if (selectedUsers.map((user) => user.id).includes(selectedUser.id)) {
      setSelectedUsers(
        selectedUsers.filter((user) => user.id !== selectedUser.id),
      );
    } else {
      setSelectedUsers((prevUsers) => [...prevUsers, selectedUser]);
    }
  };

  const handleSubmit = () => {
    navigate(
      location.pathname +
        getParentModalToMemberSearch(
          isCommandCentre,
          isInsights,
          isFatigueManager,
        ),
      {
        state: { ...location.state, assignees: selectedUsers },
      },
    );
  };

  const handleClear = () => {
    navigate(location.pathname + `?modal=${ModalType.COMMAND_CENTRE_FILTERS}`, {
      state: { ...location.state, assignees: [] },
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputsContainer>
        {users ? (
          <>
            <Input.Text
              id='search'
              label={getText('search')}
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Selection large={selectedUsers?.length > 5}>
              {selectedUsers?.map((user) => (
                <BadgeContainer key={user.id}>
                  <Badge.UserBadge
                    user={user}
                    isCurrentUser={currentUser.id === user.id}
                    onDelete={() => handleToggleSelect(user)}
                    vertical
                    large
                    extraStyles={{ padding: '0 10px', width: '100%' }}
                    extraTextStyles={{ maxHeight: '60px' }}
                  />
                </BadgeContainer>
              ))}
            </Selection>

            <Users>
              {users?.map((user) => (
                <UserRow key={user.id} onClick={() => handleToggleSelect(user)}>
                  <Badge.UserBadge
                    user={user}
                    key={`badge-${user.id}`}
                    isCurrentUser={currentUser.id === user.id}
                    large
                  />

                  <Input.Checkbox
                    key={`checkbox-${user.id}`}
                    checked={userIdIsChecked(user.id)}
                  />
                </UserRow>
              ))}
              {nextLink && (
                <LoadMore onClick={() => getUsers(nextLink)}>
                  {getText('load_more')}
                </LoadMore>
              )}
            </Users>
          </>
        ) : (
          <Loader />
        )}
      </InputsContainer>
      <ButtonContainer
        style={{
          justifyContent: isCommandCentre ? 'space-between' : 'flex-end',
        }}
      >
        {isCommandCentre && (
          <ClearButton
            text={getText('command_centre_filter_members_clear')}
            onClick={handleClear}
            large={globalAppLanguage === Languages.english.locale}
            event={constants.COMMAND_CENTER_USER_FILTER_CLEARED}
            width='115px'
          />
        )}
        <Button.Primary
          text={getText(
            isCommandCentre
              ? 'jobs_filter_apply_button'
              : 'jobs_filter_add_members',
          )}
          onClick={handleSubmit}
          IconComponent={isCommandCentre ? PlusIcon : null}
          width={isCommandCentre ? '300px' : '350px'}
          extraStyles={{
            margin: 0,
            fontSize:
              !isCommandCentre || globalAppLanguage === Languages.english.locale
                ? fontSizes.m
                : fontSizes.default,
          }}
        />
      </ButtonContainer>
    </Form>
  );
};

export default SearchMembers;

import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins, media } = theme;

export const LinkContainer = styled(RouterLink)<{
  width: string;
  disabled: boolean;
  $hasBlackBackground?: boolean;
}>`
  height: 55px;
  width: ${({ width }) => width};
  color: ${colors.black} !important;
  box-sizing: border-box;
  outline: 2px solid ${colors.grey};
  outline: ${({ disabled, $hasBlackBackground }) => {
    if ($hasBlackBackground) {
      return disabled
        ? `2px solid ${colors.black}`
        : `2px solid ${colors.grey}`;
    }

    return disabled ? `2px solid ${colors.grey}` : `2px solid ${colors.black}`;
  }};
  background-color: ${({ disabled }) =>
    disabled ? colors.lightGrey : colors.white};
  padding: 0 15px;
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  ${mixins.flexBetween};
  margin: 2px;
  text-decoration: none;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  &:hover {
    outline: ${({ disabled, $hasBlackBackground }) => {
      if ($hasBlackBackground) {
        return disabled
          ? `2px solid ${colors.black}`
          : `3px solid ${colors.grey}`;
      }

      return disabled
        ? `2px solid ${colors.grey}`
        : `3px solid ${colors.black}`;
    }};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
  ${media.sm`
      margin: 10px 0;
    `}
  ${media.xxl`
      font-size: ${fontSizes.m};
      padding: 0 10px;
  `}
  ${media.xl`
    height: 45px;
    font-size: ${fontSizes.s};
  `}
`;

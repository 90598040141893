import theme from 'theme';
import { DotsIcon, TagContainer } from './styled';
import { useText } from 'hooks';

export const NotStartedTag = (): JSX.Element => {
  const getText = useText();

  return (
    <TagContainer $backgroundColor={theme.colors.darkGrey}>
      <DotsIcon />
      {getText('not_started')}
    </TagContainer>
  );
};

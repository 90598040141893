import FatigueManagerMobile from 'components/views/Protected/Jobs/FatigueManager/FatigueManagerMobile';
import FatigueManagerDesktop from 'components/views/Protected/Jobs/FatigueManager/FatigueManagerDesktop';
import { useIsMobile } from 'hooks';

const FatigueManager = (): JSX.Element => {
  const isMobile = useIsMobile();
  return isMobile ? <FatigueManagerMobile /> : <FatigueManagerDesktop />;
};

export default FatigueManager;

import { useSelector } from 'react-redux';
import { selectWorkspaceHasSettingEnabled } from 'store/selectors';
import { IconContainer, TickIcon, WarningIcon } from './styled';
import { okValue, QualityIndicator } from 'model/Media/Video';

type Props = {
  qualityIndicator: QualityIndicator | null;
};

export const QualityWarningTag = ({
  qualityIndicator,
}: Props): JSX.Element | null => {
  const isVideoQualityEnabled = useSelector(
    selectWorkspaceHasSettingEnabled('video_quality'),
  );

  const hasWarning: boolean =
    !!qualityIndicator &&
    Object.values(qualityIndicator).some(
      (indicatorValue) => indicatorValue && indicatorValue !== okValue,
    );

  if (!isVideoQualityEnabled || !qualityIndicator) {
    return null;
  }

  return (
    <IconContainer $isDark={hasWarning}>
      {hasWarning ? <WarningIcon /> : <TickIcon />}
    </IconContainer>
  );
};

import { Separator } from 'components/ui/Misc';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, mixins } = theme;

export const Container = styled.div`
  height: 80vh;
  display: flex;
  ${mixins.flexColumn}
  justify-content: space-between;
`;

export const SectionsWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  background-color: ${colors.white};
`;

export const ModalTitle = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
`;

export const Title = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.l};
  color: ${colors.red};
`;

export const FormWrapper = styled.div`
  height: 100%;
  ${mixins.flexColumn};
  justify-content: space-between;
  overflow-y: scroll;
`;

export const PaddedContainer = styled.div`
  ${mixins.flexColumn}
  padding: 20px;
  row-gap: 20px;
`;

export const FormSections = styled.div`
  ${mixins.flexColumn}
  justify-content: space-between;
  padding: 20px;
  row-gap: 40px;
`;

export const NotLastSeparator = styled(Separator)`
  &:last-child {
    display: none;
  }
`;

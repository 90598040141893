import { Container, SectionTitle } from './styled';
import { SignedOnUser } from './SignedOnUser';
import { getTimeSince } from 'helpers/dates';
import { User } from 'model/User';

type Props = {
  users: User[];
  title: string;
  keyPrefix: string;
  marginTop?: number;
};

export const SignOnSection = ({
  users,
  title,
  keyPrefix,
  marginTop = 0,
}: Props): JSX.Element => (
  <Container $marginTop={marginTop}>
    <SectionTitle>{title}</SectionTitle>
    {users.map((user, index) => (
      <SignedOnUser
        user={user}
        key={`${keyPrefix}-${index + 1}`}
        notificationTime={
          user.notifiedAt ? getTimeSince(user.notifiedAt) : undefined
        }
      />
    ))}
  </Container>
);

import { Reason } from './constants';
import { ReasonOption, TickIcon } from './styled';

type Props = {
  reason: Reason;
  onClick: () => void;
  selected: boolean;
};

export const FeedbackReasonItem = ({
  reason,
  onClick,
  selected,
}: Props): JSX.Element => (
  <ReasonOption onClick={onClick} selected={selected}>
    {selected && <TickIcon />}
    {reason.value}
  </ReasonOption>
);

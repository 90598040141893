export type SiteBriefing = {
  id: number;
  jobId: number;
  address: string;
  createdAt: string;
  sentAt: string;
  weather: Weather | null;
  trafficInsights: string[];
  locationInsights: LocationInsight[];
  cableStrikesLikely: boolean;
  nearbyJobs: NearbyJob[] | null;
};

export type LocationInsight = {
  label: string;
  roundedDistanceMetres: string | null;
};

export type Weather = {
  summary: string;
  icon: WeatherIconTypes;
};

export enum WeatherIconTypes {
  CLEAR_NIGHT = 'clear_night',
  CLOUD = 'cloud',
  COLD = 'cold',
  FLOOD = 'flood',
  FOG = 'fog',
  HAIL = 'hail',
  HEAT = 'heat',
  CLOUD_NIGHT = 'cloud_night',
  RAIN = 'rain',
  SNOW = 'snow',
  STORM = 'storm',
  SUN = 'sunny',
  WIND = 'wind',
}

export type NearbyJob = {
  id: number;
  distanceMiles: string;
  url: string;
  address: string;
  workorderItemNumber: string;
  jobType: string;
  createdAtDate: string;
  jobStatus: string;
  latestAssessmentHasMajorHazard: boolean;
};

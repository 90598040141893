export enum ForgotPasswordFlowTriggerStatus {
  ERR_INVALID = 'Password reset triggered with invalid user',
  ERR_SSO = 'SSO enabled for this organisation',
  ERR_THRESHOLD = 'Password reset threshold exceeded',
  SUCCESS = 'Forgot password flow triggered',
  ERR_OTHER = 'Other',
}

export type ForgotPasswordResponse = {
  status: ForgotPasswordFlowTriggerStatus;
};

import { Warning, YellowCheckMark } from 'assets/icons';
import { useText, useQueryParams } from 'hooks';
import { useSelector } from 'react-redux';
import theme from 'theme';
import * as Analytics from 'utils/analytics';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { Message } from 'model/Message';
import JobData from 'model/JobData';
import { MessageTypes } from './constants';

import { MessageWithAction } from './MessageWithAction';

import {
  BlackStatusContainer,
  Status,
  OrangeStatusContainer,
  SmallWarningIcon,
} from './styled';

const { colors } = theme;

type Props = {
  message: Message;
  activeJob: JobData;
  onClick: () => void;
};

export const Log = Analytics.withTrackEvent(
  ({ message, activeJob, onClick }: Props): JSX.Element => {
    const getText = useText();

    const { getPathWithQueryParams } = useQueryParams();
    const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

    const type: string = getText(
      message.target.type === MessageTypes.JOB_BLOCKER
        ? 'job_details_timeline_action_job_blocker'
        : 'job_details_timeline_action_note',
    );

    const subtype: string =
      message.target.type === MessageTypes.JOB_BLOCKER
        ? message.target.job_blocker?.[`title_${globalAppLanguage}`] ||
          message.target.job_blocker?.title
        : message.target[`title_${globalAppLanguage}`] || message.target.title;
    const to = getPathWithQueryParams(
      message.target.type === MessageTypes.JOB_BLOCKER
        ? `/jobs/${activeJob.id}/blockers/${message.target.id}`
        : `/jobs/${activeJob.id}/logs/${message.target.id}`,
    );
    const resolutionRequired = message.target.resolution_required;
    const jobBlockerResolved = !!message.target.job_blocker_resolution;

    const renderStatus = () => {
      if (jobBlockerResolved) {
        return (
          <BlackStatusContainer>
            <YellowCheckMark />
            <Status>{getText('job_log_blocker_resolved')}</Status>
          </BlackStatusContainer>
        );
      }
      return (
        <OrangeStatusContainer>
          <SmallWarningIcon />
          <Status $textColor={colors.black}>
            {getText('job_log_blocker_requires_resolution')}
          </Status>
        </OrangeStatusContainer>
      );
    };

    return (
      <MessageWithAction
        message={message}
        onClick={onClick}
        to={to}
        type={type}
        subtype={subtype}
        actionIcon={<Warning />}
      >
        {resolutionRequired && renderStatus()}
      </MessageWithAction>
    );
  },
);

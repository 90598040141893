import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Analytics from 'utils/analytics';
import * as constants from 'utils/constants';
import { useEvidence, useText } from 'hooks';
import { FormattedDate, Loader, Media, Navbar, Badge } from 'components/ui';
import { Quote } from 'assets/icons';
import { useParams } from 'react-router-dom';
import { canSeeWrapUpSummary, selectJobsView } from 'store/selectors';
import { WrapUpInsights } from './WrapUpInsights';
import theme from 'theme';
import { UnorderedList } from 'components/ui/Lists/UnorderedList';

const Information = styled.div`
  overflow: scroll;
  flex: 1;
`;

const Header = styled.div`
  height: 70px;
  background-color: ${({ theme }) => theme.colors.offWhite};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 0 15px;
  }
`;

const Section = styled.div`
  padding: 20px 25px 40px 25px;
  border-bottom: 15px solid ${({ theme }) => theme.colors.offWhite};
  &:last-of-type {
    border: none;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 20px 15px 40px 15px;
  }
`;

export default (): JSX.Element => {
  const { jobId, evidenceId } = useParams();
  const { evidence, comments, media, isFetching } = useEvidence(evidenceId);
  const getText = useText();
  const view = useSelector(selectJobsView);
  const hasWrapUpSummary = useSelector(canSeeWrapUpSummary);

  const showInsights =
    hasWrapUpSummary &&
    evidence?.videos?.length &&
    evidence?.videos[0]?.summary;

  const formatTitle = (title) => {
    if (title.length > 30) {
      return `${title.substring(0, 30)}...`;
    }

    return title;
  };

  const trackImageDownload = () => {
    Analytics.trackEvent(constants.EVENT_EVIDENCE_DOWNLOAD_IMAGE, {
      evidenceId,
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    });
  };

  useEffect(() => {
    if (evidenceId && jobId && evidence) {
      Analytics.trackPage(constants.EVENT_PAGE_VIEW_EVIDENCE, {
        jobId,
        evidenceId,
        mediaPath: [
          evidence?.photos?.map((x) => x.path),
          evidence?.videos?.map((x) => x.path),
        ],
      });
    }
  }, [evidenceId, jobId, evidence]);

  return (
    <>
      <Navbar
        title={evidence && formatTitle(evidence.type)}
        defaultBackPath={`/jobs/${jobId}/evidence`}
        mobileOnly
      />

      {isFetching ? (
        <Loader />
      ) : (
        <>
          {media && (
            <Media
              kind='evidence'
              type={media.type}
              url={media.signedUrl}
              urls={media.signedUrls}
              height='425px'
              onDownload={trackImageDownload}
              item={evidence?.videos[0]}
            />
          )}
          {evidence && (
            <Information>
              <Header>
                <Badge.User user={evidence.createdBy} />

                <FormattedDate
                  date={evidence?.createdAt?.toString() ?? ''}
                  extraStyles={{ color: theme.colors.grey }}
                />
              </Header>
              <Section>
                <UnorderedList
                  items={comments}
                  title={getText('evidence_comments')}
                  IconComponent={Quote}
                  extraItemStyle={{ marginLeft: '40px' }}
                />
                {showInsights && <WrapUpInsights evidence={evidence} />}
              </Section>
            </Information>
          )}
        </>
      )}
    </>
  );
};

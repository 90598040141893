import { Navbar } from 'components/ui';
import { useText } from 'hooks';
import { H1, H4, MobileWrapper } from './styled';

export const CommandCenterMobile = (): JSX.Element => {
  const getText = useText();
  return (
    <>
      <Navbar home mobileOnly />
      <MobileWrapper>
        <H1>{getText('command_centre_title')}</H1>
        <H4>{getText('command_centre_only_desktop')}</H4>
      </MobileWrapper>
    </>
  );
};

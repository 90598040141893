import { useEffect, useState } from 'react';
import { Storage } from 'aws-amplify';
import { Photo } from 'model/Media/Photo';
import { Image } from './styled';

// RamsPhoto
export const RamsPhoto = ({ photo }: { photo: Photo }): JSX.Element => {
  const [thumbnailURL, setThumbnailURL] = useState<string>();

  useEffect(() => {
    (async () => {
      if (photo.thumbnail && !photo?.signedUrl) {
        const signedURL = await Storage.get(photo.thumbnail);
        setThumbnailURL(signedURL);
      }
    })();
  }, [photo]);

  return <Image src={photo.signedUrl || thumbnailURL} />;
};

import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
import { formatDateTime } from 'helpers/dates';
import { Comment } from 'model/Form';

const { colors, fonts, fontSizes } = theme;

const CommentSectionTitle = styled.div`
  color: ${colors.black};
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
`;

const CommentTitle = styled.div`
  color: ${colors.darkGrey};
  font-family: ${fonts.boldItalic};
  padding: 10px 0;
`;

const CommentRecordedText = styled.div`
  font-family: ${fonts.bold};
  padding-bottom: 15px;
`;

type Props = {
  comments?: Comment[];
  subTitle?: JSX.Element;
};

// ManagerComment
export const ManagerComment = ({
  comments = [],
  subTitle,
}: Props): JSX.Element | null => {
  const getText = useText();

  return comments?.length > 0 ? (
    <>
      <CommentSectionTitle>
        {getText('insights_forms_comments')}
      </CommentSectionTitle>
      {subTitle}
      {comments.map((comment, index) => (
        <div key={`comment-${index}`}>
          <CommentTitle key={`title-${index}`}>{comment.comment}</CommentTitle>
          <CommentRecordedText key={`recorded-${index}`}>{`${getText(
            'insights_forms_comment_recorded_by',
          )} ${comment?.createdBy?.fullName}, ${formatDateTime(
            comment.createdAt,
            getText('date_today'),
            getText('date_yesterday'),
          )}`}</CommentRecordedText>
        </div>
      ))}
    </>
  ) : null;
};

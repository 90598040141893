export const PageSource = {
  COMMAND_CENTER: 'command-center',
  CREATE_JOB: 'create-job',
  EDIT_JOB: 'edit-job',
  JOB_DETAILS_LEGACY: 'job-details-legacy-view',
  JOB_DETAILS_NEW: 'job-details-new-view',
};

export const MapInteractionType = {
  ZOOM: 'zoom',
  FULL_SCREEN: 'full-screen',
  PLACE_SEARCH: 'place-search',
};

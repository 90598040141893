import { Assessment, Assessments } from 'model/Assessment';
import {
  Hazard,
  HazardWithControl,
  SuggestedHazardWithControl,
} from 'model/Assessment/Hazard';
import { HazardsAndControlsTitle } from './HazardsAndControlsTitle';
import { useCommandCenter, useHazards, useQueryParams, useText } from 'hooks';
import { useSelector } from 'react-redux';
import {
  selectGlobalLanguageSetting,
  selectIsProjectEngineerAssessment,
  selectJobsView,
} from 'store/selectors';
import * as constants from 'utils/constants';
import { Button } from 'components/ui';
import { AllItemsAppliedTag } from './AllItemsAppliedTag';
import React from 'react';
import { ItemRowSelected } from './ItemRowSelected';
import { SuggestedControls } from './SuggestedControls';
import { UnorderedList } from 'components/ui/Lists/UnorderedList';
import { getLocalizedValue } from 'helpers/locales';
import { TITLE_PROPERTY_NAME } from '../constants';
import { MajorHazardTag } from 'components/ui/Tags/MajorHazardTag';
import { SignOn } from './SignOn';
import { Comments } from './Comments';
import { Section } from '../styled';
import { ActionButtonsWrapper, SuggestedHazardContainer } from './styled';
import { ComputerVisionTag } from '../ComputerVisionTag';
import { HazardSource } from './HazardSource';
import { RequestNewVra } from './RequestNewVra';

type Props = {
  assessment: Assessment;
};

export const GroupedHazardsAndControls = ({
  assessment,
}: Props): JSX.Element => {
  const getText = useText();
  const hazards = useHazards();
  const { getPathWithQueryParams } = useQueryParams();
  const { isCommandCenter, getPathToTimeline } = useCommandCenter();
  const projectEngineerAssessment = useSelector(
    selectIsProjectEngineerAssessment(assessment?.createdBy?.id),
  );
  const view = useSelector(selectJobsView);
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  return (
    <>
      <Section>
        <HazardsAndControlsTitle />
        <ComputerVisionTag
          hazardsWithControls={assessment.hazardsWithControls}
        />

        {isCommandCenter && (
          <Button.SecondaryLink
            event={constants.EVENT_VIEW_JOB_TIMELINE}
            text={getText('assessment_details_open_timeline')}
            extraStyles={{ marginTop: '15px' }}
            to={getPathToTimeline(assessment.jobId)}
            width='inherit'
          />
        )}

        {assessment.suggestedHazardsWithControls.map(
          (hazardWithControls: SuggestedHazardWithControl, index) => (
            <React.Fragment key={`suggested-hazard-with-controls-${index + 1}`}>
              <SuggestedHazardContainer>
                <ItemRowSelected item={hazardWithControls.hazard} width={100} />
                <SuggestedControls
                  controls={hazardWithControls.controls}
                  keyPrefix='control'
                />
              </SuggestedHazardContainer>
              {assessment.editable &&
                !assessment.isSignedOff &&
                hazardWithControls?.hazard?.mappedControls &&
                (Assessments.containsAllControls(
                  hazardWithControls?.controls,
                  hazardWithControls?.hazard?.mappedControls,
                ) ? (
                  <AllItemsAppliedTag
                    text={getText('assessment_details_all_controls_applied')}
                  />
                ) : (
                  <Button.SecondaryLink
                    event={
                      projectEngineerAssessment
                        ? constants.EVENT_VIEW_ADD_CONTROL
                        : constants.EVENT_VIEW_SUGGEST_CONTROL
                    }
                    eventProperties={{
                      assessmentId: assessment.id,
                      jobId: assessment.jobId,
                      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                    }}
                    text={getText(
                      projectEngineerAssessment
                        ? 'assessment_details_edit_controls'
                        : 'assessment_details_suggest_control',
                    )}
                    extraStyles={{ marginTop: '15px' }}
                    to={getPathWithQueryParams(
                      `hazards-with-controls/${hazardWithControls.hazard.id}/controls`,
                    )}
                    state={{
                      suggested_controls: hazardWithControls?.controls ?? [],
                    }}
                    width='inherit'
                  />
                ))}
            </React.Fragment>
          ),
        )}

        {assessment.hazardsWithControls.map(
          (hazardWithControls: HazardWithControl, index) => (
            <div
              style={{ margin: '20px 0' }}
              key={`hazard-with-controls-${index + 1}`}
            >
              <UnorderedList
                items={hazardWithControls.controls}
                title={getLocalizedValue(
                  hazardWithControls.hazard,
                  TITLE_PROPERTY_NAME,
                  globalAppLanguage,
                )}
                extraItemStyle={{ marginLeft: '40px' }}
                needEmptyIcon={
                  !hazardWithControls.suggestedControls ||
                  hazardWithControls.suggestedControls?.length === 0
                }
              >
                <>
                  <HazardSource hazardWithControls={hazardWithControls} />
                  {hazardWithControls.hazard.isMajor && (
                    <MajorHazardTag
                      text={getText('assessment_details_major_hazard')}
                      fitContent
                      extraStyles={{
                        marginBottom: '5px',
                      }}
                    />
                  )}
                </>
              </UnorderedList>
              {hazardWithControls.suggestedControls && (
                <SuggestedControls
                  controls={hazardWithControls.suggestedControls}
                  keyPrefix='suggested-control'
                />
              )}
              {assessment.editable &&
                !assessment.isSignedOff &&
                hazardWithControls?.hazard?.mappedControls &&
                (Assessments.containsAllControls(
                  hazardWithControls?.controls.concat(
                    hazardWithControls?.suggestedControls ?? [],
                  ),
                  hazardWithControls?.hazard?.mappedControls,
                ) ? (
                  <AllItemsAppliedTag
                    text={getText('assessment_details_all_controls_applied')}
                  />
                ) : (
                  <Button.SecondaryLink
                    event={
                      projectEngineerAssessment
                        ? constants.EVENT_VIEW_ADD_CONTROL
                        : constants.EVENT_VIEW_SUGGEST_CONTROL
                    }
                    eventProperties={{
                      assessmentId: assessment.id,
                      jobId: assessment.jobId,
                      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                    }}
                    text={getText(
                      projectEngineerAssessment
                        ? 'assessment_details_edit_controls'
                        : 'assessment_details_suggest_control',
                    )}
                    extraStyles={{ marginTop: '15px' }}
                    to={getPathWithQueryParams(
                      `hazards-with-controls/${hazardWithControls.hazard.id}/controls`,
                    )}
                    state={{
                      suggested_controls: [],
                    }}
                    width='inherit'
                  />
                ))}
            </div>
          ),
        )}

        {assessment.editable && !assessment.isSignedOff && (
          <ActionButtonsWrapper>
            <>
              {Assessments.containsAllHazards(
                assessment?.hazardsWithControls
                  .map(
                    (hazardWithControls: HazardWithControl) =>
                      hazardWithControls?.hazard,
                  )
                  .concat(
                    assessment?.suggestedHazardsWithControls.map(
                      (
                        suggestedHazardWithControls: SuggestedHazardWithControl,
                      ) => suggestedHazardWithControls?.hazard,
                    ),
                  ),
                hazards?.filter((item: Hazard) =>
                  item?.assessmentTypeList?.includes(assessment.typeId),
                ),
              ) ? (
                <AllItemsAppliedTag
                  text={getText('assessment_details_all_hazards_applied')}
                />
              ) : (
                <Button.SecondaryLink
                  event={
                    projectEngineerAssessment
                      ? constants.EVENT_VIEW_ADD_HAZARD
                      : constants.EVENT_VIEW_SUGGEST_HAZARD
                  }
                  eventProperties={{
                    assessmentId: assessment.id,
                    jobId: assessment.jobId,
                    [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                  }}
                  text={getText(
                    projectEngineerAssessment
                      ? 'assessment_details_edit_hazards'
                      : 'assessment_details_suggest_another_hazard',
                  )}
                  to={getPathWithQueryParams(`hazards`)}
                />
              )}
            </>
            <RequestNewVra assessment={assessment} />
          </ActionButtonsWrapper>
        )}
      </Section>

      <Comments
        comments={assessment.additionalComments}
        title={getText('assessment_details_other_conditions_controls')}
      />

      <SignOn assessment={assessment} />
    </>
  );
};

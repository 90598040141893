import styled from 'styled-components';
import { useJobs, useText } from 'hooks';
import { Modal } from 'components/ui';
import TeamEdit from './TeamEdit';
import { useParams } from 'react-router-dom';

// Styled Components
const Container = styled.div`
  height: 650px;
  padding-bottom: 20px;
  overflow: auto;
`;

// DetailsModal
const DetailsModal = () => {
  const { jobId } = useParams();
  const getText = useText();
  const { navigateToOverviewTabOrJob } = useJobs();

  return (
    <Modal.Base
      isOpen
      title={getText('job_detail_overview_edit_team_members')}
      onClose={() => navigateToOverviewTabOrJob(`/jobs/${jobId}/team-members`)}
      width='850px'
      isCentered
    >
      <Container>
        <TeamEdit />
      </Container>
    </Modal.Base>
  );
};

export default DetailsModal;

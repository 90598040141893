import { useMemo } from 'react';
import { useIsMobile, useSafetyPrediction } from 'hooks';
import JobData from 'model/JobData';
import Coordinate from 'model/Coordinate';
import { useSelector } from 'react-redux';
import { Map } from '..';
import RootState from 'model/State/RootState';
import { hasMapCoordinates } from './helpers';
import {
  getLegacyCoordinatesFromJob,
  getLegacyMapMarkerIcon,
} from 'helpers/map';
import { PageSource } from '../Map/constants';

type JobMapProps = { job: JobData | null };

export const JobMap = ({ job }: JobMapProps) => {
  const isMobile = useIsMobile();
  const { mapCanLoad } = useSelector((state: RootState) => state.jobs);
  const { getRiskWarning } = useSafetyPrediction();

  const mapCoordinates: Coordinate[] | null = useMemo(() => {
    if (job && mapCanLoad && hasMapCoordinates(job)) {
      return [getLegacyCoordinatesFromJob(job, getRiskWarning)];
    }

    return null;
  }, [job, mapCanLoad, getRiskWarning]);

  return (
    mapCoordinates && (
      <Map
        id='job-overview-map'
        coordinates={mapCoordinates}
        height={isMobile ? '155px' : '260px'}
        pageSource={PageSource.JOB_DETAILS_LEGACY}
        getMapMarker={getLegacyMapMarkerIcon}
      />
    )
  );
};

import styled from 'styled-components';
import theme from 'theme';
import { LegacyMapViewIcon, List, MarkerIcon } from 'assets/icons';
import { Button } from '@mui/material';

const { colors, mixins, media } = theme;

export const TabContainer = styled.div`
  ${mixins.flexFullCenter};
  margin-bottom: -2px;
`;

export const Tab = styled(({ isActive, collapseBorders = false, ...props }) => (
  <Button disabled={isActive} {...props} />
))`
  &.MuiButtonBase-root {
    padding: 0;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    ${mixins.flexFullCenter};
    background-color: ${colors.white};
    border: 2px solid ${colors.black};
    border-radius: 0;
    text-align: center;
    ${({ collapseBorders }) =>
      collapseBorders &&
      `
      margin: 0 -2px 0 -2px;
    `};
    ${({ isActive }) =>
      isActive &&
      `
      background-color: ${colors.black};
      svg .fill-selector {
        fill: ${colors.white};
        stroke: ${colors.white};
      }
    `};
    text-decoration: none;
    &:hover {
      background-color: ${colors.white};
    }
    ${media.xl`
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
    `}
  }
`;

export const ListView = styled(List)`
  width: 18px;
  height: 18px;
  ${media.xl`
    width: 14px;
    height: 14px;
  `}
`;

export const MapView = styled(MarkerIcon)`
  width: 20px;
  height: 20px;
  ${media.xl`
    width: 15px;
    height: 15px;
  `}
`;

export const LegacyMapView = styled(LegacyMapViewIcon)`
  width: 17px;
  height: 17px;
  ${media.xl`
    width: 14px;
    height: 14px;
  `}
`;

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalLanguageSetting, selectJobsView } from 'store/selectors';
import { InformationLabel } from 'components/ui/Shared/EmptyState';
import { Card, Loader } from 'components/ui';
import { useEffect } from 'react';
import { useText } from 'hooks';
import OverviewTabBase from './OverviewTabBase';
import { Document as FormIcon } from 'assets/icons';
import {
  fetchJobForms,
  selectJobForms,
  selectJobFormsIsLoading,
} from 'store/slices/forms';
import { ItemContainer } from './styled';
import {
  EVENT_PROPERTIES_PAGE_SOURCE_FIELD,
  EVENT_VIEWED_JOB_FORMS_LIST,
  EVENT_VIEW_FORMS_ITEM,
} from 'utils/constants';
import * as Analytics from 'utils/analytics';
import { mapToUser } from 'api/User/helpers';
import { DownloadType } from '../../PdfExport/constants';
import { CustomFormClass } from 'model/Form';

export const Forms = (): JSX.Element | null => {
  const { jobId } = useParams();
  const forms = useSelector(selectJobForms(jobId));
  const isLoading = useSelector(selectJobFormsIsLoading(jobId));
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const dispatch = useDispatch();
  const getText = useText();
  const view = useSelector(selectJobsView);

  useEffect(() => {
    if (jobId) {
      dispatch(fetchJobForms(jobId));
    }
  }, [dispatch, jobId]);

  useEffect(() => {
    if (forms) {
      Analytics.trackPage(EVENT_VIEWED_JOB_FORMS_LIST, {
        jobId,
        formIds: forms.map((form) => form.id),
        formTypeIds: forms.map((form) => form.type?.id),
        formTypes: forms.map((form) => form.type?.title),
        [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, view, JSON.stringify(forms)]);

  return (
    <OverviewTabBase title={getText('tabs_forms')}>
      <>
        {isLoading ? (
          <Loader />
        ) : !forms?.length ? (
          <InformationLabel>{getText('forms_no_display')}</InformationLabel>
        ) : (
          <ItemContainer>
            {forms?.map((form) => (
              <Card.JobDetail
                key={`form-${form.id}`}
                title={
                  form.type
                    ? form.type[`title_${globalAppLanguage}`] || form.type.title
                    : getText('form')
                }
                creator={mapToUser(form.created_by)}
                date={form.created_at}
                to={`/jobs/${form.job_id}/job-forms/${form.id}`}
                IconComponent={FormIcon}
                jobId={jobId}
                downloadItemId={form.id}
                downloadType={DownloadType.FORM}
                formClass={CustomFormClass.JOB}
                event={EVENT_VIEW_FORMS_ITEM}
                eventProperties={{
                  jobId: form.job_id,
                  formId: form.id,
                  formTypeId: form.type?.id,
                  formType: form.type?.title,
                  [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
                }}
              />
            ))}
          </ItemContainer>
        )}
      </>
    </OverviewTabBase>
  );
};

import styled from 'styled-components';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import theme from 'theme';

const { colors } = theme;

export const StyledDateTimePicker = styled(DateTimePicker)<{
  disabled: boolean;
  open: boolean;
}>`
  .MuiOutlinedInput-root {
    color: ${({ disabled }) => (disabled ? colors.darkGrey : colors.black)};
    border-radius: 0;
    border: 0;
    outline: ${({ disabled, open }) => {
      if (disabled) {
        return `2px solid ${colors.grey}`;
      }
      if (open) {
        return `3px solid ${colors.orange}`;
      }
      return `2px solid ${colors.black}`;
    }};
    &:hover {
      box-shadow: none;
      border: 0;
      outline: 3px solid
        ${({ disabled, open }) => {
          if (disabled) {
            return colors.grey;
          }
          if (open) {
            return colors.orange;
          }
          return colors.black;
        }};
      background-color: ${({ disabled }) =>
        disabled ? colors.offWhite : colors.white};
      transition: ease-out 0.2s;
    }
    &:focus-within,
    &:focus {
      outline: 3px solid
        ${({ disabled }) => {
          if (disabled) {
            return colors.grey;
          }
          return colors.orange;
        }} !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
  .MuiOutlinedInput-root.Mui-disabled,
  .MuiOutlinedInput-input.Mui-disabled {
    cursor: not-allowed;
  }
`;

import { Filters } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';

const { colors, fonts, fontSizes, media, mixins } = theme;

export const H1 = styled.h1`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  margin-bottom: 8px !important;
`;

export const H4 = styled.h4`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.default};
`;

export const MobileWrapper = styled.div`
  padding: 40px 0 0;
  height: 100%;
  position: relative;
  ${media.sm`
    padding: 30px;
 `}
`;
export const DesktopWrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${colors.black};
  border-top: 1px solid ${colors.grey};
  overflow: auto;
`;

export const Header = styled.div`
  ${mixins.flexBetween};
  padding: 10px 40px;
  ${media.xl`
    padding: 10px 30px;
 `}
  ${media.md`
    padding: 10px 20px;
 `}
`;

export const HeaderTitle = styled.div`
  ${mixins.flexColumn};
`;

export const Title = styled.p`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xxxl};
  color: ${colors.white};

  ${media.xl`
    font-size: ${fontSizes.xl};
 `}
  ${media.lg`
    font-size: ${fontSizes.l};
 `}
`;

export const SubTitle = styled.p`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  color: ${colors.grey};

  ${media.xl`
    font-size: ${fontSizes.m};
 `}
  ${media.lg`
    font-size: ${fontSizes.default};
 `}
`;

export const FilterIcon = styled(Filters)`
  .fill-selector {
    fill: ${colors.white};
  }
`;

export const ContentWrapper = styled.div`
  ${mixins.flexBetween};
  flex-direction: row;
  padding: 25px 40px;
  height: 100%;
  ${media.xl`
    padding: 20px 30px;
 `}
  ${media.md`
    padding: 20px;
 `}
`;

export const BoxContainer = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 30px;
  ${media.md`
    padding-right: 20px;
 `}
`;

export const BoxRowContainer = styled.div<{ $noMargin?: boolean }>`
  display: flex;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? 0 : '25px')};
  justify-content: space-between;
`;

export const LatestActivityContainer = styled.div`
  padding-bottom: 80px;
  ${media.xxl`
    padding-bottom: 35px;
 `}
  ${media.md`
    padding-bottom: 30px;
 `}
`;

export const StatsTitle = styled.div`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.m};
  margin-bottom: 8px !important;
  color: ${colors.yellow};
  ${media.xl`
    font-size: ${fontSizes.s};
 `}
`;

export const MapContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
`;

import { GridSortItem } from '@mui/x-data-grid';
import { SortOrder } from 'model/enum/SortOrder';
import Sort from 'model/Sort';

export const getSortModel = (
  currentSort: Sort,
  newSort: GridSortItem,
): Sort => {
  if (newSort) {
    return { field: newSort?.field, order: newSort?.sort as SortOrder };
  } else {
    return currentSort;
  }
};

export const scrollToTop = () => {
  document?.querySelector('.MuiDataGrid-virtualScroller')?.scrollTo({ top: 0 });
};

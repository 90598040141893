import React, { useEffect, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { selectGlobalLanguageSetting } from 'store/selectors';
import theme from 'theme';
import { useText } from 'hooks';
import { useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material';
import { InfoModal } from '../../Modal';
import {
  GuidanceWrapper,
  Label,
  RequiredLabel,
  SelectValue,
  StyledInfoIcon,
  StyledOptions,
  StyledSelect,
} from './styled';
import { Container, LabelWrapper } from '../styled';

const { colors } = theme;

type OptionPropTypes = {
  id: number | null;
  title: string;
};

type Props = {
  id: string;
  label?: string;
  labelColor?: string;
  defaultSelected?: OptionPropTypes;
  selected?: OptionPropTypes | null;
  options?: OptionPropTypes[];
  hint?: string;
  onChange?: (event: SelectChangeEvent<unknown>) => void;
  disabled?: boolean;
  fitContent?: boolean;
  large?: boolean;
  grey?: boolean;
  noBorder?: boolean;
  required?: boolean;
  isInsights?: boolean;
  isLocation?: boolean;
  extraStyles?: CSSProperties;
  addButton?: JSX.Element;
  onAddButtonClick?: () => void;
  infoMessage?: string;
  isFocused?: boolean;
  guidance?: string;
  isLoading?: boolean;
};

// Input.Select
export const Select = ({
  id,
  label,
  labelColor,
  defaultSelected,
  selected = null,
  options,
  hint,
  onChange = () => {},
  disabled = false,
  fitContent = false,
  large = false,
  grey = false,
  noBorder = false,
  required = false,
  isInsights = false,
  isLocation = false,
  extraStyles,
  addButton,
  onAddButtonClick = () => {},
  infoMessage,
  isFocused = false,
  guidance,
  isLoading,
}: Props): JSX.Element => {
  const getText = useText();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const [showInfoMessage, setShowInfoMessage] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(isFocused);
  const getOption = (option) => {
    let optionText = '';
    if (isInsights) {
      optionText = `${
        option[`type_title_${globalAppLanguage}`] || option.type_title
      } - ${option[`title_${globalAppLanguage}`] || option.title}`;
    } else {
      optionText = option[`title_${globalAppLanguage}`] || option.title;
    }
    return optionText;
  };
  const [selectedValue, setSelectedValue] =
    React.useState<OptionPropTypes | null>(selected);

  const onValueChange = (e: SelectChangeEvent) => {
    const newOption = options?.find(
      (option) => option.id === (e?.target?.value as unknown as number),
    );
    if (newOption) {
      setSelectedValue(newOption);
    }
    onChange(e);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setSelectedValue(selected);
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container style={extraStyles}>
      {label && (
        <LabelWrapper>
          <Label
            htmlFor={id}
            color={labelColor}
            $large={large}
            disabled={disabled}
            $isLocation={isLocation}
          >
            {label}
          </Label>
          {infoMessage && (
            <StyledInfoIcon onClick={() => setShowInfoMessage(true)} />
          )}
          {required && (
            <RequiredLabel htmlFor={id}>{getText('required')}</RequiredLabel>
          )}
        </LabelWrapper>
      )}
      {guidance && <GuidanceWrapper>{guidance}</GuidanceWrapper>}

      <StyledSelect
        defaultValue={defaultSelected?.id ?? ''}
        value={isLoading ? '' : (selectedValue?.id ?? '')}
        displayEmpty
        renderValue={() => {
          if (isLoading) {
            return '';
          }
          return (
            <SelectValue>
              {selectedValue ? getOption(selectedValue) : hint}
            </SelectValue>
          );
        }}
        onChange={onValueChange}
        disabled={disabled || isLoading}
        $large={large}
        grey={grey}
        noBorder={noBorder}
        MenuProps={{
          sx: {
            '& .MuiMenu-list': {
              padding: 0,
              maxHeight: '300px',
            },
            '& .MuiPopover-paper': {
              maxWidth: '425px',
            },
            '& ::-webkit-scrollbar': {
              WebkitAppearance: 'none',
              width: '7px',
            },
            '& ::-webkit-scrollbar-thumb': {
              backgroundColor: colors.grey,
              WebkitBoxShadow: '0 0 1px rgba(255, 255, 255, 0.5)',
            },
          },
        }}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        autoFocus={isFocused}
      >
        {options?.map((option) => (
          <StyledOptions
            key={option.id}
            value={option.id ?? undefined}
            fitcontent={fitContent}
            $large={large}
          >
            {getOption(option)}
          </StyledOptions>
        ))}
        {addButton && (
          <StyledOptions onClick={onAddButtonClick}>{addButton}</StyledOptions>
        )}
      </StyledSelect>
      {showInfoMessage && (
        <InfoModal
          isVisible={showInfoMessage}
          setVisible={setShowInfoMessage}
          infoMessage={infoMessage}
        />
      )}
    </Container>
  );
};

import { useEffect, useRef, useState } from 'react';
import * as constants from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssessment } from 'store/actions/assessments';
import { useHazards, useControls, useIsMobile } from 'hooks';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Loader } from 'components/ui';
import SuggestBase from './SuggestBase';
import AddBase from './AddBase';
import { Assessment, AssessmentItemType } from 'model/Assessment';
import RootState from 'model/State/RootState';
import { Control } from 'model/Assessment/Control';

type Props = {
  assessment?: Assessment | null;
  projectEngineerAssessment?: boolean;
};

const SuggestControl = ({
  assessment = null,
  projectEngineerAssessment = false,
}: Props): JSX.Element => {
  const controls = useControls();
  const hazards = useHazards();
  const mappedControls = useRef() as React.MutableRefObject<Control[]>;
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { assessmentId, hazardId } = useParams();
  const { currentUser, isProjectEngineer } = useSelector(
    (state: RootState) => state.session,
  );
  const location = useLocation();
  const mobileAssessment = useSelector(
    (state: RootState) => state.assessments.activeAssessment,
  );
  const [projectEngineerMobile, setProjectEngineerMobile] =
    useState<boolean>(false);
  const [selectedControls] = useState<Control[]>(
    location.state?.suggested_controls ?? [],
  );

  useEffect(() => {
    if (!assessment) {
      dispatch(fetchAssessment(assessmentId));
    }
  }, [assessmentId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMobile) {
      setProjectEngineerMobile(
        isProjectEngineer &&
          !!currentUser &&
          !!mobileAssessment &&
          mobileAssessment.createdBy.id === currentUser.id,
      );
    }
  }, [mobileAssessment]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(!!hazardId);
    if (hazardId && hazards?.length > 0) {
      const hazard = hazards?.find(
        (item) => item.id === parseInt(hazardId, 10),
      );
      if (hazard?.mappedControls) {
        mappedControls.current = hazard.mappedControls;
      }
      setIsLoading(false);
    }
  }, [hazardId, hazards]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderBase = () => {
    if (projectEngineerAssessment || projectEngineerMobile) {
      return (
        <AddBase
          event={constants.EVENT_ADD_CONTROLS}
          items={
            hazardId
              ? mappedControls.current
              : controls?.filter(
                  (item) =>
                    assessment &&
                    item?.assessmentTypeList?.includes(assessment.typeId),
                )
          }
          suggestedItems={selectedControls}
          assessment={assessment ?? mobileAssessment}
          addType={AssessmentItemType.CONTROL}
          selectedId={parseInt(hazardId ?? '', 10)}
        />
      );
    }
    return (
      <SuggestBase
        event={constants.EVENT_SUGGEST_CONTROLS}
        items={
          hazardId
            ? mappedControls.current
            : controls?.filter(
                (item) =>
                  assessment &&
                  item?.assessmentTypeList?.includes(assessment.typeId),
              )
        }
        suggestedItems={selectedControls}
        assessment={assessment ?? mobileAssessment}
        suggestionType={AssessmentItemType.CONTROL}
        selectedId={parseInt(hazardId ?? '', 10)}
      />
    );
  };

  return isLoading ? <Loader /> : renderBase();
};

export default SuggestControl;

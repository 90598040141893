import { Badge } from 'components/ui';
import { useSelector } from 'react-redux';
import RootState from 'model/State/RootState';
import { Message } from 'model/Message';
import { ActionBox } from './ActionBox';
import { CreationDate } from './CreationDate';
import { Container } from './styled';

type Props = {
  message: Message;
  onClick: () => void;
  to: string;
  type: string | JSX.Element;
  subtype?: string | JSX.Element;
  date?: string;
  actionIcon: JSX.Element;
  viewIcon?: JSX.Element;
  isLocked?: boolean;
  children?: any;
};

export const MessageWithAction = ({
  message,
  onClick,
  to,
  type,
  subtype,
  date,
  actionIcon,
  viewIcon,
  isLocked,
  children,
}: Props): JSX.Element => {
  const { currentUser } = useSelector((state: RootState) => state.session);
  const sender = message.target.created_by;

  return (
    <Container onClick={onClick} id={String(message.id)}>
      {sender ? (
        <Badge.UserBadge
          user={sender}
          isCurrentUser={currentUser?.id === sender?.id}
        />
      ) : (
        <Badge.FyldBadge />
      )}
      <ActionBox
        to={to}
        type={type}
        subtype={subtype}
        actionIcon={actionIcon}
        viewIcon={viewIcon}
        isLocked={isLocked}
      >
        {children}
      </ActionBox>
      <CreationDate date={date ?? message.target.created_at} />
    </Container>
  );
};

import { useText } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { SearchHazard } from '../SearchHazard';
import { HazardList } from './HazardList';
import RootState from 'model/State/RootState';
import { SectionTitle } from './styled';
import { RamsStages } from 'model/Assessment/RAMS';
import { Button } from 'components/ui';
import { EVENT_RAMS_SUMMARY_RATE_HAZARDS_BUTTON_CLICKED } from '../constants';
import { EVENT_PROPERTIES_PAGE_SOURCE_FIELD } from 'utils/constants';
import { selectJobsView } from 'store/selectors';
import {
  selectActiveRamsAssessment,
  setAssessmentStage,
} from 'store/slices/ramsAssessments';
import { HazardsTag } from '../HazardsTag';
import {
  ActionButtons,
  ActionButtonsWrapper,
  HazardStepContentContainer,
} from '../styled';

// HazardIdentification
export const HazardIdentification = (): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const { selectedHazards } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );
  const assessment = useSelector(selectActiveRamsAssessment);
  const view = useSelector(selectJobsView);

  return (
    <>
      <HazardStepContentContainer>
        <HazardsTag text={getText('rams_summary_hazard_added')} />
        <div>
          <div>
            <SectionTitle>
              {getText('job_hazard_identification_add_hazard')}
            </SectionTitle>
            <HazardList hazards={assessment?.hazards ?? []} />
          </div>
          <div>
            <SectionTitle>
              {getText('job_hazard_identification_add__further_hazard')}
            </SectionTitle>
            <SearchHazard />
          </div>
        </div>
      </HazardStepContentContainer>
      <ActionButtonsWrapper>
        <ActionButtons>
          <Button.Primary
            onClick={() =>
              dispatch(setAssessmentStage(RamsStages.hazardRating))
            }
            text={getText('rams_summary_rate_hazards_button')}
            disabled={selectedHazards.length === 0 || !assessment?.editable}
            event={EVENT_RAMS_SUMMARY_RATE_HAZARDS_BUTTON_CLICKED}
            eventProperties={{
              [EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            extraStyles={{
              margin: 0,
              alignSelf: 'flex-end',
              height: 'inherit',
              maxHeight: '55px',
            }}
          />
        </ActionButtons>
      </ActionButtonsWrapper>
    </>
  );
};

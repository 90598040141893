import { useSelector } from 'react-redux';
import { selectWorkspaceSettings } from 'store/selectors';
import {
  CrossIcon,
  MapPositioningContainer,
  MapPositioningContainerTitle,
} from './styled';
import { useIsMobile, useText } from 'hooks';
import { useState } from 'react';

type Props = {
  isOnMap?: boolean;
};

export const PinLocationHint = ({
  isOnMap = false,
}: Props): JSX.Element | null => {
  const getText = useText();
  const isMobile = useIsMobile();
  const { pin_location_enabled } = useSelector(selectWorkspaceSettings);
  const [isVisible, setVisible] = useState<boolean>(true);

  if (!pin_location_enabled || !isVisible) {
    return null;
  }

  return (
    <MapPositioningContainer
      $isMobile={isMobile}
      $isOnMap={isOnMap && !isMobile}
    >
      <MapPositioningContainerTitle>
        {getText('create_job_location_map_hint_title')}
        <CrossIcon $isMobile={isMobile} onClick={() => setVisible(false)} />
      </MapPositioningContainerTitle>
      {getText('create_job_location_map_hint_body')}
    </MapPositioningContainer>
  );
};

import { Languages } from 'utils/constants';
import { Button as MaterialButton } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';
import { useText } from 'hooks';
const { colors, media, fontSizes, fonts } = theme;

const Wrapper = styled.div`
  align-self: flex-end;
  display: flex;
  max-width: 40%;
  margin-bottom: 40px;
  ${media.lg`
  max-width: 50%;
`}
`;

// Styled Components
const Option = styled(({ isSelected, ...rest }) => (
  <MaterialButton {...rest} />
))`
  &.MuiButtonBase-root {
    width: 300px;
    color: ${colors.white};
    ${({ isSelected }) => isSelected && `background-color: ${colors.blue};`}
    height: ${fontSizes.xxl};
    box-sizing: border-box;
    border-radius: 0;
    border: ${({ isSelected }) =>
      isSelected ? 'none' : `2px solid ${colors.offWhite}`};
    font-family: ${fonts.default};
    font-size: ${fontSizes.s};
    text-transform: none;
    letter-spacing: 0;
    &:hover {
      border: 3px solid ${colors.white};
    }
    ${media.sm`
        margin: 10px 0;
      `}
  }
`;

export const LanguageSwitchToggle = ({
  activeLanguageCode,
  setActiveLanguageCode,
}) => {
  const getText = useText();
  const options = {
    ENGLISH: {
      code: Languages.english.locale,
      label: getText(Languages.english.displayName.toLowerCase()),
    },
    SPANISH: {
      code: Languages.spanish.locale,
      label: getText(Languages.spanish.displayName.toLowerCase()),
    },
  };

  return (
    <Wrapper>
      <Option
        isSelected={activeLanguageCode === options.ENGLISH.code}
        onClick={() => setActiveLanguageCode(options.ENGLISH.code)}
      >
        {options.ENGLISH.label}
      </Option>
      <Option
        isSelected={activeLanguageCode === options.SPANISH.code}
        onClick={() => setActiveLanguageCode(options.SPANISH.code)}
      >
        {options.SPANISH.label}
      </Option>
    </Wrapper>
  );
};

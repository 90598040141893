import {
  CLEAR_FORM_OPTIONS,
  FETCH_FORM_OPTIONS,
  SET_FORM_OPTIONS,
} from 'store/actions/actionTypes';

const defaultState = {
  formOptions: null,
  isFetchingFormOptions: false,
};

const form = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_FORM_OPTIONS:
      return { ...state, isFetchingFormOptions: true };
    case SET_FORM_OPTIONS: {
      return {
        ...state,
        formOptions: action.formOptions,
        isFetchingFormOptions: false,
      };
    }
    case CLEAR_FORM_OPTIONS: {
      return {
        ...state,
        formOptions: null,
        isFetchingFormOptions: false,
      };
    }
    default:
      return state;
  }
};

export default form;

import { Badge, Card, FormattedDate } from 'components/ui';
import { useIsMounted, useJobs, useText } from 'hooks';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAssessment } from 'store/actions/assessments';
import * as constants from 'utils/constants';
import RootState from 'model/State/RootState';
import {
  selectJobsView,
  selectUserHasPermission,
  selectWorkspaceHasSettingEnabled,
} from 'store/selectors';
import { Assessment } from 'model/Assessment';
import { UploadStatus } from 'model/enum/UploadStatus';
import AssessmentSummary from '../AssessmentSummary';
import ModalCtaButton from '../ModalCtaButton';
import useCommandCenter from 'hooks/useCommandCenter';
import { useNavigate } from 'react-router-dom';
import { ModalFooter } from '../../PdfExport/ModalFooter';
import { Container, ContentWrapper, ErrorContainer, Header } from './styled';
import { WarningSection } from './components/WarningSection';
import { SeparatedHazardsAndControlsSection } from './components/SeparatedHazardsAndControlsSection';
import { GroupedHazardsAndControls } from './components/GroupedHazardsAndControls';
import theme from 'theme';
import { DownloadType } from '../../PdfExport/constants';

export const HazardsControlsComments = ({
  assessment,
}: {
  assessment: Assessment;
}): JSX.Element => {
  const getText = useText();
  const { isCommandCenter } = useCommandCenter();
  const { navigateToOverviewTabOrJob } = useJobs();
  const navigate = useNavigate();
  const { isManager } = useSelector((state: RootState) => state.session);
  const { isAssessmentUpdating: isLoading, assessmentError: errorMessage } =
    useSelector((state: RootState) => state.assessments);
  const vraSummaryEnabledForWorkspace = useSelector(
    selectWorkspaceHasSettingEnabled('enable_generated_vra_summary'),
  );
  const vraSummaryEnabledForUser = useSelector(
    selectUserHasPermission('show_generated_vra_summary_v1'),
  );
  const view = useSelector(selectJobsView);

  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const isMounted = useIsMounted();

  const isNotUploaded =
    assessment?.video?.status === UploadStatus.NEW &&
    !assessment.typeIsRams &&
    !assessment.isReviewed;
  const isLocked =
    [UploadStatus.UPLOADED, UploadStatus.PROCESSED].includes(
      assessment.videoStatus || assessment.video?.status,
    ) && !assessment.typeIsRams;

  const showSummary =
    vraSummaryEnabledForWorkspace &&
    vraSummaryEnabledForUser &&
    assessment?.video?.summaryText;

  useEffect(() => {
    if (isSubmitted && !isLoading) {
      if (isCommandCenter) {
        navigate('..');
      } else {
        navigateToOverviewTabOrJob(`/jobs/${assessment.jobId}/assessments`);
      }
    }
  }, [isSubmitted, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRiskAssessmentSignOff = async () => {
    dispatch(updateAssessment(assessment.id, { signedOff: true }));

    if (isMounted) {
      setIsSubmitted(true);
    }
  };

  const renderSections = () => {
    if (assessment.hazardsWithControls?.length > 0) {
      return <GroupedHazardsAndControls assessment={assessment} />;
    }

    return <SeparatedHazardsAndControlsSection assessment={assessment} />;
  };

  const isSuggestionAdded =
    assessment.hazardsWithControls?.length > 0
      ? assessment.suggestedHazardsWithControls?.length > 0 ||
        assessment.hazardsWithControls.some(
          (hazard_with_control) =>
            hazard_with_control.suggestedControls &&
            hazard_with_control.suggestedControls?.length > 0,
        )
      : assessment.suggestedHazards?.length > 0 ||
        assessment.suggestedControls?.length > 0;

  return (
    <ContentWrapper>
      <Header>
        <Badge.User user={assessment?.createdBy} />
        <FormattedDate
          date={assessment?.createdAt?.toString() ?? ''}
          extraStyles={{ color: theme.colors.grey, textAlign: 'center' }}
        />
      </Header>
      <Container>
        {showSummary && assessment?.video && (
          <AssessmentSummary
            assessmentId={assessment?.id}
            jobId={assessment?.jobId}
            assessmentVideo={assessment?.video}
          />
        )}
        {errorMessage && (
          <ErrorContainer>
            <Card.ErrorMessage
              message={`${getText(
                'assessment_details_sign_off_error',
              )} ${errorMessage}`}
            />
          </ErrorContainer>
        )}

        {isSuggestionAdded && (
          <WarningSection text={getText('assessment_details_cannot_signoff')} />
        )}
        {isLocked && (
          <WarningSection
            text={getText('job_details_review_pending')}
            hasIcon
          />
        )}
        {renderSections()}
      </Container>
      <ModalFooter
        downloadType={DownloadType.ASSESSMENT}
        downloadItemId={assessment.id.toString()}
        jobId={assessment.jobId}
      />
      {assessment.signoffRequired && !assessment.isSignedOff && isManager && (
        <ModalCtaButton
          text={getText('assessment_edit_sign_off')}
          onClick={handleRiskAssessmentSignOff}
          loading={isLoading}
          disabled={
            !assessment.editable ||
            isSuggestionAdded ||
            isLocked ||
            isNotUploaded
          }
          event={constants.EVENT_RISK_ASSESSMENT_SIGNOFF}
          eventProperties={{
            assessmentId: assessment.id,
            jobId: assessment.jobId,
            [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
          }}
          extraStyles={{
            marginTop: 'auto',
          }}
        />
      )}
    </ContentWrapper>
  );
};

import styled from 'styled-components';

import theme from 'theme';

const { colors, fontSizes, fonts, breakpoints } = theme;

export const SloganWrapper = styled.h1`
  padding-top: 25px;
  color: ${colors.white};
  font-size: ${fontSizes.xxl};
  font-family: ${fonts.light};
  @media only screen and (max-width: ${breakpoints.sm}px) {
    font-size: ${fontSizes.l};
    padding-top: 5px;
  }
  > div {
    &:last-child {
      color: ${colors.yellow};
    }
  }
`;

export const Separator = styled.div<{ height?: string }>`
  height: ${({ height }) => height || '15px'};
  min-height: ${({ height }) => height || '15px'};
  background-color: ${colors.lightGrey};
`;

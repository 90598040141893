import { useText } from 'hooks';
import {
  AddMemberButtonWrapper,
  MembersIcon,
  NoMembersTitle,
  NoMembersWrapper,
  TagContainer,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { RamsStages } from 'model/Assessment/RAMS';
import { Button } from 'components/ui';
import { PlusIcon } from 'assets/icons';
import { InfoTag } from 'components/ui/Tags/InfoTag';
import {
  selectIsEditTagDismissed,
  selectUserCanEditRams,
  setAssessmentStage,
} from 'store/slices/ramsAssessments';
import { NotEditableTag } from '../NotEditableTag';

export const EmptyState = ({
  isMethodStatementSubmitted,
}: {
  isMethodStatementSubmitted: boolean;
}): JSX.Element => {
  const getText = useText();
  const dispatch = useDispatch();
  const canUserEdit = useSelector(selectUserCanEditRams);
  const isEditTagDismissed = useSelector(selectIsEditTagDismissed);

  return (
    <>
      <NoMembersWrapper
        large={
          (canUserEdit && isMethodStatementSubmitted) || isEditTagDismissed
        }
      >
        <MembersIcon />
        <NoMembersTitle>{getText('rams_sign_on_no_team_added')}</NoMembersTitle>
        <AddMemberButtonWrapper>
          <Button.Primary
            text={getText('rams_sign_on_add_team_members')}
            onClick={() =>
              dispatch(setAssessmentStage(RamsStages.signOnEditMembers))
            }
            IconComponent={PlusIcon}
            disabled={!isMethodStatementSubmitted || !canUserEdit}
            extraStyles={{ alignSelf: 'center' }}
          />
        </AddMemberButtonWrapper>
      </NoMembersWrapper>
      {!isMethodStatementSubmitted && canUserEdit && (
        <TagContainer>
          <InfoTag text={getText('rams_sign_on_adding_info')} />
        </TagContainer>
      )}
      {!canUserEdit && <NotEditableTag />}
    </>
  );
};

import { DeleteIcon } from 'assets/icons';
import { Button, Misc } from 'components/ui';
import * as constants from 'utils/constants';
import { useText } from 'hooks';
import JobData from 'model/JobData';

import Details from './Details';
import TeamDisplay from './TeamDisplay';

import { MobileSection } from '../styled';
import { useSelector } from 'react-redux';
import { selectJobsView } from 'store/selectors';

type JobOverviewMobileTabSectionParams = {
  jobId?: string;
  activeJob: JobData;
  isManager: boolean;
  isBackOffice: boolean;
  setShowDeleteJob: (showDeleteJob: boolean) => void;
};

export const JobOverviewMobileTabSection = ({
  jobId,
  activeJob,
  isManager,
  isBackOffice,
  setShowDeleteJob,
}: JobOverviewMobileTabSectionParams): JSX.Element => {
  const getText = useText();
  const view = useSelector(selectJobsView);

  return (
    <>
      <Misc.Separator />

      <MobileSection>
        <TeamDisplay members={activeJob.assignees} />

        {(activeJob.is_assigned || isManager || isBackOffice) && (
          <Button.SecondaryLink
            event={constants.EVENT_EDIT_JOB_TEAM_MEMBERS_BUTTON}
            eventProperties={{
              jobId,
              [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
            }}
            text={getText('tabs_edit_team_members')}
            to={`/jobs/${jobId}/edit-team`}
          />
        )}
      </MobileSection>

      <Misc.Separator />

      <MobileSection>
        <Details activeJob={activeJob} />

        {(activeJob.is_assigned || isManager || isBackOffice) && (
          <>
            <Button.SecondaryLink
              event={constants.EVENT_JOB_UPDATE_BUTTON}
              eventProperties={{
                jobId,
                [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
              }}
              text={getText('tabs_edit_job_details')}
              to={`/jobs/${jobId}/edit-details`}
            />
            <Button.Secondary
              event={constants.EVENT_DELETE_JOB_BUTTON}
              eventProperties={{
                jobId,
                [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
              }}
              IconComponent={DeleteIcon}
              text={getText('tabs_delete_job')}
              onClick={() => setShowDeleteJob(true)}
              extraStyles={{ marginTop: '16px' }}
            />
          </>
        )}
      </MobileSection>
    </>
  );
};

import { Names, Dates } from 'helpers';
import { useSelector } from 'react-redux';
import { useText, useSafetyPrediction } from 'hooks';
import {
  selectWorkspaceSettings,
  selectGlobalLanguageSetting,
} from 'store/selectors';
import { WarningTag } from '../Tags/WarningTag';
import { MessageTag } from '../Tags/MessageTag';
import { EditableTag } from '../Tags/EditableTag';
import { getJobFullAddress } from 'helpers/names';
import { UserRole } from 'model/enum/UserRole';
import { SafetyRiskTag } from '../Tags/SafetyRiskTag';
import JobData from 'model/JobData';
import { getLabelByLocale } from 'helpers/utils';
import { StyleVariant } from 'model/enum/StyleVariant';
import {
  ArrowRight,
  CardWithWarningContainer,
  Header,
  JobDataContainer,
  JobDataContent,
  JobDataTitle,
  JobSubTitle,
  JobTitle,
  MainInfo,
  RedArrowRight,
  TagsContainer,
  TypeContainer,
  WarningCardContainer as CardContainer,
} from './styled';

type Props = {
  job: JobData;
  isActive: boolean;
  isImportant: boolean;
  isMyJobList: boolean;
  onClick: () => void;
};

// Card.Job
export const Job = ({
  job,
  isActive,
  isImportant,
  isMyJobList,
  onClick,
}: Props): JSX.Element => {
  const {
    job_number,
    created_at,
    type,
    subtype,
    status,
    assignees,
    editable,
    safety_prediction_score,
    sign_off_pending_assessment_count,
    pending_job_blocker_count,
    most_recent_job_blocker_created_at,
    most_recent_risk_assessment_created_at,
    unread_messages,
  } = job;

  const { getRiskWarning } = useSafetyPrediction();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const { work_order_number_label, work_order_number_label_es } = useSelector(
    selectWorkspaceSettings,
  );

  const workOrderNumberLabel = getLabelByLocale(globalAppLanguage, {
    work_order_number_label,
    work_order_number_label_es,
  });

  const getText = useText();

  const displayAssignees = () => {
    const fieldWorkers = assignees
      .filter((assignee) => assignee.role === UserRole.FIELDWORKER)
      .slice(0, 3);
    return fieldWorkers.map(({ first_name, last_name }, i) => {
      const comma = i !== fieldWorkers.length - 1 ? ', ' : '';
      return Names.capitalizeFullName(first_name, last_name) + comma;
    });
  };

  const safetyWarning = getRiskWarning(safety_prediction_score);

  return (
    <CardWithWarningContainer
      $hasBorder={!isActive}
      $hasRedBorder={isImportant || !!safetyWarning}
    >
      {safetyWarning && (
        <SafetyRiskTag
          message={safetyWarning.message}
          score={safetyWarning.score}
          hasBorder={isActive}
          variant={StyleVariant.INLINE}
        />
      )}
      <CardContainer
        $hasBorder={isActive}
        $hasWarningOnTop={!!safetyWarning}
        onClick={onClick}
        $isActive={isActive}
      >
        <Header>
          <MainInfo>
            <JobTitle>{displayAssignees()}</JobTitle>
            <JobSubTitle>{getJobFullAddress(job)}</JobSubTitle>
            {workOrderNumberLabel && (
              <JobDataContainer>
                <JobDataTitle>{workOrderNumberLabel}:</JobDataTitle>
                <JobDataContent>{job_number}</JobDataContent>
              </JobDataContainer>
            )}
            <JobDataContainer>
              <TypeContainer>
                <JobDataTitle>
                  {getText('jobs_search_type_prefix')}
                </JobDataTitle>
                <JobDataContent>
                  {type && (type[`title_${globalAppLanguage}`] || type.title)}
                  {subtype &&
                    ` - ${
                      subtype[`title_${globalAppLanguage}`] || subtype.title
                    }`}
                </JobDataContent>
              </TypeContainer>
            </JobDataContainer>
            <JobDataContainer>
              <JobDataTitle>
                {getText('jobs_search_date_created_prefix')}
              </JobDataTitle>
              <JobDataContent>
                {created_at &&
                  Dates.formatDateTime(
                    created_at,
                    getText('date_today'),
                    getText('date_yesterday'),
                  )}
              </JobDataContent>
            </JobDataContainer>
            <JobDataContainer>
              <JobDataTitle>{getText('jobs_search_status_label')}</JobDataTitle>
              <JobDataContent>
                {status &&
                  (status[`title_${globalAppLanguage}`] || status.title)}
              </JobDataContent>
            </JobDataContainer>
          </MainInfo>

          {isImportant ? <RedArrowRight /> : <ArrowRight isActive={isActive} />}
        </Header>
        <TagsContainer>
          {unread_messages > 0 && (
            <MessageTag unreadMessages={unread_messages} />
          )}
          {pending_job_blocker_count > 0 && (
            <WarningTag
              text={getText('job_overview_pending_blocker')}
              date={most_recent_job_blocker_created_at}
            />
          )}
          {sign_off_pending_assessment_count > 0 && (
            <WarningTag
              text={getText('job_overview_pending_sign_off_assessment')}
              date={most_recent_risk_assessment_created_at}
            />
          )}
          {!isMyJobList && <EditableTag editable={editable} />}
        </TagsContainer>
      </CardContainer>
    </CardWithWarningContainer>
  );
};

export enum ModalType {
  SEARCH_FILTERS = 'search-filters',
  COMMAND_CENTRE_FILTERS = 'command-centre-filters',
  FILTERS = 'filters',
  SEARCH_MEMBERS = 'search-members',
  CREATE_JOB = 'create-job',
  CHANGE_LANGUAGE = 'change-language',
  TEAMS = 'teams',
  TEAM_DETAILS = 'team-details',
  HAVS_COMPLIANCE_COMMENT = 'havs-compliance-comment',
}

import { Cross, Filters, PlusIcon, Search } from 'assets/icons';
import styled from 'styled-components';
import theme from 'theme';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

const { colors, fontSizes, mixins, fonts, media } = theme;

export const Container = styled.div`
  height: 100%;
  padding: 40px 80px;
  ${mixins.flexColumn};
  ${media.sm`
    padding: 15px;
  `}
  background-color: ${colors.offWhite};
  box-sizing: border-box;
  overflow: auto;
`;

export const Header = styled.h2`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
`;
export const TableContainer = styled.div`
  height: 650px;
  display: flex;
  background: ${colors.white};
  padding: 32px;
  padding-bottom: 0;
  ${media.lg`
  padding: 16px;
`};
`;
export const UserDropdownItemWrapper = styled.div`
  &.Mui-focused {
    background-color: ${colors.white} !important;
  }
  &:hover {
    background-color: ${colors.offWhite} !important;
  }
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 55px;
`;

export const UserAvatar = styled.div<{
  $isCurrentUser: boolean;
  $isManager: boolean;
  $isBackOffice: boolean;
}>`
  height: 55px;
  width: 55px;
  min-height: 55px;
  min-width: 55px;
  border-radius: 50%;
  color: ${colors.white};
  font-size: ${fontSizes.default};
  background-color: ${({ $isCurrentUser, $isManager, $isBackOffice }) => {
    if ($isCurrentUser) {
      return colors.darkGrey;
    }
    if (!$isCurrentUser && $isManager) {
      return colors.black;
    }
    if (!$isCurrentUser && $isBackOffice) {
      return colors.orange;
    }
    return colors.blue;
  }};
  margin-right: 15px;
  margin-bottom: 0;
  ${mixins.flexFullCenter};
  position: relative;
`;

export const UserTextWrapper = styled.div`
  ${mixins.flexColumn};
  min-height: 55px;
  justify-content: space-around;
`;

export const UserDetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
`;

export const UserName = styled.div`
  font-family: ${fonts.default};
  font-size: ${fontSizes.l};
  line-height: ${fontSizes.l};
`;

export const Info = styled.div`
  color: ${colors.darkGrey};
  font-family: ${fonts.default};
  margin-left: -30px !important;
  margin-right: 30px !important;

  &:before {
    content: '●';
    width: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const FiltersIcon = styled(Filters)`
  height: 20px;
  width: 20px;
  .fill-selector {
    fill: ${colors.black};
  }
  ${media.sm`
    .fill-selector {
      fill: ${colors.white};
    }
  `}
`;

export const UserFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  row-gap: 20px;
`;

export const StyledPlusIcon = styled(PlusIcon)`
  height: 20px;
  width: 20px;
  .fill-selector {
    fill: ${colors.black};
  }
`;

export const FiltersContainer = styled.div`
  overflow-y: auto;
  min-height: 40vh;
  max-height: 80vh;
  padding: 25px 0;
  ${mixins.flexFullCenter};
`;

export const FilterInputFields = styled.div`
  width: 55%;
  ${media.md`
    width: 70%;
  `}
  ${mixins.flexColumn};
  row-gap: 15px;
`;

export const HeaderContainer = styled.div`
  ${mixins.flexBetween};
  padding: 20px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  width: fit-content;
  min-width: 30%;
  ${media.md`
    min-width: 70%;
  `}
  ${media.lg`
    min-width: 50%;
  `}
  ${media.xl`
  min-width: 40%;
  `}
`;

export const ButtonsWrapper = styled.div`
  ${mixins.flexCenter}
  flex-wrap: wrap;
`;

export const StyledDataGrid = styled(({ isSkeleton, columns, ...rest }) => (
  <MuiDataGrid columns={columns} {...rest} />
))`
  &.MuiDataGrid-root {
    border: 0;
    .MuiDataGrid-columnHeaders {
      border-bottom: 2px solid ${colors.black};
      padding-right: 12px;
    }
    .MuiDataGrid-columnHeader--moving {
      background-color: ${colors.white};
    }
    .MuiDataGrid-columnHeaders {
      color: ${colors.black};
      font-family: ${fonts.bold};
      width: 100%;
      > div {
        width: 100%;
      }
      ${media.md`
        font-size: ${fontSizes.xs};
      `}
    }
    .MuiDataGrid-columnHeader {
      padding: 0 5px;
      &:focus,
      :focus-within {
        outline: 0;
      }
    }
    .MuiDataGrid-columnHeader--sortable .MuiDataGrid-sortIcon {
      opacity: 1 !important;
    }
    .MuiDataGrid-iconButtonContainer {
      visibility: visible;
      .MuiIconButton-root {
        ${media.md`
        padding: 0;
      `}
      }
    }
    .MuiDataGrid-columnHeader--sorted,
    .MuiDataGrid-columnHeader--sorted .MuiIconButton-root {
      color: ${colors.blue};
    }
    .MuiDataGrid-columnHeader.open-button-cell
      .MuiDataGrid-columnHeaderTitleContainer {
      align-items: end;
      justify-content: end;
    }
    .MuiDataGrid-columnHeaderTitleContainer {
      white-space: unset;
    }
    .MuiDataGrid-columnHeaderTitle {
      white-space: unset;
      word-break: break-word;
      line-height: normal;
    }
    .MuiDataGrid-row {
      box-sizing: border-box;
      border-bottom: 1px solid ${colors.grey};
      width: 100%;
      min-height: 80px !important;
      padding: 5px 0;
      position: relative;
      &:hover {
        cursor: default;
        ${({ isSkeleton }) =>
          isSkeleton &&
          `
          cursor: default;
          background-color: ${colors.white};
        `}
      }
    }

    .MuiDataGrid-cell:focus,
    .MuiDataGrid-cell:focus-within,
    .MuiDataGrid-cell:focus-visible {
      outline: none;
    }

    .MuiDataGrid-cell {
      color: ${colors.darkGrey};
      font-size: ${fontSizes.default};
      font-family: ${fonts.default};
      border: 0;
      padding: 0 5px;
      ${mixins.flexCenter};

      ${media.xl`
        font-size: ${fontSizes.s};
      `}
      ${media.md`
        font-size: ${fontSizes.xs};
      `}

      &.is-pinned-cell {
        > svg {
          .fill-selector {
            stroke: ${colors.blue};
          }
        }
        > svg.filled {
          .fill-selector {
            fill: ${colors.blue};
            stroke: ${colors.blue};
          }
        }
      }

      .big-cell {
        min-width: 19% !important;
        max-width: 19% !important;
        width: 19% !important;
      }
      .medium-cell {
        min-width: 15% !important;
        max-width: 15% !important;
        width: 15% !important;
      }
      .small-cell {
        min-width: 9% !important;
        max-width: 9% !important;
        width: 9% !important;
      }
    }

    .MuiDataGrid-row.Mui-selected,
    .MuiDataGrid-row.Mui-selected:hover,
    .MuiDataGrid-row:hover {
      background-color: ${colors.white} !important;
    }

    .big-cell {
      min-width: 19% !important;
      max-width: 19% !important;
      width: 19% !important;
    }
    .medium-cell {
      min-width: 15% !important;
      max-width: 15% !important;
      width: 15% !important;
    }
    .small-cell {
      min-width: 9% !important;
      max-width: 9% !important;
      width: 9% !important;
    }

    .MuiDataGrid-withBorder {
      border: 0;
    }
    .MuiDataGrid-virtualScrollerRenderZone {
      width: 100%;
    }
    .MuiDataGrid-scrollbar {
      overflow-x: hidden;
      overflow-y: auto !important;
      background-color: ${colors.white};

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 12px;
      }
      ::-webkit-scrollbar-thumb {
        min-height: 80px;
        background-color: ${colors.grey};
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
    .MuiDataGrid-iconSeparator {
      display: none;
    }
  }
`;

export const EmptyTableContainer = styled.div`
  padding: 25px;
  z-index: 9;
  position: relative;
`;

export const EmptyPlaceholderTitle = styled.h3`
  font-family: ${fonts.bold};
  font-size: ${fontSizes.xl};
  margin-bottom: 8px !important;
`;

export const EmptyPlaceholderSubtitle = styled.p`
  font-size: ${fontSizes.m};
`;

export const LoaderContainer = styled.div`
  background-color: ${colors.white};
  height: 100%;
  z-index: 100;
`;

export const TableWrapper = styled.div`
  height: 650px;
  display: flex;
  background: ${colors.white};
  padding: 32px;
  ${media.lg`
    padding: 16px;
  `};
`;

export const InputContainer = styled.div`
  display: flex;
  height: 45px;
  width: 100%;
  outline: 2px solid ${colors.black};
  background-color: ${colors.white};
  &:hover {
    outline: 3px solid ${colors.black};
  }

  input {
    border: none;
  }

  button {
    border: none;
  }
`;

export const SearchInput = styled.input<{ width?: string }>`
  flex: 1;
  padding: 5px 5px 5px 10px;
  outline: none;
  font-size: ${fontSizes.m};
  width: ${({ width }) => width};
  ${media.xl`
    font-size: ${fontSizes.default};
  `}
  ${media.lg`
    font-size: ${fontSizes.s};
  `}
  ${media.sm`
    border: none;
  `}
`;

export const SearchButton = styled.button`
  height: 45px;
  width: 45px;
  background-color: ${({ disabled }) =>
    disabled ? colors.lightGrey : colors.yellow};

  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${mixins.flexFullCenter};
  ${media.sm`
    border: none;
  `}
`;

export const SearchIcon = styled(Search)`
  height: 23px;
  width: 23px;
  .fill-selector {
    fill: ${({ disabled }) => (disabled ? colors.darkGrey : colors.black)};
  }
`;

export const CrossIcon = styled(Cross)`
  height: 20px;
  .fill-selector {
    fill: ${colors.darkGrey};
  }
`;

export const CrossWrapper = styled.div<{ $isVisible: boolean }>`
  background-color: ${colors.white};
  display: flex;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  align-items: center;
  cursor: pointer;
  ${media.sm`
    border: none;
  `}
`;

export const ActiveFilterContainer = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 0;
  box-sizing: border-box;

  ${media.xxl`
    margin-bottom: 10px;
    padding: 8px 0;
  `}
`;

export const FilterBox = styled.div`
  ${mixins.flexBetween}
  min-width: 65px;
  align-items: center;
  background-color: ${colors.white};
  outline: 2px solid ${colors.lightGrey};
  padding: 5px 15px;
  margin-left: 10px;
  margin-top: 5px;
  text-align: center;
  &:hover {
    outline-color: ${colors.black};
  }
  ${media.md`
    padding: 5px;
    margin-left: 5px;
  `}
`;

export const FilterCross = styled(({ ...rest }) => <Cross {...rest} />)`
  height: 15px;
  width: 15px;
  margin-right: 5px;
  cursor: pointer;
  .fill-selector {
    fill: ${colors.darkGrey};
  }
  ${media.lg`
    height: 10px;
    width: 10px;
    min-height: 10px;
    min-width: 10px;
 `}
`;

export const FilterText = styled.span`
  color: ${colors.darkGrey};
  font-family: ${fonts.bold};
  ${media.xl`
    font-size: ${fontSizes.s};
 `}
`;

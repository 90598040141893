import JobData from 'model/JobData';
import JobStatus from 'model/JobStatus';
import { JobStatusTitle } from 'model/enum/JobStatusTitle';

export const displayFullAddress = (job: JobData): string => {
  const { location_description, postcode, address } = job;

  if (location_description) {
    return location_description;
  }

  if (postcode && address) {
    return `${address}, ${postcode}`;
  }

  if (postcode) {
    return postcode;
  }

  if (address) {
    return address;
  }

  return '';
};

export const getValidStatuses = (
  statuses: JobStatus[],
  activeJob: JobData | null,
): JobStatus[] => {
  if (
    activeJob?.status?.title !== JobStatusTitle.CLOSED &&
    activeJob?.status?.title !== JobStatusTitle.COMPLETED
  ) {
    return statuses.filter((status) => status?.title !== JobStatusTitle.CLOSED);
  }

  return statuses;
};

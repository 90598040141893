import { useText } from 'hooks';
import {
  AppStoreLink,
  GooglePlayLink,
  LogoContainer,
  WhiteText,
  Wrapper,
  YellowText,
} from './styled';
import theme from 'theme';

const { fontSizes } = theme;

export const HappyFieldworkerFlow = (): JSX.Element => {
  const getText = useText();
  return (
    <Wrapper>
      <YellowText fontSize={fontSizes.xxxl}>
        {getText('sign_up_success')}
      </YellowText>
      <WhiteText>{getText('sign_up_success_receive_email')}</WhiteText>
      <WhiteText>{getText('sign_up_success_download_app')}</WhiteText>
      <LogoContainer variant='success'>
        <a href='https://play.google.com/store/apps/details?id=com.sitestream&hl=en_GB&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
          <GooglePlayLink
            alt={getText('mobile_install_google_play')}
            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
          />
        </a>
        <a href='https://apps.apple.com/gb/app/fyld/id1588103319?itsct=apps_box_badge&amp;itscg=30200'>
          <AppStoreLink
            src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1643846400&h=3faedb7c17387fd6e68c237b4c546d2b'
            alt={getText('mobile_install_app_store')}
          />
        </a>
      </LogoContainer>
    </Wrapper>
  );
};

export const HappyManagerWorkerFlow = (): JSX.Element => {
  const getText = useText();
  return (
    <Wrapper>
      <YellowText fontSize={fontSizes.xxxl}>
        {getText('sign_up_success_manager_access')}
      </YellowText>
      <WhiteText>{getText('sign_up_success_manager_thank_you')}</WhiteText>
      <WhiteText>{getText('sign_up_success_manager_keep_touch')}</WhiteText>
      <LogoContainer></LogoContainer>
    </Wrapper>
  );
};

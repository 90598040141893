import { useEffect, useState } from 'react';
import { Input } from 'components/ui';
import { useControls, useText } from 'hooks';
import { useSelector } from 'react-redux';
import { selectGlobalLanguageSetting } from 'store/selectors';
import { ControlList } from './Rating/ControlList';
import RootState from 'model/State/RootState';
import { SearchContainer, SearchSectionTitle } from './styled';
import { getLocalizedCamelCaseValue } from 'helpers/locales';

// SearchControl
export const SearchControl = ({
  hazardId,
}: {
  hazardId: number;
}): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  const controls = useControls();
  const getText = useText();
  const { selectedControls, selectedHazards } = useSelector(
    (state: RootState) => state.ramsAssessments,
  );
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);

  useEffect(() => {
    if (hazardId) {
      setSearch('');
    }
  }, [hazardId]);

  const handleFilterChange = (newSearch) => {
    setSearch(newSearch.toLowerCase());
  };

  const getControls = () => {
    const hazard = selectedHazards.find(
      (selectedHazard) => selectedHazard.id === hazardId,
    );
    return controls?.filter((control) => {
      const controlTitle = getLocalizedCamelCaseValue(
        control,
        'title',
        globalAppLanguage,
      ).toLowerCase();
      const isSearchTermIncluded = controlTitle.indexOf(search) > -1;
      const isSelected = selectedControls.some(
        (selectedControl) => selectedControl.id === control.id,
      );
      const isMappedToHazard = hazard?.mappedControls?.some(
        (mappedControl) => mappedControl.id === control.id,
      );
      return isSearchTermIncluded && !isSelected && hazard && !isMappedToHazard;
    });
  };

  return (
    <SearchContainer>
      <SearchSectionTitle>
        {getText('rams_search_control_title')}
      </SearchSectionTitle>
      <Input.Text
        id='search'
        label=''
        placeholder={getText('description_hint')}
        value={search}
        onChange={(e) => handleFilterChange(e.target.value)}
      />
      {search.length > 0 && <ControlList controls={getControls()} />}
    </SearchContainer>
  );
};

import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal, Button, Input, Card } from 'components/ui';
import { TickCircle } from 'assets/icons';
import { User as UserApi } from 'api';
import theme from 'theme';
import { useText } from 'hooks';
import RootState from 'model/State/RootState';
import * as constants from 'utils/constants';
import { BackendUser } from 'model/BackendUser';
import {
  selectFormOptions,
  selectGlobalLanguageSetting,
  selectWorkspaceHasSettingEnabled,
  selectWorkspaceSettings,
} from 'store/selectors';
import { getLabelByLocale, isBlank, isValidEmail } from 'helpers/utils';
import { handleError } from 'helpers/apiHelpers';
import UserDataRow from 'model/UserDataRow';

const { colors, fonts, fontSizes } = theme;

const Container = styled.div`
  width: 100%;
  margin: 20px auto;
  overflow: auto;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
`;

const InputWrapper = styled.div`
  margin-bottom: 12px;
`;

const ErrorMessage = styled.div`
  color: ${colors.darkRed};
  margin-top: 6px;
`;

const ErrorContainer = styled.div`
  min-height: 130px;
  max-height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuccessContainer = styled.div`
  height: 315px;
  padding: 25px 60px;
  background-color: ${colors.black};
  color: ${colors.white};
  display: flex;
  flex-direction: column;
`;

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fonts.boldItalic};
  font-size: ${fontSizes.l};
`;

const SuccessText = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

const TickContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const TickCircleIcon = styled(TickCircle)`
  width: 64px;
  height: 64px;
`;

type Error = {
  email: boolean;
  emailInvalid: boolean;
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  roleId: boolean;
  depotId: boolean;
};

type Props = {
  isVisible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (value: BackendUser) => void;
  initialUser?: UserDataRow;
};

type FormData = {
  id: number | null;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  roleId: number | null;
  depotId: number | null;
  workspaceUuid: string;
  isDeviceless: boolean;
  externalId: string;
};

const validatePhoneNumber = (phoneNumber) =>
  constants.Regex.phoneNumber.test(phoneNumber);

const AddEditUserModal = ({
  isVisible,
  setVisible,
  onSuccess,
  initialUser,
}: Props): JSX.Element => {
  const getText = useText();
  const formOptions = useSelector(selectFormOptions);
  const { selectedWorkspace } = useSelector(
    (state: RootState) => state.session,
  );
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const {
    fieldworker_label,
    fieldworker_label_es,
    manager_label,
    manager_label_es,
  } = useSelector(selectWorkspaceSettings);
  const devicelessSignatureEnabled = useSelector(
    selectWorkspaceHasSettingEnabled('deviceless_digital_signature'),
  );

  const hasExternalId = useSelector(
    selectWorkspaceHasSettingEnabled('allow_user_external_id'),
  );

  const fieldworkerOption = getLabelByLocale(globalAppLanguage, {
    fieldworker_label,
    fieldworker_label_es,
  });
  const managerOption = getLabelByLocale(globalAppLanguage, {
    manager_label,
    manager_label_es,
  });

  const isEditing = !!initialUser;

  const roleOptions = [
    {
      id: 1,
      title: fieldworkerOption,
      backendId: constants.userRoles.FIELDWORKER,
      backendLabel:
        constants.userRoleBackendLabels[constants.userRoles.FIELDWORKER],
      isDeviceless: false,
      showOption: fieldworkerOption,
    },
    {
      id: 2,
      title: managerOption,
      backendId: constants.userRoles.MANAGER,
      backendLabel:
        constants.userRoleBackendLabels[constants.userRoles.MANAGER],
      isDeviceless: false,
      showOption: managerOption,
    },
    {
      id: 3,
      title: getText('add_user_role_fieldworker_digital_signature'),
      backendId: constants.userRoles.FIELDWORKER,
      backendLabel:
        constants.userRoleBackendLabels[constants.userRoles.FIELDWORKER],
      isDeviceless: true,
      showOption: !isEditing,
    },
  ];

  const isAutoGeneratedEmail =
    initialUser?.email.startsWith(`${constants.DEVICELESS}+`) ?? false;

  const [formData, setFormData] = useState<FormData>(() => {
    if (isEditing) {
      const selectedRole = initialUser?.is_deviceless
        ? roleOptions[2]
        : roleOptions.find(
            (option) => option.backendLabel === initialUser.role,
          );
      return {
        id: initialUser.id,
        email: isAutoGeneratedEmail ? '' : initialUser.email,
        firstName: initialUser.first_name,
        lastName: initialUser.last_name,
        phoneNumber:
          initialUser.phone_number === '-' ? '' : initialUser.phone_number,
        roleId: selectedRole?.id ?? null,
        depotId: initialUser.depot_id ?? null,
        workspaceUuid: selectedWorkspace?.uuid,
        isDeviceless: selectedRole?.isDeviceless ?? false,
        externalId: initialUser.externalId,
      };
    }

    return {
      id: null,
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      roleId: null,
      depotId: null,
      workspaceUuid: selectedWorkspace?.uuid,
      isDeviceless: false,
      externalId: '',
    };
  });
  const [validationErrors, setValidationErrors] = useState<Error>({} as Error);
  const [callError, setCallError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string>('');

  const isDeviceless =
    formData.roleId &&
    roleOptions.find((role) => role.id === formData.roleId)?.isDeviceless;

  const validateForm = () => {
    const trimmedFormData = getTrimmedFields(formData);
    const errors = {} as Error;
    if (!isDeviceless) {
      if (isBlank(trimmedFormData.email)) {
        errors.email = true;
      } else if (!isValidEmail(trimmedFormData.email)) {
        errors.emailInvalid = true;
      }
    }
    if (isBlank(trimmedFormData.firstName)) {
      errors.firstName = true;
    }
    if (isBlank(trimmedFormData.lastName)) {
      errors.lastName = true;
    }
    if (
      !isDeviceless &&
      trimmedFormData.phoneNumber.length > countryCode.length &&
      !validatePhoneNumber(trimmedFormData.phoneNumber)
    ) {
      errors.phoneNumber = true;
    }
    if (trimmedFormData.roleId === null) {
      errors.roleId = true;
    }
    if (trimmedFormData.depotId === null) {
      errors.depotId = true;
    }

    return {
      isValid: Object.keys(errors).length === 0,
      errors: errors,
      trimmedFormData,
    };
  };

  const getTrimmedFields = (data: FormData): FormData => ({
    ...data,
    email: data.email?.trim(),
    firstName: data.firstName?.trim(),
    lastName: data.lastName?.trim(),
    phoneNumber: data.phoneNumber?.trim(),
    externalId: data.externalId?.trim(),
  });

  const handleAddSubmit = async () => {
    const { isValid, errors, trimmedFormData } = validateForm();
    if (!isValid) {
      setValidationErrors(errors);
      setFormData(trimmedFormData);
    } else {
      setIsLoading(true);
      setCallError(null);
      try {
        const phoneNumber =
          trimmedFormData.phoneNumber.length === countryCode.length
            ? ''
            : trimmedFormData.phoneNumber;
        const { data } = await UserApi.createUser({
          ...trimmedFormData,
          phoneNumber,
        });
        onSuccess(data);
        setShowSuccess(true);
      } catch (err) {
        setCallError(handleError(err, getText('request_error')));
      }
      setIsLoading(false);
    }
  };

  const handleEditSubmit = async () => {
    const { isValid, errors, trimmedFormData } = validateForm();
    if (!isValid) {
      setValidationErrors(errors);
      setFormData(trimmedFormData);
    } else {
      setIsLoading(true);
      setCallError(null);
      try {
        const selectedRole = roleOptions.find(
          (option) => option.id === trimmedFormData.roleId,
        );

        const phoneNumber =
          trimmedFormData.phoneNumber.length === countryCode.length
            ? ''
            : trimmedFormData.phoneNumber;
        const { data } = await UserApi.editUser({
          ...trimmedFormData,
          phoneNumber,
          email: isDeviceless ? initialUser?.email : trimmedFormData.email,
          roleId: selectedRole?.backendId ?? '',
          isDeviceless: selectedRole?.isDeviceless,
          isActive: initialUser?.is_active,
        });
        onSuccess(data);
        setVisible(false);
      } catch (err) {
        setCallError(handleError(err, getText('request_error')));
      }
      setIsLoading(false);
    }
  };

  const handleSubmit = isEditing ? handleEditSubmit : handleAddSubmit;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handlePhoneNumberChange = (value: string, countryCode: string) => {
    setCountryCode(countryCode);
    setFormData({
      ...formData,
      phoneNumber: value,
    });
  };

  const handleDepotChange = (event) => {
    setFormData({ ...formData, depotId: event?.target?.value });
  };

  const handleRoleChange = (event) => {
    const selectedRole = roleOptions.find(
      (role) => role.id === event?.target?.value,
    );
    setFormData({
      ...formData,
      roleId: event?.target?.value,
      isDeviceless: selectedRole?.isDeviceless ?? false,
      phoneNumber: selectedRole?.isDeviceless ? '' : formData.phoneNumber,
      email: selectedRole?.isDeviceless ? '' : formData.email,
    });
  };

  return (
    <Modal.Base
      isOpen={isVisible}
      onClose={() => setVisible(false)}
      width='650px'
      title={isEditing ? getText('edit_user') : getText('add_user_new')}
      isCentered
    >
      {showSuccess ? (
        <SuccessContainer>
          <SuccessMessage>
            <TickContainer>
              <TickCircleIcon />
            </TickContainer>
            <SuccessText>
              <span>{getText('add_user_well_done')}</span>
              <span>
                {getText('add_user_created', {
                  userData: isDeviceless
                    ? `${formData.firstName} ${formData.lastName}`
                    : formData.email,
                })}
              </span>
            </SuccessText>
          </SuccessMessage>
          <Button.Primary
            text={getText('go_back_settings')}
            width='auto'
            onClick={() => setVisible(false)}
            event={constants.EVENT_BACK_TO_SETTINGS}
          />
        </SuccessContainer>
      ) : (
        <Container>
          <FormWrapper onSubmit={handleSubmit}>
            <InputWrapper>
              <Input.Select
                id='roleId'
                label={getText('add_user_role')}
                hint={getText('add_user_select_hint')}
                options={roleOptions.filter((option) => option.showOption)}
                onChange={handleRoleChange}
                selected={roleOptions.find(
                  (option) => option.id === formData.roleId,
                )}
                required
                large
                infoMessage={
                  devicelessSignatureEnabled
                    ? getText('add_user_digital_signature_role_info')
                    : ''
                }
              />
              {validationErrors.roleId && (
                <ErrorMessage>
                  {getText('add_user_validation_required_field')}
                </ErrorMessage>
              )}
            </InputWrapper>
            {!isDeviceless && (
              <InputWrapper>
                <Input.Text
                  id='email'
                  label={getText('add_user_email')}
                  value={formData.email}
                  onChange={handleChange}
                  disabled={isEditing && !isAutoGeneratedEmail}
                  required={!isEditing || isAutoGeneratedEmail}
                  maxLength={254}
                />
                {validationErrors.email && (
                  <ErrorMessage>
                    {getText('add_user_validation_required_field')}
                  </ErrorMessage>
                )}
                {validationErrors.emailInvalid && (
                  <ErrorMessage>
                    {getText('add_user_validation_email_not_valid')}.
                  </ErrorMessage>
                )}
              </InputWrapper>
            )}
            <InputWrapper>
              <Input.Text
                id='firstName'
                label={getText('add_user_first_name')}
                value={formData.firstName}
                onChange={handleChange}
                required
                maxLength={30}
              />
              {validationErrors.firstName && (
                <ErrorMessage>
                  {getText('add_user_validation_required_field')}
                </ErrorMessage>
              )}
            </InputWrapper>
            <InputWrapper>
              <Input.Text
                id='lastName'
                label={getText('add_user_last_name')}
                value={formData.lastName}
                onChange={handleChange}
                required
                maxLength={150}
              />
              {validationErrors.lastName && (
                <ErrorMessage>
                  {getText('add_user_validation_required_field')}
                </ErrorMessage>
              )}
            </InputWrapper>
            {hasExternalId && (
              <InputWrapper>
                <Input.Text
                  id='externalId'
                  label={getText('user_id')}
                  value={formData.externalId}
                  onChange={handleChange}
                  maxLength={254}
                ></Input.Text>
              </InputWrapper>
            )}
            {!isDeviceless && (
              <InputWrapper>
                <Input.PhoneNumber
                  id='phoneNumber'
                  label={getText('user_phone_number')}
                  value={formData.phoneNumber}
                  onChange={handlePhoneNumberChange}
                />
                {validationErrors.phoneNumber && (
                  <ErrorMessage>
                    {getText('add_user_validation_phone_number_not_valid')}
                  </ErrorMessage>
                )}
              </InputWrapper>
            )}
            <InputWrapper>
              {formOptions && (
                <Input.Select
                  id='depotId'
                  label={getText('add_user_depot')}
                  hint={getText('add_user_select_hint')}
                  selected={formOptions.depots.find(
                    (depot) => depot.id === formData.depotId,
                  )}
                  options={formOptions.depots}
                  onChange={handleDepotChange}
                  required
                  large
                />
              )}
              {validationErrors.depotId && (
                <ErrorMessage>
                  {getText('add_user_validation_required_field')}
                </ErrorMessage>
              )}
            </InputWrapper>
            {callError && (
              <ErrorContainer>
                <Card.ErrorMessage message={callError} />
              </ErrorContainer>
            )}
            <Button.Primary
              text={
                isEditing
                  ? getText('edit_user_save_changes')
                  : getText('add_user_new')
              }
              width='240px'
              loading={isLoading}
              onClick={handleSubmit}
              event={constants.EVENT_CREATE_USER}
            />
          </FormWrapper>
        </Container>
      )}
    </Modal.Base>
  );
};

export default AddEditUserModal;

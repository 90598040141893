import styled from 'styled-components';
import theme from 'theme';
import { StyledDataGrid } from '../styles';

const { colors } = theme;

const SkeletonCell = styled.div<{ width: number }>`
  background-color: ${colors.lightGrey};
  height: 20px;
  width: ${({ width }) => width}%;
`;

export const SkeletonTable = ({ columns, pageSize }) => {
  const skeletonColumns = columns?.map((column, index) => ({
    ...column,
    renderCell: () => <SkeletonCell key={`skeleton-${index}`} width={50} />,
  }));

  const rows: any[] = [];
  for (let i = 0; i < pageSize; i += 1) {
    rows.push({
      ...columns?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.field]: '',
        }),
        {},
      ),
      id: i,
    });
  }

  return (
    <StyledDataGrid
      rows={rows}
      columns={skeletonColumns}
      isEmpty={!rows?.length}
      width={`${100 / columns?.length}%`}
      getRowHeight={() => 'auto'}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableExtendRowFullWidth
      disableRowSelectionOnClick
      rowsPerPageOptions={[]}
      autoHeight
    />
  );
};

import { useQueryParams, useViewVersion } from 'hooks';
import useJobTabs from 'hooks/useJobTabs';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  LIST_VIEW,
  MAP_VIEW_TAB,
  URL_TAB_QUERY_PARAM_FIELD,
  URL_VIEW_QUERY_PARAM_FIELD,
} from 'utils/constants';

const MapListViewWrapper = ({
  mapView: MapView,
  listTab: ListTab,
  mapTab: MapTab,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { params } = useQueryParams();

  const { pathname, state } = location;

  const { viewVersion, viewVersionParam } = useViewVersion();
  const { tab, tabParam } = useJobTabs();

  const generateNewUrl = useCallback(
    (viewVersionParam: string, tabParam: string) => {
      const {
        [URL_VIEW_QUERY_PARAM_FIELD]: removedViewVersionParam,
        [URL_TAB_QUERY_PARAM_FIELD]: removedTabVersionParam,
        ...restOfParams
      } = params;

      const newParams = new URLSearchParams({
        ...restOfParams,
        ...(viewVersionParam && {
          [URL_VIEW_QUERY_PARAM_FIELD]: viewVersionParam,
        }),
        ...(tabParam && {
          [URL_TAB_QUERY_PARAM_FIELD]: tabParam,
        }),
      }).toString();

      if (newParams) {
        return `${pathname}?${newParams}`;
      } else {
        return pathname;
      }
    },
    [params, pathname],
  );

  const viewParamFromUrl = params?.[URL_VIEW_QUERY_PARAM_FIELD] ?? '';
  const tabParamFromUrl = params?.[URL_TAB_QUERY_PARAM_FIELD] ?? '';

  useEffect(() => {
    if (viewParamFromUrl !== viewVersionParam || tabParamFromUrl !== tabParam) {
      const newUrl = generateNewUrl(viewVersionParam, tabParam);
      navigate(newUrl, { state, replace: true });
    }
  }, [
    viewParamFromUrl,
    tabParamFromUrl,
    viewVersionParam,
    tabParam,
    generateNewUrl,
    navigate,
    state,
  ]);

  if (viewVersion === LIST_VIEW) {
    return tab === MAP_VIEW_TAB ? MapTab : ListTab;
  } else {
    return MapView;
  }
};

export default MapListViewWrapper;

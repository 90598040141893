import { useState, useEffect } from 'react';
import { User } from 'api';
import { useDispatch } from 'react-redux';
import { setGlobalError } from 'store/slices/notifications';
import Exemption from 'model/Exemption';

// useNotifications
const useNotifications = () => {
  const [notificationExemptions, setNotificationExemptions] = useState<
    Exemption[]
  >([]);
  const [saveChange, setSaveChange] = useState<boolean>(false);
  const dispatch = useDispatch();

  const addExemption = (exemption) => {
    setNotificationExemptions([...notificationExemptions, exemption]);
    setSaveChange(true);
  };

  const removeExemption = (exemption) => {
    const filters = {
      kind: exemption.kind,
      channel: exemption.channel,
    };

    const newExemptions = notificationExemptions.filter((item) => {
      const keys = Object.keys(filters);
      for (const key of keys) {
        if (item[key] === undefined || item[key] !== filters[key]) {
          return true;
        }
      }
      return false;
    });

    setNotificationExemptions(newExemptions);
    setSaveChange(true);
  };

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { exemptions },
        } = await User.getNotificationSettings();

        setNotificationExemptions(exemptions);
      } catch (err) {
        dispatch(setGlobalError(err));
        console.error(err);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (notificationExemptions && saveChange) {
      (async () => {
        try {
          await User.updateNotificationSettings(notificationExemptions);
          setSaveChange(false);
        } catch (err) {
          dispatch(setGlobalError(err));
          console.error(err);
        }
      })();
    }
  }, [notificationExemptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return { notificationExemptions, saveChange, addExemption, removeExemption };
};

export default useNotifications;

import { WorkspaceSettings } from 'api';
import { WorkspaceItemStatus } from 'model/enum/WorkspaceItemStatus';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_WORKSPACE_JOB_SUBTYPES,
  SAVE_JOB_SUBTYPE_CHANGES,
} from 'store/actions/actionTypes';
import { setGlobalError } from 'store/slices/notifications';
import {
  addNewJobSubtypeError,
  addNewJobSubtypeSuccess,
  editExistingJobSubtypeError,
  editExistingJobSubtypeSuccess,
  fetchWorkspaceJobSubtypesError,
  fetchWorkspaceJobSubtypesSuccess,
  removeExistingJobSubtypeError,
  removeExistingJobSubtypeSuccess,
  resetJobSubtypeErrors,
  saveJobSubtypeChangesError,
  saveJobSubtypeChangesSuccess,
} from 'store/actions/settings/jobSubtype';
import * as Logger from 'utils/logger';

function* fetchJobSubtypes({ workspaceUuid, jobTypeId }) {
  try {
    const data = yield WorkspaceSettings.getJobSubtypes(
      workspaceUuid,
      jobTypeId,
    );

    yield put(fetchWorkspaceJobSubtypesSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(fetchWorkspaceJobSubtypesError());
    yield put(setGlobalError(err));
  }
}

function* saveJobSubtypeChanges({ workspaceUuid, jobTypeId, jobSubtypes }) {
  try {
    yield put(resetJobSubtypeErrors());
    yield all(
      jobSubtypes.map((jobSubtype) => {
        switch (jobSubtype.status) {
          case WorkspaceItemStatus.ADDED:
            return call(addJobSubtype, {
              workspaceUuid,
              jobTypeId,
              jobSubtype,
            });
          case WorkspaceItemStatus.EDITED:
            return call(editJobSubtype, {
              workspaceUuid,
              jobTypeId,
              jobSubtype,
            });
          case WorkspaceItemStatus.DELETED:
            return call(removeJobSubtype, {
              workspaceUuid,
              jobTypeId,
              jobSubtype,
            });
          default:
            return; // eslint-disable-line array-callback-return
        }
      }),
    );
    yield put(saveJobSubtypeChangesSuccess());
  } catch (err) {
    Logger.error(err);
    yield put(saveJobSubtypeChangesError());
    yield put(setGlobalError(err));
  }
}

function* addJobSubtype({ workspaceUuid, jobTypeId, jobSubtype }) {
  try {
    const data = yield WorkspaceSettings.addJobSubtype(
      workspaceUuid,
      jobTypeId,
      {
        ...jobSubtype,
      },
    );

    yield put(addNewJobSubtypeSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(addNewJobSubtypeError(jobSubtype));
    yield put(setGlobalError(err));
  }
}

function* editJobSubtype({ workspaceUuid, jobTypeId, jobSubtype }) {
  try {
    const data = yield WorkspaceSettings.updateJobSubtype(
      workspaceUuid,
      jobTypeId,
      {
        ...jobSubtype,
      },
    );

    yield put(editExistingJobSubtypeSuccess(workspaceUuid, data));
  } catch (err) {
    Logger.error(err);
    yield put(editExistingJobSubtypeError(jobSubtype));
    yield put(setGlobalError(err));
  }
}

function* removeJobSubtype({ workspaceUuid, jobTypeId, jobSubtype }) {
  try {
    yield WorkspaceSettings.deleteJobSubtype(
      workspaceUuid,
      jobTypeId,
      jobSubtype?.id,
    );

    yield put(removeExistingJobSubtypeSuccess(workspaceUuid, jobSubtype?.id));
  } catch (err) {
    Logger.error(err);
    yield put(removeExistingJobSubtypeError(jobSubtype));
    yield put(setGlobalError(err));
  }
}

export default function* workspaceJobSubtypeWatcher() {
  yield takeLatest(FETCH_WORKSPACE_JOB_SUBTYPES, fetchJobSubtypes);
  yield takeLatest(SAVE_JOB_SUBTYPE_CHANGES, saveJobSubtypeChanges);
}

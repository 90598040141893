import { Button, Input, Modal } from 'components/ui';
import theme from 'theme';
import { FilterInputFields, FiltersContainer } from '../styled';
import { DepotFilter } from 'components/ui/Filter/DepotFilter';
import { useText } from 'hooks';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { UserRole } from 'model/enum/UserRole';
import { UserSearchDropdown } from './UserSearchDropdown';
import { ClearButton } from 'components/ui/Button';
import {
  HavsFilters,
  HavsManager,
} from 'model/AdminPanel/HavsSettings/HavsUser';
import { Depot } from 'model/Job/Depot';
import { inputAsNumber } from 'helpers/number';
import { BLANK_FILTERS } from '../constants';

const { fontSizes } = theme;

type Props = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  initialFilters: HavsFilters;
  applyFilters: (newFilters: HavsFilters) => void;
};

export const FiltersModal = ({
  isVisible,
  setIsVisible,
  initialFilters,
  applyFilters,
}: Props): JSX.Element => {
  const getText = useText();
  const [filters, setFilters] = useState<HavsFilters>(initialFilters);
  const { depot, havsLimit, manager } = filters;
  const changeFilter =
    (field: keyof HavsFilters): Dispatch<Depot | number | HavsManager | null> =>
    (newValue: Depot | number | HavsManager | null) =>
      setFilters((prevState) => ({ ...prevState, [field]: newValue }));

  const changeHavsLimit = (e: ChangeEvent<HTMLInputElement>) => {
    const value = inputAsNumber(e.target.value);
    changeFilter('havsLimit')(value);
  };

  return (
    <Modal.Base
      title={getText('havs_users_filters_modal_title')}
      isOpen={isVisible}
      onClose={() => setIsVisible(false)}
    >
      <FiltersContainer>
        <FilterInputFields>
          <DepotFilter
            selectedDepot={depot}
            handleDepotChange={changeFilter('depot')}
          />
          <Input.Text
            id='havs-limit'
            label={getText('havs_user_add_new_havs_input_label')}
            value={havsLimit ?? ''}
            onChange={changeHavsLimit}
          />
          <UserSearchDropdown
            label={getText('havs_user_manager_dropdown_label')}
            id='havs-manager-dropdown'
            selectedUser={manager}
            setSelectedUser={changeFilter('manager')}
            userRoles={[UserRole.MANAGER]}
            placeholder={getText('havs_user_add_new_user_placeholder')}
          />
          <ClearButton
            text={getText('jobs_filter_clear_all')}
            onClick={() => setFilters(BLANK_FILTERS)}
            width='145px'
            extraStyles={{ padding: '8px 12px', alignSelf: 'flex-start' }}
          />
        </FilterInputFields>
      </FiltersContainer>
      <Button.Primary
        text={getText('havs_users_filters_modal_cta')}
        onClick={() => applyFilters(filters)}
        extraStyles={{
          position: 'sticky',
          bottom: '0',
          marginTop: 'auto',
          flex: '0 0 55px',
          fontSize: fontSizes.l,
        }}
      />
    </Modal.Base>
  );
};

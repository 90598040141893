import { useText } from 'hooks';
import { SloganWrapper } from './styled';

export const Slogan = (): JSX.Element => {
  const getText = useText();

  return (
    <SloganWrapper>
      <div>{getText('login_slogan_first')}</div>
      <div>{getText('login_slogan_second')}</div>
    </SloganWrapper>
  );
};

/*
 */
import styled from 'styled-components';
import { useIsMobile, useQueryParams, useText } from 'hooks';
import { Loader, Card, Message, Navbar } from 'components/ui';
import { SkeletonCardContainer } from 'components/ui/Shared/EmptyState';
import theme from 'theme';
import * as constants from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGlobalLanguageSetting,
  selectIsFetchingLogs,
  selectJobLogs,
  selectJobsView,
} from 'store/selectors';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchLogs } from 'store/actions/jobs';
import { getLocalizedValue } from 'helpers/locales';

const { media } = theme;

/* Styled Components */
const Container = styled.div`
  height: 100%;
  ${media.sm`
    padding: 25px 15px;
    overflow: scroll;
  `}
`;

// Logs.List
const List = (): JSX.Element => {
  const { jobId } = useParams();
  const isMobile = useIsMobile();
  const getText = useText();
  const dispatch = useDispatch();
  const { getPathWithQueryParams } = useQueryParams();
  const globalAppLanguage = useSelector(selectGlobalLanguageSetting);
  const logs = useSelector(selectJobLogs(jobId));
  const isFetchingLogs = useSelector(selectIsFetchingLogs);
  const view = useSelector(selectJobsView);

  useEffect(() => {
    dispatch(fetchLogs(jobId));
  }, [jobId]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildProps = (log) => ({
    event: constants.EVENT_VIEW_NOTE_ITEM,
    eventProperties: {
      jobId,
      logId: log.id,
      logType: 'Note',
      source: 'job-overview',
      [constants.EVENT_PROPERTIES_PAGE_SOURCE_FIELD]: view,
    },
    key: `log-${log.id}`,
    to: getPathWithQueryParams(`/jobs/${jobId}/logs/${log.id}`),
  });

  return (
    <>
      <Navbar
        title={getText('job_log_title')}
        defaultBackPath={`/jobs/${jobId}`}
        mobileOnly
      />

      <Container>
        {isFetchingLogs ? (
          <Loader />
        ) : (
          <>
            {!logs?.length ? (
              <SkeletonCardContainer>
                {getText('job_log_no_display')}
              </SkeletonCardContainer>
            ) : (
              logs?.map((log) =>
                isMobile ? (
                  <Message.Action
                    type={getText('job_details_timeline_action_note')}
                    subtype={log?.type?.title}
                    sender={log.createdBy}
                    date={log.createdAt}
                    {...buildProps(log)}
                  />
                ) : (
                  <Card.Log
                    type={log.type}
                    subtype={getLocalizedValue(log, 'title', globalAppLanguage)}
                    creator={log.createdBy}
                    date={log.createdAt}
                    {...buildProps(log)}
                  />
                ),
              )
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default List;

import { JobSort } from 'model/enum/JobSort';

export const SUCCESS = 'success';
export const ERROR = 'error';

export const AUTH_STATUS_CONFIRMED = 'CONFIRMED';
export const AUTH_STATUS_RESET_PASSWORD = 'NEW_PASSWORD_REQUIRED';

export const LoginSource = {
  EMAIL_PASSWORD: 'email_password',
  SSO: 'SSO',
  OKTA: 'OKTA',
  MAGIC_LINK: 'magic_link',
};

export const INITIAL_FILTERS = {
  q: '',
  scope: JobSort.USER,
  type: null,
  subtype: null,
  status: null,
  depot: null,
  assignees: null,
  assessmentType: null,
  hazard: null,
  liveonly: false,
  daterange: null,
  has_unresolved_vra: false,
  has_unresolved_blocker: false,
  is_active: false,
  has_major_hazard: false,
  has_vra_ready_for_sign_off: false,
  has_no_vra: false,
  sort: JobSort.PRIORITY,
};

export const AWSRegion = {
  EU_WEST_1: 'eu-west-1',
  US_EAST_1: 'us-east-1',
  AP_SOUTHEAST_2: 'ap-southeast-2',
};

const languages = [
  {
    displayName: 'English',
    locale: 'en',
  },
  {
    displayName: 'Spanish',
    locale: 'es',
  },
];

export const Languages = {
  all: languages,
  english: languages[0],
  spanish: languages[1],
};

export const Regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phoneNumber: /^\+(?:[0-9] ?){6,14}[0-9]$/,
  postcode:
    /^([A-Z][0-9]{1,2}|[A-Z][A-HJ-Y][0-9]{1,2}|[A-Z][0-9][A-Z]|[A-Z][A-HJ-Y][0-9]?[A-Z])[0-9][A-Z]{2}$/i,
};

export const WidgetKind = {
  TEXT_AREA: 'textarea',
  TEXT_FIELD: 'textfield',
  SEPARATOR: 'separator',
  BINARY_RADIOBUTTON: 'binary-radiobutton',
  UNARY_CHECKBOX: 'unary-checkbox',
  VIDEO_UPLOAD_INFO_PAGE: 'video-upload-with-info-page',
  INSTRUCTION: 'instruction',
  MULTI_SELECT_CUSTOM_TEXT: 'multi-select-with-custom-text',
  MULTI_SELECT: 'multi-select',
  SINGLE_SELECT: 'single-select',
  SINGLE_SELECT_INLINE: 'single-select-inline',
  PHOTO_UPLOAD: 'photo-upload',
  VIDEO_UPLOAD: 'video-upload',
  USER_SELECTOR: 'user-selector',
  MULTI_USER_SELECTOR: 'multi-user-selector',
  DATE_SELECTOR: 'date-selector',
  HEADER_2: 'header-2',
  CALCULATED: 'calculated-widget',
};

export const userRoles = {
  FIELDWORKER: 'fieldworker',
  MANAGER: 'manager',
};

export const userRoleBackendLabels = {
  [userRoles.FIELDWORKER]: 'Field worker',
  [userRoles.MANAGER]: 'Manager',
};

export const DEVICELESS = 'deviceless';

export const insightsComplianceFields = {
  USER_ID: 'user_id',
  USER_NAME: 'full_name',
  JOBS_ASSIGNED_COUNT: 'jobs_assigned_count',
  JOBS_ASSIGNED: 'jobs_assigned',
  VRA_AND_SIGN_ON_PERCENTAGE: 'vra_and_sign_on_percentage',
  WRAPUP_PERCENTAGE: 'wrapup_percentage',
  JOB_BLOCKER_COUNT: 'job_blocker_count',
};

export const vehicleCheckComplianceFields = {
  DATE_RANGE: 'date_range',
  REGISTRATION: 'registration',
  SUBMISSIONS: 'submissions',
  DEFECTS: 'defects',
  MILEAGE_CHECKS: 'mileage_checks',
  TYRE_PRESSURE_CHECKS: 'tyre_pressure_checks',
  TYRE_THREAD_DEPTH_CHECKS: 'tyre_thread_depth_checks',
};

export const vehicleCheckUserComplianceFields = {
  DATE_RANGE: 'date_range',
  USER_NAME: 'full_name',
  SUBMISSIONS: 'submissions',
  DEFECTS: 'defects',
  MILEAGE_CHECKS: 'mileage_checks',
  TYRE_PRESSURE_CHECKS: 'tyre_pressure_checks',
  TYRE_THREAD_DEPTH_CHECKS: 'tyre_thread_depth_checks',
};

export const jobV2Fields = {
  IS_PINNED: 'is_pinned',
  FIELDWORKERS: 'fieldworkers',
  LOCATION: 'location',
  WORK_ORDER_NUMBER: 'work_order_number',
  LAST_ACTIVITY: 'last_activity',
  STATUS: 'status',
  ACTION_REQUIRED: 'action_required',
  OPEN_BUTTON: 'open_button',
};

export const COMMAND_CENTER_LATEST_ACTIVITY_MAX_NUMBER_OF_ITEMS = 50;

export const PropertyName = {
  TITLE: 'title',
};

// Page view versioning constants
export const URL_VIEW_QUERY_PARAM_FIELD = 'view';
export const URL_TAB_QUERY_PARAM_FIELD = 'tab';

export const LIST_VIEW = 'list-view';
export const LEGACY_VIEW = 'legacy-view';
export const LIST_VIEW_TAB = 'list-view-tab';
export const MAP_VIEW_TAB = 'map-view-tab';

export const TIMELINE = 'timeline';
export const JOB_OVERVIEW = 'job-overview';

export const LoginPages = {
  EMAIL_CHECK: 'email_check',
  EMAIL_PASSWORD: 'email_password',
  MAGIC_LINK: 'magic_link',
  DECODE_MAGIC_LINK: 'decode_magic_link',
  SSO: 'SSO',
  OKTA: 'OKTA',
};

export const JobsToggle = {
  MY_JOBS: 'my-jobs',
  ALL_JOBS: 'all-jobs',
};

export const SessionInitialPageLoad = 'page-load';
export const LIST_VIEW_PAGE_SIZE = 20;
export const MAP_VIEW_PAGE_SIZE = 10000;
export const INSIGHTS_TABLE_PAGE_SIZE = 20;
export const JOB_SUBTYPES_PAGE_SIZE = 10000;

// Analytics
export const EVENT_CLICK_SIGN_UP = 'click-sign-up';
export const EVENT_CLICK_LOGIN_CONTINUE = 'click-login-continue';
export const EVENT_LOGIN_ATTEMPT = 'user-login-attempt';
export const EVENT_LOGIN_COMPLETE = 'user-login';
export const EVENT_CHECK_EMAIL_FOR_SSO_REDIRECT = 'check-email-for-sso';
export const EVENT_LOGIN_WITH_SSO_CLICKED = 'click-login-with-sso';
export const EVENT_LOGIN_WITH_EMAIL_PASSWORD_CLICKED =
  'click-login-with-email-password';
export const EVENT_CLICK_MENU = 'click-menu';
export const EVENT_CLICK_HOME = 'click-home';
export const EVENT_CLICK_ERROR_MODAL_CLOSE = 'click-error-modal-close';
export const EVENT_PAGE_VIEW_LOGIN = 'login-page-view';
export const EVENT_PAGE_VIEW_RESET_PASSWORD = 'reset-password';
export const EVENT_PAGE_VIEW_JOB_OVERVIEW = 'job-overview';
export const EVENT_PAGE_VIEW_EVIDENCE = 'page-viewed-review-evidence';
export const EVENT_PAGE_VIEW_RISK_ASSESSMENT =
  'page-viewed-review-risk-assessment';
export const EVENT_PAGE_VIEW_NOTE = 'page-viewed-review-note';
export const EVENT_PAGE_VIEW_JOB_BLOCKER = 'page-viewed-review-job-blocker';
export const EVENT_PAGE_VIEW_WORK_COMPLETED = 'page-viewed-work-completed';
export const EVENT_PAGE_VIEW_DIGITAL_SIGNATURE = 'signature-viewed';

export const EVENT_USER_LOGIN_FIRST = 'user-login-first';
export const EVENT_USER_LOGIN = 'user-login';
export const EVENT_USER_LOGOUT = 'user-logout';
export const EVENT_CREATE_USER = 'create-user-button-clicked';
export const EVENT_PASSWORD_CLICK_FORGOTTEN_LINK =
  'password-click-forgotten-link';
export const EVENT_CLICK_REQUEST_MAGIC_LINK =
  'password-click-request-magic-link';
export const EVENT_PASSWORD_REQUEST_NEW = 'password-request-new';
export const EVENT_REQUEST_MAGIC_LINK = 'request-magic-link';
export const EVENT_PASSWORD_RESET = 'password-reset';
export const EVENT_MESSAGE_SENT = 'message-sent';
export const EVENT_EVIDENCE_PLAY_VIDEO = 'evidence-play-video';
export const EVENT_EVIDENCE_PAUSE_VIDEO = 'evidence-pause-video';
export const EVENT_EVIDENCE_DOWNLOAD_IMAGE = 'evidence-image-download';
export const CHAT_JOIN_JOB = 'join_job';
export const CHAT_LEAVE_JOB = 'leave_job';
export const CHAT_NEW_MESSAGE = 'new_message';

export const EVENT_OPEN_EDIT_USER_MODAL = 'settings-edit-user-open-modal';
export const EVENT_EDIT_USER_SAVE_CHANGES = 'settings-edit-user-save-changes';
export const EVENT_EDIT_USER_SUCCESS = 'settings-edit-user-success';

export const EVENT_JOB_ADD_USER = 'job-add-user';
export const EVENT_JOB_REMOVE_USER = 'job-remove-user';
export const EVENT_TEAM_MEMBERS_BUTTON = 'job-team-members-button';
export const EVENT_JOB_UPDATE_BUTTON = 'job-update-button';
export const EVENT_JOB_REQUEST_FILES = 'job-request-files';
export const EVENT_JOB_REQUEST_FILES_CONFIRM_CLICKED =
  'job-request-files-confirm-clicked';
export const EVENT_JOB_UPDATED = 'job-updated';
export const EVENT_EDIT_JOB_TEAM_MEMBERS_BUTTON = 'edit-team-members-button';
export const EVENT_DELETE_JOB_BUTTON = 'delete_job';
export const EVENT_JOB_TEAM_MEMBERS_UPDATED = 'job-team-members-updated';
export const EVENT_VIEWED_JOB_DETAILS = 'viewed-job-details';
export const EVENT_PAGINATION_CLICKED = 'pagination-clicked';
export const EVENT_JOB_SORTED = 'job-sorted';
export const EVENT_JOB_PINNED = 'job-pinned';
export const EVENT_JOB_PAGE_BOX_FILTER_CLICKED = 'job-page-box-filter-clicked';
export const EVENT_VIEWED_HAZARDS_CONTROLS_LIST =
  'viewed-hazards-controls-list';
export const EVENT_VIEWED_RISK_ASSESSMENTS_LIST =
  'viewed-risk-assessments-list';
export const EVENT_VIEW_RISK_ASSESSMENT_ITEM = 'view-risk-assessment-item';
export const EVENT_VIEWED_JOB_EVIDENCE_LIST = 'viewed-job-evidence-list';
export const EVENT_VIEWED_JOB_FORMS_LIST = 'viewed-form-list';
export const EVENT_VIEW_FORMS_ITEM = 'view-form-item';
export const EVENT_VIEW_EVIDENCE_ITEM = 'view-evidence-item';
export const EVENT_VIEW_TEXT_ITEM = 'view-text-item';
export const EVENT_VIEW_EVENT_ITEM = 'view-event-item';
export const EVENT_VIEW_DOCUMENT_ITEM = 'view-document-item';
export const EVENT_VIEWED_LOG_LIST = 'viewed-log-list';
export const EVENT_VIEW_NOTE_ITEM = 'view-note-item';
export const EVENT_VIEWED_JOB_BLOCKER_LIST = 'viewed-job-blocker-list';
export const EVENT_VIEW_JOB_BLOCKER_ITEM = 'view-job-blocker-item';
export const EVENT_VIEWED_WORK_COMPLETED_LIST = 'viewed-work-completed-list';
export const EVENT_VIEWED_DOCUMENTS_LIST = 'viewed-documents-list';
export const EVENT_VIEW_WORK_COMPLETED_ITEM = 'view-work-completed-item';
export const EVENT_VIEWED_PERMITS_LIST = 'viewed-permits-list';
export const EVENT_VIEW_PERMIT_ITEM = 'view-permit-item';
export const EVENT_PERMIT_REJECT_BUTTON_CLICKED = 'permit-reject-clicked';
export const EVENT_PERMIT_SET_DURATION_BUTTON_CLICKED =
  'permit-set-duration-clicked';
export const EVENT_VIEW_JOB_FORM_ITEM = 'view-job-form-item';
export const EVENT_DOWNLOAD_DOCUMENT_ITEM = 'download-document-item';
export const EVENT_VIEW_SITE_BRIEFING_ITEM = 'view-site-briefing-item';
export const EVENT_JOB_SEARCH = 'job-search';
export const EVENT_JOB_SEARCH_MORE = 'job-search-more';
export const EVENT_FILTER_SEARCH = 'filter-search';
export const EVENT_CLEAR_FILTERS = 'clear-filters';
export const EVENT_JOB_FILTER_APPLY = 'job-filter-applied';
export const EVENT_TOGGLE_LIST_VIEW = 'toggle-list-view';
export const EVENT_VIEW_JOB = 'view-job';
export const EVENT_VIEW_SUGGEST_HAZARD = 'view-suggest-hazard';
export const EVENT_VIEW_SUGGEST_CONTROL = 'view-suggest-control';
export const EVENT_VIEW_ADD_HAZARD = 'view-add-hazard';
export const EVENT_VIEW_ADD_CONTROL = 'view-add-control';
export const EVENT_SUGGEST_HAZARDS = 'suggest-hazards';
export const EVENT_SUGGEST_CONTROLS = 'suggest-controls';
export const EVENT_SUGGEST_HAZARDS_WITH_CONTROL =
  'suggest-hazards-with-control';
export const EVENT_ADD_HAZARDS = 'add-hazards';
export const EVENT_ADD_CONTROLS = 'add-controls';
export const EVENT_RISK_ASSESSMENT_VRA_AI_SUMMARY_THUMBS_UP =
  'vra-ai-summary-thumbs-up';
export const EVENT_RISK_ASSESSMENT_VRA_AI_SUMMARY_THUMBS_DOWN =
  'vra-ai-summary-thumbs-down';
export const EVENT_RISK_ASSESSMENT_SIGNOFF = 'risk-assessment-sign-off';
export const EVENT_FIELDWORKER_FAILED_LOGIN = 'fieldworker-failed-login';
export const EVENT_VIEW_JOB_TIMELINE = 'view-job-timeline';
export const EVENT_VIEW_SETTINGS = 'view-settings';
export const EVENT_VIEW_INSIGHTS = 'view-insights';
export const EVENT_VIEW_FATIGUE_MANAGER = 'view-fatigue-manager';
export const EVENT_VIEW_COMMAND_CENTER = 'view-command-center';
export const EVENT_NOTIFICATION_EXEMPTIONS_UPDATED =
  'notification-exemptions-updated';
export const EVENT_JOB_BLOCKER_RESOLVE_CLICKED = 'job-blocker-resolve-clicked';
export const EVENT_JOB_BLOCKER_RESOLVED = 'job-blocker-resolved';
export const EVENT_RAMS_SUMMARY_EDIT_HAZARD_CONTROL_BUTTON_CLICKED =
  'rams-summary-edit-hazard-control-button-clicked';
export const EVENT_RAMS_SUMMARY_ADD_REMOVE_BUTTON_CLICKED =
  'rams-summary-add-remove-button-clicked';
export const EVENT_ADD_DOCUMENT_BUTTON = 'job-add-document-clicked';
export const EVENT_ADD_EVIDENCE_BUTTON = 'job-add-evidence-clicked';
export const EVENT_ADD_DOCUMENTS_UPLOAD = 'job-upload-documents';
export const EVENT_ADD_EVIDENCE_UPLOAD = 'job-upload-evidence';
export const EVENT_ADD_BULK_JOB_UPLOAD = 'job-upload-bulk-job';
export const EVENT_ADD_BULK_JOB_DOWNLOAD_TEMPLATE =
  'job-upload-bulk-job-download-template';
export const EVENT_ADD_BULK_JOB_SUBMIT_CLICKED =
  'job-upload-bulk-job-submit-clicked';
export const EVENT_JPD_LOADED = 'insights-jobs-per-day-loaded';
export const EVENT_OPD_LOADED = 'insights-output-per-day-loaded';
export const EVENT_ENGAGEMENT_LOADED = 'insights-engagement-loaded';
export const EVENT_JOB_BLOCKERS_LOADED = 'insights-job-blockers-loaded';
export const EVENT_ADD_NEW_USER_MODAL_OPEN = 'settings-add-new-user-modal-open';
export const EVENT_ADD_NEW_USER = 'settings-add-new-user-success';
export const EVENT_OPEN_ACTIVATE_MODAL = 'settings-open-activate-modal';
export const EVENT_OPEN_DEACTIVATE_MODAL = 'settings-open-deactivate-modal';
export const EVENT_OPEN_EDIT_MODAL = 'settings-open-edit-modal';
export const EVENT_ACTIVATE_USER = 'settings-activate-user';
export const EVENT_DEACTIVATE_USER = 'settings-deactivate-user';
export const EVENT_EDIT_USER = 'settings-edit-user';
export const EVENT_EXPORT_USER_LIST = 'settings-export-user-list';
export const EVENT_SORT_CLICKED = 'job-sort-clicked';
export const EVENT_SORT_SUBMITTED = 'job-sort-submitted';
export const EVENT_FRA_SUBMIT_INTERVENTION =
  'insights-fatigue-submit-fra-intervention-clicked';
export const EVENT_ENGAGEMENT_JOB_NUMBER_CLICKED =
  'insights-engagement-job-number-clicked';
export const EVENT_MAP_ENTERING_STREET_VIEW = 'map-entering-street-view';
export const EVENT_MAP_LEAVING_STREET_VIEW = 'map-leaving-street-view';
export const EVENT_VIEW_CREATE_JOB = 'view-create-job';
export const EVENT_CREATE_JOB = 'job-created';
export const EVENT_SHIFT_UPDATE_USER_FINISHING_TIME =
  'shift-update-user-finishing-time';
export const EVENT_FATIGUE_LIVE_VIEW_LOADED = 'fatigue-live-view-loaded';
export const EVENT_FATIGUE_HISTORIC_VIEW_LOADED =
  'fatigue-historic-view-loaded';
export const EVENT_FATIGUE_HISTORIC_VIEW_FILTER_APPLY =
  'fatigue-historic-view-filter-apply-clicked';
export const FATIGUE_FILTER_CLICKED = 'fatigue-filter-clicked';
export const FATIGUE_FILTER_APPLY = 'fatigue-filter-applied';
export const INSIGHTS_JOB_BLOCKERS_DRILLDOWN =
  'insights-job-blockers-drilldown';
export const INSIGHTS_JOB_BLOCKERS_JOB_CLICKED =
  'insights-job-blockers-job-clicked';
export const INSIGHTS_FILTER_CLICKED = 'insights-filter-clicked';
export const INSIGHTS_FILTER_APPLY = 'insights-filter-applied';
export const INSIGHTS_JOBS_PER_DAY_DRILLDOWN =
  'insights-jobs-per-day-drilldown';
export const INSIGHTS_JOBS_PER_DAY_JOB_CLICKED =
  'insights-jobs-per-day-job-clicked';
export const FILTER_MANAGE_TEAMS_CLICKED = 'filter-manage-teams-clicked';
export const MANAGE_TEAMS_CLICKED = 'manage-teams';
export const MANAGE_TEAMS_EDIT_TEAM_CLICKED = 'manage-teams-edit-team';
export const MANAGE_TEAMS_APPLY_FILTER_CLICKED = 'manage-teams-apply-filter';
export const MANAGE_TEAMS_CREATE_TEAM_CLICKED = 'manage-teams-create-new-team';
export const MANAGE_TEAMS_CANCEL_CLICKED = 'manage-teams-cancel';
export const MANAGE_TEAMS_CREATE_NEW_CANCEL_CLICKED =
  'manage-teams-create-new-team-cancel';
export const MANAGE_TEAMS_USER_ADDED = 'manage-teams-user-added';
export const MANAGE_TEAMS_USER_REMOVED = 'manage-teams-user-removed';
export const MANAGE_TEAMS_CREATE_CLICKED = 'manage-teams-create-team';
export const MANAGE_TEAMS_EDIT_CANCEL_CLICKED = 'manage-teams-edit-team-cancel';
export const MANAGE_TEAMS_UPDATE_CLICKED = 'manage-teams-update-team';
export const MANAGE_TEAMS_DELETE_CLICKED = 'manage-teams-delete-team';
export const EVENT_PAGE_VIEW_MANAGE_TEAMS_DELETE_CONFIRMATION =
  'view-manage-team-delete-team-confirm';
export const MANAGE_TEAMS_DELETE_CONFIRM_CLICKED =
  'manage-team-delete-team-confirm';
export const MANAGE_TEAMS_DELETE_CANCEL_CLICKED =
  'manage-team-delete-team-cancel';

export const EVENT_INSIGHTS_ADD_USER = 'insights-add-user';
export const EVENT_INSIGHTS_REMOVE_USER = 'insights-remove-user';

export const COMPLIANCE_SORT_BY = 'compliance-sort-by';
export const CLICKED = 'clicked';
export const EVENT_COMPLIANCE_LOADED = 'compliance-loaded';

export const INSIGHTS_METRIC_INFORMATION_CLICKED =
  'insights-metric-information';

export const INSIGHTS_COMPLIANCE_TEAM_MEMBERS_EDIT_CLICKED =
  'insights-compliance-team-members-edit-clicked';
export const INSIGHTS_COMPLIANCE_TEAM_MEMBERS_SAVE_CLICKED =
  'insights-compliance-team-members-save-clicked';
export const INSIGHTS_COMPLIANCE_TEAM_MEMBERS_CANCEL_CLICKED =
  'insights-compliance-team-members-cancel-clicked';
export const INSIGHTS_COMPLIANCE_JOBS_ASSIGNED_CLICKED =
  'insights-compliance-jobs-assigned-clicked';

export const TEAMS_SAVE_DEFAULT = 'teams-save-default';

export const COMMAND_CENTER_LOADED = 'command-center-loaded';
export const COMMAND_CENTER_STAT_BOX_CLICKED =
  'command-center-stat-box-clicked';
export const COMMAND_CENTER_ACTIVITY_CLICKED =
  'command-center-activity-clicked';
export const COMMAND_CENTER_VIEW_JOB = 'command-center-view-job';
export const COMMAND_CENTER_FILTER_OPENED = 'command-center-filter-opened';
export const COMMAND_CENTER_FILTER_SUBMITTED =
  'command-center-filter-submitted';
export const COMMAND_CENTER_FILTER_CLEARED = 'command-center-filter-cleared';
export const COMMAND_CENTER_USER_FILTER_CLEARED =
  'command-center-user-filter-cleared';
export const INSTRUCTION_WIDGET_CLICKED = 'instruction-widget-clicked';
export const FORM_DASHBOARD_OPENED = 'open_form_dashboard';
export const FORM_DASHBOARD_VIEWED = 'view_form_dashboard';
export const FORM_DASHBOARD_VIDEO_PLAYED = 'form_video_played_dashboard';
export const FORM_DASHBOARD_VIDEO_PAUSED = 'form_video_paused_dashboard';
export const FORM_DASHBOARD_USER_FILTER_OPENED =
  'form_dashboard_user_filter_opened';
export const FORM_DASHBOARD_USER_FILTER_SUBMITTED =
  'form_dashboard_user_filter_submitted';
export const FORM_DASHBOARD_USER_FILTER_CLEARED =
  'form_dashboard_user_filter_cleared';
export const EVENT_CLICK_EDIT_WORK_OUTPUT = 'edit_work_output';
export const EVENT_SAVE_EDIT_WORK_OUTPUT = 'save_edited_work_output';
export const EVENT_CANCEL_EDIT_WORK_OUTPUT = 'cancel_edited_work_output';

export const EVENT_CLOSE_INSIGHT_FORM_MODAL = 'close_inisght_form_modal';

export const EVENT_FEEDBACK_MODAL_CLICKED = 'feedback_modal_clicked';

export const FILE_UPLOAD_SUBMIT_CLICKED = 'file-upload-submit-clicked';
export const EVIDENCE_MEDIA_OPTION_IMAGE_CLICKED =
  'evidence-media-option-image-clicked';
export const EVIDENCE_MEDIA_OPTION_VIDEO_CLICKED =
  'evidence-media-option-video-clicked';
export const EVIDENCE_MEDIA_OPTION_TYPE_SELECTED =
  'evidence-media-option-type-selected';

export const EVENT_LIST_VIEW_CLICKED = 'list-view-clicked';
export const EVENT_LEGACY_VIEW_CLICKED = 'old-map-view-clicked';
export const EVENT_MAP_VIEW_CLICKED = 'new-map-view-clicked';
export const EVENT_JOB_DETAILS_CLOSE = 'job-details-timeline-closed';
export const EVENT_JOB_DETAILS_TIMELINE_TAB_CLICKED =
  'job-details-timeline-tab-clicked';
export const EVENT_JOB_DETAILS_OVERVIEW_TAB_CLICKED =
  'job-details-overview-tab-clicked';
export const EVENT_JOB_DETAILS_GO_BACK_TO_OVERVIEW_CLICKED =
  'job-details-back-to-overview-clicked';
export const TIMELINE_PLUS_CLICKED = 'timeline-plus-clicked';

export const EVENT_PROPERTIES_USER_ID_FIELD = 'user_id';
export const EVENT_PROPERTIES_PAGE_SOURCE_FIELD = 'page_source';
export const EVENT_PROPERTIES_PAGE_SOURCE_PREVIOUS_FIELD =
  'page_source_previous';
export const EVENT_PROPERTIES_SAFETY_RISK_FIELD = 'is_high_safety_risk';
export const EVENT_PROPERTIES_HAS_UNREAD_MESSAGES_FIELD = 'has_unread_messages';
export const EVENT_PROPERTIES_HAS_PENDING_JOB_BLOCKERS_FIELD =
  'has_pending_job_blockers';
export const EVENT_PROPERTIES_PENDING_ASSESSMENT_SIGN_OFF_FIELD =
  'pending_assessment_sign_off';
export const EVENT_PROPERTIES_HAS_MAJOR_HAZARD = 'has_major_hazard';
export const EVENT_PROPERTIES_HAS_RISK_ASSESSMENT_CREATED =
  'has_risk_assessment_created';

export const EVENT_BACK_TO_TIMELINE = 'back-to-timeline-button-clicked';
export const EVENT_BACK_TO_SETTINGS = 'back-to-settings-button-clicked';

export const EVENT_PSI_FEEDBACK_THUMBS_UP_CLICKED =
  'psi-feedback-thumbs-up-clicked';
export const EVENT_PSI_FEEDBACK_THUMBS_DOWN_CLICKED =
  'psi-feedback-thumbs-down-clicked';
export const EVENT_PSI_FEEDBACK_NONE_OF_ABOVE_OPTION_SELECTED =
  'psi-feedback-none-of-above-option-selected';
export const EVENT_PSI_FEEDBACK_SUBMITTED = 'psi-feedback-submitted';
export const EVENT_PSI_FEEDBACK_MODAL_CLOSED = 'psi-feedback-modal-closed';

export const EVENT_USER_FORM_COMMENT_SENT = 'user-form-comment-sent';
export const EVENT_USER_FORM_DATA_EXPORT = 'custom-form-data-export';

export const EVENT_HAVS_COMPLIANCE_SIGNED_OFF = 'havs-compliance-signed-off';
export const EVENT_HAVS_COMPLIANCE_COMMENT_SENT =
  'havs-compliance-comment-sent';
export const EVENT_HAVS_COMPLIANCE_DATA_EXPORT = 'havs-compliance-data-export';

export const EVENT_WALKTHROUGH_POP_UP_CLICKED = 'tour-pop-up-clicked';
export const EVENT_WALKTHROUGH_TEAM_FILTER_CONFIRM_CLICKED =
  'customer-team-filter-click-confirm';
export const EVENT_WALKTHROUGH_STAT_BOX_CONFIRM_CLICKED =
  'stat-box-click-confirm';
export const EVENT_WALKTHROUGH_PIN_JOBS_CONFIRM_CLICKED =
  'pin-jobs-click-confirm';
export const EVENT_WALKTHROUGH_SWITCH_VIEW_CONFIRM_CLICKED =
  'switch-view-click-confirm';
export const EVENT_WALKTHROUGH_TOUR_END_CONFIRM_CLICKED =
  'tour-end-confirmation-clicked';
export const EVENT_WALKTHROUGH_INFORMATION_ICON_CLICKED =
  'information-icon-clicked';

export const EVENT_MAP_INTERACTION = 'map-interaction';

export const LIST_VIEW_MAXIMUM_DATE_RANGE = 14;
export const MAP_VIEW_MAXIMUM_DATE_RANGE = 90;
export const DEFAULT_MAXIMUM_DATE_RANGE = 90;
